import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import classnames from 'classnames'

import Media from 'react-media'
import sanitizeHtml from 'sanitize-html'
import { format } from 'date-fns'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import CustomInput from '../CustomControls/CustomInput'
import CustomSelect from '../CustomControls/CustomSelect'
import CustomModal from '../CustomModal/CustomModal'
import CustomIconButton from '../CustomControls/CustomIconButton'
import CustomLoader from '../CustomLoader/CustomLoader'
import CustomDatepicker from '../CustomControls/CustomDatepicker'
import CustomAutocomplete from '../CustomControls/CustomAutocomplete'
import { ReactComponent as DocPlusIcon } from '../../assets/images/doc-plus.svg'
import { ReactComponent as DownloadIcon } from '../../assets/images/icon-button-download.svg'
import useStyles from './panel.style'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import { clearMetadata, fetchMetadataStartAsync, getMetadataSuccess } from '../../redux/metadata/metadataSlice'
import { clearSecurities, fetchSecuritiesStartAsync, getSecuritiesSuccess } from '../../redux/securities/securitiesSlice'
import {
  clearPartTransferSecurity,
  clearTransferSecurity,
  fetchGetBalancesDepoStartAsync,
  fetchPerformTransferSecurityStartAsync,
  fetchSaveTransferSecurityStartAsync,
  fetchTransferSecurityStartAsync,
  getBalancesDepoSuccess,
  getTransferSecuritySuccess,
  performTransferSecuritySuccess,
  saveTransferSecuritySuccess,
} from '../../redux/transfers/security/securityMoneySlice'
import { selectConnectionState } from '../../redux/connection/selector'
import { selectCurrentMetadata } from '../../redux/metadata/selector'
import { selectCurrentSecurities } from '../../redux/securities/selector'
import { selectCurrentError } from '../../redux/error/selector'
import {
  selectGetBalanceSecurity,
  selectGetTransferSecurity,
  selectPerformTransferSecurity,
  selectSaveTransferSecurity,
} from '../../redux/transfers/security/selector'
import createUri from '../../utils/FilterUri'
import { encodeToUTF8 } from '../../utils/EncodeToUTF8'
import Timer from '../../utils/Timer'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import PageHeading from '../PageHeading/PageHeading'
import { ReactComponent as ArrowBottomFull } from '../../assets/images/arrow-bottom-full.svg'
import CustomButton from '../CustomControls/CustomButton'
import formAction from '../../utils/FormAction'
import CustomInputSelect from '../CustomControls/CustomInputSelect'
import { addEmptyValueToArray } from '../../utils/Validate'
import { currentUserLoginAndId, selectCurrentThumbprint } from '../../redux/user/selector'
import useInput from '../../hooks/useInput'
import JsonToSHA256 from '../../utils/JsonToSHA256'

const initialState = {
  portfolio: {
    minus: '',
    plus: '',
  },
  storage: {
    minus: '',
    plus: '',
  },
  issuer: {
    id: null,
    isin: '',
    name: '',
    registerNumber: '',
    type: '',
  },
  searchStr: '',
  input: {
    type: '',
    id: null,
    code: '',
    name: '',
    isin: '',
    registerNumber: '',
  },
  validity: new Date(),
  expirationDate: null,
}

const transferType = 'Orders.Type.NonTrade.Depo.Transfer'
const cancelType = 'Orders.Type.Modify.Cancellation'

export default function SecuritiesTransfer() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const urlParams = useParams()
  const location = useLocation()
  const transferId = useMemo(() => urlParams.id, [urlParams])
  const transferUid = useMemo(() => location?.state?.orderUid, [location])
  const [metadataUid, setMetadataUid] = useState(transferUid || transferType)
  const [formDisabled, setFormDisabled] = useState(false)
  const [portfolioMinus, setPortfolioMinus] = useState(initialState.portfolio.minus)
  const [portfolioPlus, setPortfolioPlus] = useState(initialState.portfolio.plus)
  const [storageMinus, setStorageMinus] = useState(initialState.storage.minus)
  const [storagePlus, setStoragePlus] = useState(initialState.storage.plus)
  const [selectError, setSelectError] = useState(false)
  const [searchStr, setSearchStr] = useState(initialState.searchStr)
  const [input, setInput] = useState(initialState.input)
  const [groupOneVisibility, setGroupOneVisibility] = useState(false)
  const [groupTwoVisibility, setGroupTwoVisibility] = useState(false)
  const [issuer, setIssuer] = useState(initialState.issuer)
  const [validity, setValidity] = useState(initialState.validity)
  const [expirationDate, setExpirationDate] = useState(initialState.expirationDate)
  const [disabled, setDisabled] = useState(transferId === 'new')
  const [activeId, setActiveId] = useState('')
  const [showTimer, setShowTimer] = useState(false)
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [codeTextError, setCodeTextError] = useState(false)
  const amount = useInput('', { maxLength: 3, isOnlyNumbers: true })
  const [submitAction, setSubmitAction] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [updModalOpen, setUpdModalOpen] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [clientPortfolioError, setClientPortfolioError] = useState(false)
  const [clientPortfolioTextError, setClientPortfolioTextError] = useState('')
  const [clientDepoStorageError, setClientDepoStorageError] = useState(false)
  const [clientDepoStorageTextError, setClientDepoStorageTextError] = useState('')
  const [partnerDepoStorageError, setPartnerDepoStorageError] = useState(false)
  const [partnerDepoStorageTextError, setPartnerDepoStorageTextError] = useState('')
  const [partnerPortfolioError, setPartnerPortfolioError] = useState(false)
  const [partnerPortfolioTextError, setPartnerPortfolioTextError] = useState('')
  const [expirationDateError, setExpirationDateError] = useState(false)
  const [expirationDateTextError, setExpirationDateTextError] = useState('')
  const [securityNameError, setSecurityNameError] = useState(false)
  const [securityNameTextError, setSecurityNameTextError] = useState('')
  const [securityTypeError, setSecurityTypeError] = useState(false)
  const [securityTypeTextError, setSecurityTypeTextError] = useState('')
  const [securityRegisterNumberError, setSecurityRegisterNumberError] = useState(false)
  const [securityRegisterNumberTextError, setSecurityRegisterNumberTextError] = useState('')
  const [securityIsinError, setSecurityIsinError] = useState(false)
  const [securityIsinTextError, setSecurityIsinTextError] = useState('')
  const [volumeError, setVolumeError] = useState(false)
  const [volumeTextError, setVolumeTextError] = useState('')
  const [clientBalances, setClientBalances] = useState(null)
  const [partnerBalances, setPartnerBalances] = useState(null)
  const [transferHeader, setTransferHeader] = useState('')
  const [isCleared, setIsCleared] = useState(false)
  const [hashSha256, setHashSha256] = useState(null)

  const connectedWS = useSelector(selectConnectionState)
  const getRespSecurities = useSelector(selectCurrentSecurities)
  const getRespMetadata = useSelector(selectCurrentMetadata)
  const getRespTransferSecurity = useSelector(selectGetTransferSecurity)
  const getSaveTransferSecurity = useSelector(selectSaveTransferSecurity)
  const getPerformTransferSecurity = useSelector(selectPerformTransferSecurity)
  const getBalances = useSelector(selectGetBalanceSecurity)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)

  const portfolios = getRespMetadata?.references?.portfolios || []
  const [securityTypes, setSecurityTypes] = useState([])
  const [clientDepoStorage, setClientDepoStorage] = useState([])
  const [partnerDepoStorage, setPartnerDepoStorage] = useState([])
  const [currentStatus, setCurrentStatus] = useState('')
  const [canModifyDate, setCanModifyDate] = useState(true)
  const order = getRespTransferSecurity?.order
  const [currentActions, setCurrentActions] = useState(null)
  const [secretPassPhrase, setSecretPassPhrase] = useState('')

  const currentStatusDescription = order?.statusDescription
    && sanitizeHtml(order?.statusDescription)

  const createMarkup = () => (
    { __html: currentStatusDescription }
  )

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchSecuritiesStart = (obj) => {
    dispatch(fetchSecuritiesStartAsync(obj))
  }

  const fetchTransferSecurityStart = (id) => {
    dispatch(fetchTransferSecurityStartAsync({ id }))
  }

  const fetchSaveTransferSecurityStart = (data, id) => {
    dispatch(fetchSaveTransferSecurityStartAsync({ data, id }))
  }

  const fetchPerformTransferSecurityStart = (data, id) => {
    dispatch(fetchPerformTransferSecurityStartAsync({ data, id }))
  }

  const fetchGetBalancesDepoStart = (filterBody) => {
    dispatch(fetchGetBalancesDepoStartAsync({ filterBody }))
  }

  const {
    getData: getMetaData,
  } = useConnect({
    link: `GET /api${endpoints.metadata.getMetadata(metadataUid)}`,
    startFetching: fetchMetadataStart,
    action: getMetadataSuccess,
  })

  const {
    getData: securitiesFilter,
  } = useConnect({
    link: `GET /api${endpoints.securities.getSecurities()}`,
    startFetching: fetchSecuritiesStart,
    action: getSecuritiesSuccess,
  })

  const {
    getData,
  } = useConnect({
    link: `GET /api${endpoints.security.getTransferSecurity(activeId)}`,
    startFetching: fetchTransferSecurityStart,
    action: getTransferSecuritySuccess,
  })

  const {
    getData: postSaveData,
  } = useConnect({
    link: `POST /api${endpoints.security.postTransferSecurity(false)}`,
    startFetching: fetchSaveTransferSecurityStart,
    action: saveTransferSecuritySuccess,
  })

  const {
    getData: putSaveData,
  } = useConnect({
    link: `PUT /api${endpoints.security.putTransferSecurity(null, activeId, false)}`,
    startFetching: fetchSaveTransferSecurityStart,
    action: saveTransferSecuritySuccess,
  })

  const {
    getData: postPerformData,
  } = useConnect({
    link: `POST /api${endpoints.security.postTransferSecurity(false)}`,
    startFetching: fetchPerformTransferSecurityStart,
    action: performTransferSecuritySuccess,
  })

  const {
    getData: putPerformData,
  } = useConnect({
    link: `PUT /api${endpoints.security.putTransferSecurity(null, activeId, false)}`,
    startFetching: fetchPerformTransferSecurityStart,
    action: performTransferSecuritySuccess,
  })

  useConnect({ link: `GET /api${endpoints.assets.getBalancesDepo()}`, startFetching: fetchGetBalancesDepoStart, action: getBalancesDepoSuccess })

  const fillFields = (resObj, metadataPortfolios) => {
    if (metadataPortfolios && Object.keys(metadataPortfolios)?.length) {
      const licenseMinus = metadataPortfolios?.find(
        (item) => item.name === resObj?.clientPortfolio?.name,
      )
      setClientDepoStorage(licenseMinus?.depoStorages)
      const licensePlus = metadataPortfolios?.find(
        (item) => item.name === resObj?.partnerPortfolio?.name,
      )
      setPartnerDepoStorage(licensePlus?.depoStorages)
      setSecurityTypes(getRespMetadata?.references?.securityTypes)
    } else {
      setClientDepoStorage([resObj?.clientDepoStorage])
      setPartnerDepoStorage([resObj?.partnerDepoStorage])
      setSecurityTypes([resObj?.security?.type])
    }
    setPortfolioMinus(resObj?.clientPortfolio?.name)
    setPortfolioPlus(resObj?.partnerPortfolio?.name)
    setStorageMinus(resObj?.clientDepoStorage?.name || '')
    setStoragePlus(resObj?.partnerDepoStorage?.name || '')
    setSearchStr(resObj?.security?.name || '')
    setIssuer(resObj?.security)
    setInput((state) => ({
      ...state,
      type: resObj?.security?.type,
      registerNumber: resObj?.security?.registerNumber,
      isin: resObj?.security?.isin,
      id: resObj?.security?.id,
    }))
    amount.setValue(resObj?.volume || '')
    setValidity(
      (getRespTransferSecurity?.order?.expirationDate
        && new Date(getRespTransferSecurity.order.expirationDate)
      ) || null,
    )
    setExpirationDate(
      (getRespTransferSecurity?.order?.expirationDate
        && new Date(getRespTransferSecurity?.order?.expirationDate)
      ) || null,
    )
    if (currentActions?.canModify || resObj?.status?.uid === 'Draft') {
      setDisabled(false)
    } else {
      setDisabled(true)
      setGroupTwoVisibility(true)
    }
    // eslint-disable-next-line no-underscore-dangle
    if (!resObj?._links[0]?.canModify) {
      setDisabled(true)
      setCanModifyDate(false)
    }
  }

  const clearFields = (needClearData = true) => {
    if (needClearData) {
      dispatch(clearMetadata())
    }
    dispatch(clearTransferSecurity())
    dispatch(clearSecurities())
    setPortfolioMinus(initialState.portfolio.minus)
    setPortfolioPlus(initialState.portfolio.plus)
    setStorageMinus(initialState.storage.minus)
    setStoragePlus(initialState.storage.plus)
    setGroupOneVisibility(false)
    setGroupTwoVisibility(false)
    setSearchStr(initialState.searchStr)
    setInput(initialState.input)
    setIssuer(initialState.issuer)
    setValidity(initialState.validity)
    setExpirationDate(null)
    setSecurityTypes([])
    setClientDepoStorage([])
    setPartnerDepoStorage([])
    setDisabled(false)
    setClientPortfolioError(false)
    setClientDepoStorageError(false)
    setPartnerDepoStorageError(false)
    setPartnerPortfolioError(false)
    setExpirationDateError(false)
    setSecurityNameError(false)
    setSecurityTypeError(false)
    setSecurityRegisterNumberError(false)
    setSecurityIsinError(false)
    setVolumeError(false)
    setCodeError(false)
    setClientBalances(null)
    setPartnerBalances(null)
    setIsCleared(true)
    setCurrentActions(null)
    setCode('')
    setSecretPassPhrase('')
    setTransferHeader('')
    amount.setValue('')
  }

  const handleBackToHistory = () => {
    history.push('/transfers/securities/log')
  }

  const listMainFields = () => {
    let licenseMinus = null
    let licensePlus = null
    let depoStorageMinus = null
    let depoStoragePlus = null
    if (transferUid === cancelType) {
      licenseMinus = getRespTransferSecurity?.order?.affectedOrder?.clientPortfolio
      licensePlus = getRespTransferSecurity?.order?.affectedOrder?.partnerPortfolio
      depoStorageMinus = getRespTransferSecurity?.order?.affectedOrder?.clientDepoStorage
      depoStoragePlus = getRespTransferSecurity?.order?.affectedOrder?.partnerDepoStorage
    } else {
      licenseMinus = portfolios?.find(
        (item) => item.name === portfolioMinus,
      )
      licensePlus = portfolios?.find(
        (item) => item.name === portfolioPlus,
      )
      depoStorageMinus = licenseMinus?.depoStorages?.find((item) => item.name === storageMinus)
      depoStoragePlus = licensePlus?.depoStorages?.find((item) => item.name === storagePlus)
    }
    const obj = {
      clientPortfolio: licenseMinus,
      partnerPortfolio: licensePlus,
      clientDepoStorage: depoStorageMinus,
      partnerDepoStorage: depoStoragePlus,
    }
    return obj
  }

  const getSecurityValues = () => {
    if (groupOneVisibility) {
      return {
        id: input?.id || null,
        type: input?.type,
        registerNumber: input?.registerNumber,
        isin: input?.isin,
        name: searchStr,
      }
    }
    return issuer
  }

  useEffect(() => {
    if (connectedWS) {
      clearFields()
      setActiveId(null)
      if (transferId === 'new') {
        setMetadataUid(transferType)
        getMetaData(transferType)
        setIsLoading(false)
        clearFields()
      } else {
        getMetaData(metadataUid)
        getData(transferId)
        setActiveId(transferId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferId, connectedWS])

  useEffect(() => {
    if (getRespTransferSecurity && isCleared) {
      setIsLoading(false)
      setCurrentStatus(getRespTransferSecurity?.order?.status)
      const resObj = getRespTransferSecurity?.order?.affectedOrder
        || getRespTransferSecurity?.order
      fillFields(resObj, getRespMetadata?.references?.portfolios)
      if (getRespTransferSecurity?.order?.type?.uid === transferType) {
        if (getRespMetadata?.order?.type?.uid === cancelType) {
          const cancelDate = getRespMetadata?.order?.expirationDate
          const reqObj = {
            type: { uid: cancelType },
            expirationDate: cancelDate && format(new Date(cancelDate), 'yyyy-MM-dd'),
            affectedOrder: { id: +activeId },
            secret: '',
          }

          const date = getRespMetadata?.order?.date
          const number = getRespMetadata?.order?.number

          reqObj.date = date
          reqObj.number = number

          const WordArrayToBase64 = JsonToSHA256(reqObj)
          if (WordArrayToBase64) {
            reqObj.secret = WordArrayToBase64
            setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
          }

          postPerformData(reqObj)
        }
      }
      if (getRespTransferSecurity?.order?.type?.uid === cancelType) {
        // eslint-disable-next-line no-underscore-dangle
        if (getRespTransferSecurity?.order?._links[0]?.canModify) {
          setCanModifyDate(true)
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(getRespTransferSecurity?.order?._links[0])
      setTransferHeader(`${getRespTransferSecurity?.order?.description}`)
    } else if (transferId !== 'new') {
      setIsLoading(true)
    }
    // eslint-disable-next-line
  }, [getRespTransferSecurity, getRespMetadata, isCleared])

  useEffect(() => {
    if (connectedWS && isCleared) {
      if (getSaveTransferSecurity?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveTransferSecurity?.order?.id)
        }
        if (getSaveTransferSecurity?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
        if (getSaveTransferSecurity?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
      }
      if (getPerformTransferSecurity?.requiredAction) {
        if (!activeId || +activeId !== getPerformTransferSecurity?.order?.id) {
          setActiveId(getPerformTransferSecurity?.order?.id)
        }
        if (getPerformTransferSecurity?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
        if (getPerformTransferSecurity?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
        setCurrentStatus(getPerformTransferSecurity?.order?.status)
      }
      const msgData = getSaveTransferSecurity || getPerformTransferSecurity
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespTransferSecurity?.order?.type?.uid === cancelType) ? cancelType : transferType
      if (msgData?.order?.description) {
        setTransferHeader(`${msgData?.order?.description}`)
      }
      if (typeUID) {
        setIsLoading(false)
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(msgData?.order?._links[0])
      if (msgData?.order?.type?.uid === cancelType
        && getRespTransferSecurity?.order?.type?.uid !== cancelType) {
        const cancelDate = getRespMetadata?.order?.expirationDate
        setExpirationDate(cancelDate)
        setValidity(cancelDate)
      }
      if (getSaveTransferSecurity?.status === 303 || getPerformTransferSecurity?.status === 303) {
        setDisabled(true)
      }
      if (getSaveTransferSecurity?.status === 200 || getPerformTransferSecurity?.status === 200) {
        switch (getSaveTransferSecurity?.order?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (activeId) {
              setUpdModalOpen(true)
            } else {
              setSaveModalOpen(true)
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaveTransferSecurity, getPerformTransferSecurity, isCleared])

  useEffect(() => {
    if (serverError?.errors) {
      if (serverError?.errors?.ClientPortfolio?.length) {
        setClientPortfolioError(true)
        setClientPortfolioTextError(serverError?.errors?.ClientPortfolio[0])
      }
      if (serverError?.errors?.ClientDepoStorage?.length) {
        setClientDepoStorageError(true)
        setClientDepoStorageTextError(serverError?.errors?.ClientDepoStorage[0])
      }
      if (serverError?.errors?.PartnerDepoStorage?.length) {
        setPartnerDepoStorageError(true)
        setPartnerDepoStorageTextError(serverError?.errors?.PartnerDepoStorage[0])
      }
      if (serverError?.errors?.PartnerPortfolio?.length) {
        setPartnerPortfolioError(true)
        setPartnerPortfolioTextError(serverError?.errors?.PartnerPortfolio[0])
      }
      if (serverError?.errors?.ExpirationDate?.length) {
        setExpirationDateError(true)
        setExpirationDateTextError(serverError?.errors?.ExpirationDate[0])
      }
      if (serverError?.errors?.Security?.length) {
        setSecurityNameError(true)
        setSecurityNameTextError(serverError?.errors?.Security[0])
      }
      if (serverError?.errors['Security.Name']?.length) {
        setSecurityNameError(true)
        setSecurityNameTextError(serverError?.errors['Security.Name'][0])
      }
      if (serverError?.errors['Security.Type']?.length) {
        setSecurityTypeError(true)
        setSecurityTypeTextError(serverError?.errors['Security.Type'][0])
      }
      if (serverError?.errors['Security.RegisterNumber']?.length) {
        setSecurityRegisterNumberError(true)
        setSecurityRegisterNumberTextError(serverError?.errors['Security.RegisterNumber'][0])
      }
      if (serverError?.errors['Security.Isin']?.length) {
        setSecurityIsinError(true)
        setSecurityIsinTextError(serverError?.errors['Security.Isin'][0])
      }
      if (serverError?.errors?.Volume?.length) {
        setVolumeError(true)
        setVolumeTextError(serverError?.errors?.Volume[0])
      }
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeTextError(serverError?.errors?.Secret[0])
      }
      const msgData = getSaveTransferSecurity || getPerformTransferSecurity
      if ((!currentActions || currentActions?.canModify) && !msgData?.requiredAction?.type) {
        setDisabled(false)
      }
      setIsLoading(false)
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError])

  useEffect(() => () => {
    clearFields()
  }
  // eslint-disable-next-line
    , [])

  useEffect(() => {
    if (getBalances) {
      const mainFields = listMainFields()
      if (!getBalances?.portfolio) return

      const hasClientPortfolioId = getBalances?.portfolio?.id === mainFields?.clientPortfolio?.id
      const hasPartnerPortfolioId = getBalances?.portfolio?.id === mainFields?.partnerPortfolio?.id
      const hasStorageUid = getBalances?.storage?.id

      if (hasClientPortfolioId && (
        hasStorageUid ? mainFields?.clientDepoStorage?.id === getBalances?.storage?.id
          : !mainFields?.clientDepoStorage?.id)) {
        setClientBalances(getBalances)
      }

      if (hasPartnerPortfolioId && (
        hasStorageUid ? mainFields?.partnerDepoStorage?.id === getBalances?.storage?.id
          : !mainFields?.partnerDepoStorage?.id)) {
        setPartnerBalances(getBalances)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBalances])

  const fetchBalancesDepo = (filterBody, setBalances) => {
    if (filterBody) {
      fetchGetBalancesDepoStart(filterBody.replaceAll('+', ''))
    }
    setBalances(null)
  }

  useMemo(() => {
    const objMainFields = listMainFields()
    const security = getSecurityValues()
    let filterBodyClient
    if (portfolioMinus || storageMinus || security) {
      filterBodyClient = createUri({
        name: 'balancesDepo',
        portfolioId: objMainFields?.clientPortfolio?.id,
        securityId: security?.id,
        storageId: objMainFields?.clientDepoStorage?.id,
        condition: '&',
      })
      fetchBalancesDepo(filterBodyClient, setClientBalances)
    }
    setClientBalances(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioMinus, storageMinus, issuer])

  useEffect(() => {
    const objMainFields = listMainFields()
    const security = getSecurityValues()
    let filterBodyPartner
    if (portfolioPlus || storagePlus || security) {
      filterBodyPartner = createUri({
        name: 'balancesDepo',
        portfolioId: objMainFields?.partnerPortfolio?.id,
        securityId: security?.id,
        storageId: objMainFields?.partnerDepoStorage?.id,
        condition: '&',
      })
      fetchBalancesDepo(filterBodyPartner, setPartnerBalances)
    }
    setPartnerBalances(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioPlus, storagePlus, issuer])

  const handleChangePortfolioMinus = (event, selectedValue) => {
    setPortfolioMinus(selectedValue)
    setStorageMinus('')
    const currentObj = getRespMetadata?.references?.portfolios?.find(
      (elm) => elm.name === selectedValue,
    )
    if (currentObj?.depoStorages?.length) {
      setClientDepoStorage(currentObj.depoStorages)
    } else {
      setClientDepoStorage([])
    }
    setClientPortfolioError(false)
  }

  const handleChangePortfolioPlus = (event, selectedValue) => {
    setPortfolioPlus(selectedValue)
    setStoragePlus('')
    const currentObj = getRespMetadata?.references?.portfolios?.find(
      (elm) => elm.name === selectedValue,
    )
    if (currentObj?.depoStorages?.length) {
      setPartnerDepoStorage(currentObj.depoStorages)
    } else {
      setPartnerDepoStorage([])
    }
    setSelectError(false)
    setPartnerPortfolioError(false)
  }

  const handleChangeStorageMinus = (event) => {
    const { value } = event.target
    setStorageMinus(value)
    setClientDepoStorageError(false)
  }

  const handleChangeStoragePlus = (event) => {
    const { value } = event.target
    setStoragePlus(value)
    setSelectError(false)
    setPartnerDepoStorageError(false)
  }

  const handleChangeInput = (event) => {
    const { name, value } = event.target
    setInput((state) => ({ ...state, [name]: value }))
    setSecurityRegisterNumberError(false)
    setSecurityIsinError(false)
  }

  const handleChangeView = (event, object) => {
    const { name } = event.target
    const { item } = object.props
    setInput((state) => ({ ...state, [name]: item }))
    setSecurityTypeError(false)
  }

  const toggleGroupOneVisibility = () => {
    setGroupOneVisibility(true)
    setGroupTwoVisibility(false)
    setInput(issuer)
  }

  const handleChangeValidity = (date) => {
    setValidity(date)
    setExpirationDateError(false)
  }

  const getHandler = (handler) => (!disabled ? handler : null)

  const handleChangeAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStr(value)
      if (value && value.trim().length >= 3) {
        securitiesFilter(`Count=100&Search=${value}`)
      }
    }
    setSecurityNameError(false)
  }

  const handleChangeAutocomplete = (event, value) => {
    if (!value) {
      return
    }
    if (value?.name) {
      setSearchStr(value.name)
    }
    setIssuer(value)
    setGroupOneVisibility(false)
    setGroupTwoVisibility(true)
    const uri = 'Count=100'
    securitiesFilter(uri)
  }

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const addNeedsProps = (typeUID, msgData) => {
    const mainFields = listMainFields()
    const security = getSecurityValues()
    let reqObj = {}
    if (typeUID === cancelType) {
      reqObj = {
        type: { uid: typeUID },
        expirationDate: format(new Date(validity), 'yyyy-MM-dd'),
        affectedOrder: {
          id: msgData?.order?.affectedOrder?.id
            || getRespTransferSecurity?.order?.affectedOrder?.id,
        },
        secret: '',
      }
      return reqObj
    }
    reqObj = {
      type: { uid: typeUID },
      dateTime: format(new Date(), 'yyyy-MM-dd'),
      expirationDate: format(new Date(validity), 'yyyy-MM-dd'),
      clientDepoStorage: {
        id: mainFields?.clientDepoStorage?.id,
        account: mainFields?.clientDepoStorage?.account,
        name: mainFields?.clientDepoStorage?.name,
        isClosed: mainFields?.clientDepoStorage?.isClosed,
      },
      clientPortfolio: {
        id: mainFields?.clientPortfolio?.id,
      },
      partnerDepoStorage: {
        id: mainFields?.partnerDepoStorage?.id,
        account: mainFields?.partnerDepoStorage?.account,
        name: mainFields?.partnerDepoStorage?.name,
        isClosed: mainFields?.clientDepoStorage?.isClosed,
      },
      partnerPortfolio: {
        id: mainFields?.partnerPortfolio?.id,
      },
      security,
      volume: amount.value,
      secret: '',
    }
    return reqObj
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    // const mainFields = listMainFields()
    // const security = getSecurityValues()
    const msgData = getSaveTransferSecurity || getPerformTransferSecurity
    const typeUID = msgData?.requiredAction && msgData?.order?.type?.uid === cancelType
      ? cancelType : transferType
    const requestObj = addNeedsProps(typeUID, msgData)

    const date = getRespMetadata?.order?.date
    const number = getRespMetadata?.order?.number

    requestObj.date = date
    requestObj.number = number

    const WordArrayToBase64 = JsonToSHA256(requestObj)
    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID,
      putPerformData,
      postPerformData,
      activeId,
      secretPassPhrase,
    })
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
    if (connectedWS) {
      setFormDisabled(true)
      setIsLoading(true)

      if (submitAction === 'perform') {
        setDisabled(true)
      }
      const msgData = getSaveTransferSecurity || getPerformTransferSecurity
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespTransferSecurity?.order?.type?.uid === cancelType) ? cancelType : transferType
      const requestObj = addNeedsProps(typeUID, msgData)

      const date = getRespMetadata?.order?.date
      const number = getRespMetadata?.order?.number

      requestObj.date = date
      requestObj.number = number

      const WordArrayToBase64 = JsonToSHA256(requestObj)
      if (WordArrayToBase64) {
        requestObj.secret = WordArrayToBase64
        setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
      }

      formAction({
        submitAction,
        requestObj,
        typeUID,
        msgData,
        putPerformData,
        postPerformData,
        putSaveData,
        postSaveData,
        activeId,
        code,
        setCodeError,
        setCodeTextError,
        setCancellationModalOpen,
        setFormDisabled,
        setIsLoading,
        currentThumbPrint,
        secretPassPhrase,
      })
    }
  }

  const handleDownloadXml = () => {
    const msgData = getSaveTransferSecurity || getPerformTransferSecurity
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.order?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleCloseSaveModal = (goToHistory) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseUpdModal = () => {
    setUpdModalOpen(false)
    handleBackToHistory()
  }

  const handleClosePerformModal = (goToHistory) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
  }

  const handleCancelOk = () => {
    setMetadataUid(cancelType)
    getMetaData(cancelType)
    handleCloseCancellationModal()
  }

  const handleCancelOperation = (event) => {
    if (getRespMetadata?.order?.type?.uid === cancelType) { handleBackToHistory() } else {
      event.preventDefault()
      setDisabled(false)
      dispatch(clearPartTransferSecurity())
      setFormDisabled(false)
    }
  }

  const handleActionReject = () => {
    handleCloseSaveModal(true)
    handleClosePerformModal(true)
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)
    clearFields(false)
    setActiveId(null)
    amount.setValue('')
    setTransferHeader('')
    setFormDisabled(false)
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Box maxWidth={898}>
        <PageHeading text="Перевод" />
        {isLoading && (
          <Box mt="79px">
            <CustomLoader />
          </Box>
        )}
        {!isLoading && (
          <Box pt="30px">
            <form
              className={classes.form}
              onSubmit={handleSubmitForm}
              autoComplete="off"
            >
              {transferHeader && (
                <Box className={classes.orderInfo}>
                  <p className={classes.paragraph}>
                    {transferHeader}
                  </p>
                </Box>
              )}
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomInputSelect
                    label="Портфель (списания)"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={portfolioMinus || ''}
                    options={addEmptyValueToArray(portfolios?.map((option) => option.name))}
                    error={clientPortfolioError}
                    errorText={clientPortfolioTextError}
                    onChange={getHandler(handleChangePortfolioMinus)}
                    disabled={disabled}
                    unique
                  />
                </Grid>
                <Grid
                  className={classes.depoPanelWrapper}
                  item
                  lg={8}
                  sm={6}
                  xs={12}
                >
                  <CustomSelect
                    label="Место хранения (списания)"
                    value={storageMinus || ''}
                    onChange={getHandler(handleChangeStorageMinus)}
                    error={clientDepoStorageError}
                    errorText={clientDepoStorageTextError}
                    unique
                  >
                    {clientDepoStorage && clientDepoStorage?.map((item) => (
                      <MenuItem
                        key={item?.id}
                        value={item?.name}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <div className={classnames(
                    classes.amountInfo, classes.amountWrapper,
                    classes.amountWrapper__SecuritiesTransferAvailable,
                  )}
                  >
                    <p className={classes.amountInfo__text}>
                      {`Доступно на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
                    </p>
                    <p className={classes.amountInfo__value}>
                      {((clientBalances && clientBalances.balanceT0 >= 0) && `${clientBalances.balanceT0} шт.`) || ('...')}
                    </p>
                  </div>
                </Grid>
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px`}
                  render={() => (
                    <Grid
                      item
                      xs={12}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                      >
                        <ArrowBottomFull />
                      </Box>
                    </Grid>
                  )}
                />
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomInputSelect
                    label="Портфель (зачисления)"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={portfolioPlus || ''}
                    options={
                      addEmptyValueToArray(portfolios?.map((option) => option.name))
                    }
                    error={selectError || partnerPortfolioError}
                    errorText={partnerPortfolioTextError}
                    onChange={getHandler(handleChangePortfolioPlus)}
                    disabled={disabled}
                    unique
                  />
                </Grid>
                <Grid
                  className={classes.depoPanelWrapper}
                  item
                  lg={8}
                  sm={6}
                  xs={12}
                >
                  <CustomSelect
                    error={selectError || partnerDepoStorageError}
                    errorText={partnerDepoStorageTextError}
                    label="Место хранения (зачисления)"
                    value={storagePlus || ''}
                    onChange={getHandler(handleChangeStoragePlus)}
                    unique
                    disabled={!portfolioPlus}
                  >
                    {partnerDepoStorage && partnerDepoStorage?.map((item) => (
                      <MenuItem
                        key={item?.id}
                        value={item?.name}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  <div className={classnames(classes.amountInfo,
                    classes.amountWrapper,
                    classes.amountWrapper__SecuritiesTransferBalance)}
                  >
                    <p className={classes.amountInfo__text}>
                      {`Баланс на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
                    </p>
                    <p className={classes.amountInfo__value}>
                      {((partnerBalances && partnerBalances.balanceT0 >= 0) && `${partnerBalances.balanceT0} шт.`) || ('...')}
                    </p>
                  </div>
                </Grid>
                <Grid
                  className={classes.portalBtnWrapper}
                  item
                  xs={12}
                >
                  <button
                    className={classes.portalBtn}
                    type="button"
                    onClick={toggleGroupOneVisibility}
                    disabled={disabled}
                  >
                    <span className={classes.portalBtnLabel}>
                      Ценная бумага
                    </span>
                    <DocPlusIcon fill={groupOneVisibility ? '#FBC15E' : '#91B1BF'} />
                  </button>
                </Grid>
                <Grid
                  className={classes.uniqueGridItem}
                  item
                  lg={8}
                  sm={6}
                  xs={12}
                >
                  <CustomAutocomplete
                    variant="outlined"
                    label="Эмитент (для поиска укажите наименование или ISIN или № гос. рег.)"
                    name="issuer"
                    color="secondary"
                    size="small"
                    error={securityNameError}
                    errorText={securityNameTextError}
                    inputValue={searchStr || ''}
                    onInputChange={handleChangeAutocompleteInput}
                    onChange={handleChangeAutocomplete}
                    searchList={getRespSecurities && getRespSecurities?.securities}
                    disabled={disabled}
                    unique
                  />
                </Grid>
                <Grid
                  className={classes.uniqueGridItem}
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomInput
                    variant="outlined"
                    label="Количество"
                    name="amount"
                    color="secondary"
                    size="small"
                    error={volumeError}
                    errorText={volumeTextError}
                    value={amount.value}
                    onChange={(e) => {
                      amount.onChange(e)
                      setVolumeError(false)
                    }}
                    disabled={disabled}
                    unique
                  />
                </Grid>
                {groupOneVisibility && (
                  <>
                    <Media
                      query="(min-width: 577px)"
                      render={() => (
                        <Grid
                          item
                          xs={12}
                        >
                          <TableContainer
                            className={classes.issuerTable__wrapper}
                          >
                            <Table
                              className={classes.issuerTable}
                              aria-label="issuer-info"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <CustomSelect
                                      label="Вид"
                                      name="type"
                                      value={input?.type?.name || ''}
                                      onChange={getHandler(handleChangeView)}
                                      error={securityTypeError}
                                      errorText={securityTypeTextError}
                                      unique
                                    >
                                      {securityTypes && securityTypes.map((item) => {
                                        const { name } = item

                                        return (
                                          <MenuItem
                                            key={item.uid}
                                            value={name}
                                            item={item}
                                          >
                                            {name}
                                          </MenuItem>
                                        )
                                      })}
                                    </CustomSelect>
                                  </TableCell>
                                  <TableCell>
                                    <CustomInput
                                      label="Номер гос. регистрации"
                                      variant="outlined"
                                      name="registerNumber"
                                      color="secondary"
                                      size="small"
                                      error={securityRegisterNumberError}
                                      errorText={securityRegisterNumberTextError}
                                      value={input?.registerNumber || ''}
                                      onChange={handleChangeInput}
                                      disabled={disabled}
                                      unique
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <CustomInput
                                      label="ISIN"
                                      variant="outlined"
                                      name="isin"
                                      color="secondary"
                                      size="small"
                                      error={securityIsinError}
                                      errorText={securityIsinTextError}
                                      value={input?.isin || ''}
                                      onChange={handleChangeInput}
                                      disabled={disabled}
                                      unique
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      )}
                    />
                    <Media
                      query={`(max-width: ${breakpoints.phone_large}px)`}
                      render={() => (
                        <>
                          <Grid
                            item
                            xs={12}
                          >
                            <CustomSelect
                              label="Вид"
                              value={input?.type?.name || ''}
                              error={securityTypeError}
                              errorText={securityTypeTextError}
                              onChange={getHandler(handleChangeView)}
                              unique
                            >
                              {securityTypes && securityTypes.map((item) => {
                                const { name } = item

                                return (
                                  <MenuItem
                                    key={name}
                                    value={name}
                                  >
                                    {name}
                                  </MenuItem>
                                )
                              })}
                            </CustomSelect>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <CustomInput
                              label="Номер гос. регистрации"
                              variant="outlined"
                              name="registerNumber"
                              color="secondary"
                              size="small"
                              error={securityRegisterNumberError}
                              errorText={securityRegisterNumberTextError}
                              value={input?.registerNumber || ''}
                              onChange={handleChangeInput}
                              disabled={disabled}
                              unique
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <CustomInput
                              label="ISIN"
                              variant="outlined"
                              name="isin"
                              color="secondary"
                              size="small"
                              error={securityIsinError}
                              errorText={securityIsinTextError}
                              value={input?.isin || ''}
                              onChange={handleChangeInput}
                              disabled={disabled}
                              unique
                            />
                          </Grid>
                        </>
                      )}
                    />
                  </>
                )}
                {groupTwoVisibility && (
                  <>
                    <Media
                      query="(min-width: 577px)"
                      render={() => (
                        <Grid
                          item
                          xs={12}
                        >
                          <TableContainer>
                            <Table
                              className={classes.issuerTable}
                              aria-label="issuer-info"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Вид</TableCell>
                                  <TableCell>Номер гос. регистрации</TableCell>
                                  <TableCell>ISIN</TableCell>
                                  <TableCell>Номинал</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    {issuer?.type?.name}
                                  </TableCell>
                                  <TableCell>
                                    {issuer?.registerNumber}
                                  </TableCell>
                                  <TableCell>
                                    {issuer?.isin}
                                  </TableCell>
                                  <TableCell>
                                    {issuer?.faceValue}
                                    {' '}
                                    {issuer?.faceValueCurrency?.uid}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      )}
                    />
                    <Media
                      query={`(max-width: ${breakpoints.phone_large}px)`}
                      render={() => (
                        <Grid
                          item
                          xs={12}
                        >
                          <div className={classes.list}>
                            <div className={classes.list__item}>
                              <p className={classes.list__title}>
                                Вид
                              </p>
                              <p className={classes.list__text}>
                                {issuer?.type?.name}
                              </p>
                            </div>
                            <div className={classes.list__item}>
                              <p className={classes.list__title}>
                                Номер гос. регистрации
                              </p>
                              <p className={classes.list__text}>
                                {issuer?.registerNumber}
                              </p>
                            </div>
                            <div className={classes.list__item}>
                              <p className={classes.list__title}>
                                ISIN
                              </p>
                              <p className={classes.list__text}>
                                {issuer?.isin}
                              </p>
                            </div>
                          </div>
                        </Grid>
                      )}
                    />
                  </>
                )}

                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomDatepicker
                    label="Срок действия"
                    value={validity}
                    error={expirationDateError}
                    errorText={expirationDateTextError}
                    minDate={expirationDate || new Date()}
                    maxDate={disabled && !canModifyDate ? validity : undefined}
                    onChange={handleChangeValidity}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                />
                {(getSaveTransferSecurity?.requiredAction?.type === 'code' || getPerformTransferSecurity?.requiredAction?.type === 'code' || getSaveTransferSecurity?.requiredAction?.type === 'mac' || getPerformTransferSecurity?.requiredAction?.type === 'mac') && (
                  <>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        error={codeError}
                        errorText={codeTextError}
                        variant="outlined"
                        label="Введите код из сообщения"
                        name="code"
                        value={code}
                        onChange={(event) => handleChangeCodeInput(event)}
                        size="small"
                        color="secondary"
                        unique
                        className={classes.inputField}
                        autoComplete="off"
                        helperText={(getPerformTransferSecurity?.requiredAction?.type === 'mac' || getSaveTransferSecurity?.requiredAction?.type === 'mac') ? hashSha256 : ''}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={8}
                      sm={6}
                      xs={12}
                      className={classes.Transfer__codeWrapper}
                    >
                      {(showTimer && (
                        <Typography
                          className={classes.Transfer__codeText}
                          m={0}
                        >
                          Если код не пришел, Вы можете запросить новый код через
                          <span
                            className={classes.Transfer__codeInput}
                          >
                            <Timer
                              seconds={30}
                              handleStartTimer={handleStartTimer}
                            />
                          </span>
                        </Typography>
                      )) || (!showTimer && (
                        <CustomButton
                          label="Выслать новый код"
                          modifier="primary"
                          type="submit"
                          fullWidth
                          onClick={handleResetTimer}
                        />
                      ))}
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xs={12}
                  className={classes.TransferStatus}
                >
                  {order && (
                    <div className={(!!currentStatusDescription && classes.TransferStatus__wrap) || ''}>
                      <Typography
                        className={classes.TransferStatus__name}
                      >
                        Статус:
                        {' '}
                        {currentStatus?.name}
                      </Typography>
                      {currentStatusDescription && (
                        <Typography
                          className={classes.TransferStatus__description}
                          dangerouslySetInnerHTML={createMarkup()}
                        />
                      )}
                    </div>
                  )}
                </Grid>
                {(((currentActions && currentActions?.canModify) || !currentActions)
                  && (!getSaveTransferSecurity?.requiredAction?.type
                    && !getPerformTransferSecurity?.requiredAction?.type
                  ) && (
                    <Grid
                      item
                      lg={6}
                      xs={12}
                      className={classes.btnGroup}
                    >
                      <CustomIconButton
                        type="submit"
                        onClick={() => setSubmitAction('perform')}
                        disabled={formDisabled}
                      >
                        Исполнить
                      </CustomIconButton>
                      <CustomIconButton
                        type="submit"
                        onClick={() => setSubmitAction('save')}
                        disabled={formDisabled}
                      >
                        Сохранить
                      </CustomIconButton>
                      <Media
                        query="(min-width: 577px)"
                        render={() => (
                          <CustomIconButton
                            type="button"
                            onClick={handleBackToHistory}
                          >
                            Назад
                          </CustomIconButton>
                        )}
                      />
                    </Grid>
                )) || ((getSaveTransferSecurity?.requiredAction?.type === 'sign' || getPerformTransferSecurity?.requiredAction?.type === 'sign') && (
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    type="submit"
                    onClick={() => setSubmitAction('perform')}
                  >
                    Подписать
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    icon={<DownloadIcon />}
                    onClick={handleDownloadXml}
                  >
                    Скачать .xml
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    onClick={handleCancelOperation}
                  >
                    Назад
                  </CustomIconButton>
                </Grid>
                )) || ((getSaveTransferSecurity?.requiredAction?.type === 'code' || getPerformTransferSecurity?.requiredAction?.type === 'code' || getSaveTransferSecurity?.requiredAction?.type === 'mac' || getPerformTransferSecurity?.requiredAction?.type === 'mac') && (
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    type="submit"
                    onClick={() => setSubmitAction('perform')}
                  >
                    Подтвердить
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    onClick={handleCancelOperation}
                  >
                    Отменить
                  </CustomIconButton>
                </Grid>
                )) || ((currentActions && currentActions?.canCancel) && (
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    onClick={() => setCancellationModalOpen(true)}
                  >
                    Отменить поручение
                  </CustomIconButton>
                  <Media
                    query="(min-width: 577px)"
                    render={() => (
                      <CustomIconButton
                        type="button"
                        onClick={handleBackToHistory}
                      >
                        Назад
                      </CustomIconButton>
                    )}
                  />
                </Grid>
                ))
                  || ((currentActions && !currentActions?.canModify && !currentActions?.canCancel)
                    && (
                      <Media
                        query="(min-width: 577px)"
                        render={() => (
                          <Grid
                            item
                            lg={6}
                            xs={12}
                            className={classes.btnGroup}
                          >
                            <CustomIconButton
                              type="button"
                              onClick={handleBackToHistory}
                            >
                              Назад
                            </CustomIconButton>
                          </Grid>
                        )}
                      />
                    ))}
              </Grid>
            </form>
          </Box>
        )}
        <CustomModal
          open={saveModalOpen}
          title="Поручение успешно создано."
          handleClose={handleCloseSaveModal}
          actionText="Хотите создать новое?"
          actionReject={handleActionReject}
          actionSuccess={handleActionSuccess}
          disableOverlay
        />
        <CustomModal
          open={updModalOpen}
          title="Поручение обновлено"
          handleClose={handleCloseUpdModal}
        />
        <CustomModal
          open={performModalOpen}
          title="Поручение успешно подписано"
          handleClose={handleClosePerformModal}
          actionText="Хотите создать новое?"
          actionReject={handleActionReject}
          actionSuccess={handleActionSuccess}
          disableOverlay
        />
        <CustomModal
          open={cancellationModalOpen}
          title="Создать поручение на отмену"
          handleClose={handleCloseCancellationModal}
          callbacks={{
            withOk: handleCancelOk,
            withCancel: () => {
              handleCloseCancellationModal()
            },
          }}
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}

import { useEffect, useState } from 'react'

const useValidation = (value, validations) => {
  const [maxLength, setMaxLength] = useState(false)
  const [isOnlyNumbers, setIsOnlyNumbers] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const validation in validations) {
      // eslint-disable-next-line default-case
      switch (validation) {
        case 'maxLength':
          // eslint-disable-next-line no-unused-expressions
          value?.length > validations[validation] ? setMaxLength(true) : setMaxLength(false)
          break
        case 'isOnlyNumbers':
          // eslint-disable-next-line no-case-declarations
          const re = /^-?\d*\.?\d*$/
          // eslint-disable-next-line no-unused-expressions
          setIsOnlyNumbers(re.test(String(value).toLowerCase()))
          break
      }
    }
  }, [value])

  return {
    maxLength,
    isOnlyNumbers,
  }
}

export default useValidation

import React from 'react'
import TabsComponent from '../../components/Tabs/Tabs'
import { ReactComponent as CartIcon } from '../../assets/images/cart.svg'
import { ReactComponent as SettingsIcon } from '../../assets/images/settings.svg'
import { ReactComponent as SettingIcon } from '../../assets/images/setting.svg'
import CountPanel from '../../components/PortfolioPanels/Count'
import ClassTools from '../../components/PortfolioPanels/ClassTools'
import Tools from '../../components/PortfolioPanels/Tools'

const tabLinks = [
  {
    key: 'eb1a5cff-7bd5-414d-a168-d76e7e7a470b',
    link: 'count',
    exact: true,
    label: 'СЧЕТА',
    icon: <CartIcon />,
    component: CountPanel,
  },
  {
    key: '9f14e52f-acd9-4751-85c6-9b6f2fda0d1b',
    link: 'class_tools',
    exact: true,
    label: 'КЛАССЫ ИНСТРУМЕНТОВ',
    icon: <SettingsIcon />,
    component: ClassTools,
  },
  {
    key: 'fdf7c195-06d1-4617-9a22-7c3181c78678',
    link: 'tools',
    exact: true,
    label: 'ИНСТРУМЕНТЫ',
    icon: <SettingIcon />,
    component: Tools,
  },
]

export default function Portfolio() {
  return (
    <>
      <TabsComponent
        tabLinks={tabLinks}
        wishStyle={{ justifyContent: 'space-between' }}
        hideTabsOnUp={577}
      />
    </>
  )
}

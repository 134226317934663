import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import Media from 'react-media'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import { isValid } from 'date-fns'
import CollapseBar from '../../CollapseBar/CollapseBar'
import UserInfos from '../otherContents/userInfos'
import IdentificationContent from '../collapseContents/identificationContent'
import PostAddressContent from '../collapseContents/postAddressContent'
import ContactInfosContent from '../collapseContents/contactInfosContent'
import AddressContent from '../collapseContents/addressContent'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'
import { nextBar, expandedToggle } from '../../../utils/MoveBetweenBars'
import { isEmpty } from '../../../utils/Validate'

const useStyles = makeStyles(() => ({
  boxWrapper: {
    paddingTop: 33,
    '@media(max-width: 576px)': {
      paddingTop: 14,
    },
  },
  Phone__header: {
    color: '#91B1BF',
  },

  Phone__text: {
    fontWeight: 600,
  },

  Phone__hideBlock: {
    display: 'none',
    marginBottom: 0,
  },
  Phone__expandedBar: {
    '& .MuiAccordionSummary-root': {
      marginLeft: -20,
      paddingLeft: 20,
      marginRight: -20,
      paddingRight: 20,

      '&.Mui-expanded': {
        marginBottom: 15,
      },
      '& .MuiAccordionSummary-expandIcon': {
        display: 'none',
      },
      '& .Mui-expanded > .MuiTypography-root': {
        color: '#fff',
      },
    },
  },
  Phone__fullName: {
    fontWeight: 600,
    paddingBottom: 15,
  },
}))

export default function PanelContentComponent({ data }) {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()

  const hasGeneralInfo = !!isValid(new Date(data?.birthDate))
  || !!data?.birthPlace
  || !!data?.inn
  || !!data?.tin
  || !!data?.snils

  const openedBasicInfo = !!data?.nationality?.nameRu
  || !!data?.isController
  || !!data?.fullName
  || hasGeneralInfo
  || !!data?.isPublicOfficial

  const openedContacts = !!data?.phones?.length
    || !!data?.emails?.length
    || !!data?.faxes?.length
    || !!data?.otherContact

  const openedInfo = hasGeneralInfo
    || !!data?.nationality
    || !!data?.otherNationalities
    || !!data?.taxCountry

  const openedAddress = data?.address && Object.values(data?.address).some(
    (item) => !isEmpty(item),
  )

  const openedMailAddress = data?.postAddress && Object.values(data?.postAddress).some(
    (item) => !isEmpty(item),
  )

  const mapBarBeneficiar = [
    ['generalInfo', 'generalInfo', openedBasicInfo],
    ['openedInfo', 'identityCard', openedInfo],
    ['openedAddress', 'address', openedAddress],
    ['openedMailAddress', 'postAddress', openedMailAddress],
    ['openedContacts', 'contactInfo', openedContacts],
  ]

  const haveNextBar = (currentBar, needBool) => nextBar(
    mapBarBeneficiar, currentBar, needBool,
  )

  const handleChange = (newPanel, needNextPanel = false) => (event, newExpanded) => {
    expandedToggle({
      expanded, setExpanded, newExpanded, newPanel, needNextPanel, mapBar: mapBarBeneficiar,
    })
  }

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    // eslint-disable-next-line
  }, [])

  const handleRollUpBar = () => setExpanded(false)

  return (
    <Box
      className={classes.boxWrapper}
    >
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <UserInfos data={data} />
            <CollapseBar
              defaultExpanded={!!openedInfo}
              label="Удостоверение личности"
            >
              <IdentificationContent data={data} />
            </CollapseBar>
            <CollapseBar
              defaultExpanded={!!openedAddress}
              label="Адрес регистрации"
            >
              <AddressContent data={data} />
            </CollapseBar>
            <CollapseBar
              defaultExpanded={!!openedMailAddress}
              label="Почтовый адрес"
            >
              <PostAddressContent data={data} />
            </CollapseBar>
            <CollapseBar
              defaultExpanded={!!openedContacts}
              label="Контактная информация"
            >
              <ContactInfosContent data={data} />
            </CollapseBar>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            <div
              className={classes.Phone__fullName}
              onClick={handleRollUpBar}
              aria-hidden="true"
            >
              {data?.fullName}
            </div>
            {openedBasicInfo && (
            <CollapseBar
              expanded={expanded === 'generalInfo'}
              barColor={expanded ? '#91B1BF' : '#dfe8ed'}
              label="Основная информация"
              onChange={handleChange('generalInfo')}
              className={classnames(((!!expanded && expanded !== 'generalInfo') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
            >
              <UserInfos
                data={data}
                handleClickNextPanel={handleChange('generalInfo', true)}
                isNeedNextButton={haveNextBar('generalInfo', true)}
              />
            </CollapseBar>
            )}
            {openedInfo && (
              <CollapseBar
                expanded={expanded === 'identityCard'}
                barColor={expanded ? '#91B1BF' : '#dfe8ed'}
                label="Удостоверение личности"
                onChange={handleChange('identityCard')}
                className={classnames(((!!expanded && expanded !== 'identityCard') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
              >
                <IdentificationContent
                  data={data}
                  handleClickNextPanel={handleChange('openedInfo', true)}
                  isNeedNextButton={haveNextBar('openedInfo', true)}
                />
              </CollapseBar>
            )}
            {openedAddress && (
              <CollapseBar
                expanded={expanded === 'address'}
                barColor={expanded ? '#91B1BF' : '#dfe8ed'}
                label="Адрес регистрации"
                onChange={handleChange('address')}
                className={classnames(((!!expanded && expanded !== 'address') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
              >
                <AddressContent
                  data={data}
                  handleClickNextPanel={handleChange('openedAddress', true)}
                  isNeedNextButton={haveNextBar('openedAddress', true)}
                />
              </CollapseBar>
            )}
            {openedMailAddress && (
              <CollapseBar
                expanded={expanded === 'postAddress'}
                barColor={expanded ? '#91B1BF' : '#dfe8ed'}
                label="Почтовый адрес"
                onChange={handleChange('postAddress')}
                className={classnames(((!!expanded && expanded !== 'postAddress') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
              >
                <PostAddressContent
                  data={data}
                  handleClickNextPanel={handleChange('openedMailAddress', true)}
                  isNeedNextButton={haveNextBar('openedMailAddress', true)}
                />
              </CollapseBar>
            )}
            {openedContacts && (
              <CollapseBar
                expanded={expanded === 'contactInfo'}
                barColor={expanded ? '#91B1BF' : '#dfe8ed'}
                label="Контактная информация"
                onChange={handleChange('contactInfo')}
                className={classnames(((!!expanded && expanded !== 'contactInfo') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
              >
                <ContactInfosContent
                  data={data}
                />
              </CollapseBar>
            )}
          </>
        )}
      />
    </Box>
  )
}

PanelContentComponent.defaultProps = {
  data: PropTypes.object,
}

PanelContentComponent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
}

import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import Login from '../pages/Login'
import Recovery from '../pages/Recovery'
import ResetPassword from '../ui/components/pages/PasswordReset'
import Logout from '../pages/Logout'
import Common from '../pages/Common'
import { PortfolioNew } from '../ui/components/pages/Portfolio'
import Portfolio from '../pages/Portfolio'
import { Contacts as ContactsNew } from '../ui/components/pages/Contacts'
import Contacts from '../pages/Contacts'
import { TransfersNew } from '../ui/components/pages/Transfers'
import Transfers from '../pages/Transfers'
import Trades from '../pages/Trades'
import Documents from '../pages/Documents'
import Reports from '../pages/Reports'
import Message from '../pages/Message'
import MessageNew from '../ui/components/pages/Message'
import Profile from '../pages/Profile'
import ProfileNew from '../ui/components/pages/Profile'
import Scaner from '../pages/Scaner'
import ReportsSign from '../pages/ReportsSign'
import MainLayout from '../components/MainLayout/MainLayout'
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'
import PrivateAuthRoute from '../components/PrivateRoute/PrivateAuthRoute'
import WithSocket from '../hoc/socket/socket'
import { disableFetching } from '../redux/user/userSlice'
import useLogoutOverTime from '../hooks/useLogoutOverTime'
import { ROUTES } from './data'
import { ReportsNew } from '../ui/components/pages/Reports'
import { SignNew } from '../ui/components/pages/Sign'
import { DocumentsNew } from '../ui/components/pages/Documents'
import { selectLastIdNotifications } from '../redux/notifications/selector'
import {useNotificationsHandle} from "../hooks/useNotificationsHandle";
// import { fetchNotificationsStart } from '../redux/notifications/notificationsSlice'

function App({ currentUser, disableFetchingStart }) {
  // const dispatch = useDispatch()
  //
  // const lastIdNotifications = useSelector(selectLastIdNotifications)

  useEffect(() => {
    disableFetchingStart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   const interval = setInterval(dispatch(fetchNotificationsStart(lastIdNotifications)), 5000)
  //   return () => clearInterval(interval)
  // }, [dispatch(fetchNotificationsStart(lastIdNotifications)), lastIdNotifications])
  useNotificationsHandle()
  const tradeToggler = process.env.REACT_APP_TRADE_TOGGLER || 'show'

  useLogoutOverTime(900)

  return (
    <Switch>
      <PrivateAuthRoute
        exact
        path="/login"
        component={() => <Login />}
      />
      <PrivateAuthRoute
        exact
        path="/recovery"
        component={Recovery}
      />
      {!currentUser && (
        <Route
          exact
          path="/Account/PasswordReset/:id"
          component={ResetPassword}
        />
      )}
      <PrivateRoute
        exact
        path="/logout"
        component={Logout}
      />
      <Route
        exact
        path="/"
      >
        {currentUser ? <Redirect to="/portfolio" /> : <Redirect to="/login" />}
      </Route>
      {/* <WithSocket> */}
      {/*  <Switch> */}
      <PrivateRoute
        path={ROUTES.portfolio}
        component={PortfolioNew}
      />
      <PrivateRoute
        path={ROUTES.transfers}
        component={TransfersNew}
      />
      <PrivateRoute
        path={ROUTES.documents}
        component={DocumentsNew}
      />
      <PrivateRoute
        path={ROUTES.reports}
        component={ReportsNew}
      />
      <PrivateRoute
        path={ROUTES.sign}
        component={SignNew}
      />
      <PrivateRoute
        path={ROUTES.contacts}
        component={ContactsNew}
      />
      <PrivateRoute
        path={[ROUTES.feedbackNew, ROUTES.feedbackHistory, ROUTES.feedback]}
        component={MessageNew}
      />
      <PrivateRoute
        path={ROUTES.profile}
        component={ProfileNew}
      />
      <PrivateRoute
        path={ROUTES.scaner}
        component={Scaner}
      />
      <MainLayout>
        <PrivateRoute
          path="/"
          component={Common}
        />
        <PrivateRoute
          path="/documents-old"
          component={Documents}
        />
        <PrivateRoute
          path="/portfolio-old"
          component={Portfolio}
        />
        <PrivateRoute
          path="/transfers-old"
          component={Transfers}
        />
        {tradeToggler === 'show' && (
        <PrivateRoute
          path="/trades"
          component={Trades}
        />
        )}
        <PrivateRoute
          path="/reports-old"
          component={Reports}
        />
        <PrivateRoute
          path="/scaner-old"
          component={Scaner}
        />
        <PrivateRoute
          exact
          path="/contacts-old"
          component={Contacts}
        />
        <PrivateRoute
          path="/profile-old"
          component={Profile}
        />
        <PrivateRoute
          path="/feedback-old"
          component={Message}
        />
        <PrivateRoute
          path="/sign-old"
          component={ReportsSign}
        />
      </MainLayout>
      {/* </Switch> */}
      {/* </WithSocket> */}
    </Switch>
  )
}

App.defaultProps = {
  // TODO: refactor
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: PropTypes.object,
  disableFetchingStart: PropTypes.func,
}

App.propTypes = {
  currentUser: PropTypes.oneOfType([PropTypes.object]),
  disableFetchingStart: PropTypes.func,
}

const mapStateToProps = ({ user: { currentUser } }) => ({
  currentUser,
})

const mapDispatchToProps = (dispatch) => ({
  disableFetchingStart: () => dispatch(disableFetching()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Media from 'react-media'
import useStyles from './sharedStyles'
import CustomInput from '../../CustomControls/CustomInput'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

const ContactInfosContent = ({ data, handleClickNextPanel, isNeedNextButton }) => {
  const classes = useStyles()

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Grid
              container
              className={classes.row}
              justify="space-between"
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <CustomInput
                  className={classes.mediumLabelField}
                  name="phone"
                  label="Номера телефонов (через запятую, не более 5ти)"
                  value={data?.phones?.join(', ') || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <CustomInput
                  className={classes.mediumLabelField}
                  name="contactEmail"
                  label="Эл. почта (через запятую, не более 5ти)"
                  value={data?.emails?.join(', ') || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <CustomInput
                  className={classes.mediumLabelField}
                  name="fax"
                  label="Номера факсов (через запятую, не более 5ти)"
                  value={data?.faxes?.join(', ') || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <CustomInput
                  className={classes.mediumLabelField}
                  multiline
                  rows={2}
                  name="others"
                  label="Иная контактная информация"
                  value={data?.otherContact || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
            </Grid>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <div className={classes.Mobile}>
            {data?.phones?.length && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Номера телефонов (через запятую, не более 5ти)
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.phones?.join(', ')}
                </div>
              </div>
            )}
            {data?.emails?.length && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Эл. почта (через запятую, не более 5ти)
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.emails?.join(', ')}
                </div>
              </div>
            )}
            {data?.faxes?.length && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Номера факсов (через запятую, не более 5ти)
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.faxes?.join(', ')}
                </div>
              </div>
            )}
            {data?.otherContact && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Иная контактная информация
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.otherContact}
                </div>
              </div>
            )}
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </div>
        )}
      />
    </>
  )
}

ContactInfosContent.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

ContactInfosContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

export default ContactInfosContent

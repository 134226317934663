import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../../theme'
import {
  StyledLoginLeft,
  StyledLoginRight,
  StyledLoginWrapper,
} from '../../../../pages/Login/styled'
import svgLogo from '../../../../pages/Login/assets/loginLogo.svg'
import { StyledInfoBlock, StyledLoginContainer } from '../../../../components/loginRedesign/styled'
import { Text } from '../../atoms'
import Reset from './Reset';

const ResetPassword = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledLoginWrapper>
        <StyledLoginLeft>
          <img src={svgLogo} alt="Регион" />
        </StyledLoginLeft>
        <StyledLoginRight>
          <StyledLoginContainer>
            <Reset />
          </StyledLoginContainer>

          <StyledInfoBlock>
            <a href="tel:+74957772964">
              <Text variant="body3" color="secondary">
                +7 495 777 29 64
              </Text>
            </a>
            <a href="mailto:client@region.ru">
              <Text variant="body3" color="secondary">
                client@region.ru
              </Text>
            </a>
          </StyledInfoBlock>
        </StyledLoginRight>
      </StyledLoginWrapper>
    </ThemeProvider>
  )
}

export default ResetPassword

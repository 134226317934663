import { useSizeContext } from 'context/SizeContext'
import React, { useContext, useState } from 'react'
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { Tab, Tabs } from 'ui/components'
import { useTheme } from 'ui/theme'
import { Input } from './Input'
import { Output } from './Output'
import { History } from './History'
import { StyledWrapper } from './styled'
import { Transfer } from './Transfer'
import { StyledContent, StyledTabsWrapper } from '../../styled'

export const Securities: React.FC = () => {
  const { url, path } = useRouteMatch()
  const location = useLocation()
  const size = useSizeContext()
  const theme = useTheme()
  const isMobile = theme.bp.isXS(size.width)

  const { pathname } = location
  const splitPathname = pathname.split('/')
  const isPathTransfer = splitPathname[3] === 'transfer'
  const isPathInput = splitPathname[3] === 'input'
  const isPathOutput = splitPathname[3] === 'output'
  const isPathLog = splitPathname[3] === 'log'

  return (
    <StyledWrapper>
      <StyledTabsWrapper>
        <Tabs variant={2}>
          <Tab
            label="Перевод"
            active={isPathTransfer}
            to={`${url}/transfer/new`}
            component={NavLink}
          />
          <Tab
            label="Зачисление"
            active={isPathInput}
            to={`${url}/input/new`}
            component={NavLink}
          />
          <Tab label="Вывод" active={isPathOutput} to={`${url}/output/new`} component={NavLink} />
          <Tab
            label={isMobile ? 'История' : 'История поручений'}
            active={isPathLog}
            to={`${url}/log`}
            component={NavLink}
          />
        </Tabs>
      </StyledTabsWrapper>
      <StyledContent>
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/transfer/new`} />
          </Route>
          <Route exact path={`${path}/transfer/:id`} component={Transfer} />
          <Route exact path={`${path}/input/:id`} component={Input} />
          <Route exact path={`${path}/output/:id`} component={Output} />
          <Route exact path={`${path}/log`} component={History} />
        </Switch>
      </StyledContent>
    </StyledWrapper>
  )
}

import styled from 'styled-components'
import { Badge } from '@material-ui/core'
import { BadgeProps } from '@material-ui/core/Badge/Badge'
import { BadgeCounterPos } from './types'

export const StyledBadgeCounter = styled(Badge)<BadgeCounterPos & BadgeProps>`
  & .MuiBadge-badge {
    ${({ theme }) => theme.font.body1};
    color: ${({ theme }) => theme.color.text.accent};
    background-color: ${({ theme }) => theme.color.bg.secondary};
    border: 1px solid ${({ theme }) => theme.color.button.primary};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    padding: 2px 8px;
    min-height: 24px;

    ${({ badgeContent, $xPos, $yPos }) =>
      badgeContent
        ? `
        &.MuiBadge-anchorOriginTopRightRectangle {
        transform: scale(1) translate(${$xPos || 50}%, ${$yPos || -50}%);
      }
      `
        : ''}
  },

`

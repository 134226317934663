import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  boxWrapper: {
    paddingTop: 50,
    '@media (max-width: 576px)': {
      paddingTop: 10,
    },
  },
  form: {
    '& .MuiGrid-container': {
      marginTop: -10,
      marginRight: -25,
      marginBottom: 10,
      marginLeft: -25,
      '@media (max-width: 576px)': {
        margin: '-5px -16px',
      },
    },
    '& .MuiGrid-item': {
      paddingTop: 10,
      paddingRight: 25,
      paddingBottom: 10,
      paddingLeft: 25,
      '@media (max-width: 576px)': {
        padding: '5px 16px',
      },
    },
  },
  formControl: {
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#91B1BF',
    },
  },
  FilterForm__inputs: {
    '& .MuiGrid-item': {
      paddingLeft: 13,
      paddingRight: 13,
    },
  },
  fieldsetLegend: {
    marginBottom: 9,
    fontSize: 15,
    color: theme.palette.type === 'light' ? '#292D3499 !important' : '#ffffff99 !important',
  },
  radioGroup: {
    flexDirection: 'row',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 18,
    },
    '& .MuiButtonBase-root': {
      marginRight: 5,
      padding: 0,
      '&.Mui-checked': {
        color: '#91B1BF',
        '& svg': {
          '&:nth-child(2)': {
            opacity: 1,
          },
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& svg': {
      '&:first-child': {
        width: 16,
        height: 16,
        borderRadius: '50%',
        border: '1px solid #91B1BF',
        fill: 'transparent',
      },
      '&:nth-child(2)': {
        top: 4,
        left: 4,
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: '#91B1BF',
        fill: 'transparent',
        opacity: 0,
        transform: 'scale(1)',
        transition: 'opacity 0.3s ease-in-out !important',
      },
    },
    '& .MuiTypography-root': {
      fontSize: 16,
    },
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
    '@media(max-width: 576px)': {
      left: 46,
      width: 'calc(100% - 46px)',
      bottom: 0,
      padding: '0 20px',
      zIndex: 1,
      position: 'fixed',
      boxSizing: 'border-box',
      minHeight: 48,
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    },
  },
  btnGroupUnique: {
    '& > button': {
      '&:not(:last-child)': {
        marginRight: 44,
      },
    },
  },
  btnGroup_top: {
    marginTop: 24,
  },
  infoTextWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
  },
  tableWrapper: {
    paddingTop: 21,
    '@media (max-width: 576px)': {
      paddingTop: 0,
    },
    '& > .MuiTableContainer-root': {
      overflowX: 'visible',
    },
  },
  tableRow_EpsNBr: {
    '& > .MuiTableCell-root:nth-child(1)': {
      width: '10.49%',
    },
    '& > .MuiTableCell-root:nth-child(2)': {
      width: '12.42%',
    },
    '& > .MuiTableCell-root:nth-child(3)': {
      width: '20.98%',
    },
    '& > .MuiTableCell-root:nth-child(4)': {
      width: '22.33%',
    },
    '& > .MuiTableCell-root:nth-child(5)': {
      width: '13.28%',
    },
    '& > .MuiTableCell-root:nth-child(6)': {
      width: '6.64%',
    },
    '& > .MuiTableCell-root:nth-child(7)': {
      width: '9.24%',
    },
    '& > .MuiTableCell-root:nth-child(8)': {
      width: '4.62%',
    },
  },
  tableRow_Corp: {
    '& > .MuiTableCell-root:nth-child(1)': {
      width: '11.26%',
    },
    '& > .MuiTableCell-root:nth-child(2)': {
      width: '11.26%',
    },
    '& > .MuiTableCell-root:nth-child(3)': {
      width: '13.76%',
    },
    '& > .MuiTableCell-root:nth-child(4)': {
      width: '20.98%',
    },
    '& > .MuiTableCell-root:nth-child(5)': {
      width: '22.81%',
    },
    '& > .MuiTableCell-root:nth-child(6)': {
      width: '11.26%',
    },
    '& > .MuiTableCell-root:nth-child(7)': {
      width: '8.66%',
    },
  },
  tableRow: {
    position: 'relative',
    borderRight: '6px solid transparent',
    cursor: 'pointer',
    '&.MuiTableRow-root:not(.MuiTableRow-head)': {
      '&:hover': {
        borderRightColor: '#FBC15E',
        backgroundColor: '#91B1BF1A',
      },
    },
    '&.MuiTableRow-head': {
      cursor: 'initial',
    },
    '&.MuiTableRow-head > .MuiTableCell-root': {
      fontSize: 14,
    },
    '& > .MuiTableCell-root': {
      padding: '16px 16px 15px',
      borderBottomColor: theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78',
      borderBottomStyle: 'dashed',
      fontSize: 16,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 2,
      },
      '& button': {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& svg': {
        fill: '#91B1BF',
      },
    },
  },
  tableCell__newMark: {
    padding: '0 !important',
    pointerEvents: 'none',
    borderBottom: 'inherit',
    right: 0,
  },
  tableCell__newMark__icon: {
    position: 'absolute',
    marginTop: '-8px',
    marginLeft: 15,
  },
  PhoneGrid__newMark__icon: {
    position: 'absolute',
    right: 15,
    marginTop: '-14px',
  },
  tableHeaderCell: {
    color: '#91B1BF',
  },
  tableBtn: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 'auto',
    margin: 0,
    padding: 0,
    border: '0',
    outline: 'none',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover svg': {
      fill: '#363E47',
    },
    '& svg': {
      marginRight: 12,
      fill: '#91B1BF',
      '@media (max-width: 576px)': {
        maxHeight: 16,
        marginRight: 8,
      },
    },
    '& p': {
      '@media (max-width: 576px)': {
        fontSize: 14,
        lineHeight: '19px',
      },
    },
  },
  nestedRow: {
    boxSizing: 'border-box',
    borderLeft: '12px solid #92B2C0',
    backgroundColor: 'rgba(145, 177, 191, 0.15)',
  },
  nestedRowInner: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '39px 16px 39px 3px',
  },
  textWithIcon: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      alignItems: 'flex-end',
      marginBottom: 20,
    },
    '& svg': {
      marginRight: 12,
      fill: '#91B1BF',
      '@media (max-width: 576px)': {
        width: 36,
        maxHeight: 23,
        height: '100%',
      },
    },
    '& span': {
      margin: 0,
      fontSize: 16,
      lineHeight: '21px',
      color: '#91B1BF',
      '@media (max-width: 576px)': {
        position: 'relative',
        top: 3,
        fontSize: 14,
        lineHeight: '19px',
      },
    },
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '& .MuiFormControl-root': {
      maxWidth: 198,
      marginRight: 34,
      marginBottom: 0,
      '@media (max-width: 576px)': {
        minWidth: 260,
      },
    },
  },
  formGroup_unique: {
    position: 'relative',
    top: -7,
    marginTop: -5,
    marginBottom: -6,
  },
  buttonsContainer: {
    display: 'flex',
    '& button': {
      '&:only-child': {
        position: 'relative',
        top: 3,
      },
    },
  },
  downloadLink: {
    position: 'absolute',
    width: 0,
    height: 0,
    opacity: 0,
    fontSize: 0,
    color: 'transparent',
  },
  PhoneGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '6px 9px',
    paddingTop: 6,
    paddingBottom: 9,
    borderBottom: '0.5px dashed #91B1BF',
    '&:first-child': {
      borderTop: '0.5px dashed #91B1BF',
    },
  },
  PhoneGrid__item: {},
  PhoneGrid__item_fullColumn: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  PhoneGrid__item_unique: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  PhoneGrid__header: {
    display: 'block',
    fontSize: 12,
    lineHeight: '16px',
    color: '#91B1BF',
  },
  PhoneGrid__value: {
    margin: 0,
    fontSize: 14,
    lineHeight: '19px',
    wordBreak: 'break-word',
  },
  PhoneGrid__value_medium: {
    fontWeight: 600,
  },
  PhoneGrid__collapse: {
    position: 'relative',
    left: -20,
    boxSizing: 'border-box',
    gridColumnStart: 1,
    gridColumnEnd: 3,
    width: 'calc(100% + 40px)',
    marginTop: -1,
    marginBottom: 5,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 17,
    borderBottom: '0.5px dashed #91B1BF',
    backgroundColor: '#fff',
  },
  PhoneGrid__collapse_filled: {
    backgroundColor: '#EFF3F5',
  },
  PhoneGrid__collapseInner: {},
  PhoneGrid__collapseButton: {
    position: 'relative',
    top: 1,
    right: -7,
    padding: 0,
    '& svg': {
      fill: '#91B1BF',
    },
  },
  PhoneGrid__codeInputWrapper: {
    marginBottom: 10,
    '& .MuiFormLabel-root': {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  PhoneGrid__btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      marginTop: 3,
    },
  },
}))

export default useStyles

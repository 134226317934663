import React from 'react'
import { StyledChip, StyledChips } from './styled';
import { IChipsProps } from './types';


export const Chips: React.FC<IChipsProps> = ({
   options = [],
   value = null,
    onChange = (v: any) => { },
    isMulti = false,
    ...props
  }) => {

  const handleChange = (option) => {
    if (isMulti) {
      if(option.value === null) return onChange([])

      let result = []
      if (value?.includes(option.value)) {
        result = value.filter(o => o !== option.value)
      } else {
        result = [...value, option.value];
      }
      if (options.every(o => result.find(option => option === o.value))) {
        result = [];
      }
      return onChange(result)
    }
    onChange(value === option.value ? null : option.value)
  }

  const getIsActive = (optionValue) => {
    if(!isMulti) return value === optionValue

    if(optionValue === null && !value?.length) return true

    return value?.includes(optionValue)
  }

  return <StyledChips {...props}>
    {/*<StyledChip*/}
    {/*  onClick={() => onChange(isMulti ? [] : null)}*/}
    {/*  active={isMulti ? !value?.length : !value}*/}
    {/*>*/}
    {/*  Все*/}
    {/*</StyledChip>*/}
    {
      options.map((option) => (
        <StyledChip
          onClick={() => !option.disabled && handleChange(option)}
          key={option.value}
          disabled={option.disabled}
          active={getIsActive(option.value)}
          {...option}
        >
          {option.label}
        </StyledChip>
      ))
    }
  </StyledChips>
}

export const initialState = {
  portfolio: {
    portfolios: [],
    currentPortfolio: '',
  },
  orderType: {
    orderTypes: [],
    currentOrderType: '',
  },
  status: {
    statuses: [],
    currentStatus: '',
  },
  date: {
    from: undefined,
    to: undefined,
  },
}

export const moneyCancelUid = 'Orders.Type.Modify.Cancellation'
export const moneyTransferUid = 'Orders.Type.NonTrade.Money.Transfer'
export const moneyOutputUid = 'Orders.Type.NonTrade.Money.Out'

export const statuses = [
  'Все',
  'Черновик',
  'Получено исполнителем',
  'На исполнении',
  'Отменено клиентом',
  'Отказ в исполнении',
  'Исполнено',
]

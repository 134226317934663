import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Media from 'react-media'
import CustomInput from '../../CustomControls/CustomInput'
import CustomSwitch from '../../CustomControls/CustomSwitch'
import CustomSelect from '../../CustomControls/CustomSelect'
import CustomDatepicker from '../../CustomControls/CustomDatepicker'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

const useStyles = makeStyles((theme) => ({
  radioLabel: {
    color: '#292D3499',
    fontSize: 16,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    '& .MuiButtonBase-root': {
      padding: 0,
      '&.Mui-checked': {
        color: '#91B1BF',
        '& svg': {
          '&:nth-child(2)': {
            opacity: 1,
          },
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiTypography-root': {
      marginRight: 20,
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.type === 'light' ? '#292D3499' : '#fff',

      '@media(max-width: 576px)': {
        fontSize: 14,
      },
    },
    '& svg': {
      '&:first-child': {
        width: 16,
        height: 16,
        borderRadius: '50%',
        border: '1px solid #91B1BF',
        fill: 'transparent',
      },
      '&:nth-child(2)': {
        top: 4,
        left: 4,
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: '#91B1BF',
        fill: 'transparent',
        opacity: 0,
        transform: 'scale(1)',
        transition: 'opacity 0.3s ease-in-out !important',
      },
    },
  },
  rowMR: {
    marginRight: '3rem',
  },
  rowMb: {
    marginBottom: 15,
  },
  lastRowMb: {
    marginBottom: '2rem',
  },
  mediumLabelField: {
    '& .MuiInputBase-input[disabled]': {
      color: theme.palette.type === 'light' ? '#292D3499' : '#fff',
    },
    '& > .MuiFormLabel-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
  },
  switchLegend: {
    position: 'relative',
    top: 1,
    color: theme.palette.type === 'light' ? '#292D3499' : '#ffffff99',
  },
  switchWrapper: {
    '@media (max-width: 600px)': {
      justifyContent: 'flex-start',
    },
  },
  Mobile: {

  },
  Mobile__block: {
    paddingBottom: 10,
  },
  Mobile__blockHeader: {
    color: '#91B1BF',
    fontSize: 12,
  },
  MobileFilter__toggleBtnWrapper: {
    position: 'fixed',
    zIndex: 1,
    left: 46,
    bottom: 0,
    display: 'flex',
    width: 'calc(100% - 46px)',
    height: 60,
    paddingTop: 6,
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
  },
  bottomSidebar__toggleBtn: {
    margin: 'auto',
    bottom: 15,
    left: '50%',
    backgroundColor: 'transparent',
    border: 0,
  },
  switchContainer: {
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      marginRight: 16,
    },
  },
}))

const CustomRadio = withStyles({
  root: {
    color: '#91B1BF',
    '&$checked': {
      color: '#91B1BF',
    },
  },
  checked: {},
})((props) => (
  <Radio
    color="default"
    {...props}
  />
))

export default function UserInfo({ data, handleClickNextPanel, isNeedNextButton }) {
  const classes = useStyles()
  const [radioValue] = useState('true')

  const nationalityRu = data?.nationality?.nameRu

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Box
        mb={3}
        pt={0}
      >
        <FormControl
          component="fieldset"
          fullWidth
        >
          <RadioGroup
            row
            aria-label="group"
            value={radioValue}
          >
            <Grid
              item
              container
              spacing={2}
              alignItems="center"
              justify="space-between"
              sm={10}
              xs={12}
            >
              <Grid
                item
                lg={5}
                sm={6}
                xs={12}
              >
                <FormControlLabel
                  value={(!data?.isController)?.toString()}
                  className={classes.radioLabel}
                  control={<CustomRadio color="primary" />}
                  label="Бенефициарный владелец (преобладающее участие более 25 % в капитале)"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                lg={5}
                sm={6}
                xs={12}
              >
                <FormControlLabel
                  value={(data?.isController)?.toString()}
                  className={classes.radioLabel}
                  control={<CustomRadio color="primary" />}
                  label="Лицо, которое прямо или косвенное контролирует действия клиента"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Grid
              container
              spacing={2}
              className={classes.rowMb}
            >
              <Grid
                item
                lg={7}
                sm={8}
                xs={12}
              >
                <CustomInput
                  className={classes.mediumLabelField}
                  size="small"
                  variant="outlined"
                  name="fullName"
                  fullWidth
                  value={data?.fullName || ''}
                  label="Фамилия, имя, отчество (при наличии последнего)"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg={5}
                sm={4}
                xs={12}
              >
                <CustomSelect
                  label="Гражданство"
                  value={nationalityRu}
                  unique
                >
                  <MenuItem value={nationalityRu}>{nationalityRu}</MenuItem>
                </CustomSelect>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className={classes.rowMb}
            >
              <Grid
                item
                sm={5}
                xs={12}
              >
                <CustomDatepicker
                  label="Дата рождения"
                  value={data?.birthDate}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                sm={7}
                xs={12}
              >
                <CustomInput
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="birthplace"
                  value={data?.birthPlace || ''}
                  label="Место рождения"
                  disabled
                  unique
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className={classes.rowMb}
            >
              <Grid
                item
                sm={4}
                xs={12}
              >
                <CustomInput
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="snils"
                  value={data?.snils || ''}
                  label="СНИЛС"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
              >
                <CustomInput
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="inn"
                  value={data?.inn || ''}
                  label="ИНН"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
              >
                <CustomInput
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="tin"
                  value={data?.tin || ''}
                  label="TIN"
                  disabled
                  unique
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.switchContainer}
            >
              <span className={classes.switchLegend}>Публичное должностное лицо</span>
              <CustomSwitch
                name="first"
                isChecked={!!data?.isPublicOfficial}
                disabled
              />
            </Grid>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            {data?.fullName && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Фамилия, имя, отчество (при наличии последнего)
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.fullName}
              </div>
            </div>
            )}
            {nationalityRu && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Гражданство
              </div>
              <div className={classes.Mobile__blockText}>
                {nationalityRu}
              </div>
            </div>
            )}
            {data?.birthDate && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Дата рождения
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.birthDate}
              </div>
            </div>
            )}
            {data?.birthPlace && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Место рождения
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.birthPlace}
              </div>
            </div>
            )}
            {data?.snils && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                СНИЛС
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.snils}
              </div>
            </div>
            )}
            {data?.inn && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                ИНН
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.inn}
              </div>
            </div>
            )}
            {data?.tin && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                TIN
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.tin}
              </div>
            </div>
            )}
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Публичное должностное лицо
              </div>
              <div className={classes.Mobile__blockText}>
                <CustomSwitch
                  name="first"
                  isChecked={!!data?.isPublicOfficial}
                  disabled
                />
              </div>
            </div>
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </>
        )}
      />
    </MuiPickersUtilsProvider>
  )
}

UserInfo.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

UserInfo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

import { styled } from 'ui/theme'
import { ACCORDION_VARIANTS_LIST, AccordionVariants, ChevronPosition, IAccordionContainer, TTitleType } from './type'

export const AccordionContainer = styled.div<IAccordionContainer>`
  padding: ${({ padding }) => (typeof padding === 'undefined' ? '24px 32px' : padding)};
  width: 100%;

  ${({ withShadow }) => withShadow && 'box-shadow: 0 0 20px 2px rgba(49, 58, 78, 0.06)'};
  border-radius: 8px;

  @media ${({ theme: { breakpoint } }) => breakpoint.xs} {
    padding: ${({ padding }) => (typeof padding === 'undefined' ? '24px' : padding)};
  }

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    padding: ${({ padding }) => (typeof padding === 'undefined' ? '20px' : padding)};
  }
`

export const WrapperHeading = styled.div<{ type: TTitleType }>`
  display: flex;
  justify-content: ${({ type }) => type === 'standart' && 'space-between'};
  cursor: pointer;
`

export const WrapperBody = styled.div<{
  isOpen?: boolean
  isAnimated?: boolean
}>`
  overflow: hidden;

  transition: ${({ isAnimated }) => (isAnimated ? '0.2s' : 0)} linear;
  transition-property: max-height, padding-top, padding-bottom;
`

export const WrapperBodyContent = styled.div<{ variant?: AccordionVariants; paddingTop?: number }>`
  padding-top: ${({ paddingTop }) => (typeof paddingTop === 'undefined' ? 20 : paddingTop)}px;

  display: flex;
  flex-direction: column;

  ${({ variant }) => variant === ACCORDION_VARIANTS_LIST.PLAIN && 'gap: 24px;'}
`

export const Title = styled.p<{
  type: TTitleType
  maxWidth?: number
  textVariant?: string
  fontType?: string
}>`
  margin: 0;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}

  color: ${({ theme }) => theme.color.text.primary};

  ${({ theme: { font } }) => font.h4}

  ${({ type, theme: { color } }) =>
    type === 'bordered' &&
    `
    padding-left: 12px;
    border-left: 2px solid ${color.text.accent};
    `}

  cursor: pointer;

  ${({ fontType }) => fontType}

  @media ${({ theme: { breakpoint } }) => breakpoint?.xxs} {
    font-weight: 600;
  }
`

export const DataArea = styled.div`
  display: flex;
  align-items: center;
`

export const DataWrapper = styled.div`
  width: 540px;
  margin-right: 16px;

  display: grid;
  align-items: center;
  grid-template-columns: 35% 35% 30%;
`

export const Data = styled.div<{ isPositive?: boolean | undefined }>`
  display: flex;
  justify-content: flex-end;

  color: ${({ isPositive, theme }) =>
    isPositive === undefined
      ? theme.color.text.primary
      : theme.color.text[isPositive ? 'positive' : 'negative']};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

export const Currency = styled.p`
  margin: 0 4px;

  font-family: system-ui;
`

export const Chevron = styled.div<{ isOpen?: boolean, chevronPosition: ChevronPosition }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: ${({ chevronPosition }) => chevronPosition === 'bottom' ? 'flex-end' : 'flex-start'};
  margin-left: 10px;

  transition: transform 0.3s ease-in;
  transform: rotate(${({ isOpen }) => (isOpen ? '180' : '0')}deg);
}
`

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ currentUser, component: Component, path }) => (
  <Route
    path={path}
    render={() => (currentUser ? (
      <Component />
    ) : (
      <Redirect to={{
        pathname: '/login',
      }}
      />
    ))}
  />
)

PrivateRoute.defaultProps = {
  currentUser: PropTypes.object,
}

PrivateRoute.propTypes = {
  currentUser: PropTypes.oneOfType([PropTypes.object]),
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  path: PropTypes.string.isRequired,
}

const mapStateToProps = ({ user: { currentUser } }) => ({
  currentUser,
})

export default connect(mapStateToProps, null)(PrivateRoute)

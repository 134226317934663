import { useSizeContext } from 'context/SizeContext'
import useMedia from 'hooks/useMedia'
import React, { FC, memo } from 'react'
import { FormCol, FormRow, TextField } from 'ui/components/atoms'
import { Field } from 'ui/components/molecules'
import Accordion from 'ui/components/molecules/Accordion'
import { theme } from 'ui/theme'
import { formatDateClient } from 'utils/GetDate'
import { isObjectEmpty } from 'utils/helpers/objects'
import RadioSwitch from '../../../Tabs/CommonTab/components/RadioSwitch'
import { StyledBeneficiaresRow, StyledRadioSwitch } from '../styled'
import { IIdentification } from './types'

const Identification: FC<IIdentification> = ({
  identity,
  migrationCard,
  legalResidence,
  ...arrordionProps
}) => {
  const { kind, series, number, departmentCode, issueDate, expirationDate, issuingAuthority } =
    identity || {}

  const { isDesktop } = useMedia()

  return (
    <Accordion
      withShadow={false}
      padding={0}
      variant="plain"
      title="Удостоверение личности"
      {...(isDesktop && { titleType: 'bordered' })}
      bodyPaddingTop={isDesktop ? 24 : 28}
      {...arrordionProps}
    >
      <FormCol gap={28}>
        <FormCol gap={24}>
          <StyledBeneficiaresRow mobileColumn>
            <Field label="Тип документа">
              <TextField disabled value={kind?.name} />
            </Field>

            <Field label="Серия">
              <TextField disabled value={series} />
            </Field>

            <Field label="Номер">
              <TextField disabled value={number} />
            </Field>
          </StyledBeneficiaresRow>

          <StyledBeneficiaresRow mobileColumn>
            <Field label="Код подразделения">
              <TextField disabled value={departmentCode} />
            </Field>

            <Field label="Дата выдачи">
              <TextField disabled value={formatDateClient(issueDate)} />
            </Field>

            <Field label="Дата окончания">
              <TextField disabled value={formatDateClient(expirationDate)} />
            </Field>
          </StyledBeneficiaresRow>

          <Field label="Выдан">
            <TextField disabled value={issuingAuthority} />
          </Field>
        </FormCol>

        <StyledRadioSwitch>
          <RadioSwitch value={!isObjectEmpty(migrationCard)} title="Миграционная карта" />
        </StyledRadioSwitch>

        <StyledBeneficiaresRow mobileColumn>
          <Field label="Номер">
            <TextField disabled value={migrationCard?.number} />
          </Field>

          <Field label="Дата начала срока пребывания">
            <TextField disabled value={formatDateClient(migrationCard?.issueDate)} />
          </Field>

          <Field label="Дата окончания срока пребывания">
            <TextField disabled value={formatDateClient(migrationCard?.expirationDate)} />
          </Field>
        </StyledBeneficiaresRow>

        <StyledRadioSwitch>
          <RadioSwitch value={!isObjectEmpty(legalResidence)} title="Вид на жительство" />
        </StyledRadioSwitch>

        <StyledBeneficiaresRow mobileColumn>
          <Field label="Номер">
            <TextField disabled value={legalResidence?.number} />
          </Field>

          <Field label="Дата начала срока пребывания">
            <TextField disabled value={formatDateClient(legalResidence?.issueDate)} />
          </Field>

          <Field label="Дата окончания срока пребывания">
            <TextField disabled value={formatDateClient(legalResidence?.expirationDate)} />
          </Field>
        </StyledBeneficiaresRow>
      </FormCol>
    </Accordion>
  )
}

export default memo(Identification)

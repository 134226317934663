import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  accountsInvestments: null,
  agreementsDepo: null,
  moneyRequisites: null,
  depoRequisites: null,
}

const investmentsNoticesSlice = createSlice({
  name: 'investmentsNotices',
  initialState,
  reducers: {
    getAccountsInvestments(state) {
      return {
        ...state,
        error: null,
      }
    },
    getAccountsInvestmentsSuccess(state, action) {
      return {
        ...state,
        accountsInvestments: action.payload,
      }
    },
    getAccountsInvestmentsFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getAgreementsDepo(state) {
      return {
        ...state,
        error: null,
      }
    },
    getAgreementsDepoSuccess(state, action) {
      return {
        ...state,
        agreementsDepo: action.payload.parsedResponse,
      }
    },
    getAgreementsDepoFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getMoneyRequisitesFilter(state) {
      return {
        ...state,
        error: null,
      }
    },
    getMoneyRequisitesFilterSuccess(state, action) {
      return {
        ...state,
        moneyRequisites: action.payload,
      }
    },
    getMoneyRequisitesFilterFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getDepoRequisitesFilter(state) {
      return {
        ...state,
        error: null,
      }
    },
    getDepoRequisitesFilterSuccess(state, action) {
      return {
        ...state,
        depoRequisites: action.payload?.parsedResponse,
      }
    },
    getDepoRequisitesFilterFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
  },
})

const { actions, reducer } = investmentsNoticesSlice

export const {
  getAccountsInvestments,
  getAccountsInvestmentsSuccess,
  getAccountsInvestmentsFailed,
  getAgreementsDepo,
  getAgreementsDepoSuccess,
  getAgreementsDepoFailed,
  getMoneyRequisitesFilter,
  getMoneyRequisitesFilterSuccess,
  getMoneyRequisitesFilterFailed,
  getDepoRequisitesFilter,
  getDepoRequisitesFilterSuccess,
  getDepoRequisitesFilterFailed,
} = actions

export default reducer

export const fetchAccountsInvestmentsStartAsync = () => async (dispatch) => {
  dispatch(getAccountsInvestments())
  try {
    await Api.getAccountsInvestments()
  } catch (err) {
    dispatch(getAccountsInvestmentsFailed(err))
  }
}

export const fetchAgreementsDepoStartAsync = () => async (dispatch) => {
  dispatch(getAgreementsDepo())
  try {
    await Api.getAgreementsDepo()
  } catch (err) {
    dispatch(getAgreementsDepoFailed(err))
  }
}

export const fetchMoneyRequisitesFilterStartAsync = ({ filterBody }) => async (dispatch) => {
  dispatch(getMoneyRequisitesFilter())
  try {
    if (filterBody) {
      await Api.getMoneyRequisitesFilter(filterBody)
    }
  } catch (err) {
    dispatch(getMoneyRequisitesFilterFailed(err))
  }
}

export const fetchDepoRequisitesFilterStartAsync = ({ filterBody }) => async (dispatch) => {
  dispatch(getDepoRequisitesFilter())
  try {
    if (filterBody) {
      await Api.getDepoRequisitesFilter(filterBody)
    }
  } catch (err) {
    dispatch(getDepoRequisitesFilterFailed(err))
  }
}

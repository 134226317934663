import { createSlice } from '@reduxjs/toolkit'

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    current: 'light',
  },
  reducers: {
    changeTheme(state, action) {
      return {
        ...state,
        current: action.payload,
      }
    },
  },
})

const { actions, reducer } = themeSlice

export const { changeTheme } = actions

export default reducer

export const USER_REQUEST_TYPE_LIST = {
  UNKNOWN: 'unknown',
  QUESTION: 'question',
  SUGGESTION: 'suggestion',
  CLAIM: 'claim',
  CALL: 'call',
} as const

export const USER_REQUEST_TYPE_LIST_VALUES = {
  [USER_REQUEST_TYPE_LIST.UNKNOWN]: '',
  [USER_REQUEST_TYPE_LIST.QUESTION]: 'Вопрос',
  [USER_REQUEST_TYPE_LIST.SUGGESTION]: 'Предложение',
  [USER_REQUEST_TYPE_LIST.CLAIM]: 'Жалоба',
  [USER_REQUEST_TYPE_LIST.CALL]: 'Обращение',
} as const

/**
 * Типы обращений.
 */
export type ApiUserRequestType = ValueOf<typeof USER_REQUEST_TYPE_LIST>

export const API_USER_REQUEST_STATUS_TYPE_LIST = {
  UNKNOWN: 'unknown',
  DRAFT: 'draft',
  SIGNED: 'signed',
  EXECUTED: 'executed',
  NEED_EXECUTION: 'needExecution',
  REJECTED: 'rejected',
} as const

export const API_USER_REQUEST_STATUS_TYPE_LIST_VALUES = {
  [API_USER_REQUEST_STATUS_TYPE_LIST.UNKNOWN]: 'Черновик',
  [API_USER_REQUEST_STATUS_TYPE_LIST.DRAFT]: 'Черновик',
  [API_USER_REQUEST_STATUS_TYPE_LIST.SIGNED]: 'Подписано клиентом',
  [API_USER_REQUEST_STATUS_TYPE_LIST.EXECUTED]: 'Исполнено',
  [API_USER_REQUEST_STATUS_TYPE_LIST.NEED_EXECUTION]: 'Черновик',
  [API_USER_REQUEST_STATUS_TYPE_LIST.REJECTED]: 'Отказ в исполнении',
} as const

/**
 * Статусы обращений.
 */
export type ApiUserRequestStatusType = ValueOf<typeof API_USER_REQUEST_STATUS_TYPE_LIST>

/**
 * Обращение.
 */
export interface UserRequest {
  /**
   * Внутренний идентификатор.
   *
   * @example
   * id: 0
   */
  id: number | null

  /**
   * Тип обращения.
   */
  typeUid: ApiUserRequestType | null

  /**
   * Процентная разница.
   */
  statusUid: ApiUserRequestStatusType | null

  /**
   * Название статуса
   */
  statusName

  /**
   * Название типа
   */
  typeName

  /**
   * Тема.
   *
   * @example
   * subject: ''
   */
  subject: string

  /**
   * Текст обращения.
   *
   * @example
   * request: ''
   */
  request: string

  /**
   * Дата обращения.
   *
   * @example
   * date: '2022-11-23'
   */
  date: string | null

  /**
   * Время обращения.
   *
   * @example
   * time: '11:12:36'
   */
  time: string | null

  /**
   * Идентификатор пользователя.
   *
   * @example
   * userID: 0
   */
  userID: number | null

  /**
   * Файловые вложения.
   */
  files: number[]
}

import React from 'react'

import { ITag } from './types'
import { StyledSubtitle } from './styled'

const Tag: FCC<ITag> = ({ children, type = 'neutral', ...otherProps }) => {
  return (
    <StyledSubtitle type={type} {...otherProps}>
      {children}
    </StyledSubtitle>
  )
}

export default Tag

import { styled } from "ui/theme";
import { Link } from "react-router-dom";
import { IHeaderNavItemProps } from "./types";

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  // padding: 30px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.bg.secondary};
  height: 80px;
  flex-shrink: 0;
  align-items: center;
  @media ${({ theme }) => theme.breakpoint.xs} {
    padding: 0 0 ${({ theme }) => theme.spacing.lg} 0;
    border-bottom: 1px solid rgba(209, 214, 225, 0.4);
    height: auto;
  }
`

export const StyledHeaderNav = styled.nav`
  display: flex;
  grid-gap: ${({ theme }) => theme.spacing.xl};
  @media ${({ theme }) => theme.breakpoint.xs} {
    justify-content: space-between;
    grid-gap: ${({ theme }) => theme.spacing.md};
    flex-direction: column;
    width: 100%;
    a:first-child, a:last-child {
      display: none;
    }
  }
`

export const StyledHeaderText = styled.span`
  color: ${({ theme }) => theme.color.text.secondary};
  ${({ theme }) => theme.font.body2}
  @media ${({ theme }) => theme.breakpoint.xs} {
    display: none;
  }
`

export const StyledHeaderNavItem = styled(Link) <IHeaderNavItemProps>`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.color.text.primary};
  ${({ theme }) => theme.font.body2}}
  @media ${({ theme }) => theme.breakpoint.xs} {
    color: ${({ theme }) => theme.color.text.inverted};
  }
`

export const StyledHeaderNavItemText = styled.span`
`

import React, { useEffect, useState } from 'react'
import {
  Redirect, Route, Switch, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Media from 'react-media'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg'
import Broker from './Broker'
import LogBroker from './LogBroker'
import LogDeposit from './LogDeposit'
import LogCorporate from './LogCorporate'
import useStyles from '../InnerTabs/InnerTabs.style'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import useWindowSize from '../../hooks/useWindows'
import PageHeading from '../PageHeading/PageHeading'

export default function ReportPanel() {
  const classes = useStyles()
  const { windowWidth } = useWindowSize()
  const [secondNestingVisibility, setSecondNestingVisibility] = useState(true)
  const { url, path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const splitPathname = pathname.split('/')
  const isPathFormClassName = splitPathname[3] === 'new'
  const isPathBrokerClassName = splitPathname[3] === 'broker'
  const isPathDepositClassName = splitPathname[3] === 'deposit'
  const isPathCorporateClassName = splitPathname[3] === 'corporate'

  useEffect(() => {
    if (windowWidth <= 576) {
      if ((pathname.match(/\//g) || []).length >= 3) {
        setSecondNestingVisibility(false)
      } else {
        setSecondNestingVisibility(true)
      }
    } else {
      setSecondNestingVisibility(true)
    }
  }, [windowWidth, pathname])

  const goTo = (link) => history.push(`${url}/${link}`)
  const getDynamicClassname = (isActive) => (isActive ? `${classes.breadCrumb} ${classes.activeCrumb}` : classes.breadCrumb)

  return (
    <Box>
      {secondNestingVisibility && (
        <>
          <PageHeading text="Отчеты" />
          <Grid
            container
            className={classes.crumbContainer}
          >
            <button
              type="button"
              className={getDynamicClassname(isPathFormClassName)}
              onClick={() => goTo('new')}
            >
              <Media
                query={`(max-width: ${breakpoints.phone_large}px)`}
                render={() => (
                  <TransfersIcon />
                )}
              />
              <span>Новый отчет</span>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <ArrowRightIcon />
                )}
              />
            </button>
            <button
              type="button"
              className={getDynamicClassname(isPathBrokerClassName)}
              onClick={() => goTo('broker')}
            >
              <Media
                query={`(max-width: ${breakpoints.phone_large}px)`}
                render={() => (
                  <TransfersIcon />
                )}
              />
              <span>Брокерские отчеты</span>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <ArrowRightIcon />
                )}
              />
            </button>
            <button
              type="button"
              className={getDynamicClassname(isPathDepositClassName)}
              onClick={() => goTo('deposit')}
            >
              <Media
                query={`(max-width: ${breakpoints.phone_large}px)`}
                render={() => (
                  <TransfersIcon />
                )}
              />
              <span>Депозитарные отчеты</span>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <ArrowRightIcon />
                )}
              />
            </button>
            <button
              type="button"
              className={getDynamicClassname(isPathCorporateClassName)}
              onClick={() => goTo('corporate')}
            >
              <Media
                query={`(max-width: ${breakpoints.phone_large}px)`}
                render={() => (
                  <TransfersIcon />
                )}
              />
              <span>Корпоративные действия</span>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <ArrowRightIcon />
                )}
              />
            </button>
          </Grid>
        </>
      )}
      <Switch>
        <Route
          exact
          path={path}
        >
          {windowWidth > 577 ? <Redirect to={`${path}/new`} /> : null}
        </Route>
        <Route
          exact
          path={`${path}/new`}
          component={Broker}
        />
        <Route
          exact
          path={`${path}/broker`}
          component={LogBroker}
        />
        <Route
          exact
          path={`${path}/deposit`}
          component={LogDeposit}
        />
        <Route
          exact
          path={`${path}/corporate`}
          component={LogCorporate}
        />
      </Switch>
    </Box>
  )
}

import { createGlobalStyle } from 'styled-components'
import { styled } from 'ui/theme'
import { IDateFieldProps } from './types'

export const StyledDateField = styled.div<IDateFieldProps>`
  .ant-picker {
    height: 44px;
    padding: 12px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.color.border.default};
    background-color: ${({ theme }) => theme.color.bg.secondary};
    color: ${({ theme }) => theme.color.text.primary};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    &.ant-picker-focused,
    &:focus {
      border: 1px solid ${({ theme }) => theme.color.button.primary};
      outline: none;
    }
    &-disabled {
      border-color: transparent;
      color: ${({ theme }) => theme.color.text.secondary};

      & input:disabled {
        color: ${({ theme }) => theme.color.text.secondary};
      }
    }

    ${({ hasError, theme }) => hasError && `border-color: ${theme.color.text.negative}`};

    ${({ theme }) => theme.font.body2}
  }
`

export const StyledCalendar = createGlobalStyle`
  .ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
    width: 210px;
    .ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn {
      display: none;
    }
  }
  & .ant-picker-input > input:focus {
    border: none !important;
  }
  .ant-picker-panel-container {
    border-radius: 4px;

    
  }
  .ant-picker-header {
    height: 24px;
    border: 0;
    margin-top: 14px;
    margin-bottom: 14px;
    button {
      line-height: inherit;
    }
    .ant-picker-header-view {
      line-height: inherit;
    }
  }

  .ant-picker-cell .ant-picker-cell-inner {
    font-size: 12px;
    border-radius: 12px;
    &:before {
      display: none;
    }
  }
  .ant-picker-date-panel  {
    .ant-picker-body {
      padding: 0 12px;
    }
    .ant-picker-content {
      width: 100%;
      th, td {
        width: 26px;
        height: 26px;
        vertical-align: center;
        line-height: inherit;
        font-size: 12px;
        padding: 0;
      }
    }
  }
  .ant-picker-month-panel, .ant-picker-year-panel {
    width: 140px;
    .ant-picker-header {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .ant-picker-content {
      height: auto;
      cellspacing: 8px;
    }
    .ant-picker-cell {
      padding: 0;
      height: 16px;

    }
    .ant-picker-cell-inner {
      width: 36px;
      padding: 0;
    }
  }

  .ant-picker-input input::placeholder {
    color: #8C93A8;
  }
`

import { request } from './Request'
import { syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {


  getTransferMoney(id) {
    return request(syncApiURL, endpoints.moneys.getTransferMoney(id))
  },
  postTransferMoney(data, needInstruction) {
    return request(syncApiURL, endpoints.moneys.postTransferMoney(needInstruction), 'POST', data)
  },
  putTransferMoney(data, id, needInstruction = false) {
    return request(syncApiURL, endpoints.moneys.putTransferMoney(data, id, needInstruction), 'PUT', data)
  },



  getTransferSecurity(id) {
    return request(syncApiURL, endpoints.security.getTransferSecurity(id))
  },
  postTransferSecurity(data, needInstruction) {
    return request(syncApiURL, endpoints.security.postTransferSecurity(needInstruction), 'POST', data)
  },
  putTransferSecurity(data, id, needInstruction = false) {
    return request(syncApiURL, endpoints.security.putTransferSecurity(data, id, needInstruction), 'PUT', data)
  },



  deleteMoney(id) {
    return request(syncApiURL, endpoints.moneys.putTransferMoney(null, id, false), 'DELETE')
  },
  getBanks(filterBody) {
    return request(syncApiURL, endpoints.banks.getBanksFilter(filterBody))
  },
  getPaymentTemplates(filterBody) {
    return request(syncApiURL, endpoints.paymentTemplates.getPaymentTemplatesFilter(filterBody))
  },
  deletePaymentTemplate(id) {
    return request(syncApiURL, endpoints.paymentTemplates.deletePaymentTemplate(id), 'DELETE')
  },
}

import endpoints from 'api/endpoints';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConnectionState } from 'redux/connection/selector';
import { fetchAccountsInvestmentsStartAsync, fetchMoneyRequisitesFilterStartAsync, getAccountsInvestmentsSuccess, getMoneyRequisitesFilterSuccess } from 'redux/investmentsNotices/investmentsNoticesSlice';
import { selectAccountsInvestments, selectMoneyRequisites } from 'redux/investmentsNotices/selector';
import { Form, FormRow, Text, Field, Select, BlockHeading, Icon } from 'ui/components';
import useConnect from 'utils/Connect';
import createUri from 'utils/FilterUri';
import { StyledContent, StyledItem, StyledReqBlock, StyledReqBlockContent, StyledReqBlockCopy } from './styled';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';


export const Money: React.FC = () => {
  const dispatch = useDispatch()
  const [account, setAccount] = useState(null)
  const [platform, setPlatform] = useState(null)
  const [platformList, setPlatformList] = useState([])
  const [moneyRequisitesList, setMoneyRequisitesList] = useState([])
  const [isCleared, setIsCleared] = useState(false)

  const connectedWS = useSelector(selectConnectionState)
  const accountsInvestments = useSelector(selectAccountsInvestments)
  const moneyRequisites = useSelector(selectMoneyRequisites)

  const fetchAccountsInvestmentsStart = () => {
    dispatch(fetchAccountsInvestmentsStartAsync())
  }

  const fetchMoneyRequisitesFilterStart = (filterBody) => {
    dispatch(fetchMoneyRequisitesFilterStartAsync({ filterBody }))
  }

  const {
    getData: getAccountsInvestments,
  } = useConnect({
    link: `GET /api${endpoints.investmentsNotices.getAccountsInvestments}`,
    startFetching: fetchAccountsInvestmentsStart,
    action: getAccountsInvestmentsSuccess,
  })

  const {
    getData: getMoneyRequisitesFilter,
  } = useConnect({
    link: `GET /api${endpoints.investmentsNotices.moneyRequisitesFilter()}`,
    startFetching: fetchMoneyRequisitesFilterStart,
    action: getMoneyRequisitesFilterSuccess,
  })

  const clearData = () => {
    setIsCleared(true)
    setAccount(null)
    setPlatform(null)
    setPlatformList([])
    setMoneyRequisitesList([])
    dispatch(getMoneyRequisitesFilterSuccess(''))
  }

  useEffect(() => {
    clearData()
    if (connectedWS) {
      getAccountsInvestments()
    }
    // eslint-disable-next-line
  }, [connectedWS])

  useEffect(() => {
    if (account) {
      setPlatformList([])
      const activeAccount = accountsInvestments?.accounts?.find(
        (accountItem) => accountItem?.id === account,
      )
      const tempObjectList = []
      activeAccount.portfolios.forEach(
        (portfolios) => portfolios?.moneyStorages.forEach((item) => tempObjectList.push(item)),
      )
      const filterTempObjectList = Array.from(new Set(tempObjectList.map((a) => a.uid)))
        .map((uid) => tempObjectList.find((a) => a.uid === uid))
      setPlatformList(filterTempObjectList)
    }
    // eslint-disable-next-line
  }, [account])

  useEffect(() => {
    if (account && platform) {
      // eslint-disable-next-line
      handleAcceptFilter()
    }
    // eslint-disable-next-line
  }, [account, platform])

  useEffect(() => {
    if (!platform) {
      setMoneyRequisitesList([])
      dispatch(getMoneyRequisitesFilterSuccess(''))
    }
    // eslint-disable-next-line
  }, [platform])

  useEffect(() => {
    if (isCleared && moneyRequisites) {
      setMoneyRequisitesList([])
      const list = moneyRequisites.parsedResponse;
      const groupList = list.reduce((r, a) => {
        // eslint-disable-next-line
        r[a.currencyUID] = r[a.currencyUID] || []
        r[a.currencyUID].push(a)
        return r
      }, Object.create(null))
      Object.keys(groupList).forEach((item) => {
        setMoneyRequisitesList((prev) => [...prev, groupList[item]])
      })
    }
  }, [moneyRequisites, isCleared])

  const handleChangeAccount = (option) => {
    setAccount(option?.value)
    setPlatform(null)
  }

  const handleChangePlatform = (option) => {
    setPlatform(option?.value)
  }

  const content = React.useRef(null)

  const accounts = accountsInvestments?.accounts.map(({ id, number }) => ({ value: id, label: number })) ?? []
  const platforms = platformList?.map(({ uid, description }) => ({ value: uid, label: description })) ?? []

  const handleAcceptFilter = () => {
    if (account && platform) {
      const filterBodyClient = createUri({
        name: 'moneyRequisites',
        accountId: account,
        marketUID: platform,
        condition: '&',
      })
      getMoneyRequisitesFilter(filterBodyClient.replaceAll('+', ''))
    }
  }
  return <>
    <Form>
      <FormRow>
        <Field label="Счет">
          <Select
            options={accounts}
            value={accounts.find(a => a.value === account)}
            onChange={handleChangeAccount}
            placeholder="Введите номер или выберите из списка"
          />
        </Field>
        <Field label="Площадка">
          <Select
            options={platforms}
            value={platforms.find(a => a.value === platform)}
            onChange={handleChangePlatform}
            placeholder="Введите номер или выберите из списка"
          />
        </Field>
      </FormRow>
    </Form>
    {
      moneyRequisitesList.map((groupItem, groupItemKey) => (
        <StyledItem
          key={groupItem?.description + groupItemKey.toString()}
        >
          <BlockHeading title={groupItem[0]?.currencyUID} />
          <StyledReqBlock>
            <StyledReqBlockContent ref={content}>
              {groupItem.map((item, key) => (
                <Text
                  color={'secondary'}
                  key={item?.caption + key.toString()}
                >
                  <strong>
                    {item?.caption}
                  </strong>
                  {item?.text}
                </Text>
              ))}
            </StyledReqBlockContent>
            <StyledReqBlockCopy onClick={() => {
              copy(content.current?.innerText);
              toast('Скопировано в буфер обмена');
            }}>
              <Icon name="copy" size={'md'} />
            </StyledReqBlockCopy>
          </StyledReqBlock>
        </StyledItem>
      ))
    }
  </>
}

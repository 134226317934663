import { createSlice } from '@reduxjs/toolkit'
import getCadespluginAPI from 'async-cadesplugin'
import moment from 'moment'
import Cookies from 'js-cookie'
import Api from '../../api/Api'
import encrypt from '../../utils/Encrypt'
import { oldEncodeToUTF8 } from '../../utils/EncodeToUTF8'
import { openNotificationsModal } from '../notifications/notificationsSlice'
import { getErrors } from '../errorsModal/errorsModalSlice'

const initialState = {
  isFetching: false,
  error: null,
  currentUser: null,
  currentAgent: null,
  currentClient: null,
  currentCertificate: null,
  thumbPrint: null,
  code: null,
  selector: null,
  recovery: null,
  reset: null,
  resetClient: null,
  pass: null,
  status: null,
  statusError: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    disableFetching(state) {
      return {
        ...state,
        isFetching: false,
      }
    },
    userLoginStart(state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    },
    userLoginSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        currentUser: action.payload,
        error: null,
      }
    },
    userLoginFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    userLogout() {
      return initialState
    },
    agentSelectStart(state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    },
    agentSelectSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        currentAgent: action.payload,
        error: null,
      }
    },
    agentSelectFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    clearAgent(state) {
      return {
        ...state,
        currentAgent: null,
      }
    },
    clientSelectStart(state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    },
    clientSelectSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        currentClient: action.payload,
        error: null,
      }
    },
    clientSelectFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    clearClient(state) {
      return {
        ...state,
        currentClient: null,
      }
    },
    certificateSelectStart(state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    },
    certificateSelectSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        currentCertificate: action.payload,
        error: null,
      }
    },
    clearCertificate(state) {
      return {
        ...state,
        currentCertificate: null,
        thumbPrint: null,
      }
    },
    certificateSelectFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    setThumbPrints(state, action) {
      return {
        ...state,
        thumbPrints: action.payload,
      }
    },
    setThumbPrint(state, action) {
      return {
        ...state,
        thumbPrint: action.payload,
      }
    },
    enterCodeStart(state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    },
    enterCodeSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        code: action.payload,
        error: null,
      }
    },
    enterCodeFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    selectorSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        selector: action.payload,
      }
    },
    selectorFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    clearSelector(state) {
      return {
        ...state,
        selector: null,
      }
    },
    clearCode(state) {
      return {
        ...state,
        code: null,
      }
    },
    userRecoveryStart(state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    },
    userRecoverySuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        recovery: action.payload,
        error: null,
      }
    },
    userRecoveryFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    userResetStart(state, action) {
      return {
        ...state,
        isFetching: true,
        error: action.payload,
      }
    },
    userResetSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        reset: action.payload,
        error: null,
      }
    },
    userResetFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    resetClientSelectStart(state, action) {
      return {
        ...state,
        isFetching: true,
        error: action.payload,
      }
    },
    resetClientSelectSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        resetClient: action.payload,
        error: null,
      }
    },
    resetClientSelectFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    resetCertificateSelectStart(state) {
      return {
        ...state,
        isFetching: false,
        error: null,
      }
    },
    resetCertificateSelectSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        currentCertificate: action.payload,
        error: null,
      }
    },
    resetCertificateSelectFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    setPassStart(state, action) {
      return {
        ...state,
        isFetching: true,
        error: action.payload,
      }
    },
    setPassSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        pass: action.payload,
        error: null,
      }
    },
    setPassFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    getStatusStart(state) {
      return {
        ...state,
        status: null,
      }
    },
    getStatusSuccess(state, action) {
      return {
        ...state,
        status: action.payload,
      }
    },
    getStatusError(state, action) {
      return {
        ...state,
        statusError: action.payload,
      }
    },
    setAccessToken(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          accessToken: action.payload,
        },
      }
    },
    clearPass(state) {
      return {
        ...state,
        pass: null,
      }
    },
    clearAccessToken(state) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          accessToken: null,
        },
      }
    },
    clearUser() {
      return initialState
    },
  },
})

const { actions, reducer } = userSlice

export const {
  disableFetching,
  userLoginStart, userLoginSuccess, userLoginFailed, clearUser, userLogout,
  agentSelectStart, agentSelectSuccess, agentSelectFailed, clearAgent,
  clientSelectStart, clientSelectSuccess, clientSelectFailed, clearClient,
  certificateSelectStart, certificateSelectSuccess, certificateSelectFailed,
  clearCertificate, setThumbPrints, setThumbPrint,
  enterCodeStart, enterCodeSuccess, enterCodeFailed, clearCode,
  selectorSuccess, selectorFailed, clearSelector,
  userRecoveryStart, userRecoverySuccess, userRecoveryFailed,
  userResetStart, userResetSuccess, userResetFailed,
  resetClientSelectStart, resetClientSelectSuccess, resetClientSelectFailed,
  setPassStart, setPassSuccess, setPassFailed, clearPass,
  resetCertificateSelectStart, resetCertificateSelectSuccess,
  resetCertificateSelectFailed,
  getStatusStart, getStatusSuccess, getStatusError,
  setAccessToken, clearAccessToken,
} = actions

export default reducer

const claimsObj = [{
  response: '',
}]

const reqObj = {
  claims: '',
}

const setDateTime = () => {
  reqObj.date = moment(new Date()).format('YYYY-MM-DDThh:mm:ss.MSS+03:00')
  reqObj.nonce = new Date().getTime()
}

const setTokens = (accessToken, refreshToken) => {
  Cookies.set('accessToken', accessToken, { sameSite: 'Strict' })
  Cookies.set('refreshToken', refreshToken, { sameSite: 'Strict' })
}

const checkNextConnectionStatus = (response, dispatch, isPrevStep = false, currentThumbPrint = null) => {
  try {
    if (!response) {
      return
    }
    if (!isPrevStep) {
      claimsObj.push({ response })
    }
    reqObj.claims = response?.data?.claims
    switch (response?.data?.requiredAction?.type) {
      case 'select':
        dispatch(selectorSuccess(response?.data?.requiredAction))
        break
      case 'code':
        dispatch(enterCodeSuccess(response?.data))
        break
      case 'sign':
        dispatch(setThumbPrints(
          // eslint-disable-next-line max-len,no-shadow
          response?.data?.requiredAction?.signAction?.certificates?.map((element) => element?.thumbprint),
        ))
        reqObj.signedXml = response?.data?.requiredAction?.signAction?.xml
        dispatch(certificateSelectSuccess(response.data))
        break
      case 'password':
        dispatch(setPassSuccess(response?.data))
        break
      default:
        if (reqObj?.password1 && reqObj?.password2) {
          dispatch(resetClientSelectSuccess(response?.data))
        } else {
          setTokens(response?.data?.accessToken, response?.data?.refreshToken)
          dispatch(userLoginSuccess(response?.data))
          dispatch(openNotificationsModal(true))
          claimsObj.splice(0, claimsObj.length)
          claimsObj.push({ response: '' })
          dispatch(setThumbPrint(currentThumbPrint))
        }
        break
    }
  } catch (error) {
    dispatch(getErrors(error))
    dispatch(userLoginFailed(error?.data))
  }
}

export const goToPrevStepAsync = ({ action }) => async (dispatch) => {
  dispatch(action())
  claimsObj.splice(claimsObj.length - 1, 1)
  const lastResponse = claimsObj[claimsObj.length - 1].response
  checkNextConnectionStatus(lastResponse, dispatch, true)
}

export const fetchUserStartAsync = ({ username, password }) => async (dispatch) => {
  dispatch(userLoginStart())
  try {
    if (Object.keys(reqObj)?.length) {
      Object.keys(reqObj).map((item) => delete reqObj[item])
    }
    setDateTime()
    reqObj.claims = ''
    reqObj.login = username
    reqObj.password = password
    const cryptedData = encrypt(reqObj)
    await Api.login(cryptedData).then((response) => checkNextConnectionStatus(response, dispatch))
  } catch (error) {
    dispatch(getErrors(error))
    dispatch(userLoginFailed(error?.data))
    return error
  }
  return null
}

export const fetchSelectorStartAsync = ({ agentId }) => async (dispatch) => {
  dispatch(agentSelectStart())
  try {
    setDateTime()
    reqObj.selectedID = agentId
    delete reqObj.signedXml
    delete reqObj.thumbPrint
    delete reqObj.secret
    const cryptedData = encrypt(reqObj)
    await Api.login(cryptedData).then((response) => {
      checkNextConnectionStatus(response, dispatch)
    })
  } catch (error) {
    dispatch(getErrors(error))
    dispatch(selectorFailed(error?.data))
    return error
  }
  return null
}

export const fetchCertificateStartAsync = ({ currentThumbPrint }) => async (dispatch) => {
  dispatch(certificateSelectStart())
  try {
    setDateTime()
    const api = await getCadespluginAPI()
    const signature = await api.signXml(currentThumbPrint, reqObj.signedXml)
    delete reqObj.thumbPrint
    delete reqObj.secret
    reqObj.signedXml = signature
    const cryptedData = encrypt(reqObj)
    await Api.login(cryptedData).then((response) => {
      checkNextConnectionStatus(response, dispatch, false, currentThumbPrint)
    })
  } catch (error) {
    dispatch(getErrors(error))
    dispatch(certificateSelectFailed(error?.data))
    return error
  }
  return null
}

export const fetchCodeStartAsync = ({ code }) => async (dispatch) => {
  dispatch(enterCodeStart())
  try {
    setDateTime()
    reqObj.secret = code
    delete reqObj.signedXml
    delete reqObj.thumbPrint
    const cryptedData = encrypt(reqObj)
    await Api.login(cryptedData).then((response) => {
      checkNextConnectionStatus(response, dispatch)
    })
  } catch (error) {
    dispatch(getErrors(error))
    dispatch(enterCodeFailed(error?.data))
    return error
  }
  return null
}

export const fetchRecoveryStartAsync = ({ login }) => async (dispatch) => {
  dispatch(userRecoveryStart())
  let res = {}
  try {
    const obj = {
      date: moment(new Date()).format('YYYY-MM-DDThh:mm:ss.MSS+03:00'),
      nonce: new Date().getTime(),
      login: oldEncodeToUTF8(login),
      resetUrl: `${window.location.origin}/Account/PasswordReset/{link}`,
    }
    const cryptedData = encrypt(obj)
    await Api.reset(cryptedData).then((response) => {
      res = response.data
      dispatch(userRecoverySuccess(response.data))
    })
  } catch (error) {
    res = error
    dispatch(getErrors(error))
    dispatch(userRecoveryFailed(error?.data))
  }
  return res
}

export const fetchResetStartAsync = ({ secret }) => async (dispatch) => {
  dispatch(userResetStart())
  let res = {}
  try {
    setDateTime()
    reqObj.secret = secret
    const cryptedData = encrypt(reqObj)
    await Api.reset(cryptedData).then((response) => {
      res = response.data
      delete reqObj.secret
      reqObj.claims = response.data.claims
      reqObj.login = response.data.login
      checkNextConnectionStatus(response, dispatch)
      dispatch(userResetSuccess('success'))
    })
  } catch (error) {
    res = error
    dispatch(getErrors(error))
    dispatch(userResetFailed(error?.data))
  }
  return res
}

export const fetchResetClientStartAsync = ({ clientId }) => async (dispatch) => {
  dispatch(resetClientSelectStart())
  let res = {}
  try {
    delete reqObj.secret
    setDateTime()
    reqObj.selectedID = clientId
    const cryptedData = encrypt(reqObj)
    await Api.reset(cryptedData).then((response) => {
      res = response.data
      reqObj.claims = response.data.claims
      checkNextConnectionStatus(response, dispatch)
    })
  } catch (error) {
    dispatch(getErrors(error))
    dispatch(resetClientSelectFailed(error?.data))
  }
  return res
}

export const fetchPassStartAsync = ({ password1, password2 }) => async (dispatch) => {
  dispatch(setPassStart())
  let res = {}
  try {
    setDateTime()
    reqObj.password1 = password1
    reqObj.password2 = password2
    const cryptedData = encrypt(reqObj)
    await Api.reset(cryptedData).then((response) => {
      res = response.data
      checkNextConnectionStatus(response, dispatch)
    })
  } catch (error) {
    dispatch(getErrors(error))
    dispatch(setPassFailed(error?.data))
    res = error
  }
  return res
}

export const fetchPassCertificateStartAsync = ({ currentThumbPrint }) => async (dispatch) => {
  dispatch(resetCertificateSelectStart())
  let res = {}
  try {
    setDateTime()
    const api = await getCadespluginAPI()
    const signature = await api.signXml(currentThumbPrint, reqObj.signedXml)
    delete reqObj.thumbPrint
    reqObj.signedXml = signature
    const cryptedData = encrypt(reqObj)
    await Api.reset(cryptedData).then((response) => {
      res = response
      checkNextConnectionStatus(response, dispatch, false, currentThumbPrint)
    })
  } catch (error) {
    res = error
    dispatch(getErrors(error))
    dispatch(resetCertificateSelectFailed(error?.data))
  }
  return res
}

export const fetchPassCodeStartAsync = ({ code }) => async (dispatch) => {
  dispatch(enterCodeStart())
  let res = {}
  try {
    setDateTime()
    reqObj.secret = code
    const cryptedData = encrypt(reqObj)
    await Api.reset(cryptedData).then((response) => {
      res = response.data
      checkNextConnectionStatus(response, dispatch)
    })
  } catch (error) {
    res = error
    dispatch(getErrors(error))
    dispatch(enterCodeFailed(error?.data))
  }
  return res
}

export const fetchStatusStartAsync = () => async (dispatch) => {
  dispatch(getStatusStart())
  try {
    const value = await Api.status()
    dispatch(getStatusSuccess(value))
  } catch (error) {
    dispatch(getErrors(error))
    dispatch(getStatusError(error))
  }
}

export const fetchUserLogoutAsync = () => async (dispatch) => {
  const response = await Api.logout()
  dispatch(userLogout())
  Cookies.remove('accessToken')
  Cookies.remove('refreshToken')
  return response
}

import { FormRow } from "ui/components";
import { styled } from "ui/theme";

export const StyledWrapper = styled.div`

`
export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xl};
  padding: ${({ theme }) => theme.spacing.xxl};
  box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.04);
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin-bottom: 28px;
  @media (${({ theme }) => theme.breakpoint.xs}) {
    padding: 0;
    margin-top: 32px;
    box-shadow: none;
  }
`

export const StyledHeading = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`

export const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`

export const StyledReqBlock = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: 8px;
  background: ${({ theme }) => theme.color.bg.secondary};
  position: relative;
`

export const StyledReqBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`
export const StyledReqBlockCopy = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.spacing.lg};
  top: ${({ theme }) => theme.spacing.lg};
  color: ${({ theme }) => theme.color.graphics.secondary};
  cursor: pointer;
`

export const StyledTabsWrapper = styled.div`
  margin-bottom: 32px;
  @media ${({ theme }) => theme.breakpoint.xs} {
    margin: 0;
    > div {
      flex-direction: column;
      width: 100%;
    }
  }
`

export const StyledAgreementField = styled(FormRow)`
  max-width: 534px;
  display: flex;
  @media(${({ theme }) => theme.breakpoint.sm}) {
    max-width: 334px;
  }
  @media(${({ theme }) => theme.breakpoint.xs}) {
    max-width: 100%;
  }
`

import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import CustomSelect from '../CustomControls/CustomSelect'
import useStyles from './panel.style'
import { selectConnectionState } from '../../redux/connection/selector'
import { selectAgreementsDepo, selectDepoRequisites } from '../../redux/investmentsNotices/selector'
import {
  fetchAgreementsDepoStartAsync,
  fetchDepoRequisitesFilterStartAsync,
  getAgreementsDepoSuccess, getDepoRequisitesFilterSuccess,
} from '../../redux/investmentsNotices/investmentsNoticesSlice'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import createUri from '../../utils/FilterUri'
import PageHeading from '../PageHeading/PageHeading'

export default function RequisitesDepo() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [contract, setContract] = useState(null)

  const connectedWS = useSelector(selectConnectionState)
  const agreementsDepo = useSelector(selectAgreementsDepo)
  const depoRequisites = useSelector(selectDepoRequisites)

  const fetchAgreementsDepoStart = () => {
    dispatch(fetchAgreementsDepoStartAsync())
  }

  const fetchDepoRequisitesFilterStart = (filterBody) => {
    dispatch(fetchDepoRequisitesFilterStartAsync({ filterBody }))
  }

  const {
    getData: getAgreementsDepo,
  } = useConnect({
    link: `GET /api${endpoints.investmentsNotices.getAgreementsDepo}`,
    startFetching: fetchAgreementsDepoStart,
    action: getAgreementsDepoSuccess,
  })

  const {
    getData: getDepoRequisitesFilter,
  } = useConnect({
    link: `GET /api${endpoints.investmentsNotices.depoRequisitesFilter()}`,
    startFetching: fetchDepoRequisitesFilterStart,
    action: getDepoRequisitesFilterSuccess,
  })

  const clearData = () => {
    setContract(null)
    dispatch(getDepoRequisitesFilterSuccess(''))
  }

  const handleAcceptFilter = () => {
    if (contract) {
      const filterBodyClient = createUri({
        name: 'depoRequisites',
        agreementId: contract,
      })
      getDepoRequisitesFilter(filterBodyClient)
    }
  }

  useEffect(() => {
    clearData()
    if (connectedWS) {
      getAgreementsDepo()
    }
    // eslint-disable-next-line
  }, [connectedWS])

  useEffect(() => {
    if (contract) {
      // eslint-disable-next-line
      handleAcceptFilter()
    } else {
      dispatch(getDepoRequisitesFilterSuccess(''))
    }
    // eslint-disable-next-line
  }, [contract])

  // todo
  // useEffect(() => {
  //   if (agreementsDepo && !agreementsDepo[1]) {
  //     setContract(agreementsDepo[0]?.id)
  //     handleAcceptFilter()
  //   }
  //   // eslint-disable-next-line
  // }, [agreementsDepo])

  const handleChangeContract = (event) => {
    const { value } = event.target
    setContract(value)
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <PageHeading text={'Реквизиты \n депозитария'} />
      <Box
        pt="30px"
        maxWidth={898}
      >
        <form
          className={classnames(classes.form, classes.form_mobilePaddingLess)}
          onSubmit={handleSubmitForm}
        >
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={4}
              sm={6}
              xs={12}
            >
              <CustomSelect
                label="Договор"
                value={contract || ''}
                onChange={handleChangeContract}
                unique
              >
                {agreementsDepo && agreementsDepo.map((item) => (
                  <MenuItem
                    key={item?.id}
                    value={item?.id}
                  >
                    {item?.number}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
          </Grid>
        </form>
      </Box>
      {depoRequisites && (
        <div className={classes.infoBlock}>
          <div className={classes.infoBlock__item}>
            <div className={classnames(classes.groupLabel, classes.groupLabel_unique)}>
              Депозитарные реквизиты
            </div>
            <div className={classes.infoBlock__content}>
              {depoRequisites?.map((item, key) => (
                <div
                  key={item?.id + key.toString()}
                  className={classnames(classes.infoBlock__row,
                    depoRequisites[key + 1]?.caption ? classes.infoBlock__row_mbBottom : '')}
                >
                  {item?.caption && (
                    <div className={classnames(classes.infoBlock__text)}>
                      <span>
                        {item?.caption}
                      </span>
                      {item?.text}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

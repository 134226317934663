import React from 'react';
import {
  Badge,
  Text
} from 'ui/components';
import { StyledPriceChangeCardLabel, StyledPriceChangeCardBody, StyledPriceChangeCard, StyledBodyText } from './styled';
import { IPriceChangeCardProps } from './types';

export const PriceChangeCard: React.FC<IPriceChangeCardProps> = ({ label, value, badge, plain = false }) => {

  return <StyledPriceChangeCard plain={plain}>
    <StyledPriceChangeCardLabel>
      {label}
    </StyledPriceChangeCardLabel>
    <StyledPriceChangeCardBody>
      <StyledBodyText>{value}</StyledBodyText>
      {badge && <Badge {...badge} />}
    </StyledPriceChangeCardBody>
  </StyledPriceChangeCard>;
}

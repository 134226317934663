import { request } from './Request'
import { syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getUsersRequest() {
    return request(syncApiURL, endpoints.feedback.getUser())
  },
  getUserRequest(id) {
    return request(syncApiURL, endpoints.feedback.getUser(id))
  },
  postUserRequest(data) {
    return request(syncApiURL, endpoints.feedback.postUser(), 'POST', data)
  },
  putUserRequest(data) {
    return request(syncApiURL, endpoints.feedback.putUser(data), 'PUT', data)
  },
}

import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import classnames from 'classnames'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Media from 'react-media'
import endpoints from '../../api/endpoints'
import { rowsPerPage } from '../../config/constants'
import {
  fetchHistoryMoneyStartAsync,
  getHistoryMoneySuccess,
  fetchHistoryMoneyFilterStartAsync,
} from '../../redux/history/historySlice'
import { selectHistoryMoney, selectHistoryPortfolios, selectHistoryStatuses } from '../../redux/history/selector'
import useConnect from '../../utils/Connect'

import CustomSelect from '../CustomControls/CustomSelect'
import useStyles from './panel.styles'
import createUri from '../../utils/FilterUri'
import UsePagination from '../Pagination/Pagination'
import CustomDatepicker from '../CustomControls/CustomDatepicker'
import CustomIconButton from '../CustomControls/CustomIconButton'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import MobileFilter from '../MobileFilter/MobileFilter'
import PageHeading from '../PageHeading/PageHeading'
import { selectCurrentDeleteMoney, selectGetTransferMoney, selectPerformTransferMoney } from '../../redux/transfers/money/selector'
import {
  fetchPerformTransferMoneyStartAsync, fetchTransferMoneyStartAsync,
  performTransferMoneySuccess, getTransferMoneySuccess, clearTransferMoney,
  deleteMoneySuccess, fetchDeleteMoneyStartAsync,
} from '../../redux/transfers/money/transferMoneySlice'
import { ReactComponent as IconEds } from '../../assets/images/icon-eds.svg'
import { ReactComponent as IconClose } from '../../assets/images/close-icon.svg'
import useArrayConnect from '../../utils/ArrayConnect'
import { selectCurrentStatus, selectCurrentThumbprint } from '../../redux/user/selector'
import CustomCheckbox from '../CustomControls/CustomCheckbox'
import CustomModal from '../CustomModal/CustomModal'
import CustomLoader from '../CustomLoader/CustomLoader'
import declOfNum from '../../utils/DeclensionWord'
import { clearError } from '../../redux/error/errorSlice'
import CustomInputSelect from '../CustomControls/CustomInputSelect'
import { addEmptyValueToArray } from '../../utils/Validate'

const initialState = {
  portfolio: {
    portfolios: [],
    currentPortfolio: '',
  },
  status: {
    statuses: [],
    currentStatus: '',
  },
  date: {
    from: undefined,
    to: undefined,
  },
}

const moneyCancelUid = 'Orders.Type.Modify.Cancellation'
const moneyTransferUid = 'Orders.Type.NonTrade.Money.Transfer'
const moneyOutputUid = 'Orders.Type.NonTrade.Money.Out'

const MoneyPanel = ({ connectedWS, fetchHistoryMoneyStart, fetchHistoryFilterStart }) => {
  const classes = useStyles()
  const [portfolioSelect, setPortfolioSelect] = useState(initialState.portfolio)
  const [statusSelect, setStatusSelect] = useState(initialState.status)
  const [dateFrom, setDateFrom] = useState(initialState.date.from)
  const [dateTo, setDateTo] = useState(initialState.date.to)
  const [page, setPage] = useState(0)
  const [idFiles, setIdFiles] = useState([])
  const [arraySigned, setArraySigned] = useState([])
  const [arrayDeleteSigned, setArrayDeleteSigned] = useState([])
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [successSigned, setSuccessSigned] = useState([])
  const [failSigned, setFailSigned] = useState(null)
  const [finishSignModalOpen, setFinishSignModalOpen] = useState(false)
  const [finishSignLoaderModalOpen, setFinishSignLoaderModalOpen] = useState(false)
  const [finishDeleteSignModalOpen, setFinishDeleteSignModalOpen] = useState(false)
  const [isCleared, setIsCleared] = useState(false)
  const [selected, setSelected] = useState([])
  const [filterUri, setFilterUri] = useState('')

  const history = useHistory()
  const dispatch = useDispatch()

  const getThumbprintSelector = useSelector(selectCurrentThumbprint)
  const getUserStatus = useSelector(selectCurrentStatus)
  const historyMoneyData = useSelector(selectHistoryMoney)
  const filterPortfolios = useSelector(selectHistoryPortfolios)
  const filterStatuses = useSelector(selectHistoryStatuses)
  const getPerformMoneySelector = useSelector(selectPerformTransferMoney)
  const getRespMoneySelector = useSelector(selectGetTransferMoney)
  const deleteMoneySelector = useSelector(selectCurrentDeleteMoney)
  const getRespMoney = useMemo(() => getRespMoneySelector, [getRespMoneySelector])
  const getPerformMoney = useMemo(() => getPerformMoneySelector, [getPerformMoneySelector])

  const fetchPerformTransferStart = (data, id) => {
    dispatch(fetchPerformTransferMoneyStartAsync({ data, id }))
  }

  const fetchGetTransferStart = (id) => {
    dispatch(fetchTransferMoneyStartAsync({ id }))
  }

  const fetchDeleteTransferStart = (id) => {
    dispatch(fetchDeleteMoneyStartAsync({ id }))
  }

  const setSelectedAll = () => {
    const newSelecteds = historyMoneyData?.orders?.map((item) => (item?.status?.name === 'Черновик' && item?.id))?.filter(Boolean) ?? []
    setSelected(newSelecteds)
  }

  const { getData } = useConnect({ link: `GET /api${endpoints.history.getMoney}`, startFetching: fetchHistoryMoneyStart, action: getHistoryMoneySuccess })
  const { getData: moneyFilter } = useConnect({ link: `GET /api${endpoints.history.moneyFilter()}`, startFetching: fetchHistoryFilterStart, action: getHistoryMoneySuccess })
  const { getData: putPerformData } = useArrayConnect({
    link: `PUT /api${endpoints.moneys.putTransferMoney(null)}`, idList: idFiles, startFetching: fetchPerformTransferStart, action: performTransferMoneySuccess,
  })
  const { getData: deleteData } = useArrayConnect({
    link: `DELETE /api${endpoints.moneys.getTransferMoney()}`, idList: idFiles, startFetching: fetchDeleteTransferStart, action: deleteMoneySuccess,
  })
  useConnect({
    link: `DELETE /api${endpoints.moneys.getTransferMoney()}`,
  })
  const { getData: getMoneyData } = useArrayConnect({
    link: `GET /api${endpoints.moneys.getTransferMoney()}`, idList: idFiles, startFetching: fetchGetTransferStart, action: getTransferMoneySuccess,
  })
  useEffect(() => {
    dispatch(clearTransferMoney())
    setIsCleared(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (connectedWS) {
      setArraySigned([])
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  useEffect(() => {
    if (filterPortfolios?.length) {
      const portfolioNames = []
      filterPortfolios.map((portfolio) => {
        portfolioNames.push(portfolio.name)
        return portfolio
      })
      setPortfolioSelect({ ...portfolioSelect, portfolios: portfolioNames })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPortfolios])

  useEffect(() => {
    if (filterStatuses?.length) {
      const statusNames = []
      filterStatuses.map((status) => {
        statusNames.push(status.name)
        return status
      })
      setStatusSelect({ ...statusSelect, statuses: statusNames })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatuses])

  useEffect(() => {
    if (historyMoneyData?.orders?.length) {
      const currentOrdersLength = historyMoneyData?.orders?.length
      if (page * rowsPerPage > currentOrdersLength) {
        setPage(0)
      }
      if (getUserStatus?.useCertificate && selected.length) {
        const arrayFoundChecked = []
        historyMoneyData.orders.forEach((item) => {
          if (selected.some((selectElm) => item.id === selectElm)) {
            arrayFoundChecked.push(item.id)
          }
        })
        setSelected(arrayFoundChecked)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyMoneyData])

  useEffect(() => {
    if ((arraySigned?.length && selected.length)
      && arraySigned?.length === selected.length) {
      setSuccessSigned(arraySigned.filter((x) => x).length)
      setFailSigned(arraySigned.filter((x) => !x).length)
      setArraySigned([])
      setSelected([])
      setFinishSignLoaderModalOpen(false)
      setFinishSignModalOpen(true)
      if (connectedWS) {
        if (filterUri) {
          moneyFilter(filterUri)
        } else {
          getData()
        }
      }
      return
    }
    if ((arrayDeleteSigned?.length && selected.length)
      && arrayDeleteSigned?.length === selected.length) {
      setSuccessSigned(arrayDeleteSigned.filter((x) => x).length)
      setFailSigned(arrayDeleteSigned.filter((x) => !x).length)
      setArrayDeleteSigned([])
      setSelected([])
      setFinishSignLoaderModalOpen(false)
      setFinishDeleteSignModalOpen(true)
      if (connectedWS) {
        if (filterUri) {
          moneyFilter(filterUri)
        } else {
          getData()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arraySigned, selected, arrayDeleteSigned, connectedWS])

  useEffect(() => {
    if (failSigned) {
      dispatch(clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failSigned])

  useEffect(() => {
    if (getRespMoney?.order && isCleared) {
      const reqObj = { ...getRespMoney?.order }
      if (getThumbprintSelector) {
        reqObj.thumbPrint = getThumbprintSelector
      }
      putPerformData(reqObj, reqObj?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRespMoney, isCleared])

  useEffect(() => {
    if (getPerformMoney?.status && isCleared) {
      if (getPerformMoney?.status === 303) {
        const reqObj = { ...getPerformMoney.order }
        reqObj.thumbPrint = getThumbprintSelector
        reqObj.signedXml = getPerformMoney?.requiredAction?.signAction?.xml
        putPerformData(reqObj, reqObj?.id)
        return
      }
      if (getPerformMoney?.status === 200) {
        setArraySigned((prev) => [
          ...prev,
          true,
        ])
        return
      }
      setArraySigned((prev) => [
        ...prev,
        false,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPerformMoney, isCleared])

  useEffect(() => {
    if (deleteMoneySelector?.status && isCleared) {
      if (deleteMoneySelector?.status === 200) {
        setArrayDeleteSigned((prev) => [
          ...prev,
          true,
        ])
        return
      }
      setArrayDeleteSigned((prev) => [
        ...prev,
        false,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMoneySelector, isCleared])

  const handleClosePerformModal = () => {
    setPerformModalOpen(false)
  }

  const callActionOnOk = (actionMethod) => {
    if (connectedWS) {
      setIdFiles(selected)
      selected.forEach((id) => {
        actionMethod(id)
      })
      setFinishSignLoaderModalOpen(true)
    }
  }

  const handlePerformOk = () => {
    callActionOnOk(getMoneyData)
    handleClosePerformModal()
  }

  const handleChangePortfolioSelect = (event, newValue) => {
    setPortfolioSelect({
      ...portfolioSelect,
      currentPortfolio: newValue,
    })
  }

  const handleChangeStatusSelect = (event) => {
    setStatusSelect({
      ...statusSelect,
      [event.target.name]: event.target.value,
    })
  }

  const getFormatDate = (date, formatDate) => format(new Date(date), formatDate)
  const formatDateFrom = useMemo(() => dateFrom && getFormatDate(dateFrom, 'yyyy-MM-dd'), [dateFrom])
  const formatDateTo = useMemo(() => dateTo && getFormatDate(dateTo, 'yyyy-MM-dd'), [dateTo])
  const handleAcceptFilter = () => {
    const statusUID = filterStatuses?.find(
      (item) => item.name === statusSelect.currentStatus,
    )?.uid || null
    const accountId = filterPortfolios?.find(
      (item) => item.name === portfolioSelect.currentPortfolio,
    )?.id || null
    let uri = 'Count=100'
    if (statusUID || accountId || formatDateFrom || formatDateTo) {
      uri = createUri({
        accountId, dateFrom: formatDateFrom, dateTo: formatDateTo, statusUID, name: 'ordersMoney', condition: 'and',
      })
      uri = `Count=100&Filter=${uri}`
    }
    setFilterUri(uri)
    moneyFilter(uri)
  }

  const handleResetFilter = () => {
    setFilterUri('')
    moneyFilter('Count=100')
    setPortfolioSelect({
      ...portfolioSelect,
      currentPortfolio: initialState.portfolio.currentPortfolio,
    })
    setStatusSelect({
      ...statusSelect,
      currentStatus: initialState.status.currentStatus,
    })
    setDateFrom(
      initialState.date.from,
    )
    setDateTo(
      initialState.date.to,
    )
  }

  const handleChangeDateFrom = (date) => {
    setDateFrom(date)

    // Update `dateTo` if it's nullable value.
    if (dateTo) return
    setDateTo(date)
  }

  const handleChangeDateTo = (date) => {
    setDateTo(date)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleOpenRequest = (id, order) => () => {
    let nextLocation = ''
    if ((order?.type?.uid === moneyCancelUid
      && order?.affectedOrder?.type?.uid === moneyTransferUid)
      || order?.type?.uid === moneyTransferUid) {
      nextLocation = {
        pathname: `/transfers/money/transfer/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    } else if ((order?.type?.uid === moneyCancelUid
      && order?.affectedOrder?.type?.uid === moneyOutputUid)
      || order?.type?.uid === moneyOutputUid) {
      nextLocation = {
        pathname: `/transfers/money/output/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    }
    history.push(nextLocation)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedAll()
      return
    }
    setSelected([])
  }

  const handleSelectClick = (event, id, currentStatusRow) => {
    event.preventDefault()
    event.stopPropagation()
    if (currentStatusRow !== 'Черновик') {
      return
    }
    const selectedIndex = selected.findIndex((itemId) => itemId === id)
    const orders = historyMoneyData?.orders
    let newSelected = []

    if (selectedIndex < 0) {
      newSelected = newSelected.concat(selected, orders.find((order) => order.id === id).id)
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1, selected.length),
      )
    }
    setSelected(newSelected)
  }

  const handleCloseFinishSignModal = () => {
    setFinishSignModalOpen(false)
    setFailSigned(null)
  }

  const handleRowRemove = (event) => {
    event.preventDefault()

    if (selected.length) {
      setDeleteModalOpen(true)
    }
  }

  const isSelected = (id) => selected.some((itemId) => itemId === id)

  const handleRowSign = (event) => {
    event.preventDefault()

    if (selected.length) {
      setPerformModalOpen(true)
    }
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const handleDeleteOk = () => {
    callActionOnOk(deleteData)
    setDeleteModalOpen(false)
  }

  const switchClass = (selectedLength) => (selectedLength > 0
    ? classnames(classes.DepoPanel__multipleSign, classes.DepoPanel__multipleSignChecked)
    : classnames(classes.DepoPanel__multipleSign, classes.DepoPanel__multipleSign_nonChecked))

  const getDeclensionWordOrder = (number) => declOfNum(+number, ['поручение', 'поручения', 'поручений'])

  const getDeclensionWordSign = (number) => declOfNum(+number, ['подписано', 'подписаны', 'подписаны'])

  const getDeclensionWordDelete = (number) => declOfNum(+number, ['удалено', 'удалены', 'удалены'])

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <PageHeading text="История поручений" />
      <Box className={classes.MoneyPanel__BoxWrapper}>
        <Media
          query="(min-width: 577px)"
          render={() => (
            <>
              <Grid
                className={classes.inputsContainer}
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                >
                  <CustomInputSelect
                    labelId="count-select-label"
                    label="Портфель"
                    name="currentPortfolio"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={portfolioSelect?.currentPortfolio || ''}
                    options={addEmptyValueToArray(
                      portfolioSelect?.portfolios?.map((option) => option),
                    )}
                    onChange={handleChangePortfolioSelect}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                >
                  <CustomSelect
                    labelId="count-select-label"
                    name="currentStatus"
                    label="Статус"
                    value={statusSelect?.currentStatus}
                    onChange={handleChangeStatusSelect}
                    unique
                  >
                    {statusSelect?.statuses?.map((name, key) => (
                      (
                        <MenuItem
                          key={key.toString() + name}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      )
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                >
                  <CustomDatepicker
                    label="Период с"
                    maxDate={dateTo}
                    value={dateFrom}
                    setDateFrom={setDateFrom}
                    isDateFrom
                    onChange={handleChangeDateFrom}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                >
                  <CustomDatepicker
                    label="Период по"
                    minDate={dateFrom}
                    value={dateTo || dateFrom}
                    setDateTo={setDateTo}
                    isDateTo
                    onChange={handleChangeDateTo}
                    unique
                  />
                </Grid>
              </Grid>
              <Grid
                item
                style={{ marginTop: 22 }}
              >
                <div className={classes.btnGroup}>
                  <CustomIconButton
                    type="button"
                    onClick={handleAcceptFilter}
                    unique
                    needLine
                    noIcon
                  >
                    Применить
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    onClick={handleResetFilter}
                    unique
                    noIcon
                  >
                    Очистить
                  </CustomIconButton>
                </div>
              </Grid>
              {getUserStatus?.useCertificate && (
                <div className={switchClass(selected.length)}>
                  <CustomCheckbox
                    checked={selected.length > 0}
                    onChange={handleSelectAllClick}
                    size="small"
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                  <div
                    className={classes.DepoPanel__sign}
                    onClick={handleRowSign}
                    aria-hidden="true"
                  >
                    <IconEds />
                    Подписать
                  </div>
                  <div
                    className={classes.DepoPanel__remove}
                    onClick={handleRowRemove}
                    aria-hidden="true"
                  >
                    <IconClose />
                    Удалить
                  </div>
                </div>
              )}
              <div>
                <TableContainer
                  className={classes.tableContainer}
                  style={{ paddingTop: 9 }}
                >
                  <Table aria-label="assetsMoney table">
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        {getUserStatus?.useCertificate && (<TableCell padding="checkbox" />)}
                        <TableCell className={classes.tableHeaderCell}>Дата отправления</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Номер</TableCell>
                        <TableCell className={classes.tableHeaderCell}>
                          Тип операции&nbsp;
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell}>Портфель&nbsp;</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Сумма &nbsp;</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Валюта &nbsp;</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Статус &nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyMoneyData?.orders?.slice(
                        ((page) * (rowsPerPage)), ((page + 1) * (rowsPerPage)),
                      ).map((row, key) => {
                        const formatDate = getFormatDate(row?.dateTime, "dd.MM.yyyy'T'HH:mm").replace('T', ' ')
                        let isItemSelected = ''
                        let labelId = ''
                        if (getUserStatus?.useCertificate) {
                          isItemSelected = isSelected(row.id)
                          labelId = `enhanced-table-checkbox-${key}`
                        }
                        return (
                          <TableRow
                            className={classes.tableRow}
                            hover
                            aria-checked={isItemSelected}
                            key={row.id}
                            onClick={handleOpenRequest(row.id, row)}
                            selected={isItemSelected}
                            style={{ height: 80 }}
                          >
                            {getUserStatus?.useCertificate && (
                              <TableCell padding="checkbox">
                                <CustomCheckbox
                                  onClick={(event) => handleSelectClick(
                                    event, row.id, row?.status?.name,
                                  )}
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                  size="small"
                                />
                              </TableCell>
                            )}
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableBodyCell}
                              style={{ minWidth: 200 }}
                            >
                              {formatDate}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyCell}
                              style={{ minWidth: 125 }}
                            >
                              {row?.number}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyCell}
                              style={{ minWidth: 150 }}
                            >
                              {row?.type?.name}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyCell}
                              style={{ minWidth: 145 }}
                            >
                              {row?.clientPortfolio?.name}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyCell}
                              style={{ minWidth: 200 }}
                            >
                              {row?.amount || row?.affectedOrder?.amount}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyCell}
                              style={{ minWidth: 100 }}
                            >
                              {row?.currency?.uid || row?.affectedOrder?.currencyUid}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyCell}
                              style={{ minWidth: 150 }}
                            >
                              {row?.status?.name}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        />
        <Media
          query={`(max-width: ${breakpoints.phone_large}px)`}
          render={() => historyMoneyData?.orders?.map((row) => (
            <div
              className={classes.PhoneGrid}
              key={row.id}
              onClick={handleOpenRequest(row.id, row)}
              aria-hidden="true"
            >
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Тип операции
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.type?.name}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Номер
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.number}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Портфель
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.clientPortfolio?.name}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Дата отправления
                </span>
                <p className={
                  classnames(classes.PhoneGrid__value, classes.PhoneGrid__value_medium)
                }
                >
                  {getFormatDate(row?.dateTime, "dd.MM.yyyy'T'HH:mm").replace('T', ' ')}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Сумма
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.amount || row?.affectedOrder?.amount}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Валюта
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.currency?.uid || row?.affectedOrder?.currencyUid}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Статус
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.status?.name}
                </p>
              </div>
            </div>
          )) || null}
        />
        <Media
          query={`(max-width: ${breakpoints.phone_large}px)`}
          render={() => (
            <MobileFilter
              handleAcceptFilter={handleAcceptFilter}
              handleResetFilter={handleResetFilter}
              constItems={[
                portfolioSelect?.currentPortfolio,
                statusSelect?.currentStatus,
                dateFrom,
                dateTo,
              ]}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                >
                  <CustomInputSelect
                    labelId="count-select-label"
                    label="Портфель"
                    name="currentPortfolio"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={portfolioSelect?.currentPortfolio || ''}
                    options={addEmptyValueToArray(
                      portfolioSelect?.portfolios?.map((option) => option),
                    )}
                    onChange={handleChangePortfolioSelect}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CustomSelect
                    labelId="count-select-label"
                    name="currentStatus"
                    label="Статус"
                    value={statusSelect?.currentStatus}
                    onChange={handleChangeStatusSelect}
                    unique
                    inFilter
                  >
                    {statusSelect?.statuses?.map((name, key) => (
                      (
                        <MenuItem
                          key={key.toString() + name}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      )
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CustomDatepicker
                    label="Период с"
                    maxDate={dateTo}
                    value={dateFrom}
                    setDateFrom={setDateFrom}
                    isDateFrom
                    onChange={handleChangeDateFrom}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CustomDatepicker
                    label="Период по"
                    minDate={dateFrom}
                    value={dateTo || dateFrom}
                    setDateTo={setDateTo}
                    isDateTo
                    onChange={handleChangeDateTo}
                    unique
                  />
                </Grid>
              </Grid>
            </MobileFilter>
          )}
        />
        <Media
          query="(min-width: 577px)"
          render={() => (
            <Box pt={3}>
              <UsePagination
                count={
                  historyMoneyData && Math.floor(historyMoneyData?.orders?.length / rowsPerPage)
                }
                currentPage={page}
                onChangePage={handleChangePage}
              />
            </Box>
          )}
        />
        <CustomModal
          open={performModalOpen}
          title={`Вы действительно хотите подписать ${selected.length} ${getDeclensionWordOrder(selected.length)}?`}
          handleClose={handleClosePerformModal}
          callbacks={{
            withOk: handlePerformOk,
            withCancel: () => {
              handleClosePerformModal()
            },
          }}
        />
        <CustomModal
          open={deleteModalOpen}
          title={`Вы действительно хотите удалить ${selected.length} ${getDeclensionWordOrder(selected.length)}?`}
          handleClose={handleCloseDeleteModal}
          callbacks={{
            withOk: handleDeleteOk,
            withCancel: () => {
              handleCloseDeleteModal()
            },
          }}
        />
        <CustomModal
          isNoIcons
          open={finishSignModalOpen}
          title={`${(successSigned && `${successSigned} ${getDeclensionWordOrder(successSigned)} успешно ${getDeclensionWordSign(successSigned)
          } \n`) || ''} ${(failSigned && `${failSigned} ${getDeclensionWordOrder(failSigned)} не ${getDeclensionWordSign(failSigned)}`) || ''
          }`}
          handleClose={handleCloseFinishSignModal}
          callbacks={{
            withClose: () => {
              setFinishSignModalOpen(false)
            },
          }}
        />
        <CustomModal
          isNoIcons
          open={finishDeleteSignModalOpen}
          title={`${(successSigned && `${successSigned} ${getDeclensionWordOrder(successSigned)} успешно ${getDeclensionWordDelete(successSigned)} \n`) || ''
          } ${(failSigned && `${failSigned} ${getDeclensionWordOrder(failSigned)} не ${getDeclensionWordDelete(failSigned)}`) || ''
          }`}
          handleClose={handleCloseFinishSignModal}
          callbacks={{
            withClose: () => {
              setFinishDeleteSignModalOpen(false)
            },
          }}
        />
        <CustomModal
          isNoIcons
          open={finishSignLoaderModalOpen}
          title={<CustomLoader />}
          callbacks={{
            withCancel: () => { },
          }}
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}

MoneyPanel.propTypes = {
  fetchHistoryMoneyStart: PropTypes.func.isRequired,
  fetchHistoryFilterStart: PropTypes.func.isRequired,
  connectedWS: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
})

const mapDispatchToProps = (dispatch) => ({
  fetchHistoryMoneyStart: () => dispatch(fetchHistoryMoneyStartAsync()),
  fetchHistoryFilterStart: (obj) => dispatch(fetchHistoryMoneyFilterStartAsync({ obj })),
})

export default connect(mapStateToProps, mapDispatchToProps)(MoneyPanel)

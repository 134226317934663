import useMedia from 'hooks/useMedia'
import React, { FC, useState } from 'react'
import { Tab, Tabs } from 'ui/components/molecules'
import { Languages, LANGUAGES_LIST } from 'ui/components/pages/Profile/types'
import { theme } from 'ui/theme'

export interface useSwitchLanguageTabsProps {
  /**
   * Начальный активный язык.
   *
   * @default 'RU'
   *
   * @example
   * initialLanguage: 'EN'
   */
  initialLanguage?: Languages
}

/**
 * Хук для повторяющейся вкладки переключения языков в форме "Профиля".
 */
export const useSwitchLanguageTabs = ({
  initialLanguage = LANGUAGES_LIST.RU,
}: useSwitchLanguageTabsProps) => {
  const [activeTab, setActiveTab] = useState<Languages>(initialLanguage)
  const languageList = Object.keys(LANGUAGES_LIST)

  const TabBar: FC = () => {
    const { isMobile } = useMedia()

    return (
      <Tabs {...(!isMobile && { style: { width: 'fit-content' } })}>
        {languageList.map((languageKey) => (
          <Tab
            key={LANGUAGES_LIST[languageKey]}
            label={LANGUAGES_LIST[languageKey]}
            active={activeTab === LANGUAGES_LIST[languageKey]}
            onClick={() => setActiveTab(LANGUAGES_LIST[languageKey])}
            font={theme.font.body1}
          />
        ))}
      </Tabs>
    )
  }

  return {
    /**
     * Табы переключения языка.
     */
    TabBar,

    /**
     * Текущий активный язык.
     */
    activeTab,

    /**
     * Установка нового активного языка.
     */
    setActiveTab,
  }
}

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  MessageForm__form: {
    paddingTop: 48,
    '@media(max-width: 576px)': {
      paddingTop: 4,
    },
  },
  MessageForm__inputGroup: {
    display: 'grid',
    gridGap: 33,
  },
  MessageForm__selectWrapper: {
    '& .MuiInputBase-multiline': {
      '& .MuiInputBase-inputMultiline': {
        minHeight: 96,
      },
    },
  },
  MessageForm__messageTimer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  MessageForm__secretCodeForm: {
    marginBottom: 16,
  },
  MessageForm__codeWrapper: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    display: 'flex',
    paddingTop: 20,
    alignItems: 'flex-end',
    position: 'relative',
    left: 15,
    '@media (max-width: 576px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        paddingBottom: 10,
        paddingTop: 10,
        position: 'initial',
      },
    },
    '& > .MuiButton-root': {
      minHeight: 'auto',
      width: 'auto',
      paddingTop: 1,
      paddingBottom: 1,
      fontSize: 14,
      '&:hover': {
        border: '1px solid #91B1BF',
        color: '#91B1BF',
        backgroundColor: '#E6EDEF',
      },
    },
  },
  MessageForm__codeText: {
    fontSize: 12,
    color: '#91B1BF',
    paddingBottom: 5,
  },
  MessageForm__codeInput: {
    paddingLeft: 5,
  },
  MessageForm__blockFields: {
    '@media (min-width: 577px)': {
      paddingBottom: 25,
    },
  },
  MessageForm__marginBottom: {
    marginBottom: 5,
  },
  MessageForm__status: {
    fontSize: 15,
    color: '#292D3499',
    fontWeight: 'bold',
  },
  MessageForm__btnGroup: {
    display: 'flex',
    paddingTop: 23,
    '@media(max-width: 576px)': {
      left: 46,
      width: 'calc(100% - 46px)',
      bottom: 0,
      padding: '0 20px',
      zIndex: 1,
      position: 'fixed',
      boxSizing: 'border-box',
      minHeight: 48,
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    },
  },
  tableRow: {
    borderRight: '6px solid transparent',
    cursor: 'pointer',
    '&.MuiTableRow-root:not(.MuiTableRow-head)': {
      '&:hover': {
        borderRightColor: '#FBC15E',
        backgroundColor: '#91B1BF1A',
      },
    },
    '&.MuiTableRow-head': {
      cursor: 'initial',
    },
    '&.MuiTableRow-head > .MuiTableCell-root': {
      fontSize: 14,
    },
    '& > .MuiTableCell-root': {
      padding: '16px 12px 17px',
      borderBottomColor: theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78',
      borderBottomStyle: 'dashed',
      fontSize: 16,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 2,
      },
      '& button': {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& svg': {
        fill: '#91B1BF',
      },
    },
  },
  tableHeaderCell: {
    color: '#91B1BF',
  },
  History__BoxWrapper: {
    '@media (max-width: 576px)': {
      marginTop: 10,
    },
  },
  PhoneHeader: {
    fontSize: 14,
    fontWeight: 600,
    color: '#91B1BF',
  },
  PhoneFiltersPanel: {
    height: '100%',
    backgroundColor: '#f4f8f9',
  },
  PhoneFiltersPanel__header: {
    fontSize: 16,
    padding: '5px 23px',
  },
  PhoneGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.5fr',
    gridGap: '3px 9px',
    paddingTop: 6,
    paddingBottom: 12,
    borderBottom: '0.5px dashed #91B1BF',
    '&:first-child': {
      borderTop: '0.5px dashed #91B1BF',
    },
  },
  PhoneMessage__dateInfo: {
    display: 'flex',
    '& > div ': {
      paddingRight: 38,
      marginBottom: 6,
      '& > p': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
  PhoneGrid__item_fullColumn: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  PhoneGrid__header_s: {
    lineHeight: '16px',
    color: '#91B1BF',
    fontSize: 12,
  },
  PhoneGrid__header_m: {
    lineHeight: '16px',
    color: '#91B1BF',
    fontSize: 14,
  },
  PhoneGrid__value_medium: {
    fontWeight: 600,
  },
  PhoneGrid__value: {
    margin: 0,
    fontSize: 14,
    lineHeight: '19px',
  },
  PhoneGrid__status: {
    color: '#292D34',
    fontSize: 15,
    lineHeight: 1,
  },
  PhoneGrid__statusText: {
    fontWeight: 400,
    marginTop: 5,
    marginBottom: 10,
    fontSize: 14,
  },
}))

export default useStyles

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Media from 'react-media'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import NonActiveUserIcon from '../../assets/images/user-icon-light.svg'
import ActiveUserIcon from '../../assets/images/user-icon-dark.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import PageHeading from '../PageHeading/PageHeading'

const useStyles = makeStyles({
  boxTar: {
    cursor: 'pointer',
    '@media(max-width: 576px)': {
      '& .MuiTypography-root': {
        fontSize: 14,
      },
    },
  },
  avatar: {
    width: 23,
    height: 23,
  },
})

export default function CollapseProfileWithContent({ contentArray = [] }) {
  const classes = useStyles()
  const [current, setCurrent] = useState('')
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location?.state?.activeBeneficiary) {
      const beneficiarKey = location?.state?.activeBeneficiary
      setCurrent(current === beneficiarKey ? '' : beneficiarKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.activeBeneficiary])

  const handleToggleCollapse = (key) => {
    setCurrent(current === key ? '' : key)
  }

  const handleOpenRequest = (id) => {
    const nextLocation = {
      pathname: '/profile/additionalInfo/beneficiaries',
      state: { activeBeneficiary: id[0] },
    }
    history.push(nextLocation)
  }

  const userIcon = (key) => (current === key && ActiveUserIcon) || NonActiveUserIcon
  const currentArrow = (key) => (current === key && <KeyboardArrowDownIcon />)
    || <KeyboardArrowUpIcon />

  // console.log('contentArray', contentArray)

  return (
    <Box>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Grid
              container
              spacing={2}
            >
              {contentArray.map(({ key, title }) => (
                <Grid
                  key={key}
                  item
                  container
                  md={3}
                  xs={8}
                  className={classes.boxTar}
                  spacing={1}
                  alignItems="center"
                  onClick={() => handleToggleCollapse(key)}
                >
                  <Grid item>
                    <Avatar
                      alt="avatar"
                      src={userIcon(key)}
                      className={classes.avatar}
                    />
                  </Grid>
                  <Grid item>
                    <Typography>{title}</Typography>
                  </Grid>
                  <Media
                    query="(min-width: 577px)"
                    render={() => (
                      <Grid item>{currentArrow(key)}</Grid>
                    )}
                  />
                </Grid>
              ))}
            </Grid>

            {contentArray.map(({ key, Component, data }) => (
              <Collapse
                key={key}
                in={current === key}
                timeout="auto"
                unmountOnExit
              >
                <Component data={data} />
              </Collapse>
            ))}
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            {!location?.state?.activeBeneficiary && (
              <Grid
                container
                spacing={2}
              >
                {contentArray.map(({ key, title }) => (
                  <Grid
                    key={key}
                    item
                    container
                    md={3}
                    xs={8}
                    className={classes.boxTar}
                    spacing={1}
                    alignItems="center"
                    onClick={() => handleOpenRequest(key)}
                  >
                    <Grid item>
                      <Avatar
                        alt="avatar"
                        src={userIcon(key)}
                        className={classes.avatar}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>{title}</Typography>
                    </Grid>
                    <Media
                      query="(min-width: 577px)"
                      render={() => (
                        <Grid item>{currentArrow(key)}</Grid>
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {contentArray.map(({ key, Component, data }) => (
              <Collapse
                key={key}
                in={current === key[0]}
                timeout="auto"
                unmountOnExit
              >
                <PageHeading text="Бенефициары" />
                <Component data={data} />
              </Collapse>
            ))}
          </>
        )}
      />
    </Box>
  )
}

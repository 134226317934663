import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CertificateComponent from './certificateComponent'
import getCertificates from '../../utils/getCertificates'

function Certificates({
  currentUser, stepVisibility, thumbPrints, isPasswordRecovery, setStepArray,
  arraySteps, setArraySteps,
}) {
  const [certificates, setCertificates] = useState(null)
  useEffect(() => {
    if (currentUser === null && stepVisibility.sign) {
      getCertificates({ thumbPrints })
        .then((userCertificates) => setCertificates(userCertificates))
      // eslint-disable-next-line no-console
        .catch((error) => console.error(error))
    }
  }, [currentUser, stepVisibility, thumbPrints])

  if (currentUser !== null) {
    return null
  }

  return (
    <CertificateComponent
      certificates={certificates || ''}
      isPasswordRecovery={isPasswordRecovery}
      setStepArray={setStepArray}
      arraySteps={arraySteps}
      setArraySteps={setArraySteps}
    />
  )
}

Certificates.defaultProps = {
  currentUser: PropTypes.object,
  isPasswordRecovery: false,
  thumbPrints: PropTypes.array,
}

Certificates.propTypes = {
  currentUser: PropTypes.oneOfType([PropTypes.object]),
  stepVisibility: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  thumbPrints: PropTypes.array,
  isPasswordRecovery: PropTypes.bool,
  setStepArray: PropTypes.func.isRequired,
  arraySteps: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setArraySteps: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user: { currentUser, thumbPrints } }) => ({
  currentUser,
  thumbPrints,
})

export default connect(mapStateToProps, null)(Certificates)

export const nextBar = (mapBars, currentBar, needResultInBool) => {
  const currentIndex = mapBars?.findIndex(([name]) => name === currentBar)
  const bar = mapBars?.slice(currentIndex + 1).find(([, , hasValue]) => hasValue)
  const barName = bar && bar[1]

  return needResultInBool ? !!barName : barName
}

export const expandedToggle = ({
  expanded, setExpanded, newExpanded, newPanel, needNextPanel, mapBar,
}) => {
  let currentPanel = newPanel
  if (needNextPanel) {
    currentPanel = nextBar(mapBar, currentPanel, false)
  }
  setExpanded(!expanded || expanded !== currentPanel || newExpanded ? currentPanel : false)
  window.scrollTo(0, 0)
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, Modal } from '@material-ui/core'
import classnames from 'classnames'
import ListItemText from '@material-ui/core/ListItemText'
import useStyles from './CustomModal.style'

const CustomModal = ({
  errorModal, title, textList, open, handleClose,
  callbacks, actionText, actionReject, actionSuccess, infinity, disableOverlay,
}) => {
  const classes = useStyles()
  const withOk = callbacks?.withOk
  const withCancel = callbacks?.withCancel
  const withClose = callbacks?.withClose

  let interval

  const startInterval = () => {
    interval = setInterval(() => {
      handleClose()
    }, 3000)
  }

  useEffect(() => {
    if (open && !callbacks && !actionText && !infinity) {
      startInterval()
    }
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [open])

  return (
    <Modal
      className={classes.Modal}
      open={open}
      onClose={handleClose}
      disableBackdropClick={!!withOk || !!withCancel || disableOverlay}
    >
      <div className={classnames(
        classes.Modal__inner,
        {
          [classes.Modal__inner_unique]: actionText,
          [classes.Modal__inner_error]: errorModal,
        },
      )}
      >
        <div className={
        classnames(
          classes.Modal__content,
          {
            [classes.Modal__content_unique]: (withOk && withCancel),
          },
        )
      }
        >
          <div className={classes.Modal__infoWrapper}>
            <p className={classes.Modal__title}>
              {title}
            </p>
            {textList?.length ? (
              <List className={classes.Modal__list}>
                {textList.map((item, index) => (
                  <ListItem
                    key={`${item}${String(index)}`}
                  >
                    <ListItemText>
                      {item}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
                ) : null}
          </div>
        </div>
        {(callbacks || actionText) && (
          <div className={classes.Modal__actions}>
            {actionText && (
              <p className={classes.Modal__actionsText}>{actionText}</p>
            )}
            <div className={classes.Modal__actionsButtonsWrapper}>
              {(withOk && withCancel) && (
                <>
                  <button
                    type="button"
                    onClick={withOk}
                  >
                    Да
                  </button>
                  <button
                    type="button"
                    onClick={withCancel}
                  >
                    Нет
                  </button>
                </>
              )}
              {withClose && (
                <button
                  type="button"
                  onClick={withClose}
                >
                  Ок
                </button>
              )}
              {actionText && (
                <>
                  <button
                    type="button"
                    onClick={actionReject}
                  >
                    Нет
                  </button>
                  <button
                    type="button"
                    onClick={actionSuccess}
                  >
                    Да
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

CustomModal.defaultProps = {
  errorModal: false,
  textList: [],
  callbacks: null,
  actionText: null,
  actionReject: null,
  actionSuccess: null,
  infinity: false,
  disableOverlay: false,
  handleClose: PropTypes.func,
}

CustomModal.propTypes = {
  errorModal: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  textList: PropTypes.oneOfType([PropTypes.array]),
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  callbacks: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  actionText: PropTypes.string,
  actionReject: PropTypes.func,
  actionSuccess: PropTypes.func,
  infinity: PropTypes.bool,
  disableOverlay: PropTypes.bool,
}

export default CustomModal

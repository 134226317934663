import { styled } from "ui/theme";
import { Link, NavLink } from "react-router-dom";
import { ISidebarItemProps, ISidebarProps } from "./types";
import { Icon } from 'ui/components';

export const StyledSidebar = styled.div<Partial<ISidebarProps>>`
  background: ${({ theme }) => theme.color.bg.accent};
  padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.lg} ${theme.spacing.xl}`};
  height: 100vh;
  width: 200px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
  ${({ theme, shown }) => `
    @media ${theme.breakpoint.xs} {
      transition: ${theme.transition.common};
      padding: ${theme.spacing.xl} 16px;
      transform: translateX(${shown ? 0 : '-100%'});
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
      gap: 32px;
  `}
`

export const StyledSidebarHeader = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledSidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  grid-gap: 28px;
  flex: 1;
  ${({ theme }) => `
    @media ${theme.breakpoint.xs} {
      padding: 28px 0 0;
    }
  `}
`

export const StyledSidebarItem = styled(NavLink) <ISidebarItemProps>`
  color: ${({ theme }) => theme.color.text.secondary};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  text-decoration: none;
  flex: 1;
  &:hover, &.active {
    color: ${({ theme }) => theme.color.text.inverted};
  }
  ${({ theme }) => `
    @media ${theme.breakpoint.xs} {
      margin: 0 ${theme.spacing.lg} 0;
    }
  `}
`

export const StyledSidebarItemText = styled.span`
${({ theme }) => theme.font.body1_lg};
`

export const StyledSidebarLogo = styled(Icon)`
  color: ${({ theme }) => theme.color.bg.primary};
  margin-bottom: ${({ theme }) => theme.spacing.xxxl};
  flex-shrink: 0;
  svg {
    display: block;
  }
  ${({ theme }) => `
    @media ${theme.breakpoint.xs} {
      width: 140px;
      margin-bottom: 0;
  `}

`

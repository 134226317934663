import { styled } from 'ui/theme'

export const BankRequisitesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;

  padding: 32px;

  box-shadow: 0 0 20px 2px rgba(49, 58, 78, 0.06);
  border-radius: 8px;

  @media ${({ theme }) => theme.breakpoint.xxs} {
    padding: 20px;
  }
`

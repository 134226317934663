import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import {
  AccordionContainer,
  Chevron,
  Currency,
  Data,
  DataArea,
  DataWrapper,
  Title,
  WrapperBody,
  WrapperBodyContent,
  WrapperHeading,
} from './styled'
import { Badge, Icon } from 'ui/components'
import { AccordionProps, ACCORDION_VARIANTS_LIST } from './type'

import { accrodionBodyPaddingTopDefault, formatCurrency, getState, getStringState } from './data'
import getPrettyNumber from 'utils/GetPrettyNumber'
import { getCorrectPercentValue } from 'ui/components/pages/Portfolio/components/PortfolioTables/data'

const Accordion: FCC<AccordionProps> = ({
  children,
  isOpen,
  title = '',
  sum = 0,
  dayDiff = 0,
  dayDiffPercent = 0,
  share = 0,
  currency = 'RUB',
  variant = ACCORDION_VARIANTS_LIST.PORTFOLIO,
  onHandleClick,
  padding,
  withShadow = true,
  bodyPaddingTop = accrodionBodyPaddingTopDefault,
  isOpenDefault,
  titleType = 'standart',
  customTitle,
  titleMaxWidth,
  titleFontType,
  chevronPosition = 'standard'
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpenDefault || isOpen || false)
  const [maxContentHeight, setMaxContentHeight] = useState(0)
  const [isAnimated, setIsAnimated] = useState(true)

  const wrapperBodyContentRef = useRef<HTMLDivElement | null>(null)

  const toggleByHandler = useCallback(
    (value: boolean) => {
      setIsAnimated(true)

      onHandleClick ? onHandleClick(value) : setIsAccordionOpen(value)
    },
    [onHandleClick]
  )

  const toggleSateHandler = useCallback(() => {
    toggleByHandler(!isAccordionOpen)
  }, [toggleByHandler, isAccordionOpen])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((data) => {
      const newHeight = data[0]?.contentRect.height + bodyPaddingTop

      setMaxContentHeight(newHeight)
    })

    if (wrapperBodyContentRef.current) resizeObserver.observe(wrapperBodyContentRef.current)

    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      setIsAnimated(true)

      setIsAccordionOpen(isOpen)
    }
  }, [isOpen])

  const onBodyAnimatedEndHandler = useCallback(() => setIsAnimated(false), [])

  return (
    <AccordionContainer withShadow={withShadow} padding={padding}>
      {(title || customTitle) && (
        <WrapperHeading type={titleType} onClick={toggleSateHandler}>
          {customTitle || (
            <Title fontType={titleFontType} maxWidth={titleMaxWidth} type={titleType}>
              {title}
            </Title>
          )}

          <DataArea>
            {variant === ACCORDION_VARIANTS_LIST.PORTFOLIO && (
              <DataWrapper>
                <Data>
                  {formatCurrency(sum)} {currency}
                </Data>

                <Data isPositive={getState(dayDiff)}>
                  {' '}
                  {getPrettyNumber(dayDiff)} <Currency>₽</Currency>
                  {
                    <Badge
                      value={`${getCorrectPercentValue(dayDiffPercent)}%`}
                      variant={getStringState(dayDiff)}
                      icon={getStringState(dayDiffPercent)}
                    />
                  }
                </Data>

                <Data>{getCorrectPercentValue(share)}%</Data>
              </DataWrapper>
            )}

            <Chevron {...(maxContentHeight && { isOpen: isAccordionOpen, chevronPosition: chevronPosition })}>
              <Icon name="arrow-down" size="sm" />
            </Chevron>
          </DataArea>
        </WrapperHeading>
      )}

      <WrapperBody
        onTransitionEnd={onBodyAnimatedEndHandler}
        style={{
          maxHeight: isAccordionOpen ? maxContentHeight : 0,
        }}
        isOpen={isAccordionOpen}
        isAnimated={isAnimated}
      >
        <WrapperBodyContent
          paddingTop={bodyPaddingTop}
          ref={wrapperBodyContentRef}
          variant={variant}
        >
          {children}
        </WrapperBodyContent>
      </WrapperBody>
    </AccordionContainer>
  )
}

export default Accordion
export { Accordion }

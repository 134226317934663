import { useSizeContext } from 'context/SizeContext';
import React from 'react';
import { useTheme } from 'ui/theme';
import {
  StyledTable,
  StyledTableHeader,
  StyledTableHeaderCell,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableBodyCell,
  StyledTableMobileRow,
  StyledTableMobileCell,
  StyledCheckBoxWrapper,
} from './styled';
import { DataTableColumnData, IDataTableProps } from './types';
import { Checkbox } from "../../atoms";

export function DataTable<T extends DataTableColumnData>({
  columns = [],
  rows = [],
  layout = [],
  onRowClick = (row) => null,
  checkbox
}: IDataTableProps<T>) {
  const size = useSizeContext();
  const theme = useTheme();
  const isMobile = theme.bp.isXS(size.width);
  const isTablet = theme.bp.isSM(size.width);
  const colsLeft = [...columns.slice(0, Math.round(columns.length / 2))];
  const colsRight = [...columns.slice(Math.round(columns.length / 2))];
  const renderCell = (row, column) => (
    <StyledTableBodyCell key={column.id}>
      {
        (isMobile || isTablet) ?
          <StyledTableMobileRow>
            <StyledTableHeaderCell>
              {column.mobileTitle || column.title}
            </StyledTableHeaderCell>
            <StyledTableMobileCell>
              {column.render ? column.render(row) : row[column.id]}
            </StyledTableMobileCell>
          </StyledTableMobileRow> :
          <>{column.render ? column.render(row) : row[column.id]}</>
      }
    </StyledTableBodyCell>
  );
  return <StyledTable>
    {
      !(isMobile || isTablet) &&
      <StyledTableHeader width={layout}>
        {
          columns.map(column => <StyledTableHeaderCell key={column.id}>{column.title}</StyledTableHeaderCell>)
        }
      </StyledTableHeader>
    }
    <StyledTableBody>
      {
        rows.map(row =>
          <StyledTableBodyRow
            key={row.id}
            width={[
              ...(checkbox?.isVisible ? ['4%'] : []),
              ...layout
            ]}
            onClick={() => onRowClick(row)}
          >
            {checkbox?.isVisible && (
              <StyledCheckBoxWrapper
                onClick={(event) => event.stopPropagation()}
              >
                <Checkbox
                  checked={checkbox?.checked(row)}
                  disabled={checkbox?.disabled(row)}
                  onChange={() => checkbox?.onChange(row)}
                  size={checkbox?.size}
                />
              </StyledCheckBoxWrapper>
            )}
            {
              (isMobile || isTablet) ?
                <>
                  <div>
                    {
                      colsLeft.map((col) => renderCell(row, col))
                    }
                  </div>
                  <div>
                    {
                      colsRight.map((col) => renderCell(row, col))
                    }
                  </div>
                </> :
                <>
                  {
                    colsLeft.map((col) => renderCell(row, col))
                  }
                  {
                    colsRight.map((col) => renderCell(row, col))
                  }
                </>
            }
          </StyledTableBodyRow>
        )
      }
    </StyledTableBody>
  </StyledTable>
}

export * from './types';

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import Box from '@material-ui/core/Box'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Media from 'react-media'
import classnames from 'classnames'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'
import endpoints from '../../../api/endpoints'
import { rowsPerPage } from '../../../config/constants'

import useConnect from '../../../utils/Connect'

import useStyles from './Documents.styles'
import UsePagination from '../../Pagination/Pagination'
import PageHeading from '../../PageHeading/PageHeading'

import {
  fetchDocumentsStartAsync,
  getDocumentsSuccess,
} from '../../../redux/documents/brokerageServices/documentsBrokerageServicesSlece'
import { selectGetDocuments } from '../../../redux/documents/brokerageServices/selectop'

const DocumentsTable = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const getFormatDate = (date, formatDate) => ((!!date || !!formatDate) ? format(new Date(date), formatDate) : '')

  const [page, setPage] = useState(0)

  const fetchDocumentsStart = () => {
    dispatch(fetchDocumentsStartAsync())
  }

  const { getData: documents } = useConnect({
    link: `GET /api${endpoints.documents.getExchangeRequests}`,
    startFetching: fetchDocumentsStart,
    action: getDocumentsSuccess,
  })

  const getRespDocuments = useSelector(selectGetDocuments)

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleOpenRequest = (id, document) => () => {
    let nextLocation = ''
    if (document) {
      nextLocation = {
        pathname: `/documents/brokerageServices/document/${id}`,
      }
    }
    history.push(nextLocation)
  }

  useEffect(() => {
    documents()
  }, [])

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <PageHeading text="Документы" />
      <Box className={classes.MoneyPanel__BoxWrapper}>
        <Media
          query="(min-width: 577px)"
          render={() => (
            <>
              <div>
                <TableContainer
                  className={classes.tableContainer}
                  style={{ paddingTop: 9 }}
                >
                  <Table aria-label="assetsMoney table">
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={classes.tableHeaderCell}>Дата отправления</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Номер</TableCell>
                        <TableCell
                          className={classes.tableHeaderCell}
                          align="center"
                        >
                          Тип документа&nbsp;
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell}>Статус &nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(getRespDocuments?.length ? getRespDocuments : [])?.slice(
                        ((page) * (rowsPerPage)), ((page + 1) * (rowsPerPage)),
                      )?.map((row) => {
                        const formatDate = getFormatDate(row?.submitted, "dd.MM.yyyy'T'HH:mm")?.replace('T', ' ')
                        const isItemSelected = ''
                        return (
                          <TableRow
                            className={classes.tableRow}
                            hover
                            aria-checked={isItemSelected}
                            key={row.id}
                            onClick={handleOpenRequest(row.id, row)}
                            selected={isItemSelected}
                            style={{ height: 80 }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableBodyCell}
                              style={{ minWidth: 200 }}
                            >
                              {formatDate}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyCell}
                              style={{ minWidth: 125 }}
                            >
                              {row?.id || ''}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyCell}
                              style={{ minWidth: 150 }}
                            >
                              {row?.type?.name || ''}
                            </TableCell>
                            <TableCell
                              className={classes.tableBodyCell}
                              style={{ minWidth: 150 }}
                            >
                              {row?.status?.name || ''}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          )}
        />

        <Media
          query={`(max-width: ${breakpoints.phone_large}px)`}
          render={() => getRespDocuments?.map((row) => (
            <div
              className={classes.PhoneGrid}
              key={row.id}
              onClick={handleOpenRequest(row.id, row)}
              aria-hidden="true"
            >
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Дата отправления
                </span>
                <p className={
                  classnames(classes.PhoneGrid__value, classes.PhoneGrid__value_medium)
                }
                >
                  {getFormatDate(row?.submitted, "dd.MM.yyyy'T'HH:mm").replace('T', ' ')}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Номер
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.id || ''}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Тип документа
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.type?.name || ''}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Статус
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.status?.name || ''}
                </p>
              </div>
            </div>
          )) || null}
        />

        <Media
          query="(min-width: 577px)"
          render={() => (
            <Box pt={3}>
              <UsePagination
                count={
                  getRespDocuments && Math.floor(getRespDocuments?.length / rowsPerPage)
                }
                currentPage={page}
                onChangePage={handleChangePage}
              />
            </Box>
          )}
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}

export default DocumentsTable

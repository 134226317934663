import React, { memo } from 'react'
import { Row } from '.'

import {
  StyledCollapsibleTable,
  StyledCollapsibleTableHeader,
  StyledCollapsibleTableHeaderCell,
  StyledCollapsibleTableBody,
} from './styled'
import { CollapsibleTableColumnData, ICollapsibleTableProps } from './types'

const CollapsibleTable = <T extends CollapsibleTableColumnData>({
  columns = [],
  rows = [],
  layout = [],
  areOpenedRows,
  accordName,
}: ICollapsibleTableProps<T>) => {

  return (
    <StyledCollapsibleTable>
      <StyledCollapsibleTableHeader width={layout}>

        {columns.map((column) => (
          <StyledCollapsibleTableHeaderCell key={column.id}>
            {typeof column.title === 'function' ? column.title(rows) : column.title}
          </StyledCollapsibleTableHeaderCell>
        ))}

      </StyledCollapsibleTableHeader>


      <StyledCollapsibleTableBody>
        {rows.map((row, index) => {
          return <Row<T>
            rowIndex={index}
            columns={columns}
            layout={layout}
            row={row}
            key={row.id}
            rows={rows}
            isOpenExternal={areOpenedRows}
            accordName={accordName}
          />
        })}
      </StyledCollapsibleTableBody>


    </StyledCollapsibleTable>
  )
}

export default memo(CollapsibleTable) as typeof CollapsibleTable

import React from 'react'
import PageHeading from '../../components/PageHeading/PageHeading'
import ContactsInfo from '../../components/ContactsInfo/ContactsInfo'

export default function Contacts() {
  return (
    <>
      <PageHeading text="Контакты" />
      <ContactsInfo />
    </>
  )
}

import React, { useState } from 'react';
import { Tab, Tabs } from 'ui/components';
import { Depo } from './Depo';
import { StyledContent, StyledTabsWrapper, StyledWrapper } from './styled';
import { Money } from './Money';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

export const Requisites: React.FC = () => {
  const { url, path } = useRouteMatch()
  return <StyledWrapper>
    <StyledTabsWrapper>
      <Tabs variant={2}>
        <Tab
          label="Реквизиты для зачисления ДС"
          to={`${url}/moneyRequisites`}
          component={NavLink}
        />
        <Tab
          label={'Реквизиты депозитария'}
          to={`${url}/depoRequisites`}
          component={NavLink}
        />

      </Tabs>
    </StyledTabsWrapper>
    <StyledContent>
      <Switch>
        <Route
          exact
          path={path}
        >
          <Redirect to={`${path}/moneyRequisites`} />
        </Route>
        <Route
          exact
          path={`${path}/moneyRequisites`}
          component={Money}
        />
        <Route
          exact
          path={`${path}/depoRequisites/`}
          component={Depo}
        />
      </Switch>
    </StyledContent>
  </StyledWrapper>
}
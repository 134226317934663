import endpoints from 'api/endpoints';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConnectionState } from 'redux/connection/selector';
import { fetchAgreementsDepoStartAsync, fetchDepoRequisitesFilterStartAsync, getAgreementsDepoSuccess, getDepoRequisitesFilterSuccess } from 'redux/investmentsNotices/investmentsNoticesSlice';
import { selectAgreementsDepo, selectDepoRequisites } from 'redux/investmentsNotices/selector';
import { Form, FormRow, Text, Field, Select, BlockHeading, Icon } from 'ui/components';
import useConnect from 'utils/Connect';
import createUri from 'utils/FilterUri';
import { StyledAgreementField, StyledContent, StyledItem, StyledReqBlock, StyledReqBlockContent, StyledReqBlockCopy } from './styled';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

export const Depo: React.FC = () => {
  const dispatch = useDispatch()
  const [contract, setContract] = useState(null)

  const connectedWS = useSelector(selectConnectionState)
  const agreementsDepo = useSelector(selectAgreementsDepo)
  const depoRequisites = useSelector(selectDepoRequisites) ?? []

  const fetchAgreementsDepoStart = () => {
    dispatch(fetchAgreementsDepoStartAsync())
  }

  const fetchDepoRequisitesFilterStart = (filterBody) => {
    dispatch(fetchDepoRequisitesFilterStartAsync({ filterBody }))
  }

  const {
    getData: getAgreementsDepo,
  } = useConnect({
    link: `GET /api${endpoints.investmentsNotices.getAgreementsDepo}`,
    startFetching: fetchAgreementsDepoStart,
    action: getAgreementsDepoSuccess,
  })

  const {
    getData: getDepoRequisitesFilter,
  } = useConnect({
    link: `GET /api${endpoints.investmentsNotices.depoRequisitesFilter()}`,
    startFetching: fetchDepoRequisitesFilterStart,
    action: getDepoRequisitesFilterSuccess,
  })

  const clearData = () => {
    setContract(null)
    dispatch(getDepoRequisitesFilterSuccess(''))
  }

  const handleAcceptFilter = () => {
    if (contract) {
      const filterBodyClient = createUri({
        name: 'depoRequisites',
        agreementId: contract,
      })
      getDepoRequisitesFilter(filterBodyClient)
    }
  }

  useEffect(() => {
    clearData()
    if (connectedWS) {
      getAgreementsDepo()
    }
    // eslint-disable-next-line
  }, [connectedWS])

  useEffect(() => {
    if (contract) {
      // eslint-disable-next-line
      handleAcceptFilter()
    } else {
      dispatch(getDepoRequisitesFilterSuccess(''))
    }
    // eslint-disable-next-line
  }, [contract])

  useEffect(() => {
    if (agreementsDepo && !agreementsDepo[1]) {
      setContract(agreementsDepo[0]?.id)
      handleAcceptFilter()
    }
    // eslint-disable-next-line
  }, [agreementsDepo])

  const handleChangeContract = (option) => {
    setContract(option?.value)
  }

  const content = React.useRef(null)
  const agreements = agreementsDepo?.map((item) => ({ value: item?.id, label: item?.number })) ?? []

  return <>
    <Form>
      <StyledAgreementField>
        <Field label="Договор">
          <Select
            options={agreements}
            value={agreements.find(a => a.value === contract)}
            onChange={handleChangeContract}
            placeholder=""
          />
        </Field>
      </StyledAgreementField>
    </Form>
    {
      !!depoRequisites.length &&
      <StyledItem>
        <BlockHeading title={'Депозитарные реквизиты'} />
        <StyledReqBlock>
          <StyledReqBlockContent ref={content}>
            {depoRequisites.map((item, key) => (
              <Text
                color={'secondary'}
                key={item + key.toString()}
              >
                <strong>
                  {item?.caption}
                </strong>
                {item?.text}
              </Text>
            ))}
          </StyledReqBlockContent>
          <StyledReqBlockCopy onClick={() => {
            copy(content.current?.innerText);
            toast('Скопировано в буфер обмена');
          }}>
            <Icon name="copy" size={'md'} />
          </StyledReqBlockCopy>
        </StyledReqBlock>
      </StyledItem>
    }
  </>
}

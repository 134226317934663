import React from 'react'
import { ThemeProvider } from "styled-components";
import {CardsLayout, Description, SubTitle} from "./styled";
import { useSize } from "hooks/useSize";
import { SizeContext } from "context/SizeContext";
import { GlobalStyle, theme } from "ui/theme";
import { MainLayout } from "ui/components/templates/MainLayout";
import { ContactCard } from "ui/components/pages/Contacts/ContactCard";
import { contactsData } from './data'

import type { FC } from "react";

const ContactsPage: FC = () => {
  const MAIN_PAGE_URL = "https://region.broker/documents/disclosure/informatsiya-o-kompanii/1informatsiya-predostavlyaemaya-v-sootvetstvii-s-trebovaniyami-bazovykh-standartov-sro/"
  return (
    <MainLayout title="Контакты">

      <SubTitle>Брокерская компания РЕГИОН</SubTitle>

      <CardsLayout>
        <>
          {
            contactsData.map(({title, fields}) => (
              <ContactCard
                key={title}
                title={title}
                fields={fields}
              />
            ))
          }

          <ContactCard
            title="Раскрытие информации"
          >
            <Description>
              Информация, предоставляемая в соответствии с требованиями Базовых стандартов СРО, на сайте <a target="_blank" rel="noopener noreferrer" href={ MAIN_PAGE_URL }>ООО «БК РЕГИОН»</a>
            </Description>
          </ContactCard>
        </>
      </CardsLayout>

    </MainLayout>
  )
}

const Contacts: FC = () => {
  const size = useSize();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <SizeContext.Provider value={size}>
        <ContactsPage />
      </SizeContext.Provider>

    </ThemeProvider>
  )
}

export { Contacts }

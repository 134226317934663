import { Link } from 'react-router-dom';
import { styled } from "ui/theme";


export const StyledClientButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  gap: ${({ theme }) => theme.spacing.sm};
  svg {
    display: block;
    position: relative;
    z-index: 1;
  }
  @media ${({ theme }) => theme.breakpoint.xs} {
    color: ${({ theme }) => theme.color.text.inverted};
  }
`


export const StyledClientButtonText = styled.span`
`

export const StyledClientPopover = styled.div`
  min-width: 308px;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background: ${({ theme }) => theme.color.bg.primary};
  padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.lg}`};
  ${({ theme }) => theme.font.body1};
  display: flex;
  flex-direction: column;
  grid-gap: 28px;
  flex: 1;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
`


export const StyledClientItem = styled(Link)`
  color: ${({ theme }) => theme.color.text.primary};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.color.text.secondary};
  }
`

export const StyledClientItemText = styled.span`
  position: relative;
${({ theme }) => theme.font.body1};

  & > span {
    display: flex;
    width: 33px;
    height: 24px;

    position: absolute;
    right: -35px;
    bottom: 3px;

    color: #2C96DA;
    background: #F4F6FC;
    border: 1px solid #2C96DA;
    border-radius: 8px;

    justify-content: center;
    align-items: center;
  }

`

import React from 'react'
import PropTypes from 'prop-types'
import cuid from 'cuid'
import Media from 'react-media'
import classnames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Collapse from '@material-ui/core/Collapse'
import TableContainer from '@material-ui/core/TableContainer'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import useStyles from './sharedStyles'
import CustomInput from '../../CustomControls/CustomInput'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

const Row = ({
  licenceInfoContent: {
    agentName,
    currency,
    bankAccount,
    bankName,
    rubPayment,
    ground,
    correspondentAccount,
    bankBic,
    correspondentSwift,
    bankSwift,
    correspondentName,
    bankAddress,
  },
}) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const handleRowClick = () => setOpen(!open)

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <TableRow
              className={classes.tableRow}
              hover
              onClick={handleRowClick}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ paddingLeft: 0 }}
              >
                {agentName}
              </TableCell>
              <TableCell>{currency}</TableCell>
              <TableCell>{bankAccount}</TableCell>
              <TableCell>{bankName}</TableCell>
            </TableRow>
            <TableRow>
              {(rubPayment && (
              <TableCell
                colSpan={4}
                className={classnames(classes.nestedCell, { [classes.nestedCell_active]: open })}
              >
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                    >
                      <CustomInput
                        name="bankName"
                        label="Наименование банка получателя"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={bankName}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                    >
                      <CustomInput
                        name="bankAddress"
                        label="Город"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={bankAddress}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={4}
                      xs={12}
                    >
                      <CustomInput
                        name="bankAccount"
                        label="Рассчетный счет"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={bankAccount}
                        disabled
                        unique
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={12}
                    >
                      <CustomInput
                        name="corrAccount"
                        label="Корр. счет"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={correspondentAccount}
                        disabled
                        unique
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={12}
                    >
                      <CustomInput
                        name="bic"
                        label="БИК"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={bankBic}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                    >
                      <CustomInput
                        name="agentName"
                        label="Получатель"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={agentName}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                    >
                      <CustomInput
                        name="ground"
                        label="Назначение платежа"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={ground}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </TableCell>
              )) || (
              <TableCell
                colSpan={4}
                className={classnames(classes.nestedCell, { [classes.nestedCell_active]: open })}
              >
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                >
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                    >
                      <CustomInput
                        name="bankName"
                        label="Наименование банка"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={bankName}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                    >
                      <CustomInput
                        name="bankName"
                        label="Наименование банка получателя"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={bankName}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                    >
                      <CustomInput
                        name="correspondentName"
                        label="Банк-корреспондент"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={correspondentName}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={4}
                      xs={12}
                    >
                      <CustomInput
                        name="bankAccount"
                        label="Счет получателя"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={bankAccount}
                        disabled
                        unique
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={12}
                    >
                      <CustomInput
                        name="currency"
                        label="Валюта"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={currency}
                        disabled
                        unique
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xs={12}
                    >
                      <CustomInput
                        name="bankSwift"
                        label="SWIFT банка получателя"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={bankSwift}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={6}
                      xs={12}
                    >
                      <CustomInput
                        name="corrAccount"
                        label="Счет в банке-корреспонденте"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={correspondentAccount}
                        disabled
                        unique
                      />
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      xs={12}
                    >
                      <CustomInput
                        name="correspondentSwift"
                        label="SWIFT банка-корреспондента"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={correspondentSwift}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className={classes.row}
                  >
                    <Grid
                      item
                      lg={12}
                      xs={12}
                    >
                      <CustomInput
                        name="agentName"
                        label="Получатель платежа"
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={agentName}
                        disabled
                        unique
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </TableCell>
              )}
            </TableRow>
          </>
        )}
      />
    </MuiPickersUtilsProvider>
  )
}

Row.defaultProps = {
  licenceInfoContent: null,
}

Row.propTypes = {
  licenceInfoContent: PropTypes.shape({
    agentName: PropTypes.string,
    currency: PropTypes.string,
    bankAccount: PropTypes.string,
    bankName: PropTypes.string,
    rubPayment: PropTypes.bool,
    ground: PropTypes.string,
    correspondentAccount: PropTypes.string,
    bankBic: PropTypes.string,
    correspondentSwift: PropTypes.string,
    bankSwift: PropTypes.string,
    correspondentName: PropTypes.string,
    bankAddress: PropTypes.string,
  }),
}

const RequisitesContent = ({ data, template }) => {
  const classes = useStyles()
  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <TableContainer className={classes.tableContainer}>
              <Table aria-label="licence table">
                <TableHead>
                  {((data && data?.length) && (
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 0, paddingLeft: 0 }}
                      className={classes.tableHeaderCell}
                    >
                      Получатель платежа
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: 0 }}
                      className={classes.tableHeaderCell}
                    >
                      Валюта
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: 0 }}
                      className={classes.tableHeaderCell}
                    >
                      Счёт получателя&nbsp;
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: 0 }}
                      className={classes.tableHeaderCell}
                    >
                      Наименование банка получателя&nbsp;
                    </TableCell>
                  </TableRow>
                  )) || null}
                </TableHead>
                <TableBody>
                  {data && data?.map((requisite, key) => (
                    template === requisite?.templatePurpose && (
                    <Row
                      key={key.toString + cuid()}
                      gaveDate={new Date(requisite?.issueDate)}
                      licenceInfoContent={{
                        agentName: requisite?.agentName,
                        bankAccount: requisite?.bankAccount,
                        bankName: requisite?.bankName,
                        ground: requisite?.ground,
                        correspondentAccount: requisite?.correspondentAccount,
                        bankBic: requisite?.bankBic,
                        rubPayment: requisite?.currencyUid === 'RUB',
                        currency: requisite?.currencyUid,
                        correspondentSwift: requisite?.correspondentSwift,
                        bankSwift: requisite?.bankSwift,
                        correspondentName: requisite?.correspondentName,
                        bankAddress: requisite?.bankAddress,
                      }}
                    />
                    )
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      />
      {data && data?.map((requisite) => (
        template === requisite?.templatePurpose && (
          <Media
            query={`(max-width: ${breakpoints.phone_large}px`}
            render={() => (
              <>
                {(requisite?.currencyUid === 'RUB' && (
                  <div className={classes.Mobile}>
                    <div className={classes.Mobile__requisites}>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Получатель платежа
                        </div>
                        <div className={classes.Mobile__agentName}>
                          {requisite?.agentName}
                        </div>
                      </div>
                      <div className={classes.Mobile__bankAccount}>
                        <div className={classes.Mobile__block}>
                          <div className={classes.Mobile__blockHeader}>
                            Валюта
                          </div>
                          <div className={classes.Mobile__blockText}>
                            {requisite?.currencyUid}
                          </div>
                        </div>
                        <div className={classes.Mobile__block}>
                          <div className={classes.Mobile__blockHeader}>
                            Счет получателя
                          </div>
                          <div className={classes.Mobile__blockText}>
                            {requisite?.bankAccount}
                          </div>
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Наименование банка получателя
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.bankName}
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Рассчетный счет
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.bankAccount}
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Корр. счет
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.correspondentAccount}
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          БИК
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.bankBic}
                        </div>
                      </div>
                    </div>
                  </div>
                )) || (
                  <div className={classes.Mobile}>
                    <div className={classes.Mobile__requisites}>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Получатель платежа
                        </div>
                        <div className={classes.Mobile__agentName}>
                          {requisite?.agentName}
                        </div>
                      </div>
                      <div className={classes.Mobile__bankAccount}>
                        <div className={classes.Mobile__block}>
                          <div className={classes.Mobile__blockHeader}>
                            Валюта
                          </div>
                          <div className={classes.Mobile__blockText}>
                            {requisite?.currencyUid}
                          </div>
                        </div>
                        <div className={classes.Mobile__block}>
                          <div className={classes.Mobile__blockHeader}>
                            Счет получателя
                          </div>
                          <div className={classes.Mobile__blockText}>
                            {requisite?.bankAccount}
                          </div>
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Наименование банка
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.bankName}
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Наименование банка получателя
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.bankName}
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Банк-корреспондент
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.correspondentName}
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Счет получателя
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.bankAccount}
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          SWIFT банка получателя
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.bankSwift}
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          Счет в банке-корреспонденте
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.correspondentAccount}
                        </div>
                      </div>
                      <div className={classes.Mobile__block}>
                        <div className={classes.Mobile__blockHeader}>
                          SWIFT банка-корреспондента
                        </div>
                        <div className={classes.Mobile__blockText}>
                          {requisite?.correspondentSwift}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          />
        )))}
    </>
  )
}

RequisitesContent.defaultProps = {
  data: PropTypes.array,
}

RequisitesContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  template: PropTypes.string.isRequired,
}

export default RequisitesContent

import React from 'react'
import Media from 'react-media'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { MenuItem } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'
import CustomInput from '../../CustomControls/CustomInput'
import CustomSwitch from '../../CustomControls/CustomSwitch'
import CustomSelect from '../../CustomControls/CustomSelect'
import CustomDatepicker from '../../CustomControls/CustomDatepicker'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import useStyles from './sharedStyles'

const GeneralInfo = ({
  // eslint-disable-next-line react/prop-types
  data, handleClickNextPanel, isNeedNextButton, isQualifiedInvestor, clientInfo,
}) => {
  const classes = useStyles()

  const nationalityRu = data?.nationality?.nameRu

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Grid
              container
              spacing={2}
              className={classes.row}
            >
              <Grid
                item
                sm={5}
                xs={12}
              >
                <CustomDatepicker
                  label="Дата рождения"
                  value={data?.birthDate}
                  minDate={data?.birthDate}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                sm={7}
                xs={12}
              >
                <CustomInput
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="birthplace"
                  value={data?.birthPlace || ''}
                  label="Место рождения"
                  disabled
                  unique
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className={classes.row}
            >
              <Grid
                item
                lg={5}
                xs={5}
              >
                <CustomSelect
                  label="Гражданство"
                  value={nationalityRu || ''}
                  unique
                >
                  <MenuItem value={nationalityRu}>{nationalityRu}</MenuItem>
                </CustomSelect>
              </Grid>
              <Grid
                item
                lg={7}
                xs={7}
              >
                <CustomInput
                  label="Гражданство других стран"
                  value={data?.otherNationalities?.map((value) => value?.nameRu).join(', ') || ''}
                  size="small"
                  variant="outlined"
                  fullWidth
                  disabled
                  unique
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className={classes.row}
            >
              <Grid
                item
                lg={3}
                xs={6}
              >
                <CustomSelect
                  label="Налоговое резидентство"
                  value={clientInfo?.taxResidency?.uid === 'RUS'}
                  unique
                >
                  <MenuItem value>Резидент РФ</MenuItem>
                  <MenuItem value={false}>Нерезидент РФ</MenuItem>
                </CustomSelect>
              </Grid>
              <Grid
                item
                lg={3}
                xs={6}
              >
                <CustomInput
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="inn"
                  value={data?.inn || ''}
                  label="ИНН"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg={3}
                xs={6}
              >
                <CustomInput
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="tin"
                  value={data?.tin || ''}
                  label="TIN"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg={3}
                xs={6}
              >
                <CustomInput
                  size="small"
                  variant="outlined"
                  fullWidth
                  name="snils"
                  value={data?.snils || ''}
                  label="СНИЛС"
                  disabled
                  unique
                />
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.row}
            >
              <Grid
                container
                item
                lg={3}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
                justify="space-between"
                className={classes.residentRF}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.switchContainer}
                >
                  <span className={classes.switchLegend}>Валютный резидент РФ</span>
                  <CustomSwitch
                    name="first"
                    isChecked={!!clientInfo?.currencyResidency}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justify="space-between"
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
              >
                <Grid
                  item
                  xs={12}
                  className={classes.switchContainer}
                >
                  <span className={classes.switchLegend}>Публичное должностное лицо</span>
                  <CustomSwitch
                    name="first"
                    isChecked={!!data?.isPublicOfficial}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                lg={4}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
                justify="space-between"
              >
                <Grid
                  item
                  xs={12}
                  className={classes.switchContainer}
                >
                  <span className={classes.switchLegend}>Квалифицированный инвестор</span>
                  <CustomSwitch
                    name="first"
                    isChecked={!!isQualifiedInvestor}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <div className={classes.Mobile}>
            {data?.birthDate && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Дата рождения
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.birthDate}
              </div>
            </div>
            )}
            {data?.birthPlace && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Место рождения
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.birthPlace}
              </div>
            </div>
            )}
            {data?.nationality?.nameRu && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Гражданство
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.nationality?.nameRu}
              </div>
            </div>
            )}
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Налоговое резидентство
              </div>
              <div className={classes.Mobile__blockText}>
                {clientInfo?.taxResidency?.uid === 'RUS' ? 'Резидент РФ' : 'Нерезидент РФ'}
              </div>
            </div>
            {data?.inn && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                ИНН
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.inn}
              </div>
            </div>
            )}
            {data?.tin && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                TIN
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.tin}
              </div>
            </div>
            )}
            {data?.snils && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                СНИЛС
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.snils}
              </div>
            </div>
            )}
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Валютный резидент РФ
              </div>
              <div className={classes.Mobile__blockText}>
                <CustomSwitch
                  name="first"
                  isChecked={!!clientInfo?.currencyResidency}
                  disabled
                />
              </div>
            </div>
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Публичное должностное лицо
              </div>
              <div className={classes.Mobile__blockText}>
                <CustomSwitch
                  name="first"
                  isChecked={!!data?.isPublicOfficial}
                  disabled
                />
              </div>
            </div>
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Квалифицированный инвестор
              </div>
              <div className={classes.Mobile__blockText}>
                <CustomSwitch
                  name="first"
                  isChecked={!!isQualifiedInvestor}
                  disabled
                />
              </div>
            </div>
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </div>
        )}
      />
    </MuiPickersUtilsProvider>
  )
}

GeneralInfo.defaultProps = {
  data: PropTypes.object,
  clientInfo: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

GeneralInfo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  clientInfo: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

export default GeneralInfo

import { createSelector } from '@reduxjs/toolkit'

const selectFiles = (state) => state.file

export const selectGetFilesData = createSelector(
  [selectFiles],
  (state) => state.getFile,
)

export const selectPostFilesData = createSelector(
  [selectFiles],
  (state) => state.postFileId?.data,
)

export const selectPostFilesDataError = createSelector(
  [selectFiles],
  (state) => state.error,
)

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { MainLayout } from 'ui/components/templates/MainLayout'
import { theme, GlobalStyle } from 'ui/theme'
import { useSize } from 'hooks/useSize'
import PortfolioActions from './components/PortfolioActions'
import {
  fetchPortfolioStartAsync,
  fetchValuationsStart3Async,
  getPortfolioSuccess,
  getValuationsSuccess
} from 'redux/portfolio/portfolioSlice'
import { selectConnectionState } from 'redux/connection/selector'
import { SizeContext } from 'context/SizeContext'
import PortfolioTables from './components/PortfolioTables'

export const Portfolio: React.FC = () => {
  const dispatch = useDispatch()

  const fetchValuationsStart = () => {
    dispatch(fetchValuationsStart3Async())
  }
    const fetchPortfolioStart = () => {
    dispatch(fetchPortfolioStartAsync())
  }

  const [currentCategory, setCurrentCategory] = useState([])

  useEffect(() => {
    fetchPortfolioStart()
  }, [])

  return (
    <MainLayout title="Портфель">

      <PortfolioActions
        currentCategory={currentCategory}
        onChangeCurrentCategory={setCurrentCategory}
      />

      <PortfolioTables currentCategory={currentCategory} />
    </MainLayout>
  )
}

export const PortfolioNew: React.FC = () => {
  const size = useSize()
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SizeContext.Provider value={size}>
        <Portfolio />
      </SizeContext.Provider>
    </ThemeProvider>
  )
}

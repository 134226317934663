import { styled } from 'ui/theme'
import { Button, Tabs, TextField, Text, Field } from 'ui/components'

export const StyledMessageFormWindow = styled.div`
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 2px rgba(49, 58, 78, 0.06);

  @media ${({ theme }) => theme.breakpoint.xs} {
    padding: 0;
    box-shadow: none;
  }
`

export const StyledMessageFormTabs = styled(Tabs)`
  max-width: 376px;

  @media ${({ theme }) => theme.breakpoint.xs} {
    max-width: none;
  }
`

export const StyledSubtitle = styled(Text)`
  display: block;
  margin-bottom: 24px;

  @media ${({ theme: { breakpoint } }) => breakpoint.xs} {
    max-width: 500px;
  }

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    max-width: 500px;
  }
`

export const StyledFormBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.breakpoint.xs} {
    flex-direction: column;
  }
`

export const StyledButtonGroup = styled.div`
  display: flex;

  &:last-of-type {
    margin-left: 32px;
  }

  @media (${({ theme }) => theme.breakpoint.xs}) {
    flex-direction: column;
    width: 100%;

    &:last-of-type {
      margin-left: 0;
    }
  }
`

export const StyledSendBtn = styled(Button)`
  margin-right: 16px;

  @media ${({ theme }) => theme.breakpoint.xs} {
    margin-top: 28px;
    margin-bottom: 16px;
    margin-right: 0;
    width: 100%;
  }
`

export const StyledCancelBtn = styled(Button)`
  @media ${({ theme }) => theme.breakpoint.xs} {
    width: 100%;
  }
`

export const StyledDownloadBtn = styled(Button)`
  margin-right: 16px;

  @media ${({ theme }) => theme.breakpoint.xs} {
    margin-bottom: 16px;
    margin-right: 0;
    width: 100%;
  }
`

export const StyledHistoryCard = styled.div`
  padding: ${({ theme: { spacing } }) => spacing.sm};
  margin-bottom: ${({ theme: { spacing } }) => spacing.md};
  border-radius: ${({ theme: { borderRadius } }) => borderRadius.sm};
  box-shadow: 0px 0px 20px 2px rgba(49, 58, 78, 0.06);
  cursor: pointer;
  transition: 0.2s opactiy;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const StyledHistoryCardSubject = styled(Text)`
  display: block;
  word-break: break-all;
  margin-right: 30px;
`

export const StyledHistoryCardDate = styled(Text)`
  white-space: nowrap;
`

export const StyledHistoryCardRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:last-child {
    margin-top: ${({ theme: { spacing } }) => spacing.xxs};
  }
`
export const StyledHistoryTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media ${({ theme }) => theme.breakpoint.xs} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 28px;
  }
`

export const StyledHistoryTitleText = styled(Text)`
  display: block;
  margin-right: 12px;

  ${({ theme: { font } }) => font.h4}

  @media ${({ theme }) => theme.breakpoint.xs} {
    display: block;
    margin-bottom: 12px;
  }
`

export const StyledMessageFormComeBack = styled(Button)`
  margin-right: 0;
  margin-left: auto;

  @media ${({ theme }) => theme.breakpoint.xs} {
    margin-top: 8px;
    width: 100%;
  }
`

export const StyledMessageModalText = styled(Text)`
  margin-bottom: 24px;

  ${({ theme: { font } }) => font.body2}
`

export const StyledMessageModalForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    flex-direction: column;
    gap: 30px;
  }
`

export const StyledMessageCodeField = styled(Field)`
  width: calc(100% - 164px);

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    width: 100%;
  }
`

export const StyledMessageCodeBtn = styled(Button)`
  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    width: 100%;
  }
`

export const StyledMessageModalAgain = styled(Button)`
  display: block;
  margin-top: 12px;
  padding-bottom: 10px;
  text-align: center;
  color: ${({ theme: { color } }) => color.text.accent};

  ${({ theme: { font } }) => font.caption1}

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    margin-top: 21px;

    &:disabled {
      ${({ theme: { color } }) =>
        `
      opacity: 1;
      color: ${color.text.secondary};
        `}
    }
  }
`

export const StyledMessageModalThank = styled(Button)`
  @media ${({ theme }) => theme.breakpoint.xs} {
    width: 100%;
  }
`

export const StyledTypeField = styled(Field)`
  max-width: 372px;
  width: 100%;

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    max-width: none;
  }
`

export const StyledHistoryWrapper = styled.div`
`

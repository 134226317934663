import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  error: null,
  getFile: [],
  postFileID: null,
}

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    getFile(state) {
      return {
        ...state,
        error: null,
      }
    },
    getFileSuccess(state, action) {
      return {
        ...state,
        getFile: [action.payload?.data],
        error: null,
      }
    },
    getFileFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    postFile(state) {
      return {
        ...state,
        error: null,
      }
    },
    postFileSuccess(state, action) {
      return {
        ...state,
        postFileId: action.payload,
        error: null,
      }
    },
    postFileFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    fileClean() {
      return initialState
    },
  },
})

const { actions, reducer } = fileSlice

export const {
  getFile,
  getFileSuccess,
  getFileFailed,
  postFile,
  postFileSuccess,
  postFileFailed,
  fileClean,
} = actions

export default reducer

export const fetchGetFileStartAsync = ({ id }) => async (dispatch) => {
  dispatch(getFile())
  try {
    const value = await Api.getFile(id)
    dispatch(getFileSuccess(value))
  } catch (error) {
    dispatch(getFileFailed(error))
  }
}

export const fetchPostFileStartAsync = ({ obj }) => async (dispatch) => {
  dispatch(postFile())
  try {
    const value = await Api.postFile(obj)
    dispatch(postFileSuccess(value))
  } catch (error) {
    dispatch(postFileFailed(error))
  }
}

export const fetchPostFileListStartAsync = ({ arrayFiles }) => async (dispatch) => {
  dispatch(postFile())
  try {
    await Api.postFileList(arrayFiles)
  } catch (error) {
    dispatch(postFileFailed(error))
  }
}

import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import classNames from 'classnames'
import { FormControl, FormHelperText, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import useStyles from './CustomControls.style'
import { ReactComponent as DropdownIcon } from '../../assets/images/dropdown.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/closeIcon.svg'
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg'

const CustomInputSelect = ({
  label, helperText, placeholder, specialOptions, error, errorText, value = '', onChange, required, uniqueSearchList, disabled, disabledMain, unique, onInputChange, onDeleteItem, ...props
}) => {
  const classes = useStyles({ inputSelect: true })

  const [valueInput, setValueInput] = useState(null)

  useEffect(() => {
    setValueInput(value)
  }, [value])

  const onInputChangeSelect = (event, newValue) => {
    if (disabled) return
    setValueInput(newValue)
  }

  return (
    <FormControl
      fullWidth
      error={error}
      className={classNames(classes.formControl, { [classes.uniqueFormControl]: unique })}
      required={required}
    >
      <FormLabel className={classes.inputLabel}>{label}</FormLabel>
      <Autocomplete
        {...props}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          if (option.templateName) {
            return option.templateName
          }
          return option.name
        }}
        disableClearable
        value={value}
        disabled={disabledMain}
        getOptionSelected={(
          option,
          val,
        ) => val.value === option.value}
        inputValue={valueInput}
        onChange={onChange}
        popupIcon={<DropdownIcon />}
        openText=""
        closeText=""
        noOptionsText=""
        renderOption={(option) => {
          if (specialOptions) {
            // eslint-disable-next-line array-callback-return,consistent-return
            return specialOptions.map((elem) => {
              if (option[elem]) {
                return (
                  <div
                    className={classes.searchItem}
                  >
                    <div><Typography noWrap>{`${option[elem]}          `}</Typography></div>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,no-underscore-dangle,jsx-a11y/no-static-element-interactions */}
                    { option?._links?.[0].canDelete && (<div onClick={(event) => { event.stopPropagation(); onDeleteItem(option?.id) }}><CloseIcon /></div>)}

                    { (option[elem] === 'Добавить новые реквизиты') && (<div><PlusIcon /></div>)}

                  </div>
                )
              }
            })
          }
          return <Typography noWrap>{option}</Typography>
        }}
        onInputChange={onInputChange || onInputChangeSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.inputField}
            helperText={helperText}
            placeholder={placeholder}
          />
        )}
        classes={{
          paper: classNames(classes.autocompletePaper, classes.autocompletePaper_inputSelect,
            classes.menuPaperUnique),
          popper: classes.autocompletePopper,
        }}
      />
      {error && (
        <FormHelperText>{errorText}</FormHelperText>
      )}
    </FormControl>
  )
}

CustomInputSelect.defaultProps = {
  helperText: null,
  value: '',
  uniqueSearchList: false,
  unique: false,
  required: false,
  error: false,
  errorText: '',
  disabled: false,
  disabledMain: false,
  onChange: () => {},
}

CustomInputSelect.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  uniqueSearchList: PropTypes.bool,
  unique: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMain: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  specialOptions: PropTypes.oneOfType([PropTypes.array]),
}

export default CustomInputSelect

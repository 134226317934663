import React, { useEffect, useState } from 'react'
import {
  Redirect, Route, Switch, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Media from 'react-media'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg'
import RequisitesMoney from './RequisitesMoney'
import RequisitesDepo from './RequisitesDepo'
import useStyles from '../InnerTabs/InnerTabs.style'
import useWindowSize from '../../hooks/useWindows'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import PageHeading from '../PageHeading/PageHeading'

export default function Requisites() {
  const classes = useStyles()
  const { windowWidth } = useWindowSize()
  const [secondNestingVisibility, setSecondNestingVisibility] = useState(true)
  const { url, path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const splitPathname = pathname.split('/')
  const isPathMoneyClassName = splitPathname[3] === 'moneyRequisites'
  const isPathDepoClassName = splitPathname[3] === 'depoRequisites'

  useEffect(() => {
    const nestingVisibility = !(windowWidth <= 576 && (pathname.match(/\//g) || []).length >= 3)
    setSecondNestingVisibility(nestingVisibility)
  }, [windowWidth, pathname])

  const goTo = (link) => history.push(`${url}/${link}`)
  const getDynamicClassname = (isActive) => (isActive ? `${classes.breadCrumb} ${classes.activeCrumb}` : classes.breadCrumb)

  return (
    <>
      <Box maxWidth={898}>
        {secondNestingVisibility && (
          <>
            <PageHeading text="Ценные бумаги" />
            <Grid
              container
              className={classes.crumbContainer}
            >
              <button
                type="button"
                className={getDynamicClassname(isPathMoneyClassName)}
                onClick={() => goTo('moneyRequisites')}
              >
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px)`}
                  render={() => (
                    <TransfersIcon />
                  )}
                />
                <span>Реквизиты для зачисления ДС</span>
                <Media
                  query="(min-width: 577px)"
                  render={() => (
                    <ArrowRightIcon />
                  )}
                />
              </button>
              <button
                type="button"
                className={getDynamicClassname(isPathDepoClassName)}
                onClick={() => goTo('depoRequisites')}
              >
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px)`}
                  render={() => (
                    <TransfersIcon />
                  )}
                />
                <span>Реквизиты депозитария</span>
                <Media
                  query="(min-width: 577px)"
                  render={() => (
                    <ArrowRightIcon />
                  )}
                />
              </button>
            </Grid>
          </>
        )}
      </Box>
      <Box>
        <Switch>
          <Route
            exact
            path={path}
          >
            {windowWidth > 577 ? <Redirect to={`${path}/moneyRequisites`} /> : null}
          </Route>
          <Route
            path={`${path}/moneyRequisites`}
            component={RequisitesMoney}
          />
          <Route
            exact
            path={`${path}/depoRequisites`}
            component={RequisitesDepo}
          />
        </Switch>
      </Box>
    </>
  )
}

import React from 'react'
import AntPagination from 'antd/es/pagination';
import { Icon, Text } from 'ui/components'
import { StyledPagination } from './styled'
import { IPaginationProps } from './types'

export const Pagination: React.FC<IPaginationProps> = (props) => {
  return <StyledPagination>
    <div>
      <Text color="secondary">{props.current} из {Math.ceil(props.total / props.pageSize)} страниц</Text>
    </div>
    <AntPagination
      showTitle={false}
      itemRender={(page, type, el) => {
        if (type === 'jump-prev' || type === 'jump-next') {
          return '...';
        }
        return (
          type === 'next' ?
            <Icon name="arrow-right" /> :
            type === 'prev' ?
              <Icon name="arrow-left" /> : el
        )
      }}
      showSizeChanger={false}
      {...props}
    />
  </StyledPagination>
}
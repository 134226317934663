import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ContactsInfo__boxWrapper: {
    flexDirection: 'column',
    display: 'flex',
    flexGrow: 1,
    paddingTop: 73,
    paddingBottom: 6,
    '@media (max-width: 576px)': {
      paddingTop: 21,
      paddingBottom: 0,
    },
  },
  ContactsInfo__header: {
    marginBottom: 47,
    color: '#91B1BF',
    fontSize: 20,
    fontWeight: '700',
    fontFamily: 'Open Sans, sans-serif',
    '@media (max-width: 576px)': {
      marginBottom: 16,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '21px',
    },
  },
  ContactsInfo__grid: {
    display: 'flex',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  ContactsInfo__col: {
    '&:not(:last-child)': {
      marginRight: 61,
      '@media (max-width: 576px)': {
        marginRight: 0,
        marginBottom: 16,
      },
    },
  },
  ContactsInfo__title: {
    color: '#91B1BF',
    fontSize: 16,
    fontWeight: '700',
    fontFamily: 'Open Sans, sans-serif',
    '@media (max-width: 576px)': {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '19px',
    },
  },
  ContactsInfo__colTitle: {
    marginBottom: 16,
    '@media (max-width: 576px)': {
      marginBottom: 4,
    },
  },
  ContactsInfo__list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  ContactsInfo__listItem: {
    display: 'flex',
    '&:not(:last-child)': {
      marginBottom: 9,
      '@media (max-width: 576px)': {
        marginBottom: 2,
      },
    },
  },
  ContactsInfo__listItemTitle: {
    minWidth: 51,
    marginRight: 10,
    fontSize: 16,
    color: '#91B1BF',
    '@media (max-width: 576px)': {
      marginRight: 3,
      fontSize: 14,
      lineHeight: '19px',
    },
  },
  ContactsInfo__listItemValue: {
    fontSize: 16,
    '@media (max-width: 576px)': {
      fontSize: 14,
      lineHeight: '19px',
    },
  },
  ContactsInfo__footer: {
    marginTop: 'auto',
    '@media (max-width: 576px)': {
      position: 'relative',
      top: 44,
    },
    '& .MuiTypography-root:not($ContactsInfo__footerTitle)': {
      '@media (max-width: 576px)': {
        fontSize: 12,
        lineHeight: '16px',
      },
    },
  },
  ContactsInfo__footerTitle: {
    marginBottom: 2,
    '@media (max-width: 576px)': {
      marginBottom: 6,
    },
  },
  ContactsInfo__footerLink: {
    marginLeft: 4,
    color: theme.palette.type === 'light' ? '#0000EE' : '#91B1BF',
    '@media (max-width: 576px)': {
      color: theme.palette.type === 'light' ? '#000' : '#91B1BF',
    },
  },
}))

export default useStyles

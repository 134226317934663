import { request } from './Request'
import { syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getBalancesDepo(filterBody) {
    return request(syncApiURL, endpoints.assets.getBalancesDepo(filterBody))
  },
  getBalancesMoney(filterBody) {
    return request(syncApiURL, endpoints.assets.getBalancesMoney(filterBody))
  },
}

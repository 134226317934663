import { ROUTES } from 'app/data'

export const actionsList = [
  {
    title: 'Профиль',
    link: ROUTES.profile,
    icon: 'menu-user',
  },
  {
    title: 'Документы на подпись',
    link: '/sign/reports',
    icon: 'menu-contacts',
    hasBadge: true,
  },
  {
    title: 'Выйти/сменить пользователя',
    link: '/logout',
    icon: 'menu-logout',
  },
]

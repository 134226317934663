import { request } from './Request'
import { asyncApiURL, syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getFile(id) {
    return request(syncApiURL, endpoints.file.getFile(id))
  },
  postFile(data) {
    return request(syncApiURL, endpoints.file.postFile, 'POST', data)
  },
  postFileList(data) {
    return request(asyncApiURL, endpoints.file.postFileList, 'POST', data)
  },
}

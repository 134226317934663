import { styled } from "ui/theme";
import { IBlockHeadingProps } from "./types";

export const StyledBlockHeading = styled.div<IBlockHeadingProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: 6px;

  ${({ border = true, theme }) => border ? `
    padding-left: ${theme.spacing.sm};
    border-left: 2px solid ${theme.color.text.accent};
  `: ''}
`

export const StyledBlockHeadingTop = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.hair};
`

export const StyledBlockHeadingTitle = styled.div`
  ${({ theme }) => theme.font.body1_lg}
  color: ${({ theme }) => theme.color.text.primary};
  display: flex;
  gap: 8px;
  align-items: center;
  @media(${({ theme }) => theme.breakpoint.xs}) {
    align-items: flex-start;
  }
`
export const StyledBlockHeadingCaption = styled.span<IBlockHeadingProps>`
  ${({ theme }) => theme.font.caption1}
  color: ${({ theme }) => theme.color.text.secondary};
  margin-top: ${({ theme }) => theme.spacing.xxs};
`

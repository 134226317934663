import {queryApi} from "../queryApi";

export const notificationsApi = queryApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotification: builder.query({
      query: (arg) => ({
        url: 'notifications',
        method: 'GET',
        params: arg,
      }),
    }),
  })
})

export const { useLazyGetNotificationQuery } = notificationsApi

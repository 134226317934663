import { styled } from "ui/theme";
import { ICheckboxFieldProps } from "./types";

export const StyledCheckboxField = styled.div`
  display: flex;
  align-items: center;
  gap: ${({theme}) => theme.spacing.sm};
`
export const StyledCheckboxFieldContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCheckboxFieldLabel = styled.label`
  ${({ theme }) => theme.font.body1}
  color: ${({ theme }) => theme.color.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
`
export const StyledCheckboxFieldCaption = styled.span<ICheckboxFieldProps>`
  ${({ theme }) => theme.font.caption1}
  color: ${({ theme }) => theme.color.text.secondary};
  ${({ hasError, theme }) => hasError ? `
    color: ${theme.color.text.negative};
  ` : ''}
  margin-top: ${({ theme }) => theme.spacing.xxs};
`
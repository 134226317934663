import React from 'react'
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { StyledWrapper } from '../../Transfers/components/Money/styled'
import { StyledContent, StyledTabsWrapper } from '../../Transfers/styled'
import { Tab, Tabs } from '../../../molecules'
import DocumentsTable from '../DocumentsTableNew'
import NewDocument from "../NewDocument";
import IncomingDocumentsTable from "../IncomingDocumentsTable";
import TemporarilyUnavailableTab from "../TemporarilyUnavailableTab";
const DocumentPanel: React.FC = () => {
  const { url, path } = useRouteMatch()
  const location = useLocation()

  const { pathname } = location
  const splitPathname = pathname.split('/')
  const isPathDocument = splitPathname[3] === 'document'
  const isPathDocuments = splitPathname[3] === 'documents'
  const isPathIncomingDocuments = splitPathname[3] === 'incoming'

  return (
    <StyledWrapper>
      <StyledTabsWrapper>
        <Tabs variant={2}>
          <Tab
            label="Новый документ"
            active={isPathDocument}
            to={`${url}/document/new`}
            component={NavLink}
          />
          <Tab
            label="Исходящие документы"
            active={isPathDocuments}
            to={`${url}/documents`}
            component={NavLink}
          />
          <Tab
            label="Входящие документы"
            active={isPathIncomingDocuments}
            to={`${url}/incoming`}
            component={NavLink}
          />
        </Tabs>
      </StyledTabsWrapper>
      <StyledContent>
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/document/new`} />
          </Route>
          {/*TODO - temporarily disabled tab, remove after enabling*/}
          {/*<Route exact path={`${path}/document/:id`} component={NewDocument} />*/}
          <Route exact path={`${path}/document/:id`} component={TemporarilyUnavailableTab} />
          <Route exact path={`${path}/documents`} component={DocumentsTable} />
          <Route exact path={`${path}/incoming`} component={IncomingDocumentsTable} />
        </Switch>
      </StyledContent>
    </StyledWrapper>
  )
}

export default DocumentPanel

import { styled } from 'ui/theme'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // gap: ${({ theme }) => theme.spacing.xl};

`

export const FilterWrapper = styled.div`

  background-color: #ff1;
  padding: 40px 16px;
  border-radius: 24px;
`

import React from 'react'
import { Field } from 'ui/components/molecules'
import { FormCol, TextField } from 'ui/components/atoms'
import { BankRequisitesContainer } from './styled'

import type { FC } from 'react'
import type { BankRequisitesProps } from './types'

/**
 * Банковские реквизиты.
 *
 * @param swift - SWIFT кредит. орг. -нерезидента/ рег.номер.
 * @param regNumber - Регистрационный номер № кредит. орг.-резидента..
 * @param bik - БИК.
 *
 * @constructor
 */
export const BankRequisites: FC<BankRequisitesProps> = ({
  swift = '',
  regNumber = '',
  bik = '',
}) => {
  return (
    <BankRequisitesContainer>
      <FormCol gap={22} gapMobile={24}>
        {/*
          SWIFT КРЕДИТ. ОРГ. -НЕРЕЗИДЕНТА/ РЕГ.НОМЕР
        */}
        <Field label="SWIFT кредит. орг. -нерезидента/ рег.номер">
          <TextField disabled value={swift} />
        </Field>

        {/*
          РЕГИСТРАЦИОННЫЙ НОМЕР № КРЕДИТ. ОРГ.-РЕЗИДЕНТА
        */}
        <Field label="Регистрационный номер № кредит. орг.-резидента">
          <TextField disabled value={regNumber} />
        </Field>

        {/*
          БИК (ПРИ НАЛИЧИИ)
        */}
        <Field label="БИК (при наличии)">
          <TextField disabled value={bik} />
        </Field>
      </FormCol>
    </BankRequisitesContainer>
  )
}

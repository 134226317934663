import React from 'react'
import classnames from 'classnames'
import { Link, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import useStyles from './contacts.style'

const ContactsInfo = () => {
  const classes = useStyles()

  return (
    <Box className={classes.ContactsInfo__boxWrapper}>
      <Typography
        variant="h2"
        className={classes.ContactsInfo__header}
      >
        Брокерская компания РЕГИОН
      </Typography>
      <div className={classes.ContactsInfo__grid}>
        <div className={classes.ContactsInfo__col}>
          <Typography
            className={classnames(classes.ContactsInfo__title, classes.ContactsInfo__colTitle)}
            variant="h3"
          >
            Отдел продаж
          </Typography>
          <div className={classes.ContactsInfo__list}>
            <div className={classes.ContactsInfo__listItem}>
              <span className={classes.ContactsInfo__listItemTitle}>тел.</span>
              <span className={classes.ContactsInfo__listItemValue}>+7 495 777 29 64</span>
            </div>
            <div className={classes.ContactsInfo__listItem}>
              <span className={classes.ContactsInfo__listItemTitle}>доб.</span>
              <span className={classes.ContactsInfo__listItemValue}>306 | 435</span>
            </div>
            <div className={classes.ContactsInfo__listItem}>
              <span className={classes.ContactsInfo__listItemTitle}>e-mail</span>
              <span className={classes.ContactsInfo__listItemValue}>sales@region.ru</span>
            </div>
          </div>
        </div>
        <div className={classes.ContactsInfo__col}>
          <Typography
            className={classnames(classes.ContactsInfo__title, classes.ContactsInfo__colTitle)}
            variant="h3"
          >
            Отдел по работе с клиентами
          </Typography>
          <div className={classes.ContactsInfo__list}>
            <div className={classes.ContactsInfo__listItem}>
              <span className={classes.ContactsInfo__listItemTitle}>тел.</span>
              <span className={classes.ContactsInfo__listItemValue}>+7 495 777 29 64</span>
            </div>
            <div className={classes.ContactsInfo__listItem}>
              <span className={classes.ContactsInfo__listItemTitle}>доб.</span>
              <span className={classes.ContactsInfo__listItemValue}>172 | 185 | 636 | 341</span>
            </div>
            <div className={classes.ContactsInfo__listItem}>
              <span className={classes.ContactsInfo__listItemTitle}>e-mail</span>
              <span className={classes.ContactsInfo__listItemValue}>client@region.ru</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.ContactsInfo__footer}>
        <Typography
          className={classnames(classes.ContactsInfo__title, classes.ContactsInfo__footerTitle)}
        >
          Раскрытие информации
        </Typography>
        <Typography component="p">
          Информация, предоставляемая в соответствии с требованиями Базовых стандартов СРО, на
          сайте
          <Link
            underline="always"
            color="textSecondary"
            href="https://region.broker/documents/disclosure/formy-dokumentov-i-reglamenty/reglament-brokerskogo-obsluzhivaniya/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.ContactsInfo__footerLink}
          >
            ООО «БК РЕГИОН»
          </Link>
        </Typography>
      </div>
    </Box>
  )
}
export default ContactsInfo

import React, {
  Fragment, useEffect, useRef, useState,
} from 'react'
import { format } from 'date-fns'
import Media from 'react-media'
import Box from '@material-ui/core/Box'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import classnames from 'classnames'
import useStyles from './sign.style'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import {
  fetchNeedSignStartAsync, fetchPostClientSignStartAsync, fetchPostReportRejectStartAsync,
  fetchReportStartAsync,
  getNeedSignSuccess,
  getReportSuccess, postClientSignSuccess, postReportRejectSuccess,
} from '../../redux/reports/reportsSlice'
import {
  selectClientSign, selectNeedSignReports, selectReport, selectReportReject,
} from '../../redux/reports/selector'
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg'
import { ReactComponent as EdsIcon } from '../../assets/images/eds.svg'
import CustomIconButton from '../CustomControls/CustomIconButton'
import b64toBlob from '../../utils/ConvertToBlob'
import CustomInput from '../CustomControls/CustomInput'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import PageHeading from '../PageHeading/PageHeading'
import { isRequired } from '../../utils/Validate'
import { selectCurrentError } from '../../redux/error/selector'
import CustomModal from '../CustomModal/CustomModal'
import { selectCurrentThumbprint } from '../../redux/user/selector'

const tabs = [
  {
    title: 'Дата',
  },
  {
    title: 'Тип',
  },
  {
    title: 'Формат',
  },
]

const Sign = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [reportId, setReportId] = useState(null)
  const [fileId, setFileId] = useState(null)
  const [isEps, setIsEps] = useState(false)
  const [open, setOpen] = useState('')
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [codeErrorText, setCodeErrorText] = useState(null)
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [rejectReportID, setRejectReportID] = useState(null)

  const needSignReports = useSelector(selectNeedSignReports)
  const reportDetail = useSelector(selectReport)
  const clientSign = useSelector(selectClientSign)
  const reportRejectStatus = useSelector(selectReportReject)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)

  const fetchReportStart = (id) => {
    dispatch(fetchReportStartAsync({ id }))
  }

  const fetchPostClientSignStart = (obj, dataToSign, thumbprint) => {
    dispatch(fetchPostClientSignStartAsync(obj, dataToSign, thumbprint))
  }

  const fetchPostReportRejectStart = (obj) => {
    dispatch(fetchPostReportRejectStartAsync(obj))
  }

  const fetchNeedSignStart = () => {
    dispatch(fetchNeedSignStartAsync())
  }

  const {
    getData: getReport,
  } = useConnect({
    link: `GET /api${endpoints.reports.getReport(fileId || null)}`,
    startFetching: fetchReportStart,
    action: getReportSuccess,
  })

  const {
    getData: postClientSign,
  } = useConnect({
    link: `POST /api${endpoints.reports.postClientSign}`,
    startFetching: fetchPostClientSignStart,
    action: postClientSignSuccess,
  })

  const {
    getData: postReportReject,
  } = useConnect({
    link: `POST /api${endpoints.reports.postReportReject}`,
    startFetching: fetchPostReportRejectStart,
    action: postReportRejectSuccess,
  })

  const {
    getData: postNeedSignReports,
  } = useConnect({
    link: `GET /api${endpoints.reports.needSign}`,
    startFetching: fetchNeedSignStart,
    action: getNeedSignSuccess,
  })

  useEffect(() => () => {
    dispatch(getReportSuccess(null))
    dispatch(postClientSignSuccess(null))
  }
  // eslint-disable-next-line
  , [])

  const updateNeedSignReports = () => {
    postNeedSignReports(currentThumbPrint)
    setOpen('')
    dispatch(postClientSignSuccess(null))
    dispatch(postReportRejectSuccess(null))
  }

  useEffect(() => {
    if (serverError) {
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeErrorText(serverError?.errors?.Secret[0])
      }
    }
  }, [serverError])

  useEffect(() => {
    if (clientSign?.actionNeeded === 'sign') {
      setFileId(reportId)
      getReport(reportId)
    }
    if (!clientSign?.actionNeeded && clientSign?.status === 200) {
      updateNeedSignReports()
    }
    // eslint-disable-next-line
  }, [clientSign])

  useEffect(() => {
    if (reportRejectStatus) {
      if (reportRejectStatus?.status === 200) {
        updateNeedSignReports()
      }
    }
    // eslint-disable-next-line
  }, [reportRejectStatus])

  const downloadLink = useRef(null)

  useEffect(() => {
    if (reportDetail && !clientSign) {
      downloadLink.current.href = URL.createObjectURL(b64toBlob(reportDetail.data))
      downloadLink.current.download = isEps ? reportDetail.signFileName : reportDetail.fileName
      downloadLink.current.click()
      dispatch(getReportSuccess())
    } else if (clientSign?.actionNeeded === 'sign') {
      const reqObj = {
        id: reportId,
      }
      postClientSign(reqObj, reportDetail, currentThumbPrint)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDetail])

  const handleDownloadFile = async (event, id, isEpsVal) => {
    event.stopPropagation()
    dispatch(postClientSignSuccess(''))
    setIsEps(isEpsVal)
    setFileId(id)
    await getReport(id)
  }

  const handleClickSign = async (reportID) => {
    setCode('')
    setReportId(reportID)
    const reqObj = {
      id: reportID,
    }
    postClientSign(reqObj)
  }

  const handleClickReject = async (reportID) => {
    setRejectModalOpen(true)
    setRejectReportID(reportID)
  }

  const handleClickCollapse = (index) => {
    setCode('')
    setReportId(null)
    dispatch(postClientSignSuccess(''))
    setOpen(open.includes(index) ? '' : `tabRow-${index}`)
  }

  const handleChangeCodeInput = (event) => {
    setCode(event.target.value)
    // setCodeError(false)
  }

  const handleSubmitCode = () => {
    if (!code) {
      isRequired(code, setCodeError, setCodeErrorText)
      return
    }
    const reqObj = {
      id: reportId,
      secret: code,
    }
    postClientSign(reqObj)
  }

  const handleRejectOk = () => {
    const reqObj = {
      id: rejectReportID,
    }
    postReportReject(reqObj)
    setRejectModalOpen(false)
  }

  const handleCloseRejectModal = () => {
    setRejectModalOpen(false)
    setRejectReportID(null)
  }

  return (
    <>
      <PageHeading text="Документы на подпись" />
      <Media
        query="(min-width: 577px)"
        render={() => (
          <Box
            pt="30px"
            maxWidth={806}
          >
            <TableContainer>
              <Table aria-label="instrument table">
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    {tabs.map((item, index) => (
                      <TableCell
                        key={item?.title + index.toString()}
                        className={classes.tableHeaderCell}
                      >
                        {item?.title}
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {needSignReports && needSignReports?.reports.map((item, index) => {
                    const {
                      dateTime, id, typeName, needClientSign,
                    } = item
                    const formatDate = format(new Date(dateTime), 'dd.MM.yyyy HH:mm')
                    const fileExtension = item?.format?.name
                    const collapseButton = (
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleClickCollapse(index)}
                      >
                        {open === `tabRow-${index}` ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    )
                    const buttonsContainer = (
                      <Box>
                        <Grid
                          container
                          item
                          spacing={2}
                        >
                          {(clientSign?.actionNeeded === 'code' && id === reportId) ? (
                            <div className={classnames(
                              classes.formGroup,
                              classes.formGroup_unique,
                            )}
                            >
                              <CustomInput
                                unique
                                label="Введите код из сообщения"
                                variant="outlined"
                                name="code"
                                value={code}
                                onChange={handleChangeCodeInput}
                                size="small"
                                fullWidth
                                error={codeError}
                                errorText={codeErrorText}
                                autoComplete="off"
                              />
                              <div className={classes.buttonsContainer}>
                                <CustomIconButton
                                  type="submit"
                                  unique
                                  noIcon
                                  onClick={handleSubmitCode}
                                >
                                  Подтвердить
                                </CustomIconButton>
                              </div>
                            </div>
                          ) : (
                            <div className={classes.formGroup}>
                              <div className={classes.buttonsContainer}>
                                <CustomIconButton
                                  unique
                                  needLine
                                  noIcon
                                  onClick={() => handleClickSign(id)}
                                >
                                  Подписать
                                </CustomIconButton>
                                <CustomIconButton
                                  unique
                                  noIcon
                                  onClick={() => handleClickReject(id)}
                                >
                                  Отклонить
                                </CustomIconButton>
                              </div>
                            </div>
                          )}
                        </Grid>
                      </Box>
                    )
                    const collapseContent = (
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
                          colSpan={12}
                          className={classes.nestedRow}
                        >
                          <Collapse
                            in={open === `tabRow-${index}`}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              p={2}
                              className={classes.nestedRowInner}
                            >
                              <Typography className={classes.textWithIcon}>
                                <EdsIcon />
                                {needClientSign ? (
                                  <span>Ожидаем Вашей подписи</span>
                                ) : <span>Отчет подписан</span>}
                              </Typography>
                              {(needClientSign) && (
                                buttonsContainer
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )

                    return (
                      <Fragment key={id}>
                        <TableRow
                          className={classes.tableRow}
                          hover
                          onClick={() => handleClickCollapse(index)}
                        >
                          <TableCell>
                            {formatDate}
                          </TableCell>
                          <TableCell>
                            {typeName}
                          </TableCell>
                          <TableCell>
                            <Button
                              className={classes.tableBtn}
                              disableRipple
                              onClick={(event) => handleDownloadFile(event, id, false)}
                            >
                              <DownloadIcon />
                              <Typography
                                tag="span"
                                color="secondary"
                              >
                                {fileExtension}
                              </Typography>
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            {(needClientSign) && (
                              collapseButton
                            )}
                          </TableCell>
                        </TableRow>
                        {(needClientSign) && (
                          collapseContent
                        )}
                      </Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <Box mt="12px">
            {needSignReports && needSignReports?.reports.map((item, index) => {
              const {
                dateTime, id, typeName, needClientSign,
              } = item
              const formatDate = format(new Date(dateTime), 'dd.MM.yyyy HH:mm')
              const fileExtension = item?.format?.name
              const collapseButton = (
                <IconButton
                  className={classes.PhoneGrid__collapseButton}
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleClickCollapse(index)}
                >
                  {open === `tabRow-${index}` ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              )
              const buttonsContainer = (
                <>
                  {(clientSign?.actionNeeded === 'code' && id === reportId) && (
                    <div className={classes.PhoneGrid__codeInputWrapper}>
                      <CustomInput
                        label="Введите код из сообщения"
                        variant="outlined"
                        name="code"
                        value={code}
                        onChange={handleChangeCodeInput}
                        size="small"
                        fullWidth
                        error={codeError}
                        errorText={codeErrorText}
                        autoComplete="off"
                      />
                    </div>
                  )}
                  <div className={classes.PhoneGrid__btnGroup}>
                    {(clientSign?.actionNeeded === 'code' && id === reportId) ? (
                      <CustomIconButton
                        type="submit"
                        unique
                        onClick={handleSubmitCode}
                      >
                        Подтвердить
                      </CustomIconButton>
                    ) : (
                      <>
                        <CustomIconButton
                          unique
                          onClick={() => handleClickSign(id)}
                        >
                          Подписать
                        </CustomIconButton>
                        <CustomIconButton
                          unique
                          onClick={() => handleClickReject(id)}
                        >
                          Отклонить
                        </CustomIconButton>
                      </>
                    )}
                  </div>
                </>
              )
              const collapseContent = (
                <Collapse
                  in={open === `tabRow-${index}`}
                  timeout="auto"
                  unmountOnExit
                  className={
                    classnames(
                      classes.PhoneGrid__collapse,
                      { [classes.PhoneGrid__collapse_filled]: clientSign?.actionNeeded === 'code' },
                    )
                  }
                >
                  <Box
                    className={classes.PhoneGrid__collapseInner}
                  >
                    <Typography className={classes.textWithIcon}>
                      <EdsIcon />
                      {needClientSign ? (
                        <span>Ожидаем Вашей подписи</span>
                      ) : <span>Отчет подписан</span>}
                    </Typography>
                    {(needClientSign) && (
                      buttonsContainer
                    )}
                  </Box>
                </Collapse>
              )

              return (
                <Fragment key={id + index.toString()}>
                  <div
                    className={classes.PhoneGrid}
                    onClick={() => handleClickCollapse(index)}
                    onKeyPress={() => handleClickCollapse(index)}
                    role="button"
                    tabIndex={0}
                  >
                    <div className={classes.PhoneGrid__item}>
                      <span
                        className={classes.PhoneGrid__header}
                      >
                        Дата отправления
                      </span>
                      <p className={
                        classnames(classes.PhoneGrid__value, classes.PhoneGrid__value_medium)
                      }
                      >
                        {formatDate}
                      </p>
                    </div>
                    <div className={
                      classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_unique)
                    }
                    >
                      {open === `tabRow-${index}` && collapseButton}
                    </div>
                    <div className={
                      classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullColumn)
                    }
                    >
                      <span
                        className={classes.PhoneGrid__header}
                      >
                        Тип
                      </span>
                      <p className={classes.PhoneGrid__value}>
                        {typeName}
                      </p>
                    </div>
                    <div className={
                      classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullColumn)
                    }
                    >
                      <span
                        className={classes.PhoneGrid__header}
                      >
                        Формат
                      </span>
                      <Button
                        className={classes.tableBtn}
                        disableRipple
                      >
                        <Typography
                          tag="span"
                          color="secondary"
                        >
                          {fileExtension}
                        </Typography>
                        <DownloadIcon onClick={(event) => handleDownloadFile(event, id, false)} />
                      </Button>
                    </div>
                  </div>
                  {(needClientSign) && (
                    collapseContent
                  )}
                </Fragment>
              )
            })}
          </Box>
        )}
      />
      <CustomModal
        open={rejectModalOpen}
        title="Вы действительно желаете отклонить подписание данного документа?"
        handleClose={handleCloseRejectModal}
        callbacks={{
          withOk: handleRejectOk,
          withCancel: () => {
            handleCloseRejectModal()
          },
        }}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={classes.downloadLink}
        href=""
        ref={downloadLink}
      >
        download
      </a>
    </>
  )
}

export default Sign

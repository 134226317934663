import React, { useCallback, useState, useEffect, useMemo, useRef, Fragment, memo } from 'react'
import { Icon } from 'ui/components'
import Accordion from '../Accordion'
import {
  StyledTable,
  StyledTableHeader,
  StyledTableHeaderCell,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableBodyCell,
  StyledTableHeadRow,
  StyledBodyArrowCell,
} from './styled'
import { TableColumnData, ITableProps } from './types'

const Table = <T extends TableColumnData>({
  columns = [],
  rows = [],
  layout = [],
  onRowClick,
  accordionBody,
  accordionProps,
  withRowShadow = true,
  onChangeOpenedIndexes,
  rowsGap,
  rowsHeight,
  withBodyRowBorder = false,
  leftPadding,
  rightPadding,
  topHeadPadding,
  bottomHeadPadding,
  leftHeadPadding,
  rightHeadPadding,
  columnsGap,
  padding,
  bodyRowBorderColor,
  withBorderLastBodyRow = true,
  headRowBorderColor,
  withHeadRowBorder,
  tableLayout,
}: ITableProps<T>) => {
  const tableRef = useRef<HTMLTableElement | null>(null)

  const [openedAccrodionIndexes, setOpenedAccrodionIndexes] = useState<number[]>([])

  const onToggleAccordionOpening = useCallback(
    (index: number) => {
      const isOpenedByIndex = openedAccrodionIndexes.includes(index)

      const openedIndexes = isOpenedByIndex
        ? openedAccrodionIndexes.filter((openedIndex) => openedIndex !== index)
        : [...openedAccrodionIndexes, index]

      setOpenedAccrodionIndexes(openedIndexes)
    },
    [openedAccrodionIndexes, onChangeOpenedIndexes]
  )

  const onRowClickHandler = (e, index: number, rowData: T) => {
    e.stopPropagation()

    onRowClick?.(rowData)
    accordionBody && onToggleAccordionOpening(index)
  }

  useEffect(() => void onChangeOpenedIndexes?.(openedAccrodionIndexes), [
    openedAccrodionIndexes,
    onChangeOpenedIndexes,
  ])

  const contentByRows = useMemo(() => rows.map((row) => accordionBody?.(row)), [
    rows,
    accordionBody,
  ])

  const tableWidth = tableRef.current?.clientWidth

  const getColumnWidthByTableWidth = useCallback(
    (width: number | string) => {
      if (tableWidth && width && typeof width === 'number') return `${(width * 100) / tableWidth}%`

      return width
    },
    [tableWidth]
  )

  return (
    <StyledTable
      padding={padding}
      ref={tableRef}
      rowsGap={rowsGap}
      cellSpacing={20}
      tableLayout={tableLayout}
    >
      <StyledTableHeader width={layout}>
        <StyledTableHeadRow withBorder={withHeadRowBorder} headRowBorderColor={headRowBorderColor}>
          {columns.map((column, index) => (
            <StyledTableHeaderCell
              maxWidth={String(getColumnWidthByTableWidth(column.width))}
              topHeadPadding={topHeadPadding}
              bottomHeadPadding={bottomHeadPadding}
              rightHeadPadding={typeof columnsGap === 'number' ? columnsGap : rightHeadPadding}
              leftHeadPadding={column.paddingLeft}
              leftPadding={leftPadding}
              rightPadding={rightPadding}
              headTextAlign={column.headTextAlign}
              key={index}
            >
              {column.title}
            </StyledTableHeaderCell>
          ))}

          {accordionBody && <StyledTableHeaderCell />}
        </StyledTableHeadRow>
      </StyledTableHeader>

      <StyledTableBody>
        {rows.map((row, index) => {
          const isOpened = openedAccrodionIndexes.includes(index)

          return (
            <Fragment key={index}>
              <StyledTableBodyRow
                withBorder={withBodyRowBorder && !isOpened}
                withBorderLastRow={withBorderLastBodyRow}
                bodyRowBorderColor={bodyRowBorderColor}
                height={rowsHeight}
                withRowShadow={withRowShadow}
                onClick={(e) => onRowClickHandler?.(e, index, row)}
                width={layout}
                clickable={!!onRowClickHandler}
              >
                {columns.map((column, index) => (
                  <StyledTableBodyCell
                    maxWidth={String(column.width)}
                    leftPadding={leftPadding}
                    rightPadding={rightPadding}
                    key={index}
                    label={column.title}
                    rightBodyCellPadding={
                      column.paddingRight ?? (typeof columnsGap === 'number' ? columnsGap : 10)
                    }
                    leftBodyCellPadding={column.paddingLeft}
                    wrapText={column.wrapText}
                    showAlways={column.showAlways}
                    bodyTextAlign={column.bodyTextAlign}
                    font={column.font}
                  >
                    {column.render ? column.render(row[column.id], row) : row[column.id]}
                  </StyledTableBodyCell>
                ))}

                {accordionBody && (
                  <StyledBodyArrowCell isOpened={isOpened}>
                    <Icon name="arrow-down" size="sm" />
                  </StyledBodyArrowCell>
                )}
              </StyledTableBodyRow>

              {accordionBody && (
                <StyledTableBodyRow
                  withBorder={withBodyRowBorder && isOpened}
                  bodyRowBorderColor={bodyRowBorderColor}
                  isHide={!isOpened}
                  width={layout}
                  withRowShadow={withRowShadow}
                >
                  <StyledTableBodyCell
                    leftPadding={leftPadding}
                    rightPadding={rightPadding}
                    bottomPadding={withBodyRowBorder ? 10 : 0}
                    colSpan={columns?.length + 1}
                    style={{
                      display: 'table-cell',
                    }}
                  >
                    <Accordion
                      bodyPaddingTop={0}
                      withShadow={false}
                      padding={0}
                      isOpen={isOpened}
                      {...accordionProps}
                    >
                      {contentByRows[index]}
                    </Accordion>
                  </StyledTableBodyCell>
                </StyledTableBodyRow>
              )}
            </Fragment>
          )
        })}
      </StyledTableBody>
    </StyledTable>
  )
}

export { Table }
export default memo(Table) as typeof Table

export * from './types'

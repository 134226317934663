const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[
    (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]
  ]
}
export default declOfNum

export const getDeclensionWordOrder = (number) => declOfNum(+number, ['поручение', 'поручения', 'поручений'])

export const getDeclensionWordSign = (number) => declOfNum(+number, ['подписано', 'подписаны', 'подписаны'])

export const getDeclensionWordDelete = (number) => declOfNum(+number, ['удалено', 'удалены', 'удалены'])

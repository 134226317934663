import { useSizeContext } from 'context/SizeContext'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { selectFormsData } from 'redux/profilePanel/selector'
import { selectCurrentUser } from 'redux/user/selector'
import { LegalEntityList, LEGAL_ENTITY } from 'types/client'
import { ClientFirmForm } from 'types/clientFirmForm'
import { VipInfo } from 'types/vipInfo'
import { Field, FormCol, FormRow, Icon, Radio, Text, TextField } from 'ui/components'
import Accordion from 'ui/components/molecules/Accordion'
import { theme } from 'ui/theme'
import { formatDateClient } from 'utils/GetDate'
import { isObjectEmpty } from 'utils/helpers/objects'
import RadioSwitch from '../../Tabs/CommonTab/components/RadioSwitch'
import AddressInformation from './AddressInformation'
import ContactInformation from './ContactInformation'
import Identification from './Identification'
import {
  StyledAccrodionTitle,
  StyledBeneficiaresRow,
  StyledOwnerRadioText,
  StyledRadioSwitch,
} from './styled'

const Beneficiares = () => {
  const size = useSizeContext()
  const isMobile = theme.bp.isXXS(size.width)
  const isTablet = theme.bp.isXS(size.width)

  const isDesktop = !isMobile && !isTablet

  const legalEntity: LegalEntityList = useSelector(selectCurrentUser)?.client?.legalEntity

  const formData: ClientFirmForm = useSelector(selectFormsData)?.forms[0]?.list[0]

  const data: VipInfo = formData?.vip

  const personalBeneficiares = !isObjectEmpty(formData?.beneficiary) ? [formData.beneficiary] : []

  const isFirm = legalEntity === LEGAL_ENTITY.FIRM

  return (
    <FormCol gap={isMobile ? 20 : 24}>
      {(isFirm ? data?.beneficiaries : personalBeneficiares)?.map(
        ({
          isController,
          fullName,
          nationality,
          birthDate,
          birthPlace,
          snils,
          inn,
          tin,
          isPublicOfficial,
          identity,
          migrationCard,
          legalResidence,
          address,
          postAddress,
          phones,
          emails,
          faxes,
          otherContact,
          shortName,
        }) => (
          <Accordion
            key={inn}
            customTitle={
              <StyledAccrodionTitle>
                {!isMobile && !isTablet && <Icon name="user" size="md" />} {shortName}
              </StyledAccrodionTitle>
            }
            variant="plain"
            bodyPaddingTop={isMobile || isTablet ? 28 : 40}
          >
            <FormCol gap={58}>
              <FormCol gap={28}>
                <FormRow gap={60}>
                  <Radio
                    alignItems="center"
                    {...(!isDesktop && {
                      justifyContent: 'flex-start',
                    })}
                    value="owner"
                    checked={!isController}
                  >
                    <StyledOwnerRadioText variant="body3">
                      Бенефициарный владелец
                      <br /> (преобладающее участие более 25 % в капитале)
                    </StyledOwnerRadioText>
                  </Radio>

                  <Radio
                    {...(!isDesktop && {
                      justifyContent: 'flex-start',
                    })}
                    alignItems="center"
                    value="other"
                    checked={isController}
                  >
                    <StyledOwnerRadioText variant="body3">
                      Лицо, которое прямо или косвенное контролирует действия клиента
                    </StyledOwnerRadioText>
                  </Radio>
                </FormRow>

                <FormCol gap={24}>
                  <StyledBeneficiaresRow tabletColumn mobileColumn>
                    <Field label="Фамилия, имя, отчество (при наличии последнего)">
                      <TextField disabled value={fullName} />
                    </Field>

                    <Field label="Гражданство">
                      <TextField disabled value={nationality.nameRu} />
                    </Field>
                  </StyledBeneficiaresRow>

                  <StyledBeneficiaresRow mobileColumn>
                    <Field label="Дата рождения">
                      <TextField disabled value={formatDateClient(birthDate)} />
                    </Field>

                    <Field label="Место рождения">
                      <TextField disabled value={birthPlace} />
                    </Field>
                  </StyledBeneficiaresRow>

                  <StyledBeneficiaresRow mobileColumn>
                    <Field label="СНИЛС">
                      <TextField disabled value={snils} />
                    </Field>

                    <Field label="ИНН">
                      <TextField disabled value={inn} />
                    </Field>

                    <Field label="TIN">
                      <TextField disabled value={tin} />
                    </Field>
                  </StyledBeneficiaresRow>
                </FormCol>

                <StyledRadioSwitch>
                  <RadioSwitch value={isPublicOfficial} title="Публичное должностное лицо" />
                </StyledRadioSwitch>
              </FormCol>

              <FormCol gap={32}>
                <Identification
                  identity={identity}
                  legalResidence={legalResidence}
                  migrationCard={migrationCard}
                />

                <AddressInformation addressData={address} title="Адрес регистрации" />

                <AddressInformation addressData={postAddress} title="Почтовый адрес" />

                <ContactInformation
                  phones={phones}
                  emails={emails}
                  faxes={faxes}
                  otherContact={otherContact}
                />
              </FormCol>
            </FormCol>
          </Accordion>
        )
      )}
    </FormCol>
  )
}

export default memo(Beneficiares)

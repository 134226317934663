import React from 'react'
import { Accordion } from 'ui/components/molecules/Accordion'
import { Field } from 'ui/components/molecules'
import { FormCol, TextField } from 'ui/components/atoms'
import { TextArea } from 'ui/components/atoms/TextArea'

import type { FC } from 'react'
import type { ContactsInfoProps } from './types'
import CustomerBlocksAccordion from '../CustomerBlocksAccordion'

/**
 * Сведения об учёте в налоговом органе.
 *
 * @param phones - Номера телефонов.
 * @param emails - Электронная почта .
 * @param faxes - Номера факсов .
 * @param otherContactInfo - Иная контактная информация.
 * @param isOpen - Открыт ли по умолчанию аккордеон.
 *
 * @constructor
 */
export const ContactsInfo: FC<ContactsInfoProps> = ({
  phones = [],
  emails = [],
  faxes = [],
  otherContactInfo = '',
  isOpen = false,
}) => {
  const SEPARATOR = ', '

  return (
    <CustomerBlocksAccordion
      bodyPaddingTop={28}
      title="Контактная информация"
      isOpenDefault={isOpen}
    >
      <FormCol gap={24}>
        {/*
          НОМЕРА ТЕЛЕФОНОВ (ЧЕРЕЗ ЗАПЯТУЮ, НЕ БОЛЕЕ 5-ТИ)
        */}
        <Field label="Номера телефонов (через запятую, не более 5-ти)">
          <TextField disabled value={phones?.join(SEPARATOR)} />
        </Field>

        {/*
          ЭЛЕКТРОННАЯ ПОЧТА (ЧЕРЕЗ ЗАПЯТУЮ, НЕ БОЛЕЕ 5-ТИ)
        */}
        <Field label="Электронная почта (через запятую, не более 5-ти)">
          <TextField disabled value={emails?.join(SEPARATOR)} />
        </Field>

        {/*
          НОМЕРА ФАКСОВ (ЧЕРЕЗ ЗАПЯТУЮ, НЕ БОЛЕЕ 5-ТИ)
        */}
        <Field label="Номера факсов (через запятую, не более 5-ти)">
          <TextField disabled value={faxes?.join(SEPARATOR)} />
        </Field>

        {/*
          ИНАЯ КОНТАКТНАЯ ИНФОРМАЦИЯ
        */}
        <Field label="Иная контактная информация">
          <TextArea disabled autoresize value={otherContactInfo} minAreaHeight={144} />
        </Field>
      </FormCol>
    </CustomerBlocksAccordion>
  )
}

import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import cuid from 'cuid'
import classnames from 'classnames'
import Media from 'react-media'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import useConnect from '../../utils/Connect'
import getPercent from '../../utils/GetPercent'
import getPrettyNumber from '../../utils/GetPrettyNumber'
import endpoints from '../../api/endpoints'
import { fetchValuationsStartAsync, getValuationsSuccess } from '../../redux/portfolio/portfolioSlice'
import useStyles from './panel.styles'
import CustomSelect from '../CustomControls/CustomSelect'
import { selectGetValuations } from '../../redux/portfolio/selector'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import MobileFilter from '../MobileFilter/MobileFilter'
import PageHeading from '../PageHeading/PageHeading'

const Tools = ({ fetchValuationsStart, connectedWS }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useState('')

  const [activeAccount, setActiveAccount] = useState()
  const [activeAccountNumber, setActiveAccountNumber] = useState()
  const [account, setAccount] = useState(location?.state?.activeAccount || '')
  const [accountList, setAccountList] = useState(null)

  const [activeCategory, setActiveCategory] = useState(location?.state?.activeCategory || '')
  const [category, setCategory] = useState('')
  const [categoryList, setCategoryList] = useState(null)

  const [totalAssets, setTotalAssets] = useState(0)
  const [instrumentsList, setInstrumentsList] = useState([])

  const {
    getData: getValuations,
  } = useConnect({
    link: `GET /api${endpoints.portfolio.valuations}`,
    startFetching: fetchValuationsStart,
    action: getValuationsSuccess,
  })

  const valuations = useSelector(selectGetValuations)

  const totalValuations = valuations?.totalValuations
  const categoriesValuations = valuations?.categoriesValuations
  const instrumentsValuations = valuations?.instrumentsValuations

  useEffect(() => {
    if (connectedWS) {
      getValuations()
    }
    if (
      location?.state?.activeAccount
      || location?.state?.activeCategory
      || location?.state?.categoryColor
    ) {
      setActiveAccount(location?.state?.activeAccount)
      setActiveCategory(location?.state?.activeCategory)
      history.replace({})
    } else {
      setActiveAccount(0)
      setActiveCategory(0)
    }
    return () => {
      location.state = undefined
      dispatch(getValuationsSuccess(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  useEffect(() => {
    const currentAccount = _.filter(
      accountList, (item) => item.account.id === activeAccount,
    )
    setActiveAccountNumber(currentAccount[0]?.account?.number)
    // eslint-disable-next-line
  }, [accountList])

  const calcTotalAssets = (list) => {
    setTotalAssets(0)
    _.map(list, (item) => {
      setTotalAssets((prev) => prev + item.valuationAssets)
    })
  }

  const getGroupList = (list) => {
    const groupedItems = _.groupBy(
      list,
      (item) => item.instrument.name,
    )
    return _.reduce(Object.keys(groupedItems), (memo, item) => {
      memo.push(groupedItems[item])
      return memo
    }, [])
  }

  const getCombinedList = (list) => _.reduce(list, (memo, item) => {
    if (item.length > 1) {
      const filteredObj = _.filter(item, (filterItem) => filterItem.account.id === account)[0]
      const copyObj = _.cloneDeep(item[0])
      copyObj.valuationAssets = _.sumBy(item, 'valuationAssets')
      copyObj.volume = _.sumBy(item, 'volume')
      const itemsObj = []
      item.forEach((innerItem) => {
        innerItem.details.forEach((innerDetailItem) => {
          itemsObj.push(_.cloneDeep(innerDetailItem))
        })
      })
      const groupItems = _.groupBy(itemsObj, 'instrument.name')
      Object.values(groupItems).forEach((groupItem) => {
        groupItem.forEach(({ volume }, index) => {
          if (index > 0) {
            groupItem[0].volume += volume
          }
        })
      })
      copyObj.details = _.uniqBy(itemsObj, 'instrument.name')
      memo.push(filteredObj || copyObj)
    } else {
      _.map(item, (obj) => {
        memo.push(obj)
      })
    }
    return memo
  }, [])

  useEffect(() => {
    if (totalValuations) {
      setAccountList(totalValuations)
      if (activeAccount) {
        setAccount(activeAccount)
        setActiveAccount(activeAccount)
      }
    }

    if (categoriesValuations) {
      const uniqueItems = _.uniqBy(categoriesValuations, (obj) => obj.category)
      setCategoryList(uniqueItems)
      if (activeCategory) {
        setCategory(activeCategory)
        setActiveCategory(activeCategory)
      }
    }

    if (instrumentsValuations) {
      const filters = _.pickBy({
        account: (account || activeAccount) && {
          id: (account || activeAccount) || undefined,
        },
        instrument: (category || activeCategory) && {
          category: (category || activeCategory) || undefined,
        },
      }, _.identity)
      const groupList = getGroupList(instrumentsValuations)
      const combinedList = getCombinedList(groupList)
      const filteredList = _.filter(combinedList, filters)
      setInstrumentsList(filteredList)
      calcTotalAssets(filteredList)
    }
    // eslint-disable-next-line
  }, [valuations])

  const updateList = () => {
    getValuations()
  }

  const handleChangeAccount = (event) => {
    const { value } = event.target
    setAccount(value)
    setOpen('')
    const currentAccount = _.filter(accountList, (item) => item.account.id === value)
    setActiveAccountNumber(currentAccount[0]?.account?.number)

    if (value !== 0) {
      const filteredList = _.filter(instrumentsValuations, (item) => {
        if (category) {
          return category === item.instrument.category && value === item.account.id
        }
        return value === item.account.id
      })
      setInstrumentsList(filteredList)
      calcTotalAssets(filteredList)
    } else {
      setActiveAccount(location?.state?.activeAccount)
      updateList()
    }
  }

  const handleChangeCategory = (event) => {
    const { value } = event.target
    setCategory(value)
    setOpen('')

    if (value !== 0) {
      const filteredList = _.filter(instrumentsValuations, (item) => {
        if (account) {
          return account === item.account.id && value === item.instrument.category
        }
        return value === item.instrument.category
      })
      const groupList = getGroupList(filteredList)
      const combinedList = getCombinedList(groupList)
      setInstrumentsList(combinedList)
      calcTotalAssets(combinedList)
    } else {
      setActiveCategory(location?.state?.activeCategory)
      updateList()
    }
  }

  const handleClickCollapse = (index) => {
    setOpen(open.includes(index) ? '' : `tabRow-${index}`)
  }

  const handleResetFilter = () => {
    setAccount(0)
    setActiveAccount(0)
    setActiveAccountNumber(0)
    setCategory(0)
    setActiveCategory(0)
    setInstrumentsList(instrumentsValuations)
  }

  return (
    <>
      <Box mb="17px">
        <PageHeading text="Инструменты" />
      </Box>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <Box pt="29px">
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                lg={3}
              >
                <CustomSelect
                  label="Счет"
                  value={account}
                  onChange={handleChangeAccount}
                  unique
                >
                  {accountList && accountList.map((item) => {
                    const { id, number } = item.account

                    return (
                      <MenuItem
                        key={id}
                        value={id}
                        defaultValue={activeAccount && activeAccount === id}
                      >
                        {number}
                      </MenuItem>
                    )
                  })}
                </CustomSelect>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                lg={3}
              >
                <CustomSelect
                  label="Класс инструмента"
                  value={category}
                  onChange={handleChangeCategory}
                  unique
                >
                  {categoryList && categoryList.map((item) => (
                    <MenuItem
                      key={cuid()}
                      value={item.category}
                      defaultValue={activeCategory && activeCategory === item.category}
                    >
                      {item.category}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
            </Grid>
            <TableContainer style={{ paddingTop: 25 }}>
              <Table aria-label="instrument table">
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableHeaderCell}>
                      Инструмент
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Цена
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Количество&nbsp;
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Оценка позиции, RUB&nbsp;
                    </TableCell>
                    <TableCell className={classes.tableHeaderCell}>
                      Доля, %&nbsp;
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instrumentsList && instrumentsList.map((item, index) => {
                    const instrument = item.instrument.name
                    const {
                      price, volume, valuationAssets, details, priceCurrency: { uid: currencyUid },
                    } = item

                    return (
                      <Fragment key={cuid()}>
                        <TableRow
                          className={classes.tableRow}
                          hover
                          onClick={() => handleClickCollapse(index)}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            width="40%"
                          >
                            {instrument}
                          </TableCell>
                          <TableCell>
                            {`${getPrettyNumber(price)} ${currencyUid}`}
                          </TableCell>
                          <TableCell>
                            {getPrettyNumber(volume)}
                          </TableCell>
                          <TableCell>
                            {getPrettyNumber(valuationAssets)}
                          </TableCell>
                          <TableCell>
                            {getPercent(valuationAssets, totalAssets)}
                            %
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleClickCollapse(index)}
                            >
                              {open === `tabRow-${index}` ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        {details && details.map((detailItem) => {
                          const { name } = detailItem?.instrument

                          return detailItem && (
                            <TableRow key={cuid()}>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
                                colSpan={2}
                                className={classes.nestedRow}
                              >
                                <Collapse
                                  in={open === `tabRow-${index}`}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box padding="16px">{name}</Box>
                                </Collapse>
                              </TableCell>
                              <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
                                colSpan={4}
                                className={classes.nestedRow}
                              >
                                <Collapse
                                  in={open === `tabRow-${index}`}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Box padding="16px">{getPrettyNumber(detailItem.volume)}</Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            <div className={classnames(classes.ActiveFilters, classes.ActiveFilters_unique)}>
              {(activeAccountNumber && (
                <span className={classes.ActiveFilters__text}>
                  Счёт №
                  {' '}
                  {activeAccountNumber}
                </span>
              )) || null}
            </div>
            <div className={classes.Tools}>
              {instrumentsList && instrumentsList.map((item) => {
                const instrument = item.instrument.name
                const {
                  price, volume, valuationAssets,
                } = item

                return (
                  <div
                    key={cuid()}
                    className={classes.Tools__item}
                  >
                    <div className={classes.Tools__title}>{instrument}</div>
                    <div className={classes.Tools__grid}>
                      <div className={classes.Tools__gridItem}>
                        <div className={classes.Tools__gridLabel}>Цена</div>
                        <div className={classes.Tools__gridValue}>{getPrettyNumber(price)}</div>
                      </div>
                      <div className={classes.Tools__gridItem}>
                        <div className={classes.Tools__gridLabel}>Оценка позиции, RUB</div>
                        <div className={classes.Tools__gridValue}>
                          {getPrettyNumber(valuationAssets)}
                        </div>
                      </div>
                      <div className={classes.Tools__gridItem}>
                        <div className={classes.Tools__gridLabel}>Количество</div>
                        <div className={classes.Tools__gridValue}>{getPrettyNumber(volume)}</div>
                      </div>
                      <div className={classes.Tools__gridItem}>
                        <div className={classes.Tools__gridLabel}>Доля, %</div>
                        <div className={classes.Tools__gridValue}>
                          {getPercent(valuationAssets, totalAssets)}
                          {' '}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <MobileFilter
              handleAcceptFilter={() => { }}
              handleResetFilter={handleResetFilter}
              constItems={[account, category]}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <CustomSelect
                    label="Счет"
                    value={account}
                    onChange={handleChangeAccount}
                    unique
                    inFilter
                  >
                    {accountList && accountList.map((item) => {
                      const { id, number } = item.account

                      return (
                        <MenuItem
                          key={id}
                          value={id}
                          defaultValue={activeAccount && activeAccount === id}
                        >
                          {number}
                        </MenuItem>
                      )
                    })}
                  </CustomSelect>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <CustomSelect
                    label="Класс инструмента"
                    value={category}
                    onChange={handleChangeCategory}
                    unique
                    inFilter
                  >
                    {categoryList && categoryList.map((item) => (
                      <MenuItem
                        key={cuid()}
                        value={item.category}
                        defaultValue={activeCategory && activeCategory === item.category}
                      >
                        {item.category}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </Grid>
              </Grid>
            </MobileFilter>
          </>
        )}
      />
    </>
  )
}

Tools.propTypes = {
  fetchValuationsStart: PropTypes.func.isRequired,
  connectedWS: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
})

const mapDispatchToProps = (dispatch) => ({
  fetchValuationsStart: () => dispatch(fetchValuationsStartAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tools)

import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  error: null,
  calculations: null,
}

const userSlice = createSlice({
  name: 'trades',
  initialState,
  reducers: {
    postCalculations(state) {
      state.error = null
    },
    postCalculationsSuccess(state, action) {
      state.calculations = action.payload
      state.error = null
    },
    postCalculationsFailed(state, action) {
      state.error = action.payload
    },
  },
})

const { actions, reducer } = userSlice

export const {
  postCalculations,
  postCalculationsSuccess,
  postCalculationsFailed,
} = actions

export default reducer

export const fetchPostCalculationsStartAsync = (reqObj) => async (dispatch) => {
  dispatch(postCalculations())
  try {
    await Api.postCalculations(reqObj)
  } catch (error) {
    dispatch(postCalculationsFailed())
  }
}

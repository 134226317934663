import { useDispatch } from 'react-redux'
import { fetchUserLogoutAsync } from '../redux/user/userSlice'
import { isDevelop } from '../config/constants'

const useLogoutOverTime = (time = 120) => {
  const dispatch = useDispatch()

  if (isDevelop) return

  const timeout = 1000 * time
  let lastActiveTimestamp = 0
  let userIsActive = false

  function active() {
    lastActiveTimestamp = new Date().getTime()
    if (!userIsActive) {
      userIsActive = true
    }
  }

  function checkUserIsActive() {
    if (userIsActive && new Date().getTime() - lastActiveTimestamp > timeout) {
      userIsActive = false
      dispatch(fetchUserLogoutAsync())
    }
  }

  window.addEventListener('mousemove', active)
  window.addEventListener('keypress', active)
  window.addEventListener('click', active)

  setInterval(checkUserIsActive, 1000)
  active()
}
export default useLogoutOverTime

import { styled } from "../../../../theme";

export const DocumentsTableFiltersWrapper = styled.div`
  max-width: 420px;
  width: 100%;
  margin-bottom: 20px;
`

export const DocumentsTableSignButtonsWrapper = styled.div`
  display: flex;
  column-gap: 10px;

  @media (${({ theme }) => theme.breakpoint.xs}) {
    flex-wrap: wrap;
    row-gap: 16px;
  }
`

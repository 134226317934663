import { Card } from "ui/components";
import { styled } from "ui/theme";
import { IPriceChangeCardProps } from "./types";

export const StyledPriceChangeCard = styled(Card) <Partial<IPriceChangeCardProps>>`
  ${({ plain = false }) =>
    plain ? `
      padding: 0;
      border: 0;
    ` : ``
  }
`
export const StyledPriceChangeCardLabel = styled.span`
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  ${({ theme }) => theme.font.body2};
  white-space: nowrap;
  @media(${({ theme }) => theme.breakpoint.xs}) {
    ${({ theme }) => theme.font.caption1};
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
  }
`

export const StyledPriceChangeCardBody = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const StyledBodyText = styled.div`
${({ theme }) => theme.font.h2};
@media(${({ theme }) => theme.breakpoint.xs}) {
  ${({ theme }) => theme.font.h3};
}
`
import useMedia from 'hooks/useMedia'
import React, { FC, memo } from 'react'
import { FormCol, FormRow, TextField } from 'ui/components/atoms'
import { Field, Select } from 'ui/components/molecules'
import Accordion from 'ui/components/molecules/Accordion'
import { formatDateClient } from 'utils/GetDate'
import { StyledRadioSwitch } from '../../../AdditionalInfo/Beneficiares/styled'
import RadioSwitch from '../../CommonTab/components/RadioSwitch'
import { IJurInfoBasic } from './types'

const Basic: FC<IJurInfoBasic> = ({ customer, clientInfo }) => {
  const { isMobile } = useMedia()

  const radioTitleMinWidth = 250

  const {
    birthDate,
    birthPlace,
    nationality: { nameRu = '' } = {},
    otherNationalities,
    inn,
    tin,
    snils,
  } = customer || {}

  const { taxResidency, currencyResidency, isPublicOfficial, isQualifiedInvestor } =
    clientInfo || {}

  return (
    <Accordion
      bodyPaddingTop={24}
      padding={isMobile ? '20px' : '32px'}
      variant="plain"
      title="Основные сведения"
    >
      <FormCol gap={28} gapMobile={16}>
        <FormRow gap={16} tabletRowDirection gapTablet={16} gapMobile={16}>
          <Field label="Дата рождения">
            <TextField disabled value={formatDateClient(birthDate)} />
          </Field>

          <Field label="Место рождения">
            <TextField disabled value={birthPlace} />
          </Field>
        </FormRow>

        <FormRow gap={16} tabletRowDirection gapTablet={16} gapMobile={16}>
          <Field label="Гражданство">
            <Select isDisabled value={nameRu} />
          </Field>

          <Field label="Гражданство других стран">
            <TextField
              disabled
              value={otherNationalities?.map((value) => value?.nameRu).join(', ') || ''}
            />
          </Field>
        </FormRow>

        <Field label="Налоговое резидентство">
          <Select
            isDisabled
            value={taxResidency?.uid === 'RUS' ? 'Резидент РФ' : 'Нерезидент РФ'}
          />
        </Field>

        <FormRow gap={16} tabletRowDirection gapTablet={16} gapMobile={16}>
          <Field label="ИНН">
            <TextField disabled value={inn} />
          </Field>

          <Field label="TIN">
            <TextField disabled value={tin} />
          </Field>

          <Field label="СНИЛС">
            <TextField disabled value={snils} />
          </Field>
        </FormRow>

        <StyledRadioSwitch>
          <RadioSwitch
            titleMinWidth={radioTitleMinWidth}
            value={!!currencyResidency}
            title="Валютный резидент РФ"
          />
        </StyledRadioSwitch>

        <StyledRadioSwitch>
          <RadioSwitch
            titleMinWidth={radioTitleMinWidth}
            value={!!isPublicOfficial}
            title="Публичное должностное лицо"
          />
        </StyledRadioSwitch>

        <StyledRadioSwitch>
          <RadioSwitch
            titleMinWidth={radioTitleMinWidth}
            value={!!isQualifiedInvestor}
            title="Квалифицированный инвестор"
          />
        </StyledRadioSwitch>
      </FormCol>
    </Accordion>
  )
}

export default memo(Basic)

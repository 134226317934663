import { StylesConfig, CommonProps, GroupBase } from 'react-select'
import { Option } from './types'
import { borderRadius, color, spacing, styled, font } from 'ui/theme'
import { getSelectBorderColor } from './data'

export function SelectStyles({
  hasError = false,
}: {
  hasError?: boolean
}): StylesConfig<Option, false, GroupBase<Option>> {
  return {
    control: (provided, { isFocused, isDisabled }) => ({
      ...provided,
      height: '44px',
      borderColor: getSelectBorderColor(isFocused, isDisabled, hasError),
      boxShadow: 'none',
      backgroundColor: color.bg.secondary,
      borderRadius: borderRadius.sm,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      border: `1px solid ${hasError && color.border.negative}`,
    }),
    singleValue: (provided, state) => {
      return {
        ...provided,
        padding: '12px',
        fontSize: '14px',
        lineHeight: '20px',
        margin: 0,
      }
    },
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: '0 12px 0 0',
      color: color.graphics.tertiary,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0',
      color: color.graphics.tertiary,
    }),
    input: (provided) => ({
      ...provided,
      color: color.text.primary,
      fontSize: '14px',
      lineHeight: '20px',
      padding: '0 12px',
      margin: 0,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '20px',
      padding: '0 12px',
      margin: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      color: color.text.primary,
      padding: 0,
    }),
    menu: (provided: any, state: any) => {
      return {
        ...provided,
        borderColor: color.border.default,
        borderRadius: borderRadius.sm,
        backgroundColor: color.bg.secondary,
        padding: 0,
      }
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        padding: spacing.xxs,
      }
    },
    option: (provided, state) => {
      return {
        ...provided,
        margin: 0,
        color: color.text.primary,
        fontSize: '14px',
        lineHeight: '20px',
        minHeight: '48px',
        padding: '14px 12px',
        borderRadius: borderRadius.sm,
        backgroundColor: state.isFocused || state.isSelected ? color.bg.primary : 'transparent',
        ':hover': {
          backgroundColor: color.bg.primary,
        },
      }
    },
  }
}

export const StyledNoOptionsMessage = styled.div`
  padding: 14px;
  color: ${color.text.secondary};
  ${font.caption1}
`

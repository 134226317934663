import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import cuid from 'cuid'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Media from 'react-media'
import CircularProgress from '../CustomCircularProgress/CustomCircularProgress'
import useConnect from '../../utils/Connect'
import getPercent from '../../utils/GetPercent'
import getPrettyNumber from '../../utils/GetPrettyNumber'
import endpoints from '../../api/endpoints'
import {
  fetchCategoriesStartAsync, fetchTotalStartAsync, getTotalSuccess, getCategoriesSuccess,
} from '../../redux/portfolio/portfolioSlice'
import useStyles from './panel.styles'
import CustomSelect from '../CustomControls/CustomSelect'
import { selectGetTotal, selectGetCategories } from '../../redux/portfolio/selector'
import PageHeading from '../PageHeading/PageHeading'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import MobileFilter from '../MobileFilter/MobileFilter'
import LinearProgress from '../LinearProgress/LinearProgress'
import { chartColors } from './colors'

const ClassTools = ({ fetchTotalStart, fetchCategoriesStart, connectedWS }) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [activeAccount, setActiveAccount] = useState()
  const [activeAccountNumber, setActiveAccountNumber] = useState()
  const [accountList, setAccountList] = useState(null)
  const [account, setAccount] = useState('')

  const [totalAssets, setTotalAssets] = useState(0)
  const [categoriesList, setCategoriesList] = useState({})

  const {
    getData: getTotal,
  } = useConnect({
    link: `GET /api${endpoints.portfolio.total}`,
    startFetching: fetchTotalStart,
    action: getTotalSuccess,
  })

  const {
    getData: getCategories,
  } = useConnect({
    link: `GET /api${endpoints.portfolio.categories}`,
    startFetching: fetchCategoriesStart,
    action: getCategoriesSuccess,
  })

  const total = useSelector(selectGetTotal)
  const categories = useSelector(selectGetCategories)

  const totalValuations = total?.valuations
  const categoriesValuations = categories?.valuations

  const filterItems = (value) => {
    const groupList = _.groupBy(categoriesValuations, (item) => item.category)
    setTotalAssets(0)
    const filteredList = {}
    if (activeAccount || value) {
      _.map(Object.keys(groupList), (keyName, index) => {
        const items = groupList[keyName]
        _.map(items, (item) => {
          if (item.account.id === (value || activeAccount)) {
            filteredList[index] = [item]
            setTotalAssets((prev) => prev + item.valuationAssets)
          }
        })
      })
    }
    const isFilteredListEmpty = Object.keys(filteredList).length
    setCategoriesList(isFilteredListEmpty ? filteredList : groupList)

    if (value === '' && !activeAccount) {
      setTotalAssets(null)
      _.map(categoriesValuations, (item) => {
        setTotalAssets((prev) => prev + item.valuationAssets)
      })
      setCategoriesList(groupList)
    }
  }

  useEffect(() => {
    if (activeAccount) {
      const currentAccount = _.filter(
        accountList, (item) => item.account.id === activeAccount,
      )
      setActiveAccountNumber(currentAccount[0]?.account?.number)
    } else {
      filterItems(0)
    }
    // eslint-disable-next-line
  }, [activeAccount])

  useEffect(() => {
    if (connectedWS) {
      getTotal()
      getCategories()
    }
    if (location?.state?.activeAccount) {
      setActiveAccount(location?.state?.activeAccount)
      history.replace({})
    } else {
      setActiveAccount(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  useEffect(() => {
    if (totalValuations) {
      setAccountList(totalValuations)
      if (activeAccount) {
        setAccount(activeAccount)
      }
    }
    // eslint-disable-next-line
  }, [total])

  const calcAssets = (list) => {
    let assets = 0
    _.map(list, (item) => {
      assets += item.valuationAssets
    })
    return assets
  }

  useEffect(() => {
    if (categoriesValuations) {
      filterItems()
      if (!activeAccount) {
        _.map(categoriesValuations, (item) => {
          setTotalAssets((prev) => prev + item.valuationAssets)
        })
      }
    }
    // eslint-disable-next-line
  }, [categories])

  const handleChangeAccount = (event) => {
    const { value } = event.target
    setAccount(value)
    if (activeAccount !== 0) {
      setActiveAccount(0)
    }
    filterItems(value)
    const currentAccount = _.filter(accountList, (item) => item.account.id === value)
    setActiveAccountNumber(currentAccount[0]?.account?.number)
  }

  const handleClickCategory = (category, color) => {
    const nextLocation = {
      pathname: 'tools',
      state: {
        activeAccount: account || undefined,
        activeCategory: category,
        categoryColor: color || undefined,
      },
    }
    history.push(nextLocation)
  }

  const handleResetFilter = () => {
    setAccount(0)
    setActiveAccount(0)
    setActiveAccountNumber(0)
    filterItems(0)
  }

  return (
    <>
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <Box mb="15px">
            <PageHeading text="Классы" />
          </Box>
        )}
      />
      <Media
        query="(min-width: 577px)"
        render={() => (
          <Box pt="29px">
            <Grid
              className={classes.topRow}
              container
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant="h5"
                  className={classes.heading}
                  style={{ fontSize: 22 }}
                >
                  По всем счетам
                </Typography>
              </Grid>
              <Grid item>
                <CustomSelect
                  label="По счетам"
                  value={account}
                  onChange={handleChangeAccount}
                  unique
                >
                  {accountList && accountList.map((item) => {
                    const { id, number } = item.account

                    return (
                      <MenuItem
                        key={id}
                        value={id}
                        defaultValue={activeAccount && activeAccount === id}
                      >
                        {number}
                      </MenuItem>
                    )
                  })}
                </CustomSelect>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                className={classes.circleContainer}
                container
              >
                {_.map(Object.keys(categoriesList), (keyName, index) => {
                  const { category } = categoriesList[keyName][0]
                  const assets = calcAssets(categoriesList[keyName])
                  const val = +parseFloat(getPercent(assets, totalAssets)).toFixed(2)

                  return (
                    <CircularProgress
                      key={cuid()}
                      val={val}
                      color={chartColors[index]}
                      label={category}
                    />
                  )
                })}
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.infoTable}
            >
              {_.map(Object.keys(categoriesList), (keyName, index) => {
                const { category } = categoriesList[keyName][0]
                const assets = calcAssets(categoriesList[keyName])

                return (
                  <Grid
                    key={cuid()}
                    item
                    container
                    className={classes.row}
                    onClick={() => handleClickCategory(category)}
                    style={{ borderLeftColor: chartColors[index] }}
                  >
                    <Grid
                      item
                      xs={4}
                      className={classes.col}
                    >
                      <span className={classes.title}>{category}</span>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      className={classes.col}
                    >
                      <span className={classes.text}>
                        {getPercent(assets, totalAssets)}
                        {' '}
                        %
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      className={classes.col}
                    >
                      <span className={classes.text}>
                        {getPrettyNumber(assets)}
                        {' '}
                        RUB
                      </span>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            <div className={classes.ActiveFilters}>
              {(activeAccountNumber && (
                <span className={classes.ActiveFilters__text}>
                  Счёт №
                  {' '}
                  {activeAccountNumber}
                </span>
              )) || null}
            </div>
            <div className={classes.ProgressItems}>
              {_.map(Object.keys(categoriesList), (keyName, index) => {
                const { category } = categoriesList[keyName][0]
                const assets = calcAssets(categoriesList[keyName])
                const percent = +parseFloat(getPercent(assets, totalAssets)).toFixed(2)

                return (
                  <LinearProgress
                    key={cuid()}
                    category={category}
                    assets={assets}
                    percent={percent}
                    color={chartColors[index]}
                    onClick={() => handleClickCategory(category, chartColors[index])}
                  />
                )
              })}
            </div>
            <MobileFilter
              handleAcceptFilter={() => { }}
              handleResetFilter={handleResetFilter}
              constItems={[account]}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <CustomSelect
                    label="Счет"
                    value={account}
                    onChange={handleChangeAccount}
                    unique
                    inFilter
                  >
                    {accountList && accountList.map((item) => {
                      const { id, number } = item.account

                      return (
                        <MenuItem
                          key={id}
                          value={id}
                          defaultValue={activeAccount && activeAccount === id}
                        >
                          {number}
                        </MenuItem>
                      )
                    })}
                  </CustomSelect>
                </Grid>
              </Grid>
            </MobileFilter>
          </>
        )}
      />
    </>
  )
}

ClassTools.propTypes = {
  fetchTotalStart: PropTypes.func.isRequired,
  fetchCategoriesStart: PropTypes.func.isRequired,
  connectedWS: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTotalStart: () => dispatch(fetchTotalStartAsync()),
  fetchCategoriesStart: () => dispatch(fetchCategoriesStartAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClassTools)

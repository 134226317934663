import { format } from 'date-fns'
import moment from "moment";

export const getDate = (date) => (date && new Date(date)) || null

export const formatDateServer = (date) => format(date, 'yyyy-MM-dd')
export const formatDateHourServer = (date) => format(date, 'yyyy-MM-dd')

export const formatDateClient = (date?: Date | string, withTime?: boolean) =>
  date && format(new Date(date), `dd.MM.yyyy${withTime ? ' HH:mm' : ''}`)

export const formatDateAndTime = (
  {
    date,
    time,
    dateFormat = 'DD.MM.YYYY',
    timeFormat = 'HH:mm',
    initialDateFormat = 'YYYY-MM-DD',
    initialTimeFormat = 'HH:mm:ss',
  }) => {
  const _date = moment(date, initialDateFormat)
  const _time = moment(time, initialTimeFormat)

  return `
  ${_date.isValid() ? _date.format(dateFormat) : ''}
   ${_time.isValid() ? _time.format(timeFormat) : ''}
  `
}

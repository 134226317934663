export const BASE_URLS = {
  feedback: '/feedback',
  reports: '/reports',
  profile: '/profile',
  portfolio: '/portfolio',
  transfers: '/transfers',
  documents: '/documents',
  sign: '/sign',
  contacts: '/contacts',
  scaner: '/scaner',
} as const

export const ROUTES = {
  ...BASE_URLS,
  feedbackNew: `${BASE_URLS.feedback}/new`,
  feedbackHistory: `${BASE_URLS.feedback}/history`,
  profileBasicInfo: `${BASE_URLS.profile}/basic-info`,
  profileAdditionalInfo: `${BASE_URLS.profile}/additional-info`,
  profileConfidant: `${BASE_URLS.profile}/additional-info/confidant`,
  profileBeneficiaries: `${BASE_URLS.profile}/additional-info/beneficiaries`,
  transfersSecuritiesLog: `${BASE_URLS.transfers}/securities/log`,

  reportsNew: `${BASE_URLS.reports}/log/new`,

} as const

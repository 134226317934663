import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Cookies from 'js-cookie'
import { CustomModal, Header } from 'ui/components';
import { Sidebar } from 'ui/components';
import { StyledMainLayout, StyledMainLayoutContent, StyledMainLayoutBody, StyledMainLayoutHeading, StyledMainLayoutTitle, StyledMainLayoutAction } from './styled';
import { IMainLayoutProps } from './types';
import { useSize } from 'hooks/useSize';
import { useTheme } from 'ui/theme';
import {
  Button,
  Icon
} from 'ui/components';
import { navList } from './data';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearNotificationsClone,
  getNotificationSuccess,
  openNotificationsModal
} from 'redux/notifications/notificationsSlice';
import { selectCurrentTheme } from 'redux/theme/selector'
import {
  selectCurrentNotificationsClone, selectLastIdNotifications,
  selectNotificationsModalOpen
} from 'redux/notifications/selector';
import { selectCurrentUser } from 'redux/user/selector';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import LightTheme from 'theme/LightTheme';
import DarkTheme from 'theme/DarkTheme';
import { useHistory, useLocation } from 'react-router-dom';
import useConnect from 'utils/Connect';
import { clearError } from 'redux/error/errorSlice';
import { selectConnectionState } from 'redux/connection/selector';
import { selectCurrentHeartbeats } from 'redux/heartbeats/selector';
import { selectCurrentError } from 'redux/error/selector';

import { fetchHeartBeatsStartAsync, getHeartbeatsSuccess } from 'redux/heartbeats/heartbeatsSlice';
import endpoints from 'api/endpoints';
import { setAccessToken } from 'redux/user/userSlice';
import { FloatingTip } from 'ui/components/atoms/FloatingTip';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setErrorStatusModal } from 'redux/ui/uiSlice';
import { selectIsErrorModalOpen, selectIsConfirmModalOpen } from 'redux/ui/selector';
import { useGetDocumentsListQuery } from "../../../../redux/documents/documentsApi";
import { ScrollContext } from 'hooks/useScrollTop'
import { useUpdateQueryCacheFromNotificationService } from "../../../../hooks/useUpdateQueryCacheFromNotificationService";

export const MainLayout: React.FC<IMainLayoutProps> = ({ title, actionBack, children }) => {
  const size = useSize();
  const theme = useTheme();
  const isMobile = theme.bp.isXS(size.width);

  const mainContentRef = useRef(null)

  const {
    data: needSignReports,
  } = useGetDocumentsListQuery('NeedSign')

  const isErrorModalOpen = useSelector(selectIsErrorModalOpen)
  const isConfirmModalOpen = useSelector(selectIsConfirmModalOpen)
  const notificationsClone = useSelector(selectCurrentNotificationsClone)
  const getNotificationsModalOpen = useSelector(selectNotificationsModalOpen)
  const currentUser = useSelector(selectCurrentUser)
  const currentTheme = useSelector(selectCurrentTheme)
  const connectedWS = useSelector(selectConnectionState)
  const heartbeats = useSelector(selectCurrentHeartbeats)
  const error = useSelector(selectCurrentError)
  const selectUser = useSelector(selectCurrentUser)


  const [showSidebar, setShowSidebar] = React.useState(false);


  const dispatch = useDispatch()
  const [notificationsCount, setNotificationsCount] = useState(0)


  useEffect(() => {
    if (notificationsClone) {
      const trueNotifications = _.filter(notificationsClone, (item) => item.message !== null)
      setNotificationsCount(trueNotifications.length)
    }
  }, [notificationsClone])

  const shortAgentName = currentUser && currentUser?.agent?.name

  const noticeOpen = () => {
    setNotificationsCount(0)
    dispatch(clearNotificationsClone())
  }

  const setIsErrorModalStatus = (status) => {
    dispatch(setErrorStatusModal(status))
  }

  const isThemeLight = currentTheme === 'light'
  // @ts-ignore
  const appliedTheme = createMuiTheme(isThemeLight ? LightTheme : DarkTheme)

  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  }

  // ===================================
  const history = useHistory()
  const location = useLocation()
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorTitle, setErrorTitle] = useState('')
  const [textList, setTextList] = useState([])
  const [timer, setTimer] = useState(10)
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false)

  useUpdateQueryCacheFromNotificationService()

  // useConnect({
  //   link: 'PushNotification',
  //   action: getNotificationSuccess,
  // })
  // @ts-ignore

  useEffect(() => {
    if (needSignReports?.length && getNotificationsModalOpen) {
      setNotificationsModalOpen(true)
    }
  }, [getNotificationsModalOpen, needSignReports])

  const handleCloseInfoModal = () => {
    setInfoModalOpen(false)
    dispatch({
      type: 'user/userLogout',
    })
    history.push('/')
  }

  const handleCloseErrorModal = () => {
    setIsErrorModalStatus(false)
    setErrorModalOpen(false)
    setTextList([])
    dispatch(clearError())
  }

  const handleCloseNotificationsModal = () => {
    dispatch(openNotificationsModal(false))
    setNotificationsModalOpen(false)
  }

  const handleNotificationsModalOk = () => {
    handleCloseNotificationsModal()
    history.push(`/sign/reports`)
  }

  const fetchHeartBeatsStart = () => {
    dispatch(fetchHeartBeatsStartAsync())
  }

  // const {
  //   getData: getHeartbeats,
  // } = useConnect({
  //   link: `GET /api${endpoints.heartbeats}`,
  //   startFetching: fetchHeartBeatsStart,
  //   action: getHeartbeatsSuccess,
  // })

  // useEffect(() => {
  //   let interval
  //   if (connectedWS) {
  //     interval = setInterval(() => {
  //       // getHeartbeats()
  //     }, 60000)
  //   }
  //   return () => {
  //     clearInterval(interval)
  //   }
  //   // eslint-disable-next-line
  // }, [connectedWS, heartbeats])

  useEffect(() => {
    let interval
    // TODO: временное решение, до починки логики токенов
    // const cookieToken = Cookies.get('accessToken') || Cookies.get('refreshToken')
    const cookieToken = Cookies.get('accessToken')
    if (!cookieToken) {
      setInfoModalOpen(true)
      interval = setInterval(() => {
        if (timer === 1) {
          handleCloseInfoModal()
        } else {
          setTimer((prev) => prev - 1)
        }
      }, 1000)
    }
    if (cookieToken && (!selectUser?.accessToken || cookieToken !== selectUser?.accessToken)) {
      dispatch(setAccessToken(cookieToken))
    }
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [selectUser, timer])

  useEffect(() => {
    if (!location?.pathname || (location?.pathname !== '/transfers/money/log'
      && location?.pathname !== '/transfers/securities/log')) {
      if (error && error?.errors) {
        Object.entries(error?.errors).forEach((item: any) => {
          item[1].forEach((subItem) => {
            setTextList((prev) => prev.concat(subItem))
          })
        })
        setErrorTitle(error?.title)
        if (isConfirmModalOpen) return;

        setErrorModalOpen(true)
        setIsErrorModalStatus(true)
      } else if (error && error?.Message) {
        setTextList((prev) => prev.concat(error?.Message))

        if (isConfirmModalOpen) return;
        setErrorModalOpen(true)
        setIsErrorModalStatus(true)
      }
    }
    return () => {
      setTextList([])
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    const needSignRedirect = localStorage.getItem('needSignRedirect')
    if (needSignReports?.length && needSignRedirect === '1') {
      history.push('/sign/reports')
      localStorage.setItem('needSignRedirect', '0')
    }
    // eslint-disable-next-line
  }, [needSignReports])

  useLayoutEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0)
    }
  }, [isMobile])

  return (
    <ThemeProvider theme={appliedTheme}>
      <ScrollContext.Provider value={mainContentRef} >
        <StyledMainLayout>
          <Sidebar onClose={handleToggleSidebar} items={navList} shown={(showSidebar || !isMobile)}>
            {isMobile ? <Header
              noticeOpen={noticeOpen}
              noticeCount={notificationsCount}
              agentName={shortAgentName}
              clientName={currentUser?.client?.shortName}
            /> : null}
          </Sidebar>
          <StyledMainLayoutContent ref={mainContentRef}>
            {
              !isMobile ? <Header
                noticeOpen={noticeOpen}
                noticeCount={notificationsCount}
                agentName={shortAgentName}
                clientName={currentUser?.client?.shortName}
              /> : null
            }
            <StyledMainLayoutHeading>
              {
                actionBack ?
                  <StyledMainLayoutAction variant="header" onClick={actionBack}><Icon name={'arrow-left'} size={32} /></StyledMainLayoutAction> :
                  <div />
              }
              <StyledMainLayoutTitle>{title}</StyledMainLayoutTitle>
              {
                isMobile ? <Button variant="header" onClick={handleToggleSidebar}><Icon name="bullets" size={'md'} /></Button> : null
              }
            </StyledMainLayoutHeading>
            <StyledMainLayoutBody>
              {children}
            </StyledMainLayoutBody>
          </StyledMainLayoutContent>

          <CustomModal
            open={infoModalOpen}
            title={`Вы будете перенаправлены на страницу авторизации через ${timer} секунды`}
            handleClose={handleCloseInfoModal}
            infinity
          />
          <CustomModal
            open={notificationsModalOpen}
            title="Есть документы требующие подпись."
            actionText="Перейти к разделу?"
            handleClose={handleCloseNotificationsModal}
            callbacks={{
              withOk: handleNotificationsModalOk,
              withCancel: handleCloseNotificationsModal,
            }}
            infinity
          />
          <CustomModal
            errorModal
            open={errorModalOpen}
            title={errorTitle}
            actionText={<ul>
              {
                textList.map((l, i: any) => <li key={i}>{l}</li>)
              }
            </ul>}
            handleClose={handleCloseErrorModal}
          />
          <FloatingTip />
          <ToastContainer
            position='bottom-right'
            autoClose={3000}
            hideProgressBar
          />
        </StyledMainLayout>
      </ScrollContext.Provider>
    </ThemeProvider>
  );
}

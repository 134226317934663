import React from 'react'
import { ThemeProvider } from 'styled-components'
import { SizeContext } from 'context/SizeContext'
import Scaner from '../../components/ScanerInfo/Scaner'
import { GlobalStyle, theme } from '../../ui/theme'
import { useSize } from '../../hooks/useSize'
import { MainLayout } from '../../ui/components/templates/MainLayout'

export default function ScanerInfo() {
  const size = useSize()

  return (
    <ThemeProvider theme={theme}>

      <GlobalStyle />

      <SizeContext.Provider value={size}>
        <MainLayout title="Сканер">
          <Scaner />
        </MainLayout>
      </SizeContext.Provider>
    </ThemeProvider>
  )
}

import { StylesConfig, CommonProps, GroupBase } from 'react-select';
import { Option } from './types';
import { borderRadius, color, spacing, styled, font } from 'ui/theme';
import { createGlobalStyle } from 'styled-components';

export function SelectStyles({ hasError = false }: { hasError?: boolean }): StylesConfig<Option, false, GroupBase<Option>> {
  return ({
    control: (provided, state) => ({
      ...provided,
      height: '44px',
      borderColor: state.isFocused ? color.button.primary : state.isDisabled ? color.bg.secondary : color.border.default,
      boxShadow: 'none',
      backgroundColor: color.bg.secondary,
      borderRadius: borderRadius.sm,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      border: `1px solid ${hasError && color.border.negative}`,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return {
        ...provided,
        opacity,
        transition,
        padding: '12px',
        fontSize: '14px',
        lineHeight: '20px',
        margin: 0,
      };
    },
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: '0 12px 0 0',
      color: color.graphics.tertiary,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0',
      color: color.graphics.tertiary,
    }),
    input: (provided) => ({
      ...provided,
      color: color.text.primary,
      fontSize: '14px',
      lineHeight: '20px',
      padding: '0 12px',
      margin: 0,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '20px',
      padding: '0 12px',
      margin: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      color: color.text.primary,
      padding: 0,
    }),
    menu: (provided: any, state: any) => {
      return {
        ...provided,
        borderColor: color.border.default,
        borderRadius: borderRadius.sm,
        backgroundColor: color.bg.secondary,
        padding: 0
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        padding: spacing.xxs,
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        margin: 0,
        color: color.text.primary,
        fontSize: '14px',
        lineHeight: '20px',
        padding: '14px 12px',
        borderRadius: borderRadius.sm,
        backgroundColor: state.isFocused || state.isSelected ? color.bg.primary : 'transparent',
        ':hover': {
          backgroundColor: color.bg.primary,
        },
      };
    },
  })
}

export const StyledNoOptionsMessage = styled.div`
  padding: 14px;
  color: ${color.text.secondary};
  ${font.caption1}
`

export const StyledWrapper = styled.div`
  .MuiFormLabel-root {
    display: none;
  }
  .MuiInputBase-root {
    padding-right: 0 !important;
    &:before, &:after {
      display: none;
    }
  }
  .MuiInputBase-input {
    height: 44px;
    padding: 12px;
    border: 1px solid ${color.border.default};
    border-radius: 8px;
    background-color: ${color.bg.secondary};
    color: ${color.text.primary};
    font-size: 14px;
    line-height: 20px;
    &:focus {
      border-color: ${color.button.primary}
    }
  }
  .MuiAutocomplete-endAdornment:after {
    display: none;
  }
  .Mui-disabled {
    color: #8C93A8 !important;
  }
`

export const StyledGlobal = createGlobalStyle`
  .MuiAutocomplete-listbox {
    border-radius: ${borderRadius.sm};
    border-color: ${color.border.default} !important;
    background-color: ${color.bg.secondary} !important;
    padding: ${spacing.xxs} !important;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: orange;
      background-color: ${color.graphics.disabled};
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${color.graphics.primary};
      border-radius: 4px;
    }
    .MuiAutocomplete-option {
      color: ${color.text.primary};
      font-size: 14px;
      line-height: 20px;
      padding: 14px 12px;
      border-radius: ${borderRadius.sm};
      &:hover {
        background-color: ${color.bg.primary} !important;
      }
    }
  }

`;

import React from 'react'
import TabsComponent from '../../components/Tabs/Tabs'
import { ReactComponent as TransfersSecuritiesIcon } from '../../assets/images/transfers-securities.svg'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import TradeOrder from '../../components/TradePanels/TradeOrder'
import PageHeading from '../../components/PageHeading/PageHeading'
import useWindowSize from '../../hooks/useWindows'

export default function Trades() {
  const { windowWidth } = useWindowSize()

  const tabLinks = [
    {
      key: '6acc4dde-1423-11eb-adc1-0242ac120002',
      link: 'tradeOrder',
      label: 'Торговое поручение',
      icon: windowWidth > 576 ? <TransfersSecuritiesIcon /> : <TransfersIcon />,
      component: TradeOrder,
    },
    {
      key: '75edca6c-1423-11eb-adc1-0242ac120002',
      link: 'repo',
      label: 'Репо',
      icon: windowWidth > 576 ? <TransfersSecuritiesIcon /> : <TransfersIcon />,
      component: TradeOrder,
    },
    {
      key: 'd38ebd14-a40d-11eb-bcbc-0242ac130002 ',
      link: 'primaryPlacement',
      label: 'Первичное размещение',
      icon: windowWidth > 576 ? <TransfersSecuritiesIcon /> : <TransfersIcon />,
      component: TradeOrder,
    },
  ]

  return (
    <>
      <PageHeading text="Торговля" />
      <TabsComponent
        tabLinks={tabLinks}
        wishStyle={{ justifyContent: 'flex-start' }}
      />
    </>
  )
}

import { styled } from "ui/theme";
import { IDataTableRowProps } from "./types";

export const StyledTable = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.bg.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

export const StyledTableHeader = styled.div<IDataTableRowProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ width }) => width.map(c => c || '1fr').join(' ')} 1fr;
  // padding: ${({ theme }) => `0 ${theme.spacing.lg} ${theme.spacing.sm}`};
  padding-top: 24px;
  padding-bottom: 24px;
  ${({ theme }) => `
    @media(${theme.breakpoint.xssm}) {
        display: none;
    }
  `}
`

export const StyledTableHeaderCell = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
  ${({ theme }) => theme.font.caption1};
  padding-right: 0;
  ${({ theme }) => `
    @media(${theme.breakpoint.md}) {
      padding: ${({ theme }) => theme.spacing.lg};
    }
  `}
`

export const StyledTableBody = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`

export const StyledTableBodyRow = styled.div<IDataTableRowProps>`
  display: grid;
  grid-template-columns: ${({ width }) => width.map(c => c || '1fr').join(' ')} 1fr;
  // padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.lg}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.bg.secondary};
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${({ theme }) => theme.color.bg.secondary}
  }
  ${({ theme }) => `
    padding: 24px 13px;
    @media ${theme.breakpoint.sm} {
      gap: 14px;
      display: flex;
      > div {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 14px;
      }
    }
    @media ${theme.breakpoint.xs} {
      gap: 14px;
      display: flex;
      flex-direction: column;
      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 14px;
      }
    }
  `}
  `

export const StyledTableBodyCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0;
  gap: ${({ theme }) => theme.spacing.xs};
  ${({ theme }) => theme.font.body2};
  &:last-child {
    justify-content: flex-end;
  }
  ${({ theme }) => `
    @media ${theme.breakpoint.md} {
      padding: ${({ theme }) => theme.spacing.lg};
    }
  `}
`

export const StyledTableMobileRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${() => `
    ${StyledTableHeaderCell} {
      width: 80px;
    }
  `}
`
export const StyledTableMobileCell = styled.div`
  flex: 1;
  overflow: hidden;
  ${({ theme }) => theme.font.body1_lg};
`

export const StyledCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px !important;

  @media (${({theme}) => theme.breakpoint.xs}) {
    position: absolute;
    right: 32px;
  }
`

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { fetchPassStartAsync } from '../../../../redux/user/userSlice'
import { selectNewPass } from '../../../../redux/user/selector'
import {
  StyledLoginWrapper,
  StyledLoginTitle,
  StyledLabel,
  StyledField,
  StyledErrorText,
  StyledFieldSecond,
  StyledSecurityIcon,
  StyledButton,
} from '../../../../components/loginRedesign/styled'
import { Button, Text, TextField } from '../../atoms'
import Eye from '../../../../pages/Login/assets/Eye.svg'
import EyeClose from '../../../../pages/Login/assets/EyeClose.svg'

const NewPass = ({
  fetching, fetchPassStart, setArraySteps, arraySteps,
}) => {
  const [passwordError, setPasswordError] = useState(false)
  const [rePasswordError, setRePasswordError] = useState(false)
  const [passwordErrorText, setPasswordErrorText] = useState('')
  const [rePasswordErrorText, setRePasswordErrorText] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const currentPass = useSelector(selectNewPass)
  const [value, setValue] = useState({
    password: '',
    rePassword: '',
  })

  const checkCorrectResponse = (response) => {
    if (response?.Password1?.length) {
      setPasswordErrorText(response?.Password1[0])
      setPasswordError(true)
      setRePasswordError(true)
    } else {
      const respData = response?.status
      switch (respData?.status || response?.status) {
        case 401:
          setRePasswordErrorText(respData?.title)
          break
        case 403:
          setRePasswordErrorText('Запрещено менять пароль')
          break
        default:
          setArraySteps([...arraySteps, 'password'])
          setPasswordErrorText('')
          setRePasswordErrorText('')
      }
    }
  }

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    const { password, rePassword } = value
    if (!password) {
      setPasswordError(true)
      setPasswordErrorText('Поле обязательно для заполнения')
      return
    }
    if (!rePassword) {
      setRePasswordError(true)
      setRePasswordErrorText('Поле обязательно для заполнения')
      return
    }
    if (rePassword !== password) {
      setRePasswordError(true)
      setRePasswordErrorText('Пароли должны совпадать')
      return
    }
    await fetchPassStart(value.password, value.rePassword).then((response) => {
      checkCorrectResponse(response)
    })
  }

  const handleChangeInput = (event, error) => {
    setValue({ ...value, [event.target.name]: event.target.value })
    error(false)
    setPasswordErrorText('')
  }

  return (
    <StyledLoginWrapper>
      <StyledLoginTitle>
        <Text
          variant="h1"
          color="primary"
        >
          Установка пароля
        </Text>
      </StyledLoginTitle>
      <form onSubmit={handleSubmitForm}>
        <StyledLabel>
          <Text
            variant="body2"
            color="secondary"
          >
            Логин
          </Text>
        </StyledLabel>

        <StyledField>
          <TextField
            value={currentPass.login}
            name="username"
            disabled
            type="email"
          />
        </StyledField>

        <StyledLabel>
          <Text
            variant="body2"
            color="secondary"
            hasError={passwordError}
          >
            Новый пароль
          </Text>
        </StyledLabel>

        <StyledFieldSecond>
          <TextField
            hasError={passwordError}
            onChange={(event) => handleChangeInput(event, setPasswordError)}
            value={value.password}
            name="password"
            type={showPassword ? 'text' : 'password'}
          />
          <StyledSecurityIcon>
            {showPassword ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <img
                src={Eye}
                alt=""
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <img
                src={EyeClose}
                alt=""
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </StyledSecurityIcon>

          {passwordErrorText && (
            <StyledErrorText>
              <Text
                variant="caption1"
                color="negative"
              >
                {passwordErrorText}
              </Text>
            </StyledErrorText>
          )}
        </StyledFieldSecond>

        <StyledLabel>
          <Text
            variant="body2"
            color="secondary"
            hasError={rePasswordError}
          >
            Подтвердите пароль
          </Text>
        </StyledLabel>

        <StyledFieldSecond>
          <TextField
            hasError={rePasswordError}
            onChange={(event) => handleChangeInput(event, setRePasswordError)}
            value={value.rePassword}
            name="rePassword"
            type={showPassword ? 'text' : 'password'}
          />
          <StyledSecurityIcon>
            {showPassword ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <img
                src={Eye}
                alt=""
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <img
                src={EyeClose}
                alt=""
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </StyledSecurityIcon>

          {rePasswordErrorText && (
            <StyledErrorText>
              <Text
                variant="caption1"
                color="negative"
              >
                {rePasswordErrorText}
              </Text>
            </StyledErrorText>
          )}
        </StyledFieldSecond>

        <StyledButton>
          <Button
            type="submit"
            disabled={fetching}
          >
            Установить пароль
          </Button>
        </StyledButton>
      </form>
    </StyledLoginWrapper>
  )
}

NewPass.defaultProps = {
  fetching: false,
}

NewPass.propTypes = {
  fetching: PropTypes.bool,
  fetchPassStart: PropTypes.func.isRequired,
  setArraySteps: PropTypes.func.isRequired,
  arraySteps: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

const mapStateToProps = ({ user: { fetching, code } }) => ({
  fetching,
  code,
})

const mapDispatchToProps = (dispatch) => ({
  fetchPassStart: (password1, password2) => dispatch(fetchPassStartAsync({ password1, password2 })),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewPass)

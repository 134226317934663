import React from "react";
import { ContactCardProps, ContactData } from "ui/components/pages/Contacts/types";
import {
  CardInfoBlock,
  CardTitle,
  CardWrapper,
  ContactInfoKey,
  ContactInfoRow,
  ContactInfoValue
} from "ui/components/pages/Contacts/styled";
import type { FC } from "react";

const ContactRow: FC<ContactData> = ({
   contactKey,
   contactValue,
   link,
   isPhone,
   isMail,
 }) => {
  return (
    <ContactInfoRow>
      <ContactInfoKey>{contactKey}</ContactInfoKey>

      <ContactInfoValue
        isEmail={isMail}
        href={`${isPhone ? 'tel:' : ''}${isMail ? 'mailto:' : ''}${link}`}
      >
        {contactValue}
      </ContactInfoValue>
    </ContactInfoRow>
  )
}

/**
 * Карточка контактной информации.
 *
 * @param title - заголовок карточки контакта
 * @param fields - список полей контактной информации
 * @param children - уникальный контент
 * @constructor
 */
export const ContactCard: FC<ContactCardProps> = ({
  title,
  fields,
  children,
}) => {

  return (
    <CardWrapper>
      <CardTitle>{title}</CardTitle>

      {
        !children && (
          <CardInfoBlock>
            <>
              {fields.map((data) => (<ContactRow key={data.contactValue} {...data}/>))}
            </>
          </CardInfoBlock>
        )
      }

      {children}
    </CardWrapper>
  )
}

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    position: 'relative',
    display: 'inline-flex',
  },
  circularStatic: {
    position: 'absolute',
    top: 1,
    left: 1,
  },
  circularDynamic: {
    position: 'relative',
    transition: 'none',
    '& .MuiCircularProgress-circle': {
      transition: '1s ease-out',
    },
  },
  boxInner: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 24,
  },
  percent: {
    position: 'absolute',
    top: -4,
    fontSize: 20,
    lineHeight: '24px',
  },
  count: {
    marginBottom: 2,
    fontSize: 34,
    lineHeight: '32px',
    color: theme.palette.type === 'light' ? '#363E47' : '#fff',
  },
  label: {
    maxWidth: '80%',
    fontSize: 14,
    lineHeight: '18px',
    textAlign: 'center',
    color: theme.palette.type === 'light' ? '#363E47' : '#91B1BF',
  },
}))
export default useStyles

import { styled } from 'ui/theme'
import { isNumb } from 'utils/helpers/numbers'
import { ITableCell, ITableHeadCell, ITableHeadRow, ITableRowProps } from './types'

export const StyledTable = styled.table<{
  rowsGap?: number
  padding?: string
  tableLayout?: string
}>`
  width: 100%;
  background-color: ${({ theme }) => theme.color.bg.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border-collapse: separate;
  ${({ rowsGap }) => {
    const gap = `${rowsGap ?? 16}px`

    return `
      border-spacing: 0 ${gap};
      margin-top: -${gap};
      margin-bottom: -${gap};
    `
  }}
  table-layout: ${({ tableLayout }) => tableLayout || 'fixed'};

  ${({ padding }) => padding && `padding: ${padding};`}

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    & td,
    th {
      max-width: none;
    }

    border-spacing: 0 16px;
    margin: -16px 0;
  }
`

export const StyledTableHeader = styled.thead<ITableRowProps>`
  padding: ${({ theme }) => `0 ${theme.spacing.lg} ${theme.spacing.sm}`};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.bg.secondary};

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    display: none;
  }
`

export const StyledTableHeadRow = styled.tr<ITableHeadRow>`
  position: relative;

  ${({ withBorder, theme: { color }, headRowBorderColor }) =>
    withBorder &&
    `
      &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${headRowBorderColor ?? color.border.default};
      }
  `}
`

export const StyledTableHeaderCell = styled.th<ITableHeadCell>`
  ${({ topHeadPadding }) => (isNumb(topHeadPadding) ? `padding-top: ${topHeadPadding}px` : '')};
  ${({ rightHeadPadding }) =>
    isNumb(rightHeadPadding) ? `padding-right: ${rightHeadPadding}px` : ''};
  ${({ bottomHeadPadding }) =>
    isNumb(bottomHeadPadding) ? `padding-bottom: ${bottomHeadPadding}px` : ''};
  ${({ leftHeadPadding }) => (isNumb(leftHeadPadding) ? `padding-left: ${leftHeadPadding}px` : '')};

  ${({ maxWidth }) => maxWidth && `width: ${maxWidth};`}

  text-align: ${({ headTextAlign }) => headTextAlign || 'left'};
  color: ${({ theme }) => theme.color.text.secondary};
  ${({ theme }) => theme.font.caption1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    padding-left: ${({ leftPadding }) => leftPadding ?? 20}px;
  }

  &:last-child {
    padding-right: ${({ rightPadding }) => rightPadding ?? 25}px;
  }
`

export const StyledTableBody = styled.tbody`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`

export const StyledTableBodyRow = styled.tr<ITableRowProps>`
  position: relative;
  height: ${({ height }) => height ?? 60}px;
  border-bottom: 1px solid
    ${({ theme, bodyRowBorderColor }) => bodyRowBorderColor ?? theme.color.bg.secondary};
  ${({ withRowShadow }) => withRowShadow && 'box-shadow: 0px 0px 20px 2px rgba(49, 58, 78, 0.06);'};
  transition: 0.2s;
  transition-property: opacity, visibility, height;

  ${({ isHide }) =>
    isHide &&
    `
    height: 0;
    visibility: collapse;
  `}

  ${({ clickable }) =>
    clickable &&
    `
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
  `}

  ${({ withBorder, theme: { color }, bodyRowBorderColor, withBorderLastRow }) =>
    withBorder &&
    `
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${bodyRowBorderColor ?? color.border.default};
  }

  ${
    !withBorderLastRow &&
    `
    &:last-child::after {
      display:none;
    }
  `
  }
  `}
`

export const StyledTableBodyCell = styled.td<ITableCell>`
  ${({ wrapText }) =>
    !wrapText &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};

  ${({ showAlways }) =>
    showAlways &&
    `
  white-space: nowrap;
  overflow: visible;
  `};

  ${({ theme, font }) => font ?? theme.font.body2};

  ${({ bottomPadding }) => (isNumb(bottomPadding) ? `padding-bottom: ${bottomPadding}px` : '')};

  ${({ leftBodyCellPadding }) =>
    isNumb(leftBodyCellPadding) ? `padding-left: ${leftBodyCellPadding}px` : ''};
  ${({ rightBodyCellPadding }) =>
    isNumb(rightBodyCellPadding) ? `padding-right: ${rightBodyCellPadding}px` : ''};

  ${({ bodyTextAlign }) => bodyTextAlign && `text-align: ${bodyTextAlign}`};

  &:first-child {
    padding-left: ${({ leftPadding }) => leftPadding ?? 20}px;
  }

  &:last-child {
    padding-right: ${({ rightPadding }) => rightPadding ?? 25}px;
  }

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0;
    margin-bottom: 12px;
    white-space: break-spaces;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid ${({ theme: { color } }) => color.text.tertiary};
    }

    &::before {
      content: attr(label);
      color: ${({ theme: { color } }) => color.text.secondary};

      ${({ theme: { font } }) => font.caption1};
    }
  }
`

export const StyledBodyArrowCell = styled.td<{ isOpened: boolean }>`
  width: 75px;
  height: 100%;
  text-align: center;

  & svg {
    transform: rotate(${({ isOpened }) => (isOpened ? 180 : 0)}deg);
    transition: 0.2s transform;
  }

  @media ${({ theme: { breakpoint } }) => breakpoint.xs} {
    width: auto;
  }

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    width: 20px;
  }
`

import { theme } from 'ui/theme'
import { ITagColorsByType } from './types'

export const tagColorsByType: ITagColorsByType = {
  success: {
    background: theme.color.bg.positiveTransparent,
    color: theme.color.text.positive,
  },
  neutral: {
    background: theme.color.bg.secondary,
    color: theme.color.text.secondary,
  },
  error: {
    background: theme.color.bg.negative,
    color: theme.color.text.negative,
  },
} as const

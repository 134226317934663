import React from "react";
import { StyledSubTitle, StyledTitle, StyledUnavailableTabWrapper } from "./styled";

const TemporarilyUnavailableTab = () => {
  return (
    <StyledUnavailableTabWrapper>
      <StyledTitle>Ведутся технические работы</StyledTitle>
      <StyledSubTitle>Скоро здесь появится новый функционал</StyledSubTitle>
    </StyledUnavailableTabWrapper>
  )
}

export default TemporarilyUnavailableTab

export const initialTransferGroupData = {
  searchStr: '',
  securityNameError: false,
  securityNameTextError: '',

  securitiesList: null,

  amount: undefined,
  volumeError: false,
  volumeTextError: '',

  validity: new Date(),
  expirationDateError: false,
  expirationDateTextError: '',

  groupTwoVisibility: false,

  issuer: {
    id: null,
    isin: '',
    name: '',
    registerNumber: '',
    type: {
      name: '',
    },
    faceValue: '',
    faceValueCurrency: {
      uid: '',
    },
  },
}


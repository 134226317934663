import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  access: {
    scaner: false,
    portfolio: true,
  },
  error: null,
}

const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    getScanerPermissions(state) {
      return {
        ...state,
        error: null,
      }
    },
    fetchScanerPermissionsSuccess(state, action) {
      return {
        ...state,
        access: {
          ...state.access,
          scaner: !!action.payload.scannerParams,
        },
        error: null,
      }
    },
    fetchScanerPermissionsFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
  },
})

const { actions, reducer } = accessSlice

export const {
  getScanerPermissions,
  fetchScanerPermissionsSuccess,
  fetchScanerPermissionsFailed,
} = actions

export default reducer

export const fetchScanerPermissionsStartAsync = () => async (dispatch) => {
  dispatch(getScanerPermissions())
  try {
    await Api.getScanerPermissions()
  } catch (error) {
    dispatch(fetchScanerPermissionsFailed(error?.message))
  }
}

import { createSlice } from '@reduxjs/toolkit'
import getCadespluginAPI from 'async-cadesplugin'
import Api from '../../../../api/Api'
import {getErrorSuccess} from "../../../error/errorSlice";

const initialState = {
  getOutputMoney: null,
  error: null,
  saveOutputMoney: null,
  saveOutputError: null,
  performOutputMoney: null,
  performOutputError: null,
  banks: [],
  paymentTemplates: [],
}

const outputMoneySlice = createSlice({
  name: 'outputMoney',
  initialState,
  reducers: {
    getOutputMoney(state) {
      return {
        ...state,
        error: null,
      }
    },
    getOutputMoneySuccess(state, action) {
      return {
        ...state,
        getOutputMoney: action.payload,
      }
    },
    getOutputMoneyFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    saveOutputMoney(state) {
      return {
        ...state,
        saveOutputError: null,
      }
    },
    saveOutputMoneySuccess(state, action) {
      return {
        ...state,
        saveOutputMoney: action.payload,
      }
    },
    saveOutputMoneyFailed(state, action) {
      return {
        ...state,
        saveOutputError: action.payload,
      }
    },
    performOutputMoney(state) {
      return {
        ...state,
        performOutputError: null,
      }
    },
    performOutputMoneySuccess(state, action) {
      return {
        ...state,
        performOutputMoney: action.payload,
      }
    },
    performOutputMoneyFailed(state, action) {
      return {
        ...state,
        performOutputError: action.payload,
      }
    },
    clearOutputMoney() {
      return initialState
    },
    clearPartOutputMoney(state) {
      return {
        ...state,
        error: null,
        saveOutputMoney: null,
        saveOutputError: null,
        performOutputMoney: null,
        performOutputError: null,
      }
    },
    getBanks(state) {
      return { ...state }
    },
    getBanksSuccess(state, action) {
      return {
        ...state,
        banks: action.payload,
      }
    },
    getBanksFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getPaymentTemplates(state) {
      return { ...state }
    },
    getPaymentTemplatesSuccess(state, action) {
      return {
        ...state,
        paymentTemplates: action.payload,
      }
    },
    getPaymentTemplatesFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
  },
})

const { actions, reducer } = outputMoneySlice

export const {
  getOutputMoney,
  getOutputMoneySuccess,
  getOutputMoneyFailed,
  saveOutputMoney,
  saveOutputMoneySuccess,
  saveOutputMoneyFailed,
  performOutputMoney,
  performOutputMoneySuccess,
  performOutputMoneyFailed,
  clearOutputMoney,
  clearPartOutputMoney,
  getBanks,
  getBanksSuccess,
  getBanksFailed,
  getPaymentTemplates,
  getPaymentTemplatesSuccess,
  getPaymentTemplatesFailed,
} = actions

export default reducer

export const fetchOutputMoneyStartAsync = ({ id }) => async (dispatch) => {
  dispatch(getOutputMoney())
  try {
    const value = await Api.getTransferMoney(id)
    dispatch(getOutputMoneySuccess(value))
  } catch (err) {
    dispatch(getOutputMoneyFailed())
  }
}

export const fetchSaveOutputMoneyStartAsync = ({ data, id = null }) => async (dispatch) => {
  dispatch(saveOutputMoney())
  try {
    if (id) {
      const value = await Api.putTransferMoney(data, id)
      dispatch(saveOutputMoneySuccess(value))
    } else {
      const value = await Api.postTransferMoney(data, false)
      dispatch(saveOutputMoneySuccess(value))
    }
  } catch (err) {
    dispatch(saveOutputMoneyFailed())
    dispatch(getErrorSuccess(err))
  }
}

export const fetchPerformOutputMoneyStartAsync = ({ data, id = null }) => async (dispatch) => {
  dispatch(performOutputMoney())
  try {
    if (id) {
      if (data?.thumbPrint && data?.signedXml) {
        const api = await getCadespluginAPI()
        const signature = await api.signXml(data.thumbPrint, data.signedXml)
        const signedObj = data
        signedObj.signedXml = signature
        const value = await Api.putTransferMoney(signedObj, id, true)
        dispatch(performOutputMoneySuccess(value))
      } else {
        const value = await Api.putTransferMoney(data, id, true)
        dispatch(performOutputMoneySuccess(value))
      }
    } else {
      const value = await Api.postTransferMoney(data, true)
      dispatch(performOutputMoneySuccess(value))
    }
  } catch (err) {
    dispatch(performOutputMoneyFailed())
    dispatch(getErrorSuccess(err))
  }
}

export const fetchGetBanksStartAsync = (filterBody) => async (dispatch) => {
  dispatch(getBanks())
  try {
    const value = await Api.getBanks(filterBody)
    dispatch(getBanksSuccess(value))
  } catch (error) {
    dispatch(getBanksFailed(error))
  }
}

export const fetchGetPaymentTemplatesStartAsync = (filterBody) => async (dispatch) => {
  dispatch(getPaymentTemplates())
  try {
    const value = await Api.getPaymentTemplates(filterBody)
    dispatch(getPaymentTemplatesSuccess(value))
  } catch (error) {
    dispatch(getPaymentTemplatesFailed(error))
  }
}

export const fetchDeletePaymentTemplateStartAsync = (id) => async (dispatch) => {
  try {
    await Api.deletePaymentTemplate(id)
  } catch (error) {
    dispatch(getPaymentTemplatesFailed(error))
  }
}

import React from 'react'
import Media from 'react-media'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import useStyles from './sharedStyles'
import CustomInput from '../../CustomControls/CustomInput'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

const GoovCodesContent = ({ data, handleClickNextPanel, isNeedNextButton }) => {
  const classes = useStyles()

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Grid
              container
              className={classes.row}
              spacing={2}
            >
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="okapo"
                  label="ОКПО"
                  value={data?.okpo || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="okato"
                  label="ОКАТО(при наличии)"
                  value={data?.okato || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="okved"
                  label="ОКВЭД"
                  value={data?.okved || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="okfc"
                  label="ОКФС"
                  value={data?.okfs || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="kodley"
                  label="Код LEI"
                  value={data?.lei || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <CustomInput
                  multiline
                  rows={2}
                  name="codes"
                  label="Иные коды форм федерального государственного статистического наблюдения"
                  value={data?.otherStatisticsCodes || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
            </Grid>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            {data?.okpo && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  ОКПО
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.okpo}
                </div>
              </div>
            )}
            {data?.okato && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  ОКАТО(при наличии)
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.okato}
                </div>
              </div>
            )}
            {data?.okved && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  ОКВЭД
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.okved}
                </div>
              </div>
            )}
            {data?.okfs && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  ОКФС
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.okfs}
                </div>
              </div>
            )}
            {data?.lei && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Код LEI
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.lei}
                </div>
              </div>
            )}
            {data?.otherStatisticsCodes && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Иные коды форм федерального государственного статистического наблюдения
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.otherStatisticsCodes}
                </div>
              </div>
            )}
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </>
        )}
      />
    </>
  )
}

GoovCodesContent.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

GoovCodesContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

export default GoovCodesContent

import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components'
import { MainLayout } from 'ui/components/templates/MainLayout';
import { theme, GlobalStyle } from 'ui/theme';
import { useSize } from 'hooks/useSize';
import { SizeContext } from 'context/SizeContext';
import { Icon, Tab, Tabs } from 'ui/components';
import { StyledContent, StyledTop } from './styled';
import { Button } from 'ui/components';
import { Money } from './components/Money';
import { Securities } from './components/Securities';
import { Requisites } from './components/Requisites';
import { NavLink, Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';


export const Transfers: React.FC = () => {

  const { url, path } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const { pathname } = location
  const tabLinks: any = [
    {
      link: 'money',
      label: 'Денежные средства',
      component: Money,
    },
    {
      link: 'securities',
      label: 'Ценные бумаги',
      component: Securities,
    },
    {
      link: 'requisites',
      label: 'Реквизиты',
      component: Requisites,
    }
  ]
  const page = pathname.split('/')[2];

  return <MainLayout
    actionBack={
      page === 'requisites' ? () => history.push('/transfers') : undefined
    }
    title={
      page === 'requisites' ? <>

        <span>
          Реквизиты
        </span>
      </> : 'Поручения'
    }>
    {
      page !== 'requisites' &&
      <StyledTop>
        <Tabs>
          <Tab
            label="Денежные средства"
            component={NavLink}
            to={`${url}/money`}
          />
          <Tab
            label="Ценные бумаги"
            component={NavLink}
            to={`${url}/securities`}
          />
        </Tabs>
        <Button variant="tertiary" onClick={() => history.push(`${url}/requisites`)}>Реквизиты</Button>
      </StyledTop>
    }
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/${tabLinks[0].link}`} />
      </Route>
      {tabLinks.map((currentLink: any) => (
        <Route
          key={currentLink.link}
          path={`${path}/${currentLink.link}`}
          component={currentLink.component}
        />
      ))}
    </Switch>
  </MainLayout>
}

export const TransfersNew: React.FC = () => {
  const size = useSize();
  return <ThemeProvider theme={theme}>
    <GlobalStyle />
    <SizeContext.Provider value={size}>
      <Transfers />
    </SizeContext.Provider>
  </ThemeProvider>
}

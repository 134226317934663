import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import classNames from 'classnames'
import { FormControl, FormHelperText } from '@material-ui/core'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import List from '@material-ui/core/List'
import useStyles from './CustomControls.style'

const CustomAutocomplete = ({
  specialOptions,
  label,
  helperText,
  error,
  errorText,
  defaultShowList,
  inputValue = '',
  defaultValue,
  onChange,
  onInputChange,
  searchList,
  required,
  uniqueSearchList,
  unique,
  ...props
}) => {
  const classes = useStyles()
  return (
    <FormControl
      fullWidth
      error={error}
      className={classNames(classes.formControl, {
        [classes.uniqueFormControl]: unique,
        [classes.invalid_border]: error,
      })}
      required={required}
    >
      <FormLabel className={classes.inputLabel}>{label}</FormLabel>
      <Autocomplete
        handleHomeEndKeys
        /* eslint-disable-next-line */
        options={defaultShowList || inputValue?.length >= 3 ? searchList || [] : []}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          if (option.templateName) {
            return option.templateName
          }
          return option.name
        }}
        onChange={onChange}
        onInputChange={onInputChange}
        value={inputValue}
        defaultValue={defaultValue}
        {...(uniqueSearchList && {
          ListboxComponent: React.forwardRef((listboxProps, ref) => (
            <div ref={ref}>
              {uniqueSearchList && (
                <div className={classes.paperList}>
                  <span className={classes.paperList__heading}>Наименования</span>
                  <span className={classes.paperList__heading}>Рынок | Режим торгов</span>
                  <span className={classes.paperList__heading}>Тикер</span>
                  <span className={classes.paperList__heading}>ISIN | Гос. рег.</span>
                </div>
              )}
              <List {...listboxProps} />
            </div>
          )),
        })}
        renderOption={(option) => {
          if (specialOptions) {
            return specialOptions.map((elem) => `${option[elem]}          `)
          }
          const { name, isin, registerNumber } = option
          const marketName = option?.marketBoard?.market?.name
          const marketBoardName = option?.marketBoard?.name
          const code = option?.code
          return (
            <>
              {(uniqueSearchList && (
                <>
                  <span className={classes.paperList__option}>{name}</span>
                  <span className={classes.paperList__option}>
                    {(marketName && `${marketName} | ${marketBoardName}`) || ''}
                  </span>
                  <span className={classes.paperList__option}>{code || ''}</span>
                  <span className={classes.paperList__option}>{isin || registerNumber || ''}</span>
                </>
              ))
                || `${name}${isin ? ` | ${isin}` : ''}${registerNumber ? ` | ${registerNumber}` : ''}`}
            </>
          )
        }}
        freeSolo
        {...props}
        renderInput={(params) => (
          <TextField
            className={classes.inputField}
            required={required}
            placeholder={props?.placeholder}
            {...params}
            helperText={helperText}
          />
        )}
        classes={{
          paper: classNames(classes.autocompletePaper, {
            [classes.autocompletePaper_unique]: uniqueSearchList,
          }),
        }}
      />
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  )
}

CustomAutocomplete.defaultProps = {
  helperText: null,
  defaultShowList: false,
  inputValue: '',
  defaultValue: null,
  searchList: [],
  uniqueSearchList: false,
  unique: false,
  required: false,
  error: false,
  errorText: '',
  placeholder: '',
}

CustomAutocomplete.propTypes = {
  error: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  specialOptions: PropTypes.oneOfType([PropTypes.array]),
  helperText: PropTypes.string,
  defaultShowList: PropTypes.bool,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOfType([PropTypes.object])]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOfType([PropTypes.object])]),
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  searchList: PropTypes.oneOfType([PropTypes.array]),
  uniqueSearchList: PropTypes.bool,
  unique: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default CustomAutocomplete

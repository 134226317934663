import { styled } from "../../../../theme";


export const StyledTitle = styled.h1`
  ${({theme}) => theme.font.h1}
  color: ${({theme}) => theme.color.bg.tertiary};
  margin-bottom: 16px;

  @media ${({ theme }) => theme.breakpoint.xs} {
    ${({theme}) => theme.font.h3}
  }
`

export const StyledSubTitle = styled.h2`
  ${({theme}) => theme.font.body3}
  color: ${({theme}) => theme.color.bg.tertiary};

  @media ${({ theme }) => theme.breakpoint.xs} {
    ${({theme}) => theme.font.body3}
  }
`

export const StyledUnavailableTabWrapper = styled.div`
  padding: 32px 64px;
`

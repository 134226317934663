import React from 'react'
import { useSize } from '../../../../hooks/useSize'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from '../../../theme'
import { SizeContext } from '../../../../context/SizeContext'
import { MainLayout } from '../../templates/MainLayout'
import { Tab } from 'ui/components'
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import DocumentPanel from "./DocumentPanel";
import { StyledDocumentsTabs } from "./styled";

const tabLinks = [
  {
    link: 'brokerageServices',
    label: 'Брокерское обслуживание',
    component: DocumentPanel,
  },
  {
    link: 'depositoryService',
    label: 'Депозитарное обслуживание',
    component: DocumentPanel,
  },
]

const Documents = () => {
  const { url, path } = useRouteMatch()

  return (
    <MainLayout title="Документы">
      <StyledDocumentsTabs>
        {
          tabLinks.map((tab) => (
            <Tab
              label={tab.label}
              component={NavLink}
              to={`${url}/${tab.link}`}
              style={{whiteSpace: 'break-spaces'}}
            />
          ))
        }
      </StyledDocumentsTabs>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/${tabLinks[0].link}`} />
        </Route>
          {tabLinks.map((currentLink: any) => (
            <Route
              key={currentLink.link}
              path={`${path}/${currentLink.link}`}
              component={currentLink.component}
            />
          ))}
      </Switch>
    </MainLayout>
  )
}

export const DocumentsNew = () => {
  const size = useSize()
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SizeContext.Provider value={size}>
        <Documents />
      </SizeContext.Provider>
    </ThemeProvider>
  )
}

import React from 'react'
import { ThemeProvider } from 'styled-components'
import LogOut from '../../components/logoutRedisign/logout'
import { theme } from '../../ui/theme'
import { StyledLoginLeft, StyledLoginRight, StyledLoginWrapper } from '../Login/styled'
import svgLogo from '../Login/assets/loginLogo.svg'

export default function Logout() {
  return (
    <ThemeProvider theme={theme}>
      <StyledLoginWrapper>
        <StyledLoginLeft>
          <img
            src={svgLogo}
            alt="Регион"
          />
        </StyledLoginLeft>
        <StyledLoginRight>
          <LogOut />
        </StyledLoginRight>
      </StyledLoginWrapper>
    </ThemeProvider>
  )
}

import { request } from './Request'
import { asyncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getScanerPermissions() {
    return request(asyncApiURL, endpoints.checks.scanerPermissions)
  },
  getPortfolioPermissions() {
    return request(asyncApiURL, endpoints.checks.portfolioPermissions)
  },
}

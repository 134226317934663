import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import useWindowSize from "../../../../hooks/useWindows";
import { TextAreaProps } from './types'
import { StyledTextArea, StyledTextAreaWrapper } from './styled'
import type { FC } from 'react'

const TextArea: FC<TextAreaProps> = (
  {
    minAreaHeight = 80,
    autoresize = false,
    ...props
  }
) => {
  const MIN_TEXTAREA_HEIGHT = minAreaHeight
  const SCROLL_SHIFT = 6

  const textAreaRef = useRef<HTMLTextAreaElement>()
  const [textAreaHeight, setTextAreaHeight] = useState(minAreaHeight)
  const { windowWidth } = useWindowSize()

  const resizeTextAreaHeight = () => {
    if (!autoresize) return

    // Reset field height to get not fixed actual client height.
    textAreaRef.current.style.minHeight = 'auto'
    const currentTextAreaHeight = textAreaRef.current.scrollHeight

    if (currentTextAreaHeight === textAreaHeight) return

    const actualHeight =
      currentTextAreaHeight > MIN_TEXTAREA_HEIGHT
        ? currentTextAreaHeight + SCROLL_SHIFT
        : MIN_TEXTAREA_HEIGHT + SCROLL_SHIFT

    setTextAreaHeight(actualHeight)
    textAreaRef.current.style.minHeight = `${textAreaHeight}px`
  }

  useLayoutEffect(resizeTextAreaHeight, [windowWidth])

  useEffect(() => {
    setTextAreaHeight(minAreaHeight)
  }, [minAreaHeight])

  return (
    <StyledTextAreaWrapper>
      <StyledTextArea
        autoresize={autoresize}
        ref={textAreaRef}
        style={{
          minHeight: textAreaHeight,
          resize: autoresize ? 'none' : 'vertical',
        }}
        onInputCapture={resizeTextAreaHeight}
        {...props}
      />
    </StyledTextAreaWrapper>
  )
}

export { TextArea }

import { CardData } from "./types";

export const contactsData: CardData[] = [
  {
    title: 'Отдел продаж',

    fields: [
      {
        contactKey: 'телефон',
        contactValue: '+7 (495) 777 29 64',
        link: '+7(495)777-29-64',
        isPhone: true,
      },

      {
        contactKey: 'добавочный',
        contactValue: '306 | 469',
      },

      {
        contactKey: 'e-mail',
        contactValue: 'sales@region.ru',
        link: 'sales@region.ru',
        isMail: true,
      },
    ]
  },

  {
    title: 'Отдел по работе с клиентами',

    fields: [
      {
        contactKey: 'телефон',
        contactValue: '+7 (495) 777 29 64 ',
        link: '+7(495)777-29-64 ',
        isPhone: true,
      },

      {
        contactKey: 'добавочный',
        contactValue: '172 | 636 | 341 | 262',
      },

      {
        contactKey: 'e-mail',
        contactValue: 'client@region.ru',
        link: 'client@region.ru',
        isMail: true,
      },
    ]
  },
]

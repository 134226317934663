import { createSelector } from '@reduxjs/toolkit'

const selectInvestmentsNotices = (state) => state.investmentsNotices

export const selectAccountsInvestments = createSelector(
  [selectInvestmentsNotices],
  (state) => state.accountsInvestments,
)

export const selectAgreementsDepo = createSelector(
  [selectInvestmentsNotices],
  (state) => state.agreementsDepo,
)

export const selectMoneyRequisites = createSelector(
  [selectInvestmentsNotices],
  (state) => state.moneyRequisites,
)

export const selectDepoRequisites = createSelector(
  [selectInvestmentsNotices],
  (state) => state.depoRequisites,
)

import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import sanitizeHtml from 'sanitize-html'
import ruRU from 'date-fns/locale/ru'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Media from 'react-media'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import CustomSelect from '../CustomControls/CustomSelect'
import CustomIconButton from '../CustomControls/CustomIconButton'
import useStyles from './panel.style'
import CustomInput from '../CustomControls/CustomInput'
import CustomDatepicker from '../CustomControls/CustomDatepicker'
import {
  clearOutputMoney,
  fetchPerformOutputMoneyStartAsync,
  fetchSaveOutputMoneyStartAsync,
  fetchOutputMoneyStartAsync,
  getOutputMoneySuccess,
  performOutputMoneySuccess,
  saveOutputMoneySuccess,
  clearPartOutputMoney,
  fetchGetBanksStartAsync,
  // eslint-disable-next-line import/named
  getBanksSuccess,
  fetchGetPaymentTemplatesStartAsync,
  getPaymentTemplatesSuccess,
  fetchDeletePaymentTemplateStartAsync,
} from '../../redux/transfers/money/output/outputMoneySlice'
import { clearMetadata, fetchMetadataStartAsync, getMetadataSuccess } from '../../redux/metadata/metadataSlice'
import { selectConnectionState } from '../../redux/connection/selector'
import {
  selectGetOutputMoney,
  selectPerformOutputMoney,
  selectSaveOutputMoney,
  selectCurrentBanks,
  selectPaymentTemplates,
} from '../../redux/transfers/money/output/selector'
import { selectCurrentMetadata } from '../../redux/metadata/selector'
import { selectCurrentError } from '../../redux/error/selector'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import { encodeToUTF8 } from '../../utils/EncodeToUTF8'
import Timer from '../../utils/Timer'
import CustomLoader from '../CustomLoader/CustomLoader'
import PageHeading from '../PageHeading/PageHeading'
import CustomModal from '../CustomModal/CustomModal'
import { ReactComponent as DownloadIcon } from '../../assets/images/icon-button-download.svg'
import createUri from '../../utils/FilterUri'
import { fetchGetBalancesMoneyStartAsync, getBalancesMoneySuccess } from '../../redux/transfers/money/transferMoneySlice'
import { selectBalancesMoney } from '../../redux/transfers/money/selector'
import numberWithSpaces from '../../utils/Money'
import CustomButton from '../CustomControls/CustomButton'
import formAction from '../../utils/FormAction'
import CustomInputSelect from '../CustomControls/CustomInputSelect'
import { addEmptyValueToArray } from '../../utils/Validate'
import { currentUserLoginAndId, selectCurrentThumbprint } from '../../redux/user/selector'
import getDoubleZero from '../../utils/getDoubleZero'
import useInput from '../../hooks/useInput'
import JsonToSHA256 from '../../utils/JsonToSHA256'

const transferType = 'Orders.Type.NonTrade.Money.Out'
const cancelType = 'Orders.Type.Modify.Cancellation'

export default function MoneyOutput() {
  const classes = useStyles()
  const history = useHistory()
  const [clientPortfolio, setClientPortfolio] = useState('')
  const [currentClientPortfolio, setCurrentClientPortfolio] = useState(null)
  const [clientMoneyStorage, setClientMoneyStorage] = useState('')
  const [currentCurrency, setCurrentCurrency] = useState('')
  const currentAmount = useInput('', { isOnlyNumbers: true })
  const [expirationDate, setExpirationDate] = useState(null)
  const [validity, setValidity] = useState('')
  const [arrayCurrencies, setArrayCurrencies] = useState('')
  const [commentary, setCommentary] = useState('')
  const [reason, setReason] = useState('')
  const [allDisabled, setAllDisabled] = useState(false)
  const [currentActions, setCurrentActions] = useState(null)
  const [showTimer, setShowTimer] = useState(false)
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [codeTextError, setCodeTextError] = useState('')
  const [currentStatus, setCurrentStatus] = useState('')
  const [submitAction, setSubmitAction] = useState('')
  const [transferHeader, setOutputHeader] = useState('')
  const [arrayPlatform, setArrayPlatform] = useState('')
  const [agentName, setAgentName] = useState('')
  const [bankName, setBankName] = useState('')
  const [bic, setBic] = useState('')
  const [accountName, setAccountName] = useState('')
  const [bankAccount, setBankAccount] = useState('')
  const [ground, setGround] = useState('')
  const [isRealTime, setIsRealTime] = useState(false)
  const [bankSwift, setBankSwift] = useState('')
  const [correspondentSwift, setCorrespondentSwift] = useState('')
  const [addresseeBank, setAddresseeBank] = useState('')
  const [correspondentName, setCorrespondentName] = useState('')
  const [bankAccountAddressee, setBankAccountAddressee] = useState('')
  const [correspondentAccount, setCorrespondentAccount] = useState('')
  const urlParams = useParams()
  const location = useLocation()
  const transferId = useMemo(() => urlParams.id, [urlParams])
  const transferUid = useMemo(() => location?.state?.orderUid, [location])
  const [metadataUid, setMetadataUid] = useState(transferUid || transferType)
  const [formDisabled, setFormDisabled] = useState(false)
  const [activeId, setActiveId] = useState('')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [updModalOpen, setUpdModalOpen] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [expirationDateError, setExpirationDateError] = useState(false)
  const [clientPortfolioError, setClientPortfolioError] = useState(false)
  const [clientMoneyStorageError, setClientMoneyStorageError] = useState(false)
  const [accountNameError, setAccountNameError] = useState(false)
  const [currencyError, setCurrencyError] = useState(false)
  const [amountError, setAmountError] = useState(false)
  const [bankSwiftError, setBankSwiftError] = useState(false)
  const [agentNameError, setAgentNameError] = useState(false)
  const [agentNameTextError, setAgentNameTextError] = useState('')
  const [bankNameError, setBankNameError] = useState(false)
  const [bankNameTextError, setBankNameTextError] = useState('')
  const [bankBicError, setBankBicError] = useState(false)
  const [bankBicTextError, setBankBicTextError] = useState('')
  const [bankAccountError, setBankAccountError] = useState(false)
  const [bankAccountTextError, setBankAccountTextError] = useState('')
  const [correspondentSwiftError, setCorrespondentSwiftError] = useState(false)
  const [correspondentSwiftTextError, setCorrespondentSwiftTextError] = useState('')
  const [correspondentNameError, setCorrespondentNameError] = useState(false)
  const [correspondentNameTextError, setCorrespondentNameTextError] = useState('')
  const [correspondentAccountError, setCorrespondentAccountError] = useState(false)
  const [clientPortfolioTextError, setClientPortfolioTextError] = useState('')
  const [clientMoneyStorageTextError, setClientMoneyStorageTextError] = useState('')
  const [currencyTextError, setCurrencyTextError] = useState('')
  const [expirationDateTextError, setExpirationDateTextError] = useState('')
  const [accountNameTextError, setAccountNameTextError] = useState('')
  const [bankSwiftTextError, setBankSwiftTextError] = useState('')
  const [correspondentAccountTextError, setCorrespondentAccountTextError] = useState('')
  const [amountTextError, setAmountTextError] = useState('')
  const [arrayDisabledFields, setArrayDisabledFields] = useState([])
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [balances, setBalances] = useState(null)
  const [isCleared, setIsCleared] = useState(false)
  const [canModifyDate, setCanModifyDate] = useState(true)
  const [deletePaymentTemplateModalOpen, setDeletePaymentTemplateModalOpen] = useState(false)
  const [deletePaymentTemplateId, setDeletePaymentTemplateId] = useState(null)
  const [secretPassPhrase, setSecretPassPhrase] = useState('')

  const [isNewDetails, setIsNewDetails] = useState(false)
  const [searchStrBankName, setSearchStrBankName] = useState('')
  const [searchStrBic, setSearchStrBic] = useState('')
  const [searchStrCorrespondentAccount, setSearchStrCorrespondentAccount] = useState('')
  const [searchStrBankSwift, setSearchStrBankSwift] = useState('')
  const [searchStrAddresseeBank, setSearchStrAddresseeBank] = useState('')
  const [searchStrAccountName, setSearchStrAccountName] = useState('')
  const [hashSha256, setHashSha256] = useState(null)

  const fetchOutputMoneyStart = (id) => {
    dispatch(fetchOutputMoneyStartAsync({ id }))
  }

  const fetchSaveOutputMoneyStart = (data, id) => {
    dispatch(fetchSaveOutputMoneyStartAsync({ data, id }))
  }

  const fetchPerformOutputMoneyStart = (data, id) => {
    dispatch(fetchPerformOutputMoneyStartAsync({ data, id }))
  }

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchBanksStart = (filterBody) => {
    dispatch(fetchGetBanksStartAsync(filterBody))
  }

  const fetchPaymentTemplatesStart = (filterBody) => {
    dispatch(fetchGetPaymentTemplatesStartAsync(filterBody))
  }

  const fetchDeletePaymentTemplatesStart = (id) => {
    dispatch(fetchDeletePaymentTemplateStartAsync(id))
  }

  const fetchGetBalancesMoneyStart = (filterBody) => {
    dispatch(fetchGetBalancesMoneyStartAsync({ filterBody }))
  }

  const connectedWS = useSelector(selectConnectionState)
  const getRespOutputMoney = useSelector(selectGetOutputMoney)
  const getRespBanks = useSelector(selectCurrentBanks)
  const getRespPaymentTemplates = useSelector(selectPaymentTemplates)
  const getRespMetadata = useSelector(selectCurrentMetadata)
  const getSaveOutputMoney = useSelector(selectSaveOutputMoney)
  const getPerformOutputMoney = useSelector(selectPerformOutputMoney)
  const getBalances = useSelector(selectBalancesMoney)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)

  const currentStatusDescription = getRespOutputMoney?.order?.statusDescription
    && sanitizeHtml(getRespOutputMoney?.order?.statusDescription)

  const { getData } = useConnect({ link: `GET /api${endpoints.moneys.getTransferMoney(activeId)}`, startFetching: fetchOutputMoneyStart, action: getOutputMoneySuccess })
  const { getData: getMetaData } = useConnect({ link: `GET /api${endpoints.metadata.getMetadata(metadataUid)}`, startFetching: fetchMetadataStart, action: getMetadataSuccess })
  const { getData: postSaveData } = useConnect({ link: `POST /api${endpoints.moneys.postTransferMoney(false)}`, startFetching: fetchSaveOutputMoneyStart, action: saveOutputMoneySuccess })
  const { getData: putSaveData } = useConnect({ link: `PUT /api${endpoints.moneys.putTransferMoney(null, activeId, false)}`, startFetching: fetchSaveOutputMoneyStart, action: saveOutputMoneySuccess })
  const { getData: postPerformData } = useConnect({ link: `POST /api${endpoints.moneys.postTransferMoney(false)}`, startFetching: fetchPerformOutputMoneyStart, action: performOutputMoneySuccess })
  const { getData: putPerformData } = useConnect({ link: `PUT /api${endpoints.moneys.putTransferMoney(null, activeId, false)}`, startFetching: fetchPerformOutputMoneyStart, action: performOutputMoneySuccess })
  useConnect({ link: `GET /api${endpoints.assets.getBalancesMoney()}`, startFetching: fetchGetBalancesMoneyStart, action: getBalancesMoneySuccess })

  const { getData: getBankRecipientFilter } = useConnect({
    link: `GET /api${endpoints.banks.getBanksFilter()}`,
    startFetching: fetchBanksStart,
    action: getBanksSuccess,
  })

  const { getData: getPaymentTemplatesFilter } = useConnect({
    link: `GET /api${endpoints.paymentTemplates.getPaymentTemplatesFilter()}`,
    startFetching: fetchPaymentTemplatesStart,
    action: getPaymentTemplatesSuccess,
  })

  const { getData: deletePaymentTemplate } = useConnect({
    link: `DELETE /api${endpoints.paymentTemplates.deletePaymentTemplate(deletePaymentTemplateId)}`,
    startFetching: fetchDeletePaymentTemplatesStart,
    action: getPaymentTemplatesSuccess,
  })

  const deleteTemplate = (id) => {
    setDeletePaymentTemplateModalOpen(true)
    if (id) {
      setDeletePaymentTemplateId(id)
    }
  }

  const clearAdditionalFields = () => {
    setAgentName('')
    setBankName('')
    setBic('')
    setAccountName('')
    setBankAccount('')
    setGround('')
    setBankSwift('')
    setCorrespondentSwift('')
    setAddresseeBank('')
    setCorrespondentName('')
    setBankAccountAddressee('')
    setCorrespondentAccount('')
  }

  const clearFields = (needClearData = true) => {
    if (needClearData) dispatch(clearMetadata())
    dispatch(clearOutputMoney())
    setCommentary('')
    setReason('')
    setArrayCurrencies('')
    setCurrentCurrency('')
    currentAmount.setValue('')
    setExpirationDate('')
    setValidity(new Date())
    setAllDisabled(false)
    setCurrentActions(null)

    setClientPortfolio('')
    setClientMoneyStorage('')
    setShowTimer(false)
    setCode('')
    setCodeError('')
    setCurrentStatus('')
    setSubmitAction('')
    setOutputHeader('')
    clearAdditionalFields()

    setExpirationDateError(false)
    setClientPortfolioError(false)
    setClientMoneyStorageError(false)
    setCurrencyError(false)
    setAmountError(false)
    setAccountNameError(false)
    setBankSwiftError(false)
    setCorrespondentAccountError(false)
    setBalances(null)
    setIsCleared(true)
    setSecretPassPhrase('')
  }

  const fillTemplateFields = (currentAccount) => {
    if (typeof (currentAccount) === 'object' && Object.keys(currentAccount)?.length) {
      setAgentName(currentAccount?.agentName)
      let objectFields = {}
      if (currentAccount?.currencyUid?.toLowerCase() === 'rub') {
        objectFields = {
          agentName: !!currentAccount?.agentName,
          bankName: !!currentAccount?.bankName,
          bic: !!currentAccount?.bankBic,
          bankAccount: !!currentAccount?.bankAccount,
          correspondentAccount: !!currentAccount?.correspondentAccount,
          isRealTime: !!currentAccount?.isRealTime,
        }
        setBankName(currentAccount?.bankName)
        setBic(currentAccount?.bankBic)
        setBankAccount(currentAccount?.bankAccount)
        setCorrespondentAccount(currentAccount?.correspondentAccount)
        // eslint-disable-next-line no-unused-expressions
        currentAccount?.isRealTime && setIsRealTime(currentAccount?.isRealTime)
        if (currentAccount?.bankName && currentAccount?.bankAccount) {
          setIsNewDetails(true)
          setAccountName(`${currentAccount?.bankName} / ${currentAccount?.bankAccount}`)
        }
      } else if (currentAccount?.currencyUid?.toLowerCase() !== 'rub') {
        objectFields = {
          agentName: !!currentAccount?.agentName,
          correspondentSwift: !!currentAccount?.correspondentSwift,
          addresseeBank: !!currentAccount?.bankName,
          correspondentName: !!currentAccount?.correspondentName,
          bankAccountAddressee: !!currentAccount?.bankAccount,
          bankSwift: !!currentAccount?.bankSwift,
          correspondentAccount: !!currentAccount?.correspondentAccount,
        }
        setCorrespondentSwift(currentAccount?.correspondentSwift)
        setAddresseeBank(currentAccount?.bankName)
        setCorrespondentName(currentAccount?.correspondentName)
        setBankAccountAddressee(currentAccount?.bankAccount)
        setBankSwift(currentAccount?.bankSwift)
        setCorrespondentAccount(currentAccount?.correspondentAccount)
        setBic(currentAccount?.bankBic)
        setBankName(currentAccount?.bankName)
        setBankAccount(currentAccount?.bankAccount)
        if (currentAccount?.bankName && currentAccount?.bankAccount) {
          setIsNewDetails(true)
          setAccountName(`${currentAccount?.bankName} / ${currentAccount?.bankAccount}`)
        }
      }
      setArrayDisabledFields(objectFields)
    } else {
      clearAdditionalFields()
    }
  }

  const fillTemplate = (paymentTemplates, currency, currentOrder) => {
    let accountNames = null
    if (paymentTemplates?.length >= 0) {
      accountNames = paymentTemplates
    } else {
      accountNames = [{ templateName: `${currentOrder?.bankName} / ${currentOrder?.bankAccount}`, bankName: currentOrder?.bankName }]
    }
    if (currentOrder) {
      const selectedBankName = accountNames?.find(
        (item) => item.bankName === currentOrder?.bankName,
      )
      setAccountName(selectedBankName?.templateName)
      fillTemplateFields(currentOrder)
      return
    }
    if (accountNames?.length === 1) {
      setAccountName(accountNames[0]?.templateName)
      fillTemplateFields(accountNames[0])
    }
  }

  const fillFields = (order, references) => {
    let payments = null
    let licenseMinus = null
    const currenCurrency = order?.currency?.uid
    if (references && Object.keys(references)?.length) {
      licenseMinus = references?.portfolios?.find(
        (item) => item.name === order?.clientPortfolio?.name,
      )
      payments = references?.paymentTemplates
      setArrayPlatform(licenseMinus?.moneyStorages)
      setArrayCurrencies(licenseMinus?.currencies)
    } else {
      setArrayCurrencies([{ uid: order?.currency?.uid }])
      setArrayPlatform([order?.clientMoneyStorage])
    }
    if (currenCurrency) {
      fillTemplate(payments, currenCurrency, order)
    }
    setGround(order?.ground)
    setClientPortfolio(order?.clientPortfolio?.name)
    setClientMoneyStorage(order?.clientMoneyStorage?.description)
    setCurrentCurrency(currenCurrency)
    currentAmount.setValue(order?.amount)
    setExpirationDate(getRespOutputMoney?.order?.expirationDate)
    setValidity(getRespOutputMoney?.order?.expirationDate)
    setCommentary(order?.comment)
    setReason(order?.reason)
    // eslint-disable-next-line no-underscore-dangle
    if (!order?._links[0]?.canModify) {
      setAllDisabled(true)
      setCanModifyDate(false)
    }
  }

  const handleBackToHistory = () => {
    history.push('/transfers/money/log')
  }

  useEffect(() => {
    if (connectedWS) {
      clearFields()
      setActiveId(null)
      if (transferId === 'new') {
        setMetadataUid(transferType)
        getMetaData(transferType)
        setIsLoading(false)
        clearFields()
      } else if (transferId) {
        getMetaData(metadataUid)
        getData(transferId)
        setActiveId(transferId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferId, connectedWS])

  useEffect(() => {
    if (getRespOutputMoney?.order && isCleared) {
      setIsLoading(false)
      setCurrentStatus(getRespOutputMoney?.order?.status)
      let order = null
      if (getRespOutputMoney?.order?.type?.uid === transferType) {
        order = getRespOutputMoney?.order
        if (getRespMetadata?.references && Object.keys(getRespMetadata.references).length) {
          const portfolios = getRespMetadata?.references?.portfolios
          fillFields(order, portfolios)
        }
        if (getRespMetadata?.order?.type?.uid === cancelType) {
          const cancelDate = getRespMetadata?.order?.expirationDate
          const reqObj = {
            type: { uid: cancelType },
            expirationDate: cancelDate && format(new Date(cancelDate), 'yyyy-MM-dd'),
            affectedOrder: { id: +activeId },
            secret: '',
          }

          const date = getRespMetadata?.order?.date
          const number = getRespMetadata?.order?.number

          reqObj.date = date
          reqObj.number = number

          const WordArrayToBase64 = JsonToSHA256(reqObj)
          if (WordArrayToBase64) {
            reqObj.secret = WordArrayToBase64
            setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
          }

          postPerformData(reqObj)
        }
      } else if (getRespOutputMoney?.order?.type?.uid === cancelType) {
        order = getRespOutputMoney?.order?.affectedOrder
        fillFields(order, [])
        // eslint-disable-next-line no-underscore-dangle
        if (getRespOutputMoney?.order?._links[0]?.canModify) {
          setCanModifyDate(true)
        }
      }
      const references = getRespMetadata?.references

      fillFields(order, references)
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(getRespOutputMoney?.order?._links[0])
      setOutputHeader(`${getRespOutputMoney?.order?.description}`)
    } else if (transferId !== 'new') {
      setIsLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRespOutputMoney, getRespMetadata, isCleared])

  useEffect(() => {
    if (connectedWS && isCleared) {
      if (getSaveOutputMoney?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveOutputMoney?.order?.id)
        }
        if (getSaveOutputMoney?.requiredAction?.type === 'code' || getSaveOutputMoney?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
      }
      if (getPerformOutputMoney?.requiredAction) {
        if (!activeId || +activeId !== getPerformOutputMoney?.order?.id) {
          setActiveId(getPerformOutputMoney?.order?.id)
        }
        if (getPerformOutputMoney?.requiredAction?.type === 'code' || getPerformOutputMoney?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
        setCurrentStatus(getPerformOutputMoney?.order?.status)
      }
      const msgData = getSaveOutputMoney || getPerformOutputMoney
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespOutputMoney?.order?.type?.uid === cancelType) ? cancelType : transferType
      if (msgData?.order?.description) {
        setOutputHeader(`${msgData?.order?.description}`)
      }
      if (typeUID) {
        setIsLoading(false)
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(msgData?.order?._links[0])
      if (msgData?.order?.type?.uid === cancelType
        && getRespOutputMoney?.order?.type?.uid !== cancelType) {
        const cancelDate = getRespMetadata?.order?.expirationDate
        setExpirationDate(cancelDate)
        setValidity(cancelDate)
      }
      if (getSaveOutputMoney?.status === 200 || getPerformOutputMoney?.status === 200) {
        switch (getSaveOutputMoney?.order?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (activeId) {
              setUpdModalOpen(true)
            } else {
              setSaveModalOpen(true)
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaveOutputMoney, getPerformOutputMoney, isCleared])

  useEffect(() => {
    if (serverError) {
      if (serverError?.errors?.ExpirationDate?.length) {
        setExpirationDateError(true)
        setExpirationDateTextError(serverError?.errors?.ExpirationDate[0])
      }
      if (serverError?.errors?.ClientPortfolio?.length) {
        setClientPortfolioError(true)
        setClientPortfolioTextError(serverError?.errors?.ClientPortfolio[0])
      }
      if (serverError?.errors?.ClientMoneyStorage?.length) {
        setClientMoneyStorageError(true)
        setClientMoneyStorageTextError(serverError?.errors?.ClientMoneyStorage[0])
      }
      if (serverError?.errors?.Currency?.length) {
        setCurrencyError(true)
        setCurrencyTextError(serverError?.errors?.Currency[0])
      }
      if (serverError?.errors?.Amount?.length) {
        setAmountError(true)
        setAmountTextError(serverError?.errors?.Amount[0])
      }
      if (serverError?.errors?.AccountName?.length) {
        setAccountNameError(true)
        setAccountNameTextError(serverError?.errors?.AccountName[0])
      }
      if (serverError?.errors?.BankSwift?.length) {
        setBankSwiftError(true)
        setBankSwiftTextError(serverError?.errors?.BankSwift[0])
      }
      if (serverError?.errors?.CorrespondentAccount?.length) {
        setCorrespondentAccountError(true)
        setCorrespondentAccountTextError(serverError?.errors?.CorrespondentAccount[0])
      }
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeTextError(serverError?.errors?.Secret[0])
      }
      if (serverError?.errors?.AgentName?.length) {
        setAgentNameError(true)
        setAgentNameTextError(serverError?.errors?.AgentName[0])
      }
      if (serverError?.errors?.BankName?.length) {
        setBankNameError(true)
        setBankNameTextError(serverError?.errors?.BankName[0])
      }
      if (serverError?.errors?.BankBic?.length) {
        setBankBicError(true)
        setBankBicTextError(serverError?.errors?.BankBic[0])
      }
      if (serverError?.errors?.BankAccount?.length) {
        setBankAccountError(true)
        setBankAccountTextError(serverError?.errors?.BankAccount[0])
      }
      if (serverError?.errors?.CorrespondentSwift?.length) {
        setCorrespondentSwiftError(true)
        setCorrespondentSwiftTextError(serverError?.errors?.CorrespondentSwift[0])
      }
      if (serverError?.errors?.CorrespondentName?.length) {
        setCorrespondentNameError(true)
        setCorrespondentNameTextError(serverError?.errors?.CorrespondentName[0])
      }
      const msgData = getSaveOutputMoney || getPerformOutputMoney
      if ((!currentActions || currentActions?.canModify) && !msgData?.requiredAction?.type) {
        setAllDisabled(false)
      }
      setIsLoading(false)
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError])

  useEffect(() => () => {
    clearFields()
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const fetchBalancesMoney = (filterBody, setBalance) => {
    if (filterBody) {
      fetchGetBalancesMoneyStart(filterBody.replaceAll('+', ''))
    }
    setBalance(null)
  }

  const getPortfolioObj = (clientPortfolioName) => {
    if (
      getRespMetadata?.order?.type?.uid === cancelType
    ) return getRespOutputMoney?.order?.affectedOrder?.clientPortfolio
    return getRespMetadata?.references?.portfolios?.find(
      (item) => item.name === clientPortfolioName,
    )
  }

  useMemo(() => {
    let license = null
    let moneyStorage = null
    if (transferUid === cancelType) {
      license = getRespOutputMoney?.order?.affectedOrder?.clientPortfolio
      moneyStorage = getRespOutputMoney?.order?.affectedOrder?.clientMoneyStorage
    } else {
      license = getPortfolioObj(clientPortfolio)
      moneyStorage = license?.moneyStorages?.find(
        (item) => item.description === clientMoneyStorage,
      )
    }
    let filterBodyClient
    if (clientPortfolio || clientMoneyStorage) {
      filterBodyClient = createUri({
        name: 'balancesMoney',
        portfolioId: license?.id,
        currencyUid: currentCurrency,
        storageUid: moneyStorage?.uid,
        condition: '&',
      })
      fetchBalancesMoney(filterBodyClient, setBalances)
    }
    setBalances(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientPortfolio, clientMoneyStorage, currentCurrency])

  useEffect(() => {
    if (getBalances) {
      const license = getPortfolioObj(clientPortfolio)
      if (!getBalances?.portfolio) return
      if (getBalances?.portfolio?.id === license?.id) {
        setBalances(getBalances)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBalances])

  const handleChangeCurrency = (event) => {
    const { value } = event.target
    setAccountName('')
    clearAdditionalFields()
    setCurrentCurrency(value)
    setCurrencyError(false)
    setSearchStrAccountName('')
    setBankAccountError(false)
  }

  const handleIsRealTimeSwitchChange = (event) => {
    const { checked } = event.target
    setIsRealTime(checked)
  }

  const handleChangeValidity = (date) => {
    setValidity(date)
    setExpirationDateError(false)
  }

  const handleChangeCommentary = (event) => {
    const { value } = event.target
    setCommentary(value)
  }

  const handleChangeReason = (event) => {
    setReason(event.target.value)
  }

  const handleChangePlatform = (event) => {
    const { value } = event.target
    setClientMoneyStorage(value)
    setClientMoneyStorageError(false)
  }

  const handleChangeGround = (event) => {
    const { value } = event.target
    setGround(value)
  }

  const handleChangeCorrespondentAccount = (event) => {
    const { value } = event.target
    setCorrespondentAccount(value)
    setCorrespondentAccountError(false)
  }

  const handleChangeAgentName = (event) => {
    const { value } = event.target
    setAgentName(value)
    setAgentNameError(false)
  }

  const handleChangeBankAccount = (event) => {
    const { value } = event.target
    setBankAccount(value)
    setBankAccountError(false)
    setBankAccountTextError(false)
  }

  const handleChangeCorrespondentSwift = (event) => {
    const { value } = event.target
    setCorrespondentSwift(value)
    setCorrespondentSwiftError(false)
  }

  const handleChangeCorrespondentName = (event) => {
    const { value } = event.target
    setCorrespondentName(value)
    setCorrespondentNameError(false)
  }

  const handleChangeBankAccountAddressee = (event) => {
    const { value } = event.target
    setBankAccountAddressee(value)
    setBankAccountError(false)
  }

  const addNeedsProps = (typeUID, msgData) => {
    const license = getPortfolioObj(clientPortfolio)
    const moneyStorage = license?.moneyStorages?.find(
      (item) => item.description === clientMoneyStorage,
    )
    if (typeUID === cancelType) {
      const reqObj = {
        type: { uid: typeUID },
        expirationDate: format(new Date(validity), 'yyyy-MM-dd'),
        affectedOrder: {
          id: msgData?.order?.affectedOrder?.id
            || getRespOutputMoney?.order?.affectedOrder?.id,
        },
        secret: '',
      }
      return reqObj
    }
    const reqObj = {
      type: { uid: typeUID },
      dateTime: format(new Date(), 'yyyy-MM-dd'),
      expirationDate: format(new Date(validity), 'yyyy-MM-dd'),
      clientPortfolio: { id: license?.id },
      clientMoneyStorage: { uid: moneyStorage?.name },
      comment: commentary,
      reason,
      currency: { uid: currentCurrency },
      amount: +currentAmount.value,
      secret: '',
    }
    if (currentCurrency?.toLowerCase() === 'rub') {
      reqObj.bankBic = bic
      reqObj.agentName = agentName
      reqObj.bankName = bankName
      reqObj.bankAccount = bankAccount
      reqObj.correspondentAccount = correspondentAccount
      reqObj.ground = ground
      reqObj.isRealTime = isRealTime
    } else {
      reqObj.agentName = agentName
      reqObj.bankSwift = bankSwift
      reqObj.correspondentSwift = correspondentSwift
      reqObj.bankName = addresseeBank
      reqObj.correspondentName = correspondentName
      reqObj.bankAccount = bankAccountAddressee
      reqObj.correspondentAccount = correspondentAccount
      reqObj.ground = ground
    }
    return reqObj
  }

  const handlePerformOutput = (event) => {
    event.preventDefault()
    if (connectedWS) {
      setFormDisabled(true)
      setIsLoading(true)

      if (submitAction === 'perform') {
        setAllDisabled(true)
      }

      const msgData = getSaveOutputMoney || getPerformOutputMoney
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespOutputMoney?.order?.type?.uid === cancelType) ? cancelType : transferType
      const requestObj = addNeedsProps(typeUID, msgData)

      const date = getRespMetadata?.order?.date
      const number = getRespMetadata?.order?.number

      requestObj.date = date
      requestObj.number = number

      const WordArrayToBase64 = JsonToSHA256(requestObj)
      if (WordArrayToBase64) {
        requestObj.secret = WordArrayToBase64
        setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
      }

      formAction({
        submitAction,
        requestObj,
        typeUID,
        msgData,
        putPerformData,
        postPerformData,
        putSaveData,
        postSaveData,
        activeId,
        code,
        setCodeError,
        setCodeTextError,
        setCancellationModalOpen,
        setFormDisabled,
        setIsLoading,
        currentThumbPrint,
        secretPassPhrase,
      })
    }
  }

  const handleChangePortfolio = (event, selectedValue) => {
    setClientPortfolio(selectedValue)
    setClientMoneyStorage('')
    const currentObj = getPortfolioObj(selectedValue)
    setCurrentClientPortfolio(currentObj)
    if (currentObj?.moneyStorages?.length) {
      setArrayPlatform(currentObj.moneyStorages)
    } else {
      setArrayPlatform('')
    }
    setArrayCurrencies(currentObj?.currencies)
    setCurrentCurrency('')
    setAccountName('')
    setClientPortfolioError(false)
    currentAmount.setValue('')
    setIsRealTime(false)
    setIsNewDetails(false)
  }

  const handleCancelOperation = (event) => {
    if (getRespMetadata?.order?.type?.uid === cancelType) { handleBackToHistory() } else {
      event.preventDefault()
      dispatch(clearPartOutputMoney())
      setAllDisabled(false)
      setFormDisabled(false)
    }
  }

  const handleDownloadXml = () => {
    const msgData = getSaveOutputMoney || getPerformOutputMoney
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.order?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const msgData = getSaveOutputMoney || getPerformOutputMoney
    const typeUID = msgData?.requiredAction && msgData?.order?.type?.uid === cancelType
      ? cancelType : transferType
    const requestObj = addNeedsProps(typeUID, msgData)

    const date = getRespMetadata?.order?.date
    const number = getRespMetadata?.order?.number

    requestObj.date = date
    requestObj.number = number

    const WordArrayToBase64 = JsonToSHA256(requestObj)
    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID,
      putPerformData,
      postPerformData,
      activeId,
      secretPassPhrase,
    })
  }

  const createMarkup = () => (
    { __html: currentStatusDescription }
  )

  const getHandler = (handler) => (!allDisabled ? handler : null)

  const handleCloseSaveModal = (goToHistory) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseUpdModal = () => {
    setUpdModalOpen(false)
    handleBackToHistory()
  }

  const handleClosePerformModal = (goToHistory) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
  }

  const handleCloseDeletePaymentTemplateModal = () => {
    setDeletePaymentTemplateModalOpen(false)
  }

  const handleCancelOk = () => {
    setMetadataUid(cancelType)
    getMetaData(cancelType)
    handleCloseCancellationModal()
  }
  const handleDeletePaymentTemplateOk = () => {
    deletePaymentTemplate(deletePaymentTemplateId)
    setDeletePaymentTemplateId(null)
    setDeletePaymentTemplateModalOpen(false)
  }

  const handleActionReject = () => {
    handleCloseSaveModal(true)
    handleClosePerformModal(true)
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)
    clearFields(false)
    setActiveId(null)
    setFormDisabled(false)
    setMetadataUid(transferType)
    setIsNewDetails(false)
    history.replace('/transfers/money/output/new')
  }

  const handleChangeBankNameAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrBankName(value)
      setBankName(value)
      if (value && value.trim().length >= 3) {
        getBankRecipientFilter(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Search=${value}`)
      }
    }
  }
  const handleChangeBankNameAutocomplete = (event, value) => {
    if (value.name) {
      setSearchStrBankName(value?.name)
      setBankName(value?.name)
      setBic(value?.bic)
      setCorrespondentAccount(value?.correspondentAccount)
      setBankNameError(false)
      setBankBicError(false)
      setCorrespondentAccountError(false)
    } else {
      setSearchStrBankName('')
      setSearchStrCorrespondentAccount('')
      setSearchStrBic('')
      setBankName('')
      setBic('')
      setCorrespondentAccount('')
    }
  }

  const StandartAutocompleteArray = (array, startArray) => {
    const correctStartArray = startArray || []
    return array?.length ? correctStartArray.concat(array) : correctStartArray
  }

  useEffect(() => {
    if (clientPortfolio && currentCurrency) {
      const portfolioObj = getPortfolioObj(clientPortfolio)
      getPaymentTemplatesFilter(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Context.PortfolioID=${portfolioObj?.id}`)
    }
  }, [currentClientPortfolio?.id, currentCurrency, deletePaymentTemplateId])

  const handleChangeAccountNameAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrAccountName(value)

      if (value && value.trim().length >= 1) {
        getPaymentTemplatesFilter(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Context.PortfolioID=${currentClientPortfolio?.id}&Search=${value}`)
      }
    }
  }
  const handleChangeAccountNameAutocomplete = (event, object) => {
    if (object) {
      setSearchStrAccountName(object?.templateName)
      setIsNewDetails(true)
      if (!object?.bankSwift) {
        setBankSwift('')
        setSearchStrBankSwift('')
      }
    }
    setAccountName(object?.templateName)
    fillTemplateFields(object)
    setAccountNameError(false)
    setBankSwiftError(false)
    setCorrespondentAccountError(false)
    setAgentNameError(false)
    setBankNameError(false)
    setBankBicError(false)
    setBankAccountError(false)
    setCorrespondentSwiftError(false)
    setCorrespondentNameError(false)
    if (object?.templateName === 'Добавить новые реквизиты') {
      setIsNewDetails(true)
      setArrayDisabledFields([])
      setSearchStrAccountName(object?.templateName)
      setSearchStrBankName('')
      setSearchStrCorrespondentAccount('')
      setSearchStrBic('')
      setBankName('')
      setBic('')
      setCorrespondentAccount('')
      setBankSwift('')
      setAddresseeBank('')
      setSearchStrAddresseeBank('')
      setSearchStrBankSwift('')
    }
    if (object?.templateName === ' ') {
      setIsNewDetails(false)
    }
  }

  const handleChangeBicAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrBic(value)
      setBic(value)
      if (value && value.trim().length >= 3) {
        getBankRecipientFilter(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Filter=[Bic]+contains+${value}`)
      }
    }
  }
  const handleChangeBicAutocomplete = (event, value) => {
    if (value.bic) {
      setSearchStrBic(value?.bic)
      setBankName(value?.name)
      setBic(value?.bic)
      setCorrespondentAccount(value?.correspondentAccount)
      setBankNameError(false)
      setBankBicError(false)
      setCorrespondentAccountError(false)
    } else {
      setSearchStrBankName('')
      setSearchStrCorrespondentAccount('')
      setSearchStrBic('')
      setBankName('')
      setBic('')
      setCorrespondentAccount('')
    }
  }

  const handleChangeCorrespondentAccountAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrCorrespondentAccount(value)
      setCorrespondentAccount(value)
      if (value && value.trim().length >= 3) {
        getBankRecipientFilter(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Filter=[CorrespondentAccount]+contains+${value}`)
      }
    }
  }
  const handleChangeCorrespondentAccountAutocomplete = (event, value) => {
    if (value.correspondentAccount) {
      setSearchStrCorrespondentAccount(value?.correspondentAccount)
      setBankName(value?.name)
      setBic(value?.bic)
      setCorrespondentAccount(value?.correspondentAccount)
      setBankNameError(false)
      setBankBicError(false)
      setCorrespondentAccountError(false)
      setBankAccountError(false)
      setBankAccountTextError(false)
    } else {
      setSearchStrBankName('')
      setSearchStrCorrespondentAccount('')
      setSearchStrBic('')
      setBankName('')
      setBic('')
      setCorrespondentAccount('')
    }
  }

  const handleChangeAddresseeBankAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrAddresseeBank(value)
      setAddresseeBank(value)
      if (value && value.trim().length >= 3) {
        getBankRecipientFilter(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Search=${value}`)
      }
    }
  }
  const handleChangeAddresseeBankAutocomplete = (event, value) => {
    if (value.name) {
      setSearchStrAddresseeBank(value?.name)
      setBankSwift(value?.swift)
      setAddresseeBank(value?.name)
      setBankSwiftError(false)
      setBankNameError(false)
    } else {
      setBankSwift('')
      setAddresseeBank('')
      setSearchStrAddresseeBank('')
      setSearchStrBankSwift('')
    }
  }

  const handleChangeBankSwiftAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrBankSwift(value)
      setBankSwift(value)
      if (value && value.trim().length >= 3) {
        getBankRecipientFilter(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Filter=[Swift]+contains+${value}`)
      }
    }
  }
  const handleChangeBankSwiftAutocomplete = (event, value) => {
    if (value.swift) {
      setSearchStrBankSwift(value?.swift)
      setBankSwift(value?.swift)
      setAddresseeBank(value?.name)
      setBankSwiftError(false)
      setBankNameError(false)
    } else {
      setBankSwift('')
      setAddresseeBank('')
      setSearchStrAddresseeBank('')
      setSearchStrBankSwift('')
    }
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Box maxWidth={898}>
        <PageHeading text="Вывод" />
        {isLoading && (
          <Box mt="79px">
            <CustomLoader />
          </Box>
        )}
        {!isLoading && (
          <Box pt="30px">
            <form
              className={classes.form}
              onSubmit={handlePerformOutput}
            >
              {transferHeader && (
                <Typography className={classes.transferHeader}>
                  {transferHeader}
                </Typography>
              )}
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                  className={classes.transferFields}
                >
                  <CustomInputSelect
                    label="Портфель"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={clientPortfolio || ''}
                    options={
                      addEmptyValueToArray(
                        getRespMetadata?.references?.portfolios?.map((option) => option.name)
                        || getRespOutputMoney?.order?.affectedOrder?.clientPortfolio?.name,
                      )
                    }
                    error={clientPortfolioError}
                    errorText={clientPortfolioTextError}
                    onChange={getHandler(handleChangePortfolio)}
                    disabled={allDisabled}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                  className={classes.transferFields}
                >
                  <CustomSelect
                    label="Площадка"
                    value={clientMoneyStorage || ''}
                    onChange={getHandler(handleChangePlatform)}
                    error={clientMoneyStorageError}
                    errorText={clientMoneyStorageTextError}
                    unique
                  >
                    {(!!arrayPlatform?.length && arrayPlatform?.map((item) => (
                      <MenuItem
                        value={item?.description}
                        key={item?.uid}
                      >
                        {item?.description}
                      </MenuItem>
                    ))) || (
                    <MenuItem
                      value=""
                    />
                    )}
                  </CustomSelect>
                </Grid>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <div className={classes.amountInfo}>
                    <p className={classes.amountInfo__text}>
                      {`Баланс на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
                    </p>
                    <p className={classes.amountInfo__value}>
                      {((balances && balances.balanceT0 >= 0) && `${numberWithSpaces(getDoubleZero(balances.balanceT0))} ${balances.currency?.uid}`) || ('...')}
                    </p>
                  </div>
                </Grid>
                {(clientMoneyStorage && clientPortfolio && (
                  <>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                      className={classes.transferFields}
                    >
                      <CustomSelect
                        label="Валюта"
                        value={currentCurrency || ''}
                        onChange={getHandler(handleChangeCurrency)}
                        error={currencyError}
                        errorText={currencyTextError}
                        unique
                      >
                        {(!!arrayCurrencies?.length && arrayCurrencies.map((currency) => (
                          <MenuItem
                            value={currency?.uid}
                            key={currency?.id}
                          >
                            {currency?.uid}
                          </MenuItem>
                        ))) || (
                        <MenuItem
                          value=""
                        />
                        )}
                      </CustomSelect>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                      className={classes.transferFields}
                    >
                      <CustomInput
                        variant="outlined"
                        color="secondary"
                        label="Сумма для вывода"
                        value={currentAmount.value}
                        onChange={(e) => {
                          currentAmount.onChange(e)
                          setAmountError(false)
                        }}
                        error={amountError}
                        errorText={amountTextError}
                        unique
                        disabled={allDisabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                      className={classes.transferFields}
                    >
                      <CustomDatepicker
                        error={expirationDateError}
                        errorText={expirationDateTextError}
                        label="Срок действия поручения до"
                        value={validity || ''}
                        onChange={handleChangeValidity}
                        unique
                        minDate={expirationDate || new Date()}
                        maxDate={allDisabled && !canModifyDate ? validity : undefined}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      {(currentCurrency?.toLowerCase() === 'rub' && clientMoneyStorage === 'Внебиржевой счет') && (
                        <div className={classes.urgent__payment}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                onChange={getHandler(handleIsRealTimeSwitchChange)}
                                checked={isRealTime}
                                size="small"
                                disabled={allDisabled}
                              />
                            )}
                            label="Срочный платеж"
                          />
                          <Typography color="secondary">
                            <span>
                              Банком может взиматься дополнительная комиссия
                              за осуществление срочного платежа
                            </span>
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        color="secondary"
                        label="Комментарий"
                        value={commentary || ''}
                        onChange={handleChangeCommentary}
                        unique
                        disabled={allDisabled}
                      />
                    </Grid>
                    {(currentCurrency && (
                      <>
                        <Grid
                          item
                          lg={12}
                          sm={12}
                          xs={12}
                        >
                          <Typography
                            color="secondary"
                            className={classes.bank__details}
                          >
                            <span>
                              Банковские реквизиты
                            </span>
                            <span>
                              Перечисление денежных средств на реквизиты расчетного счета,
                              открытого в банке за пределами РФ, невозможно
                            </span>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          sm={12}
                          xs={12}
                        >
                          <CustomInputSelect
                            label="Наименование счета"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            value={accountName || searchStrAccountName || ''}
                            options={StandartAutocompleteArray(getRespPaymentTemplates && getRespPaymentTemplates, [{ templateName: ' ' }, { templateName: 'Добавить новые реквизиты' }])}
                            error={accountNameError}
                            errorText={accountNameTextError}
                            onChange={getHandler(handleChangeAccountNameAutocomplete)}
                            onInputChange={getHandler(handleChangeAccountNameAutocompleteInput)}
                            disabled={allDisabled}
                            unique
                            onDeleteItem={deleteTemplate}
                            specialOptions={['templateName']}
                            placeholder="Новый счет"
                          />
                        </Grid>
                      </>
                    )) || null}
                  </>
                )) || null}
                {(((currentCurrency && currentCurrency.toLowerCase() === 'rub' && isNewDetails) && (
                  <>
                    <>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          color="secondary"
                          label="Получатель"
                          error={agentNameError}
                          errorText={agentNameTextError}
                          onChange={handleChangeAgentName}
                          value={agentName || ''}
                          unique
                          disabled={allDisabled || arrayDisabledFields?.agentName}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        sm={6}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          color="secondary"
                          label="Расчетный счет"
                          value={bankAccount || ''}
                          error={bankAccountError}
                          errorText={bankAccountTextError}
                          onChange={handleChangeBankAccount}
                          unique
                          disabled={allDisabled || arrayDisabledFields?.bankAccount}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        sm={6}
                        xs={12}
                      >
                        <CustomInputSelect
                          variant="outlined"
                          color="secondary"
                          label="Банк"
                          error={bankNameError}
                          errorText={bankNameTextError}
                          size="small"
                          disabledMain={allDisabled || arrayDisabledFields?.bankName}
                          unique
                          onInputChange={getHandler(handleChangeBankNameAutocompleteInput)}
                          onChange={getHandler(handleChangeBankNameAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: ' ' }])}
                          value={bankName || searchStrBankName || ''}
                          specialOptions={['name']}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        sm={6}
                        xs={12}
                      >
                        <CustomInputSelect
                          variant="outlined"
                          color="secondary"
                          label="Корреспондентский счет"
                          error={correspondentAccountError}
                          errorText={correspondentAccountTextError}
                          size="small"
                          disabledMain={allDisabled || arrayDisabledFields?.correspondentAccount}
                          unique
                          onInputChange={getHandler(handleChangeCorrespondentAccountAutocompleteInput)}
                          onChange={getHandler(handleChangeCorrespondentAccountAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: ' ' }])}
                          value={correspondentAccount || searchStrCorrespondentAccount || ''}
                          specialOptions={['correspondentAccount', 'name']}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        sm={6}
                        xs={12}
                      >
                        <CustomInputSelect
                          variant="outlined"
                          color="secondary"
                          label="БИК"
                          error={bankBicError}
                          errorText={bankBicTextError}
                          size="small"
                          disabledMain={allDisabled || arrayDisabledFields?.bic}
                          unique
                          onInputChange={getHandler(handleChangeBicAutocompleteInput)}
                          onChange={getHandler(handleChangeBicAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: ' ' }])}
                          value={bic || searchStrBic || ''}
                          specialOptions={['bic', 'name']}
                        />
                      </Grid>
                    </>

                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        color="secondary"
                        label="Назначение платежа"
                        value={ground || ''}
                        onChange={handleChangeGround}
                        unique
                        disabled={allDisabled}
                      />
                    </Grid>
                  </>
                )) || ((currentCurrency && currentCurrency?.toLowerCase() !== 'rub' && isNewDetails) && (
                  <>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        color="secondary"
                        label="Получатель"
                        error={agentNameError}
                        errorText={agentNameTextError}
                        value={agentName || ''}
                        onChange={handleChangeAgentName}
                        unique
                        disabled={allDisabled || arrayDisabledFields?.agentName}
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                    >
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          color="secondary"
                          className={classes.bank__details}
                        >
                          <span>
                            Банк получатель
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          color="secondary"
                          label="Расчетный счет"
                          value={bankAccountAddressee || ''}
                          error={bankAccountError}
                          errorText={bankAccountTextError}
                          onChange={handleChangeBankAccountAddressee}
                          unique
                          disabled={allDisabled || arrayDisabledFields?.bankAccountAddressee}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                      >
                        <CustomInputSelect
                          variant="outlined"
                          color="secondary"
                          label="Банк"
                          error={bankNameError}
                          errorText={bankNameTextError}
                          size="small"
                          disabledMain={(allDisabled) || arrayDisabledFields?.addresseeBank}
                          unique
                          onInputChange={getHandler(handleChangeAddresseeBankAutocompleteInput)}
                          onChange={getHandler(handleChangeAddresseeBankAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: ' ' }])}
                          value={addresseeBank || searchStrAddresseeBank || ''}
                          specialOptions={['name']}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                      >
                        <CustomInputSelect
                          variant="outlined"
                          color="secondary"
                          label="SWIFT"
                          error={bankSwiftError}
                          errorText={bankSwiftTextError}
                          size="small"
                          disabledMain={allDisabled || arrayDisabledFields?.bankSwift}
                          unique
                          onInputChange={getHandler(handleChangeBankSwiftAutocompleteInput)}
                          onChange={getHandler(handleChangeBankSwiftAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: ' ' }])}
                          value={bankSwift || searchStrBankSwift || ''}
                          specialOptions={['swift', 'name']}
                        />
                      </Grid>

                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                    >
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                      >
                        <Typography
                          color="secondary"
                          className={classes.bank__details}
                        >
                          <span>
                            Банк-корреспондент
                          </span>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          color="secondary"
                          label="Счет банка получателя"
                          value={correspondentAccount || ''}
                          onChange={handleChangeCorrespondentAccount}
                          error={correspondentAccountError}
                          errorText={correspondentAccountTextError}
                          unique
                          disabled={allDisabled || arrayDisabledFields?.correspondentAccount}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          color="secondary"
                          label="Банк"
                          error={correspondentNameError}
                          errorText={correspondentNameTextError}
                          value={correspondentName || ''}
                          onChange={handleChangeCorrespondentName}
                          unique
                          disabled={allDisabled || arrayDisabledFields?.correspondentName}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          color="secondary"
                          label="SWIFT"
                          error={correspondentSwiftError}
                          errorText={correspondentSwiftTextError}
                          value={correspondentSwift || ''}
                          onChange={handleChangeCorrespondentSwift}
                          unique
                          disabled={allDisabled || arrayDisabledFields?.correspondentSwift}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        color="secondary"
                        label="Назначение платежа"
                        value={ground || ''}
                        onChange={handleChangeGround}
                        unique
                        disabled={allDisabled}
                      />
                    </Grid>
                  </>
                ))) || null}
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xs={12}
                >
                  <CustomInput
                    variant="outlined"
                    color="secondary"
                    label="Основание платежа"
                    value={reason || ''}
                    onChange={handleChangeReason}
                    unique
                    disabled={allDisabled}
                  />
                </Grid>
                {(getSaveOutputMoney?.requiredAction?.type === 'code' || getPerformOutputMoney?.requiredAction?.type === 'code' || getSaveOutputMoney?.requiredAction?.type === 'mac' || getPerformOutputMoney?.requiredAction?.type === 'mac') && (
                  <>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        error={codeError}
                        errorText={codeTextError}
                        variant="outlined"
                        label="Введите код из сообщения"
                        name="code"
                        value={code}
                        onChange={(event) => handleChangeCodeInput(event)}
                        size="small"
                        color="secondary"
                        unique
                        className={classes.inputField}
                        autoComplete="off"
                        helperText={(getPerformOutputMoney?.requiredAction?.type === 'mac' || getSaveOutputMoney?.requiredAction?.type === 'mac') ? hashSha256 : ''}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={8}
                      sm={6}
                      xs={12}
                      className={classes.Transfer__codeWrapper}
                    >
                      {(showTimer && (
                        <Typography
                          className={classes.Transfer__codeText}
                          m={0}
                        >
                          Если код не пришел, Вы можете запросить новый код через
                          <span
                            className={classes.Transfer__codeInput}
                          >
                            <Timer
                              seconds={30}
                              handleStartTimer={handleStartTimer}
                            />
                          </span>
                        </Typography>
                      )) || (!showTimer && (
                        <CustomButton
                          label="Выслать новый код"
                          modifier="primary"
                          type="submit"
                          fullWidth
                          onClick={handleResetTimer}
                        />
                      ))}
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xs={12}
                  className={classes.TransferStatus}
                >
                  {getRespOutputMoney?.order && (
                    <div className={(!!currentStatusDescription && classes.TransferStatus__wrap) || ''}>
                      <Typography
                        className={classes.TransferStatus__name}
                      >
                        Статус:
                        {' '}
                        {currentStatus?.name}
                      </Typography>
                      {currentStatusDescription && (
                        <Typography
                          className={classes.TransferStatus__description}
                          dangerouslySetInnerHTML={createMarkup()}
                        />
                      )}
                    </div>
                  )}
                </Grid>
                {(((currentActions && currentActions?.canModify) || !currentActions)
                  && (!getSaveOutputMoney?.requiredAction?.type
                    && !getPerformOutputMoney?.requiredAction?.type
                  ) && (
                    <Grid
                      item
                      lg={6}
                      sm={6}
                      xs={12}
                      className={classes.btnGroup}
                    >
                      <CustomIconButton
                        type="submit"
                        onClick={() => { setSubmitAction('perform') }}
                        disabled={formDisabled}
                      >
                        Исполнить
                      </CustomIconButton>
                      <CustomIconButton
                        type="submit"
                        onClick={() => { setSubmitAction('save') }}
                        disabled={formDisabled}
                      >
                        Сохранить
                      </CustomIconButton>
                      <Media
                        query="(min-width: 577px)"
                        render={() => (
                          <CustomIconButton
                            type="button"
                            onClick={handleBackToHistory}
                          >
                            Назад
                          </CustomIconButton>
                        )}
                      />
                    </Grid>
                )) || ((getSaveOutputMoney?.requiredAction?.type === 'sign' || getPerformOutputMoney?.requiredAction?.type === 'sign') && (
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    onClick={() => { setSubmitAction('perform') }}
                    type="submit"
                  >
                    Подписать
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    icon={<DownloadIcon />}
                    onClick={handleDownloadXml}
                  >
                    Скачать .xml
                  </CustomIconButton>
                  <Media
                    query="(min-width: 577px)"
                    render={() => (
                      <CustomIconButton
                        type="button"
                        onClick={handleCancelOperation}
                      >
                        Назад
                      </CustomIconButton>
                    )}
                  />
                </Grid>
                )) || ((getSaveOutputMoney?.requiredAction?.type === 'code' || getPerformOutputMoney?.requiredAction?.type === 'code' || getSaveOutputMoney?.requiredAction?.type === 'mac' || getPerformOutputMoney?.requiredAction?.type === 'mac') && (
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    type="submit"
                    onClick={() => { setSubmitAction('perform') }}
                  >
                    Подтвердить
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    onClick={handleCancelOperation}
                  >
                    Отменить
                  </CustomIconButton>
                </Grid>
                )) || ((currentActions && currentActions?.canCancel) && (
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    onClick={() => setCancellationModalOpen(true)}
                  >
                    Отменить поручение
                  </CustomIconButton>
                  <Media
                    query="(min-width: 577px)"
                    render={() => (
                      <CustomIconButton
                        type="button"
                        onClick={handleBackToHistory}
                      >
                        Назад
                      </CustomIconButton>
                    )}
                  />
                </Grid>
                ))
                  || ((currentActions && !currentActions?.canModify && !currentActions?.canCancel)
                    && (
                      <Media
                        query="(min-width: 577px)"
                        render={() => (
                          <Grid
                            item
                            lg={6}
                            sm={6}
                            xs={12}
                            className={classes.btnGroup}
                          >
                            <CustomIconButton
                              type="button"
                              onClick={handleBackToHistory}
                            >
                              Назад
                            </CustomIconButton>
                          </Grid>
                        )}
                      />
                    ))}
              </Grid>
            </form>
          </Box>
        )}
        <CustomModal
          open={saveModalOpen}
          title="Поручение успешно создано."
          handleClose={handleCloseSaveModal}
          actionText="Хотите создать новое?"
          actionReject={handleActionReject}
          actionSuccess={handleActionSuccess}
          disableOverlay
        />
        <CustomModal
          open={updModalOpen}
          title="Поручение обновлено"
          handleClose={handleCloseUpdModal}
        />
        <CustomModal
          open={performModalOpen}
          title="Поручение успешно подписано"
          handleClose={handleClosePerformModal}
          actionText="Хотите создать новое?"
          actionReject={handleActionReject}
          actionSuccess={handleActionSuccess}
          disableOverlay
        />
        <CustomModal
          open={cancellationModalOpen}
          title="Создать поручение на отмену"
          handleClose={handleCloseCancellationModal}
          callbacks={{
            withOk: handleCancelOk,
            withCancel: () => {
              handleCloseCancellationModal()
            },
          }}
        />
        <CustomModal
          open={deletePaymentTemplateModalOpen}
          title={`Удалить счет (${getRespPaymentTemplates?.find((elem) => elem.id === deletePaymentTemplateId)?.templateName}) из списка?`}
          handleClose={handleCloseDeletePaymentTemplateModal}
          callbacks={{
            withOk: handleDeletePaymentTemplateOk,
            withCancel: () => {
              handleCloseDeletePaymentTemplateModal()
            },
          }}
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}

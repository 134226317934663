import { ROUTES } from 'app/data'

const tradeToggler = process.env.REACT_APP_TRADE_TOGGLER || 'show'

// @ts-ignore
// @ts-ignore
// @ts-ignore
export const navList = [
  {
    title: 'Портфель',
    link: ROUTES.portfolio,
    icon: 'menu-portfolio',
    id: 'portfolio',
  },
  {
    title: 'Поручения',
    link: ROUTES.transfers,
    icon: 'menu-transfers',
    id: 'transfers',
  },
  // {
  //   title: 'Торговля',
  //   link: '/trades',
  //   icon: 'menu-trade',
  //   id: 'trades',
  // },
  {
    title: 'Сканер',
    link: ROUTES.scaner,
    icon: 'menu-scan',
    id: 'scaner',
  },
  {
    title: 'Документы',
    link: '/documents',
    icon: 'menu-documents',
    id: 'documents',
  },
  {
    title: 'Отчеты',
    link: ROUTES.reports,
    icon: 'menu-reports',
    id: 'reports',
  },
  {
    title: 'Обращения',
    link: ROUTES.feedback,
    icon: 'menu-feedback',
    id: 'feedback',
  },
  {
    title: 'Контакты',
    link: ROUTES.contacts,
    icon: 'menu-contacts',
    id: 'contacts',
  },
  // @ts-ignore
].filter((item) => (tradeToggler === 'hide' ? item.link !== '/trades' : item))

// export const actionsList = [
//   {
//     title: 'Профиль',
//     link: '/profile',
//     icon: <UserIcon />,
//   },
//   {
//     title: 'Документы на подпись',
//     link: '/sign',
//     icon: <MessagesIcon />,
//     hasBadge: true,
//   },
//   {
//     title: 'Выйти/сменить пользователя',
//     link: '/logout',
//     icon: <ExitIcon />,
//   },
// ]

import { styled } from "ui/theme";
import {Form, FormRow, FormCol, Button, CheckboxField} from 'ui/components';

export const StyledTop = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${({ theme }) => theme.breakpoint.xs} {
    flex-direction: column;
    gap: 36px;
  }
`

export const StyledContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xl};
  box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.04);
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin-bottom: 28px;
  @media (${({ theme }) => theme.breakpoint.sm}) {
    padding: ${({ theme }) => theme.spacing.lg};
  }
  @media (${({ theme }) => theme.breakpoint.xs}) {
    padding: 0;
    margin-top: 32px;
    box-shadow: none;
  }
`

export const StyledHeading = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xs};

  @media ${({ theme }) => theme.breakpoint.xs} {
    max-width: 465px;  }

`

export const StyledFormRightCol = styled(FormCol)<{ gap?: number }>`
  min-width: 250px;
  ${({gap}) => gap ? `gap: ${gap}px` : ''}
`

export const StyledPriceChangeCardWrapper = styled.div`
  max-width: 250px;
  padding-top: 21px;
  @media ${({ theme }) => theme.breakpoint.xs} {
    padding: 0;
  }
`

export const StyledTabsWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  @media ${({ theme }) => theme.breakpoint.xs} {
    margin: 0;
  }
`

export const StyledHistoryStatusCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledHistoryTableButton = styled(Button)`
  color: ${({ theme }) => theme.color.graphics.secondary};
`

export const StyledHistoryForm = styled(Form)`
margin-bottom: 32px;
@media ${({ theme }) => theme.breakpoint.xs} {
  display: flex;
  ${FormRow} {

  }
}
@media ${({ theme }) => theme.breakpoint.sm} {
  margin-bottom: 32px;
  ${FormRow} {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

}
`

export const StyledBadge = styled.div<{ variant: string }>`
  font-size: 12px;
  line-height: 16px;
  padding: 4px 12px;
  border-radius: 4px;
  font-weight: 400;
  ${({ variant, theme }) => `
    ${variant === 'default' ? `
      color: ${theme.color.text.secondary};
      background: ${theme.color.graphics.processing};
    ` : ''}
    ${variant === 'success' ? `
      color: ${theme.color.text.positive};
      background: rgba(8, 145, 112, 0.1);
    ` : ''}
    ${variant === 'danger' ? `
      color: #CA5964;
      background: rgba(202, 89, 100, 0.1);
    ` : ''}

  `}
`

export const StyledTableWrapper = styled.div`
  margin-bottom: 40px;
`

export const StyledTableActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 16px;

  @media (${({ theme }) => theme.breakpoint.xxs}) {
    flex-direction: row-reverse;
  }
`

export const StyledCheckboxField = styled(CheckboxField)`
  margin-left: 10px;

  @media (${({ theme }) => theme.breakpoint.xxs}) {
    flex-direction: row-reverse;
    margin-right: 15px;
  }
`

export const StyledTableActionsButtonWrapper = styled.div`
  @media (${({ theme }) => theme.breakpoint.xxs}) {
    display: none;
  }
  display: flex;
  gap: 16px;
`

import React from 'react'
import Grid from '@material-ui/core/Grid'
import CustomInput from '../../components/CustomControls/CustomInput'
import CustomCheckbox from '../../components/CustomControls/CustomCheckbox'

const FieldsGenerator = (fieldsData, values, setValues, allDisabled) => (fieldsData?.map((element) => {
  const { fieldName, fieldCaption, fieldType } = element

  const commonProps = {
    key: fieldName,
    disabled: allDisabled,
    label: fieldCaption,
    onChange: (event) => {
      const key = fieldType === 'System.Boolean' ? 'checked' : 'value'
      const { target } = event

      setValues((v) => ({ ...v, [fieldName]: target[key] }))
    },
    otherData: fieldName,
    value: values[fieldName],
  }

  const inputByType = {
    'System.Boolean': (
      <CustomCheckbox
        size="small"
        checked={!!values[fieldName]}
        isDocument
        {...commonProps}
      />
    ),
    'System.String': (
      <CustomInput
        color="secondary"
        unique
        isDocument
        {...commonProps}
      />
    ),
    'System.Int32': (
      <CustomInput
        color="secondary"
        unique
        isDocument
        {...commonProps}
      />
    ),
  }

  const input = inputByType[fieldType]

  return input && (
  <Grid
    item
    sm={6}
    xs={12}
  >
    {input}
  </Grid>
  )
})
)

export default FieldsGenerator

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  list__item: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 36,
    paddingLeft: 10,
    borderLeft: '6px solid #91b1bf',
    '&:not(:last-child)': {
      marginRight: 40,
      '@media (max-width: 576px)': {
        marginRight: 0,
        marginBottom: 17,
      },
    },
  },
  list__title: {
    marginBottom: 8,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '14px',
    color: '#91b1bf',
    '@media (max-width: 576px)': {
      marginBottom: 4,
      fontSize: 12,
    },
  },
  list__value: {
    fontSize: 15,
    lineHeight: '14px',
    color: theme.palette.type === 'light' ? '#363E47' : '#fff',
    '@media (max-width: 576px)': {
      fontSize: 16,
      lineHeight: '18px',
    },
  },
}))

export default useStyles

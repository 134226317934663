import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ConnectionContext } from '../hoc/socket/socket'
import { getErrorSuccess } from '../redux/error/errorSlice'

export default function useArrayConnect(props) {
  const {
    link, startFetching, action, idList,
  } = props
  const context = useContext(ConnectionContext)
  const dispatch = useDispatch()

  useEffect(() => {
    if (idList.length) {
      idList.forEach((id) => {
        context.connection.on(`${link}/${id}`, (status, response) => {
          const parsedResponse = JSON.parse(response)
          switch (status) {
            case 200:
              // eslint-disable-next-line no-console
              console.log('connected')
              dispatch(action({ ...parsedResponse, status }))
              break
            case 303:
              // eslint-disable-next-line no-console
              console.log('Need next step')
              dispatch(action({ ...parsedResponse, status }))
              break
            case 400:
              dispatch(getErrorSuccess({ ...parsedResponse }))
              dispatch(action({ ...parsedResponse, status }))
              break
            case 500:
              dispatch(getErrorSuccess({ ...parsedResponse }))
              dispatch(action({ ...parsedResponse, status }))
              break
            default:
              break
          }
        })
      })
    }
    return () => {
      // eslint-disable-next-line no-unused-expressions
      idList && idList.forEach((id) => context.connection.off(`${link}/${id}`))
      // eslint-disable-next-line no-console
      console.log('disconnected')
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idList])

  return { getData: startFetching }
}

import React from 'react'
import { useSelector } from 'react-redux'
import cuid from 'cuid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import CollapseProfileWithContent from '../../CollapseProfileWithContent/CollapseProfileWithContent'
import PanelContentComponent from './PanelContentComponent'

import { selectFormsData } from '../../../redux/profilePanel/selector'

const useStyle = makeStyles({
  boxWrapper: {
    paddingTop: 49,
    '@media(max-width: 576px)': {
      paddingTop: 0,
    },
  },
})

export default function BeneficiariesPanel() {
  const classes = useStyle()
  const respGetData = useSelector(selectFormsData)

  const contentArray = []
  const listForms = respGetData?.forms[0]?.list[0]

  if (listForms?.vip?.beneficiaries?.length) {
    // eslint-disable-next-line no-unused-expressions
    listForms?.vip?.beneficiaries.map((beneficiar, key) => contentArray.push(
      {
        key: key.toString() + cuid(),
        title: beneficiar.shortName,
        Component: PanelContentComponent,
        data: beneficiar,
      },
    ))
  } else if (
    listForms?.beneficiary
      && Object.keys(listForms?.beneficiary).length
  ) {
    contentArray.push(
      {
        key: `0${cuid()}`,
        title: listForms?.beneficiary?.shortName,
        Component: PanelContentComponent,
        data: listForms?.beneficiary,
      },
    )
  }

  return (
    <Box className={classes.boxWrapper}>
      <CollapseProfileWithContent contentArray={contentArray} />
    </Box>
  )
}

import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Grid, Modal } from '@material-ui/core'
import useStyles from './MobileFilterNew.style'
import { Button } from '../../ui/components'
import useDisableBodyScrollNew from '../../hooks/useDisableBodyScrollNew'

const MobileFilterNew = ({
  children, handleAcceptFilter, handleResetFilter, constItems,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [filterCount, setFilterCount] = useState(0)

  useDisableBodyScrollNew(open)

  const handleOpenFilter = () => {
    setOpen(true)
  }

  useEffect(() => {
    let count = 0
    _.map(constItems, (item) => {
      if (item) {
        count += 1
      }
      if (item === false) {
        count -= 1
      }
    })
    if (count < 0) count = 0
    setFilterCount(count)
  }, [constItems])

  return (
    <div
      className={classes.MobileFilter}
    >

      <div className={classes.FilterUnderMobile}>
        <div className={classes.MobileFilter__btnWrapper}>

          <Button
            fullWidth
            variant="secondary"
            onClick={handleOpenFilter}
            type="button"
          >
            Фильтры
            {(filterCount && (
              <span
                className={classes.MobileFilter__buttonCount}
              >
                {filterCount}
              </span>
            )) || null}
          </Button>

        </div>
      </div>

      <Modal
        open
        className={
          classnames(classes.MobileFilter__modal, { [classes.MobileFilter__modal_active]: open })
        }
        onBackdropClick={() => setOpen(false)}
        disableEnforceFocus
      >

        <div>
          <div className={classes.FilterUnder} />
          <div className={classes.MobileFilter__modalContent}>
            {children}
            <Grid
              item
              className={classes.MobileFilter__groupBtn}
            >

              <Button
                variant="primary"
                fullWidth
                onClick={() => {
                  setOpen(false)
                  handleAcceptFilter()
                }}
              >
                Применить
              </Button>
              <Button
                variant="secondary"
                fullWidth
                onClick={() => {
                  handleResetFilter()
                  setFilterCount(0)
                }}
              >
                Очистить
              </Button>

            </Grid>
          </div>

        </div>

      </Modal>
    </div>
  )
}

MobileFilterNew.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleAcceptFilter: PropTypes.func.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
  constItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default MobileFilterNew

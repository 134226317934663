import React from 'react';

import { useHistory } from "react-router-dom";
import { BlockHeading, Button, CustomModal, Icon, Text } from "ui/components";
import { styled } from "ui/theme"

export const TransferHeading: React.FC<{ transferHeader?: string, isRemoval?: boolean, isDownload?: boolean, deletedOrderCall?: () => void }> = ({ transferHeader,  isRemoval = true, isDownload = true, deletedOrderCall}) => {
  const history = useHistory()
  if (!transferHeader) return null;

  return <StyledTransferHeading>
    <BlockHeading
      title={
        <>
          <Button
            variant="plain"
            onClick={() => { history.go(-1) }}>
            <Icon name={'arrow-left'} size={24} />
          </Button>
          <span>{transferHeader}</span>
        </>
      }
      border={false}
    />
    <StyledActions>

      { isDownload &&
        <Button
          variant="plain"
          disabled
        >
          <Icon name="download" size={24} />
          <Text variant='body1_lg'>PDF</Text>
        </Button>
      }

      { (isRemoval && isDownload) && <StyledActionsSep /> }

      { isRemoval &&
        <Button
          variant="plain"
          onClick={deletedOrderCall}
        >
        <Icon name="trash" size={24} />
      </Button>
      }

    </StyledActions>
    {/* <CustomModal
      open={deleteModalOpen}
      title={`Удалить черновик поручения?`}
      actionText="Отменить это действие будет невозможно"
      handleClose={handleCloseDeleteModal}
      callbacks={{
        withOk: handleDeleteOk,
        withCancel: () => {
          handleCloseDeleteModal()
        },
      }}
    /> */}
  </StyledTransferHeading>
}

const StyledTransferHeading = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 32px;
  @media(${({ theme }) => theme.breakpoint.xs}) {
    flex-direction: column;
  }
`

const StyledActions = styled.div`
  background: #F4F6FC;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  gap: 12px;
  align-items: center;
  ${Button} {
    display: flex;
    gap: 4px;
    align-items: center;

  }
`
const StyledActionsSep = styled.div`
  border-left: 1px solid #D1D6E1;
  height: 24px;
`

import { createSelector } from '@reduxjs/toolkit'

const selectFeedback = (state) => state.feedback
const selectFeedbackNew = (state) => state.feedbackNew

export const selectHistoryData = createSelector(
  [selectFeedback],
  (state) => state.history?.data,
)

export const selectGetFeedbackData = createSelector(
  [selectFeedback],
  (state) => state.getFeedback?.data,
)

export const selectPostFeedbackData = createSelector(
  [selectFeedback],
  (state) => state.postFeedback?.data,
)
export const selectPostFeedbackDataStatus = createSelector(
  [selectFeedback],
  (state) => state.postFeedback?.status,
)

export const selectPutFeedbackData = createSelector(
  [selectFeedback],
  (state) => state.putFeedback?.data,
)
export const selectPutFeedbackDataStatus = createSelector(
  [selectFeedback],
  (state) => state.putFeedback?.status,
)

export const selectFeedbackStatus = createSelector(
  [selectFeedbackNew],
  (state) => state.status,
)

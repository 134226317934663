import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Auth from './auth'
import Selector from './selector'
import Certificates from '../Certificates'
import Code from './code'
import {
  selectCurrentUser,
  selectCurrentCertificate, selectCurrentCode, selector,
} from '../../redux/user/selector'
import { CustomModal } from '../../ui/components'

const Login = () => {
  const history = useHistory()
  const location = useLocation()
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [stepVisibility, setStepVisibility] = useState({
    auth: true,
    select: false,
    sign: false,
    code: false,
  })
  const [arraySteps, setArraySteps] = useState(['auth'])
  const [cause, setCause] = useState('')
  const [selectorItems, setSelectorItems] = useState([])

  const currentUser = useSelector(selectCurrentUser)
  const currentCertificate = useSelector(selectCurrentCertificate)
  const currentCode = useSelector(selectCurrentCode)
  const getSelector = useSelector(selector)

  const setStepArray = (currentStep) => {
    const currentArraySteps = arraySteps
    const getLastStep = currentArraySteps[currentArraySteps.length - 1]
    if (getLastStep !== 'auth') {
      currentArraySteps.splice(currentArraySteps.length - 1, 1)
    }
    setStepVisibility({
      ...stepVisibility,
      [getLastStep]: true,
      [currentStep]: false,
    })
    setArraySteps(currentArraySteps)
  }

  useEffect(() => {
    if (location?.state?.error === 'forbidden') {
      setSaveModalOpen(true)
      const state = { ...history.location.state }
      delete state.error
      history.replace({ ...history.location, state })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.error])

  useEffect(() => {
    const requiredAction = getSelector
    if (requiredAction) {
      setStepVisibility({
        auth: false,
        select: true,
        sign: false,
        code: false,
      })
      setCause(requiredAction?.cause)
      setSelectorItems(requiredAction?.selectAction?.items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelector])

  useEffect(() => {
    const accessToken = currentUser?.accessToken
    if (accessToken) {
      history.push('/')
    }
    const type = currentCertificate?.requiredAction?.type
    if (type) {
      switch (type) {
        case 'sign':
          setStepVisibility({
            auth: false,
            select: false,
            sign: true,
            code: false,
          })
          break
        case 'code':
          setStepVisibility({
            auth: false,
            select: false,
            sign: false,
            code: true,
          })
          break
        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCertificate, currentUser])

  useEffect(() => {
    const type = currentCertificate?.requiredAction?.type
    if (type === 'code') {
      setStepVisibility({
        auth: false,
        select: false,
        sign: false,
        code: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCertificate])

  useEffect(() => {
    const type = currentCode?.requiredAction?.type
    const accessToken = currentCode?.accessToken
    if (type) {
      switch (type) {
        case 'sign':
          setStepVisibility({
            auth: false,
            select: false,
            sign: true,
            code: false,
          })
          break
        case 'code':
          setStepVisibility({
            auth: false,
            select: false,
            sign: false,
            code: true,
          })
          break
        default:
          break
      }
    }
    if (accessToken) {
      history.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode])

  const handleCloseSaveModal = () => {
    setSaveModalOpen(false)
  }

  return (
    <>
      <div style={{ width: '100%', maxWidth: 460 }}>
        {stepVisibility.auth && <Auth stepVisibility={stepVisibility} />}
        {stepVisibility.select && (
          <Selector
            setStepVisibility={setStepVisibility}
            stepVisibility={stepVisibility}
            setStepArray={setStepArray}
            cause={cause}
            selectorItems={selectorItems}
            arraySteps={arraySteps}
            setArraySteps={setArraySteps}
          />
        )}
        {stepVisibility.sign && (
          <Certificates
            setStepVisibility={setStepVisibility}
            stepVisibility={stepVisibility}
            setStepArray={setStepArray}
            arraySteps={arraySteps}
            setArraySteps={setArraySteps}
          />
        )}
        {stepVisibility.code && (
          <Code
            setStepVisibility={setStepVisibility}
            stepVisibility={stepVisibility}
            setStepArray={setStepArray}
            arraySteps={arraySteps}
            setArraySteps={setArraySteps}
          />
        )}
      </div>
      <CustomModal
        open={saveModalOpen}
        title="Ссылка недействительна"
        handleClose={handleCloseSaveModal}
      />
    </>
  )
}

export default Login

import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import classnames from 'classnames'
import PropTypes from 'prop-types'
import Media from 'react-media'
import { format } from 'date-fns'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'

import Box from '@material-ui/core/Box'
import { fetchHistoryStartAsync, getHistorySuccess } from '../../redux/feedback/feedbackSlice'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import { selectHistoryData } from '../../redux/feedback/selector'

import useStyles from './panel.style'
import PageHeading from '../PageHeading/PageHeading'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import { getStatus, getAppeal } from '../../utils/GetStatus'

const HistoryTable = ({ fetchHistoryStart, connectedWS }) => {
  const classes = useStyles()
  const history = useHistory()
  const { getData } = useConnect({ link: `GET /api${endpoints.feedback.getUser()}`, startFetching: fetchHistoryStart, action: getHistorySuccess })
  useEffect(() => {
    if (connectedWS) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])
  const historyData = useSelector(selectHistoryData)
  const handleOpenRequest = (id) => () => {
    const nextLocation = {
      pathname: 'new',
      state: { activeMessage: id },
    }
    history.push(nextLocation)
  }
  return (
    <>
      <PageHeading text="История обращений" />
      <Box className={classes.History__BoxWrapper}>
        <Media
          query="(min-width: 577px)"
          render={() => (
            <TableContainer style={{ paddingTop: 25 }}>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableHeaderCell}>Дата</TableCell>
                    <TableCell className={classes.tableHeaderCell}>ID</TableCell>
                    <TableCell className={classes.tableHeaderCell}>Тип</TableCell>
                    <TableCell className={classes.tableHeaderCell}>Тема</TableCell>
                    <TableCell className={classes.tableHeaderCell}>Статус</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyData && ([...historyData?.usersRequests].reverse()?.map((row) => (
                    <TableRow
                      className={classes.tableRow}
                      key={row.id}
                      onClick={handleOpenRequest(row?.id)}
                    >
                      <TableCell>{format(new Date(row?.dateTime), "dd.MM.yyyy' 'HH:mm")}</TableCell>
                      <TableCell>{row?.id}</TableCell>
                      <TableCell>{getAppeal(row?.type)}</TableCell>
                      <TableCell>{row?.subject}</TableCell>
                      <TableCell>{getStatus(row?.status)}</TableCell>
                    </TableRow>
                  ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        />
        <Media
          query={`(max-width: ${breakpoints.phone_large}px)`}
          render={() => historyData && ([...historyData?.usersRequests].reverse()?.map((row) => (
            <div
              className={classes.PhoneGrid}
              key={row.id}
              onClick={handleOpenRequest(row?.id)}
              aria-hidden="true"
            >
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header_s}
                >
                  Дата отправления
                </span>
                <p className={
                  classnames(classes.PhoneGrid__value, classes.PhoneGrid__value_medium)
                }
                >
                  {format(new Date(row?.dateTime), "dd.MM.yyyy' 'HH:mm")}
                </p>
              </div>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header_s}
                >
                  ID
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.id}
                </p>
              </div>
              <div className={
                classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullColumn)
              }
              >
                <span
                  className={classes.PhoneGrid__header_s}
                >
                  Тип
                </span>
                <p className={classes.PhoneGrid__value}>
                  {getAppeal(row?.type)}
                </p>
              </div>
              <div className={
                classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullColumn)
              }
              >
                <span
                  className={classes.PhoneGrid__header_s}
                >
                  Тема
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.subject}
                </p>
              </div>
              <div className={classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullRow)}>
                <span
                  className={classes.PhoneGrid__header_s}
                >
                  Статус
                </span>
                <p className={classes.PhoneGrid__value}>
                  {getStatus(row?.status)}
                </p>
              </div>
            </div>
          )))}
        />
      </Box>
    </>
  )
}

HistoryTable.propTypes = {
  fetchHistoryStart: PropTypes.func.isRequired,
  connectedWS: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
})

const mapDispatchToProps = (dispatch) => ({
  fetchHistoryStart: () => dispatch(fetchHistoryStartAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTable)

import endpoints from 'api/endpoints'
import CustomLoader from 'components/CustomLoader/CustomLoader'
import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectConnectionState } from 'redux/connection/selector'
import {
  deleteSecuritySuccess,
  fetchDeleteSecurityStartAsync,
} from 'redux/securities/securitiesSlice'
import { selectCurrentDeleteSecurity } from 'redux/securities/selector'
import { CustomModal } from 'ui/components/organisms'
import useArrayConnect from 'utils/ArrayConnect'

export interface IUseDeleteSecurity {
  onSuccess?(): void
}

export const useDeleteSecurity = ({ onSuccess }: IUseDeleteSecurity) => {
  const connectedWS = useSelector(selectConnectionState)
  const deletedSecurity = useSelector(selectCurrentDeleteSecurity)

  const dispatch = useDispatch()

  const [idFiles, setIdFiles] = useState([])
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [finishSignLoaderModalOpen, setFinishSignLoaderModalOpen] = useState(false)
  const [finishDeleteSignModalOpen, setFinishDeleteSignModalOpen] = useState(false)
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false)
  const [selected, setSelected] = useState([])

  const fetchDeleteSecurityStart = (id) => {
    dispatch(fetchDeleteSecurityStartAsync({ id }))
  }

  const { getData: deleteData } = useArrayConnect({
    link: `DELETE /api${endpoints.securities.getSecurity()}`,
    idList: idFiles,
    startFetching: fetchDeleteSecurityStart,
    action: deleteSecuritySuccess,
  })

  const handleRowRemove = (id) => {
    setSelected([id])

    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const callActionOnOk = (actionMethod) => {
    if (connectedWS) {
      setIdFiles(selected)
      selected.forEach((id) => {
        actionMethod(id)
      })
      setFinishSignLoaderModalOpen(true)
    }
  }

  const handleDeleteOk = () => {
    callActionOnOk(deleteData)
    setDeleteModalOpen(false)
  }

  const onCloseDeleteSignModal = () => {
    setFinishDeleteSignModalOpen(false)
    setIsDeleteSuccess(false)
  }

  useEffect(() => {
    if (deletedSecurity) {
      const isSuccess = deletedSecurity?.status === 200

      setIsDeleteSuccess(isSuccess)

      setFinishSignLoaderModalOpen(false)
      setFinishDeleteSignModalOpen(true)

      if (isSuccess) onSuccess?.()
    }
  }, [deletedSecurity, onSuccess])

  const DeleteModals: FC = () => (
    <>
      <CustomModal
        open={deleteModalOpen}
        title={`Удалить черновик поручения?`}
        actionText="Отменить это действие будет невозможно"
        textOk="Да, удалить"
        handleClose={handleCloseDeleteModal}
        callbacks={{
          withOk: handleDeleteOk,
          withCancel: handleCloseDeleteModal,
        }}
      />

      {/*TODO возможно нужно оставить модалку для ошибок*/}
      {/*<CustomModal*/}
      {/*  open={finishDeleteSignModalOpen}*/}
      {/*  title={isDeleteSuccess ? 'Поручение успешно удалено' : 'Ошибка при удалении поручения'}*/}
      {/*  handleClose={onCloseDeleteSignModal}*/}
      {/*  callbacks={{*/}
      {/*    withCancel: () => {*/}
      {/*      onCloseDeleteSignModal()*/}
      {/*    },*/}
      {/*  }}*/}
      {/*/>*/}

      {/*<CustomModal*/}
      {/*  open={finishSignLoaderModalOpen}*/}
      {/*  title={''}*/}
      {/*  callbacks={{*/}
      {/*    withCancel: () => {*/}
      {/*      setFinishSignLoaderModalOpen(false)*/}
      {/*    },*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <CustomLoader />*/}
      {/*</CustomModal>*/}
    </>
  )

  return {
    setSelected,
    selected,
    handleRowRemove,
    idFiles,
    onCloseDeleteSignModal,
    DeleteModals,
  }
}

import { request } from './Request'
import { syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  login(data) {
    return request(syncApiURL, endpoints.auth.login, 'POST', data)
  },
  reset(data) {
    return request(syncApiURL, endpoints.auth.reset, 'POST', data)
  },
  logout() {
    return request(syncApiURL, endpoints.auth.logout, 'POST')
  },
  status() {
    return request(syncApiURL, endpoints.auth.status)
  },
}

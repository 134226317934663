import React, {
  Fragment, useEffect, useMemo, useState,
} from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import sanitizeHtml from 'sanitize-html'
import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Media from 'react-media'
import { ReactComponent as DocPlusIcon } from '../../assets/images/doc-plus.svg'
import CustomSelect from '../CustomControls/CustomSelect'
import CustomDatepicker from '../CustomControls/CustomDatepicker'
import CustomInput from '../CustomControls/CustomInput'
import CustomIconButton from '../CustomControls/CustomIconButton'
import CustomSwitch from '../CustomControls/CustomSwitch'
import DropZone from '../DropZone'
import CustomAutocomplete from '../CustomControls/CustomAutocomplete'
import useStyles from './panel.style'
import { selectConnectionState } from '../../redux/connection/selector'
import {
  selectCurrentSecurities,
  selectCurrentGetSecurity,
  selectCurrentSaveSecurity,
  selectCurrentPerformSecurity,
} from '../../redux/securities/selector'
import { selectCurrentMetadata } from '../../redux/metadata/selector'
import { selectCurrentError } from '../../redux/error/selector'
import {
  selectGetFilesData,
  selectPostFilesData,
  selectPostFilesDataError,
} from '../../redux/file/selector'
import {
  clearSecurities,
  fetchGetSecurityStartAsync,
  fetchPerformSecurityStartAsync,
  fetchSaveSecurityStartAsync,
  fetchSecuritiesStartAsync,
  getSecuritiesSuccess,
  getSecuritySuccess,
  performSecuritySuccess,
  saveSecuritySuccess,
  clearPartSecurities,
} from '../../redux/securities/securitiesSlice'
import {
  clearMetadata,
  fetchMetadataStartAsync,
  getMetadataSuccess,
} from '../../redux/metadata/metadataSlice'
import {
  fetchGetFileStartAsync,
  fetchPostFileStartAsync,
  getFileSuccess,
  postFileSuccess,
  fileClean,
} from '../../redux/file/fileSlice'
import useConnect from '../../utils/Connect'
import createUri from '../../utils/FilterUri'
import { encodeToUTF8 } from '../../utils/EncodeToUTF8'
import Timer from '../../utils/Timer'
import useArrayConnect from '../../utils/ArrayConnect'
import endpoints from '../../api/endpoints'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import useWindowSize from '../../hooks/useWindows'
import CustomModal from '../CustomModal/CustomModal'
import { ReactComponent as DownloadIcon } from '../../assets/images/icon-button-download.svg'
import { selectGetBalanceSecurity } from '../../redux/transfers/security/selector'
import {
  fetchGetBalancesDepoStartAsync,
  getBalancesDepoSuccess,
} from '../../redux/transfers/security/securityMoneySlice'
import CustomButton from '../CustomControls/CustomButton'
import formAction from '../../utils/FormAction'
import CustomInputSelect from '../CustomControls/CustomInputSelect'
import { addEmptyValueToArray } from '../../utils/Validate'
import { currentUserLoginAndId, selectCurrentThumbprint } from '../../redux/user/selector'
import useInput from '../../hooks/useInput'
import JsonToSHA256 from '../../utils/JsonToSHA256'

const initialState = {
  issuer: {
    id: null,
    name: '',
    isin: '',
    registerNumber: '',
    type: '',
  },
  searchStr: '',
  input: {
    type: '',
    id: null,
    code: '',
    name: '',
    isin: '',
    registerNumber: '',
  },
  files: [],
  idFiles: [],
  validity: new Date(),
}

const CustomRadio = withStyles({
  root: {
    color: '#91B1BF',
    '&$checked': {
      color: '#91B1BF',
    },
  },
  checked: {},
})((props) => (
  <Radio
    color="default"
    {...props}
  />
))

const cancelType = 'Orders.Type.Modify.Cancellation'

export default function Assignment({ hasSecuritySwitch, securityType }) {
  const [isDocLoading, setIsDocLoading] = useState(false)
  const [docFiles, setDocFiles] = useState([])
  const classes = useStyles({
    needEmptySpace: !isDocLoading && !docFiles?.length,
  })
  const dispatch = useDispatch()
  const { windowWidth } = useWindowSize()
  const urlParams = useParams()
  const location = useLocation()
  const transferId = useMemo(() => urlParams.id, [urlParams])
  const transferUid = useMemo(() => location?.state?.orderUid, [location])
  const amount = useInput('', { maxLength: 3, isOnlyNumbers: true })
  const [metadataUid, setMetadataUid] = useState(transferUid || securityType)
  const [formDisabled, setFormDisabled] = useState(false)
  const [orderType, setOrderType] = useState('')
  const [validity, setValidity] = useState(null)
  const [portfolio, setPortfolio] = useState('')
  const [storagePlus, setStoragePlus] = useState('')
  const [searchStr, setSearchStr] = useState(initialState.searchStr)
  const [input, setInput] = useState(initialState.input)
  const [counterpartyTemplate, setCounterpartyTemplate] = useState('')
  const [accountType, setAccountType] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [dealDate, setDealDate] = useState(null)
  const [reRegistrationDate, setReRegistrationDate] = useState(null)
  const [documentDate, setDocumentDate] = useState(null)
  const [depoAccount, setDepoAccount] = useState('')
  const [agentName, setAgentName] = useState('')
  const [depoAccountDivision, setDepoAccountDivision] = useState('')
  const [depoCode, setDepoCode] = useState('')
  const [interDepoAgreementNumber, setInterDepoAgreementNumber] = useState('')
  const [docNumber, setDocNumber] = useState('')
  const [expirationDate, setExpirationDate] = useState(null)
  const [reference, setReference] = useState('')
  const [arrayTypeDocument] = useState([
    {
      eng: 'foreignNationalPassport',
      ru: 'Паспорт иностранца',
    },
    {
      eng: 'foreignPassport',
      ru: 'Загранпаспорт',
    },
    {
      eng: 'idenityCard',
      ru: 'Удостоверение личности',
    },
    {
      eng: 'militaryID',
      ru: 'Военный билет',
    },
    {
      eng: 'passport',
      ru: 'Паспорт',
    },
    {
      eng: 'refugeeCertificate',
      ru: 'Удостоверение беженца',
    },
    {
      eng: 'residencePermit',
      ru: 'Вид на жительство',
    },
    {
      eng: 'temporaryIdentityCard',
      ru: 'Временное удостоверение личности',
    },
    {
      eng: 'temporaryResidencePermit',
      ru: 'Разрешение на временное проживание',
    },
  ])
  const [currentTypeDocument, setCurrentTypeDocument] = useState('')
  const [personSeries, setPersonSeries] = useState('')
  const [personNumber, setPersonNumber] = useState('')
  const [personCode, setPersonCode] = useState('')
  const [personIssueDate, setPersonIssueDate] = useState('')
  const [personExpirationDate, setPersonExpirationDate] = useState('')
  const [firmSeries, setFirmSeries] = useState('')
  const [firmNumber, setFirmNumber] = useState('')
  const [firmIssuingAuthority, setFirmIssuingAuthority] = useState('')
  const [firmValidity, setFirmValidity] = useState('')
  const [personIssuingAuthority, setPersonIssuingAuthority] = useState('')
  const [arrayDocuments, setArrayDocuments] = useState([])
  const [submitAction, setSubmitAction] = useState([])
  const [switchStates, setSwitchStates] = useState({
    first: false,
  })
  const [currency, setCurrency] = useState('')
  const [centralDepository, setCentralDepository] = useState('')
  const [groupOneVisibility, setGroupOneVisibility] = useState(false)
  const [groupTwoVisibility, setGroupTwoVisibility] = useState(false)
  const [groupThreeVisibility, setGroupThreeVisibility] = useState(false)
  const [groupFourVisibility, setGroupFourVisibility] = useState(false)
  const [groupFiveVisibility, setGroupFiveVisibility] = useState(false)
  const [issuer, setIssuer] = useState(initialState.issuer)
  const [disabled, setDisabled] = useState(transferId === 'new')
  const [activeId, setActiveId] = useState('')
  const [idDocCostFiles, setIdDocCostFiles] = useState('')
  const [scanFiles, setScanFiles] = useState([])
  const [temporaryScanFiles, setTemporaryScanFiles] = useState([])
  const [temporaryDocFiles, setTemporaryDocFiles] = useState([])
  const [idScanFiles, setIdScanFiles] = useState([])
  const [idFileList, setIdFileList] = useState([])
  const [open, setOpen] = useState('')
  const [currentActions, setCurrentActions] = useState(null)
  const [radioValue, setRadioValue] = useState('firm')
  const [clientDepoStorage, setClientDepoStorage] = useState([])
  const [orderSubTypes, setOrderSubTypes] = useState([])
  const [currentArrayTemplates, setCurrentArrayTemplates] = useState([])
  const [centralDepositories, setCentralDepositories] = useState('')
  const [depoAccountHoldingTypes, setDepoAccountHoldingTypes] = useState('')
  const [securityTypes, setSecurityTypes] = useState('')
  const [orderReasonTypes, setOrderReasonTypes] = useState('')
  const [nameDocument, setNameDocument] = useState('')
  const [orderReasonTypeUID, setOrderReasonTypeUID] = useState('')
  const [arrayPortfolios, setArrayPortfolios] = useState([])
  const [comment, setComment] = useState('')
  const [acquisitionCost, setAcquisitionCost] = useState('')
  const [arrayCurrencies, setArrayCurrencies] = useState([])
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')
  const [codeTextError, setCodeTextError] = useState('')
  const [showTimer, setShowTimer] = useState(false)
  const [currentStatus, setCurrentStatus] = useState('')
  const [fileContainer, setFileContainer] = useState([])
  const [orderSubTypeUid, setOrderSubTypeUid] = useState(0)
  const [securityHeader, setSecurityHeader] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [updModalOpen, setUpdModalOpen] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [subTypeError, setSubTypeError] = useState(false)
  const [subTypeTextError, setSubTypeTextError] = useState('')
  const [expirationDateError, setExpirationDateError] = useState(false)
  const [expirationDateTextError, setExpirationDateTextError] = useState('')
  const [partnerPortfolioError, setPartnerPortfolioError] = useState(false)
  const [partnerPortfolioTextError, setPartnerPortfolioTextError] = useState('')
  const [clientDepoStorageError, setClientDepoStorageError] = useState(false)
  const [clientDepoStorageTextError, setClientDepoStorageTextError] = useState('')
  const [securityNameError, setSecurityNameError] = useState(false)
  const [securityNameTextError, setSecurityNameTextError] = useState('')
  const [volumeError, setVolumeError] = useState(false)
  const [volumeTextError, setVolumeTextError] = useState('')
  const [securityTypeError, setSecurityTypeError] = useState(false)
  const [securityTypeTextError, setSecurityTypeTextError] = useState('')
  const [securityRegisterNumberError, setSecurityRegisterNumberError] = useState(false)
  const [securityRegisterNumberTextError, setSecurityRegisterNumberTextError] = useState('')
  const [securityIsinError, setSecurityIsinError] = useState(false)
  const [securityIsinTextError, setSecurityIsinTextError] = useState('')
  const [depoClearanceTemplatesError, setDepoClearanceTemplatesError] = useState(false)
  const [depoClearanceTemplatesTextError, setDepoClearanceTemplatesTextError] = useState('')
  const [depoAccountHoldingError, setDepoAccountHoldingError] = useState(false)
  const [depoAccountHoldingTextError, setDepoAccountHoldingTextError] = useState('')
  const [depoAccountError, setDepoAccountError] = useState(false)
  const [depoAccountTextError, setDepoAccountTextError] = useState('')
  const [agentNameError, setAgentNameError] = useState(false)
  const [agentNameTextError, setAgentNameTextError] = useState('')
  const [depoAccountDivisionError, setDepoAccountDivisionError] = useState(false)
  const [depoAccountDivisionTextError, setDepoAccountDivisionTextError] = useState('')
  const [depoCodeError, setDepoCodeError] = useState(false)
  const [depoCodeTextError, setDepoCodeTextError] = useState('')
  const [referenceError, setReferenceError] = useState(false)
  const [referenceTextError, setReferenceTextError] = useState('')
  const [agentIdentityDocumentError, setAgentIdentityDocumentError] = useState(false)
  const [agentIdentityDocumentTextError, setAgentIdentityDocumentTextError] = useState('')
  const [agentIdentitySeriesError, setAgentIdentitySeriesError] = useState(false)
  const [agentIdentitySeriesTextError, setAgentIdentitySeriesTextError] = useState('')
  const [agentIdentityNumberError, setAgentIdentityNumberError] = useState(false)
  const [agentIdentityNumberTextError, setAgentIdentityNumberTextError] = useState('')
  const [agentIdentityDepartmentCodeError, setAgentIdentityDepartmentCodeError] = useState(false)
  const [agentIdentityDepartmentCodeTextError, setAgentIdentityDepartmentCodeTextError] = useState(
    '',
  )
  const [agentIdentityIssueDateError, setAgentIdentityIssueDateError] = useState(false)
  const [agentIdentityIssueDateTextError, setAgentIdentityIssueDateTextError] = useState('')
  const [agentIdentityExpirationDateError, setAgentIdentityExpirationDateError] = useState(false)
  const [agentIdentityExpirationDateTextError, setAgentIdentityExpirationDateTextError] = useState(
    '',
  )
  const [agentIdentityIssuingAuthorityError, setAgentIdentityIssuingAuthorityError] = useState(
    false,
  )
  const [
    agentIdentityIssuingAuthorityTextError,
    setAgentIdentityIssuingAuthorityTextError,
  ] = useState('')
  const [agentRegistrationDateError, setAgentRegistrationDateError] = useState(false)
  const [agentRegistrationDateTextError, setAgentRegistrationDateTextError] = useState('')
  const [agentRegistrationSeriesError, setAgentRegistrationSeriesError] = useState(false)
  const [agentRegistrationSeriesTextError, setAgentRegistrationSeriesTextError] = useState('')
  const [agentRegistrationNumberError, setAgentRegistrationNumberError] = useState(false)
  const [agentRegistrationNumberTextError, setAgentRegistrationNumberTextError] = useState('')
  const [
    agentRegistrationIssuingAuthorityError,
    setAgentRegistrationIssuingAuthorityError,
  ] = useState(false)
  const [
    agentRegistrationIssuingAuthorityTextError,
    setAgentRegistrationIssuingAuthorityTextError,
  ] = useState('')
  const [centralDepositoryError, setCentralDepositoryError] = useState(false)
  const [centralDepositoryTextError, setCentralDepositoryTextError] = useState('')
  const [interDepoAgreementNumberError, setInterDepoAgreementNumberError] = useState(false)
  const [interDepoAgreementNumberTextError, setInterDepoAgreementNumberTextError] = useState('')
  const [dealDateError, setDealDateError] = useState(false)
  const [dealDateTextError, setDealDateTextError] = useState('')
  const [registerDateError, setRegisterDateError] = useState(false)
  const [registerDateTextError, setRegisterDateTextError] = useState('')
  const [orderReasonTypesError, setOrderReasonTypesError] = useState(false)
  const [orderReasonTypesTextError, setOrderReasonTypesTextError] = useState('')
  const [docNumberError, setDocNumberError] = useState(false)
  const [docNumberTextError, setDocNumberTextError] = useState('')
  const [docDateError, setDocDateError] = useState(false)
  const [docDateTextError, setDocDateTextError] = useState('')
  const [docNameError, setDocNameError] = useState(false)
  const [docNameTextError, setDocNameTextError] = useState('')
  const [documentedCostValueError, setDocumentedCostValueError] = useState(false)
  const [documentedCostValueTextError, setDocumentedCostValueTextError] = useState('')
  const [documentedCostCurrencyError, setDocumentedCostCurrencyError] = useState(false)
  const [documentedCostCurrencyTextError, setDocumentedCostCurrencyTextError] = useState('')
  const [isCleared, setIsCleared] = useState(false)
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [balances, setBalances] = useState(null)
  const [canModifyDate, setCanModifyDate] = useState(true)
  const [failedLoadModalOpen, setFailedLoadModalOpen] = useState(false)
  const [failedEmptyFile, setFailedEmptyFile] = useState(false)
  const [secretPassPhrase, setSecretPassPhrase] = useState('')
  const [hashSha256, setHashSha256] = useState(null)

  const history = useHistory()

  useEffect(() => {
    if (windowWidth > 576) {
      setGroupFourVisibility(true)
      setGroupFiveVisibility(true)
    }
  }, [windowWidth])

  const connectedWS = useSelector(selectConnectionState)
  const getRespSecurities = useSelector(selectCurrentSecurities)
  const getRespMetadata = useSelector(selectCurrentMetadata)
  const getRespSecuritySelector = useSelector(selectCurrentGetSecurity)
  const getSaveSecurity = useSelector(selectCurrentSaveSecurity)
  const getPerformSecurity = useSelector(selectCurrentPerformSecurity)
  const respGetFileData = useSelector(selectGetFilesData)
  const respPostFileData = useSelector(selectPostFilesData)
  const getBalances = useSelector(selectGetBalanceSecurity)
  const serverError = useSelector(selectCurrentError)
  const respPostFileDataError = useSelector(selectPostFilesDataError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)

  const getRespSecurity = useMemo(() => getRespSecuritySelector, [getRespSecuritySelector])

  const currentStatusDescription = getRespSecurity?.order?.statusDescription
    && sanitizeHtml(getRespSecurity?.order?.statusDescription)

  const clearTemplateFields = () => {
    setAccountType('')
    setDepoAccount('')
    setAgentName('')
    setDepoAccountDivision('')
    setDepoCode('')
    setReference('')
    setFirmNumber('')
    setFirmSeries('')
    setFirmIssuingAuthority('')
    setFirmValidity('')
    setCurrentTypeDocument('')
    setPersonSeries('')
    setPersonNumber('')
    setPersonCode('')
    setPersonIssueDate('')
    setPersonExpirationDate('')
    setPersonIssuingAuthority('')
  }

  const handleChangeOrderType = (event, object) => {
    setOrderType(event.target.value)
    setOrderSubTypeUid(object.props.uid)
    setCounterpartyTemplate('')
    const clearanceTemplates = getRespMetadata?.references?.depoClearanceTemplates
    const currentTemplates = clearanceTemplates?.filter(
      (item) => item.orderSubTypeUid === object.props.uid,
    )
    setCurrentArrayTemplates(currentTemplates)
    clearTemplateFields()
    setSubTypeError(false)
  }

  const handleChangeValidity = (date) => {
    setValidity(date)
    setExpirationDateError(false)
  }

  const handleBackToHistory = () => {
    history.push('/transfers/securities/log')
  }

  const handleChangePersonIssueDate = (date) => {
    setPersonIssueDate(date)
    setAgentIdentityIssueDateError(false)
  }

  const handleChangePersonExpirationDate = (date) => {
    setPersonExpirationDate(date)
    setAgentIdentityExpirationDateError(false)
  }

  useEffect(() => {
    const typeDepository = getRespMetadata?.references?.orderReasonTypes?.find(
      (type) => type.uid === 'Orders.Reason.Type.Depository',
    )?.name
    const linkedAccount = getRespMetadata?.references?.portfolios
      ?.find((p) => p.name === portfolio)
      ?.depoStorages?.find((ds) => ds.name === storagePlus)?.linkedAccount

    if (linkedAccount && typeDepository) {
      const documentTemplate = {
        type: typeDepository,
        number: linkedAccount?.agreement?.number,
        dateTime: new Date(linkedAccount?.agreement?.date),
        attachments: [],
        required: true,
      }

      setArrayDocuments((prevState) =>
        (documentTemplate && prevState.every((e) => e.number !== documentTemplate?.number)
          ? [documentTemplate, ...prevState]
          : [...prevState]))
    }
  }, [portfolio, storagePlus])

  const handleChangePortfolio = (event, newValue) => {
    setPortfolio(newValue)
    setStoragePlus('')
    const arrayCurrentPortfolios = getRespMetadata?.references?.portfolios?.find(
      (item) => item.name === newValue,
    )
    if (arrayCurrentPortfolios?.depoStorages?.length) {
      setClientDepoStorage(arrayCurrentPortfolios?.depoStorages)
    } else {
      setClientDepoStorage([])
    }
    setPartnerPortfolioError(false)
    setClientDepoStorageError(false)
    const hasLinkedAccount = !!getRespMetadata?.references?.portfolios
      ?.find((p) => p.name === newValue)
      ?.depoStorages?.find((ds) => ds.name === storagePlus)?.linkedAccount
    if (arrayDocuments.find((item) => item.required) && !hasLinkedAccount) arrayDocuments.shift()
  }

  const handleChangeStoragePlus = (event) => {
    setStoragePlus(event.target.value)
    setClientDepoStorageError(false)
    const hasLinkedAccount = !!getRespMetadata?.references?.portfolios
      ?.find((p) => p.name === portfolio)
      ?.depoStorages?.find((ds) => ds.name === storagePlus)?.linkedAccount
    if (arrayDocuments.find((item) => item.required) && hasLinkedAccount) arrayDocuments.shift()
  }

  const handleChangeInput = (event) => {
    const { name, value } = event.target
    setInput((state) => ({ ...state, [name]: value }))
    setSecurityRegisterNumberError(false)
    setSecurityIsinError(false)
  }

  const handleChangeView = (event, object) => {
    const { name } = event.target
    const { item } = object.props
    setInput((state) => ({ ...state, [name]: item }))
    setSecurityTypeError(false)
  }

  const handleChangeCounterpartyTemplate = (event, object) => {
    clearTemplateFields()
    setDepoClearanceTemplatesError(false)
    if (!event?.target?.value) {
      setCounterpartyTemplate('')
      return
    }
    setCounterpartyTemplate(event?.target?.value)
    if (object) {
      const template = object.props.currenttemplate
      setAccountType(template?.depoAccountHolding?.description)
      setDepoAccount(template?.depoAccount)
      setAgentName(template?.agentName)
      setDepoAccountDivision(template?.depoAccountDivision)
      setDepoCode(template?.depoCode)
      setReference(template?.reference)
      setDepoAccountHoldingError(false)
      setDepoAccountError(false)
      setAgentNameError(false)
      setDepoAccountDivisionError(false)
      setDepoCodeError(false)
      setReferenceError(false)
      const agentLegalEntity = template?.agentLegalEntity
      setRadioValue(agentLegalEntity)
      if (agentLegalEntity === 'firm') {
        const registration = template?.agentRegistration
        setFirmNumber(registration?.number)
        setFirmSeries(registration?.series)
        setFirmIssuingAuthority(registration?.issuingAuthority)
        setFirmValidity(registration?.date)
        setAgentRegistrationDateError(false)
        setAgentRegistrationSeriesError(false)
        setAgentRegistrationNumberError(false)
        setAgentRegistrationIssuingAuthorityError(false)
      } else if (agentLegalEntity === 'person') {
        const identity = template?.agentIdentity
        setCurrentTypeDocument(identity?.document)
        setPersonSeries(identity?.series)
        setPersonNumber(identity?.number)
        setPersonCode(identity?.departmentCode)
        setPersonIssueDate((identity?.issueDate && new Date(identity.issueDate)) || null)
        setPersonExpirationDate(
          (identity?.expirationDate && new Date(identity.expirationDate)) || null,
        )
        setPersonIssuingAuthority(identity?.issuingAuthority)
        setAgentIdentityDocumentError(false)
        setAgentIdentitySeriesError(false)
        setAgentIdentityNumberError(false)
        setAgentIdentityDepartmentCodeError(false)
        setAgentIdentityIssueDateError(false)
        setAgentIdentityExpirationDateError(false)
        setAgentIdentityIssuingAuthorityError(false)
      }
    }
  }

  const handleChangeCounterpartyTemplateInput = (event) => {
    setCounterpartyTemplate(event?.target?.value)
    setDepoClearanceTemplatesError(false)
  }

  const handleChangeAccountType = (event) => {
    setAccountType(event.target.value)
    setDepoAccountHoldingError(false)
  }

  const handleChangeDocumentType = (event) => {
    setDocumentType(event.target.value)
    const typeUID = getRespMetadata?.references?.orderReasonTypes?.find(
      (item) => item.description === event.target.value,
    )?.uid
    setOrderReasonTypeUID(typeUID)
    setNameDocument('')
    setOrderReasonTypesError(false)
  }

  const handleChangeDealDate = (date) => {
    setDealDate(date)
    setDealDateError(false)
  }

  const handleChangeComment = (event) => {
    setComment(event.target.value)
  }

  const handleChangeAcquisitionCost = (event) => {
    setAcquisitionCost(event.target.value)
    setDocumentedCostValueError(false)
  }

  const handleChangeReRegistrationDate = (date) => {
    setReRegistrationDate(date)
    setRegisterDateError(false)
  }

  const handleChangeDocumentDate = (date) => {
    setDocumentDate(date)
    setDocDateError(false)
  }

  const handleSwitchChanges = (event) => {
    setSwitchStates({ ...switchStates, [event.target.name]: event.target.checked })
  }

  const handleChangeCurrency = (event) => {
    setCurrency(event.target.value)
    setDocumentedCostCurrencyError(false)
  }

  const toggleGroupOneVisibility = () => {
    setGroupOneVisibility(true)
    setGroupTwoVisibility(false)
    setInput(issuer)
  }

  const toggleGroupTwoVisibility = () => {
    if (open || groupThreeVisibility) return
    setGroupThreeVisibility(true)
    setOrderReasonTypesError(false)
    setDocNumberError(false)
    setDocDateError(false)
    const idArray = [...idScanFiles]
    idArray.push([])
    setIdScanFiles(idArray)
  }

  const toggleGroupFourVisibility = () => {
    if (windowWidth <= 576) {
      setGroupFourVisibility(true)
    }
  }

  const toggleGroupFiveVisibility = () => {
    if (windowWidth <= 576) {
      setGroupFiveVisibility(true)
    }
  }

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchSecuritiesStart = (obj) => {
    dispatch(fetchSecuritiesStartAsync(obj))
  }

  const fetchGetSecurityStart = (id) => {
    dispatch(fetchGetSecurityStartAsync({ id }))
  }

  const fetchSaveSecurityStart = (data, id) => {
    dispatch(fetchSaveSecurityStartAsync({ data, id }))
  }

  const fetchPerformSecurityStart = (data, id) => {
    dispatch(fetchPerformSecurityStartAsync({ data, id }))
  }

  const fetchPostFileStart = (obj) => {
    dispatch(fetchPostFileStartAsync({ obj }))
  }

  const fetchGetFileStart = (id) => {
    dispatch(fetchGetFileStartAsync({ id }))
  }

  const fetchGetBalancesDepoStart = (filterBody) => {
    dispatch(fetchGetBalancesDepoStartAsync({ filterBody }))
  }

  const { getData: getMetaData } = useConnect({
    link: `GET /api${endpoints.metadata.getMetadata(metadataUid)}`,
    startFetching: fetchMetadataStart,
    action: getMetadataSuccess,
  })

  const { getData: securitiesFilter } = useConnect({
    link: `GET /api${endpoints.securities.getSecurities()}`,
    startFetching: fetchSecuritiesStart,
    action: getSecuritiesSuccess,
  })

  const { getData: postFileData } = useConnect({
    link: `POST /api${endpoints.file.postFile}`,
    startFetching: fetchPostFileStart,
    action: postFileSuccess,
  })
  // useConnect({ link: `POST /api${endpoints.file.postFile}`, action: postFileSuccess })
  const { getData: getFilesData } = useArrayConnect({
    link: `GET /api${endpoints.file.getFile()}`,
    idList: idFileList,
    startFetching: fetchGetFileStart,
    action: getFileSuccess,
  })

  const { getData: getSecurityData } = useConnect({
    link: `GET /api${endpoints.securities.getSecurity(activeId)}`,
    startFetching: fetchGetSecurityStart,
    action: getSecuritySuccess,
  })

  const { getData: postSecuritySaveData } = useConnect({
    link: `POST /api${endpoints.securities.postSecurity(false)}`,
    startFetching: fetchSaveSecurityStart,
    action: saveSecuritySuccess,
  })

  const { getData: putSecuritySaveData } = useConnect({
    link: `PUT /api${endpoints.securities.putSecurity(null, activeId, false)}`,
    startFetching: fetchSaveSecurityStart,
    action: saveSecuritySuccess,
  })

  const { getData: postSecurityPerformData } = useConnect({
    link: `POST /api${endpoints.securities.postSecurity(false)}`,
    startFetching: fetchPerformSecurityStart,
    action: performSecuritySuccess,
  })

  const { getData: putSecurityPerformData } = useConnect({
    link: `PUT /api${endpoints.securities.putSecurity(null, activeId, false)}`,
    startFetching: fetchPerformSecurityStart,
    action: performSecuritySuccess,
  })

  useConnect({
    link: `GET /api${endpoints.assets.getBalancesDepo()}`,
    startFetching: fetchGetBalancesDepoStart,
    action: getBalancesDepoSuccess,
  })

  const getHandler = (handler) => (!disabled ? handler : null)

  const getDisabledState = () =>
    // eslint-disable-next-line no-underscore-dangle
    (!!getRespSecurity
      && !getRespSecurity?.order?._links[0]?.canModify
      && !getSaveSecurity
      && !getPerformSecurity)
    || !!getSaveSecurity
    || !!getPerformSecurity

  const assignIdFile = (
    file,
    setFile,
    postFile,
    action,
    arrayPostFile = null,
    setUniqFileContainer = null,
  ) => {
    let needUpdate = false
    // const fileItem = file
    if (postFile?.id >= 0 && file?.length) {
      if (action === 'get') {
        const newArr = file.map((item) => {
          if (item?.id === postFile?.id) {
            const fileObj = { ...item }
            fileObj.data = postFile?.data
            fileObj.file = {
              data: postFile?.data,
              name: postFile?.file?.name,
              path: postFile?.file?.path,
              size: postFile?.file?.size,
            }
            needUpdate = true
            return fileObj
          }
          return item
        })
        setFile(newArr)
      } else if (action === 'post') {
        const newArr = file.map((item) => {
          if (
            item?.file?.name === postFile?.fileName
            && item?.file?.size === postFile?.fileLength
            && !item?.file?.id
          ) {
            const fileObj = { ...item }
            fileObj.file.id = postFile?.id
            const uniqueFileContainer = arrayPostFile?.filter(
              (itemId) => itemId?.id !== postFile?.id,
            )
            setUniqFileContainer(uniqueFileContainer)
            needUpdate = true
            return fileObj
          }
          return item
        })
        if (needUpdate) {
          setFile(newArr)
        }
      }
    }
    return needUpdate
  }

  /**
   * Method requests files from backend by id
   * @param {Array} array - File id array
   * @param {String} form - Name of the component containing the files
   */
  const processArray = (array, form) => {
    if (!array?.length) return
    const idArray = []
    if (form === 'documentedCost') {
      array.map((file) => {
        getFilesData(file.id)
        idArray.push(file.id)
        return file
      })
      setIdDocCostFiles(idArray)
      setIdFileList(idArray)
    } else if (form === 'scanDocs') {
      const attachmentsId = array.flat()
      const currentIdFileList = [...idFileList]
      attachmentsId.map((id) => {
        if (id >= 0) {
          getFilesData(id)
          if (currentIdFileList.indexOf(id) === -1) {
            currentIdFileList.push(attachmentsId)
          }
          idArray.push(id)
        }
        return id
      })
      setIdFileList(currentIdFileList.flat())
    }
  }

  const handleRemoveFile = (files, setFiles, idFiles, setIdFiles, removedFile) => {
    if (getDisabledState()) return
    const newArr = files.filter((f) => f !== removedFile)
    const indexRemovedFile = files.findIndex((f) => f === removedFile)
    setFiles(newArr)
    if (indexRemovedFile !== -1) {
      const arrayIdFiles = [...idFiles]
      arrayIdFiles.splice(indexRemovedFile, 1)
      setIdFiles(arrayIdFiles)
    }
  }

  const handleAddFile = (files, setFiles, setTemporaryFiles, addedFiles) => {
    if (!addedFiles.length) {
      setFiles([])
      return
    }
    if (addedFiles.some((file) => !file?.file?.size)) {
      setFailedEmptyFile(true)
      setIsDocLoading(false)
      setIsLoading(false)
      return
    }
    const arrayFiles = [...files]
    addedFiles.forEach((file) => {
      const obj = {
        fileName: file.file.name,
        fileData: file.data.split('base64,')[1],
        fileSize: file.file.size,
      }
      postFileData(obj)
      arrayFiles.push(file)
      setTemporaryFiles((prevState) => [...prevState, file])
    })
  }

  const saveIdScanFiles = () => {
    const arrayIdScanFiles = [...idScanFiles]
    const docIndex = (open && open?.split('-')[1]) || (groupThreeVisibility && arrayIdScanFiles?.length - 1)
    if (docIndex >= 0) {
      arrayIdScanFiles[docIndex] = []
      if (scanFiles?.length) {
        scanFiles.map((file) => {
          arrayIdScanFiles[docIndex].push(file?.file?.id || file?.id)
          return file
        })
        setIdScanFiles(arrayIdScanFiles)
      }
    }
  }

  const saveIdDocFiles = () => {
    const arrayIdDocFiles = []
    if (docFiles?.length) {
      docFiles.map((file) => {
        arrayIdDocFiles.push(file?.file?.id || file?.id)
        return file
      })
    }
    setIdDocCostFiles(arrayIdDocFiles)
  }

  const getSecurityValues = () => {
    if (groupOneVisibility) {
      return {
        id: null,
        type: input?.type,
        registerNumber: input?.registerNumber,
        isin: input?.isin,
        name: searchStr,
      }
    }
    return issuer
  }

  const enterMetadataFields = (metadata) => {
    const order = getRespSecurity?.order?.type?.uid === cancelType
      ? getRespSecurity?.order?.affectedOrder
      : getRespSecurity?.order
    const arrayCurrentPortfolios = metadata?.portfolios
    setArrayPortfolios(arrayCurrentPortfolios)
    setArrayCurrencies(metadata?.currencies || [order?.documentedCost?.currency])
    setOrderSubTypes(metadata?.orderSubTypes || [order?.subType])
    setCentralDepositories(metadata?.centralDepositories || [order?.centralDepository])
    setDepoAccountHoldingTypes(metadata?.depoAccountHoldingTypes || [order?.depoAccountHolding])
    setSecurityTypes(metadata?.securityTypes || [order?.security?.type])
    setOrderReasonTypes(metadata?.orderReasonTypes || order?.reasons?.map(({ type }) => type))
  }

  const fillFields = (resObj) => {
    let arrayCurrentPortfolios = []
    let currentDepoClearanceTemplates = []
    const currentPortfolioCode = resObj?.clientPortfolio?.code
    if (getRespMetadata?.references && Object.keys(getRespMetadata.references).length) {
      currentDepoClearanceTemplates = getRespMetadata?.references?.depoClearanceTemplates
      arrayCurrentPortfolios = getRespMetadata?.references?.portfolios
      const currentPortfolios = arrayCurrentPortfolios?.find(
        (item) => item.code === currentPortfolioCode,
      )
      setClientDepoStorage(currentPortfolios?.depoStorages)
    } else {
      arrayCurrentPortfolios = [resObj?.clientPortfolio]
      currentDepoClearanceTemplates = [resObj?.subType]
      setClientDepoStorage([resObj?.clientDepoStorage])
    }
    const subTypeUID = resObj?.subType?.uid
    const currentTemplates = currentDepoClearanceTemplates?.filter(
      (item) => item.orderSubTypeUid === subTypeUID,
    )
    setCurrentArrayTemplates(currentTemplates)
    setArrayPortfolios(arrayCurrentPortfolios)
    setPortfolio(currentPortfolioCode)
    setCounterpartyTemplate(resObj?.templateName)
    setCentralDepository(resObj?.centralDepository?.description)
    setInterDepoAgreementNumber(resObj?.interDepoAgreementNumber)
    setDepoAccountDivision(resObj?.depoAccountDivision)
    setDepoCode(resObj?.depoCode)
    amount.setValue(resObj?.volume)
    if (resObj?.agentLegalEntity === 'person') {
      setRadioValue('person')
      const agentIdentity = resObj?.agentIdentity
      setPersonCode(agentIdentity?.departmentCode)
      setCurrentTypeDocument(agentIdentity?.document)
      setPersonSeries(agentIdentity?.series)
      setPersonNumber(agentIdentity?.number)
      setPersonIssueDate((agentIdentity?.issueDate && new Date(agentIdentity?.issueDate)) || null)
      setPersonExpirationDate(
        (agentIdentity?.expirationDate && new Date(agentIdentity?.expirationDate)) || null,
      )
      setPersonIssuingAuthority(agentIdentity?.issuingAuthority)
    } else if (resObj?.agentLegalEntity === 'firm') {
      setRadioValue('firm')
      const agentRegistration = resObj?.agentRegistration
      setFirmValidity((agentRegistration?.date && new Date(agentRegistration.date)) || null)
      setFirmSeries(agentRegistration?.series)
      setFirmNumber(agentRegistration?.number)
      setFirmIssuingAuthority(agentRegistration?.issuingAuthority)
    }
    setDealDate((resObj?.dealDate && new Date(resObj.dealDate)) || null)
    setReRegistrationDate((resObj?.registerDate && new Date(resObj?.registerDate)) || null)
    setOrderSubTypeUid(subTypeUID)
    setOrderType(resObj?.subType?.description)
    setAccountType(resObj?.depoAccountHolding?.description)
    setDepoAccount(resObj?.depoAccount)
    setAgentName(resObj?.agentName)
    setReference(resObj?.reference)
    if (resObj?.reasons?.length) {
      const arrIdScanFiles = []
      const arr = []
      resObj.reasons.map((item) => {
        arr.push({
          type: item?.type?.description,
          number: item?.number,
          dateTime: (item?.date && new Date(item.date)) || null,
          attachments: (item?.attachments?.length && item?.attachments) || [],
          id: item.id,
          otherTypeName: item?.otherTypeName,
          typeUID: item?.type?.uid,
        })
        const attachmentsId = item?.attachments?.map((file) => file.id)
        arrIdScanFiles.push(attachmentsId)
        return item
      })
      setIdScanFiles(arrIdScanFiles)
      setArrayDocuments(arr)
      setOpen('')
    }
    setComment(resObj?.comment)
    setStoragePlus(resObj?.clientDepoStorage?.code || '')
    setSearchStr(resObj?.security?.name || '')
    setIssuer(resObj?.security)
    setInput((state) => ({
      ...state,
      type: resObj?.security?.type?.name,
      registerNumber: resObj?.security?.registerNumber,
      isin: resObj?.security?.isin,
      id: resObj?.security?.id,
      code: resObj?.security?.code,
      name: resObj?.security?.name,
    }))
    const resExpirationDate = (getRespSecurity?.order?.expirationDate && new Date(getRespSecurity.order.expirationDate))
      || null
    setValidity(resExpirationDate)
    setExpirationDate(resExpirationDate)

    if (resObj?.hasDocumentedCost) {
      setSwitchStates({ first: true })
      setAcquisitionCost(resObj?.documentedCost?.value)
      setCurrency(resObj?.documentedCost?.currency?.uid)
      setDocFiles(resObj?.documentedCost?.attachments)
      processArray(resObj?.documentedCost?.attachments, 'documentedCost')
    }
    setGroupOneVisibility(false)
    setGroupTwoVisibility(true)
    // eslint-disable-next-line no-underscore-dangle
    const currentAction = resObj?._links[0]
    setCurrentActions(currentAction)
    if (currentAction?.canModify) {
      setDisabled(false)
    } else {
      setDisabled(true)
      setCanModifyDate(false)
    }
  }

  const clearFields = (needClearData = true) => {
    if (needClearData) {
      dispatch(clearMetadata())
    }
    dispatch(clearSecurities())
    dispatch(fileClean())
    setPortfolio('')
    setStoragePlus('')
    setGroupOneVisibility(false)
    setGroupTwoVisibility(false)
    setSearchStr(initialState.searchStr)
    setInput(initialState.input)
    setIssuer(initialState.issuer)
    setValidity(initialState.validity)
    setExpirationDate(null)
    setDisabled(false)
    setOrderType('')
    setCounterpartyTemplate('')
    setAccountType('')
    setDepoAccount('')
    setAgentName('')
    setDepoAccountDivision('')
    setDepoCode('')
    setReference('')
    setCurrentTypeDocument('')
    setPersonSeries('')
    setPersonNumber('')
    setPersonCode('')
    setPersonIssueDate(null)
    setPersonExpirationDate(null)
    setPersonIssuingAuthority('')
    setFirmValidity('')
    setFirmSeries('')
    setFirmNumber('')
    setFirmIssuingAuthority('')
    setCentralDepository('')
    setInterDepoAgreementNumber('')
    setDealDate(null)
    setReRegistrationDate(null)
    setDocumentType('')
    setDocNumber('')
    setDocumentDate(null)
    setNameDocument('')
    setComment('')
    setSwitchStates({ first: false })
    setAcquisitionCost('')
    setCurrency('')
    setArrayDocuments([])
    amount.setValue('')
    setShowTimer(false)
    setCode('')
    setCodeError('')
    setCurrentStatus('')
    setSecurityHeader('')
    setCurrentActions(null)
    setDocFiles([])
    setIdDocCostFiles([])
    setIdFileList([])
    setSubTypeError(false)
    setExpirationDateError(false)
    setPartnerPortfolioError(false)
    setClientDepoStorageError(false)
    setSecurityNameError(false)
    setVolumeError(false)
    setSecurityTypeError(false)
    setSecurityRegisterNumberError(false)
    setSecurityIsinError(false)
    setDepoClearanceTemplatesError(false)
    setDepoAccountHoldingError(false)
    setDepoAccountError(false)
    setAgentNameError(false)
    setDepoCodeError(false)
    setReferenceError(false)
    setAgentIdentityDocumentError(false)
    setAgentIdentitySeriesError(false)
    setAgentIdentityNumberError(false)
    setAgentIdentityDepartmentCodeError(false)
    setAgentIdentityIssueDateError(false)
    setAgentIdentityExpirationDateError(false)
    setAgentIdentityIssuingAuthorityError(false)
    setAgentRegistrationDateError(false)
    setAgentRegistrationSeriesError(false)
    setAgentRegistrationNumberError(false)
    setAgentRegistrationIssuingAuthorityError(false)
    setCentralDepositoryError(false)
    setInterDepoAgreementNumberError(false)
    setDealDateError(false)
    setRegisterDateError(false)
    setOrderReasonTypesError(false)
    setDocNumberError(false)
    setDocDateError(false)
    setDocNameError(false)
    setDocumentedCostValueError(false)
    setDocumentedCostCurrencyError(false)
    setCodeError(false)
    setIsCleared(true)
    setBalances(null)
    setSecretPassPhrase('')
  }
  useEffect(() => {
    if (connectedWS) {
      clearFields()
      setActiveId(null)
      if (transferId === 'new') {
        setMetadataUid(securityType)
        getMetaData(securityType)
        clearFields()
      } else {
        getMetaData(metadataUid)
        getSecurityData(transferId)
        setActiveId(transferId)
      }
    }
    return () => {
      clearFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferId, connectedWS])

  useEffect(() => {
    if (serverError?.errors) {
      if (serverError?.errors?.SubType?.length) {
        setSubTypeError(true)
        setSubTypeTextError(serverError?.errors?.SubType[0])
      }
      if (serverError?.errors?.ExpirationDate?.length) {
        setExpirationDateError(true)
        setExpirationDateTextError(serverError?.errors?.ExpirationDate[0])
      }
      if (serverError?.errors?.ClientPortfolio?.length) {
        setPartnerPortfolioError(true)
        setPartnerPortfolioTextError(serverError?.errors?.ClientPortfolio[0])
      }
      if (serverError?.errors?.ClientDepoStorage?.length) {
        setClientDepoStorageError(true)
        setClientDepoStorageTextError(serverError?.errors?.ClientDepoStorage[0])
      }
      if (serverError?.errors['Security.Name' || '']?.length) {
        setSecurityNameError(true)
        setSecurityNameTextError(serverError?.errors['Security.Name'][0])
      }
      if (serverError?.errors?.Volume?.length) {
        setVolumeError(true)
        setVolumeTextError(serverError?.errors?.Volume[0])
      }
      if (serverError?.errors?.Security?.length) {
        setSecurityNameError(true)
        setSecurityNameTextError(serverError?.errors?.Security[0])
      }
      if (serverError?.errors['Security.Type' || '']?.length) {
        setSecurityTypeError(true)
        setSecurityTypeTextError(serverError?.errors['Security.Type'][0])
      }
      if (serverError?.errors['Security.RegisterNumber' || '']?.length) {
        setSecurityRegisterNumberError(true)
        setSecurityRegisterNumberTextError(serverError?.errors['Security.RegisterNumber'][0])
      }
      if (serverError?.errors['Security.Isin' || '']?.length) {
        setSecurityIsinError(true)
        setSecurityIsinTextError(serverError?.errors['Security.Isin'][0])
      }
      if (serverError?.errors?.DepoClearanceTemplates?.length) {
        setDepoClearanceTemplatesError(true)
        setDepoClearanceTemplatesTextError(serverError?.errors?.DepoClearanceTemplates[0])
      }
      if (serverError?.errors?.DepoAccountHolding?.length) {
        setDepoAccountHoldingError(true)
        setDepoAccountHoldingTextError(serverError?.errors?.DepoAccountHolding[0])
      }
      if (serverError?.errors?.DepoAccount?.length) {
        setDepoAccountError(true)
        setDepoAccountTextError(serverError?.errors?.DepoAccount[0])
      }
      if (serverError?.errors?.AgentName?.length) {
        setAgentNameError(true)
        setAgentNameTextError(serverError?.errors?.AgentName[0])
      }
      if (serverError?.errors?.DepoAccountDivision?.length) {
        setDepoAccountDivisionError(true)
        setDepoAccountDivisionTextError(serverError?.errors?.DepoAccountDivision[0])
      }
      if (serverError?.errors?.DepoCode?.length) {
        setDepoCodeError(true)
        setDepoCodeTextError(serverError?.errors?.DepoCode[0])
      }
      if (serverError?.errors?.Reference?.length) {
        setReferenceError(true)
        setReferenceTextError(serverError?.errors?.Reference[0])
      }
      if (serverError?.errors['AgentIdentity.Document']?.length) {
        setAgentIdentityDocumentError(true)
        setAgentIdentityDocumentTextError(serverError?.errors['AgentIdentity.Document'][0])
      }
      if (serverError?.errors['AgentIdentity.Series']?.length) {
        setAgentIdentitySeriesError(true)
        setAgentIdentitySeriesTextError(serverError?.errors['AgentIdentity.Series'][0])
      }
      if (serverError?.errors['AgentIdentity.Number']?.length) {
        setAgentIdentityNumberError(true)
        setAgentIdentityNumberTextError(serverError?.errors['AgentIdentity.Number'][0])
      }
      if (serverError?.errors['AgentIdentity.DepartmentCode']?.length) {
        setAgentIdentityDepartmentCodeError(true)
        setAgentIdentityDepartmentCodeTextError(
          serverError?.errors['AgentIdentity.DepartmentCode'][0],
        )
      }
      if (serverError?.errors['AgentIdentity.IssueDate']?.length) {
        setAgentIdentityIssueDateError(true)
        setAgentIdentityIssueDateTextError(serverError?.errors['AgentIdentity.IssueDate'][0])
      }
      if (serverError?.errors['AgentIdentity.ExpirationDate']?.length) {
        setAgentIdentityExpirationDateError(true)
        setAgentIdentityExpirationDateTextError(
          serverError?.errors['AgentIdentity.ExpirationDate'][0],
        )
      }
      if (serverError?.errors['AgentIdentity.IssuingAuthority']?.length) {
        setAgentIdentityIssuingAuthorityError(true)
        setAgentIdentityIssuingAuthorityTextError(
          serverError?.errors['AgentIdentity.IssuingAuthority'][0],
        )
      }
      if (serverError?.errors['AgentRegistration.Date']?.length) {
        setAgentRegistrationDateError(true)
        setAgentRegistrationDateTextError(serverError?.errors['AgentRegistration.Date'][0])
      }
      if (serverError?.errors['AgentRegistration.Series']?.length) {
        setAgentRegistrationSeriesError(true)
        setAgentRegistrationSeriesTextError(serverError?.errors['AgentRegistration.Series'][0])
      }
      if (serverError?.errors['AgentRegistration.Number']?.length) {
        setAgentRegistrationNumberError(true)
        setAgentRegistrationNumberTextError(serverError?.errors['AgentRegistration.Number'][0])
      }
      if (serverError?.errors['AgentRegistration.IssuingAuthority']?.length) {
        setAgentRegistrationIssuingAuthorityError(true)
        setAgentRegistrationIssuingAuthorityTextError(
          serverError?.errors['AgentRegistration.IssuingAuthority'][0],
        )
      }
      if (serverError?.errors?.CentralDepository?.length) {
        setCentralDepositoryError(true)
        setCentralDepositoryTextError(serverError?.errors?.CentralDepository[0])
      }
      if (serverError?.errors?.InterDepoAgreementNumber?.length) {
        setInterDepoAgreementNumberError(true)
        setInterDepoAgreementNumberTextError(serverError?.errors?.InterDepoAgreementNumber[0])
      }
      if (serverError?.errors?.DealDate?.length) {
        setDealDateError(true)
        setDealDateTextError(serverError?.errors?.DealDate[0])
      }
      if (serverError?.errors?.RegisterDate?.length) {
        setRegisterDateError(true)
        setRegisterDateTextError(serverError?.errors?.RegisterDate[0])
      }
      if (serverError?.errors?.orderReasonTypes?.length) {
        setOrderReasonTypesError(true)
        setOrderReasonTypesTextError(serverError?.errors?.OrderReasonTypes[0])
      }
      if (serverError?.errors?.docNumber?.length) {
        setDocNumberError(true)
        setDocNumberTextError(serverError?.errors?.DocNumber[0])
      }
      if (serverError?.errors?.docDate?.length) {
        setDocDateError(true)
        setDocDateTextError(serverError?.errors?.DocDate[0])
      }
      if (serverError?.errors?.docName?.length) {
        setDocNameError(true)
        setDocNameTextError(serverError?.errors?.DocName[0])
      }
      if (serverError?.errors['DocumentedCost.Value']?.length) {
        setDocumentedCostValueError(true)
        setDocumentedCostValueTextError(serverError?.errors['DocumentedCost.Value'][0])
      }
      if (serverError?.errors['DocumentedCost.Currency']?.length) {
        setDocumentedCostCurrencyError(true)
        setDocumentedCostCurrencyTextError(serverError?.errors['DocumentedCost.Currency'][0])
      }
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeTextError(serverError?.errors?.Secret[0])
      }
      if (serverError?.errors?.FileData?.length) {
        setIsDocLoading(false)
      }
      const msgData = getSaveSecurity || getPerformSecurity
      if ((!currentActions || currentActions?.canModify) && !msgData?.requiredAction?.type) {
        setDisabled(false)
      }
      setIsLoading(false)
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError])

  useEffect(() => {
    if (getRespSecurity && isCleared) {
      const references = getRespMetadata?.references
      enterMetadataFields(references)
      setCurrentStatus(getRespSecurity?.order?.status)
      const order = getRespSecurity?.order?.type?.uid === cancelType
        ? getRespSecurity?.order?.affectedOrder
        : getRespSecurity?.order
      fillFields(order)
      if (getRespSecurity?.order?.type?.uid === securityType) {
        if (getRespMetadata?.order?.type?.uid === cancelType) {
          const cancelDate = getRespMetadata?.order?.expirationDate
          const reqObj = {
            type: { uid: cancelType },
            expirationDate: cancelDate && format(new Date(cancelDate), 'yyyy-MM-dd'),
            affectedOrder: { id: +activeId },
            secret: '',
          }

          const date = getRespMetadata?.order?.date
          const number = getRespMetadata?.order?.number

          reqObj.date = date
          reqObj.number = number

          const WordArrayToBase64 = JsonToSHA256(reqObj)
          if (WordArrayToBase64) {
            reqObj.secret = WordArrayToBase64
            setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
          }

          postSecurityPerformData(reqObj)
        }
      }
      if (getRespSecurity?.order?.type?.uid === cancelType) {
        // eslint-disable-next-line no-underscore-dangle
        if (getRespSecurity?.order?._links[0]?.canModify) {
          setCanModifyDate(true)
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(getRespSecurity?.order?._links[0])
      setSecurityHeader(`${getRespSecurity?.order?.description}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRespSecurity, getRespMetadata, isCleared])

  useEffect(() => {
    if (getRespMetadata?.references) {
      const references = getRespMetadata?.references
      enterMetadataFields(references)
    }
    // eslint-disable-next-line
  }, [getRespMetadata])

  useEffect(() => {
    if (respGetFileData.length && connectedWS) {
      const arrayFileObjects = []
      respGetFileData.map((file) =>
        arrayFileObjects.push({
          data: file.fileData,
          file: {
            name: file.fileName,
            path: file.fileName,
            size: file.fileLength,
          },
          id: file.id,
        }))
      arrayFileObjects.map((fileObject) => {
        if (Object.keys(fileObject)?.length > 0) {
          assignIdFile(scanFiles, setScanFiles, fileObject, 'get')
          assignIdFile(docFiles, setDocFiles, fileObject, 'get')
        }
        return null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetFileData])

  const getFindFile = (fileData, temporaryFiles) =>
    temporaryFiles.find(
      (file) => file?.fileName === fileData?.file?.name && file?.fileSize === fileData?.file?.size,
    )

  const getFilterFile = (fileData, temporaryFiles) =>
    temporaryFiles.filter(
      (file) => file?.fileName !== fileData?.file?.name && file?.fileSize !== fileData?.file?.size,
    )

  const handleSplitFiles = (
    setFiles,
    temporaryFiles,
    setTemporaryFiles,
    setLoader,
    responseFile,
  ) => {
    const currentTemporaryArray = getFilterFile(responseFile, temporaryFiles)
    if (temporaryFiles?.length > 0) {
      setFiles((prevState) => [...prevState, getFindFile(responseFile, temporaryFiles)])
      setTemporaryFiles(currentTemporaryArray)
    }
    if (!currentTemporaryArray?.length) {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (respPostFileData?.id) {
      if (temporaryScanFiles?.length > 0) {
        handleSplitFiles(
          setScanFiles,
          temporaryScanFiles,
          setTemporaryScanFiles,
          setIsLoading,
          respPostFileData,
        )
      }
      if (temporaryDocFiles?.length > 0) {
        handleSplitFiles(
          setDocFiles,
          temporaryDocFiles,
          setTemporaryDocFiles,
          setIsDocLoading,
          respPostFileData,
        )
      }
      setFileContainer((state) => [...state, respPostFileData])
      dispatch(fileClean())
    }
    if (respPostFileDataError?.type) {
      if (temporaryScanFiles?.length > 0) {
        const filterScanFiles = temporaryScanFiles.filter(
          (file) =>
            file?.name !== respPostFileData?.data?.name
            && file?.size !== respPostFileData?.data?.size,
        )
        setTemporaryScanFiles(filterScanFiles)
        if (!filterScanFiles?.length) {
          setIsLoading(false)
        }
      }
      if (temporaryDocFiles?.length > 0) {
        const filterDocFiles = temporaryDocFiles.filter(
          (file) =>
            file?.name !== respPostFileData?.data?.name
            && file?.size !== respPostFileData?.data?.size,
        )
        setTemporaryDocFiles(filterDocFiles)
        if (!filterDocFiles?.length) {
          setIsDocLoading(false)
        }
      }
      setFailedLoadModalOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respPostFileData, respPostFileDataError])

  useEffect(() => {
    if (!fileContainer?.length) return
    let needUpdateDocFiles = false
    fileContainer.map((postFile) => {
      if (scanFiles?.length) {
        assignIdFile(scanFiles, setScanFiles, postFile, 'post', fileContainer, setFileContainer)
      }
      if (docFiles?.length) {
        if (
          assignIdFile(docFiles, setDocFiles, postFile, 'post', fileContainer, setFileContainer)
        ) {
          needUpdateDocFiles = true
        }
      }
      return postFile
    })
    if (needUpdateDocFiles) {
      saveIdDocFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanFiles, docFiles, fileContainer])

  useEffect(() => {
    if (connectedWS && isCleared) {
      if (getSaveSecurity?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveSecurity?.order?.id)
        }
        if (getSaveSecurity?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
        if (getSaveSecurity?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
      }
      if (getPerformSecurity?.requiredAction) {
        if (!activeId || +activeId !== getPerformSecurity?.order?.id) {
          setActiveId(getPerformSecurity?.order?.id)
        }
        if (getPerformSecurity?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
        if (getPerformSecurity?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
        setCurrentStatus(getPerformSecurity?.order?.status)
      }

      const msgData = getSaveSecurity || getPerformSecurity
      const typeUID = (msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespSecurity?.order?.type?.uid === cancelType
        ? cancelType
        : securityType
      if (msgData?.order?.description) {
        setSecurityHeader(`${msgData?.order?.description}`)
      }
      if (typeUID) {
        setIsLoading(false)
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(msgData?.order?._links[0])
      if (
        msgData?.order?.type?.uid === cancelType
        && getRespSecurity?.order?.type?.uid !== cancelType
      ) {
        const cancelDate = getRespMetadata?.order?.expirationDate
        setExpirationDate(cancelDate)
        setValidity(cancelDate)
      }
      if (getSaveSecurity?.status === 200 || getPerformSecurity?.status === 200) {
        switch (getSaveSecurity?.order?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (activeId) {
              setUpdModalOpen(true)
            } else {
              setSaveModalOpen(true)
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaveSecurity, getPerformSecurity, isCleared])

  useEffect(() => {
    if (getBalances) {
      const portfolios = getRespMetadata?.references?.portfolios
      const clientPortfolio = portfolios?.find((item) => item.name === portfolio)
      if (!getBalances?.portfolio) return
      if (getBalances?.portfolio?.id === clientPortfolio?.id) {
        setBalances(getBalances)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBalances])

  const fetchBalancesDepo = (filterBody, setBalance) => {
    if (filterBody) {
      fetchGetBalancesDepoStart(filterBody.replaceAll('+', ''))
    }
    setBalance(null)
  }

  useMemo(() => {
    const portfolios = getRespMetadata?.references?.portfolios
    const clientPortfolio = portfolios?.find((item) => item.name === portfolio)
    const currentDepoStorage = clientPortfolio?.depoStorages?.filter(
      (item) => item.code === storagePlus,
    )[0]
    const security = getSecurityValues()
    let filterBodyClient
    if (portfolio || storagePlus || security) {
      filterBodyClient = createUri({
        name: 'balancesDepo',
        portfolioId: clientPortfolio?.id,
        securityId: security?.id,
        storageId: currentDepoStorage?.id,
        condition: '&',
      })
      fetchBalancesDepo(filterBodyClient, setBalances)
    }
    setBalances(getBalances)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio, storagePlus, issuer])

  const addNeedsProps = (typeUID, msgData) => {
    let reqObj = {}
    const portfolios = getRespMetadata?.references?.portfolios
    const clientPortfolio = portfolios?.find((item) => item.name === portfolio)
    const currentDepoStorage = clientPortfolio?.depoStorages?.filter(
      (item) => item.code === storagePlus,
    )[0]
    const security = getSecurityValues()
    const agentLegalEntity = orderSubTypeUid === `${securityType}.Registry` ? radioValue : null
    let agentRegistration = null
    let agentIdentity = null
    if (agentLegalEntity) {
      if (agentLegalEntity === 'firm') {
        agentRegistration = {
          date: (firmValidity && format(new Date(firmValidity), 'yyyy-MM-dd')) || null,
          series: firmSeries,
          number: firmNumber,
          issuingAuthority: firmIssuingAuthority,
        }
      } else {
        agentIdentity = {
          document: currentTypeDocument,
          series: personSeries,
          number: personNumber,
          departmentCode: personCode,
          issueDate: (personIssueDate && format(new Date(personIssueDate), 'yyyy-MM-dd')) || null,
          expirationDate:
            (personExpirationDate && format(new Date(personExpirationDate), 'yyyy-MM-dd')) || null,
          issuingAuthority: personIssuingAuthority,
        }
      }
    }
    const currentCentralDepository = getRespMetadata?.references?.centralDepositories?.find(
      (item) => item.description === centralDepository,
    )
    let docCost = null
    if (switchStates.first) {
      const attachmentsArray = []
      docFiles.map((el, index) => {
        if (attachmentsArray.every((eve) => el?.file?.id !== eve?.id)) {
          attachmentsArray.push({
            id: el?.file?.id || idDocCostFiles[index] || null,
            name: el?.file?.fileName ? el?.file?.fileName : el?.file?.name,
            size: el?.file?.fileSize ? el?.file?.fileSize : el?.file?.size,
          })
        }
        return el
      })
      docCost = {
        attachments: attachmentsArray,
        currency: getRespMetadata?.references?.currencies?.find((item) => item.uid === currency),
        value: acquisitionCost,
      }
    }
    const reasons = []
    arrayDocuments.map((item, docIndex) => {
      const attachmentsArray = []
      if (item?.attachments?.length > 0) {
        item.attachments.map((el, fileIndex) =>
          attachmentsArray.push({
            id: el?.file?.id || idScanFiles[docIndex][fileIndex] || null,
            name: el?.file?.fileName ? el?.file?.fileName : el?.file?.name,
            size: el?.file?.fileSize ? el?.file?.fileSize : el?.file?.size,
          }))
      }
      reasons.push({
        type:
          getRespMetadata?.references?.orderReasonTypes?.find(
            (el) => el.description === item.type,
          ) || null,
        number: item?.number || null,
        date: (item?.dateTime && format(new Date(item.dateTime), 'yyyy-MM-dd')) || null,
        attachments: attachmentsArray,
        otherTypeName: item?.otherTypeName,
        id: item?.id || null,
      })
      return item
    })
    const accountTypeObj = getRespMetadata?.references?.depoAccountHoldingTypes?.find(
      (item) => item.description === accountType,
    )

    if (typeUID === cancelType) {
      reqObj = {
        type: { uid: typeUID },
        expirationDate: format(new Date(validity), 'yyyy-MM-dd'),
        affectedOrder: {
          id: msgData?.order?.affectedOrder?.id || getRespSecurity?.order?.affectedOrder?.id,
        },
        secret: '',
      }
      return reqObj
    }
    reqObj = {
      type: { uid: typeUID },
      dateTime: format(new Date(), 'yyyy-MM-dd'),
      expirationDate: format(new Date(validity), 'yyyy-MM-dd'),
      clientPortfolio: { id: clientPortfolio?.id },
      clientDepoStorage: {
        id: currentDepoStorage?.id,
        account: currentDepoStorage?.account,
        name: currentDepoStorage?.name,
        isClosed: currentDepoStorage?.isClosed,
      },
      security,
      volume: amount.value,
      agentLegalEntity,
      agentRegistration,
      agentIdentity,
      agentName,
      centralDepository: currentCentralDepository,
      dealDate: dealDate ? format(new Date(dealDate), 'yyyy-MM-dd') : null,
      depoAccount,
      depoAccountDivision,
      depoAccountHolding: accountTypeObj,
      depoCode,
      hasDocumentedCost: switchStates.first,
      documentedCost: docCost,
      interDepoAgreementNumber,
      reasons,
      comment,
      reference,
      registerDate: reRegistrationDate ? format(new Date(reRegistrationDate), 'yyyy-MM-dd') : null,
      subType: { uid: orderSubTypeUid },
      templateName: counterpartyTemplate,
      secret: '',
    }
    return reqObj
  }

  const handlePerformSecurityInput = (event) => {
    event.preventDefault()
    if (connectedWS && !isLoading && !isDocLoading) {
      setFormDisabled(true)
      setIsLoading(true)

      const msgData = getSaveSecurity || getPerformSecurity
      const typeUID = (msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespSecurity?.order?.type?.uid === cancelType
        ? cancelType
        : securityType
      const requestObj = addNeedsProps(typeUID, msgData)

      const date = getRespMetadata?.order?.date
      const number = getRespMetadata?.order?.number

      requestObj.date = date
      requestObj.number = number

      const WordArrayToBase64 = JsonToSHA256(requestObj)
      if (WordArrayToBase64) {
        requestObj.secret = WordArrayToBase64
        setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
      }

      formAction({
        submitAction,
        requestObj,
        typeUID,
        msgData,
        putPerformData: putSecurityPerformData,
        postPerformData: postSecurityPerformData,
        putSaveData: putSecuritySaveData,
        postSaveData: postSecuritySaveData,
        activeId,
        code,
        setCodeError,
        setCodeTextError,
        setCancellationModalOpen,
        setFormDisabled,
        setIsLoading,
        currentThumbPrint,
        secretPassPhrase,
      })
    }
  }

  const handleChangeAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStr(value)
      if (value && value.trim().length >= 3) {
        securitiesFilter(`Count=100&Search=${value}`)
      }
    }
    setSecurityNameError(false)
  }

  const handleChangeAutocomplete = (event, value) => {
    if (!value) {
      return
    }
    if (value?.name) {
      setSearchStr(value.name)
    }
    setIssuer(value)
    setGroupOneVisibility(false)
    setGroupTwoVisibility(true)
    const uri = 'Count=100'
    securitiesFilter(uri)
  }

  const handleChangeFormatRadio = (event) => {
    const currentRadioValue = event.target.value
    setRadioValue(currentRadioValue)
    setFirmNumber('')
    setFirmSeries('')
    setFirmIssuingAuthority('')
    setFirmValidity('')
    setCurrentTypeDocument('')
    setPersonSeries('')
    setPersonNumber('')
    setPersonCode('')
    setPersonIssueDate('')
    setPersonExpirationDate('')
    setPersonIssuingAuthority('')
    setAgentIdentityDocumentError(false)
    setAgentIdentitySeriesError(false)
    setAgentIdentityNumberError(false)
    setAgentIdentityDepartmentCodeError(false)
    setAgentIdentityIssueDateError(false)
    setAgentIdentityExpirationDateError(false)
    setAgentIdentityIssuingAuthorityError(false)
    setAgentRegistrationDateError(false)
    setAgentRegistrationSeriesError(false)
    setAgentRegistrationNumberError(false)
    setAgentRegistrationIssuingAuthorityError(false)
  }

  const handleChangeCentralDepository = (event) => {
    setCentralDepository(event.target.value)
    setCentralDepositoryError(false)
  }

  const handleChangeDepoAccount = (event) => {
    setDepoAccount(event.target.value)
    setDepoAccountError(false)
  }

  const handleChangeAgentName = (event) => {
    setAgentName(event.target.value)
    setAgentNameError(false)
  }

  const handleChangeDepoAccountDivision = (event) => {
    setDepoAccountDivision(event.target.value)
    setDepoAccountDivisionError(false)
  }

  const handleChangeDepoCode = (event) => {
    setDepoCode(event.target.value)
    setDepoCodeError(false)
  }

  const handleChangeInterDepoAgreementNumber = (event) => {
    setInterDepoAgreementNumber(event.target.value)
    setInterDepoAgreementNumberError(false)
  }

  const handleChangeDocNumber = (event) => {
    setDocNumber(event.target.value)
    setDocNumberError(false)
  }

  const handleChangeReference = (event) => {
    setReference(event.target.value)
    setReferenceError(false)
  }

  const handleChangeCurrentTypeDocument = (event) => {
    setCurrentTypeDocument(event.target.value)
    setAgentIdentityDocumentError(false)
  }

  const handleChangePersonSeries = (event) => {
    setPersonSeries(event.target.value)
    setAgentIdentitySeriesError(false)
  }

  const handleChangePersonNumber = (event) => {
    setPersonNumber(event.target.value)
    setAgentIdentityNumberError(false)
  }

  const handleChangePersonCode = (event) => {
    setPersonCode(event.target.value)
    setAgentIdentityDepartmentCodeError(false)
  }

  const handleChangeFirmSeries = (event) => {
    setFirmSeries(event.target.value)
    setAgentRegistrationSeriesError(false)
  }

  const handleChangeFirmNumber = (event) => {
    setFirmNumber(event.target.value)
    setAgentRegistrationNumberError(false)
  }

  const handleChangeFirmIssuingAuthority = (event) => {
    setFirmIssuingAuthority(event.target.value)
    setAgentRegistrationIssuingAuthorityError(false)
  }

  const handleChangeFirmValidity = (date) => {
    setFirmValidity(date)
    setAgentRegistrationDateError(false)
  }

  const handleChangePersonIssuingAuthority = (event) => {
    setPersonIssuingAuthority(event.target.value)
    setAgentIdentityIssuingAuthorityError(false)
  }

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const createMarkup = () => ({ __html: currentStatusDescription })

  const handleResetTimer = () => {
    setShowTimer(true)
    const msgData = getSaveSecurity || getPerformSecurity
    const typeUID = msgData?.requiredAction && msgData?.order?.type?.uid === cancelType
      ? cancelType
      : securityType
    const requestObj = addNeedsProps(typeUID, msgData)

    const date = getRespMetadata?.order?.date
    const number = getRespMetadata?.order?.number

    requestObj.date = date
    requestObj.number = number

    const WordArrayToBase64 = JsonToSHA256(requestObj)
    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID,
      putPerformData: putSecurityPerformData,
      postPerformData: postSecurityPerformData,
      activeId,
      secretPassPhrase,
    })
  }

  const handleDownloadXml = () => {
    const msgData = getSaveSecurity || getPerformSecurity
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.order?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleCancelOperation = (event) => {
    if (getRespMetadata?.order?.type?.uid === cancelType) {
      handleBackToHistory()
    } else {
      event.preventDefault()
      dispatch(clearPartSecurities())
      setDisabled(false)
      setFormDisabled(false)
    }
  }

  const handleChangeNameDocument = (event) => {
    setNameDocument(event.target.value)
    setDocNameError(false)
  }

  const handleOpenRequest = (index) => {
    if (typeof index !== 'number' || groupThreeVisibility || open) return
    const arr = [...arrayDocuments]
    setArrayDocuments(arr)
    const date = arr[index]?.dateTime || null
    setDocumentDate(date)
    setDocumentType(arr[index]?.type)
    setDocNumber(arr[index]?.number || '')
    setScanFiles(arr[index]?.attachments || [])
    setOrderReasonTypeUID(arr[index]?.typeUID || '')
    setNameDocument(arr[index]?.otherTypeName || '')
    const attachmentsId = arr[index]?.attachments?.map((item) => item.id)
    processArray(attachmentsId, 'scanDocs')
    setOpen(open.includes(index) ? '' : `tabRow-${index}`)
  }

  const clearDocFields = () => {
    setDocNumber('')
    setDocumentDate('')
    setDocumentType('')
    setNameDocument('')
    setScanFiles([])
    setTemporaryScanFiles([])
  }

  const handleChangeDocumentDelete = (index = null) => {
    if (typeof index !== 'number' || isLoading) return
    const arr = [...arrayDocuments]
    arr.splice(index, 1)
    setArrayDocuments(arr)
    const idArray = [...idScanFiles]
    idArray.splice(index, 1)
    setIdScanFiles(idArray)
    clearDocFields()
    setOpen('')
  }

  const handleChangeDocumentCancel = (index = null) => {
    if (isLoading) return
    if (typeof index === 'number') {
      const arr = [...arrayDocuments]
      setArrayDocuments(arr)
    }
    clearDocFields()
    setOpen('')
  }

  const handleChangeDocumentSave = (item, index = null) => {
    if (typeof index !== 'number' || isLoading) return
    const arr = [...arrayDocuments]
    arr[index].type = documentType
    arr[index].number = docNumber
    arr[index].dateTime = documentDate || null
    arr[index].attachments = (scanFiles?.length && scanFiles) || []
    if (orderReasonTypeUID === 'Orders.Reason.Type.Other') {
      arr[index].otherTypeName = nameDocument
      arr[index].typeUID = 'Orders.Reason.Type.Other'
    }
    setArrayDocuments(arr)
    saveIdScanFiles()
    clearDocFields()
    setOpen('')
  }

  const removeLastIndexIdScanFile = () => {
    const idArray = [...idScanFiles]
    const lastId = idArray?.length - 1
    if (lastId >= 0) {
      idArray.splice(lastId)
      setIdScanFiles(idArray)
    }
  }

  const handleAddDocumentDelete = () => {
    if (isLoading) return
    setGroupThreeVisibility(false)
    clearDocFields()
    removeLastIndexIdScanFile()
  }

  const handleAddDocumentSave = () => {
    if (isLoading) return
    const obj = {
      type: documentType || null,
      number: docNumber,
      dateTime: documentDate,
      attachments: (scanFiles?.length && scanFiles) || [],
    }
    if (orderReasonTypeUID === 'Orders.Reason.Type.Other') {
      obj.otherTypeName = nameDocument
      obj.typeUID = 'Orders.Reason.Type.Other'
    }
    const arrayDocs = [...arrayDocuments]
    arrayDocs.push(obj)
    setArrayDocuments(arrayDocs)
    setGroupThreeVisibility(false)
    saveIdScanFiles()
    clearDocFields()
  }

  const handleAddDocumentCancel = () => {
    if (isLoading) return
    setGroupThreeVisibility(false)
    clearDocFields()
    removeLastIndexIdScanFile()
  }

  const handleCloseSaveModal = (goToHistory) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseUpdModal = () => {
    setUpdModalOpen(false)
    handleBackToHistory()
  }

  const handleClosePerformModal = (goToHistory) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
  }

  const handleCancelOk = () => {
    setMetadataUid(cancelType)
    getMetaData(cancelType)
    handleCloseCancellationModal()
  }

  const handleActionReject = () => {
    handleCloseSaveModal(true)
    handleClosePerformModal(true)
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)
    clearFields(false)
    clearDocFields()
    setActiveId(null)
    setValidity(new Date())
    setFormDisabled(false)
    setMetadataUid(securityType)
    if (securityType === 'Orders.Type.NonTrade.Depo.In') {
      history.replace('/transfers/securities/input/new')
    } else {
      history.replace('/transfers/securities/output/new')
    }
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Box pt="30px">
        <form
          className={classes.form}
          onSubmit={handlePerformSecurityInput}
          autoComplete="off"
        >
          {securityHeader && (
            <Typography className={classes.transferHeader}>{securityHeader}</Typography>
          )}
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={8}
              sm={6}
              xs={12}
            >
              <CustomSelect
                label="Тип поручения"
                error={subTypeError}
                errorText={subTypeTextError}
                value={orderType}
                onChange={getHandler(handleChangeOrderType)}
                unique
              >
                {orderSubTypes
                  && orderSubTypes.map((item) => (
                    <MenuItem
                      key={item?.description}
                      value={item?.description}
                      uid={item?.uid}
                    >
                      {item?.description}
                    </MenuItem>
                  ))}
              </CustomSelect>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xs={12}
            >
              <CustomDatepicker
                error={expirationDateError}
                errorText={expirationDateTextError}
                label="Срок действия"
                value={validity}
                minDate={disabled ? validity : expirationDate || new Date()}
                maxDate={disabled && !canModifyDate ? validity : undefined}
                onChange={(date) => handleChangeValidity(date)}
                unique
              />
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xs={12}
            >
              <CustomInputSelect
                label="Портфель"
                variant="outlined"
                color="secondary"
                size="small"
                value={portfolio || ''}
                options={addEmptyValueToArray(arrayPortfolios?.map((option) => option.name))}
                error={partnerPortfolioError}
                errorText={partnerPortfolioTextError}
                onChange={getHandler(handleChangePortfolio)}
                disabled={disabled}
                unique
              />
            </Grid>
            <Grid
              item
              lg={8}
              sm={6}
              xs={12}
            >
              <CustomSelect
                label={
                  securityType === 'Orders.Type.NonTrade.Depo.Out'
                    ? 'Место хранения (списания)'
                    : 'Место хранения (зачисления)'
                }
                value={storagePlus || ''}
                error={clientDepoStorageError}
                errorText={clientDepoStorageTextError}
                onChange={getHandler(handleChangeStoragePlus)}
                unique
              >
                {clientDepoStorage
                  && clientDepoStorage?.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.name}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </CustomSelect>
            </Grid>
            <Grid
              className={classes.portalBtnWrapper}
              item
              xs={12}
            >
              <button
                className={classes.portalBtn}
                type="button"
                onClick={toggleGroupOneVisibility}
                disabled={disabled}
              >
                <span className={classes.portalBtnLabel}>Ценная бумага</span>
                <DocPlusIcon fill={groupOneVisibility ? '#FBC15E' : '#91B1BF'} />
              </button>
            </Grid>
            <Grid
              className={classes.uniqueGridItem}
              item
              lg={8}
              sm={6}
              xs={12}
            >
              <CustomAutocomplete
                error={securityNameError}
                errorText={securityNameTextError}
                variant="outlined"
                label="Эмитент (для поиска укажите наименование или ISIN или № гос. рег.)"
                name="issuer"
                color="secondary"
                size="small"
                inputValue={searchStr || ''}
                onInputChange={handleChangeAutocompleteInput}
                onChange={handleChangeAutocomplete}
                searchList={getRespSecurities && getRespSecurities?.securities}
                disabled={disabled}
                unique
              />
            </Grid>
            <Grid
              className={classnames(classes.uniqueGridItem, classes.depoPanelWrapper)}
              item
              lg={4}
              sm={6}
              xs={12}
            >
              <CustomInput
                variant="outlined"
                label="Количество"
                name="amount"
                color="secondary"
                size="small"
                error={volumeError}
                errorText={volumeTextError}
                value={amount.value}
                onChange={(e) => {
                  amount.onChange(e)
                  setVolumeError(false)
                }}
                unique
                disabled={disabled}
              />
              <div
                className={classnames(
                  classes.amountInfo,
                  classes.amountWrapper,
                  classes.amountWrapper__SecuritiesIOBalance,
                )}
              >
                <p className={classes.amountInfo__text}>
                  {`Баланс на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
                </p>
                <p className={classes.amountInfo__value}>
                  {(balances && balances.balanceT0 >= 0 && `${balances.balanceT0} шт`) || '...'}
                </p>
              </div>
            </Grid>
            {groupOneVisibility && (
              <>
                <Media
                  query="(min-width: 577px)"
                  render={() => (
                    <Grid
                      item
                      xs={12}
                    >
                      <TableContainer className={classes.issuerTable__wrapper}>
                        <Table
                          className={classes.issuerTable}
                          aria-label="issuer-info"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <CustomSelect
                                  label="Вид"
                                  value={input?.type?.name || ''}
                                  name="type"
                                  error={securityTypeError}
                                  errorText={securityTypeTextError}
                                  onChange={getHandler(handleChangeView)}
                                  unique
                                >
                                  {securityTypes
                                    && securityTypes.map((item) => {
                                      const { name, uid } = item

                                      return (
                                        <MenuItem
                                          key={uid}
                                          value={name}
                                          item={item}
                                        >
                                          {name}
                                        </MenuItem>
                                      )
                                    })}
                                </CustomSelect>
                              </TableCell>
                              <TableCell>
                                <CustomInput
                                  label="Номер гос. регистрации"
                                  variant="outlined"
                                  name="registerNumber"
                                  color="secondary"
                                  size="small"
                                  error={securityRegisterNumberError}
                                  errorText={securityRegisterNumberTextError}
                                  value={input?.registerNumber || ''}
                                  onChange={handleChangeInput}
                                  unique
                                  disabled={disabled}
                                />
                              </TableCell>
                              <TableCell>
                                <CustomInput
                                  label="ISIN"
                                  variant="outlined"
                                  name="isin"
                                  color="secondary"
                                  error={securityIsinError}
                                  errorText={securityIsinTextError}
                                  size="small"
                                  value={input?.isin || ''}
                                  onChange={handleChangeInput}
                                  unique
                                  disabled={disabled}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                />
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px)`}
                  render={() => (
                    <>
                      <Grid
                        item
                        xs={12}
                      >
                        <CustomSelect
                          label="Вид"
                          value={input?.type?.name || ''}
                          name="type"
                          error={securityTypeError}
                          errorText={securityTypeTextError}
                          onChange={getHandler(handleChangeView)}
                          unique
                        >
                          {securityTypes
                            && securityTypes.map((item) => {
                              const { name, uid } = item

                              return (
                                <MenuItem
                                  key={uid}
                                  value={name}
                                  item={item}
                                >
                                  {name}
                                </MenuItem>
                              )
                            })}
                        </CustomSelect>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <CustomInput
                          label="Номер гос. регистрации"
                          variant="outlined"
                          name="registerNumber"
                          color="secondary"
                          size="small"
                          error={securityRegisterNumberError}
                          errorText={securityRegisterNumberTextError}
                          value={input.registerNumber || ''}
                          onChange={handleChangeInput}
                          unique
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <CustomInput
                          label="ISIN"
                          variant="outlined"
                          name="isin"
                          color="secondary"
                          size="small"
                          error={securityIsinError}
                          errorText={securityIsinTextError}
                          value={input.isin || ''}
                          onChange={handleChangeInput}
                          unique
                          disabled={disabled}
                        />
                      </Grid>
                    </>
                  )}
                />
              </>
            )}
            {groupTwoVisibility && (
              <>
                <Media
                  query="(min-width: 577px)"
                  render={() => (
                    <Grid
                      item
                      xs={12}
                    >
                      <TableContainer>
                        <Table
                          className={classes.issuerTable}
                          aria-label="issuer-info"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Вид</TableCell>
                              <TableCell>Номер гос. регистрации</TableCell>
                              <TableCell>ISIN</TableCell>
                              <TableCell>Номинал</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>{issuer?.type?.name}</TableCell>
                              <TableCell>{issuer?.registerNumber}</TableCell>
                              <TableCell>{issuer?.isin}</TableCell>
                              <TableCell>
                                {issuer?.faceValue}
                                {' '}
                                {issuer?.faceValueCurrency?.uid}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                />
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px)`}
                  render={() => (
                    <Grid
                      item
                      xs={12}
                    >
                      <div className={classes.list}>
                        <div className={classes.list__item}>
                          <p className={classes.list__title}>Вид</p>
                          <p className={classes.list__text}>{issuer?.type?.name}</p>
                        </div>
                        <div className={classes.list__item}>
                          <p className={classes.list__title}>Номер гос. регистрации</p>
                          <p className={classes.list__text}>{issuer?.registerNumber}</p>
                        </div>
                        <div className={classes.list__item}>
                          <p className={classes.list__title}>ISIN</p>
                          <p className={classes.list__text}>{issuer?.isin}</p>
                        </div>
                      </div>
                    </Grid>
                  )}
                />
              </>
            )}
            <Grid
              className={classnames(classes.portalBtnWrapper, classes.portalBtnWrapperUnique)}
              item
              xs={12}
              onClick={toggleGroupFourVisibility}
            >
              <span className={classes.groupLabel}>Контрагент</span>
            </Grid>
            {groupFourVisibility && (
              <>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomSelect
                    label="Шаблон контрагента"
                    value=""
                    error={depoClearanceTemplatesError}
                    errorText={depoClearanceTemplatesTextError}
                    withInput
                    inputValue={counterpartyTemplate}
                    onChange={getHandler(handleChangeCounterpartyTemplate)}
                    onChangeInput={handleChangeCounterpartyTemplateInput}
                    disabled={disabled}
                    unique
                  >
                    {currentArrayTemplates
                      && currentArrayTemplates?.map((item) => (
                        <MenuItem
                          value={item.templateName}
                          key={item.id}
                          currenttemplate={item}
                        >
                          {item.templateName}
                        </MenuItem>
                      ))}
                  </CustomSelect>
                </Grid>
                {(orderSubTypeUid === `${securityType}.Broker` || !orderSubTypeUid) && (
                  <>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomSelect
                        label="Тип счета"
                        value={accountType}
                        error={depoAccountHoldingError}
                        errorText={depoAccountHoldingTextError}
                        onChange={getHandler(handleChangeAccountType)}
                        unique
                      >
                        {depoAccountHoldingTypes
                          && depoAccountHoldingTypes?.map((item) => (
                            <MenuItem
                              value={item?.description}
                              key={item?.uid}
                            >
                              {item?.description}
                            </MenuItem>
                          ))}
                      </CustomSelect>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Счет депо"
                        error={depoAccountError}
                        errorText={depoAccountTextError}
                        name="depo-account"
                        color="secondary"
                        size="small"
                        onChange={handleChangeDepoAccount}
                        value={depoAccount}
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={8}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Наименование контрагента"
                        name="counterparty-name"
                        error={agentNameError}
                        errorText={agentNameTextError}
                        color="secondary"
                        size="small"
                        onChange={handleChangeAgentName}
                        value={agentName}
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                  </>
                )}
                {orderSubTypeUid === `${securityType}.Depository` && (
                  <>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomSelect
                        label="Тип счета"
                        value={accountType}
                        error={depoAccountHoldingError}
                        errorText={depoAccountHoldingTextError}
                        onChange={getHandler(handleChangeAccountType)}
                        unique
                      >
                        {depoAccountHoldingTypes
                          && depoAccountHoldingTypes?.map((item) => (
                            <MenuItem
                              value={item.description}
                              key={item.uid}
                            >
                              {item.description}
                            </MenuItem>
                          ))}
                      </CustomSelect>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Счет депо"
                        error={depoAccountError}
                        errorText={depoAccountTextError}
                        name="depo-account"
                        color="secondary"
                        size="small"
                        onChange={handleChangeDepoAccount}
                        value={depoAccount}
                        fullWidth
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={8}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Наименование контрагента"
                        name="counterparty-name"
                        error={agentNameError}
                        errorText={agentNameTextError}
                        color="secondary"
                        size="small"
                        onChange={handleChangeAgentName}
                        value={agentName}
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                  </>
                )}
                {orderSubTypeUid === `${securityType}.NSD` && (
                  <>
                    <Grid
                      item
                      lg={8}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Наименование контрагента"
                        name="counterparty-name"
                        error={agentNameError}
                        errorText={agentNameTextError}
                        color="secondary"
                        size="small"
                        onChange={handleChangeAgentName}
                        value={agentName}
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomSelect
                        label="Тип счета"
                        value={accountType}
                        error={depoAccountHoldingError}
                        errorText={depoAccountHoldingTextError}
                        onChange={getHandler(handleChangeAccountType)}
                        unique
                      >
                        {depoAccountHoldingTypes
                          && depoAccountHoldingTypes?.map((item) => (
                            <MenuItem
                              value={item.description}
                              key={item.uid}
                            >
                              {item.description}
                            </MenuItem>
                          ))}
                      </CustomSelect>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Счет депо"
                        error={depoAccountError}
                        errorText={depoAccountTextError}
                        name="depo-account"
                        color="secondary"
                        size="small"
                        onChange={handleChangeDepoAccount}
                        value={depoAccount}
                        fullWidth
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Номер раздела"
                        name="depo_account_division"
                        error={depoAccountDivisionError}
                        errorText={depoAccountDivisionTextError}
                        color="secondary"
                        size="small"
                        onChange={handleChangeDepoAccountDivision}
                        value={depoAccountDivision}
                        fullWidth
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={8}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Идентификатор"
                        name="depo_code"
                        error={depoCodeError}
                        errorText={depoCodeTextError}
                        color="secondary"
                        size="small"
                        onChange={handleChangeDepoCode}
                        value={depoCode}
                        fullWidth
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                  </>
                )}
                {orderSubTypeUid === `${securityType}.Registry` && (
                  <>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomSelect
                        label="Тип счета"
                        value={accountType}
                        error={depoAccountHoldingError}
                        errorText={depoAccountHoldingTextError}
                        onChange={getHandler(handleChangeAccountType)}
                        unique
                      >
                        {depoAccountHoldingTypes
                          && depoAccountHoldingTypes?.map((item) => (
                            <MenuItem
                              value={item.description}
                              key={item.uid}
                            >
                              {item.description}
                            </MenuItem>
                          ))}
                      </CustomSelect>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Счет депо"
                        name="depo-account"
                        error={depoAccountError}
                        errorText={depoAccountTextError}
                        color="secondary"
                        size="small"
                        onChange={handleChangeDepoAccount}
                        value={depoAccount}
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={8}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Наименование контрагента"
                        name="counterparty-name"
                        error={agentNameError}
                        errorText={agentNameTextError}
                        color="secondary"
                        size="small"
                        onChange={handleChangeAgentName}
                        value={agentName}
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        label="Референс"
                        name="reference"
                        error={referenceError}
                        errorText={referenceTextError}
                        color="secondary"
                        size="small"
                        onChange={handleChangeReference}
                        value={reference}
                        unique
                        disabled={disabled}
                      />
                    </Grid>
                    <FormControl
                      component="fieldset"
                      fullWidth
                    >
                      <Grid
                        item
                        lg={8}
                        sm={6}
                        xs={12}
                      >
                        <FormLabel
                          component="legend"
                          className={classes.fieldsetLegend}
                        >
                          Правовой тип контрагента
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="group"
                          className={classes.radioGroup}
                          value={radioValue}
                          onChange={getHandler(handleChangeFormatRadio)}
                        >
                          <Grid
                            item
                            container
                            spacing={2}
                            sm={10}
                            xs={12}
                          >
                            <FormControlLabel
                              value="person"
                              control={<CustomRadio color="primary" />}
                              label="Физическое лицо"
                            />
                            <FormControlLabel
                              value="firm"
                              control={<CustomRadio color="primary" />}
                              label="Юридическое лицо"
                            />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </FormControl>
                  </>
                )}
                {orderSubTypeUid === `${securityType}.Registry`
                  && ((radioValue === 'person' && (
                    <>
                      <Grid
                        item
                        lg={4}
                        sm={6}
                        xs={12}
                      >
                        <CustomSelect
                          label="Вид документа"
                          value={currentTypeDocument}
                          error={agentIdentityDocumentError}
                          errorText={agentIdentityDocumentTextError}
                          onChange={getHandler(handleChangeCurrentTypeDocument)}
                          unique
                        >
                          {arrayTypeDocument
                            && arrayTypeDocument?.map((item) => (
                              <MenuItem
                                value={item.eng}
                                key={item.eng}
                              >
                                {item.ru}
                              </MenuItem>
                            ))}
                        </CustomSelect>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        sm={6}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          label="Серия"
                          name="person_series"
                          error={agentIdentitySeriesError}
                          errorText={agentIdentitySeriesTextError}
                          color="secondary"
                          size="small"
                          onChange={handleChangePersonSeries}
                          value={personSeries}
                          unique
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        sm={6}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          label="Номер"
                          name="person_number"
                          error={agentIdentityNumberError}
                          errorText={agentIdentityNumberTextError}
                          color="secondary"
                          size="small"
                          onChange={handleChangePersonNumber}
                          value={personNumber}
                          unique
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        sm={6}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          label="Код подразделения"
                          name="person_code"
                          error={agentIdentityDepartmentCodeError}
                          errorText={agentIdentityDepartmentCodeTextError}
                          color="secondary"
                          size="small"
                          onChange={handleChangePersonCode}
                          value={personCode}
                          unique
                          disabled={disabled}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        sm={6}
                        xs={12}
                      >
                        <CustomDatepicker
                          error={agentIdentityIssueDateError}
                          errorText={agentIdentityIssueDateTextError}
                          label="Дата выдачи"
                          value={personIssueDate || null}
                          maxDate={
                            (disabled && personIssueDate) || personExpirationDate || undefined
                          }
                          minDate={(disabled && personIssueDate) || undefined}
                          onChange={(date) => handleChangePersonIssueDate(date)}
                          unique
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        sm={6}
                        xs={12}
                      >
                        <CustomDatepicker
                          error={agentIdentityExpirationDateError}
                          errorText={agentIdentityExpirationDateTextError}
                          label="Срок окончания"
                          value={personExpirationDate || null}
                          minDate={
                            (disabled && personExpirationDate) || personIssueDate || undefined
                          }
                          maxDate={(disabled && personExpirationDate) || undefined}
                          onChange={(date) => handleChangePersonExpirationDate(date)}
                          unique
                        />
                      </Grid>
                      <Grid
                        item
                        lg={8}
                        sm={6}
                        xs={12}
                      >
                        <CustomInput
                          variant="outlined"
                          label="Орган, выдавший документ"
                          name="person_issuingAuthority"
                          error={agentIdentityIssuingAuthorityError}
                          errorText={agentIdentityIssuingAuthorityTextError}
                          color="secondary"
                          size="small"
                          onChange={handleChangePersonIssuingAuthority}
                          value={personIssuingAuthority}
                          unique
                          disabled={disabled}
                        />
                      </Grid>
                    </>
                  ))
                    || (radioValue === 'firm' && (
                      <>
                        <Grid
                          item
                          lg={4}
                          sm={6}
                          xs={12}
                        >
                          <CustomDatepicker
                            error={agentRegistrationDateError}
                            errorText={agentRegistrationDateTextError}
                            label="Дата"
                            value={firmValidity || null}
                            maxDate={(disabled && firmValidity) || undefined}
                            minDate={(disabled && firmValidity) || undefined}
                            onChange={(date) => handleChangeFirmValidity(date)}
                            unique
                          />
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          sm={6}
                          xs={12}
                        >
                          <CustomInput
                            variant="outlined"
                            label="Серия"
                            name="firm_series"
                            error={agentRegistrationSeriesError}
                            errorText={agentRegistrationSeriesTextError}
                            color="secondary"
                            size="small"
                            onChange={handleChangeFirmSeries}
                            value={firmSeries}
                            unique
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          sm={6}
                          xs={12}
                        >
                          <CustomInput
                            variant="outlined"
                            label="Номер"
                            name="firm_number"
                            error={agentRegistrationNumberError}
                            errorText={agentRegistrationNumberTextError}
                            color="secondary"
                            size="small"
                            onChange={handleChangeFirmNumber}
                            value={firmNumber}
                            unique
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          sm={12}
                          xs={12}
                        >
                          <CustomInput
                            variant="outlined"
                            label="Регистрирующий орган"
                            name="firm_issuing_Authority"
                            error={agentRegistrationIssuingAuthorityError}
                            errorText={agentRegistrationIssuingAuthorityTextError}
                            color="secondary"
                            size="small"
                            onChange={handleChangeFirmIssuingAuthority}
                            value={firmIssuingAuthority}
                            unique
                            disabled={disabled}
                          />
                        </Grid>
                      </>
                    )))}
              </>
            )}
            <Grid
              className={classes.portalBtnWrapper}
              item
              xs={12}
              onClick={toggleGroupFiveVisibility}
            >
              <span className={classes.groupLabel}>
                <Media
                  query="(min-width: 577px)"
                  render={() => <>Основание для перерегистрации / сведения о сделке</>}
                />
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px)`}
                  render={() => (
                    <>
                      Основание
                      <br />
                      для перерегистрации / сведения о сделке
                    </>
                  )}
                />
              </span>
            </Grid>

            {groupFiveVisibility && (
              <>
                {orderSubTypeUid === `${securityType}.Depository` && (
                  <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                  >
                    <CustomSelect
                      label="Место перерегистрации"
                      error={centralDepositoryError}
                      errorText={centralDepositoryTextError}
                      value={centralDepository}
                      onChange={getHandler(handleChangeCentralDepository)}
                      unique
                    >
                      {centralDepositories
                        && centralDepositories?.map((item) => (
                          <MenuItem
                            value={item.description}
                            key={item.uid}
                          >
                            {item.description}
                          </MenuItem>
                        ))}
                    </CustomSelect>
                  </Grid>
                )}
                {orderSubTypeUid === `${securityType}.NSD` && (
                  <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                  >
                    <CustomInput
                      variant="outlined"
                      label="Номер междеп. договора"
                      error={interDepoAgreementNumberError}
                      errorText={interDepoAgreementNumberTextError}
                      name="inter_depo_agreement_number"
                      color="secondary"
                      size="small"
                      value={interDepoAgreementNumber}
                      onChange={handleChangeInterDepoAgreementNumber}
                      fullWidth
                      unique
                      disabled={disabled}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomDatepicker
                    label="Дата сделки"
                    error={dealDateError}
                    errorText={dealDateTextError}
                    value={dealDate}
                    maxDate={(disabled && dealDate) || undefined}
                    minDate={(disabled && dealDate) || undefined}
                    onChange={(date) => handleChangeDealDate(date)}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomDatepicker
                    label="Дата перерегистрации"
                    error={registerDateError}
                    errorText={registerDateTextError}
                    value={reRegistrationDate}
                    maxDate={(disabled && reRegistrationDate) || undefined}
                    minDate={(disabled && reRegistrationDate) || undefined}
                    onChange={(date) => handleChangeReRegistrationDate(date)}
                    unique
                  />
                </Grid>
              </>
            )}
            <Grid
              className={classes.portalBtnWrapper}
              item
              xs={12}
            >
              <button
                className={classes.portalBtn}
                type="button"
                onClick={toggleGroupTwoVisibility}
                disabled={disabled}
              >
                <span className={classes.portalBtnLabel}>
                  Документы основания для перерегистрации
                </span>
                <DocPlusIcon fill={groupThreeVisibility ? '#FBC15E' : '#91B1BF'} />
              </button>
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xs={12}
            >
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <TableContainer className={classes.tableContainer}>
                    <Table aria-label="assetsMoney table">
                      <TableHead>
                        <TableRow className={classes.tableRow}>
                          <TableCell
                            className={classes.tableHeaderCell}
                            style={{ minWidth: 295 }}
                          >
                            Тип документа
                          </TableCell>
                          <TableCell
                            className={classes.tableHeaderCell}
                            style={{ minWidth: 308 }}
                          >
                            Номер документа
                          </TableCell>
                          <TableCell
                            className={classes.tableHeaderCell}
                            style={{ minWidth: 266 }}
                          >
                            Дата документа&nbsp;
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {arrayDocuments
                          && arrayDocuments?.map((item, key) => (
                            <Fragment key={key.toString()}>
                              <TableRow
                                className={classes.tableRow}
                                hover
                                {...(!item?.required && {
                                  onClick: () => getHandler(handleOpenRequest(key)),
                                })}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableBodyCell}
                                  style={{ minWidth: 295 }}
                                >
                                  {item?.type}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableBodyCell}
                                  style={{ minWidth: 308 }}
                                >
                                  {item?.number}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableBodyCell}
                                  style={{ minWidth: 266 }}
                                >
                                  {item?.dateTime && format(new Date(item.dateTime), 'dd.MM.yyyy' || null)}
                                </TableCell>
                              </TableRow>
                              {open && !groupThreeVisibility ? (
                                <TableRow>
                                  <TableCell colSpan={3}>
                                    <Collapse
                                      in={open === `tabRow-${key}`}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <Table>
                                        <TableBody>
                                          <TableRow className={classes.tableRowLineNone}>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              className={classes.tableBodyCell}
                                              style={{ minWidth: 200 }}
                                            >
                                              <CustomSelect
                                                label="Тип документа"
                                                error={orderReasonTypesError}
                                                errorText={orderReasonTypesTextError}
                                                value={documentType}
                                                onChange={getHandler(handleChangeDocumentType)}
                                                unique
                                              >
                                                {orderReasonTypes
                                                  && orderReasonTypes?.map((type) => (
                                                    <MenuItem
                                                      value={type?.description}
                                                      key={type.uid}
                                                    >
                                                      {type?.description}
                                                    </MenuItem>
                                                  ))}
                                              </CustomSelect>
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              className={classes.tableBodyCell}
                                              style={{ minWidth: 200 }}
                                            >
                                              <CustomInput
                                                variant="outlined"
                                                label="Номер документа"
                                                error={docNumberError}
                                                errorText={docNumberTextError}
                                                name="document-number"
                                                color="secondary"
                                                size="small"
                                                value={docNumber}
                                                onChange={handleChangeDocNumber}
                                                unique
                                                disabled={disabled}
                                              />
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              className={classes.tableBodyCell}
                                              style={{ minWidth: 200 }}
                                            >
                                              <CustomDatepicker
                                                error={docDateError}
                                                errorText={docDateTextError}
                                                label="Дата документа"
                                                value={documentDate}
                                                maxDate={
                                                  (disabled && (documentDate || item?.dateTime))
                                                  || undefined
                                                }
                                                minDate={
                                                  (disabled && (documentDate || item?.dateTime))
                                                  || undefined
                                                }
                                                onChange={(date) => handleChangeDocumentDate(date)}
                                                unique
                                              />
                                            </TableCell>
                                          </TableRow>
                                          <TableRow className={classes.tableRowLineNone}>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              className={classnames(
                                                classes.tableBodyCell,
                                                classes.fileInputWrapper,
                                              )}
                                              style={{ minWidth: 200 }}
                                              colSpan={3}
                                            >
                                              <FormLabel>Скан-копия документа</FormLabel>
                                              <div>
                                                {(scanFiles?.length || item?.attachments)
                                                  && ((!getDisabledState() && (
                                                    <DropZone
                                                      onAdd={(addedFile) =>
                                                        handleAddFile(
                                                          scanFiles,
                                                          setScanFiles,
                                                          setTemporaryScanFiles,
                                                          addedFile,
                                                        )}
                                                      onDrop={() => setIsLoading(true)}
                                                      onDelete={(deletedFile) =>
                                                        handleRemoveFile(
                                                          scanFiles,
                                                          setScanFiles,
                                                          idScanFiles,
                                                          setIdScanFiles,
                                                          deletedFile,
                                                        )}
                                                      fileObjects={scanFiles || item?.attachments}
                                                      maximumNumberofFiles={555}
                                                      isLoading={isLoading}
                                                      readOnly={isLoading || isDocLoading}
                                                    />
                                                  )) || (
                                                    <DropZone
                                                      fileObjects={scanFiles || item?.attachments}
                                                      maximumNumberofFiles={555}
                                                      readOnly
                                                    />
                                                  ))}
                                              </div>
                                            </TableCell>
                                          </TableRow>

                                          {orderReasonTypeUID === 'Orders.Reason.Type.Other' && (
                                            <TableRow className={classes.tableRowLineNone}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                className={classes.tableBodyCell}
                                                style={{ minWidth: 200 }}
                                                colSpan={3}
                                              >
                                                <CustomInput
                                                  variant="outlined"
                                                  label="Наименование документа"
                                                  error={docNameError}
                                                  errorText={docNameTextError}
                                                  name="nameDocument"
                                                  color="secondary"
                                                  size="small"
                                                  value={nameDocument}
                                                  onChange={handleChangeNameDocument}
                                                  fullWidth
                                                  unique
                                                  disabled={disabled}
                                                />
                                              </TableCell>
                                            </TableRow>
                                          )}

                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              className={classes.tableBodyCell}
                                              style={{ minWidth: 200 }}
                                              colSpan={3}
                                            >
                                              <div
                                                className={classnames(
                                                  classes.btnGroup,
                                                  classes.tableBodyCell__btn,
                                                )}
                                              >
                                                {(((currentActions && currentActions?.canModify)
                                                  || !currentActions)
                                                  && !disabled && (
                                                    <>
                                                      <CustomIconButton
                                                        type="button"
                                                        onClick={() =>
                                                          handleChangeDocumentDelete(key)}
                                                        unique
                                                        noIcon
                                                        needLine
                                                      >
                                                        Удалить
                                                      </CustomIconButton>
                                                      <CustomIconButton
                                                        type="button"
                                                        onClick={() =>
                                                          handleChangeDocumentSave(item, key)}
                                                        unique
                                                        noIcon
                                                        needLine
                                                      >
                                                        Сохранить
                                                      </CustomIconButton>
                                                      <CustomIconButton
                                                        type="button"
                                                        onClick={() =>
                                                          handleChangeDocumentCancel(key)}
                                                        unique
                                                        noIcon
                                                      >
                                                        Отменить
                                                      </CustomIconButton>
                                                    </>
                                                )) || (
                                                  <CustomIconButton
                                                    type="button"
                                                    onClick={() => handleChangeDocumentCancel(key)}
                                                    unique
                                                    noIcon
                                                  >
                                                    Отменить
                                                  </CustomIconButton>
                                                )}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              ) : null}
                            </Fragment>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
              <Media
                query={`(max-width: ${breakpoints.phone_large}px)`}
                render={() =>
                  arrayDocuments
                  && arrayDocuments?.map((item, key) => (
                    <div
                      className={classes.list}
                      key={key.toString()}
                      onClick={() => getHandler(handleOpenRequest(key))}
                      aria-hidden="true"
                    >
                      <div className={classes.list__item}>
                        <p className={classes.list__title}>Тип документа</p>
                        <p className={classes.list__text}>{item?.type}</p>
                      </div>
                      <div className={classes.list__item}>
                        <p className={classes.list__title}>Номер документа</p>
                        <p className={classes.list__text}>{item?.number}</p>
                      </div>
                      <div className={classes.list__item}>
                        <p className={classes.list__title}>Дата документа&nbsp;</p>
                        <p className={classes.list__text}>{format(item?.dateTime, 'dd.MM.yyyy')}</p>
                      </div>
                      {open && !groupThreeVisibility ? (
                        <>
                          <div className={classes.list__item}>
                            <CustomSelect
                              label="Тип документа"
                              error={orderReasonTypesError}
                              errorText={orderReasonTypesTextError}
                              value={documentType}
                              onChange={getHandler(handleChangeDocumentType)}
                              unique
                            >
                              {orderReasonTypes
                                && orderReasonTypes?.map((type) => (
                                  <MenuItem
                                    value={type?.description}
                                    key={type.uid}
                                  >
                                    {type?.description}
                                  </MenuItem>
                                ))}
                            </CustomSelect>
                          </div>
                          <div className={classes.list__item}>
                            <CustomInput
                              variant="outlined"
                              label="Номер документа"
                              name="document-number"
                              error={docNumberError}
                              errorText={docNumberTextError}
                              color="secondary"
                              size="small"
                              value={docNumber}
                              onChange={handleChangeDocNumber}
                              unique
                              disabled={disabled}
                            />
                          </div>
                          <div className={classes.list__item}>
                            <CustomDatepicker
                              error={docDateError}
                              errorText={docDateTextError}
                              label="Дата документа"
                              maxDate={(disabled && (documentDate || item?.dateTime)) || undefined}
                              minDate={(disabled && (documentDate || item?.dateTime)) || undefined}
                              value={documentDate}
                              onChange={(date) => handleChangeDocumentDate(date)}
                              unique
                            />
                          </div>
                          <div className={classes.list__item}>
                            <p className={classes.list__title}>Скан-копия документа</p>
                            <div
                              className={classnames(
                                classes.fileInputWrapper,
                                classes.list__item_fullWidth,
                              )}
                            >
                              {(scanFiles?.length || item?.attachments)
                                && ((!getDisabledState() && (
                                  <DropZone
                                    onAdd={(addedFile) =>
                                      handleAddFile(
                                        scanFiles,
                                        setScanFiles,
                                        setTemporaryScanFiles,
                                        addedFile,
                                      )}
                                    onDrop={() => setIsLoading(true)}
                                    onDelete={(deletedFile) =>
                                      handleRemoveFile(
                                        scanFiles,
                                        setScanFiles,
                                        idScanFiles,
                                        setIdScanFiles,
                                        deletedFile,
                                      )}
                                    fileObjects={scanFiles || item?.attachments}
                                    maximumNumberofFiles={555}
                                    isLoading={isLoading}
                                    readOnly={isLoading || isDocLoading}
                                  />
                                )) || (
                                  <DropZone
                                    fileObjects={scanFiles || item?.attachments}
                                    maximumNumberofFiles={555}
                                    readOnly
                                  />
                                ))}
                            </div>
                          </div>
                          {orderReasonTypeUID === 'Orders.Reason.Type.Other' && (
                            <>
                              <div className={classes.list__item}>
                                <CustomInput
                                  variant="outlined"
                                  label="Наименование документа"
                                  error={docNameError}
                                  errorText={docNameTextError}
                                  name="nameDocument"
                                  color="secondary"
                                  size="small"
                                  value={nameDocument}
                                  onChange={handleChangeNameDocument}
                                  fullWidth
                                  unique
                                  disabled={disabled}
                                />
                              </div>
                            </>
                          )}
                          <div
                            className={classnames(classes.btnGroupUnique, classes.btnGroupPhone)}
                          >
                            {(((currentActions && currentActions?.canModify)
                              || !currentActions) && (
                              <>
                                <CustomIconButton
                                  customClassNames={classes.btnPhoneUniq}
                                  type="button"
                                  onClick={() => handleChangeDocumentDelete(key)}
                                  unique
                                  noIcon
                                  needLine
                                >
                                  Удалить
                                </CustomIconButton>
                                <CustomIconButton
                                  customClassNames={classes.btnPhoneUniq}
                                  type="button"
                                  onClick={() => handleChangeDocumentSave(item, key)}
                                  unique
                                  noIcon
                                  needLine
                                >
                                  Сохранить
                                </CustomIconButton>
                                <CustomIconButton
                                  type="button"
                                  onClick={() => handleChangeDocumentCancel(key)}
                                  unique
                                  noIcon
                                >
                                  Отменить
                                </CustomIconButton>
                              </>
                            )) || (
                              <CustomIconButton
                                type="button"
                                onClick={() => handleChangeDocumentCancel(key)}
                                unique
                                noIcon
                              >
                                Отменить
                              </CustomIconButton>
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))}
              />
            </Grid>
            {groupThreeVisibility && (
              <>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomSelect
                    label="Тип документа"
                    value={documentType}
                    error={orderReasonTypesError}
                    errorText={orderReasonTypesTextError}
                    onChange={getHandler(handleChangeDocumentType)}
                    unique
                  >
                    {orderReasonTypes
                      && orderReasonTypes?.map((type) => (
                        <MenuItem
                          value={type?.description}
                          key={type.uid}
                        >
                          {type.description}
                        </MenuItem>
                      ))}
                  </CustomSelect>
                </Grid>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomInput
                    variant="outlined"
                    label="Номер документа"
                    error={docNumberError}
                    errorText={docNumberTextError}
                    name="document-number"
                    color="secondary"
                    size="small"
                    value={docNumber}
                    onChange={handleChangeDocNumber}
                    unique
                    disabled={disabled}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomDatepicker
                    label="Дата документа"
                    error={docDateError}
                    errorText={docDateTextError}
                    maxDate={(disabled && (documentDate || null)) || undefined}
                    minDate={(disabled && (documentDate || null)) || undefined}
                    value={documentDate || null}
                    onChange={(date) => handleChangeDocumentDate(date)}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.fileInputWrapper}
                >
                  <FormLabel>Скан-копия документа</FormLabel>
                  <div>
                    {(!getDisabledState() && (
                      <DropZone
                        onAdd={(addedFile) =>
                          handleAddFile(scanFiles, setScanFiles, setTemporaryScanFiles, addedFile)}
                        onDrop={() => setIsLoading(true)}
                        onDelete={(deletedFile) =>
                          handleRemoveFile(
                            scanFiles,
                            setScanFiles,
                            idScanFiles,
                            setIdScanFiles,
                            deletedFile,
                          )}
                        fileObjects={scanFiles}
                        maximumNumberofFiles={555}
                        isLoading={isLoading}
                        readOnly={isLoading || isDocLoading}
                      />
                    )) || (
                    <DropZone
                      fileObjects={scanFiles}
                      maximumNumberofFiles={555}
                      readOnly
                    />
                    )}
                  </div>
                </Grid>
                {orderReasonTypeUID === 'Orders.Reason.Type.Other' && (
                  <Grid
                    item
                    lg={12}
                    sm={6}
                    xs={12}
                  >
                    <CustomInput
                      variant="outlined"
                      label="Наименование документа"
                      error={docNameError}
                      errorText={docNameTextError}
                      name="nameDocument"
                      color="secondary"
                      size="small"
                      value={nameDocument}
                      onChange={handleChangeNameDocument}
                      fullWidth
                      unique
                      disabled={disabled}
                    />
                  </Grid>
                )}
                <Media
                  query="(min-width: 577px)"
                  render={() => (
                    <Grid
                      item
                      xs={12}
                      className={classes.btnGroup}
                    >
                      <CustomIconButton
                        type="button"
                        onClick={handleAddDocumentDelete}
                        unique
                        noIcon
                        needLine
                      >
                        Удалить
                      </CustomIconButton>
                      <CustomIconButton
                        type="button"
                        onClick={handleAddDocumentSave}
                        unique
                        noIcon
                        needLine
                      >
                        Сохранить
                      </CustomIconButton>
                      <CustomIconButton
                        type="button"
                        onClick={handleAddDocumentCancel}
                        unique
                        noIcon
                      >
                        Отменить
                      </CustomIconButton>
                    </Grid>
                  )}
                />
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px)`}
                  render={() => (
                    <Grid
                      item
                      xs={12}
                      className={classes.btnGroupPhone}
                    >
                      <CustomIconButton
                        customClassNames={classes.btnPhoneUniq}
                        type="button"
                        onClick={handleAddDocumentDelete}
                        unique
                        noIcon
                        needLine
                      >
                        Удалить
                      </CustomIconButton>
                      <CustomIconButton
                        customClassNames={classes.btnPhoneUniq}
                        type="button"
                        onClick={handleAddDocumentSave}
                        unique
                        noIcon
                        needLine
                      >
                        Сохранить
                      </CustomIconButton>
                      <CustomIconButton
                        type="button"
                        onClick={handleAddDocumentCancel}
                        unique
                        noIcon
                      >
                        Отменить
                      </CustomIconButton>
                    </Grid>
                  )}
                />
              </>
            )}
            <Grid
              item
              xs={12}
            >
              <CustomInput
                variant="outlined"
                label="Дополнительные инструкции"
                name="additional-instructions"
                color="secondary"
                value={comment}
                onChange={handleChangeComment}
                multiline
                rows={2}
                unique
                disabled={disabled}
              />
            </Grid>
            {hasSecuritySwitch ? (
              <>
                <Grid
                  item
                  container
                  spacing={4}
                >
                  <Grid
                    item
                    lg={5}
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    <Typography style={{ fontSize: 15 }}>
                      Подтверждена стоимость приобретения ЦБ
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.switchGridItem}
                    item
                    lg={2}
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    <CustomSwitch
                      name="first"
                      isChecked={switchStates.first}
                      onChange={getHandler(handleSwitchChanges)}
                    />
                  </Grid>
                </Grid>
                {switchStates.first ? (
                  <>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        label="Стоимость приобретения"
                        variant="outlined"
                        name="acquisition-cost"
                        error={documentedCostValueError}
                        errorText={documentedCostValueTextError}
                        color="secondary"
                        size="small"
                        value={acquisitionCost}
                        onChange={handleChangeAcquisitionCost}
                        unique
                        disabled={disabled}
                      />
                      <CustomSelect
                        label="Валюта"
                        value={currency}
                        error={documentedCostCurrencyError}
                        errorText={documentedCostCurrencyTextError}
                        onChange={getHandler(handleChangeCurrency)}
                        unique
                      >
                        {arrayCurrencies?.length
                          && arrayCurrencies.map((item) => (
                            <MenuItem
                              value={item.uid}
                              key={item.uid}
                            >
                              {item.uid}
                            </MenuItem>
                          ))}
                      </CustomSelect>
                    </Grid>
                    <Grid
                      item
                      lg={8}
                      sm={6}
                      xs={12}
                      className={classes.fileInputWrapper}
                    >
                      <FormLabel>Документ, подтверждающий стоимость приобретения</FormLabel>
                      <div className={classes.dropzoneWrapper}>
                        {(!getDisabledState() && (
                          <DropZone
                            onAdd={(addedFile) =>
                              handleAddFile(docFiles, setDocFiles, setTemporaryDocFiles, addedFile)}
                            onDrop={() => setIsDocLoading(true)}
                            onDelete={(deletedFile) =>
                              handleRemoveFile(
                                docFiles,
                                setDocFiles,
                                idDocCostFiles,
                                setIdDocCostFiles,
                                deletedFile,
                              )}
                            fileObjects={docFiles}
                            maximumNumberofFiles={555}
                            isLoading={isDocLoading}
                            readOnly={isLoading || isDocLoading}
                          />
                        )) || (
                          <DropZone
                            fileObjects={docFiles}
                            maximumNumberofFiles={555}
                            readOnly
                          />
                        )}
                      </div>
                    </Grid>
                  </>
                ) : null}
              </>
            ) : null}
            {(getSaveSecurity?.requiredAction?.type === 'code'
              || getPerformSecurity?.requiredAction?.type === 'code'
              || getSaveSecurity?.requiredAction?.type === 'mac'
              || getPerformSecurity?.requiredAction?.type === 'mac') && (
              <>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomInput
                    error={codeError}
                    errorText={codeTextError}
                    variant="outlined"
                    label="Введите код из сообщения"
                    name="code"
                    value={code}
                    onChange={(event) => handleChangeCodeInput(event)}
                    size="small"
                    color="secondary"
                    unique
                    className={classes.inputField}
                    autoComplete="off"
                    helperText={
                      getPerformSecurity?.requiredAction?.type === 'mac'
                      || getSaveSecurity?.requiredAction?.type === 'mac'
                        ? hashSha256
                        : ''
                    }
                  />
                </Grid>
                <Grid
                  item
                  lg={8}
                  sm={6}
                  xs={12}
                  className={classes.Transfer__codeWrapper}
                >
                  {(showTimer && (
                    <Typography
                      className={classes.Transfer__codeText}
                      m={0}
                    >
                      Если код не пришел, Вы можете запросить новый код через
                      <span className={classes.Transfer__codeInput}>
                        <Timer
                          seconds={30}
                          handleStartTimer={handleStartTimer}
                        />
                      </span>
                    </Typography>
                  ))
                    || (!showTimer && (
                      <CustomButton
                        label="Выслать новый код"
                        modifier="primary"
                        type="submit"
                        fullWidth
                        onClick={handleResetTimer}
                      />
                    ))}
                </Grid>
              </>
            )}
            <Grid
              item
              lg={6}
              sm={6}
              xs={12}
              className={classes.TransferStatus}
            >
              {getRespSecurity?.order && (
                <div className={(!!currentStatusDescription && classes.TransferStatus__wrap) || ''}>
                  <Typography className={classes.TransferStatus__name}>
                    Статус:
                    {' '}
                    {currentStatus?.name}
                  </Typography>
                  {currentStatusDescription && (
                    <Typography
                      className={classes.TransferStatus__description}
                      dangerouslySetInnerHTML={createMarkup()}
                    />
                  )}
                </div>
              )}
            </Grid>
            {(((currentActions && currentActions?.canModify) || !currentActions)
              && !getSaveSecurity?.requiredAction?.type
              && !getPerformSecurity?.requiredAction?.type && (
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    type="submit"
                    onClick={() => {
                      setSubmitAction('perform')
                    }}
                    disabled={formDisabled}
                  >
                    Исполнить
                  </CustomIconButton>
                  <CustomIconButton
                    type="submit"
                    onClick={() => {
                      setSubmitAction('save')
                    }}
                    disabled={formDisabled}
                  >
                    Сохранить
                  </CustomIconButton>
                  <Media
                    query="(min-width: 577px)"
                    render={() => (
                      <CustomIconButton
                        type="button"
                        onClick={handleBackToHistory}
                      >
                        Назад
                      </CustomIconButton>
                    )}
                  />
                </Grid>
            ))
              || ((getSaveSecurity?.requiredAction?.type === 'sign'
                || getPerformSecurity?.requiredAction?.type === 'sign') && (
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    onClick={() => {
                      setSubmitAction('perform')
                    }}
                    type="submit"
                  >
                    Подписать
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    onClick={handleDownloadXml}
                    icon={<DownloadIcon />}
                  >
                    Скачать .xml
                  </CustomIconButton>
                  <Media
                    query="(min-width: 577px)"
                    render={() => (
                      <CustomIconButton
                        type="button"
                        onClick={handleCancelOperation}
                      >
                        Назад
                      </CustomIconButton>
                    )}
                  />
                </Grid>
              ))
              || ((getSaveSecurity?.requiredAction?.type === 'code'
                || getPerformSecurity?.requiredAction?.type === 'code'
                || getSaveSecurity?.requiredAction?.type === 'mac'
                || getPerformSecurity?.requiredAction?.type === 'mac') && (
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    type="submit"
                    onClick={() => {
                      setSubmitAction('perform')
                    }}
                  >
                    Подтвердить
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    onClick={handleCancelOperation}
                  >
                    Отменить
                  </CustomIconButton>
                </Grid>
              ))
              || (currentActions && currentActions?.canCancel && (
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton onClick={() => setCancellationModalOpen(true)}>
                    Отменить поручение
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    onClick={handleBackToHistory}
                  >
                    Назад
                  </CustomIconButton>
                </Grid>
              ))
              || (currentActions && !currentActions?.canModify && !currentActions?.canCancel && (
                <Grid
                  item
                  lg={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    type="button"
                    onClick={handleBackToHistory}
                  >
                    Назад
                  </CustomIconButton>
                </Grid>
              ))}
          </Grid>
        </form>
      </Box>
      <CustomModal
        open={saveModalOpen}
        title="Поручение успешно создано."
        handleClose={handleCloseSaveModal}
        actionText="Хотите создать новое?"
        actionReject={handleActionReject}
        actionSuccess={handleActionSuccess}
        disableOverlay
      />
      <CustomModal
        open={updModalOpen}
        title="Поручение обновлено"
        handleClose={handleCloseUpdModal}
      />
      <CustomModal
        open={performModalOpen}
        title="Поручение успешно подписано"
        handleClose={handleClosePerformModal}
        actionText="Хотите создать новое?"
        actionReject={handleActionReject}
        actionSuccess={handleActionSuccess}
        disableOverlay
      />
      <CustomModal
        open={cancellationModalOpen}
        title="Создать поручение на отмену"
        handleClose={handleCloseCancellationModal}
        callbacks={{
          withOk: handleCancelOk,
          withCancel: () => {
            handleCloseCancellationModal()
          },
        }}
      />
      <CustomModal
        open={failedLoadModalOpen}
        title="Не удалось загрузить файл"
        handleClose={() => setFailedLoadModalOpen(false)}
      />
      <CustomModal
        open={failedEmptyFile}
        title="Не удалось загрузить пустой файл"
        handleClose={() => setFailedEmptyFile(false)}
      />
    </MuiPickersUtilsProvider>
  )
}

Assignment.defaultProps = {
  hasSecuritySwitch: true,
  securityType: '',
}

Assignment.propTypes = {
  hasSecuritySwitch: PropTypes.bool,
  securityType: PropTypes.string,
}

import { createGlobalStyle } from 'styled-components'
import { styled } from 'ui/theme'
import { ITooltipWrap } from './types'

export const StyledTippy = styled.div`
  background-color: ${({ theme }) => theme.color.bg.tertiary};
  color: ${({ theme }) => theme.color.text.inverted};
  ${({ theme }) => theme.font.body2}
`

export const StyledTooltipWrap = styled.span<ITooltipWrap>`
  ${({ width }) => width && `width: ${width};`}
  color: ${({ theme, color }) => color || theme.color.graphics.tertiary};
  svg {
    display: block;
  }
`

export const TippyStyles = createGlobalStyle`
.tippy-box{
  background-color:${({ theme }: any) => theme.color.bg.tertiary};
}
.tippy-arrow{
  color:${({ theme }: any) => theme.color.bg.tertiary}
}
.tippy-content{
  padding:9px 12px;
  ${({ theme }: any) => theme.font.caption2};
}
`

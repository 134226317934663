import React from 'react';
import CustomInputSelect from 'components/CustomControls/CustomInputSelect';
import { StyledGlobal, StyledWrapper } from './styled';
import {SelectStyles} from "../Select/styled";


type IProps = Partial<React.ComponentProps<typeof CustomInputSelect>>

export const CustomAutocompleteSelect: React.FC<IProps> = ({
   hasError = undefined,
   label = undefined,
   helperText = undefined,
   placeholder = undefined,
   specialOptions = undefined,
   error = undefined,
   errorText = undefined,
   value = '',
   onChange = undefined,
   required = undefined,
   uniqueSearchList = undefined,
   disabled = undefined,
   disabledMain = undefined,
   unique = undefined,
   onInputChange = undefined,
   onDeleteItem = undefined,
   ...props
}) => {
  return <StyledWrapper>
    <StyledGlobal />
    <CustomInputSelect
    label = {label}
    helperText = {helperText}
    placeholder = {placeholder}
    specialOptions = {specialOptions}
    error = {error}
    errorText = {errorText}
    hasError = {hasError}
    value = {value}
    onChange = {onChange}
    required = {required}
    uniqueSearchList = {uniqueSearchList}
    disabled = {disabled}
    disabledMain = {disabledMain}
    unique = {unique}
    onInputChange = {onInputChange}
    onDeleteItem = {onDeleteItem}
    {...props}
    />
  </StyledWrapper>;
}

import React from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'
import useStyles from './PageHeading.style'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'

const PageHeading = ({ text }) => {
  const classes = useStyles()

  return (
    <Media
      query={`(max-width: ${breakpoints.phone_large}px)`}
      render={() => (
        <div className={classes.PageHeading}>
          <p className={classes.PageHeading__text}>{text}</p>
        </div>
      )}
    />
  )
}

PageHeading.propTypes = {
  text: PropTypes.string.isRequired,
}

export default PageHeading

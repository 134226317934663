import { styled } from 'ui/theme'
import { IRadioSwitchContainer, IRadioSwitchTitle } from './types'

export const RadioSwitchContainer = styled.div<IRadioSwitchContainer>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  padding: 24px 32px;

  box-shadow: 0 0 20px 2px rgba(49, 58, 78, 0.06);
  border-radius: 8px;

  @media ${({ theme }) => theme.breakpoint.xs} {
    ${({ rowTablet }) => !rowTablet && 'flex-direction: column'};
    align-items: ${({ alignItemsTablet }) => alignItemsTablet || 'flex-start'};

    padding: ${({ paddingTablet }) => paddingTablet || '20px'};
  }

  @media ${({ theme }) => theme.breakpoint.xxs} {
    flex-direction: column;
    align-items: flex-start;

    padding: 20px;
  }
`

export const RadioSwitchTitle = styled.div<IRadioSwitchTitle>`
  max-width: ${({ titleMaxWidth }) => `${titleMaxWidth ?? 715}px`};

  ${({ titleMinWidth }) => titleMinWidth && `min-width: ${titleMinWidth}px;`}

  ${({ theme }) => theme.font.body3}
`

import { styled } from 'ui/theme'

export const RadioLabeledWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
`

export const RadioLabeledList = styled.div`
  display: flex;
  gap: 16px;
`

export const RadioLabeledLabel = styled.p<{labelBold?: boolean}>`
  font-family: 'Open Sans', 'sans-serif';
  margin-bottom: 12px;

  // TODO: get font-size from theme (update theme)
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.text.primary};

  cursor: pointer;

  ${({ labelBold, theme }) => labelBold === false ? `
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: ${theme.color.text.secondary};
  ` : `` };
`

export const RadioLabeledCaption = styled.p`
  position: absolute;
  left: 0;
  top: calc(100% + 12px);

  margin-bottom: 0;

  font-size: 14px;
  color: ${({ theme }) => theme.color.text.secondary};
`

import { createSlice } from '@reduxjs/toolkit'
import getCadespluginAPI from 'async-cadesplugin'
import Api from '../../api/Api'
import { getErrors } from '../errorsModal/errorsModalSlice'

const initialState = {
  error: null,
  history: null,
  getFeedback: null,
  postFeedback: null,
  putFeedback: null,
}

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    getHistory(state) {
      return {
        ...state,
      }
    },
    getHistorySuccess(state, action) {
      return {
        ...state,
        history: action.payload,
      }
    },
    getHistoryFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getFeedback(state) {
      return {
        ...state,
      }
    },
    getFeedbackSuccess(state, action) {
      return {
        ...state,
        getFeedback: action.payload,
      }
    },
    getFeedbackFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    postFeedback(state) {
      return {
        ...state,
      }
    },
    postFeedbackSuccess(state, action) {
      return {
        ...state,
        postFeedback: action.payload,
      }
    },
    postFeedbackFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    putFeedback(state) {
      return {
        ...state,
      }
    },
    putFeedbackSuccess(state, action) {
      return {
        ...state,
        putFeedback: action.payload,
      }
    },
    putFeedbackFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    clearFeedback() {
      return initialState
    },
  },
})

const { actions, reducer } = feedbackSlice

export const {
  getHistory,
  getHistorySuccess,
  getHistoryFailed,
  getFeedback,
  getFeedbackSuccess,
  getFeedbackFailed,
  putFeedback,
  putFeedbackSuccess,
  putFeedbackFailed,
  postFeedback,
  postFeedbackSuccess,
  postFeedbackFailed,
  clearFeedback,
} = actions

export default reducer

export const fetchHistoryStartAsync = () => async (dispatch) => {
  dispatch(getHistory())
  try {
    const value = await Api.getUsersRequest()
    dispatch(getHistorySuccess(value))
  } catch (err) {
    dispatch(getErrors(err))
    dispatch(getHistoryFailed())
  }
}

export const fetchSendMessageStartAsync = ({ obj }) => async (dispatch) => {
  dispatch(postFeedback())
  try {
    if (obj.id) {
      const value = await Api.putUserRequest(obj)
      dispatch(postFeedbackSuccess(value))
    } else {
      const value = await Api.postUserRequest(obj)
      dispatch(postFeedbackSuccess(value))
    }
  } catch (err) {
    dispatch(getErrors(err))
    dispatch(obj.id ? putFeedbackFailed(err) : postFeedbackFailed(err))
  }
}

export const fetchCertificateStartAsync = ({ obj }) => async (dispatch) => {
  dispatch(putFeedback())
  try {
    const api = await getCadespluginAPI()
    const signature = await api.signXml(obj.thumbPrint, obj.signedXml)
    const reqObj = obj
    reqObj.signedXml = signature
    const value = await Api.putUserRequest(reqObj)
    dispatch(putFeedbackSuccess(value))
  } catch (err) {
    dispatch(getErrors(err))
    dispatch(putFeedbackFailed(err))
  }
}

export const fetchCodeStartAsync = ({ obj }) => async (dispatch) => {
  dispatch(putFeedback())
  try {
    const value = await Api.putUserRequest(obj)
    dispatch(putFeedbackSuccess(value))
  } catch (err) {
    dispatch(getErrors(err))
    dispatch(putFeedbackFailed(err))
  }
}

export const fetchFeedbackStartAsync = ({ id }) => async (dispatch) => {
  dispatch(getFeedback())
  try {
    const value = await Api.getUserRequest(id)
    dispatch(getFeedbackSuccess(value))
  } catch (err) {
    dispatch(getErrors(err))
    dispatch(getFeedbackFailed(err))
  }
}

import { createSelector } from '@reduxjs/toolkit'

const selectHistories = (state) => state.history

export const selectHistoryMoney = createSelector(
  [selectHistories],
  (state) => state.getHistoryMoney?.data,
)

export const selectHistoryPortfolios = createSelector(
  [selectHistories],
  (state) => state.getHistoryMoney?.data?.references?.portfolios
    || state.getHistoryDepo?.data?.references?.portfolios,
)

export const selectHistoryStatuses = createSelector(
  [selectHistories],
  (state) => state.getHistoryMoney?.data?.references?.orderStatuses
    || state.getHistoryDepo?.data?.references?.orderStatuses,
)

export const selectHistoryOrderTypes = createSelector(
  [selectHistories],
  (state) => state.getHistoryMoney?.data?.references?.orderTypes
    || state.getHistoryDepo?.data?.references?.orderTypes,
)

export const selectHistoryOrderTypesAll = createSelector(
  [selectHistories],
  (state) => state.ordersTypesNames?.data,
)

export const selectHistoryDepo = createSelector(
  [selectHistories],
  (state) => state.getHistoryDepo?.data,
)
export const selectOrdersTypesNames = createSelector(
  [selectHistories],
  (state) => state.ordersTypesNames?.data,
)

import { createSlice } from '@reduxjs/toolkit'
import getCadespluginAPI from 'async-cadesplugin'
import Api from '../../../api/Api'
import { getErrorSuccess } from '../../error/errorSlice'

const initialState = {
  getTransferMoney: null,
  error: null,
  saveTransferMoney: null,
  saveTransferError: null,
  performTransferMoney: null,
  performTransferError: null,
  deleteMoney: null,
  balances: null,
}

const transferMoneySlice = createSlice({
  name: 'transferMoney',
  initialState,
  reducers: {
    getTransferMoney(state) {
      return {
        ...state,
        error: null,
      }
    },

    getTransferMoneySuccess(state, action) {
      return {
        ...state,
        getTransferMoney: action.payload,
      }
    },

    getTransferMoneyFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    saveTransferMoney(state) {
      return {
        ...state,
        saveTransferError: null,
      }
    },

    saveTransferMoneySuccess(state, action) {
      return {
        ...state,
        saveTransferMoney: action.payload,
      }
    },

    saveTransferMoneyFailed(state, action) {
      return {
        ...state,
        saveTransferError: action.payload,
      }
    },
    performTransferMoney(state) {
      return {
        ...state,
        performTransferError: null,
      }
    },
    performTransferMoneySuccess(state, action) {
      return {
        ...state,
        performTransferMoney: action.payload,
      }
    },
    performTransferMoneyFailed(state, action) {
      return {
        ...state,
        performTransferError: action.payload,
      }
    },
    deleteMoney(state) {
      return {
        ...state,
      }
    },
    deleteMoneySuccess(state, action) {
      return {
        ...state,
        deleteMoney: action.payload,
      }
    },
    deleteMoneyFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getBalancesMoney(state) {
      return {
        ...state,
      }
    },
    getNdfl(state) {
      return {
        ...state,
      }
    },
    getNdflSuccess(state, action) {
      return {
        ...state,
        ndfl: action.payload,
      }
    },
    getNdflFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getBalancesMoneySuccess(state, action) {
      return {
        ...state,
        balances: action.payload,
      }
    },
    getBalancesMoneyFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    clearTransferMoney() {
      return initialState
    },
    clearPartTransferMoney(state) {
      return {
        ...state,
        error: null,
        saveTransferMoney: null,
        saveTransferError: null,
        performTransferMoney: null,
        performTransferError: null,
      }
    },
  },
})

const { actions, reducer } = transferMoneySlice

export const {
  getTransferMoney,
  getTransferMoneySuccess,
  getTransferMoneyFailed,
  saveTransferMoney,
  saveTransferMoneySuccess,
  saveTransferMoneyFailed,
  performTransferMoney,
  performTransferMoneySuccess,
  performTransferMoneyFailed,
  deleteMoney,
  deleteMoneySuccess,
  deleteMoneyFailed,
  getBalancesMoney,
  getBalancesMoneySuccess,
  getBalancesMoneyFailed,
  clearTransferMoney,
  clearPartTransferMoney,
  getNdfl,
  getNdflSuccess,
} = actions

export default reducer

export const fetchTransferMoneyStartAsync = ({ id }) => async (dispatch) => {
  dispatch(getTransferMoney())
  try {
    const value = await Api.getTransferMoney(id)
    dispatch(getTransferMoneySuccess(value))
  } catch (err) {
    dispatch(getTransferMoneyFailed())
  }
}

export const fetchSaveTransferMoneyStartAsync = ({ data, id = null }) => async (dispatch) => {
  dispatch(saveTransferMoney())
  try {
    if (id) {
      const value = await Api.putTransferMoney(data, id)

      dispatch(saveTransferMoneySuccess(value))
    } else {
      const value = await Api.postTransferMoney(data, false)

      dispatch(saveTransferMoneySuccess(value))
    }
  } catch (err) {
    dispatch(saveTransferMoneyFailed())
    dispatch(getErrorSuccess(err))
  }
}

export const fetchPerformTransferMoneyStartAsync = ({ data, id = null }) => async (dispatch) => {
  dispatch(performTransferMoney())
  try {
    if (id) {
      if (data?.thumbPrint && data?.signedXml) {
        const api = await getCadespluginAPI()
        const signature = await api.signXml(data.thumbPrint, data.signedXml)
        const signedObj = data
        signedObj.signedXml = signature
        const value = await Api.putTransferMoney(signedObj, id, true)

        dispatch(performTransferMoneySuccess(value))
      } else {
        const value = await Api.putTransferMoney(data, id, true)

        dispatch(performTransferMoneySuccess(value))
      }
    } else {
      const value = await Api.postTransferMoney(data, true)

      dispatch(performTransferMoneySuccess(value))
    }
  } catch (err) {
    dispatch(performTransferMoneyFailed())
    dispatch(getErrorSuccess(err))
  }
}

export const fetchDeleteMoneyStartAsync = ({ id }) => async (dispatch) => {
  dispatch(deleteMoney())
  try {
    if (id) {
      const value = await Api.deleteMoney(id)
      dispatch(deleteMoneySuccess(value))
    }
  } catch (err) {
    dispatch(deleteMoneyFailed())
  }
}

export const fetchGetBalancesMoneyStartAsync = ({ filterBody }) => async (dispatch) => {
  dispatch(getBalancesMoney())
  try {
    if (filterBody) {
      const value = await Api.getBalancesMoney(filterBody)
      dispatch(getBalancesMoneySuccess(value))
    }
  } catch (err0) {
    dispatch(getBalancesMoneyFailed())
  }
}

export const fetchGetNdflStartAsync = () => async (dispatch) => {
  dispatch(getNdfl())
  try {
    const value = await Api.getNdfl()
    dispatch(getNdflSuccess(value))
  } catch (err0) {
    dispatch(getBalancesMoneyFailed())
  }
}

import { useState } from 'react'
import useValidation from './useValidation'
import numberWithSpaces from '../utils/Money'

export const checkInputRegExp = /^\d{0,18}(\.\d{0,10})?$/

const useInput = (initialValue, validations) => {
  const [value, setValue] = useState(initialValue)
  const valid = useValidation(value, validations)
  const onChange = (e) => {
    const result = e.target?.value.replace(/ /g, '').replace(',', '.')

    const regExp = /^\d{0,18}(\.\d{0,10})?$/
    // eslint-disable-next-line no-unused-expressions
    regExp.test(String(result).toLowerCase()) ? setValue(numberWithSpaces(result)) : setValue(numberWithSpaces(value))
  }

  return {
    value,
    setValue,
    onChange,
    ...valid,
  }
}

export default useInput

export default function createUri({
  typeUID, accountId, dateFrom, dateTo, statusUID, name, condition,
  searchStr, portfolioId, securityId, storageId, currencyUid, storageUid, marketUID, agreementId, typeName,
}: Record<string, any>) {
  let params = null
  const dateToPlusDay = dateTo ? new Date(dateTo).setDate(new Date(dateTo).getDate() + 1) : null
  if (name === 'ordersMoney' || name === 'ordersDepo') {
    params = {
      dateFrom: dateFrom ? `%5bdateTime%5d+%3e%3d+${dateFrom}` : null,
      dateTo: dateTo ? `%5bdateTime%5d+%3c+${new Date(dateToPlusDay).toISOString().split('T')[0]}` : null,
      accountId: accountId ? `%5bclientPortfolio.id%5d+%3d+${accountId}` : null,
      statusUID: statusUID ? `%5bstatus.uid%5d+%3d+${statusUID}` : null,
      typeUID: typeUID ? `%5btype.uid%5d+%3d+${`%27${typeUID}%27`}` : null,
    }
  } else if (name === 'reports') {
    params = {
      dateFrom: dateFrom ? `%5bdateTime%5d+%3e%3d+${dateFrom}` : null,
      dateTo: dateTo ? `%5bdateTime%5d+%3c+${new Date(dateToPlusDay).toISOString().split('T')[0]}` : null,
      accountId: accountId ? `%5baccount.id%5d+%3d+${accountId}` : null,
      typeUID: typeUID ? `%5btype%5d+%3d+${`%27${typeUID}%27`}` : null,
      typeName: typeName ? `%5btypeName%5d+%3d+${`%27${typeName}%27`}` : null,
      statusUID: statusUID ? `%5bstatus%5d+%3d+${statusUID}` : null,
    }
  } else if (name === 'securities') {
    params = {
      name: `%5bname%5d+contains+%27${searchStr}%27`,
      isin: `%5bisin%5d+contains+%27${searchStr}%27`,
      registerNumber: `%5bregisterNumber%5d+contains+%27${searchStr}%27`,
    }
  } else if (name === 'securitiesUnique') {
    params = {
      uid: typeUID ? `%5Btype.uid%5D+%3D+%27${typeUID}%27` : null,
    }
  } else if (name === 'partners') {
    params = {
      name: `%5bname%5d+contains+%27${searchStr}%27`,
    }
  } else if (name === 'partnersDefault') {
    params = {}
  } else if (name === 'balancesDepo') {
    params = {
      portfolioId: portfolioId ? `PortfolioID=${portfolioId}` : null,
      securityId: securityId ? `SecurityID=${securityId}` : null,
      storageId: storageId ? `StorageID=${storageId}` : null,
    }
  } else if (name === 'balancesMoney') {
    params = {
      portfolioId: portfolioId ? `PortfolioID=${portfolioId}` : null,
      currencyUid: currencyUid ? `CurrencyUid=${currencyUid}` : null,
      storageUid: storageUid ? `StorageUid=${storageUid}` : null,
    }
  } else if (name === 'moneyRequisites') {
    params = {
      accountId: accountId ? `accountID=${accountId}` : null,
      marketUID: marketUID ? `marketUID=${marketUID}` : null,
    }
  } else if (name === 'depoRequisites') {
    params = {
      agreementId: agreementId ? `agreementID=${agreementId}` : null,
    }
  }

  let uri = ''
  Object.keys(params)
    .map((k) => {
      if (params[k]) {
        if (uri !== '') {
          uri += `+${condition}+`
        }
        uri += params[k]
      }
      return k
    })
  return uri
}

import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  scanLink: null,
  error: null,
}

const scanerSlice = createSlice({
  name: 'scaner',
  initialState,
  reducers: {
    getScaner(state) {
      return {
        ...state,
        error: null,
      }
    },
    getScanerSuccess(state, action) {
      return {
        ...state,
        scanLink: action.payload,
        error: null,
      }
    },
    getScanerFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    scanerClean() {
      return initialState
    },
  },
})

const { actions, reducer } = scanerSlice

export const {
  getScaner,
  getScanerSuccess,
  getScanerFailed,
  scanerClean,
} = actions

export default reducer

export const fetchScanerStartAsync = () => async (dispatch) => {
  dispatch(getScaner())
  try {
    await Api.getLink()
  } catch (error) {
    dispatch(getScanerFailed(error?.message))
  }
}

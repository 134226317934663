import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import _ from 'lodash'
import MobileAppBar from '../MobileAppBar/MobileAppBar'
import DesktopAppBar from '../DesktopAppBar/DesktopAppBar'
import useStyles from './MainLayout.style'
import { selectCurrentTheme } from '../../redux/theme/selector'
import LightTheme from '../../theme/LightTheme'
import DarkTheme from '../../theme/DarkTheme'
import { clearNotificationsClone } from '../../redux/notifications/notificationsSlice'
import { selectCurrentNotificationsClone } from '../../redux/notifications/selector'

export const NotificationContext = createContext()

const MainLayout = ({ children, currentUser }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [noticeEl, setNoticeEl] = useState(null)
  const [notificationsCount, setNotificationsCount] = useState(0)
  const [noticeVisibility, setNoticeVisibility] = useState(false)

  const notificationsClone = useSelector(selectCurrentNotificationsClone)

  useEffect(() => {
    if (notificationsClone) {
      const trueNotifications = _.filter(notificationsClone, (item) => item.message !== null)
      setNotificationsCount(trueNotifications.length)
    }
  }, [notificationsClone])

  const shortAgentName = currentUser && currentUser?.agent?.name

  const noticeOpen = (event) => {
    setNoticeEl(event.currentTarget)
    setNoticeVisibility(true)
    setNotificationsCount(0)
    dispatch(clearNotificationsClone())
  }

  const noticeClose = () => {
    setNoticeEl(null)
    setNoticeVisibility(false)
  }

  const currentTheme = useSelector(selectCurrentTheme)
  const isThemeLight = currentTheme === 'light'
  const appliedTheme = createMuiTheme(isThemeLight ? LightTheme : DarkTheme)

  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline />
      <Container
        maxWidth="xl"
        disableGutters
        className={classes.container}
      >
        <Hidden smUp>
          <MobileAppBar
            noticeOpen={noticeOpen}
            noticeCount={notificationsCount}
            agentName={shortAgentName}
            isThemeLight={isThemeLight}
          />
        </Hidden>
        <Hidden xsDown>
          <DesktopAppBar
            noticeOpen={noticeOpen}
            noticeCount={notificationsCount}
            agentName={shortAgentName}
            clientName={currentUser?.client?.shortName}
          />
        </Hidden>
        <main className={classes.main}>
          <div className={classes.content}>
            <NotificationContext.Provider
              value={{ noticeVisibility, noticeEl, noticeClose }}
            >
              {children}
            </NotificationContext.Provider>
          </div>
        </main>
      </Container>
    </ThemeProvider>
  )
}

MainLayout.defaultProps = {
  currentUser: PropTypes.object,
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array]).isRequired,
  currentUser: PropTypes.oneOfType([PropTypes.object]),
}

const mapStateToProps = ({ user: { currentUser } }) => ({
  currentUser,
})

export default connect(mapStateToProps)(MainLayout)

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Media from 'react-media'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import CollapseBar from '../../CollapseBar/CollapseBar'
import LicenceInfosContent from '../collapseContents/licenceInfosContent'
import OperationsPlanContent from '../collapseContents/operationsPlanContent'
import ProfileLastCollapseInfoContent from '../collapseContents/profileLastCollapseInfoContent'
import GeneralInfo from '../otherContents/generalInfo'
import { selectFormsData } from '../../../redux/profilePanel/selector'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'
import useStyles from '../collapseContents/sharedStyles'
import { nextBar, expandedToggle } from '../../../utils/MoveBetweenBars'

export default function GeneralPanel({ handleClickNextPanel, isNeedNextButton }) {
  const classes = useStyles()
  const respGetData = useSelector(selectFormsData)
  const listForms = respGetData?.forms[0]?.list[0]
  const [panel, setPanel] = useState(false)

  const hasLicenses = (listForms?.clientInfo?.management
    && Object.keys(listForms?.clientInfo?.management).length > 0 && (
      listForms?.clientInfo?.management?.directorsBoardMembers?.length > 0
      || listForms?.clientInfo?.management?.generalMeetingMembers?.length > 0
      || listForms?.clientInfo?.management?.hasDirectorsBoard
      || listForms?.clientInfo?.management?.hasGeneralMeeting
      || listForms?.clientInfo?.management?.hasManagementBoard
      || listForms?.clientInfo?.management?.hasSoleExecutive
      || listForms?.clientInfo?.management?.managementBoardMembers?.length > 0
    ))
  || listForms?.clientInfo?.[['licenses'] || []]?.length > 0
  || listForms?.clientInfo?.affiliates?.length > 0
  || listForms?.clientInfo?.registeredCapital
  || listForms?.clientInfo?.registeredCapitalCurrency?.uid

  const hasPlanned = (listForms?.clientInfo?.planned
    && Object.keys(listForms?.clientInfo?.planned).length > 0)
  const hasTrustee = (listForms?.clientInfo?.trustee
    && Object.keys(listForms?.clientInfo?.trustee).length > 0)
  && (listForms?.clientInfo?.trustee?.address
    && Object.keys(listForms?.clientInfo?.trustee?.address).length > 0)

  const mapBar = [
    ['licenses', 'licenses', hasLicenses],
    ['planned', 'planOperations', hasPlanned],
    ['trustee', 'trusted', hasTrustee],
  ]

  const haveNextBar = (currentBar, needBool) => nextBar(
    mapBar, currentBar, needBool,
  )

  const handleChange = (newPanel, needNextPanel = false) => (event, newExpanded) => {
    expandedToggle({
      expanded: panel, setExpanded: setPanel, newPanel, newExpanded, mapBar, needNextPanel,
    })
  }

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box pt="50px">
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <GeneralInfo data={listForms || null} />
            <CollapseBar
              barHeight={50}
              label="Сведения о наличии лицензий (разрешений)"
              secondaryLabel="на осуществление определенного вида деятельности или операций"
              defaultExpanded={hasLicenses}
            >
              <LicenceInfosContent data={listForms || null} />
            </CollapseBar>
            <CollapseBar
              label="Сведения о планируемых операциях"
              defaultExpanded={hasPlanned}
            >
              <OperationsPlanContent data={listForms || null} />
            </CollapseBar>
            <CollapseBar
              barHeight={50}
              label="В отношении трастов и иных иностранных структур без образования юридического лица"
              secondaryLabel="с аналогичной структурой или функцией"
              defaultExpanded={hasTrustee}
            >
              <ProfileLastCollapseInfoContent data={listForms || null} />
            </CollapseBar>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            <GeneralInfo
              className={(!!panel && classes.Phone__hideBlock) || ''}
              data={listForms || null}
            />
            {hasLicenses && (
              <CollapseBar
                expanded={panel === 'licenses'}
                barColor={panel ? '#91B1BF' : '#dfe8ed'}
                onChange={handleChange('licenses')}
                className={classnames(((!!panel && panel !== 'licenses') && classes.Phone__hideBlock), (!!panel && panel === 'licenses') && classnames(classes.Phone__expandedBar, classes.Mobile__expandedChildBar))}
                label="Сведения о наличии лицензий (разрешений)"
                secondaryLabel="на осуществление определенного вида деятельности или операций"
              >
                <LicenceInfosContent
                  data={listForms || null}
                  handleClickNextPanel={handleChange('licenses', true)}
                  isNeedNextButton={haveNextBar('licenses', true)}
                />
              </CollapseBar>
            )}
            {hasPlanned && (
              <CollapseBar
                expanded={panel === 'planOperations'}
                barColor={panel ? '#91B1BF' : '#dfe8ed'}
                onChange={handleChange('planOperations')}
                className={classnames(((!!panel && panel !== 'planOperations') && classes.Phone__hideBlock), (!!panel && panel === 'planOperations') && classnames(classes.Phone__expandedBar, classes.Mobile__expandedPlanOperation))}
                label="Сведения о планируемых операциях"
              >
                <OperationsPlanContent
                  data={listForms || null}
                  handleClickNextPanel={handleChange('planned', true)}
                  isNeedNextButton={haveNextBar('planned', true)}
                />
              </CollapseBar>
            )}
            {hasTrustee && (
            <CollapseBar
              expanded={panel === 'trusted'}
              barColor={panel ? '#91B1BF' : '#dfe8ed'}
              onChange={handleChange('trusted')}
              className={classnames(((!!panel && panel !== 'trusted') && classes.Phone__hideBlock), (!!panel && panel === 'trusted') && classnames(classes.Phone__expandedBar, classes.Mobile__expandedTrusted))}
              label="В отношении трастов и иных иностранных структур без образования юридического лица"
              secondaryLabel="с аналогичной структурой или функцией"
            >
              <ProfileLastCollapseInfoContent
                data={listForms || null}
              />
            </CollapseBar>
            )}
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classnames(classes.bottomSidebar__toggleBtn, !!panel
                  && classes.Phone__hideBlock)}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </>
        )}
      />
    </Box>
  )
}

GeneralPanel.defaultProps = {
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

GeneralPanel.propTypes = {
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

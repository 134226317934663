import useMedia from 'hooks/useMedia'
import React, { FC, memo } from 'react'
import { FormCol, FormRow, TextField } from 'ui/components/atoms'
import { TextArea } from 'ui/components/atoms/TextArea'
import { Field } from 'ui/components/molecules'
import Accordion from 'ui/components/molecules/Accordion'
import { IPlainOperationsDetails } from './types'

const PlainOperationsDetails: FC<IPlainOperationsDetails> = ({ isOpenDefault = true }) => {
  const { isMobile } = useMedia()

  return (
    <Accordion
      padding={isMobile ? '20px' : '32px'}
      variant="plain"
      isOpenDefault={isOpenDefault}
      title="Сведения о планируемых операциях"
      bodyPaddingTop={28}
    >
      <FormCol gap={24}>
        <Field label="Рынки">
          <TextField disabled />
        </Field>

        <Field label="Источники происхождения денежных средств">
          <TextArea disabled minAreaHeight={144} />
        </Field>

        <FormRow tabletRowDirection gap={16} gapTablet={16} mobileRowDirection gapMobile={16}>
          <Field label="Объём операций (шт./мес.)">
            <TextField disabled />
          </Field>

          <Field label="Объём сделок (руб./мес.)">
            <TextField disabled />
          </Field>
        </FormRow>

        <Field label="Виды сделок">
          <TextField disabled />
        </Field>
      </FormCol>
    </Accordion>
  )
}

export default memo(PlainOperationsDetails)

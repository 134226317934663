import { Modal } from '@material-ui/core'
import React, { useEffect } from 'react'
import {
  classes,
  StyledModalInner,
  StyledModalContent,
  StyledModalInfoWrapper,
  StyledModalTitle,
  StyledModalActions,
  StyledModalCancelButton,
  StyledModalOkButton,
  StyledModalClose,
} from './styled'
import { ICustomModalProps } from './types'
import { Button, Icon, Text } from 'ui/components'

export const CustomModal: React.FC<ICustomModalProps> = ({
  errorModal = false,
  title = '',
  open = false,
  handleClose = () => {},
  callbacks,
  actionText = '',
  actionReject,
  actionSuccess,
  infinity = false,
  disableOverlay = false,
  textOk,
  textCancel,
  textClose,
  children,
  closeInterval = 5000,
}) => {
  const withOk = callbacks?.withOk
  const withCancel = callbacks?.withCancel
  const withClose = callbacks?.withClose

  let interval

  const startInterval = () => {
    interval = setInterval(() => {
      handleClose()
    }, closeInterval)
  }

  useEffect(() => {
    if (open && !callbacks && !actionText && !infinity) {
      startInterval()
    }
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [open])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableBackdropClick={!!withOk || !!withCancel || disableOverlay}
    >
      <StyledModalInner unique={!!actionText}>
        <StyledModalContent unique={!!(withOk && withCancel)} error={!!errorModal}>
          <StyledModalClose>
            <Icon name={'close2'} height={12} size={12} onClick={handleClose} />
          </StyledModalClose>
          <StyledModalInfoWrapper>
            <StyledModalTitle>{title}</StyledModalTitle>
          </StyledModalInfoWrapper>
          {actionText && <Text variant={'body1'}>{actionText}</Text>}
          {(callbacks || actionText) && (
            <StyledModalActions>
              {withOk && withCancel && (
                <>
                  <Button onClick={withOk} variant="secondary">
                    {textOk || 'Да'}
                  </Button>
                  <Button onClick={withCancel}>{textCancel || 'Нет'}</Button>
                </>
              )}
              {withClose && <Button onClick={withClose}>{textClose || 'Ок'}</Button>}
              {actionText && actionSuccess && actionReject && (
                <>
                  <Button onClick={actionSuccess} variant="secondary">
                    {textOk || 'Да'}
                  </Button>
                  <Button onClick={actionReject}>{textCancel || 'Нет'}</Button>
                </>
              )}
              {errorModal && (
                <Button variant="negative" onClick={handleClose}>
                  Понятно
                </Button>
              )}
            </StyledModalActions>
          )}
          {children}
        </StyledModalContent>
      </StyledModalInner>
    </Modal>
  )
}

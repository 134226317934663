import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import Media from 'react-media'
import {
  fetchSelectorStartAsync, fetchCodeStartAsync, clearCode,
  fetchPassCodeStartAsync,
  goToPrevStepAsync,
} from '../../redux/user/userSlice'
import Timer from '../../utils/Timer'
import { Button, Text, TextField } from '../../ui/components'
import {
  StyledButton,
  StyledLabel,
  StyledLoginTitle,
  StyledLoginWrapper,
  StyledFieldSecond,
  StyledResetText, StyledTimer, StyledLabelSecond, StyledErrorText,
} from './styled'
import { clearErrors } from '../../redux/errorsModal/errorsModalSlice'

const Code = ({
  fetching, fetchCodeStart,
  isPasswordRecovery, fetchPassCodeStart, setStepArray,
  clearCodeStart, clearModalErrors, arraySteps, setArraySteps,
}) => {
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [showTimer, setShowTimer] = useState(false)
  const [selectorErrorText, setSelectorErrorText] = useState('')
  const dispatch = useDispatch()

  const fetchCodeTimeout = 5 * 60 * 1000

  useEffect(
    () => {
      setShowTimer(true)

      const timer = setTimeout(() => {
        setShowTimer(false)
      }, fetchCodeTimeout)

      return () => {
        clearTimeout(timer)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const goToPrevStep = (action) => {
    dispatch(goToPrevStepAsync({ action }))
  }

  const checkCorrectResponse = (response) => {
    if (response?.Secret?.length) {
      setSelectorErrorText(response?.Secret[0])
      setCodeError(true)
    } else {
      const respData = response?.status
      switch (respData?.status || response?.status) {
        case 401:
          setSelectorErrorText(respData?.title)
          break
        case 403:
          setSelectorErrorText('Вход запрещен')
          break
        default:
          setArraySteps([...arraySteps, 'code'])
          setSelectorErrorText('')
      }
    }
  }

  const handleSubmitMainForm = async (event) => {
    clearModalErrors()
    event.preventDefault()
    if (!code) {
      setCodeError(true)
      return
    }
    if (isPasswordRecovery) {
      await fetchPassCodeStart(code).then((response) => {
        checkCorrectResponse(response)
      })
    } else {
      await fetchCodeStart(code).then((response) => {
        checkCorrectResponse(response)
      })
    }
  }

  const handleResetTimer = async () => {
    setShowTimer(true)
    if (isPasswordRecovery) {
      await fetchPassCodeStart()
    } else {
      await fetchCodeStart()
    }
  }

  const handleChangeInput = (event) => {
    setCode(event.target.value)
    setSelectorErrorText('')
    setCodeError(false)
  }

  const handleClickPrev = () => {
    setStepArray('code')
    goToPrevStep(clearCodeStart)
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  return (
    <>
      <StyledLoginWrapper>
        <StyledLoginTitle>
          <Text
            variant="h1"
            color="primary"
          >
            {isPasswordRecovery ? 'Установка пароля' : 'Вход в личный кабинет'}
          </Text>
        </StyledLoginTitle>

        <form
          onSubmit={handleSubmitMainForm}
        >

          <StyledLabelSecond>
            <Text
              variant="body2"
              color="primary"
            >

              <Media
                query="(min-width: 361px)"
                render={() => (
                  <>
                    Введите код из СМС-сообщения. Если код не пришёл,
                    <br />
                    {'Вы можете запросить новый через '}
                  </>
                )}
              />

              <Media
                query="(max-width: 360px)"
                render={() => (
                  <>
                    {'Введите код из СМС-сообщения. Если код не пришёл, Вы можете запросить новый через '}
                  </>
                )}
              />

              {showTimer ? (
                <StyledTimer>
                  <Timer
                    seconds={60}
                    handleStartTimer={handleStartTimer}
                  />
                </StyledTimer>
              ) : (
                <StyledTimer>
                  0:00
                </StyledTimer>
              )}
            </Text>
          </StyledLabelSecond>

          <StyledLabel>
            <Text
              variant="body2"
              color="secondary"
              hasError={codeError}
            >
              Введите код из СМС
            </Text>
          </StyledLabel>

          <StyledFieldSecond>
            <TextField
              hasError={codeError}
              onChange={handleChangeInput}
              value={code}
              name="code"
              autoComplete="off"
              type="tel"
              formNoValidate
            />
            {codeError && (
            <StyledErrorText>
              <Text
                variant="caption1"
                color="negative"
              >
                {selectorErrorText}
              </Text>
            </StyledErrorText>
            )}
          </StyledFieldSecond>

          <StyledButton>
            <Button
              type="submit"
              disabled={!code || fetching}
            >
              {isPasswordRecovery ? 'Установить пароль' : 'Подтвердить вход'}
            </Button>
          </StyledButton>

          <StyledButton>
            <Button
              type="submit"
              variant="secondary"
              onClick={handleClickPrev}
            >
              Назад
            </Button>
          </StyledButton>

        </form>
        {!showTimer && (
        <StyledResetText>
          <Text
            as="button"
            variant="body2"
            color="accent"
            onClick={handleResetTimer}
          >
            Отправить код ещё раз
          </Text>
        </StyledResetText>
        )}
      </StyledLoginWrapper>
    </>
  )
}

Code.defaultProps = {
  isPasswordRecovery: false,
}

Code.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetchCodeStart: PropTypes.func.isRequired,
  fetchPassCodeStart: PropTypes.func.isRequired,
  isPasswordRecovery: PropTypes.bool,
  setStepArray: PropTypes.func.isRequired,
  clearCodeStart: PropTypes.func.isRequired,
  clearModalErrors: PropTypes.func.isRequired,
  arraySteps: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setArraySteps: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user: { isFetching, currentClient } }) => ({
  fetching: isFetching,
  currentClient,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCodeStart: (code) => dispatch(fetchCodeStartAsync({ code })),
  fetchClientStart: (clientId) => dispatch(fetchSelectorStartAsync({ clientId })),
  fetchPassCodeStart: (code) => dispatch(fetchPassCodeStartAsync({ code })),
  clearCodeStart: () => dispatch(clearCode()),
  clearModalErrors: () => dispatch(clearErrors()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Code)

import { styled } from 'ui/theme'
import { ACCORDION_VARIANTS_LIST, AccordionVariants, IAccordionContainer, TTitleType } from './types'
import { css } from "styled-components";

export const AccordionContainer = styled.div<IAccordionContainer>`
  padding: 24px 24px;

  ${({ withShadow }) => withShadow && 'box-shadow: 0 0 20px 2px rgba(49, 58, 78, 0.06)'};
  border-radius: 8px;

  @media ${({ theme: { breakpoint } }) => breakpoint.xs} {
    padding: ${({ padding }) => (typeof padding === 'undefined' ? '24px' : padding)};
  }

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    padding: ${({ padding }) => (typeof padding === 'undefined' ? '20px' : padding)};
  }
`

export const WrapperHeading = styled.div<{ type: TTitleType }>`
  display: flex;
  justify-content: ${({ type }) => type === 'standart' && 'space-between'};
  align-items: center;
  //height: 52px;
  cursor: pointer;
`

export const WrapperBody = styled.div<{
  isOpen?: boolean
  isAnimated?: boolean
}>`
  overflow: hidden;

  transition: ${({ isAnimated }) => (isAnimated ? '0.2s' : 0)} linear;
  transition-property: max-height, padding-top, padding-bottom;
`

export const WrapperBodyContent = styled.div<{ variant?: AccordionVariants; paddingTop?: number }>`
  padding-top: ${({ paddingTop }) => (typeof paddingTop === 'undefined' ? 20 : paddingTop)}px;

  display: flex;
  flex-direction: column;

  ${({ variant }) => variant === ACCORDION_VARIANTS_LIST.PLAIN && 'gap: 24px;'}
`

export const Title = styled.p<{
  type: TTitleType
  maxWidth?: number
  textVariant?: string
  fontType?: string
}>`
  margin: 0;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`}

  color: ${({ theme }) => theme.color.text.primary};

  ${({ theme: { font } }) => font.h4}

  ${({ type, theme: { color } }) =>
  type === 'bordered' &&
  `
    padding-left: 12px;
    border-left: 2px solid ${color.text.accent};
    `}

  cursor: pointer;

  ${({ fontType }) => fontType}

  @media ${({ theme: { breakpoint } }) => breakpoint?.xxs} {
    font-weight: 600;
  }
`

export const DataArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const DataWrapper = styled.div<{layout: string[]; isDesktop: boolean}>`
  display: grid;
  width: 100%;

  grid-template-columns: ${({ isDesktop, layout }) =>
    isDesktop
      ? layout?.join(' ')
      : '140px 1fr'};

  ${({isDesktop, layout}) => isDesktop
    ? css`
      grid-template-columns: ${layout?.join(' ')};
      height: 52px;
    `
    : css`
      grid-template-columns: 140px 1fr;
      gap: 8px;
    `
  }
`

export const DataMobileTitle = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
  ${({ theme }) => theme.font.caption1};
  padding-right: 0;
  ${({ theme }) => `
    @media ${theme.breakpoint.md} {
      padding: ${({ theme }) => theme.spacing.lg};
    }
  `}
`

export const DataMobileContent = styled.div`
  flex: 1;
  overflow: hidden;
  ${({ theme }) => theme.font.body1_lg};
`

export const Data = styled.div<{ isPositive?: boolean | undefined }>`
  display: flex;
  align-items: center;

  color: ${({ isPositive, theme }) =>

  isPositive === undefined
    ? theme.color.text.primary
    : theme.color.text[isPositive ? 'positive' : 'negative']};

  ${({ theme }) => theme.font.body2};
`

export const Currency = styled.p`
  margin: 0 4px;

  font-family: system-ui;
`

export const Chevron = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;

  width: 20px;
  margin-left: 10px;

  transition: transform 0.3s ease-in;
  transform: rotate(${({ isOpen }) => (isOpen ? '180' : '0')}deg);
`

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  popover: {
    '& .MuiPopover-paper': {
      overflow: 'visible',
      maxWidth: 360,
      minWidth: 205,
      maxHeight: 320,
      marginTop: 15,
      marginLeft: 27,
      borderRadius: 0,
      border: '1px solid #91B1BF',
      boxShadow: 'none',
      listStyle: 'none',
      backgroundColor: theme.palette.background.default,
      '@media (max-width: 576px)': {
        maxWidth: 225,
        minWidth: 225,
        marginLeft: -14,
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: -19,
        left: -1,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '18px 0 0 18px',
        borderColor: 'transparent transparent transparent #91B1BF',
        '@media (max-width: 576px)': {
          left: 'auto',
          right: -1,
          borderWidth: '0 0 18px 18px',
          borderColor: 'transparent transparent #91B1BF transparent',
        },
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: -17,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '17px 0 0 17px',
        borderColor: `transparent transparent transparent ${theme.palette.background.default}`,
        '@media (max-width: 576px)': {
          right: 0,
          borderWidth: '0 0 17px 17px',
          borderColor: `transparent transparent ${theme.palette.background.default} transparent`,
        },
      },
    },
    '& .MuiList-root': {
      overflowY: 'auto',
      maxWidth: 360,
      maxHeight: 150,
      padding: '20px 17px',
      '@media (max-width: 576px)': {
        padding: 16,
      },
      '&::-webkit-scrollbar': {
        '@media (pointer: fine)': {
          width: 6,
          backgroundColor: '#ffffff',
          borderLeft: '1px solid #91B1BF',
          borderRight: 'none',
          borderTop: 'none',
          borderBottom: 'none',
        },
      },
      '&::-webkit-scrollbar-thumb': {
        '@media (pointer: fine)': {
          backgroundColor: '#91B1BF',
          border: 'none',
          cursor: 'pointer',
        },
      },
      '& .MuiListItem-root': {
        position: 'relative',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 15,
        '&:before': {
          content: '""',
          position: 'absolute',
          left: 0,
          width: 4,
          height: 4,
          borderRadius: '50%',
          backgroundColor: theme.palette.type === 'light' ? '#000' : theme.palette.text.secondary,
          '@media (max-width: 576px)': {
            top: 7,
          },
        },
      },
      '& .MuiListItemText-root': {
        margin: 0,
      },
      '& .MuiTypography-root': {
        fontSize: 12,
        lineHeight: '18px',
        color: theme.palette.type === 'light' ? '#000' : theme.palette.text.secondary,
      },
    },
  },
}))

export default useStyles

import { request } from './Request'
import { asyncApiURL, syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getCategories() {
    return request(asyncApiURL, endpoints.portfolio.categories)
  },
  getTotal() {
    return request(asyncApiURL, endpoints.portfolio.total)
  },
  getTime() {
    return request(asyncApiURL, endpoints.portfolio.time)
  },
  getValuations() {
    return request(asyncApiURL, endpoints.portfolio.valuations)
  },
  getValuations2() {
    return request(asyncApiURL, endpoints.portfolio.valuations2)
  },
  getValuations3() {
    return request(asyncApiURL, endpoints.portfolio.valuations3)
  },
  getPortfolio() {
    return request(syncApiURL, endpoints.portfolio.portfolio)
  },
  getInstruments() {
    return request(asyncApiURL, endpoints.portfolio.instruments)
  },
  getNdfl() {
    return request(syncApiURL, endpoints.portfolio.ndfl)
  },
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EpsTable from './EpsTable'
import {
  fetchBrokerFilterStartAsync,
  fetchBrokerStartAsync,
} from '../../../../redux/reports/reportsSlice'
import {
  selectBrokerReportsDataTable,
} from '../../../../redux/reports/selector'
import { rowsPerPage } from '../../../../config/constants'
import FilterForm from './FilterForm'

const statuses = [
  'Все',
  'Подписано',
  'Заказано клиентом',
  'Исполнено',
  'Отклонено клиентом',
  'На подписи',
]

const LogBroker = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)

  const reportsBrokerData = useSelector(selectBrokerReportsDataTable)

  const fetchBrokerStart = () => {
    dispatch(fetchBrokerStartAsync())
  }

  const fetchBrokerFilterStart = () => {
    dispatch(fetchBrokerFilterStartAsync())
  }

  useEffect(() => {
    fetchBrokerStart()
  }, [])

  useEffect(() => {
    if (reportsBrokerData?.references?.accounts) {
      const currentReportsLength = reportsBrokerData?.reports?.length
      if (page * rowsPerPage > currentReportsLength) {
        setPage(0)
      }
    }
  }, [reportsBrokerData])

  return (
    <>
      <FilterForm
        accounts={reportsBrokerData?.references?.accounts || []}
        fetchFilterStart={fetchBrokerFilterStart}
        tableType="Broker"
        statuses={statuses}
      />
      <EpsTable
        page={page}
        setPageIner={setPage}
        reportsArrayData={reportsBrokerData?.reports || []}
        brokerFilter={fetchBrokerFilterStart}
      />
    </>
  )
}

export default LogBroker

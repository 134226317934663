import { useEffect } from 'react'

const useDisableBodyScroll = (open) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.setAttribute('style', `overflow: ${open ? 'hidden !important' : 'hidden auto !important'}`)
    }, 1)
    return () => {
      clearTimeout(timeout)
    }
  }, [open])
}

export default useDisableBodyScroll

export const removeKeysFromObject = (object, keys) => {
  if (Array.isArray(keys)) {
    return keys.reduce((acc, key) => {
      const { [key]: _ , ...restKeys } = acc;
      return restKeys;
    }, object)
  }

  if (typeof keys === 'string') {
    const { [keys]: _ , ...restKeys } = object
    return restKeys
  }

  return object
}


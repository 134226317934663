import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import userReducer from './user/userSlice'
import portfolioReducer from './portfolio/portfolioSlice'
import accessReducer from './access/accessSlice'
import scanerReducer from './scaner/scanerSlice'
import feedbackReducer from './feedback/feedbackSlice'
import fileReducer from './file/fileSlice'
import connectionReducer from './connection/connectionSlice'
import notificationsReducer from './notifications/notificationsSlice'
import themeReducer from './theme/themeSlice'
import historyReducer from './history/historySlice'
import reportsReducer from './reports/reportsSlice'
import formsReducer from './profilePanel/profilePanelSlice'
import metadataReducer from './metadata/metadataSlice'
import transferMoneyReducer from './transfers/money/transferMoneySlice'
import outputMoneyReducer from './transfers/money/output/outputMoneySlice'
import securitiesReducer from './securities/securitiesSlice'
import transferSecurityReducer from './transfers/security/securityMoneySlice'
import heartbeatsReducer from './heartbeats/heartbeatsSlice'
import errorReducer from './error/errorSlice'
import errorsModalReducer from './errorsModal/errorsModalSlice'
import investmentsNoticesReducer from './investmentsNotices/investmentsNoticesSlice'
import tradesReducer from './trades/tradesSlice'
import partnersReducer from './partners/partnersSlice'
import documentsBrokerageServicesReducer from './documents/brokerageServices/documentsBrokerageServicesSlece'
import uiReducer from './ui/uiSlice'
import feedbackNew from './feedback/feedbackApi'
import { queryApi } from './queryApi'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    // 'notifications',
    'theme',
    'portfolio',
    'scaner',
    'connection',
    'feedback',
    'file',
    'history',
    'metadata',
    'forms',
    'transferMoney',
    'outputMoney',
    'securities',
    'transferSecurity',
    'heartbeats',
    'error',
    'errorsModal',
    'investmentsNotices',
    'trades',
    'partners',
    'documentsBrokerageServices',
    'documents',
  ],
  blacklist: [queryApi.reducerPath],
}

const appReducer = combineReducers({
  user: userReducer,
  portfolio: portfolioReducer,
  access: accessReducer,
  scaner: scanerReducer,
  notifications: notificationsReducer,
  feedback: feedbackReducer,
  file: fileReducer,
  history: historyReducer,
  forms: formsReducer,
  transferMoney: transferMoneyReducer,
  outputMoney: outputMoneyReducer,
  theme: themeReducer,
  connection: connectionReducer,
  reports: reportsReducer,
  metadata: metadataReducer,
  securities: securitiesReducer,
  transferSecurity: transferSecurityReducer,
  heartbeats: heartbeatsReducer,
  error: errorReducer,
  errorsModal: errorsModalReducer,
  investmentsNotices: investmentsNoticesReducer,
  trades: tradesReducer,
  partners: partnersReducer,
  documentsBrokerageServices: documentsBrokerageServicesReducer,
  ui: uiReducer,
  feedbackNew,
  [queryApi.reducerPath]: queryApi.reducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'user/userLogout') {
    // eslint-disable-next-line no-param-reassign
    localStorage.setItem('theme', state.theme.current)
    state = {}
  } else if (action.type === 'user/userLoginSuccess') {
    state.theme = {
      current: localStorage.getItem('theme') || 'light',
    }
  }

  return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer)

import React from 'react'
import Tabs from '../../components/Tabs/Tabs'
import { ReactComponent as AssetMoneyIcon } from '../../assets/images/assetMoneyIcon.svg'
import { ReactComponent as AdministrativeMoneyIcon } from '../../assets/images/administrativeMoneyIcon.svg'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import BasicInformations from '../../components/ProfilePanels/BasicInformations'
import AdditionalInformations from '../../components/ProfilePanels/AdditionalInformations'
import PageHeading from '../../components/PageHeading/PageHeading'
import useWindowSize from '../../hooks/useWindows'

export default function Profile() {
  const { windowWidth } = useWindowSize()

  const tabLinks = [
    {
      key: 'eb1a5cff-7bd5-414d-d76e7e7a470b',
      link: 'basicinfo',
      label: 'Основные сведения',
      icon: windowWidth > 576 ? <AssetMoneyIcon /> : <TransfersIcon />,
      component: BasicInformations,
    },
    {
      key: '9f14e52f-acd9-4751-9b6f2fda0d1b',
      link: 'additionalInfo',
      label: 'Дополнительные сведения',
      icon: windowWidth > 576 ? <AdministrativeMoneyIcon /> : <TransfersIcon />,
      component: AdditionalInformations,
    },
  ]
  return (
    <>
      <PageHeading text="Профиль" />
      <Tabs
        hideTabsOnUp={577}
        tabLinks={tabLinks}
      />
    </>
  )
}

import React from 'react'
import { useSize } from '../../../../../../hooks/useSize'
import { useTheme } from '../../../../../theme'
import AccordionRow from '../AccordionRow'
import {
  StyledAccordionTable,
  TableHeadingContainer,
  TableHeadingTitle,
} from './styled'
import ChildrenTable from "../ChildrenTable";

export const AccordionTable = ({
  rows,
  columns,
  childrenColumns,
  layout,
  childrenLayout,
  onRowClick,
}: any) => {
  const size = useSize()
  const theme = useTheme()

  const isTablet = theme.bp.isSM(size.width);
  const isMobile = theme.bp.isXS(size.width);


  return (
    <div>
      {!(isTablet || isMobile) && (
        <TableHeadingContainer
          layout={layout ? layout : (columns || []).map(() => '1fr')}
        >
          {columns.map((item) => (
            <TableHeadingTitle key={item.title}>{item.title}</TableHeadingTitle>
          ))}
        </TableHeadingContainer>
      )}
      <StyledAccordionTable>
        {(rows || []).map((currentRow) => (
          <AccordionRow
            key={currentRow.id}
            columns={columns}
            row={currentRow}
            layout={layout}
            showChevron={currentRow?.files?.length}
            onRowClick={onRowClick}
          >
            {!!childrenColumns && (
              <ChildrenTable
                layout={childrenLayout}
                columns={childrenColumns}
                rows={currentRow.files}
              />
            )}
          </AccordionRow>
        ))}
      </StyledAccordionTable>
    </div>
  )
}

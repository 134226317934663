import { styled } from "ui/theme";
import { IMobileTableSectionProps } from "./types";

export const StyledMobileTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
`

export const StyledMobileTableRow = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.bg.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  min-height: 52px;
  padding: 5px 10px;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

export const StyledMobileTableSection = styled.div<IMobileTableSectionProps>`
  display: flex;
  align-items: center;
  ${({ align = 'left', row = false, theme }) => `
    ${row ? `
        align-items: center;
        justify-content: ${align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center"};
      ` : `
        justify-content: center;
        align-items: ${align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center"};
      `
    }
    flex-direction: ${row ? "row" : "column"};
    gap: ${row ? theme.spacing.xs : theme.spacing.xxs};
  `}
`

export const StyledMobileTableSectionCell = styled.div<IMobileTableSectionProps>`
  display: flex;
  flex-direction: column;
  ${({ align = 'left', theme }) => `
    align-items: ${align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center"};
    gap: ${theme.spacing.xxs};
  `}
`
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterForm from './FilterForm'
import EpsTable from './EpsTable'
import {
  fetchDepoFilterStartAsync,
  fetchDepoStartAsync,
} from '../../../../redux/reports/reportsSlice'
import { rowsPerPage } from '../../../../config/constants'
import { selectDepoReportsDataTable } from '../../../../redux/reports/selector'

const LogDeposit = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)

  const reportsDepoData = useSelector(selectDepoReportsDataTable)

  const fetchDepoStart = () => {
    dispatch(fetchDepoStartAsync())
  }
  const fetchDepoFilterStart = (reset) => {
    dispatch(fetchDepoFilterStartAsync(reset))
  }

  useEffect(() => {
    fetchDepoStart()
  }, [])

  useEffect(() => {
    if (reportsDepoData?.reports?.length) {
      const currentReportsLength = reportsDepoData.reports.length
      if (page * rowsPerPage > currentReportsLength) {
        setPage(0)
      }
    }
  }, [reportsDepoData])

  return (
    <>
      <FilterForm
        accounts={reportsDepoData?.references?.accounts || []}
        fetchFilterStart={fetchDepoFilterStart}
        tableType="Depo"
      />
      <EpsTable
        page={page}
        setPageIner={setPage}
        reportsArrayData={reportsDepoData?.reports || []}
        brokerFilter={fetchDepoFilterStart}
      />
    </>
  )
}

export default LogDeposit

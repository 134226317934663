import React from 'react';
import { Icon } from '../Icon';
import { StyledCheckbox, StyledInput } from './styled';
import { ICheckboxProps } from './types';

export const Checkbox: React.FC<ICheckboxProps> = ({ checked, disabled, size = 16, onChange = () => null }) => {
  let Component = <Icon name={'checkbox'} size={size} />
  if (checked) {
    Component = <Icon name={'checkbox-checked'} size={size} />;
  }
  return <StyledCheckbox disabled={disabled} checked={checked}>
    {Component}
    <StyledInput type={'checkbox'} onChange={onChange} checked={checked}/>
  </StyledCheckbox>;
};

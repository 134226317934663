import React from 'react'
import CustomAutocompleteOrig from './CustomAutocomplete'
import { StyledGlobal, StyledWrapper } from './styled'

type IProps = Partial<React.ComponentProps<typeof CustomAutocompleteOrig>>

export const CustomAutocomplete: React.FC<IProps> = ({
  variant = undefined,
  label = undefined,
  name = undefined,
  color = undefined,
  size = undefined,
  error = undefined,
  errorText = undefined,
  inputValue = undefined,
  onInputChange = undefined,
  onChange = undefined,
  searchList = undefined,
  disabled = undefined,
  unique = undefined,
  specialOptions = undefined,
  helperText = undefined,
  defaultShowList = undefined,
  defaultValue = undefined,
  required = undefined,
  uniqueSearchList = undefined,
  ...props
}) => {
  return (
    <StyledWrapper>
      <StyledGlobal />
      <CustomAutocompleteOrig
        variant={variant}
        label={label}
        name={name}
        color={color}
        size={size}
        error={error}
        errorText={errorText}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={onChange}
        searchList={searchList}
        disabled={disabled}
        unique={unique}
        specialOptions={specialOptions}
        helperText={helperText}
        defaultShowList={defaultShowList}
        defaultValue={defaultValue}
        required={required}
        uniqueSearchList={uniqueSearchList}
        {...props}
      />
    </StyledWrapper>
  )
}

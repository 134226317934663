import useMedia from 'hooks/useMedia'
import React, { FC, memo } from 'react'
import { FormCol, TextField } from 'ui/components/atoms'
import { Field } from 'ui/components/molecules'
import { AccordionProps } from 'ui/components/molecules/Accordion/type'
import AddressInformation from '../../../AdditionalInfo/Beneficiares/AddressInformation'
import ContactInformation from '../../../AdditionalInfo/Beneficiares/ContactInformation'
import Identification from '../../../AdditionalInfo/Beneficiares/Identification'
import PlainOperationsDetails from '../../CommonTab/Blocks/PlainOperationsDetails'
import Basic from './Basic'
import { IJurInfo } from './types'

const JurInfo: FC<IJurInfo> = ({ customer, clientInfo }) => {
  const { isMobile } = useMedia()

  const {
    fullName,
    address,
    postAddress,
    emails,
    phones,
    faxes,
    otherContactInfo,
    identity,
    legalResidence,
    migrationCard,
  } = customer || {}

  const accodionProps: AccordionProps = {
    withShadow: true,
    padding: isMobile ? '20px' : '32px',
    titleType: 'standart',
  }

  return (
    <FormCol gap={16}>
      <Field label="Фамилия, имя, отчество (при наличии последнего)">
        <TextField disabled value={fullName} />
      </Field>

      <Basic customer={customer} clientInfo={clientInfo} />

      <Identification
        identity={identity}
        legalResidence={legalResidence}
        migrationCard={migrationCard}
        {...accodionProps}
      />

      <AddressInformation title="Адрес регистрации" addressData={address} {...accodionProps} />

      <AddressInformation title="Почтовый адрес" addressData={postAddress} {...accodionProps} />

      <ContactInformation
        emails={emails}
        phones={phones}
        faxes={faxes}
        otherContact={otherContactInfo}
        {...accodionProps}
      />

      <PlainOperationsDetails isOpenDefault={false} />
    </FormCol>
  )
}

export default memo(JurInfo)

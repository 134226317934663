import { createSlice } from '@reduxjs/toolkit'
import getCadespluginAPI from 'async-cadesplugin'
import Api from '../../../api/Api'

const initialState = {
  error: null,
  documentTypes: [],
  documents: [],
  jsonFields: [],
  jsonFieldsForType: [],
  postDocumentError: null,
  documentBrokerageServices: null,
  saveDocumentError: null,
  saveDocument: null,
  performDocument: null,
  performDocumentError: null,
}

const documentsBrokerageServicesSlece = createSlice({
  name: 'documentsBrokerageServices',
  initialState,
  reducers: {
    getDocumentsBrokerageTypesServices(state) {
      return {
        ...state,
        error: null,
      }
    },
    getDocumentsBrokerageServicesTypesSuccess(state, action) {
      return {
        ...state,
        documentTypes: action.payload.parsedResponse,
      }
    },
    getDocumentsBrokerageServicesTypesFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getDocumentsSuccess(state, action) {
      return {
        ...state,
        documents: action.payload.documents,
      }
    },
    getDocumentsFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getJsonFieldsSuccess(state, action) {
      return {
        ...state,
        jsonFields: action.payload.parsedResponse,
      }
    },
    getJsonFieldsFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getJsonFieldsForTypeSuccess(state, action) {
      return {
        ...state,
        jsonFieldsForType: action.payload.parsedResponse,
      }
    },
    getJsonFieldsForTypeFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    postDocument(state) {
      return {
        ...state,
        postDocumentError: null,
      }
    },
    postDocumentSuccess(state, action) {
      return {
        ...state,
        documents: action.payload,
      }
    },
    postDocumentFailed(state, action) {
      return {
        ...state,
        postDocumentError: action.payload,
      }
    },
    getDocumentBrokerageServicesSuccess(state, action) {
      return {
        ...state,
        documentBrokerageServices: action.payload.document,
      }
    },
    getDocumentBrokerageServicesFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    saveDocument(state) {
      return {
        ...state,
        saveDocumentError: null,
      }
    },
    saveDocumentError(state, action) {
      return {
        ...state,
        saveDocumentError: action.payload,
      }
    },
    saveDocumentSuccess(state, action) {
      return {
        ...state,
        saveDocument: action.payload,
      }
    },
    performDocument(state) {
      return {
        ...state,
        performDocumentError: null,
      }
    },
    performDocumentError(state, action) {
      return {
        ...state,
        performDocumentError: action.payload,
      }
    },
    performDocumentSuccess(state, action) {
      return {
        ...state,
        performDocument: action.payload,
      }
    },
    clearDocumentStore() {
      return initialState
    },
    clearDocument(state) {
      return {
        ...state,
        error: null,
        saveDocumentError: null,
        saveDocument: null,
        performDocument: null,
        performDocumentError: null,
      }
    },
  },
})

const { actions, reducer } = documentsBrokerageServicesSlece

export const {
  getDocumentsBrokerageTypesServices,
  getDocumentsBrokerageServicesTypesSuccess,
  getDocumentsBrokerageServicesTypesFailed,
  getDocumentsSuccess,
  getDocumentsFailed,
  getJsonFieldsSuccess,
  getJsonFieldsFailed,
  getJsonFieldsForTypeSuccess,
  getJsonFieldsForTypeFailed,
  postDocument,
  postDocumentSuccess,
  postDocumentFailed,
  getDocumentBrokerageServicesSuccess,
  getDocumentBrokerageServicesFailed,
  saveDocument,
  saveDocumentError,
  saveDocumentSuccess,
  performDocument,
  performDocumentError,
  performDocumentSuccess,
  clearDocumentStore,
  clearDocument,
} = actions

export default reducer

export const fetchDocumentsBrokerageServicesTypesStartAsync = () => async (dispatch) => {
  dispatch(getDocumentsBrokerageTypesServices())
  try {
    await Api.getDocumentsBrokerageServicesTypes()
  } catch (err) {
    dispatch(getDocumentsBrokerageServicesTypesFailed())
  }
}
export const fetchDocumentBrokerageServicesStartAsync = ({ id }) => async (dispatch) => {
  dispatch(getDocumentsBrokerageTypesServices())
  try {
    await Api.getDocumentBrokerageServices(id)
  } catch (err) {
    dispatch(getDocumentBrokerageServicesFailed())
  }
}

export const fetchDocumentsStartAsync = () => async (dispatch) => {
  dispatch(getDocumentsBrokerageTypesServices())
  try {
    const value = await Api.getExchangeRequests()
    dispatch(getDocumentsSuccess(value.data))
  } catch (err) {
    dispatch(getDocumentsFailed())
  }
}

export const fetchJsonFieldsAsync = () => async (dispatch) => {
  dispatch(getDocumentsBrokerageTypesServices())
  try {
    await Api.getExchangeRequestsJsonFields()
  } catch (err) {
    dispatch(getJsonFieldsFailed())
  }
}
export const fetchJsonFieldsForTypeAsync = (documentType) => async (dispatch) => {
  dispatch(getDocumentsBrokerageTypesServices())
  try {
    await Api.getJsonFieldsForType(documentType)
  } catch (err) {
    dispatch(getJsonFieldsForTypeFailed())
  }
}

export const fetchPostDocumentAsync = ({ data, id = null }) => async (dispatch) => {
  dispatch(postDocument())
  try {
    if (id) {
      if (data?.thumbPrint && data?.signedXml) {
        const api = await getCadespluginAPI()
        const signature = await api.signXml(data.thumbPrint, data.signedXml)
        const signedObj = data
        signedObj.signedXml = signature
        await Api.putDocument(signedObj, id, true)
      } else {
        await Api.putDocument(data, id, true)
      }
    } else {
      await Api.postDocument(data, true)
    }
  } catch (err) {
    dispatch(postDocumentFailed())
  }
}

export const fetchPostSaveDocumentAsync = ({ data, id = null }) => async (dispatch) => {
  dispatch(saveDocument())
  try {
    if (id) {
      await Api.putDocument(data, id)
    } else {
      await Api.postDocument(data, false)
    }
  } catch (error) {
    dispatch(saveDocumentError())
  }
}

import { createGlobalStyle, useTheme as useThemeStyled } from 'styled-components'
import baseStyled, { ThemedStyledInterface } from 'styled-components'

export const breakpoint = {
  /**
   * (max-width: 360px)
   */
  xxs: '(max-width: 500px)',

  /**
   * (min-width: 321px) and (max-width: 1023px)
   */
  xs: '(max-width: 1023px)',

  /**
   * (min-width: 1024px) and (max-width: 1439px)
   */
  sm: '(min-width: 1024px) and (max-width: 1439px)',

  /**
   * (min-width: 1440px)
   */
  md: '(min-width: 1440px)',
  xssm: '(min-width: 320px) and (max-width: 1439px)',
  smmd: '(min-width: 1024px)',
}

export const bp = {
  isXXS: (value: number) => value <= 500,
  isXS: (value: number) => value >= 320 && value < 1024,
  isSM: (value: number) => value >= 1024 && value < 1440,
  isMD: (value: number) => value >= 1440,
}

export const borderRadius = {
  xs: '4px',
  sm: '8px',
  md: '12px',
}

export const spacing = {
  hair: '2px',
  xxs: '4px',
  xs: '8px',
  s: '10px',
  sm: '12px',
  smd: '16px',
  md: '20px',
  lg: '24px',
  xl: '32px',
  xxl: '40px',
  xxxl: '52px',
}

export const fontWeight = {
  normal: 400,
  semibold: 600,
  bold: 700,
}

export const font = {
  h1: `
    font-size: 28px;
    line-height: 40px;
    font-weight: ${fontWeight.bold};
  `,
  h2: `
  font-size: 24px;
  line-height: 29px;
  font-weight: ${fontWeight.bold};
`,
  h3: `
    font-size: 18px;
    line-height: 24px;
    font-weight: ${fontWeight.bold};
  `,
  h4: `
    font-size: 16px;
    line-height: 24px;
    font-weight: ${fontWeight.bold};
  `,
  body1: `
    font-size: 14px;
    line-height: 20px;
    font-weight: ${fontWeight.semibold};
  `,
  body2: `
    font-size: 14px;
    line-height: 18px;
    font-weight: ${fontWeight.normal};
  `,
  body3: `
    font-size: 16px;
    line-height: 20px;
    font-weight: ${fontWeight.normal};
  `,
  body1_lg: `
    font-size: 16px;
    line-height: 24px;
    font-weight: ${fontWeight.semibold};
  `,
  body2_lg: `
    font-size: 16px;
    line-height: 24px;
    font-weight: ${fontWeight.normal};
  `,
  caption1: `
    font-size: 12px;
    line-height: 16px;
    font-weight: ${fontWeight.normal};
  `,
  caption2: `
    font-size: 12px;
    line-height: 16px;
    font-weight: ${fontWeight.semibold};
  `,
}

export const color = {
  bg: {
    primary: '#FFFFFF',
    secondary: '#F4F6FC',
    accent: '#313A4E',
    tertiary: '#1D3E6F',
    funBlue: '#1670AA',
    positive: '#EBFDFD',
    negative: '#FCEDED',
    positiveTransparent: 'rgba(8, 145, 112, 0.1)',
    negativeTransparent: 'rgba(202, 89, 100, 0.1)',
  },
  text: {
    primary: '#0D0D22',
    secondary: '#8C93A8',
    tertiary: '#D1D6E1',
    inverted: '#FFFFFF',
    positive: '#34AA88',
    negative: '#B11B1B',
    accent: '#2C96DA',
  },
  button: {
    primary: '#2C96DA',
    hover: '#057DC6',
  },
  graphics: {
    primary: '#1D3E6F',
    secondary: '#0D0D22',
    tertiary: '#8C93A8',
    disabled: '#D1D6E1',
    inverted: '#FFFFFF',
    processing: '#EFF3FE',
  },
  border: {
    default: '#D1D6E1',
    default40: 'rgba(209, 214, 225, 0.4)',
    hover: '#B2B7C2',
    negative: '#B11B1B',
  },
}

const transition = {
  common: 'all 0.2s ease-in-out',
}

export const theme = {
  color,
  font,
  fontWeight,
  borderRadius,
  breakpoint,
  bp,
  spacing,
  transition,
}

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), local('Open Sans'),
    url(${require('../assets/fonts/OpenSans-Regular.ttf')}) format('ttf'),
    url(${require('../assets/fonts/OpenSans-Regular.woff')}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), local('Open Sans'),
    url(${require('../assets/fonts/OpenSans-SemiBold.ttf')}) format('ttf'),
    url(${require('../assets/fonts/OpenSans-SemiBold.woff')}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), local('Open Sans'),
    url(${require('../assets/fonts/OpenSans-Bold.ttf')}) format('ttf'),
    url(${require('../assets/fonts/OpenSans-Bold.woff')}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
* {
  box-sizing: border-box;
}
body {
  font-family: 'Open Sans', sans-serif;
  color: ${theme.color.text.primary};
  padding: 0;
  margin: 0;
}
h1,h2,h3,h4,h5 {
  margin: 0;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: orange;
  background-color: ${theme.color.graphics.disabled};
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: ${theme.color.graphics.primary};
  border-radius: 4px;
}

.ant-popover-inner-content {
  padding: 0;
}
.ant-popover-placement-bottomRight {
  padding-top: 0 !important;
}

.Toastify__toast {
  border: 1px solid ${theme.color.text.accent};
  box-shadow: none;
  border-radius: 8px;
  padding: 20px 16px;
}
.Toastify__close-button {
  display: none;
}
.Toastify__toast-body {
  ${theme.font.body1}
  color: ${theme.color.text.primary};
  font-family: 'Open Sans';
  padding: 0;
  margin: 0;
}
`

export type Theme = typeof theme
export const styled = baseStyled as ThemedStyledInterface<Theme>
export function useTheme(): Theme {
  return useThemeStyled() as Theme
}

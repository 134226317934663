import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import LogIn from '../../components/loginRedesign/login'

import { clearUser } from '../../redux/user/userSlice'
import { StyledLoginLeft, StyledLoginRight, StyledLoginWrapper } from './styled'
import { theme } from '../../ui/theme'
import svgLogo from './assets/loginLogo.svg'
import { StyledInfoBlock, StyledLoginContainer } from '../../components/loginRedesign/styled'
import { CustomModal, Text } from '../../ui/components'
import { selectReset } from '../../redux/user/selector'

const Login = ({ clearUserStart }) => {
  const dispatch = useDispatch()
  const getReset = useSelector(selectReset)

  const [passwordResetSuccessModal, setPasswordResetSuccessModal] = useState(false)

  useEffect(() => {
    if (getReset) {
      setPasswordResetSuccessModal(true)
    }
  }, [getReset])

  const handleCloseSuccessReset = () => {
    setPasswordResetSuccessModal(false)
  }

  dispatch(clearUserStart())

  return (
    <ThemeProvider theme={theme}>
      <StyledLoginWrapper>
        <StyledLoginLeft>
          <img
            src={svgLogo}
            alt="Регион"
          />
        </StyledLoginLeft>
        <StyledLoginRight>

          <StyledLoginContainer>
            <LogIn />
          </StyledLoginContainer>

          <StyledInfoBlock>
            <a href="tel:+74957772964">
              <Text
                variant="body3"
                color="secondary"
              >
                +7 495 777 29 64
              </Text>
            </a>
            <a href="mailto:client@region.ru">
              <Text
                variant="body3"
                color="secondary"
              >
                client@region.ru
              </Text>
            </a>
          </StyledInfoBlock>
        </StyledLoginRight>
      </StyledLoginWrapper>
      <CustomModal
        open={passwordResetSuccessModal}
        title="Новый пароль успешно установлен!"
        textClose="Продолжить"
        handleClose={handleCloseSuccessReset}
        callbacks={{
          withClose: handleCloseSuccessReset,
        }}
      />
    </ThemeProvider>
  )
}

Login.propTypes = {
  clearUserStart: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  clearUserStart: () => dispatch(clearUser()),
})

export default connect(null, mapDispatchToProps)(Login)

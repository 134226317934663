import React, { useContext } from 'react'
import Popover from '@material-ui/core/Popover'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useSelector } from 'react-redux'
import { NotificationContext } from '../MainLayout/MainLayout'
import useStyles from './Notification.style'
import { selectCurrentNotifications } from '../../redux/notifications/selector'

const Notification = () => {
  const classes = useStyles()
  const context = useContext(NotificationContext)
  const { noticeVisibility, noticeEl, noticeClose } = context

  const notifications = useSelector(selectCurrentNotifications)
  const trueNotifications = notifications?.length >= 0
  && notifications.filter((item) => item.message !== null).reverse()

  return (
    <Popover
      className={classes.popover}
      open={noticeVisibility}
      anchorEl={noticeEl}
      onClose={noticeClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <List>
        {trueNotifications && trueNotifications.length ? trueNotifications.map((notification) => (
          <ListItem key={notification.id}>
            <ListItemText>{notification.message}</ListItemText>
          </ListItem>
        )) : (
          <ListItem>
            <ListItemText>Список уведомлений пуст</ListItemText>
          </ListItem>
        )}
      </List>
    </Popover>
  )
}

export default Notification

import { styled } from 'ui/theme'
import type { RadioProps } from './types'

export const StyledRadioWrapper = styled.div<{ alignItems?: string; justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`}
`

export const StyledRadio = styled.div<Partial<RadioProps>>`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 24px;
  height: 24px;

  margin-right: 8px;

  // TODO: get colors from theme (update theme)
  background-color: ${({ checked }) => (checked ? '#2C96DA' : '#fff')};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ checked }) => (checked ? '#2C96DA' : '#D1D6E1')};
  border-radius: 100%;
  // outline: ${({ isFocused }) => (isFocused ? '3px solid #8eabff' : 'none')};

  transition: background-color 0.3s, border-color 0.3s, outline 0.3s;
  cursor: pointer;

  input[type='radio'] {
    left: 0;
    top: 0;
    position: absolute;

    width: 0;
    height: 0;
  }

  &:hover {
    ${({ checked }) => (checked ? '' : 'border-color: #B2B7C2;')}
  }
`

export const StyledCircle = styled.div<Partial<RadioProps>>`
  width: ${({ checked }) => (checked ? '9px' : '0px')};
  height: ${({ checked }) => (checked ? '9px' : '0px')};

  // TODO: get colors from theme (update theme)
  background-color: #fff;
  border-radius: 100%;

  transition: width 0.3s, height 0.3s;
  pointer-events: none;
`

export const StyledRadioTitle = styled.label`
  color: ${({ theme }) => theme.color.text.primary};
  // TODO: get text size from theme (update theme)
  font-size: 14px;
  font-weight: 400;

  cursor: pointer;
`

import { uniqueArray } from '../../../utils/uniqueArray'

export function sortFieldsCategories(allFields) {
  const finalObject = {}

  const allCategory = allFields?.map((e) => e.category)

  const uniqueCategory = uniqueArray(allCategory)

  uniqueCategory.map((e) => (finalObject[e] = allFields?.filter((et) => et.category === e)))
  return { uniqueCategory, finalObject }
}

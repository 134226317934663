import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cuid from 'cuid'
import moment from 'moment'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Media from 'react-media'
import classnames from 'classnames'
import FilterForm from './FilterForm'
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg'
import {
  fetchCorpFilterStartAsync,
  fetchCorpStartAsync,
  fetchReportStartAsync,
  getReportSuccess,
  // getTableDataSuccess,
} from '../../redux/reports/reportsSlice'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import useStyles from './panel.style'
import { selectReport } from '../../redux/reports/selector'
import { rowsPerPage } from '../../config/constants'
import UsePagination from '../Pagination/Pagination'
import b64toBlob from '../../utils/ConvertToBlob'
import { selectConnectionState } from '../../redux/connection/selector'
import PageHeading from '../PageHeading/PageHeading'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'

const tabs = [
  {
    title: 'Дата отправки',
  },
  {
    title: 'Счёт',
  },
  {
    title: 'Референс',
  },
  {
    title: 'Эмитент / ISIN',
  },
  {
    title: 'Тип',
  },
  {
    title: 'Дата КД',
  },
  {
    title: 'Формат',
  },
]

const LogCorporate = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [fileId, setFileId] = useState(null)
  const [page, setPage] = useState(0)

  const connectedWS = useSelector(selectConnectionState)
  // const reportsCorpData = useSelector(selectReportsDataTable)
  const reportsCorpData = []
  const reportDetail = useSelector(selectReport)

  const fetchCorpStart = () => {
    dispatch(fetchCorpStartAsync())
  }
  const fetchCorpFilterStart = () => {
    dispatch(fetchCorpFilterStartAsync())
  }
  const fetchReportStart = (id) => {
    dispatch(fetchReportStartAsync({ id }))
  }

  const {
    getData,
  } = useConnect({
    link: `GET /api${endpoints.reports.corp}`,
    startFetching: fetchCorpStart,
    // action: getTableDataSuccess,
  })

  const {
    getData: brokerFilter,
  } = useConnect({
    link: `GET /api${endpoints.reports.corpFilter()}`,
    startFetching: fetchCorpFilterStart,
    // action: getTableDataSuccess,
  })

  const {
    getData: getReport,
  } = useConnect({
    link: `GET /api${endpoints.reports.getReport(fileId || null)}`,
    startFetching: fetchReportStart,
    action: getReportSuccess,
  })

  useEffect(() => {
    if (connectedWS) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  const downloadLink = useRef(null)

  useEffect(() => {
    if (reportsCorpData?.reports?.length > 0) {
      const currentReportsLength = reportsCorpData.reports.length
      if (page * rowsPerPage > currentReportsLength) {
        setPage(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsCorpData])

  useEffect(() => {
    if (reportDetail) {
      downloadLink.current.href = URL.createObjectURL(b64toBlob(reportDetail.data))
      downloadLink.current.download = reportDetail.fileName
      downloadLink.current.click()
    }
  }, [reportDetail])

  const handleDownloadFile = async (id) => {
    setFileId(id)
    await getReport(id)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  return (
    <>
      <PageHeading text="Корпоративные действия" />
      <Box className={classes.boxWrapper}>
        <FilterForm
          accounts={reportsCorpData && reportsCorpData?.references?.accounts}
          fetchFilterStart={brokerFilter}
        />
        <Box className={classes.tableWrapper}>
          <Media
            query="(min-width: 577px)"
            render={() => (
              <TableContainer>
                <Table aria-label="corporate actions table">
                  <TableHead>
                    <TableRow className={classnames(classes.tableRow, classes.tableRow_Corp)}>
                      {tabs.map(({ title }) => (
                        <TableCell
                          key={cuid()}
                          className={classes.tableHeaderCell}
                        >
                          {title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportsCorpData && (reportsCorpData?.reports?.slice(
                      ((page) * (rowsPerPage)), ((page + 1) * (rowsPerPage)),
                    ).map((item) => {
                      const {
                        dateFrom, corpActionRef, corpIssuer, corpActionType, id,
                      } = item
                      const { number } = item.account
                      const formatName = item.format.name

                      return (
                        <TableRow
                          key={cuid()}
                          className={classes.tableRow}
                          hover
                        >
                          <TableCell>
                            {moment(dateFrom).format('DD.MM.YYYY')}
                          </TableCell>
                          <TableCell>
                            {number}
                          </TableCell>
                          <TableCell>
                            {corpActionRef}
                          </TableCell>
                          <TableCell>
                            {corpIssuer}
                          </TableCell>
                          <TableCell>
                            {corpActionType}
                          </TableCell>
                          <TableCell>
                            {dateFrom}
                          </TableCell>
                          <TableCell>
                            {formatName && (
                            <Button
                              className={classes.tableBtn}
                              disableRipple
                              onClick={() => handleDownloadFile(id)}
                            >
                              <DownloadIcon />
                              <Typography
                                tag="span"
                                color="secondary"
                              >
                                {formatName}
                              </Typography>
                            </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    }))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          />
          <Media
            query={`(max-width: ${breakpoints.phone_large}px)`}
            render={() => reportsCorpData && reportsCorpData?.reports?.map((item) => {
              const {
                dateFrom, corpActionRef, corpIssuer, corpActionType, id,
              } = item
              const { number } = item.account
              const formatName = item.format.name

              return (
                <div
                  key={id + cuid()}
                  className={classes.PhoneGrid}
                >
                  <div className={classes.PhoneGrid__item}>
                    <span
                      className={classes.PhoneGrid__header}
                    >
                      Дата отправки
                    </span>
                    <p className={
                      classnames(classes.PhoneGrid__value, classes.PhoneGrid__value_medium)
                    }
                    >
                      {moment(dateFrom).format('DD.MM.YYYY')}
                    </p>
                  </div>
                  <div className={classes.PhoneGrid__item}>
                    <span
                      className={classes.PhoneGrid__header}
                    >
                      Счёт
                    </span>
                    <p className={classes.PhoneGrid__value}>
                      {number}
                    </p>
                  </div>
                  <div className={classes.PhoneGrid__item}>
                    <span
                      className={classes.PhoneGrid__header}
                    >
                      Референс
                    </span>
                    <p className={classes.PhoneGrid__value}>
                      {corpActionRef}
                    </p>
                  </div>
                  <div className={
                    classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullColumn)
                  }
                  >
                    <span
                      className={classes.PhoneGrid__header}
                    >
                      Эмитент / ISIN
                    </span>
                    <p className={classes.PhoneGrid__value}>
                      {corpIssuer}
                    </p>
                  </div>
                  <div className={
                    classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullColumn)
                  }
                  >
                    <span
                      className={classes.PhoneGrid__header}
                    >
                      Тип
                    </span>
                    <p className={classes.PhoneGrid__value}>
                      {corpActionType}
                    </p>
                  </div>
                  <div className={classes.PhoneGrid__item}>
                    <span
                      className={classes.PhoneGrid__header}
                    >
                      Дата КД
                    </span>
                    <p className={classes.PhoneGrid__value}>
                      {dateFrom}
                    </p>
                  </div>
                  {formatName && (
                    <div className={classes.PhoneGrid__item}>
                      <span
                        className={classes.PhoneGrid__header}
                      >
                        Формат
                      </span>
                      <Button
                        className={classes.tableBtn}
                        disableRipple
                        onClick={() => handleDownloadFile(id)}
                      >
                        <DownloadIcon />
                        <Typography
                          tag="span"
                          color="secondary"
                        >
                          {formatName}
                        </Typography>
                      </Button>
                    </div>
                  )}
                </div>
              )
            })}
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={classes.downloadLink}
            href=""
            ref={downloadLink}
          >
            download
          </a>
          <Media
            query="(min-width: 577px)"
            render={() => (
              <Box pt={3}>
                <UsePagination
                  count={
                    reportsCorpData ? Math.floor(reportsCorpData?.reports?.length / rowsPerPage) : 0
                  }
                  currentPage={page}
                  onChangePage={handleChangePage}
                />
              </Box>
            )}
          />
        </Box>
      </Box>
    </>
  )
}

export default LogCorporate

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  MobileFilter: {

  },
  MobileFilter__modal: {
    transform: 'translateY(100%)',
    transition: '225ms cubic-bezier(0, 0, 0.2, 1)',
    transformOrigin: '50% 0',
    '& > div': {
      '&:first-child': {
        backgroundColor: 'transparent !important',
      },
    },
  },
  MobileFilter__modal_active: {
    transform: 'translateY(0)',
  },
  MobileFilter__modalContent: {
    height: '100%',
    marginTop: 98,
    marginLeft: 46,
    outline: 'none',
    backgroundColor: theme.palette.type === 'light' ? '#f4f8f9' : '#343A42',
    padding: '17px 24px',
    '& .MuiFormLabel-root': {
      fontSize: 12,
      lineHeight: '16px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#343A42',
    },
    '& .MuiGrid-container': {
      marginTop: -10,
      marginBottom: -10,
      '& .MuiGrid-root': {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },
  MobileFilter__header: {
    fontSize: 16,
    marginBottom: 26,
    position: 'relative',
    left: -7,
  },
  MobileFilter__buttonCount: {
    position: 'relative',
    paddingLeft: 12,
    marginLeft: 13,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 3,
      left: 0,
      width: 1,
      height: 14,
      backgroundColor: '#688695',
    },
  },
  MobileFilter__btnWrapper: {
    position: 'fixed',
    zIndex: 1,
    left: 46,
    bottom: 0,
    display: 'flex',
    width: 'calc(100% - 46px)',
    height: 60,
    paddingTop: 6,
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
  },
  MobileFilter__button: {
    backgroundColor: '#92B2C0',
    color: '#fff',
    fontFamily: 'Open Sans',
    fontSize: 14,
    lineHeight: '19px',
    padding: '2px 8px 3px',
    textAlign: 'center',
    border: 0,
    outline: 0,
    margin: 'auto',
    minWidth: 118,
    '&:hover': {
      backgroundColor: '#92B2C0',
    },
  },
  MobileFilter__button_primary: {
    backgroundColor: '#F2C94C',
    color: '#313A4E',
    '&:hover': {
      backgroundColor: '#F2C94C',
    },
  },
  MobileFilter__groupBtn: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 46,
    '& button:not(:last-child)': {
      marginBottom: 24,
    },
  },
}))

export default useStyles

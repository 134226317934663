import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import {
  Switch, Route, Redirect, useRouteMatch, useLocation, useHistory,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import Media from 'react-media'
import Grid from '@material-ui/core/Grid'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg'
import ProfilePanel from './basicInformationsPanels/profilePanel'
import GeneralPanel from './basicInformationsPanels/generalPanel'
import RequisitesPanel from './basicInformationsPanels/requisitesPanel'
import useStyles from '../InnerTabs/InnerTabs.style'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import { fetchFormsStartAsync, getFormsSuccess } from '../../redux/profilePanel/profilePanelSlice'
import { selectCurrentUser } from '../../redux/user/selector'

const BasicInformations = ({ fetchFormsStart, connectedWS }) => {
  const classes = useStyles()
  const { url, path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const isPathProfileClassName = pathname.split('/')[3] === 'profile'
  const isPathGeneralClassName = pathname.split('/')[3] === 'general'
  const isPathRequisitesClassName = pathname.split('/')[3] === 'requisites'

  const { getData } = useConnect({
    link: `GET /api${endpoints.forms.getForms}`, startFetching: fetchFormsStart, action: getFormsSuccess,
  })

  useEffect(() => {
    if (connectedWS) {
      getData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  const legalEntity = useSelector(selectCurrentUser)?.client?.legalEntity
  const goTo = (link) => history.push(`${url}/${link}`)
  const returnNiceClass = (isNice) => (isNice ? `${classes.breadCrumb} ${classes.activeCrumb}` : classes.breadCrumb)

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <Grid
            container
            className={classes.crumbContainer}
          >
            <button
              type="button"
              className={returnNiceClass(isPathProfileClassName)}
              onClick={() => goTo('profile')}
            >
              <span>Профиль</span>
              <ArrowRightIcon />
            </button>
            {(legalEntity && legalEntity === 'firm') && (
              <button
                type="button"
                className={returnNiceClass(isPathGeneralClassName)}
                onClick={() => goTo('general')}
              >
                <span>Общее</span>
                <ArrowRightIcon />
              </button>
            )}
            <button
              type="button"
              className={returnNiceClass(isPathRequisitesClassName)}
              onClick={() => goTo('requisites')}
            >
              <span>Реквизиты</span>
              <ArrowRightIcon />
            </button>
          </Grid>
        )}
      />
      <Switch>
        <Route
          exact
          path={path}
        >
          <Redirect to={`${path}/profile`} />
        </Route>
        <Route
          exact
          path={`${path}/profile`}
          component={ProfilePanel}
        />
        <Route
          exact
          path={`${path}/general`}
          component={GeneralPanel}
        />
        <Route
          exact
          path={`${path}/requisites`}
          component={RequisitesPanel}
        />
      </Switch>
    </>
  )
}

BasicInformations.propTypes = {
  fetchFormsStart: PropTypes.func.isRequired,
  connectedWS: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
})

const mapDispatchToProps = (dispatch) => ({
  fetchFormsStart: () => dispatch(fetchFormsStartAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicInformations)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { connect, useSelector } from 'react-redux'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'

import { ReactComponent as UserIcon } from '../../assets/images/user.svg'
import { ReactComponent as DingIcon } from '../../assets/images/ding.svg'
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg'
import { ReactComponent as BurgerIcon } from '../../assets/images/burger.svg'
import { ReactComponent as ExitIcon } from '../../assets/images/exit.svg'
import { ReactComponent as GearIcon } from '../../assets/images/gear.svg'
import { ReactComponent as SunUniqueIcon } from '../../assets/images/sun-unique.svg'
import { ReactComponent as MoonIcon } from '../../assets/images/moon.svg'
import useStyles from './MobileAppBar.style'
import { navList, actionsList } from '../MainLayout/data'
import { ReactComponent as LogoPath } from '../../assets/images/logo-path.svg'
import { changeTheme } from '../../redux/theme/themeSlice'
import { selectNeedSignReports } from '../../redux/reports/selector'
import { ReactComponent as MessagesIcon } from '../../assets/images/messages.svg'
import { useAccess } from '../../hooks/useAccess'

const MobileAppBar = ({
  noticeOpen, noticeCount, agentName, isThemeLight, switchTheme,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const [menuState, setMenuState] = useState({ leftMenu: false })
  const [additionalMenuState, setAdditionalMenuState] = useState(false)
  const [isFirstPage, setIsFirstPage] = useState(true)
  const groupedList = navList.concat(actionsList)
  const access = useAccess()

  const needSignReports = useSelector(selectNeedSignReports)
  const needSignReportsCount = needSignReports?.reports?.length

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    if (pathname === '/portfolio/count') {
      setIsFirstPage(true)
    } else {
      setIsFirstPage(false)
    }
    // eslint-disable-next-line
  }, [pathname])

  const toggleAdditionalMenu = () => {
    setAdditionalMenuState((prev) => !prev)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    const isKeyPressed = event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')
    if (isKeyPressed) {
      return
    }
    setMenuState({ ...menuState, [anchor]: open })
    if (additionalMenuState) {
      setAdditionalMenuState(false)
    }
  }

  const leftMenu = (anchor) => (
    <>
      <div className={classes.mobileMenu__main}>
        <div className={classes.mobileMenu__topRow}>
          <LogoPath className={classes.mobileMenu__logo} />
          <p className={classnames(classes.label, classes.label_greyBlue)}>
            {agentName}
          </p>
        </div>
        <List className={classes.mobileMenu__list}>
          {navList
            .filter(({ id }) => access[id] !== false)
            .map(({
              link, title, icon,
            }, index) => (
              <NavLink
                key={`${title}${String(index)}`}
                to={link}
                onClick={toggleDrawer(anchor, false)}
                style={{ textDecoration: 'none' }}
              >
                <ListItem
                  button
                  disableRipple
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>
                    <Typography color="textPrimary">{title}</Typography>
                  </ListItemText>
                </ListItem>
              </NavLink>
            ))}
        </List>
        <div className={classes.mobileMenu__bottomRow}>
          <List className={classes.mobileMenu__list}>
            <NavLink
              to="/logout"
              style={{ textDecoration: 'none' }}
            >
              <ListItem
                button
                disableRipple
              >
                <ListItemIcon>
                  <ExitIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="textPrimary">
                    Выход
                  </Typography>
                </ListItemText>
              </ListItem>
            </NavLink>
          </List>
          <IconButton
            color="inherit"
            onClick={toggleAdditionalMenu}
          >
            <GearIcon />
          </IconButton>
        </div>
      </div>
      <div className={
        classnames(
          classes.mobileMenu__additional,
          {
            [classes.mobileMenu__additional_active]: additionalMenuState,
          },
        )
      }
      >
        <div className={classes.mobileMenu__actions}>
          <div className={classes.mobileMenu__action}>
            <button
              type="button"
              className={
                classnames(
                  classes.mobileMenu__actionItem,
                  { [classes.mobileMenu__actionItem_active]: isThemeLight },
                )
              }
              onClick={() => switchTheme('light')}
            >
              <SunUniqueIcon />
            </button>
            <button
              type="button"
              className={
                classnames(
                  classes.mobileMenu__actionItem,
                  { [classes.mobileMenu__actionItem_active]: !isThemeLight },
                )
              }
              onClick={() => switchTheme('dark')}
            >
              <MoonIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  )

  const handleClickPrev = () => {
    history.go(-1)
  }

  return (
    <div className={classes.MobileAppBar}>
      <AppBar position="fixed">
        <Toolbar className={classes.MobileAppBar__toolbar}>
          <p className={classes.label}>
            {agentName}
          </p>
          <div className={classes.MobileAppBar__userControls}>
            <NavLink to="/sign/reports">
              <IconButton color="inherit">
                <MessagesIcon style={{ width: 22, height: 20 }} />
              </IconButton>
              {needSignReportsCount ? <span>{needSignReportsCount}</span> : null}
            </NavLink>
            <NavLink to="/profile">
              <IconButton color="inherit">
                <UserIcon style={{ width: 17, height: 18 }} />
              </IconButton>
            </NavLink>
            <IconButton
              color="inherit"
              onClick={noticeOpen}
            >
              <Badge
                className={classes.MobileAppBar__badgeWrapper}
                badgeContent={noticeCount}
                color="error"
              >
                <DingIcon style={{ width: 19, height: 18 }} />
              </Badge>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.leftSidebar}>
        <>
          <div className={classes.leftSidebar__topRow}>
            <LogoPath
              className={classes.leftSidebar__logo}
              style={{ fill: '#fff' }}
            />
            {groupedList.map(({ id, link, icon }) => {
              const trimPathname = pathname.split('/')[1]
              const trimLink = link.substr(1)

              return trimPathname === trimLink && (
                <div
                  key={id}
                  className={classes.leftSidebar__pageIconWrapper}
                >
                  {icon}
                </div>
              )
            })}
          </div>
          {!isFirstPage && (
            <button
              type="button"
              className={classes.leftSidebar__returnBtn}
              onClick={handleClickPrev}
            >
              <ArrowLeftIcon />
            </button>
          )}
          <IconButton
            edge="start"
            className={classes.leftSidebar__menuButton}
            color="inherit"
            onClick={toggleDrawer('left', true)}
          >
            <BurgerIcon />
          </IconButton>
          <Drawer
            className={classes.mobileMenu}
            anchor="left"
            open={menuState.left}
            onClose={toggleDrawer('left', false)}
          >
            {leftMenu('left')}
          </Drawer>
        </>
      </div>
    </div>
  )
}

MobileAppBar.propTypes = {
  noticeOpen: PropTypes.func.isRequired,
  noticeCount: PropTypes.number.isRequired,
  agentName: PropTypes.string.isRequired,
  isThemeLight: PropTypes.bool.isRequired,
  switchTheme: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  switchTheme: (next) => dispatch(changeTheme(next)),
})

export default connect(null, mapDispatchToProps)(MobileAppBar)

// eslint-disable-next-line
export const GLOBAL_BREAKPOINTS = {
  desktop_large: 1920,
  desktop_medium: 1600,
  desktop_small: 1536,
  laptop_large: 1440,
  laptop_medium: 1366,
  laptop_small: 1280,
  tablet_large: 1024,
  tablet_medium: 960,
  tablet_small: 768,
  phone_large: 576,
  phone_medium: 414,
  phone_small: 320,
}

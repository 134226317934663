import React, { FC, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import { ReactComponent as LineChart } from '../../assets/icons/scaner/line-chart.svg';
import { ReactComponent as Filter } from '../../assets/icons/scaner/filter.svg';
import { ReactComponent as Analytics } from '../../assets/icons/scaner/analytics.svg';
import { ReactComponent as ServiceAnalysis } from '../../assets/icons/scaner/service-analysis.svg';
import { ReactComponent as Invoice } from '../../assets/icons/scaner/invoice.svg';
import { ReactComponent as Overview } from '../../assets/icons/scaner/overview.svg';

import { fetchScanerStartAsync } from '../../redux/scaner/scanerSlice';

import { Button, CustomModal, Text } from '../../ui/components';

import { BtnWrapper, Cards, Card, CardText, ScanerWrapper } from './styled';

import { CARDS, POPUP_ACTION_TEXT } from './data';
import Api from "../../api/Api";
import { TScaner } from "./types";

const switchIcon = (icon: string) => {
  switch (icon) {
    case "LineChart": return <LineChart />;
    case "Filter": return <Filter />;
    case "Analytics": return <Analytics />;
    case "ServiceAnalysis": return <ServiceAnalysis />;
    case "Invoice": return <Invoice />;
    case "Overview": return <Overview />;
  }
};

const Scaner: FC<TScaner> = ({ fetchScanerStart, connectedWS }) => {
  const [ accessScaner, setAccessScaner ] = useState( false);
  const [ link, setLink ] = useState('');
  const [ isOpenModal, setIsOpenModal ] = useState(false);

  useEffect(() => {
    checkIsAccess();
  }, []);

  useEffect(() => {
    accessScaner && getLink();
  }, [accessScaner])

  const checkIsAccess = async () => {
    const accessRequest = await Api.getScannerIsAccessRequest();
    return setAccessScaner(accessRequest.data.isAccess);
  }

  const getLink = async () => {
    const linkRequest = await Api.getScannerLinkRequest();
    return setLink(linkRequest.data.link);
  };

  const handleScaner = async () => {
    if (accessScaner) {
      window.open(link);
    } else {
      await Api.postScannerAccessRequest();
      setIsOpenModal(true);
    }
  };

  const handleClose = () => {
    setIsOpenModal(false)
  };

  return (
    <ScanerWrapper>
      <Text variant="h3">Программно-аналитическое приложение СКАНЕР</Text>

      <Text variant="body3">для профессиональной работы на фондовом рынке</Text>

      <Cards>
        {CARDS.map((item) => (
          <Card>
            {switchIcon(item.icon)}

            <CardText>
              {item.title1}
            </CardText>

            <CardText>
              {item.title2}
            </CardText>
          </Card>
        ))}
      </Cards>

      <BtnWrapper>
        <Button
          variant="primary"
          type="submit"
          onClick={handleScaner}
        >
          {accessScaner ? "Перейти" : "Получить доступ"}
        </Button>
      </BtnWrapper>

      <CustomModal
        actionText={POPUP_ACTION_TEXT}
        open={isOpenModal}
        handleClose={handleClose}
        title="Ваш запрос направлен в департамент брокерского обслуживания"
        infinity
      >
        <Button
          width="100%"
          variant="primary"
          type="submit"
          onClick={handleClose}
        >
          ОК
        </Button>
      </CustomModal>
    </ScanerWrapper>
  );
};

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
});

const mapDispatchToProps = (dispatch) => ({
  fetchScanerStart: () => dispatch(fetchScanerStartAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scaner);

import { createSlice } from '@reduxjs/toolkit'
import getCadespluginAPI from 'async-cadesplugin'
import Api from '../../api/Api'
import { fillCertificates } from '../../utils/FillCertificates'
import createUri from '../../utils/FilterUri'
import { getErrorSuccess } from '../error/errorSlice'

const initialState = {
  postReport: null,
  report: null,
  clientSign: null,
  reportReject: null,
  reportBrokerDataTable: null,
  reportDepoDataTable: null,
  reportCorpActionDataTable: null,
  needSign: null,
  account: null,
  accountBroker: null,
  accountDepo: null,
  accountCorpAction: null,
  formatPeriodFromBroker: null,
  formatPeriodToBroker: null,
  formatPeriodFromDepo: null,
  formatPeriodToDepo: null,
  formatPeriodFromCorpAction: null,
  formatPeriodToCorpAction: null,
  statusDepo: null,
  statusBroker: null,
  statusCorpAction: null,
  typeBroker: null,
  typeDepo: null,
  typeCorpAction: null,
  typeName: null,
  reportMarkread: null,
  reportTypesNames: null,
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    postReport(state) {
      return {
        ...state,
        error: null,
      }
    },
    postReportSuccess(state, action) {
      return {
        ...state,
        postReport: action.payload,
      }
    },
    postReportFailed(state) {
      return {
        ...state,
        error: true,
      }
    },
    getReport(state) {
      return {
        ...state,
        error: null,
      }
    },
    getReportTypesNames(state) {
      return {
        ...state,
      }
    },
    getReportTypesNamesSuccess(state, action) {
      return {
        ...state,
        reportTypesNames: action.payload?.data,
      }
    },
    getReportTypesNamesFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getReportSuccess(state, action) {
      return {
        ...state,
        report: action.payload,
        error: null,
      }
    },
    getReportFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    postClientSign(state) {
      return {
        ...state,
        error: null,
      }
    },
    postClientSignSuccess(state, action) {
      return {
        ...state,
        clientSign: action.payload,
      }
    },
    postClientSignFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    postReportMarkread(state) {
      return {
        ...state,
        error: null,
      }
    },
    postReportMarkreadSuccess(state, action) {
      return {
        ...state,
        reportMarkread: action.payload,
      }
    },
    postReportMarkreadFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    postReportReject(state) {
      return {
        ...state,
        error: null,
      }
    },
    postReportRejectSuccess(state, action) {
      return {
        ...state,
        reportReject: action.payload,
      }
    },
    postReportRejectFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getTableData(state) {
      return {
        ...state,
        error: null,
      }
    },
    getBrokerTableDataSuccess(state, action) {
      return {
        ...state,
        reportBrokerDataTable: action.payload,
        error: null,
      }
    },
    getDepoTableDataSuccess(state, action) {
      return {
        ...state,
        reportDepoDataTable: action.payload,
        error: null,
      }
    },
    getCorpTableDataSuccess(state, action) {
      return {
        ...state,
        reportCorpActionDataTable: action.payload,
        error: null,
      }
    },
    getTableDataFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getNeedSign(state) {
      return {
        ...state,
        error: null,
      }
    },
    getNeedSignSuccess(state, action) {
      return {
        ...state,
        needSign: action.payload,
        error: null,
      }
    },
    getNeedSignFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    accountFilter(state, { payload }) {
      if (payload?.gridUid) {
        return {
          ...state,
          [`account${payload.gridUid}`]: payload.value,
        }
      }

      return { ...state }
    },
    statusFilter(state, { payload }) {
      if (payload?.gridUid) {
        return {
          ...state,
          [`status${payload.gridUid}`]: payload.value,
        }
      }

      return { ...state }
    },
    typeFilter(state, { payload }) {
      if (payload?.gridUid) {
        return {
          ...state,
          [`type${payload.gridUid}`]: payload.value,
        }
      }

      return { ...state }
    },
    typeNameFilter(state, action) {
      return {
        ...state,
        typeName: action.payload,
      }
    },
    formatPeriodFromFilter(state, { payload }) {
      if (payload?.gridUid) {
        return {
          ...state,
          [`formatPeriodFrom${payload?.gridUid}`]: payload.value,
        }
      }

      return { ...state }
    },
    formatPeriodToFilter(state, { payload }) {
      if (payload?.gridUid) {
        return {
          ...state,
          [`formatPeriodTo${payload?.gridUid}`]: payload?.value,
        }
      }

      return { ...state }
    },
    clearFilterFields(state) {
      return {
        ...state,
        accountBroker: null,
        accountDepo: null,
        accountCorpAction: null,
        reportBrokerDataTable: null,
        reportDepoDataTable: null,
        reportCorpActionDataTable: null,
        formatPeriodFromBroker: null,
        formatPeriodToBroker: null,
        formatPeriodFromDepo: null,
        formatPeriodToDepo: null,
        formatPeriodFromCorpAction: null,
        formatPeriodToCorpAction: null,
      }
    },
  },
})

const { actions, reducer } = reportsSlice

export const {
  postReport,
  postReportSuccess,
  postReportFailed,
  getReport,
  getReportTypesNames,
  getReportTypesNamesSuccess,
  getReportTypesNamesFailed,
  getReportSuccess,
  getReportFailed,
  postClientSign,
  postClientSignSuccess,
  postClientSignFailed,
  postReportReject,
  postReportRejectSuccess,
  postReportRejectFailed,
  postReportMarkread,
  postReportMarkreadSuccess,
  postReportMarkreadFailed,
  getTableData,
  getBrokerTableDataSuccess,
  getDepoTableDataSuccess,
  getCorpTableDataSuccess,
  getTableDataFailed,
  getNeedSign,
  getNeedSignSuccess,
  getNeedSignFailed,
  accountFilter,
  statusFilter,
  typeFilter,
  typeNameFilter,
  formatPeriodFromFilter,
  formatPeriodToFilter,
  clearFilterFields,
} = actions

export default reducer

export const fetchPostReportsStartAsync = (reqObj) => async (dispatch) => {
  dispatch(postReport())
  try {
    const value = await Api.reportsRequest(reqObj)
    dispatch(postReportSuccess(value))
  } catch (error) {
    dispatch(postReportFailed())
  }
}

export const fetchReportStartAsync = ({ id }) => async (dispatch) => {
  dispatch(getReport())
  try {
    const value = await Api.getReport(id)
    dispatch(getReportSuccess(value))
  } catch (error) {
    dispatch(getReportFailed())
  }
}

export const fetchPostClientSignStartAsync = (
  reqObj,
  dataToSign = '',
  thumbprint,
) => async (dispatch) => {
  dispatch(postClientSign())
  try {
    if (thumbprint) {
      const api = await getCadespluginAPI()
      const response = await api.getCert(thumbprint)
      fillCertificates(reqObj, dataToSign, thumbprint, [response], (data) => {
        dispatch(
          getErrorSuccess({
            Message: `Ошибка в работе сертификата${data?.message && ': '} ${data?.message}`,
          }),
        )
      })
    } else {
      const value = await Api.postClientSign(reqObj)
      dispatch(postClientSignSuccess(value))
    }
  } catch (error) {
    dispatch(postClientSignFailed(error))
  }
}

export const fetchPostReportMarkreadAsync = (reqObj) => async (dispatch) => {
  dispatch(postReportMarkread())
  try {
    const value = await Api.postReportMarkread(reqObj)
    dispatch(postReportMarkreadSuccess(value))
  } catch (error) {
    dispatch(postReportMarkreadFailed())
  }
}

export const fetchPostReportRejectStartAsync = (reqObj) => async (dispatch) => {
  dispatch(postReportReject())
  try {
    const value = await Api.postReportReject(reqObj)
    dispatch(postReportRejectSuccess(value))
  } catch (error) {
    dispatch(postReportRejectFailed(error))
  }
}

export const fetchBrokerStartAsync = () => async (dispatch) => {
  dispatch(getTableData())
  try {
    const value = await Api.getBroker()
    dispatch(getBrokerTableDataSuccess(value))
  } catch (error) {
    dispatch(getTableDataFailed(error?.message))
  }
}

export const setAccountFilter = (value, gridUid) => async (dispatch) => {
  dispatch(accountFilter({ value, gridUid }))
}
export const setStatusFilter = (value, gridUid) => async (dispatch) => {
  dispatch(statusFilter({ value, gridUid }))
}
export const setTypeFilter = (value, gridUid) => async (dispatch) => {
  dispatch(typeFilter({ value, gridUid }))
}

export const setTypeNameFilter = (value) => async (dispatch) => {
  dispatch(typeNameFilter(value))
}

export const setFormatPeriodFromFilter = (value, gridUid) => async (dispatch) => {
  dispatch(formatPeriodFromFilter({ value, gridUid }))
}

export const setFormatPeriodToFilter = (value, gridUid) => async (dispatch) => {
  dispatch(formatPeriodToFilter({ value, gridUid }))
}

const getCurrentUri = (account, formatPeriodFrom, formatPeriodTo, statusUID, typeUID, typeName) => {
  let uri = ''
  if (account || formatPeriodFrom || formatPeriodTo || statusUID || typeUID || typeName) {
    uri = createUri({
      accountId: account,
      dateFrom: formatPeriodFrom,
      dateTo: formatPeriodTo,
      statusUID,
      typeUID,
      name: 'reports',
      condition: 'and',
      typeName,
    })
    return `Count=100&Filter=${uri}`
  }
  return 'Count=100'
}

export const fetchBrokerFilterStartAsync = () => async (dispatch, getState) => {
  dispatch(getTableData())
  try {
    const value = await Api.getBrokerFilter(
      getCurrentUri(getState().reports.accountBroker,
        getState().reports.formatPeriodFromBroker,
        getState().reports.formatPeriodToBroker,
        getState().reports.statusBroker,
        getState().reports.typeBroker),
    )
    dispatch(getBrokerTableDataSuccess(value))
  } catch (error) {
    dispatch(getTableDataFailed(error?.message))
  }
}

export const fetchDepoStartAsync = () => async (dispatch) => {
  dispatch(getTableData())
  try {
    const value = await Api.getDepo()
    dispatch(getDepoTableDataSuccess(value))
  } catch (error) {
    dispatch(getTableDataFailed(error?.message))
  }
}

export const fetchDepoFilterStartAsync = () => async (dispatch, getState) => {
  dispatch(getTableData())
  try {
    const value = await Api.getDepoFilter(
      getCurrentUri(getState().reports.accountDepo,
        getState().reports.formatPeriodFromDepo,
        getState().reports.formatPeriodToDepo,
        getState().reports.statusDepo,
        getState().reports.typeDepo,
        getState().reports.typeName),
    )
    dispatch(getDepoTableDataSuccess(value))
  } catch (error) {
    dispatch(getTableDataFailed(error?.message))
  }
}

export const fetchCorpStartAsync = () => async (dispatch) => {
  dispatch(getTableData())
  try {
    const value = await Api.getCorp()
    dispatch(getCorpTableDataSuccess(value))
  } catch (error) {
    dispatch(getTableDataFailed(error?.message))
  }
}

export const fetchCorpFilterStartAsync = () => async (dispatch, getState) => {
  dispatch(getTableData())
  try {
    const value = await Api.getCorpFilter(
      getCurrentUri(getState().reports.accountCorpAction,
        getState().reports.formatPeriodFromCorpAction,
        getState().reports.formatPeriodToCorpAction,
        getState().reports.statusCorpAction,
        getState().reports.typeCorpAction),
    )
    dispatch(getCorpTableDataSuccess(value))
  } catch (error) {
    dispatch(getTableDataFailed(error?.message))
  }
}

export const fetchNeedSignStartAsync = () => async (dispatch) => {
  dispatch(getNeedSign())
  try {
    const value = await Api.getNeedSign()
    dispatch(getNeedSignSuccess(value))
  } catch (error) {
    dispatch(getNeedSignFailed(error?.message))
  }
}

export const fetchReportTypesNamesStartAsync = () => async (dispatch) => {
  dispatch(getReportTypesNames())
  try {
    const value = await Api.getReportTypesNames()
    dispatch(getReportTypesNamesSuccess(value))
  } catch (error) {
    dispatch(getReportTypesNamesFailed(error?.message))
  }
}

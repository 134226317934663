import React from 'react';
import { Tab, Tabs } from 'ui/components';
import { StyledWrapper } from './styled';
import {NavLink, Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {GlobalStyle, theme, useTheme} from 'ui/theme';
import { useSizeContext } from 'context/SizeContext';
import Broker from "./Broker";
import LogBroker from "./LogBroker";
import LogDeposit from "./LogDeposit";
import LogCorporate from "./LogCorporate";
import {ThemeProvider} from "styled-components";
import {MainLayout} from "../../templates/MainLayout";
import { SizeContext } from 'context/SizeContext'
import {useSize} from "../../../../hooks/useSize";
import {StyledContent, StyledTabsWrapper} from "../Transfers/styled";
import {ROUTES} from "../../../../app/data";
import {EMessagePageTabsValues} from "../Message/data";


export const ReportsNew: React.FC = () => {
  const { url, path } = useRouteMatch()
  const size = useSize();
  // const isMobile = theme.bp.isXS(size.width);

  const { pathname } = useLocation()
  if (pathname === ROUTES.reports) return <Redirect to={ROUTES.reportsNew} />

  return(

    <ThemeProvider theme={theme}>

      <GlobalStyle />

      <SizeContext.Provider value={size}>
      <MainLayout title="Отчеты">

      <StyledWrapper>
        <StyledTabsWrapper>
          <Tabs variant={2}>
            <Tab
              label="Новый отчёт"
              to={`${url}/log/new`}
              component={NavLink}
            />
            <Tab
              label="Брокерские отчёты"
              to={`${url}/log/broker`}
              component={NavLink}
            />
            <Tab
              label="Депозитарные отчёты"
              to={`${url}/log/deposit`}
              component={NavLink}
            />
            <Tab
              label="Корпоративные действия"
              to={`${url}/log/corporate`}
              component={NavLink}
            />
          </Tabs>
        </StyledTabsWrapper>

        <StyledContent>
          <Switch>
            <Route
              exact
              path={`${path}/log/new`}
              component={Broker}
            />
            <Route
              exact
              path={`${path}/log/broker`}
              component={LogBroker}
            />
            <Route
              exact
              path={`${path}/log/deposit`}
              component={LogDeposit}
            />
            <Route
              exact
              path={`${path}/log/corporate`}
              component={LogCorporate}
            />
          </Switch>
        </StyledContent>

      </StyledWrapper>
      </MainLayout>
      </SizeContext.Provider>
    </ThemeProvider>
  )

}

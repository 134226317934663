import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import useStyles from './CustomCircularProgress.style'

const CustomCircularProgress = ({ val, color, label }) => {
  const classes = useStyles()
  const [circularVal, setCircularVal] = useState(0)

  const randomRotate = Math.round(Math.random() * 360)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCircularVal(val)
    }, 800)

    return () => {
      clearTimeout(timeout)
    }
  }, [val])

  return (
    <Box className={classes.boxWrapper}>
      <CircularProgress
        className={classes.circularStatic}
        variant="static"
        value={100}
        size={142}
        thickness={0.4}
        style={{ color: '#4b5057' }}
      />
      <CircularProgress
        className={classes.circularDynamic}
        variant="static"
        value={circularVal}
        size={144}
        thickness={1.25}
        style={{
          color,
          transform: `rotate(${randomRotate}deg)`,
        }}
      />
      <Box className={classes.boxInner}>
        <Typography
          className={classes.percent}
          variant="caption"
          component="span"
        >
          %
        </Typography>
        <Typography
          className={classes.count}
          variant="caption"
          component="span"
        >
          {val}
        </Typography>
        <Typography
          className={classes.label}
          variant="caption"
          component="span"
        >
          {label}
        </Typography>
      </Box>
    </Box>
  )
}

CustomCircularProgress.propTypes = {
  val: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default CustomCircularProgress

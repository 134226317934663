import React, { FC, memo, useCallback } from 'react'
import Tag from 'ui/components/atoms/Tag'

import { Text } from 'ui/components/atoms/Text'
import { formatDateAndTime } from 'utils/GetDate'

import {
  StyledHistoryCard,
  StyledHistoryCardDate,
  StyledHistoryCardRow,
  StyledHistoryCardSubject,
} from './styled'
import { ETagTypeByMessageStatus, IMessageHistoryCard } from './types'

const MessageHistoryCard: FC<IMessageHistoryCard> = ({ onClick, ...otherProps }) => {
  const { subject, statusUid, statusName, typeName, date, time } = otherProps

  const onClickHandler = useCallback(() => onClick?.(otherProps), [onClick, otherProps])

  return (
    <StyledHistoryCard onClick={onClickHandler}>
      <StyledHistoryCardRow>
        <Text variant="caption2" color="secondary">
          {typeName}
        </Text>

        <Tag type={ETagTypeByMessageStatus[statusUid?.toLowerCase()]}>
          {statusName}
        </Tag>
      </StyledHistoryCardRow>

      <StyledHistoryCardRow>
        <StyledHistoryCardSubject variant="body1">{subject}</StyledHistoryCardSubject>

        <StyledHistoryCardDate variant="caption1" color="secondary">
          {formatDateAndTime({date, time})}
        </StyledHistoryCardDate>
      </StyledHistoryCardRow>
    </StyledHistoryCard>
  )
}

export default memo(MessageHistoryCard)

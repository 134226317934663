import React, { useEffect } from 'react';
import moment from 'moment';
import { Icon } from 'ui/components';
import { NotificationButton } from './components/NotificationButton';
import { StyledHeader, StyledHeaderNav, StyledHeaderNavItem, StyledHeaderNavItemText, StyledHeaderText } from './styled';
import { IHeaderProps } from './types';
import { ProfileButton } from './components/ProfileButton';
import { useTheme } from 'ui/theme';
import { useSizeContext } from 'context/SizeContext';
import { ClientButton } from './components/ClientButton';
import { selectConnectionState } from 'redux/connection/selector';
import { useDispatch, useSelector } from 'react-redux';
import endpoints from 'api/endpoints';
import { fetchTimeStartAsync, getTimeSuccess } from 'redux/portfolio/portfolioSlice';
import useConnect from 'utils/Connect';
import ErrorsResponseModal from "../../../../components/ErrorsResponseModal/ErrorsResponseModal";

export const Header: React.FC<IHeaderProps> = ({
  noticeOpen,
  noticeCount,
  agentName,
  clientName,
}) => {
  const size = useSizeContext();
  const theme = useTheme();
  const isMobile = theme.bp.isXS(size.width);
  const dispatch = useDispatch();

  const connectedWS = useSelector(selectConnectionState)
  const fetchTimeStart = () => dispatch(fetchTimeStartAsync())
  // const {
  //   getData: getTime,
  // } = useConnect({
  //   link: `GET /api${endpoints.portfolio.time}`,
  //   startFetching: fetchTimeStart,
  //   action: getTimeSuccess,
  // })

  useEffect(() => {
    if (connectedWS) {
      // getTime()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])


  return <StyledHeader>
    <ErrorsResponseModal />
    <StyledHeaderText>
      Данные на {moment().format('DD.MM.YYYY HH:mm')}
    </StyledHeaderText>
    <StyledHeaderNav>
      {
        !isMobile &&
        <NotificationButton value={noticeCount} onOpen={noticeOpen} />
      }
      <ProfileButton agentName={agentName} />
      <ClientButton clientName={clientName} />
      <StyledHeaderNavItem to="/">
        <Icon name="dark" size={'md'} />
      </StyledHeaderNavItem>
    </StyledHeaderNav>
  </StyledHeader>;
};

const forge = require('node-forge')

const certificate = process.env.REACT_APP_CERTIFICATE

const encrypt = (obj) => {
  // const methodType = '2.16.840.1.101.3.4.1.42'
  const p7 = forge.pkcs7.createEnvelopedData()
  const cert = forge.pki.certificateFromPem(certificate)
  p7.addRecipient(cert)
  p7.content = forge.util.createBuffer(JSON.stringify(obj))
  p7.encrypt()
  const pem = forge.pkcs7.messageToPem(p7)
  const plines = pem.split('\r\n')
  plines.splice(0, 1)
  plines.splice(-2, 1)
  return plines.join('\r\n')
}

export default encrypt

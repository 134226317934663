import React, { MouseEventHandler } from 'react'
import { Button } from '../../../../atoms'
import { getDeclensionWordOrder } from '../../../../../../utils/DeclensionWord'
import { StyledMobileMultiselectWrapper, StyledMobileMultiselectTitle } from './styled'

interface IMobileMultiselectMenu {
  handleSign: MouseEventHandler
  handleDelete: MouseEventHandler
  transfersCount?: number
}

const MobileMultiselectMenu: React.FC<IMobileMultiselectMenu>= ({
  handleSign,
  handleDelete,
  transfersCount,
}) => {
  return (
    <StyledMobileMultiselectWrapper>
      <StyledMobileMultiselectTitle>
        Выбрано {transfersCount} {getDeclensionWordOrder(transfersCount)}
      </StyledMobileMultiselectTitle>

      <Button fullWidth variant="primary" onClick={handleSign} type="button" style={{ marginBottom: '8px' }}>
        Подписать
      </Button>
      <Button fullWidth variant="negative-outline" onClick={handleDelete} type="button">
        Удалить
      </Button>
    </StyledMobileMultiselectWrapper>
  )
}

export default MobileMultiselectMenu

import React, {
  Fragment, useEffect, useMemo, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import cuid from 'cuid'
import { format } from 'date-fns'
import classnames from 'classnames'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Collapse from '@material-ui/core/Collapse'
import TableContainer from '@material-ui/core/TableContainer'
import Button from '@material-ui/core/Button'
import Media from 'react-media'
import CustomIconButton from '../CustomControls/CustomIconButton'
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg'
import { ReactComponent as EdsIcon } from '../../assets/images/eds.svg'
import { ReactComponent as EdsNewMark } from '../../assets/images/mark-new.svg'
import useStyles from './panel.style'
import { rowsPerPage } from '../../config/constants'
import b64toBlob from '../../utils/ConvertToBlob'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import {
  fetchNeedSignStartAsync,
  fetchPostClientSignStartAsync, fetchPostReportMarkreadAsync, fetchPostReportRejectStartAsync,
  fetchReportStartAsync, getNeedSignSuccess,
  getReportSuccess,
  postClientSignSuccess, postReportMarkreadSuccess, postReportRejectSuccess,
} from '../../redux/reports/reportsSlice'
import {
  selectClientSign,
  selectReport,
  selectReportMarkread,
  selectReportReject,
} from '../../redux/reports/selector'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import CustomInput from '../CustomControls/CustomInput'
import { selectCurrentNotifications } from '../../redux/notifications/selector'
import { selectCurrentMetadata } from '../../redux/metadata/selector'
import { fetchMetadataStartAsync, getMetadataSuccess } from '../../redux/metadata/metadataSlice'
import { selectConnectionState } from '../../redux/connection/selector'
import CustomModal from '../CustomModal/CustomModal'
import { isRequired } from '../../utils/Validate'
import { selectCurrentError } from '../../redux/error/selector'
import { selectCurrentThumbprint } from '../../redux/user/selector'

const tabs = [
  {
    title: 'Дата',
  },
  {
    title: 'Счёт',
  },
  {
    title: 'Тип',
  },
  {
    title: 'Период',
  },
  {
    title: 'Статус',
  },
  {
    title: 'ЭЦП',
  },
  {
    title: 'Формат',
  },
]

const EpsTable = ({ page, reportsArrayData, brokerFilter }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [reportId, setReportId] = useState(null)
  const [fileId, setFileId] = useState(null)
  const [isEps, setIsEps] = useState(false)
  const [open, setOpen] = useState('')
  const [code, setCode] = useState('')
  const [lastReportItems, setLastReportItems] = useState([])
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [rejectReportID, setRejectReportID] = useState(null)
  const [codeError, setCodeError] = useState(false)
  const [codeErrorText, setCodeErrorText] = useState(null)
  const [disableButtons, setDisableButtons] = useState(false)

  const connectedWS = useSelector(selectConnectionState)
  const reportDetail = useSelector(selectReport)
  const clientSign = useSelector(selectClientSign)
  const reportRejectStatus = useSelector(selectReportReject)
  const currentNotifications = useSelector(selectCurrentNotifications)
  const reportStatuses = useSelector(selectCurrentMetadata)?.references?.reportStatuses
  const reportMarkread = useSelector(selectReportMarkread)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)

  const reportsArray = useMemo(() => reportsArrayData?.map(
    (item) => ({ ...item }),
  ), [reportsArrayData])

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchReportStart = (id) => {
    dispatch(fetchReportStartAsync({ id }))
  }

  const fetchPostClientSignStart = (obj, dataToSign, thumbprint) => {
    dispatch(fetchPostClientSignStartAsync(obj, dataToSign, thumbprint))
  }

  const fetchPostReportRejectStart = (obj) => {
    dispatch(fetchPostReportRejectStartAsync(obj))
  }

  const fetchPostReportMarkread = (obj) => {
    dispatch(fetchPostReportMarkreadAsync(obj))
  }

  const fetchNeedSignStart = () => {
    dispatch(fetchNeedSignStartAsync())
  }

  const {
    getData,
  } = useConnect({
    link: `GET /api${endpoints.metadata.getMetadata('Reports.Type.Broker')}`,
    startFetching: fetchMetadataStart,
    action: getMetadataSuccess,
  })

  const {
    getData: getReport,
  } = useConnect({
    link: `GET /api${endpoints.reports.getReport(fileId || null)}`,
    startFetching: fetchReportStart,
    action: getReportSuccess,
  })

  const {
    getData: postClientSign,
  } = useConnect({
    link: `POST /api${endpoints.reports.postClientSign}`,
    startFetching: fetchPostClientSignStart,
    action: postClientSignSuccess,
  })

  const {
    getData: postReportMarkread,
  } = useConnect({
    link: `POST /api${endpoints.reports.reportMarkread}`,
    startFetching: fetchPostReportMarkread,
    action: postReportMarkreadSuccess,
  })

  const {
    getData: postReportReject,
  } = useConnect({
    link: `POST /api${endpoints.reports.postReportReject}`,
    startFetching: fetchPostReportRejectStart,
    action: postReportRejectSuccess,
  })

  const {
    getData: postNeedSignReports,
  } = useConnect({
    link: `GET /api${endpoints.reports.needSign}`,
    startFetching: fetchNeedSignStart,
    action: getNeedSignSuccess,
  })

  useEffect(() => {
    if (connectedWS && !reportStatuses) {
      getData('Reports.Type.Broker')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  useEffect(() => {
    dispatch(postClientSignSuccess(''))
    // eslint-disable-next-line
  }, [])

  useEffect(() => () => {
    dispatch(getReportSuccess(null))
    dispatch(postClientSignSuccess(null))
  }
  // eslint-disable-next-line
  , [])

  useEffect(() => {
    if (serverError) {
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeErrorText(serverError?.errors?.Secret[0])
      }
    }
  }, [serverError])

  useEffect(() => {
    const lastItem = currentNotifications[currentNotifications.length - 1]
    if (reportsArray && !!lastItem?.detail?.statusUid) {
      const hasLastItemReport = reportsArray?.some((item) => item?.id === lastItem?.recordID)
      if (hasLastItemReport) {
        const lastReportItemsIndex = lastReportItems.findIndex((e) => e.recordID === lastItem?.recordID)
        if (lastReportItemsIndex >= 0) {
          const finalArray = lastReportItems.map((elem, index) => (index === lastReportItemsIndex ? lastItem : elem))
          setLastReportItems(finalArray)
          return
        }
        setLastReportItems((prevState) => [...prevState, lastItem])
      }
    }
    // eslint-disable-next-line
  }, [currentNotifications])

  useEffect(() => {
    if (reportMarkread?.reportsAffected?.length) {
      reportMarkread.reportsAffected.forEach((id) => {
        if (reportsArray?.length > 0) {
          reportsArray.forEach((item) => {
            if (item?.id === id) {
              item.isNew = false
            }
          })
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportMarkread])

  const updateNeedSignReports = () => {
    postNeedSignReports()
    setOpen('')
    dispatch(postClientSignSuccess(null))
    dispatch(postReportRejectSuccess(null))
    brokerFilter()
    setDisableButtons(false)
  }

  useEffect(() => {
    if (clientSign?.actionNeeded === 'sign') {
      setFileId(reportId)
      getReport(reportId)
    }
    if (!clientSign?.actionNeeded && clientSign?.status === 200) {
      updateNeedSignReports()
    }
    // eslint-disable-next-line
  }, [clientSign])

  useEffect(() => {
    if (reportRejectStatus) {
      if (reportRejectStatus?.status === 200) {
        updateNeedSignReports()
      }
      setDisableButtons(false)
    }
    // eslint-disable-next-line
  }, [reportRejectStatus])

  const downloadLink = useRef(null)

  useEffect(() => {
    if (reportDetail && !clientSign) {
      downloadLink.current.href = URL.createObjectURL(b64toBlob(reportDetail.data))
      downloadLink.current.download = isEps ? reportDetail.signFileName : reportDetail.fileName
      downloadLink.current.click()
      dispatch(getReportSuccess())
    } else if (clientSign?.actionNeeded === 'sign') {
      const reqObj = {
        id: reportId,
      }
      postClientSign(reqObj, reportDetail, currentThumbPrint)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDetail])

  useEffect(() => {
    setOpen('')
  }, [page])

  const handleDownloadFile = async (event, id, isEpsVal) => {
    event.stopPropagation()
    dispatch(postClientSignSuccess(''))
    if (isEpsVal) {
      setIsEps(true)
    } else {
      setIsEps(false)
    }
    setFileId(id)
    await getReport(id)
  }

  const handleClickSign = async (reportID) => {
    setDisableButtons(true)
    setCode('')
    setReportId(reportID)
    const reqObj = {
      id: reportID,
    }
    postClientSign(reqObj)
  }

  const handleClickReject = async (reportID) => {
    setRejectModalOpen(true)
    setRejectReportID(reportID)
  }

  const handleClickCollapse = (index, isNew, reportRowId) => {
    setCode('')
    setReportId(null)
    dispatch(postClientSignSuccess(''))
    setOpen(open.includes(index) ? '' : `tabRow-${index}`)
    if (isNew && reportRowId >= 0) {
      postReportMarkread({
        reportID: reportRowId,
      })
    }
  }

  const handleChangeCodeInput = (event) => {
    setCode(event.target.value)
    // setCodeError(false)
  }

  const handleSubmitCode = () => {
    if (!code) {
      isRequired(code, setCodeError, setCodeErrorText)
      return
    }
    const reqObj = {
      id: reportId,
      secret: code,
    }
    postClientSign(reqObj)
  }

  const handleRejectOk = () => {
    const reqObj = {
      id: rejectReportID,
    }
    postReportReject(reqObj)
    setRejectModalOpen(false)
  }

  const handleCloseRejectModal = () => {
    setRejectModalOpen(false)
    setRejectReportID(null)
  }

  const getHandler = (handle) => (disableButtons ? null : handle)

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <TableContainer>
            <Table aria-label="instrument table">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  {tabs.map(({ title }) => (
                    <TableCell
                      key={cuid()}
                      className={classes.tableHeaderCell}
                    >
                      {title}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {reportsArray && (reportsArray.slice(
                  ((page) * (rowsPerPage)), ((page + 1) * (rowsPerPage)),
                ).map((item, index) => {
                  const { number } = item.account
                  const {
                    dateTime, typeName, dateFrom, dateTo, id, status, isSigned,
                  } = item
                  const dataFileID = item?.dataFileID
                  const fileExtension = item.format.name

                  const lastReportItem = lastReportItems.find((e) => e.recordID === id)

                  const getNotificationReportStatus = lastReportItem?.detail
                    ?.statusUid?.new?.toLowerCase()

                  const statusName = reportStatuses?.find((statusItem) => (
                    statusItem?.uid?.toLowerCase() === (getNotificationReportStatus || status.toLowerCase())))?.name

                  const needClientSign = item?.needClientSign
                  const isNew = (lastReportItem?.recordID === id)
                    ? lastReportItem?.detail?.isNew : item?.isNew
                  const edsButton = (
                    <Button
                      className={classes.tableBtn}
                      disableRipple
                      onClick={(event) => handleDownloadFile(event, id, true)}
                    >
                      <EdsIcon style={{ marginRight: 0 }} />
                    </Button>
                  )
                  const collapseButton = (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleClickCollapse(index, isNew, item?.id)}
                    >
                      {open === `tabRow-${index}` ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )
                  const buttonsContainer = (
                    <Box>
                      <Grid
                        container
                        item
                        spacing={2}
                      >
                        {(clientSign?.actionNeeded === 'code' && id === reportId) ? (
                          <div className={classnames(classes.formGroup, classes.formGroup_unique)}>
                            <CustomInput
                              unique
                              label="Введите код из сообщения"
                              variant="outlined"
                              name="code"
                              value={code}
                              onChange={handleChangeCodeInput}
                              size="small"
                              fullWidth
                              error={codeError}
                              errorText={codeErrorText}
                              autoComplete="off"
                            />
                            <div className={classes.buttonsContainer}>
                              <CustomIconButton
                                type="submit"
                                unique
                                noIcon
                                onClick={handleSubmitCode}
                              >
                                Подтвердить
                              </CustomIconButton>
                            </div>
                          </div>
                        ) : (
                          <div className={classes.formGroup}>
                            <div className={classes.buttonsContainer}>
                              <CustomIconButton
                                type="submit"
                                unique
                                needLine
                                noIcon
                                onClick={getHandler(() => handleClickSign(id))}
                              >
                                Подписать
                              </CustomIconButton>
                              <CustomIconButton
                                type="submit"
                                unique
                                noIcon
                                onClick={getHandler(() => handleClickReject(id))}
                              >
                                Отклонить
                              </CustomIconButton>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </Box>
                  )
                  const collapseContent = (
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
                        colSpan={12}
                        className={classes.nestedRow}
                      >
                        <Collapse
                          in={open === `tabRow-${index}`}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box
                            p={2}
                            className={classes.nestedRowInner}
                          >
                            <Typography className={classes.textWithIcon}>
                              <EdsIcon />
                              {needClientSign ? (
                                <span>Ожидаем Вашей подписи</span>
                              ) : <span>Отчет подписан</span>}
                            </Typography>
                            {(needClientSign) && (
                              buttonsContainer
                            )}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )

                  return (
                    <Fragment key={id + index.toString()}>
                      <TableRow
                        className={classnames(classes.tableRow, classes.tableRow_EpsNBr)}
                        hover
                        onClick={() => handleClickCollapse(index, isNew, item?.id)}
                      >
                        <TableCell>
                          {format(new Date(dateTime), 'dd.MM.yyyy')}
                        </TableCell>
                        <TableCell>
                          {number}
                        </TableCell>
                        <TableCell>
                          {typeName}
                        </TableCell>
                        <TableCell>
                          {`${format(new Date(dateFrom), 'dd.MM.yyyy')} - ${format(new Date(dateTo), 'dd.MM.yyyy')}`}
                        </TableCell>
                        <TableCell>
                          {statusName}
                        </TableCell>
                        <TableCell>
                          {isSigned && (
                            edsButton
                          )}
                        </TableCell>
                        <TableCell>
                          {(dataFileID || lastReportItem?.detail?.statusUid?.new === 'executed') && (
                            <Button
                              className={classes.tableBtn}
                              disableRipple
                              onClick={(event) => handleDownloadFile(event, id, false)}
                            >
                              <DownloadIcon />
                              <Typography
                                tag="span"
                                color="secondary"
                              >
                                {fileExtension}
                              </Typography>
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {(needClientSign) && (
                            collapseButton
                          )}
                        </TableCell>
                        <TableCell
                          width={0}
                          className={classes.tableCell__newMark}
                        >
                          {isNew && (
                          <div className={classes.tableCell__newMark__icon}>
                            <EdsNewMark
                              width={90}
                              height={21}
                            />
                          </div>
                          )}
                        </TableCell>
                      </TableRow>
                      {(needClientSign) && (
                        collapseContent
                      )}
                    </Fragment>
                  )
                }))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (reportsArray && reportsArray.map((item, index) => {
          const { number } = item.account
          const {
            dateTime, typeName, dateFrom, dateTo, id, status, isSigned,
          } = item
          const dataFileID = item?.dataFileID
          const fileExtension = item.format.name

          const lastReportItem = lastReportItems.find((e) => e.recordID === id)

          const statusName = reportStatuses?.find((statusItem) => (
            statusItem?.uid?.toLowerCase() === (
               lastReportItem?.detail?.statusUid?.new?.toLowerCase()
              || status.toLowerCase())))?.name
          const needClientSign = item?.needClientSign
          const isNew = item?.isNew
          const edsButton = (
            <>
              <div className={classes.PhoneGrid__item}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  ЭЦП
                </span>
                <Button
                  className={classes.tableBtn}
                  disableRipple
                  onClick={(event) => handleDownloadFile(event, id, true)}
                >
                  <EdsIcon style={{ marginRight: 0 }} />
                </Button>
              </div>
            </>
          )
          const buttonsContainer = (
            <>
              {(clientSign?.actionNeeded === 'code' && id === reportId) && (
                <div className={classes.PhoneGrid__codeInputWrapper}>
                  <CustomInput
                    label="Введите код из сообщения"
                    variant="outlined"
                    name="code"
                    value={code}
                    onChange={handleChangeCodeInput}
                    size="small"
                    fullWidth
                    error={codeError}
                    errorText={codeErrorText}
                    autoComplete="off"
                  />
                </div>
              )}
              <div className={classes.PhoneGrid__btnGroup}>
                {(clientSign?.actionNeeded === 'code' && id === reportId) ? (
                  <CustomIconButton
                    type="submit"
                    unique
                    onClick={handleSubmitCode}
                  >
                    Подтвердить
                  </CustomIconButton>
                ) : (
                  <>
                    <CustomIconButton
                      type="submit"
                      unique
                      onClick={getHandler(() => handleClickSign(id))}
                    >
                      Подписать
                    </CustomIconButton>
                    <CustomIconButton
                      type="submit"
                      unique
                      onClick={getHandler(() => handleClickReject(id))}
                    >
                      Отклонить
                    </CustomIconButton>
                  </>
                )}
              </div>
            </>
          )
          const collapseContent = (
            <Collapse
              in={open === `tabRow-${index}`}
              timeout="auto"
              unmountOnExit
              className={
                classnames(
                  classes.PhoneGrid__collapse,
                  { [classes.PhoneGrid__collapse_filled]: clientSign?.actionNeeded === 'code' },
                )
              }
            >
              <Box
                className={classes.PhoneGrid__collapseInner}
              >
                <Typography className={classes.textWithIcon}>
                  <EdsIcon />
                  {needClientSign ? (
                    <span>Ожидаем Вашей подписи</span>
                  ) : <span>Отчет подписан</span>}
                </Typography>
                {(needClientSign) && (
                  buttonsContainer
                )}
              </Box>
            </Collapse>
          )

          return (
            <Fragment key={id + index.toString()}>
              <div
                className={classes.PhoneGrid}
                onClick={() => handleClickCollapse(index, isNew, item?.id)}
                onKeyPress={() => handleClickCollapse(index)}
                role="button"
                tabIndex={0}
              >
                {isNew && (
                <div className={classes.PhoneGrid__newMark__icon}>
                  <EdsNewMark />
                </div>
                )}
                <div className={classes.PhoneGrid__item}>
                  <span
                    className={classes.PhoneGrid__header}
                  >
                    Дата
                  </span>
                  <p className={
                    classnames(classes.PhoneGrid__value, classes.PhoneGrid__value_medium)
                  }
                  >
                    {format(new Date(dateTime), 'dd.MM.yyyy')}
                  </p>
                </div>
                <div className={classes.PhoneGrid__item}>
                  <span
                    className={classes.PhoneGrid__header}
                  >
                    Счёт
                  </span>
                  <p className={classes.PhoneGrid__value}>
                    {number}
                  </p>
                </div>
                <div className={
                  classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullColumn)
                }
                >
                  <span
                    className={classes.PhoneGrid__header}
                  >
                    Тип
                  </span>
                  <p className={classes.PhoneGrid__value}>
                    {typeName}
                  </p>
                </div>
                <div className={
                  classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullColumn)
                }
                >
                  <span
                    className={classes.PhoneGrid__header}
                  >
                    Период
                  </span>
                  <p className={classes.PhoneGrid__value}>
                    {`${format(new Date(dateFrom), 'dd.MM.yyyy')} - ${format(new Date(dateTo), 'dd.MM.yyyy')}`}
                  </p>
                </div>
                <div className={
                  classnames(classes.PhoneGrid__item, classes.PhoneGrid__item_fullColumn)
                }
                >
                  <span
                    className={classes.PhoneGrid__header}
                  >
                    Статус
                  </span>
                  <p className={classes.PhoneGrid__value}>
                    {statusName}
                  </p>
                </div>
                {isSigned && (
                  edsButton
                )}
                {(dataFileID || lastReportItem?.detail?.statusUid?.new === 'executed') && (
                  <div className={classes.PhoneGrid__item}>
                    <span
                      className={classes.PhoneGrid__header}
                    >
                      Формат
                    </span>
                    <Button
                      className={classes.tableBtn}
                      disableRipple
                      onClick={(event) => handleDownloadFile(event, id, false)}
                    >
                      <DownloadIcon />
                      <Typography
                        tag="span"
                        color="secondary"
                      >
                        {fileExtension}
                      </Typography>
                    </Button>
                  </div>
                )}
              </div>
              {needClientSign && (
                collapseContent
              )}
            </Fragment>
          )
        })) || (<></>)}
      />
      <CustomModal
        open={rejectModalOpen}
        title="Вы действительно желаете отклонить подписание данного документа?"
        handleClose={handleCloseRejectModal}
        callbacks={{
          withOk: handleRejectOk,
          withCancel: () => {
            handleCloseRejectModal()
          },
        }}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={classes.downloadLink}
        href=""
        ref={downloadLink}
      >
        download
      </a>
    </>
  )
}

EpsTable.defaultProps = {
  reportsArrayData: PropTypes.array,
  brokerFilter: () => {},
}

EpsTable.propTypes = {
  page: PropTypes.number.isRequired,
  reportsArrayData: PropTypes.arrayOf(PropTypes.object),
  brokerFilter: PropTypes.func,
}

export default EpsTable

import React from 'react'
import { Snackbar, SnackbarProps } from '@material-ui/core'
import { StyledNotificationContent, StyledNotificationMessage } from './styled'

const SnackbarNotification = ({ message, ...props }: SnackbarProps) => {
  return (
    <Snackbar
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      style={{
        right: 40,
        bottom: 40,
      }}
      {...props}
    >
      <StyledNotificationContent>
        <StyledNotificationMessage>{message}</StyledNotificationMessage>
      </StyledNotificationContent>
    </Snackbar>
  )
}

export default SnackbarNotification

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  error: null,
}

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    getErrorSuccess(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    clearError() {
      return initialState
    },
  },
})

const { actions, reducer } = errorSlice

export const {
  getErrorSuccess,
  clearError,
} = actions

export default reducer

type TGetPrettyNumber = {
  <T>(
    number: T,
    fixed?: number,
    trailingZero?: boolean,
    limiter?: string,
    minimumDigits?: number,
  ): string;
}

const getPrettyNumber: TGetPrettyNumber = (
  number,
  fixed = 2,
  trailingZero = true,
  limiter = ",",
  minimumDigits = 2,
) => {
  const regNumberToFixed = new RegExp(
    "^-?\\d+(?:\\.\\d{0," + fixed + "})?",
    "g"
  );

  const numberToStringFixed =
    String(number).match(regNumberToFixed)?.[0] || "0";

  const numberToStringFormatted = "0".repeat(Math.max(minimumDigits - numberToStringFixed.length, 0)) + numberToStringFixed.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  const dotPosition = numberToStringFixed.indexOf(".");

  if (trailingZero) {
    if (dotPosition === -1) {
      return numberToStringFormatted + limiter + "0".repeat(fixed);
    }

    const lastDigitIndex = numberToStringFixed.length - 1;
    const currentDecimalLength = lastDigitIndex - dotPosition;
    const zeroCount = fixed - currentDecimalLength;
    return numberToStringFormatted.replace(".", limiter) + "0".repeat(zeroCount);
  }

  return numberToStringFormatted;
}

export default getPrettyNumber

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: 30,
    '@media (max-width: 576px)': {
      paddingTop: 12,
    },
  },
  wrapper_unique: {
    '@media (max-width: 576px)': {
      paddingTop: 35,
    },
  },
  fileInputWrapper: {
    '& .MuiFormLabel-root': {
      display: 'block',
      marginBottom: 5,
      fontSize: 15,
      color: '#292D3499',
    },
    '& .MuiBox-root': {
      marginTop: 2,
    },
    '& .MuiDropzoneArea-root': {
      '@media (max-width: 576px)': {
        height: '50px !important',
      },
    },
  },

  Transfer__codeWrapper: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 10,
    alignItems: 'flex-end',
    position: 'relative',
    left: -10,
    '@media (max-width: 576px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        paddingLeft: 16,
        position: 'initial',
      },
    },
    '& > .MuiButton-root': {
      minHeight: 'auto',
      width: 'auto',
      paddingTop: 1,
      paddingBottom: 1,
      fontSize: 14,
      '&:hover': {
        border: '1px solid #91B1BF',
        color: '#91B1BF',
        backgroundColor: '#E6EDEF',
      },
    },
  },

  Transfer__codeText: {
    fontSize: 12,
    color: '#91B1BF',
    paddingBottom: 5,
  },

  Transfer__codeInput: {
    paddingLeft: 5,
  },

  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 32,
    '@media (max-width: 576px)': {
      position: 'fixed',
      zIndex: 1,
      bottom: 0,
      left: 46,
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'calc(100% - 46px)',
      minHeight: 48,
      padding: '0 20px',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    },
    '& + *:not(.iconButtonUnique) > *:not(:last-child)': {
      marginRight: 60,
      '@media (max-width: 576px)': {
        marginRight: 16,
      },
    },
    '& button': {
      '&:not(:last-child)': {
        '@media (max-width: 576px)': {
          marginRight: 16,
        },
      },
    },
  },
  transferHeader: {
    paddingBottom: 22,
  },
  accordion: {
    marginBottom: -30,
    '& .MuiCollapse-container': {
      paddingTop: 30,
    },
  },
  otherFieldsContainer: {
    marginBottom: 15,
  },
  actualStatus: {
    fontWeight: '600',
    color: '#292D34',
    fontSize: 15,
    lineHeight: 1,
  },
}))

export default useStyles

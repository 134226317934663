import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  StyledAccountSelectMobile,
  StyledPortfolioActions,
  StyledPortfolioActionsMobile,
  StyledPortfolioCards,
  StyledPortfolioForm,
  StyledPortfolioMobileCard,
  StyledPortfolioMobileCardTitle,
  StyledPortfolioMobileCardAmount,
  StyledAccountSelectMobileClose,
  StyledMobileAccount,
  StyledMobileAccountValue,
} from './styled'
import {
  Field,
  Select,
  Chips,
  PriceChangeCard,
  MobileTable,
  MobileTableRow,
  MobileTableSection,
  MobileTableSectionCell,
} from 'ui/components'
import { useTheme } from 'ui/theme'
import { useSize } from 'hooks/useSize'
import { Button, Badge, Text } from 'ui/components'
import { IPortfolioActionsProps } from './types'
import { setCurrentAccount } from 'redux/portfolio/portfolioSlice'
import {
  selectGetCurrentAccount,
  selectGetPortfolio,
} from 'redux/portfolio/selector'
import getPrettyNumber from 'utils/GetPrettyNumber'
import { getCorrectPercentValue } from '../PortfolioTables/data'
import { commonCategory } from './data'
import { getBadgeVariantByValues } from 'ui/components/atoms/Badge/data'

const currencies = [
  {
    label: 'RUB',
    value: 2,
  },
]

const PortfolioActions: React.FC<IPortfolioActionsProps> = ({
  currentCategory,
  onChangeCurrentCategory,
}) => {
  const size = useSize()
  const theme = useTheme()
  const isMobile = theme.bp.isXS(size.width)
  const [showAccountSelect, setShowAccountSelect] = React.useState(false)

  const [assetsSum, setAssetsSum] = useState<number | null>(null)
  const [assetsDeltaSum, setAssetsDeltaSum] = useState<number | null>(null)
  const [assetsDeltaPercents, setAssetsDeltaPercents] = useState<number | null>(null)

  const dispatch = useDispatch()

  const handleSelectAccount = (id: number) => dispatch(setCurrentAccount(id))
  const portfolio = useSelector(selectGetPortfolio)
  const currentAccount = useSelector(selectGetCurrentAccount)

  const accountCategories = [
    commonCategory,
    ...(portfolio?.filter(f => f.account.id === currentAccount)[0]?.account?.categories?.map( (p) => ({
      label: p.name,
      value: p.name,
      disabled: false,
    }) ) ?? []),
  ]

  const accounts = portfolio?.map(a => (
    {
      label:a.account.number,
      value:a.account.id,
    }
  ))


  useEffect(() => {
    handleSelectAccount(accounts?.[0]?.value)
  }, [portfolio])

  useEffect(() => {
    if (portfolio) {
      const valuation = portfolio?.filter(a => a.account?.id === currentAccount)[0]?.account ?? []
      setAssetsSum(valuation?.valuation)
      setAssetsDeltaSum(valuation?.valuationDelta)
      setAssetsDeltaPercents(valuation?.valuationDeltaPercent)

    }
    // eslint-disable-next-line
  }, [portfolio, currentAccount])

  useEffect(() => {
    isMobile && onChangeCurrentCategory([])
  }, [isMobile])

  useEffect(() => {
    onChangeCurrentCategory([])
  }, [currentAccount])

  const dayChange = assetsDeltaSum ? `${getPrettyNumber(assetsDeltaSum)} ₽` : '00,00 ₽'
  const dayChangePercent = `${assetsDeltaPercents > 0 ? '+' : ''}${getCorrectPercentValue(
    assetsDeltaPercents
  )}%`

  return (
    <>
      {!isMobile ? (
        <>
          <StyledPortfolioActions>
            <StyledPortfolioForm>
              <Field label="Счёт">
                <Select
                  options={accounts}
                  noOptionsText={
                    <>
                      Такого счёта не существует. <br />
                      Введите другой номер или выберите счёт из списка.
                    </>
                  }
                  value={accounts?.find((acc) => acc.value === currentAccount)}
                  onChange={(e) => handleSelectAccount(e!.value as number)}
                />
              </Field>
              <Field width="100px" label="Валюта">
                <Select options={currencies} isDisabled value={currencies[0]} />
              </Field>
            </StyledPortfolioForm>
            <Chips
              options={accountCategories}
              value={currentCategory}
              onChange={onChangeCurrentCategory}
              isMulti
            />
          </StyledPortfolioActions>
          <StyledPortfolioCards>
            <PriceChangeCard
              label="Оценка позиции"
              value={assetsSum ? `${getPrettyNumber(assetsSum)} ₽` : '00.00 ₽'}
              badge={/*{ positive: true, value: '+12%' }*/ undefined}
            />
            <PriceChangeCard
              label="Результат за день"
              value={dayChange}
              badge={{
                variant:
                  assetsDeltaPercents > 0
                    ? 'positive'
                    : assetsDeltaPercents < 0
                    ? 'negative'
                    : undefined,
                value: dayChangePercent,
              }}
            />
          </StyledPortfolioCards>
        </>
      ) : (
        <>
          <StyledPortfolioActionsMobile>
            <Button variant="link" onClick={() => setShowAccountSelect(true)}>
              Все счета
            </Button>
            <StyledPortfolioMobileCard>
              <StyledPortfolioMobileCardTitle>
                Счёт №{accounts?.find((acc) => acc.value === currentAccount)?.label}{' '}
                <Button variant="link">в рублях</Button>
              </StyledPortfolioMobileCardTitle>

              <StyledPortfolioMobileCardAmount>
                {assetsSum ? `${getPrettyNumber(assetsSum)} ₽` : '00.00 ₽'}
              </StyledPortfolioMobileCardAmount>

              <Badge
                variant={getBadgeVariantByValues({
                  isPositive: +assetsDeltaSum > 0,
                  isNegative: +assetsDeltaSum < 0,
                })}
                value={
                  <StyledMobileAccount>
                    <StyledMobileAccountValue
                      isPositive={+assetsDeltaSum > 0}
                      isNegative={+assetsDeltaSum < 0}
                      variant={'caption2'}
                      color="secondary"
                    >
                      {dayChange} ({dayChangePercent})
                    </StyledMobileAccountValue>

                    <Text variant={'caption2'} color="secondary">
                      за день
                    </Text>
                  </StyledMobileAccount>
                }
              />
              {/* <Badge positive size={'md'} showIcon={false} value={<>+12% <Text variant={'caption2'} color="secondary">за день</Text></>} /> */}
            </StyledPortfolioMobileCard>
          </StyledPortfolioActionsMobile>
          <StyledAccountSelectMobile show={showAccountSelect}>
            <StyledPortfolioActionsMobile>
              <StyledAccountSelectMobileClose>
                <Button variant="link" onClick={() => setShowAccountSelect(false)}>
                  Закрыть
                </Button>
              </StyledAccountSelectMobileClose>
              <StyledPortfolioMobileCard>
                <StyledPortfolioMobileCardTitle>
                  На всех счетах в рублях <Button variant="link">в рублях</Button>
                </StyledPortfolioMobileCardTitle>
                <StyledPortfolioMobileCardAmount>
                  {getPrettyNumber(portfolio?.reduce((accumulator, currentValue ) => accumulator + currentValue.account.valuation,
                  0))} ₽
                </StyledPortfolioMobileCardAmount>
                <Badge
                  variant={
                    assetsDeltaPercents > 0
                      ? 'positive'
                      : assetsDeltaPercents < 0
                      ? 'negative'
                      : undefined
                  }
                  icon={
                    assetsDeltaPercents > 0
                      ? 'positive'
                      : assetsDeltaPercents < 0
                      ? 'negative'
                      : undefined
                  }
                  size={'md'}
                  value={
                    <>
                      {assetsDeltaPercents > 0 ? '+' : ''}
                      {getCorrectPercentValue(assetsDeltaPercents)}%
                      <Text variant={'caption2'} color="secondary">
                        за день
                      </Text>
                    </>
                  }
                />
              </StyledPortfolioMobileCard>
            </StyledPortfolioActionsMobile>
            <MobileTable>
              {portfolio?.map((valuation: any) => (
                <MobileTableRow
                  key={valuation.account.id}
                  onClick={() => (
                    handleSelectAccount(valuation.account.id as number), setShowAccountSelect(false)
                  )}
                >
                  <MobileTableSection row>
                    <MobileTableSectionCell>
                      <Text variant="caption2" color="secondary">
                        Счёт №{valuation.account.number}
                      </Text>
                      <Text variant="body2">{getPrettyNumber(valuation.account.valuation)} ₽</Text>
                    </MobileTableSectionCell>
                  </MobileTableSection>
                  <MobileTableSection align="right">
                    <Text
                      variant="body2"
                      color={valuation.account.valuationDelta >= 0 ? 'positive' : 'negative'}
                    >
                      {valuation.account.valuationDelta >= 0 ? '+' : ''}
                      {getPrettyNumber(valuation.account.valuationDelta)} ₽ ($
                      {getPrettyNumber(valuation.account.valuationDeltaPercent)}%)
                    </Text>
                    <Text variant="caption2" color="secondary">
                      {' '}
                      за сегодня
                    </Text>
                  </MobileTableSection>
                </MobileTableRow>
              ))}
            </MobileTable>
          </StyledAccountSelectMobile>
        </>
      )}
    </>
  )
}

export default memo(PortfolioActions)

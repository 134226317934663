import React, { createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr'
import { baseURL } from '../../config/api'
import { connectStart, disconnectStart } from '../../redux/connection/connectionSlice'
import { clearError } from '../../redux/error/errorSlice'
import { selectCurrentUser } from '../../redux/user/selector'

export const ConnectionContext = createContext()

const WithSocket = (props) => {
  console.log('render')
  const {
    children,
  } = props
  const dispatch = useDispatch()
  const selectUser = useSelector(selectCurrentUser)
  const connectWSStart = () => dispatch(connectStart())
  const disconnectWSStart = () => dispatch(disconnectStart())
  // const connection = new HubConnectionBuilder()
  //   .withUrl(`${baseURL}/ws`)
  //   .withAutomaticReconnect()
  //   .configureLogging(LogLevel.Information)
  //   .build()
  //
  // if (selectUser?.accessToken && selectUser?.refreshToken) {
  //   disconnectWSStart()
  //   dispatch(clearError())
  //   if (connection.state !== 'Connected') {
  //     connection.start().then(async () => {
  //       try {
  //         await connection.invoke('SetToken', selectUser.accessToken)
  //         connectWSStart()
  //       } catch (err) {
  //         // eslint-disable-next-line no-console
  //         console.error(err)
  //       }
  //     })
  //   }
  // }

  useEffect(() => () => {
    // connection.stop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // connection.onclose(() => {
  //   disconnectWSStart()
  // })

  return (
    <ConnectionContext.Provider
      value={{}}
    >
      {children}
    </ConnectionContext.Provider>
  )
}

WithSocket.propTypes = {
  children: PropTypes.element.isRequired,
}

export default React.memo(WithSocket, () => true)

import React from 'react'
import { BadgeProps } from '@material-ui/core/Badge/Badge'
import { StyledBadgeCounter } from './styled'
import { BadgeCounterPos } from './types'

export const BadgeCounter = ({ children, xPos, yPos, ...props }: BadgeCounterPos & BadgeProps) => {
  return (
    <StyledBadgeCounter $xPos={xPos} $yPos={yPos} {...props}>
      {children}
    </StyledBadgeCounter>
  )
}

import React, { memo } from 'react'
import { RadioLabeled } from 'ui/components/molecules/RadioLabeled'
import { RadioSwitchContainer, RadioSwitchTitle } from './styled'

import type { FC } from 'react'
import type { RadioSwitchProps } from './types'

const RadioSwitch: FC<RadioSwitchProps> = ({
  title,
  value = false,
  titleMinWidth,
  titleMaxWidth,
  ...containerProps
}) => {
  const radioData = [
    { title: 'Да', name: '', value: 'true', id: 'yes', checked: value, disabled: true },
    { title: 'Нет', name: '', value: 'false', id: 'no', checked: !value, disabled: true },
  ]

  return (
    <RadioSwitchContainer {...containerProps}>
      <RadioSwitchTitle
        titleMinWidth={titleMinWidth}
        titleMaxWidth={titleMaxWidth}
        dangerouslySetInnerHTML={{ __html: title }}
      />

      <RadioLabeled radioButtonsList={radioData} />
    </RadioSwitchContainer>
  )
}

export default memo(RadioSwitch)

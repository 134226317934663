import React, { useEffect, useState } from 'react'

interface TimerProps {
  seconds: number
  handleStartTimer: (v: boolean) => void
  restart?: {}
}

const padTime = (time) => (String(time).length === 1 ? `0${time}` : `${time}`)

const format = (time) => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60)

  // Get the seconds left after converting minutes
  const seconds = time % 60

  return `${minutes}:${padTime(seconds)}`
}
const Timer: React.FC<TimerProps> = ({ seconds, handleStartTimer, restart }) => {
  const [counter, setCounter] = useState(seconds)
  useEffect(() => {
    const timer = setTimeout(() => counter > 0 && setCounter(counter - 1), 1000)
    // eslint-disable-next-line no-unused-expressions
    if (!counter) {
      handleStartTimer(false)
    }
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])

  useEffect(() => {
    setCounter(seconds)
  }, [restart])

  return <>{format(counter)}</>
}

export default Timer

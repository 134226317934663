import { createSelector } from 'reselect'

const selectTransferSecurity = (state) => state.transferSecurity

export const selectGetTransferSecurity = createSelector(
  [selectTransferSecurity],
  (state) => state.getTransferSecurity?.data,
)

export const selectSaveTransferSecurity = createSelector(
  [selectTransferSecurity],
  (state) => state.saveTransferSecurity?.data,
)
export const selectSaveTransferSecurityStatus = createSelector(
  [selectTransferSecurity],
  (state) => state.saveTransferSecurity?.status,
)

export const selectPerformTransferSecurity = createSelector(
  [selectTransferSecurity],
  (state) => state.performTransferSecurity?.data,
)
export const selectPerformTransferSecurityStatus = createSelector(
  [selectTransferSecurity],
  (state) => state.performTransferSecurity?.status,
)

export const selectGetBalanceSecurity = createSelector(
  [selectTransferSecurity],
  (state) => state.balances?.data,
)

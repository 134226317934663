import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Switch from '@material-ui/core/Switch'
import useStyles from './CustomControls.style'

const CustomSwitch = ({ isChecked, label, ...props }) => {
  const classes = useStyles()

  const switchClass = (active) => (active ? classes.switchActiveText : classes.switchText)

  return (
    <div className={classes.switchContainer}>
      {label ? (
        <span className={classes.switchLabel}>{label}</span>
      ) : (
        <Grid item>
          <span className={switchClass(isChecked)}>Да</span>
        </Grid>
      )}
      <Grid item>
        <Switch
          className={classes.switch}
          checked={isChecked}
          {...props}
        />
      </Grid>
      {!label && (
        <Grid item>
          <span className={switchClass(!isChecked)}>Нет</span>
        </Grid>
      )}
    </div>
  )
}

CustomSwitch.defaultProps = {
  label: '',
}

CustomSwitch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.string,
}

export default CustomSwitch

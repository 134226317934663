import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  contentTabs: {
    minHeight: 40,
    height: 40,
    '& > .MuiTabs-scroller': {
      '& > .MuiTabs-flexContainer': {
        justifyContent: 'flex-end',
        '& > .MuiButtonBase-root': {
          marginLeft: '1.5rem',
          paddingLeft: 0,
          paddingRight: 0,
          opacity: 1,
          '&.Mui-selected': {
            '& > .MuiTab-wrapper': {
              borderBottomColor: '#FBC15E',
              color: theme.palette.type === 'light' ? '#363E47' : '#fff',
            },
          },
          '& > .MuiTab-wrapper': {
            display: 'inline-block',
            borderBottom: '2px solid #91B1BF',
            fontSize: 15,
            fontWeight: 400,
            textTransform: 'lowercase',
            textAlign: 'left',
            color: '#91B1BF',
          },
        },
      },
      '& > .MuiTabs-indicator': {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        overflowX: 'auto !important',
        '& > .MuiTabs-flexContainer': {
          justifyContent: 'flex-start',
        },
      },
    },
  },
  row_pbLess: {
    paddingBottom: 8,
  },
  tableContainer: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
    },
  },
  tableRow: {
    borderRight: '6px solid transparent',
    cursor: 'pointer',
    '& > .MuiTableCell-root': {
      borderBottomColor: '#91B1BF',
      borderBottomStyle: 'dashed',
    },
    '&:hover': {
      borderRightColor: '#FBC15E',
    },
  },
  tableHeaderCell: {
    color: '#91B1BF',
  },
  row: {
    '&:first-child': {
      paddingTop: 15,
    },
    '&:not(:last-child)': {
      marginBottom: '15px',
    },
  },
  nestedCell: {
    padding: 0,
    borderBottomColor: 'transparent',
    transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0.15s',
  },
  nestedCell_active: {
    paddingTop: '2rem',
    paddingBottom: 16,
    borderBottomColor: 'rgba(224, 224, 224, 1)',
  },
  switchWrapper: {
    '@media (max-width: 600px)': {
      justifyContent: 'flex-start',
    },
  },
  switcher: {
    minWidth: 125,
  },
  residentRF: {
    '&.MuiGrid-container': {
      [theme.breakpoints.down('xl')]: {
        maxWidth: '30%',
        flexBasis: '30%',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
  },
  longLabelField: {
    '& > .MuiFormLabel-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: 13,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },
  },
  mediumLabelField: {
    '& > .MuiFormLabel-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
  },
  textareaWrapper: {
    '& .MuiInputBase-input': {
      height: 92,
    },
  },
  Mobile: {

  },
  Mobile__block: {
    paddingBottom: 10,
  },
  Mobile__blockHeader: {
    color: '#91B1BF',
    fontSize: 12,
  },
  Mobile__blockAddressee: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: 16,
  },
  Mobile__agentName: {
    fontWeight: 600,
  },
  Mobile__requisites: {
    borderTop: '1px solid #91B1BF',
    paddingTop: 10,
  },
  Mobile__seriesNumber: {
    display: 'flex',
    '& > div': {
      '&:first-child': {
        paddingRight: 89,
      },
    },
    paddingBottom: 10,
  },
  Mobile__bankAccount: {
    display: 'flex',
    '& > div': {
      '&:first-child': {
        paddingRight: 45,
      },
    },
  },
  Mobile__expandedChildBar: {
    '&.Mui-expanded.MuiAccordion-rounded': {
      marginTop: '-41px',
    },
  },
  Mobile__expandedPlanOperation: {
    '&.Mui-expanded.MuiAccordion-rounded': {
      marginTop: '-71px',
    },
  },
  Mobile__expandedTrusted: {
    '&.Mui-expanded.MuiAccordion-rounded': {
      marginTop: '-71px',
    },
  },
  Mobile__blockTextStructure: {
    whiteSpace: 'pre-line',
  },
  Mobile__licenses: {
    borderBottom: '1px solid #91B1BF',
    marginBottom: 10,
  },
  Phone__hideBlock: {
    display: 'none',
  },
  MobileFilter__toggleBtnWrapper: {
    position: 'fixed',
    zIndex: 1,
    left: 46,
    bottom: 0,
    display: 'flex',
    width: 'calc(100% - 46px)',
    height: 60,
    paddingTop: 6,
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
  },
  bottomSidebar__toggleBtn: {
    margin: 'auto',
    bottom: 15,
    left: '50%',
    backgroundColor: 'transparent',
    border: 0,
  },
  switchLegend: {
    position: 'relative',
    top: 1,
    color: theme.palette.type === 'light' ? '#292D3499' : '#ffffff99',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
    '& > span': {
      marginRight: 16,
    },
  },
}))

export default useStyles

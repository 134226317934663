import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  Pagination__list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.type === 'light' ? '#91B1BF' : '#fff',
  },
  Pagination__buttons: {
    marginRight: 9,
    padding: 0,
    cursor: 'pointer',
    background: 'transparent',
    border: 0,
    display: 'flex',
    backgroundColor: '#91B1BF',
    borderRadius: 4,
    '& svg g': {
      stroke: '#fff',
    },
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? '#313A4E' : '#fff',
      '& svg g': {
        stroke: theme.palette.type === 'light' ? '#fff' : '#313A4E',
      },
    },
  },
  Pagination__revertIcon: {
    transform: 'scale(-1, 1)',
  },
}))

export default useStyles

/**
 * Базовый интерфейс аккордеона с общими свойствами.
 */
export interface AccordionProfile {
  /**
   * Открыт ли по умолчанию аккордеон.
   *
   * @default false
   */
  isOpen?: boolean;
}

export const LANGUAGES_LIST = {
  RU: 'RU',
  EN: 'EN',
} as const;

/**
 * Список языков табов.
 */
export type Languages = typeof LANGUAGES_LIST[keyof typeof LANGUAGES_LIST];

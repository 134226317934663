import { createSelector } from '@reduxjs/toolkit'
import { format } from 'date-fns'

const getFormatDate = (date, formatDate) => format(new Date(date), formatDate)

const selectReports = (state) => state.reports

export const selectPostReport = createSelector(
  [selectReports],
  (state) => state.postReport?.data,
)

export const selectReport = createSelector(
  [selectReports],
  (state) => state.report?.data,
)

export const selectClientSign = createSelector(
  [selectReports],
  (state) => state.clientSign?.data,
)

export const selectClientSignStatus = createSelector(
  [selectReports],
  (state) => state.clientSign?.status,
)

export const selectReportReject = createSelector(
  [selectReports],
  (state) => state.reportReject?.data,
)

export const selectReportRejectStatus = createSelector(
  [selectReports],
  (state) => state.reportReject?.status,
)

export const selectBrokerReportsDataTable = createSelector(
  [selectReports],
  (state) => state.reportBrokerDataTable?.data,
)

export const selectDepoReportsDataTable = createSelector(
  [selectReports],
  (state) => state.reportDepoDataTable?.data,
)

export const selectCorpReportsDataTable = createSelector(
  [selectReports],
  (state) => state.reportCorpActionDataTable?.data,
)

export const selectNeedSignReports = createSelector(
  [selectReports],
  (state) => state.needSign?.data,
)

export const selectAccount = createSelector(
  [selectReports, (state, tableType) => tableType],
  (state, tableType) => state[`account${tableType}`],
)

export const selectReportMarkread = createSelector(
  [selectReports],
  (state) => state.reportMarkread?.data,
)
export const selectReportTypesNames = createSelector(
  [selectReports],
  (state) => state.reportTypesNames?.data,
)

export const selectFormatPeriodFrom = createSelector(
  [selectReports],
  (state) => state.formatPeriodFrom && getFormatDate(state.formatPeriodFrom, 'yyyy-MM-dd'),
)

export const selectFormatPeriodTo = createSelector(
  [selectReports],
  (state) => state.formatPeriodTo && getFormatDate(state.formatPeriodTo, 'yyyy-MM-dd'),
)

export const selectReportsStatuses = createSelector(
  [selectReports, (state, tableType) => tableType],
  (state, tableType) => state[`report${tableType}DataTable`]?.data?.references?.reportStatuses,
)
export const selectFilterReportsTypes = createSelector(
  [selectReports],
  (state) => state?.reportDataTable?.data?.references?.reportTypes,
)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from '@material-ui/core/Link'
import { fetchUserStartAsync } from '../../redux/user/userSlice'
import { Button, Text, TextField } from '../../ui/components'

import {
  StyledField,
  StyledLabel,
  StyledLoginTitle,
  StyledLoginWrapper,
  StyledFieldSecond,
  StyledRememberPassword,
  StyledButton, StyledErrorText, StyledSecurityIcon,
} from './styled'
import Eye from '../../pages/Login/assets/Eye.svg'
import EyeClose from '../../pages/Login/assets/EyeClose.svg'

const Auth = ({ fetchUserStart }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [usernameError, setUsernameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [usernameErrorText, setUsernameErrorText] = useState('')
  const [passwordErrorText, setPasswordErrorText] = useState('')

  useEffect(() => {
    const needSignRedirect = localStorage.getItem('needSignRedirect')
    if (needSignRedirect === '0') {
      localStorage.setItem('needSignRedirect', '1')
    }
  }, [])

  const [value, setValue] = useState({
    username: '',
    password: '',
  })

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    const { username, password } = value
    if (!username && !password) {
      setUsernameError(true)
      setUsernameErrorText('Введите логин')
      setPasswordErrorText('Введите пароль')
      return
    }
    if (!username) {
      setUsernameError(true)
      setPasswordError(false)
      setPasswordErrorText('Неизвестный пользователь или пароль')
      return
    }
    if (!password) {
      setPasswordError(true)
      setUsernameError(false)
      setPasswordErrorText('Неизвестный пользователь или пароль')
      return
    }
    await fetchUserStart(username, password).then((response) => {
      const respData = response?.data
      switch (respData?.status) {
        case 401:
          setUsernameError(true)
          setPasswordError(true)
          setPasswordErrorText(respData?.title)
          setUsernameErrorText('')
          break
        case 403:
          break
        default:
      }
    })
  }

  const handleChangeInput = (event, error) => {
    setValue({ ...value, [event.target.name]: event.target.value })
    error(false)
    setUsernameError(false)
    setPasswordError(false)
    setPasswordErrorText('')
  }

  return (
    <>
      <StyledLoginWrapper>
        <StyledLoginTitle>
          <Text
            variant="h1"
            color="primary"
          >
            Вход в личный кабинет
          </Text>
        </StyledLoginTitle>

        <form
          onSubmit={handleSubmitForm}
        >

          <StyledLabel>
            <Text
              variant="body2"
              color="secondary"
              hasError={passwordError || usernameError}
            >
              Логин
            </Text>
          </StyledLabel>

          <StyledField>
            <TextField
              hasError={passwordError || usernameError}
              onChange={(event) => handleChangeInput(event, setUsernameError)}
              value={value.username}
              name="username"
              type="email"
            />
            {usernameError && (
              <StyledErrorText>
                <Text
                  variant="caption1"
                  color="negative"
                >
                  {usernameErrorText}
                </Text>
              </StyledErrorText>
            )}
          </StyledField>
          <StyledLabel>
            <Text
              variant="body2"
              color="secondary"
              hasError={passwordError || usernameError}
            >
              Пароль
            </Text>
          </StyledLabel>

          <StyledFieldSecond>
            <TextField
              hasError={passwordError || usernameError}
              onChange={(event) => handleChangeInput(event, setPasswordError)}
              value={value.password}
              name="password"
              type={showPassword ? 'text' : 'password'}
            />
            <StyledSecurityIcon>
              {showPassword ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                <img
                  src={Eye}
                  alt=""
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                <img
                  src={EyeClose}
                  alt=""
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) }

            </StyledSecurityIcon>

            {passwordErrorText && (
              <StyledErrorText>
                <Text
                  variant="caption1"
                  color="negative"
                >
                  {passwordErrorText}
                </Text>
              </StyledErrorText>
            )}

          </StyledFieldSecond>
          <StyledButton>
            <Button type="submit">
              Войти в систему
            </Button>
          </StyledButton>

          <StyledRememberPassword>
            <Link href="/recovery">
              <Text
                variant="body2"
                color="primary"
              >
                Забыли пароль?
              </Text>
            </Link>
          </StyledRememberPassword>
        </form>
      </StyledLoginWrapper>
    </>
  )
}

Auth.propTypes = {
  fetchUserStart: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user: { isFetching } }) => ({
  fetching: isFetching,
})

const mapDispatchToProps = (dispatch) => ({
  fetchUserStart: (username, password) => dispatch(fetchUserStartAsync({ username, password })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Auth)

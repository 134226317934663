import React from 'react'
import { IIconProps } from './types';

export const iconsCache: Record<string, () => React.ReactElement> = {};

require.context('../../../../assets/icons', true, /\.svg$/)
  .keys()
  .forEach(key => {
    key = key.replace(/^\.\/(.*)\.svg$/, '$1');
    const name = key.replace('/', '-');
    try {
      iconsCache[name] = require(`!!react-svg-loader!../../../../assets/icons/${key}.svg`).default;
    } catch (e) {
      console.log(e)
    }
  });

export const iconSize = {
  xs: 12,
  sm: 20,
  md: 24,
  lg: 32,
}

export const Icon: React.FC<IIconProps> = ({ name = 'currency-usd', size = 24, ...props }) => {
  const Component = iconsCache[name];
  size = typeof size === 'number' ? size : iconSize[size];
  return (
    Component
      ? <Component width={size} {...props} />
      : null
  )
}

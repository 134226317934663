import React from 'react'
import { DropzoneAreaBase } from 'material-ui-dropzone'
import { AttachFile } from '@material-ui/icons'
import { Box, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import cuid from 'cuid'
import Media from 'react-media'
import b64toBlob from '../../utils/ConvertToBlob'
import { ReactComponent as IconFile } from '../../assets/images/icon-file-yellow.svg'
import { ReactComponent as IconFileClose } from '../../assets/images/close.svg'
import { ReactComponent as IconFileClosePhone } from '../../assets/images/close-nb.svg'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import useStyles from './DropZone.styles'
import CustomLoader from '../CustomLoader/CustomLoader'
import useWindowSize from '../../hooks/useWindows'

const DropZone = ({
  readOnly,
  fullWidth,
  onAdd,
  onDrop,
  onDelete,
  maxFileSize,
  maximumNumberofFiles,
  label,
  width,
  minHeight,
  fileObjects,
  isLoading,
  isNewStyle,
  mt,
  mb,
  wrapperWidth,
  padding,
}) => {
  const needBorderBottom = !fileObjects.length && !isLoading
  const classes = useStyles({
    minHeight,
    fullWidth,
    width,
    needBorderBottom,
    padding,
  })
  const { windowWidth } = useWindowSize()

  const iff = (condition, then, otherwise) => (condition < 474 ? then : otherwise)

  const dropZoneTextByStyle = isNewStyle ? label : iff(windowWidth, 'Прикрепить файл', label)

  return (
    <Box
      mt={mt}
      mb={mb}
      width={wrapperWidth}
    >
      {!readOnly && (
        <DropzoneAreaBase
          clearOnUnmount
          Icon={isNewStyle ? UploadIcon : AttachFile}
          dropzoneProps={{
            disabled: readOnly,
          }}
          inputProps={{
            disabled: readOnly,
          }}
          filesLimit={maximumNumberofFiles}
          getFileAddedMessage={(fileName) => `Файл ${fileName} успешно добавлен.`}
          getFileRemovedMessage={(fileName) => `Файл ${fileName} удален.`}
          showPreviewsInDropzone={false}
          dropzoneClass={isNewStyle ? classes.dropZone__new : classes.dropZone}
          dropzoneText={readOnly ? '' : dropZoneTextByStyle}
          maxFileSize={maxFileSize}
          onAdd={onAdd}
          onDrop={onDrop}
          fileObjects={fileObjects}
          showAlerts={false}
        />
      )}
      {(!!fileObjects.length || isLoading) && (
        <div
          className={`${
            isNewStyle ? classes.dropZone__container_new : classes.dropZone__container
          } ${
            readOnly
            && (isNewStyle ? classes.dropZone__containerBorder_new : classes.dropZone__containerBorder)
          }`}
        >
          {isLoading && (
            <div>
              <Media
                query="(max-width: 576px)"
                render={() => (
                  <CustomLoader
                    text={isNewStyle ? 'Загрузка файла' : 'Загрузка файла . . .'}
                    length={16}
                  />
                )}
              />
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <div>
                    <CustomLoader
                      text="Загрузка файла"
                      isNew={isNewStyle}
                    />
                  </div>
                )}
              />
            </div>
          )}
          <div
            className={
              isNewStyle ? classes.dropZone__containerInner_new : classes.dropZone__containerInner
            }
          >
            {fileObjects.map(
              (file) => file?.data && (
              <div
                className={
                      isNewStyle
                        ? classes.dropZone__containerItem_new
                        : classes.dropZone__containerItem
                    }
                key={cuid()}
              >
                {!readOnly && (
                <Button
                  className={
                          isNewStyle
                            ? classes.dropZone__removeButton_new
                            : classes.dropZone__removeButton
                        }
                  onClick={() => {
                    onDelete(file)
                  }}
                >
                  <Media
                    query="(min-width: 577px)"
                    render={() => (isNewStyle ? <CloseIcon /> : <IconFileClose />)}
                  />
                  <Media
                    query="(max-width: 576px)"
                    render={() => <IconFileClosePhone />}
                  />
                </Button>
                )}
                <a
                  href={URL.createObjectURL(
                    b64toBlob(file?.data?.split('base64,')[1] || file?.data),
                  )}
                  download={file?.file?.name}
                >
                  {isNewStyle ? null : <IconFile />}
                  <span
                    className={
                          isNewStyle ? classes.dropZone__fileName_new : classes.dropZone__fileName
                        }
                  >
                    {file?.file?.name}
                  </span>
                </a>
              </div>
              ),
            )}
          </div>
        </div>
      )}
    </Box>
  )
}

DropZone.defaultProps = {
  fullWidth: PropTypes.func,
  width: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  fileObjects: PropTypes.array,
  onAdd: PropTypes.func,
  onDrop: PropTypes.func,
  readOnly: false,
  maxFileSize: 30000000,
  onDelete: PropTypes.func,
  label: 'Прикрепить файл или перетащите в это поле',
  minHeight: 45,
  isLoading: false,
  maximumNumberofFiles: PropTypes.number,
  isNewStyle: false,
  mt: 2,
  mb: 2,
  wrapperWidth: undefined,
  padding: undefined,
}

DropZone.propTypes = {
  readOnly: PropTypes.bool,
  fileObjects: PropTypes.oneOfType([PropTypes.array]),
  fullWidth: PropTypes.func,
  width: PropTypes.func,
  onAdd: PropTypes.func,
  onDrop: PropTypes.func,
  onDelete: PropTypes.func,
  maxFileSize: PropTypes.number,
  maximumNumberofFiles: PropTypes.number,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  minHeight: PropTypes.number,
  isNewStyle: PropTypes.bool,
  mt: PropTypes.number,
  mb: PropTypes.number,
  wrapperWidth: PropTypes.string,
  padding: PropTypes.string,
}

export default DropZone

const statuses = {
  draft: 'Черновик',
  created: 'Создано клиентом',
  cancelled: 'Отменено клиентом',
  executed: 'Исполнено',
  processing: 'На исполнении',
  received: 'Получено исполнителем',
  rejected: 'Отказ в исполнении',
  rejectedbyclient: 'Отклонено клиентом',
  sent: 'Отправлено исполнителю',
  signed: 'Подписано клиентом',
  clientsigned: 'Подписано',
  matrixconfirmed: 'Подтверждено',
  needclientsign: 'На подписи',
}
export const getStatus = (status) => statuses[status.toLowerCase()]

const typeAppeals = {
  call: 'Обращение',
  claim: 'Жалоба',
  question: 'Вопрос',
  suggestion: 'Предложение',
}
export const getAppeal = (appeal) => typeAppeals[appeal.toLowerCase()]

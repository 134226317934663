import React from 'react'
import { Accordion } from 'ui/components/molecules/Accordion'
import { Field, Select } from 'ui/components/molecules'
import { FormCol, FormRow, TextField } from 'ui/components/atoms'

import type { FC } from 'react'
import type { TaxAuthorityInfoProps } from './types'
import { StyledTaxAuthorityInfo } from './styled'
import CustomerBlocksAccordion from '../CustomerBlocksAccordion'
import useMedia from 'hooks/useMedia'

/**
 * Сведения об учёте в налоговом органе.
 *
 * @param inn - ИНН.
 * @param kpp - КПП.
 * @param tin - TIN.
 * @param kio - КИО.
 * @param taxResidency - Налоговое резидентство.
 * @param isOpen - Открыт ли по умолчанию аккордеон.
 *
 * @constructor
 */
export const TaxAuthorityInfo: FC<TaxAuthorityInfoProps> = ({
  inn = '',
  kpp = '',
  tin = '',
  kio = '',
  taxResidency = null,
  isOpen = false,
}) => {
  const { isTablet } = useMedia()

  const tinField = (
    <Field label="TIN">
      <TextField disabled value={tin} />
    </Field>
  )

  const kioField = (
    <Field label="КИО">
      <TextField disabled value={kio} />
    </Field>
  )

  const nameField = (
    <Field label="Налоговое резидентство">
      <Select isDisabled value={taxResidency?.uid === 'RUS' ? 'Резидент РФ' : 'Нерезидент РФ'} />
    </Field>
  )

  return (
    <CustomerBlocksAccordion
      bodyPaddingTop={28}
      title="Сведения об учёте в&nbsp;налоговом органе"
      isOpenDefault={isOpen}
    >
      <FormCol gapTablet={28} gapMobile={24}>
        <FormRow gap={16} tabletRowDirection gapTablet={16} gapMobile={24}>
          {/*
          ИНН
        */}
          <Field label="ИНН">
            <TextField disabled value={inn} />
          </Field>

          {/*
          КПП
        */}
          <Field label="КПП">
            <TextField disabled value={kpp} />
          </Field>

          {/*
          TIN
        */}
          {/*
          КИО
        */}
          {!isTablet && (
            <>
              {tinField}
              {kioField}
              {nameField}
            </>
          )}
        </FormRow>

        {isTablet && (
          <>
            <FormRow tabletRowDirection gapTablet={16} gapMobile={24}>
              {tinField}
              {kioField}
            </FormRow>

            {nameField}
          </>
        )}
      </FormCol>
    </CustomerBlocksAccordion>
  )
}

import { request } from './Request'
import { asyncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getAccountsInvestments() {
    return request(asyncApiURL, endpoints.investmentsNotices.getAccountsInvestments)
  },
  getAgreementsInvestments() {
    return request(asyncApiURL, endpoints.investmentsNotices.getAgreementsInvestments)
  },
  getAccountsDepo() {
    return request(asyncApiURL, endpoints.investmentsNotices.getAccountsDepo)
  },
  getAgreementsDepo() {
    return request(asyncApiURL, endpoints.investmentsNotices.getAgreementsDepo)
  },
  getMoneyRequisitesFilter(data) {
    return request(asyncApiURL, endpoints.investmentsNotices.moneyRequisitesFilter(data))
  },
  getDepoRequisitesFilter(data) {
    return request(asyncApiURL, endpoints.investmentsNotices.depoRequisitesFilter(data))
  },
}

import React, { useEffect, useState } from 'react'
import {
  Switch, Route, Link, Redirect, useRouteMatch, useLocation,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import Media from 'react-media'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import useStyles from './tab.style'
import useWindowSize from '../../hooks/useWindows'

export default function TabsComponent({
  tabLinks, wishStyle, hideTabsOnUp, firstStepOverOnMobile,
}) {
  const classes = useStyles(wishStyle)
  const { windowWidth } = useWindowSize()
  const [firstNestingVisibility, setFirstNestingVisibility] = useState(true)
  const { url, path } = useRouteMatch()
  const location = useLocation()
  const { pathname } = location
  const currentIndex = tabLinks.indexOf(tabLinks?.find((l) => l.link === pathname.split('/')[2]))
  const isIndexGreaterThanZero = currentIndex > 0

  const [tabValue, setTabValue] = useState((isIndexGreaterThanZero && currentIndex) || 0)

  useEffect(() => {
    if (windowWidth <= 576) {
      if ((pathname.match(/\//g) || []).length >= 2) {
        setFirstNestingVisibility(false)
      } else {
        setFirstNestingVisibility(true)
      }
    } else {
      setFirstNestingVisibility(true)
    }
    setTabValue((isIndexGreaterThanZero && currentIndex) || 0)
  }, [windowWidth, pathname, isIndexGreaterThanZero, currentIndex])

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <>
      <Media
        query={hideTabsOnUp ? `(min-width: ${hideTabsOnUp}px)` : '(min-width: 0px)'}
        render={() => firstNestingVisibility
          && (
            <div className={classes.tabsWrapper}>
              <Tabs
                value={tabValue}
                variant="standard"
                onChange={handleChange}
                aria-label="portfolio tabs"
                className={classes.tab}
                TabIndicatorProps={{
                  style: {
                    display: 'none',
                  },
                }}
              >
                {tabLinks.map((currentLink) => (
                  <Tab
                    key={currentLink.key}
                    component={Link}
                    to={`${url}/${currentLink.link}`}
                    icon={currentLink.icon}
                    label={currentLink.label}
                    disableRipple
                  />
                ))}
              </Tabs>
            </div>
          )}
      />

      <Switch>
        <Route
          exact
          path={path}
        >
          {windowWidth <= 576 && (hideTabsOnUp || firstStepOverOnMobile) ? (
            <Redirect to={`${path}/${tabLinks[0].link}`} />
          ) : null}
          {windowWidth > 577 && <Redirect to={`${path}/${tabLinks[0].link}`} />}
        </Route>
        {tabLinks.map((currentLink) => (
          <Route
            key={currentLink.key}
            exact={currentLink.exact}
            path={`${path}/${currentLink.link}`}
            component={currentLink.component}
          />
        ))}
      </Switch>
    </>
  )
}

TabsComponent.defaultProps = {
  tabLinks: [],
  wishStyle: {},
  hideTabsOnUp: 0,
  firstStepOverOnMobile: false,
}

TabsComponent.propTypes = {
  tabLinks: PropTypes.oneOfType([PropTypes.array]),
  wishStyle: PropTypes.oneOfType([PropTypes.object]),
  hideTabsOnUp: PropTypes.number,
  firstStepOverOnMobile: PropTypes.bool,
}

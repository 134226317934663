import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import classnames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Media from 'react-media'
import _ from 'lodash'
import useConnect from '../../utils/Connect'
import GetPrettyNumber from '../../utils/GetPrettyNumber'
import endpoints from '../../api/endpoints'
import { fetchTotalStartAsync, getTotalSuccess } from '../../redux/portfolio/portfolioSlice'
import { selectGetTotal } from '../../redux/portfolio/selector'
import useStyles from './panel.styles'
import PageHeading from '../PageHeading/PageHeading'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import Actives from '../Actives/Actives'
import getPercent from '../../utils/GetPercent'
import { fetchStatusStartAsync, getStatusSuccess } from '../../redux/user/userSlice'

const CountPanel = ({ fetchTotalStart, connectedWS }) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [totalAssets, setTotalAssets] = useState(0)
  const dispatch = useDispatch()

  const fetchGetStatusStart = () => {
    dispatch(fetchStatusStartAsync())
  }

  const {
    getData: getTotal,
  } = useConnect({
    link: `GET /api${endpoints.portfolio.total}`,
    startFetching: fetchTotalStart,
    action: getTotalSuccess,
  })

  const {
    getData: getStatus,
  } = useConnect({
    link: `GET /api${endpoints.auth.status}`,
    startFetching: fetchGetStatusStart,
    action: getStatusSuccess,
  })

  const total = useSelector(selectGetTotal)

  useEffect(() => {
    if (connectedWS) {
      getTotal()
      getStatus()
    }
    return () => {
      location.state = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  useEffect(() => {
    if (total) {
      setTotalAssets(0)
      _.map(total?.valuations, (item) => {
        setTotalAssets((prev) => prev + item.valuationAssets)
      })
    }
  }, [total])

  const handleClickAccount = (id) => {
    const nextLocation = {
      pathname: 'class_tools',
      state: { activeAccount: id },
    }
    history.push(nextLocation)
  }

  return (
    <>
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            <Box mb="19px">
              <PageHeading text="Счета" />
            </Box>
            <Box mb="20px">
              <Actives />
            </Box>
          </>
        )}
      />
      <Box>
        {total && total?.valuations?.map((item) => {
          const { id, number } = item.account
          const {
            valuationAssets,
            valuationAssetsDelta,
            valuationAssetsDeltaPercents,
          } = item

          return (
            <Grid
              key={id}
              container
              className={classes.countRow}
              justify="space-between"
              onClick={() => handleClickAccount(id)}
            >
              <Grid
                item
                xs={12}
                sm={4}
                lg={2}
                className={classes.countCol}
              >
                <span className={classes.countRowTitle}>
                  Счёт №&nbsp;
                  {number}
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                lg={2}
                className={classnames(classes.countCol, classes.countCol_unique)}
              >
                <span className={classes.countTitle}>
                  Инвестиционный
                </span>
                <span className={classnames(classes.countText, classes.countPercent)}>
                  {`${getPercent(valuationAssets, totalAssets)} %`}
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                lg={2}
                className={classes.countCol}
              >
                <span className={classes.countTitle}>
                  Активы, RUB
                </span>
                <span className={classes.countText}>
                  {GetPrettyNumber(valuationAssets)}
                </span>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                lg={2}
                className={classes.countCol}
              >
                <span className={classes.countTitle}>
                  Результат, RUB
                </span>
                <span className={classes.countText}>
                  {GetPrettyNumber(valuationAssetsDelta)}
                </span>
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                lg={2}
                className={classes.countCol}
              >
                <span className={classes.countTitle}>
                  Изменение, %
                </span>
                <span className={classes.countText}>
                  {valuationAssetsDeltaPercents.toFixed(2)}
                  {' '}
                  %
                </span>
              </Grid>
            </Grid>
          )
        })}
      </Box>
    </>
  )
}

CountPanel.propTypes = {
  fetchTotalStart: PropTypes.func.isRequired,
  connectedWS: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTotalStart: () => dispatch(fetchTotalStartAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CountPanel)

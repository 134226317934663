import {useEffect} from "react";

export const useDisableBodyScroll = (open) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden !important';
    } else {
      document.body.style.overflow = 'unset !important';
    }
  }, [open]);
};

export default useDisableBodyScroll

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from './sharedStyles'
import CustomInput from '../../CustomControls/CustomInput'
import CustomSelect from '../../CustomControls/CustomSelect'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`firmcontent-tabpanel-${index}`}
      aria-labelledby={`firmcontent-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const FirmContent = ({ data, handleClickNextPanel, isNeedNextButton }) => {
  const classes = useStyles()

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const countryNameEn = data?.registerCountry?.nameEn
  const countryNameRu = data?.registerCountry?.nameRu

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.contentTabs}
              aria-label="firm content tab "
            >
              <Tab label="сведения на русском" />
              <Tab label="сведения на иностранном" />
            </Tabs>

            <TabPanel
              value={value}
              index={0}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className={classes.row}
                >
                  <CustomInput
                    name="fullName"
                    label="Полное наименование на русском"
                    value={data?.fullNameRu || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    unique
                  />
                </Grid>
                <Grid
                  item
                  container
                  className={classes.row}
                  justify="space-between"
                  spacing={2}
                >
                  <Grid
                    item
                    sm={7}
                    xs={12}
                  >
                    <CustomInput
                      name="shortName"
                      label="Краткое наименование на русском"
                      value={data?.shortNameRu || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    sm={5}
                    xs={12}
                  >
                    <CustomSelect
                      label="Страна регистрации"
                      value={countryNameRu || ''}
                      unique
                    >
                      <MenuItem value={countryNameRu || ''}>
                        {countryNameRu || ''}
                      </MenuItem>
                    </CustomSelect>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CustomInput
                    name="regPlace"
                    className={classes.longLabelField}
                    label="Место государственной регистрации (местонахождение) на русском языке"
                    value={data?.addressRu || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    unique
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className={classes.row}
                >
                  <CustomInput
                    name="fullName"
                    label="Полное наименование на английском"
                    value={data?.fullNameEn || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    unique
                  />
                </Grid>
                <Grid
                  item
                  container
                  className={classes.row}
                  justify="space-between"
                  spacing={2}
                >
                  <Grid
                    item
                    sm={7}
                    xs={12}
                  >
                    <CustomInput
                      name="shortName"
                      label="Краткое наименование на английском"
                      value={data?.shortNameEn || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    sm={5}
                    xs={12}
                  >
                    <CustomSelect
                      label="Страна регистрации"
                      value={countryNameEn || ''}
                      unique
                    >
                      <MenuItem value={countryNameEn || ''}>
                        {countryNameEn || ''}
                      </MenuItem>
                    </CustomSelect>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CustomInput
                    name="regPlace"
                    className={classes.longLabelField}
                    label="Место государственной регистрации (местонахождение) на английском языке"
                    value={data?.addressEn || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    unique
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            {data?.fullNameRu && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Полное наименование
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.fullNameRu}
                </div>
              </div>
            )}
            {data?.shortNameRu && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Краткое наименование
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.shortNameRu}
              </div>
            </div>
            )}
            {countryNameRu && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Страна регистрации
              </div>
              <div className={classes.Mobile__blockText}>
                {countryNameRu}
              </div>
            </div>
            )}
            {data?.addressRu && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Место государственной регистрации (местонахождение)
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.addressRu}
              </div>
            </div>
            )}
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </>
        )}
      />
    </>
  )
}

FirmContent.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

FirmContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

export default FirmContent

import { styled } from "ui/theme";

export const StyledCollapsibleTable = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const StyledCollapsibleTableHeader = styled.div<{ layout: any }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ layout }) => layout.map((c) => c || '1fr').join(' ')};
  padding: 8px 24px;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`

export const StyledCollapsibleTableHeaderCell = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs};
  ${({ theme }) => theme.font.caption1}

  justify-content: flex-start;
`

export const StyledCollapsibleTableBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxs};
`

export const StyledChildrenTableRow = styled.div<{ layout: any }>`
  display: grid;
  grid-template-columns: ${({ layout }) => layout.map((c) => c || '1fr').join(' ')};
  width: 100%;

  padding: 18px 24px;

  font-weight: 400;
  line-height: 18px;
  font-size: 14px;

  background-color: ${({ theme }) => theme.color.bg.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  cursor: pointer;

  align-items: center;
`

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Media from 'react-media'
import classnames from 'classnames'
import Box from '@material-ui/core/Box'
import { Grid, makeStyles } from '@material-ui/core'
import { isValid } from 'date-fns'
import CollapseBar from '../../CollapseBar/CollapseBar'
import FirmContent from '../collapseContents/firmContent'
import GovRegContent from '../collapseContents/govRegContent'
import LawRegToDateContent from '../collapseContents/lawRegToDateContent'
import AddressContent from '../collapseContents/addressContent'
import PostAddressContent from '../collapseContents/postAddressContent'
import StandPlaceContent from '../collapseContents/standPlaceContent'
import GoovCodesContent from '../collapseContents/goovCodesContent'
import ContactInfosContent from '../collapseContents/contactInfosContent'
import { selectFormsData } from '../../../redux/profilePanel/selector'
import { selectCurrentUser } from '../../../redux/user/selector'
import CustomInput from '../../CustomControls/CustomInput'
import GeneralInfo from '../collapseContents/generalInfo'
import IdentificationContent from '../collapseContents/identificationContent'
import OperationsPlanContent from '../collapseContents/operationsPlanContent'
import RequisitesContent from '../collapseContents/requisites'
import BeneficiariesPanel from '../additionalInformationPanels/beneficiariesPanel'
import GeneralPanel from './generalPanel'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'
import { nextBar, expandedToggle } from '../../../utils/MoveBetweenBars'
import { isEmpty } from '../../../utils/Validate'

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    paddingTop: 50,
    '@media(max-width: 576px)': {
      paddingTop: 11,
    },
  },
  rowMb: {
    marginBottom: '2rem',
  },
  mediumLabelField: {
    '& > .MuiFormLabel-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
  },
  Phone__fullName: {
    fontWeight: 600,
    paddingBottom: 10,
  },
  Phone__header: {
    color: '#91B1BF',
  },
  Phone__text: {
    fontWeight: 600,
  },
  Phone__block: {
    paddingBottom: 2,
  },
  Phone__generalBlock: {
    paddingBottom: 15,
  },
  Phone__beneficiarBlock: {
    paddingBottom: 15,
  },
  Phone__hideBlock: {
    display: 'none',
  },
  Phone__expandedBar: {
    '& .MuiAccordionSummary-root': {
      marginLeft: -20,
      paddingLeft: 20,
      marginRight: -20,
      paddingRight: 20,

      '&.Mui-expanded': {
        marginBottom: 15,
      },
      '& .MuiAccordionSummary-expandIcon': {
        display: 'none',
      },
      '& .Mui-expanded > .MuiTypography-root': {
        color: '#fff',
      },
    },
  },
}))

export default function ProfilePanel() {
  const classes = useStyles()
  const [panel, setPanel] = useState(false)
  const respGetData = useSelector(selectFormsData)?.forms[0]?.list[0]
  const legalEntity = useSelector(selectCurrentUser)?.client?.legalEntity
  const listFirm = respGetData?.customer
  const listPerson = respGetData?.clientInfo?.customer
  const clientInfo = respGetData?.clientInfo
  const isQualifiedInvestor = respGetData?.clientInfo?.isQualifiedInvestor
  const individual = listPerson || respGetData?.customer
  const fullName = listPerson?.fullName || listFirm?.fullNameRu
  const bankRequisites = respGetData?.bankRequisites
  const beneficiaries = respGetData?.vip?.beneficiaries?.length > 0
  || (respGetData?.beneficiary && Object.keys(respGetData?.beneficiary).length)

  const openedAddress = Object.values(individual?.address || {}).some(
    (item) => !isEmpty(item),
  )

  const openedMailAddress = Object.values(individual?.postAddress || {}).some(
    (item) => !isEmpty(item),
  )

  const openedContacts = !!individual?.phones?.length
    || !!individual?.emails?.length
    || !!individual?.faxes?.length
    || !!individual?.otherContact

  const openedName = !!listFirm?.fullNameRu
    || !!listFirm?.shortNameRu
    || !!listFirm?.addressRu

  const openedTax = !!listFirm?.inn
    || !!listFirm?.kpp
    || !!listFirm?.tin
    || !!listFirm?.kio
    || !!listFirm?.taxResidency

  const openedCodes = !!listFirm?.okpo
    || !!listFirm?.okato
    || !!listFirm?.okved
    || !!listFirm?.okfs
    || !!listFirm?.lei
    || !!listFirm?.otherStatisticsCodes

  const hasOgrnDetails = !!listFirm?.rusRegisterNumber
    || isValid(new Date(listFirm?.rusRegisterDate))
    || !!listFirm?.rusRegisterIssuer
    || !!listFirm?.rusRegisterLocation

  const hasRegJurFields = !!listFirm?.registerNumber
    || isValid(new Date(listFirm?.registerDate))
    || !!listFirm?.registerIssuer

  const openedId = (listPerson?.identity
    && Object.keys(listPerson?.identity).length)
      || (listPerson?.identity
        && Object.keys(listPerson?.migrationCard).length)
      || (listPerson?.identity
        && Object.keys(listPerson?.legalResidence).length)

  const openedInfo = isValid(new Date(listPerson?.birthDate))
      || !!listPerson?.birthPlace
      || !!listPerson?.nationality
      || !!listPerson?.otherNationalities
      || !!listPerson?.taxCountry
      || !!listPerson?.inn
      || !!listPerson?.tin
      || !!listPerson?.snils

  const hasPlanned = !!individual?.planned

  const mapBarsFirmNotResident = [
    ['openedName', 'firmName', openedName],
    ['ogrnDetails', 'govRegistr', hasOgrnDetails],
    ['openedMailAddress', 'postAddress', openedMailAddress],
    ['openedTax', 'standPlace', openedTax],
    ['openedCodes', 'goovCodes', openedCodes],
    ['openedContacts', 'contactInfo', openedContacts],
    ['planned', 'planned', hasPlanned],
    ['bankRequisites', 'requisites', bankRequisites],
  ]

  const mapBarsFirmResident = [
    ['openedName', 'firmName', openedName],
    ['ogrnDetails', 'govRegistr', hasOgrnDetails],
    ['regJurFields', 'lawRegToDate', hasRegJurFields],
    ['openedAddress', 'firmAddress', openedAddress],
    ['openedMailAddress', 'postAddress', openedMailAddress],
    ['openedTax', 'standPlace', openedTax],
    ['openedCodes', 'goovCodes', openedCodes],
    ['openedContacts', 'contactInfo', openedContacts],
    ['planned', 'planned', hasPlanned],
    ['bankRequisites', 'requisites', bankRequisites],
  ]

  const mapBarsLegal = [
    ['openedInfo', 'generalPanel', openedInfo],
    ['openedId', 'identityCard', openedId],
    ['openedAddress', 'address', openedAddress],
    ['openedMailAddress', 'postAddress', openedMailAddress],
    ['openedContacts', 'contactInfo', openedContacts],
    ['planned', 'planned', hasPlanned?.length],
    ['bankRequisites', 'requisites', bankRequisites?.length],
  ]

  const getMapBar = () => {
    let mapBar = null
    if (legalEntity === 'firm' && listFirm?.registerCountry?.uid !== 'RUS') {
      mapBar = mapBarsFirmNotResident
    } else if (legalEntity === 'firm' && listFirm?.registerCountry?.uid === 'RUS') {
      mapBar = mapBarsFirmResident
    } else {
      mapBar = mapBarsLegal
    }
    return mapBar
  }

  const haveNextBar = (currentBar, needBool) => nextBar(getMapBar(), currentBar, needBool)

  const handleChange = (newPanel, needNextPanel = false) => (event, newExpanded) => {
    expandedToggle({
      expanded: panel,
      setExpanded: setPanel,
      newPanel,
      newExpanded,
      needNextPanel,
      mapBar: getMapBar(),
    })
  }

  const handleRollUpBar = () => setPanel(false)

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box className={classes.boxWrapper}>
      {(legalEntity && (((legalEntity === 'firm') && (
        (((listFirm?.registerCountry && (listFirm?.registerCountry.uid !== 'RUS')) && (
        <>
          <Media
            query="(min-width: 577px)"
            render={() => (
              <>
                <CollapseBar
                  defaultExpanded={openedName}
                  label="Фирменное наименование с указанием организационно-правовой формы"
                >
                  <FirmContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={hasOgrnDetails}
                  label="Сведения о государственной регистрации"
                >
                  <GovRegContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedMailAddress}
                  label="Почтовый адрес"
                >
                  <PostAddressContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedTax}
                  label="Сведения об учёте в налоговом органе"
                >
                  <StandPlaceContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedCodes}
                  label="Коды форм федерального государственного статистического наблюдения"
                >
                  <GoovCodesContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedContacts}
                  label="Контактная информация"
                >
                  <ContactInfosContent data={listFirm || null} />
                </CollapseBar>
              </>
            )}
          />
          <Media
            query={`(max-width: ${breakpoints.phone_large}px)`}
            render={() => (
              <>
                <div
                  className={classes.Phone__fullName}
                  onClick={handleRollUpBar}
                  aria-hidden="true"
                >
                  {fullName}
                </div>
                <div className={
                  classnames(
                    classes.Phone__generalBlock,
                    !!panel && classes.Phone__hideBlock,
                  )
                }
                >
                  {listFirm?.phones && (
                    <div className={classes.Phone__block}>
                      <div className={classes.Phone__header}>Телефон</div>
                      <span className={classes.Phone__text}>{listFirm?.phones?.join(', ') || ''}</span>
                    </div>
                  )}
                  {listFirm?.emails && (
                    <div className={classes.Phone__block}>
                      <div className={classes.Phone__header}>E-mail</div>
                      <span className={classes.Phone__text}>{listFirm?.emails?.join(', ') || ''}</span>
                    </div>
                  )}
                </div>
                {openedName && (
                  <CollapseBar
                    expanded={panel === 'firmName'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Фирменное наименование с указанием организационно-правовой формы"
                    onChange={handleChange('firmName')}
                    className={classnames(((!!panel && panel !== 'firmName') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <FirmContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedName', true)}
                      isNeedNextButton={haveNextBar('openedName', true)}
                    />
                  </CollapseBar>
                )}
                {hasOgrnDetails && (
                  <CollapseBar
                    expanded={panel === 'govRegistr'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Сведения о государственной регистрации"
                    onChange={handleChange('govRegistr')}
                    className={classnames(((!!panel && panel !== 'govRegistr') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <GovRegContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('ogrnDetails', true)}
                      isNeedNextButton={haveNextBar('ogrnDetails', true)}
                    />
                  </CollapseBar>
                )}
                {openedMailAddress && (
                  <CollapseBar
                    expanded={panel === 'postAddress'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Почтовый адрес"
                    onChange={handleChange('postAddress')}
                    className={classnames(((!!panel && panel !== 'postAddress') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <PostAddressContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedMailAddress', true)}
                      isNeedNextButton={haveNextBar('openedMailAddress', true)}
                    />
                  </CollapseBar>
                )}
                {openedTax && (
                  <CollapseBar
                    expanded={panel === 'standPlace'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Сведения об учёте в налоговом органе"
                    onChange={handleChange('standPlace')}
                    className={classnames(((!!panel && panel !== 'standPlace') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <StandPlaceContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedTax', true)}
                      isNeedNextButton={haveNextBar('openedTax', true)}
                    />
                  </CollapseBar>
                )}
                {openedCodes && (
                  <CollapseBar
                    expanded={panel === 'goovCodes'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Коды форм федерального государственного статистического наблюдения"
                    onChange={handleChange('goovCodes')}
                    className={classnames(((!!panel && panel !== 'goovCodes') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <GoovCodesContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedCodes', true)}
                      isNeedNextButton={haveNextBar('openedCodes', true)}
                    />
                  </CollapseBar>
                )}
                {openedContacts && (
                  <CollapseBar
                    expanded={panel === 'contactInfo'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Контактная информация"
                    onChange={handleChange('contactInfo')}
                    className={classnames(((!!panel && panel !== 'contactInfo') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <ContactInfosContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedContacts', true)}
                      isNeedNextButton={haveNextBar('openedContacts', true)}
                    />
                  </CollapseBar>
                )}
                {hasPlanned && (
                  <CollapseBar
                    expanded={panel === 'planned'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Планируемые операции"
                    onChange={handleChange('planned')}
                    className={classnames(((!!panel && panel !== 'planned') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <OperationsPlanContent
                      data={listPerson || null}
                      handleClickNextPanel={handleChange('planned', true)}
                      isNeedNextButton={haveNextBar('planned', true)}
                    />
                  </CollapseBar>
                )}
                {bankRequisites?.length > 0 && (
                  <CollapseBar
                    expanded={panel === 'requisites'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Реквизиты"
                    onChange={handleChange('requisites')}
                    className={classnames(((!!panel && panel !== 'requisites') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <RequisitesContent
                      template="settlements"
                      data={bankRequisites || null}
                    />
                  </CollapseBar>
                )}
                {beneficiaries && (
                  <CollapseBar
                    expanded={panel === 'beneficiaries'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Бенефициары"
                    onChange={handleChange('beneficiaries')}
                    className={classnames(((!!panel && panel !== 'beneficiaries') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <BeneficiariesPanel />
                  </CollapseBar>
                )}
                <CollapseBar
                  expanded={panel === 'other'}
                  barColor={panel ? '#91B1BF' : '#dfe8ed'}
                  label="Прочее"
                  onChange={handleChange('other')}
                  className={classnames(((!!panel && panel !== 'other') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                >
                  <GeneralPanel />
                </CollapseBar>
              </>
            )}
          />
        </>
        )) || (
        <>
          <Media
            query="(min-width: 577px)"
            render={() => (
              <>
                <CollapseBar
                  defaultExpanded={openedName}
                  label="Фирменное наименование с указанием организационно-правовой формы"
                >
                  <FirmContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={hasOgrnDetails}
                  label="Сведения о государственной регистрации"
                >
                  <GovRegContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={hasRegJurFields}
                  label="Для юридических лиц, зарегистрированных на территории Российской Федерации до 01 июля 2002 года"
                >
                  <LawRegToDateContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedAddress}
                  label="Адрес в соответствие с единым государственным реестром юридических лиц (ЕГРЮЛ)"
                >
                  <AddressContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedMailAddress}
                  label="Почтовый адрес"
                >
                  <PostAddressContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedTax}
                  label="Сведения об учёте в налоговом органе"
                >
                  <StandPlaceContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedCodes}
                  label="Коды форм федерального государственного статистического наблюдения"
                >
                  <GoovCodesContent data={listFirm || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedContacts}
                  label="Контактная информация"
                >
                  <ContactInfosContent data={listFirm || null} />
                </CollapseBar>
              </>
            )}
          />
          <Media
            query={`(max-width: ${breakpoints.phone_large}px)`}
            render={() => (
              <>
                <div
                  className={classes.Phone__fullName}
                  onClick={handleRollUpBar}
                  aria-hidden="true"
                >
                  {fullName}
                </div>
                <div className={
                  classnames(
                    classes.Phone__generalBlock,
                    !!panel && classes.Phone__hideBlock,
                  )
                }
                >
                  {listFirm?.phones && (
                    <div className={classes.Phone__block}>
                      <div className={classes.Phone__header}>Телефон</div>
                      <span className={classes.Phone__text}>{listFirm?.phones?.join(', ') || ''}</span>
                    </div>
                  )}
                  {listFirm?.emails && (
                    <div className={classes.Phone__block}>
                      <div className={classes.Phone__header}>E-mail</div>
                      <span className={classes.Phone__text}>{listFirm?.emails?.join(', ') || ''}</span>
                    </div>
                  )}
                </div>
                {openedName && (
                  <CollapseBar
                    barTextColor="#235"
                    expanded={panel === 'firmName'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Фирменное наименование с указанием организационно-правовой формы"
                    onChange={handleChange('firmName')}
                    className={classnames(((!!panel && panel !== 'firmName') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <FirmContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedName', true)}
                      isNeedNextButton={haveNextBar('openedName', true)}
                    />
                  </CollapseBar>
                )}
                {hasOgrnDetails && (
                  <CollapseBar
                    expanded={panel === 'govRegistr'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Сведения о государственной регистрации"
                    onChange={handleChange('govRegistr')}
                    className={classnames(((!!panel && panel !== 'govRegistr') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <GovRegContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('ogrnDetails', true)}
                      isNeedNextButton={haveNextBar('ogrnDetails', true)}
                    />
                  </CollapseBar>
                )}
                {hasRegJurFields && (
                  <CollapseBar
                    expanded={panel === 'lawRegToDate'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Для юридических лиц, зарегистрированных на территории Российской Федерации до 01 июля 2002 года"
                    onChange={handleChange('lawRegToDate')}
                    className={classnames(((!!panel && panel !== 'lawRegToDate') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <LawRegToDateContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('regJurFields', true)}
                      isNeedNextButton={haveNextBar('regJurFields', true)}
                    />
                  </CollapseBar>
                )}
                {openedAddress && (
                  <CollapseBar
                    expanded={panel === 'firmAddress'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Адрес в соответствие с единым государственным реестром юридических лиц (ЕГРЮЛ)"
                    onChange={handleChange('firmAddress')}
                    className={classnames(((!!panel && panel !== 'firmAddress') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <AddressContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedAddress', true)}
                      isNeedNextButton={haveNextBar('openedAddress', true)}
                    />
                  </CollapseBar>
                )}
                {openedMailAddress && (
                  <CollapseBar
                    expanded={panel === 'postAddress'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Почтовый адрес"
                    onChange={handleChange('postAddress')}
                    className={classnames(((!!panel && panel !== 'postAddress') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <PostAddressContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedMailAddress', true)}
                      isNeedNextButton={haveNextBar('openedMailAddress', true)}
                    />
                  </CollapseBar>
                )}
                {openedTax && (
                  <CollapseBar
                    expanded={panel === 'standPlace'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Сведения об учёте в налоговом органе"
                    onChange={handleChange('standPlace')}
                    className={classnames(((!!panel && panel !== 'standPlace') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <StandPlaceContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedTax', true)}
                      isNeedNextButton={haveNextBar('openedTax', true)}
                    />
                  </CollapseBar>
                )}
                {openedCodes && (
                  <CollapseBar
                    expanded={panel === 'goovCodes'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Коды форм федерального государственного статистического наблюдения"
                    onChange={handleChange('goovCodes')}
                    className={classnames(((!!panel && panel !== 'goovCodes') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <GoovCodesContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedCodes', true)}
                      isNeedNextButton={haveNextBar('openedCodes', true)}
                    />
                  </CollapseBar>
                )}
                {openedContacts && (
                  <CollapseBar
                    expanded={panel === 'contactInfo'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Контактная информация"
                    onChange={handleChange('contactInfo')}
                    className={classnames(((!!panel && panel !== 'contactInfo') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <ContactInfosContent
                      data={listFirm || null}
                      handleClickNextPanel={handleChange('openedContacts', true)}
                      isNeedNextButton={haveNextBar('openedContacts', true)}
                    />
                  </CollapseBar>
                )}
                {hasPlanned && (
                  <CollapseBar
                    expanded={panel === 'planned'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Планируемые операции"
                    onChange={handleChange('planned')}
                    className={classnames(((!!panel && panel !== 'planned') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <OperationsPlanContent
                      data={listPerson || null}
                      handleClickNextPanel={handleChange('planned', true)}
                      isNeedNextButton={haveNextBar('planned', true)}
                    />
                  </CollapseBar>
                )}
                {bankRequisites?.length > 0 && (
                  <CollapseBar
                    expanded={panel === 'requisites'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Реквизиты"
                    onChange={handleChange('requisites')}
                    className={classnames(((!!panel && panel !== 'requisites') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <RequisitesContent
                      template="settlements"
                      data={bankRequisites || null}
                    />
                  </CollapseBar>
                )}
                {beneficiaries && (
                  <CollapseBar
                    expanded={panel === 'beneficiaries'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Бенефициары"
                    onChange={handleChange('beneficiaries')}
                    className={classnames(((!!panel && panel !== 'beneficiaries') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <BeneficiariesPanel />
                  </CollapseBar>
                )}
                <CollapseBar
                  expanded={panel === 'other'}
                  barColor={panel ? '#91B1BF' : '#dfe8ed'}
                  label="Прочее"
                  onChange={handleChange('other')}
                  className={classnames(((!!panel && panel !== 'other') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                >
                  <GeneralPanel />
                </CollapseBar>
              </>
            )}
          />
        </>
        ))
      )) || (
        <>
          <Media
            query="(min-width: 577px)"
            render={() => (
              <>
                <Grid
                  container
                  spacing={2}
                  className={classes.rowMb}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CustomInput
                      className={classes.mediumLabelField}
                      size="small"
                      variant="outlined"
                      name="fullName"
                      fullWidth
                      value={listPerson?.fullName || ''}
                      label="Фамилия, имя, отчество (при наличии последнего)"
                      disabled
                      unique
                    />
                  </Grid>
                </Grid>
                <CollapseBar
                  defaultExpanded={openedInfo}
                  label="Основные сведения"
                >
                  <GeneralInfo
                    data={listPerson || null}
                    isQualifiedInvestor={isQualifiedInvestor}
                    clientInfo={clientInfo}
                  />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedId}
                  label="Удостоверение личности"
                >
                  <IdentificationContent data={listPerson || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedAddress}
                  label="Адрес регистрации"
                >
                  <AddressContent data={listPerson || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedMailAddress}
                  label="Почтовый адрес"
                >
                  <PostAddressContent data={listPerson || null} />
                </CollapseBar>
                <CollapseBar
                  defaultExpanded={openedContacts}
                  label="Контактная информация"
                >
                  <ContactInfosContent data={listPerson || null} />
                </CollapseBar>
                <CollapseBar
                  label="Сведения о планируемых операциях"
                >
                  <OperationsPlanContent data={listPerson || null} />
                </CollapseBar>
              </>
            )}
          />
          <Media
            query={`(max-width: ${breakpoints.phone_large}px)`}
            render={() => (
              <>
                <div
                  className={classes.Phone__fullName}
                  onClick={handleRollUpBar}
                  aria-hidden="true"
                >
                  {fullName}
                </div>
                <div className={
                  classnames(
                    classes.Phone__generalBlock,
                    !!panel && classes.Phone__hideBlock,
                  )
                }
                >
                  {listPerson?.phones && (
                    <div className={classes.Phone__block}>
                      <div className={classes.Phone__header}>Телефон</div>
                      <span className={classes.Phone__text}>{listPerson?.phones?.join(', ') || ''}</span>
                    </div>
                  )}
                  {listPerson?.emails && (
                    <div className={classes.Phone__block}>
                      <div className={classes.Phone__header}>E-mail</div>
                      <span className={classes.Phone__text}>{listPerson?.emails?.join(', ') || ''}</span>
                    </div>
                  )}
                </div>
                {openedInfo && (
                  <CollapseBar
                    expanded={panel === 'generalPanel'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Основные сведения"
                    onChange={handleChange('generalPanel')}
                    className={classnames(((!!panel && panel !== 'generalPanel') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <GeneralInfo
                      data={listPerson || null}
                      handleClickNextPanel={handleChange('openedInfo', true)}
                      isNeedNextButton={haveNextBar('openedInfo', true)}
                      isQualifiedInvestor={isQualifiedInvestor}
                      clientInfo={clientInfo}
                    />
                  </CollapseBar>
                )}
                {openedId && (
                  <CollapseBar
                    expanded={panel === 'identityCard'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Удостоверение личности"
                    onChange={handleChange('identityCard')}
                    className={classnames(((!!panel && panel !== 'identityCard') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <IdentificationContent
                      data={listPerson || null}
                      handleClickNextPanel={handleChange('openedId', true)}
                      isNeedNextButton={haveNextBar('openedId', true)}
                    />
                  </CollapseBar>
                )}
                {openedAddress && (
                <CollapseBar
                  expanded={panel === 'address'}
                  barColor={panel ? '#91B1BF' : '#dfe8ed'}
                  label="Адрес регистрации"
                  onChange={handleChange('address')}
                  className={classnames(((!!panel && panel !== 'address') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                >
                  <AddressContent
                    data={listPerson || null}
                    handleClickNextPanel={handleChange('openedAddress', true)}
                    isNeedNextButton={haveNextBar('openedAddress', true)}
                  />
                </CollapseBar>
                )}
                {openedMailAddress && (
                <CollapseBar
                  expanded={panel === 'postAddress'}
                  barColor={panel ? '#91B1BF' : '#dfe8ed'}
                  label="Почтовый адрес"
                  onChange={handleChange('postAddress')}
                  className={classnames(((!!panel && panel !== 'postAddress') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                >
                  <PostAddressContent
                    data={listPerson || null}
                    handleClickNextPanel={handleChange('openedMailAddress', true)}
                    isNeedNextButton={haveNextBar('openedMailAddress', true)}
                  />
                </CollapseBar>
                )}
                {openedContacts && (
                  <CollapseBar
                    expanded={panel === 'contactInfo'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Контактная информация"
                    onChange={handleChange('contactInfo')}
                    className={classnames(((!!panel && panel !== 'contactInfo') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <ContactInfosContent
                      data={listPerson || null}
                      handleClickNextPanel={handleChange('openedContacts', true)}
                      isNeedNextButton={haveNextBar('openedContacts', true)}
                    />
                  </CollapseBar>
                )}
                {hasPlanned && (
                  <CollapseBar
                    expanded={panel === 'planned'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Планируемые операции"
                    onChange={handleChange('planned')}
                    className={classnames(((!!panel && panel !== 'planned') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <OperationsPlanContent
                      data={listPerson || null}
                      handleClickNextPanel={handleChange('planned', true)}
                      isNeedNextButton={haveNextBar('planned', true)}
                    />
                  </CollapseBar>
                )}
                {bankRequisites?.length > 0 && (
                  <CollapseBar
                    expanded={panel === 'requisites'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Реквизиты"
                    onChange={handleChange('requisites')}
                    className={classnames(((!!panel && panel !== 'requisites') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <RequisitesContent
                      template="settlements"
                      data={bankRequisites || null}
                    />
                  </CollapseBar>
                )}
                {beneficiaries && (
                  <CollapseBar
                    expanded={panel === 'beneficiaries'}
                    barColor={panel ? '#91B1BF' : '#dfe8ed'}
                    label="Бенефициары"
                    onChange={handleChange('beneficiaries')}
                    className={classnames(((!!panel && panel !== 'beneficiaries') && classes.Phone__hideBlock), classes.Phone__expandedBar)}
                  >
                    <BeneficiariesPanel />
                  </CollapseBar>
                )}
              </>
            )}
          />
        </>
      )))}
    </Box>
  )
}

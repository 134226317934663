import { VariantsBadge } from './type'

export const getState = (dayDiff: number): boolean | undefined => {
  if (dayDiff === 0) return

  return dayDiff > 0
}

export const getStringState = (dayDiffPercent: number): VariantsBadge => {
  if (dayDiffPercent === 0) return 'default'

  return dayDiffPercent > 0 ? 'positive' : 'negative'
}

export const formatCurrency = new Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 2,
  minimumIntegerDigits: 1,
}).format

export const accrodionBodyPaddingTopDefault = 20

import styled from 'styled-components'
import { Tabs } from 'ui/components/molecules'

export const StyledProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledProfileTabs = styled(Tabs)`
  margin-bottom: 32px;
  width: 310px;

  @media ${({ theme }) => theme.breakpoint.xxs} {
    width: 100%;
  }
`

export const StyledBasicInfoTabs = styled(Tabs)`
  margin-bottom: 20px;

  @media ${({ theme }) => theme.breakpoint.xxs} {
    margin-bottom: 16px;
  }
`

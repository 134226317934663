import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  categories: null,
  total: null,
  currentAccount: null,
  time: null,
  valuations: null,
  portfolio: null,
  instruments: null,
}

const scanerSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    getCategories(state) {
      return {
        ...state,
        error: null,
      }
    },
    getCategoriesSuccess(state, action) {
      return {
        ...state,
        categories: action.payload,
        error: null,
      }
    },
    getCategoriesFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getTotal(state) {
      return {
        ...state,
        error: null,
      }
    },
    getTotalSuccess(state, action) {
      return {
        ...state,
        total: action.payload,
        currentAccount: state.currentAccount || action.payload?.valuations[0].account.id,
        error: null,
      }
    },
    getTotalFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getTime(state) {
      return {
        ...state,
        error: null,
      }
    },
    getTimeSuccess(state, action) {
      return {
        ...state,
        time: action.payload,
        error: null,
      }
    },
    getTimeFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getValuations(state) {
      return {
        ...state,
        error: null,
      }
    },
    getValuationsSuccess(state, action) {
      return {
        ...state,
        valuations: action.payload,
        error: null,
      }
    },
    getValuationsFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getPortfolio(state) {
      return {
        ...state,
        error: null,
      }
    },
    getPortfolioSuccess(state, action) {
      return {
        ...state,
        portfolio: action.payload?.data?.valuations,
        error: null,
      }
    },
    getPortfolioFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getInstruments(state) {
      return {
        ...state,
        error: null,
      }
    },
    getInstrumentsSuccess(state, action) {
      return {
        ...state,
        instruments: action.payload,
        error: null,
      }
    },
    getInstrumentsFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    portfolioClean() {
      return initialState
    },
    setCurrentAccount(state, action) {
      return {
        ...state,
        currentAccount: action.payload,
      }
    },
  },
})

const { actions, reducer } = scanerSlice

export const {
  getCategories,
  getCategoriesSuccess,
  getCategoriesFailed,
  getTotal,
  getTotalSuccess,
  getTotalFailed,
  getTime,
  getTimeSuccess,
  getTimeFailed,
  getValuations,
  getValuationsSuccess,
  getValuationsFailed,
  getPortfolio,
  getPortfolioSuccess,
  getPortfolioFailed,
  getInstruments,
  getInstrumentsSuccess,
  getInstrumentsFailed,
  portfolioClean,
  setCurrentAccount,
} = actions

export default reducer

export const fetchCategoriesStartAsync = () => async (dispatch) => {
  dispatch(getCategories())
  try {
    await Api.getCategories()
  } catch (error) {
    dispatch(getCategoriesFailed(error?.message))
  }
}

export const fetchTotalStartAsync = () => async (dispatch) => {
  dispatch(getTotal())
  try {
    await Api.getTotal()
  } catch (error) {
    dispatch(getTotalFailed(error?.message))
  }
}

export const fetchTimeStartAsync = () => async (dispatch) => {
  dispatch(getTime())
  try {
    await Api.getTime()
  } catch (error) {
    dispatch(getTimeFailed(error?.message))
  }
}

export const fetchValuationsStartAsync = () => async (dispatch) => {
  dispatch(getValuations())
  try {
    await Api.getValuations()
  } catch (error) {
    dispatch(getValuationsFailed(error?.message))
  }
}
export const fetchValuationsStart2Async = () => async (dispatch) => {
  dispatch(getValuations())
  try {
    await Api.getValuations2()
  } catch (error) {
    dispatch(getValuationsFailed(error?.message))
  }
}

export const fetchValuationsStart3Async = () => async (dispatch) => {
  dispatch(getValuations())
  try {
    await Api.getValuations3()
  } catch (error) {
    dispatch(getValuationsFailed(error?.message))
  }
}

export const fetchPortfolioStartAsync = () => async (dispatch) => {
  dispatch(getPortfolio())
  try {
    const value = await Api.getPortfolio()
    dispatch(getPortfolioSuccess(value))
  } catch (error) {
    dispatch(getPortfolioFailed(error?.message))
  }
}


export const fetchInstrumentsStartAsync = () => async (dispatch) => {
  dispatch(getInstruments())
  try {
    await Api.getInstruments()
  } catch (error) {
    dispatch(getInstrumentsFailed(error?.message))
  }
}

import React from 'react'
import {
  Switch, Route, Redirect, useRouteMatch, useLocation, useHistory,
} from 'react-router-dom'
import Media from 'react-media'
import Grid from '@material-ui/core/Grid'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg'
import ConfidentPanel from './additionalInformationPanels/confidentPanel'
import BeneficiariesPanel from './additionalInformationPanels/beneficiariesPanel'
import useStyles from '../InnerTabs/InnerTabs.style'

export default function AdditionalInformations() {
  const classes = useStyles()
  const { url, path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const isPathConfidantClassName = pathname.split('/')[3] === 'confidant'
  const isPathBeneficiariesClassName = pathname.split('/')[3] === 'beneficiaries'

  const goTo = (link) => history.push(`${url}/${link}`)
  const returnNiceClass = (isNice) => (isNice ? `${classes.breadCrumb} ${classes.activeCrumb}` : classes.breadCrumb)

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <Grid
            container
            className={classes.crumbContainer}
          >
            <button
              type="button"
              className={returnNiceClass(isPathConfidantClassName)}
              onClick={() => goTo('confidant')}
            >
              <span>Доверенное лицо</span>
              <ArrowRightIcon />
            </button>
            <button
              type="button"
              className={returnNiceClass(isPathBeneficiariesClassName)}
              onClick={() => goTo('beneficiaries')}
            >
              <span>Бенефициары</span>
              <ArrowRightIcon />
            </button>
          </Grid>
        )}
      />
      <Switch>
        <Route
          exact
          path={path}
        >
          <Redirect to={`${path}/confidant`} />
        </Route>
        <Route
          exact
          path={`${path}/confidant`}
          component={ConfidentPanel}
        />
        <Route
          exact
          path={`${path}/beneficiaries`}
          component={BeneficiariesPanel}
        />
      </Switch>
    </>
  )
}

import React from 'react'
import TabsComponent from '../../components/Tabs/Tabs'
import Sign from '../../components/Sign/Sign'

export default function ReportsSign() {
  const tabLinks = [
    {
      key: 'fdd4f89c-11fd-11eb-adc1-0242ac120002',
      link: 'reports',
      label: 'Документы на подпись',
      icon: '',
      component: Sign,
    },
  ]

  return (
    <>
      <TabsComponent
        tabLinks={tabLinks}
        wishStyle={{ justifyContent: 'flex-start' }}
      />
    </>
  )
}

import styled from 'styled-components'

export const StyledRequestWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 31px;

  @media ${({ theme: { breakpoint } }) => breakpoint.xs} {
    gap: 16px;
  }

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    gap: 32px;
  }
`

export const StyledRequestFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FilterForm from './FilterForm'
import { ReactComponent as DownloadIcon } from '../../../../assets/images/downloadNew.svg'
import {
  fetchCorpFilterStartAsync,
  fetchCorpStartAsync,
  fetchReportStartAsync,
} from '../../../../redux/reports/reportsSlice'
import useStyles from './panel.style'
import { selectCorpReportsDataTable, selectReport } from '../../../../redux/reports/selector'
import { rowsPerPage } from '../../../../config/constants'
import b64toBlob from '../../../../utils/ConvertToBlob'
import { StyledWrapper } from '../Transfers/components/Money/styled'
import { StyledTableWrapper } from '../Transfers/styled'
import { Pagination } from '../../organisms'
import { useSize } from '../../../../hooks/useSize'
import { useTheme } from '../../../theme'
import { DataTable } from '../../molecules'

const LogCorporate = () => {
  const size = useSize()
  const theme = useTheme()
  const isDesktop = theme.bp.isMD(size.width)

  const dispatch = useDispatch()
  const classes = useStyles()
  const [page, setPage] = useState(0)

  const reportsCorpData = useSelector(selectCorpReportsDataTable)
  const reportDetail = useSelector(selectReport)

  const fetchCorpStart = () => {
    dispatch(fetchCorpStartAsync())
  }
  const fetchCorpFilterStart = () => {
    dispatch(fetchCorpFilterStartAsync())
  }
  const fetchReportStart = (id) => {
    dispatch(fetchReportStartAsync({ id }))
  }

  useEffect(() => {
    fetchCorpStart()
  }, [])

  const downloadLink = useRef(null)

  useEffect(() => {
    if (reportsCorpData?.reports?.length > 0) {
      const currentReportsLength = reportsCorpData.reports.length
      if (page * rowsPerPage > currentReportsLength) {
        setPage(0)
      }
    }
  }, [reportsCorpData])

  useEffect(() => {
    if (reportDetail) {
      downloadLink.current.href = URL.createObjectURL(b64toBlob(reportDetail.data))
      downloadLink.current.download = reportDetail.fileName
      downloadLink.current.click()
    }
  }, [reportDetail])

  const handleDownloadFile = async (id) => {
    await fetchReportStart(id)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage - 1)
  }

  const Table = ({
    rows, onRowClick,
  }) => {
    const columnsLayout = ['14.8%', '11.1%', '12%',
      '20%',
      '20%',
      '8.2%',
      '15%',
    ]
    const columns = [
      {
        id: 'date',
        title: 'Дата отправки',
        width: '14.8%',
        render: (row) => moment(row?.dateFrom).format('DD.MM.YYYY'),
      },
      {
        id: 'check',
        title: 'Счёт',
        width: '11.1%',
        render: (row) => row?.account?.number,
      },
      {
        id: 'reference',
        title: 'Референс',
        render: (row) => row?.corpActionRef,
      },
      {
        id: 'isin',
        title: 'Эмитент / ISIN',
        render: (row) => row?.corpIssuer,
      },
      {
        id: 'type',
        title: 'Тип',
        render: (row) => row?.corpActionType,
      },
      {
        id: 'dateKD',
        title: 'Дата КД',
        render: (row) => row?.dateFrom,
      },
      {
        id: 'format',
        title: 'Формат',
        render: (row) => {
          const formatName = row?.format?.name

          return (
            <TableCell style={{ padding: 0, borderBottom: 'none' }}>
              {formatName && (
              <Button
                className={classes.tableBtn}
                disableRipple
                onClick={() => handleDownloadFile(row?.id)}
              >
                <DownloadIcon />
                <Typography
                  tag="span"
                  color="secondary"
                >
                  {formatName}
                </Typography>
              </Button>
              )}
            </TableCell>
          )
        },
      },
    ].filter(Boolean)
    return (
      <DataTable
        columns={columns}
        rows={rows}
        layout={columnsLayout}
        onRowClick={onRowClick}
      />
    )
  }

  return (
    <>
      <a
        className={classes.downloadLink}
        href=""
        ref={downloadLink}
      >
        download
      </a>

      <FilterForm
        accounts={reportsCorpData?.references?.accounts || []}
        fetchFilterStart={fetchCorpFilterStart}
        tableType="CorpAction"
      />

      <StyledWrapper>
        <StyledTableWrapper>
          <Table
            rows={isDesktop
              ? reportsCorpData?.reports?.slice(
                ((page) * (rowsPerPage)), ((page + 1) * (rowsPerPage)),
              )
              : reportsCorpData?.reports}
            handleDownload={() => { }}

          />
        </StyledTableWrapper>
        {(!!reportsCorpData?.reports?.length && isDesktop) && (
          <Pagination
            total={reportsCorpData?.reports?.length}
            current={page + 1}
            onChange={handleChangePage}
            pageSize={rowsPerPage}
          />
        )}
      </StyledWrapper>

    </>
  )
}

export default LogCorporate

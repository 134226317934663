import React from 'react'
import { Accordion } from 'ui/components/molecules/Accordion'
import { FormCol, TextField } from 'ui/components/atoms'
import { Field, ITableColumn, Table } from 'ui/components/molecules'

import { TextArea } from 'ui/components/atoms/TextArea'

import type { FC } from 'react'
import type { ActivityAndOperationsInfoProps } from './types'
import { ProfessionalLicense } from 'types/professionalLicense'
import { formatDateClient } from 'utils/GetDate'
import useMedia from 'hooks/useMedia'
import { getColumnWidthForTablet } from './data'
import { Text } from 'ui/components/atoms'
import { StyledActivityAndOperationsTitle } from './styled'
import { getTableCellWidthPercent } from 'utils/helpers/table'

/**
 * Адрес в соответствии с единым государственным реестром юридических лиц (ЕГРЮЛ).
 *
 * @param licenses - Список лицензий на деятельность.
 * @param management - Менеджмент компании.
 * @param affiliates - Список аффилированных лиц.
 * @param registeredCapital - Уставный капитал
 * @param registeredCapitalCurrency - Валюта капитала.
 * @param isOpen - Открыт ли по умолчанию аккордеон.
 *
 * @constructor
 */
export const ActivityAndOperationsInfo: FC<ActivityAndOperationsInfoProps> = ({
  licenses = [],
  management = null,
  affiliates = [],
  registeredCapital = 0,
  registeredCapitalCurrency = null,
  isOpen = false,
}) => {
  const { isDesktop, isTablet, isMobile } = useMedia()

  const SEPARATOR = ', '
  const textRowsList = []

  if (management) {
    const GENERAL_MEETING_MEMBERS_TITLE = 'Состав общего собрания участников'
    const SOLE_EXECUTIVE_TITLE = 'Единоличный исполнительный орган'

    const hasMembersInList = !!management?.generalMeetingMembers?.length
    const hasSoleExecutive = management?.hasSoleExecutive

    if (hasMembersInList) {
      const resultMembers = `· ${GENERAL_MEETING_MEMBERS_TITLE}: ${management?.generalMeetingMembers?.join(
        SEPARATOR
      )}`
      textRowsList.push(resultMembers)
    }

    if (hasSoleExecutive) {
      const resultSolveExecutive = `· ${SOLE_EXECUTIVE_TITLE}: ${management?.soleExecutive}`
      textRowsList.push(resultSolveExecutive)
    }
  }

  const text = textRowsList.join('\n')

  const tableWidthOnDesktop = 1096
  const tableWidthOnTablet = 680

  const currentTablerWidth = isDesktop ? tableWidthOnDesktop : tableWidthOnTablet

  const activityAndOperationsColumns: ITableColumn<ProfessionalLicense>[] = [
    {
      id: 'activity',
      title: 'Вид деятельности',
      width: getTableCellWidthPercent(isDesktop ? 557 : 315, currentTablerWidth),
      wrapText: isTablet,
      ...(isTablet && { paddingRight: 20 }),
    },
    {
      id: 'number',
      title: 'Номер',
      width: getTableCellWidthPercent(isDesktop ? 119 : 127, currentTablerWidth),
    },
    {
      id: 'issuingAuthority',
      title: 'Выдавший орган',
      width: getTableCellWidthPercent(isDesktop ? 264 : 163, currentTablerWidth),
    },
    {
      id: 'issueDate',
      title: 'Дата выдачи',
      render: (date: string) => formatDateClient(date),
      width: isDesktop ? getTableCellWidthPercent(156, tableWidthOnDesktop) : '76px',
      showAlways: isTablet,
    },
  ]

  const titleText =
    'Сведения о наличии лицензий (разрешений) на осуществление определённого вида деятельности или операций'

  return (
    <Accordion
      title={titleText}
      variant="plain"
      isOpenDefault={isOpen}
      padding={isMobile ? '20px' : '32px'}
      titleMaxWidth={560}
      bodyPaddingTop={isMobile ? 28 : 32}
      {...(isMobile && {
        customTitle: (
          <StyledActivityAndOperationsTitle variant="body1">
            {titleText}
          </StyledActivityAndOperationsTitle>
        ),
      })}
    >
      <FormCol gap={24}>
        {/*
          ВИДЫ ДЕЯТЕЛЬНОСТИ
        */}
        <Table<ProfessionalLicense>
          leftPadding={isDesktop ? 20 : 0}
          rightPadding={0}
          columns={activityAndOperationsColumns}
          rows={licenses}
          withRowShadow={false}
          rowsGap={8}
          withBodyRowBorder
          bottomHeadPadding={8}
          columnsGap={8}
        />

        {/*
          СВЕДЕНИЯ О СТРУКТУРЕ УПРАВЛЕНИЯ
        */}
        <Field label="Сведения о структуре управления">
          <TextArea autoresize value={text} minAreaHeight={144} disabled />
        </Field>

        {/*
          СВЕДЕНИЯ ОБ АФФИЛИРОВАННЫХ ЛИЦАХ
        */}
        <Field label="Сведения об аффилированных лицах">
          <TextField disabled value={affiliates?.join(SEPARATOR)} />
        </Field>

        {/*
          УСТАВНОЙ КАПИТАЛ
        */}
        <Field label="Уставной капитал">
          <TextField disabled value={`${registeredCapital} ${registeredCapitalCurrency?.uid}`} />
        </Field>
      </FormCol>
    </Accordion>
  )
}

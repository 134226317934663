// import { TextField } from 'ui/components'
import { StyledTextField } from 'ui/components/atoms/TextField/styled'
import { styled } from 'ui/theme'
import { IFieldProps, IFieldWrapper } from './types'

export const StyledField = styled.div<IFieldWrapper>`
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ flex }) => flex && `flex: ${flex};`}

  position: relative;
  display: flex;
  flex-direction: column;
`
interface StyledFieldLabelProps {
  hasError?: boolean
}

export const StyledFieldLabel = styled.label<StyledFieldLabelProps>`
  ${({ theme }) => theme.font.body1}
  color: ${({ theme, hasError }) =>
    hasError ? theme.color.text.negative : theme.color.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  min-height: 20px;
`
export const StyledFieldContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.lg};
  width: 100%;
  > * {
    flex-shrink: 0;
    flex: 1;
  }
`
export const StyledFieldCaption = styled.span<IFieldProps>`
  position: absolute;
  left: 0;
  bottom: -2px;
  transform: translateY(100%);

  ${({ theme }) => theme.font.caption1}
  color: ${({ theme }) => theme.color.text.secondary};
  ${({ hasError, theme }) =>
    hasError
      ? `
    color: ${theme.color.text.negative};
  `
      : ''}
  margin-top: ${({ theme }) => theme.spacing.xxs};
  ${StyledTextField} {
    border-color: ${({ theme }) => theme.color.text.negative};
  }
`

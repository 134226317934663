import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useTheme } from 'ui/theme'
import { useSize } from 'hooks/useSize'

import {
  selectAccount,
  selectReportsStatuses,
  selectReportTypesNames,
} from '../../../../redux/reports/selector'
import {
  clearFilterFields,
  fetchReportTypesNamesStartAsync,
  setAccountFilter,
  setFormatPeriodFromFilter,
  setFormatPeriodToFilter,
  setStatusFilter,
  setTypeFilter,
  setTypeNameFilter,
} from '../../../../redux/reports/reportsSlice'
import { Form, FormCol, FormRow } from '../../atoms'
import {
  Chips, DateRangeField, Field, Select,
} from '../../molecules'
import { selectHistoryStatuses } from '../../../../redux/history/selector'
import MobileFilterNew from '../../../../components/MobileFilterNew/MobileFilterNew'

const TOTAL_STATUS = 'Все'

const initialState = {
  account: '',
  period: {
    from: null,
    to: null,
  },
  status: {
    statuses: ['Все', 'Черновик', 'Получено исполнителем', 'На исполнении', 'Отменено клиентом', 'Отказ в исполнении', 'Исполнено'],
    currentStatus: '',
  },

  portfolio: {
    portfolios: [],
    currentPortfolio: '',
  },

  orderType: {
    orderTypes: [],
    currentOrderType: '',
  },
  currentStatus: '',
  currentType: '',
  currentTypeName: '',
  typeLabel: '',
}

const FilterForm = ({
// eslint-disable-next-line react/prop-types
  accounts, fetchFilterStart, statuses = [], tableType,
}) => {
  const theme = useTheme()
  const size = useSize()

  const isDesktop = theme.bp.isMD(size.width)
  const isMobile = theme.bp.isXXS(size.width)

  const dispatch = useDispatch()

  const [statusSelect, setStatusSelect] = useState(initialState.status)

  const [dateFrom, setDateFrom] = useState(initialState.period.from)
  const [dateTo, setDateTo] = useState(initialState.period.to)
  const [accountValue, setAccountValue] = useState(initialState.account)
  const [status, setStatus] = useState(initialState.currentStatus)
  const [type, setType] = useState(initialState.currentType)
  const [typeName, setTypeName] = useState(initialState.currentTypeName)
  const [typeLabel, setTypeLabel] = useState(initialState.currentType)

  const account = useSelector((state) => selectAccount(state, tableType))

  const filterStatuses = useSelector(selectHistoryStatuses)
  const filterReportsStatuses = useSelector((state) => selectReportsStatuses(state, tableType))

  const reportTypesNames = useSelector(selectReportTypesNames)

  const getFormatDate = (date, formatDate) => format(new Date(date), formatDate)

  const fetchSetAccountFilter = (value) => {
    dispatch(setAccountFilter(value, tableType))
  }
  const fetchReportTypesNamesStart = () => {
    dispatch(fetchReportTypesNamesStartAsync())
  }
  const fetchSetStatusFilter = (value) => {
    dispatch(setStatusFilter(value, tableType))
  }
  const fetchSetTypeFilter = (value) => {
    dispatch(setTypeFilter(value, tableType))
  }
  const fetchSetTypeNameFilter = (value) => {
    dispatch(setTypeNameFilter(value))
  }

  const fetchSetFormatPeriodFromFilter = (value) => {
    dispatch(setFormatPeriodFromFilter(value && getFormatDate(value, 'yyyy-MM-dd'), tableType))
  }

  const fetchSetFormatPeriodToFilter = (value) => {
    dispatch(setFormatPeriodToFilter(value && getFormatDate(value, 'yyyy-MM-dd'), tableType))
  }

  useEffect(() => {
    dispatch(clearFilterFields())
    fetchReportTypesNamesStart()
  }, [])

  useEffect(() => {
    if (filterStatuses?.length) {
      const statusNames = [TOTAL_STATUS]

      filterStatuses.map((status) => {
        statusNames.push(status.name)
        return status
      })
      setStatusSelect({ ...statusSelect, statuses: statusNames })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatuses])

  const handleChangeAccount = (event) => {
    const { value } = event
    fetchSetAccountFilter(value)

    setAccountValue(value)
  }

  const handleChangeStatus = (event) => {
    const { value } = event

    setStatusSelect({
      ...statusSelect,
      currentStatus: value,
    })

    const statusUID = filterReportsStatuses?.find((item) => item.name === value)?.uid || null

    fetchSetStatusFilter(statusUID)

    setStatus(statusUID)
  }

  const handleChangeType = (event) => {
    const { value, label } = event

    if (tableType === 'Depo') {
      setType('')
      fetchSetTypeFilter('')
      fetchSetStatusFilter('')

      setTypeName(label)
      setTypeLabel(label)
      fetchSetTypeNameFilter(label)
      return
    }

    if (tableType !== 'Depo') {
      fetchSetTypeNameFilter('')

      fetchSetTypeFilter(value)
      setType(value)
      setTypeLabel(label)
    }
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
  }

  const handleAcceptFilter = () => {
    fetchFilterStart()
  }

  useEffect(() => {
    handleAcceptFilter()
  }, [
    status,
    type,
    typeName,
    dateFrom,
    dateTo,
    accountValue,
  ])

  const handleResetFilter = () => {
    fetchSetAccountFilter(
      initialState.account,
    )
    fetchSetFormatPeriodFromFilter(null)
    fetchSetFormatPeriodToFilter(null)
    fetchFilterStart()
  }

  const handleChangeDate = (date) => {
    const from = date?.[0] ?? null
    const to = date?.[1] ?? null
    fetchSetFormatPeriodFromFilter(from)
    fetchSetFormatPeriodToFilter(to)

    setDateFrom(from)
    setDateTo(to)
  }

  const handleChangeStatusSelect = (value) => {
    setStatusSelect({
      ...statusSelect,
      currentStatus: value,
    })

    const statusUID = filterReportsStatuses?.find((item) => item.name === value)?.uid || null

    fetchSetStatusFilter(statusUID)

    setStatus(statusUID)
  }

  const filtersBarNode = (
    <Form onSubmit={handleSubmitForm}>
      <FormRow>
        <FormCol flex={3}>
          <Field label="Счет">
            <Select
              options={
                [{ number: '', id: '' }, ...(accounts || [])].map((item) => ({
                  label: item.number,
                  value: item.id,
                }))
              }
              value={accounts?.find((e) => e.id === account)?.number}
              onChange={handleChangeAccount}
              placeholder="Выберите из списка"
            />
          </Field>
        </FormCol>

        <FormCol flex={3}>
          <Field label="Тип">
            <Select
              options={
              [{ label: '', value: '' }, ...(reportTypesNames
                ?.filter((f) => !!f?.typeName && f?.gridUID === tableType) || [])]
                .map((item) => ({
                  label: item?.typeName,
                  value: item?.typeUID,
                }))
}
              value={reportTypesNames?.find((e) => e.typeName === typeLabel)?.typeName}
              onChange={handleChangeType}
              placeholder="Выберите из списка"
            />
          </Field>
        </FormCol>

        <FormCol flex={3}>
          <Field label="Дата или период">

            <DateRangeField
              picker="week"
              onChange={handleChangeDate}
              isEqually
            />

          </Field>
        </FormCol>

        {!isDesktop && (
          <FormCol flex={3}>
            <Field label="Статус">
              <Select
                options={statuses?.map((name) => ({ label: name, value: name }))}
                value={statusSelect.currentStatus}
                onChange={handleChangeStatus}
                placeholder="Выберите из списка"
              />
            </Field>
          </FormCol>
        )}

      </FormRow>

      <FormRow>
        {
          isDesktop
          && (
          <Chips
            options={statuses?.map((name) => ({ label: name, value: name }))}
            value={statusSelect.currentStatus}
            onChange={handleChangeStatusSelect}
          />
          )
        }
      </FormRow>

    </Form>
  )

  return (
    <>

      {isMobile && (
        <MobileFilterNew
          handleAcceptFilter={handleAcceptFilter}
          handleResetFilter={handleResetFilter}
          constItems={[
            initialState.portfolio,
            statusSelect?.currentStatus,
            dateFrom,
            dateTo,
          ]}
        >
          {filtersBarNode}
        </MobileFilterNew>
      )}

      {!isMobile && filtersBarNode}

    </>
  )
}

FilterForm.defaultProps = {
  accounts: PropTypes.array,
  tableType: PropTypes.string,
}

FilterForm.propTypes = {
  fetchFilterStart: PropTypes.func.isRequired,
  accounts: PropTypes.oneOfType([PropTypes.array]),
  tableType: PropTypes.string,
}

export default FilterForm

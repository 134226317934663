import React from 'react'
import { Accordion } from 'ui/components/molecules/Accordion'
import { DateField, Field } from 'ui/components/molecules'
import { FormRow, TextField } from 'ui/components/atoms'

import type { FC } from 'react'
import type { LegacyRegistrationProps } from './types'
import CustomerBlocksAccordion from '../CustomerBlocksAccordion'
import useMedia from 'hooks/useMedia'

/**
 * Для юридических лиц, зарегистрированных на территории РФ до 01 июля 2002 года.
 *
 * @param registerNumber - Регистрационный номер.
 * @param registerDate - Дата регистрации.
 * @param registerIssuer - Регистрирующий орган.
 * @param isOpen - Открыт ли по умолчанию аккордеон.
 *
 * @constructor
 */
export const LegacyRegistration: FC<LegacyRegistrationProps> = ({
  registerNumber = '',
  registerDate = null,
  registerIssuer = '',
  isOpen = false,
}) => {
  const { isTablet } = useMedia()

  return (
    <CustomerBlocksAccordion
      {...(isTablet && { titleMaxWidth: 400 })}
      title="Для юридических лиц, зарегистрированных на&nbsp;территории РФ до 01 июля 2002 года"
      isOpenDefault={isOpen}
      bodyPaddingTop={28}
    >
      <FormRow gap={16} gapTablet={16} tabletRowDirection>
        {/*
          РЕГИСТРАЦИОННЫЙ НОМЕР
        */}
        <Field label="Регистрационный номер">
          <TextField disabled value={registerNumber} />
        </Field>

        {/*
          ДАТА РЕГИСТРАЦИИ
        */}
        <Field label="Дата регистрации">
          <DateField disabled value={registerDate} />
        </Field>
      </FormRow>

      {/*
        РЕГИСТРИРУЮЩИЙ ОРГАН
      */}
      <FormRow>
        <Field label="Регистрирующий орган">
          <TextField disabled value={registerIssuer} />
        </Field>
      </FormRow>
    </CustomerBlocksAccordion>
  )
}

import React from 'react'
import { ITextProps } from './types'
import { styled } from 'ui/theme'

const DynamicText = ({
  tag = 'span',
  children,
  ...props
}: any): React.FunctionComponentElement<ITextProps> => React.createElement<{}>(tag, props, children)

export const StyledText = styled(DynamicText).withConfig({
  shouldForwardProp: (prop, defPropValFN) =>
    !['variant'].includes(prop as string) && defPropValFN(prop),
})<ITextProps>`
  margin: 0;
  padding: 0;
  color: ${({ theme, color }) => theme.color.text[color!] || 'inherit'};
  ${({ theme, variant = 'body1' }) => theme.font[variant]};

  ${({ hasError, theme }) =>
    hasError
      ? `
    color: ${theme.color.text.negative};
  `
      : ''};

  @media ${({ theme }) => theme.breakpoint.xxs} {
    line-height: 24px;
  }
`

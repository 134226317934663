import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: 30,
    '@media (max-width: 576px)': {
      paddingTop: 12,
    },
  },
  wrapper_unique: {
    '@media (max-width: 576px)': {
      paddingTop: 35,
    },
  },
  form: {
    '@media (max-width: 576px)': {
      paddingBottom: 24,
    },
    '& .MuiGrid-container': {
      marginTop: -10,
      marginRight: -25,
      marginBottom: -10,
      marginLeft: -25,
      '@media (max-width: 576px)': {
        margin: '-5px -16px',
      },
      '& $btnGroup': {
        paddingTop: 32,
      },
    },
    '& .MuiGrid-item': {
      paddingTop: 10,
      paddingRight: 25,
      paddingBottom: 10,
      paddingLeft: 25,
      '@media (max-width: 576px)': {
        padding: '5px 16px',
      },
    },
    '& $portalBtnWrapper': {
      paddingTop: 6,
    },
    '& $amountInfo': {
      marginTop: 20,
      '@media (max-width: 576px)': {
        marginTop: 0,
      },
    },
  },
  form_mobilePaddingLess: {
    '@media (max-width: 576px)': {
      paddingBottom: 0,
    },
  },
  orderInfo: {
    marginBottom: 36,
    '@media (max-width: 576px)': {
      marginBottom: 24,
    },
  },
  fileInputWrapper: {
    '& .MuiFormLabel-root': {
      display: 'block',
      marginBottom: 5,
      fontSize: 15,
      color: '#292D3499',
    },
    '& .MuiBox-root': {
      marginTop: 2,
    },
    '& .MuiDropzoneArea-root': {
      '@media (max-width: 576px)': {
        height: '50px !important',
      },
    },
  },
  dropzoneWrapper: {
    '& .MuiDropzoneArea-root': {
      height: (props) => (props.needEmptySpace && 96) || 'auto',
    },
  },
  infoTextWrapper: {
    '& .MuiTypography-root': {
      paddingTop: 18,
    },
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 32,
    '@media (max-width: 576px)': {
      position: 'fixed',
      zIndex: 1,
      bottom: 0,
      left: 46,
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'calc(100% - 46px)',
      minHeight: 48,
      padding: '0 20px',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    },
    '& + *:not(.iconButtonUnique) > *:not(:last-child)': {
      marginRight: 60,
      '@media (max-width: 576px)': {
        marginRight: 16,
      },
    },
    '& button': {
      '&:not(:last-child)': {
        '@media (max-width: 576px)': {
          marginRight: 16,
        },
      },
    },
  },
  btnGroup_sms: {
    justifyContent: 'space-between',
    '& > *:not(:last-child)': {
      marginRight: 16,
    },
  },
  btnGroupPhone: {
    display: 'flex',
    marginBottom: 14,
  },
  btnPhoneUniq: {
    '&:not(:last-child)': {
      marginRight: 18,
    },
    '& :after': {
      content: '""',
      position: 'absolute',
      top: 3,
      right: -10,
      width: 1,
      height: 15,
      backgroundColor: '#91B1BF',
    },
  },
  btnGroupUnique: {
    '@media (max-width: 576px)': {
      position: 'static',
      justifyContent: 'flex-start',
      width: '100%',
      marginBottom: 14,
      padding: 0,
    },
    '& button': {
      position: 'relative',
      '@media (max-width: 576px)': {
        paddingRight: 7,
      },
      '&:not(:last-child)': {
        marginRight: 11,
      },
      '& svg': {
        '@media (max-width: 576px)': {
          display: 'none',
        },
      },
    },
  },
  btnGroupPaddingLess: {
    paddingTop: 24,
  },
  portalBtnWrapper: {
    '& + $portalBtnWrapper': {
      '@media (max-width: 576px)': {
        marginTop: 18,
      },
    },
  },
  portalBtnWrapperUnique: {
    '&.MuiGrid-item': {
      paddingBottom: 3,
    },
  },
  portalBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0 4px 7px',
    borderTop: '0',
    borderRight: '0',
    borderBottom: '0',
    borderLeft: '6px solid #FBC15E',
    outline: 'none',
    backgroundColor: 'transparent',
    textAlign: 'left',
    cursor: 'pointer',
    '@media (max-width: 576px)': {
      padding: '4px 0 4px 10px',
    },
    '&[disabled]': {
      cursor: 'initial',
    },
    '& svg': {
      minWidth: 18,
    },
  },
  portalBtnLabel: {
    marginRight: 13,
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: '600',
    color: theme.palette.type === 'light' ? '#000' : '#fff',
  },
  importantText: {
    '&.MuiGrid-item': {
      paddingTop: 11,
      paddingBottom: 9,
    },
  },
  switchGridItem: {
    '&.MuiGrid-item': {
      paddingTop: 2,
      paddingBottom: 2,
    },
  },
  groupLabel: {
    display: 'flex',
    padding: '4px 0 4px 8px',
    borderLeft: '6px solid #FBC15E',
    fontSize: '16px',
    fontWeight: '600',
  },
  groupLabel_unique: {
    fontWeight: 400,
    '@media (max-width: 576px)': {
      padding: '3px 0 0px 6px',
      fontSize: 14,
      lineHeight: '19px',
    },
  },
  fileInput: {
    display: 'none',
  },
  fileInputLabel: {
    marginBottom: 8,
  },
  transferHeader: {
    paddingBottom: 22,
  },
  infoTextRequired: {
    paddingTop: 18,
  },
  TransferStatus: {
    justifyContent: 'flex-start',
    '& .MuiTypography-root': {
      fontSize: 15,
      color: theme.palette.type === 'light' ? '#292D34' : '#fff',
      lineHeight: 1,
    },
  },
  fieldsetLegend: {
    fontSize: 15,
    color: theme.palette.type === 'light' ? '#292D3499 !important' : '#ffffff99 !important',
    padding: '10px 0 15px 0',
  },
  depoPanelWrapper: {
    position: 'relative',
    '& > .MuiFormControl-root:not(:last-child)': {
      margin: 0,
    },
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 18,
    },
    '& .MuiButtonBase-root': {
      marginRight: 5,
      padding: 0,
      '&.Mui-checked': {
        color: '#91B1BF',
        '& svg': {
          '&:nth-child(2)': {
            opacity: 1,
          },
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& svg': {
      '&:first-child': {
        width: 16,
        height: 16,
        borderRadius: '50%',
        border: '1px solid #91B1BF',
        fill: 'transparent',
      },
      '&:nth-child(2)': {
        top: 4,
        left: 4,
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: '#91B1BF',
        fill: 'transparent',
        opacity: 0,
        transform: 'scale(1)',
        transition: 'opacity 0.3s ease-in-out !important',
      },
    },
  },
  '& .MuiTypography-root': {
    fontSize: 16,
  },
  TransferStatus__wrap: {
    borderLeft: '3px solid #FF1D25',
    padding: '5px 0 5px 10px',
  },
  TransferStatus__name: {
    fontWeight: '600',
    marginTop: 10,
    marginBottom: 10,
  },
  TransferStatus__description: {

  },
  moneyOutputReq__header: {
    display: 'block',
    fontSize: 15,
    color: '#292D3499',
  },
  moneyOutputReq__body: {
    display: 'block',
    color: theme.palette.type === 'light' ? '#91B1BF' : '#fff',
    fontSize: 13,
  },
  moneyTransferComment: {
    '&.MuiGrid-item': {
      padding: '0 16px 16px 16px',
    },
  },
  Transfer__messageTimer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  Transfer__codeWrapper: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 10,
    alignItems: 'flex-end',
    position: 'relative',
    left: -10,
    '@media (max-width: 576px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        paddingLeft: 16,
        position: 'initial',
      },
    },
    '& > .MuiButton-root': {
      minHeight: 'auto',
      width: 'auto',
      paddingTop: 1,
      paddingBottom: 1,
      fontSize: 14,
      '&:hover': {
        border: '1px solid #91B1BF',
        color: '#91B1BF',
        backgroundColor: '#E6EDEF',
      },
    },
  },
  Transfer__codeText: {
    fontSize: 12,
    color: '#91B1BF',
    paddingBottom: 5,
  },
  Transfer__codeInput: {
    paddingLeft: 5,
  },
  uniqueGridItem: {
    '&.MuiGrid-item': {
      paddingTop: 6,
    },
  },
  paragraph: {
    margin: 0,
    fontSize: 16,
    lineHeight: '16px',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
  },
  infoText: {
    margin: 0,
    fontSize: 15,
    fontWeight: 600,
    color: theme.palette.type === 'light' ? '#292D34' : '#fff',
  },
  issuerTable: {
    backgroundColor: theme.palette.type === 'light' ? '#fbfbfb' : '#292D34',
    '& .MuiTableCell-root': {
      paddingLeft: 5,
      paddingRight: 5,
      borderBottom: 0,
      '&.MuiTableCell-head': {
        paddingTop: 15,
        paddingBottom: 5,
        fontSize: 14,
        lineHeight: '14px',
        color: '#91B1BF',
      },
      '&.MuiTableCell-body': {
        paddingTop: 0,
        paddingBottom: 15,
        fontSize: 16,
        lineHeight: '16px',
      },
      '& .MuiFormControl-root': {
        maxWidth: 210,
        marginTop: 4,
      },
    },
  },
  issuerTable__wrapper: {
    overflowX: 'initial',
  },
  tableContainer: {
    [theme.breakpoints.up('lg')]: {
      overflow: 'unset',
    },
  },
  tableRow: {
    borderRight: '6px solid transparent',
    cursor: 'pointer',
    '&.MuiTableRow-root:not(.MuiTableRow-head)': {
      '&:hover': {
        borderRightColor: '#FBC15E',
        backgroundColor: '#91B1BF1A',
      },
    },
    '&.MuiTableRow-head': {
      cursor: 'initial',
    },
    '&.MuiTableRow-head > .MuiTableCell-root': {
      fontSize: 14,
    },
    '& > .MuiTableCell-root': {
      padding: '16px 12px 17px',
      borderBottomColor: theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78',
      borderBottomStyle: 'dashed',
      fontSize: 16,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 2,
      },
      '& button': {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& svg': {
        fill: '#91B1BF',
      },
    },
  },
  tableHeaderCell: {
    color: '#91B1BF',
  },
  tableBodyCell: {
    fontSize: 16,
  },
  tableBodyCellWidth: {
    minWidth: 155,
  },
  tableRowLineNone: {
    borderBottomStyle: 'hidden',
  },
  tableBodyCell__btn: {
    padding: 'initial',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 4,
    paddingBottom: 10,
    borderBottom: '1px solid #91B1BF',
    '&:first-of-type': {
      borderTop: '1px solid #91B1BF',
    },
  },
  list__item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&:not(:last-child)': {
      marginBottom: 7,
    },
  },
  list__item_fullWidth: {
    width: '100%',
  },
  list__title: {
    marginTop: 0,
    marginBottom: 2,
    fontSize: 12,
    lineHeight: '16px',
    color: '#91B1BF',
  },
  list__text: {
    margin: 0,
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.type === 'light' ? '#000' : '#fff',
  },
  Phone__container: {
    padding: '0 17px',
  },
  Phone__header: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#91B1BF',
  },
  Phone__value: {
    margin: 0,
    marginBottom: 6,
    fontSize: 14,
    lineHeight: '19px',
  },
  amountWrapper__SecuritiesIOBalance: {
    top: 6,
    right: -193,
  },
  amountWrapper__SecuritiesTransferBalance: {
    top: 10,
    right: -193,
  },
  amountWrapper__SecuritiesTransferAvailable: {
    top: 10,
    right: -208,
  },
  amountWrapper: {
    position: 'absolute',
    '@media (max-width: 576px)': {
      position: 'initial',
      paddingTop: 15,
    },
  },
  amountInfo: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 7,
    borderLeft: '7px solid #91B1BF',
    '@media (max-width: 576px)': {
      paddingLeft: 0,
      borderLeft: 0,
    },
  },
  amountInfo__text: {
    position: 'relative',
    top: -1,
    margin: 0,
    fontSize: 12,
    lineHeight: '14px',
    color: '#91B1BF',
    '@media (max-width: 576px)': {
      position: 'static',
      top: 0,
      marginBottom: 4,
      fontWeight: 600,
    },
  },
  amountInfo__value: {
    position: 'relative',
    top: 1,
    margin: 0,
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.type === 'light' ? '#000' : '#fff',
    '@media (max-width: 576px)': {
      position: 'static',
      top: 0,
      fontWeight: 600,
    },
  },
  infoBlock: {
    marginTop: 30,
    '@media (max-width: 576px)': {
      marginTop: 12,
    },
  },
  infoBlock__item: {
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  },
  infoBlock__content: {
    boxSizing: 'border-box',
    marginTop: 12,
    padding: '11px 18px',
    backgroundColor: 'rgba(145, 177, 191, 0.1)',
    '@media (max-width: 576px)': {
      marginTop: 7,
      padding: '11px 12px',
    },
  },
  infoBlock__row: {
    display: 'flex',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '& $infoBlock__text': {
      '&:first-child': {
        marginRight: 8,
      },
    },
  },
  infoBlock__row_mbBottom: {
    '&:not(:last-of-type)': {
      marginBottom: 11,
      '@media (max-width: 576px)': {
        marginBottom: 8,
      },
    },
  },
  infoBlock__text: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '21px',
    color: 'rgba(0, 0, 0, 0.6)',
    '@media (max-width: 576px)': {
      fontSize: 14,
      lineHeight: '19px',
    },
    '& span': {
      fontWeight: 600,
    },
  },
  bank__details: {
    '& > span:first-child': {
      display: 'block',
      paddingLeft: 3,
      fontSize: 16,
      color: '#fff',
      backgroundColor: '#91B1BF',
    },
    '& > span:nth-child(2)': {
      display: 'block',
      paddingLeft: 4,
      color: theme.palette.type === 'light' ? '#91B1BF' : '#fff',
      fontSize: 13,
    },
  },
  urgent__payment: {
    '& > label': {
      height: 20,
    },
    '& > p > span:first-child': {
      display: 'block',
      paddingLeft: 27,
      color: theme.palette.type === 'light' ? '#91B1BF' : '#fff',
      fontSize: 13,
    },
  },
}))

export default useStyles

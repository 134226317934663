import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Media from 'react-media'
import EpsTable from './EpsTable'
import {
  fetchBrokerFilterStartAsync,
  fetchBrokerStartAsync,
  // getTableDataSuccess,
} from '../../redux/reports/reportsSlice'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
// import { selectReportsDataTable } from '../../redux/reports/selector'
import { rowsPerPage } from '../../config/constants'
import UsePagination from '../Pagination/Pagination'
import { selectConnectionState } from '../../redux/connection/selector'
import PageHeading from '../PageHeading/PageHeading'
import useStyles from './panel.style'
import FilterForm from './FilterForm'

const LogBroker = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [page, setPage] = useState(0)

  const connectedWS = useSelector(selectConnectionState)
  // const reportsBrokerData = useSelector(selectReportsDataTable)
  const reportsBrokerData = []

  const fetchBrokerStart = () => {
    dispatch(fetchBrokerStartAsync())
  }

  const fetchBrokerFilterStart = () => {
    dispatch(fetchBrokerFilterStartAsync())
  }

  const {
    getData,
  } = useConnect({
    link: `GET /api${endpoints.reports.broker}`,
    startFetching: fetchBrokerStart,
    // action: getTableDataSuccess,
  })

  const {
    getData: brokerFilter,
  } = useConnect({
    link: `GET /api${endpoints.reports.brokerFilter()}`,
    startFetching: fetchBrokerFilterStart,
    // action: getTableDataSuccess,
  })

  useEffect(() => {
    if (connectedWS) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  useEffect(() => {
    if (reportsBrokerData?.references?.accounts) {
      const currentReportsLength = reportsBrokerData?.reports?.length
      if (page * rowsPerPage > currentReportsLength) {
        setPage(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsBrokerData])

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  return (
    <>
      <PageHeading text="Брокерские отчеты" />
      <Box className={classes.boxWrapper}>
        <FilterForm
          accounts={reportsBrokerData && reportsBrokerData?.references?.accounts}
          fetchFilterStart={brokerFilter}
        />
        <Box className={classes.tableWrapper}>
          <EpsTable
            page={page}
            reportsArrayData={reportsBrokerData && reportsBrokerData?.reports}
            brokerFilter={brokerFilter}
          />
          <Media
            query="(min-width: 577px)"
            render={() => (
              <Box pt={3}>
                <UsePagination
                  count={
                    reportsBrokerData
                      ? Math.floor(reportsBrokerData?.reports?.length / rowsPerPage)
                      : 0
                  }
                  currentPage={page}
                  onChangePage={handleChangePage}
                />
              </Box>
            )}
          />
        </Box>
      </Box>
    </>
  )
}

export default LogBroker

import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import DatePicker, { CalendarContainer } from 'react-datepicker'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { InputAdornment } from '@material-ui/core'
import {
  getYear, getMonth,
  startOfMonth,
  endOfMonth,
  endOfYear,
  startOfYear,
  addMonths,
  differenceInMonths,
  addYears,
  differenceInYears,
  isBefore,
  isAfter,
} from 'date-fns'
import CustomInput from './CustomInput'
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg'
import { ReactComponent as ArrowBottomIcon } from '../../assets/images/arrow-bottom-uniq.svg'
import useStyles from './CustomControls.style'
import 'react-datepicker/dist/react-datepicker.css'
import { getMonthName, getShortMonthName, getYearsPeriod } from '../../utils/Month'
import { getDate } from '../../utils/GetDate'

const CustomDatepicker = ({
  label, error, errorText, required, unique, onChangeYear,
  minDate, maxDate, onChangeMonth, value, onChange, setDateTo, setDateFrom,
  isDateTo, isDateFrom, disabled,
}) => {
  const classes = useStyles()
  const [showYearPicker, setShowYearPicker] = useState(false)
  const [showMonthPicker, setShowMonthPicker] = useState(false)
  let isButtonActive = true
  const datePicker = useRef()
  const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
  const locale = {
    localize: {
      month: (n) => getShortMonthName(n),
      day: (n) => days[n],
    },
    formatLong: {},
    options: {
      firstWeekContainsDate: 1,
      weekStartsOn: 1,
    },
    formatDistance: {},
    formatRelative: {},
    match: {},
  }

  const handleChangeDateMonth = (date) => {
    if (isDateTo || isDateFrom) {
      const oldDate = isDateFrom ? minDate || new Date() : maxDate || minDate || new Date()
      const newDate = addMonths(oldDate, differenceInMonths(startOfMonth(date),
        startOfMonth(oldDate)))
      if (isDateFrom) {
        setDateFrom(
          isAfter(newDate, maxDate)
            ? maxDate
            : newDate,
        )
        return
      }
      setDateTo(
        isBefore(newDate, minDate)
          ? minDate
          : newDate,
      )
    }
  }

  const handleChangeDateYear = (date) => {
    if (isDateTo || isDateFrom) {
      const oldDate = isDateFrom ? minDate || new Date() : maxDate || minDate || new Date()
      const newDate = addYears(oldDate, differenceInYears(startOfYear(date), startOfYear(oldDate)))
      if (isDateFrom) {
        setDateFrom(
          isAfter(newDate, maxDate)
            ? maxDate
            : newDate,
        )
        return
      }
      setDateTo(
        isBefore(newDate, minDate)
          ? minDate
          : newDate,
      )
    }
  }

  const renderYearHeader = (date) => {
    const { startPeriod, endPeriod } = getYearsPeriod(date)
    return (
      <div className="react-datepicker__header react-datepicker-year-header">
        {showYearPicker ? `${startPeriod} - ${endPeriod}` : getYear(date)}
      </div>
    )
  }
  // eslint-disable-next-line react/prop-types
  const DaysContainer = ({ className, children }) => (
    <div>
      {(!showYearPicker && !showMonthPicker) && (
        <CalendarContainer className={className}>{children}</CalendarContainer>
      )}
    </div>
  )

  const HeaderContainer = ({
    // eslint-disable-next-line react/prop-types
    date, prevMonthButtonDisabled, changeMonth, nextMonthButtonDisabled, hideContent, changeYear,
  }) => (
    <div className={classes.CustomDatepicker__headerButtons}>
      <div className={classes.CustomDatepicker__monthYearButtons}>
        {(!hideContent || (hideContent !== 'monthYear' && hideContent === 'year')) && (
        <button
          type="button"
          className={
              classnames(classes.CustomDatepicker__button,
                classes.CustomDatepicker__button_month)
            }
          onClick={() => {
            setShowYearPicker(false)
            setShowMonthPicker(true)
          }}
        >
          {getMonthName(getMonth(date))}
        </button>
        )}
        {(!hideContent || (hideContent !== 'monthYear' && hideContent === 'month')) && (
        <button
          className={classes.CustomDatepicker__button}
          type="button"
          onClick={() => {
            setShowYearPicker(true)
            setShowMonthPicker(false)
          }}
        >
          {getYear(date)}
        </button>
        )}
        {hideContent === 'monthYear' && (
          renderYearHeader(date)
        )}
      </div>

      <div className={classes.CustomDatepicker__rangeButtonWrapper}>
        <button
          className={classes.CustomDatepicker__rangeButton}
          type="button"
          onClick={() => {
            if (isButtonActive) {
              if (hideContent !== 'monthYear') {
                isButtonActive = false
              }
              const currDate = new Date(date)
              if (hideContent === 'monthYear') {
                changeYear(currDate.getFullYear() - 12)
              } else if (hideContent === 'month') {
                changeYear(currDate.getFullYear() - 1)
              } else {
                changeMonth(currDate.getMonth() - 1)
              }
              if (hideContent !== 'monthYear') {
                isButtonActive = true
              }
            }
          }}
          disabled={prevMonthButtonDisabled}
        >
          <ArrowBottomIcon />
        </button>
        <button
          className={classes.CustomDatepicker__rangeButton}
          type="button"
          onClick={() => {
            if (isButtonActive) {
              if (hideContent !== 'monthYear') {
                isButtonActive = false
                setTimeout(() => {
                }, 1)
              }
              const currDate = new Date(date)
              if (hideContent === 'monthYear') {
                // setStartDate(new Date(date.getFullYear() + 12, date.getMonth()))
                changeYear(currDate.getFullYear() + 12)
              } else if (hideContent === 'month') {
                changeYear(currDate.getFullYear() + 1)
              } else {
                changeMonth(currDate.getMonth() + 1)
              }
              if (hideContent !== 'monthYear') {
                isButtonActive = true
              }
            }
          }}
          disabled={nextMonthButtonDisabled}
        >
          <ArrowBottomIcon />
        </button>
      </div>
    </div>
  )

  return (
    <>
      <FormControl
        error={error}
        className={classnames(classes.formControl, { [classes.uniqueFormControl]: unique })}
        fullWidth
        required={required}
      >

        <FormLabel
          className={classes.inputLabel}
        >
          {label}
        </FormLabel>
        <DatePicker
          onChangeYear={onChangeYear}
          minDate={getDate(minDate)}
          maxDate={getDate(maxDate)}
          onChangeMonth={onChangeMonth}
          onChange={onChange}
          dateFormat="dd.MM.yyyy"
          ref={datePicker}
          customInput={(
            <Input />
          )}
          popperClassName={classes.CustomDatepicker__popper}
          calendarClassName={classes.CustomDatepicker__calendar}
          disabled={disabled}
          selected={getDate(value)}
          locale={locale}
          calendarContainer={DaysContainer}
          renderCustomHeader={({
            date,
            changeMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <HeaderContainer
              changeMonth={changeMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
              date={date}
            />
          )}
        />
        {showMonthPicker && (
          <div className={classes.CustomDatepicker__modal}>
            <DatePicker
              onChange={((isDateTo || isDateFrom) && handleChangeDateMonth) || onChange}
              minDate={startOfMonth(minDate)}
              maxDate={endOfMonth(maxDate)}
              disabled={disabled}
              onSelect={() => {
                setShowMonthPicker(false)
                datePicker.current.setOpen(true)
              }}
              onClickOutside={() => setShowMonthPicker(false)}
              popperClassName={classes.CustomDatepicker__popper}
              calendarClassName={classnames(classes.CustomDatepicker__calendar,
                classes.CustomDatepicker__monthCalendar)}
              showMonthYearPicker
              locale={locale}
              selected={getDate(value)}
              renderCustomHeader={({
                date,
                changeMonth,
                changeYear,
                increaseMonth,
                decreaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <HeaderContainer
                  date={date}
                  changeMonth={changeMonth}
                  changeYear={changeYear}
                  increaseMonth={increaseMonth}
                  decreaseMonth={decreaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                  hideContent="month"
                />
              )}
              inline
              peekNextMonth
            />
          </div>
        )}
        {showYearPicker && (
          <div className={classes.CustomDatepicker__modal}>
            <DatePicker
              onChange={((isDateTo || isDateFrom) && handleChangeDateYear) || onChange}
              minDate={startOfYear(minDate)}
              maxDate={endOfYear(maxDate)}
              disabled={disabled}
              onClickOutside={() => setShowYearPicker(false)}
              popperClassName={classes.CustomDatepicker__popper}
              calendarClassName={classnames(classes.CustomDatepicker__calendar,
                classes.CustomDatepicker__yearCalendar)}
              yearItemNumber={12}
              showYearPicker
              locale={locale}
              selected={getDate(value)}
              onSelect={() => {
                setShowYearPicker(false)
                datePicker.current.setOpen(true)
              }}
              renderCustomHeader={({
                date,
                changeMonth,
                changeYear,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <HeaderContainer
                  changeMonth={changeMonth}
                  changeYear={changeYear}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                  hideContent="monthYear"
                  date={date}
                />
              )}
              inline
              peekNextMonth
            />
          </div>
        )}
        {error && (
          <FormHelperText>{errorText}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

CustomDatepicker.defaultProps = {
  error: false,
  errorText: '',
  required: false,
  unique: false,
  value: null,
  onChangeYear: () => {},
  onChangeMonth: () => {},
  onChange: () => {},
  minDate: null,
  maxDate: null,
  setDateTo: PropTypes.func,
  setDateFrom: PropTypes.func,
  isDateTo: false,
  isDateFrom: false,
  disabled: false,
}

CustomDatepicker.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  required: PropTypes.bool,
  unique: PropTypes.bool,
  onChangeYear: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChangeMonth: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date),
  setDateTo: PropTypes.func,
  setDateFrom: PropTypes.func,
  isDateTo: PropTypes.bool,
  isDateFrom: PropTypes.bool,
  disabled: PropTypes.bool,
}

function Input(props) {
  const ref = useRef(null)
  return (
    <CustomInput
      {...props}
      variant="outlined"
      size="small"
      unique
      format="##.##.####"
      InputProps={{
        endAdornment: (
          <InputAdornment>
            <CalendarIcon />
          </InputAdornment>
        ),
      }}
      ref={ref}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === 'Escape') {
          ref.current.querySelector('input').blur()
          setTimeout(() => {
            ref.current.querySelector('input').focus()
          }, 100)
          props.onKeyDown(e)
        }
      }}
    />
  )
}

Input.defaultProps = {
  onKeyDown: () => {},
}

Input.propTypes = {
  onKeyDown: PropTypes.func,
}

export default CustomDatepicker

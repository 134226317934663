import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  Modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      boxSizing: 'border-box',
      justifyContent: 'flex-end',
      paddingRight: 6,
    },
    '& > div': {
      '&:first-child': {
        backgroundColor: '#0000002F !important',
        '@media (max-width: 576px)': {
          backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
        },
      },
    },
  },
  Modal__inner: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 391,
    outline: 'none',
    '@media (max-width: 576px)': {
      maxWidth: 'calc(100vw - 46px - 12px)',
      width: '100%',
    },
  },
  Modal__inner_unique: {
    maxWidth: 308,
    width: '100%',
    '@media (max-width: 576px)': {
      maxWidth: 'calc(100vw - 46px - 12px)',
    },
    '& $Modal__content': {
      paddingTop: 26,
      paddingLeft: 28,
      paddingRight: 28,
      '@media (max-width: 576px)': {
        paddingTop: 14,
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  },
  Modal__inner_error: {
    maxWidth: 418,
    width: '100%',
    '@media (max-width: 576px)': {
      maxWidth: 'calc(100vw - 46px - 12px)',
    },
    '& $Modal__infoWrapper': {
      textAlign: 'left',
    },
    '& $Modal__title': {
      width: 'auto',
    },
  },
  Modal__content: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 280,
    padding: '25px 36px',
    borderRadius: '5px',
    backgroundColor: '#91B1BF',
    outline: 'none',
    '@media (max-width: 576px)': {
      position: 'relative',
      width: '100%',
      minWidth: 'auto',
      padding: '19px 20px',
    },
  },
  Modal__content_unique: {
    flexDirection: 'column',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: 13,
      paddingLeft: 10,
      paddingRight: 10,
    },
    '& $Modal__title': {
      '@media (max-width: 576px)': {
        marginBottom: 11,
      },
    },
  },
  Modal__content_actions: {
    flexDirection: 'column',
  },
  Modal__infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    textAlign: 'center',
  },
  Modal__title: {
    whiteSpace: 'pre-line',
    maxWidth: 321,
    width: '100%',
    margin: 0,
    fontSize: 16,
    lineHeight: '21px',
    color: '#FFF',
  },
  Modal__list: {
    marginTop: 15,
    paddingTop: 0,
    paddingBottom: 0,
    '@media (max-width: 576px)': {
      marginTop: 15,
      marginBottom: 3,
    },
    '& .MuiListItem-root': {
      position: 'relative',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 14,
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: 4,
        height: 4,
        backgroundColor: '#fff',
      },
      '&:not(:last-child)': {
        marginBottom: 13,
      },
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiTypography-root': {
      fontSize: 13,
      lineHeight: '15px',
      color: '#fff',
    },
  },
  Modal__actions: {
    position: 'relative',
    top: -24,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 20px 26px',
    borderRadius: '0px 0px 5px 5px',
    backgroundColor: '#91B1BF',
    '@media (max-width: 576px)': {
      top: -12,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 19,
    },
  },
  Modal__actionsText: {
    margin: 0,
    fontSize: 16,
    lineHeight: '21px',
    color: '#fff',
    '@media (max-width: 576px)': {
      marginBottom: 16,
    },
  },
  Modal__actionsButtonsWrapper: {
    display: 'flex',
    marginTop: 17,
    '@media (max-width: 576px)': {
      marginTop: 0,
    },
    '& button': {
      boxSizing: 'border-box',
      minWidth: 50,
      padding: '5px 4px 6px',
      borderRadius: '5px',
      border: '1px solid #000',
      backgroundColor: '#91B1BF',
      fontFamily: 'Open Sans',
      fontSize: 14,
      lineHeight: '19px',
      color: '#000',
      cursor: 'pointer',
      '&:not(:last-child)': {
        marginRight: 57,
      },
      '&:nth-child(2)': {
        borderColor: '#fff',
        backgroundColor: '#fff',
        color: '#000',
      },
    },
  },
})

export default useStyles


export const TOTAL_STATUS = 'Все'

export const initialState = {
  portfolio: {
    portfolios: [],
    currentPortfolio: '',
  },
  orderType: {
    orderTypes: [],
    currentOrderType: '',
  },
  status: {
    statuses: [],
    currentStatus: TOTAL_STATUS,
  },
  date: {
    from: null,
    to: null,
  },
}

export const securityCancelUid = 'Orders.Type.Modify.Cancellation'
export const securityTransferUid = 'Orders.Type.NonTrade.Depo.Transfer'
export const securityInputUid = 'Orders.Type.NonTrade.Depo.In'
export const securityOutputUid = 'Orders.Type.NonTrade.Depo.Out'

export const statuses = [
  'Все',
  'Черновик',
  'Получено исполнителем',
  'На исполнении',
  'Отменено клиентом',
  'Отказ в исполнении',
  'Исполнено',
]

import { styled } from "ui/theme";

export const SubTitle = styled.h2`
  ${({theme}) => theme.font.body1_lg}
  color: ${({theme}) => theme.color.text.primary};
`

export const CardsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.md};
  row-gap: ${({ theme }) => theme.spacing.lg};

  @media ${({ theme }) => theme.breakpoint.md} {
    gap: ${({ theme }) => theme.spacing.xl};
  }
`

export const CardWrapper = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 380px;
  gap: 24px;

  padding: 32px;

  border-radius: 8px;
  background-color: ${({theme}) => theme.color.bg.primary};
  box-shadow: 0 0 20px 2px rgba(49, 58, 78, 0.06);
`

export const CardTitle = styled.p`
  ${({theme}) => theme.font.body1_lg}

  margin: 0;

  color: ${({theme}) => theme.color.text.primary};
`

export const CardInfoBlock = styled.address`
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin: 0;
`

export const ContactInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ContactInfoKey = styled.p`
  ${({theme}) => theme.font.body2_lg}

  margin-bottom: 0;
  margin-right: 10px;

  color: ${({theme}) => theme.color.text.secondary};
`

export const ContactInfoValue = styled.a<{isEmail?: boolean}>`
  ${({theme}) => theme.font.body2_lg}

  color: ${({theme, isEmail}) => isEmail ? theme.color.text.accent : theme.color.text.primary};
`

export const Description = styled.p`
  ${({theme}) => theme.font.body2_lg}

  color: ${({theme}) => theme.color.text.secondary};
`

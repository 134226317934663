import React from 'react'
import { styled } from 'ui/theme'
import { ITabProps, ITabsProps } from './types'

export const StyledTabs = styled.div.withConfig({
  shouldForwardProp: (prop, defPropValFN) => !['variant'].includes(prop) && defPropValFN(prop),
})<ITabsProps>`
  display: inline-flex;
  // gap: ${({ theme }) => theme.spacing.xxs};
  ${({ theme, variant }) => `
  ${
    variant === 1
      ? `
    padding: 1px;
    border-radius: 10px;
    border: 1px solid ${theme.color.button.primary};
    gap: ${theme.spacing.xxs};
      `
      : ''
  }
  ${
    variant === 2
      ? `
    // gap: ${theme.spacing.lg};
    overflow: auto;
    max-width: 100vw;
  `
      : ''
  }
  `}

  &::-webkit-scrollbar:horizontal {
    height: 0;
    width: 0;
    display: none;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    display: none;
  }
`

export const StyledTab = styled(({ component = 'div', ...props }) =>
  React.createElement(component, props)
)<ITabProps> // }) //     !['active', 'variant', 'label'].includes(prop as string) && defPropValFN(prop), //   shouldForwardProp: (prop, defPropValFN) => // .withConfig({
`
${({ theme, variant, font }) => `
    ${font || theme.font.body1_lg}
    cursor: pointer;
    text-decoration: none;
    ${
      variant === 1
        ? `
        flex: 1;
        flex-shrink: 0;
        white-space: nowrap;
        text-align: center;
        border-radius: 10px;
        background-color: ${theme.color.text.inverted};
        color: ${theme.color.button.primary};
        padding: 10px ${theme.spacing.sm};
        &.active {
          background-color: ${theme.color.button.primary};
          color: ${theme.color.text.inverted};
          cursor: default;
        }
        @media (${theme.breakpoint.xs}) {
        padding: 10px ${theme.spacing.xs};
        }
      `
        : ''
    }
    ${
      variant === 2
        ? `
        color: ${theme.color.graphics.tertiary};
        padding: ${theme.spacing.smd} ${theme.spacing.xl};
        border-bottom: 1px solid ${theme.color.bg.secondary};
        &.active {
          border-bottom: 1px solid ${theme.color.graphics.primary};
          color: ${theme.color.graphics.primary};
          cursor: default;
        }
        @media ${theme.breakpoint.xs} {
          padding: ${theme.spacing.smd};
        }
      `
        : ''
    }

    @media ${theme.breakpoint.xs} {
      ${theme.font.body1}
    }
`}
}
`

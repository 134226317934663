import React, { useEffect, useState } from 'react'
import {
  Redirect, Route, Switch, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Media from 'react-media'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import MoneyTransfer from './MoneyTransfer'
import MoneyOutput from './MoneyOutput'
import MoneyPanel from '../HistoryPanels/MoneyPanel'
import useStyles from '../InnerTabs/InnerTabs.style'
import useWindowSize from '../../hooks/useWindows'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import PageHeading from '../PageHeading/PageHeading'

export default function Money() {
  const classes = useStyles()
  const { windowWidth } = useWindowSize()
  const [secondNestingVisibility, setSecondNestingVisibility] = useState(true)
  const { url, path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const splitPathname = pathname.split('/')
  const isPathTransferClassName = splitPathname[3] === 'transfer'
  const isPathOutputClassName = splitPathname[3] === 'output'
  const isPathLogClassName = splitPathname[3] === 'log'

  useEffect(() => {
    if (windowWidth <= 576) {
      if ((pathname.match(/\//g) || []).length >= 3) {
        setSecondNestingVisibility(false)
      } else {
        setSecondNestingVisibility(true)
      }
    } else {
      setSecondNestingVisibility(true)
    }
  }, [windowWidth, pathname])

  const goTo = (link) => history.push(`${url}/${link}`)
  const getDynamicClassname = (isActive) => (isActive ? `${classes.breadCrumb} ${classes.activeCrumb}` : classes.breadCrumb)

  return (
    <>
      <Box maxWidth={898}>
        {secondNestingVisibility && (
          <>
            <PageHeading text="Денежные средства" />
            <Grid
              container
              className={classes.crumbContainer}
            >
              <button
                type="button"
                className={getDynamicClassname(isPathTransferClassName)}
                onClick={() => goTo('transfer/new')}
              >
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px)`}
                  render={() => (
                    <TransfersIcon />
                  )}
                />
                <span>Перевод</span>
                <Media
                  query="(min-width: 577px)"
                  render={() => (
                    <ArrowRightIcon />
                  )}
                />
              </button>
              <button
                type="button"
                className={getDynamicClassname(isPathOutputClassName)}
                onClick={() => goTo('output/new')}
              >
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px)`}
                  render={() => (
                    <TransfersIcon />
                  )}
                />
                <span>Вывод</span>
                <Media
                  query="(min-width: 577px)"
                  render={() => (
                    <ArrowRightIcon />
                  )}
                />
              </button>
              <button
                type="button"
                className={getDynamicClassname(isPathLogClassName)}
                onClick={() => goTo('log')}
              >
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px)`}
                  render={() => (
                    <TransfersIcon />
                  )}
                />
                <span>История поручений</span>
                <Media
                  query="(min-width: 577px)"
                  render={() => (
                    <ArrowRightIcon />
                  )}
                />
              </button>
            </Grid>
          </>
        )}
      </Box>
      <Switch>
        <Route
          exact
          path={path}
        >
          {windowWidth > 577 ? <Redirect to={`${path}/transfer/new`} /> : null}
        </Route>
        <Route
          exact
          path={`${path}/transfer/:id`}
          component={MoneyTransfer}
        />
        <Route
          exact
          path={`${path}/output/:id`}
          component={MoneyOutput}
        />
        <Route
          exact
          path={`${path}/log`}
          component={MoneyPanel}
        />
      </Switch>
    </>
  )
}

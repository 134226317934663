export const sortArrayByOrder = (
  array: any[],
  order: any[],
  sorterField?: string
): any[] => {

  return [...array].sort((a, b) => {
    const aIndex = order.indexOf(sorterField ? a[sorterField] : a)
    const bIndex = order.indexOf(sorterField ? b[sorterField] : b)

    if (aIndex < 0 || bIndex < 0) {
      // If a name is not in the order array, move it to the end of the array
      return aIndex < 0 ? 1 : -1
    }

    return aIndex - bIndex
  })
}

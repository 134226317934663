import getCadespluginAPI from 'async-cadesplugin'
import { createSlice } from '@reduxjs/toolkit'
import JsonToSHA256 from '../../utils/JsonToSHA256'
import { queryApi } from '../queryApi'
import {
  NEW_RECORD_ACTION,
  UPDATE_RECORD_ACTION,
  updateCache,
} from '../../hooks/useUpdateQueryCacheFromNotificationService'
import {removeKeysFromObject} from "../../utils/RemoveKeysFromObject";

interface ISendFeedbackMessageParams {
  arg: any
  code?: boolean
}

export const feedbackApi = queryApi.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedbackMessage: builder.mutation<any, ISendFeedbackMessageParams>({
      queryFn: async ({ arg, code }, _queryApi, _extraOptions, fetchWithBQ) => {
        if (arg?.id) {
          const _arg = { ...arg }
          if (_arg?.thumbPrint && _arg?.signedXml) {
            const api = await getCadespluginAPI()
            const signature = await api?.signXml(_arg.thumbPrint, _arg.signedXml)
            _arg.signedXml = signature
          }

          return await fetchWithBQ({
            url: 'documents?instruction=execute',
            method: 'PUT',
            body: _arg,
            validateStatus: (response) => response?.status === 303 || response?.status === 200,
          })
        } else {
          let codeArg: any = {}

          if (code) {
            const postResponse = await fetchWithBQ({
              url: 'documents',
              method: 'POST',
              body: arg,
              validateStatus: (response) => response?.status === 303 || response?.status === 200,
            })

            codeArg = postResponse?.data

            codeArg.secret = ''

            codeArg.secret = JsonToSHA256(codeArg)
          }

          return await fetchWithBQ({
            url: 'documents?instruction=execute',
            method: code ? 'PUT' : 'POST',
            body: code ? codeArg : arg,
            validateStatus: (response) => response.status === 303 || response.status === 200,
          })
        }
      },
      onQueryStarted: async (originalArg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled

          updateCache({
            dispatch,
            recordId: data?.id,
            recordAction: originalArg?.arg?.id ? UPDATE_RECORD_ACTION : NEW_RECORD_ACTION,
            gridUid: 'UsersRequests',
            documentData: removeKeysFromObject(data, 'requiredAction'),
          })
        } catch (e) {}
      },
    }),
  }),
})

export const {
  useSendFeedbackMessageMutation,
} = feedbackApi


const slice = createSlice({
  name: 'feedbackNew',
  initialState: { status: '' },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(feedbackApi.endpoints.sendFeedbackMessage.matchFulfilled, (state, action ) => {
      //@ts-ignore
      state.status = action?.meta?.baseQueryMeta?.response?.status
    })
  },
})

const { reducer } = slice

export default reducer;

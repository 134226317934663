import {
  persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,
} from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './root-reducer'
import { queryApi } from "./queryApi";

const customMiddleWare = []

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...customMiddleWare,
    ...getDefaultMiddleware( {
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      queryApi.middleware,
    ),
  ],
})

const persistor = persistStore(store)

export { store, persistor }

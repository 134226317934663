import React from 'react';
import { IBadgeProps } from './types';
import { StyledBadge, StyledBadgeValue } from './styled';
import { Icon } from '../Icon';

export const Badge: React.FC<IBadgeProps> = ({ variant, value, size = 'sm', icon }) => {
  return <StyledBadge variant={variant} size={size}>
    <StyledBadgeValue>{value}</StyledBadgeValue>
    {icon &&
      <Icon name={icon} size={'xs'} />
    }
  </StyledBadge>;
}

export * from './types';
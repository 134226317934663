import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import CollapseBar from '../../CollapseBar/CollapseBar'
import { selectFormsData } from '../../../redux/profilePanel/selector'
import RequisitesContent from '../collapseContents/requisites'

export default function RequisitesPanel() {
  const respGetData = useSelector(selectFormsData)
  const bankRequisites = respGetData?.forms[0]?.list[0]?.bankRequisites
  return (
    <Box pt="50px">
      <CollapseBar
        label="Банковские реквизиты для расчетов"
        defaultExpanded={bankRequisites?.length > 0}
      >
        <RequisitesContent
          template="settlements"
          data={bankRequisites || null}
        />
      </CollapseBar>
      <CollapseBar
        label="Банковские реквизиты для перечисления доходов и (или) выплат по ЦБ"
      >
        <RequisitesContent
          template="securitiesIncome"
          data={bankRequisites || null}
        />
      </CollapseBar>
    </Box>
  )
}

import React from 'react'
import { StyledBlockHeading, StyledBlockHeadingCaption, StyledBlockHeadingTitle, StyledBlockHeadingTop } from './styled'
import { IBlockHeadingProps } from './types'

export const BlockHeading: React.FC<IBlockHeadingProps> = ({ title, caption, border = true, actions = [], ...props }) => {
  return <StyledBlockHeading border={border} {...props}>
    <StyledBlockHeadingTop>
      <StyledBlockHeadingTitle>{title}</StyledBlockHeadingTitle>
      {actions}
    </StyledBlockHeadingTop>
    {caption && <StyledBlockHeadingCaption>{caption}</StyledBlockHeadingCaption>}
  </StyledBlockHeading>
}
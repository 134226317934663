import { request } from './Request'
import { syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getHistoryMoney() {
    return request(syncApiURL, endpoints.history.getMoney)
  },
  getHistoryDepo() {
    return request(syncApiURL, endpoints.history.getDepo)
  },
  getHistoryMoneyFilter(data) {
    return request(syncApiURL, endpoints.history.moneyFilter(data))
  },
  getHistoryDepoFilter(data) {
    return request(syncApiURL, endpoints.history.depoFilter(data))
  },
  getOrdersTypesNames() {
    return request(syncApiURL, endpoints.history.ordersTypesNames)
  },

}

import { createSlice } from '@reduxjs/toolkit'
import getCadespluginAPI from 'async-cadesplugin'
import Api from '../../../api/Api'
import { getErrorSuccess } from '../../error/errorSlice'

const initialState = {
  getTransferSecurity: null,
  saveTransferSecurity: null,
  saveTransferError: null,
  performTransferSecurity: null,
  performTransferError: null,
  balances: null,
  error: null,
}

const ordersSlice = createSlice({
  name: 'transferSecurity',
  initialState,
  reducers: {
    getTransferSecurity(state) {
      return {
        ...state,
        error: null,
      }
    },
    getTransferSecuritySuccess(state, action) {
      return {
        ...state,
        getTransferSecurity: action.payload,
      }
    },
    getTransferSecurityFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    saveTransferSecurity(state) {
      return {
        ...state,
        saveTransferError: null,
      }
    },
    saveTransferSecuritySuccess(state, action) {
      return {
        ...state,
        saveTransferSecurity: action.payload,
      }
    },
    saveTransferSecurityFailed(state, action) {
      return {
        ...state,
        saveTransferError: action.payload,
      }
    },
    performTransferSecurity(state) {
      return {
        ...state,
        performTransferError: null,
      }
    },
    performTransferSecuritySuccess(state, action) {
      return {
        ...state,
        performTransferSecurity: action.payload,
      }
    },
    performTransferSecurityFailed(state, action) {
      return {
        ...state,
        performTransferError: action.payload,
      }
    },
    getBalancesDepo(state) {
      return {
        ...state,
      }
    },
    getBalancesDepoSuccess(state, action) {
      return {
        ...state,
        balances: action.payload,
      }
    },
    getBalancesDepoFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    clearTransferSecurity() {
      return initialState
    },
    clearPartTransferSecurity(state) {
      return {
        ...state,
        saveTransferSecurity: null,
        saveTransferError: null,
        performTransferSecurity: null,
        performTransferError: null,
        error: null,
      }
    },
  },
})

const { actions, reducer } = ordersSlice

export const {
  getTransferSecurity,
  getTransferSecuritySuccess,
  getTransferSecurityFailed,
  saveTransferSecurity,
  saveTransferSecuritySuccess,
  saveTransferSecurityFailed,
  performTransferSecurity,
  performTransferSecuritySuccess,
  performTransferSecurityFailed,
  getBalancesDepo,
  getBalancesDepoSuccess,
  getBalancesDepoFailed,
  clearTransferSecurity,
  clearPartTransferSecurity,
} = actions

export default reducer

export const fetchTransferSecurityStartAsync = ({ id }) => async (dispatch) => {
  dispatch(getTransferSecurity())
  try {
    const value = await Api.getTransferSecurity(id)
    dispatch(getTransferSecuritySuccess(value))
  } catch (err) {
    dispatch(getTransferSecurityFailed(err))
  }
}

export const fetchSaveTransferSecurityStartAsync = ({ data, id }) => async (dispatch) => {
  dispatch(saveTransferSecurity())
  try {
    if (id) {
      const value = await Api.putTransferSecurity(data, id)
      dispatch(saveTransferSecuritySuccess(value))
    } else {
      const value = await Api.postTransferSecurity(data, false)
      dispatch(saveTransferSecuritySuccess(value))
    }
  } catch (err) {
    dispatch(saveTransferSecurityFailed(err))
    dispatch(getErrorSuccess(err))
  }
}

export const fetchPerformTransferSecurityStartAsync = ({ data, id = null }) => async (dispatch) => {
  dispatch(performTransferSecurity())
  try {
    if (data?.thumbPrint && data?.signedXml) {
      const api = await getCadespluginAPI()
      const signature = await api.signXml(data.thumbPrint, data.signedXml)
      const signedObj = data
      signedObj.signedXml = signature
      const value = await Api.putTransferSecurity(signedObj, id, true)
      dispatch(performTransferSecuritySuccess(value))
    } else if (!id) {
      const value = await Api.postTransferSecurity(data, true)
      dispatch(performTransferSecuritySuccess(value))
    } else {
      const value = await Api.putTransferSecurity(data, id, true)
      dispatch(performTransferSecuritySuccess(value))
    }
  } catch (err) {
    dispatch(performTransferSecurityFailed(err))
    dispatch(getErrorSuccess(err))
  }
}

export const fetchGetBalancesDepoStartAsync = ({ filterBody }) => async (dispatch) => {
  dispatch(getBalancesDepo())
  try {
    if (filterBody) {
      const value = await Api.getBalancesDepo(filterBody)
      dispatch(getBalancesDepoSuccess(value))
    }
  } catch (err0) {
    dispatch(getBalancesDepoFailed())
  }
}

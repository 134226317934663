import { queryApi } from '../queryApi'
import {
  UPDATE_RECORD_ACTION,
  updateCache,
} from '../../hooks/useUpdateQueryCacheFromNotificationService'

type TDocumentDataType = {
  id: number
  typeUid: string
  isAccept: boolean
  statusUid: string
  statusName: string
  date: string
  time: string
  number: number
  files: {
    uid: string
    fileName: string
    fileLength: number
    signUid: string
    signFileName: string
    signFileLength: number
  }[]
  requiredAction?: {
    type: string
    cause: string
  }
}

interface IDocumentsResponse {
  documents: TDocumentDataType[],
}

export type TDocumentType = 'Depo' | 'Broker' | 'NeedSign' | 'UsersRequests' | 'Money'

interface IDocumentsState {
  documentsBroker: any[],
  documentsDepo: any[],
  documentsNeedSign: any[],
}

export const initialState: IDocumentsState = Object.freeze({
  documentsBroker: [],
  documentsDepo: [],
  documentsNeedSign: [],
})

export const documentsApi = queryApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocument: builder.query({
      query: (id) => ({
        url: `documents/${id}`,
        method: 'GET',
      }),
    }),
    getDocumentsList: builder.query<TDocumentDataType[], TDocumentType>({
      query: (documentType) => ({
        url: 'documents/list',
        method: 'POST',
        body: JSON.stringify({
          dateFrom: null,
          dateTo: null,
          gridUid: documentType,
        }),
      }),
      transformResponse: (rawResponse: IDocumentsResponse): TDocumentDataType[] => {
        return rawResponse?.documents
      },
    }),
    putDocument: builder.mutation<
      TDocumentDataType,
      { reqObj: TDocumentDataType; docType: TDocumentType }
    >({
      query: ({ reqObj }) => ({
        url: 'documents?instruction=execute',
        method: 'PUT',
        body: reqObj,
        validateStatus: (response) => response?.status === 303 || response?.status === 200,
      }),
      onQueryStarted: async (originalArg, { dispatch, queryFulfilled }) => {
        try {
          const { data, meta } = await queryFulfilled

          // @ts-ignore
          const STATUS_OK = meta?.response?.ok

          if (STATUS_OK) {
            updateCache({
              dispatch,
              recordId: data?.id,
              recordAction: UPDATE_RECORD_ACTION,
              gridUid: originalArg?.docType,
              documentData: data
            })
          }

        } catch (e) {}
      },
    }),
    getFile: builder.query<any, any>({
      query: (arg) => {
        return {
          url: 'documents/files/download',
          method: 'POST',
          body: arg,
        }
      },
    }),
    postFile: builder.mutation<any, any>({
      query: (arg) => ({
        url: 'files',
        method: 'POST',
        body: arg,
      }),
    }),
  }),
})

export const { useLazyGetDocumentQuery, useGetDocumentsListQuery, usePutDocumentMutation,
  useLazyGetFileQuery,
  usePostFileMutation,
} = documentsApi

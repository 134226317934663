import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isConfirmModalOpen: false,
  isErrorModalOpen: false,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setConfirmStatusModal: (state, action) => {
      state.isConfirmModalOpen = action.payload
    },
    setErrorStatusModal: (state, action) => {
      state.isErrorModalOpen = action.payload
    },
  },
})

const { actions, reducer } = uiSlice

export const {
  setConfirmStatusModal,
  setErrorStatusModal,
} = actions

export default reducer

import React, { useState } from 'react'
import { Certificate } from 'crypto-pro'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import {
  fetchCertificateStartAsync, clearCertificate,
  fetchPassCertificateStartAsync,
  goToPrevStepAsync,
} from '../../redux/user/userSlice'
import {
  StyledButton, StyledLabel, StyledLoginTitle, StyledSelect, StyledLoginWrapper, StyledErrorText,
} from '../loginRedesign/styled'
import { Button, Select, Text } from '../../ui/components'

const CertificateComponent = ({
  fetching, certificates, fetchCertificateStart,
  isPasswordRecovery, fetchPassCertificateStart,
  setStepArray, clearCertificateStart, arraySteps, setArraySteps,
}) => {
  const [certificate, setCertificate] = useState(certificates?.[0]?.name)
  const [certificateName, setCertificateName] = useState('')
  const [errorCertificate, setErrorCertificate] = useState(false)
  const [selectorErrorText, setSelectorErrorText] = useState('')
  const dispatch = useDispatch()

  const checkCorrectResponse = (response) => {
    if (response?.Certificate?.length) {
      setErrorCertificate(true)
      setSelectorErrorText(response?.Certificate[0])
    } else {
      const respData = response?.status
      switch (respData?.status || response?.status) {
        case 401:
          setSelectorErrorText(respData?.title)
          break
        case 403:
          setSelectorErrorText('Вход запрещен')
          break
        default:
          setArraySteps([...arraySteps, 'sign'])
          setSelectorErrorText('')
      }
    }
  }

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    if (!certificate) {
      setErrorCertificate(true)
      setSelectorErrorText('Выберите сертификат')
      return
    }
    const currentThumbprint = certificates?.find(({ name }) => certificate === name).thumbprint
    if (isPasswordRecovery) {
      await fetchPassCertificateStart(currentThumbprint).then((response) => {
        checkCorrectResponse(response)
      })
    } else {
      await fetchCertificateStart(currentThumbprint).then((response) => {
        checkCorrectResponse(response)
      })
    }
  }

  const goToPrevStep = (action) => {
    dispatch(goToPrevStepAsync({ action }))
  }

  const handleClickPrev = () => {
    setStepArray('sign')
    goToPrevStep(clearCertificateStart)
  }

  const handleChangeCertificate = (event) => {
    setCertificate(event?.value)
    setCertificateName(event?.label)
  }

  return (
    <>
      <StyledLoginWrapper>
        <StyledLoginTitle>
          <Text
            variant="h1"
            color="primary"
          >
            {isPasswordRecovery ? 'Установка пароля' : 'Вход в личный кабинет'}
          </Text>
        </StyledLoginTitle>

        <form
          onSubmit={handleSubmitForm}
        >
          <StyledLabel>
            <Text
              variant="body2"
              color="secondary"
            >
              Выберите сертификат ЭЦП
            </Text>
          </StyledLabel>

          <StyledSelect>
            <Select
              hasError={errorCertificate}
              value={certificateName || ''}
              onChange={handleChangeCertificate}
              options={!!certificates?.length && certificates?.map((item) => {
                const dateTo = item.validTo?.split('T')[0] && format(new Date(item.validTo?.split('T')[0]), 'dd.MM.yyyy')
                return {
                  label: `${item.name}${dateTo && `, До: ${dateTo}`}`,
                  value: item.name,
                }
              })}
            />

            {errorCertificate && (
              <StyledErrorText>
                <Text
                  variant="caption1"
                  color="negative"
                >
                  {selectorErrorText}
                </Text>
              </StyledErrorText>
            )}

          </StyledSelect>
          <StyledButton>
            <Button
              type="submit"
              disabled={fetching}
            >
              {isPasswordRecovery ? 'Установить пароль' : 'Подтвердить вход'}
            </Button>
          </StyledButton>

          <StyledButton>
            <Button
              type="submit"
              variant="secondary"
              onClick={handleClickPrev}
            >
              Назад
            </Button>
          </StyledButton>

        </form>
      </StyledLoginWrapper>
    </>
  )
}

CertificateComponent.defaultProps = {
  isPasswordRecovery: false,
}

CertificateComponent.propTypes = {
  fetching: PropTypes.bool.isRequired,
  certificates: PropTypes.oneOfType([PropTypes.instanceOf(Certificate),
    PropTypes.string]).isRequired,
  fetchCertificateStart: PropTypes.func.isRequired,
  fetchPassCertificateStart: PropTypes.func.isRequired,
  isPasswordRecovery: PropTypes.bool,
  setStepArray: PropTypes.func.isRequired,
  clearCertificateStart: PropTypes.func.isRequired,
  arraySteps: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setArraySteps: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user: { isFetching } }) => ({
  fetching: isFetching,
})

const mapDispatchToProps = (dispatch) => ({
  fetchCertificateStart: (currentThumbPrint) => dispatch(fetchCertificateStartAsync({ currentThumbPrint })),
  clearCertificateStart: () => dispatch(clearCertificate()),
  fetchPassCertificateStart: (currentThumbPrint) => dispatch(fetchPassCertificateStartAsync({ currentThumbPrint })),
})

export default connect(mapStateToProps, mapDispatchToProps)(CertificateComponent)

import React from 'react'
import { PropTypes } from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Media from 'react-media'
import CustomInput from '../../CustomControls/CustomInput'
import CustomSwitch from '../../CustomControls/CustomSwitch'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

const useStyles = makeStyles((theme) => ({
  rowMb: {
    marginBottom: '2rem',
    lineHeight: '15px',
    color: theme.palette.type === 'light' ? '#292D3499' : '#ffffff99',
    '@media(max-width: 576px)': {
      marginBottom: '0.5rem',
      flexFlow: 'column',
    },
  },
  mediumLabelField: {
    '& .MuiInputBase-input[disabled]': {
      color: theme.palette.type === 'light' ? '#292D3499' : '#fff',
    },
    '& > .MuiFormLabel-root': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
  },
  Grid__firstBlock: {
    '& > .MuiGrid-item': {
      paddingRight: 20,
    },
    '@media(max-width: 576px)': {
      marginBottom: -16,
    },
  },
  Mobile: {

  },
  Mobile__block: {
    paddingBottom: 10,
  },
  Mobile__blockHeader: {
    color: '#91B1BF',
    fontSize: 12,
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
    '@media(max-width: 576px)': {
      display: 'block',
    },
    '& > span': {
      marginRight: 16,
    },
    '&:first-child': {
      marginBottom: 'auto',
    },
  },
  blockFields: {
    '&:last-child': {
      paddingBottom: 16,
    },
  },
}))

const GeneralInfo = ({ data, ...props }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={2}
      {...props}
    >
      <Grid
        item
        container
        lg={6}
        md={12}
        xs={12}
        className={classes.Grid__firstBlock}
      >
        <Grid
          item
          container
          className={classes.rowMb}
          justify="space-between"
        >
          <Grid
            item
            xs={12}
            className={classes.switchContainer}
          >
            <span className={classes.switchLegend}>
              Является ли юридическое лицо согласно Указанию Банка России от 25.07.2014 N 3349-У
              &quot;О единых требованиях к правилам осуществления брокерской деятельности
              при совершении операций с имуществом клиента брокера&quot; клиентским брокером?
            </span>
            <CustomSwitch
              name="first"
              isChecked={data?.clientInfo?.isBroker || false}
              disabled
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          className={classes.rowMb}
          justify="space-between"
        >
          <Grid
            item
            xs={12}
            className={classes.switchContainer}
          >
            <span className={classes.switchLegend}>
              Осуществление юридическим лицом внутреннего контроля согласно требованиям Федерального
              закона от 07.08.2001 N 115-ФЗ &quot;О противодействии легализации (отмыванию) доходов,
              полученных преступным путем, и финансированию терроризма&quot;
            </span>
            <CustomSwitch
              name="second"
              isChecked={data?.clientInfo?.hasAmlControl || false}
              disabled
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          className={classes.rowMb}
          justify="space-between"
        >
          <Grid
            item
            xs={12}
            className={classes.switchContainer}
          >
            <span className={classes.switchLegend}>
              Наличие счетов в банках, зарегистрированных в государствах (территориях), которые не
              участвуют в международном сотрудничестве в целях исполнения 115ФЗ
            </span>
            <CustomSwitch
              name="third"
              isChecked={data?.clientInfo?.hasNonFatfCountriesAccounts || false}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        xs={12}
        className={classes.Grid__secondBlock}
      >
        <Grid
          item
          container
          className={classes.rowMb}
          justify="space-between"
        >
          <Grid
            item
            xs={12}
            className={classes.switchContainer}
          >
            <span className={classes.switchLegend}>
              Общество имеет стратегическое значение для оборонно-промышленного комплекса и
              безопасности Российской Федерации, а также обществом, находящимся под его прямым или
              косвенным контролем
            </span>
            <CustomSwitch
              name="fourth"
              isChecked={data?.clientInfo?.isStrategicallyImportant || false}
              disabled
            />
          </Grid>
        </Grid>
        <Media
          query="(min-width: 577px)"
          render={() => (
            <div className={classes.blockFields}>
              <Grid
                item
                xs={12}
                className={classes.rowMb}
              >
                <CustomInput
                  className={classes.mediumLabelField}
                  size="small"
                  name="swift"
                  variant="outlined"
                  label="SWIFT кредит. орг.-нерезидента/ рег. номер"
                  fullWidth
                  value={data?.clientInfo?.swift || ''}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.rowMb}
              >
                <CustomInput
                  className={classes.mediumLabelField}
                  size="small"
                  name="opg"
                  variant="outlined"
                  label="Регистрационный № кредит. орг.-резидента"
                  fullWidth
                  value={data?.clientInfo?.org || ''}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.rowMb}
              >
                <CustomInput
                  className={classes.mediumLabelField}
                  size="small"
                  name="bik"
                  variant="outlined"
                  label="БИК (при наличии)"
                  fullWidth
                  value={data?.clientInfo?.bic || ''}
                  disabled
                  unique
                />
              </Grid>
            </div>
          )}
        />
        <Media
          query={`(max-width: ${breakpoints.phone_large}px)`}
          render={() => (
            <>
              <div className={classes.Mobile}>
                {data?.clientInfo?.swift && (
                  <div className={classes.Mobile__block}>
                    <div className={classes.Mobile__blockHeader}>
                      SWIFT кредит. орг.-нерезидента/ рег. номер
                    </div>
                    <div className={classes.Mobile__blockText}>
                      {data?.clientInfo?.swift}
                    </div>
                  </div>
                )}
                {data?.clientInfo?.org && (
                <div className={classes.Mobile__block}>
                  <div className={classes.Mobile__blockHeader}>
                    Регистрационный № кредит. орг.-резидента
                  </div>
                  <div className={classes.Mobile__blockText}>
                    {data?.clientInfo?.org}
                  </div>
                </div>
                )}
                {data?.clientInfo?.bic && (
                <div className={classes.Mobile__block}>
                  <div className={classes.Mobile__blockHeader}>
                    БИК (при наличии)
                  </div>
                  <div className={classes.Mobile__blockText}>
                    {data?.clientInfo?.bic}
                  </div>
                </div>
                )}
              </div>
            </>
          )}
        />
      </Grid>
    </Grid>
  )
}

GeneralInfo.defaultProps = {
  data: PropTypes.object,
}

GeneralInfo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
}

export default GeneralInfo

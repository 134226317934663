import { styled } from 'ui/theme'
import { TextAreaProps } from './types'

export const StyledTextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  height: 44px;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.color.border.default};
  background-color: ${({ theme }) => theme.color.bg.secondary};
  color: ${({ theme }) => theme.color.text.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  &:focus {
    border: 1px solid ${({ theme }) => theme.color.button.primary};
    outline: none;
  }
  &:disabled {
    border-color: transparent;
    color: ${({ theme }) => theme.color.text.secondary};
  }
  ${({ hasError, theme }) =>
    hasError
      ? `
    border-color: ${theme.color.text.negative};
  `
      : ''};
  ${({ theme }) => theme.font.body2}
  ${({ autoresize }) => autoresize
    ? `
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `
    : ''};
`

export const StyledTextAreaWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  overflow: hidden;
`

import React from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

export const FloatingTip: React.FC<TooltipProps> = (props) => {
  return <ReactTooltip
    effect="float"
    place="top"
    className={'floating-tip'}
    id="floating"
    {...props}
  />
}
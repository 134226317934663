import { styled } from 'ui/theme'
import { ICollapsibleTableBodyCell, RowProps } from './types'

export const StyledCollapsibleTable = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const StyledCollapsibleTableHeader = styled.div<RowProps>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ width }) => width.map((c) => c || '1fr').join(' ')} 60px;
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.md}`};
`

export const StyledCollapsibleTableHeaderCell = styled.div`
  color: ${({ theme }) => theme.color.text.secondary};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs};
  ${({ theme }) => theme.font.caption1}

  justify-content: flex-end;

  &:first-child {
    justify-content: flex-start;
  }
`

export const StyledCollapsibleTableBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxs};
`

export const StyledCollapsibleTableBodyRowWrapper = styled.div`
  //padding: ${({ theme }) => `0 ${theme.spacing.md}`};
  background-color: ${({ theme }) => theme.color.bg.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  cursor: pointer;
`

export const StyledCollapsibleTableBodyRow = styled.div<RowProps>`
  display: grid;
  grid-template-columns: ${({ width }) => width.map((c) => c || '1fr').join(' ')} 60px;
  //height: 60px;
  padding: 20px 18px;
`

export const StyledCollapsibleTableBodyContent = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing.smd} ${theme.spacing.smd} ${theme.spacing.smd}`};
`

export const StyledCollapsibleTableBodyCell = styled.div<ICollapsibleTableBodyCell>`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  ${({ theme }) => theme.font.body2};
  justify-content: flex-end;

  ${({ font }) => font};

  ${({ overflow }) => overflow && `overflow: ${overflow};`};
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`};

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}

    &:first-child {
    justify-content: flex-start;
  }
`

export const StyleBodyCellValue = styled.div<ICollapsibleTableBodyCell>`
  ${({ eilpsable, theme, font, display }) =>
    eilpsable &&
    `
    width: 100%;
    gap: ${theme.spacing.xs};
    ${theme.font.body2};
    ${font};
    display: ${display || 'block'};
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: ${theme.color.text.primary};

    `}
`

export const StyledCollapsibleTableArrow = styled.div<{ open: boolean }>`
  transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
  transition: 0.2s transform;
  cursor: pointer;
  svg {
    display: block;
  }
`

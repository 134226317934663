export const getBadgeVariantByValues = ({
  isPositive,
  isNegative,
}: {
  isPositive: boolean
  isNegative: boolean
}) => {
  if (isPositive) return 'positive'

  if (isNegative) return 'negative'

  return
}

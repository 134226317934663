import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  getForms: null,
  error: null,
}

const FormsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    getForms(state) {
      return {
        ...state,
        error: null,
      }
    },
    getFormsSuccess(state, action) {
      return {
        ...state,
        getForms: action.payload,
      }
    },
    getFormsFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
  },
})

const { actions, reducer } = FormsSlice

export const {
  getForms,
  getFormsSuccess,
  getFormsFailed,
} = actions

export default reducer

export const fetchFormsStartAsync = () => async (dispatch) => {
  dispatch(getForms())
  try {
    const value = await Api.getForms()
    dispatch(getFormsSuccess(value))
  } catch (err) {
    dispatch(getFormsFailed())
  }
}

import { styled } from 'ui/theme'
import { Text } from 'ui/components'
import { HTMLAttributes } from 'react'

export const StyledPortfolioTables = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.smd};
`

export const TableHeadingContainer = styled.div`
  padding: 0 32px;
  margin-bottom: 12px;

  display: flex;
  justify-content: space-between;
`

export const TableHeadingTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8c93a8;
`

export const TableHeadingWrapper = styled.div`
  width: 540px;
  margin-right: 40px;

  display: grid;
  align-items: center;
  grid-template-columns: 35% 35% 30%;
`

export const TableNameCell = styled.span<{ alignSelf?: string }>`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.font.body1}

  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}

  color: ${({ theme }) => theme.color.text.primary};
`

export const StyledTableWrapper = styled.div<{ isActive: boolean }>`
  position: ${({ isActive }) => (isActive ? 'static' : 'absolute')};
  height: ${({ isActive }) => (isActive ? 'auto' : '0')};
  overflow: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
`

import { Button } from "ui/components";
import { styled } from "ui/theme";

export const StyledMainLayout = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;

  ${({ theme }) => `
    @media ${theme.breakpoint.xs} {
      display: block;
    }
  `};

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`

export const StyledMainLayoutContent = styled.div`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xxl};
  padding: ${({ theme }) => `0 ${theme.spacing.xxl} ${theme.spacing.xxl}`};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;

  @media ${({ theme }) => theme.breakpoint.xs} {
    padding: 0;
    gap: 0;
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`

export const StyledMainLayoutBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${({ theme }) => `calc(${theme.spacing.xl} - ${theme.spacing.xxs})`};
  @media ${({ theme }) => theme.breakpoint.xs} {
    gap: ${({ theme }) => `${theme.spacing.md}`};
    padding: ${({ theme }) => `0 16px ${theme.spacing.xxl}`};
    flex: 1;
  }
`

export const StyledMainLayoutHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${({ theme }) => theme.breakpoint.xs} {
    background: ${({ theme }) => theme.color.bg.primary};
    padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.smd}`};
    position: relative;
    z-index: 2;
    margin: ${({ theme }) => `${theme.spacing.lg} 0 ${theme.spacing.xl}`};
    display: grid;
    grid-template-columns: 40px 1fr 40px;
  }
`

export const StyledMainLayoutTitle = styled.h1`
  display: flex;
  justify-content: space-between;
  flex: 1;
  ${({ theme }) => theme.font.h1};
  color: ${({ theme }) => theme.color.text.primary};
  @media ${({ theme }) => theme.breakpoint.xs} {
    ${({ theme }) => theme.font.h3};
    text-align: center;
    width: 100%;
    display: block;
  }
`

export const StyledMainLayoutAction = styled(Button)`
  cursor: pointer;
  @media(${({ theme }) => theme.breakpoint.smmd}) {
    padding: 0;
    background: 0;
  }
`

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tableBody: {
    '& $tableRow': {
      '&:hover': {
        '& > .MuiTableCell-root': {
          '&:last-child': {
            '&:after': {
              borderRightColor: '#FBC15E',
            },
          },
        },
      },
    },
  },
  tableRow: {
    '&.MuiTableRow-root:not(.MuiTableRow-head)': {
      '&:hover': {
        borderRightColor: '#FBC15E',
        backgroundColor: 'rgba(145, 177, 191, 0.15)',
      },
    },
    '&.MuiTableRow-head': {
      cursor: 'initial',
    },
    '&.MuiTableRow-head > .MuiTableCell-root': {
      fontSize: 14,
    },
    '& > .MuiTableCell-root': {
      position: 'relative',
      padding: '16px 8px 15px',
      borderBottomColor: theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78',
      borderBottomStyle: 'dashed',
      fontSize: 16,
      '&:first-child': {
        width: '25%',
        paddingLeft: 0,
      },
      '&:nth-child(2)': {
        width: '59%',
      },
      '&:last-child': {
        paddingRight: 14,
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: 6,
          height: '100%',
          borderRight: '6px solid transparent',
        },
      },
      '& button': {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& svg': {
        fill: '#91B1BF',
      },
    },
  },
  tableRowActive: {
    backgroundColor: 'rgba(145, 177, 191, 0.15)',
  },
  tableHeaderCell: {
    color: '#91B1BF',
  },
  tableBtn: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 'auto',
    margin: 0,
    padding: 0,
    border: 0,
    outline: 'none',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    cursor: 'pointer',
    '@media (max-width: 576px)': {
      justifyContent: 'space-between',
      width: '100%',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover svg': {
      fill: '#363E47',
      '@media (max-width: 576px)': {
        fill: '#91B1BF',
      },
    },
    '& svg': {
      marginRight: 12,
      fill: '#91B1BF',
      '@media (max-width: 576px)': {
        position: 'relative',
        top: -4,
        maxHeight: 19,
        marginRight: 0,
      },
    },
    '& p': {
      '@media (max-width: 576px)': {
        fontSize: 14,
        lineHeight: '19px',
      },
    },
  },
  nestedRow: {
    boxSizing: 'border-box',
    borderLeft: '12px solid #92B2C0',
    backgroundColor: 'rgba(145, 177, 191, 0.15)',
  },
  nestedRowInner: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '39px 16px 39px 3px',
  },
  textWithIcon: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      alignItems: 'flex-end',
      marginBottom: 20,
    },
    '& svg': {
      marginRight: 12,
      fill: '#91B1BF',
      '@media (max-width: 576px)': {
        width: 36,
        maxHeight: 23,
        height: '100%',
      },
    },
    '& span': {
      margin: 0,
      fontSize: 16,
      lineHeight: '21px',
      color: '#91B1BF',
      '@media (max-width: 576px)': {
        position: 'relative',
        top: 3,
        fontSize: 14,
        lineHeight: '19px',
      },
    },
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '& .MuiFormControl-root': {
      maxWidth: 198,
      marginRight: 34,
      marginBottom: 0,
      '@media (max-width: 576px)': {
        minWidth: 260,
      },
    },
  },
  formGroup_unique: {
    position: 'relative',
    top: -7,
    marginTop: -5,
    marginBottom: -6,
  },
  buttonsContainer: {
    display: 'flex',
    '& button': {
      '&:only-child': {
        position: 'relative',
        top: 3,
      },
    },
  },
  downloadLink: {
    position: 'absolute',
    width: 0,
    height: 0,
    opacity: 0,
    fontSize: 0,
    color: 'transparent',
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  PhoneGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '6px 9px',
    paddingTop: 6,
    paddingBottom: 9,
    borderBottom: '0.5px dashed #91B1BF',
    '&:first-child': {
      borderTop: '0.5px dashed #91B1BF',
    },
  },
  PhoneGrid__item: {},
  PhoneGrid__item_fullColumn: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  PhoneGrid__item_unique: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  PhoneGrid__header: {
    display: 'block',
    fontSize: 12,
    lineHeight: '16px',
    color: '#91B1BF',
  },
  PhoneGrid__value: {
    margin: 0,
    fontSize: 14,
    lineHeight: '19px',
    wordBreak: 'break-word',
  },
  PhoneGrid__value_medium: {
    fontWeight: 600,
  },
  PhoneGrid__collapse: {
    position: 'relative',
    left: -20,
    boxSizing: 'border-box',
    gridColumnStart: 1,
    gridColumnEnd: 3,
    width: 'calc(100% + 40px)',
    marginTop: -1,
    marginBottom: 5,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 17,
    borderBottom: '0.5px dashed #91B1BF',
    backgroundColor: '#fff',
  },
  PhoneGrid__collapse_filled: {
    backgroundColor: '#EFF3F5',
  },
  PhoneGrid__collapseInner: {},
  PhoneGrid__collapseButton: {
    position: 'relative',
    top: 1,
    right: -7,
    padding: 0,
    '& svg': {
      fill: '#91B1BF',
    },
  },
  PhoneGrid__codeInputWrapper: {
    marginBottom: 10,
    '& .MuiFormLabel-root': {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  PhoneGrid__btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      marginTop: 3,
    },
  },
}))

export default useStyles

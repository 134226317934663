import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  partners: null,
}

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    getPartners(state) {
      state.error = null
    },
    getPartnersSuccess(state, action) {
      state.partners = action.payload
      state.error = null
    },
    getPartnersFailed(state, action) {
      state.error = action.payload
    },
  },
})

const { actions, reducer } = partnersSlice

export const {
  getPartners,
  getPartnersSuccess,
  getPartnersFailed,
} = actions

export default reducer

export const fetchPartnersStartAsync = (obj) => async (dispatch) => {
  dispatch(getPartners())
  try {
    await Api.getPartners(obj)
  } catch (error) {
    dispatch(getPartnersFailed(error))
  }
}

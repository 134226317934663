import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { rowsPerPage } from '../../../../../config/constants'
import { useSize } from '../../../../../hooks/useSize'
import { useTheme } from '../../../../theme'
import { Button, CustomModal, DateRangeField, Icon, Pagination } from 'ui/components'
import { AccordionTable } from '../components/AccordionTable'
import { baseURL } from '../../../../../config/api'
import {
  currentUserLoginAndId,
  selectCurrentThumbprint,
  selectCurrentUser,
} from '../../../../../redux/user/selector'
import JsonToSHA256 from '../../../../../utils/JsonToSHA256'
import { SMSConfirmModal } from '../../Transfers/components/modals/SMSConfim'
import formAction from '../../../../../utils/FormAction'
import b64toBlob from '../../../../../utils/ConvertToBlob'
import getCadespluginAPI from 'async-cadesplugin'
import { StyledBadge, StyledHistoryStatusCell } from '../../Transfers/styled'
import moment from "moment"
import { useGetDocumentsListQuery, usePutDocumentMutation } from "../../../../../redux/documents/documentsApi";
import { DocumentsTableSignButtonsWrapper, DocumentsTableFiltersWrapper } from "./styled";

const brokerageServiceLayout = ['10%', '5%', '42%', '5%', '10%', '28%']
const depositoryServiceLayout = ['10%', '5%', '12%', '30%', '5%', '10%', '28%']

const layout = ({ isDepositoryService }) =>
  isDepositoryService ? depositoryServiceLayout : brokerageServiceLayout

const IncomingDocumentsStatusCell = ({ row, handleSign, isDesktop }) => {

  const getVariant = (statusUid, isAccept) => {
    switch (statusUid) {
      case 'Signed': {
        if (isAccept) {
          return 'success'
        } else {
          return 'danger'
        }
      }
      case 'Executed': return 'success'
      case 'Rejected': return 'danger'
      default: return 'default'
    }
  }

  if (row?.statusUid === 'NeedSign') {
    return (
      <DocumentsTableSignButtonsWrapper>
        <Button onClick={() => handleSign(row, true)} fullWidth={!isDesktop}>
          Подписать
        </Button>
        <Button onClick={() => handleSign(row, false)} fullWidth={!isDesktop} variant="secondary">
          Отклонить
        </Button>
      </DocumentsTableSignButtonsWrapper>
    )
  }

  return (
    <StyledHistoryStatusCell>
      <StyledBadge
        variant={getVariant(row?.statusUid, row?.isAccept)}>
        {row?.statusName}
      </StyledBadge>
    </StyledHistoryStatusCell>
  )
}

const columns = ({ handleSign, handleDownload, isDepositoryService, isDesktop }) =>
  [
    {
      id: 'submitted',
      title: 'Дата',
      render: (row) => row?.date && moment(row?.date, 'YYYY.MM.DD').format('DD.MM.YYYY'),
    },
    {
      id: 'number',
      title: 'Номер',
    },
    ...(isDepositoryService
      ? [
          {
            id: 'check',
            title: 'Счёт',
            render: (row) => 'GB-91279384791',
          },
        ]
      : []),
    {
      id: 'typeName',
      title: 'Тип документа',
    },
    {
      id: 'signature',
      title: 'ЭЦП',
      render: (row) => row?.files[0]?.signUid ? (
        <Button variant="plain">
          <Icon
            name="pencil"
            size={24}
            onClick={(e) => {
              handleDownload(row, true)
            }}
          />
        </Button>
      ) : '',
    },
    {
      id: 'download',
      title: 'Формат',
      render: (row) => row?.files[0]?.uid ? (
        <Button
          variant="plain"
          onClick={(e) => {
            handleDownload(row, false)
          }}
        >
          <Icon name="download" size={24} />
          &nbsp;
          {row.files[0].fileName.split('.').pop().toUpperCase()}
        </Button>
      ) : '',
    },
    {
      id: 'statusUid',
      title: 'Статус',
      render: (row) => <IncomingDocumentsStatusCell row={row} handleSign={handleSign} isDesktop={isDesktop}/>,
    },
  ].filter(Boolean)

const IncomingDocumentsTable = () => {
  const size = useSize()
  const theme = useTheme()
  const isDesktop = theme.bp.isMD(size.width)

  const location = useLocation()
  const { pathname } = location
  const splitPathname = pathname.split('/')
  const isDepositoryService = splitPathname[2] === 'depositoryService'
  const documentType = isDepositoryService ? 'Depo' : 'Broker'

  const selectUser = useSelector(selectCurrentUser)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)

  const { data: incomingDocumentsData } = useGetDocumentsListQuery(documentType)
  const [putDocument, { data: putDocumentResponse, error: putDocumentError }] = usePutDocumentMutation()


  const [page, setPage] = useState(0)
  const [hasSha256, setHasSha256] = useState(null)
  const [code, setCode] = useState('')
  const [secretPassPhrase, setSecretPassPhrase] = useState('')
  const [submitAction, setSubmitAction] = useState('')
  const [currentRow, setCurrentRow] = useState<any>(null)
  const [msgData, setMsgData] = useState<any>(null)
  const [isAccept, setAccept] = useState(null)
  const [showTimer, setShowTimer] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [codeError, setCodeError] = useState(false)
  const [codeTextError, setCodeTextError] = useState('')

  const [showSmsModal, setShowSmsModal] = useState(false)
  const [showXmlModal, setShowXmlModal] = useState(false)

  const [dateFrom, setDateFrom] = useState(moment().subtract(3, 'months').toDate())
  const [dateTo, setDateTo] = useState(moment().toDate())

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const reset = () => {
    setShowSmsModal(false)
    setShowXmlModal(false)
    setCurrentRow(null)
    setMsgData(null)
    setSubmitAction('')

    setCodeError(false)
    setCodeTextError('')
    setCode('')
    setServerError(null)
  }

  const getDocumentsData = () => {
    // getDocumentList(documentType)
  }

  const handleDownload = async (row, isEps) => {
    try {
      const res = await fetch(`${baseURL}/api/v3/documents/files/download`, {
        method: 'POST',
        headers: new Headers({
          Authorization: 'Bearer ' + selectUser.accessToken,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          documentId: row?.id,
          uid: isEps ? row?.files[0].signUid : row?.files[0].uid,
        }),
      })

      const data = await res.json()

      if (res.status >= 400) {
        throw data
      }

      const downloadLink = document.createElement('a')
      if (data?.fileData) {
        downloadLink.href = URL.createObjectURL(b64toBlob(data?.fileData))
        downloadLink.download = isEps ? row?.files[0].signFileName : row?.files[0].fileName
        downloadLink.click()
      }
      URL.revokeObjectURL(downloadLink.href)
    } catch (error) {
      setServerError(error)
    }
  }

  useEffect(() => {
    if (putDocumentResponse) {
      if (putDocumentResponse?.requiredAction?.type === 'mac') {
        setShowSmsModal(true)
        setShowTimer(true)
      }

      if (putDocumentResponse?.requiredAction?.type === 'sign') {
        setShowXmlModal(true)
      }

      if (!putDocumentResponse?.requiredAction?.type) {
        reset()
        return
      }

      setMsgData({
        ...putDocumentResponse,
        order: {
          type: {
            uid: putDocumentResponse?.typeUid,
          },
        },
      })
    }
  }, [putDocumentResponse])

  useEffect(() => {
    setServerError(putDocumentError)
  }, [putDocumentError])

  const putIncomingDocument = async (requestObj, id) => {
    try {
      if (requestObj?.thumbPrint && requestObj?.signedXml) {
        const api = await getCadespluginAPI()
        const signature = await api.signXml(requestObj.thumbPrint, requestObj.signedXml)
        requestObj.signedXml = signature
      }

      putDocument({reqObj: requestObj, docType: documentType})
    } catch (error) {
      setServerError(error)
    }
  }

  const handlePerformIncoming = () => {
    if (!currentRow) {
      return
    }
    const { id, typeUid } = currentRow

    const requestObj = { ...currentRow, ...(typeof isAccept === 'boolean' && {isAccept}), secret: ''}

    const WordArrayToBase64 = JsonToSHA256(requestObj)

    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHasSha256(WordArrayToBase64)
    }

    formAction({
      submitAction,
      postPerformData: () => {},
      putPerformData: putIncomingDocument,
      requestObj,
      typeUID: typeUid,
      code,
      currentThumbPrint,
      secretPassPhrase,
      activeId: id,
      msgData: {
        ...msgData,
        requiredAction: {
          ...msgData?.requiredAction,
          type: msgData?.requiredAction?.type === 'error' ? 'mac' : msgData?.requiredAction?.type,
        },
      },
    })
  }

  useEffect(() => {
    if (currentRow) {
      handlePerformIncoming()
    }
  }, [currentRow])

  const handleSign = (row, acceptStatus) => {
    setAccept(acceptStatus)
    setSubmitAction('perform')
    setCurrentRow(row)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const handleResetTimer = () => {
    setShowTimer(true)

    const requestObj = { ...currentRow, isAccept, secret: '' }

    const {
      id,
      typeUid,
    } = currentRow;

    const WordArrayToBase64 = JsonToSHA256(requestObj)

    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHasSha256(`${WordArrayToBase64}`)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID: typeUid,
      putPerformData: putIncomingDocument,
      activeId: id,
      secretPassPhrase,
    })
  }

  useEffect(() => {
    if (putDocumentResponse?.requiredAction?.type === 'error') {
      if (putDocumentResponse?.requiredAction?.cause === 'Неправильный код') {
        setCodeError(true)
        setCodeTextError(putDocumentResponse?.requiredAction?.cause)
      }
    }
    if (serverError?.errors?.Secret?.length) {
      setCodeError(true)
      setCodeTextError(serverError?.errors?.Secret[0])
    }
  }, [serverError, putDocumentResponse])

  const handleCloseSmsModal = () => {
    reset()
  }

  const handleCloseXmlModal = () => {
    reset()
  }

  useEffect(() => {
    getDocumentsData()
  }, [dateFrom, dateTo])

  const handleRowClick = (row) => {
    if (row?.statusUid === 'NeedView') {
      setSubmitAction('perform')
      setCurrentRow(row)
    }
  }

  return (
    <>
      <AccordionTable
        rows={
          isDesktop
            ? (incomingDocumentsData || [])?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            : (incomingDocumentsData || [])
        }
        columns={columns({ handleSign, handleDownload, isDepositoryService, isDesktop })}
        layout={layout({ isDepositoryService })}
        onRowClick={handleRowClick}
      />
      {isDesktop && (
        <Pagination
          total={(incomingDocumentsData || [])?.length}
          current={page + 1}
          onChange={(page) => handleChangePage(page - 1)}
          pageSize={rowsPerPage}
        />
      )}
      <SMSConfirmModal
        isShowImmitPaste
        title={isAccept ? 'Подтвердите подписание документа' : 'Подтвердите отклонение документа'}
        hashSha256={hasSha256}
        code={code}
        codeError={codeError}
        setCodeError={setCodeError}
        codeTextError={codeTextError}
        setCodeTextError={setCodeTextError}
        showTimer={showTimer}
        isOpen={showSmsModal}
        handleResetTimer={handleResetTimer}
        handleStartTimer={handleStartTimer}
        handleClose={handleCloseSmsModal}
        handleChangeCodeInput={handleChangeCodeInput}
        handleSubmit={() => {
          setSubmitAction('perform')
          handlePerformIncoming()
        }}
      />
      <CustomModal
        open={showXmlModal}
        title={isAccept ? 'Подтвердите подписание документа' : 'Подтвердите отклонение документа'}
        actionText={' '}
        handleClose={handleCloseXmlModal}
        actionReject={handleCloseXmlModal}
        actionSuccess={() => {
          setSubmitAction('perform')
          handlePerformIncoming()
        }}
        textOk="Подтвердить"
        textCancel="Отменить"
        disableOverlay
      />
    </>
  )
}

export default IncomingDocumentsTable

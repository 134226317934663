import { request } from './Request'
import { syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getSecurities(typeUID) {
    return request(syncApiURL, endpoints.securities.getSecurities(typeUID))
  },
  getSecurity(id) {
    return request(syncApiURL, endpoints.securities.getSecurity(id))
  },
  postSecurity(data, needInstruction) {
    return request(syncApiURL, endpoints.securities.postSecurity(needInstruction), 'POST', data)
  },
  putSecurity(data, id, needInstruction = false) {
    return request(syncApiURL, endpoints.securities.putSecurity(data, id, needInstruction), 'PUT', data)
  },
  deleteSecurity(id) {
    return request(syncApiURL, endpoints.securities.putSecurity(null, id, false), 'DELETE')
  },
}

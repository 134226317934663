import React from 'react'
import { Accordion } from 'ui/components/molecules/Accordion'
import { DateField, Field } from 'ui/components/molecules'
import { FormCol, FormRow, TextField } from 'ui/components/atoms'

import type { FC } from 'react'
import type { StateRegistrationProps } from './types'
import useMedia from 'hooks/useMedia'
import CustomerBlocksAccordion from '../CustomerBlocksAccordion'

/**
 * Сведения о государственной регистрации.
 *
 * @param rusRegisterNumber - ОГРН.
 * @param rusRegisterDate - Дата присвоения ОГРН.
 * @param rusRegisterIssuer - Наименование органа, присвоившего ОГРН.
 * @param rusRegisterLocation - Место государственной регистрации (местонахождение) на русском языке.
 * @param isOpen - Открыт ли по умолчанию аккордеон.
 *
 * @constructor
 */
export const StateRegistration: FC<StateRegistrationProps> = ({
  rusRegisterNumber = '',
  rusRegisterDate = null,
  rusRegisterIssuer = '',
  rusRegisterLocation = '',
  isOpen = false,
}) => {
  const { isTablet, isDesktop } = useMedia()

  const registerLocation = (
    <Field
      width="100%"
      {...(isDesktop && { maxWidth: '456px' })}
      flex="unset"
      label="Место государственной регистрации (местонахождение)"
    >
      <TextField disabled value={rusRegisterLocation} />
    </Field>
  )

  const ogrnWidth = isDesktop
    ? {
        width: '100%',
        maxWidth: '304px',
      }
    : {}

  return (
    <CustomerBlocksAccordion
      bodyPaddingTop={28}
      title="Сведения о государственной регистрации"
      isOpenDefault={isOpen}
    >
      <FormRow flex={isDesktop ? 'auto' : '1'} gap={16} tabletRowDirection gapTablet={16}>
        {/*
          ОГРН
        */}
        <Field {...ogrnWidth} label="ОГРН">
          <TextField disabled value={rusRegisterNumber} />
        </Field>

        {/*
          ДАТА ПРИСВОЕНИЯ ОГРН
        */}
        <Field {...ogrnWidth} label="Дата присвоения ОГРН">
          <DateField disabled value={rusRegisterDate} />
        </Field>

        {/*
          МЕСТО ГОСУДАРСТВЕННОЙ РЕГИСТРАЦИИ (МЕСТОНАХОЖДЕНИЕ)
        */}
        {!isTablet && registerLocation}
      </FormRow>

      {isTablet && registerLocation}

      {/*
        НАИМЕНОВАНИЕ ОРГАНА, ПРИСВОИВШЕГО ОГРН
      */}
      <FormCol>
        <Field label="Наименование органа, присвоившего ОГРН">
          <TextField disabled value={rusRegisterIssuer} />
        </Field>
      </FormCol>
    </CustomerBlocksAccordion>
  )
}

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  switchButtonsWrapper: {
    display: 'flex',
    '& button': {
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 127,
      minHeight: 72,
      padding: 16,
      borderRadius: 0,
      border: 0,
      backgroundColor: 'rgb(145, 177, 191, .2)',
      fontSize: 16,
      lineHeight: '21px',
      textTransform: 'uppercase',
      color: '#91B1BF',
      cursor: 'pointer',
      '&.Mui-selected': {
        backgroundColor: '#FBC15E',
        color: '#333',
        '&:hover': {
          backgroundColor: '#FBC15E',
        },
        '& svg': {
          fill: '#000',
        },
      },
      '&:first-child': {
        '& svg': {
          transform: 'rotate(180deg)',
        },
      },
      '&:not(:last-child)': {
        marginRight: 5,
      },
      '&:hover': {
        backgroundColor: 'rgb(145, 177, 191, .2)',
      },
      '& svg': {
        marginLeft: 6,
        fill: '#91B1BF',
      },
    },
  },
  instrumentTable: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridGap: '18px 16px',
    padding: '14px 12px 20px',
    backgroundColor: 'rgba(145, 177, 191, 0.1)',
  },
  instrumentTableUnique: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  instrumentTable_unique: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: '16px 32px',
    paddingBottom: 14,
    '& $instrumentTable__item': {
      '&:nth-child(3)': {
        gridColumn: '-1 / 4',
        '& $instrumentTable__title': {
          color: '#91B1BF',
        },
        '& $instrumentTable__text': {
          fontSize: 22,
          lineHeight: '30px',
        },
      },
      '& $instrumentTable__title': {
        color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, .6)' : '#91B1BF',
      },
      '& $instrumentTable__text': {
        color: theme.palette.type === 'light' ? '#000' : '#fff',
      },
    },
  },
  instrumentTable__item: {},
  instrumentTable__item_long: {
    gridColumn: '1 / 3',
  },
  instrumentTable__title: {
    display: 'inline-block',
    marginBottom: 3,
    fontSize: 15,
    lineHeight: '20px',
    color: '#91B1BF',
  },
  instrumentTable__text: {
    margin: 0,
    fontSize: 16,
    lineHeight: '21px',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, .6)' : '#fff',
  },
  portalBtnWrapper: {
    '& + $portalBtnWrapper': {
      '@media (max-width: 320px)': {
        marginTop: 18,
      },
    },
  },
  portalBtnLabel: {
    marginRight: 13,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 7,
    borderLeft: '6px solid #FBC15E',
    fontFamily: 'Open Sans',
    fontSize: '15px',
    fontWeight: '600',
    color: theme.palette.type === 'light' ? '#000' : '#fff',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > div': {
      position: 'relative',
      top: 5,
    },
  },
  gridSecondRow: {
    marginTop: -1,
    paddingTop: '0 !important',
  },
  oneColGridContainer: {
    '& .MuiGrid-item': {
      paddingBottom: 4,
    },
    '& textarea': {
      minHeight: 96,
    },
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 32,
    '@media (max-width: 320px)': {
      position: 'fixed',
      zIndex: 1,
      bottom: 0,
      left: 46,
      boxSizing: 'border-box',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'calc(100% - 46px)',
      minHeight: 48,
      padding: '0 20px',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    },
    '& + *:not(.iconButtonUnique) > *:not(:last-child)': {
      marginRight: 60,
      '@media (max-width: 320px)': {
        marginRight: 16,
      },
    },
  },
  Transfer__codeWrapper: {
    '&.MuiGrid-root.MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 10,
    alignItems: 'flex-end',
    position: 'relative',
    '@media (max-width: 576px)': {
      '&.MuiGrid-root.MuiGrid-item': {
        paddingLeft: 16,
        position: 'initial',
      },
    },
    '& > .MuiButton-root': {
      minHeight: 'auto',
      width: 'auto',
      paddingTop: 1,
      paddingBottom: 1,
      fontSize: 14,
      '&:hover': {
        border: '1px solid #91B1BF',
        color: '#91B1BF',
        backgroundColor: '#E6EDEF',
      },
    },
  },
  Transfer__codeText: {
    fontSize: 12,
    color: '#91B1BF',
    paddingBottom: 5,
  },
  Transfer__codeInput: {
    paddingLeft: 5,
  },
}))

export default useStyles

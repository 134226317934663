import useMedia from 'hooks/useMedia'
import React, { FC, memo } from 'react'
import { Field, FormCol, TextField } from 'ui/components'
import { TextArea } from 'ui/components/atoms/TextArea'
import Accordion from 'ui/components/molecules/Accordion'
import { IContactInformation } from './types'

const ContactInformation: FC<IContactInformation> = ({
  emails,
  phones,
  faxes,
  otherContact,
  ...arrordionProps
}) => {
  const { isDesktop } = useMedia()

  return (
    <Accordion
      withShadow={false}
      padding={0}
      variant="plain"
      title="Контактная информация"
      bodyPaddingTop={isDesktop ? 24 : 28}
      {...(isDesktop && { titleType: 'bordered' })}
      {...arrordionProps}
    >
      <FormCol gap={24}>
        <Field label="Номера телефонов (через запятую, не более 5ти)">
          <TextField disabled value={phones?.join?.(' , ')} />
        </Field>

        <Field label="Эл. почта (через запятую, не более 5ти)">
          <TextField disabled value={emails?.join?.(' , ')} />
        </Field>

        <Field label="Номера факсов (через запятую, не более 5ти)">
          <TextField disabled value={faxes?.join?.(' , ')} />
        </Field>

        <Field label="Иная контактная информация">
          <TextArea autoresize value={otherContact} minAreaHeight={144} disabled />
        </Field>
      </FormCol>
    </Accordion>
  )
}

export default memo(ContactInformation)

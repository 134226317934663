import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'
import { clearAccessToken } from '../user/userSlice'

const initialState = {
  isFetching: false,
  heartbeats: null,
  error: null,
}

const heartbeatsSlice = createSlice({
  name: 'heartbeats',
  initialState,
  reducers: {
    getHeartbeats(state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    },
    getHeartbeatsSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        heartbeats: action.payload,
      }
    },
    getHeartbeatsFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    clearHeartbeats() {
      return initialState
    },
  },
})

const { actions, reducer } = heartbeatsSlice

export const {
  getHeartbeats,
  getHeartbeatsSuccess,
  getHeartbeatsFailed,
  clearHeartbeats,
} = actions

export default reducer

export const fetchHeartBeatsStartAsync = () => async (dispatch) => {
  dispatch(getHeartbeats())
  try {
    await Api.getHeartBeats()
  } catch (error) {
    if (error?.data?.status === 401 || !error) {
      dispatch(clearAccessToken())
      console.log('heartBeats die')
    }
  }
}

import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import useStyles from './actives.style'
import {
  fetchTimeStartAsync,
  fetchTotalStartAsync,
  getTimeSuccess,
  getTotalSuccess,
} from '../../redux/portfolio/portfolioSlice'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import getPrettyNumber from '../../utils/GetPrettyNumber'
import { selectGetTime, selectGetTotal } from '../../redux/portfolio/selector'
import CustomLoader from '../CustomLoader/CustomLoader'

const Actives = ({ fetchTotalStart, fetchTimeStart, connectedWS }) => {
  const [assetsSum, setAssetsSum] = useState(null)
  const [assetsDeltaSum, setAssetsDeltaSum] = useState(null)
  const [actualTime, setActualTime] = useState(null)

  const {
    getData: getTotal,
  } = useConnect({
    link: `GET /api${endpoints.portfolio.total}`,
    startFetching: fetchTotalStart,
    action: getTotalSuccess,
  })

  const {
    getData: getTime,
  } = useConnect({
    link: `GET /api${endpoints.portfolio.time}`,
    startFetching: fetchTimeStart,
    action: getTimeSuccess,
  })

  const total = useSelector(selectGetTotal)
  const time = useSelector(selectGetTime)

  const totalValuations = total?.valuations
  const valuationActualTime = time?.valuationActualTime

  useEffect(() => {
    if (connectedWS) {
      getTotal()
      getTime()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  useEffect(() => {
    if (totalValuations) {
      setAssetsSum(_.sumBy(totalValuations, 'valuationAssets'))
      setAssetsDeltaSum(_.sumBy(totalValuations, 'valuationAssetsDelta'))
    }
    if (valuationActualTime) {
      setActualTime(valuationActualTime)
    }
    // eslint-disable-next-line
  }, [total, time])

  const classes = useStyles()
  return (
    <>
      { (total && time)
        ? (
          <div className={classes.list}>
            <div className={classes.list__item}>
              <span className={classes.list__title}>
                {`Активы на ${moment().format('DD.MM.YYYY')} 00:00`}
              </span>
              <span className={classes.list__value}>
                {assetsSum ? `${getPrettyNumber(assetsSum)} RUB` : '00,00 RUB'}
              </span>
            </div>
            <div className={classes.list__item}>
              <span className={classes.list__title}>
                {`Результат за ${actualTime && moment(actualTime).format('DD.MM.YYYY')}`}
              </span>
              <span className={classes.list__value}>
                {assetsDeltaSum ? `${getPrettyNumber(assetsDeltaSum)} RUB` : '00,00 RUB'}
              </span>
            </div>
          </div>
        )
        : <CustomLoader />}
    </>

  )
}

Actives.propTypes = {
  fetchTotalStart: PropTypes.func.isRequired,
  fetchTimeStart: PropTypes.func.isRequired,
  connectedWS: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTotalStart: () => dispatch(fetchTotalStartAsync()),
  fetchTimeStart: () => dispatch(fetchTimeStartAsync()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Actives)

import React, { FC, useState } from 'react'
import { StyledRadioWrapper, StyledRadio, StyledCircle, StyledRadioTitle } from './styled'
import { RadioProps, ChangeRadioEvent } from './types'

export const Radio: FCC<RadioProps> = ({
  name,
  value,
  title,
  id = 'undefined-id',
  checked = false,
  disabled = false,
  onChange = () => {},
  children,
  alignItems,
  justifyContent,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)

  /**
   * Handle change event from wrapper to initial input checkbox.
   *
   * @param event - radio event
   * @example
   *
   * const event = {
   *   name: 'type',
   *   id: 'firm_uid',
   *   value: 'Юр лицо',
   *   checked: true,
   * }
   *
   * handleChange(event)
   */
  const handleChange = (event: ChangeRadioEvent) => {
    onChange(event)
  }

  return (
    <StyledRadioWrapper alignItems={alignItems} justifyContent={justifyContent}>
      <StyledRadio
        checked={checked}
        isFocused={isFocused}
        onClick={() => handleChange({ name, id, value, checked })}
      >
        <input
          type="radio"
          id={id}
          disabled={disabled}
          checked={checked}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          {...props}
        />

        <StyledCircle checked={checked} />
      </StyledRadio>

      <StyledRadioTitle htmlFor={id} onClick={() => handleChange({ name, id, value, checked })}>
        {children || title}
      </StyledRadioTitle>
    </StyledRadioWrapper>
  )
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errors: [],
  isOpen: false,
};

const errorsModalSlice = createSlice({
  name: 'errorsModal',
  initialState,
  reducers: {
    getErrors(state, action) {
      let err = [];
      for (let key in action.payload) {
        err.push(...action.payload[key])
      }
      return {
        ...state,
        errors: err,
        isOpen: !!Object.keys(action.payload).length,
      };
    },
    clearErrors() {
      return initialState;
    },
  },
})

const { actions, reducer } = errorsModalSlice;

export const { getErrors, clearErrors } = actions;

export default reducer;

import { createSelector } from '@reduxjs/toolkit'

const selectUser = (state) => state.user

export const selectCurrentUser = createSelector([selectUser], (user) => user.currentUser)

export const selectCurrentAgent = createSelector([selectUser], (user) => user.currentAgent)

export const selectCurrentClient = createSelector([selectUser], (user) => user.currentClient)

export const selectCurrentCertificate = createSelector([selectUser],
  (user) => user.currentCertificate)

export const selectCurrentCode = createSelector([selectUser], (user) => user.code)

export const selectCurrentResetClient = createSelector([selectUser], (user) => user.resetClient)

export const selector = createSelector([selectUser], (user) => user.selector)

export const selectNewPass = createSelector([selectUser], (user) => user.pass)

export const selectCurrentThumbprint = createSelector([selectUser], (user) => user.thumbPrint)

export const selectCurrentStatus = createSelector([selectUser], (user) => user.status?.data)

export const currentUserLoginAndId = createSelector([selectUser], (user) => {
  if (!user.currentUser) return null
  const { login, client: { id } } = user.currentUser
  return {
    userLogin: login,
    clientId: id,
  }
})

export const selectReset = createSelector([selectUser], (user) => user.reset)

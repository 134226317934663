import { createSelector } from '@reduxjs/toolkit'

const selectTransferMoney = (state) => state.transferMoney

export const selectGetTransferMoney = createSelector(
  [selectTransferMoney],
  (state) => state.getTransferMoney?.data,
)

export const selectSaveTransferMoney = createSelector(
  [selectTransferMoney],
  (state) => state.saveTransferMoney?.data,
)
export const selectSaveTransferMoneyStatus = createSelector(
  [selectTransferMoney],
  (state) => state.saveTransferMoney?.status,
)

export const selectPerformTransferMoney = createSelector(
  [selectTransferMoney],
  (state) => state.performTransferMoney?.data,
)
export const selectPerformTransferMoneyStatus = createSelector(
  [selectTransferMoney],
  (state) => state.performTransferMoney?.status,
)

export const selectCurrentDeleteMoney = createSelector(
  [selectTransferMoney],
  (state) => state.deleteMoney,
)

export const selectBalancesMoney = createSelector(
  [selectTransferMoney],
  (state) => state.balances?.data,
)

export const selectNdfl = createSelector(
  [selectTransferMoney],
  (state) => state.ndfl?.data,
)

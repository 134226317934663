import React, { useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { fetchFormsStartAsync, getFormsSuccess } from 'redux/profilePanel/profilePanelSlice'
import { selectConnectionState } from 'redux/connection/selector'
import { SizeContext } from 'context/SizeContext'
import { useSize } from 'hooks/useSize'
import { GlobalStyle, theme } from 'ui/theme'
import { MainLayout } from 'ui/components/templates/MainLayout'
import { Tab, Tabs } from 'ui/components/molecules'

import type { FC } from 'react'
import { StyledProfileContainer, StyledProfileTabs } from './styled'
import BasicInfo from './BasicInfo'
import { ROUTES } from 'app/data'
import AdditionalInfo from './AdditionalInfo'

const ProfilePage: FC = () => {
  const { pathname } = useLocation()

  const history = useHistory()

  useEffect(() => void (pathname === ROUTES.profile && history.push(ROUTES.profileBasicInfo)), [
    pathname,
  ])

  return (
    <MainLayout title="Профиль">
      <StyledProfileContainer>
        <StyledProfileTabs>
          <Tab label="Основные сведения" component={NavLink} to={ROUTES.profileBasicInfo} />
          <Tab label="Прочее" component={NavLink} to={ROUTES.profileAdditionalInfo} />
        </StyledProfileTabs>

        <Switch>
          <Route path={ROUTES.profileBasicInfo} component={BasicInfo} />

          <Route path={ROUTES.profileAdditionalInfo} component={AdditionalInfo} />
        </Switch>
      </StyledProfileContainer>
    </MainLayout>
  )
}

const Profile: FC = () => {
  const size = useSize()

  const dispatch = useDispatch()

  const fetchFormsStart = () => {
    dispatch(fetchFormsStartAsync())
  }

  useEffect(() => {
    fetchFormsStart()
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <SizeContext.Provider value={size}>
        <ProfilePage />
      </SizeContext.Provider>
    </ThemeProvider>
  )
}

export default memo(Profile)

import { ReactNode } from 'react'
import { API_USER_REQUEST_STATUS_TYPE_LIST, UserRequest } from 'types/userRequest'
import { Option } from 'ui/components/molecules/Select/types'

export interface IMessagePageFormFields {
  type: Option
  subject: string
  request: string
}

export interface IMessagePageModalFormFields {
  code: string
}

export interface IMessageHistoryCard extends UserRequest {
  onClick?(data: UserRequest): void
}

export const ETagTypeByMessageStatus = {
  [API_USER_REQUEST_STATUS_TYPE_LIST.UNKNOWN]: 'neutral',
  [API_USER_REQUEST_STATUS_TYPE_LIST.DRAFT]: 'neutral',
  [API_USER_REQUEST_STATUS_TYPE_LIST.SIGNED]: 'success',
  [API_USER_REQUEST_STATUS_TYPE_LIST.EXECUTED]: 'neutral',
  [API_USER_REQUEST_STATUS_TYPE_LIST.NEED_EXECUTION]: 'error',
  [API_USER_REQUEST_STATUS_TYPE_LIST.REJECTED]: 'error',
} as const

export interface IMessageHistoryParams {
  id?: string
}

export interface IMessageFile {
  fileData: string
  fileLength: number
  fileName: string
  id: number
  inserted: Date
  sha2_256: string
  status: number
  userID: number
}

export interface IFileCorrectData {
  name: string
  path: string
  size: number
}

export interface IMessageFileCorrect {
  data: string
  file: IFileCorrectData
}

export interface IMessageForm {
  title?: ReactNode
  disabledForm?: boolean
  btns?: ReactNode
  id?: number
  status?: string
  updateFeedbackById?(): void
  defaultData?: IMessagePageFormFields
  defaultFiles?: IMessageFileCorrect[]
  filesIdDefault?: number[]
}

export const EMessageModalTypes = {
  FORM: 'FORM',
  FINAL: 'FINAL',
} as const

import React, {createContext, useCallback, useContext} from 'react'

export const ScrollContext = createContext<React.RefObject<HTMLDivElement>>(null)

export const useScrollTop = (scrollArg: ScrollToOptions = { top: 0, left: 0 }) => {
  const appContainerRef = useContext(ScrollContext)

  const executeScrollToTop = useCallback(() => {
    if (appContainerRef?.current) {
      appContainerRef?.current?.scrollTo(scrollArg)
    }
  }, [appContainerRef])
  return executeScrollToTop
}

import { request } from './Request'
import { asyncApiURL, syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getDocumentsBrokerageServicesTypes() {
    return request(asyncApiURL, endpoints.documents.getExchangeRequestsTypelist)
  },
  getExchangeRequests() {
    return request(syncApiURL, endpoints.documents.getExchangeRequests)
  },
  getDocumentBrokerageServices(id) {
    return request(asyncApiURL, endpoints.documents.getDocument(id))
  },
  getExchangeRequestsJsonFields() {
    return request(asyncApiURL, endpoints.documents.getExchangeRequestsJsonFields)
  },
  getJsonFieldsForType(documentType) {
    return request(asyncApiURL, endpoints.documents.getExchangeRequestsJsonFieldsForType(documentType))
  },
  postDocument(data, needInstruction) {
    return request(asyncApiURL, endpoints.documents.postDocument(needInstruction), 'POST', data)
  },
  putDocument(data, id, needInstruction = false) {
    return request(asyncApiURL, endpoints.documents.putDocument(data, id, needInstruction), 'PUT', data)
  },
}

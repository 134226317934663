export const checkZero = (numb: number | string) => (+numb > 9 ? numb : `0${numb}`)

export const isNumb = (value?: string | number) => typeof value === 'number'

export const divideNumbersWithSpace = (value?: any) => {
  const dividedValue = (+value).toLocaleString('ru-RU', {
    maximumFractionDigits: 20,
    // maximumSignificantDigits: 20,
  })

  if (!value || typeof Number(dividedValue) !== 'number' || Number.isNaN(dividedValue)) return value

  return dividedValue
}

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  DesktopAppBar__actionsListItem: {
    marginLeft: 8,
    textTransform: 'initial',
    color: '#91b1bf',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      '& svg': {
        fill: theme.palette.type === 'light' ? '#313A4E' : '#fff',
      },
    },
  },
  DesktopAppBar__actionsListIcon: {
    fill: '#91B1BF',
  },
  switch: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  switchLabel: {
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: 0,
  },
}))

export default useStyles

import React, { useEffect, useState } from 'react'
import {
  Redirect, Route, Switch, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Media from 'react-media'
import Grid from '@material-ui/core/Grid'
import useStyles from '../InnerTabs/InnerTabs.style'
import useWindowSize from '../../hooks/useWindows'
import PageHeading from '../PageHeading/PageHeading'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg'
import NewDocument from './NewDocument'
import DocumentsTable from './DocumentsTable/DocumentsTable'

export default function DocumentPanel() {
  const classes = useStyles()
  const { windowWidth } = useWindowSize()
  const [secondNestingVisibility, setSecondNestingVisibility] = useState(true)
  const { url, path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const splitPathname = pathname.split('/')
  const isPathFormClassName = splitPathname[3] === 'new'
  const isPathDocumentsClassName = splitPathname[3] === 'documents'

  useEffect(() => {
    if (windowWidth <= 576) {
      if ((pathname.match(/\//g) || []).length >= 3) {
        setSecondNestingVisibility(false)
      } else {
        setSecondNestingVisibility(true)
      }
    } else {
      setSecondNestingVisibility(true)
    }
  }, [windowWidth, pathname])

  const goTo = (link) => history.push(`${url}/${link}`)
  const getDynamicClassname = (isActive) => (isActive ? `${classes.breadCrumb} ${classes.activeCrumb}` : classes.breadCrumb)

  return (
    <Box>
      { secondNestingVisibility && (
        <>
          <PageHeading text="Документы" />
          <Grid
            container
            className={classes.crumbContainer}
          >
            <button
              type="button"
              className={getDynamicClassname(isPathFormClassName)}
              onClick={() => goTo('document/new')}
            >
              <Media
                query={`(max-width: ${breakpoints.phone_large}px)`}
                render={() => (
                  <TransfersIcon />
                )}
              />
              <span>Новый документ</span>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <ArrowRightIcon />
                )}
              />
            </button>
            <button
              type="button"
              className={getDynamicClassname(isPathDocumentsClassName)}
              onClick={() => goTo('documents')}
            >
              <Media
                query={`(max-width: ${breakpoints.phone_large}px)`}
                render={() => (
                  <TransfersIcon />
                )}
              />
              <span>Исходящие документы</span>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <ArrowRightIcon />
                )}
              />
            </button>
          </Grid>
        </>
      )}

      <Switch>
        <Route
          exact
          path={path}
        >
          {windowWidth > 577 ? <Redirect to={`${path}/document/new`} /> : null}
        </Route>
        <Route
          exact
          path={`${path}/document/:id`}
          component={NewDocument}
        />
        <Route
          exact
          path={`${path}/documents`}
          component={DocumentsTable}
        />
      </Switch>
    </Box>
  )
}

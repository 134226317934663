import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html'
import { format } from 'date-fns'
import { Radio, withStyles } from '@material-ui/core'
import DropZone from 'components/DropZone'
import { selectConnectionState } from 'redux/connection/selector'
import {
  selectCurrentSecurities,
  selectCurrentGetSecurity,
  selectCurrentSaveSecurity,
  selectCurrentPerformSecurity, selectCurrentSaveSecurityStatus, selectCurrentPerformSecurityStatus,
} from 'redux/securities/selector'
import { selectCurrentMetadata } from 'redux/metadata/selector'
import { selectCurrentError } from 'redux/error/selector'
import {
  selectGetFilesData,
  selectPostFilesData,
  selectPostFilesDataError,
} from 'redux/file/selector'
import {
  clearSecurities,
  fetchGetSecurityStartAsync,
  fetchPerformSecurityStartAsync,
  fetchSaveSecurityStartAsync,
  fetchSecuritiesStartAsync,
  getSecuritiesSuccess,
  getSecuritySuccess,
  performSecuritySuccess,
  saveSecuritySuccess,
  clearPartSecurities,
} from 'redux/securities/securitiesSlice'
import {
  clearMetadata,
  fetchMetadataStartAsync,
  getMetadataSuccess,
} from 'redux/metadata/metadataSlice'
import {
  fetchGetFileStartAsync,
  fetchPostFileStartAsync,
  getFileSuccess,
  postFileSuccess,
  fileClean,
} from 'redux/file/fileSlice'
import createUri from 'utils/FilterUri'
import { encodeToUTF8 } from 'utils/EncodeToUTF8'
import useWindowSize from 'hooks/useWindows'
import { selectGetBalanceSecurity } from 'redux/transfers/security/selector'
import {
  clearTransferSecurity,
  fetchGetBalancesDepoStartAsync,
  getBalancesDepoSuccess,
} from 'redux/transfers/security/securityMoneySlice'
import formAction from 'utils/FormAction'
import { currentUserLoginAndId, selectCurrentThumbprint } from 'redux/user/selector'
import useInput from 'hooks/useInput'
import JsonToSHA256 from 'utils/JsonToSHA256'
import {
  Form,
  BlockHeading,
  CustomModal,
  Select,
  TextField,
  Field,
  FormRow,
  FormCol,
  DateField,
  CustomAutocomplete,
  FormFooter,
  FormFooterActions,
  Button,
  Switchbox,
  PriceChangeCard,
  Icon,
  Text,
} from 'ui/components'
import { SMSConfirmModal } from '../modals/SMSConfim'
import { formatDateServer } from 'utils/GetDate'
import { TransferHeading } from '../parts/TransferHeading'
import { StyledPriceChangeCardWrapper } from 'ui/components/pages/Transfers/styled'
import { TextArea } from 'ui/components/atoms/TextArea'
import { RadioLabeled } from 'ui/components/molecules/RadioLabeled'
import { RadioButton } from 'ui/components/molecules/RadioLabeled/types'
import { setConfirmStatusModal } from 'redux/ui/uiSlice'
import { onlyNumbersInputPattern } from 'utils/inputPatterns'
import { useDeleteSecurity } from './hooks/useDeleteSecurity'
import { ROUTES } from 'app/data'
import { theme } from 'ui/theme'
import { useOnClickOutside } from 'hooks/useOnclickOutside'
import { DocumentsBlock } from "ui/components/organisms/DocumentsBlock";
import { clearError } from '../../../../../../redux/error/errorSlice'
import { useScrollTop } from "../../../../../../hooks/useScrollTop";
import moment from "moment";

const initialState = {
  issuer: {
    id: null,
    name: '',
    isin: '',
    registerNumber: '',
    type: '',
  },
  searchStr: '',
  input: {
    type: '',
    id: null,
    code: '',
    name: '',
    isin: '',
    registerNumber: '',
  },
  files: [],
  idFiles: [],
  validity: new Date(),
}

const CustomRadio = withStyles({
  root: {
    color: '#91B1BF',
    '&$checked': {
      color: '#91B1BF',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const cancelType = 'Orders.Type.Modify.Cancellation'

export interface Attachment {
  id: string;
  file: {
    id: string,
    name: string,
    fileName: string;
    size: string,
    fileSize,
  }
}

export interface Document {
  id: string;
  type: string;
  number: string;
  dateTime: Date;
  attachments: Attachment[];
  required: boolean;
  otherTypeName: string;
  typeUID: string;
}

export function Assignment({ hasSecuritySwitch, securityType }) {
  const scrollToTop = useScrollTop()

  const [isDocLoading, setIsDocLoading] = useState(false)
  const [docFiles, setDocFiles] = useState([])
  const classes: any = {}
  // useStyles({
  //   needEmptySpace: !isDocLoading && !docFiles?.length,
  // })
  const dispatch = useDispatch()
  const { windowWidth } = useWindowSize()
  const urlParams = useParams<{ id?: string }>()
  const location = useLocation()
  const transferId = useMemo(() => urlParams.id, [urlParams])
  const transferUid = useMemo(() => location?.state?.orderUid, [location])
  const amount = useInput('', { maxLength: 3, isOnlyNumbers: true })
  const [metadataUid, setMetadataUid] = useState(transferUid || securityType)
  const [formDisabled, setFormDisabled] = useState(false)
  const [orderType, setOrderType] = useState('')
  const [validity, setValidity] = useState(null)
  const [portfolio, setPortfolio] = useState('')
  const [storagePlus, setStoragePlus] = useState('')
  const [searchStr, setSearchStr] = useState(initialState.searchStr)
  const [input, setInput] = useState<any>(initialState.input)
  const [counterpartyTemplate, setCounterpartyTemplate] = useState('')
  const [accountType, setAccountType] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [dealDate, setDealDate] = useState(null)
  const [reRegistrationDate, setReRegistrationDate] = useState(null)
  const [documentDate, setDocumentDate] = useState(null)
  const [depoAccount, setDepoAccount] = useState('')
  const [agentName, setAgentName] = useState('')
  const [depoAccountDivision, setDepoAccountDivision] = useState('')
  const [depoCode, setDepoCode] = useState('')
  const [interDepoAgreementNumber, setInterDepoAgreementNumber] = useState('')
  const [docNumber, setDocNumber] = useState('')
  const [expirationDate, setExpirationDate] = useState(null)
  const [reference, setReference] = useState('')
  const [arrayTypeDocument, setArrayTypeDocument] = useState(
    [
      {
        "description": "Вид на жительство",
        "name": "Вид на жительство",
        "uid": "Persons.Document.ID.Permission"
      },
      {
        "description": "Временное удостоверение личности",
        "name": "Временное удостоверение личности",
        "uid": "Persons.Document.ID.Temporary"
      },
      {
        "description": "Разрешение на временное проживание",
        "name": "Разрешение на временное проживание",
        "uid": "Persons.Document.ID.Temporary.Residence"
      },
      {
        "description": "Паспорт иностранного гражданина",
        "name": "Паспорт иностранного гражданина",
        "uid": "Persons.Document.ID.Passport.Foreign.National"
      },
      {
        "description": "Паспорт",
        "name": "Паспорт",
        "uid": "Persons.Document.ID.Passport"
      },
      {
        "description": "Удостоверение личности",
        "name": "Удостоверение личности",
        "uid": "Persons.Document.ID.ID"
      },
      {
        "description": "Военный билет",
        "name": "Военный билет",
        "uid": "Persons.Document.ID.Military"
      },
      {
        "description": "Загранпаспорт",
        "name": "Загранпаспорт",
        "uid": "Persons.Document.ID.Passport.Foreign"
      },
      {
        "description": "Удостоверение беженца",
        "name": "Удостоверение беженца",
        "uid": "Persons.Document.ID.Refugee"
      }
    ]
  )
  const [currentTypeDocument, setCurrentTypeDocument] = useState('')
  const [personSeries, setPersonSeries] = useState('')
  const [personNumber, setPersonNumber] = useState('')
  const [personCode, setPersonCode] = useState('')
  const [personIssueDate, setPersonIssueDate] = useState<any>('')
  const [personExpirationDate, setPersonExpirationDate] = useState<any>('')
  const [firmSeries, setFirmSeries] = useState('')
  const [firmNumber, setFirmNumber] = useState('')
  const [firmIssuingAuthority, setFirmIssuingAuthority] = useState('')
  const [firmValidity, setFirmValidity] = useState<any>('')
  const [personIssuingAuthority, setPersonIssuingAuthority] = useState('')

  const [arrayDocuments, setArrayDocuments] = useState<Document[]>([])
  const [submitAction, setSubmitAction] = useState<any>([])
  const [switchStates, setSwitchStates] = useState({
    first: false,
  })
  const [currency, setCurrency] = useState('')
  const [centralDepository, setCentralDepository] = useState('')
  const [groupOneVisibility, setGroupOneVisibility] = useState(false)
  const [groupTwoVisibility, setGroupTwoVisibility] = useState(false)
  const [groupThreeVisibility, setGroupThreeVisibility] = useState(false)
  const [groupFourVisibility, setGroupFourVisibility] = useState(false)
  const [groupFiveVisibility, setGroupFiveVisibility] = useState(false)
  const [issuer, setIssuer] = useState<any>(initialState.issuer)
  const [disabled, setDisabled] = useState(transferId === 'new')
  const [activeId, setActiveId] = useState('')
  const [idDocCostFiles, setIdDocCostFiles] = useState<any>('')
  const [scanFiles, setScanFiles] = useState([])
  const [temporaryScanFiles, setTemporaryScanFiles] = useState([])
  const [temporaryDocFiles, setTemporaryDocFiles] = useState([])
  const [idScanFiles, setIdScanFiles] = useState([])
  const [idFileList, setIdFileList] = useState([])
  const [open, setOpen] = useState<any>('')
  const [currentActions, setCurrentActions] = useState(null)
  const [radioValue, setRadioValue] = useState('firm')
  const [clientDepoStorage, setClientDepoStorage] = useState([])
  const [orderSubTypes, setOrderSubTypes] = useState([])
  const [currentArrayTemplates, setCurrentArrayTemplates] = useState([])
  const [centralDepositories, setCentralDepositories] = useState<any>('')
  const [depoAccountHoldingTypes, setDepoAccountHoldingTypes] = useState<any>('')
  const [securityTypes, setSecurityTypes] = useState<any>('')
  const [orderReasonTypes, setOrderReasonTypes] = useState<any>('')
  const [nameDocument, setNameDocument] = useState('')
  const [orderReasonTypeUID, setOrderReasonTypeUID] = useState('')
  const [arrayPortfolios, setArrayPortfolios] = useState([])
  const [comment, setComment] = useState('')
  const [acquisitionCost, setAcquisitionCost] = useState('')
  const [arrayCurrencies, setArrayCurrencies] = useState([])
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState<any>('')
  const [codeTextError, setCodeTextError] = useState<any>('')
  const [showTimer, setShowTimer] = useState(false)
  const [currentStatus, setCurrentStatus] = useState<any>('')
  const [fileContainer, setFileContainer] = useState([])
  const [orderSubTypeUid, setOrderSubTypeUid] = useState<any>(0)
  const [securityHeader, setSecurityHeader] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [updModalOpen, setUpdModalOpen] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [subTypeError, setSubTypeError] = useState(false)
  const [subTypeTextError, setSubTypeTextError] = useState('')
  const [expirationDateError, setExpirationDateError] = useState(false)
  const [expirationDateTextError, setExpirationDateTextError] = useState('')
  const [partnerPortfolioError, setPartnerPortfolioError] = useState(false)
  const [partnerPortfolioTextError, setPartnerPortfolioTextError] = useState('')
  const [clientDepoStorageError, setClientDepoStorageError] = useState(false)
  const [clientDepoStorageTextError, setClientDepoStorageTextError] = useState('')
  const [securityNameError, setSecurityNameError] = useState(false)
  const [securityNameTextError, setSecurityNameTextError] = useState('')
  const [volumeError, setVolumeError] = useState(false)
  const [volumeTextError, setVolumeTextError] = useState('')
  const [securityTypeError, setSecurityTypeError] = useState(false)
  const [securityTypeTextError, setSecurityTypeTextError] = useState('')
  const [securityRegisterNumberError, setSecurityRegisterNumberError] = useState(false)
  const [securityRegisterNumberTextError, setSecurityRegisterNumberTextError] = useState('')
  const [securityIsinError, setSecurityIsinError] = useState(false)
  const [securityIsinTextError, setSecurityIsinTextError] = useState('')
  const [depoClearanceTemplatesError, setDepoClearanceTemplatesError] = useState(false)
  const [depoClearanceTemplatesTextError, setDepoClearanceTemplatesTextError] = useState('')
  const [depoAccountHoldingError, setDepoAccountHoldingError] = useState(false)
  const [depoAccountHoldingTextError, setDepoAccountHoldingTextError] = useState('')
  const [depoAccountError, setDepoAccountError] = useState(false)
  const [depoAccountTextError, setDepoAccountTextError] = useState('')
  const [agentNameError, setAgentNameError] = useState(false)
  const [agentNameTextError, setAgentNameTextError] = useState('')
  const [depoAccountDivisionError, setDepoAccountDivisionError] = useState(false)
  const [depoAccountDivisionTextError, setDepoAccountDivisionTextError] = useState('')
  const [depoCodeError, setDepoCodeError] = useState(false)
  const [depoCodeTextError, setDepoCodeTextError] = useState('')
  const [referenceError, setReferenceError] = useState(false)
  const [referenceTextError, setReferenceTextError] = useState('')
  const [agentIdentityDocumentError, setAgentIdentityDocumentError] = useState(false)
  const [agentIdentityDocumentTextError, setAgentIdentityDocumentTextError] = useState('')
  const [agentIdentitySeriesError, setAgentIdentitySeriesError] = useState(false)
  const [agentIdentitySeriesTextError, setAgentIdentitySeriesTextError] = useState('')
  const [agentIdentityNumberError, setAgentIdentityNumberError] = useState(false)
  const [agentIdentityNumberTextError, setAgentIdentityNumberTextError] = useState('')
  const [agentIdentityDepartmentCodeError, setAgentIdentityDepartmentCodeError] = useState(false)
  const [agentIdentityDepartmentCodeTextError, setAgentIdentityDepartmentCodeTextError] = useState(
    ''
  )
  const [agentIdentityIssueDateError, setAgentIdentityIssueDateError] = useState(false)
  const [agentIdentityIssueDateTextError, setAgentIdentityIssueDateTextError] = useState('')
  const [agentIdentityExpirationDateError, setAgentIdentityExpirationDateError] = useState(false)
  const [agentIdentityExpirationDateTextError, setAgentIdentityExpirationDateTextError] = useState(
    ''
  )
  const [agentIdentityIssuingAuthorityError, setAgentIdentityIssuingAuthorityError] = useState(
    false
  )
  const [
    agentIdentityIssuingAuthorityTextError,
    setAgentIdentityIssuingAuthorityTextError,
  ] = useState('')
  const [agentRegistrationDateError, setAgentRegistrationDateError] = useState(false)
  const [agentRegistrationDateTextError, setAgentRegistrationDateTextError] = useState('')
  const [agentRegistrationSeriesError, setAgentRegistrationSeriesError] = useState(false)
  const [agentRegistrationSeriesTextError, setAgentRegistrationSeriesTextError] = useState('')
  const [agentRegistrationNumberError, setAgentRegistrationNumberError] = useState(false)
  const [agentRegistrationNumberTextError, setAgentRegistrationNumberTextError] = useState('')
  const [
    agentRegistrationIssuingAuthorityError,
    setAgentRegistrationIssuingAuthorityError,
  ] = useState(false)
  const [
    agentRegistrationIssuingAuthorityTextError,
    setAgentRegistrationIssuingAuthorityTextError,
  ] = useState('')
  const [centralDepositoryError, setCentralDepositoryError] = useState(false)
  const [centralDepositoryTextError, setCentralDepositoryTextError] = useState('')
  const [interDepoAgreementNumberError, setInterDepoAgreementNumberError] = useState(false)
  const [interDepoAgreementNumberTextError, setInterDepoAgreementNumberTextError] = useState('')
  const [dealDateError, setDealDateError] = useState(false)
  const [dealDateTextError, setDealDateTextError] = useState('')
  const [registerDateError, setRegisterDateError] = useState(false)
  const [registerDateTextError, setRegisterDateTextError] = useState('')
  const [orderReasonTypesError, setOrderReasonTypesError] = useState(false)
  const [orderReasonTypesTextError, setOrderReasonTypesTextError] = useState('')
  const [docNumberError, setDocNumberError] = useState(false)
  const [docNumberTextError, setDocNumberTextError] = useState('')
  const [docDateError, setDocDateError] = useState(false)
  const [docDateTextError, setDocDateTextError] = useState('')
  const [docNameError, setDocNameError] = useState(false)
  const [docNameTextError, setDocNameTextError] = useState('')
  const [documentedCostValueError, setDocumentedCostValueError] = useState(false)
  const [documentedCostValueTextError, setDocumentedCostValueTextError] = useState('')
  const [documentedCostCurrencyError, setDocumentedCostCurrencyError] = useState(false)
  const [documentedCostCurrencyTextError, setDocumentedCostCurrencyTextError] = useState('')
  const [isCleared, setIsCleared] = useState(false)
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [balances, setBalances] = useState(null)
  const [canModifyDate, setCanModifyDate] = useState(true)
  const [failedLoadModalOpen, setFailedLoadModalOpen] = useState(false)
  const [failedEmptyFile, setFailedEmptyFile] = useState(false)
  const [secretPassPhrase, setSecretPassPhrase] = useState('')
  const [hashSha256, setHashSha256] = useState(null)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [radioButtonsList, setRadioButtonsList] = useState<RadioButton[]>([
    {
      checked: false,
      id: 'person',
      value: 'person',
      name: 'type',
      title: 'Физическое лицо',
      disabled: false,
    },
    {
      checked: true,
      id: 'firm',
      value: 'firm',
      name: 'type',
      title: 'Юридическое лицо',
      disabled: false,
    },
  ])
  const [disableValidation, setDisableValidation] = useState(false)
  const [copyStatus, setCopyStatus] = useState(false)
  const [copyData, setCopyData] = useState<{
    order: {},
    portfolios: {},
    validity: {}
  } | null>(null)

  const history = useHistory()

  useEffect(() => {
    if (windowWidth > 576) {
      setGroupFourVisibility(true)
      setGroupFiveVisibility(true)
    }
  }, [windowWidth])

  const getRespSecurities = useSelector(selectCurrentSecurities)
  const getRespMetadata = useSelector(selectCurrentMetadata)
  const getRespSecuritySelector = useSelector(selectCurrentGetSecurity)
  const getSaveSecurity = useSelector(selectCurrentSaveSecurity)
  const statusRequestSave = useSelector(selectCurrentSaveSecurityStatus)
  const getPerformSecurity = useSelector(selectCurrentPerformSecurity)
  const statusRequestPerform = useSelector(selectCurrentPerformSecurityStatus)
  const respGetFileData = useSelector(selectGetFilesData)
  const respPostFileData = useSelector(selectPostFilesData)
  const getBalances = useSelector(selectGetBalanceSecurity)
  const serverError = useSelector(selectCurrentError)
  const respPostFileDataError = useSelector(selectPostFilesDataError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)

  const getRespSecurity = useMemo(() => getRespSecuritySelector, [getRespSecuritySelector])

  const isNew = transferId === 'new'

  useEffect(() => {
    getRespSecurity?.references?.identities && setArrayTypeDocument(getRespSecurity?.references?.identities)
  }, [getRespSecurity])

  const currentStatusDescription =
    getRespSecurity?.order?.statusDescription &&
    sanitizeHtml(getRespSecurity?.order?.statusDescription)

  const clearTemplateFields = () => {
    setAccountType('')
    setDepoAccount('')
    setAgentName('')
    setDepoAccountDivision('')
    setDepoCode('')
    setReference('')
    setFirmNumber('')
    setFirmSeries('')
    setFirmIssuingAuthority('')
    setFirmValidity('')
    setCurrentTypeDocument('')
    setPersonSeries('')
    setPersonNumber('')
    setPersonCode('')
    setPersonIssueDate('')
    setPersonExpirationDate('')
    setPersonIssuingAuthority('')
  }

  const handleChangeOrderType = (option) => {
    setOrderType(option.value)
    setOrderSubTypeUid(option.uid)
    setCounterpartyTemplate('')
    const clearanceTemplates = getRespMetadata?.references?.depoClearanceTemplates
    const currentTemplates = clearanceTemplates?.filter(
      (item) => item.orderSubTypeUid === option.uid
    )
    setCurrentArrayTemplates(currentTemplates)
    clearTemplateFields()
    setSubTypeError(false)
    setSubTypeTextError('')
  }

  const handleChangeValidity = (date) => {
    setValidity(date)
    setExpirationDateError(false)
  }

  const handleBackToHistory = () => {
    history.push('/transfers/securities/log')
  }

  const handleChangePersonIssueDate = (date) => {
    setPersonIssueDate(date)
    setAgentIdentityIssueDateError(false)
  }

  const handleChangePersonExpirationDate = (date) => {
    setPersonExpirationDate(date)
    setAgentIdentityExpirationDateError(false)
  }

  const onDeleteSuccessHandler = () => {
    history.push(ROUTES.transfersSecuritiesLog)
  }

  const { DeleteModals, handleRowRemove } = useDeleteSecurity({
    onSuccess: onDeleteSuccessHandler,
  })

  const onDeleteHandler = () => {
    transferId && handleRowRemove(transferId)
  }

  const todayDate = moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD')

  useEffect(() => {
    const locationPath = location.pathname

    if (currentStatus?.uid && currentStatus?.uid !== 'Draft' && locationPath.indexOf('/new') > -1) {
      history.push(locationPath.replace(/\/new/, `/${currentStatus?.uid}`))
    }
  }, [currentStatus?.uid, location])

  useEffect(() => {
    const typeDepository = getRespMetadata?.references?.orderReasonTypes?.find(
      (type) => type.uid === 'Orders.Reason.Type.Depository'
    )?.name
    const linkedAccount = getRespMetadata?.references?.portfolios
      ?.find((p) => p.name === portfolio)
      ?.depoStorages?.find((ds) => ds.name === storagePlus)?.linkedAccount

    if (linkedAccount && typeDepository) {
      const documentTemplate = {
        type: typeDepository,
        number: linkedAccount?.agreement?.number,
        dateTime: new Date(linkedAccount?.agreement?.date),
        attachments: [],
        required: true,
      }

      // @ts-ignore
      setArrayDocuments((prevState) =>
        documentTemplate && prevState.every((e) => e.number !== documentTemplate?.number)
          ? [documentTemplate]
          : [...prevState]
      )
    }
  }, [portfolio, storagePlus])

  const handleChangePortfolio = (option) => {
    setPortfolio(option?.value)
    setStoragePlus('')
    const arrayCurrentPortfolios = getRespMetadata?.references?.portfolios?.find(
      (item) => item.name === option?.value
    )
    if (arrayCurrentPortfolios?.depoStorages?.length) {
      setClientDepoStorage(arrayCurrentPortfolios?.depoStorages)
    } else {
      setClientDepoStorage([])
    }
    setPartnerPortfolioError(false)
    setPartnerPortfolioTextError('')
    setClientDepoStorageError(false)
    setClientDepoStorageTextError('')
    const hasLinkedAccount = !!getRespMetadata?.references?.portfolios
      ?.find((p) => p.name === option?.value)
      ?.depoStorages?.find((ds) => ds.name === storagePlus)?.linkedAccount
    if (arrayDocuments.find((item) => item.required) && !hasLinkedAccount) arrayDocuments.shift()
  }

  const handleChangeStoragePlus = (option) => {
    setStoragePlus(option?.value)
    setClientDepoStorageError(false)
    const hasLinkedAccount = !!getRespMetadata?.references?.portfolios
      ?.find((p) => p.name === portfolio)
      ?.depoStorages?.find((ds) => ds.name === storagePlus)?.linkedAccount
    if (arrayDocuments.find((item) => item.required) && hasLinkedAccount) arrayDocuments.shift()
  }

  const handleChangeInput = (event) => {
    const { name, value } = event.target
    setInput((state) => ({ ...state, [name]: value }))
    setSecurityRegisterNumberError(false)
    setSecurityIsinError(false)
  }

  const handleChangeView = (event, object) => {
    const { name } = event.target
    const { item } = object.props
    setInput((state) => ({ ...state, [name]: item }))
    setSecurityTypeError(false)
  }

  const handleChangeCounterpartyTemplate = (option) => {
    const { value, currenttemplate } = option ?? {}
    clearTemplateFields()
    setDepoClearanceTemplatesError(false)
    if (!value) {
      setCounterpartyTemplate('')
      return
    }
    setCounterpartyTemplate(value)
    if (currenttemplate) {
      const template = currenttemplate
      setAccountType(template?.depoAccountHolding?.description)
      setDepoAccount(template?.depoAccount)
      setAgentName(template?.agentName)
      setDepoAccountDivision(template?.depoAccountDivision)
      setDepoCode(template?.depoCode)
      setReference(template?.reference)
      setDepoAccountHoldingError(false)
      setDepoAccountError(false)
      setAgentNameError(false)
      setDepoAccountDivisionError(false)
      setDepoCodeError(false)
      setReferenceError(false)
      const agentLegalEntity = template?.agentLegalEntity
      setRadioValue(agentLegalEntity)
      if (agentLegalEntity === 'firm') {
        const registration = template?.agentRegistration
        setFirmNumber(registration?.number)
        setFirmSeries(registration?.series)
        setFirmIssuingAuthority(registration?.issuingAuthority)
        setFirmValidity(registration?.date)
        setAgentRegistrationDateError(false)
        setAgentRegistrationSeriesError(false)
        setAgentRegistrationNumberError(false)
        setAgentRegistrationIssuingAuthorityError(false)
      } else if (agentLegalEntity === 'person') {
        const identity = template?.agentIdentity
        setCurrentTypeDocument(identity?.document)
        setPersonSeries(identity?.series)
        setPersonNumber(identity?.number)
        setPersonCode(identity?.departmentCode)
        setPersonIssueDate((identity?.issueDate && new Date(identity.issueDate)) || null)
        setPersonExpirationDate(
          (identity?.expirationDate && new Date(identity.expirationDate)) || null
        )
        setPersonIssuingAuthority(identity?.issuingAuthority)
        setAgentIdentityDocumentError(false)
        setAgentIdentitySeriesError(false)
        setAgentIdentityNumberError(false)
        setAgentIdentityDepartmentCodeError(false)
        setAgentIdentityIssueDateError(false)
        setAgentIdentityExpirationDateError(false)
        setAgentIdentityIssuingAuthorityError(false)
      }
    }
  }

  const handleChangeCounterpartyTemplateInput = (value) => {
    setCounterpartyTemplate(value)
    setDepoClearanceTemplatesError(false)
  }

  const handleInputChangeCounterpartyTemplate = (value, { action }) => {
    if (action !== 'input-change') return

    setCounterpartyTemplate(value)
    setDepoClearanceTemplatesError(false)
  }

  const handleChangeAccountType = (option) => {
    setAccountType(option?.value)
    setDepoAccountHoldingError(false)
    setDepoAccountHoldingTextError('')
  }

  const handleChangeDocumentType = (event) => {
    setDocumentType(event.value)
    const typeUID = getRespMetadata?.references?.orderReasonTypes?.find(
      (item) => item.description === event.value
    )?.uid
    setOrderReasonTypeUID(typeUID)
    setNameDocument('')
    setOrderReasonTypesError(false)
  }

  const handletest = (e) => {
    console.log(e)
  }

  const handleChangeDealDate = (date) => {
    setDealDate(date)
    setDealDateError(false)
    setDealDateTextError('')
  }

  const handleChangeComment = (event) => {
    setComment(event.target.value)
  }

  const handleChangeAcquisitionCost = (event) => {
    setAcquisitionCost(event.target.value)
    setDocumentedCostValueError(false)
    setDocumentedCostValueTextError('')
  }

  const handleChangeReRegistrationDate = (date) => {
    setReRegistrationDate(date)
    setRegisterDateError(false)
    setRegisterDateTextError('')
  }

  const handleChangeDocumentDate = (date) => {
    setDocumentDate(date)
    setDocDateError(false)
    setDocDateTextError('')
  }

  const handleSwitchChanges = (event) => {
    setSwitchStates({ ...switchStates, [event.target.name]: event.target.checked })
  }

  const handleChangeCurrency = (option) => {
    setCurrency(option?.value)
    setDocumentedCostCurrencyError(false)
    setDocumentedCostValueTextError('')
  }

  const toggleGroupOneVisibility = () => {
    setGroupOneVisibility(true)
    setGroupTwoVisibility(false)
    setInput(issuer)
  }

  const toggleGroupTwoVisibility = () => {
    if (open || groupThreeVisibility) return
    setGroupThreeVisibility(true)
    setOrderReasonTypesError(false)
    setDocNumberError(false)
    setDocDateError(false)

    const idArray = [...idScanFiles]
    idArray.push([])
    setIdScanFiles(idArray)
  }

  const toggleGroupFourVisibility = () => {
    if (windowWidth <= 576) {
      setGroupFourVisibility(true)
    }
  }

  const toggleGroupFiveVisibility = () => {
    if (windowWidth <= 576) {
      setGroupFiveVisibility(true)
    }
  }

  const setConfirmModalStatus = (status) => {
    dispatch(setConfirmStatusModal(status))
  }

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchSecuritiesStart = (obj) => {
    dispatch(fetchSecuritiesStartAsync(obj))
  }

  const fetchGetSecurityStart = (id) => {
    dispatch(fetchGetSecurityStartAsync({ id }))
  }

  const fetchSaveSecurityStart = (data, id) => {
    dispatch(fetchSaveSecurityStartAsync({ data, id }))
  }

  const fetchPerformSecurityStart = (data, id) => {
    dispatch(fetchPerformSecurityStartAsync({ data, id }))
  }

  const fetchPostFileStart = (obj) => {
    dispatch(fetchPostFileStartAsync({ obj }))
  }

  const fetchGetFileStart = (id) => {
    dispatch(fetchGetFileStartAsync({ id }))
  }

  const fetchGetBalancesDepoStart = (filterBody) => {
    dispatch(fetchGetBalancesDepoStartAsync({ filterBody }))
  }

  const getHandler = (handler) => (!disabled ? handler : null)

  const getDisabledState = () =>
    // eslint-disable-next-line no-underscore-dangle
    (!!getRespSecurity &&
      !getRespSecurity?.order?._links[0]?.canModify &&
      !getSaveSecurity &&
      !getPerformSecurity) ||
    !!getSaveSecurity ||
    !!getPerformSecurity

  const assignIdFile = (
    file,
    setFile,
    postFile,
    action,
    arrayPostFile = null,
    setUniqFileContainer = null
  ) => {
    let needUpdate = false
    // const fileItem = file
    if (postFile?.id >= 0 && file?.length) {
      if (action === 'get') {
        const newArr = file.map((item) => {
          if (item?.id === postFile?.id) {
            const fileObj = { ...item }
            fileObj.data = postFile?.data
            fileObj.file = {
              data: postFile?.data,
              name: postFile?.file?.name,
              path: postFile?.file?.path,
              size: postFile?.file?.size,
            }
            needUpdate = true
            return fileObj
          }
          return item
        })
        setFile(newArr)
      } else if (action === 'post') {
        const newArr = file.map((item) => {
          if (
            item?.file?.name === postFile?.fileName &&
            item?.file?.size === postFile?.fileLength &&
            !item?.file?.id
          ) {
            const fileObj = { ...item }
            fileObj.file.id = postFile?.id
            const uniqueFileContainer = arrayPostFile?.filter(
              (itemId) => itemId?.id !== postFile?.id
            )
            setUniqFileContainer(uniqueFileContainer)
            needUpdate = true
            return fileObj
          }
          return item
        })
        if (needUpdate) {
          setFile(newArr)
        }
      }
    }
    return needUpdate
  }

  /**
   * Method requests files from backend by id
   * @param {Array} array - File id array
   * @param {String} form - Name of the component containing the files
   */
  const processArray = (array, form) => {
    if (!array?.length) return
    const idArray = []
    if (form === 'documentedCost') {
      array.map((file) => {
        fetchGetFileStart(file.id)
        idArray.push(file.id)
        return file
      })
      setIdDocCostFiles(idArray)
      setIdFileList(idArray)
    } else if (form === 'scanDocs') {
      const attachmentsId = array.flat()
      const currentIdFileList = [...idFileList]
      attachmentsId.map((id) => {
        if (id >= 0) {
          fetchGetFileStart(id)
          if (currentIdFileList.indexOf(id) === -1) {
            currentIdFileList.push(attachmentsId)
          }
          idArray.push(id)
        }
        return id
      })
      setIdFileList(currentIdFileList.flat())
    }
  }

  const handleRemoveFile = (files, setFiles, idFiles, setIdFiles) => (removedFile) => {
    if (getDisabledState()) return
    const newArr = files.filter((f) => f !== removedFile)
    const indexRemovedFile = files.findIndex((f) => f === removedFile)
    setFiles(newArr)
    if (indexRemovedFile !== -1) {
      const arrayIdFiles = [...idFiles]
      arrayIdFiles.splice(indexRemovedFile, 1)
      setIdFiles(arrayIdFiles)
    }
  }

  const handleAddFile = (files, setFiles, setTemporaryFiles) => (addedFiles) => {
    if (!addedFiles.length) {
      setFiles([])
      return
    }
    if (addedFiles.some((file) => !file?.file?.size)) {
      setFailedEmptyFile(true)
      setIsDocLoading(false)
      setIsLoading(false)
      return
    }
    const arrayFiles = [...files]
    addedFiles.forEach((file) => {
      const obj = {
        fileName: file.file.name,
        fileData: file.data.split('base64,')[1],
        fileSize: file.file.size,
      }
      fetchPostFileStart(obj)
      arrayFiles.push(file)
      setTemporaryFiles((prevState) => [...prevState, file])
    })
  }

  const saveIdScanFiles = () => {
    const arrayIdScanFiles = [...idScanFiles]
    const docIndex =
      (open && open?.split('-')[1]) || (groupThreeVisibility && arrayIdScanFiles?.length - 1)
    if (docIndex >= 0) {
      arrayIdScanFiles[docIndex] = []
      if (scanFiles?.length) {
        scanFiles.map((file) => {
          arrayIdScanFiles[docIndex].push(file?.file?.id || file?.id)
          return file
        })
        setIdScanFiles(arrayIdScanFiles)
      }
    }
  }

  const saveIdDocFiles = () => {
    const arrayIdDocFiles = []
    if (docFiles?.length) {
      docFiles.map((file) => {
        arrayIdDocFiles.push(file?.file?.id || file?.id)
        return file
      })
    }
    setIdDocCostFiles(arrayIdDocFiles)
  }

  const getSecurityValues = () => {
    if (groupOneVisibility) {
      return {
        id: null,
        type: input?.type,
        registerNumber: input?.registerNumber,
        isin: input?.isin,
        name: searchStr,
      }
    }
    return issuer
  }

  const enterMetadataFields = (metadata) => {
    const order =
      getRespSecurity?.order?.type?.uid === cancelType
        ? getRespSecurity?.order?.affectedOrder
        : getRespSecurity?.order
    const arrayCurrentPortfolios = metadata?.portfolios
    setArrayPortfolios(arrayCurrentPortfolios)
    setArrayCurrencies(metadata?.currencies || [order?.documentedCost?.currency])
    setOrderSubTypes(metadata?.orderSubTypes || [order?.subType])
    setCentralDepositories(metadata?.centralDepositories || [order?.centralDepository])
    setDepoAccountHoldingTypes(metadata?.depoAccountHoldingTypes || [order?.depoAccountHolding])
    setSecurityTypes(metadata?.securityTypes || [order?.security?.type])
    setOrderReasonTypes(metadata?.orderReasonTypes || order?.reasons?.map(({ type }) => type))
  }

  const fillFields = (resObj) => {
    let arrayCurrentPortfolios = []
    let currentDepoClearanceTemplates = []
    const currentPortfolioCode = resObj?.clientPortfolio?.code
    if (getRespMetadata?.references && Object.keys(getRespMetadata.references).length) {
      currentDepoClearanceTemplates = getRespMetadata?.references?.depoClearanceTemplates
      arrayCurrentPortfolios = getRespMetadata?.references?.portfolios
      const currentPortfolios = arrayCurrentPortfolios?.find(
        (item) => item.code === currentPortfolioCode
      )
      setClientDepoStorage(currentPortfolios?.depoStorages)
    } else {
      arrayCurrentPortfolios = [resObj?.clientPortfolio]
      currentDepoClearanceTemplates = [resObj?.subType]
      setClientDepoStorage([resObj?.clientDepoStorage])
    }
    const subTypeUID = resObj?.subType?.uid
    const currentTemplates = currentDepoClearanceTemplates?.filter(
      (item) => item.orderSubTypeUid === subTypeUID
    )
    setCurrentArrayTemplates(currentTemplates)
    setArrayPortfolios(arrayCurrentPortfolios)
    setPortfolio(currentPortfolioCode)
    setCounterpartyTemplate(resObj?.templateName)
    setCentralDepository(resObj?.centralDepository?.description)
    setInterDepoAgreementNumber(resObj?.interDepoAgreementNumber)
    setDepoAccountDivision(resObj?.depoAccountDivision)
    setDepoCode(resObj?.depoCode)
    amount.setValue(resObj?.volume)
    if (resObj?.agentLegalEntity === 'person') {
      setRadioValue(resObj?.agentLegalEntity)
      setRadioButtonsList([
        {
          checked: true,
          id: "person",
          value: "person",
          name: "type",
          title: "Физическое лицо",
          disabled: false
        },
        {
          checked: false,
          id: "firm",
          value: "firm",
          name: "type",
          title: "Юридическое лицо",
          disabled: false
        }
      ])
      const agentIdentity = resObj?.agentIdentity
      setCurrentTypeDocument(agentIdentity?.kind?.name)
      setPersonCode(agentIdentity?.departmentCode)
      setPersonSeries(agentIdentity?.series)
      setPersonNumber(agentIdentity?.number)
      setPersonIssueDate((agentIdentity?.issueDate && new Date(agentIdentity?.issueDate)) || null)
      setPersonExpirationDate(
        (agentIdentity?.expirationDate && new Date(agentIdentity?.expirationDate)) || null
      )
      setPersonIssuingAuthority(agentIdentity?.issuingAuthority)
    } else if (resObj?.agentLegalEntity === 'firm') {
      setRadioValue('firm')
      const agentRegistration = resObj?.agentRegistration
      setFirmValidity((agentRegistration?.date && new Date(agentRegistration.date)) || null)
      setFirmSeries(agentRegistration?.series)
      setFirmNumber(agentRegistration?.number)
      setFirmIssuingAuthority(agentRegistration?.issuingAuthority)
    }
    setDealDate((resObj?.dealDate && new Date(resObj.dealDate)) || null)
    setReRegistrationDate((resObj?.registerDate && new Date(resObj?.registerDate)) || null)
    setOrderSubTypeUid(subTypeUID)
    setOrderType(resObj?.subType?.description)
    setAccountType(resObj?.depoAccountHolding?.description)
    setDepoAccount(resObj?.depoAccount)
    setAgentName(resObj?.agentName)
    setReference(resObj?.reference)
    if (resObj?.reasons?.length) {
      const arrIdScanFiles = []
      const arr = []
      resObj.reasons.map((item) => {
        arr.push({
          type: item?.type?.description,
          number: item?.number,
          dateTime: (item?.date && new Date(item.date)) || null,
          attachments: (item?.attachments?.length && item?.attachments) || [],
          id: item.id,
          otherTypeName: item?.otherTypeName,
          typeUID: item?.type?.uid,
        })
        const attachmentsId = item?.attachments?.map((file) => file.id)
        arrIdScanFiles.push(attachmentsId)
        return item
      })
      setIdScanFiles(arrIdScanFiles)
      setArrayDocuments(arr)
      //custom getting files
      arr.forEach(({ attachments }) => {
        const attachmentsId = attachments?.map((item) => item.id)

        processArray(attachmentsId, 'scanDocs')
      })
      setOpen('')
    }
    setComment(resObj?.comment)
    setStoragePlus(resObj?.clientDepoStorage?.code || '')
    setSearchStr(resObj?.security?.name || '')
    setIssuer(resObj?.security)
    setInput((state) => ({
      ...state,
      type: resObj?.security?.type?.name,
      registerNumber: resObj?.security?.registerNumber,
      isin: resObj?.security?.isin,
      id: resObj?.security?.id,
      code: resObj?.security?.code,
      name: resObj?.security?.name,
    }))
    const resExpirationDate =
      (getRespSecurity?.order?.expirationDate && new Date(getRespSecurity.order.expirationDate)) ||
      null
    setValidity(resExpirationDate)
    setExpirationDate(resExpirationDate)

    if (resObj?.hasDocumentedCost) {
      setSwitchStates({ first: true })
      setAcquisitionCost(resObj?.documentedCost?.value)
      setCurrency(resObj?.documentedCost?.currency?.uid)
      setDocFiles(resObj?.documentedCost?.attachments)
      processArray(resObj?.documentedCost?.attachments, 'documentedCost')
    }
    setGroupOneVisibility(false)
    setGroupTwoVisibility(true)
    // eslint-disable-next-line no-underscore-dangle
    const currentAction = resObj?._links[0]
    setCurrentActions(currentAction)
    if (currentAction?.canModify) {
      setDisabled(false)
    } else {
      setDisabled(true)
      setCanModifyDate(false)
    }
  }

  const clearFields = (needClearData = true) => {
    if (needClearData) {
      dispatch(clearMetadata())
    }
    dispatch(clearTransferSecurity())
    dispatch(clearSecurities())
    dispatch(fileClean())
    setPortfolio('')
    setStoragePlus('')
    setGroupOneVisibility(false)
    setGroupTwoVisibility(false)
    setSearchStr(initialState.searchStr)
    setInput(initialState.input)
    setIssuer(initialState.issuer)
    setValidity(initialState.validity)
    setExpirationDate(null)
    setDisabled(false)
    setOrderType('')
    setCounterpartyTemplate('')
    setAccountType('')
    setDepoAccount('')
    setAgentName('')
    setDepoAccountDivision('')
    setDepoCode('')
    setReference('')
    setCurrentTypeDocument('')
    setPersonSeries('')
    setPersonNumber('')
    setPersonCode('')
    setPersonIssueDate(null)
    setPersonExpirationDate(null)
    setPersonIssuingAuthority('')
    setFirmValidity('')
    setFirmSeries('')
    setFirmNumber('')
    setFirmIssuingAuthority('')
    setCentralDepository('')
    setInterDepoAgreementNumber('')
    setDealDate(null)
    setReRegistrationDate(null)
    setDocumentType('')
    setDocNumber('')
    setDocumentDate(null)
    setNameDocument('')
    setComment('')
    setSwitchStates({ first: false })
    setAcquisitionCost('')
    setCurrency('')
    setArrayDocuments([])
    amount.setValue('')
    setShowTimer(false)
    setCode('')
    setCodeError('')
    setCurrentStatus('')
    setSecurityHeader('')
    setCurrentActions(null)
    setDocFiles([])
    setIdDocCostFiles([])
    setIdFileList([])
    setSubTypeError(false)
    setExpirationDateError(false)
    setPartnerPortfolioError(false)
    setClientDepoStorageError(false)
    setSecurityNameError(false)
    setVolumeError(false)
    setSecurityTypeError(false)
    setSecurityRegisterNumberError(false)
    setSecurityIsinError(false)
    setDepoClearanceTemplatesError(false)
    setDepoAccountHoldingError(false)
    setDepoAccountError(false)
    setAgentNameError(false)
    setDepoCodeError(false)
    setReferenceError(false)
    setAgentIdentityDocumentError(false)
    setAgentIdentitySeriesError(false)
    setAgentIdentityNumberError(false)
    setAgentIdentityDepartmentCodeError(false)
    setAgentIdentityIssueDateError(false)
    setAgentIdentityExpirationDateError(false)
    setAgentIdentityIssuingAuthorityError(false)
    setAgentRegistrationDateError(false)
    setAgentRegistrationSeriesError(false)
    setAgentRegistrationNumberError(false)
    setAgentRegistrationIssuingAuthorityError(false)
    setCentralDepositoryError(false)
    setInterDepoAgreementNumberError(false)
    setDealDateError(false)
    setRegisterDateError(false)
    setOrderReasonTypesError(false)
    setDocNumberError(false)
    setDocDateError(false)
    setDocNameError(false)
    setDocumentedCostValueError(false)
    setDocumentedCostCurrencyError(false)
    setCodeError(false)
    setIsCleared(true)
    setBalances(null)
    setSecretPassPhrase('')
    setSecurityNameError(false)
    setSecurityNameTextError('')
    setVolumeError(false)
    setVolumeTextError('')
  }


  useEffect(() => {

    clearFields()

    if (transferId === 'new') {
      setMetadataUid(securityType)
      setActiveId(null)

      return
    }

    if (!transferId) return;

    setActiveId(transferId)

    scrollToTop()
  }, [transferId])



  useEffect(() => {

    if (transferId === 'new') {
      fetchMetadataStart(securityType)
      setIsLoading(false)

      return
    }

    if (!transferId) return;

    fetchMetadataStart(metadataUid)
    fetchGetSecurityStart(transferId)

    return () => {
      dispatch(clearError())
      clearFields()
    }

  }, [transferId])



  useEffect(() => {
    if (serverError) {
      if (serverError?.SubType?.length) {
        setSubTypeError(true)
        setSubTypeTextError(serverError?.SubType[0])
      }
      if (serverError?.ExpirationDate?.length) {
        setExpirationDateError(true)
        setExpirationDateTextError(serverError?.ExpirationDate[0])
      }
      if (serverError?.ClientPortfolio?.length) {
        setPartnerPortfolioError(true)
        setPartnerPortfolioTextError(serverError?.ClientPortfolio[0])
      }
      if (serverError?.ClientDepoStorage?.length) {
        setClientDepoStorageError(true)
        setClientDepoStorageTextError(serverError?.ClientDepoStorage[0])
      }
      if (serverError['Security.Name' || '']?.length) {
        setSecurityNameError(true)
        setSecurityNameTextError(serverError['Security.Name'][0])
      }
      if (serverError?.Volume?.length) {
        setVolumeError(true)
        setVolumeTextError(serverError?.Volume[0])
      }
      if (serverError?.Security?.length) {
        setSecurityNameError(true)
        setSecurityNameTextError(serverError?.Security[0])
      }
      if (serverError['Security.Type' || '']?.length) {
        setSecurityTypeError(true)
        setSecurityTypeTextError(serverError['Security.Type'][0])
      }
      if (serverError['Security.RegisterNumber' || '']?.length) {
        setSecurityRegisterNumberError(true)
        setSecurityRegisterNumberTextError(serverError['Security.RegisterNumber'][0])
      }
      if (serverError['Security.Isin' || '']?.length) {
        setSecurityIsinError(true)
        setSecurityIsinTextError(serverError['Security.Isin'][0])
      }
      if (serverError?.DepoClearanceTemplates?.length) {
        setDepoClearanceTemplatesError(true)
        setDepoClearanceTemplatesTextError(serverError?.DepoClearanceTemplates[0])
      }
      if (serverError?.DepoAccountHolding?.length) {
        setDepoAccountHoldingError(true)
        setDepoAccountHoldingTextError(serverError?.DepoAccountHolding[0])
      }
      if (serverError?.DepoAccount?.length) {
        setDepoAccountError(true)
        setDepoAccountTextError(serverError?.DepoAccount[0])
      }
      if (serverError?.AgentName?.length) {
        setAgentNameError(true)
        setAgentNameTextError(serverError?.AgentName[0])
      }
      if (serverError?.DepoAccountDivision?.length) {
        setDepoAccountDivisionError(true)
        setDepoAccountDivisionTextError(serverError?.DepoAccountDivision[0])
      }
      if (serverError?.DepoCode?.length) {
        setDepoCodeError(true)
        setDepoCodeTextError(serverError?.DepoCode[0])
      }
      if (serverError?.Reference?.length) {
        setReferenceError(true)
        setReferenceTextError(serverError?.Reference[0])
      }
      if (serverError['AgentIdentity.Document']?.length) {
        setAgentIdentityDocumentError(true)
        setAgentIdentityDocumentTextError(serverError['AgentIdentity.Document'][0])
      }
      if (serverError['AgentIdentity.Series']?.length) {
        setAgentIdentitySeriesError(true)
        setAgentIdentitySeriesTextError(serverError['AgentIdentity.Series'][0])
      }
      if (serverError['AgentIdentity.Number']?.length) {
        setAgentIdentityNumberError(true)
        setAgentIdentityNumberTextError(serverError['AgentIdentity.Number'][0])
      }
      if (serverError['AgentIdentity.DepartmentCode']?.length) {
        setAgentIdentityDepartmentCodeError(true)
        setAgentIdentityDepartmentCodeTextError(
          serverError['AgentIdentity.DepartmentCode'][0]
        )
      }
      if (serverError['AgentIdentity.IssueDate']?.length) {
        setAgentIdentityIssueDateError(true)
        setAgentIdentityIssueDateTextError(serverError['AgentIdentity.IssueDate'][0])
      }
      if (serverError['AgentIdentity.ExpirationDate']?.length) {
        setAgentIdentityExpirationDateError(true)
        setAgentIdentityExpirationDateTextError(
          serverError['AgentIdentity.ExpirationDate'][0]
        )
      }
      if (serverError['AgentIdentity.IssuingAuthority']?.length) {
        setAgentIdentityIssuingAuthorityError(true)
        setAgentIdentityIssuingAuthorityTextError(
          serverError['AgentIdentity.IssuingAuthority'][0]
        )
      }
      if (serverError['AgentRegistration.Date']?.length) {
        setAgentRegistrationDateError(true)
        setAgentRegistrationDateTextError(serverError['AgentRegistration.Date'][0])
      }
      if (serverError['AgentRegistration.Series']?.length) {
        setAgentRegistrationSeriesError(true)
        setAgentRegistrationSeriesTextError(serverError['AgentRegistration.Series'][0])
      }
      if (serverError['AgentRegistration.Number']?.length) {
        setAgentRegistrationNumberError(true)
        setAgentRegistrationNumberTextError(serverError['AgentRegistration.Number'][0])
      }
      if (serverError['AgentRegistration.IssuingAuthority']?.length) {
        setAgentRegistrationIssuingAuthorityError(true)
        setAgentRegistrationIssuingAuthorityTextError(
          serverError['AgentRegistration.IssuingAuthority'][0]
        )
      }
      if (serverError?.CentralDepository?.length) {
        setCentralDepositoryError(true)
        setCentralDepositoryTextError(serverError?.CentralDepository[0])
      }
      if (serverError?.InterDepoAgreementNumber?.length) {
        setInterDepoAgreementNumberError(true)
        setInterDepoAgreementNumberTextError(serverError?.InterDepoAgreementNumber[0])
      }
      if (serverError?.DealDate?.length) {
        setDealDateError(true)
        setDealDateTextError(serverError?.DealDate[0])
      }
      if (serverError?.RegisterDate?.length) {
        setRegisterDateError(true)
        setRegisterDateTextError(serverError?.RegisterDate[0])
      }
      if (serverError?.orderReasonTypes?.length) {
        setOrderReasonTypesError(true)
        setOrderReasonTypesTextError(serverError?.OrderReasonTypes[0])
      }
      if (serverError?.docNumber?.length) {
        setDocNumberError(true)
        setDocNumberTextError(serverError?.DocNumber[0])
      }
      if (serverError?.docDate?.length) {
        setDocDateError(true)
        setDocDateTextError(serverError?.DocDate[0])
      }
      if (serverError?.docName?.length) {
        setDocNameError(true)
        setDocNameTextError(serverError?.DocName[0])
      }
      if (serverError['DocumentedCost.Value']?.length) {
        setDocumentedCostValueError(true)
        setDocumentedCostValueTextError(serverError['DocumentedCost.Value'][0])
      }
      if (serverError['DocumentedCost.Currency']?.length) {
        setDocumentedCostCurrencyError(true)
        setDocumentedCostCurrencyTextError(serverError['DocumentedCost.Currency'][0])
      }
      if (serverError?.Secret?.length) {
        setCodeError(true)
        setCodeTextError(serverError?.Secret[0])
      }
      if (serverError?.FileData?.length) {
        setIsDocLoading(false)
      }
      const msgData = getSaveSecurity || getPerformSecurity
      if ((!currentActions || currentActions?.canModify) && !msgData?.requiredAction?.type) {
        setDisabled(false)
      }
      setIsLoading(false)
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError])

  useEffect(() => {
    if (getRespSecurity && isCleared) {
      const references = getRespMetadata?.references
      enterMetadataFields(references)
      setCurrentStatus(getRespSecurity?.order?.status)
      const order =
        getRespSecurity?.order?.type?.uid === cancelType
          ? getRespSecurity?.order?.affectedOrder
          : getRespSecurity?.order
      fillFields(order)
      if (getRespSecurity?.order?.type?.uid === securityType) {
        if (getRespMetadata?.order?.type?.uid === cancelType) {
          const cancelDate = getRespMetadata?.order?.expirationDate
          const reqObj = {
            type: { uid: cancelType },
            expirationDate: cancelDate && formatDateServer(new Date(cancelDate)),
            affectedOrder: { id: +activeId },
            secret: '',
          }

          const date = getRespMetadata?.order?.date
          const number = getRespMetadata?.order?.number

          // @ts-ignore
          reqObj.date = date
          // @ts-ignore
          reqObj.number = number

          const WordArrayToBase64 = JsonToSHA256(reqObj)
          if (WordArrayToBase64) {
            reqObj.secret = WordArrayToBase64
            setHashSha256(WordArrayToBase64)
          }

          fetchPerformSecurityStart(reqObj, null)
        }
      }
      if (getRespSecurity?.order?.type?.uid === cancelType) {
        // eslint-disable-next-line no-underscore-dangle
        if (getRespSecurity?.order?._links[0]?.canModify) {
          setCanModifyDate(true)
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(getRespSecurity?.order?._links[0])
      setSecurityHeader(`${getRespSecurity?.order?.description}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRespSecurity, getRespMetadata, isCleared])

  useEffect(() => {
    if (getRespMetadata?.references) {
      const references = getRespMetadata?.references
      enterMetadataFields(references)
    }
    // eslint-disable-next-line
  }, [getRespMetadata])

  useEffect(() => {
    if (respGetFileData.length) {
      const arrayFileObjects = []
      respGetFileData.map((file) =>
        arrayFileObjects.push({
          data: file.fileData,
          file: {
            name: file.fileName,
            path: file.fileName,
            size: file.fileLength,
          },
          id: file.id,
        })
      )

      setArrayDocuments((prevDocuments: any) => {
        const changedDocuments = prevDocuments.map((prevDocument) => {
          const attachmentsWithFile = prevDocument.attachments?.map((attachment) => {
            const suitableFile = arrayFileObjects.find(({ id: fileId }) => fileId === attachment.id)

            return suitableFile
              ? {
                  ...attachment,
                  ...suitableFile,
                }
              : attachment
          })

          return {
            ...prevDocument,
            attachments: attachmentsWithFile,
          }
        })

        return changedDocuments
      })

      arrayFileObjects.map((fileObject) => {
        if (Object.keys(fileObject)?.length > 0) {
          assignIdFile(scanFiles, setScanFiles, fileObject, 'get')
          assignIdFile(docFiles, setDocFiles, fileObject, 'get')
        }
        return null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetFileData])

  useEffect(() => {
    if (!switchStates.first) {
      setAcquisitionCost('')
      setDocumentedCostValueError(false)
      setDocumentedCostValueTextError('')

      setCurrency('')
      setDocumentedCostValueError(false)
      setDocumentedCostCurrencyError(false)

      setDocFiles([])
      setTemporaryDocFiles([])
    }
  }, [switchStates.first])

  const getFindFile = (fileData, temporaryFiles) =>
    temporaryFiles.find(
      (file) => file?.fileName === fileData?.file?.name && file?.fileSize === fileData?.file?.size
    )

  const getFilterFile = (fileData, temporaryFiles) =>
    temporaryFiles.filter(
      (file) => file?.fileName !== fileData?.file?.name && file?.fileSize !== fileData?.file?.size
    )

  const handleSplitFiles = (
    setFiles,
    temporaryFiles,
    setTemporaryFiles,
    setLoader,
    responseFile
  ) => {
    const currentTemporaryArray = getFilterFile(responseFile, temporaryFiles)
    if (temporaryFiles?.length > 0) {
      setFiles((prevState) => [...prevState, getFindFile(responseFile, temporaryFiles)])
      setTemporaryFiles(currentTemporaryArray)
    }
    if (!currentTemporaryArray?.length) {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (respPostFileData?.id) {
      if (temporaryScanFiles?.length > 0) {
        handleSplitFiles(
          setScanFiles,
          temporaryScanFiles,
          setTemporaryScanFiles,
          setIsLoading,
          respPostFileData
        )
      }
      if (temporaryDocFiles?.length > 0) {
        handleSplitFiles(
          setDocFiles,
          temporaryDocFiles,
          setTemporaryDocFiles,
          setIsDocLoading,
          respPostFileData
        )
      }
      setFileContainer((state) => [...state, respPostFileData])
      dispatch(fileClean())
    }
    if (respPostFileDataError?.type) {
      if (temporaryScanFiles?.length > 0) {
        const filterScanFiles = temporaryScanFiles.filter(
          (file) =>
            file?.name !== respPostFileData?.data?.name &&
            file?.size !== respPostFileData?.data?.size
        )
        setTemporaryScanFiles(filterScanFiles)
        if (!filterScanFiles?.length) {
          setIsLoading(false)
        }
      }
      if (temporaryDocFiles?.length > 0) {
        const filterDocFiles = temporaryDocFiles.filter(
          (file) =>
            file?.name !== respPostFileData?.data?.name &&
            file?.size !== respPostFileData?.data?.size
        )
        setTemporaryDocFiles(filterDocFiles)
        if (!filterDocFiles?.length) {
          setIsDocLoading(false)
        }
      }
      setFailedLoadModalOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respPostFileData, respPostFileDataError])

  useEffect(() => {
    if (!fileContainer?.length) return
    let needUpdateDocFiles = false
    fileContainer.map((postFile) => {
      if (scanFiles?.length) {
        assignIdFile(scanFiles, setScanFiles, postFile, 'post', fileContainer, setFileContainer)
      }
      if (docFiles?.length) {
        if (
          assignIdFile(docFiles, setDocFiles, postFile, 'post', fileContainer, setFileContainer)
        ) {
          needUpdateDocFiles = true
        }
      }
      return postFile
    })
    if (needUpdateDocFiles) {
      saveIdDocFiles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanFiles, docFiles, fileContainer])

  useEffect(() => {
    if (isCleared) {
      if (getSaveSecurity?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveSecurity?.order?.id)
        }
        if (getSaveSecurity?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
        if (getSaveSecurity?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
      }
      if (getPerformSecurity?.requiredAction) {
        if (!activeId || +activeId !== getPerformSecurity?.order?.id) {
          setActiveId(getPerformSecurity?.order?.id)
        }
        if (getPerformSecurity?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
        if (getPerformSecurity?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
        setCurrentStatus(getPerformSecurity?.order?.status)
      }

      const msgData = getSaveSecurity || getPerformSecurity
      const typeUID =
        (msgData?.requiredAction && msgData?.order?.type?.uid === cancelType) ||
        getRespSecurity?.order?.type?.uid === cancelType
          ? cancelType
          : securityType
      if (msgData?.order?.description) {
        setSecurityHeader(`${msgData?.order?.description}`)
      }
      if (typeUID) {
        setIsLoading(false)
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(msgData?.order?._links[0])
      if (
        msgData?.order?.type?.uid === cancelType &&
        getRespSecurity?.order?.type?.uid !== cancelType
      ) {
        const cancelDate = getRespMetadata?.order?.expirationDate
        setExpirationDate(cancelDate)
        setValidity(cancelDate)
      }
      if (statusRequestSave === 200 || statusRequestPerform === 200) {
        switch (getSaveSecurity?.order?.status?.uid || getPerformSecurity?.order?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (getSaveSecurity?.order?.id) {
              if (securityType === 'Orders.Type.NonTrade.Depo.In') {
                history.replace(`/transfers/securities/input/${getSaveSecurity?.order?.id}`)
              } else {
                history.replace(`/transfers/securities/output/${getSaveSecurity?.order?.id}`)
              }

              setDisabled(false)
              setFormDisabled(false)
            }
            if (activeId) {
              setUpdModalOpen(true)
            } else {
              if (submitAction !== 'save') {
                setSaveModalOpen(true)
              }
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaveSecurity, getPerformSecurity, isCleared])

  useEffect(() => {
    if (getBalances) {
      const portfolios = getRespMetadata?.references?.portfolios
      const clientPortfolio = portfolios?.find((item) => item.name === portfolio)
      if (!(getBalances?.portfolio || getBalances?.security)) return
      if ((getBalances?.portfolio?.id === clientPortfolio?.id) || !!getBalances?.security?.id) {
        setBalances(getBalances)
      }
    } else {
      setBalances(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBalances])

  const fetchBalancesDepo = (filterBody, setBalance) => {
    if (filterBody) {
      fetchGetBalancesDepoStart(filterBody.replaceAll('+', ''))
    }
    setBalance(null)
  }

  useMemo(() => {
    const portfolios = getRespMetadata?.references?.portfolios
    const clientPortfolio = portfolios?.find((item) => item.name === portfolio)
    const currentDepoStorage = clientPortfolio?.depoStorages?.filter(
      (item) => item.code === storagePlus
    )[0]
    const security = getSecurityValues()
    let filterBodyClient
    if (portfolio || storagePlus || security) {
      filterBodyClient = createUri({
        name: 'balancesDepo',
        portfolioId: clientPortfolio?.id,
        securityId: security?.id,
        storageId: currentDepoStorage?.id,
        condition: '&',
      })
      fetchBalancesDepo(filterBodyClient, setBalances)
    }
    setBalances(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio, storagePlus, issuer])

  const addNeedsProps = (typeUID, msgData) => {
    let reqObj = {}
    const portfolios = getRespMetadata?.references?.portfolios
    const clientPortfolio = portfolios?.find((item) => item.name === portfolio)
    const currentDepoStorage = clientPortfolio?.depoStorages?.filter(
      (item) => item.code === storagePlus
    )[0]
    const security = getSecurityValues()
    const agentLegalEntity: any = orderSubTypeUid === `${securityType}.Registry` ? radioValue : null
    let agentRegistration = null
    let agentIdentity = null
    if (agentLegalEntity) {
      if (agentLegalEntity === 'firm') {
        agentRegistration = {
          date: (firmValidity && formatDateServer(new Date(firmValidity))) || null,
          series: firmSeries,
          number: firmNumber,
          issuingAuthority: firmIssuingAuthority,
        }
      } else {
        /**
         * TODO: поменять захардкоженные значения из `arrayTypeDocument` на список из getRespMetadata?.references?.identities.
         */
        const activeDocument = getRespMetadata?.references?.identities?.find(
          (document) => document.name === currentTypeDocument
        )

        agentIdentity = {
          kind: activeDocument ?? null,
          series: personSeries,
          number: personNumber,
          departmentCode: personCode,
          issueDate: (personIssueDate && formatDateServer(new Date(personIssueDate))) || null,
          expirationDate:
            (personExpirationDate && formatDateServer(new Date(personExpirationDate))) || null,
          issuingAuthority: personIssuingAuthority,
        }
      }
    }
    const currentCentralDepository = getRespMetadata?.references?.centralDepositories?.find(
      (item) => item.description === centralDepository
    )
    let docCost = null
    if (switchStates.first) {
      const attachmentsArray = []
      docFiles.map((el, index) => {
        if (attachmentsArray.every((eve) => el?.file?.id !== eve?.id)) {
          attachmentsArray.push({
            id: el?.file?.id || idDocCostFiles[index] || null,
            name: el?.file?.fileName ? el?.file?.fileName : el?.file?.name,
            size: el?.file?.fileSize ? el?.file?.fileSize : el?.file?.size,
          })
        }
        return el
      })
      docCost = {
        attachments: attachmentsArray,
        currency: getRespMetadata?.references?.currencies?.find((item) => item.uid === currency),
        value: acquisitionCost,
      }
    }
    const reasons = []
    arrayDocuments.map((item, docIndex) => {
      const attachmentsArray = []
      if (item?.attachments?.length > 0) {
        item.attachments.map((el, fileIndex) =>
          attachmentsArray.push({
            id: el?.file?.id || idScanFiles[docIndex][fileIndex] || null,
            name: el?.file?.fileName ? el?.file?.fileName : el?.file?.name,
            size: el?.file?.fileSize ? el?.file?.fileSize : el?.file?.size,
          })
        )
      }
      reasons.push({
        type:
          getRespMetadata?.references?.orderReasonTypes?.find(
            (el) => el.description === item.type
          ) || null,
        number: item?.number || null,
        date: (item?.dateTime && formatDateServer(new Date(item.dateTime))) || null,
        attachments: attachmentsArray,
        otherTypeName: item?.otherTypeName,
        id: item?.id || null,
      })
      return item
    })
    const accountTypeObj = getRespMetadata?.references?.depoAccountHoldingTypes?.find(
      (item) => item.description === accountType
    )

    if (typeUID === cancelType) {
      reqObj = {
        type: { uid: typeUID },
        expirationDate: formatDateServer(new Date(validity)),
        affectedOrder: {
          id: msgData?.order?.affectedOrder?.id || getRespSecurity?.order?.affectedOrder?.id,
        },
        secret: '',
      }
      return reqObj
    }
    reqObj = {
      type: { uid: typeUID },
      dateTime: formatDateServer(new Date()),
      expirationDate: formatDateServer(new Date(validity)),
      clientPortfolio: { id: clientPortfolio?.id },
      clientDepoStorage: {
        id: currentDepoStorage?.id,
        account: currentDepoStorage?.account,
        name: currentDepoStorage?.name,
        isClosed: currentDepoStorage?.isClosed,
      },
      security,
      volume: +String(amount.value).replaceAll(' ', ''),
      agentLegalEntity,
      agentRegistration,
      agentIdentity,
      agentName,
      centralDepository: currentCentralDepository,
      dealDate: dealDate ? formatDateServer(new Date(dealDate)) : null,
      depoAccount,
      depoAccountDivision,
      depoAccountHolding: accountTypeObj,
      depoCode,
      hasDocumentedCost: switchStates.first,
      documentedCost: docCost,
      interDepoAgreementNumber,
      reasons,
      comment,
      reference,
      registerDate: reRegistrationDate ? formatDateServer(new Date(reRegistrationDate)) : null,
      subType: { uid: orderSubTypeUid },
      templateName: counterpartyTemplate,
      secret: '',
    }
    return reqObj
  }
  const handlePerformSecurityInput = (event) => {
    event.preventDefault()
      setFormDisabled(true)
      setIsLoading(true)

      const msgData = getSaveSecurity || getPerformSecurity
      const typeUID =
        (msgData?.requiredAction && msgData?.order?.type?.uid === cancelType) ||
        getRespSecurity?.order?.type?.uid === cancelType
          ? cancelType
          : securityType
      const requestObj: any = addNeedsProps(typeUID, msgData)

      const date = getRespMetadata?.order?.date
      const number = getRespMetadata?.order?.number

      requestObj.date = date
      requestObj.number = number

      const WordArrayToBase64 = JsonToSHA256(requestObj)
      if (WordArrayToBase64) {
        requestObj.secret = WordArrayToBase64
        setHashSha256(WordArrayToBase64)
      }

      formAction({
        submitAction,
        requestObj,
        typeUID,
        msgData,
        putPerformData: fetchPerformSecurityStart,
        postPerformData: fetchPerformSecurityStart,
        putSaveData: fetchSaveSecurityStart,
        postSaveData: fetchSaveSecurityStart,
        activeId,
        code,
        setCodeError,
        setCodeTextError,
        setCancellationModalOpen,
        setFormDisabled,
        setIsLoading,
        currentThumbPrint,
        secretPassPhrase,
        disableValidation
      })
      setDisableValidation(false)
  }

  const handleChangeAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStr(value)
      if (value && value.trim().length >= 3) {
        fetchSecuritiesStart(`Count=100&Search=${value}`)
      }
    }
    setSecurityNameError(false)
    setSecurityNameTextError('')
  }

  const handleChangeAutocomplete = (event, value) => {
    if (!value) {
      return
    }
    if (value?.name) {
      setSearchStr(value.name)
    }
    setIssuer(value)
    setGroupOneVisibility(false)
    setGroupTwoVisibility(true)
    const uri = 'Count=100'
    fetchSecuritiesStart(uri)
  }

  const handleChangeFormatRadio = (event) => {
    const currentRadioValue = event.target.value
    setRadioValue(currentRadioValue)
    setFirmNumber('')
    setFirmSeries('')
    setFirmIssuingAuthority('')
    setFirmValidity('')
    setCurrentTypeDocument('')
    setPersonSeries('')
    setPersonNumber('')
    setPersonCode('')
    setPersonIssueDate('')
    setPersonExpirationDate('')
    setPersonIssuingAuthority('')
    setAgentIdentityDocumentError(false)
    setAgentIdentitySeriesError(false)
    setAgentIdentityNumberError(false)
    setAgentIdentityDepartmentCodeError(false)
    setAgentIdentityIssueDateError(false)
    setAgentIdentityExpirationDateError(false)
    setAgentIdentityIssuingAuthorityError(false)
    setAgentRegistrationDateError(false)
    setAgentRegistrationSeriesError(false)
    setAgentRegistrationNumberError(false)
    setAgentRegistrationIssuingAuthorityError(false)
  }

  const handleChangeCentralDepository = (option) => {
    setCentralDepository(option?.value)
    setCentralDepositoryError(false)
    setCentralDepositoryTextError('')
  }

  const handleChangeDepoAccount = (event) => {
    setDepoAccount(event.target.value)
    setDepoAccountError(false)
    setDepoAccountTextError('')
  }

  const handleChangeAgentName = (event) => {
    setAgentName(event.target.value)
    setAgentNameError(false)
    setAgentNameTextError('')
  }

  const handleChangeDepoAccountDivision = (event) => {
    setDepoAccountDivision(event.target.value)
    setDepoAccountDivisionError(false)
    setDepoAccountDivisionTextError('')
  }

  const handleChangeDepoCode = (event) => {
    setDepoCode(event.target.value)
    setDepoCodeError(false)
    setDepoCodeTextError('')
  }

  const handleChangeInterDepoAgreementNumber = (event) => {
    setInterDepoAgreementNumber(event.target.value)
    setInterDepoAgreementNumberError(false)
    setInterDepoAgreementNumberTextError('')
  }

  const handleChangeDocNumber = (event) => {
    setDocNumber(event.target.value)
    setDocNumberError(false)
    setDocNumberTextError('')
  }

  const handleChangeReference = (event) => {
    setReference(event.target.value)
    setReferenceError(false)
    setReferenceTextError('')
  }

  const handleChangeCurrentTypeDocument = (event) => {
    setCurrentTypeDocument(event.label)
    setAgentIdentityDocumentError(false)
    setAgentIdentityDocumentTextError('')
  }

  const handleChangePersonSeries = (event) => {
    setPersonSeries(event.target.value)
    setAgentIdentitySeriesError(false)
    setAgentIdentitySeriesTextError('')
  }

  const handleChangePersonNumber = (event) => {
    setPersonNumber(event.target.value)
    setAgentIdentityNumberError(false)
    setAgentIdentityNumberTextError('')
  }

  const handleChangePersonCode = (event) => {
    setPersonCode(event.target.value)
    setAgentIdentityDepartmentCodeError(false)
    setAgentIdentityDepartmentCodeTextError('')
  }

  const handleChangeFirmSeries = (event) => {
    setFirmSeries(event.target.value)
    setAgentRegistrationSeriesError(false)
    setAgentRegistrationSeriesTextError('')
  }

  const handleChangeFirmNumber = (event) => {
    setFirmNumber(event.target.value)
    setAgentRegistrationNumberError(false)
    setAgentRegistrationNumberTextError('')
  }

  const handleChangeFirmIssuingAuthority = (event) => {
    setFirmIssuingAuthority(event.target.value)
    setAgentRegistrationIssuingAuthorityError(false)
    setAgentRegistrationIssuingAuthorityTextError('')
  }

  const handleChangeFirmValidity = (date) => {
    setFirmValidity(date)
    setAgentRegistrationDateError(false)
    setAgentRegistrationDateTextError('')
  }

  const handleChangePersonIssuingAuthority = (event) => {
    setPersonIssuingAuthority(event.target.value)
    setAgentIdentityIssuingAuthorityError(false)
    setAgentIdentityIssuingAuthorityTextError('')
  }

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = useCallback((bool) => {
    setShowTimer(bool)
  }, [])

  const createMarkup = () => ({ __html: currentStatusDescription })

  const handleResetTimer = () => {
    setShowTimer(true)
    const msgData = getSaveSecurity || getPerformSecurity
    const typeUID =
      msgData?.requiredAction && msgData?.order?.type?.uid === cancelType
        ? cancelType
        : securityType
    const requestObj: any = addNeedsProps(typeUID, msgData)

    const date = getRespMetadata?.order?.date
    const number = getRespMetadata?.order?.number

    requestObj.date = date
    requestObj.number = number

    const WordArrayToBase64 = JsonToSHA256(requestObj)
    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHashSha256(WordArrayToBase64)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID,
      putPerformData: fetchPerformSecurityStart,
      postPerformData: fetchPerformSecurityStart,
      activeId,
      secretPassPhrase,
    })
  }

  const handleDownloadXml = () => {
    const msgData = getSaveSecurity || getPerformSecurity
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.order?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleCancelOperation = (event) => {
    event.preventDefault()
    dispatch(clearPartSecurities())
    setDisabled(false)
    setFormDisabled(false)
  }

  const handleChangeNameDocument = (event) => {
    setNameDocument(event.target.value)
    setDocNameError(false)
  }

  const handleOpenRequest = (index) => {
    if (typeof index !== 'number' || groupThreeVisibility || open) return
    const arr = [...arrayDocuments]
    setArrayDocuments(arr)
    const date = arr[index]?.dateTime || null
    setDocumentDate(date)
    setDocumentType(arr[index]?.type)
    setDocNumber(arr[index]?.number || '')
    setScanFiles(arr[index]?.attachments || [])
    setOrderReasonTypeUID(arr[index]?.typeUID || '')
    setNameDocument(arr[index]?.otherTypeName || '')
    const attachmentsId = arr[index]?.attachments?.map((item) => item.id)
    processArray(attachmentsId, 'scanDocs')
    setOpen(open.includes(index) ? '' : `tabRow-${index}`)
  }

  const clearDocFields = () => {
    setDocNumber('')
    setDocumentDate('')
    setDocumentType('')
    setNameDocument('')
    setScanFiles([])
    setTemporaryScanFiles([])
  }

  const handleChangeDocumentDelete = (index = null) => {
    if (typeof index !== 'number' || isLoading) return
    const arr = [...arrayDocuments]
    arr.splice(index, 1)
    setArrayDocuments(arr)
    const idArray = [...idScanFiles]
    idArray.splice(index, 1)
    setIdScanFiles(idArray)
    clearDocFields()
    setOpen('')
  }

  const handleChangeDocumentCancel = (index = null) => {
    if (isLoading) return
    if (typeof index === 'number') {
      const arr = [...arrayDocuments]
      setArrayDocuments(arr)
    }
    clearDocFields()
    setOpen('')
  }

  const handleChangeDocumentSave = (item, index = null) => {
    if (typeof index !== 'number' || isLoading) return
    const arr = [...arrayDocuments]
    arr[index].type = documentType
    arr[index].number = docNumber
    arr[index].dateTime = documentDate || null
    arr[index].attachments = (scanFiles?.length && scanFiles) || []
    if (orderReasonTypeUID === 'Orders.Reason.Type.Other') {
      arr[index].otherTypeName = nameDocument
      arr[index].typeUID = 'Orders.Reason.Type.Other'
    }
    setArrayDocuments(arr)
    saveIdScanFiles()
    clearDocFields()
    setOpen('')
  }

  const removeLastIndexIdScanFile = () => {
    const idArray = [...idScanFiles]
    const lastId = idArray?.length - 1
    if (lastId >= 0) {
      idArray.splice(lastId)
      setIdScanFiles(idArray)
    }
  }

  const handleAddDocumentDelete = () => {
    if (isLoading) return
    setGroupThreeVisibility(false)
    clearDocFields()
    removeLastIndexIdScanFile()
  }

  const handleAddDocumentSave = () => {
    if (isLoading) return
    const obj: any = {
      type: documentType || null,
      number: docNumber,
      dateTime: documentDate,
      attachments: (scanFiles?.length && scanFiles) || [],
    }
    if (orderReasonTypeUID === 'Orders.Reason.Type.Other') {
      obj.otherTypeName = nameDocument
      obj.typeUID = 'Orders.Reason.Type.Other'
    }
    const arrayDocs = [...arrayDocuments]
    arrayDocs.push(obj)
    setArrayDocuments(arrayDocs)
    setGroupThreeVisibility(false)
    saveIdScanFiles()
    clearDocFields()
  }

  const handleAddDocumentCancel = () => {
    if (isLoading) return
    setGroupThreeVisibility(false)
    clearDocFields()
    removeLastIndexIdScanFile()
  }

  const handleCloseSaveModal = (goToHistory) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseUpdModal = () => {
    setUpdModalOpen(false)
    handleBackToHistory()
  }

  const handleClosePerformModal = (goToHistory) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
  }

  const handleCancelOk = () => {
    setMetadataUid(cancelType)
    fetchMetadataStart(cancelType)
    handleCloseCancellationModal()
    // setShowModalConfirm(true)
  }

  const handleActionReject = (modalType) => {
    if (modalType === 'saveModal') {
      handleCloseSaveModal(false)
    }
    if (modalType === 'performModal') {
      handleClosePerformModal(true)
    }
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)
    clearFields(false)
    clearDocFields()
    setActiveId(null)
    setValidity(new Date())
    setFormDisabled(false)
    setMetadataUid(securityType)
    if (securityType === 'Orders.Type.NonTrade.Depo.In') {
      history.replace('/transfers/securities/input/new')
    } else {
      history.replace('/transfers/securities/output/new')
    }
  }

  useEffect(() => {
    if (transferId === 'new' && !groupThreeVisibility && !arrayDocuments.length) {
      toggleGroupTwoVisibility()
    }
  }, [transferId, groupThreeVisibility, arrayDocuments.length])

  const onDocumentEditableFormBlur = (e) => {
    const clickedElem = e.target

    const isDatepickerDropdown = clickedElem.closest('.ant-picker-dropdown')

    if (isDatepickerDropdown) return

    const activeIndex = open.split('-')[1]

    const item = arrayDocuments[activeIndex]

    if (!item) return handleAddDocumentSave()

    handleChangeDocumentSave(item, +activeIndex)
  }

  const formDocumentsRef = useRef<HTMLDivElement | null>(null)

  useOnClickOutside(formDocumentsRef, onDocumentEditableFormBlur)

  // ТИПЫ ПОРУЧЕНИЙ

  /**
   * **Тип:**
   * `Пустое значение типа по-умолчанию`
   */
  const isNullableType = !orderType

  /**
   * **Тип:**
   * `Поручение с расчетами внутри депозитария БК РЕГИОН`
   */
  const isBrokerType = orderSubTypeUid === `${securityType}.Broker`

  /**
   * **Тип:**
   * `Поручение с расчетами в депозитарии-корреспонденте`
   */
  const isDepositoryType = orderSubTypeUid === `${securityType}.Depository`

  /**
   * **Тип:**
   * `Поручение с расчетами в НКО АО НРД`
   */
  const isNSDType = orderSubTypeUid === `${securityType}.NSD`

  /**
   * **Тип:**
   * `Поручение с расчетами в реестре`
   */
  const isRegistryType = orderSubTypeUid === `${securityType}.Registry`

  /**
   *
   * Является ли контрагент физическим лицо
   */
  const isPerson = radioValue === 'person'

  /**
   * Является ли контрагент юридическое лицо
   */
  const isFirm = radioValue === 'firm'

  /**
   * Отображать ли модальное окно подтверждения по запросу кода.
   */
  const isConfirmModalShow = () =>
    getSaveSecurity?.requiredAction?.type === 'code' ||
    getPerformSecurity?.requiredAction?.type === 'code' ||
    getSaveSecurity?.requiredAction?.type === 'mac' ||
    getPerformSecurity?.requiredAction?.type === 'mac'

  useEffect(() => {
    if (!isConfirmModalShow()) {
      setConfirmModalStatus(false)
    }

    if (isConfirmModalShow()  && showModalConfirm) {
      setConfirmModalStatus(true)
      setDisableValidation(true)
    }
  }, [getSaveSecurity, getPerformSecurity, showModalConfirm])

  const isStatusDraft = currentStatus?.uid === 'Draft'

  const actualDocumentList = [...arrayDocuments, ...(groupThreeVisibility ? [undefined] : [])];

  {
    /* ШАБЛОН КОНТРАГЕНТА */
  }

  const copyAssignment = () => {
    if (copyData !== null) {
      setGroupThreeVisibility(false)
      fillFields(copyData.order)
      setValidity(copyData.validity)
    }
    setFormDisabled(false)
    setDisabled(false)
    setCanModifyDate(true)

    setCopyStatus(false)
    setCopyData(null)
  }

  const preCopy = () => {
    const expirationDate = getRespSecurity?.order?.expirationDate;
    const isActualDate = expirationDate < todayDate ? todayDate : expirationDate
    const order = getRespSecurity?.order
    setCopyData({
      order: {
        ...order,
        _links: [],
      },
      portfolios: getRespMetadata?.references?.portfolios,
      validity: isActualDate,
    })

    if (securityType === 'Orders.Type.NonTrade.Depo.In') {
      history.replace('/transfers/securities/input/new')
    } else {
      history.replace('/transfers/securities/output/new')
    }

    clearFields();

    setMetadataUid(securityType)
    fetchMetadataStart(securityType)

    setIsLoading(false)
    setActiveId(null)
    setCopyStatus(true)
  }

  useEffect(() => {
    if (getRespMetadata && copyStatus) {
      copyAssignment();
    }
  }, [copyStatus, getRespMetadata])

  const contrAgentTemplate = (flex = 1) => (
    <FormCol flex={flex}>
      <Field
        label="Шаблон контрагента"
        hasError={depoClearanceTemplatesError}
        caption={depoClearanceTemplatesTextError}
      >
        <Select
          isCreatable
          placeholder="Введите текст"
          isDisabled={disabled}
          value={counterpartyTemplate}
          options={currentArrayTemplates?.map((item) => ({
            label: item.templateName,
            value: item.templateName,
            currenttemplate: item,
          }))}
          onChange={getHandler(handleChangeCounterpartyTemplate)}
          onCreateOption={handleChangeCounterpartyTemplateInput}
          onInputChange={handleInputChangeCounterpartyTemplate}
        />
      </Field>
    </FormCol>
  )

  const handleCloseSmsModal = () => {
    setShowModalConfirm(false)
    setConfirmModalStatus(false)
    setCodeError(false)
    dispatch(clearError())
    setCodeTextError('')
    setCode('')
    history.push(location.pathname.replace(/\/new/, `/${activeId}`))
  }

  return (
    <>
      <TransferHeading
        transferHeader={securityHeader}
        isRemoval={isStatusDraft}
        deletedOrderCall={onDeleteHandler}
      />

      <DeleteModals />

      <Form onSubmit={handlePerformSecurityInput} gap={40}>
        {/*
          БЛОК: ТИП ПОРУЧЕНИЯ
        */}
        <FormRow>
          {/* ТИП ПОРУЧЕНИЯ */}
          <FormCol flex={4}>
            <Field label="Тип поручения" hasError={subTypeError} caption={subTypeTextError}>
              <Select
                placeholder="Введите номер или выберите из списка"
                isDisabled={disabled}
                value={orderType || ''}
                options={orderSubTypes?.map((option) => ({
                  label: option?.description,
                  value: option?.description,
                  uid: option?.uid,
                }))}
                onChange={getHandler(handleChangeOrderType)}
              />
            </Field>
          </FormCol>

          {/* СРОК ДЕЙСТВИЯ ДО */}
          <FormCol flex={1}>
            <Field
              label="Срок действия до"
              hasError={expirationDateError}
              caption={expirationDateError ? expirationDateTextError : ''}
            >
              <DateField
                placeholder="Введите дату"
                value={validity}
                minDate={todayDate}
                maxDate={disabled && !canModifyDate ? validity : undefined}
                onChange={(date) => handleChangeValidity(date)}
                disabled={disabled}
              />
            </Field>
          </FormCol>
        </FormRow>

        {/*
          БЛОК: ПОРТФЕЛЬ & МЕСТО ХРАНЕНИЯ (СПИСАНИЯ / ЗАЧИСЛЕНИЯ)
        */}
        <FormRow>
          {/* ПОРТФЕЛЬ */}
          <FormCol flex={1}>
            <Field
              label="Портфель"
              hasError={partnerPortfolioError}
              caption={partnerPortfolioTextError}
            >
              <Select
                placeholder="Введите номер или выберите из списка"
                isDisabled={disabled}
                value={portfolio || ''}
                options={arrayPortfolios?.map((option) => ({
                  label: option.name,
                  value: option.name,
                }))}
                onChange={getHandler(handleChangePortfolio)}
              />
            </Field>
          </FormCol>

          {/* Место хранения (списания / зачисления) */}
          <FormCol flex={1}>
            <Field
              label={
                securityType === 'Orders.Type.NonTrade.Depo.Out'
                  ? 'Место хранения (списания)'
                  : 'Место хранения (зачисления)'
              }
              hasError={clientDepoStorageError}
              caption={clientDepoStorageTextError}
            >
              <Select
                placeholder={`Введите текст или выберите место ${
                  securityType === 'Orders.Type.NonTrade.Depo.Out' ? 'списания' : 'зачисления'
                }`}
                value={storagePlus || ''}
                options={clientDepoStorage?.map((item) => ({
                  label: item?.name,
                  value: item?.name,
                }))}
                onChange={getHandler(handleChangeStoragePlus)}
                isDisabled={disabled}
              />
            </Field>
          </FormCol>
        </FormRow>

        {/*
          БЛОК: ЦЕННЫЕ БУМАГИ
        */}
        <FormRow>
          <FormCol flex={1} gap={20}>
            <BlockHeading
              title={`Ценная бумага`}
              caption={
                currentStatusDescription ? <div dangerouslySetInnerHTML={createMarkup()} /> : ''
              }
              style={{ marginBottom: 22 }}
            />
            <FormRow>
              {/* ЭМИТЕНТ */}
              <FormCol flex={3}>
                <Field
                  label="Эмитент (для поиска укажите наименование или ISIN или № гос. рег.)"
                  hasError={securityNameError}
                  caption={securityNameTextError}
                >
                  <CustomAutocomplete
                    unique
                    placeholder="Введите текст"
                    variant="outlined"
                    name="issuer"
                    color="secondary"
                    size="small"
                    error={securityNameError}
                    disabled={disabled}
                    inputValue={searchStr || ''}
                    searchList={getRespSecurities?.securities}
                    onChange={handleChangeAutocomplete}
                    onInputChange={handleChangeAutocompleteInput}
                  />
                </Field>
              </FormCol>

              {/* КОЛИЧЕСТВО */}
              <FormCol flex={1}>
                <Field label="Количество" hasError={volumeError} caption={volumeTextError}>
                  <TextField
                    placeholder="Введите количество"
                    disabled={disabled}
                    value={amount.value}
                    onChange={(e) => {
                      amount.onChange(e)
                      setVolumeError(false)
                      setVolumeTextError('')
                    }}
                    type="text"
                    inputMode="numeric"
                  />
                </Field>
              </FormCol>

              {/* ДОСТУПНО НА ... */}
              <FormCol flex={1}>
                <StyledPriceChangeCardWrapper>
                  <PriceChangeCard
                    plain
                    label={`Баланс на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
                    value={`${
                      (balances &&
                        balances.balanceT0 >= 0 &&
                        `${balances.balanceT0.toLocaleString('ru')}`) ||
                      '0'
                    } ШТ`}
                  />
                </StyledPriceChangeCardWrapper>
              </FormCol>
            </FormRow>

            {/* TODO: проверить отображение */}
            {groupTwoVisibility && (
              <FormRow>
                {/* ВИД */}
                <FormCol flex={1}>
                  <Field label="Вид">
                    <TextField disabled value={issuer?.type?.name} />
                  </Field>
                </FormCol>

                {/* НОМЕР ГОС. РЕГИСТРАЦИИ */}
                <FormCol flex={1}>
                  <Field label="Номер гос. регистрации">
                    <TextField value={issuer?.registerNumber} disabled />
                  </Field>
                </FormCol>

                {/* ISIN */}
                <FormCol flex={1}>
                  <Field label="ISIN">
                    <TextField value={issuer?.isin} disabled />
                  </Field>
                </FormCol>

                {/* НОМИНАЛ */}
                <FormCol flex={1}>
                  <Field label="Номинал">
                    <TextField
                      value={`${issuer?.faceValue ?? ''} ${issuer?.faceValueCurrency?.uid ?? ''}`}
                      disabled
                    />
                  </Field>
                </FormCol>
              </FormRow>
            )}
          </FormCol>
        </FormRow>

        {/*
          БЛОК: КОНТРАГЕНТ
        */}
        {!isNullableType && (
          <FormRow>
            <FormCol flex={1} gap={20}>
              <BlockHeading title={`Контрагент`} />
              <>
                {(isBrokerType || !orderSubTypeUid) && (
                  <FormCol gap={20}>
                    <FormRow style={{ marginBottom: 16 }}>
                      {/* ШАБЛОН КОНТРАГЕНТА */}
                      {contrAgentTemplate(2.7)}
                      {/* <FormCol flex={3}>
                        <Field
                          label="Шаблон контрагента"
                          hasError={depoClearanceTemplatesError}
                          caption={depoClearanceTemplatesTextError}
                        >
                          <Select
                            isCreatable
                            placeholder="Введите текст"
                            isDisabled={disabled}
                            value={counterpartyTemplate}
                            options={currentArrayTemplates?.map((item) => ({
                              label: item.templateName,
                              value: item.templateName,
                              currenttemplate: item,
                            }))}
                            // inputValue={counterpartyTemplate}
                            onChange={getHandler(handleChangeCounterpartyTemplate)}
                            onCreateOption={handleChangeCounterpartyTemplateInput}
                            // onInputChange={handleInputChangeCounterpartyTemplate}
                          />
                        </Field>
                      </FormCol> */}

                      {/* ТИП СЧЕТА */}
                      <FormCol flex={1}>
                        <Field
                          label="Тип счета"
                          hasError={depoAccountHoldingError}
                          caption={depoAccountHoldingTextError}
                        >
                          <Select
                            placeholder="Введите текст"
                            options={
                              depoAccountHoldingTypes &&
                              depoAccountHoldingTypes?.map((item) => ({
                                label: item?.description,
                                value: item?.description,
                              }))
                            }
                            value={accountType}
                            onChange={getHandler(handleChangeAccountType)}
                            isDisabled={disabled}
                          />
                        </Field>
                      </FormCol>

                      {/* СЧЕТ ДЕПО */}
                      <FormCol flex={1}>
                        <Field
                          label="Счет депо"
                          hasError={depoAccountError}
                          caption={depoAccountTextError}
                        >
                          <TextField
                            name="depo-account"
                            placeholder="Введите текст"
                            value={depoAccount}
                            disabled={disabled}
                            onChange={handleChangeDepoAccount}
                          />
                        </Field>
                      </FormCol>
                    </FormRow>

                    {/* НАИМЕНОВАНИЕ КОНТРАГЕНТА */}
                    <FormRow>
                      <Field
                        label="Наименование контрагента"
                        hasError={agentNameError}
                        caption={agentNameTextError}
                      >
                        <TextField
                          name="counterparty-name"
                          placeholder="Введите текст"
                          value={agentName}
                          disabled={disabled}
                          onChange={handleChangeAgentName}
                        />
                      </Field>
                    </FormRow>
                  </FormCol>
                )}

                {isDepositoryType && (
                  <>
                    <FormRow>
                      {/* ШАБЛОН КОНТРАГЕНТА */}
                      {contrAgentTemplate(2.7)}
                      {/* <FormCol flex={3}>
                        <Field
                          label="Шаблон контрагента"
                          hasError={depoClearanceTemplatesError}
                          caption={depoClearanceTemplatesTextError}
                        >
                          <Select
                            isCreatable
                            placeholder="Введите текст"
                            value={counterpartyTemplate}
                            isDisabled={disabled}
                            options={currentArrayTemplates?.map((item) => ({
                              label: item.templateName,
                              value: item.templateName,
                              currenttemplate: item,
                            }))}
                            onChange={getHandler(handleChangeCounterpartyTemplate)}
                            onCreateOption={handleChangeCounterpartyTemplateInput}
                          />
                        </Field>
                      </FormCol> */}

                      {/* ТИП СЧЕТА */}
                      <FormCol flex={1}>
                        <Field
                          label="Тип счета"
                          hasError={depoAccountHoldingError}
                          caption={depoAccountHoldingTextError}
                        >
                          <Select
                            placeholder="Введите текст"
                            value={accountType}
                            options={
                              depoAccountHoldingTypes &&
                              depoAccountHoldingTypes?.map((item) => ({
                                label: item?.description,
                                value: item?.description,
                              }))
                            }
                            onChange={getHandler(handleChangeAccountType)}
                            isDisabled={disabled}
                          />
                        </Field>
                      </FormCol>

                      {/* СЧЕТ ДЕПО */}
                      <FormCol flex={1}>
                        <Field
                          label="Счет депо"
                          hasError={depoAccountError}
                          caption={depoAccountTextError}
                        >
                          <TextField
                            name="depo-account"
                            placeholder="Введите текст"
                            value={depoAccount}
                            disabled={disabled}
                            onChange={handleChangeDepoAccount}
                          />
                        </Field>
                      </FormCol>
                    </FormRow>

                    {/* НАИМЕНОВАНИЕ КОНТРАГЕНТА */}
                    <FormRow>
                      <Field
                        label="Наименование контрагента"
                        hasError={agentNameError}
                        caption={agentNameTextError}
                      >
                        <TextField
                          name="counterparty-name"
                          placeholder="Введите текст"
                          value={agentName}
                          disabled={disabled}
                          onChange={handleChangeAgentName}
                        />
                      </Field>
                    </FormRow>
                  </>
                )}

                {isNSDType && (
                  <>
                    <FormRow>
                      {/* ШАБЛОН КОНТРАГЕНТА */}
                      {contrAgentTemplate(1.32)}
                      {/* <FormCol flex={1}>
                        <Field
                          label="Шаблон контрагента"
                          hasError={depoClearanceTemplatesError}
                          caption={depoClearanceTemplatesTextError}
                        >
                          <Select
                            isCreatable
                            placeholder="Введите текст"
                            value={counterpartyTemplate}
                            isDisabled={disabled}
                            options={
                              currentArrayTemplates &&
                              currentArrayTemplates?.map((item) => ({
                                label: item.templateName,
                                value: item.templateName,
                                currenttemplate: item,
                              }))
                            }
                            onChange={getHandler(handleChangeCounterpartyTemplate)}
                            onCreateOption={handleChangeCounterpartyTemplateInput}
                          />
                        </Field>
                      </FormCol> */}

                      {/* НАИМЕНОВАНИЕ КОНТРАГЕНТА */}
                      <FormCol flex={1}>
                        <Field
                          label="Наименование контрагента"
                          hasError={agentNameError}
                          caption={agentNameTextError}
                        >
                          <TextField
                            name="counterparty-name"
                            placeholder="Введите текст"
                            value={agentName}
                            disabled={disabled}
                            onChange={handleChangeAgentName}
                          />
                        </Field>
                      </FormCol>
                    </FormRow>

                    <FormRow>
                      {/* ТИП СЧЕТА */}
                      <FormCol flex={1.38}>
                        <Field
                          label="Тип счета"
                          hasError={depoAccountHoldingError}
                          caption={depoAccountHoldingTextError}
                        >
                          <Select
                            placeholder="Введите текст"
                            value={accountType}
                            options={
                              depoAccountHoldingTypes &&
                              depoAccountHoldingTypes?.map((item) => ({
                                label: item?.description,
                                value: item?.description,
                              }))
                            }
                            onChange={getHandler(handleChangeAccountType)}
                            isDisabled={disabled}
                          />
                        </Field>
                      </FormCol>

                      {/* СЧЕТ ДЕПО */}
                      <FormCol flex={0.5}>
                        <Field
                          label="Счет депо"
                          hasError={depoAccountError}
                          caption={depoAccountTextError}
                        >
                          <TextField
                            name="depo-account"
                            value={depoAccount}
                            onChange={handleChangeDepoAccount}
                            placeholder="Введите текст"
                            disabled={disabled}
                          />
                        </Field>
                      </FormCol>

                      {/* НОМЕР РАЗДЕЛА */}
                      <FormCol flex={0.5}>
                        <Field
                          label="Номер раздела"
                          hasError={depoAccountDivisionError}
                          caption={depoAccountDivisionTextError}
                        >
                          <TextField
                            name="depo_account_division"
                            value={depoAccountDivision}
                            onChange={handleChangeDepoAccountDivision}
                            placeholder="Введите текст"
                            disabled={disabled}
                          />
                        </Field>
                      </FormCol>
                    </FormRow>

                    {/* ИДЕНТИФИКАТОР */}
                    <FormRow>
                      <Field
                        label="Идентификатор"
                        hasError={depoCodeError}
                        caption={depoCodeTextError}
                      >
                        <TextField
                          name="depo_code"
                          value={depoCode}
                          onChange={handleChangeDepoCode}
                          placeholder="Введите текст"
                          disabled={disabled}
                        />
                      </Field>
                    </FormRow>
                  </>
                )}

                {isRegistryType && (
                  <>
                    <FormRow>
                      {/* ШАБЛОН КОНТРАГЕНТА */}
                      {contrAgentTemplate(1.38)}
                      {/* <FormCol flex={3}>
                        <Field
                          label="Шаблон контрагента"
                          hasError={depoClearanceTemplatesError}
                          caption={depoClearanceTemplatesTextError}
                        >
                          <Select
                            isCreatable
                            placeholder="Введите текст"
                            value={counterpartyTemplate}
                            isDisabled={disabled}
                            options={
                              currentArrayTemplates &&
                              currentArrayTemplates?.map((item) => ({
                                label: item.templateName,
                                value: item.templateName,
                                currenttemplate: item,
                              }))
                            }
                            onChange={getHandler(handleChangeCounterpartyTemplate)}
                            onCreateOption={handleChangeCounterpartyTemplateInput}
                          />
                        </Field>
                      </FormCol> */}

                      {/* ТИП СЧЕТА */}
                      <FormCol flex={0.5}>
                        <Field
                          label="Тип счета"
                          hasError={depoAccountHoldingError}
                          caption={depoAccountHoldingTextError}
                        >
                          <Select
                            placeholder="Введите текст"
                            value={accountType}
                            options={
                              depoAccountHoldingTypes &&
                              depoAccountHoldingTypes?.map((item) => ({
                                label: item?.description,
                                value: item?.description,
                              }))
                            }
                            onChange={getHandler(handleChangeAccountType)}
                            isDisabled={disabled}
                          />
                        </Field>
                      </FormCol>

                      {/* СЧЕТ ДЕПО */}
                      <FormCol flex={0.5}>
                        <Field
                          label="Счет депо"
                          hasError={depoAccountError}
                          caption={depoAccountTextError}
                        >
                          <TextField
                            name="depo-account"
                            placeholder="Введите текст"
                            value={depoAccount}
                            disabled={disabled}
                            onChange={handleChangeDepoAccount}
                          />
                        </Field>
                      </FormCol>
                    </FormRow>

                    <FormRow>
                      {/* НАИМЕНОВАНИЕ КОНТРАГЕНТА */}
                      <FormCol flex={1.32}>
                        <Field
                          label="Наименование контрагента"
                          hasError={agentNameError}
                          caption={agentNameTextError}
                        >
                          <TextField
                            name="counterparty-name"
                            placeholder="Введите текст"
                            value={agentName}
                            disabled={disabled}
                            onChange={handleChangeAgentName}
                          />
                        </Field>
                      </FormCol>

                      {/* РЕФЕРЕНС */}
                      <FormCol flex={1}>
                        <Field
                          label="Референс"
                          hasError={referenceError}
                          caption={referenceTextError}
                        >
                          <TextField
                            name="reference"
                            placeholder="Введите количество"
                            value={reference}
                            disabled={disabled}
                            onChange={handleChangeReference}
                          />
                        </Field>
                      </FormCol>
                    </FormRow>

                    {/* ИДЕНТИФИКАТОР */}
                    {/* <FormRow>
                      <Field
                        label="Идентификатор"
                        hasError={depoCodeError}
                        caption={depoCodeTextError}
                      >
                        <TextField
                          name="depo_code"
                          placeholder="Введите текст"
                          value={depoCode}
                          disabled={disabled}
                          onChange={handleChangeDepoCode}
                        />
                      </Field>
                    </FormRow> */}

                    {/*  RADIO */}
                    <RadioLabeled
                      label="Тип контрагента"
                      radioButtonsList={radioButtonsList}
                      onChange={({ value }, updatedList) => {
                        setRadioValue(value as string)
                        setRadioButtonsList(updatedList)
                      }}
                    />

                    {/*  ФИЗИЧЕСКОЕ ЛИЦО */}
                    {isPerson && (
                      <>
                        <FormRow>
                          {/* ВИД ДОКУМЕНТА */}
                          <FormCol flex={1}>
                            <Field
                              label="Вид документа"
                              hasError={agentIdentityDocumentError}
                              caption={agentIdentityDocumentTextError}
                            >
                              <Select
                                isCreatable
                                placeholder="Выберите из списка"
                                isDisabled={disabled}
                                value={currentTypeDocument}
                                options={
                                  arrayTypeDocument &&
                                  arrayTypeDocument?.map((item) => ({
                                    label: item.description,
                                    value: item.uid,
                                    currenttemplate: item,
                                  }))
                                }
                                onChange={getHandler(handleChangeCurrentTypeDocument)}
                                onCreateOption={handletest}
                              />
                            </Field>
                          </FormCol>

                          {/* СЕРИЯ */}
                          <FormCol flex={1}>
                            <Field
                              label="Серия"
                              hasError={agentIdentitySeriesError}
                              caption={agentIdentitySeriesTextError}
                            >
                              <TextField
                                name="person_series"
                                placeholder="Введите текст"
                                value={personSeries}
                                disabled={disabled}
                                onChange={handleChangePersonSeries}
                              />
                            </Field>
                          </FormCol>

                          {/* НОМЕР */}
                          <FormCol flex={1}>
                            <Field
                              label="Номер"
                              hasError={agentIdentityNumberError}
                              caption={agentIdentityNumberTextError}
                            >
                              <TextField
                                name="person_number"
                                placeholder="Введите текст"
                                value={personNumber}
                                disabled={disabled}
                                onChange={handleChangePersonNumber}
                              />
                            </Field>
                          </FormCol>
                        </FormRow>

                        <FormRow>
                          {/* КОД ПОДРАЗДЕЛЕНИЯ */}
                          <FormCol flex={1}>
                            <Field
                              label="Код подразделения"
                              hasError={agentIdentityDepartmentCodeError}
                              caption={agentIdentityDepartmentCodeTextError}
                            >
                              <TextField
                                name="person_code"
                                placeholder="Введите текст"
                                value={personCode}
                                disabled={disabled}
                                onChange={handleChangePersonCode}
                              />
                            </Field>
                          </FormCol>

                          {/* ДАТА ВЫДАЧИ */}
                          <FormCol flex={1}>
                            <Field
                              label="Дата выдачи"
                              hasError={agentIdentityIssueDateError}
                              caption={
                                agentIdentityIssueDateError ? agentIdentityIssueDateTextError : ''
                              }
                            >
                              <DateField
                                placeholder="Выберите дату"
                                value={personIssueDate || null}
                                minDate={(disabled && personIssueDate) || undefined}
                                maxDate={
                                  (disabled && personIssueDate) || personExpirationDate || undefined
                                }
                                onChange={handleChangePersonIssueDate}
                                disabled={disabled}
                              />
                            </Field>
                          </FormCol>

                          {/* СРОК ОКОНЧАНИЯ */}
                          <FormCol flex={1}>
                            <Field
                              label="Срок окончания"
                              hasError={agentIdentityExpirationDateError}
                              caption={
                                agentIdentityExpirationDateError
                                  ? agentIdentityExpirationDateTextError
                                  : ''
                              }
                            >
                              <DateField
                                placeholder="Выберите дату"
                                value={personExpirationDate || null}
                                minDate={
                                  (disabled && personExpirationDate) || personIssueDate || undefined
                                }
                                maxDate={(disabled && personExpirationDate) || undefined}
                                onChange={handleChangePersonExpirationDate}
                                disabled={disabled}
                              />
                            </Field>
                          </FormCol>
                        </FormRow>

                        {/* ОРГАН, ВЫДАВШИЙ ДОКУМЕНТ */}
                        <FormRow>
                          <FormCol flex={1}>
                            <Field
                              label="Орган, выдавший документ"
                              hasError={agentIdentityIssuingAuthorityError}
                              caption={agentIdentityIssuingAuthorityTextError}
                            >
                              <TextField
                                name="person_issuingAuthority"
                                placeholder="Введите текст"
                                value={personIssuingAuthority}
                                disabled={disabled}
                                onChange={handleChangePersonIssuingAuthority}
                              />
                            </Field>
                          </FormCol>
                        </FormRow>
                      </>
                    )}

                    {/*  ЮРИДИЧЕСКОЕ ЛИЦО */}
                    {isFirm && (
                      <>
                        <FormRow>
                          {/* ДАТА */}
                          <FormCol flex={1}>
                            <Field
                              label="Дата"
                              hasError={agentRegistrationDateError}
                              caption={
                                agentRegistrationDateError ? agentRegistrationDateTextError : ''
                              }
                            >
                              <DateField
                                placeholder="Выберите дату"
                                value={firmValidity || null}
                                minDate={(disabled && firmValidity) || undefined}
                                maxDate={(disabled && firmValidity) || undefined}
                                onChange={handleChangeFirmValidity}
                                disabled={disabled}
                              />
                            </Field>
                          </FormCol>

                          {/* СЕРИЯ */}
                          <FormCol flex={1}>
                            <Field
                              label="Серия"
                              hasError={agentRegistrationSeriesError}
                              caption={agentRegistrationSeriesTextError}
                            >
                              <TextField
                                name="firm_series"
                                placeholder="Введите текст"
                                value={firmSeries}
                                disabled={disabled}
                                onChange={handleChangeFirmSeries}
                              />
                            </Field>
                          </FormCol>

                          {/* НОМЕР */}
                          <FormCol flex={1}>
                            <Field
                              label="Номер"
                              hasError={agentRegistrationNumberError}
                              caption={agentRegistrationNumberTextError}
                            >
                              <TextField
                                name="firm_number"
                                placeholder="Введите текст"
                                value={firmNumber}
                                disabled={disabled}
                                onChange={handleChangeFirmNumber}
                              />
                            </Field>
                          </FormCol>
                        </FormRow>

                        {/* РЕГИСТРИРУЮЩИЙ ОРГАН */}
                        <FormRow>
                          <FormCol flex={1}>
                            <Field
                              label="Регистрирующий орган"
                              hasError={agentRegistrationIssuingAuthorityError}
                              caption={agentRegistrationIssuingAuthorityTextError}
                            >
                              <TextField
                                name="firm_issuing_Authority"
                                placeholder="Введите текст"
                                value={firmIssuingAuthority}
                                disabled={disabled}
                                onChange={handleChangeFirmIssuingAuthority}
                              />
                            </Field>
                          </FormCol>
                        </FormRow>
                      </>
                    )}
                  </>
                )}
              </>
            </FormCol>
          </FormRow>
        )}

        {/*
          БЛОК: ОСНОВАНИЕ ДЛЯ ПЕРЕРЕГИСТРАЦИИ / СВЕДЕНИЯ О СДЕЛКЕ
        */}

        {!isNullableType && (
          <>
            <FormCol gap={20}>
              <FormRow>
                <FormCol flex={1}>
                  <BlockHeading title={'Основание для перерегистрации / сведения о сделке'} />
                </FormCol>
              </FormRow>

              {/* МЕСТО ПЕРЕРЕГИСТРАЦИИ */}
              <FormRow>
                {isDepositoryType && (
                  <FormCol flex={3}>
                    <Field
                      label="Место перерегистрации"
                      hasError={centralDepositoryError}
                      caption={centralDepositoryTextError}
                    >
                      <Select
                        placeholder="Введите текст"
                        options={centralDepositories?.map((option) => ({
                          label: option?.description,
                          value: option?.description,
                        }))}
                        value={centralDepository}
                        isDisabled={disabled}
                        onChange={getHandler(handleChangeCentralDepository)}
                      />
                    </Field>
                  </FormCol>
                )}

                {/* НОМЕР МЕЖДЕП. ДОГОВОРА */}
                {isNSDType && (
                  <FormCol flex={3}>
                    <Field
                      label="Номер междеп. договора"
                      hasError={interDepoAgreementNumberError}
                      caption={interDepoAgreementNumberTextError}
                    >
                      <TextField
                        name="inter_depo_agreement_number"
                        placeholder="Введите текст"
                        disabled={disabled}
                        value={interDepoAgreementNumber}
                        onChange={handleChangeInterDepoAgreementNumber}
                      />
                    </Field>
                  </FormCol>
                )}

                {/* ДАТА СДЕЛКИ */}
                <FormCol flex={1} style={{ maxWidth: 435 }}>
                  <Field label="Дата сделки" hasError={dealDateError} caption={dealDateTextError}>
                    <DateField
                      placeholder="Введите дату"
                      value={dealDate}
                      minDate={(disabled && dealDate) || undefined}
                      maxDate={(disabled && dealDate) || undefined}
                      onChange={handleChangeDealDate}
                      disabled={disabled}
                    />
                  </Field>
                </FormCol>

                {/* ДАТА ПЕРЕРЕГИСТРАЦИИ */}
                <FormCol flex={1} style={{ maxWidth: 435 }}>
                  <Field
                    label="Дата перерегистрации"
                    hasError={registerDateError}
                    caption={registerDateTextError}
                  >
                    <DateField
                      placeholder="Введите дату"
                      value={reRegistrationDate}
                      minDate={(disabled && reRegistrationDate) || undefined}
                      maxDate={(disabled && reRegistrationDate) || undefined}
                      onChange={handleChangeReRegistrationDate}
                      disabled={disabled}
                    />
                  </Field>
                </FormCol>
              </FormRow>
            </FormCol>
          </>
        )}

        {/*
          БЛОК: ДОКУМЕНТЫ ОСНОВАНИЯ ДЛЯ ПЕРЕРЕГИСТРАЦИИ
        */}
        {!isNullableType && (
          <FormCol gap={32} gapTablet={32} gapMobile={32}>
            {actualDocumentList.map((item, key) => {
              const isEdited = open === `tabRow-${key}` || !item

              const currentDocumentsData = item

              const currentDocumentType = (isEdited && documentType) || currentDocumentsData?.type
              const currentDocName = (isEdited && nameDocument) || currentDocumentsData?.otherTypeName || ''
              const currentDocNumber = (isEdited && docNumber) || currentDocumentsData?.number || ''
              const currentDocumentDate =
                (isEdited && documentDate) || currentDocumentsData?.dateTime || null
              const currentScanFiles =
                scanFiles?.length && isEdited ? scanFiles : currentDocumentsData?.attachments || []
              return (
                <div {...(isEdited && { ref: (el) => (formDocumentsRef.current = el) })} key={key}>
                  <FormCol gap={24} gapTablet={32} gapMobile={32}>
                    <FormRow>
                      <FormCol flex={1} gap={20}>
                        <FormRow justifyContent="space-between">
                          <BlockHeading
                            border={key === 0}
                            title={
                              key === 0 ? 'Документы основания для перерегистрации' : 'Документ'
                            }
                          />

                          {(isStatusDraft || isNew) && (
                            <FormRow gap={8} mobileRowDirection tabletRowDirection>
                              <Button
                                onClick={() => getHandler(handleOpenRequest(key))}
                                variant="header"
                                type="button"
                                disabled={isEdited}
                              >
                                <Icon color={theme.color.graphics.secondary} name="pencil" />
                              </Button>

                              {!!actualDocumentList?.length && actualDocumentList.length > 1 && (
                                <Button
                                  onClick={() =>
                                    item
                                      ? handleChangeDocumentDelete(key)
                                      : handleAddDocumentDelete()
                                  }
                                  variant="header"
                                  type="button"
                                >
                                  <Icon color={theme.color.graphics.secondary} name="trash" />
                                </Button>
                              )}
                            </FormRow>
                          )}
                        </FormRow>

                        <FormRow>
                          {/* ТИП ДОКУМЕНТА */}
                          <FormCol flex={3}>
                            <Field
                              label="Тип документа"
                              hasError={orderReasonTypesError}
                              caption={orderReasonTypesTextError}
                            >
                              <Select
                                placeholder="Выберите из списка"
                                value={currentDocumentType}
                                isSearchable={false}
                                options={
                                  orderReasonTypes &&
                                  orderReasonTypes?.map((option) => ({
                                    label: option.description,
                                    value: option.description,
                                  }))
                                }
                                onChange={getHandler(handleChangeDocumentType)}
                                isDisabled={disabled || !isEdited}
                              />
                            </Field>
                          </FormCol>

                          {/* НОМЕР ДОКУМЕНТА */}
                          <FormCol flex={1}>
                            <Field
                              label="Номер документа"
                              hasError={docNumberError}
                              caption={docNumberTextError}
                            >
                              <TextField
                                name="document-number"
                                placeholder="Введите номер"
                                value={currentDocNumber}
                                disabled={disabled || !isEdited}
                                onChange={handleChangeDocNumber}
                              />
                            </Field>
                          </FormCol>

                          {/* ДАТА ДОКУМЕНТА */}
                          <FormCol flex={1}>
                            <Field
                              label="Дата документа"
                              hasError={docDateError}
                              caption={docDateTextError}
                            >
                              <DateField
                                placeholder="Введите дату"
                                value={currentDocumentDate || null}
                                minDate={
                                  (disabled && (currentDocumentDate || item?.dateTime)) || undefined
                                }
                                maxDate={
                                  (disabled && (currentDocumentDate || item?.dateTime)) || undefined
                                }
                                onChange={handleChangeDocumentDate}
                                disabled={disabled || !isEdited}
                              />
                            </Field>
                          </FormCol>
                        </FormRow>

                        {(orderReasonTypeUID === 'Orders.Reason.Type.Other' || currentDocName) && (
                          <Field
                            label="Наименование документа"
                            hasError={docNameError}
                            caption={docNameTextError}
                          >
                            <TextField
                              name="nameDocument"
                              placeholder="Введите текст"
                              value={currentDocName}
                              disabled={disabled  || !isEdited}
                              onChange={handleChangeNameDocument}
                            />
                          </Field>
                        )}

                      </FormCol>
                    </FormRow>

                    <FormRow>
                      {/* ДОКУМЕНТЫ ОСНОВАНИЯ ДЛЯ ПЕРЕРЕГИСТРАЦИИ */}
                      <FormCol flex={1}>
                        <Field label="Документ">
                          <div className={classes.dropzoneWrapper}>
                            {(!getDisabledState() && isEdited && (
                              <DropZone
                                isNewStyle
                                label="Выберите или перетащите сюда файлы"
                                maximumNumberofFiles={555}
                                isLoading={isLoading}
                                readOnly={isLoading || isDocLoading}
                                fileObjects={scanFiles || item?.attachments}
                                onAdd={(addedFile) =>
                                  handleAddFile(
                                    currentScanFiles,
                                    setScanFiles,
                                    setTemporaryScanFiles,
                                  )(addedFile)
                                }
                                onDrop={() => setIsLoading(true)}
                                onDelete={(deletedFile) =>
                                  handleRemoveFile(
                                    currentScanFiles,
                                    setScanFiles,
                                    idScanFiles,
                                    setIdScanFiles,
                                  )(deletedFile)
                                }
                              />
                            )) || (
                              <DropZone
                                isNewStyle
                                readOnly
                                maximumNumberofFiles={555}
                                fileObjects={currentScanFiles || item?.attachments}
                              />
                            )}
                          </div>
                        </Field>
                      </FormCol>
                    </FormRow>
                  </FormCol>
                </div>
              )
            })}

            {(isStatusDraft || isNew) && (
              <Button
                justifyContent="space-between"
                padding="10px 12px"
                width="201px"
                variant="tertiary"
                type="button"
                onClick={toggleGroupTwoVisibility}
                disabled={disabled}
              >
                <Text variant="body1">Добавить документ</Text> <Icon name="plus" />
              </Button>
            )}
          </FormCol>
        )}

        {/*
          БЛОК: ДОПОЛНИТЕЛЬНЫЕ ИНСТРУКЦИИ
        */}
        {!isNullableType && (
          <>
            <FormRow>
              <FormCol flex={1} gap={20}>
                <BlockHeading title="Дополнительные инструкции" />

                <TextArea
                  autoresize
                  name="additional-instructions"
                  placeholder="Введите текст"
                  value={comment}
                  disabled={disabled}
                  onChange={handleChangeComment}
                />
              </FormCol>
            </FormRow>
          </>
        )}

        {/*
          БЛОК: ПОДТВЕРЖДЕНА СТОИМОСТЬ ПРИОБРЕТЕНИЯ ЦБ
        */}
        {!isNullableType && (
          <FormRow>
            <FormCol flex={1}>
              {hasSecuritySwitch ? (
                <FormCol gap={20}>
                  <BlockHeading
                    title={'Подтверждена стоимость приобретения ЦБ'}
                    actions={[
                      <Switchbox
                        name="first"
                        checked={switchStates.first}
                        onChange={getHandler(handleSwitchChanges)}
                      />,
                    ]}
                  />

                  {switchStates.first ? (
                    <>
                      {/* СТОИМОСТЬ ПРИОБРЕТЕНИЯ */}
                      <FormRow style={{ maxWidth: 910 }}>
                        <FormCol flex={1}>
                          <Field
                            label="Стоимость приобретения"
                            hasError={documentedCostValueError}
                            caption={documentedCostValueTextError}
                          >
                            <TextField
                              divideValueWithSpace
                              name="acquisition-cost"
                              placeholder="Введите стоимость"
                              disabled={disabled}
                              value={acquisitionCost}
                              onChange={handleChangeAcquisitionCost}
                            />
                          </Field>
                        </FormCol>

                        {/* ВАЛЮТА */}
                        <FormCol flex={1}>
                          <Field
                            label="Валюта"
                            hasError={documentedCostCurrencyError}
                            caption={documentedCostCurrencyTextError}
                          >
                            <Select
                              placeholder="Выберите из списка"
                              value={currency}
                              options={
                                arrayCurrencies?.length &&
                                arrayCurrencies?.map((item) => ({
                                  label: item?.uid,
                                  value: item?.uid,
                                }))
                              }
                              onChange={getHandler(handleChangeCurrency)}
                              isDisabled={disabled}
                            />
                          </Field>
                        </FormCol>
                      </FormRow>

                      {/* ДОКУМЕНТ, ПОДТВЕРЖДАЮЩИЙ СТОИМОСТЬ ПРИОБРЕТЕНИЯ */}
                      <Field
                        label="Документ, подтверждающий стоимость приобретения"
                        style={{ maxWidth: 910 }}
                      >
                        <div className={classes.dropzoneWrapper}>
                          {(!getDisabledState() && (
                            <DropZone
                              isNewStyle
                              onAdd={(addedFile) =>
                                handleAddFile(
                                  docFiles,
                                  setDocFiles,
                                  setTemporaryDocFiles)
                                (addedFile)
                              }
                              onDrop={() => setIsDocLoading(true)}
                              onDelete={(deletedFile) =>
                                handleRemoveFile(
                                  docFiles,
                                  setDocFiles,
                                  idDocCostFiles,
                                  setIdDocCostFiles,
                                )(deletedFile)
                              }
                              label="Выберите или перетащите сюда файлы"
                              fileObjects={docFiles}
                              maximumNumberofFiles={555}
                              isLoading={isDocLoading}
                              readOnly={isLoading || isDocLoading}
                            />
                          )) || (
                            <DropZone
                              fileObjects={docFiles}
                              maximumNumberofFiles={555}
                              readOnly />
                          )}
                        </div>
                      </Field>
                    </>
                  ) : null}
                </FormCol>
              ) : null}
            </FormCol>
          </FormRow>
        )}

        {/*
          FOOTER
        */}
        <FormFooter>
          <FormFooterActions>
            {!disabled && !isNullableType && !getRespSecurity?.order ? (
              <Button variant="tertiary" onClick={() => clearFields(false)} type={'button'}>
                Очистить форму
              </Button>
            ) : null}

            {getRespSecurity?.order && (
              <BlockHeading
                title={`Статус: ${currentStatus?.name}`}
                caption={
                  currentStatusDescription ? <div dangerouslySetInnerHTML={createMarkup()} /> : ''
                }
              />
            )}
          </FormFooterActions>

          <FormFooterActions>
            {(((currentActions && currentActions?.canModify) || !currentActions) &&
              !getSaveSecurity?.requiredAction?.type &&
              !getPerformSecurity?.requiredAction?.type &&
              !isNullableType && (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setSubmitAction('perform')
                      setShowModalConfirm(true)
                    }}
                    disabled={formDisabled}
                    type={'submit'}
                  >
                    Исполнить
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={getRespSecurity?.order?.status?.uid === 'Draft' ? preCopy : () => {
                      setSubmitAction('save')
                    }}
                    disabled={formDisabled}
                    type={'submit'}
                  >
                    {
                      (getRespSecurity?.order?.status?.uid === 'Draft')
                        ? 'Скопировать'
                        : 'Сохранить в черновики'
                    }
                  </Button>
                  {
                    getRespSecurity?.order?.status?.uid == 'Draft' && (
                      <Button
                        variant='secondary'
                        type='submit'
                        onClick={() => { setSubmitAction('save') }}
                        disabled={formDisabled}
                      >
                        Сохранить
                      </Button>
                    )
                  }
                </>
              )) ||
              ((getSaveSecurity?.requiredAction?.type === 'sign' ||
                getPerformSecurity?.requiredAction?.type === 'sign') && (
                <>
                  <Button
                    variant="primary"
                    type={'submit'}
                    onClick={() => {
                      setSubmitAction('perform')
                      setShowModalConfirm(true)
                    }}
                  >
                    Подписать
                  </Button>
                  <Button variant="primary" type={'button'} onClick={handleDownloadXml}>
                    Скачать .xml
                  </Button>
                  <Button variant="secondary" type={'button'} onClick={handleCancelOperation}>
                    Отменить
                  </Button>
                </>
              )) ||
              ((getSaveSecurity?.requiredAction?.type === 'code' ||
                getPerformSecurity?.requiredAction?.type === 'code' ||
                getSaveSecurity?.requiredAction?.type === 'mac' ||
                getPerformSecurity?.requiredAction?.type === 'mac') && (
                <>
                  <Button
                    variant="primary"
                    type={'submit'}
                    onClick={() => {
                      setSubmitAction('perform')
                      setShowModalConfirm(true)
                    }}
                  >
                    Подтвердить
                  </Button>
                  <Button variant="secondary" type={'button'} onClick={handleCancelOperation}>
                    Отменить
                  </Button>
                </>
              )) ||
              (currentActions && currentActions?.canCancel && (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setCancellationModalOpen(true)
                      setShowModalConfirm(true)
                    }}
                    type={'button'}
                  >
                    Отменить поручение
                  </Button>
                </>
              ))}
            {
              (
                getRespSecurity?.order?.status?.uid === 'Sent' ||
                getRespSecurity?.order?.status?.uid === 'Signed' ||
                getRespSecurity?.order?.status?.uid === 'Received' ||
                getRespSecurity?.order?.status?.uid === 'Executed' ||
                getRespSecurity?.order?.status?.uid === 'Cancelled' ||
                getRespSecurity?.order?.status?.uid === 'Rejected' ||
                getRespSecurity?.order?.status?.uid === 'Processing'
              ) &&
              getRespMetadata?.order?.type?.uid !== cancelType &&
              <Button
                variant='secondary'
                onClick={preCopy}
                type={'button'}
              >
                Скопировать
              </Button>
            }
            {currentStatus?.uid && (
              <Button
                variant='secondary'
                onClick={handleBackToHistory}
              >
                Назад
              </Button>
            )}
          </FormFooterActions>
        </FormFooter>

        <SMSConfirmModal
          isShowImmitPaste
          title="Подтвердите создание поручения"
          hashSha256={hashSha256}
          code={code}
          codeError={codeError}
          codeTextError={codeTextError}
          setCodeError={setCodeError}
          setCodeTextError={setCodeTextError}
          showTimer={showTimer}
          isOpen={isConfirmModalShow() && showModalConfirm}
          handleResetTimer={handleResetTimer}
          handleStartTimer={handleStartTimer}
          handleClose={handleCloseSmsModal}
          handleChangeCodeInput={handleChangeCodeInput}
          handleSubmit={() => {
            setSubmitAction('perform')
            setShowModalConfirm(true)
            setConfirmModalStatus(true)
            setCodeTextError('')
            setCodeError(false)
          }}
        />
      </Form>
      <CustomModal
        disableOverlay
        title="Поручение успешно создано."
        actionText="Хотите создать новое?"
        open={saveModalOpen}
        actionSuccess={handleActionSuccess}
        actionReject={() => handleActionReject('saveModal')}
        handleClose={handleCloseSaveModal}
      />
      <CustomModal
        title="Поручение обновлено"
        open={updModalOpen}
        handleClose={handleCloseUpdModal}
        closeInterval={1800}
      />
      <CustomModal
        disableOverlay
        title="Поручение успешно подписано"
        textOk="Да, создать"
        actionText="Хотите создать новое?"
        open={performModalOpen}
        handleClose={handleClosePerformModal}
        actionSuccess={handleActionSuccess}
        actionReject={() => handleActionReject('performModal')}
      />
      <CustomModal
        title="Создать поручение на отмену"
        textOk="Да, создать"
        open={cancellationModalOpen}
        handleClose={handleCloseCancellationModal}
        callbacks={{
          withOk: handleCancelOk,
          withCancel: () => {
            handleCloseCancellationModal()
          },
        }}
      />
      <CustomModal
        title="Не удалось загрузить файл"
        open={failedLoadModalOpen}
        handleClose={() => setFailedLoadModalOpen(false)}
      />
      <CustomModal
        open={failedEmptyFile}
        title="Не удалось загрузить пустой файл"
        handleClose={() => setFailedEmptyFile(false)}
      />
    </>
  )
}

Assignment.defaultProps = {
  hasSecuritySwitch: true,
  securityType: '',
}

Assignment.propTypes = {
  hasSecuritySwitch: PropTypes.bool,
  securityType: PropTypes.string,
}

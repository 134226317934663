import { request } from './Request'
import { syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  reportsRequest(data) {
    return request(syncApiURL, endpoints.reports.request, 'POST', data)
  },
  getReport(id) {
    return request(syncApiURL, endpoints.reports.getReport(id))
  },
  postClientSign(data) {
    return request(syncApiURL, endpoints.reports.postClientSign, 'POST', data)
  },
  postReportMarkread(data) {
    return request(syncApiURL, endpoints.reports.reportMarkread, 'POST', data)
  },
  postReportReject(data) {
    return request(syncApiURL, endpoints.reports.postReportReject, 'POST', data)
  },
  getBroker() {
    return request(syncApiURL, endpoints.reports.broker)
  },
  getBrokerFilter(data) {
    return request(syncApiURL, endpoints.reports.brokerFilter(data))
  },
  getDepo() {
    return request(syncApiURL, endpoints.reports.depo)
  },
  getDepoFilter(data) {
    return request(syncApiURL, endpoints.reports.depoFilter(data))
  },
  getCorp() {
    return request(syncApiURL, endpoints.reports.corp)
  },
  getCorpFilter(data) {
    return request(syncApiURL, endpoints.reports.corpFilter(data))
  },
  getNeedSign() {
    return request(syncApiURL, endpoints.reports.needSign)
  },
  getReportTypesNames() {
    return request(syncApiURL, endpoints.reports.reportTypesNames)
  },
}

/* eslint-disable */
import { fileEds } from './FileEds';

export const fillCertificates = (requestObj, dataToSign, thumbprint, validCertificates, failCallback) => {
  let currentThumbprint = thumbprint
  let availableCertificate = validCertificates
  let subjectName = null
  let certificates = []

  var CryptographyObject = {
    certificates: []
  }

  cadesplugin.async_spawn( (function*() {
    try {
      let oStore = yield cadesplugin.CreateObjectAsync("CAPICOM.Store");
      oStore.Open(cadesplugin.CAPICOM_CURRENT_USER_STORE, cadesplugin.CAPICOM_MY_STORE, cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);
      let certs = yield oStore.Certificates;
      certs = yield certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID);
      let certsCount = yield certs.Count;
      for (let i = 1; i <= certsCount; i++) {
        let cert = yield certs.Item(i);
        CryptographyObject.certificates.push(cert);
      }
      oStore.Close();
      certificates = [];
      let all = [];
      CryptographyObject.certificates.forEach(cert => {
        let obj = {};
        cert.IssuerName     .then( el => obj.issuerName     = el );
        cert.PrivateKey     .then( el => obj.privateKey     = el );
        cert.SerialNumber   .then( el => obj.serialNumber   = el );
        cert.SubjectName    .then( el => obj.subjectName    = el );
        cert.Thumbprint     .then( el => obj.thumbprint     = el );
        cert.ValidFromDate  .then( el => obj.validFromDate  = el );
        cert.ValidToDate    .then( el => obj.validToDate    = el );
        cert.Version        .then( el => obj.version        = el );
        all.push( cert.IssuerName    );
        all.push( cert.PrivateKey    );
        all.push( cert.SerialNumber  );
        all.push( cert.SubjectName   );
        all.push( cert.Thumbprint    );
        all.push( cert.ValidFromDate );
        all.push( cert.ValidToDate   );
        all.push( cert.Version       );
        certificates.push(obj);
      })

      Promise.all(all)
      .then(
        () => {
          if (!availableCertificate.length) availableCertificate = certificates.filter(el => el.thumbprint === currentThumbprint);
          if (availableCertificate.length === 1) currentThumbprint = availableCertificate[0].thumbprint;
          subjectName = availableCertificate?.find(f => f.thumbprint === currentThumbprint ).subjectInfo;

          const certName = subjectName.split(',').map(el => el.trim().split('=')).find(el => el[0].toUpperCase() === 'CN')[1]
          fileEds.run(certName, requestObj, dataToSign.data, currentThumbprint, failCallback)
        }
      )
      .catch(err => console.log(err))
    } catch (exc) {
      failCallback(exc);
    }
  }).bind(this) );
}

/* eslint-enable */

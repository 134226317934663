import React from "react";
import { CustomModal } from "../../ui/components";
import { connect, useDispatch } from "react-redux";
import { clearErrors } from "../../redux/errorsModal/errorsModalSlice";

const ErrorsResponseModal = ({ errorsData, opened }) => {
  const data = String(errorsData.join(", "));
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(clearErrors());
  };

  return(
    <CustomModal
      title={data}
      open={opened}
      handleClose={handleClose}
      callbacks={{ withClose: handleClose }}
    />
  )
};

const mapStateToProps = ({ errorsModal: { isOpen, errors } }) => ({
  opened: isOpen,
  errorsData: errors,
});

export default connect(mapStateToProps, {})(ErrorsResponseModal);

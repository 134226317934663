import styled from "styled-components";

export const StyledNotificationContent = styled.div`
  padding: 15px 27px;
  background-color: ${({ theme }) => theme.color.bg.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.color.text.accent};
`

export const StyledNotificationMessage = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 20px;
`

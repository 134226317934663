import { rowsPerPage } from 'config/constants'
import { format } from 'date-fns'
import { useSize } from 'hooks/useSize'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { clearError } from 'redux/error/errorSlice'
import {
  fetchHistoryMoneyFilterStartAsync,
  fetchHistoryMoneyStartAsync,
  fetchOrdersTypesNamesStartAsync,
} from 'redux/history/historySlice'
import {
  selectHistoryMoney,
  selectHistoryOrderTypes,
  selectHistoryPortfolios,
  selectHistoryStatuses, selectOrdersTypesNames,
} from 'redux/history/selector'
import {
  selectCurrentDeleteMoney,
  selectGetTransferMoney,
  selectPerformTransferMoney, selectPerformTransferMoneyStatus,
} from 'redux/transfers/money/selector'
import {
  clearTransferMoney,
  deleteMoneySuccess,
  fetchDeleteMoneyStartAsync,
  fetchPerformTransferMoneyStartAsync,
  fetchTransferMoneyStartAsync,
} from 'redux/transfers/money/transferMoneySlice'
import {selectCurrentStatus, selectCurrentThumbprint} from 'redux/user/selector'
import {
  FormCol,
  FormRow,
  Chips,
  CustomModal,
  Field,
  Select,
  DataTable,
  Pagination,
  IDataTableColumn,
  Button,
  Icon,
  DateRangeField,
} from 'ui/components'
import { useTheme } from 'ui/theme'
import {
  getDeclensionWordOrder,
  getDeclensionWordSign,
} from 'utils/DeclensionWord'
import createUri from 'utils/FilterUri'
import { StyledWrapper } from './styled'
import { formatDateServer } from 'utils/GetDate'
import {
  StyledBadge,
  StyledHistoryForm,
  StyledHistoryStatusCell,
  StyledHistoryTableButton,
  StyledTableActionsWrapper,
  StyledTableActionsButtonWrapper,
  StyledTableWrapper,
  StyledCheckboxField
} from '../../styled'
import numberWithSpaces from 'utils/Money'
import MobileFilterNew from '../../../../../../components/MobileFilterNew/MobileFilterNew'
import SnackbarNotification from "../../../../molecules/Snackbar";
import moment from "moment";
import { fetchStatusStartAsync } from "../../../../../../redux/user/userSlice";
import MobileMultiselectMenu from "../mobileMultiselectMenu";

import { initialState, statuses, moneyCancelUid, moneyOutputUid, moneyTransferUid } from './store'

export const History: React.FC<Record<string, any>> = () => {
  const size = useSize()
  const theme = useTheme()
  const isDesktop = theme.bp.isMD(size.width)
  const isMobile = theme.bp.isXXS(size.width)
  const [portfolioSelect, setPortfolioSelect] = useState(initialState.portfolio)
  const [orderTypeSelect, setOrderTypeSelect] = useState(initialState.orderType)
  const [statusSelect, setStatusSelect] = useState(initialState.status)
  const [dateFrom, setDateFrom] = useState(initialState.date.from)
  const [dateTo, setDateTo] = useState(initialState.date.to)
  const [page, setPage] = useState(0)
  const [arraySigned, setArraySigned] = useState([])
  const [arrayDeleteSigned, setArrayDeleteSigned] = useState([])
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [successSigned, setSuccessSigned] = useState(0)
  const [failSigned, setFailSigned] = useState(null)
  const [finishSignModalOpen, setFinishSignModalOpen] = useState(false)
  const [finishDeleteSignModalOpen, setFinishDeleteSignModalOpen] = useState(false)
  const [isCleared, setIsCleared] = useState(false)
  const [selected, setSelected] = useState([])
  const [filterUri, setFilterUri] = useState('')
  const [typeLabel, setTypeLabel] = useState('')
  const [selectAllChecked, setSelectedAllChecked] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const getThumbprintSelector = useSelector(selectCurrentThumbprint)
  const getUserStatus = useSelector(selectCurrentStatus)
  const historyMoneyData = useSelector(selectHistoryMoney)
  const filterPortfolios = useSelector(selectHistoryPortfolios)
  const filterStatuses = useSelector(selectHistoryStatuses)
  const filterOrderTypes = useSelector(selectHistoryOrderTypes)
  const getPerformMoneySelector = useSelector(selectPerformTransferMoney)
  const getPerformMoneySelectorStatus = useSelector(selectPerformTransferMoneyStatus)
  const getRespMoneySelector = useSelector(selectGetTransferMoney)
  const deleteMoneySelector = useSelector(selectCurrentDeleteMoney)
  const ordersTypesNames  = useSelector(selectOrdersTypesNames)
  const getRespMoney = useMemo(() => getRespMoneySelector, [getRespMoneySelector])
  const getPerformMoney = useMemo(() => getPerformMoneySelector, [getPerformMoneySelector])
  const fetchPerformTransferStart = (data, id) => {
    dispatch(fetchPerformTransferMoneyStartAsync({ data, id }))
  }

  const fetchGetTransferStart = (id) => {
    dispatch(fetchTransferMoneyStartAsync({ id }))
  }

  const fetchDeleteTransferStart = (id) => {
    dispatch(fetchDeleteMoneyStartAsync({ id }))
  }

  const fetchHistoryMoneyStart = () => {
    return dispatch(fetchHistoryMoneyStartAsync())
  }
  const fetchOrdersTypesNamesStart = () => {
    return dispatch(fetchOrdersTypesNamesStartAsync())
  }

  const fetchGetStatusStart = () => {
    dispatch(fetchStatusStartAsync())
  }

  const fetchHistoryFilterStart = (obj) => {
    dispatch(fetchHistoryMoneyFilterStartAsync({ obj }))
  }

  useEffect(() => {
      dispatch(clearTransferMoney())
      setIsCleared(true)
      fetchOrdersTypesNamesStart()
      fetchGetStatusStart()
  }, [])

  useEffect(() => {
    fetchHistoryMoneyStart()
      setArraySigned([])
  }, [])

  useEffect(() => {
    if (filterPortfolios?.length) {
      const portfolioNames = []
      filterPortfolios.map((portfolio) => {
        portfolioNames.push(portfolio.name)
        return portfolio
      })
      setPortfolioSelect({ ...portfolioSelect, portfolios: ['Все', ...portfolioNames] })
    }
  }, [filterPortfolios])
  useEffect(() => {
    if (filterOrderTypes?.length) {
      const orderTypeNames = filterOrderTypes.map((orderType) => {
        return orderType.name
      })
      setOrderTypeSelect({ ...orderTypeSelect, orderTypes: ['Все', ...orderTypeNames] })
    }
  }, [filterOrderTypes])

  useEffect(() => {
    if (filterStatuses?.length) {
      const statusNames = []
      filterStatuses.map((status) => {
        statusNames.push(status.name)
        return status
      })
      setStatusSelect({ ...statusSelect, statuses: statusNames })
    }
  }, [filterStatuses])

  useEffect(() => {
    if (historyMoneyData?.orders?.length) {
      const currentOrdersLength = historyMoneyData?.orders?.length
      if (page * rowsPerPage > currentOrdersLength) {
        setPage(0)
      }
      if (getUserStatus?.useCertificate && selected.length) {
        const arrayFoundChecked = []
        historyMoneyData.orders.forEach((item) => {
          if (selected.some((selectElm) => item.id === selectElm)) {
            arrayFoundChecked.push(item.id)
          }
        })
        setSelected(arrayFoundChecked)
      }
    }
  }, [historyMoneyData])

  useEffect(() => {
    if (arraySigned?.length && selected.length && arraySigned?.length === selected.length) {
      setSuccessSigned(arraySigned.filter((x) => x).length)
      setFailSigned(arraySigned.filter((x) => !x).length)
      setArraySigned([])
      setSelected([])
      setFinishSignModalOpen(true)
        if (filterUri) {
          fetchHistoryFilterStart(filterUri)
        } else {
          fetchHistoryMoneyStart()
        }
      return
    }
    if (
      arrayDeleteSigned?.length &&
      selected.length &&
      arrayDeleteSigned?.length === selected.length
    ) {
      setSuccessSigned(arrayDeleteSigned.filter((x) => x).length)
      setFailSigned(arrayDeleteSigned.filter((x) => !x).length)
      setArrayDeleteSigned([])
      setSelected([])
        if (filterUri) {
          fetchHistoryFilterStart(filterUri)
        } else {
          fetchHistoryMoneyStart()
        }
    }
  }, [arraySigned, selected, arrayDeleteSigned])

  useEffect(() => {
    if (failSigned) {
      dispatch(clearError())
    }
  }, [failSigned])

  useEffect(() => {
    if (getRespMoney?.order && isCleared) {
      const reqObj = { ...getRespMoney?.order }
      if (getThumbprintSelector) {
        reqObj.thumbPrint = getThumbprintSelector
      }
      fetchPerformTransferStart(reqObj, reqObj?.id)
    }
  }, [getRespMoney, isCleared])

  useEffect(() => {
    if (getPerformMoneySelectorStatus && isCleared) {
      if (getPerformMoneySelectorStatus === 303) {
        const reqObj = { ...getPerformMoney.order }
        reqObj.thumbPrint = getThumbprintSelector
        reqObj.signedXml = getPerformMoney?.requiredAction?.signAction?.xml
        fetchPerformTransferStart(reqObj, reqObj?.id)
        return
      }
      if (getPerformMoneySelectorStatus === 200) {
        setArraySigned((prev) => [...prev, true])
        return
      }
      setArraySigned((prev) => [...prev, false])
    }
  }, [getPerformMoney, isCleared, getPerformMoneySelectorStatus])

  useEffect(() => {
    if (deleteMoneySelector?.status && isCleared) {
      if (deleteMoneySelector?.status === 200) {
        setArrayDeleteSigned((prev) => [...prev, true])
        return
      }
      setArrayDeleteSigned((prev) => [...prev, false])
    }
  }, [deleteMoneySelector, isCleared])

  useEffect(() => {
    if (deleteMoneySelector?.status === 200) {
      setFinishDeleteSignModalOpen(true)
      dispatch(deleteMoneySuccess(null))
    }
  }, [deleteMoneySelector])


  const callActionOnOk = (actionMethod) => {
      selected.forEach((id) => {
        actionMethod(id)
      })
  }

  const handleChangePortfolioSelect = (option) => {
    setPortfolioSelect({
      ...portfolioSelect,
      currentPortfolio: option?.value,
    })
  }
  const handleChangeOrderTypeSelect = (option) => {
    setOrderTypeSelect({
      ...orderTypeSelect,
      currentOrderType: option?.value,
    })

    setTypeLabel(option?.label)

  }

  const handleChangeStatusSelect = (value) => {
    setStatusSelect({
      ...statusSelect,
      currentStatus: value,
    })
  }

  const handleSelectAll = () => {
    if (selectAllChecked) {
      setSelected([])
      setSelectedAllChecked(false)
      return
    }

    const newSelected =
      historyMoneyData?.orders
        ?.map((item) => item?.status?.uid === 'Draft' && item?.id)
        ?.filter(Boolean) ?? []
    setSelected(newSelected)
    setSelectedAllChecked(true)
  }

  const handleCheckboxSelect = (row) => {
    if (row.status.uid !== 'Draft') {
      return
    }

    const selectedIndex = selected.findIndex((itemId) => itemId === row.id)
    const orders = historyMoneyData?.orders
    let newSelected = []

    if (selectedIndex < 0) {
      newSelected = newSelected.concat(selected, orders.find((order) => order.id === row.id).id)
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1, selected.length),
      )
    }
    setSelected(newSelected)
  }

  const handleSignSelectedRows = () => {
    if (selected.length) {
      setPerformModalOpen(true)
    }
  }

  const handleDeleteSelectedRows = () => {
    if (selected.length) {
      setDeleteModalOpen(true)
    }
  }

  const formatDateFrom = useMemo(() => dateFrom && formatDateServer(dateFrom), [dateFrom])
  const formatDateTo = useMemo(() => dateTo && formatDateServer(dateTo), [dateTo])
  const handleAcceptFilter = () => {
    handleChangePage(0)
    const statusUID =
      filterStatuses?.find((item) => item.name === statusSelect.currentStatus)?.uid || null
    const accountId =
      filterPortfolios?.find((item) => item.name === portfolioSelect.currentPortfolio)?.id || null
    const typeUID =
      filterOrderTypes?.find((item) => item.uid === orderTypeSelect.currentOrderType)?.uid || null
    let uri = 'Count=100'
    if (statusUID || accountId || typeUID || formatDateFrom || formatDateTo) {
      uri = createUri({
        typeUID,
        accountId,
        dateFrom: formatDateFrom,
        dateTo: formatDateTo,
        statusUID,
        name: 'ordersMoney',
        condition: 'and',
      })
      uri = `Count=100&Filter=${uri}`
    }
    setFilterUri(uri)


    fetchHistoryFilterStart(uri)
  }

  useEffect(() => {
    handleAcceptFilter()
  }, [
    statusSelect.currentStatus,
    portfolioSelect.currentPortfolio,
    orderTypeSelect.currentOrderType,
    formatDateFrom,
    formatDateTo,
  ])

  const handleResetFilter = () => {
    setFilterUri('')
    fetchHistoryFilterStart('Count=100')
    setPortfolioSelect({
      ...portfolioSelect,
      currentPortfolio: initialState.portfolio.currentPortfolio,
    })
    setStatusSelect({
      ...statusSelect,
      currentStatus: initialState.status.currentStatus,
    })
    setDateFrom(initialState.date.from)
    setDateTo(initialState.date.to)
  }

  const handleChangeDateFrom = (date) => {
    setDateFrom(date)
  }

  const handleChangeDate = (date) => {
    if (!date) {
      fetchHistoryMoneyStart()
      return
    }

    const from = date[0]?.toDate()
    const to = date[1]?.toDate()
    setDateFrom(from)
    setDateTo(to)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleOpenRequest = (id, order) => {
    let nextLocation: any = ''
    if (
      (order?.type?.uid === moneyCancelUid &&
        order?.affectedOrder?.type?.uid === moneyTransferUid) ||
      order?.type?.uid === moneyTransferUid
    ) {
      nextLocation = {
        pathname: `/transfers/money/transfer/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    } else if (
      (order?.type?.uid === moneyCancelUid && order?.affectedOrder?.type?.uid === moneyOutputUid) ||
      order?.type?.uid === moneyOutputUid
    ) {
      nextLocation = {
        pathname: `/transfers/money/output/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    }
    history.push(nextLocation)
  }

  const handleCloseFinishSignModal = () => {
    setFinishSignModalOpen(false)
    setFailSigned(null)
  }

  const handleRowRemove = (id) => {
    setSelected([id])
    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const handleDeleteOk = () => {
    callActionOnOk(fetchDeleteTransferStart)
    setDeleteModalOpen(false)
  }

  const handleClosePerformModal = () => {
    setPerformModalOpen(false)
  }

  const handlePerformOk = () => {
    callActionOnOk(fetchGetTransferStart)
    handleClosePerformModal()
  }

  const filtersBarNode = (
    <FormRow>
      <FormCol flex={3}>
        <Field label="Портфель">
          <Select
            options={portfolioSelect?.portfolios?.map((option) => ({
              label: option,
              value: option,
            }))}
            value={
              portfolioSelect?.currentPortfolio
                ? {
                    value: portfolioSelect?.currentPortfolio,
                    label: portfolioSelect?.currentPortfolio,
                  }
                : null
            }
            onChange={handleChangePortfolioSelect}
            placeholder="Выберите из списка"
          />
        </Field>
      </FormCol>
      <FormCol flex={3}>
        <Field label="Тип">
          <Select
            options={[{ label: '', value: '' }, ...(ordersTypesNames
              ?.filter((f) => f?.gridUID === "Money") || [])]
              .map((item) => ({
              label: item?.name,
              value: item?.uid,
            }))
            }
            value={ordersTypesNames?.find((e) => e.name === typeLabel)?.name}
            onChange={handleChangeOrderTypeSelect}
            placeholder="Выберите из списка"
          />
        </Field>
      </FormCol>
      <FormCol flex={1.5}>
        <Field label="Дата или период">
          <DateRangeField
            value={[
              dateFrom ? moment(dateFrom) : undefined,
              dateTo ? moment(dateTo) : undefined
            ]}
            picker="week"
            onChange={handleChangeDate}
            isEqually
            getPopupContainer={trigger => trigger.parentElement}
          />
        </Field>
      </FormCol>
      {!isDesktop && (
        <FormCol flex={3}>
          <Field label="Статус">
            <Select
              options={statuses?.map((option) => ({
                label: option,
                value: option,
              }))}
              value={statusSelect.currentStatus}
              onChange={(status) => handleChangeStatusSelect(status.value)}
              placeholder="Выберите из списка"
            />
          </Field>
        </FormCol>
      )}
    </FormRow>
  )

  return (
    <>
      <StyledWrapper>
        <StyledHistoryForm>
          {isMobile && !selected?.length && (
            <MobileFilterNew
              handleAcceptFilter={handleAcceptFilter}
              handleResetFilter={handleResetFilter}
              constItems={[
                portfolioSelect?.currentPortfolio,
                statusSelect?.currentStatus,
                dateFrom ?? dateTo,
              ]}
            >
              {filtersBarNode}
            </MobileFilterNew>
          )}

          {isMobile && !!selected?.length && (
            <MobileMultiselectMenu
              transfersCount={selected?.length}
              handleSign={handleSignSelectedRows}
              handleDelete={handleDeleteSelectedRows}
            />
          )}

          {!isMobile && filtersBarNode}

          {isDesktop && (
            <FormRow>
              <Chips
                options={statuses?.map((name, key) => ({ label: name, value: name }))}
                value={statusSelect.currentStatus}
                onChange={handleChangeStatusSelect}
              />
            </FormRow>
          )}
        </StyledHistoryForm>
        {getUserStatus?.useCertificate && (
          <StyledTableActionsWrapper>
            <StyledCheckboxField checked={selectAllChecked} label='Выбрать всё' onChange={handleSelectAll}/>

            <StyledTableActionsButtonWrapper>
              <Button disabled={!selected.length} onClick={handleSignSelectedRows}>Подписать</Button>
              <Button disabled={!selected.length} variant='negative-outline' onClick={handleDeleteSelectedRows}>Удалить</Button>
            </StyledTableActionsButtonWrapper>
          </StyledTableActionsWrapper>
        )}
        <StyledTableWrapper>
          <Table
            rows={
              isDesktop
                ? historyMoneyData?.orders?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                : historyMoneyData?.orders
            }
            onRowClick={(row) => handleOpenRequest(row.id, row)}
            handleRemove={handleRowRemove}
            handleDownload={() => {}}
            selectedRows={selected}
            handleCheckboxSelect={getUserStatus?.useCertificate && handleCheckboxSelect}
          />
        </StyledTableWrapper>
        {!!historyMoneyData?.orders?.length && isDesktop && (
          <Pagination
            total={historyMoneyData?.orders?.length}
            current={page + 1}
            onChange={(page) => handleChangePage(page - 1)}
            pageSize={rowsPerPage}
          />
        )}
      </StyledWrapper>
      <CustomModal
        open={performModalOpen}
        title={`Вы действительно хотите подписать ${selected.length} ${getDeclensionWordOrder(selected.length)}?`}
        handleClose={handleClosePerformModal}
        callbacks={{
          withOk: handlePerformOk,
          withCancel: handleClosePerformModal,
        }}
      />
      <CustomModal
        open={deleteModalOpen}
        title={`Вы действительно хотите удалить ${selected.length} ${getDeclensionWordOrder(selected.length)}?`}
        actionText="Отменить это действие будет невозможно"
        textOk="Да, удалить"
        handleClose={handleCloseDeleteModal}
        callbacks={{
          withOk: handleDeleteOk,
          withCancel: handleCloseDeleteModal,
        }}
      />
      <CustomModal
        open={finishSignModalOpen}
        title={`${
          (successSigned &&
            `${successSigned} ${getDeclensionWordOrder(
              successSigned
            )} успешно ${getDeclensionWordSign(successSigned)} \n`) ||
          ''
        } ${
          (failSigned &&
            `${failSigned} ${getDeclensionWordOrder(failSigned)} не ${getDeclensionWordSign(
              failSigned
            )}`) ||
          ''
        }`}
        handleClose={handleCloseFinishSignModal}
        callbacks={{
          withClose: () => {
            setFinishSignModalOpen(false)
          },
        }}
      />
      <SnackbarNotification
        open={finishDeleteSignModalOpen}
        message="Черновик поручения удален"
        onClose={() => {
          setFinishDeleteSignModalOpen(false)
        }}
      />
    </>
  )
}

const Table: React.FC<{
  rows: any[]
  onRowClick: any
  handleRemove: any
  handleDownload: any
  selectedRows: any[]
  handleCheckboxSelect: any
}> = ({
  rows,
  onRowClick,
  handleRemove,
  handleDownload,
  selectedRows,
  handleCheckboxSelect,
}) => {
  const size = useSize()
  const theme = useTheme()
  const isDesktop = theme.bp.isMD(size.width)
  const columnsLayout = ['14.8%', '11.1%', '12%', '12.5%', '14%', '8.2%']
  const getFormatDate = (date, formatDate) => format(new Date(date), formatDate)
  const columns: IDataTableColumn<ITableRow>[] = [
    {
      id: 'name',
      title: isDesktop ? 'Дата отправления' : 'Дата',
      width: '14.8%',
      render: (row) => getFormatDate(row?.dateTime, "dd.MM.yyyy'T'HH:mm").replace('T', ' '),
    },
    {
      id: 'number',
      title: 'Номер',
      width: '8%',
    },
    {
      id: 'type',
      title: 'Тип',
      render: (row) => row?.type?.name,
    },
    {
      id: 'clientPortfolio',
      title: 'Портфель',
      render: (row) => row?.clientPortfolio?.name,
    },
    {
      id: 'amount',
      title: 'Сумма',
      render: (row) =>
        isDesktop
          ? `${numberWithSpaces(row?.amount || row?.affectedOrder?.amount)}`
          : `${numberWithSpaces(row?.amount || row?.affectedOrder?.amount)} ${
              row?.currency?.uid || row?.affectedOrder?.currencyUid
            }`,
    },
    isDesktop && {
      id: 'currency',
      title: 'Валюта',
      render: (row) => row?.currency?.uid || row?.affectedOrder?.currencyUid,
    },
    {
      id: 'status',
      title: 'Статус',
      width: '10%',
      render: (row) => (
        <StyledHistoryStatusCell>
          <StyledBadge
            variant={
              row?.status?.name === 'Исполнено'
                ? 'success'
                : row?.status?.name === 'Отказ в исполнении'
                ? 'danger'
                : 'default'
            }
          >
            {row?.status?.name}
          </StyledBadge>
          <>
            {row?.status?.uid === 'Draft' ? (
              <StyledHistoryTableButton
                onClick={(e) => {
                  e.stopPropagation()
                }}
                variant={'plain'}
              >
                <Icon
                  name="trash"
                  size={24}
                  onClick={(e) => {
                    handleRemove(row.id)
                  }}
                />
              </StyledHistoryTableButton>
            ) : (
              <StyledHistoryTableButton
                variant={'plain'}
                onClick={(e) => {
                  e.stopPropagation()
                  handleDownload()
                }}
              >
                <Icon name="download" size={24} />
              </StyledHistoryTableButton>
            )}
          </>
        </StyledHistoryStatusCell>
      ),
    },
  ].filter(Boolean)
  return (
    <DataTable<ITableRow>
      columns={columns}
      rows={rows}
      layout={columnsLayout}
      onRowClick={onRowClick}
      checkbox={{
        isVisible: !!handleCheckboxSelect,
        checked: (row) => selectedRows.some((itemId) => itemId === row.id),
        disabled: (row) => row?.status.uid !== 'Draft',
        onChange: (row) => handleCheckboxSelect(row),
        size: 'md',
      }}
    />
  )
}

export interface ITableRow extends Record<string, any> {}

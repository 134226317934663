import React, { useEffect, useState, ChangeEvent } from 'react'
import DatePicker from 'antd/es/date-picker'
import 'antd/es/date-picker/style/css'
import moment, { Moment } from 'moment'
import { IDateFieldProps } from './types'
import { StyledCalendar, StyledDateField } from './styled'
import { Icon, TextField } from 'ui/components'
import { useTheme } from 'ui/theme'
import 'moment/locale/ru'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { isDateValid } from 'utils/helpers/date'

export const DateField: React.FC<IDateFieldProps> = ({
  onChange = (e) => void 0,
  value,
  minDate,
  maxDate,
  hasError,
  disabled = false,
  ...props
}) => {
  const [dateValue, setDateValue] = useState<moment.Moment>()
  const [inputValue, setInputValue] = useState('')

  const theme = useTheme()

  const handleChange = (e: Moment) => {
    setDateValue(e)
    onChange(e?.toDate())
  }

  const isDateDisabled = (date: Moment) => {
    if (minDate && date.diff(moment(minDate)) < 0) {
      return true
    }
    if (maxDate && date.diff(moment(maxDate)) > 0) {
      return true
    }
    return false
  }

  const onFieldChangeHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value)
  }

  useEffect(() => {
    setDateValue(value ? moment(value) : null)
  }, [value])

  useEffect(() => {
    if (isDateValid(inputValue, maxDate, minDate)) {
      const [day, month, year] = inputValue.split('.')

      setDateValue(moment(new Date(+year, +month - 1, +day)))
      onChange(moment(inputValue, 'DD-MM-YYYY').toDate())
    }
  }, [inputValue])

  return (
    <StyledDateField hasError={hasError}>
      <StyledCalendar />
      <DatePicker
        getPopupContainer={trigger => trigger.parentElement}
        locale={locale}
        onChange={handleChange}
        value={dateValue}
        format="DD.MM.YYYY"
        disabledDate={isDateDisabled}
        suffixIcon={<Icon name="calendar" size={20} color={theme.color.graphics.secondary} />}
        placeholder="Введите дату"
        allowClear={false}
        disabled={disabled}
        inputRender={(inputProps) => (
          <TextField
            mask="99.99.9999"
            maskChar=""
            {...inputProps}
            onChange={(e) => {
              onFieldChangeHandler(e)
              inputProps.onChange?.(e)
            }}
          />
        )}
        {...props}
      />
    </StyledDateField>
  )
}

import CommonTheme from './CommonTheme'

export default {
  ...CommonTheme,
  palette: {
    type: 'dark',
    primary: {
      main: '#313a4e',
    },
    secondary: {
      main: '#91b1bf',
    },
    background: {
      default: '#292D34',
    },
    text: {
      secondary: '#ffffff',
    },
  },
}

import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { TextArea } from '../../atoms/TextArea'
import { theme } from '../../../theme'
import { useSizeContext } from '../../../../context/SizeContext'
import { encodeToUTF8 } from '../../../../utils/EncodeToUTF8'
import {
  CustomModal, Field, Form, FormCol, FormRow, Select, TextField,
} from '../..'
import DropZone from '../../../../components/DropZone'
import { currentUserLoginAndId, selectCurrentThumbprint } from '../../../../redux/user/selector'
import { isRequired } from '../../../../utils/Validate'
import { formatDateClient } from '../../../../utils/GetDate'
import {
  selectFeedbackStatus,
} from '../../../../redux/feedback/selector'
import Tag from '../../atoms/Tag'
import {
  StyledMessageFormWindow,
  StyledSubtitle,
  StyledFormBottom,
  StyledSendBtn,
  StyledCancelBtn,
  StyledHistoryTitle,
  StyledHistoryTitleText,
  StyledMessageFormComeBack,
  StyledDownloadBtn,
  StyledTypeField,
  StyledButtonGroup,
} from './styled'
import { ETagTypeByMessageStatus } from './types'
import formAction from '../../../../utils/FormAction'
import { SMSConfirmModal } from '../Transfers/components/modals/SMSConfim'
import JsonToSHA256 from '../../../../utils/JsonToSHA256'
import {
  useLazyGetDocumentQuery,
  useLazyGetFileQuery,
  usePostFileMutation,
} from '../../../../redux/documents/documentsApi'
import { useSendFeedbackMessageMutation } from '../../../../redux/feedback/feedbackApi'

const initialState = {
  value: {
    type: '',
    subject: '',
    request: '',
  },
  files: [],
  idFiles: [],
}

const typeUidMap = {
  'Users.Requests.Type.call': 'Обращение',
  'Users.Requests.Type.claim': 'Жалоба',
}

const typeUidValues = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Обращение',
    value: 'Users.Requests.Type.call',
  },
  {
    label: 'Жалоба',
    value: 'Users.Requests.Type.claim',
  },
]

const formatFiles = (filesFromDropZone, filesToSend) => {
  if (filesFromDropZone && filesToSend) {
    return filesToSend?.map((toSendFile, index) => ({
      ...toSendFile,
      fileName: filesFromDropZone[index]?.file?.name,
    }))
  }

  return []
}

const MessageForm = () => {
  const size = useSizeContext()

  const isMobile = theme.bp.isXS(size.width)

  const history = useHistory()

  const { id } = useParams()

  const locationStateMessageId = id === 'new' ? null : id

  const [value, setValue] = useState(initialState.value)
  const [code, setCode] = useState('')
  const [showTimer, setShowTimer] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [typeError, setTypeError] = useState(false)
  const [subjectError, setSubjectError] = useState(false)
  const [requestError, setRequestError] = useState(false)
  const [codeError, setCodeError] = useState(false)
  const [typeErrorText, setTypeErrorText] = useState('')
  const [subjectErrorText, setSubjectErrorText] = useState('')
  const [requestErrorText, setRequestErrorText] = useState('')
  const [codeErrorText, setCodeErrorText] = useState('')
  const [files, setFiles] = useState(initialState.files)
  const [idFiles, setIdFiles] = useState(initialState.idFiles)
  const [isFileLoading, setFileLoading] = useState(false)
  const [failedEmptyFile, setFailedEmptyFile] = useState(false)
  const [isSuccessSignModal, setSuccessSignModalOpen] = useState(false)
  const [isSmsModalOpen, setSmsModalOpen] = useState(false)
  const [secretPassPhrase, setSecretPassPhrase] = useState('')
  const [hashSha256, setHashSha256] = useState('')

  const [
    sendFeedbackMessage,
    {
      data: feedbackMessageData,
      error: serverError,
      loading: sendFeedbackMessageLoading,
      reset: feedbackMessageReset,
    },
  ] = useSendFeedbackMessageMutation()

  const [getFileData, { data: respGetFileData }] = useLazyGetFileQuery()
  const [getFeedback, { data: respGetData, loading: getFeedbackLoading }] = useLazyGetDocumentQuery()
  const [postFile, { data: respPostFileData }] = usePostFileMutation()

  const isFormLoading = sendFeedbackMessageLoading || getFeedbackLoading

  const fetchPostFileStart = (obj) => {
    postFile(obj)
  }

  const fetchGetFileStart = (uid) => {
    getFileData({ uid, documentId: locationStateMessageId })
  }

  const feedbackMessageStatus = useSelector(selectFeedbackStatus)

  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)

  useEffect(() => {
    if (feedbackMessageData?.requiredAction?.type === 'error') {
      if (feedbackMessageData?.requiredAction?.cause === 'Неправильный код') {
        setCodeError(true)
        setCodeErrorText(feedbackMessageData?.requiredAction?.cause)
      }
    }
    if (serverError) {
      if (serverError?.errors?.Type?.length && serverError?.errors?.Type[0]) {
        setTypeError(true)
        setTypeErrorText(serverError?.errors?.Type[0])
      }
      if (serverError?.errors?.Subject?.length && serverError?.errors?.Subject[0]) {
        setSubjectError(true)
        setSubjectErrorText(serverError?.errors?.Subject[0])
      }
      if (serverError?.errors?.Request?.length && serverError?.errors?.Request[0]) {
        setRequestError(true)
        setRequestErrorText(serverError?.errors?.Request[0])
      }
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeErrorText(serverError?.errors?.Secret[0])
      }
    }
  }, [serverError, feedbackMessageData])

  const setDeactiveMessage = () => {
    setActiveId(null)
    setFiles(initialState.files)
    setIdFiles(initialState.idFiles)
    setValue(initialState.value)
  }

  const setActiveMessage = (messageId) => {
    setActiveId(messageId)
  }

  const handleGoToHistory = () => {
    history.push('/feedback/history')
  }

  useEffect(() => {
    setDeactiveMessage()
    return () => {
      setDeactiveMessage()
    }
  }, [])

  useEffect(() => {
    if (locationStateMessageId) {
      setActiveMessage(locationStateMessageId)
      getFeedback(locationStateMessageId, false)
    } else {
      setDeactiveMessage()
    }
  }, [locationStateMessageId])

  const processArray = (filesArr) => {
    filesArr.forEach(async (fileItem) => {
      await fetchGetFileStart(fileItem.uid)
    })
  }

  useEffect(() => {
    if (respGetData) {
      setValue({
        type: respGetData?.typeUid,
        subject: respGetData?.subject,
        request: respGetData?.request,
      })
    }
    if (respGetData?.files) {
      setIdFiles(respGetData.files)
      processArray(respGetData.files)
    }
  }, [respGetData])

  useEffect(() => {
    if (feedbackMessageData?.requiredAction) {
      if (!activeId) {
        setActiveId(feedbackMessageData?.id)
      }
      if (feedbackMessageData?.requiredAction?.type === 'mac' && feedbackMessageStatus === 303) {
        setShowTimer(true)
      }
    }
    if (feedbackMessageStatus === 200) {
      setSmsModalOpen(false)
    }
    if (feedbackMessageData?.statusUid === 'Signed') {
      setSuccessSignModalOpen(true)
    }
  }, [feedbackMessageData, feedbackMessageStatus])

  useEffect(() => {
    if (respPostFileData?.uid) {
      setIdFiles((prevState) => ([...prevState, { ...respPostFileData }]))
    }
  }, [respPostFileData])

  useEffect(() => {
    if (respGetFileData?.uid) {
      const fileInCurrentMessage = respGetData?.files?.find((item) => (item.uid === respGetFileData?.uid))

      if (fileInCurrentMessage) {
        setFiles((prevState) => [...prevState, {
          data: respGetFileData?.fileData,
          file: {
            name: fileInCurrentMessage?.fileName,
            path: fileInCurrentMessage?.fileName,
            size: respGetFileData?.fileLength,
          },
        }])
      }
    }
  }, [respGetFileData])

  const getDisabledState = () => (!!respGetData
      && respGetData?.statusUid !== 'Draft'
      && !feedbackMessageData)
    || !!feedbackMessageData

  const getHandler = (handler) => (!getDisabledState() ? handler : null)

  const newHandleChangeType = ({ value: typeValue }) => {
    setValue({ ...value, type: typeValue })
    setTypeError(false)
  }

  const handleChangeSubject = (event) => {
    const { name } = event.target
    setValue({ ...value, [name]: event.target.value })
    setSubjectError(false)
  }

  const handleChangeRequest = (event) => {
    const { name } = event.target
    setValue({ ...value, [name]: event.target.value })
    setRequestError(false)
  }

  const handleCancelOperation = () => {
    feedbackMessageReset()
    setCode('')
    setCodeError('')
    setCodeErrorText('')

    if (!respGetData && activeId) {
      setDeactiveMessage()
      history.push(`/feedback/message/${activeId}`)
    }
  }

  const getDocName = (data) => `${data?.typeName} № ${activeId} от ${moment(data?.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}`

  const handleDownloadXml = () => {
    const msgData = feedbackMessageData
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${getDocName(msgData)}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleAddedFile = (addedFiles) => {
    if (!addedFiles.length) {
      setFiles([])
      return
    }
    if (addedFiles.some((file) => !file?.file?.size)) {
      setFailedEmptyFile(true)
      setFileLoading(false)
      return
    }
    const arrayFiles = [...files]
    addedFiles.forEach((file) => {
      const obj = {
        fileName: file.file.name,
        fileData: file.data.split('base64,')[1],
        fileSize: file.file.size,
      }
      fetchPostFileStart(obj)
      arrayFiles.push(file)
    })
    setTimeout(() => {
      setFiles(arrayFiles)
      setFileLoading(false)
    }, 1000)
  }

  const handleRemoveFile = (removedFile) => {
    if (getDisabledState()) return
    const newArr = files.filter((f) => f !== removedFile)
    const indexRemovedFile = files.findIndex((f) => f === removedFile)
    setFiles(newArr)
    if (indexRemovedFile !== -1) {
      const arrayIdFiles = [...idFiles]
      arrayIdFiles.splice(indexRemovedFile, 1)
      setIdFiles(arrayIdFiles)
    }
  }

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const addSecret = (requestObject, skip) => {
    const _requestObj = { ...requestObject }
    if (!_requestObj?.secret && !skip) {
      _requestObj.secret = ''

      const WordArrayToBase64 = JsonToSHA256(_requestObj)
      _requestObj.secret = WordArrayToBase64
      setHashSha256(WordArrayToBase64)
    }

    return _requestObj
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const reqObj = {
      id: feedbackMessageData?.id,
      typeUid: value?.type,
      typeName: feedbackMessageData?.typeName,
      statusUid: feedbackMessageData?.statusUid,
      statusName: feedbackMessageData?.statusName,
      date: feedbackMessageData?.date,
      time: feedbackMessageData?.time,
      number: feedbackMessageData?.number,
      subject: value?.subject,
      request: value?.request,
      files: formatFiles(files, idFiles),
    }

    sendFeedbackMessage({ arg: addSecret(reqObj), code: !currentThumbPrint })
  }

  const handleSubmitMessageForm = (event) => {
    event.preventDefault()
    let reqObj = {}
    const msgData = feedbackMessageData

    if (msgData?.requiredAction?.type) {
      switch (msgData?.requiredAction?.type) {
        case 'sign':
          reqObj = {
            id: msgData.id,
            typeUid: msgData?.typeUid,
            typeName: msgData?.typeName,
            statusUid: msgData?.statusUid,
            statusName: msgData?.statusName,
            date: msgData?.date,
            time: msgData?.time,
            number: msgData?.number,
            subject: value?.subject,
            request: value?.request,
            files: formatFiles(files, idFiles),
            thumbPrint: currentThumbPrint,
            signedXml: msgData.requiredAction.signAction.xml,
          }
          sendFeedbackMessage({ arg: reqObj })
          break
        case 'error':
        case 'mac':
          if (!code) {
            isRequired(code, setCodeError, setCodeErrorText)
            break
          }

          if (msgData?.requiredAction?.type === 'error' && !msgData?.requiredAction?.cause === 'Неправильный код') {
            break
          }

          reqObj = {
            id: msgData.id,
            typeUid: msgData?.typeUid,
            typeName: msgData?.typeName,
            statusUid: msgData?.statusUid,
            statusName: msgData?.statusName,
            date: msgData?.date,
            time: msgData?.time,
            number: msgData?.number,
            subject: value?.subject,
            request: value?.request,
            files: formatFiles(files, idFiles),
          }

          formAction({
            submitAction: 'perform',
            postPerformData: () => {},
            putPerformData: (requestObj) => {
              sendFeedbackMessage({ arg: requestObj, code: !currentThumbPrint })
            },
            requestObj: reqObj,
            typeUid: value?.type,
            code,
            currentThumbPrint,
            secretPassPhrase,
            activeId: msgData.id,
            msgData: {
              ...msgData,
              requiredAction: {
                ...msgData?.requiredAction,
                type: 'mac',
              },
            },
          })

          break

        default:
          break
      }
    } else {
      reqObj = {
        request: value?.request,
        subject: value?.subject,
        typeUid: value?.type,
        files: formatFiles(files, idFiles),
      }

      if (id) {
        reqObj = {
          id: respGetData?.id,
          typeUid: value?.type,
          typeName: respGetData?.typeName,
          statusUid: respGetData?.statusUid,
          statusName: respGetData?.statusName,
          date: respGetData?.date,
          time: respGetData?.time,
          number: respGetData?.number,
          subject: value?.subject,
          request: value?.request,
          files: formatFiles(files, idFiles),
        }
      }

      sendFeedbackMessage({ arg: addSecret(reqObj, currentThumbPrint), code: !currentThumbPrint })
    }
  }

  const onCloseSmsModal = () => {
    setSmsModalOpen(false)
    handleCancelOperation()
  }

  const isCodeAction = feedbackMessageData?.requiredAction?.type === 'mac'

  useEffect(() => {
    if (isCodeAction) setSmsModalOpen(true)
  }, [isCodeAction])

  const dateTime = respGetData?.date
  const status = respGetData?.statusUid?.toLowerCase()

  const isStatusDraft = (!respGetData || respGetData?.statusUid === 'Draft') && !feedbackMessageData
  const isTypeSign = !isStatusDraft
    && (feedbackMessageData?.requiredAction?.type === 'sign')
  const isTypeCode = !isTypeSign && isCodeAction

  return (
    <>
      <StyledMessageFormWindow>
        <SMSConfirmModal
          isShowImmitPaste
          title="Подтвердите отправку обращения"
          handleResetTimer={handleResetTimer}
          handleStartTimer={handleStartTimer}
          hashSha256={hashSha256}
          showTimer={showTimer}
          code={code}
          codeError={codeError}
          codeTextError={codeErrorText}
          setCodeError={setCodeError}
          setCodeTextError={setCodeErrorText}
          isOpen={isSmsModalOpen}
          handleChangeCodeInput={handleChangeCodeInput}
          handleSubmit={handleSubmitMessageForm}
          handleClose={onCloseSmsModal}
        />

        {locationStateMessageId && dateTime && status ? (
          <StyledHistoryTitle>
            <StyledHistoryTitleText>
              {`${respGetData?.typeName} №${locationStateMessageId} от ${formatDateClient(dateTime)}`}
            </StyledHistoryTitleText>

            <Tag type={ETagTypeByMessageStatus[status]}>{respGetData?.statusName}</Tag>
          </StyledHistoryTitle>
        ) : (
          <StyledSubtitle variant="h4">
            Если у вас есть вопросы, жалобы или предложения по&nbsp;улучшению качества нашей работы,
            сообщите нам.
          </StyledSubtitle>
        )}

        {!isFormLoading && (
          <Form onSubmit={handleSubmitMessageForm}>
            <FormCol gap={28}>
              <FormCol gap={24}>
                <FormRow gap={16}>
                  <StyledTypeField
                    label="Тип"
                    hasError={typeError}
                    caption={typeError && typeErrorText}
                  >
                    <Select
                      options={typeUidValues}
                      placeholder="Выберите тип обращения"
                      error={typeError}
                      errorText={typeErrorText}
                      label="Тип"
                      name="type"
                      color="secondary"
                      value={
                        value.type ? { value: value.type, label: typeUidMap[value?.type] } : ''
                      }
                      onChange={getHandler(newHandleChangeType)}
                      unique
                      isDisabled={getDisabledState()}
                    />
                  </StyledTypeField>

                  <FormCol flex={1}>
                    <Field
                      label="Тема"
                      hasError={subjectError}
                      caption={subjectError && subjectErrorText}
                    >
                      <TextField
                        placeholder="Опишите тему обращения"
                        name="subject"
                        autoComplete="off"
                        error={subjectError}
                        errorText={subjectErrorText}
                        variant="outlined"
                        label="Тема"
                        color="secondary"
                        size="small"
                        value={value.subject || ''}
                        onChange={(event) => handleChangeSubject(event)}
                        fullWidth
                        unique
                        disabled={getDisabledState()}
                      />
                    </Field>
                  </FormCol>
                </FormRow>

                <FormRow>
                  <FormCol flex={1}>
                    <Field
                      label="Описание"
                      hasError={requestError}
                      caption={requestError && requestErrorText}
                    >
                      <TextArea
                        minAreaHeight={144}
                        placeholder="Напишите нам сообщение"
                        name="request"
                        autoComplete="off"
                        error={requestError}
                        errorText={requestErrorText}
                        variant="outlined"
                        label="Текст обращения"
                        color="secondary"
                        value={value.request || ''}
                        onChange={(event) => handleChangeRequest(event)}
                        multiline
                        rows={3}
                        unique
                        disabled={getDisabledState()}
                      />
                    </Field>
                  </FormCol>
                </FormRow>
              </FormCol>

              <StyledFormBottom>
                <StyledButtonGroup>
                  {!getDisabledState() ? (
                    <DropZone
                      isNewStyle
                      label="Выберите или перетащите сюда файлы"
                      mt={0}
                      mb={0}
                      wrapperWidth={isMobile ? '100%' : undefined}
                      onAdd={(addedFile) => handleAddedFile(addedFile)}
                      onDrop={() => setFileLoading(true)}
                      onDelete={(deletedFile) => handleRemoveFile(deletedFile)}
                      fileObjects={files}
                      maximumNumberofFiles={555}
                      isLoading={isFileLoading}
                      padding="14px 16px 14px 20px"
                    />
                  ) : (
                    <DropZone
                      fileObjects={files}
                      maximumNumberofFiles={555}
                      readOnly
                      isNewStyle
                      label="Выберите или перетащите сюда файлы"
                      mt={0}
                      mb={0}
                      wrapperWidth={isMobile ? '100%' : undefined}
                      padding="14px 16px"
                    />
                  )}
                </StyledButtonGroup>

                <StyledButtonGroup>
                  {isStatusDraft && (
                    <>
                      <StyledSendBtn type="submit">Отправить</StyledSendBtn>
                      {/* <StyledSendBtn variant="secondary" type="button">Сохранить</StyledSendBtn> */}
                      <StyledCancelBtn
                        onClick={handleGoToHistory}
                        type="button"
                        variant="secondary"
                      >
                        Отмена
                      </StyledCancelBtn>
                    </>
                  )}

                  {isTypeSign && (
                    <>
                      <StyledSendBtn type="submit">Подписать</StyledSendBtn>

                      <StyledDownloadBtn
                        type="button"
                        variant="secondary"
                        onClick={handleDownloadXml}
                      >
                        Скачать .xml
                      </StyledDownloadBtn>

                      <StyledCancelBtn
                        onClick={handleCancelOperation}
                        type="button"
                        variant="secondary"
                      >
                        Назад
                      </StyledCancelBtn>
                    </>
                  )}

                  {!isStatusDraft && !isTypeSign && !isTypeCode && (
                    <StyledMessageFormComeBack onClick={handleGoToHistory}>
                      Веруться к истории
                    </StyledMessageFormComeBack>
                  )}
                </StyledButtonGroup>
              </StyledFormBottom>
            </FormCol>
          </Form>
        )}

        {/* <CustomModal open={open} title="Обращение обновлено" handleClose={handleClose} /> */}
        <CustomModal
          open={failedEmptyFile}
          title="Не удалось загрузить пустой файл"
          handleClose={() => setFailedEmptyFile(false)}
        />

        <CustomModal
          open={isSuccessSignModal}
          title="Обращение успешно подписано"
          handleClose={() => {
            setSuccessSignModalOpen(false)
            handleGoToHistory()
          }}
        />
      </StyledMessageFormWindow>
    </>
  )
}

export default MessageForm

import React, { useRef, useState } from 'react';
import { Icon } from 'ui/components';
import { StyledClientButton, StyledClientButtonText, StyledClientItem, StyledClientItemText, StyledClientPopover } from './styled';
import { IClientButtonProps } from './types';
import { useOnClickOutside } from "hooks/useOnclickOutside";
import { actionsList } from 'ui/components/organisms/Header/data';
import { BadgeCounter } from "../../../../atoms/BadgeCounter";
import { useGetDocumentsListQuery } from "../../../../../../redux/documents/documentsApi";


export const ClientButton: React.FC<IClientButtonProps> = ({ clientName }) => {
  const { data: needSignReports } = useGetDocumentsListQuery('NeedSign')

  const needSignReportsCount = needSignReports && needSignReports?.length


  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(menuRef, () => setIsOpen(false));
  return (
    <StyledClientButton ref={menuRef} onClick={() => setIsOpen(!isOpen)}>
      <BadgeCounter badgeContent={needSignReportsCount} yPos={-70}>
        <Icon name="menu-buildings" size={'md'} />
      </BadgeCounter>
      <StyledClientButtonText>{clientName}</StyledClientButtonText>
      {
        isOpen ?
          <StyledClientPopover>
            {
              actionsList.map(({ title, link, icon, hasBadge }) => (
                <StyledClientItem key={title} to={link}>
                  <Icon name={icon} size={'md'} />
                  <BadgeCounter
                    xPos={100}
                    badgeContent={hasBadge && needSignReportsCount}
                  >
                    <StyledClientItemText>
                      {title}
                    </StyledClientItemText>
                  </BadgeCounter>
                </StyledClientItem>
              ))
            }
          </StyledClientPopover> : null
      }
    </StyledClientButton>
  );
}

import { styled } from "ui/theme";


export const StyledLoginWrapper = styled.div`
  height: 100vh;

  display: grid;
  grid-template-columns: 50% 50%;

  @media ${({ theme }) => theme.breakpoint.md} {
    grid-template-columns: 40% 60%;
  }

  @media ${({ theme }) => theme.breakpoint.xs} {
    grid-template-columns: 40% 60%;
  }

  @media (max-width: 500px) {
    grid-template-columns: auto;
    grid-template-rows: 40% 1fr;
  }
`
export const StyledLoginLeft = styled.div`
  background-color: #313A4E;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 115px;

  @media ${({ theme }) => theme.breakpoint.sm} {
    padding: 0 115px;
  }

  @media ${({ theme }) => theme.breakpoint.xs} {
    padding: 0 57px 0 40px;
  }


  @media ${({ theme }) => theme.breakpoint.xxs} {
    padding: 0 40px;
  }

  > img {
    width: 100%;
    height: 100%;

    object-fit: contain;
`
export const StyledLoginRight = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  padding: 42px 130px;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 42px 84px;
  }

  @media ${({ theme }) => theme.breakpoint.sm} {
    padding: 42px 84px;
  }

  @media ${({ theme }) => theme.breakpoint.xs} {
    padding: 42px 48px;
  }

  @media ${({ theme }) => theme.breakpoint.xxs} {
    padding: 40px 40px 32px 40px;
  }
`

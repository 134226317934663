import React, { FC } from 'react'
import { useSize } from 'hooks/useSize'
import { MainLayout } from 'ui/components/templates/MainLayout'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from 'ui/theme'
import { SizeContext } from 'context/SizeContext'
import { Tab } from 'ui/components/molecules'
import { StyledMessageFormTabs } from './styled'
import { useLocation, Redirect, Route, Switch, NavLink } from 'react-router-dom'
import MessageForm from './MessageForm'
import MessageHistory from './MessageHistory'

const MessagePage = () => {
  const { pathname } = useLocation()

  const splitPathName = pathname.split('/')
  const isPathFeedbackHistory = splitPathName[2] === 'history'
  const isPathFeedbackMessage = splitPathName[2] === 'message'

  return (
    <MainLayout title="Обращения">
      <StyledMessageFormTabs>
        <Tab
          label="Новое обращение"
          active={isPathFeedbackMessage}
          to={`/feedback/message/new`}
          component={NavLink}
        />
        <Tab
          label="История обращений"
          active={isPathFeedbackHistory}
          to={`/feedback/history`}
          component={NavLink}
        />
      </StyledMessageFormTabs>
      <Switch>
        <Route path="/feedback" exact>
          <Redirect to={`/feedback/message/new`} />
        </Route>
        <Route exact path={`/feedback/message/:id`} component={MessageForm} />
        <Route exact path={`/feedback/history`} component={MessageHistory} />
      </Switch>
    </MainLayout>
  )
}

export const Message: FC = () => {
  const size = useSize()
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SizeContext.Provider value={size}>
        <MessagePage />
      </SizeContext.Provider>
    </ThemeProvider>
  )
}

export default Message

import React from 'react'
import TabsComponent from '../../components/Tabs/Tabs'
import MessagePanel from '../../components/MessagePanels/MessagePanel'
import { ReactComponent as MessageIcon } from '../../assets/images/message.svg'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import PageHeading from '../../components/PageHeading/PageHeading'
import useWindowSize from '../../hooks/useWindows'

export default function Message() {
  const { windowWidth } = useWindowSize()

  const tabLinks = [
    {
      key: 'fdd4f89c-11fd-11eb-adc1-0242ac120002',
      link: 'message',
      label: 'Обращения',
      icon: windowWidth > 576 ? <MessageIcon /> : <TransfersIcon />,
      component: MessagePanel,
    },
  ]

  return (
    <>
      <PageHeading text="Обращения" />
      <TabsComponent
        tabLinks={tabLinks}
        wishStyle={{ justifyContent: 'flex-start' }}
        firstStepOverOnMobile
      />
    </>
  )
}

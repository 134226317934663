import { StyledField } from 'ui/components/molecules/Field/styled'
import { styled } from 'ui/theme'

export const StyledForm = styled.form<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, gap }) => (gap ? `${gap}px` : theme.spacing.lg)};
  width: 100%;
  @media ${({ theme }) => theme.breakpoint.xs} {
    gap: ${({ theme, gap }) => (gap ? `${gap}px` : theme.spacing.xl)};
  }
`

export const StyledFormRow = styled.div<{
  gap?: number
  gapTablet?: number
  gapMobile?: number
  flex?: string
  tabletRowDirection?: boolean
  mobileRowDirection?: boolean
  justifyContent?: string
}>`
  display: ${({ gap }) => (gap ?? true ? 'flex' : 'block')};
  gap: ${({ gap }) => (gap ? `${gap}px` : '20px')};

  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};

  ${({ flex }) => {
    return `
    ${StyledField} {
      flex: ${flex || 1};
    }`
  }};

  @media ${({ theme }) => theme.breakpoint.xs } {
    flex-direction: ${({ tabletRowDirection }) => (tabletRowDirection ? 'row' : 'column')};
    gap: ${({ theme, gapTablet }) => (gapTablet ? `${gapTablet}px` : theme.spacing.lg)};
  }

  @media ${({ theme }) => theme.breakpoint.xxs} {
    flex-direction: ${({ mobileRowDirection }) => (mobileRowDirection ? 'row' : 'column')};
    gap: ${({ theme, gapMobile }) => (gapMobile ? `${gapMobile}px` : theme.spacing.lg)};
  }
`

export const StyledFormCol = styled.div<{
  flex?: number
  gap?: number
  gapTablet?: number
  gapMobile?: number
  maxWidth?: string
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme, gap }) => (gap ? `${gap}px` : theme.spacing.xxl)};
  ${({ flex }) => flex && `flex: ${flex};`}

  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  @media ${({ theme }) => theme.breakpoint.xs} {
    ${({ gapTablet }) => gapTablet && `gap: ${gapTablet}px;`}
  }

  @media ${({ theme }) => theme.breakpoint.xxs} {
    ${({ gapMobile }) => gapMobile && `gap: ${gapMobile}px;`}
  }
`

export const StyledFormFooter = styled.div`
  padding-top: 40px;
  border-top: 1px solid ${({ theme }) => theme.color.bg.secondary };
  display: flex;
  justify-content: space-between;
  @media ${({ theme }) => theme.breakpoint.xs } {
    padding-top: 32px;
    flex-direction: column;
    gap: 12px;
  }
`

export const StyledFormFooterActions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  @media ${({ theme }) => theme.breakpoint.xs } {
    flex-direction: column;
    gap: 12px;
  }
`

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Media from 'react-media'
import FilterForm from './FilterForm'
import EpsTable from './EpsTable'
import {
  fetchDepoFilterStartAsync,
  fetchDepoStartAsync,
  // getTableDataSuccess,
} from '../../redux/reports/reportsSlice'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import { rowsPerPage } from '../../config/constants'
import UsePagination from '../Pagination/Pagination'
// import { selectReportsDataTable } from '../../redux/reports/selector'
import { selectConnectionState } from '../../redux/connection/selector'
import PageHeading from '../PageHeading/PageHeading'
import useStyles from './panel.style'

const LogDeposit = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [page, setPage] = useState(0)

  const connectedWS = useSelector(selectConnectionState)
  // const reportsDepoData = useSelector(selectReportsDataTable)
  const reportsDepoData = []

  const fetchDepoStart = () => {
    dispatch(fetchDepoStartAsync())
  }
  const fetchDepoFilterStart = (reset) => {
    dispatch(fetchDepoFilterStartAsync(reset))
  }

  const {
    getData,
  } = useConnect({
    link: `GET /api${endpoints.reports.depo}`,
    startFetching: fetchDepoStart,
    // action: getTableDataSuccess,
  })

  const {
    getData: depoFilter,
  } = useConnect({
    link: `GET /api${endpoints.reports.depoFilter()}`,
    startFetching: fetchDepoFilterStart,
    // action: getTableDataSuccess,
  })

  useEffect(() => {
    if (connectedWS) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  useEffect(() => {
    if (reportsDepoData?.reports?.length) {
      const currentReportsLength = reportsDepoData.reports.length
      if (page * rowsPerPage > currentReportsLength) {
        setPage(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsDepoData])

  return (
    <>
      <PageHeading text="Депозитарные отчеты" />
      <Box className={classes.boxWrapper}>
        <FilterForm
          accounts={reportsDepoData && reportsDepoData?.references?.accounts}
          fetchFilterStart={depoFilter}
        />
        <Box className={classes.tableWrapper}>
          <EpsTable
            page={page}
            reportsArrayData={reportsDepoData && reportsDepoData?.reports}
            brokerFilter={depoFilter}
          />
          <Media
            query="(min-width: 577px)"
            render={() => (
              <Box pt={3}>
                <UsePagination
                  count={
                  reportsDepoData ? Math.floor(reportsDepoData?.reports?.length / rowsPerPage) : 0
                }
                  currentPage={page}
                  onChangePage={handleChangePage}
                />
              </Box>
            )}
          />
        </Box>
      </Box>
    </>
  )
}

export default LogDeposit

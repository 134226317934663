import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  state: null,
}

const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    connectStart(state) {
      return {
        ...state,
        state: true,
      }
    },
    disconnectStart(state) {
      return {
        ...state,
        state: false,
      }
    },
  },
})

const { actions, reducer } = connectionSlice

export const {
  connectStart,
  disconnectStart,
} = actions

export default reducer

import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './panel.style'
import CustomSelect from '../CustomControls/CustomSelect'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import {
  fetchAccountsInvestmentsStartAsync, fetchMoneyRequisitesFilterStartAsync,
  getAccountsInvestmentsSuccess, getMoneyRequisitesFilterSuccess,
} from '../../redux/investmentsNotices/investmentsNoticesSlice'
import { selectConnectionState } from '../../redux/connection/selector'
import { selectAccountsInvestments, selectMoneyRequisites } from '../../redux/investmentsNotices/selector'
import PageHeading from '../PageHeading/PageHeading'
import createUri from '../../utils/FilterUri'

export default function RequisitesMoney() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [account, setAccount] = useState(null)
  const [platform, setPlatform] = useState(null)
  const [platformList, setPlatformList] = useState([])
  const [moneyRequisitesList, setMoneyRequisitesList] = useState([])
  const [isCleared, setIsCleared] = useState(false)

  const connectedWS = useSelector(selectConnectionState)
  const accountsInvestments = useSelector(selectAccountsInvestments)
  const moneyRequisites = useSelector(selectMoneyRequisites)

  const fetchAccountsInvestmentsStart = () => {
    dispatch(fetchAccountsInvestmentsStartAsync())
  }

  const fetchMoneyRequisitesFilterStart = (filterBody) => {
    dispatch(fetchMoneyRequisitesFilterStartAsync({ filterBody }))
  }

  const {
    getData: getAccountsInvestments,
  } = useConnect({
    link: `GET /api${endpoints.investmentsNotices.getAccountsInvestments}`,
    startFetching: fetchAccountsInvestmentsStart,
    action: getAccountsInvestmentsSuccess,
  })

  const {
    getData: getMoneyRequisitesFilter,
  } = useConnect({
    link: `GET /api${endpoints.investmentsNotices.moneyRequisitesFilter()}`,
    startFetching: fetchMoneyRequisitesFilterStart,
    action: getMoneyRequisitesFilterSuccess,
  })

  const clearData = () => {
    setIsCleared(true)
    setAccount(null)
    setPlatform(null)
    setPlatformList([])
    setMoneyRequisitesList([])
    dispatch(getMoneyRequisitesFilterSuccess(''))
  }

  useEffect(() => {
    clearData()
    if (connectedWS) {
      getAccountsInvestments()
    }
    // eslint-disable-next-line
  }, [connectedWS])

  useEffect(() => {
    if (account) {
      setPlatformList([])
      const activeAccount = accountsInvestments?.accounts?.find(
        (accountItem) => accountItem?.id === account,
      )
      const tempObjectList = []
      activeAccount.portfolios.forEach(
        (portfolios) => portfolios?.moneyStorages.forEach((item) => tempObjectList.push(item)),
      )
      const filterTempObjectList = Array.from(new Set(tempObjectList.map((a) => a.uid)))
        .map((uid) => tempObjectList.find((a) => a.uid === uid))
      setPlatformList(filterTempObjectList)
    }
    // eslint-disable-next-line
  }, [account])

  useEffect(() => {
    if (account && platform) {
      // eslint-disable-next-line
      handleAcceptFilter()
    }
    // eslint-disable-next-line
  }, [account, platform])

  useEffect(() => {
    if (!platform) {
      setMoneyRequisitesList([])
      dispatch(getMoneyRequisitesFilterSuccess(''))
    }
    // eslint-disable-next-line
  }, [platform])

  useEffect(() => {
    if (isCleared) {
      if (moneyRequisites) {
        setMoneyRequisitesList([])
        const list = Object.keys(moneyRequisites)
          .slice(0, -1)
          .map((item) => moneyRequisites[item])
        const groupList = list.reduce((r, a) => {
          // eslint-disable-next-line
          r[a.currencyUID] = r[a.currencyUID] || []
          r[a.currencyUID].push(a)
          return r
        }, Object.create(null))
        Object.keys(groupList).forEach((item) => {
          setMoneyRequisitesList((prev) => [...prev, groupList[item]])
        })
      }
    }
  }, [moneyRequisites, isCleared])

  const handleChangeAccount = (event) => {
    const { value } = event.target
    setAccount(value)
    setPlatform(null)
  }

  const handleChangePlatform = (event) => {
    const { value } = event.target
    setPlatform(value)
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
  }

  const handleAcceptFilter = () => {
    if (account && platform) {
      const filterBodyClient = createUri({
        name: 'moneyRequisites',
        accountId: account,
        marketUID: platform,
        condition: '&',
      })
      getMoneyRequisitesFilter(filterBodyClient.replaceAll('+', ''))
    }
  }

  return (
    <>
      <PageHeading text={'Реквизиты \n для зачисления ДС'} />
      <Box
        pt="30px"
        maxWidth={898}
      >
        <form
          className={classnames(classes.form, classes.form_mobilePaddingLess)}
          onSubmit={handleSubmitForm}
        >
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={4}
              sm={6}
              xs={12}
            >
              <CustomSelect
                label="Счет"
                value={account || ''}
                onChange={handleChangeAccount}
                unique
              >
                {accountsInvestments && accountsInvestments?.accounts.map(({ id, number }) => (
                  (
                    <MenuItem
                      key={id}
                      value={id}
                    >
                      {number}
                    </MenuItem>
                  )
                ))}
              </CustomSelect>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xs={12}
            >
              <CustomSelect
                label="Площадка"
                value={platform || ''}
                onChange={handleChangePlatform}
                unique
              >
                {platformList && platformList.map((item, index) => (
                  <MenuItem
                    key={item?.uid + index.toString()}
                    value={item?.uid}
                  >
                    {item?.description}
                  </MenuItem>
                ))}
              </CustomSelect>
            </Grid>
          </Grid>
        </form>
      </Box>
      <div className={classes.infoBlock}>
        {moneyRequisitesList.map((moneyRequisites => (moneyRequisites.map((groupItem, groupItemKey) => (
          <div
            key={groupItem?.description + groupItemKey.toString()}
            className={classes.infoBlock__item}
          >
            <div className={classnames(classes.groupLabel, classes.groupLabel_unique)}>
              {groupItem[groupItemKey]?.currencyUID}
            </div>
            <div className={classes.infoBlock__content}>
              {groupItem.map((item, key) => (
                <div
                  key={item?.caption + key.toString()}
                  className={classnames(classes.infoBlock__row,
                    groupItem[key + 1]?.caption ? classes.infoBlock__row_mbBottom : '')}
                >
                  {item?.caption && (
                    <div className={classes.infoBlock__text}>
                      <span>
                        {item?.caption}
                      </span>
                      {item?.text}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )))))}
      </div>
    </>
  )
}

import React from 'react'
import ReactSelect, { CommonProps } from 'react-select'
import ReactCreatableSelect from 'react-select/creatable'
import { Icon } from 'ui/components'
import { SelectStyles, StyledNoOptionsMessage } from './styled'
import { ISelectProps } from './types'

export const Select: React.FC<ISelectProps> = ({
  hasError,
  noOptionsText = 'Нет значений',
  value,
  isCreatable = false,
  ...props
}) => {
  if (value === '' || value === undefined) {
    value = null
  }
  if (typeof value !== 'object') {
    value = { label: value, value }
  }
  const Cmp = isCreatable ? ReactCreatableSelect : ReactSelect
  return (
    <Cmp
      styles={SelectStyles({ hasError })}
      value={value}
      placeholder={props.placeholder ?? ''}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => <Icon name={'arrow-down'} size={'sm'} />,
        NoOptionsMessage: () => <StyledNoOptionsMessage>{noOptionsText}</StyledNoOptionsMessage>,
      }}
      {...props}
    />
  )
}

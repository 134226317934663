import styled from 'styled-components'
import { Text } from 'ui/components'
import { StyledField } from 'ui/components/molecules/Field/styled'

export const StyledOwnerRadioText = styled(Text)`
  display: block;
  max-width: 384px;

  @media ${({ theme: { breakpoint } }) => breakpoint.xs} {
    max-width: 414px;
  }
`

export const StyledRadioSwitch = styled.div`
  & > div {
    justify-content: flex-start;
    padding: 0;
    box-shadow: none;
  }
`

export const StyledAccrodionTitle = styled.div`
  display: flex;
  align-items: center;

  ${({ theme: { font } }) => font.body3};

  & svg {
    margin-right: 8px;
  }
`

export const StyledBeneficiaresRow = styled.div<{
  tabletColumn?: boolean
  mobileColumn?: boolean
  wrapOnTablet?: boolean
}>`
  display: flex;
  gap: 16px;

  ${StyledField} {
    flex: 1;
  }

  @media ${({ theme: { breakpoint } }) => breakpoint.xs} {
    ${({ tabletColumn }) => tabletColumn && 'flex-direction: column;'}
  }

  @media ${({ theme: { breakpoint } }) => breakpoint.xxs} {
    ${({ mobileColumn }) => mobileColumn && 'flex-direction: column;'}
  }
`

export const StyledBeneficiaresAddressRow = styled(StyledBeneficiaresRow)`
  @media ${({ wrapOnTablet, theme: { breakpoint } }) =>
      `${breakpoint.xxs} ${wrapOnTablet ? `, ${breakpoint.xs}` : ''}`} {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 24px 16px;

    ${StyledField} {
      flex: unset;
      width: calc(33% - (32px / 3));

      &:first-child,
      &:nth-child(2) {
        width: calc(50% - 8px);
      }
    }
  }
`

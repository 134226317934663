import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import useStyles from './ThemeSwitch.style'
import { changeTheme } from '../../redux/theme/themeSlice'
import { ReactComponent as SunIcon } from '../../assets/images/sun.svg'
import { ReactComponent as MoonUniqueIcon } from '../../assets/images/moon-unique.svg'

const ThemeSwitch = ({ switchTheme }) => {
  const classes = useStyles()
  return (
    <>
      <IconButton
        className={classes.DesktopAppBar__actionsListItem}
        color="inherit"
        disableRipple
        onClick={() => switchTheme('light')}
      >
        <SunIcon className={classes.DesktopAppBar__actionsListIcon} />
      </IconButton>
      <IconButton
        className={classes.DesktopAppBar__actionsListItem}
        color="inherit"
        disableRipple
        onClick={() => switchTheme('dark')}
      >
        <MoonUniqueIcon className={classes.DesktopAppBar__actionsListIcon} />
      </IconButton>
    </>
  )
}

const mapStateToProps = ({ theme: { current } }) => ({
  currentTheme: current,
})
const mapDispatchToProps = (dispatch) => ({
  switchTheme: (next) => dispatch(changeTheme(next)),
})

ThemeSwitch.propTypes = {
  switchTheme: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitch)

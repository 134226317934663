import getPrettyNumber from 'utils/GetPrettyNumber'

export const getProtfolioTableCellWidth = (width: number) => `${(width * 100) / 991}%`

export const getCorrectPercentValue = (value?: number) => {
  return value
    ? getPrettyNumber(
      value,
      2,
      false,
      ',',
      1
    )
    : 0

  //   return parseFloat(restedValue)
}

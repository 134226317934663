import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isFetching: false,
  notifications: [],
  notificationsClone: [],
  notificationsModalOpen: false,
  error: null,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    },
    getNotificationSuccess(state, action) {
      return {
        ...state,
        isFetching: false,
        notifications: state.notifications.concat(action.payload),
        notificationsClone: state.notificationsClone.concat(action.payload),
        error: null,
      }
    },
    getNotificationsFailed(state, action) {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    },
    clearNotifications() {
      return initialState
    },
    clearNotificationsClone(state) {
      return {
        ...state,
        notificationsClone: [],
      }
    },
    openNotificationsModal(state, action) {
      return {
        ...state,
        notificationsModalOpen: action.payload,
      }
    },
  },
})

const { actions, reducer } = notificationsSlice

export const {
  getNotifications,
  getNotificationSuccess,
  getNotificationsFailed,
  clearNotifications,
  clearNotificationsClone,
  openNotificationsModal,
} = actions

export default reducer

import { createSelector } from '@reduxjs/toolkit'

const selectPortfolio = (state) => state.portfolio

export const selectGetCategories = createSelector([selectPortfolio], (state) => state.categories)

export const selectGetTotal = createSelector([selectPortfolio], (state) => state.total)

export const selectGetTime = createSelector([selectPortfolio], (state) => state.time)

export const selectGetValuations = createSelector([selectPortfolio], (state) => state.valuations)

export const selectGetPortfolio = createSelector([selectPortfolio], (state) => state.portfolio)

export const selectGetInstruments = createSelector([selectPortfolio], (state) => state.instruments)

export const selectGetCurrentAccount = createSelector([selectPortfolio], (state) => state.currentAccount)

export const POPUP_ACTION_TEXT =
  'При возникновении вопросов обратитесь к менеджеру по телефону: +7 (495) 777 29 64 (добавочный 469) или направьте письмо на почту: sales@region.ru';

export const CARDS = [
  {
    icon: 'Analytics',
    title1: 'Анализ рынка долговых',
    title2: 'инструментов',
  },
  {
    icon: 'LineChart',
    title1: 'Сравнительный анализ для поиска',
    title2: 'инвестиционных альтернатив',
  },
  {
    icon: 'Invoice',
    title1: 'Моделирование',
    title2: 'портфеля',
  },
  {
    icon: 'Filter',
    title1: 'Фильтр для поиска ',
    title2: 'облигаций',
  },
  {
    icon: 'ServiceAnalysis',
    title1: 'Сравнительный анализ',
    title2: 'рынка облигаций',
  },
  {
    icon: 'Overview',
    title1: 'Анализ эффективности',
    title2: 'инвестиций',
  },
];

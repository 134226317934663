import { styled } from "ui/theme";

export const TableHeadingContainer = styled.div<{ layout: any}>`
  padding: 0 24px;
  margin-bottom: 15px;

  display: grid;
  align-items: center;
  grid-template-columns: ${({ layout }) => layout.join(' ')};

`

export const TableHeadingTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8c93a8;
`

export const TableHeadingWrapper = styled.div`
  width: 540px;
  margin-right: 40px;

  display: grid;
  align-items: center;
  grid-template-columns: 35% 35% 30%;
`

export const StyledAccordionTable = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.smd};
  margin-bottom: 28px;
`

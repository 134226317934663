import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Media from 'react-media'
import Cookies from 'js-cookie'
import Notification from '../../components/Notification/Notification'
import Actives from '../../components/Actives/Actives'
import useStyles from './common.style'
import useConnect from '../../utils/Connect'
import {
  clearNotifications,
  clearNotificationsClone,
  getNotificationSuccess,
} from '../../redux/notifications/notificationsSlice'
import endpoints from '../../api/endpoints'
import {
  fetchHeartBeatsStartAsync, getHeartbeatsSuccess,
} from '../../redux/heartbeats/heartbeatsSlice'
import { selectConnectionState } from '../../redux/connection/selector'
import { selectCurrentHeartbeats } from '../../redux/heartbeats/selector'
import CustomModal from '../../components/CustomModal/CustomModal'
import { selectCurrentError } from '../../redux/error/selector'
import { clearError } from '../../redux/error/errorSlice'
import { setAccessToken } from '../../redux/user/userSlice'
import { selectCurrentUser } from '../../redux/user/selector'
import { selectNeedSignReports } from '../../redux/reports/selector'
import { fetchNeedSignStartAsync, getNeedSignSuccess } from '../../redux/reports/reportsSlice'
import { selectCurrentNotifications } from '../../redux/notifications/selector'

export default function Common() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [errorTitle, setErrorTitle] = useState('')
  const [textList, setTextList] = useState([])
  const [timer, setTimer] = useState(10)

  const currentNotifications = useSelector(selectCurrentNotifications)

  // useConnect({
  //   link: 'PushNotification',
  //   action: getNotificationSuccess,
  // })

  const handleCloseInfoModal = () => {
    setInfoModalOpen(false)
    dispatch({
      type: 'user/userLogout',
    })
    history.push('/')
  }

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false)
    setTextList([])
    dispatch(clearError())
  }

  const connectedWS = useSelector(selectConnectionState)
  const heartbeats = useSelector(selectCurrentHeartbeats)
  const error = useSelector(selectCurrentError)
  const selectUser = useSelector(selectCurrentUser)
  const needSignReports = useSelector(selectNeedSignReports)

  useEffect(() => {
    dispatch(clearNotifications())
    dispatch(clearNotificationsClone())
    // eslint-disable-next-line
  }, [])

  const fetchHeartBeatsStart = () => {
    dispatch(fetchHeartBeatsStartAsync())
  }

  const fetchNeedSignStart = () => {
    dispatch(fetchNeedSignStartAsync())
  }

  // const {
  //   getData: getHeartbeats,
  // } = useConnect({
  //   link: `GET /api${endpoints.heartbeats}`,
  //   startFetching: fetchHeartBeatsStart,
  //   action: getHeartbeatsSuccess,
  // })

  // const { getData } = useConnect({
  //   link: `GET /api${endpoints.reports.needSign}`,
  //   startFetching: fetchNeedSignStart,
  //   action: getNeedSignSuccess,
  // })

  useEffect(() => {
    if (connectedWS) {
      // getData()
    }
    // eslint-disable-next-line
  }, [connectedWS])

  useEffect(() => {
    let getDataTimer
    if (connectedWS && currentNotifications) {
      let disabled = false
      if (!disabled) {
        const hasNeedSignReport = currentNotifications.find((item) => item?.objectUid === 'reports' && item?.detail?.statusUid?.new === 'needClientSign')
        if (hasNeedSignReport) {
          // getData()
          getDataTimer = setTimeout(() => {
            disabled = true
          }, 5000)
        }
      }
    }
    return () => {
      clearTimeout(getDataTimer)
    }
    // eslint-disable-next-line
  }, [connectedWS, currentNotifications])

  useEffect(() => {
    let interval
    if (connectedWS) {
      interval = setInterval(() => {
        // getHeartbeats()
      }, 60000)
    }
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [connectedWS, heartbeats])

  useEffect(() => {
    let interval
    const cookieToken = Cookies.get('accessToken')
    if (!cookieToken) {
      setInfoModalOpen(true)
      interval = setInterval(() => {
        if (timer === 1) {
          handleCloseInfoModal()
        } else {
          setTimer((prev) => prev - 1)
        }
      }, 1000)
    }
    if (cookieToken && (!selectUser?.accessToken || cookieToken !== selectUser?.accessToken)) {
      dispatch(setAccessToken(cookieToken))
    }
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [selectUser, timer])

  useEffect(() => {
    if (!location?.pathname || (location?.pathname !== '/transfers/money/log'
    && location?.pathname !== '/transfers/securities/log')) {
      if (error && error?.errors) {
        Object.entries(error?.errors).forEach((item) => {
          item[1].forEach((subItem) => {
            setTextList((prev) => prev.concat(subItem))
          })
        })
        setErrorTitle(error?.title)
        setErrorModalOpen(true)
      } else if (error && error?.Message) {
        setTextList((prev) => prev.concat(error?.Message))
        setErrorModalOpen(true)
      }
    }
    return () => {
      setTextList([])
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    const needSignRedirect = localStorage.getItem('needSignRedirect')
    if (needSignReports?.reports?.length && needSignRedirect === '1') {
      history.push('/sign/reports')
      localStorage.setItem('needSignRedirect', '0')
    }
    // eslint-disable-next-line
  }, [needSignReports])

  return (
    <>
      <Notification />
      <Media
        query="(min-width: 577px)"
        render={() => (
          <div className={classes.wrapper}>
            <Actives />
          </div>
        )}
      />
      <CustomModal
        open={infoModalOpen}
        title={`Вы будете перенаправлены на страницу авторизации через ${timer} секунды`}
        handleClose={handleCloseInfoModal}
        infinity
      />
      <CustomModal
        errorModal
        open={errorModalOpen}
        title={errorTitle}
        textList={textList}
        handleClose={handleCloseErrorModal}
      />
    </>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import useStyles from './CustomControls.style'
import { ReactComponent as CircleIcon } from '../../assets/images/circle.svg'

const CustomIconButton = ({
  unique, icon, needLine, noIcon, customClassNames, type, children, ...props
}) => {
  const classes = useStyles()

  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      className={classnames(classes.iconButton,
        { [classes.iconButtonUnique]: unique },
        customClassNames)}
      {...props}
    >
      {!noIcon && icon}
      <span
        className={classnames({
          [classes.IconButton__line]: needLine,
        })}
      >
        {children}

      </span>
    </button>
  )
}

CustomIconButton.defaultProps = {
  type: 'button',
  unique: false,
  icon: <CircleIcon />,
  needLine: false,
  noIcon: false,
  customClassNames: null,
}

CustomIconButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  unique: PropTypes.bool,
  icon: PropTypes.node,
  needLine: PropTypes.bool,
  noIcon: PropTypes.bool,
  customClassNames: PropTypes.string,
}

export default CustomIconButton

import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { selectLastIdNotifications } from "../redux/notifications/selector";
import { useLazyGetNotificationQuery } from "../redux/notifications/notificationsApi";
import { selectCurrentUser } from "../redux/user/selector";
import { useUpdateQueryCacheFromNotificationService } from "./useUpdateQueryCacheFromNotificationService";
import { getNotificationSuccess } from "../redux/notifications/notificationsSlice";
import { fetchHistoryDepoStartAsync, fetchHistoryMoneyStartAsync } from "../redux/history/historySlice";

export const useNotificationsHandle = () => {
  const currentUser = useSelector(selectCurrentUser)
  const lastIdNotifications = useSelector(selectLastIdNotifications)

  const dispatch = useDispatch()

  const [getNotification, { data: notificationResponse }] = useLazyGetNotificationQuery()

  const updateCache = useUpdateQueryCacheFromNotificationService()

  const accessToken = currentUser?.accessToken

  useEffect(() => {
    accessToken && getNotification({ lastId: 0 })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => accessToken && getNotification({ lastId: notificationResponse?.lastId || 0 }), 5000)
    return () => clearInterval(interval)
  }, [notificationResponse?.lastId, accessToken])

  useEffect(() => {
    (notificationResponse?.notifications??[]).forEach(notific => {
      dispatch(getNotificationSuccess(notific))
      console.log(notific)
      const isMoney = notific?.detail?.GridUid === 'Money';
      const isDepo = notific?.detail?.GridUid === 'Depo';

      isMoney && dispatch(fetchHistoryMoneyStartAsync());
      isDepo && dispatch(fetchHistoryDepoStartAsync())

      updateCache(notific)
    })
  }, [notificationResponse])
}


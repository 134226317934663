import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import { selectFormsData } from 'redux/profilePanel/selector'
import { selectCurrentUser } from 'redux/user/selector'
import { Tab, Tabs } from 'ui/components/molecules'
import { CustomerTab } from './Tabs/CustomerTab'
import { CommonTab } from './Tabs/CommonTab'

import type { FC } from 'react'
import { LegalEntityList, LEGAL_ENTITY } from 'types/client'
import type { ClientFirmForm } from 'types/clientFirmForm'
import { StyledBasicInfoTabs } from './styled'
import RequisitesTab from './Tabs/RequisitesTab'

/**
 * Основные сведения.
 *
 * @constructor
 */
const BasicInfo: FC = () => {
  const DEFAULT_TAB = 'profile'

  const { url, path } = useRouteMatch()
  const { pathname } = useLocation()
  const splitPathname = pathname.split('/')
  const formData: ClientFirmForm = useSelector(selectFormsData)?.data?.forms[0]?.list[0]

  const clientInfo = formData?.clientInfo
  const listPerson = clientInfo?.customer

  const legalEntity: LegalEntityList = useSelector(selectCurrentUser)?.client?.legalEntity

  if (!splitPathname[3]) {
    return <Redirect to={`${url}/${DEFAULT_TAB}`} />
  }

  const isFirmUser = legalEntity === LEGAL_ENTITY.FIRM

  return (
    <>
      <StyledBasicInfoTabs variant={2}>
        <Tab label="Профиль" to={`${url}/profile`} component={NavLink} />
        {isFirmUser && <Tab label="Общее" to={`${url}/general`} component={NavLink} />}
        <Tab label="Реквизиты" to={`${url}/requisites`} component={NavLink} />
      </StyledBasicInfoTabs>

      <Switch>
        {/*
          ВКЛАДКА "ПРОФИЛЬ"
        */}
        <Route path={`${url}/profile`}>
          <CustomerTab
            customer={isFirmUser ? formData?.customer : listPerson}
            clientInfo={clientInfo}
            legalEntity={legalEntity}
          />
        </Route>

        {/*
          ВКЛАДКА "ОБЩИЕ"
        */}
        {isFirmUser && (
          <Route path={`${url}/general`}>
            <CommonTab clientInfo={clientInfo} />
          </Route>
        )}

        {/*
          ВКЛАДКА "РЕКВИЗИТЫ"
        */}
        <Route path={`${url}/requisites`}>
          <RequisitesTab bankRequisites={formData?.bankRequisites} />
        </Route>
      </Switch>
    </>
  )
}

export default memo(BasicInfo)

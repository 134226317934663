import {styled} from "../../../../../theme";

export const StyledMobileMultiselectWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 10;

  display: flex;
  flex-direction: column;
  padding: 32px 40px 24px;

  background-color: ${({ theme }) => theme.color.bg.secondary};
`

export const StyledMobileMultiselectTitle = styled.span`
  ${({ theme }) => theme.font.h3};

  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`

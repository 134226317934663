import CryptoJS from 'crypto-js'

// Пример работы, под капотом происходит преобразование в utf8, перед вычислением WordArray(см. в документации Crypto-js)
const JsonToSHA256 = (json) => {
  const finalHashJson = JSON.stringify(json)
  const WordArrayHash = CryptoJS.SHA256(finalHashJson)
  const WordArrayToBase64 = WordArrayHash.toString(CryptoJS.enc.Base64)
  return WordArrayToBase64
}

export default JsonToSHA256

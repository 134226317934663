import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { fetchDocumentsStartAsync } from '../../../../../redux/documents/brokerageServices/documentsBrokerageServicesSlece'
import { selectGetDocuments } from '../../../../../redux/documents/brokerageServices/selectop'
import { rowsPerPage } from '../../../../../config/constants'
import { useSize } from '../../../../../hooks/useSize'
import { useTheme } from '../../../../theme'
import { StyledBadge, StyledHistoryStatusCell } from '../../Transfers/styled'
import { ITableRow } from '../../Transfers/components/Money/History'
import { Button, Pagination, Icon } from 'ui/components'
import { IDataTableColumn } from '../../../../components'
import { AccordionTable } from '../components/AccordionTable'

const getFormatDate = (date, formatDate) => format(new Date(date), formatDate)

const columns = ({handleRemove}): IDataTableColumn<ITableRow>[] => [
  {
    id: 'submitted',
    title: 'Дата отправления',
    width: '20%',
    render: (row) => getFormatDate(row?.submitted, 'dd.MM.yyyy'),
  },
  {
    id: 'id',
    title: '№',
    width: '15%',
  },
  {
    id: 'type',
    title: 'Тип документа',
    width: '60%',
    render: (row) => row?.type?.name,
  },
  {
    id: 'status',
    title: 'Статус',
    render: (row) => (
      <StyledHistoryStatusCell style={{ width: '100%' }}>
        <StyledBadge
          variant={
            row?.status?.name === 'Подписано'
              ? 'success'
              : row?.status?.name === 'Отказ в исполнении'
              ? 'danger'
              : 'default'
          }
        >
          {row?.status?.name}
        </StyledBadge>
        <Button variant="plain">
          <Icon
            name="trash"
            size={24}
            onClick={(e) => {
              handleRemove(row.id)
            }}
          />
        </Button>
      </StyledHistoryStatusCell>
    ),
  },
].filter(Boolean)

const layout = ['15%', '5%', '56%', '24%']
const childrenLayout = ['18%', '48%', '7%', '18%']

const childrenColumns = [
  {
    id: 'submitted',
    title: 'Дата отправления',
    render: (row) => getFormatDate(row?.inserted, 'dd.MM.yyyy'),
  },
  {
    id: 'contract',
    title: 'Договор',
    render: (row) => row?.fileName
  },
  {
    id: 'sha2_256',
    title: 'ЭЦП',
    render: (row) => (
      <Button variant="plain">
        <Icon
          name="pencil"
          size={24}
          onClick={(e) => {
            // handleRemove(row.id)
          }}
        />
      </Button>
    ),
  },
  {
    id: 'download',
    title: 'Формат',
    render: (row) => (
      <Button variant="plain">
        <Icon
          name="download"
          size={24}
          onClick={(e) => {
            // handleRemove(row.id)
          }}
        />
      </Button>
    ),
  },
]

const DocumentsTable = () => {
  const size = useSize()
  const theme = useTheme()

  const history = useHistory()
  const dispatch = useDispatch()

  const isDesktop = theme.bp.isMD(size.width)

  const [page, setPage] = useState(0)


  const fetchDocumentsStart = () => {
    dispatch(fetchDocumentsStartAsync())
  }

  useEffect(() => {
    fetchDocumentsStart()
  }, [])

  const getRespDocuments = useSelector(selectGetDocuments)

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleOpenRequest = (document) => {
    let nextLocation = {}
    if (document?.id) {
      nextLocation = {
        pathname: `/documents/brokerageServices/document/${document.id}`
      }
    }
    history.push(nextLocation)
  }

  const handleRemove = () => {

  }

  return (
    <>
      <AccordionTable
        rows={
          isDesktop
            ? getRespDocuments.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            : getRespDocuments
        }
        columns={columns({handleRemove})}
        childrenColumns={childrenColumns}
        layout={layout}
        childrenLayout={childrenLayout}
        onRowClick={handleOpenRequest}
      />
      {isDesktop && (
        <Pagination
          total={getRespDocuments.length}
          current={page + 1}
          onChange={(page) => handleChangePage(page - 1)}
          pageSize={rowsPerPage}
        />
      )}
    </>
  )
}

export default DocumentsTable

import React, {
  useEffect, useMemo, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Media from 'react-media'
import sanitizeHtml from 'sanitize-html'
import classnames from 'classnames'
import { format } from 'date-fns'
import ruRU from 'date-fns/locale/ru'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CustomSelect from '../CustomControls/CustomSelect'
import CustomIconButton from '../CustomControls/CustomIconButton'
import useStyles from './panel.style'
import useConnect from '../../utils/Connect'
import formAction from '../../utils/FormAction'
import endpoints from '../../api/endpoints'
import {
  fetchTransferMoneyStartAsync,
  getTransferMoneySuccess,
  clearTransferMoney,
  fetchSaveTransferMoneyStartAsync,
  saveTransferMoneySuccess,
  fetchPerformTransferMoneyStartAsync,
  performTransferMoneySuccess,
  clearPartTransferMoney,
  fetchGetBalancesMoneyStartAsync,
  getBalancesMoneySuccess,
} from '../../redux/transfers/money/transferMoneySlice'
import { fetchMetadataStartAsync, getMetadataSuccess, clearMetadata } from '../../redux/metadata/metadataSlice'
import { selectCurrentMetadata } from '../../redux/metadata/selector'
import {
  selectBalancesMoney,
  selectGetTransferMoney,
  selectPerformTransferMoney,
  selectSaveTransferMoney,
} from '../../redux/transfers/money/selector'
import { selectCurrentError } from '../../redux/error/selector'
import { selectConnectionState } from '../../redux/connection/selector'
import { currentUserLoginAndId, selectCurrentThumbprint } from '../../redux/user/selector'
import CustomInput from '../CustomControls/CustomInput'
import CustomDatepicker from '../CustomControls/CustomDatepicker'
import Timer from '../../utils/Timer'
import { encodeToUTF8 } from '../../utils/EncodeToUTF8'
import CustomLoader from '../CustomLoader/CustomLoader'
import PageHeading from '../PageHeading/PageHeading'
import CustomModal from '../CustomModal/CustomModal'
import { ReactComponent as DownloadIcon } from '../../assets/images/icon-button-download.svg'
import { ReactComponent as ArrowBottomFull } from '../../assets/images/arrow-bottom-full.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import createUri from '../../utils/FilterUri'
import numberWithSpaces from '../../utils/Money'
import CustomButton from '../CustomControls/CustomButton'
import CustomInputSelect from '../CustomControls/CustomInputSelect'
import { addEmptyValueToArray } from '../../utils/Validate'
import getDoubleZero from '../../utils/getDoubleZero'
import useInput from '../../hooks/useInput'
import JsonToSHA256 from '../../utils/JsonToSHA256'

const transferType = 'Orders.Type.NonTrade.Money.Transfer'
const cancelType = 'Orders.Type.Modify.Cancellation'

export default function MoneyTransfer() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [clientPortfolio, setClientPortfolio] = useState('')
  const [partnerPortfolio, setPartnerPortfolio] = useState('')
  const [clientMoneyStorage, setClientMoneyStorage] = useState('')
  const [partnerMoneyStorage, setPartnerMoneyStorage] = useState('')
  const [arrayCurrencies, setArrayCurrencies] = useState('')
  const [currentCurrency, setCurrentCurrency] = useState('')
  const currentAmount = useInput('', { isOnlyNumbers: true })
  const [validity, setValidity] = useState(null)
  const [expirationDate, setExpirationDate] = useState(null)
  const [transferHeader, setTransferHeader] = useState('')
  const [commentary, setCommentary] = useState('')
  const [allDisabled, setAllDisabled] = useState(false)
  const [currentActions, setCurrentActions] = useState(null)
  const [showTimer, setShowTimer] = useState(false)
  const [code, setCode] = useState('')
  const [secretPassPhrase, setSecretPassPhrase] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [codeTextError, setCodeTextError] = useState('')
  const [currentStatus, setCurrentStatus] = useState('')
  const [submitAction, setSubmitAction] = useState('')
  const urlParams = useParams()
  const location = useLocation()
  const transferId = useMemo(() => urlParams.id, [urlParams])
  const transferUid = useMemo(() => location?.state?.orderUid, [location])
  const [metadataUid, setMetadataUid] = useState(transferUid || transferType)
  const [formDisabled, setFormDisabled] = useState(false)

  const [arrayMinusPlatform, setArrayMinusPlatform] = useState('')
  const [arrayPlusPlatform, setArrayPlusPlatform] = useState('')
  const [activeId, setActiveId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [updModalOpen, setUpdModalOpen] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [amountError, setAmountError] = useState(false)
  const [clientMoneyStorageError, setClientMoneyStorageError] = useState(false)
  const [expirationDateError, setExpirationDateError] = useState(false)
  const [partnerMoneyStorageError, setPartnerMoneyStorageError] = useState(false)
  const [partnerPortfolioError, setPartnerPortfolioError] = useState(false)
  const [clientPortfolioError, setClientPortfolioError] = useState(false)
  const [currencyError, setCurrencyError] = useState(false)
  const [amountTextError, setAmountTextError] = useState('')
  const [clientMoneyStorageTextError, setClientMoneyStorageTextError] = useState('')
  const [expirationDateTextError, setExpirationDateTextError] = useState('')
  const [partnerMoneyStorageTextError, setPartnerMoneyStorageTextError] = useState('')
  const [partnerPortfolioTextError, setPartnerPortfolioTextError] = useState('')
  const [clientPortfolioTextError, setClientPortfolioTextError] = useState('')
  const [currencyTextError, setCurrencyTextError] = useState('')
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [clientBalances, setClientBalances] = useState(null)
  const [partnerBalances, setPartnerBalances] = useState(null)
  const [isCleared, setIsCleared] = useState(false)
  const [canModifyDate, setCanModifyDate] = useState(true)
  const [hashSha256, setHashSha256] = useState(null)

  const fetchTransferMoneyStart = (id) => {
    dispatch(fetchTransferMoneyStartAsync({ id }))
  }

  const fetchSaveTransferMoneyStart = (data, id) => {
    dispatch(fetchSaveTransferMoneyStartAsync({ data, id }))
  }

  const fetchPerformTransferMoneyStart = (data, id) => {
    dispatch(fetchPerformTransferMoneyStartAsync({ data, id }))
  }

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchGetBalancesMoneyStart = (filterBody) => {
    dispatch(fetchGetBalancesMoneyStartAsync({ filterBody }))
  }

  const connectedWS = useSelector(selectConnectionState)
  const getRespTransferMoney = useSelector(selectGetTransferMoney)
  const getRespMetadata = useSelector(selectCurrentMetadata)
  const getSaveTransferMoney = useSelector(selectSaveTransferMoney)
  const getPerformTransferMoney = useSelector(selectPerformTransferMoney)
  const getBalances = useSelector(selectBalancesMoney)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)

  const currentStatusDescription = getRespTransferMoney?.order?.statusDescription
    && sanitizeHtml(getRespTransferMoney?.order?.statusDescription)

  const { getData } = useConnect({
    link: `GET /api${endpoints.moneys.getTransferMoney(activeId)}`,
    startFetching: fetchTransferMoneyStart,
    action: getTransferMoneySuccess,
  })
  const { getData: getMetaData } = useConnect({
    link: `GET /api${endpoints.metadata.getMetadata(metadataUid)}`,
    startFetching: fetchMetadataStart,
    action: getMetadataSuccess,
  })
  const { getData: postSaveData } = useConnect({
    link: `POST /api${endpoints.moneys.postTransferMoney(false)}`,
    startFetching: fetchSaveTransferMoneyStart,
    action: saveTransferMoneySuccess,
  })
  const { getData: putSaveData } = useConnect({
    link: `PUT /api${endpoints.moneys.putTransferMoney(null, activeId, false)}`,
    startFetching: fetchSaveTransferMoneyStart,
    action: saveTransferMoneySuccess,
  })
  const { getData: postPerformData } = useConnect({
    link: `POST /api${endpoints.moneys.postTransferMoney(false)}`,
    startFetching: fetchPerformTransferMoneyStart,
    action: performTransferMoneySuccess,
  })
  const { getData: putPerformData } = useConnect({
    link: `PUT /api${endpoints.moneys.putTransferMoney(null, activeId, false)}`,
    startFetching: fetchPerformTransferMoneyStart,
    action: performTransferMoneySuccess,
  })
  useConnect({
    link: `GET /api${endpoints.assets.getBalancesMoney()}`,
    startFetching: fetchGetBalancesMoneyStart,
    action: getBalancesMoneySuccess,
  })

  const clearFields = (needClearData = true) => {
    if (needClearData) dispatch(clearMetadata())
    dispatch(clearTransferMoney())
    setClientPortfolio('')
    setPartnerPortfolio('')
    setClientMoneyStorage('')
    setArrayMinusPlatform('')
    setPartnerMoneyStorage('')
    setArrayPlusPlatform('')
    setTransferHeader('')
    setCommentary('')
    setArrayCurrencies('')
    setCurrentCurrency('')
    currentAmount.setValue('')
    setExpirationDate('')
    setValidity(new Date())
    setAllDisabled(false)
    setCurrentActions(null)
    setAmountError(false)
    setClientMoneyStorageError(false)
    setClientPortfolioError(false)
    setExpirationDateError(false)
    setPartnerMoneyStorageError(false)
    setPartnerPortfolioError(false)
    setCurrencyError(false)
    setCodeError(false)
    setClientBalances(null)
    setIsCleared(true)
    setCode('')
    setSecretPassPhrase('')
  }

  const fillFields = (order, portfolios) => {
    if (Object.keys(portfolios)?.length) {
      const licenseMinus = portfolios?.find((item) => item.name === order?.clientPortfolio?.name)
      setArrayMinusPlatform(licenseMinus?.moneyStorages)
      setArrayCurrencies(licenseMinus?.currencies)
      const licensePlus = portfolios?.find((item) => item.name === order?.partnerPortfolio?.name)
      setArrayPlusPlatform(licensePlus?.moneyStorages)
    } else {
      setArrayMinusPlatform([order?.partnerMoneyStorage])
      setArrayPlusPlatform([order?.clientMoneyStorage])
      setArrayCurrencies([{ uid: order?.currency?.uid }])
    }
    setClientPortfolio(order?.clientPortfolio?.name)
    setPartnerPortfolio(order?.partnerPortfolio?.name)
    setClientMoneyStorage(order?.clientMoneyStorage?.description)
    setPartnerMoneyStorage(order?.partnerMoneyStorage?.description)
    setCurrentCurrency(order?.currency?.uid)
    currentAmount.setValue(order?.amount)
    setCommentary(order?.comment)
    setExpirationDate(getRespTransferMoney?.order?.expirationDate)
    setValidity(getRespTransferMoney?.order?.expirationDate)
    // eslint-disable-next-line no-underscore-dangle
    if (!order?._links[0]?.canModify) {
      setAllDisabled(true)
      setCanModifyDate(false)
    }
  }

  const handleBackToHistory = () => {
    history.push('/transfers/money/log')
  }

  useEffect(() => {
    if (connectedWS) {
      clearFields()
      setActiveId(null)
      if (transferId === 'new') {
        setMetadataUid(transferType)
        getMetaData(transferType)
        setIsLoading(false)
        clearFields()
      } else if (transferId) {
        getMetaData(metadataUid)
        getData(transferId)
        setActiveId(transferId)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferId, connectedWS])

  useEffect(() => () => {
    clearFields()
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  useEffect(() => {
    if (isCleared) {
      if (getRespTransferMoney?.order) {
        setIsLoading(false)
        setCurrentStatus(getRespTransferMoney?.order?.status)
        let order = null
        if (getRespTransferMoney?.order?.type?.uid === transferType) {
          order = getRespTransferMoney?.order
          if (getRespMetadata?.references && Object.keys(getRespMetadata.references).length) {
            const portfolios = getRespMetadata?.references?.portfolios
            fillFields(order, portfolios)
          }
          if (getRespMetadata?.order?.type?.uid === cancelType) {
            const cancelDate = getRespMetadata?.order?.expirationDate
            const reqObj = {
              type: { uid: cancelType },
              expirationDate: cancelDate && format(new Date(cancelDate), 'yyyy-MM-dd'),
              affectedOrder: { id: +activeId },
              secret: '',
            }

            const date = getRespMetadata?.order?.date
            const number = getRespMetadata?.order?.number

            reqObj.date = date
            reqObj.number = number

            const WordArrayToBase64 = JsonToSHA256(reqObj)
            if (WordArrayToBase64) {
              reqObj.secret = WordArrayToBase64
              setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
            }

            postPerformData(reqObj)
          }
        } else if (getRespTransferMoney?.order?.type?.uid === cancelType) {
          order = getRespTransferMoney?.order?.affectedOrder
          fillFields(order, [])
          // eslint-disable-next-line no-underscore-dangle
          if (getRespTransferMoney?.order?._links[0]?.canModify) {
            setCanModifyDate(true)
          }
        }
        // eslint-disable-next-line no-underscore-dangle
        setCurrentActions(getRespTransferMoney?.order?._links[0])
        setTransferHeader(`${getRespTransferMoney?.order?.description}`)
      } else if (transferId !== 'new') {
        setIsLoading(true)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRespTransferMoney, getRespMetadata, isCleared])

  useEffect(() => {
    if (connectedWS && isCleared) {
      if (getSaveTransferMoney?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveTransferMoney?.order?.id)
        }
        if (getSaveTransferMoney?.requiredAction?.type === 'code' || getSaveTransferMoney?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
      }
      if (getPerformTransferMoney?.requiredAction) {
        if (!activeId || +activeId !== getPerformTransferMoney?.order?.id) {
          setActiveId(getPerformTransferMoney?.order?.id)
        }
        if (getPerformTransferMoney?.requiredAction?.type === 'code' || getSaveTransferMoney?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }

        setCurrentStatus(getPerformTransferMoney?.order?.status)
      }
      const msgData = getSaveTransferMoney || getPerformTransferMoney
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespTransferMoney?.order?.type?.uid === cancelType) ? cancelType : transferType
      if (msgData?.order?.description) {
        setTransferHeader(`${msgData?.order?.description}`)
      }
      if (typeUID) {
        setIsLoading(false)
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(msgData?.order?._links[0])
      if (msgData?.order?.type?.uid === cancelType) {
        if (msgData?.order?.type?.uid === cancelType
          && getRespTransferMoney?.order?.type?.uid !== cancelType
        ) {
          const cancelDate = getRespMetadata?.order?.expirationDate
          setExpirationDate(cancelDate)
          setValidity(cancelDate)
        }
      }
      if (getSaveTransferMoney?.status === 200 || getPerformTransferMoney?.status === 200) {
        switch (getSaveTransferMoney?.order?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (activeId) {
              setUpdModalOpen(true)
            } else {
              setSaveModalOpen(true)
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaveTransferMoney, getPerformTransferMoney, isCleared])

  useEffect(() => {
    if (serverError) {
      if (serverError?.errors?.Amount?.length) {
        setAmountError(true)
        setAmountTextError(serverError?.errors?.Amount[0])
      }
      if (serverError?.errors?.ClientMoneyStorage?.length) {
        setClientMoneyStorageError(true)
        setClientMoneyStorageTextError(serverError?.errors?.ClientMoneyStorage[0])
      }
      if (serverError?.errors?.ClientPortfolio?.length) {
        setClientPortfolioError(true)
        setClientPortfolioTextError(serverError?.errors?.ClientPortfolio[0])
      }
      if (serverError?.errors?.ExpirationDate?.length) {
        setExpirationDateError(true)
        setExpirationDateTextError(serverError?.errors?.ExpirationDate[0])
      }
      if (serverError?.errors?.PartnerMoneyStorage?.length) {
        setPartnerMoneyStorageError(true)
        setPartnerMoneyStorageTextError(serverError?.errors?.PartnerMoneyStorage[0])
      }
      if (serverError?.errors?.PartnerPortfolio?.length) {
        setPartnerPortfolioError(true)
        setPartnerPortfolioTextError(serverError?.errors?.PartnerPortfolio[0])
      }
      if (serverError?.errors?.Currency?.length) {
        setCurrencyError(true)
        setCurrencyTextError(serverError?.errors?.Currency[0])
      }
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeTextError(serverError?.errors?.Secret[0])
      }
      const msgData = getSaveTransferMoney || getPerformTransferMoney
      if ((!currentActions || currentActions?.canModify) && !msgData?.requiredAction?.type) {
        setAllDisabled(false)
      }
      setIsLoading(false)
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
      setIsLoading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError])

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const listMainFields = () => {
    let licenseMinus = null
    let licensePlus = null
    let moneyStorageMinus = null
    let moneyStoragePlus = null
    if (transferUid === cancelType) {
      licenseMinus = getRespTransferMoney?.order?.affectedOrder?.clientPortfolio
      licensePlus = getRespTransferMoney?.order?.affectedOrder?.partnerPortfolio
      const [clientMoneyStorageObj] = arrayMinusPlatform
      const [partnerMoneyStorageObj] = arrayPlusPlatform
      moneyStorageMinus = clientMoneyStorageObj
      moneyStoragePlus = partnerMoneyStorageObj
    } else {
      licenseMinus = getRespMetadata?.references?.portfolios?.find(
        (item) => item.name === clientPortfolio,
      )
      licensePlus = getRespMetadata?.references?.portfolios?.find(
        (item) => item.name === partnerPortfolio,
      )
      moneyStorageMinus = licenseMinus?.moneyStorages?.find(
        (item) => item.description === clientMoneyStorage,
      )
      moneyStoragePlus = licensePlus?.moneyStorages?.find(
        (item) => item.description === partnerMoneyStorage,
      )
    }
    const obj = {
      clientPortfolio: licenseMinus,
      partnerPortfolio: licensePlus,
      clientMoneyStorage: moneyStorageMinus,
      partnerMoneyStorage: moneyStoragePlus,
    }
    return obj
  }

  useEffect(() => {
    if (getBalances) {
      const mainFields = listMainFields()
      const hasClientPortfolioId = getBalances?.portfolio?.id === mainFields?.clientPortfolio?.id
      const hasPartnerPortfolioId = getBalances?.portfolio?.id === mainFields?.partnerPortfolio?.id
      const hasStorageUid = getBalances?.storage?.uid

      if (!getBalances?.portfolio) return
      if (hasClientPortfolioId && (
        hasStorageUid ? mainFields?.clientMoneyStorage?.uid === getBalances?.storage?.uid
          : !mainFields?.clientMoneyStorage?.uid)) {
        setClientBalances(getBalances)
      }

      if (hasPartnerPortfolioId && (
        hasStorageUid ? mainFields?.partnerMoneyStorage?.uid === getBalances?.storage?.uid
          : !mainFields?.partnerMoneyStorage?.uid)) {
        setPartnerBalances(getBalances)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBalances])

  const fetchBalancesMoney = (filterBody, setBalances) => {
    if (filterBody) {
      fetchGetBalancesMoneyStart(filterBody.replaceAll('+', ''))
    }
    setBalances(null)
  }

  useMemo(() => {
    const objMainFields = listMainFields()
    let filterBodyClient
    if (clientPortfolio || clientMoneyStorage) {
      filterBodyClient = createUri({
        name: 'balancesMoney',
        portfolioId: objMainFields?.clientPortfolio?.id,
        currencyUid: currentCurrency,
        storageUid: objMainFields?.clientMoneyStorage?.uid,
        condition: '&',
      })
      fetchBalancesMoney(filterBodyClient, setClientBalances)
    }
    setClientBalances(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientPortfolio, clientMoneyStorage, currentCurrency])

  useEffect(() => {
    const objMainFields = listMainFields()
    let filterBodyPartner
    if (partnerPortfolio || partnerMoneyStorage) {
      filterBodyPartner = createUri({
        name: 'balancesMoney',
        portfolioId: objMainFields?.partnerPortfolio?.id,
        currencyUid: currentCurrency,
        storageUid: objMainFields?.partnerMoneyStorage?.uid,
        condition: '&',
      })
      fetchBalancesMoney(filterBodyPartner, setPartnerBalances)
    }
    setPartnerBalances(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerPortfolio, partnerMoneyStorage, currentCurrency])

  const addNeedsProps = (typeUID, msgData) => {
    const mainFields = listMainFields()
    let reqObj = {}
    if (typeUID === cancelType) {
      reqObj = {
        type: { uid: typeUID },
        expirationDate: format(new Date(validity), 'yyyy-MM-dd'),
        affectedOrder: {
          id: msgData?.order?.affectedOrder?.id
          || getRespTransferMoney?.order?.affectedOrder?.id,
        },
        secret: '',
      }
      return reqObj
    }
    reqObj = {
      type: { uid: typeUID },
      dateTime: format(new Date(), 'yyyy-MM-dd'),
      expirationDate: format(new Date(validity), 'yyyy-MM-dd'),
      clientPortfolio: { id: mainFields.clientPortfolio?.id },
      clientMoneyStorage: { uid: mainFields.clientMoneyStorage?.uid },
      partnerPortfolio: { id: mainFields.partnerPortfolio?.id },
      partnerMoneyStorage: { uid: mainFields.partnerMoneyStorage?.uid },
      comment: commentary,
      currency: { uid: currentCurrency },
      amount: +currentAmount.value,
      secret: '',
    }
    return reqObj
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const msgData = getSaveTransferMoney || getPerformTransferMoney
    const typeUID = msgData?.requiredAction && msgData?.order?.type?.uid === cancelType
      ? cancelType : transferType
    const requestObj = addNeedsProps(typeUID, msgData)

    const date = getRespMetadata?.order?.date
    const number = getRespMetadata?.order?.number

    requestObj.date = date
    requestObj.number = number

    const WordArrayToBase64 = JsonToSHA256(requestObj)
    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID,
      putPerformData,
      postPerformData,
      activeId,
      secretPassPhrase,
    })
  }

  const handleChangePortfolioMinus = (event, selectedValue) => {
    setClientPortfolio(selectedValue)
    setClientMoneyStorage('')
    const currentObj = getRespMetadata?.references?.portfolios?.find(
      (elm) => elm.name === selectedValue,
    )
    if (currentObj?.moneyStorages?.length) {
      setArrayMinusPlatform(currentObj?.moneyStorages)
    } else {
      setArrayMinusPlatform('')
    }
    setArrayCurrencies(currentObj?.currencies)
    setClientPortfolioError(false)
  }

  const handleChangePortfolioPlus = (event, selectedValue) => {
    setPartnerPortfolio(selectedValue)
    setPartnerMoneyStorage('')
    const currentObj = getRespMetadata?.references?.portfolios?.find(
      (elm) => elm.name === selectedValue,
    )
    if (currentObj?.moneyStorages?.length) {
      setArrayPlusPlatform(currentObj.moneyStorages)
    } else {
      setArrayPlusPlatform('')
    }
    setClientMoneyStorageError(false)
  }

  const handleChangePlatformMinus = (event) => {
    setClientMoneyStorage(event.target.value)
    setClientMoneyStorageError(false)
    setClientMoneyStorageTextError('')
  }

  const handleChangePlatformPlus = (event) => {
    setPartnerMoneyStorage(event.target.value)
    setPartnerMoneyStorageError(false)
    setPartnerMoneyStorageTextError('')
  }

  const handleChangeCurrency = (event) => {
    setCurrentCurrency(event.target.value)
    setCurrencyError(false)
  }

  const handleChangeValidity = (date) => {
    setValidity(date)
    setExpirationDateError(false)
  }

  const handleChangeCommentary = (event) => {
    setCommentary(event.target.value)
  }

  const handlePerformTransfer = (event) => {
    event.preventDefault()
    if (connectedWS) {
      setFormDisabled(true)
      setIsLoading(true)

      if (submitAction === 'perform') {
        setAllDisabled(true)
      }
      const msgData = getSaveTransferMoney || getPerformTransferMoney
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespTransferMoney?.order?.type?.uid === cancelType) ? cancelType : transferType
      const reqObj = addNeedsProps(typeUID, msgData)

      const date = getRespMetadata?.order?.date
      const number = getRespMetadata?.order?.number

      reqObj.date = date
      reqObj.number = number

      const WordArrayToBase64 = JsonToSHA256(reqObj)
      if (WordArrayToBase64) {
        reqObj.secret = WordArrayToBase64
        setHashSha256(`Контрольная сумма: ${WordArrayToBase64}`)
      }

      formAction({
        submitAction,
        requestObj: reqObj,
        typeUID,
        msgData,
        putPerformData,
        postPerformData,
        putSaveData,
        postSaveData,
        activeId,
        code,
        setCodeError,
        setCodeTextError,
        setCancellationModalOpen,
        setFormDisabled,
        setIsLoading,
        currentThumbPrint,
        secretPassPhrase,
      })
    }
  }

  const handleCancelOperation = (event) => {
    if (getRespMetadata?.order?.type?.uid === cancelType) { handleBackToHistory() } else {
      event.preventDefault()
      dispatch(clearPartTransferMoney())
      setAllDisabled(false)
      setFormDisabled(false)
    }
  }

  const handleDownloadXml = () => {
    const msgData = getSaveTransferMoney || getPerformTransferMoney
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.order?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const createMarkup = () => (
    { __html: currentStatusDescription }
  )

  const getHandler = (handler) => (!allDisabled ? handler : null)

  const handleCloseSaveModal = (goToHistory) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseUpdModal = () => {
    setUpdModalOpen(false)
    handleBackToHistory()
  }

  const handleClosePerformModal = (goToHistory) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
    setIsLoading(false)
  }

  const handleCancelOk = () => {
    setMetadataUid(cancelType)
    getMetaData(cancelType)
    handleCloseCancellationModal()
  }

  const handleActionReject = () => {
    handleCloseSaveModal(true)
    handleClosePerformModal(true)
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)
    clearFields(false)
    setActiveId(null)
    setFormDisabled(false)
    setMetadataUid(transferType)
    history.replace('/transfers/money/transfer/new')
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Box maxWidth={898}>
        <PageHeading text="Перевод" />
        {isLoading && (
          <Box mt="79px">
            <CustomLoader />
          </Box>
        )}
        {!isLoading && (
          <Box className={classnames(classes.wrapper, classes.wrapper_unique)}>
            <form
              className={classes.form}
              onSubmit={handlePerformTransfer}
            >
              {transferHeader && (
                <Typography className={classes.transferHeader}>
                  { transferHeader }
                </Typography>
              )}
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomInputSelect
                    label="Портфель (списания)"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={clientPortfolio || ''}
                    options={
                      addEmptyValueToArray(
                        getRespMetadata?.references?.portfolios?.map((option) => option.name)
                        || getRespTransferMoney?.order?.affectedOrder?.clientPortfolio?.name,
                      )
                    }
                    error={clientPortfolioError}
                    errorText={clientPortfolioTextError}
                    onChange={getHandler(handleChangePortfolioMinus)}
                    disabled={allDisabled}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomSelect
                    label="Площадка (списания)"
                    value={clientMoneyStorage || ''}
                    onChange={getHandler(handleChangePlatformMinus)}
                    error={clientMoneyStorageError}
                    errorText={clientMoneyStorageTextError}
                    unique
                  >
                    {(!!arrayMinusPlatform?.length && arrayMinusPlatform.map((item) => (
                      <MenuItem
                        value={item?.description}
                        key={item?.uid}
                      >
                        {item?.description}
                      </MenuItem>
                    ))) || (
                      <MenuItem
                        value=""
                      />
                    )}
                  </CustomSelect>
                </Grid>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <div className={classes.amountInfo}>
                    <p className={classes.amountInfo__text}>
                      Доступно на
                      {' '}
                      {`${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
                    </p>
                    <p className={classes.amountInfo__value}>
                      {((clientBalances && clientBalances.balanceT0 >= 0) && `${numberWithSpaces(getDoubleZero(clientBalances.balanceT0))} ${clientBalances.currency?.uid}`) || ('...')}
                    </p>
                  </div>
                </Grid>
                <Media
                  query={`(max-width: ${breakpoints.phone_large}px`}
                  render={() => (
                    <Grid
                      item
                      xs={12}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                      >
                        <ArrowBottomFull />
                      </Box>
                    </Grid>
                  )}
                />
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomInputSelect
                    label="Портфель (зачисления)"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={partnerPortfolio || ''}
                    options={
                      addEmptyValueToArray(
                        getRespMetadata?.references?.portfolios?.map((option) => option.name)
                        || getRespTransferMoney?.order?.affectedOrder?.partnerPortfolio?.name,
                      )
                    }
                    onChange={getHandler(handleChangePortfolioPlus)}
                    errorText={partnerPortfolioTextError}
                    error={partnerPortfolioError}
                    disabled={allDisabled}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <CustomSelect
                    label="Площадка (зачисления)"
                    value={partnerMoneyStorage || ''}
                    onChange={getHandler(handleChangePlatformPlus)}
                    error={partnerMoneyStorageError}
                    errorText={partnerMoneyStorageTextError}
                    unique
                  >
                    {(!!arrayPlusPlatform?.length && arrayPlusPlatform.map((item) => (
                      <MenuItem
                        value={item?.description}
                        key={item?.uid}
                      >
                        {item?.description}
                      </MenuItem>
                    ))) || (
                      <MenuItem
                        value=""
                      />
                    )}
                  </CustomSelect>
                </Grid>
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                >
                  <div className={classes.amountInfo}>
                    <p className={classes.amountInfo__text}>
                      {`Баланс на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
                    </p>
                    <p className={classes.amountInfo__value}>
                      {((partnerBalances && partnerBalances.balanceT0 >= 0) && `${numberWithSpaces(getDoubleZero(partnerBalances.balanceT0))} ${partnerBalances.currency?.uid}`) || ('...')}
                    </p>
                  </div>
                </Grid>
                {((clientPortfolio && partnerPortfolio
                && clientMoneyStorage && partnerMoneyStorage) && (
                  <>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomSelect
                        label="Валюта"
                        value={currentCurrency || ''}
                        onChange={getHandler(handleChangeCurrency)}
                        error={currencyError}
                        errorText={currencyTextError}
                        unique
                      >
                        {(!!arrayCurrencies?.length && arrayCurrencies.map((item) => (
                          <MenuItem
                            value={item?.uid}
                            key={item?.id}
                          >
                            {item?.uid}
                          </MenuItem>
                        ))) || (
                          <MenuItem
                            value=""
                          />
                        )}
                      </CustomSelect>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomInput
                        error={amountError}
                        errorText={amountTextError}
                        variant="outlined"
                        color="secondary"
                        label="Сумма"
                        value={currentAmount.value || ''}
                        onChange={(e) => {
                          currentAmount.onChange(e)
                          setAmountError(false)
                        }}
                        unique
                        disabled={allDisabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      sm={6}
                      xs={12}
                    >
                      <CustomDatepicker
                        label="Срок действия поручения до"
                        error={expirationDateError}
                        errorText={expirationDateTextError}
                        value={validity || ''}
                        onChange={handleChangeValidity}
                        unique
                        minDate={expirationDate || new Date()}
                        maxDate={allDisabled && !canModifyDate ? validity : undefined}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      sm={12}
                      xs={12}
                    >
                      <CustomInput
                        variant="outlined"
                        color="secondary"
                        label="Комментарий"
                        value={commentary || ''}
                        onChange={handleChangeCommentary}
                        unique
                        disabled={allDisabled}
                      />
                    </Grid>
                  </>
                )) || null}
                {(getSaveTransferMoney?.requiredAction?.type === 'code' || getPerformTransferMoney?.requiredAction?.type === 'code' || getSaveTransferMoney?.requiredAction?.type === 'mac' || getPerformTransferMoney?.requiredAction?.type === 'mac') && (
                <>
                  <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                  >
                    <CustomInput
                      error={codeError}
                      errorText={codeTextError}
                      variant="outlined"
                      label="Введите код из сообщения"
                      name="code"
                      value={code}
                      onChange={(event) => handleChangeCodeInput(event)}
                      size="small"
                      color="secondary"
                      unique
                      className={classes.inputField}
                      autoComplete="off"
                      helperText={(getPerformTransferMoney?.requiredAction?.type === 'mac' || getSaveTransferMoney?.requiredAction?.type === 'mac') ? hashSha256 : ''}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    sm={6}
                    xs={12}
                    className={classes.Transfer__codeWrapper}
                  >
                    {(showTimer && (
                    <Typography
                      className={classes.Transfer__codeText}
                      m={0}
                    >
                      Если код не пришел, Вы можете запросить новый код через
                      <span
                        className={classes.Transfer__codeInput}
                      >
                        <Timer
                          seconds={30}
                          handleStartTimer={handleStartTimer}
                        />
                      </span>
                    </Typography>
                    )) || (!showTimer && (
                    <CustomButton
                      label="Выслать новый код"
                      modifier="primary"
                      type="submit"
                      fullWidth
                      onClick={handleResetTimer}
                    />
                    ))}
                  </Grid>
                </>
                )}
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xs={12}
                  className={classes.TransferStatus}
                >
                  {getRespTransferMoney?.order && (
                    <div className={(!!currentStatusDescription && classes.TransferStatus__wrap) || ''}>
                      <Typography
                        className={classes.TransferStatus__name}
                      >
                        Статус:
                        {' '}
                        {currentStatus?.name}
                      </Typography>
                      {currentStatusDescription && (
                        <Typography
                          className={classes.TransferStatus__description}
                          dangerouslySetInnerHTML={createMarkup()}
                        />
                      )}
                    </div>
                  )}
                </Grid>
                {(((currentActions && currentActions?.canModify) || !currentActions)
                  && (!getSaveTransferMoney?.requiredAction?.type
                    && !getPerformTransferMoney?.requiredAction?.type
                  ) && (
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                    className={classes.btnGroup}
                  >
                    <CustomIconButton
                      type="submit"
                      onClick={() => { setSubmitAction('perform') }}
                      disabled={formDisabled}
                    >
                      Исполнить
                    </CustomIconButton>
                    <CustomIconButton
                      type="submit"
                      onClick={() => { setSubmitAction('save') }}
                      disabled={formDisabled}
                    >
                      Сохранить
                    </CustomIconButton>
                    <Media
                      query="(min-width: 577px)"
                      render={() => (
                        <CustomIconButton
                          type="button"
                          onClick={handleBackToHistory}
                        >
                          Назад
                        </CustomIconButton>
                      )}
                    />
                  </Grid>
                )) || ((getSaveTransferMoney?.requiredAction?.type === 'sign' || getPerformTransferMoney?.requiredAction?.type === 'sign') && (
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                    className={classes.btnGroup}
                  >
                    <CustomIconButton
                      onClick={() => { setSubmitAction('perform') }}
                      type="submit"
                    >
                      Подписать
                    </CustomIconButton>
                    <CustomIconButton
                      type="button"
                      icon={<DownloadIcon />}
                      onClick={handleDownloadXml}
                    >
                      Скачать .xml
                    </CustomIconButton>
                    <Media
                      query="(min-width: 577px)"
                      render={() => (
                        <CustomIconButton
                          type="button"
                          onClick={handleCancelOperation}
                        >
                          Назад
                        </CustomIconButton>
                      )}
                    />
                  </Grid>
                )) || ((getSaveTransferMoney?.requiredAction?.type === 'code' || getPerformTransferMoney?.requiredAction?.type === 'code' || getSaveTransferMoney?.requiredAction?.type === 'mac' || getPerformTransferMoney?.requiredAction?.type === 'mac') && (
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                    className={classes.btnGroup}
                  >
                    <CustomIconButton
                      type="submit"
                      onClick={() => { setSubmitAction('perform') }}
                    >
                      Подтвердить
                    </CustomIconButton>
                    <Media
                      query="(min-width: 577px)"
                      render={() => (
                        <CustomIconButton
                          type="button"
                          onClick={handleCancelOperation}
                        >
                          Отменить
                        </CustomIconButton>
                      )}
                    />
                  </Grid>
                )) || ((currentActions && currentActions?.canCancel) && (
                <Grid
                  item
                  lg={6}
                  sm={6}
                  xs={12}
                  className={classes.btnGroup}
                >
                  <CustomIconButton
                    onClick={() => setCancellationModalOpen(true)}
                  >
                    Отменить поручение
                  </CustomIconButton>
                  <Media
                    query="(min-width: 577px)"
                    render={() => (
                      <CustomIconButton
                        type="button"
                        onClick={handleBackToHistory}
                      >
                        Назад
                      </CustomIconButton>
                    )}
                  />
                </Grid>
                ))
                || ((currentActions && !currentActions?.canModify && !currentActions?.canCancel)
                  && (
                    <Media
                      query="(min-width: 577px)"
                      render={() => (
                        <Grid
                          item
                          lg={6}
                          sm={6}
                          xs={12}
                          className={classes.btnGroup}
                        >
                          <CustomIconButton
                            type="button"
                            onClick={handleBackToHistory}
                          >
                            Назад
                          </CustomIconButton>
                        </Grid>
                      )}
                    />
                  ))}
              </Grid>
            </form>
          </Box>
        )}
        <CustomModal
          open={saveModalOpen}
          title="Поручение успешно создано."
          handleClose={handleCloseSaveModal}
          actionText="Хотите создать новое?"
          actionReject={handleActionReject}
          actionSuccess={handleActionSuccess}
          disableOverlay
        />
        <CustomModal
          open={updModalOpen}
          title="Поручение обновлено"
          handleClose={handleCloseUpdModal}
        />
        <CustomModal
          open={performModalOpen}
          title="Поручение успешно подписано"
          handleClose={handleClosePerformModal}
          actionText="Хотите создать новое?"
          actionReject={handleActionReject}
          actionSuccess={handleActionSuccess}
          disableOverlay
        />
        <CustomModal
          open={cancellationModalOpen}
          title="Создать поручение на отмену"
          handleClose={handleCloseCancellationModal}
          callbacks={{
            withOk: handleCancelOk,
            withCancel: () => {
              handleCloseCancellationModal()
            },
          }}
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}

import useMedia from 'hooks/useMedia'
import React, { FC, memo } from 'react'
import Accordion from 'ui/components/molecules/Accordion'
import { AccordionProps } from 'ui/components/molecules/Accordion/type'

const CustomerBlocksAccordion: FC<AccordionProps> = (props) => {
  const { isMobile } = useMedia()

  return (
    <Accordion
      variant="plain"
      bodyPaddingTop={isMobile ? 28 : 24}
      {...(!isMobile && { padding: '32px' })}
      {...props}
    />
  )
}

export default memo(CustomerBlocksAccordion)

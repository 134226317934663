import { styled } from 'ui/theme'
import { IButtonProps } from './types'

export const StyledButton = styled.button<IButtonProps>`
  ${({ width }) => width && `width: ${width}`};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme, padding }) => padding || `8.5px ${theme.spacing.lg}`};
  cursor: pointer;
  ${({ theme }) => theme.font.body1_lg};
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: center;
  transition: color 0.2s ease-in, background-color 0.2s ease-in, border-color 0.2s ease-in;

  &:disabled {
    opacity: 0.6;
  }

  ${({ variant = 'primary', fullWidth, theme }) => `
    ${
      variant === 'primary'
        ? `
        background-color: ${theme.color.button.primary};
        color: ${theme.color.text.inverted};
        border: 1px solid ${theme.color.button.primary};
        &:hover {
          background-color: ${theme.color.bg.funBlue};
        }
      `
        : ''
    }
    ${
      variant === 'secondary'
        ? `
        background-color: transparent;
        border: 1px solid ${theme.color.button.primary};
        color: ${theme.color.button.primary};
        border: 1px solid ${theme.color.button.primary};

        &:hover {
          background-color: ${theme.color.bg.secondary};
        }
      `
        : ''
    }
    ${
      variant === 'link'
        ? `
      color: ${theme.color.button.primary};
      padding: 0;
      display: inline-block;
      font-size: inherit;
      background-color: transparent;
      border:0;
      `
        : ''
    }
    ${
      variant === 'tertiary'
        ? `
      color: ${theme.color.text.primary};
      border: 1px solid ${theme.color.border.default};
      background-color: transparent;

      &:hover {
        color: ${theme.color.text.accent};
        border-color: ${theme.color.text.accent};
      }

      `
        : ''
    }
    ${
      variant === 'header'
        ? `
      color: ${theme.color.graphics.primary};
      border: 0;
      background-color: transparent;
      width: 40px;
      height: 40px;
      padding: 0;
      background-color: ${theme.color.bg.secondary};
      `
        : ''
    }
    ${
      variant === 'sidebar'
        ? `
      color: ${theme.color.graphics.secondary};
      border: 0;
      background-color: transparent;
      width: 40px;
      height: 40px;
      padding: 0;
      background-color: ${theme.color.graphics.inverted};
      `
        : ''
    }
    ${
      variant === 'negative'
        ? `
      background-color: ${theme.color.text.negative};
      color: ${theme.color.text.inverted};
      border: 1px solid ${theme.color.text.negative};
      `
        : ''
    }
    ${
      variant === 'negative-outline'
        ? `
      background-color: transparent;
      color: ${theme.color.text.negative};
      border: 1px solid ${theme.color.text.negative};
      `
        : ''
    }
    ${
      variant === 'plain'
        ? `
      background: none;
      padding: 0;
      border-radius: 0;
      border: 0;
      `
        : ''
    }

      ${
        fullWidth
          ? `
      width: 100%;
      `
          : ''
      }
  `}
  svg {
    display: block;
  }
`

import { createSelector } from '@reduxjs/toolkit'
import {selectSaveOutputMoneyStatus} from "../transfers/money/output/selector";

const selectSecurities = (state) => state.securities

export const selectCurrentSecurities = createSelector(
  [selectSecurities],
  (state) => state.securities?.data,
)

export const selectCurrentGetSecurity = createSelector(
  [selectSecurities],
  (state) => state.security?.data,
)

export const selectCurrentSaveSecurity = createSelector(
  [selectSecurities],
  (state) => state.saveSecurity?.data,
)
export const selectCurrentSaveSecurityStatus = createSelector(
  [selectSecurities],
  (state) => state.saveSecurity?.status,
)

export const selectCurrentPerformSecurity = createSelector(
  [selectSecurities],
  (state) => state.performSecurity?.data,
)
export const selectCurrentPerformSecurityStatus = createSelector(
  [selectSecurities],
  (state) => state.performSecurity?.status,
)

export const selectCurrentDeleteSecurity = createSelector(
  [selectSecurities],
  (state) => state.deleteSecurity,
)

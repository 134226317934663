import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '@material-ui/core/Button'
import useStyles from './CustomControls.style'

const CustomButton = ({ label, ...props }) => {
  const classes = useStyles()
  const { modifier } = props

  return (
    <Button
      className={classNames(classes.button, classes[modifier])}
      {...props}
    >
      {label}
    </Button>
  )
}

CustomButton.propTypes = {
  label: PropTypes.string.isRequired,
  modifier: PropTypes.string.isRequired,
}

export default CustomButton

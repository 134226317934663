import { ROUTES } from 'app/data'
import React, { memo } from 'react'
import { NavLink, useLocation, Redirect } from 'react-router-dom'
import { Tab } from 'ui/components'
import Beneficiares from './Beneficiares'
import { profileAdditionalInfoTabs } from './data'
import { StyledAdditionalInfoTabs } from './styled'

const AdditionalInfo = () => {
  const { pathname } = useLocation()

  if (pathname === ROUTES.profileAdditionalInfo) return <Redirect to={ROUTES.profileConfidant} />

  return (
    <div>
      <StyledAdditionalInfoTabs variant={2}>
        {profileAdditionalInfoTabs.map(({ label, value }) => (
          <Tab component={NavLink} label={label} to={value} />
        ))}
      </StyledAdditionalInfoTabs>

      {pathname === ROUTES.profileBeneficiaries && <Beneficiares />}
    </div>
  )
}

export default memo(AdditionalInfo)

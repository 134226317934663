import React from 'react'
import { ThemeProvider } from 'styled-components'
import RecoVery from '../../components/recoveryRedisign/recovery'
import { theme } from '../../ui/theme'
import { StyledLoginLeft, StyledLoginRight, StyledLoginWrapper } from '../Login/styled'
import svgLogo from '../Login/assets/loginLogo.svg'
import { StyledInfoBlock } from '../../components/loginRedesign/styled'
import { Text } from '../../ui/components'

export default function Recovery() {
  return (
    <ThemeProvider theme={theme}>
      <StyledLoginWrapper>
        <StyledLoginLeft>
          <img
            src={svgLogo}
            alt="Регион"
          />
        </StyledLoginLeft>
        <StyledLoginRight>
          <RecoVery />
          <StyledInfoBlock>
            <Text
              variant="body1_lg"
              color="secondary"
            >
              +7 495 777 29 64
            </Text>
            <Text
              variant="body1_lg"
              color="secondary"
            >
              client@region.ru
            </Text>
          </StyledInfoBlock>
        </StyledLoginRight>
      </StyledLoginWrapper>
    </ThemeProvider>
  )
}

import useMedia from 'hooks/useMedia'
import React, { memo } from 'react'

import { FormCol, TextField } from 'ui/components/atoms'
import { Field } from 'ui/components/molecules'
import Accordion from 'ui/components/molecules/Accordion'
import { font } from 'ui/theme'

const Trusts = () => {
  const { isMobile } = useMedia()

  return (
    <Accordion
      isOpenDefault
      variant="plain"
      title="В отношении трастов и иных иностранных структур без образования
    юридического лица&nbsp;с&nbsp;аналогичной структурой и&nbsp;функцией"
      titleMaxWidth={600}
      padding={isMobile ? '20px' : '32px'}
      bodyPaddingTop={28}
      {...(isMobile && { titleFontType: font.body1 })}
    >
      <FormCol gap={24}>
        <Field label="Состав имущества, находящегося в управлении (собственности)">
          <TextField disabled />
        </Field>

        <Field label="ФИО (при наличии) / наименование учредителей и доверительного собственника (управляющего)">
          <TextField disabled />
        </Field>

        <Field label="Адрес места жительства (места нахождения) учредителей и доверительного собственника (управляющего)">
          <TextField disabled />
        </Field>
      </FormCol>
    </Accordion>
  )
}

export default memo(Trusts)

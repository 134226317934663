import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import endpoints from '../api/endpoints'
import { fetchScanerPermissionsStartAsync, fetchScanerPermissionsSuccess } from '../redux/access/accessSlice'
import { selectGetAccess } from '../redux/access/selector'
import { selectConnectionState } from '../redux/connection/selector'
import useConnect from '../utils/Connect'

export function useAccess() {
  const connectedWS = useSelector(selectConnectionState)
  const access = useSelector(selectGetAccess)
  const dispatch = useDispatch()
  const fetchScanerPermissions = () => dispatch(fetchScanerPermissionsStartAsync())
  function getScanerPermissionsSuccess(data) {
    return dispatch(fetchScanerPermissionsSuccess(data))
  }
  const {
    getData: getScanerPermissions,
  } = useConnect({
    link: `GET /api${endpoints.checks.scanerPermissions}`,
    startFetching: fetchScanerPermissions,
    action: getScanerPermissionsSuccess,
  })
  useEffect(() => {
    if (connectedWS) {
      getScanerPermissions()
    }
  }, [connectedWS])

  return access
}

import heartBeats from './heartBeats'
import auth from './auth'
import scaner from './scaner'
import reports from './reports'
import portfolio from './portfolio'
import access from './access'
import feedback from './feedback'
import files from './files'
import history from './history'
import forms from './forms'
import metadata from './metadata'
import transfer from './transfer'
import securities from './securities'
import notifications from './notifications'
import assets from './assets'
import investmentsNotices from './investmentsNotices'
import trades from './trades'
import partners from './partners'
import documents from './documents'

const {
  login, logout, reset, status,
} = auth
const { getHeartBeats } = heartBeats
const {
  getLink, getScannerIsAccessRequest, getScannerLinkRequest, postScannerAccessRequest,
} = scaner
const {
  reportsRequest,
  getReport,
  postClientSign,
  postReportReject,
  postReportMarkread,
  getBroker,
  getBrokerFilter,
  getDepo,
  getDepoFilter,
  getCorp,
  getCorpFilter,
  getNeedSign,
  getReportTypesNames,
} = reports
const {
  getCategories,
  getTotal,
  getTime,
  getValuations,
  getValuations2,
  getValuations3,
  getPortfolio,
  getInstruments,
  getNdfl,
} = portfolio
const {
  getScanerPermissions,
  getPortfolioPermissions,
} = access
const {
  getUsersRequest, getUserRequest, postUserRequest, putUserRequest,
} = feedback
const {
  getFile,
  postFile,
  postFileList,
} = files
const {
  getMetadata,
} = metadata
const {
  getHistoryMoney,
  getHistoryDepo,
  getHistoryMoneyFilter,
  getHistoryDepoFilter,
  getOrdersTypesNames,
} = history
const {
  getForms,
} = forms
const {
  getSecurities,
  getSecurity,
  postSecurity,
  putSecurity,
  deleteSecurity,
} = securities
const {
  getTransferMoney,
  postTransferMoney,
  putTransferMoney,
  getTransferSecurity,
  postTransferSecurity,
  putTransferSecurity,
  deleteMoney,
  getBanks,
  getPaymentTemplates,
  deletePaymentTemplate,
} = transfer
const {
  getNotificationsData,
} = notifications
const {
  getAccountsInvestments,
  getAgreementsInvestments,
  getAccountsDepo,
  getAgreementsDepo,
  getMoneyRequisitesFilter,
  getDepoRequisitesFilter,
} = investmentsNotices
const {
  getBalancesDepo,
  getBalancesMoney,
} = assets
const {
  postCalculations,
} = trades
const {
  getPartners,
} = partners

const {
  getDocumentsBrokerageServicesTypes,
  getExchangeRequests,
  getExchangeRequestsJsonFields,
  getJsonFieldsForType,
  postDocument,
  putDocument,
  getDocumentBrokerageServices,
} = documents
export default {
  getUsersRequest,
  getUserRequest,
  postUserRequest,
  putUserRequest,
  getFile,
  postFile,
  postFileList,
  getMetadata,
  getTransferMoney,
  postTransferMoney,
  putTransferMoney,
  getHistoryMoney,
  getHistoryDepo,
  getHistoryMoneyFilter,
  getHistoryDepoFilter,
  getForms,
  login,
  reset,
  logout,
  status,
  getHeartBeats,
  getLink,
  getScannerIsAccessRequest,
  getScannerLinkRequest,
  postScannerAccessRequest,
  reportsRequest,
  getReport,
  postClientSign,
  postReportMarkread,
  postReportReject,
  getBroker,
  getBrokerFilter,
  getDepo,
  getDepoFilter,
  getCorp,
  getCorpFilter,
  getNeedSign,
  getReportTypesNames,
  getOrdersTypesNames,
  getCategories,
  getTotal,
  getTime,
  getValuations,
  getValuations2,
  getValuations3,
  getPortfolio,
  getInstruments,
  getNdfl,
  getSecurities,
  getBanks,
  getPaymentTemplates,
  deletePaymentTemplate,
  getSecurity,
  postSecurity,
  putSecurity,
  deleteSecurity,
  getTransferSecurity,
  postTransferSecurity,
  putTransferSecurity,
  deleteMoney,
  getNotificationsData,
  getBalancesDepo,
  getBalancesMoney,
  getAccountsInvestments,
  getAgreementsInvestments,
  getAccountsDepo,
  getAgreementsDepo,
  getMoneyRequisitesFilter,
  getDepoRequisitesFilter,
  postCalculations,
  getPartners,
  getDocumentsBrokerageServicesTypes,
  getExchangeRequests,
  getDocumentBrokerageServices,
  getExchangeRequestsJsonFields,
  getJsonFieldsForType,
  postDocument,
  putDocument,
  getScanerPermissions,
  getPortfolioPermissions,
}

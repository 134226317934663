import React from 'react'
import { StyledTippy, StyledTooltipWrap, TippyStyles } from './styled'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import { ITooltipProps } from './types'
import { Icon } from 'ui/components'

export const Tooltip: React.FC<ITooltipProps> = ({
  content,
  children,
  wrapper,
  active = true,
  maxWidth,
}) => {
  if (!active) return children

  return (
    <>
      <TippyStyles />
      <Tippy maxWidth={maxWidth} content={<StyledTippy>{content}</StyledTippy>}>
        <StyledTooltipWrap {...wrapper}>
          {children || <Icon name="help" size={'xs'} />}
        </StyledTooltipWrap>
      </Tippy>
    </>
  )
}

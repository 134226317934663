import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowBottomIcon } from '../../assets/images/arrow-bottom.svg'
import useStyles from './LinearProgress.style'
import getPrettyNumber from '../../utils/GetPrettyNumber'

const LinearProgress = ({
  category, assets, percent, color, onClick,
}) => {
  const classes = useStyles()
  const [percentVal, setPercentVal] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPercentVal(percent)
    }, 800)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line
  }, [percentVal])

  return (
    <button
      type="button"
      className={classes.LinearProgress}
      onClick={onClick}
    >
      <div className={classes.LinearProgress__topRow}>
        <span className={classes.LinearProgress__category}>{category}</span>
        <ArrowBottomIcon />
      </div>
      <div className={classes.LinearProgress__mainRow}>
        <span className={classes.LinearProgress__assets}>
          {getPrettyNumber(assets)}
        </span>
        <span className={classes.LinearProgress__percent}>
          {percent}
          %
        </span>
      </div>
      <div className={classes.LinearProgress__progress}>
        <div
          className={classes.LinearProgress__progressItem}
          style={{ width: `${percentVal}%`, backgroundColor: color }}
        />
      </div>
    </button>
  )
}

LinearProgress.propTypes = {
  category: PropTypes.string.isRequired,
  assets: PropTypes.number.isRequired,
  percent: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default LinearProgress

import React from 'react'
import TabsComponent from '../../components/Tabs/Tabs'
import { ReactComponent as TransfersMoneyIcon } from '../../assets/images/transfers-money.svg'
import { ReactComponent as TransfersSecuritiesIcon } from '../../assets/images/transfers-securities.svg'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import PageHeading from '../../components/PageHeading/PageHeading'
import useWindowSize from '../../hooks/useWindows'
import DocumentPanel from '../../components/DocumentPanels/DocumentPanel'

export default function Documents() {
  const { windowWidth } = useWindowSize()

  const tabLinks = [
    {
      key: '6acc4dde-1423-11eb-adc1-0242ac130002',
      link: 'brokerageServices',
      label: 'Брокерское обслуживание',
      icon: windowWidth > 576 ? <TransfersMoneyIcon /> : <TransfersIcon />,
      component: DocumentPanel,
    },
    {
      key: '75ebca6c-1423-11bb-adc1-0242ab120002',
      link: 'DepositoryService',
      label: 'Депозитарное обслуживание',
      icon: windowWidth > 576 ? <TransfersSecuritiesIcon /> : <TransfersIcon />,
      component: DocumentPanel,
    },
  ]

  return (
    <>
      <PageHeading text="Документы" />
      <TabsComponent
        tabLinks={tabLinks}
        wishStyle={{ justifyContent: 'flex-start' }}
      />
    </>
  )
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import classnames from 'classnames'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import { useSelector } from 'react-redux'
import { ReactComponent as ExitIcon } from '../../assets/images/exit.svg'
import { ReactComponent as DingIcon } from '../../assets/images/ding.svg'
import { ReactComponent as UserIcon } from '../../assets/images/user.svg'
import { ReactComponent as BuildingsIcon } from '../../assets/images/buildings.svg'
import { ReactComponent as LogoPathIcon } from '../../assets/images/logo-path.svg'
import useStyles from './DesktopAppBar.style'
import { navList, actionsList } from '../MainLayout/data'
import ThemeSwitch from '../ThemeSwitch'
import useWindowSize from '../../hooks/useWindows'
import { selectNeedSignReports } from '../../redux/reports/selector'
import { useAccess } from '../../hooks/useAccess'

const DesktopAppBar = ({
  noticeOpen, noticeCount, agentName, clientName,
}) => {
  const classes = useStyles()
  const { windowWidth } = useWindowSize()
  const [isMenuOpen, setIsMenuOpen] = useState(null)
  const [currentTime, setCurrentTime] = useState(moment().format('HH:mm'))
  const access = useAccess()
  const needSignReports = useSelector(selectNeedSignReports)
  const needSignReportsCount = needSignReports && needSignReports?.reports?.length

  const openMenu = (event) => {
    setIsMenuOpen(event.currentTarget)
  }

  const closeMenu = () => {
    setIsMenuOpen(null)
  }

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(moment().format('HH:mm')), 10)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <header className={classes.DesktopAppBar}>
      <div className={classes.DesktopAppBar__inner}>
        <div className={classes.DesktopAppBar__top}>
          <div className={classes.DesktopAppBar__actionsList}>
            <IconButton
              className={classes.DesktopAppBar__actionsListItem}
              color="inherit"
              disableRipple
              onClick={noticeOpen}
            >
              <Badge
                className={classes.DesktopAppBar__badgeWrapper}
                badgeContent={noticeCount}
                color="error"
              >
                <DingIcon className={classes.DesktopAppBar__actionsListIcon} />
              </Badge>
            </IconButton>
            <Button
              className={classes.DesktopAppBar__actionsListItem}
              size="large"
              disableRipple
              startIcon={<UserIcon className={classes.DesktopAppBar__actionsListIcon} />}
              style={{ pointerEvents: 'none' }}
              tabIndex={-1}
            >
              {agentName}
            </Button>
            <Button
              className={classes.DesktopAppBar__actionsListItem}
              size="large"
              disableRipple
              startIcon={<BuildingsIcon className={classes.DesktopAppBar__actionsListIcon} />}
              onClick={openMenu}
            >
              {clientName}
            </Button>
            <div className={classes.DesktopAppBar__switchButtons}>
              <ThemeSwitch />
            </div>
            <Menu
              className={classes.DesktopAppBar__menu}
              anchorEl={isMenuOpen}
              keepMounted
              open={Boolean(isMenuOpen)}
              onClose={closeMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: windowWidth > 1800 ? 'left' : 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: windowWidth > 1800 ? 'left' : 'right',
              }}
            >
              {actionsList.map(({
                link, icon, title, hasBadge,
              }, index) => (
                <MenuItem
                  key={`${title}${String(index)}`}
                  onClick={closeMenu}
                  style={{ padding: 0 }}
                  disableRipple
                >
                  <NavLink to={link}>
                    <ListItemIcon>
                      {icon}
                    </ListItemIcon>
                    <Typography color="secondary">
                      {title}
                      {hasBadge && needSignReportsCount
                        ? <span>{needSignReportsCount}</span>
                        : null}
                    </Typography>
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
        <div className={classes.DesktopAppBar__sidebar}>
          <div className={classes.DesktopAppBar__logoWrapper}>
            <LogoPathIcon className={classes.DesktopAppBar__logo} />
            <span className={classes.DesktopAppBar__today}>{moment().format('DD.MM.YYYY')}</span>
            &nbsp;
            <span className={classes.DesktopAppBar__todayTime}>{currentTime}</span>
          </div>
          <List
            className={classnames(classes.DesktopAppBar__list, classes.DesktopAppBar__menuList)}
          >
            {navList
              .filter(({ id }) => access[id] !== false)
              .map(({
                title, link, icon,
              }, index) => (
                <NavLink
                  key={title + link + index.toString()}
                  to={`${link}`}
                  style={{ textDecoration: 'none' }}
                  activeClassName={classes.activeLink}
                >
                  <ListItem
                    className={classes.DesktopAppBar__listItem}
                    button
                    disableRipple
                    tabIndex={-1}
                  >
                    <ListItemIcon className={classes.DesktopAppBar__listIconWrapper}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography color="secondary">{title}</Typography>
                    </ListItemText>
                  </ListItem>
                </NavLink>
              ))}
          </List>
          <List className={classes.DesktopAppBar__list}>
            <NavLink
              to="/logout"
              style={{ textDecoration: 'none' }}
            >
              <ListItem
                className={
                  classnames(classes.DesktopAppBar__listItem, classes.DesktopAppBar__listItemExit)
                }
                button
                disableRipple
                tabIndex={-1}
              >
                <ListItemIcon className={classes.DesktopAppBar__listIconWrapper}>
                  <ExitIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="secondary">Выход</Typography>
                </ListItemText>
              </ListItem>
            </NavLink>
          </List>
          <div className={classes.DesktopAppBar__sidebarLayer} />
        </div>
      </div>
    </header>
  )
}

DesktopAppBar.defaultProps = {
  agentName: '',
  clientName: '',
}

DesktopAppBar.propTypes = {
  noticeOpen: PropTypes.func.isRequired,
  noticeCount: PropTypes.number.isRequired,
  agentName: PropTypes.string,
  clientName: PropTypes.string,
}

export default DesktopAppBar

import { styled } from "ui/theme";
import { makeStyles } from '@material-ui/core/styles'

export const StyledModalInner = styled.div<{
  unique: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  @media(${({ theme }) => theme.breakpoint.xs}) {
    max-width: none;
    justify-content: flex-end;
  }
`

export const StyledModalContent = styled.div<{
  unique: boolean
  error: boolean
}>`
  width: 100%;
  max-width: 456px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: #ffffff;
  color: ${({ theme }) => theme.color.text.primary};
  text-align: center;
  li {
    text-align: left;
  }
  @media(${({ theme }) => theme.breakpoint.xs}) {
    max-width: none;
    padding: 32px 40px 24px;
  }
`

export const StyledModalInfoWrapper = styled.div`

`

export const StyledModalClose = styled.div`
  margin-bottom: 4px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`


export const StyledModalTitle = styled.div`
  ${({ theme }) => theme.font.h3};
  text-align: center;
  margin-bottom: 20px;

`

export const StyledModalActions = styled.div`
margin-top: 20px;
display: flex;
justify-content: center;
width: 100%;
gap: ${({ theme }) => theme.spacing.xs};
> * {
  flex: 1;
}
`

export const StyledModalOkButton = styled.button`
  padding: 10px 24px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  ${({ theme }) => theme.font.body1_lg};
  background: none;
  border: 1px solid ${({ theme }) => theme.color.border.default};
  color: ${({ theme }) => theme.color.text.inverted};
  cursor: pointer;
`

export const StyledModalCancelButton = styled.button`
  padding: 10px 24px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  ${({ theme }) => theme.font.body1_lg};
  background: ${({ theme }) => theme.color.graphics.secondary};
  border: none;
  color: ${({ theme }) => theme.color.text.inverted};
  cursor: pointer;

`

export const classes = makeStyles({
  Modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      boxSizing: 'border-box',
      justifyContent: 'flex-end',
      paddingRight: 6,
    },
    '& > div': {
      '&:first-child': {
        backgroundColor: '#0000002F !important',
        '@media (max-width: 576px)': {
          backgroundColor: 'rgba(255, 255, 255, 0.8) !important',
        },
      },
    },
  },
})

import React, { FC, memo } from 'react'

import { FormRow, TextField } from 'ui/components'
import { Field, Select, Table } from 'ui/components/molecules'
import Accordion from 'ui/components/molecules/Accordion'
import { PaymentTemplate } from 'types/paymentTemplate'

import { StyledRequestFormWrapper, StyledRequestWrapper } from './styled'
import { IRequisitesTab } from './types'
import useMedia from 'hooks/useMedia'
import { StyledBeneficiaresRow } from '../../AdditionalInfo/Beneficiares/styled'
import useRequisitesTable from './useRequisitesTable'
import { theme } from 'ui/theme'

const RequisitesTab: FC<IRequisitesTab> = ({ bankRequisites }) => {
  const { isDesktop, isMobile } = useMedia()

  const settlements = bankRequisites?.filter(
    ({ templatePurpose }) => templatePurpose === 'settlements'
  )
  const securitiesIncome = bankRequisites?.filter(
    ({ templatePurpose }) => templatePurpose === 'securitiesIncome'
  )

  const tables = [
    {
      title: 'Банковские реквизиты для расчётов',
      data: settlements,
    },
    {
      title: 'Банковские реквизиты для перечисления доходов и (или) выплат по ЦБ',
      data: securitiesIncome,
    },
  ]

  const { getRequisitesTabColumns } = useRequisitesTable()

  return (
    <StyledRequestWrapper>
      {tables.map(({ title, data }) => (
        <Accordion padding="32px 28px" bodyPaddingTop={24} variant="plain" title={title}>
          <Table<PaymentTemplate>
            {...(isMobile && { tableLayout: 'auto' })}
            topHeadPadding={18}
            bottomHeadPadding={18}
            leftPadding={24}
            withBodyRowBorder={isDesktop}
            rowsGap={6}
            rowsHeight={66}
            bodyRowBorderColor={theme.color.border.default40}
            withRowShadow={false}
            columns={getRequisitesTabColumns({
              showAccount: isDesktop,
              showCurrency: !isMobile,
              showName: !isMobile,
            })}
            {...(!isDesktop && { leftPadding: 0, rightPadding: 0 })}
            rows={data}
            accordionBody={({
              bankName,
              bankAddress,
              bankAccount,
              correspondentAccount,
              bankBic,
              agentName,
            }) => (
              <StyledRequestFormWrapper>
                <FormRow>
                  <Field label="Наименование банка получателя">
                    <TextField disabled value={bankName} />
                  </Field>
                </FormRow>

                <FormRow>
                  <Field label="Город">
                    <TextField disabled value={bankAddress} />
                  </Field>
                </FormRow>

                <StyledBeneficiaresRow mobileColumn>
                  <Field label="Расчётный счёт">
                    <TextField disabled value={bankAccount} />
                  </Field>

                  <Field label="Корр. счёт">
                    <Select isDisabled value={correspondentAccount} />
                  </Field>

                  <Field label="БИК">
                    <Select isDisabled value={bankBic} />
                  </Field>
                </StyledBeneficiaresRow>

                <FormRow>
                  <Field label="Получатель">
                    <TextField disabled value={agentName} />
                  </Field>
                </FormRow>

                <FormRow>
                  <Field label="Назначение платежа">
                    <TextField disabled />
                  </Field>
                </FormRow>
              </StyledRequestFormWrapper>
            )}
          />
        </Accordion>
      ))}
    </StyledRequestWrapper>
  )
}

export default memo(RequisitesTab)

import CommonTheme from './CommonTheme'

export default {
  ...CommonTheme,
  palette: {
    type: 'light',
    primary: {
      main: '#313a4e',
    },
    secondary: {
      main: '#313a4e',
    },
    text: {
      secondary: '#91B1BF',
    },
    background: {
      default: '#fff',
    },
  },
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import {
  fetchSelectorStartAsync, clearSelector, goToPrevStepAsync, fetchResetClientStartAsync,
} from '../../redux/user/userSlice'
import {
  StyledLoginTitle, StyledLoginWrapper, StyledSelect, StyledButton, StyledLabel,
} from './styled'
import {
  Button, Select, Text,
} from '../../ui/components'

const Selector = ({
  fetching,
  fetchSelectorStart,
  setStepArray,
  clearSelectorStart,
  stepVisibility,
  selectorItems,
  arraySteps,
  setArraySteps,
  isPasswordRecovery,
  cause,
}) => {
  const [selectorList, setSelectorList] = useState(null)
  const [selector, setSelector] = useState('')
  const [selectorName, setSelectorName] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (stepVisibility.select) {
      const items = selectorItems
      if (items) {
        setSelectorList(items)
      }
    }
  }, [selectorItems, stepVisibility])

  const goToPrevStep = (action) => {
    dispatch(goToPrevStepAsync({ action }))
  }

  const checkCorrectResponse = (response) => {
    const respData = response?.status
    switch (respData?.status || response?.status) {
      case 401:
        break
      case 403:
        break
      default:
        setArraySteps([...arraySteps, 'select'])
        setSelector('')
    }
  }

  const fetchResetClientStart = (clientId) => {
    dispatch(fetchResetClientStartAsync({ clientId })).then((response) => {
      checkCorrectResponse(response)
    })
  }

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    if (isPasswordRecovery) {
      await fetchResetClientStart(selector)
      setSelectorName('')
    } else {
      await fetchSelectorStart(selector).then((response) => {
        checkCorrectResponse(response)
      })
      setSelectorName('')
    }
  }

  const handleChangeSelector = (event) => {
    setSelector(event.value)
    setSelectorName(event.label)
  }

  const handleClickPrev = () => {
    setStepArray('select')
    goToPrevStep(clearSelectorStart)
  }

  return (
    <>
      <StyledLoginWrapper>
        <StyledLoginTitle>
          <Text
            variant="h1"
            color="primary"
          >
            Вход в личный кабинет
          </Text>
        </StyledLoginTitle>

        <form onSubmit={handleSubmitForm}>
          <StyledLabel>
            <Text
              variant="body2"
              color="secondary"
            >
              {cause ?? ''}
            </Text>
          </StyledLabel>

          <StyledSelect>
            <Select
              value={selectorName}
              onChange={handleChangeSelector}
              options={selectorList?.map((item) => ({
                label: item.description,
                value: item.id,
              }))}
            />
          </StyledSelect>

          <StyledButton>
            <Button
              type="submit"
              disabled={!selector || fetching}
            >
              Далее
            </Button>
          </StyledButton>

          {!isPasswordRecovery && (
            <StyledButton>
              <Button
                type="submit"
                variant="secondary"
                onClick={handleClickPrev}
              >
                Назад
              </Button>
            </StyledButton>
          )}
        </form>
      </StyledLoginWrapper>
    </>
  )
}

Selector.defaultProps = {
  isPasswordRecovery: false,
}

Selector.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetchSelectorStart: PropTypes.func.isRequired,
  setStepArray: PropTypes.func.isRequired,
  clearSelectorStart: PropTypes.func.isRequired,
  stepVisibility: PropTypes.oneOfType([PropTypes.object]).isRequired,
  arraySteps: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setArraySteps: PropTypes.func.isRequired,
  selectorItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  isPasswordRecovery: PropTypes.bool,
  cause: PropTypes.string.isRequired,
}

const mapStateToProps = ({ user: { isFetching } }) => ({
  fetching: isFetching,
})

const mapDispatchToProps = (dispatch) => ({
  fetchSelectorStart: (agentId) => dispatch(fetchSelectorStartAsync({ agentId })),
  clearSelectorStart: () => dispatch(clearSelector()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Selector)

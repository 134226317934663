import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import cuid from 'cuid'
import Media from 'react-media'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Collapse from '@material-ui/core/Collapse'
import TableContainer from '@material-ui/core/TableContainer'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import useStyles from './sharedStyles'
import CustomInput from '../../CustomControls/CustomInput'
import CustomDatepicker from '../../CustomControls/CustomDatepicker'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

const Row = ({
  licenceInfoContent: {
    type, number, gaveOrg, gaveDate,
  },
}) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const handleRowClick = () => setOpen(!open)

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <TableRow
        className={classes.tableRow}
        hover
        onClick={handleRowClick}
      >
        <TableCell
          component="th"
          scope="row"
          style={{ paddingLeft: 0 }}
        >
          {type}
        </TableCell>
        <TableCell>{number}</TableCell>
        <TableCell>{gaveOrg}</TableCell>
        <TableCell>{format(new Date(gaveDate), 'dd.MM.yyyy')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            padding: 0, borderBottom: 0,
          }}
          colSpan={4}
          className={classes.nestedRow}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Grid
              container
              spacing={2}
              className={classes.row}
            >
              <Grid
                item
                lg={6}
                xs={12}
              >
                <CustomInput
                  name="type"
                  label="Вид деятельности"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={type}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
              >
                <CustomInput
                  name="number"
                  label="Номер"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={number}
                  disabled
                  unique
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className={classes.row}
            >
              <Grid
                item
                lg={6}
                xs={12}
              >
                <CustomInput
                  name="gaveOrg"
                  label="Выдавший орган"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={gaveOrg}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
              >
                <CustomDatepicker
                  label="Дата выдачи"
                  value={gaveDate}
                  disabled
                  unique
                />
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </MuiPickersUtilsProvider>
  )
}

Row.defaultProps = {
  licenceInfoContent: null,
}

Row.propTypes = {
  licenceInfoContent: PropTypes.shape({
    type: PropTypes.string,
    number: PropTypes.string,
    gaveOrg: PropTypes.string,
    gaveDate: PropTypes.string,
  }),
}

const LicenceInfosContent = ({ data, handleClickNextPanel, isNeedNextButton }) => {
  const classes = useStyles()

  const getStructure = () => {
    const members = ['Состав совета директоров', 'Состав общего собрания участников', 'Состав правления', 'Единоличный исполнительный орган']
    let localManagementBodies = ''

    if (data?.clientInfo?.management && Object.keys(data?.clientInfo?.management).length > 0) {
      if (data.clientInfo.management.hasDirectorsBoard) {
        localManagementBodies += `- ${members[0]}: ${data?.clientInfo?.management?.directorsBoardMembers?.join(', ')}`
      }

      if (data?.clientInfo?.management?.hasGeneralMeeting) {
        localManagementBodies += localManagementBodies !== '' ? '\n' : ''
        localManagementBodies += `- ${members[1]}: ${data?.clientInfo?.management?.generalMeetingMembers?.join(', ')}`
      }

      if (data?.clientInfo?.management?.hasManagementBoard) {
        localManagementBodies += localManagementBodies !== '' ? '\n' : ''
        localManagementBodies += `- ${members[2]}: ${data?.clientInfo?.management?.managementBoardMembers?.join(', ')}`
      }

      if (data?.clientInfo?.management?.hasSoleExecutive) {
        localManagementBodies += localManagementBodies !== '' ? '\n' : ''
        localManagementBodies += `- ${members[3]}: ${data?.clientInfo?.management?.soleExecutive}`
      }
    }
    return localManagementBodies
  }

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <TableContainer className={classes.tableContainer}>
              <Table aria-label="licence table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: 0, paddingLeft: 0 }}
                      className={classes.tableHeaderCell}
                    >
                      Вид деятельности
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: 0 }}
                      className={classes.tableHeaderCell}
                    >
                      Номер
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: 0 }}
                      className={classes.tableHeaderCell}
                    >
                      Выдавший орган&nbsp;
                    </TableCell>
                    <TableCell
                      style={{ borderBottom: 0 }}
                      className={classes.tableHeaderCell}
                    >
                      Дата выдачи&nbsp;
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data?.clientInfo?.[['licenses'] || []]?.map((license, key) => (
                      <Row
                        key={key.toString() + cuid()}
                        gaveDate={new Date(license?.issueDate)}
                        licenceInfoContent={{
                          type: license?.activity,
                          number: license?.number,
                          gaveOrg: license?.issuingAuthority,
                          gaveDate: license?.issueDate,
                        }}
                      />
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.row}
              >
                <CustomInput
                  fullWidth
                  name="structure"
                  multiline
                  rows={2}
                  size="small"
                  variant="outlined"
                  label="Сведения о структуре управления"
                  value={getStructure()}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.row}
              >
                <CustomInput
                  fullWidth
                  name="affiliation"
                  size="small"
                  variant="outlined"
                  label="Сведения об аффилированных лицах"
                  value={data?.clientInfo?.affiliates?.join(', ')}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.row}
              >
                <CustomInput
                  fullWidth
                  name="capital"
                  size="small"
                  variant="outlined"
                  label="Уставной капитал"
                  value={`${data?.clientInfo?.registeredCapital || ''} ${data?.clientInfo?.registeredCapitalCurrency?.uid || ''}`}
                  disabled
                  unique
                />
              </Grid>
            </Grid>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            <div className={classes.Mobile}>
              {
                data?.clientInfo?.[['licenses'] || []]?.map((license, key) => (
                  <div
                    key={key.toString() + cuid()}
                    className={classes.Mobile__licenses}
                  >
                    <div className={classes.Mobile__block}>
                      <div className={classes.Mobile__blockHeader}>
                        Вид деятельности
                      </div>
                      <div className={classes.Mobile__blockTextStructure}>
                        {license?.activity}
                      </div>
                    </div>
                    <div className={classes.Mobile__block}>
                      <div className={classes.Mobile__blockHeader}>
                        Номер
                      </div>
                      <div className={classes.Mobile__blockTextStructure}>
                        {license?.number}
                      </div>
                    </div>
                    <div className={classes.Mobile__block}>
                      <div className={classes.Mobile__blockHeader}>
                        Дата выдачи
                      </div>
                      <div className={classes.Mobile__blockTextStructure}>
                        {license?.issueDate}
                      </div>
                    </div>
                  </div>
                ))
              }
              {Object.keys(data?.clientInfo?.management).length > 0 && (
                <div className={classes.Mobile__block}>
                  <div className={classes.Mobile__blockHeader}>
                    Сведения о структуре управления
                  </div>
                  <div className={classes.Mobile__blockTextStructure}>
                    {getStructure()}
                  </div>
                </div>
              )}
              {data?.clientInfo?.affiliates?.length > 0 && (
                <div className={classes.Mobile__block}>
                  <div className={classes.Mobile__blockHeader}>
                    Сведения об аффилированных лицах
                  </div>
                  <div className={classes.Mobile__blockText}>
                    {data?.clientInfo?.affiliates?.join(', ')}
                  </div>
                </div>
              )}
              {(data?.clientInfo?.registeredCapital
              || data?.clientInfo?.registeredCapitalCurrency?.uid) && (
                <div className={classes.Mobile__block}>
                  <div className={classes.Mobile__blockHeader}>
                    Уставной капитал
                  </div>
                  <div className={classes.Mobile__blockText}>
                    {`${data?.clientInfo?.registeredCapital || ''} ${data?.clientInfo?.registeredCapitalCurrency?.uid || ''}`}
                  </div>
                </div>
              )}
            </div>
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </>
        )}
      />
    </>
  )
}

LicenceInfosContent.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

LicenceInfosContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

export default LicenceInfosContent

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Media from 'react-media'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { format } from 'date-fns'
import DropZone from '../DropZone/index'
import {
  fetchFeedbackStartAsync,
  fetchCertificateStartAsync,
  fetchCodeStartAsync,
  fetchSendMessageStartAsync,
  putFeedbackSuccess,
  getFeedbackSuccess,
  postFeedbackSuccess,
  clearFeedback,
} from '../../redux/feedback/feedbackSlice'
import {
  selectGetFeedbackData,
  selectPostFeedbackData,
  selectPutFeedbackData,
} from '../../redux/feedback/selector'
import useConnect from '../../utils/Connect'
import useArrayConnect from '../../utils/ArrayConnect'
import { encodeToUTF8 } from '../../utils/EncodeToUTF8'
import endpoints from '../../api/endpoints'
import {
  fetchGetFileStartAsync,
  fetchPostFileStartAsync,
  getFileSuccess,
  postFileSuccess,
  fileClean,
} from '../../redux/file/fileSlice'
import useStyles from './panel.style'
import { selectGetFilesData, selectPostFilesData } from '../../redux/file/selector'
import { selectCurrentError } from '../../redux/error/selector'
import Timer from '../../utils/Timer'
import { ReactComponent as DownloadIcon } from '../../assets/images/icon-button-download.svg'
import CustomInput from '../CustomControls/CustomInput'
import CustomSelect from '../CustomControls/CustomSelect'
import CustomIconButton from '../CustomControls/CustomIconButton'
import CustomModal from '../CustomModal/CustomModal'
import PageHeading from '../PageHeading/PageHeading'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import { getAppeal, getStatus } from '../../utils/GetStatus'
import CustomButton from '../CustomControls/CustomButton'
import { isRequired } from '../../utils/Validate'
import CustomLoader from '../CustomLoader/CustomLoader'
import { selectCurrentThumbprint } from '../../redux/user/selector'

const initialState = {
  value: {
    type: '',
    subject: '',
    request: '',
  },
  files: [],
  idFiles: [],
}

const MessageForm = ({
  fetchFeedbackStart, connectedWS, fetchSendMessageStart,
  fetchCertificateStart, fetchPostFile, fetchGetFile, fetchCodeStart,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [value, setValue] = useState(initialState.value)
  const [code, setCode] = useState('')
  const [showTimer, setShowTimer] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [typeError, setTypeError] = useState(false)
  const [subjectError, setSubjectError] = useState(false)
  const [requestError, setRequestError] = useState(false)
  const [codeError, setCodeError] = useState(false)
  const [typeErrorText, setTypeErrorText] = useState('')
  const [subjectErrorText, setSubjectErrorText] = useState('')
  const [requestErrorText, setRequestErrorText] = useState('')
  const [codeErrorText, setCodeErrorText] = useState('')
  const [files, setFiles] = useState(initialState.files)
  const [idFiles, setIdFiles] = useState(initialState.idFiles)
  const [isLoading, setIsLoading] = useState(false)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [failedEmptyFile, setFailedEmptyFile] = useState(false)

  const { getData } = useConnect({ link: `GET /api${endpoints.feedback.getUser(activeId || '')}`, startFetching: fetchFeedbackStart, action: getFeedbackSuccess })
  const { getData: postData } = useConnect({ link: `POST /api${endpoints.feedback.postUser(false)}`, startFetching: fetchSendMessageStart, action: postFeedbackSuccess })
  useConnect({ link: `PUT /api${endpoints.feedback.putUser(null, activeId)}`, startFetching: fetchSendMessageStart, action: putFeedbackSuccess })
  const { getData: postFileData } = useConnect({ link: `POST /api${endpoints.file.postFile}`, startFetching: fetchPostFile, action: postFileSuccess })
  const { getData: getFilesData } = useArrayConnect({
    link: `GET /api${endpoints.file.getFile()}`, idList: idFiles, startFetching: fetchGetFile, action: getFileSuccess,
  })
  const { getData: putSignedData } = useConnect({ link: '', startFetching: fetchCertificateStart, action: putFeedbackSuccess })
  const { getData: putCodeData } = useConnect({ link: '', startFetching: fetchCodeStart, action: putFeedbackSuccess })

  const respGetData = useSelector(selectGetFeedbackData)
  const respPostData = useSelector(selectPostFeedbackData)
  const respPutData = useSelector(selectPutFeedbackData)
  const respGetFileData = useSelector(selectGetFilesData)
  const respPostFileData = useSelector(selectPostFilesData)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)

  useEffect(() => {
    if (serverError) {
      if (serverError?.errors?.Type?.length && serverError?.errors?.Type[0]) {
        setTypeError(true)
        setTypeErrorText(serverError?.errors?.Type[0])
      }
      if (serverError?.errors?.Subject?.length && serverError?.errors?.Subject[0]) {
        setSubjectError(true)
        setSubjectErrorText(serverError?.errors?.Subject[0])
      }
      if (serverError?.errors?.Request?.length && serverError?.errors?.Request[0]) {
        setRequestError(true)
        setRequestErrorText(serverError?.errors?.Request[0])
      }
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeErrorText(serverError?.errors?.Secret[0])
      }
      setIsFormLoading(false)
    }
  }, [serverError])

  const setDeactiveMessage = () => {
    dispatch(clearFeedback())
    dispatch(fileClean())
    setActiveId(null)
    setFiles(initialState.files)
    setIdFiles(initialState.idFiles)
    setValue(initialState.value)
  }

  const setActiveMessage = (id) => {
    setActiveId(id)
    getData(id)
  }

  const handleGoToHistory = () => {
    history.push('history')
  }

  useEffect(() => {
    setDeactiveMessage()
    setIsFormLoading(false)
    return () => {
      setDeactiveMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (connectedWS) {
      if (location?.state?.activeMessage) {
        setActiveMessage(location?.state?.activeMessage)
      } else {
        setDeactiveMessage()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS, location?.state?.activeMessage])

  const processArray = (array) => {
    array.forEach(async (fileId) => {
      await getFilesData(fileId)
    })
  }

  useEffect(() => {
    if (connectedWS) {
      if (respGetData?.userRequest) {
        setIsFormLoading(false)
        setValue({
          type: respGetData?.userRequest?.type,
          subject: respGetData?.userRequest?.subject,
          request: respGetData?.userRequest?.request,
        })
      }
      if (respGetData?.userRequest?.files) {
        setIdFiles(respGetData.userRequest.files)
        processArray(respGetData.userRequest.files)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetData])

  useEffect(() => {
    if (connectedWS) {
      setIsFormLoading(false)
      if (respPostData?.requiredAction) {
        if (!activeId) {
          setActiveId(respPostData.userRequest.id)
        }
        if (respPostData?.requiredAction?.type === 'code' && respPostData?.status === 303) {
          setShowTimer(true)
        }
      }
      if (respPutData?.requiredAction) {
        if (!activeId) {
          setActiveId(respPutData.userRequest.id)
        }
        if (respPutData?.requiredAction?.type === 'code' && respPutData?.status === 303) {
          setShowTimer(true)
        }
      }
      if (respPostData?.status === 200 || respPutData?.status === 200) {
        setOpen(true)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respPostData, respPutData])

  useEffect(() => {
    if (respPostFileData?.id) {
      const arrayIdFiles = [...idFiles]
      arrayIdFiles.push(respPostFileData?.id)
      setIdFiles(arrayIdFiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respPostFileData])

  useEffect(() => {
    if (respGetFileData.length && connectedWS) {
      const arrayFileObjects = [...files]
      respGetFileData.map((file) => (arrayFileObjects.push({
        data: file.fileData,
        file: {
          name: file.fileName,
          path: file.fileName,
          size: file.fileLength,
        },
      })))
      setFiles(arrayFileObjects)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [respGetFileData])

  const getDisabledState = () => ((!!respGetData && respGetData?.userRequest?.status !== 'draft') && !respPostData && !respPutData)
    || !!respPostData || !!respPutData

  const getHandler = (handler) => (!getDisabledState() ? handler : null)

  const handleChangeType = (event) => {
    const { name } = event.target
    setValue({ ...value, [name]: event.target.value })
    setTypeError(false)
  }

  const handleChangeSubject = (event) => {
    const { name } = event.target
    setValue({ ...value, [name]: event.target.value })
    setSubjectError(false)
  }

  const handleChangeRequest = (event) => {
    const { name } = event.target
    setValue({ ...value, [name]: event.target.value })
    setRequestError(false)
  }

  const handleCancelOperation = () => {
    dispatch(clearFeedback())
    dispatch(fileClean())
  }

  const getDocName = (data) => `${getAppeal(value?.type)} № ${activeId} от ${format(new Date(data?.userRequest?.dateTime?.split('T')[0]), 'dd.MM.yyyy')}`

  const handleDownloadXml = () => {
    const msgData = respPostData || respPutData
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${getDocName(msgData)}.xml`
      const blob = new Blob([encodeToUTF8(msgData.requiredAction.signAction.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleAddedFile = (addedFiles) => {
    if (!addedFiles.length) {
      setFiles([])
      return
    }
    if (addedFiles.some((file) => !file?.file?.size)) {
      setFailedEmptyFile(true)
      setIsLoading(false)
      return
    }
    const arrayFiles = [...files]
    addedFiles.forEach((file) => {
      const obj = {
        fileName: file.file.name,
        fileData: file.data.split('base64,')[1],
        fileSize: file.file.size,
      }
      postFileData(obj)
      arrayFiles.push(file)
    })
    setTimeout(() => {
      setFiles(arrayFiles)
      setIsLoading(false)
    }, 1000)
  }

  const handleRemoveFile = (removedFile) => {
    if (getDisabledState()) return
    const newArr = files.filter((f) => f !== removedFile)
    const indexRemovedFile = files.findIndex((f) => f === removedFile)
    setFiles(newArr)
    if (indexRemovedFile !== -1) {
      const arrayIdFiles = [...idFiles]
      arrayIdFiles.splice(indexRemovedFile, 1)
      setIdFiles(arrayIdFiles)
    }
  }

  const handleChangeCodeInput = (event) => {
    setCode(event.target.value)
    setCodeError(false)
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const reqObj = {
      ...value,
      id: activeId || null,
      dateTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS+03:00"),
      userID: '',
      status: respGetData?.userRequest?.status,
      files: idFiles,
    }
    postData(reqObj)
  }

  const handleSubmitMessageForm = (event) => {
    event.preventDefault()
    if (connectedWS) {
      setIsFormLoading(true)
      let reqObj = {}
      const msgData = respPostData || respPutData
      if (msgData?.requiredAction) {
        switch (msgData.requiredAction.type) {
          case 'sign':
            reqObj = {
              ...value,
              id: msgData.userRequest.id,
              dateTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS+03:00"),
              userID: msgData.userRequest.userID,
              status: msgData.userRequest.status,
              files: idFiles,
              thumbPrint: currentThumbPrint,
              signedXml: msgData.requiredAction.signAction.xml,
            }
            putSignedData(reqObj)
            break
          case 'code':
            if (!code) {
              setIsFormLoading(false)
              isRequired(code, setCodeError, setCodeErrorText)
              break
            }
            reqObj = {
              ...value,
              id: msgData.userRequest.id,
              dateTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS+03:00"),
              userID: msgData.userRequest.userID,
              status: msgData.userRequest.status,
              files: idFiles,
              secret: code,
            }
            putCodeData(reqObj)
            break
          default:
            break
        }
      } else {
        reqObj = {
          ...value,
          id: activeId || null,
          dateTime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS+03:00"),
          userID: '',
          status: respGetData?.userRequest?.status,
          files: idFiles,
        }
        postData(reqObj)
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
    handleGoToHistory()
  }

  return (
    <>
      <PageHeading text="Обращениe" />
      {isFormLoading && (
        <Box mt="79px">
          <CustomLoader />
        </Box>
      )}
      {!isFormLoading && (
        <form
          className={classes.MessageForm__form}
          onSubmit={handleSubmitMessageForm}
        >
          <Media
            query="(min-width: 577px)"
            render={() => (
              <Box mb="31px">
                <Typography m={0}>
                  {respGetData ? getDocName(respGetData) : ('Если у вас есть вопросы, жалобы или предложения по улучшению качества нашей работы, сообщите нам.')}
                </Typography>
              </Box>
            )}
          />
          <Media
            query={`(max-width: ${breakpoints.phone_large}px)`}
            render={() => (
              <>
                {(respGetData?.userRequest?.dateTime && respGetData?.userRequest?.id) && (
                  <div
                    className={classes.PhoneMessage__dateInfo}
                  >
                    {respGetData?.userRequest?.dateTime && (
                      <div>
                        <span
                          className={classes.PhoneGrid__header_m}
                        >
                          Дата отправления
                        </span>
                        <p className={classes.PhoneGrid__date}>
                          {format(new Date(respGetData?.userRequest?.dateTime?.split('T')[0]), "dd.MM.yyyy' 'HH:mm")}
                        </p>
                      </div>
                    )}
                    {respGetData?.userRequest?.id && (
                      <div>
                        <span
                          className={classes.PhoneGrid__header_m}
                        >
                          ID
                        </span>
                        <p className={classes.PhoneGrid__number}>
                          {respGetData?.userRequest?.id}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          />
          <Grid
            container
            spacing={6}
            className={classes.MessageForm__blockFields}
          >
            <Grid
              item
              lg={4}
              xs={12}
            >
              <CustomSelect
                error={typeError}
                errorText={typeErrorText}
                label="Тип"
                name="type"
                color="secondary"
                value={value.type || ''}
                onChange={getHandler(handleChangeType)}
                unique
              >
                <MenuItem value="claim">Жалоба</MenuItem>
                <MenuItem value="call">Обращение</MenuItem>
              </CustomSelect>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <CustomInput
                    error={subjectError}
                    errorText={subjectErrorText}
                    variant="outlined"
                    label="Тема"
                    name="subject"
                    color="secondary"
                    size="small"
                    value={value.subject || ''}
                    onChange={(event) => handleChangeSubject(event)}
                    fullWidth
                    unique
                    disabled={getDisabledState()}
                    autoComplete="off"
                  />
                )}
              />
              <Media
                query="(max-width: 576px)"
                render={() => (
                  <>
                    <CustomInput
                      error={subjectError}
                      errorText={subjectErrorText}
                      variant="outlined"
                      label="Тема"
                      name="subject"
                      color="secondary"
                      size="small"
                      value={value.subject || ''}
                      onChange={(event) => handleChangeSubject(event)}
                      multiline
                      rows={3}
                      fullWidth
                      unique
                      disabled={getDisabledState()}
                      autoComplete="off"
                    />
                    <div className={classes.MessageForm__selectWrapper}>
                      <CustomInput
                        error={requestError}
                        errorText={requestErrorText}
                        variant="outlined"
                        label="Текст обращения"
                        name="request"
                        color="secondary"
                        value={value.request || ''}
                        onChange={(event) => handleChangeRequest(event)}
                        multiline
                        rows={3}
                        unique
                        disabled={getDisabledState()}
                      />
                    </div>
                  </>
                )}
              />
            </Grid>
            <Media
              query="(min-width: 577px)"
              render={() => (
                <Grid
                  item
                  lg={8}
                  xs={12}
                >
                  <div className={classes.MessageForm__selectWrapper}>
                    <CustomInput
                      error={requestError}
                      errorText={requestErrorText}
                      variant="outlined"
                      label="Описание"
                      name="request"
                      color="secondary"
                      value={value.request || ''}
                      onChange={(event) => handleChangeRequest(event)}
                      multiline
                      rows={3}
                      unique
                      disabled={getDisabledState()}
                    />
                  </div>
                </Grid>
              )}
            />
          </Grid>
          <Grid
            container
            className={classes.MessageForm__inputGroup}
          >
            <div className={classes.MessageForm__marginBottom}>
              {(!getDisabledState() && (
                <DropZone
                  onAdd={(addedFile) => handleAddedFile(addedFile)}
                  onDrop={() => setIsLoading(true)}
                  onDelete={(deletedFile) => handleRemoveFile(deletedFile)}
                  fileObjects={files}
                  maximumNumberofFiles={555}
                  isLoading={isLoading}
                />
              )) || (
              <DropZone
                fileObjects={files}
                maximumNumberofFiles={555}
                readOnly
              />
              )}
            </div>
          </Grid>
          {(respPostData?.requiredAction?.type === 'code' || respPutData?.requiredAction?.type === 'code') && (
            <Grid
              container
              className={classes.MessageForm__secretCodeForm}
            >
              <Grid
                item
                lg={3}
              >
                <CustomInput
                  error={codeError}
                  errorText={codeErrorText}
                  variant="outlined"
                  label="Введите код из сообщения"
                  name="code"
                  value={code}
                  onChange={(event) => handleChangeCodeInput(event)}
                  size="small"
                  color="secondary"
                  unique
                  className={classes.inputField}
                  autoComplete="off"
                />
              </Grid>
              <Grid
                item
                lg={8}
                sm={6}
                xs={12}
                className={classes.MessageForm__codeWrapper}
              >
                {(showTimer && (
                  <Typography
                    className={classes.MessageForm__codeText}
                    m={0}
                  >
                    Если код не пришел, Вы можете запросить новый код через
                    <span
                      className={classes.MessageForm__codeInput}
                    >
                      <Timer
                        seconds={60}
                        handleStartTimer={handleStartTimer}
                      />
                    </span>
                  </Typography>
                )) || (!showTimer && (
                  <CustomButton
                    label="Выслать новый код"
                    modifier="primary"
                    type="submit"
                    fullWidth
                    onClick={handleResetTimer}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          <Media
            query={`(max-width: ${breakpoints.phone_large}px)`}
            render={() => (respGetData?.userRequest?.status && (
              <div className={classes.PhoneGrid__status}>
                <span
                  className={classes.PhoneGrid__header_m}
                >
                  Статус
                </span>
                <p className={classes.PhoneGrid__statusText}>
                  {getStatus(respGetData?.userRequest?.status)}
                </p>
              </div>
            )) || null}
          />
          <Grid
            container
            spacing={6}
          >
            <Media
              query="(min-width: 577px)"
              render={() => (
                <Grid
                  item
                  lg={7}
                  sm={12}
                >
                  {respGetData && (
                    <Typography
                      m={0}
                      className={classes.MessageForm__status}
                    >
                      Статус:
                      {' '}
                      {respGetData?.userRequest?.status
                        && getStatus(respGetData?.userRequest?.status)}
                    </Typography>
                  )}
                </Grid>
              )}
            />
            {(((!respGetData || respGetData?.userRequest?.status === 'draft') && !respPostData && !respPutData) && (
              <Grid
                container
                item
                lg={5}
                xs={12}
                display="flex"
                justify="flex-end"
              >
                <div className={classes.MessageForm__btnGroup}>
                  <CustomIconButton type="submit">
                    Отправить
                  </CustomIconButton>
                  <Media
                    query="(min-width: 577px)"
                    render={() => (
                      <CustomIconButton
                        type="button"
                        onClick={handleGoToHistory}
                      >
                        Отмена
                      </CustomIconButton>
                    )}
                  />
                </div>
              </Grid>
            )) || ((respPostData?.requiredAction?.type === 'sign' || respPutData?.requiredAction?.type === 'sign') && (
              <Grid
                item
                lg={5}
                xs={12}
              >
                <Grid
                  container
                  item
                  spacing={2}
                  justify="flex-end"
                >
                  <div className={classes.MessageForm__btnGroup}>
                    <CustomIconButton
                      type="submit"
                    >
                      Подписать
                    </CustomIconButton>
                    <CustomIconButton
                      type="button"
                      icon={<DownloadIcon />}
                      onClick={handleDownloadXml}
                    >
                      Скачать .xml
                    </CustomIconButton>
                    <Media
                      query="(min-width: 577px)"
                      render={() => (
                        <CustomIconButton
                          type="button"
                          onClick={handleCancelOperation}
                        >
                          Назад
                        </CustomIconButton>
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            )) || ((respPostData?.requiredAction?.type === 'code' || respPutData?.requiredAction?.type === 'code') && (
              <Grid
                item
                lg={5}
                xs={12}
              >
                <Grid
                  container
                  item
                  spacing={2}
                  justify="flex-end"
                >
                  <div className={classes.MessageForm__btnGroup}>
                    <CustomIconButton type="submit">
                      Подтвердить
                    </CustomIconButton>
                    <Media
                      query="(min-width: 577px)"
                      render={() => (
                        <CustomIconButton
                          type="button"
                          onClick={handleCancelOperation}
                        >
                          Отменить
                        </CustomIconButton>
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            )) || (
            <Grid
              item
              lg={5}
              xs={12}
            >
              <Grid
                container
                item
                spacing={2}
                justify="flex-end"
              >
                <Media
                  query="(min-width: 577px)"
                  render={() => (
                    <CustomIconButton
                      type="button"
                      onClick={handleGoToHistory}
                    >
                      Назад
                    </CustomIconButton>
                  )}
                />
              </Grid>
            </Grid>
            )}
          </Grid>
        </form>
      )}
      <CustomModal
        open={open}
        title="Обращение обновлено"
        handleClose={handleClose}
      />
      <CustomModal
        open={failedEmptyFile}
        title="Не удалось загрузить пустой файл"
        handleClose={() => setFailedEmptyFile(false)}
      />
    </>
  )
}

MessageForm.propTypes = {
  fetchFeedbackStart: PropTypes.func.isRequired,
  connectedWS: PropTypes.bool.isRequired,
  fetchSendMessageStart: PropTypes.func.isRequired,
  fetchCertificateStart: PropTypes.func.isRequired,
  fetchPostFile: PropTypes.func.isRequired,
  fetchGetFile: PropTypes.func.isRequired,
  fetchCodeStart: PropTypes.func.isRequired,
}

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
})

const mapDispatchToProps = (dispatch) => ({
  fetchFeedbackStart: (id) => dispatch(fetchFeedbackStartAsync({ id })),
  fetchCertificateStart: (obj) => dispatch(fetchCertificateStartAsync({ obj })),
  fetchCodeStart: (obj) => dispatch(fetchCodeStartAsync({ obj })),
  fetchSendMessageStart: (obj) => dispatch(fetchSendMessageStartAsync({ obj })),
  fetchPostFile: (obj) => dispatch(fetchPostFileStartAsync({ obj })),
  fetchGetFile: (id) => dispatch(fetchGetFileStartAsync({ id })),
})

export default connect(mapStateToProps, mapDispatchToProps)(MessageForm)

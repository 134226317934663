export const isRequired = (value, setError, setTextError) => {
  if (!value) {
    setError(true)
    setTextError('Поле обязательно для заполнения')
    return true
  }
  return false
}

export const isEmpty = (value) => (value
  ? Object.keys(value).length === 0 && (value.constructor === Object || Array.isArray(value))
  : true)

export const addEmptyValueToArray = (array) => (array?.length ? [''].concat(array) : [])

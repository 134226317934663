import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { ConnectionContext } from '../hoc/socket/socket'
import { getErrorSuccess } from '../redux/error/errorSlice'
import { clearAccessToken } from '../redux/user/userSlice'
import { selectCurrentUser } from '../redux/user/selector'

export default function useConnect(props) {
  const { link, startFetching, action } = props
  const context = useContext(ConnectionContext)
  const selectUser = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    // context.connection.on(link, (status, response) => {
    //   console.log(123, link)
    //   const parsedResponse = JSON.parse(response)
    //   switch (status) {
    //     case 0:
    //     case 200:
    //       // eslint-disable-next-line no-console
    //       console.log('connected')
    //       if (typeof (parsedResponse) === 'string') {
    //         dispatch(action({ parsedResponse, status }))
    //       } else if (Array.isArray(parsedResponse)) {
    //         dispatch(action({ parsedResponse, status }))
    //       } else {
    //         dispatch(action({ ...parsedResponse, status }))
    //       }
    //       break
    //     case 303:
    //       // eslint-disable-next-line no-console
    //       console.log('Need next step')
    //       dispatch(action({ ...parsedResponse, status }))
    //       break
    //     case 400:
    //       dispatch(getErrorSuccess({ ...parsedResponse }))
    //       break
    //     case 401:
    //       if (selectUser?.accessToken) {
    //         dispatch(clearAccessToken())
    //       }
    //       Cookies.remove('accessToken')
    //       break
    //     case 500:
    //       dispatch(getErrorSuccess({ ...parsedResponse }))
    //       break
    //     default:
    //       break
    //   }
    // })
    return () => {
      // context.connection.off(link)
      // eslint-disable-next-line no-console
      console.log('disconnected')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link])

  return { getData: startFetching }
}

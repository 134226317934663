import { styled } from "ui/theme";
import { IBadgeProps } from "./types";

export const StyledBadge = styled.div<IBadgeProps>`
display: inline-flex;
align-items: center;
gap: ${({ theme }) => theme.spacing.xxs};
height: 20px;
${({ variant = 'default', theme }) => variant === 'positive' ? `
  background-color: ${theme.color.bg.positive};
  color: ${theme.color.text.positive};
` : variant === 'negative' ? `
  background-color: ${theme.color.bg.negative};
  color: ${theme.color.text.negative};
`: variant === 'default' ? `
  background-color: rgba(29, 62, 111, 0.1);
  color: ${theme.color.text.primary};
`: ''}
${({ size, theme }) => `
${size === 'sm' ? `
  font-size: 10px;
  line-height: 1.2;
  padding: ${theme.spacing.xxs};
  border-radius: ${theme.borderRadius.sm};
  font-weight: ${theme.fontWeight.bold};
` : ''}
${size === 'md' ? `
  font-size: 12px;
  line-height: 1.2;
  padding: 4px 6px;
  border-radius: ${theme.borderRadius.sm};
  font-weight: ${theme.fontWeight.bold};
` : ''}
${size === 'tag' ? `
  font-size: 12px;
  line-height: 1.2;
  padding: 3px 12px 5px;
  border-radius: ${theme.borderRadius.xs};
  font-weight: ${theme.fontWeight.semibold};
` : ''}
`}
`

export const StyledBadgeValue = styled.span`
display: inline-flex;
gap: ${({ theme }) => theme.spacing.xxs};
`
import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'

import { format } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Media from 'react-media'
import endpoints from '../../api/endpoints'
import { rowsPerPage } from '../../config/constants'
import { fetchHistoryDepoFilterStartAsync, fetchHistoryDepoStartAsync, getHistoryDepoSuccess } from '../../redux/history/historySlice'
import { selectHistoryDepo, selectHistoryPortfolios, selectHistoryStatuses } from '../../redux/history/selector'
import useConnect from '../../utils/Connect'

import useStyles from './panel.styles'
import createUri from '../../utils/FilterUri'
import CustomSelect from '../CustomControls/CustomSelect'
import UsePagination from '../Pagination/Pagination'
import CustomIconButton from '../CustomControls/CustomIconButton'
import CustomDatepicker from '../CustomControls/CustomDatepicker'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import PageHeading from '../PageHeading/PageHeading'
import useArrayConnect from '../../utils/ArrayConnect'
import { ReactComponent as IconEds } from '../../assets/images/icon-eds.svg'
import { ReactComponent as IconClose } from '../../assets/images/close-icon.svg'
import {
  clearSecurities,
  deleteSecuritySuccess,
  fetchDeleteSecurityStartAsync,
  fetchGetSecurityStartAsync, fetchPerformSecurityStartAsync,
  getSecuritySuccess, performSecuritySuccess,
} from '../../redux/securities/securitiesSlice'
import { selectCurrentGetSecurity, selectCurrentPerformSecurity, selectCurrentDeleteSecurity } from '../../redux/securities/selector'
import CustomModal from '../CustomModal/CustomModal'
import CustomCheckbox from '../CustomControls/CustomCheckbox'
import { selectCurrentStatus, selectCurrentThumbprint } from '../../redux/user/selector'
import MobileFilter from '../MobileFilter/MobileFilter'
import CustomLoader from '../CustomLoader/CustomLoader'
import declOfNum from '../../utils/DeclensionWord'
import { clearError } from '../../redux/error/errorSlice'
import CustomInputSelect from '../CustomControls/CustomInputSelect'
import { addEmptyValueToArray } from '../../utils/Validate'

const initialState = {
  portfolio: {
    portfolios: [],
    currentPortfolio: '',
  },
  status: {
    statuses: [],
    currentStatus: '',
  },
  date: {
    from: null,
    to: null,
  },
}

const securityCancelUid = 'Orders.Type.Modify.Cancellation'
const securityTransferUid = 'Orders.Type.NonTrade.Depo.Transfer'
const securityInputUid = 'Orders.Type.NonTrade.Depo.In'
const securityOutputUid = 'Orders.Type.NonTrade.Depo.Out'

const DepoPanel = ({ connectedWS, fetchHistoryDepoStart, fetchHistoryFilterStart }) => {
  const classes = useStyles()

  const history = useHistory()
  const dispatch = useDispatch()

  const [portfolioSelect, setPortfolioSelect] = useState(initialState.portfolio)
  const [statusSelect, setStatusSelect] = useState(initialState.status)
  const [dateFrom, setDateFrom] = useState(initialState.date.from)
  const [dateTo, setDateTo] = useState(initialState.date.to)
  const [page, setPage] = useState(0)
  const [idFiles, setIdFiles] = useState([])
  const [arraySigned, setArraySigned] = useState([])
  const [arrayDeleteSigned, setArrayDeleteSigned] = useState([])
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [successSigned, setSuccessSigned] = useState([])
  const [failSigned, setFailSigned] = useState(null)
  const [finishSignModalOpen, setFinishSignModalOpen] = useState(false)
  const [finishSignLoaderModalOpen, setFinishSignLoaderModalOpen] = useState(false)
  const [finishDeleteSignModalOpen, setFinishDeleteSignModalOpen] = useState(false)
  const [isCleared, setIsCleared] = useState(false)
  const [selected, setSelected] = useState([])
  const [filterUri, setFilterUri] = useState('')

  const getThumbprintSelector = useSelector(selectCurrentThumbprint)
  const getUserStatus = useSelector(selectCurrentStatus)
  const historyDepoData = useSelector(selectHistoryDepo)
  const filterPortfolios = useSelector(selectHistoryPortfolios)
  const filterStatuses = useSelector(selectHistoryStatuses)
  const getPerformSecuritySelector = useSelector(selectCurrentPerformSecurity)
  const getRespSecuritySelector = useSelector(selectCurrentGetSecurity)
  const deleteSecuritySelector = useSelector(selectCurrentDeleteSecurity)

  const getRespSecurity = useMemo(() => getRespSecuritySelector, [getRespSecuritySelector])
  const getPerformSecurity = useMemo(() => getPerformSecuritySelector, [getPerformSecuritySelector])

  const fetchPerformSecurityStart = (data, id) => {
    dispatch(fetchPerformSecurityStartAsync({ data, id }))
  }

  const fetchGetSecurityStart = (id) => {
    dispatch(fetchGetSecurityStartAsync({ id }))
  }

  const fetchDeleteSecurityStart = (id) => {
    dispatch(fetchDeleteSecurityStartAsync({ id }))
  }

  const setSelectedAll = () => {
    const newSelecteds = historyDepoData?.orders?.map((item) => (item?.status?.name === 'Черновик' && item?.id))?.filter(Boolean) ?? []

    setSelected(newSelecteds)
  }

  const { getData } = useConnect({ link: `GET /api${endpoints.history.getDepo}`, startFetching: fetchHistoryDepoStart, action: getHistoryDepoSuccess })
  const { getData: moneyFilter } = useConnect({ link: `GET /api${endpoints.history.moneyFilter()}`, startFetching: fetchHistoryFilterStart, action: getHistoryDepoSuccess })
  const { getData: putPerformData } = useArrayConnect({
    link: `PUT /api${endpoints.securities.putSecurity(null)}`, idList: idFiles, startFetching: fetchPerformSecurityStart, action: performSecuritySuccess,
  })
  const { getData: deleteData } = useArrayConnect({
    link: `DELETE /api${endpoints.securities.getSecurity()}`, idList: idFiles, startFetching: fetchDeleteSecurityStart, action: deleteSecuritySuccess,
  })
  useConnect({
    link: `DELETE /api${endpoints.securities.getSecurity()}`,
  })
  const { getData: getSecurityData } = useArrayConnect({
    link: `GET /api${endpoints.securities.getSecurity()}`, idList: idFiles, startFetching: fetchGetSecurityStart, action: getSecuritySuccess,
  })

  useEffect(() => {
    dispatch(clearSecurities())
    setIsCleared(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (connectedWS) {
      setArraySigned([])
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  useEffect(() => {
    if (filterPortfolios?.length) {
      const portfolioNames = []
      filterPortfolios.map((portfolio) => {
        portfolioNames.push(portfolio.name)
        return portfolio
      })
      setPortfolioSelect({ ...portfolioSelect, portfolios: portfolioNames })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterPortfolios])

  useEffect(() => {
    if (filterStatuses?.length) {
      const statusNames = []
      filterStatuses.map((status) => {
        statusNames.push(status.name)
        return status
      })
      setStatusSelect({ ...statusSelect, statuses: statusNames })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatuses])

  useEffect(() => {
    if (historyDepoData?.orders?.length) {
      const currentOrdersLength = historyDepoData?.orders?.length
      if (page * rowsPerPage > currentOrdersLength) {
        setPage(0)
      }
      if (getUserStatus?.useCertificate && selected.length) {
        const arrayFoundChecked = []
        historyDepoData.orders.forEach((item) => {
          if (selected.some((selectElm) => item.id === selectElm)) {
            arrayFoundChecked.push(item.id)
          }
        })
        setSelected(arrayFoundChecked)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyDepoData])

  const handleOpenRequest = (id, order) => () => {
    let nextLocation = ''
    if ((order?.type?.uid === securityCancelUid
      && order?.affectedOrder?.type?.uid === securityTransferUid)
      || order?.type?.uid === securityTransferUid) {
      nextLocation = {
        pathname: `/transfers/securities/transfer/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    } else if ((order?.type?.uid === securityCancelUid
      && order?.affectedOrder?.type?.uid === securityInputUid)
      || order?.type?.uid === securityInputUid) {
      nextLocation = {
        pathname: `/transfers/securities/input/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    } else if ((order?.type?.uid === securityCancelUid
      && order?.affectedOrder?.type?.uid === securityOutputUid)
      || order?.type?.uid === securityOutputUid) {
      nextLocation = {
        pathname: `/transfers/securities/output/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    }
    history.push(nextLocation)
  }

  const handleChangePortfolioSelect = (event, newValue) => {
    setPortfolioSelect({
      ...portfolioSelect,
      currentPortfolio: newValue,
    })
  }
  const handleChangeStatusSelect = (event) => setStatusSelect({
    ...statusSelect,
    [event.target.name]: event.target.value,
  })
  const getFormatDate = (date, formatDate) => format(new Date(date), formatDate)
  const formatDateFrom = useMemo(() => dateFrom && getFormatDate(dateFrom, 'yyyy-MM-dd'), [dateFrom])
  const formatDateTo = useMemo(() => dateTo && getFormatDate(dateTo, 'yyyy-MM-dd'), [dateTo])
  const handleAcceptFilter = () => {
    const statusUID = filterStatuses?.find(
      (item) => item.name === statusSelect.currentStatus,
    )?.uid || null
    const accountId = filterPortfolios?.find(
      (item) => item.name === portfolioSelect.currentPortfolio,
    )?.id || null
    let uri = 'Count=100'
    if (statusUID || accountId || formatDateFrom || formatDateTo) {
      uri = createUri({
        accountId, dateFrom: formatDateFrom, dateTo: formatDateTo, statusUID, name: 'ordersDepo', condition: 'and',
      })
      uri = `Count=100&Filter=${uri}`
    }
    setFilterUri(uri)
    moneyFilter(uri)
  }

  const handleResetFilter = () => {
    setFilterUri('')
    moneyFilter('Count=100')
    setPortfolioSelect({
      ...portfolioSelect,
      currentPortfolio: initialState.portfolio.currentPortfolio,
    })
    setStatusSelect({
      ...statusSelect,
      currentStatus: initialState.status.currentStatus,
    })
    setDateFrom(
      initialState.date.from,
    )
    setDateTo(
      initialState.date.to,
    )
  }

  const handleChangeDateFrom = (date) => {
    setDateFrom(date)
  }

  const handleChangeDateTo = (date) => {
    setDateTo(date)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedAll()
      return
    }
    setSelected([])
  }

  const handleSelectClick = (event, id, currentStatusRow) => {
    event.preventDefault()
    event.stopPropagation()
    if (currentStatusRow !== 'Черновик') {
      return
    }
    const selectedIndex = selected.findIndex((itemId) => itemId === id)
    const orders = historyDepoData?.orders
    let newSelected = []

    if (selectedIndex < 0) {
      newSelected = newSelected.concat(selected, orders.find((order) => order.id === id).id)
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1, selected.length),
      )
    }
    setSelected(newSelected)
  }

  const handleClosePerformModal = () => {
    setPerformModalOpen(false)
  }

  const handleCloseFinishSignModal = () => {
    setFinishSignModalOpen(false)
    setFailSigned(null)
  }

  const callActionOnOk = (actionMethod) => {
    if (connectedWS) {
      setIdFiles(selected)
      selected.forEach((id) => {
        actionMethod(id)
      })
      setFinishSignLoaderModalOpen(true)
    }
  }

  const handlePerformOk = () => {
    callActionOnOk(getSecurityData)
    handleClosePerformModal()
  }

  useEffect(() => {
    if (getPerformSecurity?.status && isCleared) {
      if (getPerformSecurity?.status === 303) {
        const reqObj = { ...getPerformSecurity.order }
        reqObj.thumbPrint = getThumbprintSelector
        reqObj.signedXml = getPerformSecurity?.requiredAction?.signAction?.xml
        putPerformData(reqObj, reqObj?.id)
        return
      }
      if (getPerformSecurity?.status === 200) {
        setArraySigned((prev) => [
          ...prev,
          true,
        ])
        return
      }
      setArraySigned((prev) => [
        ...prev,
        false,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPerformSecurity, isCleared])

  useEffect(() => {
    if (deleteSecuritySelector?.status && isCleared) {
      if (deleteSecuritySelector?.status === 200) {
        setArrayDeleteSigned((prev) => [
          ...prev,
          true,
        ])
        return
      }
      setArrayDeleteSigned((prev) => [
        ...prev,
        false,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSecuritySelector, isCleared])

  useEffect(() => {
    if ((arraySigned?.length && selected.length)
      && arraySigned?.length === selected.length) {
      setSuccessSigned(arraySigned.filter((x) => x).length)
      setFailSigned(arraySigned.filter((x) => !x).length)
      setArraySigned([])
      setSelected([])
      setFinishSignLoaderModalOpen(false)
      setFinishSignModalOpen(true)
      if (connectedWS) {
        if (filterUri) {
          moneyFilter(filterUri)
        } else {
          getData()
        }
      }
      return
    }
    if ((arrayDeleteSigned?.length && selected.length)
      && arrayDeleteSigned?.length === selected.length) {
      setSuccessSigned(arrayDeleteSigned.filter((x) => x).length)
      setFailSigned(arrayDeleteSigned.filter((x) => !x).length)
      setArrayDeleteSigned([])
      setSelected([])
      setFinishSignLoaderModalOpen(false)
      setFinishDeleteSignModalOpen(true)
      if (connectedWS) {
        if (filterUri) {
          moneyFilter(filterUri)
        } else {
          getData()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arraySigned, selected, arrayDeleteSigned, connectedWS])

  useEffect(() => {
    if (getRespSecurity?.order && isCleared) {
      const reqObj = { ...getRespSecurity?.order }
      if (getThumbprintSelector) {
        reqObj.thumbPrint = getThumbprintSelector
      }
      putPerformData(reqObj, reqObj?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRespSecurity, isCleared])

  useEffect(() => {
    if (failSigned) {
      dispatch(clearError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failSigned])

  const isSelected = (id) => selected.some((itemId) => itemId === id)

  const handleRowSign = (event) => {
    event.preventDefault()

    if (selected.length) {
      setPerformModalOpen(true)
    }
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const handleDeleteOk = () => {
    callActionOnOk(deleteData)
    setDeleteModalOpen(false)
  }

  const handleRowRemove = (event) => {
    event.preventDefault()

    if (selected.length) {
      setDeleteModalOpen(true)
    }
  }

  const switchClass = (selectedLength) => (selectedLength > 0
    ? classnames(classes.DepoPanel__multipleSign, classes.DepoPanel__multipleSignChecked)
    : classnames(classes.DepoPanel__multipleSign, classes.DepoPanel__multipleSign_nonChecked))

  const getDeclensionWordOrder = (number) => declOfNum(+number, ['поручение', 'поручения', 'поручений'])

  const getDeclensionWordSign = (number) => declOfNum(+number, ['подписано', 'подписаны', 'подписаны'])

  const getDeclensionWordDelete = (number) => declOfNum(+number, ['удалено', 'удалены', 'удалены'])

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <PageHeading text="История поручений" />
      <Box className={classes.DepoPanel__BoxWrapper}>
        <Media
          query="(min-width: 577px)"
          render={() => (
            <>
              <Grid
                className={classes.inputsContainer}
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                >
                  <CustomInputSelect
                    labelId="count-select-label"
                    label="Портфель"
                    name="currentPortfolio"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={portfolioSelect?.currentPortfolio || ''}
                    options={addEmptyValueToArray(
                      portfolioSelect?.portfolios?.map((option) => option),
                    )}
                    onChange={handleChangePortfolioSelect}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                >
                  <CustomSelect
                    labelId="count-select-label"
                    name="currentStatus"
                    label="Статус"
                    value={statusSelect?.currentStatus}
                    onChange={handleChangeStatusSelect}
                    unique
                  >
                    {statusSelect?.statuses?.map((name, key) => (
                      (
                        <MenuItem
                          key={key.toString() + name}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      )
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                >
                  <CustomDatepicker
                    label="Период с"
                    maxDate={dateTo}
                    value={dateFrom}
                    setDateFrom={setDateFrom}
                    isDateFrom
                    onChange={handleChangeDateFrom}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  lg={3}
                >
                  <CustomDatepicker
                    label="Период по"
                    minDate={dateFrom}
                    value={dateTo || dateFrom}
                    setDateTo={setDateTo}
                    isDateTo
                    onChange={handleChangeDateTo}
                    unique
                  />
                </Grid>
              </Grid>
              <Grid
                item
                style={{ marginTop: 22 }}
              >
                <div className={classes.btnGroup}>
                  <CustomIconButton
                    type="button"
                    onClick={handleAcceptFilter}
                    unique
                    needLine
                    noIcon
                  >
                    Применить
                  </CustomIconButton>
                  <CustomIconButton
                    type="button"
                    onClick={handleResetFilter}
                    unique
                    noIcon
                  >
                    Очистить
                  </CustomIconButton>
                </div>
              </Grid>
              {getUserStatus?.useCertificate && (
                <div className={switchClass(selected.length)}>
                  <CustomCheckbox
                    checked={selected.length > 0}
                    onChange={handleSelectAllClick}
                    size="small"
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                  <div
                    className={classes.DepoPanel__sign}
                    onClick={handleRowSign}
                    aria-hidden="true"
                  >
                    <IconEds />
                    Подписать
                  </div>
                  <div
                    className={classes.DepoPanel__remove}
                    onClick={handleRowRemove}
                    aria-hidden="true"
                  >
                    <IconClose />
                    Удалить
                  </div>
                </div>
              )}
              <TableContainer
                className={classes.tableContainer}
                style={{ paddingTop: 9 }}
              >
                <Table aria-label="assetsMoney table">
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      {getUserStatus?.useCertificate && (<TableCell padding="checkbox" />)}
                      <TableCell className={classes.tableHeaderCell}>Дата</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Номер</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Тип&nbsp;</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Портфель&nbsp;</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Инструмент&nbsp;</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Количество&nbsp;</TableCell>
                      <TableCell className={classes.tableHeaderCell}>Статус&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historyDepoData?.orders?.slice(
                      ((page) * (rowsPerPage)), ((page + 1) * (rowsPerPage)),
                    ).map((row, key) => {
                      const formatDate = getFormatDate(row?.dateTime, "dd.MM.yyyy'T'HH:mm").replace('T', ' ')
                      let isItemSelected = ''
                      let labelId = ''
                      if (getUserStatus?.useCertificate) {
                        isItemSelected = isSelected(row.id)
                        labelId = `enhanced-table-checkbox-${key}`
                      }
                      return (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          aria-checked={isItemSelected}
                          key={row.id}
                          onClick={handleOpenRequest(row.id, row)}
                          selected={isItemSelected}
                        >
                          {getUserStatus?.useCertificate && (
                            <TableCell padding="checkbox">
                              <CustomCheckbox
                                onClick={(event) => handleSelectClick(
                                  event, row.id, row?.status?.name,
                                )}
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                                size="small"
                              />
                            </TableCell>
                          )}
                          <TableCell
                            component="th"
                            scope="row"
                            className={classes.tableBodyCell}
                            style={{ minWidth: 160 }}
                          >
                            {formatDate}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ minWidth: 75 }}
                          >
                            {row?.number}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ minWidth: 126 }}
                          >
                            {row?.type?.name}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ minWidth: 116 }}
                          >
                            {row?.clientPortfolio?.name}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                          >
                            {row?.security?.name || row?.affectedOrder?.security?.name}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ minWidth: 110 }}
                          >
                            {row?.volume || row?.affectedOrder?.volume}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ minWidth: 120 }}
                          >
                            {row?.status?.name}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        />
        <Media
          query={`(max-width: ${breakpoints.phone_large}px)`}
          render={() => historyDepoData?.orders?.map((row) => (
            <div
              className={classes.PhoneGrid}
              key={row.id}
              onClick={handleOpenRequest(row.id, row)}
              aria-hidden="true"
            >
              <div className={classes.PhoneGrid__depoItem}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Тип операции
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.type?.name}
                </p>
              </div>
              <div className={classes.PhoneGrid__depoItem}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Номер
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.number}
                </p>
              </div>
              <div className={classes.PhoneGrid__depoItem}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Портфель
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.clientPortfolio?.name}
                </p>
              </div>
              <div className={classes.PhoneGrid__depoItem}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Дата отправления
                </span>
                <p className={
                  classnames(classes.PhoneGrid__value, classes.PhoneGrid__value_medium)
                }
                >
                  {row?.dateTime && format(new Date(row?.dateTime), "dd.MM.yyyy'T'HH:mm").replace('T', ' ')}
                </p>
              </div>
              <div className={classes.PhoneGrid__depoItem}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Инструмент
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.security?.name || row?.affectedOrder?.security?.name}
                </p>
              </div>
              <div className={classes.PhoneGrid__depoItem}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Количество
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.volume || row?.affectedOrder?.volume}
                </p>
              </div>
              <div className={classes.PhoneGrid__depoItem}>
                <span
                  className={classes.PhoneGrid__header}
                >
                  Статус
                </span>
                <p className={classes.PhoneGrid__value}>
                  {row?.status?.name}
                </p>
              </div>
            </div>
          )) || null}
        />
        <Media
          query={`(max-width: ${breakpoints.phone_large}px)`}
          render={() => (
            <MobileFilter
              handleAcceptFilter={handleAcceptFilter}
              handleResetFilter={handleResetFilter}
              constItems={[
                portfolioSelect?.currentPortfolio,
                statusSelect?.currentStatus,
                dateFrom,
                dateTo,
              ]}
            >
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                >
                  <CustomInputSelect
                    labelId="count-select-label"
                    label="Портфель"
                    name="currentPortfolio"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    value={portfolioSelect?.currentPortfolio || ''}
                    options={addEmptyValueToArray(
                      portfolioSelect?.portfolios?.map((option) => option),
                    )}
                    onChange={handleChangePortfolioSelect}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CustomSelect
                    labelId="count-select-label"
                    name="currentStatus"
                    label="Статус"
                    value={statusSelect?.currentStatus}
                    onChange={handleChangeStatusSelect}
                    unique
                  >
                    {statusSelect?.statuses?.map((name, key) => (
                      (
                        <MenuItem
                          key={key.toString() + name}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      )
                    ))}
                  </CustomSelect>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CustomDatepicker
                    label="Период с"
                    maxDate={dateTo}
                    value={dateFrom}
                    setDateFrom={setDateFrom}
                    isDateFrom
                    onChange={handleChangeDateFrom}
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CustomDatepicker
                    label="Период по"
                    minDate={dateFrom}
                    value={dateTo || dateFrom}
                    setDateTo={setDateTo}
                    isDateTo
                    onChange={handleChangeDateTo}
                    unique
                  />
                </Grid>
              </Grid>
            </MobileFilter>
          )}
        />
        <Media
          query="(min-width: 577px)"
          render={() => (
            <Box pt={3}>
              <UsePagination
                count={
                  historyDepoData ? Math.floor(historyDepoData?.orders?.length / rowsPerPage) : 0
                }
                currentPage={page}
                onChangePage={handleChangePage}
              />
            </Box>
          )}
        />
        <CustomModal
          open={performModalOpen}
          title={`Вы действительно хотите подписать ${selected.length} ${getDeclensionWordOrder(selected.length)}?`}
          handleClose={handleClosePerformModal}
          callbacks={{
            withOk: handlePerformOk,
            withCancel: () => {
              handleClosePerformModal()
            },
          }}
        />
        <CustomModal
          open={deleteModalOpen}
          title={`Вы действительно хотите удалить ${selected.length} ${getDeclensionWordOrder(selected.length)}?`}
          handleClose={handleCloseDeleteModal}
          callbacks={{
            withOk: handleDeleteOk,
            withCancel: () => {
              handleCloseDeleteModal()
            },
          }}
        />
        <CustomModal
          isNoIcons
          open={finishSignModalOpen}
          title={`${(successSigned && `${successSigned} ${getDeclensionWordOrder(successSigned)} успешно ${getDeclensionWordSign(successSigned)
          } \n`) || ''} ${(failSigned && `${failSigned} ${getDeclensionWordOrder(failSigned)} не ${getDeclensionWordSign(failSigned)}`) || ''
          }`}
          handleClose={handleCloseFinishSignModal}
          callbacks={{
            withClose: () => {
              setFinishSignModalOpen(false)
            },
          }}
        />
        <CustomModal
          isNoIcons
          open={finishDeleteSignModalOpen}
          title={`${(successSigned && `${successSigned} ${getDeclensionWordOrder(successSigned)} успешно ${getDeclensionWordDelete(successSigned)} \n`) || ''
          } ${(failSigned && `${failSigned} ${getDeclensionWordOrder(failSigned)} не ${getDeclensionWordDelete(failSigned)}`) || ''
          }`}
          handleClose={handleCloseFinishSignModal}
          callbacks={{
            withClose: () => {
              setFinishDeleteSignModalOpen(false)
            },
          }}
        />
        <CustomModal
          isNoIcons
          open={finishSignLoaderModalOpen}
          title={<CustomLoader />}
          callbacks={{
            withCancel: () => { },
          }}
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}

DepoPanel.propTypes = {
  fetchHistoryDepoStart: PropTypes.func.isRequired,
  fetchHistoryFilterStart: PropTypes.func.isRequired,
  connectedWS: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ connection: { state } }) => ({
  connectedWS: state,
})

const mapDispatchToProps = (dispatch) => ({
  fetchHistoryDepoStart: () => dispatch(fetchHistoryDepoStartAsync()),
  fetchHistoryFilterStart: (obj) => dispatch(fetchHistoryDepoFilterStartAsync({ obj })),
})

export default connect(mapStateToProps, mapDispatchToProps)(DepoPanel)

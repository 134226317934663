import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { FormControl, TextField } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import classNames from 'classnames'
import FormHelperText from '@material-ui/core/FormHelperText'
import useStyles from './CustomControls.style'

const CustomInput = forwardRef(({
  label, otherData, helperText, error, errorText, mask, required, unique, isAllowed,
  decimalScale, allowedDecimalSeparators, allowNegative, autoComplete, ...props
}, ref) => {
  const classes = useStyles()

  return (
    <FormControl
      error={error}
      fullWidth
      className={classNames(classes.formControl, { [classes.uniqueFormControl]: unique })}
      required={required}
      data-field-name={otherData}
    >
      {label && (
        <FormLabel className={classes.inputLabel}>
          {label}
        </FormLabel>
      )}
      {mask ? (
        <NumberFormat
          autoComplete={autoComplete}
          customInput={TextField}
          className={classes.inputField}
          required={required}
          mask={mask}
          isAllowed={isAllowed}
          decimalScale={decimalScale}
          allowedDecimalSeparators={allowedDecimalSeparators}
          allowNegative={allowNegative}
          {...props}
        />
      ) : (
        <TextField
          autoComplete={autoComplete}
          className={classes.inputField}
          required={required}
          ref={ref}
          {...props}
        />
      )}
      {(helperText || error) && (
        <FormHelperText className={classNames({
          [classes.defaultHelperText]: helperText,
        })}
        >
          {helperText || errorText}
        </FormHelperText>
      )}
    </FormControl>
  )
})

CustomInput.defaultProps = {
  label: null,
  autoComplete: null,
  helperText: null,
  mask: null,
  error: false,
  errorText: '',
  unique: false,
  required: false,
  isAllowed: () => {},
  decimalScale: null,
  allowedDecimalSeparators: null,
  allowNegative: null,
  otherData: null,
}

CustomInput.propTypes = {
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  helperText: PropTypes.string,
  mask: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  unique: PropTypes.bool,
  required: PropTypes.bool,
  isAllowed: PropTypes.func,
  decimalScale: PropTypes.number,
  allowedDecimalSeparators: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
  ]),
  allowNegative: PropTypes.bool,
  otherData: PropTypes.string,
}

export default CustomInput

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  MobileAppBar: {
    flexGrow: 1,
    '& > .MuiPaper-root': {
      width: 'calc(100% - 46px)',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
      boxShadow: 'none',
    },
  },
  MobileAppBar__toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 576px)': {
      minHeight: 54,
      paddingLeft: 5,
      paddingRight: 11,
    },
  },
  label: {
    marginTop: 6,
    marginBottom: 0,
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.type === 'light' ? '#313A4E' : '#fff',
  },
  label_greyBlue: {
    color: '#91B1BF',
  },
  MobileAppBar__userControls: {
    '& > a': {
      position: 'relative',
    },
    '& .MuiButtonBase-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '@media (max-width: 576px)': {
        paddingLeft: 10,
        paddingRight: 10,
      },
      '& + span': {
        position: 'absolute',
        top: 1,
        right: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: '#EE2D30',
        fontSize: 7,
        lineHeight: '9px',
        textAlign: 'center',
        color: '#FFF',
      },
    },
    '& svg': {
      fill: '#92B2C0',
    },
  },
  MobileAppBar__badgeWrapper: {
    '& .MuiBadge-badge': {
      top: 4,
      right: 2,
      minWidth: 12,
      height: 12,
      padding: '0 2px',
      fontSize: 7,
      fontWeight: 700,
      lineHeight: 9,
    },
  },
  leftSidebar: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 46,
    height: '100%',
    padding: '13px 9px 22px',
    backgroundColor: '#313A4E',
    zIndex: 100,
  },
  leftSidebar__topRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftSidebar__logo: {
    minWidth: 28,
    height: 34,
    marginBottom: 20,
  },
  leftSidebar__pageIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 20,
    '& svg': {
      maxHeight: 24,
      fill: '#fff',
    },
  },
  leftSidebar__returnBtn: {
    position: 'absolute',
    top: '50%',
    display: 'flex',
    marginTop: -13,
    padding: 4,
    border: 0,
    outline: 'none',
    backgroundColor: 'transparent',
  },
  leftSidebar__menuButton: {
    marginLeft: 0,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      fill: '#91B1BF',
    },
  },
  mobileMenu: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(255, 255, 255, .7)',
    },
    '& .MuiDrawer-paper': {
      flexDirection: 'row',
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  mobileMenu__main: {
    position: 'relative',
    zIndex: 1,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: 187,
    height: '100%',
    padding: '13px 9px',
    backgroundColor: '#313A4E',
  },
  mobileMenu__topRow: {
    display: 'flex',
    '& $label': {
      marginTop: 9,
    },
  },
  mobileMenu__logo: {
    width: 28,
    height: 34,
    marginRight: 14,
    fill: '#fff',
  },
  mobileMenu__list: {
    '& .MuiButtonBase-root': {
      padding: '11px 0',
    },
    '& .MuiListItemIcon-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 24,
      marginRight: 16,
      '& svg': {
        fill: '#91B1BF',
      },
    },
    '& .portfolio-icon': {
      '@media (max-width: 576px)': {
        width: 24,
        height: 20,
      },
    },
    '& .sync-icon': {
      '@media (max-width: 576px)': {
        width: 23,
        height: 20,
      },
    },
    '& .trade-icon': {
      '@media (max-width: 576px)': {
        width: 16,
        height: 20,
      },
    },
    '& .trending-icon': {
      '@media (max-width: 576px)': {
        width: 22,
        height: 13,
      },
    },
    '& .document-icon': {
      '@media (max-width: 576px)': {
        width: 22,
        height: 13,
      },
    },
    '& .list-icon': {
      '@media (max-width: 576px)': {
        width: 18,
        height: 23,
      },
    },
    '& .compare-icon': {
      '@media (max-width: 576px)': {
        width: 22,
        height: 16,
      },
    },
    '& .message-icon': {
      '@media (max-width: 576px)': {
        width: 21,
        height: 15,
      },
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiTypography-root': {
      color: '#91B1BF',
    },
  },
  mobileMenu__bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& .MuiList-root': {
      position: 'relative',
      top: 2,
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: 0,
      },
    },
    '& > .MuiButtonBase-root': {
      position: 'relative',
      top: 4,
      padding: 4,
    },
  },
  mobileMenu__additional: {
    position: 'fixed',
    left: 187,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 76,
    height: '100%',
    padding: '13px 9px',
    backgroundColor: '#91B1BF',
    transform: 'translateX(-100%)',
    transformOrigin: '0 0',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  mobileMenu__additional_active: {
    transform: 'translateX(0)',
  },
  mobileMenu__actions: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileMenu__action: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 28,
    },
  },
  mobileMenu__actionItem: {
    position: 'relative',
    display: 'flex',
    padding: '6px 0',
    border: 0,
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'left',
    color: '#313A4E',
    '&:not(:last-child)': {
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        bottom: 0,
        width: 20,
        height: 1,
        marginLeft: -10,
        backgroundColor: '#fff',
      },
    },
    '& svg': {
      fill: '#000',
    },
  },
  mobileMenu__actionItem_active: {
    color: '#fff',
    '& svg': {
      fill: '#fff',
    },
  },
}))

export default useStyles

import {
  createApi,
  fetchBaseQuery,
  retry,
} from '@reduxjs/toolkit/query/react'
import type { FetchArgs } from "@reduxjs/toolkit/query/react";
import { baseURL } from '../config/api'
import { TRootState } from './types'
import { clearAccessToken } from "./user/userSlice";
import Cookies from "js-cookie";
import {getErrors} from "./errorsModal/errorsModalSlice";

const baseQueryWithRetry = retry( async (args: string | FetchArgs, api, extraOptions) => {
  const response = await fetchBaseQuery(
    {
      baseUrl: `${baseURL}/api/v3`,
      prepareHeaders: (headers, { getState }) => {
        const token = (getState() as TRootState)?.user?.currentUser?.accessToken
        if (token) {
          headers.set('authorization', `Bearer ${token}`)
        }
        headers.set('Content-Type', 'application/json')
        return headers
      },
    })
  (args, api, extraOptions)

  if (response?.error?.status === 400) {
    // @ts-ignore
    api.dispatch(getErrors(response.error?.data?.errors))
  }
    // @ts-ignore
  if (response?.error?.originalStatus === 401) {
    Cookies.remove('accessToken')
    api.dispatch(clearAccessToken())
  }

  if (response?.error?.status === 403) {
    console.log(response.error)
  }

    return response
}, { maxRetries: 0 })


/*
* Add any endpoints to existing queryApi with .injectEndpoints
* Example:
*
* const someNewApi = queryApi.injectEndpoints({
*   endpoints: (builder) => ({
*     someEndpoint: ...
*   })
* })
*
* */

export const queryApi = createApi({
  reducerPath: 'queryApi',
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({}),
})

import React from 'react'
import { useSwitchLanguageTabs } from 'ui/components/pages/Profile/hooks'
import { LANGUAGES_LIST } from 'ui/components/pages/Profile/types'
import { Accordion } from 'ui/components/molecules/Accordion'
import { Field, Select } from 'ui/components/molecules'
import { FormCol, FormRow, TextField } from 'ui/components/atoms'
import { TextArea } from 'ui/components/atoms/TextArea'

import type { FC } from 'react'
import type { FirmInfoProps } from './types'
import useMedia from 'hooks/useMedia'
import CustomerBlocksAccordion from '../CustomerBlocksAccordion'

/**
 * Фирменное наименование с указанием организационно-правовой формы
 *
 * @param fullNameEn - Полное наименование (на английском).
 * @param fullNameRu - Полное наименование (на русском).
 * @param shortNameEn - Краткое наименование (на английском).
 * @param shortNameRu - Краткое наименование (на русском).
 * @param registerCountry - Страна регистрации.
 * @param registerLocation - Место государственной регистрации (местонахождение) на русском языке.
 * @param isOpen - Открыт ли по умолчанию аккордеон.
 *
 * @constructor
 */
export const FirmInfo: FC<FirmInfoProps> = ({
  fullNameEn = '',
  fullNameRu = '',

  shortNameEn = '',
  shortNameRu = '',

  registerCountryRu = '',
  registerCountryEn = '',

  registerLocationEn = '',
  registerLocationRu = '',

  isOpen = false,
}) => {
  const { TabBar, activeTab } = useSwitchLanguageTabs({ initialLanguage: LANGUAGES_LIST.RU })

  const isRuLang = activeTab === LANGUAGES_LIST.RU

  const fullName = isRuLang ? fullNameRu : fullNameEn
  const shortName = isRuLang ? shortNameRu : shortNameEn
  const registerCountry = isRuLang ? registerCountryRu : registerCountryEn
  const registerLocation = isRuLang ? registerLocationRu : registerLocationEn

  return (
    <CustomerBlocksAccordion
      title="Фирменное наименование с&nbsp;указанием организационно-правовой формы"
      isOpenDefault={isOpen}
    >
      <FormCol>
        <TabBar />
      </FormCol>

      {/*
          ПОЛНОЕ НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ
        */}
      <FormCol>
        <Field label="Полное наименование организации">
          <TextArea disabled autoresize value={fullName} minAreaHeight={42} />
        </Field>
      </FormCol>

      <FormRow tabletRowDirection gap={16} gapTablet={16}>
        {/*
          КРАТКОЕ НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ
        */}
        <Field label="Краткое наименование организации">
          <TextField disabled value={shortName} />
        </Field>

        {/*
          СТРАНА РЕГИСТРАЦИИ
        */}
        <Field label="Страна регистрации">
          <Select isDisabled value={registerCountry} />
        </Field>
      </FormRow>

      {/*
        МЕСТО ГОСУДАРСТВЕННОЙ РЕГИСТРАЦИИ (МЕСТОНАХОЖДЕНИЕ ОРГАНИЗАЦИИ)
      */}
      <FormCol>
        <Field label="Место государственной регистрации (местонахождение организации)">
          <TextField disabled value={registerLocation} />
        </Field>
      </FormCol>
    </CustomerBlocksAccordion>
  )
}

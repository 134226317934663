import ReactInputMask from 'react-input-mask'
import { css } from 'styled-components'
import { styled } from 'ui/theme'
import { ICurrencyField, ITextFieldProps } from './types'
import CurrencyInput from 'react-currency-input-field'

export const StyledTextFieldCommon = css<ITextFieldProps>`
  width: 100%;
  height: 44px;
  padding: 12px;
  border: 1px solid
    ${({ theme, hasError }) => (hasError ? theme.color.text.negative : theme.color.border.default)};
  background-color: ${({ theme }) => theme.color.bg.secondary};
  color: ${({ theme }) => theme.color.text.primary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  &:focus {
    border: 1px solid ${({ theme }) => theme.color.button.primary};
    outline: none;
  }
  &:disabled {
    border-color: transparent;
    color: ${({ theme }) => theme.color.text.secondary};
    -webkit-text-fill-color: ${({ theme }) => theme.color.text.secondary};
    opacity: 1
  }
  &::placeholder {
    color: ${({ theme }) => theme.color.text.secondary};
  }
  ${({ hasError, theme }) =>
    hasError
      ? `
    border-color: ${theme.color.text.negative};
  `
      : ''};
  ${({ theme }) => theme.font.body2}

  ${({ type }) =>
    type === 'number' &&
    `
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  `};
`

export const StyledTextField = styled.input<ITextFieldProps>`
  ${StyledTextFieldCommon}
`

export const StyledMaskedTextField = styled(ReactInputMask)<ITextFieldProps>`
  ${StyledTextFieldCommon}
`

export const StyledCurrencyTextField = styled(CurrencyInput)<ICurrencyField>`
  ${StyledTextFieldCommon}
`

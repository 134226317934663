import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  backgroundColor: theme.palette.type === 'light' ? '#fff' : '#363E47',
  dropZone: {
    borderTop: '#91B1BF solid 1px',
    borderLeft: '#91B1BF solid 1px',
    borderRight: '#91B1BF solid 1px',
    borderBottom: (props) => (props.needBorderBottom && '#91B1BF solid 1px') || 'none',
    borderRadius: 0,
    minHeight: (props) => props.minHeight,
    width: (props) => (props.fullWidth ? '100%' : props.width),
    maxHeight: 48,
    '&.MuiDropzoneArea-root': {
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
      outline: 'none',
    },
    '& .MuiDropzoneArea-textContainer': {
      display: 'flex',
      alignItems: 'center',
      minHeight: 43,
      paddingLeft: 3,
      '& .MuiDropzoneArea-icon': {
        position: 'absolute',
        top: 9,
        width: 25,
        height: 25,
        color: '#91B1BF',
      },
      '& .MuiDropzoneArea-text': {
        marginLeft: 34,
        marginTop: 10,
        marginBottom: 10,
        fontSize: 14,
        lineHeight: '14px',
        textAlign: 'left',
        color: theme.palette.type === 'light' ? '#292D3499' : '#fff',
      },
    },
    '& .MuiDropzonePreviewList-root': {
      width: '100%',
      margin: 0,
      '& .MuiGrid-item': {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 'fit-content',
        '& .MuiDropzonePreviewList-image': {
          marginRight: 15,
          color: '#91B1BF',
          width: 30,
          height: 25,
        },
        '&.MuiDropzonePreviewList-imageContainer > svg': {
          minWidth: 16,
          marginRight: 10,
          '& + .MuiTypography-body1': {
            textAlign: 'left',
          },
        },
        '& .MuiDropzonePreviewList-removeButton': {
          position: 'relative',
          top: 0,
          right: 0,
          boxShadow: 'unset',
          backgroundColor: 'transparent',
          width: 25,
          height: 25,
          marginLeft: 15,
          '& .MuiFab-label > .MuiSvgIcon-root': {
            color: '#FBC15E',
          },
        },
      },
    },
    '& input[disabled] + div + div .MuiDropzonePreviewList-removeButton': {
      display: 'none',
    },
    '& input[disabled] + .MuiDropzoneArea-textContainer': {
      display: 'none',
    },
  },
  dropZone__fileName: {
    paddingLeft: 15,
    paddingRight: 5,
    fontSize: 14,
    '@media(max-width: 576px)': {
      paddingLeft: 15,
      paddingRight: 20,
    },
  },
  dropZone__fileName_new: {
    color: '#1D3E6F',
  },
  dropZone__container_new: {
    display: 'flex',
    borderBottom: 'none',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    // padding: '8px 12px',
    // gap: '10px',
    //
    // width: '513px',
    // height: '36px',
    //
    // backgroundColor: '#F4F6FC',
    // borderRadius: '4px',

    '@media(max-width: 1024px)': {
      flexWrap: 'wrap',
      margin: '10px 0',
    },
  },

  dropZone__container: {
    display: 'flex',
    flexWrap: 'wrap',
    borderLeft: '#91B1BF solid 1px',
    borderBottom: (props) => (!props.needBorderBottom && '#91B1BF solid 1px') || 'none',
    borderRight: '#91B1BF solid 1px',
    borderRadius: 0,
    padding: (props) => (!props.needBorderBottom && 10) || 0,
  },
  dropZone__containerInner: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    margin: '-5px -15px',
    '@media(max-width: 576px)': {
      margin: 0,
    },
  },
  dropZone__containerInner_new: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '8px',
    width: '100%',
    gap: '4px',

    '@media(max-width: 576px)': {
      margin: 0,
    },

    '& a': {
      display: 'flex',
      textDecoration: 'none',
      color: theme.palette.type === 'light' ? '#292D3499' : '#fff',
      '& > svg': {
        minWidth: 16,
      },
    },
    '&:hover $dropZone__removeButton': {},
  },
  dropZone__containerItem: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 15px',
    position: 'relative',
    '@media(max-width: 576px)': {
      margin: 0,
      wordBreak: 'break-all',
    },
    '& a': {
      display: 'flex',
      textDecoration: 'none',
      color: theme.palette.type === 'light' ? '#292D3499' : '#fff',
      '& > svg': {
        minWidth: 16,
      },
    },
    '&:hover $dropZone__removeButton': {
      opacity: 1,
    },
  },

  dropZone__containerItem_new: {
    position: 'relative',
    flexDirection: 'column',
    gap: '10px',
    padding: '8px 12px',
    paddingRight: '48px',

    backgroundColor: '#F4F6FC',
    borderRadius: '4px',

    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',

    display: 'flex',
    color: '#1D3E6F',

    svg: {
      display: 'none',
    },
    '& a': {
      display: 'flex',
      textDecoration: 'none',
      color: theme.palette.type === 'light' ? '#292D3499' : '#fff',
      '& > svg': {
        minWidth: 16,
      },
    },
    '&:hover $dropZone__removeButton': {
      // opacity: 1,
    },
  },
  dropZone__containerBorder: {
    borderTop: '#91B1BF solid 1px',
  },
  dropZone__containerBorder_new: {
    borderTop: 'none',
    flexDirection: 'column',
  },
  dropZone__linearColorBar: {
    backgroundColor: '#91B1BF',
  },
  dropZone__linearColor: {
    backgroundColor: '#91B1BF99',
  },
  dropZone__downloadLink: {
    '&.MuiButton-root': {
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiButton-label': {
        alignItems: 'flex-end',
        justifyContent: 'inherit',
        lineHeight: 1,
      },
    },
  },
  dropZone__removeButton: {
    color: '#FBC15E',
    opacity: 0,
    padding: 5,
    position: 'absolute',
    top: '-10px',
    right: -16,
    zIndex: 1,
    '&.MuiButton-root': {
      minWidth: 16,
      '& svg': {
        width: 13,
        height: 13,
      },
      '@media(max-width: 576px)': {
        '& svg': {
          width: 9,
          height: 9,
        },
      },
    },
    '@media(max-width: 576px)': {
      opacity: '1 !important',
      top: 1,
      right: 0,
    },
  },

  dropZone__removeButton_new: {
    color: '#1D3E6F',
    padding: 5,
    position: 'absolute',

    top: '50%',
    right: '12px',
    transform: 'translate(0, -50%)',
    zIndex: 1,

    '&.MuiButton-root': {
      minWidth: 16,
      '& svg': {
        width: 13,
        height: 13,
      },
      '@media(max-width: 576px)': {
        '& svg': {
          width: 9,
          height: 9,
        },
      },
    },
    '@media(max-width: 576px)': {
      opacity: '1 !important',
      top: 1,
      right: 0,
    },
  },

  dropZone__new: {
    minHeight: (props) => props.minHeight,
    border: 'none',
    padding: ({ padding }) => padding ?? '20px 16px',
    width: (props) => (props.fullWidth ? '100%' : props.width),
    // eslint-disable-next-line max-len
    backgroundImage:
      "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23B2B7C2' stroke-width='1' stroke-dasharray='9' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e\")",
    borderRadius: 8,
    '&.MuiDropzoneArea-root': {
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
      outline: 'none',
    },
    '& .MuiDropzoneArea-textContainer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiDropzoneArea-icon': {
        marginLeft: 44,
        width: 20,
        height: 20,
        color: '#1D3E6F',
        // display: 'none',

        '@media(max-width: 360px)': {
          marginLeft: 10,
        },
      },
      '& .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 14,
        lineHeight: '14px',
        textAlign: 'left',
        color: theme.palette.type === 'light' ? '#8C93A8' : '#fff',
        '@media(max-width: 360px)': {
          fontSize: 12,
          lineHeight: '16px',
        },
      },
    },
    '& .MuiDropzonePreviewList-root': {
      width: '100%',
      margin: 0,
      '& .MuiGrid-item': {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 'fit-content',
        '& .MuiDropzonePreviewList-image': {
          marginRight: 15,
          color: '#91B1BF',
          width: 30,
          height: 25,
        },
        '&.MuiDropzonePreviewList-imageContainer > svg': {
          minWidth: 16,
          marginRight: 10,
          '& + .MuiTypography-body1': {
            textAlign: 'left',
          },
        },
        '& .MuiDropzonePreviewList-removeButton': {
          position: 'relative',
          top: 0,
          right: 0,
          boxShadow: 'unset',
          backgroundColor: 'transparent',
          width: 25,
          height: 25,
          marginLeft: 15,
          '& .MuiFab-label > .MuiSvgIcon-root': {
            color: '#FBC15E',
          },
        },
      },
    },
    '& input[disabled] + div + div .MuiDropzonePreviewList-removeButton': {
      display: 'none',
    },
    '& input[disabled] + .MuiDropzoneArea-textContainer': {
      display: 'none',
    },
  },
}))

export default useStyles

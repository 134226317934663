import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  inputsContainer: {
    width: 'calc(100% + 24px)',
    marginLeft: -12,
    marginRight: -12,
    '& .MuiGrid-item': {
      paddingLeft: 12,
      paddingRight: 12,
    },
    '@media (max-width: 576px)': {
      justifyContent: 'center',
      paddingTop: 25,
    },
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableContainer: {
    [theme.breakpoints.up('lg')]: {
      overflow: 'unset',
    },
  },
  tableRow: {
    borderRight: '6px solid transparent',
    cursor: 'pointer',
    '&.MuiTableRow-root:not(.MuiTableRow-head)': {
      '&:hover': {
        borderRightColor: '#FBC15E',
        backgroundColor: '#91B1BF1A',
      },
    },
    '&.MuiTableRow-head': {
      cursor: 'initial',
    },
    '&.MuiTableRow-head > .MuiTableCell-root': {
      fontSize: 14,
    },
    '& > .MuiTableCell-root': {
      padding: '16px 12px 17px',
      borderBottomColor: theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78',
      borderBottomStyle: 'dashed',
      fontSize: 16,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 2,
      },
      '& button': {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& svg': {
        fill: '#91B1BF',
      },
      '& .MuiCheckbox-root': {
        padding: '9px 9px 9px 3px',
        '& svg': {
          backgroundColor: '#fff',
        },
      },
      '& .MuiCheckbox-root.MuiIconButton-colorSecondary': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MoneyPanel__BoxWrapper: {
    paddingTop: 50,
    '@media (max-width: 576px)': {
      marginTop: 10,
      marginBottom: 30,
      paddingTop: 0,
    },
  },
  DepoPanel__multipleSign: {
    marginTop: 22,
    display: 'flex',
    alignItems: 'center',
    '& .MuiCheckbox-root': {
      padding: 1,
      marginRight: 12,
      marginLeft: 2,
      '& svg': {
        backgroundColor: '#fff',
      },
    },
  },
  DepoPanel__multipleSign_nonChecked: {
    borderBottom: '0.5px dashed #91B1BF',
    borderTop: '0.5px dashed #91B1BF',
  },
  DepoPanel__multipleSignChecked: {
    borderBottom: '0.5px solid #fff',
    borderTop: '0.5px solid #fff',
    backgroundColor: '#fbc15e',
  },
  DepoPanel__sign: {
    padding: '3px 11px 3px 5px',
    display: 'flex',
    cursor: 'pointer',
    '& > svg': {
      marginRight: 8,
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  DepoPanel__remove: {
    padding: '3px 13px 3px 14px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& > svg': {
      backgroundColor: '#fff',
      marginRight: 10,
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  DepoPanel__BoxWrapper: {
    paddingTop: 50,
    '@media (max-width: 577px)': {
      marginTop: 10,
      paddingTop: 0,
    },
  },
  PhoneHeader: {
    fontSize: 14,
    fontWeight: 600,
    color: '#91B1BF',
  },
  PhoneFiltersPanel: {
    height: '100%',
    backgroundColor: '#f4f8f9',
  },
  PhoneFiltersPanel__header: {
    fontSize: 16,
    padding: '5px 23px',
  },
  PhoneGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 98px',
    gridGap: '3px 9px',
    paddingTop: 6,
    paddingBottom: 12,
    borderBottom: '0.5px dashed #91B1BF',
    '&:first-child': {
      borderTop: '0.5px dashed #91B1BF',
    },
  },
  PhoneGrid__depoItem: {
    '&:nth-child(1)': {
      order: 4,
    },
    '&:nth-child(2)': {
      order: 3,
    },
    '&:nth-child(3)': {
      order: 6,
    },
    '&:nth-child(4)': {
      order: 2,
    },
    '&:nth-child(5)': {
      order: 1,
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
    '&:nth-child(6)': {
      order: 5,
    },
    '&:nth-child(7)': {
      order: 7,
    },
  },
  PhoneGrid__item: {
    '&:nth-child(1)': {
      order: 3,
    },
    '&:nth-child(2)': {
      order: 2,
    },
    '&:nth-child(3)': {
      order: 4,
    },
    '&:nth-child(4)': {
      order: 1,
    },
    '&:nth-child(5)': {
      order: 5,
    },
    '&:nth-child(6)': {
      order: 6,
    },
    '&:nth-child(7)': {
      order: 7,
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  PhoneGrid__header: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#91B1BF',
  },
  PhoneGrid__value: {
    margin: 0,
    fontSize: 14,
    lineHeight: '19px',
  },
  PhoneGrid__value_medium: {
    fontWeight: 600,
  },
  PhoneFilters: {
    height: 60,
    width: '100%',
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    backgroundColor: '#fff',
  },
  PhoneFilters__button: {
    backgroundColor: '#92B2C0',
    color: '#fff',
    fontSize: 12,
    padding: '4px 23px 5px 18px',
    borderRadius: 0,
    textTransform: 'none',
    lineHeight: '16px',
    margin: 'auto',
    width: 108,
    '&:hover': {
      backgroundColor: '#92B2C0',
    },
  },
  PhoneFilters__button_primary: {
    backgroundColor: '#F2C94C',
    color: '#313A4E',
    '&:hover': {
      backgroundColor: '#F2C94C',
    },
  },
  PhoneFilters__groupBtn: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 60,
    '& button:not(:last-child)': {
      marginBottom: 24,
    },
  },
  tableHeaderCell: {
    color: '#91B1BF',
  },
  tableBodyCell: {
    fontSize: 16,
  },
  tableBodyCellWidth: {
    minWidth: 155,
  },
}))

export default useStyles

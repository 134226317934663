import { createSlice } from '@reduxjs/toolkit'
import getCadespluginAPI from 'async-cadesplugin'
import Api from '../../api/Api'
import {getErrorSuccess} from "../error/errorSlice";

const initialState = {
  securities: null,
  error: null,
  security: null,
  saveSecurity: null,
  performSecurity: null,
  deleteSecurity: null,
  balances: null,
}

const securitiesSlice = createSlice({
  name: 'securities',
  initialState,
  reducers: {
    getSecurities(state) {
      return {
        ...state,
      }
    },
    getSecuritiesSuccess(state, action) {
      return {
        ...state,
        securities: action.payload,
      }
    },
    getSecuritiesFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getSecurity(state) {
      return {
        ...state,
      }
    },
    getSecuritySuccess(state, action) {
      return {
        ...state,
        security: action.payload,
      }
    },

    getSecurityFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    saveSecurity(state) {
      return {
        ...state,
      }
    },
    saveSecuritySuccess(state, action) {
      return {
        ...state,
        saveSecurity: action.payload,
      }
    },
    saveSecurityFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    performSecurity(state) {
      return {
        ...state,
      }
    },
    performSecuritySuccess(state, action) {
      return {
        ...state,
        performSecurity: action.payload,
      }
    },
    performSecurityFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getBalancesDepo(state) {
      return {
        ...state,
      }
    },
    getBalancesDepoSuccess(state, action) {
      return {
        ...state,
        balances: action.payload,
      }
    },
    getBalancesDepoFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    deleteSecurity(state) {
      return {
        ...state,
      }
    },
    deleteSecuritySuccess(state, action) {
      return {
        ...state,
        deleteSecurity: action.payload,
      }
    },
    deleteSecurityFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    clearSecurities() {
      return initialState
    },
    clearPartSecurities(state) {
      return {
        ...state,
        error: null,
        saveSecurity: null,
        performSecurity: null,
      }
    },
  },
})

const { actions, reducer } = securitiesSlice

export const {
  getSecurities,
  getSecuritiesSuccess,
  getSecuritiesFailed,
  getSecurity,
  getSecuritySuccess,
  getSecurityFailed,
  saveSecurity,
  saveSecuritySuccess,
  saveSecurityFailed,
  performSecurity,
  performSecuritySuccess,
  performSecurityFailed,
  getBalancesDepo,
  getBalancesDepoSuccess,
  getBalancesDepoFailed,
  deleteSecurity,
  deleteSecuritySuccess,
  deleteSecurityFailed,
  clearSecurities,
  clearPartSecurities,
} = actions

export default reducer

export const fetchSecuritiesStartAsync = (obj) => async (dispatch) => {
  dispatch(getSecurities())
  try {
    const value = await Api.getSecurities(obj)
    dispatch(getSecuritiesSuccess(value))
  } catch (error) {
    dispatch(getSecuritiesFailed(error))
  }
}

export const fetchGetSecurityStartAsync = ({ id }) => async (dispatch) => {
  dispatch(getSecurity())
  try {
    const value = await Api.getSecurity(id)
    dispatch(getSecuritySuccess(value))
  } catch (error) {
    dispatch(getSecurityFailed(error))
  }
}

export const fetchSaveSecurityStartAsync = ({ data, id = null }) => async (dispatch) => {
  dispatch(saveSecurity())
  try {
    if (id) {
      const value = await Api.putSecurity(data, id)
      dispatch(saveSecuritySuccess(value))
    } else {
      const value = await Api.postSecurity(data, false)
      dispatch(saveSecuritySuccess(value))
    }
  } catch (err) {
    dispatch(saveSecurityFailed(err))
    dispatch(getErrorSuccess(err))
  }
}

export const fetchPerformSecurityStartAsync = ({ data, id = null }) => async (dispatch) => {
  dispatch(performSecurity())
  try {
    if (id) {
      if (data?.thumbPrint && data?.signedXml) {
        const api = await getCadespluginAPI()
        const signature = await api.signXml(data.thumbPrint, data.signedXml)
        const signedObj = data
        signedObj.signedXml = signature
        const value = await Api.putSecurity(signedObj, id, true)
        dispatch(performSecuritySuccess(value))
      } else {
        const value = await Api.putSecurity(data, id, true)
        dispatch(performSecuritySuccess(value))
      }
    } else {
      const value = await Api.postSecurity(data, true)
      dispatch(performSecuritySuccess(value))
    }
  } catch (err) {
    dispatch(performSecurityFailed())
    dispatch(getErrorSuccess(err))
  }
}

export const fetchDeleteSecurityStartAsync = ({ id = null }) => async (dispatch) => {
  dispatch(deleteSecurity())
  try {
    if (id) {
      const value = await Api.deleteSecurity(id)
      dispatch(deleteSecuritySuccess(value))
    }
  } catch (err) {
    dispatch(deleteSecurityFailed())
  }
}

export const fetchGetBalancesDepoStartAsync = ({ filterBody }) => async (dispatch) => {
  dispatch(getBalancesDepo())
  try {
    if (filterBody) {
      const value = Api.getBalancesDepo(filterBody)
      dispatch(getBalancesDepoSuccess(value))
    }
  } catch (err0) {
    dispatch(getBalancesDepoFailed())
  }
}

import React, { useState } from 'react'
import Media from 'react-media'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import useStyles from './sharedStyles'
import CustomInput from '../../CustomControls/CustomInput'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

// eslint-disable-next-line no-unused-vars
export default function OperationsPlanContent({ data, handleClickNextPanel, isNeedNextButton }) {
  const classes = useStyles()

  const [operationPlanContent, setOperationPlanContent] = useState({
    market: '',
    fundsOrigin: '',
    operationsVolume: '',
    dealVolume: '',
    dealTypes: '',
  })

  const handleChangeOperationPlanContent = (event) => setOperationPlanContent({
    ...operationPlanContent,
    [event.target.name]: event.target.value,
  })

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Grid
              container
              xs={12}
              className={classes.row}
            >
              <CustomInput
                fullWidth
                name="market"
                size="small"
                variant="outlined"
                label="Рынки"
                value={operationPlanContent.market}
                onChange={handleChangeOperationPlanContent}
                disabled
                unique
              />
            </Grid>
            <Grid
              item
              container
              justify="space-between"
              className={classes.row}
              spacing={2}
            >
              <Grid
                item
                lg={8}
                md={7}
                xs={12}
              >
                <div className={classes.textareaWrapper}>
                  <CustomInput
                    fullWidth
                    multiline
                    rows={3}
                    name="fundsOrigin"
                    size="small"
                    variant="outlined"
                    label="Источники происхождения денежных средств"
                    value={operationPlanContent.fundsOrigin}
                    onChange={handleChangeOperationPlanContent}
                    disabled
                    unique
                  />
                </div>
              </Grid>
              <Grid
                item
                container
                lg={4}
                md={5}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <CustomInput
                    fullWidth
                    name="operationsVolume"
                    size="small"
                    variant="outlined"
                    label="Объем операций (шт./мес.)"
                    value={operationPlanContent.operationsVolume}
                    onChange={handleChangeOperationPlanContent}
                    disabled
                    unique
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <CustomInput
                    fullWidth
                    name="dealVolume"
                    size="small"
                    variant="outlined"
                    label="Объем сделок (руб./мес.)"
                    value={operationPlanContent.dealVolume}
                    onChange={handleChangeOperationPlanContent}
                    disabled
                    unique
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.row}
            >
              <CustomInput
                fullWidth
                name="dealTypes"
                size="small"
                variant="outlined"
                label="Виды сделок"
                value={operationPlanContent.dealTypes}
                onChange={handleChangeOperationPlanContent}
                disabled
                unique
              />
            </Grid>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <div className={classes.Mobile}>
            {operationPlanContent.market && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Рынки
              </div>
              <div className={classes.Mobile__blockText}>
                {operationPlanContent.market}
              </div>
            </div>
            )}
            {operationPlanContent.fundsOrigin && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Источники происхождения денежных средств
              </div>
              <div className={classes.Mobile__blockText}>
                {operationPlanContent.fundsOrigin}
              </div>
            </div>
            )}
            {operationPlanContent.dealVolume && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Объем операций (шт./мес.)
              </div>
              <div className={classes.Mobile__blockText}>
                {operationPlanContent.dealVolume}
              </div>
            </div>
            )}
            {operationPlanContent.dealTypes && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Виды сделок
              </div>
              <div className={classes.Mobile__blockText}>
                {operationPlanContent.dealTypes}
              </div>
            </div>
            )}
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </div>
        )}
      />
    </>
  )
}

OperationsPlanContent.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

OperationsPlanContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

import React, { memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  StyledPortfolioTables,
  StyledTableWrapper,
  TableHeadingContainer,
  TableHeadingTitle,
  TableHeadingWrapper,
} from './styled'
import {
  MobileTable,
  MobileTableRow,
  MobileTableSection,
  MobileTableSectionCell,
  CollapsibleTable,
  Tooltip,
  IColumn,
  ITableColumn,
  Badge,
  Text,
  Icon,
  Accordion,
} from 'ui/components'
import AccordionNew from 'ui/components/molecules/Accordion'
import { useTheme } from 'ui/theme'
import { useSize } from 'hooks/useSize'
import { ICollapsibleTableRow, IPortfolioTablesProps, ITableRow } from './types'
import {selectGetCurrentAccount, selectGetPortfolio} from 'redux/portfolio/selector'
import getPrettyNumber from 'utils/GetPrettyNumber'

import { getCorrectPercentValue } from './data'
import Table from 'ui/components/molecules/Table'

export const ChildTable: React.FC<{ rows: any[] }> = memo(({ rows }) => {
  // const size = useSize()
  const theme = useTheme()
  // const isTablet = theme.bp.isSM(size.width)
  // const columnsLayout = isTablet ? ['1fr', '25%', '15%'] : ['1fr', '25%', '15%']

  const getNameTitle = (category: string) => {
    if (category === 'Облигации' || category === 'Акции') return 'Место хранения'

    return 'Рынок'
  }

  const columns: ITableColumn<ITableRow>[] = [
    {
      id: 'name',
      title: getNameTitle(rows[0]?.instrument?.category),
      // width: getProtfolioTableCellWidth(668),
      width: '100%',
      showAlways: true,
      render: (_, row) => <div title={row?.name}>{row?.name}</div>,
      font: theme.font.body1,
    },
    {
      id: 'available',
      title: 'Доступный остаток',
      width: '152px',
      showAlways: true,
      render: (_, row) => `${getPrettyNumber(row?.volumeT0)}`,
      bodyTextAlign: 'right',
      headTextAlign: 'right',
      font: theme.font.body1,
    },
    {
      id: 'plan',
      title: 'Плановый остаток',
      width: '186px',
      showAlways: true,
      render: (_, row) => `${getPrettyNumber(row?.volumeTn)}`,
      bodyTextAlign: 'right',
      headTextAlign: 'right',
      font: theme.font.body1,
    },
  ]

  return (
    <Table<ITableRow>
      columns={columns}
      rows={rows}
      withRowShadow={false}
      withBodyRowBorder
      bodyRowBorderColor={theme.color.bg.secondary}
      withHeadRowBorder
      headRowBorderColor={theme.color.bg.secondary}
      padding="28px 20px 8px 20px"
      columnsGap={0}
      leftPadding={20}
      rightPadding={44}
      rowsGap={0}
      rowsHeight={40}
      bottomHeadPadding={4}
      withBorderLastBodyRow={false}
    />
  )
})

const PortfolioTables: React.FC<IPortfolioTablesProps> = ({ currentCategory }) => {
  const size = useSize()
  const theme = useTheme()
  const isMobile = theme.bp.isXS(size.width)
  const isTablet = theme.bp.isSM(size.width)

  const portfolio = useSelector(selectGetPortfolio)


  const currentAccount = useSelector(selectGetCurrentAccount)


  const [openAccordion, setOpenAccordion] = useState<Array<string>>([])
  const [accountId, setAccountId] = useState<number>(null)
  const [tableData, setTableData] = useState<any>([])


  const toggleOpenAccordion = (id: string) => {
    if (openAccordion.includes(id)) {
      setOpenAccordion(openAccordion?.filter((item) => item !== id))
    } else {
      setOpenAccordion([...openAccordion, id])
    }
  }

  const columnsLayout = useMemo(
    () =>
      isTablet
        ? ['1fr', '120px', '170px', '110px', '209px']
        : ['1fr', '120px', '170px', '110px', '209px'],
    [isTablet]
  )

  const collapsibleColumns: IColumn<ICollapsibleTableRow>[] = useMemo(
    () => [
      {
        id: 'name',
        font: theme.font.body1,
        maxHeight: '40px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        eilpsable: true,
        withTooltip: true,
        title: (rows) => (
          <>
            {rows.length && rows[0].name === 'Денежные средства' ? (
              <div style={{ width: 28 }} />
            ) : null}{' '}
            <span>Название</span>
          </>
        ),
        render: (row, rows, accordName) => {

          const isMoney = accordName === 'Денежные средства'

          return (
            <>
              {isMoney && (
                <Icon name={`currency-${row?.name?.toLowerCase()}`} size={28} />
              )}

              {row?.name}
            </>
          )
        },
      },
      {
        id: 'volume',
        title: 'Количество',
        whiteSpace: 'nowrap',
        render: (row) => getPrettyNumber(row?.volume),
      },
      {
        id: 'valuationAssets',
        whiteSpace: 'nowrap',
        title: (
          <>
            <div>Оценка</div> <Tooltip content="Оценка" />
          </>
        ),
        render: (row) => {
          return`${getPrettyNumber(row?.valuation)} ₽`},
      },
      {
        id: 'totalAssetsShare',
        whiteSpace: 'nowrap',
        title: (
          <>
            <div>Доля</div> <Tooltip content="Доля" />
          </>
        ),
        render: (row) => `${getCorrectPercentValue(row?.share)}%`,
      },
      {
        id: 'change',
        whiteSpace: 'nowrap',
        title: (
          <>
            <div>Изменение за день</div> <Tooltip content="Изменение за день" />
          </>
        ),
        render: (row) => {
          const sum = row?.valuationDelta;
          const percents = row?.valuationDeltaPercent;

          const getColor = () => {
            if (percents > 0) {
              return 'positive'
            }

            if (percents < 0) {
              return 'negative'
            }

            if (percents === 0) {
              if (sum > 0) {
                return 'positive'
              }

              if (sum < 0) {
                return 'negative'
              }
            }
          }

          return (
            <>
              <Text color={getColor()}>{getPrettyNumber(sum)} ₽</Text>
              <Badge
                variant={getColor()}
                icon={getColor()}
                value={`${getCorrectPercentValue(percents)}%`}
              />
            </>
          )
        },
      },
    ],
    [theme]
  )


  useEffect(() => {
    setOpenAccordion([])
  }, [currentAccount])

  useEffect(()=> {
    setTableData(portfolio?.filter((f) => f?.account?.id === currentAccount)[0])
  }, [currentAccount, portfolio])

  useEffect(()=> {
    setAccountId(tableData?.account?.id)
  }, [tableData])



  return (
    <div>
      {!isMobile && (
        <TableHeadingContainer>
          <TableHeadingTitle>Инструмент</TableHeadingTitle>

          <TableHeadingWrapper>
            <TableHeadingTitle>Сумма</TableHeadingTitle>

            <TableHeadingTitle>
              Изменение за день
              <Tooltip content="Изменение за день" />
            </TableHeadingTitle>

            <TableHeadingTitle>Доля в портфеле</TableHeadingTitle>
          </TableHeadingWrapper>
        </TableHeadingContainer>
      )}

      <StyledPortfolioTables>
        <>
          {!isMobile && (
            <>
              {tableData && tableData?.account?.categories?.map( itemAccord =>
                {
                  const accrodionKey = `${accountId}${itemAccord.name}`

                  const isOpened = openAccordion.includes(accrodionKey)

                  return (

                    <StyledTableWrapper
                      key={itemAccord.name + accountId}
                      isActive={
                        +accountId === +currentAccount &&
                        (!currentCategory?.length ||
                          currentCategory.includes(itemAccord.name))
                      }
                    >

                    <AccordionNew
                      onHandleClick={() => toggleOpenAccordion(accrodionKey)}
                      padding="32px"
                      bodyPaddingTop={32}
                      key={itemAccord.name}
                      title={itemAccord.name}
                      sum={itemAccord.valuation}
                      dayDiff={itemAccord.valuationDelta}
                      dayDiffPercent={
                        itemAccord.valuationDeltaPercent || 0
                      }
                      share={itemAccord.share}
                      isOpen={isOpened}
                    >


                      <CollapsibleTable
                        columns={collapsibleColumns}
                        layout={columnsLayout}
                        rows={itemAccord.securities}
                        accordName={itemAccord.name}
                        {...(!isOpened && { areOpenedRows: false })}
                      />

                    </AccordionNew>

                      </StyledTableWrapper>

                  ) }
                )
              }
            </>
          )}

          {isMobile &&
            portfolio?.filter(f => f?.account?.id === currentAccount)[0]?.account.categories?.map((itemAccord: any) => (
              <>
                <Accordion
                  title={itemAccord.name}
                  open={openAccordion.includes(itemAccord.name)}
                  onClick={() => toggleOpenAccordion(itemAccord.name)}
                >
                  <MobileTable>
                    {itemAccord.securities.map(
                      ({
                        name,
                        valuation,
                        volume,
                        valuationDelta,
                        valuationDeltaPercents,
                      }: any) => (
                        <MobileTableRow>
                          <MobileTableSection row>
                            {itemAccord.name === 'Денежные средства' && (
                              <MobileTableSectionCell>
                                <Icon
                                  name={`currency-${name.toLowerCase()}`}
                                  size={28}
                                />
                              </MobileTableSectionCell>
                            )}
                            <MobileTableSectionCell>
                              <Text variant="body1">{name}</Text>
                              <Text variant="caption2" color="secondary">
                                1 шт - {getPrettyNumber(valuation / volume)} ₽
                              </Text>
                            </MobileTableSectionCell>
                          </MobileTableSection>
                          <MobileTableSection align="right">
                            <Text variant="body1">{getPrettyNumber(valuation)} ₽</Text>
                            {valuationDelta > 0 ? (
                              <Text color="positive">
                                {getPrettyNumber(valuationDelta)} ₽ (
                                {getCorrectPercentValue(valuationDeltaPercents)}%)
                              </Text>
                            ) : valuationDeltaPercents < 0 ? (
                              <Text color="negative">
                                {getPrettyNumber(valuationDelta)} ₽ (
                                {getCorrectPercentValue(valuationDeltaPercents)}%)
                              </Text>
                            ) : (
                              <Text>
                                {getPrettyNumber(valuationDelta)} ₽ (
                                {getCorrectPercentValue(valuationDeltaPercents)}%)
                              </Text>
                            )}
                          </MobileTableSection>
                        </MobileTableRow>
                      )
                    )}
                  </MobileTable>
                </Accordion>
              </>
            ))}
        </>
      </StyledPortfolioTables>
    </div>
  )
}

export default memo(PortfolioTables)

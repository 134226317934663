import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    '@media (max-width: 576px)': {
      position: 'relative',
      left: -8,
      width: 'calc(100% + 8px)',
      marginTop: 32,
    },
  },
  tab: {
    position: 'relative',
    minHeight: 45,
    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      left: 0,
      bottom: 1,
      width: '100%',
      height: 1,
      borderBottom: '1px #91B1BF dashed',
      '@media (max-width: 576px)': {
        borderBottom: 0,
      },
    },
    '& .MuiTabs-fixed .MuiTabs-flexContainer ': {
      justifyContent: (props) => props.justifyContent,
      '@media (max-width: 576px)': {
        flexDirection: 'column',
      },
      '& .MuiTab-root': {
        display: 'block',
        position: 'relative',
        minHeight: 'unset',
        minWidth: 'max-content',
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 2,
        color: theme.palette.type === 'light' ? '#91B1BF99' : '#91B1BF',
        fontWeight: 600,
        fontSize: 21,
        opacity: 1,
        '@media (max-width: 576px)': {
          minWidth: 'auto',
          padding: 0,
          fontSize: 16,
          fontWeight: 400,
          textTransform: 'initial',
          textAlign: 'left',
          color: '#91B1BF',
        },
        '&.Mui-selected': {
          color: theme.palette.type === 'light' ? '#91B1BF' : '#fff',
          '@media (max-width: 576px)': {
            color: '#91B1BF',
          },
          '& .MuiTab-wrapper': {
            '&:after': {
              content: '""',
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              height: 3,
              backgroundColor: theme.palette.type === 'light' ? '#91B1BF' : '#FBC15E',
              '@media (max-width: 576px)': {
                display: 'none',
              },
            },
            '&:hover': {
              '&:after': {
                display: 'block',
                '@media (max-width: 576px)': {
                  display: 'none',
                },
              },
              color: theme.palette.type === 'light' ? '#91B1BF' : '#fff',
              '& svg': {
                fill: theme.palette.type === 'light' ? '#91B1BF' : '#fff',
              },
            },
            '& > svg': {
              fill: theme.palette.type === 'light' ? '#91B1BF' : '#fff',
              '@media (max-width: 576px)': {
                fill: '#91B1BF',
              },
            },
          },
          '&:after': {
            display: 'block',
            '@media (max-width: 576px)': {
              display: 'none',
            },
          },
        },
        '&:not(:last-child)': {
          '@media (max-width: 576px)': {
            marginBottom: 30,
          },
        },
        '& .MuiTab-wrapper': {
          display: 'flex',
          paddingRight: 5,
          flexDirection: 'row',
          width: 'max-content',
          '@media (max-width: 576px)': {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            width: '100%',
            paddingRight: 0,
            lineHeight: '21px',
          },
          '& svg': {
            fill: theme.palette.type === 'light' ? '#91B1BF99' : '#91B1BF',
            marginBottom: 0,
            marginRight: 8,
            '@media (max-width: 576px)': {
              minWidth: 19,
              marginTop: 3,
              fill: '#91B1BF',
            },
          },
          '&:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            height: 3,
            backgroundColor: 'transparent',
            '@media (max-width: 576px)': {
              display: 'none',
            },
          },
          '&:hover': {
            color: '#91B1BF',
            '&:after': {
              backgroundColor: theme.palette.type === 'light' ? '#91B1BF' : '#FBC15E',
            },
          },
        },
        '&:not(:last-of-type)': {
          marginRight: 'auto',
          [theme.breakpoints.down('sm')]: {
            marginRight: 0,
          },
        },
        '&:nth-child(2)': {
          marginRight: 'auto !important',
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    '& > .MuiTabs-scrollable > .MuiTabs-flexContainer': {
      '& > .MuiTab-root': {
        minHeight: 'unset',
        minWidth: '100%',
        '& .MuiTab-wrapper > svg': {
          fill: '#91B1BF99',
          marginBottom: 0,
          marginRight: 10,
        },
      },
      '& > .Mui-selected': {
        color: '#91B1BF',
        '& .MuiTab-wrapper > svg': {
          fill: '#91B1BF !important',
        },
      },
    },
    '& > .MuiTabs-fixed > .MuiTabs-indicator, & > .MuiTabs-scrollable > .MuiTabs-indicator': {
      backgroundColor: '#91B1BF',
      height: 3,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 37,
    },
  },
}))

export default useStyles

import React from 'react';
import DatePicker from 'antd/es/date-picker';
import 'antd/es/date-picker/style/css';
import moment, { Moment } from 'moment';
import { IDateFieldProps } from './types';
import { StyledCalendar, StyledDateRangeField } from './styled';
import { Icon } from 'ui/components';
import { useTheme } from 'ui/theme';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

const { RangePicker } = DatePicker;

export const DateRangeField: React.FC<IDateFieldProps & { getPopupContainer?: any }> = ({
  // onChange = (e) => void 0,
  value,
  minDate,
  maxDate,
  picker = 'week',
  onChange,
                                                            isEqually,
  ...props
}) => {
  const theme = useTheme()
  const localValue = value ? moment(value) : null
  const handleChange = (e: Moment) => {
    // onChange(e?.toDate());
  }
  const isDateDisabled = (date: Moment) => {
    if (minDate && date.diff(moment(minDate)) < 0) {
      return true
    }
    if (maxDate && date.diff(moment(maxDate)) > 0) {
      return true
    }
    return false
  }
  return (
    <StyledDateRangeField>
      <StyledCalendar />
      <RangePicker
        // showTime
        value={value}
        locale={locale}
        onChange={onChange}
        // value={localValue}
        format={'DD.MM.YYYY'}
        disabledDate={isDateDisabled}
        suffixIcon={<Icon name={'calendar'} size={20} color={theme.color.graphics.secondary} />}
        placeholder={['За все время', '']}
        // allowClear={false}
        {...props}
      />
    </StyledDateRangeField>
  )
}

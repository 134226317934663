import Cookies from 'js-cookie'
import { getErrors } from '../redux/errorsModal/errorsModalSlice'
// eslint-disable-next-line import/no-cycle
import { clearAccessToken } from '../redux/user/userSlice'

let store

const setStore = (s) => {
  store = s
}
const axiosInterceptor = (axiosInstance) => {
  const token = store?.getState()?.user?.currentUser?.accessToken

  axiosInstance.interceptors.request.use(
    (config) => {
      if (!token) return config
      const authField = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      }
      // eslint-disable-next-line no-param-reassign
      config.headers = authField
      return config
    },
    async (err) => Promise.reject(err),
  )

  axiosInstance.interceptors.response.use(
    (config) => config,
    (error) => {
      const { response } = error
      if (response && response.status === 403) {
        // eslint-disable-next-line no-console
        console.error(response)
      } else if (response && response.status === 303) {
        return Promise.resolve(response)
      } else if (response && response.status === 400) {
        store.dispatch(getErrors(response?.data?.errors))
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(response?.data?.errors)
      } else if (response && response.status === 401) {
        if (token) {
          store.dispatch(clearAccessToken())
          // TODO: CR-539
          // store.dispatch(getErrorUnauthorized())
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        Cookies.remove('accessToken')
        // eslint-disable-next-line
        return Promise.reject(response)
      }
      const errorData = {
        type: 'error',
        name: JSON.parse(error?.toJSON()?.config?.data || JSON.stringify({}))?.fileName,
        size: JSON.parse(error?.toJSON()?.config?.data || JSON.stringify({}))?.fileSize,
      }
      return Promise.reject(response || errorData)
    },
  )
  return axiosInstance
}

export { setStore, axiosInterceptor }

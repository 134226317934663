import { isRequired } from './Validate'
import HmacSha256Custom from './HmacSHA256Custom'

/* eslint-disable no-param-reassign */
export default function formAction({
  submitAction,
  requestObj,
  typeUID,
  msgData,
  putPerformData,
  postPerformData,
  putSaveData,
  postSaveData,
  activeId,
  code,
  setCodeError,
  setCodeTextError,
  setCancellationModalOpen,
  setFormDisabled,
  setIsLoading,
  currentThumbPrint,
  secretPassPhrase,
  disableValidation
}: Record<string, any>) {
  const msgDataTypeUID = msgData?.order?.type?.uid || msgData?.document?.type?.uid
  if (submitAction === 'perform') {
    if (msgData?.requiredAction && msgDataTypeUID === typeUID) {
      switch (msgData?.requiredAction?.type) {
        case 'sign':
          requestObj.thumbPrint = currentThumbPrint
          requestObj.signedXml = msgData?.requiredAction?.signAction?.xml
          break
        case 'code':
          if (!code && !disableValidation) {
            setFormDisabled(false)
            setIsLoading(false)
            isRequired(code, setCodeError, setCodeTextError)
            return
          }
          requestObj.secret = code
          break
        case 'mac':
          if (!code) {
            setFormDisabled(false)
            setIsLoading(false)
            // isRequired(code, setCodeError, setCodeTextError)
            return
          }

          if (requestObj && secretPassPhrase) {
            requestObj.secret = ''
            const hashToBase64 = HmacSha256Custom(requestObj, secretPassPhrase)
            requestObj.secret = hashToBase64
          }

          break
        default:
          break
      }
      putPerformData(requestObj, activeId)
    } else if (msgData?.requiredAction && msgDataTypeUID === 'Orders.Type.Modify.Cancellation') {
      typeUID = 'Orders.Type.Modify.Cancellation'
      switch (msgData?.requiredAction?.type) {
        case 'sign':
          requestObj.thumbPrint = currentThumbPrint
          requestObj.signedXml = msgData?.requiredAction?.signAction?.xml
          break
        case 'code':
          if (!code) {
            setFormDisabled(false)
            setIsLoading(false)
            isRequired(code, setCodeError, setCodeTextError)
            return
          }
          requestObj.secret = code
          break
        case 'mac':
          if (!code) {
            setFormDisabled(false)
            setIsLoading(false)
            // isRequired(code, setCodeError, setCodeTextError)
            return
          }

          if (requestObj && secretPassPhrase) {
            requestObj.secret = ''
            const hashToBase64 = HmacSha256Custom(requestObj, secretPassPhrase)
            requestObj.secret = hashToBase64
          }

          break
        default:
          break
      }
      putPerformData(requestObj, activeId)
    } else if (typeUID === 'Orders.Type.Modify.Cancellation') {
      if (activeId) {
        putPerformData(requestObj, activeId)
      }
    } else if (activeId) {
      putPerformData(requestObj, activeId)
    } else {
      postPerformData(requestObj)
    }
  } else if (submitAction === 'save') {
    if (activeId) {
      putSaveData(requestObj, activeId)
    } else {
      postSaveData(requestObj)
    }
  } else if (submitAction === 'cancellation') {
    setCancellationModalOpen(true)
  }
}

import { styled } from "ui/theme";

export const StyledNotificationButton = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 10;
  svg {
    display: block;
    position: relative;
    z-index: 1;
  }
`
export const StyledNotificationButtonCounter = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.bg.negative};
  color: ${({ theme }) => theme.color.text.negative};
  ${({ theme }) => theme.font.body1};
  position: absolute;
  left: 15px;
  bottom: 5px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`
export const StyledNotificationPopover = styled.div`
  width: 440px;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: ${({ theme }) => theme.color.bg.secondary};
  padding: ${({ theme }) => `${theme.spacing.xl} 18px`};
  ${({ theme }) => theme.font.body1};
  z-index: 100;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  ul {
    list-style-position: inside;
    margin: 0;
    padding: 0 10px;
    max-height: 84px;
    overflow: auto;
    display: flex;
    gap: 12px;
    flex-direction: column;
  }
  li {
  }
`
import React from 'react'
import { Accordion } from 'ui/components/molecules/Accordion'
import { Field } from 'ui/components/molecules'
import { FormCol, FormRow, TextField } from 'ui/components/atoms'
import { TextArea } from 'ui/components/atoms/TextArea'

import type { FC } from 'react'
import type { CodeFormsProps } from './types'
import CustomerBlocksAccordion from '../CustomerBlocksAccordion'
import useMedia from 'hooks/useMedia'

/**
 * Сведения об учёте в налоговом органе.
 *
 * @param okpo - ОКПО.
 * @param okato - ОКАТО (при наличии).
 * @param okved - ОКВЭД.
 * @param okfs - ОКФС.
 * @param lei - Код LEI.
 * @param otherStatisticsCodes - Иные коды форм федерального государственного статистического наблюдения.
 * @param isOpen - Открыт ли по умолчанию аккордеон.
 *
 * @constructor
 */
export const CodeForms: FC<CodeFormsProps> = ({
  okpo = '',
  okato = '',
  okved = '',
  okfs = '',
  lei = '',
  otherStatisticsCodes = '',
  isOpen = false,
}) => {
  const { isDesktop, isMobile } = useMedia()

  const okvdField = (
    <Field label="ОКВЭД">
      <TextField disabled value={okved} />
    </Field>
  )

  const okfsField = (
    <Field label="ОКФС">
      <TextField disabled value={okfs} />
    </Field>
  )

  const codeField = (
    <Field label="Код LEI">
      <TextField disabled value={lei} />
    </Field>
  )

  return (
    <CustomerBlocksAccordion
      title="Коды форм федерального государственного статистического наблюдения"
      isOpenDefault={isOpen}
      bodyPaddingTop={28}
    >
      <FormCol gap={24}>
        <FormRow gap={16} tabletRowDirection gapTablet={16}>
          <Field label="ОКПО">
            <TextField disabled value={okpo} />
          </Field>

          <Field label="ОКАТО (при наличии)">
            <TextField disabled value={okato} />
          </Field>

          {isDesktop && (
            <>
              {okvdField}

              {okfsField}

              {codeField}
            </>
          )}
        </FormRow>

        {!isDesktop && (
          <FormRow tabletRowDirection gapTablet={16} mobileRowDirection gapMobile={16}>
            {okvdField}

            {okfsField}

            {!isMobile && codeField}
          </FormRow>
        )}

        {isMobile && codeField}

        <Field label="Иные коды форм федерального государственного статистического наблюдения">
          <TextArea
            disabled
            autoresize
            value={otherStatisticsCodes}
            minAreaHeight={isMobile ? 104 : 144}
          />
        </Field>
      </FormCol>
    </CustomerBlocksAccordion>
  )
}

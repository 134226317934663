import * as React from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export const useSize = () => {
  const [size, setSize] = React.useState<DOMRectReadOnly>({
    width: window.innerWidth,
  } as any)

  React.useLayoutEffect(() => {
    setSize(window.document.body.getBoundingClientRect())
  }, [])

  // Where the magic happens
  useResizeObserver(window.document.body, (entry) => setSize(entry.contentRect))
  return size
}
import { rowsPerPage } from 'config/constants'
import { format } from 'date-fns'
import { useSize } from 'hooks/useSize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  fetchHistoryDepoFilterStartAsync,
  fetchHistoryDepoStartAsync,
  fetchOrdersTypesNamesStartAsync,
} from 'redux/history/historySlice'
import {
  selectHistoryDepo,
  selectHistoryOrderTypesAll,
  selectHistoryPortfolios,
  selectHistoryStatuses,
} from 'redux/history/selector'

import { selectCurrentStatus, selectCurrentThumbprint } from 'redux/user/selector'
import {
  FormCol,
  FormRow,
  Chips,
  CustomModal,
  Field,
  Select,
  DataTable,
  Pagination,
  DateField,
  IDataTableColumn,
  Icon, Button,
} from 'ui/components'
import { useTheme } from 'ui/theme'

import {
  getDeclensionWordOrder,
  getDeclensionWordSign,
} from 'utils/DeclensionWord'
import createUri from 'utils/FilterUri'
import { StyledWrapper } from './styled'
import {
  clearSecurities,
  deleteSecuritySuccess,
  fetchDeleteSecurityStartAsync,
  fetchGetSecurityStartAsync,
  fetchPerformSecurityStartAsync,
} from 'redux/securities/securitiesSlice'
import {
  selectCurrentDeleteSecurity,
  selectCurrentGetSecurity,
  selectCurrentPerformSecurity,
  selectCurrentPerformSecurityStatus,
} from 'redux/securities/selector'
import { formatDateServer } from 'utils/GetDate'
import {
  StyledBadge, StyledCheckboxField,
  StyledHistoryForm,
  StyledHistoryStatusCell,
  StyledHistoryTableButton,
  StyledTableActionsButtonWrapper,
  StyledTableActionsWrapper,
  StyledTableWrapper,
} from '../../styled'
import { getTableCellWidthPercent } from 'utils/helpers/table'
import SnackbarNotification from "../../../../molecules/Snackbar";
import MobileFilterNew from "../../../../../../components/MobileFilterNew/MobileFilterNew";
import MobileMultiselectMenu from "../mobileMultiselectMenu";
import { fetchStatusStartAsync } from "../../../../../../redux/user/userSlice";

import {
  securityInputUid,
  securityCancelUid,
  securityOutputUid,
  securityTransferUid,
  initialState,
  statuses,
  TOTAL_STATUS
} from './store'

export const History: React.FC<Record<string, any>> = () => {
  const size = useSize()
  const theme = useTheme()
  const isDesktop = theme.bp.isMD(size.width)
  const isMobile = theme.bp.isXXS(size.width)
  const history = useHistory()
  const dispatch = useDispatch()
  const [portfolioSelect, setPortfolioSelect] = useState(initialState.portfolio)
  const [orderTypeSelect, setOrderTypeSelect] = useState(initialState.orderType)
  const [statusSelect, setStatusSelect] = useState(initialState.status)
  const [dateFrom, setDateFrom] = useState(initialState.date.from)
  const [dateTo, setDateTo] = useState(initialState.date.to)
  const [page, setPage] = useState(0)
  const [arraySigned, setArraySigned] = useState([])
  const [arrayDeleteSigned, setArrayDeleteSigned] = useState([])
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [successSigned, setSuccessSigned] = useState(0)
  const [failSigned, setFailSigned] = useState(null)
  const [finishSignModalOpen, setFinishSignModalOpen] = useState(false)
  const [isCleared, setIsCleared] = useState(false)
  const [filterUri, setFilterUri] = useState('')
  const [finishDeleteSignModalOpen, setFinishDeleteSignModalOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [selectAllChecked, setSelectedAllChecked] = useState(false)

  const getThumbprintSelector = useSelector(selectCurrentThumbprint)
  const getUserStatus = useSelector(selectCurrentStatus)
  const historyDepoData = useSelector(selectHistoryDepo)
  const filterPortfolios = useSelector(selectHistoryPortfolios)
  const filterStatuses = useSelector(selectHistoryStatuses)
  const filterOrderTypesAll = useSelector(selectHistoryOrderTypesAll)
  const getPerformSecuritySelector = useSelector(selectCurrentPerformSecurity)
  const getPerformSecuritySelectorStatus = useSelector(selectCurrentPerformSecurityStatus)
  const getRespSecuritySelector = useSelector(selectCurrentGetSecurity)
  const deleteSecuritySelector = useSelector(selectCurrentDeleteSecurity)

  const getRespSecurity = useMemo(() => getRespSecuritySelector, [getRespSecuritySelector])
  const getPerformSecurity = useMemo(() => getPerformSecuritySelector, [getPerformSecuritySelector])

  const fetchPerformSecurityStart = (data, id) => {
    dispatch(fetchPerformSecurityStartAsync({ data, id }))
  }

  const fetchGetSecurityStart = (id) => {
    dispatch(fetchGetSecurityStartAsync({ id }))
  }

  const fetchOrdersTypesNamesStart = () => {
    return dispatch(fetchOrdersTypesNamesStartAsync())
  }

  const fetchDeleteSecurityStart = (id) => {
    dispatch(fetchDeleteSecurityStartAsync({ id }))
  }

  const fetchHistoryDepoStart = () => {
    return dispatch(fetchHistoryDepoStartAsync())
  }
  const fetchHistoryFilterStart = (obj) => {
    dispatch(fetchHistoryDepoFilterStartAsync({ obj }))
  }

  const handleSelectAll = () => {
    if (selectAllChecked) {
      setSelected([])
      setSelectedAllChecked(false)
      return
    }

    const newSelected =
      historyDepoData?.orders
        ?.map((item) => item?.status?.name === 'Черновик' && item?.id)
        ?.filter(Boolean) ?? []
    setSelected(newSelected)
    setSelectedAllChecked(true)
  }

  const fetchGetStatusStart = () => {
    dispatch(fetchStatusStartAsync())
  }

  const getListData = useCallback(() => {
      setArraySigned([])
      fetchHistoryDepoStart()
  }, [])
  // TODO: временный фикс
  // useNotificationEffect((response) => {
  //   const isDepo = response?.detail?.gridUid === 'Depo';
  //   isDepo && fetchHistoryDepoStart();
  // })

  useEffect(() => {
      dispatch(clearSecurities())
      setIsCleared(true)
      fetchOrdersTypesNamesStart()
      fetchGetStatusStart()
  }, [])

  useEffect(() => {
    getListData()
  }, [getListData])

  useEffect(() => {
    if (filterPortfolios?.length) {
      const portfolioNames = []
      filterPortfolios.map((portfolio) => {
        portfolioNames.push(portfolio.name)
        return portfolio
      })
      setPortfolioSelect({ ...portfolioSelect, portfolios: portfolioNames })
    }
  }, [filterPortfolios])

  useEffect(() => {
    let orderTypes = []
    filterOrderTypesAll?.length && filterOrderTypesAll.map((orderType) => {
      orderType.gridUID === 'Depo' && orderTypes.push(orderType.name)
    })
    setOrderTypeSelect({ ...orderTypeSelect, orderTypes: orderTypes })
  }, [filterOrderTypesAll])

  useEffect(() => {
    if (filterStatuses?.length) {
      const statusNames = [TOTAL_STATUS]

      filterStatuses.map((status) => {
        statusNames.push(status.name)
        return status
      })
      setStatusSelect({ ...statusSelect, statuses: statusNames })
    }
  }, [filterStatuses])

  useEffect(() => {
    if (historyDepoData?.orders?.length) {
      const currentOrdersLength = historyDepoData?.orders?.length
      if (page * rowsPerPage > currentOrdersLength) {
        setPage(0)
      }
      if (getUserStatus?.useCertificate && selected.length) {
        const arrayFoundChecked = []
        historyDepoData.orders.forEach((item) => {
          if (selected.some((selectElm) => item.id === selectElm)) {
            arrayFoundChecked.push(item.id)
          }
        })
        setSelected(arrayFoundChecked)
      }
    }
  }, [historyDepoData])

  const handleOpenRequest = (id, order) => {
    let nextLocation: any = ''
    if (
      (order?.type?.uid === securityCancelUid &&
        order?.affectedOrder?.type?.uid === securityTransferUid) ||
      order?.type?.uid === securityTransferUid
    ) {
      nextLocation = {
        pathname: `/transfers/securities/transfer/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    } else if (
      (order?.type?.uid === securityCancelUid &&
        order?.affectedOrder?.type?.uid === securityInputUid) ||
      order?.type?.uid === securityInputUid
    ) {
      nextLocation = {
        pathname: `/transfers/securities/input/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    } else if (
      (order?.type?.uid === securityCancelUid &&
        order?.affectedOrder?.type?.uid === securityOutputUid) ||
      order?.type?.uid === securityOutputUid
    ) {
      nextLocation = {
        pathname: `/transfers/securities/output/${id}`,
        state: { orderUid: order?.type?.uid },
      }
    }
    history.push(nextLocation)
  }

  const handleCloseFinishSignModal = () => {
    setFinishSignModalOpen(false)
    setFailSigned(null)
  }

  const handleRowRemove = (id) => {
    setSelected([id])
    if (selected.length) {
      setDeleteModalOpen(true)
    }
  }

  const handleChangePortfolioSelect = (option) => {
    setPortfolioSelect({
      ...portfolioSelect,
      currentPortfolio: option?.value,
    })
  }
  const handleChangeOrderTypeSelect = (option) => {
    setOrderTypeSelect({
      ...orderTypeSelect,
      currentOrderType: option?.value,
    })
  }

  const handleChangeStatusSelect = (value) => {
    setStatusSelect({
      ...statusSelect,
      currentStatus: value,
    })
  }

  const handleChangeDateTo = (date) => {
    setDateTo(date)
  }

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const formatDateFrom = useMemo(() => dateFrom && formatDateServer(dateFrom), [dateFrom])
  const formatDateTo = useMemo(() => dateTo && formatDateServer(dateTo), [dateTo])
  const handleAcceptFilter = () => {
    handleChangePage(0)
    const statusUID =
      filterStatuses?.find((item) => item.name === statusSelect.currentStatus)?.uid || null
    const accountId =
      filterPortfolios?.find((item) => item.name === portfolioSelect.currentPortfolio)?.id || null
    const typeUID =
      filterOrderTypesAll?.find((item) => item.name === orderTypeSelect.currentOrderType)?.uid || null
    let uri = 'Count=100'
    if (statusUID || accountId || typeUID || formatDateFrom || formatDateTo) {
      uri = createUri({
        typeUID,
        accountId,
        dateFrom: formatDateFrom,
        dateTo: formatDateTo,
        statusUID,
        name: 'ordersMoney',
        condition: 'and',
      })
      uri = `Count=100&Filter=${uri}`
    }
    setFilterUri(uri)

    fetchHistoryFilterStart(uri)
  }

  const handleResetFilter = () => {
    setFilterUri('')
    fetchHistoryFilterStart('Count=100')
    setPortfolioSelect({
      ...portfolioSelect,
      currentPortfolio: initialState.portfolio.currentPortfolio
    })
    setStatusSelect({
      ...statusSelect,
      currentStatus: initialState.status.currentStatus,
    })
    setOrderTypeSelect({
      ...orderTypeSelect,
      currentOrderType: initialState.orderType.currentOrderType
    })
    setDateFrom(initialState.date.from)
    setDateTo(initialState.date.to)
  }

  useEffect(() => {
    handleAcceptFilter()
  }, [
    statusSelect.currentStatus,
    portfolioSelect.currentPortfolio,
    orderTypeSelect.currentOrderType,
    formatDateFrom,
    formatDateTo,
  ])

  useEffect(() => {
    if ( deleteSecuritySelector?.status === 200 ) {
      setFinishDeleteSignModalOpen(true)
      dispatch(deleteSecuritySuccess(null))
    }
  }, [deleteSecuritySelector])

  const handleClosePerformModal = () => {
    setPerformModalOpen(false)
  }

  const handleSignSelectedRows = () => {
    if (selected.length) {
      setPerformModalOpen(true)
    }
  }

  const handleDeleteSelectedRows = () => {
    if (selected.length) {
      setDeleteModalOpen(true)
    }
  }

  const callActionOnOk = (actionMethod) => {
      selected.forEach((id) => {
        actionMethod(id)
      })
  }

  const handleDeleteOk = () => {
    callActionOnOk(fetchDeleteSecurityStart)
    setDeleteModalOpen(false)
  }

  const handlePerformOk = () => {
    callActionOnOk(fetchGetSecurityStart)
    handleClosePerformModal()
  }

  useEffect(() => {
    if (deleteSecuritySelector?.status && isCleared) {
      if (deleteSecuritySelector?.status === 200) {
        setArrayDeleteSigned((prev) => [
          ...prev,
          true,
        ])
        return
      }
      setArrayDeleteSigned((prev) => [
        ...prev,
        false,
      ])
    }
  }, [deleteSecuritySelector, isCleared])

  useEffect(() => {
    if (getRespSecurity?.order && isCleared) {
      const reqObj = { ...getRespSecurity?.order }
      if (getThumbprintSelector) {
        reqObj.thumbPrint = getThumbprintSelector
      }
      fetchPerformSecurityStart(reqObj, reqObj?.id)
    }
  }, [getRespSecurity, isCleared])

  useEffect(() => {
    if (getPerformSecuritySelectorStatus && isCleared) {
      if (getPerformSecuritySelectorStatus === 303) {
        const reqObj = { ...getPerformSecurity.order }
        reqObj.thumbPrint = getThumbprintSelector
        reqObj.signedXml = getPerformSecurity?.requiredAction?.signAction?.xml
        fetchPerformSecurityStart(reqObj, reqObj?.id)
        return
      }
      if (getPerformSecuritySelectorStatus === 200) {
        setArraySigned((prev) => [
          ...prev,
          true,
        ])
        return
      }
      setArraySigned((prev) => [
        ...prev,
        false,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPerformSecurity, isCleared, getPerformSecuritySelectorStatus])

  useEffect(() => {
    if (deleteSecuritySelector) {
      const isDeleteSuccess = deleteSecuritySelector?.status === 200
      const isPerformSuccess = getPerformSecuritySelectorStatus === 200

      setFinishDeleteSignModalOpen(true)

      if (isDeleteSuccess || isPerformSuccess) {
        getListData()
      }
    }
  }, [deleteSecuritySelector, getPerformSecurity, getListData, getPerformSecuritySelectorStatus])

  useEffect(() => {
    if (arraySigned?.length && selected.length && arraySigned?.length === selected.length) {
      setSuccessSigned(arraySigned.filter((x) => x).length)
      setFailSigned(arraySigned.filter((x) => !x).length)
      setArraySigned([])
      setSelected([])
      setFinishSignModalOpen(true)
        if (filterUri) {
          fetchHistoryFilterStart(filterUri)
        } else {
          fetchHistoryDepoStart()
        }
      return
    }
    if (
      arrayDeleteSigned?.length &&
      selected.length &&
      arrayDeleteSigned?.length === selected.length
    ) {
      setSuccessSigned(arrayDeleteSigned.filter((x) => x).length)
      setFailSigned(arrayDeleteSigned.filter((x) => !x).length)
      setArrayDeleteSigned([])
      setSelected([])
        if (filterUri) {
          fetchHistoryFilterStart(filterUri)
        } else {
          fetchHistoryDepoStart()
        }
    }
  }, [arraySigned, selected, arrayDeleteSigned])

  const handleCheckboxSelect = (row) => {
    if (row.status.uid !== 'Draft') {
      return
    }

    const selectedIndex = selected.findIndex((itemId) => itemId === row.id)
    const orders = historyDepoData?.orders

    let newSelected = []

    if (selectedIndex < 0) {
      newSelected = newSelected.concat(selected, orders.find((order) => order.id === row.id).id)
    } else {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1, selected.length),
      )
    }
    setSelected(newSelected)
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const filtersBarNode = (
    <FormRow>
      <FormCol flex={3}>
        <Field label="Портфель">
          <Select
            options={['', ...portfolioSelect?.portfolios]?.map((option) => ({
              label: option,
              value: option,
            }))}
            value={
              portfolioSelect?.currentPortfolio
                ? {
                  value: portfolioSelect?.currentPortfolio,
                  label: portfolioSelect?.currentPortfolio,
                }
                : null
            }
            onChange={handleChangePortfolioSelect}
            placeholder="Введите номер или выберите из списка"
          />
        </Field>
      </FormCol>
      <FormCol flex={2}>
        <Field label="Тип">
          <Select
            options={['', ...orderTypeSelect?.orderTypes]?.map((option) => ({
              label: option,
              value: option,
            }))}
            value={
              orderTypeSelect?.currentOrderType
                ? {
                  value: orderTypeSelect?.currentOrderType,
                  label: orderTypeSelect?.currentOrderType,
                }
                : null
            }
            onChange={handleChangeOrderTypeSelect}
            placeholder="Выберите из списка"
          />
        </Field>
      </FormCol>
      <FormCol flex={3}>
        <Field label="Дата или период">
          <DateField
            placeholder="Период с"
            value={dateFrom}
            onChange={setDateFrom}
            maxDate={dateTo}
          />
          <DateField
            placeholder="Период по"
            value={dateTo || dateFrom}
            onChange={handleChangeDateTo}
            minDate={dateFrom}
          />
        </Field>
      </FormCol>
      {!isDesktop && (
        <FormCol flex={3}>
          <Field label="Статус">
            <Select
              options={statuses?.map((option) => ({
                label: option,
                value: option,
              }))}
              value={statusSelect.currentStatus}
              onChange={(status) => handleChangeStatusSelect(status.value)}
              placeholder="Выберите из списка"
            />
          </Field>
        </FormCol>
      )}
    </FormRow>
  )

  return (
    <>
      <StyledWrapper>
        <StyledHistoryForm>
          {isMobile && !selected?.length && (
            <MobileFilterNew
              handleAcceptFilter={handleAcceptFilter}
              handleResetFilter={handleResetFilter}
              constItems={[
                portfolioSelect?.currentPortfolio,
                statusSelect?.currentStatus,
                orderTypeSelect?.currentOrderType,
                dateFrom,
                dateTo
              ]}
            >
              {filtersBarNode}
            </MobileFilterNew>
          )}

          {isMobile && !!selected?.length && (
            <MobileMultiselectMenu
              transfersCount={selected?.length}
              handleSign={handleSignSelectedRows}
              handleDelete={handleDeleteSelectedRows}
            />
          )}

          {!isMobile && filtersBarNode}

          {isDesktop && (
            <FormRow>
              <Chips
                options={statuses?.map((name, key) => ({ label: name, value: name }))}
                value={statusSelect.currentStatus}
                onChange={handleChangeStatusSelect}
              />
            </FormRow>
          )}
        </StyledHistoryForm>
        {getUserStatus?.useCertificate && (
          <StyledTableActionsWrapper>
            <StyledCheckboxField checked={selectAllChecked} label='Выбрать всё' onChange={handleSelectAll}/>

            <StyledTableActionsButtonWrapper>
              <Button disabled={!selected.length} onClick={handleSignSelectedRows}>Подписать</Button>
              <Button disabled={!selected.length} variant='negative-outline' onClick={handleDeleteSelectedRows}>Удалить</Button>
            </StyledTableActionsButtonWrapper>
          </StyledTableActionsWrapper>
        )}
        <StyledTableWrapper>
          <Table
            rows={historyDepoData?.orders?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)}
            onRowClick={(row) => handleOpenRequest(row.id, row)}
            useCertificate={getUserStatus?.useCertificate}
            handleRemove={handleRowRemove}
            handleDownload={() => {}}
            selectedRows={selected}
            handleCheckboxSelect={getUserStatus?.useCertificate && handleCheckboxSelect}
          />
        </StyledTableWrapper>
        {!!historyDepoData?.orders?.length && (
          <Pagination
            total={historyDepoData?.orders?.length}
            current={page + 1}
            onChange={(page) => handleChangePage(page - 1)}
            pageSize={rowsPerPage}
          />
        )}
      </StyledWrapper>
      <CustomModal
        open={performModalOpen}
        title={`Вы действительно хотите подписать ${selected.length} ${getDeclensionWordOrder(selected.length)}?`}
        handleClose={handleClosePerformModal}
        callbacks={{
          withOk: handlePerformOk,
          withCancel: handleClosePerformModal,
        }}
      />
      <CustomModal
        open={finishSignModalOpen}
        title={`${
          (successSigned &&
            `${successSigned} ${getDeclensionWordOrder(
              successSigned
            )} успешно ${getDeclensionWordSign(successSigned)} \n`) ||
          ''
        } ${
          (failSigned &&
            `${failSigned} ${getDeclensionWordOrder(failSigned)} не ${getDeclensionWordSign(
              failSigned
            )}`) ||
          ''
        }`}
        handleClose={handleCloseFinishSignModal}
        callbacks={{
          withClose: () => {
            setFinishSignModalOpen(false)
          },
        }}
      />

      <CustomModal
        open={deleteModalOpen}
        title={`Вы действительно хотите удалить ${selected.length} ${getDeclensionWordOrder(selected.length)}?`}
        actionText="Отменить это действие будет невозможно"
        textOk="Да, удалить"
        handleClose={handleCloseDeleteModal}
        callbacks={{
          withOk: handleDeleteOk,
          withCancel: handleCloseDeleteModal,
        }}
      />
      <SnackbarNotification
        open={finishDeleteSignModalOpen}
        message="Черновик поручения удален"
        onClose={() => {
          setFinishDeleteSignModalOpen(false)
        }}
      />
    </>
  )
}

interface TableProps {
  rows: any[]
  onRowClick: any
  useCertificate: any
  handleRemove: any
  handleDownload: any
  selectedRows: any
  handleCheckboxSelect
}

const Table: React.FC<TableProps> = ({
  rows,
  onRowClick,
  useCertificate,
  handleRemove,
  handleDownload,
  selectedRows,
  handleCheckboxSelect
}) => {
  const size = useSize()
  const theme = useTheme()
  const isDesktop = theme.bp.isMD(size.width)

  const tableWidth = 1104

  const newColumnsLayout = [
    getTableCellWidthPercent(184, tableWidth),
    getTableCellWidthPercent(73, tableWidth),
    getTableCellWidthPercent(111, tableWidth),
    getTableCellWidthPercent(101, tableWidth),
    getTableCellWidthPercent(267, tableWidth),
    getTableCellWidthPercent(77, tableWidth),
    getTableCellWidthPercent(useCertificate ? 239 : 289, tableWidth),
  ]
  const getFormatDate = (date, formatDate) => format(new Date(date), formatDate)
  const columns: IDataTableColumn<ITableRow>[] = [
    {
      id: 'name',
      title: isDesktop ? 'Дата отправления' : 'Дата',
      width: '14.8%',
      render: (row) => getFormatDate(row?.dateTime, "dd.MM.yyyy'T'HH:mm").replace('T', ' '),
    },
    {
      id: 'number',
      title: 'Номер',
      width: '11.1%',
    },
    {
      id: 'type',
      title: 'Тип',
      render: (row) => row?.type?.name,
    },
    {
      id: 'clientPortfolio',
      title: 'Портфель',
      render: (row) => row?.clientPortfolio?.name,
    },
    {
      id: 'clientPortfolio',
      title: 'Инструмент',
      render: (row) => row?.security?.name || row?.affectedOrder?.security?.name,
    },
    {
      id: 'amount',
      title: 'Количество',
      render: (row) => (
        <span style={{ marginLeft: 10 }}>
          {
            row?.volume ||
            row?.amount ||
            row?.affectedOrder?.amount ||
            row?.affectedOrder?.volume ||
            0
          }
        </span>
      ),
    },
    {
      id: 'status',
      title: 'Статус',
      _render: (row) => (
        <StyledHistoryStatusCell>
          <StyledBadge
            variant={
              row?.status?.name === 'Исполнено'
                ? 'success'
                : row?.status?.name === 'Отказ в исполнении'
                ? 'danger'
                : 'default'
            }
          >
            {row?.status?.name}
          </StyledBadge>
          <>
            {row?.status?.uid === 'Draft' ? (
              <StyledHistoryTableButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleRemove(row.id)
                }}
                variant={'plain'}
              >
                <Icon name="trash" size={24} />
              </StyledHistoryTableButton>
            ) : (
              <StyledHistoryTableButton
                variant={'plain'}
                onClick={(e) => {
                  e.stopPropagation()
                  handleDownload()
                }}
              >
                <Icon name="download" size={24} />
              </StyledHistoryTableButton>
            )}
          </>
        </StyledHistoryStatusCell>
      ),
      get render() {
        return this._render
      },
      set render(value) {
        this._render = value
      },
    },
  ].filter(Boolean)

  return (
    <DataTable<ITableRow>
      columns={columns}
      rows={rows}
      layout={newColumnsLayout}
      onRowClick={onRowClick}
      checkbox={{
        isVisible: !!handleCheckboxSelect,
        checked: (row) => selectedRows.some((itemId) => itemId === row.id),
        disabled: (row) => row?.status.uid !== 'Draft',
        onChange: (row) => handleCheckboxSelect(row),
        size: 'md'
      }}
    />
  )
}

export interface ITableRow extends Record<string, any> {}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import classnames from 'classnames'
import useStyles from './sharedStyles'
import CustomInput from '../../CustomControls/CustomInput'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`firmcontent-tabpanel-${index}`}
      aria-labelledby={`firmcontent-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const AddressContent = ({ data, handleClickNextPanel, isNeedNextButton }) => {
  const classes = useStyles()

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.contentTabs}
              aria-label="firm content tab "
            >
              <Tab label="сведения на русском" />
              <Tab label="сведения на иностранном" />
            </Tabs>

            <TabPanel
              value={value}
              index={0}
            >
              <Grid container>
                <Grid
                  item
                  container
                  className={classes.row}
                  justify="space-between"
                  spacing={2}
                >
                  <Grid
                    item
                    sm={7}
                    xs={12}
                  >
                    <CustomInput
                      name="country"
                      label="Страна"
                      value={data?.address?.country?.nameRu || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    sm={5}
                    xs={12}
                  >
                    <CustomInput
                      name="index"
                      label="Индекс"
                      value={data?.address?.postCode || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classnames(classes.row, classes.row_pbLess)}
                >
                  <CustomInput
                    name="region"
                    label="Регион"
                    value={data?.address?.regionRu || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    unique
                  />
                </Grid>
                <Grid
                  item
                  container
                  className={classes.row}
                  justify="space-between"
                  spacing={2}
                >
                  <Grid
                    item
                    sm={6}
                    xs={12}
                  >
                    <CustomInput
                      name="city"
                      label="Город"
                      value={data?.address?.cityRu || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={12}
                  >
                    <CustomInput
                      name="street"
                      label="Улица"
                      value={data?.address?.streetRu || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.row}
                  justify="space-between"
                  spacing={2}
                >
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="house"
                      label="Дом"
                      value={data?.address?.house || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="builtNumb"
                      label="Строение"
                      value={data?.address?.building || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="houseNumb"
                      label="Корпус"
                      value={data?.address?.block || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="apart"
                      label="Квартира"
                      value={data?.address?.apartment || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="office"
                      label="Офис"
                      value={data?.address?.office || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel
              value={value}
              index={1}
            >
              <Grid container>
                <Grid
                  item
                  container
                  className={classes.row}
                  justify="space-between"
                  spacing={2}
                >
                  <Grid
                    item
                    sm={7}
                    xs={12}
                  >
                    <CustomInput
                      name="country"
                      label="Страна"
                      value={data?.address?.country?.nameEn || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    sm={5}
                    xs={12}
                  >
                    <CustomInput
                      name="index"
                      label="Индекс"
                      value={data?.address?.postCode || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classnames(classes.row, classes.row_pbLess)}
                >
                  <CustomInput
                    name="region"
                    label="Регион"
                    value={data?.address?.regionEn || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    unique
                  />
                </Grid>
                <Grid
                  item
                  container
                  className={classes.row}
                  justify="space-between"
                  spacing={2}
                >
                  <Grid
                    item
                    sm={6}
                    xs={12}
                  >
                    <CustomInput
                      name="city"
                      label="Город"
                      value={data?.address?.cityEn || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    xs={12}
                  >
                    <CustomInput
                      name="street"
                      label="Улица"
                      value={data?.address?.streetEn || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  className={classes.row}
                  justify="space-between"
                  spacing={2}
                >
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="house"
                      label="Дом"
                      value={data?.address?.house || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="builtNumb"
                      label="Строение"
                      value={data?.address?.building || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="houseNumb"
                      label="Корпус"
                      value={data?.address?.block || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="apart"
                      label="Квартира"
                      value={data?.address?.apartment || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    md
                    sm
                    xs={12}
                  >
                    <CustomInput
                      name="office"
                      label="Офис"
                      value={data?.address?.office || ''}
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled
                      unique
                    />
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <div className={classes.Mobile}>
            {data?.address?.country?.nameRu && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Страна
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.address?.country?.nameRu}
              </div>
            </div>
            )}
            {data?.address?.postCode && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Индекс
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.address?.postCode}
              </div>
            </div>
            )}
            {data?.address?.regionRu && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Регион
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.address?.regionRu}
              </div>
            </div>
            )}
            {data?.address?.regionRu && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Город
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.address?.regionRu}
              </div>
            </div>
            )}
            {data?.address?.streetRu && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Улица
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.address?.streetRu}
              </div>
            </div>
            )}
            <div className={classes.Mobile__blockAddressee}>
              {data?.address?.house && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Дом
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.address?.house}
                </div>
              </div>
              )}
              {data?.address?.building && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Строение
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.address?.building}
                </div>
              </div>
              )}
              {data?.address?.block && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Корпус
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.address?.block}
                </div>
              </div>
              )}
              {data?.address?.apartment && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Квартира
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.address?.apartment}
                </div>
              </div>
              )}
            </div>
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </div>
        )}
      />
    </>
  )
}

AddressContent.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

AddressContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

export default AddressContent

import React, { useCallback, ChangeEvent, memo, useRef, useState, useEffect } from 'react'
import { divideNumbersWithSpace } from 'utils/helpers/numbers'
import { StyledCurrencyTextField, StyledMaskedTextField, StyledTextField } from './styled'
import { ITextFieldProps } from './types'

const TextField = ({
  onChange,
  divideValueWithSpace,
  value,
  mask,
  maskChar,
  ...otherProps
}: ITextFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onDividedChangeHandler = (value) => {
    //@ts-ignore
    onChange?.({ target: { value: value || '' } })
  }

  const textFieldProps = {
    onChange,
    value,
    ...otherProps,
  }

  if (divideValueWithSpace)
    return (
      <StyledCurrencyTextField
        onValueChange={onDividedChangeHandler}
        {...otherProps}
        value={value}
        onChange={undefined}
        step={1}
        defaultValue={''}
        allowNegativeValue={false}
        allowDecimals
        decimalSeparator="."
        decimalsLimit={20}
      />
    )

  return mask ? (
    <StyledMaskedTextField {...textFieldProps} mask={mask} maskChar={maskChar} />
  ) : (
    <StyledTextField ref={inputRef} {...textFieldProps} />
  )
}

export { TextField }

import React from 'react';
import { useSizeContext } from 'context/SizeContext';
import { useTheme } from 'ui/theme';
import { Button, Icon } from 'ui/components';
import {
  StyledSidebar,
  StyledSidebarItem,
  StyledSidebarItemText,
  StyledSidebarLogo,
  StyledSidebarNav,
  StyledSidebarHeader,
} from './styled';
import { ISidebarProps } from './types';

export const Sidebar: React.FC<ISidebarProps> = ({ onClose = () => null, items = [], children, shown }) => {
  const size = useSizeContext();
  const theme = useTheme();

  const isMobile = theme.bp.isXS(size.width);
  return <StyledSidebar shown={shown}>
    <StyledSidebarHeader>
      {
        isMobile ? <>
          <StyledSidebarLogo name="logo-full" size={140} />
          <Button variant="sidebar" onClick={onClose}>
            <Icon name="close" size={'md'} />
          </Button>
        </> :
          <StyledSidebarLogo name="logo" size={42} />
      }
    </StyledSidebarHeader>
    {children}
    <StyledSidebarNav>
      {

        items
          .map(item => (
          <StyledSidebarItem to={item.link} key={item.link}>
            <Icon name={item.icon} size={'md'} />
            <StyledSidebarItemText>{item.title}</StyledSidebarItemText>
          </StyledSidebarItem>
        ))

      }
    </StyledSidebarNav>
    <StyledSidebarItem to="/logout">
      <Icon name="menu-logout" size={'md'} />
      <StyledSidebarItemText>Выход</StyledSidebarItemText>
    </StyledSidebarItem>
  </StyledSidebar>;
};

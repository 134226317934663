import { useSize } from 'hooks/useSize'
import React, {ChangeEvent, useState} from 'react'
import { Button, CustomModal, Field, Form, FormCol, FormRow, Text, TextField } from 'ui/components'
import { styled, theme, useTheme } from 'ui/theme'
import Timer from 'utils/Timer'
import logBroker from "components/ReportPanels/LogBroker";
import {ImmitStyle} from "./styled";
import {isRequired} from "../../../../../../utils/Validate";

interface IProps {
  title: string
  handleResetTimer: VoidFunction
  handleStartTimer: (v: boolean) => void
  hashSha256: string
  showTimer: boolean
  codeError?: boolean
  codeTextError?: string
  code: string
  handleChangeCodeInput: (e: ChangeEvent) => void
  handleSubmit: (e) => void
  handleClose?: (e) => void
  isOpen?: boolean;
  /**
   * Показ блока имитовставки.
   */
  isShowImmitPaste?: boolean;
  setCodeError?: any,
  setCodeTextError?: any,
}

export const SMSConfirmModal: React.FC<IProps> = ({
  title,
  handleResetTimer,
  handleStartTimer,
  hashSha256,
  showTimer,
  codeError,
  codeTextError,
  code,
  handleChangeCodeInput,
  handleClose,
  handleSubmit,
  isOpen,
  isShowImmitPaste = false,
  setCodeError,
  setCodeTextError,
}) => {
  const DEFAULT_SECONDS = 30;
  const size = useSize();
  const theme = useTheme();
  const isMobile = theme.bp.isXS(size.width);
  const [restartTrigger, setRestart] = useState<{}>({});


  const handleSub = () => {
    if(!code) {
      setCodeError(true)
      setCodeTextError('Поле обязательно для заполнения')
    }
  }



  return <CustomModal
    infinity
    title={title}
    handleClose={handleClose}
    open={isOpen}
  >
    <StyledCont>
      <Text variant={'body2'}>
        Введите код из СМС-сообщения. Если код не пришёл, {!isMobile ? <br /> : ''}вы можете запросить новый через <Text variant={'body1'} color="accent"><Timer seconds={DEFAULT_SECONDS} restart={restartTrigger} handleStartTimer={handleStartTimer} /></Text>
      </Text>
      <Text
        hidden={!isShowImmitPaste}
        variant={'body1'}>
        Контрольная сумма
      </Text>

      <ImmitStyle>
        <Text
          hidden={!isShowImmitPaste}
          variant={'body1'}>
          {hashSha256}
        </Text>
      </ImmitStyle>

      <StyledForm onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e);
      }}>
        <StyledFormRow gap={8}>
          <StyledFormCol flex={1}>
            <Field
              caption={codeError && codeTextError}
              hasError={codeError}
            >
              <TextField
                hasError={codeError}
                placeholder={'Код из СМС'}
                value={code}
                onChange={(event) => handleChangeCodeInput(event)}
                autoComplete="off"
                type="tel"
                formNoValidate
              />
            </Field>
          </StyledFormCol>

          { (!!setCodeError && !!setCodeTextError)
            ? <StyledButton type="submit" onClick={handleSub}>Подтвердить</StyledButton>
            : <StyledButton type="submit">Подтвердить</StyledButton>
          }

        </StyledFormRow>
        <StyledResetWrapper>
          <StyledRepeatButton
            disabled={showTimer}
            onClick={ () => {
              handleResetTimer()
              setRestart({})
            }}
          >Отправить код ещё раз</StyledRepeatButton>
        </StyledResetWrapper>
      </StyledForm>
    </StyledCont>
  </CustomModal>
}

const StyledRepeatButton = styled.button`
  margin: 0;
  padding: 0;

  border: none;
  color: #2C96DA;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  background-color: transparent;

  &:disabled {
    color: #8C93A8;
  }
`

const StyledResetWrapper = styled.div`
  padding: 10px;
  text-align: center;
  margin-top: 18px;
  button {
    text-decoration: underline;
    cursor: pointer;

  }
`
const StyledCont = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`
const StyledForm = styled(Form)`
  gap: 0;
  text-align: left;
`

const StyledFormRow = styled(FormRow)`
  align-items: flex-start;

  & span {
    bottom: -18px;
    @media ${({ theme }) => theme.breakpoint.xs} {
      bottom: 0;
    }
  }

  @media(${({ theme }) => theme.breakpoint.xs}) {
    align-items: normal;
  }
`

const StyledFormCol = styled(FormCol)`
  @media ${({ theme }) => theme.breakpoint.xxs} {
    width: 100%;
  }
`

const StyledButton = styled(Button)`
  @media ${({ theme }) => theme.breakpoint.xxs} {
    width: 100%;
  }
`

import { createSelector } from '@reduxjs/toolkit'

const selectNotifications = (state) => state.notifications

export const selectLastIdNotifications = createSelector(
  [selectNotifications],
  (state) => state.lastId,
)

// eslint-disable-next-line
export const selectCurrentNotifications = createSelector(
  [selectNotifications],
  (state) => state.notifications,
)

// eslint-disable-next-line
export const selectCurrentNotificationsClone = createSelector(
  [selectNotifications],
  (state) => state.notificationsClone,
)

export const selectNotificationsModalOpen = createSelector(
  [selectNotifications],
  (state) => state.notificationsModalOpen,
)

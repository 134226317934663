import { color } from 'ui/theme'

export const getSelectBorderColor = (
  isFocused: boolean,
  isDisabled: boolean,
  hasError: boolean
) => {
  if (isFocused) return color.button.primary
  if (isDisabled) return color.bg.secondary
  if (hasError) return color.text.negative

  return color.border.default
}

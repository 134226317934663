import { styled } from "ui/theme";

export const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  .ant-pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    li {
      cursor: pointer;
      a {
        border-radius: 4px;
        display: block;
        min-width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        ${({ theme }) => theme.font.body1_lg};
        color: ${({ theme }) => theme.color.graphics.primary};
      }
      .anticon {
        display: none;
      }
      svg {
        display: block;
      }
    }
    .ant-pagination-item-active {
      background: rgba(16, 156, 241, 0.1);
      a {
        color: ${({ theme }) => theme.color.button.primary};
      }
    }
    .ant-pagination-item {
      a {
        padding: 0 8px;
      }
    }
    .ant-pagination-prev {
      margin-right: 8px;
    }
    .ant-pagination-next {
      margin-left: 8px;
    }
    .ant-pagination-disabled {
      color: ${({ theme }) => theme.color.graphics.disabled};
    }
  }
`
import { checkInputRegExp } from 'hooks/useInput'
import React, { useEffect, useMemo, useState, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'
import { selectCurrentError } from 'redux/error/selector'
import { selectCurrentMetadata } from 'redux/metadata/selector'
import { selectCurrentSecurities } from 'redux/securities/selector'
import {
  selectGetBalanceSecurity,
  selectGetTransferSecurity,
  selectPerformTransferSecurity,
  selectSaveTransferSecurity,
  selectSaveTransferSecurityStatus,
  selectPerformTransferSecurityStatus,
} from 'redux/transfers/security/selector'
import { currentUserLoginAndId, selectCurrentThumbprint } from 'redux/user/selector'
import {
  Button,
  TextField,
  Form,
  FormCol,
  FormFooter,
  FormFooterActions,
  FormRow,
  BlockHeading,
  CustomAutocomplete,
  CustomModal,
  DateField,
  Icon,
} from 'ui/components'
import { Field, PriceChangeCard, Select } from 'ui/components'
import {
  clearMetadata,
  fetchMetadataStartAsync,
} from 'redux/metadata/metadataSlice'
import {
  clearPartTransferSecurity,
  clearTransferSecurity,
  fetchPerformTransferSecurityStartAsync,
  fetchSaveTransferSecurityStartAsync,
  fetchTransferSecurityStartAsync,
  fetchGetBalancesDepoStartAsync,
} from 'redux/transfers/security/securityMoneySlice'
import {
  clearSecurities,
  fetchSecuritiesStartAsync,
} from 'redux/securities/securitiesSlice'
import { format } from 'date-fns'
import createUri from 'utils/FilterUri'
import formAction from 'utils/FormAction'
import JsonToSHA256 from 'utils/JsonToSHA256'
import { encodeToUTF8 } from 'utils/EncodeToUTF8'
import { SMSConfirmModal } from '../modals/SMSConfim'
import { formatDateServer } from 'utils/GetDate'
import { TransferHeading } from '../parts/TransferHeading'
import { StyledFormRightCol, StyledPriceChangeCardWrapper } from '../../styled'
import { setConfirmStatusModal } from 'redux/ui/uiSlice'
import { theme } from 'ui/theme'
import { initialTransferGroupData } from './data'
import { IChangeGroupDataByKeyHandler } from './types'
import { ROUTES } from 'app/data'
import { useDeleteSecurity } from './hooks/useDeleteSecurity'
import { clearError } from '../../../../../../redux/error/errorSlice'
import { useScrollTop } from "../../../../../../hooks/useScrollTop";
import moment from "moment";

const initialState = {
  portfolio: {
    minus: '',
    plus: '',
  },
  storage: {
    minus: '',
    plus: '',
  },
  // issuer: {
  //   id: null,
  //   isin: '',
  //   name: '',
  //   registerNumber: '',
  //   type: '',
  // },
  // searchStr: [''],
  input: {
    type: '',
    id: null,
    code: '',
    name: '',
    isin: '',
    registerNumber: '',
  },
  validity: new Date(),
  expirationDate: null,
}

const transferType = 'Orders.Type.NonTrade.Depo.Transfer'
const cancelType = 'Orders.Type.Modify.Cancellation'

export const Transfer: React.FC = () => {
  const scrollToTop = useScrollTop()

  const history = useHistory()
  const dispatch = useDispatch()
  const urlParams = useParams<{ id?: string }>()
  const location = useLocation()
  const transferId = useMemo(() => urlParams?.id, [urlParams])
  const transferUid = useMemo(() => location?.state?.orderUid, [location])
  const [metadataUid, setMetadataUid] = useState(transferUid || transferType)
  const [formDisabled, setFormDisabled] = useState(false)
  const [portfolioMinus, setPortfolioMinus] = useState(initialState.portfolio.minus)
  const [portfolioPlus, setPortfolioPlus] = useState(initialState.portfolio.plus)
  const [storageMinus, setStorageMinus] = useState(initialState.storage.minus)
  const [storagePlus, setStoragePlus] = useState(initialState.storage.plus)
  const [selectError, setSelectError] = useState(false)
  const [input, setInput] = useState<any>(initialState.input)
  const [groupOneVisibility, setGroupOneVisibility] = useState(false)
  const [_, setIssuer] = useState<any>({}) //TODO remove
  const [disabled, setDisabled] = useState(transferId === 'new')
  const [activeId, setActiveId] = useState('')
  const [showTimer, setShowTimer] = useState(false)
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [codeTextError, setCodeTextError] = useState<any>(false)
  const [submitAction, setSubmitAction] = useState(null)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [updModalOpen, setUpdModalOpen] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [clientPortfolioError, setClientPortfolioError] = useState(false)
  const [clientPortfolioTextError, setClientPortfolioTextError] = useState('')
  const [clientDepoStorageError, setClientDepoStorageError] = useState(false)
  const [clientDepoStorageTextError, setClientDepoStorageTextError] = useState('')
  const [partnerDepoStorageError, setPartnerDepoStorageError] = useState(false)
  const [partnerDepoStorageTextError, setPartnerDepoStorageTextError] = useState('')
  const [partnerPortfolioError, setPartnerPortfolioError] = useState(false)
  const [partnerPortfolioTextError, setPartnerPortfolioTextError] = useState('')
  const [clientBalances, setClientBalances] = useState(null)
  const [partnerBalances, setPartnerBalances] = useState(null)
  const [transferHeader, setTransferHeader] = useState('')
  const [isCleared, setIsCleared] = useState(false)
  const [hashSha256, setHashSha256] = useState(null)

  const getRespSecurities = useSelector(selectCurrentSecurities)
  const getRespMetadata = useSelector(selectCurrentMetadata)
  const getRespTransferSecurity = useSelector(selectGetTransferSecurity)
  const getSaveTransferSecurity = useSelector(selectSaveTransferSecurity)
  const statusRequestSave = useSelector(selectSaveTransferSecurityStatus)
  const statusRequestPerform = useSelector(selectPerformTransferSecurityStatus)
  const getPerformTransferSecurity = useSelector(selectPerformTransferSecurity)
  const getBalances = useSelector(selectGetBalanceSecurity)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)

  const portfolios = getRespMetadata?.references?.portfolios || []
  const [clientDepoStorage, setClientDepoStorage] = useState([])
  const [partnerDepoStorage, setPartnerDepoStorage] = useState([])
  const [currentStatus, setCurrentStatus] = useState<any>('')
  const [canModifyDate, setCanModifyDate] = useState(true)
  const order = getRespTransferSecurity?.order
  const [currentActions, setCurrentActions] = useState(null)
  const [secretPassPhrase, setSecretPassPhrase] = useState('')
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const [groupData, setGroupData] = useState([initialTransferGroupData])
  const [disableValidation, setDisableValidation] = useState(false)
  const [copyStatus, setCopyStatus] = useState(false)
  const [copyData, setCopyData] = useState<{
    order: {},
    portfolios: {},
    validity: {},
    issuer: {}
  } | null>(null)
  const [copyTransferDate, setCopyTransferDate] = useState(null)

  const currentStatusDescription =
    order?.statusDescription && sanitizeHtml(order?.statusDescription)

  const createMarkup = () => ({ __html: currentStatusDescription })

  const setConfirmModalStatus = (status) => {
    dispatch(setConfirmStatusModal(status))
  }

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchSecuritiesStart = (obj) => {
    dispatch(fetchSecuritiesStartAsync(obj))
  }

  const fetchTransferSecurityStart = (id) => {
    dispatch(fetchTransferSecurityStartAsync({ id }))
  }

  const fetchSaveTransferSecurityStart = (data, id) => {
    dispatch(fetchSaveTransferSecurityStartAsync({ data, id }))
  }

  const fetchPerformTransferSecurityStart = (data, id) => {
    dispatch(fetchPerformTransferSecurityStartAsync({ data, id }))
  }

  const fetchGetBalancesDepoStart = (filterBody) => {
    dispatch(fetchGetBalancesDepoStartAsync({ filterBody }))
  }

  const todayDate = moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD')

  const onDeleteSuccessHandler = () => {
    history.push(ROUTES.transfersSecuritiesLog)
  }

  const { DeleteModals, handleRowRemove } = useDeleteSecurity({
    onSuccess: onDeleteSuccessHandler,
  })

  const onDeleteHandler = () => {
    transferId && handleRowRemove(transferId)
  }

  const changeGroupDataByKeyHandler = ({
    key,
    value,
    groupIndex,
    object,
  }: IChangeGroupDataByKeyHandler) => {
    setGroupData((prev) =>
      prev.map((currentData, currenIndex) => {
        if (currenIndex === groupIndex) {
          return {
            ...currentData,
            ...(key && { [key]: value }),
            ...(object ?? {}),
          }
        }
        return currentData
      })
    )
  }

  const fillFields = (resObj, metadataPortfolios) => {
    if (metadataPortfolios && Object.keys(metadataPortfolios)?.length) {
      const licenseMinus = metadataPortfolios?.find(
        (item) => item.name === resObj?.clientPortfolio?.name
      )
      setClientDepoStorage(licenseMinus?.depoStorages)
      const licensePlus = metadataPortfolios?.find(
        (item) => item.name === resObj?.partnerPortfolio?.name
      )
      setPartnerDepoStorage(licensePlus?.depoStorages)
    } else {
      setClientDepoStorage([resObj?.clientDepoStorage])
      setPartnerDepoStorage([resObj?.partnerDepoStorage])
    }
    setPortfolioMinus(resObj?.clientPortfolio?.name)
    setPortfolioPlus(resObj?.partnerPortfolio?.name)
    setStorageMinus(resObj?.clientDepoStorage?.name || '')
    setStoragePlus(resObj?.partnerDepoStorage?.name || '')

    const transferSearchStr = resObj?.security?.name || '' // TODO check when get array

    setIssuer(resObj?.security)
    setInput((state) => ({
      ...state,
      type: resObj?.security?.type,
      registerNumber: resObj?.security?.registerNumber,
      isin: resObj?.security?.isin,
      id: resObj?.security?.id,
    }))

    const transferAmount = resObj?.volume || '' // TODO check when get array

    const transferDate =
      (getRespTransferSecurity?.order?.expirationDate &&
        new Date(getRespTransferSecurity.order.expirationDate)) ||
      copyTransferDate ||
      null // TODO check when get array

    let transferGroupTwoVisibility = false // TODO check when get array

    if (currentActions?.canModify || resObj?.status?.uid === 'Draft') {
      setDisabled(false)
      transferGroupTwoVisibility = true // TODO check when get array
    } else {
      setDisabled(true)
      transferGroupTwoVisibility = true // TODO check when get array
    }
    // eslint-disable-next-line no-underscore-dangle
    if (!resObj?._links[0]?.canModify) {
      setDisabled(true)
      setCanModifyDate(false)
    }

    changeGroupDataByKeyHandler({
      groupIndex: 0,
      object: {
        searchStr: transferSearchStr,
        amount: transferAmount,
        validity: transferDate,
        groupTwoVisibility: transferGroupTwoVisibility,
        issuer: copyData?.issuer
          ? copyData?.issuer
          : getRespTransferSecurity?.order?.security
      },
    })
  }

  const clearFields = (needClearData = true) => {
    if (needClearData) {
      dispatch(clearMetadata())
    }
    dispatch(clearTransferSecurity())
    dispatch(clearSecurities())
    setPortfolioMinus(initialState.portfolio.minus)
    setPortfolioPlus(initialState.portfolio.plus)
    setStorageMinus(initialState.storage.minus)
    setStoragePlus(initialState.storage.plus)
    setGroupOneVisibility(false)
    setInput(initialState.input)
    setClientDepoStorage([])
    setPartnerDepoStorage([])
    setDisabled(false)
    setClientPortfolioError(false)
    setClientDepoStorageError(false)
    setPartnerDepoStorageError(false)
    setPartnerPortfolioError(false)
    setCodeError(false)
    setCodeTextError('')
    setClientBalances(null)
    setPartnerBalances(null)
    setIsCleared(true)
    setCurrentActions(null)
    setCode('')
    setSecretPassPhrase('')
    setTransferHeader('')

    setGroupData([{ ...initialTransferGroupData, amount: '' }])
  }

  const handleBackToHistory = () => {
    history.push('/transfers/securities/log')
  }

  const listMainFields = () => {
    let licenseMinus = null
    let licensePlus = null
    let depoStorageMinus = null
    let depoStoragePlus = null
    if (transferUid === cancelType) {
      licenseMinus = getRespTransferSecurity?.order?.affectedOrder?.clientPortfolio
      licensePlus = getRespTransferSecurity?.order?.affectedOrder?.partnerPortfolio
      depoStorageMinus = getRespTransferSecurity?.order?.affectedOrder?.clientDepoStorage
      depoStoragePlus = getRespTransferSecurity?.order?.affectedOrder?.partnerDepoStorage
    } else {
      licenseMinus = portfolios?.find((item) => item.name === portfolioMinus)
      licensePlus = portfolios?.find((item) => item.name === portfolioPlus)
      depoStorageMinus = licenseMinus?.depoStorages?.find((item) => item.name === storageMinus)
      depoStoragePlus = licensePlus?.depoStorages?.find((item) => item.name === storagePlus)
    }
    const obj = {
      clientPortfolio: licenseMinus,
      partnerPortfolio: licensePlus,
      clientDepoStorage: depoStorageMinus,
      partnerDepoStorage: depoStoragePlus,
    }
    return obj
  }

  const getSecurityValues = () => {
    if (groupOneVisibility) {
      return {
        id: input?.id || null,
        type: input?.type,
        registerNumber: input?.registerNumber,
        isin: input?.isin,
        name: groupData[0].searchStr, // TODO set from group
      }
    }
    return groupData[0]?.issuer ?? initialTransferGroupData.issuer //issuer // TODO set from group
  }

  useEffect(() => {
    clearFields()

    if (transferId === 'new') {
      setMetadataUid(transferType)
      setActiveId(null)
      return
    }
    if (!transferId) return;

    setActiveId(transferId)

    scrollToTop()
  }, [transferId])

  useEffect(() => {
    if (transferId === 'new') {
      fetchMetadataStart(transferType)
      return
    }
    if (!transferId) return;

    fetchMetadataStart(metadataUid)
    fetchTransferSecurityStart(transferId)
  }, [transferId])

  useEffect(() => {
    if (getRespTransferSecurity) {
      setCurrentStatus(getRespTransferSecurity?.order?.status)
      const resObj = getRespTransferSecurity?.order?.affectedOrder || getRespTransferSecurity?.order
      fillFields(resObj, getRespMetadata?.references?.portfolios)
      if (getRespTransferSecurity?.order?.type?.uid === transferType) {
        if (getRespMetadata?.order?.type?.uid === cancelType) {
          const cancelDate = getRespMetadata?.order?.expirationDate
          const reqObj = {
            type: { uid: cancelType },
            expirationDate: cancelDate && formatDateServer(new Date(cancelDate)),
            affectedOrder: { id: +activeId },
            secret: '',
          }

          const date = getRespMetadata?.order?.date
          const number = getRespMetadata?.order?.number

          // @ts-ignore
          reqObj.date = date
          // @ts-ignore
          reqObj.number = number

          const WordArrayToBase64 = JsonToSHA256(reqObj)
          if (WordArrayToBase64) {
            reqObj.secret = WordArrayToBase64
            setHashSha256(WordArrayToBase64)
          }

          fetchPerformTransferSecurityStart(reqObj, null)
        }
      }
      if (getRespTransferSecurity?.order?.type?.uid === cancelType) {
        // eslint-disable-next-line no-underscore-dangle
        if (getRespTransferSecurity?.order?._links[0]?.canModify) {
          setCanModifyDate(true)
        }
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(getRespTransferSecurity?.order?._links[0])
      setTransferHeader(`${getRespTransferSecurity?.order?.description}`)
    }
  }, [getRespTransferSecurity, getRespMetadata, isCleared])

  useEffect(() => {
    if (isCleared) {
      if (getSaveTransferSecurity?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveTransferSecurity?.order?.id)
        }
        if (getSaveTransferSecurity?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
        if (getSaveTransferSecurity?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
      }
      if (getPerformTransferSecurity?.requiredAction) {
        if (!activeId || +activeId !== getPerformTransferSecurity?.order?.id) {
          setActiveId(getPerformTransferSecurity?.order?.id)
        }
        if (getPerformTransferSecurity?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
        if (getPerformTransferSecurity?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
        setCurrentStatus(getPerformTransferSecurity?.order?.status)
      }
      const msgData = getSaveTransferSecurity || getPerformTransferSecurity

      if (msgData?.order?.description) {
        setTransferHeader(`${msgData?.order?.description}`)
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(msgData?.order?._links[0])

      if (getSaveTransferSecurity?.status === 303 || getPerformTransferSecurity?.status === 303) {
        setDisabled(true)
      }
      if (statusRequestSave === 200 || statusRequestPerform === 200) {
        switch (getSaveTransferSecurity?.order?.status?.uid || getPerformTransferSecurity?.order?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (getSaveTransferSecurity?.order?.id) {
              history.replace(`/transfers/securities/transfer/${getSaveTransferSecurity?.order?.id}`)

              setDisabled(false)
              setFormDisabled(false)
            }
            if (activeId) {
              setUpdModalOpen(true)
            } else {
              if (submitAction !== 'save') {
                setSaveModalOpen(true)
              }
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }
  }, [getSaveTransferSecurity, getPerformTransferSecurity, isCleared])

  useEffect(() => {
    if (serverError) {
      if (serverError?.ClientPortfolio?.length) {
        setClientPortfolioError(true)
        setClientPortfolioTextError(serverError?.ClientPortfolio[0])
      }
      if (serverError?.ClientDepoStorage?.length) {
        setClientDepoStorageError(true)
        setClientDepoStorageTextError(serverError?.ClientDepoStorage[0])
      }
      if (serverError?.PartnerDepoStorage?.length) {
        setPartnerDepoStorageError(true)
        setPartnerDepoStorageTextError(serverError?.PartnerDepoStorage[0])
      }
      if (serverError?.PartnerPortfolio?.length) {
        setPartnerPortfolioError(true)
        setPartnerPortfolioTextError(serverError?.PartnerPortfolio[0])
      }
      if (serverError?.Secret?.length) {
        setCodeError(true)
        setCodeTextError(serverError?.Secret[0])
      }
      const msgData = getSaveTransferSecurity || getPerformTransferSecurity
      if ((!currentActions || currentActions?.canModify) && !msgData?.requiredAction?.type) {
        setDisabled(false)
      }
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
    }
  }, [serverError])

  useEffect(
    () => {
      clearFields()
      return () => {
        dispatch(clearError())
        clearFields()
      }
    },
    []
  )

  useEffect(() => {
    if (getBalances) {
      const mainFields = listMainFields()
      if (!getBalances?.portfolio) return

      const hasClientPortfolioId = getBalances?.portfolio?.id === mainFields?.clientPortfolio?.id
      const hasPartnerPortfolioId = getBalances?.portfolio?.id === mainFields?.partnerPortfolio?.id
      const hasStorageUid = getBalances?.storage?.id

      if (
        hasClientPortfolioId &&
        (hasStorageUid
          ? mainFields?.clientDepoStorage?.id === getBalances?.storage?.id
          : !mainFields?.clientDepoStorage?.id)
      ) {
        console.log(123, getBalances)
        setClientBalances(getBalances)
      }

      if (
        hasPartnerPortfolioId &&
        (hasStorageUid
          ? mainFields?.partnerDepoStorage?.id === getBalances?.storage?.id
          : !mainFields?.partnerDepoStorage?.id)
      ) {
        setPartnerBalances(getBalances)
      }
    }
  }, [getBalances])

  const fetchBalancesDepo = (filterBody, setBalances) => {
    if (filterBody) {
      fetchGetBalancesDepoStart(filterBody.replaceAll('+', ''))
    }
    setBalances(null)
  }

  useMemo(() => {
    const objMainFields = listMainFields()
    const security = getSecurityValues()
    let filterBodyClient
    if (portfolioMinus || storageMinus || security) {
      filterBodyClient = createUri({
        name: 'balancesDepo',
        portfolioId: objMainFields?.clientPortfolio?.id,
        securityId: security?.id,
        storageId: objMainFields?.clientDepoStorage?.id,
        condition: '&',
      })
      fetchBalancesDepo(filterBodyClient, setClientBalances)
    }
    setClientBalances(null)
  }, [portfolioMinus, storageMinus, groupData[0].issuer?.id])

  useEffect(() => {
    const objMainFields = listMainFields()
    const security = getSecurityValues()
    let filterBodyPartner
    if (portfolioPlus || storagePlus || security) {
      filterBodyPartner = createUri({
        name: 'balancesDepo',
        portfolioId: objMainFields?.partnerPortfolio?.id,
        securityId: security?.id,
        storageId: objMainFields?.partnerDepoStorage?.id,
        condition: '&',
      })
      fetchBalancesDepo(filterBodyPartner, setPartnerBalances)
    }
    setPartnerBalances(null)
  }, [portfolioPlus, storagePlus, groupData[0].issuer?.id])

  const handleChangePortfolioMinus = (option) => {
    setPortfolioMinus(option?.value)
    setStorageMinus('')
    const currentObj = getRespMetadata?.references?.portfolios?.find(
      (elm) => elm.name === option?.value
    )
    if (currentObj?.depoStorages?.length) {
      setClientDepoStorage(currentObj.depoStorages)
    } else {
      setClientDepoStorage([])
    }
    setClientPortfolioError(false)
  }

  const handleChangePortfolioPlus = (option) => {
    setPortfolioPlus(option?.value)
    setStoragePlus('')
    const currentObj = getRespMetadata?.references?.portfolios?.find(
      (elm) => elm.name === option?.value
    )
    if (currentObj?.depoStorages?.length) {
      setPartnerDepoStorage(currentObj.depoStorages)
    } else {
      setPartnerDepoStorage([])
    }
    setSelectError(false)
    setPartnerPortfolioError(false)
    setPartnerPortfolioTextError('')
  }

  const handleChangeStorageMinus = (option) => {
    setStorageMinus(option?.value)
    setClientDepoStorageError(false)
    setClientDepoStorageTextError('')
  }

  const handleChangeStoragePlus = (option) => {
    setStoragePlus(option?.value)
    setSelectError(false)
    setPartnerDepoStorageError(false)
    setPartnerDepoStorageTextError('')
  }

  const handleChangeValidity = (date, formIndex) => {
    changeGroupDataByKeyHandler({
      object: {
        validity: date,
        expirationDateError: false,
        expirationDateTextError: '',
      },
      groupIndex: formIndex,
    })
  }

  const getHandler = (handler) => (!disabled ? handler : null)

  const handleChangeAutocompleteInput = (event, formIndex) => {
    const value = event?.target?.value

    if (typeof value === 'string') {
      changeGroupDataByKeyHandler({ key: 'searchStr', value, groupIndex: formIndex })

      if (value && value.trim().length >= 3) {
        fetchSecuritiesStart(`Count=100&Search=${value}`)
      }
    }

    changeGroupDataByKeyHandler({
      object: {
        setSecurityNameTextError: '',
        setSecurityNameError: false,
      },
      groupIndex: formIndex,
    })
  }

  const handleChangeAutocomplete = (event, value, formIndex) => {
    if (!value) {
      return
    }
    if (value?.name) {
      changeGroupDataByKeyHandler({ key: 'searchStr', value: value.name, groupIndex: formIndex })
    }

    setGroupOneVisibility(false)

    changeGroupDataByKeyHandler({
      groupIndex: formIndex,
      object: {
        issuer: value,
        groupTwoVisibility: true,
      },
    })

    const uri = 'Count=100'
    fetchSecuritiesStart(uri)
  }

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const addNeedsProps: any = (typeUID, msgData) => {
    const mainFields = listMainFields()
    const security = getSecurityValues()
    let reqObj = {}
    if (typeUID === cancelType) {
      reqObj = {
        type: { uid: typeUID },
        expirationDate: formatDateServer(new Date(groupData[0].validity)), //formatDateServer(new Date(validity)), TODO add from array
        affectedOrder: {
          id:
            msgData?.order?.affectedOrder?.id || getRespTransferSecurity?.order?.affectedOrder?.id,
        },
        secret: ''
      }
      return reqObj
    }
    reqObj = {
      type: { uid: typeUID },
      dateTime: formatDateServer(new Date()),
      expirationDate: formatDateServer(new Date(groupData[0].validity)), //formatDateServer(new Date(validity)), //TODO add from array
      clientDepoStorage: {
        id: mainFields?.clientDepoStorage?.id,
        account: mainFields?.clientDepoStorage?.account,
        name: mainFields?.clientDepoStorage?.name,
        isClosed: mainFields?.clientDepoStorage?.isClosed,
      },
      clientPortfolio: {
        id: mainFields?.clientPortfolio?.id,
      },
      partnerDepoStorage: {
        id: mainFields?.partnerDepoStorage?.id,
        account: mainFields?.partnerDepoStorage?.account,
        name: mainFields?.partnerDepoStorage?.name,
        isClosed: mainFields?.clientDepoStorage?.isClosed,
      },
      partnerPortfolio: {
        id: mainFields?.partnerPortfolio?.id,
      },
      security,
      volume: groupData[0].amount, //amount.value, //TODO add from array
      secret: '',
    }
    return reqObj
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const msgData = getSaveTransferSecurity || getPerformTransferSecurity
    const typeUID =
      msgData?.requiredAction && msgData?.order?.type?.uid === cancelType
        ? cancelType
        : transferType
    const requestObj = addNeedsProps(typeUID, msgData)

    const date = getRespMetadata?.order?.date
    const number = getRespMetadata?.order?.number

    requestObj.date = date
    requestObj.number = number

    const WordArrayToBase64 = JsonToSHA256(requestObj)
    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHashSha256(WordArrayToBase64)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID,
      putPerformData: fetchPerformTransferSecurityStart,
      postPerformData: fetchPerformTransferSecurityStart,
      activeId,
      secretPassPhrase,
    })
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
      setFormDisabled(true)

      if (submitAction === 'perform') {
        setDisabled(true)
      }
      const msgData = getSaveTransferSecurity || getPerformTransferSecurity
      const typeUID =
        (msgData?.requiredAction && msgData?.order?.type?.uid === cancelType) ||
        getRespTransferSecurity?.order?.type?.uid === cancelType
          ? cancelType
          : transferType
      const requestObj = addNeedsProps(typeUID, msgData)

      const date = getRespMetadata?.order?.date
      const number = getRespMetadata?.order?.number

      requestObj.date = date
      requestObj.number = number

      const WordArrayToBase64 = JsonToSHA256(requestObj)
      if (WordArrayToBase64) {
        requestObj.secret = WordArrayToBase64
        setHashSha256(WordArrayToBase64)
      }

      formAction({
        submitAction,
        requestObj,
        typeUID,
        msgData,
        putPerformData: fetchPerformTransferSecurityStart,
        postPerformData: fetchPerformTransferSecurityStart,
        putSaveData: fetchSaveTransferSecurityStart,
        postSaveData: fetchSaveTransferSecurityStart,
        activeId,
        code,
        setCodeError,
        setCodeTextError,
        setCancellationModalOpen,
        setFormDisabled,
        currentThumbPrint,
        secretPassPhrase,
        disableValidation
      })
      setDisableValidation(false)
  }

  const handleDownloadXml = () => {
    const msgData = getSaveTransferSecurity || getPerformTransferSecurity
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.order?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleCloseSaveModal = (goToHistory) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseUpdModal = () => {
    setUpdModalOpen(false)
    handleBackToHistory()
  }

  const handleClosePerformModal = (goToHistory) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
  }

  const handleCancelOk = () => {
    setMetadataUid(cancelType)
    fetchMetadataStart(cancelType)
    handleCloseCancellationModal()
  }

  const handleCancelOperation = (event) => {
    event.preventDefault()
    setDisabled(false)
    dispatch(clearPartTransferSecurity())
    setFormDisabled(false)
  }

  const handleActionReject = (modalType) => {
    if (modalType === 'saveModal') {
      handleCloseSaveModal(false)
    }
    if (modalType === 'performModal') {
      handleClosePerformModal(true)
    }
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)

    clearFields()
    setActiveId('')

    setMetadataUid(transferType)
    fetchMetadataStart(transferType)

    setGroupData([{ ...initialTransferGroupData, amount: ''}])

    setTransferHeader('')
    setFormDisabled(false)
  }

  const removeForm = (index: number) => {
    if (groupData.length < 2) return

    setGroupData(groupData.filter((_, formIndex) => formIndex !== index))
  }

  const onChangeAmountHandler = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    formIndex: number
  ) => {
    if (!checkInputRegExp.test(value.toLowerCase())) return

    changeGroupDataByKeyHandler({
      object: {
        amount: value,
        setVolumeTextError: '',
        setVolumeError: false,
      },
      groupIndex: formIndex,
    })
  }

  const isEmptyFields = !(!!portfolioMinus && !!storageMinus && !!portfolioPlus && !!storagePlus)

  const isConfirmModalShow = () =>
    getSaveTransferSecurity?.requiredAction?.type === 'code' ||
    getPerformTransferSecurity?.requiredAction?.type === 'code' ||
    getSaveTransferSecurity?.requiredAction?.type === 'mac' ||
    getPerformTransferSecurity?.requiredAction?.type === 'mac'

  useEffect(() => {
    if (!isConfirmModalShow()) {
      setConfirmModalStatus(false)
    }

    if (isConfirmModalShow()  && showModalConfirm) {
      setConfirmModalStatus(true)
      setDisableValidation(true)
    }
  }, [getPerformTransferSecurity, getPerformTransferSecurity, showModalConfirm])

  const isStatusDraft = currentStatus?.uid === 'Draft'

  useEffect(() => {
    if (getRespSecurities?.queryParams) {
      setGroupData((prev) =>
        prev.map((currentData) => {
          if (currentData.searchStr === getRespSecurities.queryParams.search) {
            return {
              ...currentData,
              securitiesList: getRespSecurities?.securities,
            }
          }

          return currentData
        })
      )
    }
  }, [getRespSecurities])

  const copyTransfer = () => {
    if (copyData !== null) {
      fillFields(copyData.order, copyData.portfolios)
    }

    setTransferHeader('')
    setFormDisabled(false)
    setDisabled(false)
    setCanModifyDate(true)

    setCopyStatus(false)
    setCopyData(null)
    setCopyTransferDate(null)
  }

  const preCopy = () => {
    const expirationDate = getRespMetadata?.order?.expirationDate;
    const isActualDate = expirationDate < todayDate ? todayDate : expirationDate
    const order = getRespTransferSecurity?.order
    setCopyData({
      order: {
        ...order,
        _links: [],
      },
      portfolios: getRespMetadata?.references?.portfolios,
      validity: isActualDate,
      issuer: getRespTransferSecurity?.order?.security
    })
    setCopyTransferDate(isActualDate)
    history.replace('/transfers/securities/transfer/new')

    clearFields();

    setMetadataUid(transferType)
    fetchMetadataStart(transferType)

    setActiveId(null)
    setCopyStatus(true)
  }

  useEffect(() => {
    if (getRespMetadata && copyStatus) {
      copyTransfer();
    }
  }, [copyStatus, getRespMetadata])

  const handleCloseSmsModal = () => {
    setShowModalConfirm(false)
    setConfirmModalStatus(false)

    setCodeError(false)
    dispatch(clearError())
    setCodeTextError('')
    setCode('')
  }

  return (
    <>
      <TransferHeading
        transferHeader={transferHeader}
        isRemoval={isStatusDraft}
        deletedOrderCall={onDeleteHandler}
      />

      <DeleteModals />

      <Form onSubmit={handleSubmitForm} gap={40}>
        <FormRow gap={40}>
          <FormCol gap={40} flex={1}>
            <FormRow gap={40}>
              <Field
                label="Портфель списания"
                hasError={clientPortfolioError}
                caption={clientPortfolioError ? clientPortfolioTextError : ''}
              >
                <Select
                  options={portfolios?.map((option) => ({
                    label: option.name,
                    value: option.name,
                  }))}
                  value={portfolioMinus || ''}
                  placeholder="Введите номер или выберите из списка"
                  isDisabled={disabled}
                  onChange={getHandler(handleChangePortfolioMinus)}
                />
              </Field>
              <Field
                label="Место хранения списания"
                hasError={clientDepoStorageError}
                caption={clientDepoStorageTextError}
              >
                <Select
                  options={
                    clientDepoStorage &&
                    clientDepoStorage?.map((item) => ({ label: item?.name, value: item?.name }))
                  }
                  value={storageMinus || ''}
                  onChange={getHandler(handleChangeStorageMinus)}
                  placeholder="Введите текст или выберите из списка"
                  isDisabled={disabled}
                />
              </Field>
            </FormRow>
            <FormRow gap={40}>
              <Field
                label="Портфель зачисления"
                hasError={selectError || partnerPortfolioError}
                caption={partnerPortfolioTextError}
              >
                <Select
                  options={portfolios?.map((option) => ({
                    label: option.name,
                    value: option.name,
                  }))}
                  value={portfolioPlus || ''}
                  placeholder="Введите номер или выберите из списка"
                  isDisabled={disabled}
                  onChange={getHandler(handleChangePortfolioPlus)}
                />
              </Field>
              <Field
                label="Место хранения зачисления"
                hasError={selectError || partnerDepoStorageError}
                caption={partnerDepoStorageTextError}
              >
                <Select
                  options={partnerDepoStorage?.map((item) => ({
                    label: item?.name,
                    value: item?.name,
                  }))}
                  value={storagePlus || ''}
                  onChange={getHandler(handleChangeStoragePlus)}
                  isDisabled={!portfolioPlus || disabled}
                  placeholder="Введите текст или выберите из списка"
                />
              </Field>
            </FormRow>
          </FormCol>
          <StyledFormRightCol gap={40}>
            <FormRow>
              <StyledPriceChangeCardWrapper>
                <PriceChangeCard
                  plain
                  label={`Доступно на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
                  value={`${
                    (clientBalances &&
                      clientBalances.balanceT0 >= 0 &&
                      `${clientBalances.balanceT0.toLocaleString('ru')}`) ||
                    '0'
                  } ШТ`}
                />
              </StyledPriceChangeCardWrapper>
            </FormRow>
            <FormRow>
              <StyledPriceChangeCardWrapper>
                <PriceChangeCard
                  plain
                  label={`Баланс на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
                  value={`${
                    (partnerBalances &&
                      partnerBalances.balanceT0 >= 0 &&
                      `${partnerBalances.balanceT0.toLocaleString('ru')}`) ||
                    '0'
                  } ШТ`}
                />
              </StyledPriceChangeCardWrapper>
            </FormRow>
          </StyledFormRightCol>
        </FormRow>
        <FormCol gap={48}>
          {groupData.map(
            (
              {
                searchStr,
                securityNameError,
                securityNameTextError,
                validity,
                volumeError,
                volumeTextError,
                expirationDateError,
                expirationDateTextError,
                amount,
                securitiesList,
                issuer,
                groupTwoVisibility,
              },
              formIndex
            ) => (
              <FormRow key={formIndex}>
                <FormCol flex={1}>
                  <FormCol gap={20}>
                    <FormRow justifyContent="space-between">
                      <BlockHeading
                        border={formIndex === 0}
                        title={`Ценная бумага`}
                        caption={
                          currentStatusDescription ? (
                            <div dangerouslySetInnerHTML={createMarkup()} />
                          ) : (
                            ''
                          )
                        }
                      />

                      {groupData.length > 1 && (
                        <Button variant="header" onClick={() => removeForm(formIndex)}>
                          <Icon color={theme.color.graphics.secondary} name="trash" />
                        </Button>
                      )}
                    </FormRow>

                    <FormRow>
                      <FormCol flex={3}>
                        <Field
                          label="Эмитент (для поиска укажите наименование или ISIN или № гос. рег.)"
                          hasError={securityNameError}
                          caption={securityNameTextError}
                        >
                          <CustomAutocomplete
                            placeholder="Введите текст"
                            variant="outlined"
                            name="issuer"
                            color="secondary"
                            size="small"
                            inputValue={searchStr || ''}
                            onInputChange={(e) => handleChangeAutocompleteInput(e, formIndex)}
                            onChange={(e, value) => handleChangeAutocomplete(e, value, formIndex)}
                            searchList={securitiesList}
                            disabled={disabled}
                            unique
                          />
                        </Field>
                      </FormCol>
                      <FormCol flex={1}>
                        <Field label="Количество" hasError={volumeError} caption={volumeTextError}>
                          <TextField
                            placeholder="Введите количество"
                            value={amount}
                            divideValueWithSpace
                            onChange={(e) => onChangeAmountHandler(e, formIndex)}
                            disabled={disabled}
                            maxLength={18}
                            name="amount"
                            type="text"
                            inputMode="numeric"
                          />
                        </Field>
                      </FormCol>
                      <FormCol flex={1}>
                        <Field
                          label="Срок действия до"
                          hasError={expirationDateError}
                          caption={expirationDateError ? expirationDateTextError : ''}
                        >
                          <DateField
                            placeholder=""
                            value={validity}
                            onChange={(e) => handleChangeValidity(e, formIndex)}
                            minDate={todayDate}
                            maxDate={disabled && !canModifyDate ? validity : undefined}
                            disabled={disabled}
                          />
                        </Field>
                      </FormCol>
                    </FormRow>
                  </FormCol>
                  {groupTwoVisibility && (
                    <FormRow>
                      <FormCol flex={1}>
                        <Field label="Вид">
                          <TextField disabled value={issuer?.type?.name} />
                        </Field>
                      </FormCol>
                      <FormCol flex={1}>
                        <Field label="Номер гос. регистрации">
                          <TextField value={issuer?.registerNumber} disabled />
                        </Field>
                      </FormCol>
                      <FormCol flex={1}>
                        <Field label="ISIN">
                          <TextField value={issuer?.isin} disabled />
                        </Field>
                      </FormCol>
                      <FormCol flex={1}>
                        <Field label="Номинал">
                          <TextField
                            value={`${issuer?.faceValue ?? ''} ${
                              issuer?.faceValueCurrency?.uid ?? ''
                            }`}
                            disabled
                          />
                        </Field>
                      </FormCol>
                    </FormRow>
                  )}
                </FormCol>
              </FormRow>
            )
          )}
        </FormCol>

        <FormFooter>
          <FormFooterActions>
            {!disabled && !getRespTransferSecurity?.order && !isEmptyFields ? (
              <Button variant="tertiary" onClick={() => clearFields(false)} type={'button'}>
                Очистить форму
              </Button>
            ) : null}

            {order && (
              <BlockHeading
                title={`Статус: ${currentStatus?.name}`}
                caption={
                  currentStatusDescription ? <div dangerouslySetInnerHTML={createMarkup()} /> : ''
                }
              />
            )}
          </FormFooterActions>
          <FormFooterActions>
            {(((currentActions && currentActions?.canModify) ||
              (!currentActions && !isEmptyFields)) &&
              !getSaveTransferSecurity?.requiredAction?.type &&
              !getPerformTransferSecurity?.requiredAction?.type && (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setSubmitAction('perform')
                      setShowModalConfirm(true)
                    }}
                    disabled={formDisabled}
                    type={'submit'}
                  >
                    Исполнить
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={getRespTransferSecurity?.order?.status?.uid === 'Draft' ? preCopy : () => {
                      setSubmitAction('save')
                    }}
                    disabled={formDisabled}
                    type={'submit'}
                  >
                    {
                      (getRespTransferSecurity?.order?.status?.uid === 'Draft')
                        ? 'Скопировать'
                        : 'Сохранить в черновики'
                    }
                  </Button>
                  {
                    getRespTransferSecurity?.order?.status?.uid == 'Draft' && (
                      <Button
                        variant='secondary'
                        type='submit'
                        onClick={() => { setSubmitAction('save') }}
                        disabled={formDisabled}
                      >
                        Сохранить
                      </Button>
                    )
                  }
                </>
              )) ||
              ((getSaveTransferSecurity?.requiredAction?.type === 'sign' ||
                getPerformTransferSecurity?.requiredAction?.type === 'sign') && (
                <>
                  <Button
                    variant="primary"
                    type={'submit'}
                    onClick={() => {
                      setSubmitAction('perform')
                      setShowModalConfirm(true)
                    }}
                  >
                    Подписать
                  </Button>
                  <Button variant="primary" type={'button'} onClick={handleDownloadXml}>
                    Скачать .xml
                  </Button>
                  <Button variant="secondary" type={'button'} onClick={handleCancelOperation}>
                    Отменить
                  </Button>
                </>
              )) ||
              ((getSaveTransferSecurity?.requiredAction?.type === 'code' ||
                getPerformTransferSecurity?.requiredAction?.type === 'code' ||
                getSaveTransferSecurity?.requiredAction?.type === 'mac' ||
                getPerformTransferSecurity?.requiredAction?.type === 'mac') && (
                <>
                  <Button
                    variant="primary"
                    type={'submit'}
                    onClick={() => {
                      setSubmitAction('perform')
                      setShowModalConfirm(true)
                    }}
                  >
                    Подтвердить
                  </Button>
                  <Button variant="secondary" type={'button'} onClick={handleCancelOperation}>
                    Отменить
                  </Button>
                </>
              )) ||
              (currentActions && currentActions?.canCancel && (
                <Button
                  variant="primary"
                  type={'button'}
                  onClick={() => {
                    setCancellationModalOpen(true)
                    setShowModalConfirm(true)
                  }}
                >
                  Отменить поручение
                </Button>
              ))}
            {
              (
                getRespTransferSecurity?.order?.status?.uid === 'Sent' ||
                getRespTransferSecurity?.order?.status?.uid === 'Signed' ||
                getRespTransferSecurity?.order?.status?.uid === 'Received' ||
                getRespTransferSecurity?.order?.status?.uid === 'Executed' ||
                getRespTransferSecurity?.order?.status?.uid === 'Cancelled' ||
                getRespTransferSecurity?.order?.status?.uid === 'Rejected' ||
                getRespTransferSecurity?.order?.status?.uid === 'Processing'
              ) &&
              getRespMetadata?.order?.type?.uid !== cancelType &&
              <Button
                variant='secondary'
                onClick={preCopy}
                type={'button'}
              >
                Скопировать
              </Button>
            }
            {currentStatus?.uid && (
              <Button
                variant='secondary'
                onClick={handleBackToHistory}
              >
                Назад
              </Button>
              )
            }
          </FormFooterActions>
        </FormFooter>
      </Form>

      <SMSConfirmModal
        isShowImmitPaste
        title={'Подтвердите создание поручения'}
        hashSha256={hashSha256}
        code={code}
        codeError={codeError}
        codeTextError={codeTextError}
        showTimer={showTimer}
        isOpen={ isConfirmModalShow() && showModalConfirm }
        handleResetTimer={handleResetTimer}
        handleStartTimer={handleStartTimer}
        setCodeError={setCodeError}
        setCodeTextError={setCodeTextError}
        handleClose={handleCloseSmsModal}
        handleChangeCodeInput={handleChangeCodeInput}
        handleSubmit={(e) => {
          setSubmitAction('perform')
          handleSubmitForm(e)
          setConfirmModalStatus(true)
        }}
      />

      <CustomModal
        open={saveModalOpen}
        title="Поручение успешно создано."
        handleClose={handleCloseSaveModal}
        actionText="Хотите создать новое?"
        textOk="Да, создать"
        actionReject={() => handleActionReject('saveModal')}
        actionSuccess={handleActionSuccess}
        disableOverlay
      />
      <CustomModal
        open={updModalOpen}
        title="Поручение обновлено"
        handleClose={handleCloseUpdModal}
        closeInterval={1800}
      />
      <CustomModal
        open={performModalOpen}
        title="Поручение успешно подписано"
        handleClose={handleClosePerformModal}
        actionText="Хотите создать новое?"
        textOk="Да, создать"
        actionReject={() => handleActionReject('performModal')}
        actionSuccess={handleActionSuccess}
        disableOverlay
      />
      <CustomModal
        open={cancellationModalOpen}
        title="Создать поручение на отмену"
        handleClose={handleCloseCancellationModal}
        textOk="Да, создать"
        callbacks={{
          withOk: handleCancelOk,
          withCancel: () => {
            handleCloseCancellationModal()
          },
        }}
      />
    </>
  )
}

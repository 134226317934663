import React, {
  useEffect, useMemo, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as DownloadIcon } from '../../../../assets/images/downloadNew.svg'
import { ReactComponent as EdsIcon } from '../../../../assets/images/edsNew.svg'
import useStyles from './panel.style'
import { rowsPerPage } from '../../../../config/constants'
import b64toBlob from '../../../../utils/ConvertToBlob'

import {
  fetchNeedSignStartAsync,
  fetchPostClientSignStartAsync,
  fetchPostReportRejectStartAsync,
  fetchReportStartAsync,
  getReportSuccess,
  postClientSignSuccess,
  postReportRejectSuccess,
} from '../../../../redux/reports/reportsSlice'
import {
  selectClientSign,
  selectClientSignStatus,
  selectReport,
  selectReportMarkread,
  selectReportRejectStatus,
} from '../../../../redux/reports/selector'
import { selectCurrentNotifications } from '../../../../redux/notifications/selector'
import { selectCurrentMetadata } from '../../../../redux/metadata/selector'
import { fetchMetadataStartAsync } from '../../../../redux/metadata/metadataSlice'
import { isRequired } from '../../../../utils/Validate'
import { selectCurrentError } from '../../../../redux/error/selector'
import { selectCurrentThumbprint } from '../../../../redux/user/selector'
import { StyledWrapper } from '../Transfers/components/Money/styled'
import {
  StyledBadge, StyledHistoryStatusCell,
  StyledTableWrapper,
} from '../Transfers/styled'
import { DataTable } from '../../molecules'
import { CustomModal, Pagination } from '../../organisms'
import { useTheme } from '../../../theme'
import { useSize } from '../../../../hooks/useSize'
import { Button, FormRow } from '../../atoms'
import { SMSConfirmModal } from '../Transfers/components/modals/SMSConfim'
import { setConfirmStatusModal } from '../../../../redux/ui/uiSlice'

const EpsTable = ({
  // eslint-disable-next-line react/prop-types
  page, setPageIner, reportsArrayData, brokerFilter,
}) => {
  const size = useSize()
  const theme = useTheme()
  const isDesktop = theme.bp.isMD(size.width)

  const classes = useStyles()
  const dispatch = useDispatch()
  const [reportId, setReportId] = useState(null)
  const [isEps, setIsEps] = useState(false)
  const [code, setCode] = useState('')
  const [lastReportItems, setLastReportItems] = useState([])
  const [rejectModalOpen, setRejectModalOpen] = useState(false)
  const [rejectReportID, setRejectReportID] = useState(null)
  const [codeError, setCodeError] = useState(false)
  const [codeErrorText, setCodeErrorText] = useState(null)

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [showTimer, setShowTimer] = useState(false)

  const reportDetail = useSelector(selectReport)
  const clientSign = useSelector(selectClientSign)
  const clientSignStatus = useSelector(selectClientSignStatus)
  const reportRejectStatus = useSelector(selectReportRejectStatus)
  const currentNotifications = useSelector(selectCurrentNotifications)
  const reportStatuses = useSelector(selectCurrentMetadata)?.references?.reportStatuses
  const reportMarkread = useSelector(selectReportMarkread)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)

  const isConfirmModalShow = clientSign?.actionNeeded === 'code' && confirmModalOpen

  const reportsArray = useMemo(() => reportsArrayData?.map(
    (item) => ({ ...item }),
  ), [reportsArrayData])

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchReportStart = (id) => {
    dispatch(fetchReportStartAsync({ id }))
  }

  const fetchPostClientSignStart = (obj, dataToSign, thumbprint) => {
    dispatch(fetchPostClientSignStartAsync(obj, dataToSign, thumbprint))
  }

  const fetchPostReportRejectStart = (obj) => {
    dispatch(fetchPostReportRejectStartAsync(obj))
  }

  const fetchNeedSignStart = () => {
    dispatch(fetchNeedSignStartAsync())
  }

  useEffect(() => {
    fetchMetadataStart('Reports.Type.Broker')
  }, [])

  useEffect(() => {
    dispatch(postClientSignSuccess(''))
    // eslint-disable-next-line
  }, [])

  useEffect(() => () => {
    dispatch(getReportSuccess(null))
    dispatch(postClientSignSuccess(null))
  }, [])

  useEffect(() => {
    if (serverError) {
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeErrorText(serverError?.errors?.Secret[0])
      }
    }
  }, [serverError])

  useEffect(() => {
    const lastItem = currentNotifications[currentNotifications.length - 1]
    if (reportsArray && !!lastItem?.detail?.statusUid) {
      const hasLastItemReport = reportsArray?.some((item) => item?.id === lastItem?.recordID)
      if (hasLastItemReport) {
        const lastReportItemsIndex = lastReportItems.findIndex((e) => e.recordID === lastItem?.recordID)
        if (lastReportItemsIndex >= 0) {
          const finalArray = lastReportItems.map((elem, index) => (index === lastReportItemsIndex ? lastItem : elem))
          setLastReportItems(finalArray)
          return
        }
        setLastReportItems((prevState) => [...prevState, lastItem])
      }
    }
    // eslint-disable-next-line
  }, [currentNotifications])

  useEffect(() => {
    if (reportMarkread?.reportsAffected?.length) {
      reportMarkread.reportsAffected.forEach((id) => {
        if (reportsArray?.length > 0) {
          reportsArray.forEach((item) => {
            if (item?.id === id) {
              item.isNew = false
            }
          })
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportMarkread])

  const updateNeedSignReports = () => {
    fetchNeedSignStart()
    dispatch(postClientSignSuccess(null))
    dispatch(postReportRejectSuccess(null))
    brokerFilter()
  }

  const setConfirmModalStatus = (status) => {
    dispatch(setConfirmStatusModal(status))
  }

  useEffect(() => {
    if (clientSign?.actionNeeded === 'sign') {
      fetchReportStart(reportId)
    }
    if (!clientSign?.actionNeeded && clientSignStatus === 200) {
      updateNeedSignReports()
    }
    // eslint-disable-next-line
  }, [clientSign, clientSignStatus])

  useEffect(() => {
    if (reportRejectStatus) {
      if (reportRejectStatus === 200) {
        updateNeedSignReports()
      }
    }
    // eslint-disable-next-line
  }, [reportRejectStatus])

  const downloadLink = useRef(null)

  useEffect(() => {
    if (reportDetail && !clientSign) {
      downloadLink.current.href = URL.createObjectURL(b64toBlob(isEps ? reportDetail.signData : reportDetail.data))
      downloadLink.current.download = isEps ? reportDetail.signFileName : reportDetail.fileName
      downloadLink.current.click()
      dispatch(getReportSuccess())
    } else if (clientSign?.actionNeeded === 'sign') {
      const reqObj = {
        id: reportId,
      }
      fetchPostClientSignStart(reqObj, reportDetail, currentThumbPrint)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportDetail])

  const handleDownloadFile = async (event, id, isEpsVal) => {
    event.stopPropagation()
    dispatch(postClientSignSuccess(''))
    if (isEpsVal) {
      setIsEps(true)
    } else {
      setIsEps(false)
    }
    await fetchReportStart(id)
  }

  const handleClickSign = async (reportID) => {
    setConfirmModalStatus(true)
    setConfirmModalOpen(true)
    setCode('')
    setReportId(reportID)
    const reqObj = {
      id: reportID,
    }
    fetchPostClientSignStart(reqObj)
  }

  const handleClickReject = async (reportID) => {
    setRejectModalOpen(true)
    setRejectReportID(reportID)
  }

  const handleChangeCodeInput = (event) => {
    setCode(event.target.value)
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const reqObj = {
      id: reportId,
    }
    fetchPostClientSignStart(reqObj)
  }

  const handleSubmitCode = () => {
    if (!code) {
      isRequired(code, setCodeError, setCodeErrorText)
      return
    }
    const reqObj = {
      id: reportId,
      secret: code,
    }
    fetchPostClientSignStart(reqObj)
  }

  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false)
    setCodeError(false)
    setCodeErrorText('')
    setCode('')
    setConfirmModalStatus(false)
  }

  const handleRejectOk = () => {
    const reqObj = {
      id: rejectReportID,
    }
    fetchPostReportRejectStart(reqObj)
    setRejectModalOpen(false)
  }

  const handleCloseRejectModal = () => {
    setRejectModalOpen(false)
    setRejectReportID(null)
  }

  const Table = ({
    // eslint-disable-next-line react/prop-types, no-unused-vars
    rows, onRowClick,
  }) => {
    const columnsLayout = [
      '14%',
      '8%',
      '20%',
      '14%',
      '8%',
      '8%',
      '28%',
    ]
    const getFormatDate = (date, formatDate) => format(new Date(date), formatDate)
    const columns = [
      {
        id: 'date',
        title: 'Дата',
        width: '14.8%',
        render: (row) => getFormatDate(row?.dateTime, "dd.MM.yyyy'T'HH:mm").replace('T', ' '),
      },
      {
        id: 'check',
        title: 'Счёт',
        width: '11.1%',
        render: (row) => row?.account?.number,
      },
      {
        id: 'type',
        title: 'Тип',
        render: (row) => row?.typeName,
      },
      {
        id: 'period',
        title: 'Период',
        render: (row) => `${format(new Date(row?.dateFrom), 'dd.MM.yyyy')} - ${format(new Date(row?.dateTo), 'dd.MM.yyyy')}`,
      },
      {
        id: 'eds',
        title: 'ЭЦП',
        render: (row) => {
          const edsButton = (
            <Button
              className={classes.tableBtn}
              disableRipple
              onClick={(event) => handleDownloadFile(event, row?.id, true)}
            >
              <EdsIcon style={{ marginRight: 0 }} />
            </Button>
          )

          if (row?.isSigned) {
            return edsButton
          }

          return null
        },
      },
      {
        id: 'format',
        title: 'Формат',
        render: (row) => {
          const lastReportItem = lastReportItems.find((e) => e.recordID === row?.id)

          return (
            <TableCell style={{ padding: 0, borderBottom: 'none' }}>
              {(row?.dataFileID || lastReportItem?.detail?.statusUid?.new === 'executed') && (
                <Button
                  className={classes.tableBtn}
                  disableRipple
                  onClick={(event) => handleDownloadFile(event, row?.id, false)}
                >
                  <DownloadIcon />
                  <Typography
                    tag="span"
                    color="secondary"
                  >
                    {row?.format?.fileExtension?.substring(1, row?.format?.fileExtension?.length)}
                  </Typography>
                </Button>
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'status',
        title: 'Статус',
        render: (row) => {
          const lastReportItem = lastReportItems.find((e) => e.recordID === row?.id)

          const getNotificationReportStatus = lastReportItem?.detail
            ?.statusUid?.new?.toLowerCase()

          const statusName = reportStatuses?.find((statusItem) => (
            statusItem?.uid?.toLowerCase() === (getNotificationReportStatus || row?.status.toLowerCase())))?.name
          return (

            <>
              {(row?.needClientSign) ? (
                <FormRow>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={() => handleClickSign(row?.id)}
                  >
                    Подписать
                  </Button>
                  <Button
                    type="submit"
                    variant="secondary"
                    onClick={() => handleClickReject(row?.id)}
                  >
                    Отклонить
                  </Button>
                </FormRow>
              ) : (
                <StyledHistoryStatusCell>
                  <StyledBadge
                    variant={
                    // eslint-disable-next-line no-nested-ternary
                    statusName === 'Исполнено' ? 'success'
                      : (statusName === 'Отказ в исполнении' || statusName === 'Отклонено клиентом') ? 'danger'
                        : 'default'
                  }
                  >
                    {statusName}
                  </StyledBadge>
                </StyledHistoryStatusCell>
              )}
            </>
          )
        },
      },

    ].filter(Boolean)
    return (
      <DataTable
        columns={columns}
        rows={rows}
        layout={columnsLayout}
        onRowClick={onRowClick}
      />
    )
  }

  return (
    <>
      <StyledWrapper>
        <StyledTableWrapper>
          <Table
            rows={isDesktop
              ? reportsArray?.slice(
                ((page) * (rowsPerPage)), ((page + 1) * (rowsPerPage)),
              )
              : reportsArray}
            handleDownload={() => { }}

          />
        </StyledTableWrapper>
        {(!!reportsArray?.length && isDesktop) && (
        <Pagination
          total={reportsArray?.length}
          current={page + 1}
          // eslint-disable-next-line no-shadow
          onChange={(page) => setPageIner(page - 1)}
          pageSize={rowsPerPage}
        />
        )}
      </StyledWrapper>

      <CustomModal
        open={rejectModalOpen}
        title="Вы действительно желаете отклонить подписание данного документа?"
        handleClose={handleCloseRejectModal}
        callbacks={{
          withOk: handleRejectOk,
          withCancel: () => {
            handleCloseRejectModal()
          },
        }}
      />
      <SMSConfirmModal
        // isShowImmitPaste
        title="Подписать отчёт"
        handleResetTimer={handleResetTimer}
        handleStartTimer={handleStartTimer}
        // hashSha256={hashSha256}
        showTimer={showTimer}
        code={code}
        codeError={codeError}
        setCodeError={setCodeError}
        setCodeTextError={setCodeErrorText}
        codeTextError={codeErrorText}
        isOpen={isConfirmModalShow}
        handleClose={handleCloseConfirmModal}
        handleChangeCodeInput={handleChangeCodeInput}
        handleSubmit={handleSubmitCode}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={classes.downloadLink}
        href=""
        ref={downloadLink}
      >
        download
      </a>
    </>
  )
}

EpsTable.defaultProps = {
  reportsArrayData: PropTypes.array,
  brokerFilter: () => {},
}

EpsTable.propTypes = {
  page: PropTypes.number.isRequired,
  reportsArrayData: PropTypes.arrayOf(PropTypes.object),
  brokerFilter: PropTypes.func,
}

export default EpsTable

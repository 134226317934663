import { useSizeContext } from 'context/SizeContext'
import { theme } from 'ui/theme'

const useMedia = () => {
  const size = useSizeContext()

  const isMobile = theme.bp.isXXS(size.width)
  const isTablet = theme.bp.isXS(size.width)

  const isDesktop = !isMobile && !isTablet

  return {
    isMobile,
    isTablet,
    isDesktop,
  }
}

export default useMedia

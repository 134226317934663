import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Grid, Modal } from '@material-ui/core'
import useStyles from './MobileFilter.style'

const MobileFilter = ({
  children, handleAcceptFilter, handleResetFilter, constItems,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [filterCount, setFilterCount] = useState(0)

  const handleOpenFilter = () => {
    setOpen(true)
  }

  useEffect(() => {
    let count = 0
    _.map(constItems, (item) => {
      if (item) {
        count += 1
      }
      if (item === false) {
        count -= 1
      }
    })
    if (count < 0) count = 0
    setFilterCount(count)
  }, [constItems])

  return (
    <div
      className={classes.MobileFilter}
    >
      <div className={classes.MobileFilter__btnWrapper}>
        <button
          className={classes.MobileFilter__button}
          onClick={handleOpenFilter}
          type="button"
        >
          Фильтры
          {(filterCount && (
            <span
              className={classes.MobileFilter__buttonCount}
            >
              {filterCount}
            </span>
          )) || null}
        </button>
      </div>
      <Modal
        open
        className={
          classnames(classes.MobileFilter__modal, { [classes.MobileFilter__modal_active]: open })
        }
        onBackdropClick={() => setOpen(false)}
        disableEnforceFocus
      >
        <div className={classes.MobileFilter__modalContent}>
          <div className={classes.MobileFilter__header}>
            Выбрать фильтр
          </div>
          {children}
          <Grid
            item
            className={classes.MobileFilter__groupBtn}
          >
            <button
              onClick={() => {
                setOpen(false)
                handleAcceptFilter()
              }}
              className={classnames(
                classes.MobileFilter__button,
                classes.MobileFilter__button_primary,
              )}
              type="button"
            >
              Применить
            </button>
            <button
              onClick={() => {
                handleResetFilter()
                setFilterCount(0)
              }}
              className={classes.MobileFilter__button}
              type="button"
            >
              Очистить
            </button>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}

MobileFilter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  handleAcceptFilter: PropTypes.func.isRequired,
  handleResetFilter: PropTypes.func.isRequired,
  constItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
}

export default MobileFilter

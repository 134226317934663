import { isValid, parse } from 'date-fns'
import { ru } from 'date-fns/locale'

export const getDateFromString = (value?: string | Date) => {
  if (!value || value instanceof Date) return value

  const [day, month, year] = value.split('.')

  return new Date(+year, +month - 1, +day)
}

export const isDateValid = (date?: string | Date, max?: string | Date, min?: string | Date) => {
  if (!date) return false

  if (!(date instanceof Date)) {
    const [day, month, year] = date.split('.')

    if (+day > 31 || +month > 12 || +month < 1 || +year < 999) return false
  }

  if (max && getDateFromString(date) >= getDateFromString(max)) return false
  if (min && getDateFromString(date) <= getDateFromString(min)) return false

  if (date instanceof Date) return true

  const parsedDate = parse(date, 'P', new Date(), { locale: ru })

  return isValid(parsedDate)
}

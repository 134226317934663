import React from 'react'
import { Checkbox, FormControl, makeStyles } from '@material-ui/core'
import { ReactComponent as CheckedIcon } from '../../assets/images/checked-icon.svg'
import { ReactComponent as CheckboxIcon } from '../../assets/images/checkbox-icon.svg'
import FormLabel from '@material-ui/core/FormLabel'
import PropTypes from 'prop-types'
import cn from 'classnames'

const useStyles = makeStyles({
  checkboxField: {
    color: '#363e47',
    '& .MuiIconButton-label .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
      fill: '#363e47',
    },
  },
  checkboxFieldDocument: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    '& > label': {
    },
  },
})

const CustomCheckbox = ({ label, otherData, isDocument, ...props }) => {
  const classes = useStyles()

  return (
    <FormControl data-field-name={otherData}>
      <div className={cn(isDocument && classes.checkboxFieldDocument)}>
        {label && (
          <FormLabel>
            {label}
          </FormLabel>
        )}
        <Checkbox
          checkedIcon={<CheckedIcon />}
          icon={<CheckboxIcon />}
          className={classes.checkboxField}
          {...props}
        />
      </div>
    </FormControl>
  )
}
export default CustomCheckbox

CustomCheckbox.defaultProps = {
  label: null,
  otherData: null,
  isDocument: false,
}

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  otherData: PropTypes.string,
  isDocument: PropTypes.bool,
}

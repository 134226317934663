import { createSelector } from '@reduxjs/toolkit'

const selectDocumentsBrokerageServices = (state) => state.documentsBrokerageServices

export const selectGetDocumentsBrokerageServicesTypes = createSelector(
  [selectDocumentsBrokerageServices],
  (state) => state.documentTypes,
)

export const selectGetDocuments = createSelector(
  [selectDocumentsBrokerageServices],
  (state) => state.documents,
)

export const selectGetJsonFields = createSelector(
  [selectDocumentsBrokerageServices],
  (state) => state.jsonFields,
)
export const selectGetJsonFieldsForType = createSelector(
  [selectDocumentsBrokerageServices],
  (state) => state.jsonFieldsForType,
)
export const selectGetDocumentBrokerageServices = createSelector(
  [selectDocumentsBrokerageServices],
  (state) => state.documentBrokerageServices,
)
export const selectSaveDocument = createSelector(
  [selectDocumentsBrokerageServices],
  (state) => state.saveDocument,
)
export const selectDocument = createSelector(
  [selectDocumentsBrokerageServices],
  (state) => state.performDocument,
)

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100vh',
    '@media (max-width: 1440px)': {
      paddingLeft: 16,
      paddingRight: 16,
    },
    '@media (min-width: 577px)': {
      maxWidth: 1330,
    },
    '@media (max-width: 576px)': {
      height: 'auto',
      paddingLeft: 'calc(46px + 20px)',
      paddingRight: 20,
    },
  },
  main: {
    flexDirection: 'column',
    display: 'flex',
    minHeight: '100%',
    paddingTop: 115,
    paddingLeft: 272,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 100,
    },
    '@media (min-width: 577px)': {
      width: 1330,
      paddingRight: 16,
    },
    '@media (max-width: 576px)': {
      paddingTop: 96,
      paddingBottom: 60,
      paddingLeft: 0,
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1111,
    paddingBottom: 32,
    '@media (max-width: 576px)': {
      paddingBottom: 0,
    },
  },
}))

export default useStyles

import React from 'react'
import Box from '@material-ui/core/Box'
import Assignment from './Assignment'
import PageHeading from '../PageHeading/PageHeading'

export default function SecuritiesOutput() {
  return (
    <Box maxWidth={898}>
      <PageHeading text="Вывод" />
      <Assignment
        hasSecuritySwitch={false}
        securityType="Orders.Type.NonTrade.Depo.Out"
      />
    </Box>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import Media from 'react-media'
import { format } from 'date-fns'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import classnames from 'classnames'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'
import CustomInput from '../../CustomControls/CustomInput'
import CustomSwitch from '../../CustomControls/CustomSwitch'
import CustomDatepicker from '../../CustomControls/CustomDatepicker'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import useStyles from './sharedStyles'

export default function IdentificationContent({ data, handleClickNextPanel, isNeedNextButton }) {
  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Grid
              container
              className={classes.row}
              justify="space-between"
              spacing={2}
            >
              <Grid
                item
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="docType"
                  label="Тип документа"
                  value={data?.identity?.kind?.name || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="docSerial"
                  label="Серия"
                  value={data?.identity?.series || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="number"
                  label="Номер"
                  value={data?.identity?.number || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.row}
              justify="space-between"
              spacing={2}
            >
              <Grid
                item
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="subdivisionCode"
                  label="Код подразделения"
                  value={data?.identity?.departmentCode || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
              >
                <CustomDatepicker
                  label="Дата выдачи"
                  value={data?.identity?.issueDate}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
              >
                <CustomDatepicker
                  label="Дата окончания"
                  value={data?.identity?.expirationDate}
                  disabled
                  unique
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classnames(classes.row, classes.row_pbLess)}
            >
              <CustomInput
                name="issued"
                label="Выдан"
                value={data?.identity?.issuingAuthority || ''}
                fullWidth
                variant="outlined"
                size="small"
                disabled
                unique
              />
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.switchContainer}
            >
              <span className={classes.switchLegend}>Миграционная карта</span>
              <CustomSwitch
                name="first"
                isChecked={!!(data?.migrationCard && Object.keys(data?.migrationCard).length)}
                disabled
              />
            </Grid>
            <Grid
              container
              className={classes.row}
              spacing={2}
            >
              <Grid
                item
                lg={4}
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="migrationCardNumber"
                  label="Номер"
                  value={data?.migrationCard?.number || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg={4}
                sm={5}
                xs={12}
              >
                <CustomDatepicker
                  label="Дата начала срока пребывания"
                  value={data?.migrationCard?.issueDate}
                  minDate={data?.migrationCard?.issueDate}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg={4}
                sm={5}
                xs={12}
              >
                <CustomDatepicker
                  label="Дата окончания срока пребывания"
                  value={data?.migrationCard?.expirationDate}
                  disabled
                  unique
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={classes.switchContainer}
            >
              <span className={classes.switchLegend}>Вид на жительство</span>
              <CustomSwitch
                name="second"
                isChecked={!!(data?.legalResidence && Object.keys(data?.legalResidence).length)}
                disabled
              />
            </Grid>
            <Grid
              container
              className={classes.row}
              spacing={2}
            >
              <Grid
                item
                lg={4}
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="residencePermitNumber"
                  label="Номер"
                  value={data?.legalResidence?.number || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg={4}
                sm={5}
                xs={12}
              >
                <CustomDatepicker
                  label="Дата начала срока пребывания"
                  value={data?.legalResidence?.issueDate}
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg={4}
                sm={5}
                xs={12}
              >
                <CustomDatepicker
                  label="Дата окончания срока пребывания"
                  value={data?.legalResidence?.expirationDate}
                  disabled
                  unique
                />
              </Grid>
            </Grid>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <div className={classes.Mobile}>
            {data?.identity?.document && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Тип документа
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.identity?.kind?.name}
              </div>
            </div>
            )}
            <div className={classes.Mobile__seriesNumber}>
              {data?.identity?.series && (
                <div className={classes.Mobile__blockSeries}>
                  <div className={classes.Mobile__blockHeader}>
                    Серия
                  </div>
                  <div className={classes.Mobile__blockText}>
                    {data?.identity?.series}
                  </div>
                </div>
              )}
              {data?.identity?.number && (
                <div className={classes.Mobile__blockNumber}>
                  <div className={classes.Mobile__blockHeader}>
                    Номер
                  </div>
                  <div className={classes.Mobile__blockText}>
                    {data?.identity?.number}
                  </div>
                </div>
              )}
            </div>
            {data?.identity?.departmentCode && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  Код подразделения
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.identity?.departmentCode}
                </div>
              </div>
            )}
            {data?.identity?.issueDate && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Дата выдачи
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.identity?.issueDate && format(new Date(data?.identity?.issueDate), 'dd.MM.yyyy')}
              </div>
            </div>
            )}
            {data?.identity?.issuingAuthority && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Выдан
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.identity?.issuingAuthority}
              </div>
            </div>
            )}
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Миграционная карта
              </div>
              <div className={classes.Mobile__blockText}>
                <CustomSwitch
                  name="first"
                  isChecked={!!(data?.migrationCard && Object.keys(data?.migrationCard).length)}
                  disabled
                />
              </div>
            </div>
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Вид на жительство
              </div>
              <div className={classes.Mobile__blockText}>
                <CustomSwitch
                  name="second"
                  isChecked={!!(data?.legalResidence && Object.keys(data?.legalResidence).length)}
                  disabled
                />
              </div>
            </div>
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </div>
        )}
      />
    </MuiPickersUtilsProvider>
  )
}

IdentificationContent.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

IdentificationContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  Loader: {
  },
  Loader_new: {
    maxHeight: '60px',
    border: 'none',
    padding: '20px 16px',
    width: '100%',
    // eslint-disable-next-line max-len
    backgroundImage: 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'8\' ry=\'8\' stroke=\'%23B2B7C2\' stroke-width=\'1\' stroke-dasharray=\'9\' stroke-dashoffset=\'9\' stroke-linecap=\'square\'/%3e%3c/svg%3e")',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
  },
  Loader__label: {
    fontSize: 16,
    lineHeight: '16px',
    color: theme.palette.type === 'light' ? '#000' : '#fff',
    '@media(max-width: 576px)': {
      fontSize: 14,
    },
  },
  Loader__label_new: {
    color: '#8C93A8',
    margin: 0,
    fontSize: 14,
    lineHeight: '16px',
    marginRight: 10,
  },
  '@keyframes wave': {
    '0%, 60%, 100%': {
      transform: 'translate(0, 0)',
      backgroundColor: '#8C93A8',
    },
    '10%': {
      backgroundColor: '#e3e3e3',
    },
  },
  Loader__items: {
    '& span': {
      display: 'inline-block',
      height: 10,
      width: 10,
      '@media(max-width: 576px)': {
        height: 9,
        width: 9,
      },
      background: '#91B1BF99',
      animation: '$wave 2s ease infinite',
      '&:nth-child(1)': {
        animationDelay: '0ms',
      },
      '&:nth-child(2)': {
        animationDelay: '100ms',
      },
      '&:nth-child(3)': {
        animationDelay: '200ms',
      },
      '&:nth-child(4)': {
        animationDelay: '300ms',
      },
      '&:nth-child(5)': {
        animationDelay: '400ms',
      },
      '&:nth-child(6)': {
        animationDelay: '500ms',
      },
      '&:nth-child(7)': {
        animationDelay: '600ms',
      },
      '&:nth-child(8)': {
        animationDelay: '700ms',
      },
      '&:nth-child(9)': {
        animationDelay: '800ms',
      },
      '&:nth-child(10)': {
        animationDelay: '900ms',
      },
      '&:nth-child(11)': {
        animationDelay: '1000ms',
      },
      '&:nth-child(12)': {
        animationDelay: '1100ms',
      },
      '&:nth-child(13)': {
        animationDelay: '1200ms',
      },
      '&:nth-child(14)': {
        animationDelay: '1300ms',
      },
      '&:nth-child(15)': {
        animationDelay: '1400ms',
      },
      '&:nth-child(16)': {
        animationDelay: '1500ms',
      },
      '&:nth-child(17)': {
        animationDelay: '1600ms',
      },
      '&:nth-child(18)': {
        animationDelay: '1700ms',
      },
      '&:not(:last-child)': {
        marginRight: 4,
      },
    },
  },
  Loader__items_new: {
    '& span': {
      display: 'inline-block',
      height: 2,
      width: 2,
      background: '#8C93A8',
      animation: '$wave 2s ease infinite',
      '&:nth-child(1)': {
        animationDelay: '0ms',
      },
      '&:nth-child(2)': {
        animationDelay: '100ms',
      },
      '&:nth-child(3)': {
        animationDelay: '200ms',
      },
      '&:nth-child(4)': {
        animationDelay: '300ms',
      },
      '&:nth-child(5)': {
        animationDelay: '400ms',
      },
      '&:nth-child(6)': {
        animationDelay: '500ms',
      },
      '&:nth-child(7)': {
        animationDelay: '600ms',
      },
      '&:nth-child(8)': {
        animationDelay: '700ms',
      },
      '&:nth-child(9)': {
        animationDelay: '800ms',
      },
      '&:nth-child(10)': {
        animationDelay: '900ms',
      },
      '&:nth-child(11)': {
        animationDelay: '1000ms',
      },
      '&:nth-child(12)': {
        animationDelay: '1100ms',
      },
      '&:nth-child(13)': {
        animationDelay: '1200ms',
      },
      '&:nth-child(14)': {
        animationDelay: '1300ms',
      },
      '&:nth-child(15)': {
        animationDelay: '1400ms',
      },
      '&:nth-child(16)': {
        animationDelay: '1500ms',
      },
      '&:nth-child(17)': {
        animationDelay: '1600ms',
      },
      '&:nth-child(18)': {
        animationDelay: '1700ms',
      },
      '&:not(:last-child)': {
        marginRight: 4,
      },
    },
  },
}))

export default useStyles

import getFilterLink from '../utils/GetFilterLink'

const version = 'v2'

const endpoints = {
  auth: {
    login: `/${version}/auth/login`,
    reset: `/${version}/auth/password`,
    logout: `/${version}/auth/logout`,
    status: `/${version}/auth/status`,
  },
  heartbeats: `/${version}/heartbeats`,
  scanerLink: `/${version}/redirects/scanner`,
  reports: {
    request: `/${version}/reports`,
    getReport(id = null) { return `/${version}/reports${id ? `/${id}` : ''}` },
    postClientSign: `/${version}/reports/execute`,
    postReportReject: `/${version}/reports/reject`,
    broker: `/${version}/reports/broker`,
    depo: `/${version}/reports/depo`,
    corp: `/${version}/reports/corp`,
    brokerFilter(filterBody) { return getFilterLink(endpoints.reports.broker, filterBody) },
    depoFilter(filterBody) { return getFilterLink(endpoints.reports.depo, filterBody) },
    corpFilter(filterBody) { return getFilterLink(endpoints.reports.corp, filterBody) },
    needSign: `/${version}/reports/needsign`,
    reportMarkread: `/${version}/reports/markread`,
    reportTypesNames: `/${version}/reports/typesnames`,
  },
  portfolio: {
    categories: `/${version}/clientsassets/categoriesvaluations`,
    total: `/${version}/clientsassets/totalvaluations`,
    time: `/${version}/clientsassets/valuationactualtime`,
    valuations: `/${version}/clientsassets/valuations`,
    total2: `/${version}/clientsassets/totalvaluations2`,
    time2: `/${version}/clientsassets/valuationactualtime2`,
    valuations2: `/${version}/clientsassets/valuations2`,
    valuations3: `/${version}/clientsassets/valuations3`,
    portfolio: `/${version}/clientsassets/portfolio`,
    instruments: `/${version}/clientsassets/instrumentsvaluations2`,
    ndfl: `/${version}/clientsassets/pit`,
  },
  feedback: {
    getUser(id = null) { return `/${version}/usersrequests${id ? `/${id}` : ''}` },
    postUser(needInstruction = true) { return `/${version}/usersrequests${(needInstruction && '?instruction=execute') || ''}` },
    putUser(data, id = null) { return `/${version}/usersrequests${(data?.id || id) ? `/${(data?.id || id)}` : ''}${data?.id ? '?instruction=execute' : ''}` },
  },
  file: {
    getFile(id) { return `/${version}/files${id ? `/${id}` : ''}` },
    postFile: `/${version}/files`,
    postFileList: `/${version}/files/list`,
  },
  scanner: {
    getRequestIsAccess: '/v3/scanner/isaccess',
    getRequestAccess: '/v3/scanner/requestaccess',
    getScannerLink: '/v3/scanner/link',
  },
  history: {
    getMoney: `/${version}/orders/money`,
    getDepo: `/${version}/orders/depo`,
    moneyFilter(filterBody) { return getFilterLink(endpoints.history.getMoney, filterBody) },
    depoFilter(filterBody) { return getFilterLink(endpoints.history.getDepo, filterBody) },
    ordersTypesNames: `/${version}/orders/typesnames`,
  },
  forms: {
    getForms: `/${version}/forms`,
  },
  metadata: {
    getMetadata(typeUID = null) { return `/${version}/metadata${typeUID ? `/${typeUID}` : ''}` },
  },
  securities: {
    getSecurities(filterBody = '') { return getFilterLink(`/${version}/securities`, filterBody) },
    getSecurity(id = null) { return `/${version}/orders${id ? `/${id}` : ''}` },
    postSecurity(needInstruction = true) { return `/${version}/orders${(needInstruction && '?instruction=execute') || ''}` },
    putSecurity(data, id = null, needInstruction = true) {
      return `/${version}/orders${(data?.id || id) ? `/${(data?.id || id)}` : ''}${(data?.id || id) && needInstruction ? '?instruction=execute' : ''}`
    },
  },
  banks: {
    getBanks: `/${version}/banks`,
    getBanksFilter(filterBody = '') { return getFilterLink(endpoints.banks.getBanks, filterBody) },
  },
  paymentTemplates: {
    getPaymentTemplates: `/${version}/paymenttemplates`,
    getPaymentTemplatesFilter(filterBody = '') { return getFilterLink(endpoints.paymentTemplates.getPaymentTemplates, filterBody) },
    deletePaymentTemplate(id) { return `${endpoints.paymentTemplates.getPaymentTemplates}/${id}` },
  },
  security: {
    getTransferSecurity(id = null) { return `/${version}/orders${id ? `/${id}` : ''}` },
    postTransferSecurity(needInstruction = true) { return `/${version}/orders${(needInstruction && '?instruction=execute') || ''}` },
    putTransferSecurity(data, id = null, needInstruction = true) { return `/${version}/orders${(data?.id || id) ? `/${(data?.id || id)}` : ''}${(data?.id || id) && needInstruction ? '?instruction=execute' : ''}` },
  },
  moneys: {
    getTransferMoney(id = null) { return `/${version}/orders${id ? `/${id}` : ''}` },
    postTransferMoney(needInstruction = true) { return `/${version}/orders${(needInstruction && '?instruction=execute') || ''}` },
    putTransferMoney(data, id = null, needInstruction = true) { return `/${version}/orders${(data?.id || id) ? `/${(data?.id || id)}` : ''}${(data?.id || id) && needInstruction ? '?instruction=execute' : ''}` },
  },
  notifications: {
    getNotifications(id = null) { return `/${version}/notifications${id ? `/${id}` : ''}` },
  },
  assets: {
    getBalancesDepo(filterBody = '') { return getFilterLink(`/${version}/clientsassets/balances/depo`, filterBody) },
    getBalancesMoney(filterBody = '') { return getFilterLink(`/${version}/clientsassets/balances/money`, filterBody) },
  },
  investmentsNotices: {
    getAccountsInvestments: `/${version}/accounts/investlist`,
    getAgreementsInvestments: `/${version}/orders/agreementsinvestments`,
    getAccountsDepo: `/${version}/orders/accountsdepo`,
    getAgreementsDepo: `/${version}/agreements/depolist`,
    getMoneyRequisites: `/${version}/investmentsnotices`,
    getDepoRequisites: `/${version}/investmentsnotices/depo`,
    moneyRequisitesFilter(filterBody = '') {
      return getFilterLink(endpoints.investmentsNotices.getMoneyRequisites, filterBody)
    },
    depoRequisitesFilter(filterBody = '') {
      return getFilterLink(endpoints.investmentsNotices.getDepoRequisites, filterBody)
    },
  },
  trades: {
    calculations: `/${version}/calculations/order`,
  },
  partners: {
    getPartners(filterBody = '') {
      return getFilterLink(`/${version}/partners`, filterBody)
    },
  },
  documents: {
    getExchangeRequests: `/${version}/ExchangeRequests`,
    getExchangeRequestsTypelist: `/${version}/ExchangeRequests/typeslist`,
    getExchangeRequestsJsonFields: `/${version}/ExchangeRequests/jsonfields`,
    getExchangeRequestsJsonFieldsForType(exchangeRequestTypeUID) { return `/${version}/ExchangeRequests/jsonfieldsfortype${(exchangeRequestTypeUID && `?exchangeRequestTypeUID=${exchangeRequestTypeUID}`) || ''}` },
    postDocument(needInstruction = true) { return `/${version}/ExchangeRequests${(needInstruction && '?instruction=execute') || ''}` },
    putDocument(data, id = null, needInstruction = true) { return `/${version}/ExchangeRequests${(data?.id || id) ? `/${(data?.id || id)}` : ''}${(data?.id || id) && needInstruction ? '?instruction=execute' : ''}` },
    getDocument(id = null) { return `/${version}/ExchangeRequests${id ? `/${id}` : ''}` },
  },
  checks: {
    scanerPermissions: `/${version}/scannerpermissions`,
    portfolioPermissions: `/${version}/portfoliopermissions`,
  },
}

export default endpoints

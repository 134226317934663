import { styled } from "ui/theme";

export const StyledLoginWrapper = styled.div`
`

export const StyledLoginSecondWrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 420px) {
    justify-content: flex-start;
  }
`

export const StyledLoginTitle = styled.div`
  margin-bottom: 32px;

  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 32px;
  }

  @media ${({ theme }) => theme.breakpoint.sm} {
    margin-bottom: 32px;
  }

  @media (max-width: 420px) {
    margin-bottom: 24px;
    span {
      font-size: 18px;
    }
  }

  @media ${({ theme }) => theme.breakpoint.xxs} {
    margin-bottom: 24px;
    text-align: center;

    > span {
      font-size: 18px;
    }
  }
`
export const StyledLabel = styled.div`
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 8px;
  }

  @media ${({ theme }) => theme.breakpoint.sm} {
    margin-bottom: 8px;
  }

  @media ${({ theme }) => theme.breakpoint.xs} {
    margin-bottom: 8px;
  }

  @media ${({ theme }) => theme.breakpoint.xxs} {
    margin-bottom: 8px;
  }
`

export const StyledLabelSecond = styled.div`
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 20px;
  }

  @media ${({ theme }) => theme.breakpoint.sm} {
    margin-bottom: 22px;
  }

  @media ${({ theme }) => theme.breakpoint.xs} {
    margin-bottom: 22px;
  }

  @media ${({ theme }) => theme.breakpoint.xs} {
    margin-bottom: 20px;
  }
`

export const StyledField = styled.div`
  margin-bottom: 16px;

  > input {
    width: 100%;
  }

  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 16px;
  }

  @media ${({ theme }) => theme.breakpoint.sm} {
    margin-bottom: 16px;
  }
`

export const StyledFieldSecond = styled.div`
  margin-bottom: 28px;
  > input {
    width: 100%;
  }
`

export const StyledButton = styled.div`
  width: 100%;
  margin-bottom: 16px;

  > button {
    width: 100%;
  }
`

export const StyledRememberPassword = styled.div`
  @media ${({ theme }) => theme.breakpoint.xxs} {
    text-align: center;
  }
`

export const StyledLoginContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 460px) {
    justify-content: flex-start;
  }
`

export const StyledInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 460px;

  @media (max-width: 630px) {
    flex-direction: column;
    text-align: center;
  }

  @media (max-width: 420px) {
    flex-direction: row;
    text-align: center;
  }
`

export const StyledInfoBlockLogout = styled.div`
  //position: absolute;
  width: 100%;

  display: flex;
  justify-content: space-between;
`

export const StyledSelect = styled.div`
  margin-bottom: 28px;

  > input {
    width: 100%;
  }
`

export const StyledResetText = styled.div`
  margin-top: 6px;
  text-decoration: underline;
  text-decoration-color: ${({theme}) => theme.color.text.accent};
  cursor: pointer;
  text-align: center;

  @media ${({ theme }) => theme.breakpoint.xxs} {
    width: unset;
    margin-bottom: 34px;
  }
`

export const StyledTimer = styled.span`
  color: ${({theme}) => theme.color.text.accent};
`

export const StyledErrorText = styled.div`

  position: relative;
  > span {
    position: absolute;
    top: 4px;
  }
`

export const StyledSecurityIcon = styled.div`
  position: relative;
  > img {
    width: 20px;
    height: 20px;

    position: absolute;
    right: 12px;
    top: -32px;
  }
`

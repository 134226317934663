import useMedia from 'hooks/useMedia'
import { ITableColumn } from 'ui/components/molecules'
import { getTableCellWidthPercent } from 'utils/helpers/table'
import { IRequestTableRow } from './types'

const useRequisitesTable = () => {
  const { isDesktop, isMobile } = useMedia()

  const tableWidth = isDesktop ? 1096 : 680

  const getRequisitesTabColumns = ({
    showAccount,
    showCurrency,
    showName,
  }): ITableColumn<IRequestTableRow>[] => [
    ...(showName
      ? [
          {
            id: 'agentName' as keyof IRequestTableRow,
            title: 'Получатель платежа',
            width: isMobile
              ? undefined
              : getTableCellWidthPercent(isDesktop ? 360 : 232, tableWidth),
          },
        ]
      : []),
    ...(showCurrency
      ? [
          {
            id: 'currencyUid' as keyof IRequestTableRow,
            title: 'Валюта',
            width: isMobile
              ? undefined
              : getTableCellWidthPercent(isDesktop ? 119 : 92, tableWidth),
            paddingLeft: 24,
          },
        ]
      : []),
    ...(showAccount
      ? [
          {
            id: 'bankAccount' as keyof IRequestTableRow,
            title: 'Счёт получателя',
            width: isMobile ? undefined : getTableCellWidthPercent(259, tableWidth),
            paddingLeft: 24,
          },
        ]
      : []),
    {
      id: 'bankName',
      title: 'Наименование банка получателя',
      width: isMobile ? undefined : getTableCellWidthPercent(isDesktop ? 282 : 336, tableWidth),
      paddingLeft: 24,
    },
  ]

  return {
    getRequisitesTabColumns,
  }
}

export default useRequisitesTable

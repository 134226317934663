import Axios from 'axios'
import { axiosInterceptor } from './Axios'
/**
 *
 * @param apiURL - api url
 * @param url - api endpoint
 * @param method - GET, POST, PUT, DELETE
 * @param data - для передачи данных с пагинацией передавать параметр "page"
 * @param params - параметры запроса
 * @param args
 */

export const request = async (
  apiURL, url, method, data, params, ...args
) => {
  const axiosConfig = {
    method: (method || 'get'),
    url: `${apiURL}${url}`,
    data,
    params,
    ...args,
  }
  const createAxios = Axios.create(axiosConfig)
  const updatedCreateAxios = axiosInterceptor(createAxios)
  return updatedCreateAxios(axiosConfig)
}

export default request

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({

  searchItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    '& svg': {
      position: 'absolute',
      right: 0,
    },
    '& div': {
      maxWidth: '93%',
    },
  },
  formControl: {
    borderRadius: 0,
    '&:not(:last-child)': {
      marginBottom: 30,
      '@media (max-width: 576px)': {
        marginBottom: 24,
      },
    },
    '& > .MuiFormLabel-root.Mui-focused': {
      color: '#91B1BF',
    },
    '& > .MuiFormLabel-root.Mui-error': {
      color: '#f44336',
      '& + .MuiFormControl-root .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336',
      },
      '& + .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
        borderColor: '#f44336',
      },
      '& + .react-datepicker-wrapper': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#f44336',
        },
        '& :after': {
          borderBottom: '1px solid #f44336',
          borderRight: '1px solid #f44336',
          borderTop: '1px solid #f44336',
        },
      },
      '& + .MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
        '&:before': {
          borderColor: '#f44336',
        },
      },
    },
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
      borderRadius: 0,
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: '#91B1BF',
        },
      },
      '&.MuiInputBase-multiline': {
        '&:after': {
          display: 'none',
        },
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#91B1BF',
        },
      },
      '& .MuiInputBase-input': {
        fontFamily: 'Arial',
      },
      '& .MuiOutlinedInput-input': {
        paddingLeft: 10,
        paddingRight: 10,
        '@media (max-width: 576px)': {
          paddingLeft: 5,
          paddingRight: 5,
          '&.MuiInputBase-inputMultiline': {
            fontSize: 14,
          },
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        borderColor: '#91B1BF',
      },
      '& .MuiButtonBase-root': {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiSelect-root': {
        padding: '8px 35px 3px 11px',
        fontSize: 19,
        '@media (max-width: 576px)': {
          paddingTop: 6,
          paddingRight: '35px !important',
          fontSize: 12,
        },
      },
    },
    '& $selectField': {
      '& .MuiSelect-select': {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#f44336',
        },
      },
    },
    '& .MuiFormHelperText-root': {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      lineHeight: '16px',
      position: 'absolute',
      bottom: -15,
      left: 0,
      whiteSpace: 'nowrap',
      '@media (max-width: 576px)': {
        position: 'static',
        marginTop: 2,
        lineHeight: '12px',
        whiteSpace: 'normal',
      },
    },
    '& .MuiAutocomplete-root': {
      '&[aria-expanded=true]': {
        '& :after': {
          display: 'none',
        },
      },
    },
  },
  uniqueFormControl: {
    '&:not(:last-child)': {
      marginBottom: 20,
      '@media (max-width: 576px)': {
        marginBottom: 11,
      },
    },
    '& $selectField': {
      '&:after': {
        opacity: 0,
      },
      '& .MuiSelect-select': {
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          left: 'auto',
          width: 7,
          height: '100%',
          borderBottom: 0,
          backgroundColor: '#91B1BF',
          '@media (max-width: 576px)': {
            width: 5,
          },
        },
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: 15,
      color: theme.palette.type === 'light' ? '#292D3499' : '#ffffff99',
      '@media (max-width: 576px)': {
        marginBottom: 3,
        fontSize: 14,
        lineHeight: '19px',
        color: '#91B1BF',
      },
      '&.Mui-focused': {
        '@media (max-width: 576px)': {
          color: '#91B1BF',
        },
        color: theme.palette.type === 'light' ? '#292D3499' : '#ffffff99',
      },
    },
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-multiline': {
        padding: 0,
        '& .MuiInputBase-input': {
          paddingTop: 11,
          paddingBottom: 11,
          lineHeight: '23px',
          resize: 'auto',
        },
      },
    },
    '& .MuiInputBase-root': {
      position: 'relative',
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: '#91B1BF',
        },
      },
      '& .MuiInputBase-input': {
        boxSizing: 'border-box',
        minHeight: 28,
        fontSize: 15,
        '&.MuiInputBase-input[disabled]': {
          color: theme.palette.type === 'light' ? '#363E47' : '#fff',
        },
      },
      '& .MuiSelect-root': {
        padding: '5px 35px 4px 10px',
        fontSize: 15,
        '@media (max-width: 576px)': {
          paddingLeft: 5,
          paddingRight: 48,
          fontSize: 14,
        },
        '&[aria-expanded=true]': {
          '&:after': {
            display: 'none',
          },
        },
      },
      '& .MuiSelect-icon': {
        top: 10,
        right: 13,
        '@media (max-width: 576px)': {
          right: 18,
        },
      },
    },
    '& .MuiOutlinedInput-adornedEnd': {
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 'auto',
        width: 7,
        height: '100%',
        borderBottom: 0,
        backgroundColor: '#91B1BF',
        '@media (max-width: 576px)': {
          width: 5,
        },
      },
    },
  },
  groupFormControl: {
    '& > .MuiFormControl-root': {
      '&:first-of-type': {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        bottom: 0,
        width: 'calc(100% - 35px)',
        marginBottom: 0,
        '& .MuiInputBase-root': {
          '&:after': {
            content: 'none',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRight: 0,
        },
      },
    },
  },
  inputLabel: {
    marginBottom: 5,
    fontSize: 18,
    '@media (max-width: 576px)': {
      marginBottom: 3,
      fontSize: 14,
      lineHeight: '19px',
    },
  },
  inputField: {

    '& > input': {
      color: '#f44336',
    },

    '& .MuiInputBase-root': {
      '&.MuiInput-root': {
        paddingBottom: 0,
      },
      '&:hover': {
        '&:before': {
          borderBottom: '1px solid #91B1BF',
        },
      },
      '&:before': {
        height: '100%',
        border: '1px solid #91B1BF',
      },
    },
    '& .MuiInputBase-input': {
      boxSizing: 'border-box',
      minHeight: 38,
      padding: '7px 11px',
      fontFamily: 'Arial',
      fontSize: 19,
      '@media (max-width: 576px)': {
        minHeight: 28,
        fontSize: 12,
      },
      '&[disabled]': {
        color: theme.palette.type === 'light' ? '#363E47' : '#fff',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#91B1BF',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .MuiInputBase-input.MuiOutlinedInput-inputAdornedEnd': {
      paddingRight: 38,
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      right: 11,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
    '& .MuiAutocomplete-inputRoot': {
      '&:after': {
        display: 'none',
      },
      '& .MuiAutocomplete-input': {
        padding: '7px 0 7px 10px !important',
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      height: '100%',
      display: (props) => (props.inputSelect ? 'block' : 'none'),
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 'auto',
        width: 7,
        height: '100%',
        borderBottom: 0,
        backgroundColor: '#91B1BF',
        '@media (max-width: 576px)': {
          width: 5,
        },
      },
      '& .MuiAutocomplete-popupIndicator': {
        top: 4,
        right: 14,
      },
    },
  },
  defaultHelperText: {
    left: '7px !important',
    bottom: '-24px !important',
    fontSize: 15,
    lineHeight: '20px !important',
    color: '#292D3499',
  },
  selectField: {
    minWidth: 200,
    borderRadius: 0,
    '@media (max-width: 576px)': {
      minWidth: 'auto',
    },
    '&:after': {
      opacity: 0,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#91B1BF',
    },
    '& .MuiSelect-icon': {
      top: 13,
      right: 11,
      '@media (max-width: 576px)': {
        top: 10,
      },
    },
  },
  selectField__outOfRange: {
    '&.MuiButtonBase-root.MuiListItem-root': {
      display: 'none',
    },
  },
  menuPaper: {
    marginTop: -1,
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    '@media (max-width: 576px)': {
      maxWidth: 'calc(100% - 46px - 40px)',
    },
  },
  menuPaperUnique: {
    '& .MuiList-root': {
      overflowY: 'auto',
      maxHeight: 204,
      '&::-webkit-scrollbar': {
        width: 6,
        backgroundColor: 'transparent',
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#91B1BF',
        border: 'none',
        cursor: 'pointer',
      },
      '& .MuiButtonBase-root': {
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 15,
      },
    },
  },
  menuPaperFilter: {
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#343A42',
  },
  menuList: {
    padding: 0,
    border: '1px solid #91B1BF',
    '& .MuiButtonBase-root': {
      padding: '6px 10px',
      fontSize: 19,
      lineHeight: '22px',
      whiteSpace: 'normal',
      '@media (max-width: 576px)': {
        minHeight: 'auto',
        padding: '2px 6px 5px',
        fontSize: 14,
        lineHeight: '19px',
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
      },
      '&:not(:last-child)': {
        '@media (max-width: 576px)': {
          borderBottom: '1px solid #91B1BF',
        },
      },
      '&:hover': {
        backgroundColor: '#91B1BF1A',
      },
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& .MuiGrid-root': {
      '&.MuiGrid-item': {
        padding: 0,
      },
      '& > span': {
        fontSize: 16,
        '@media(max-width: 576px)': {
          fontSize: 12,
        },
      },
    },
  },
  switchLabel: {
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, .6)' : '#91B1BF',
  },
  IconButton__line: {
    position: 'relative',
    '&:after': {
      content: "''",
      position: 'absolute',
      height: 16,
      backgroundColor: theme.palette.type === 'light' ? '#000' : '#fff',
      width: 1,
      right: -23,
      pointerEvents: 'none',
    },
  },
  switch: {
    '&.MuiSwitch-root': {
      width: 72,
      height: 40,
      padding: 10,
      '@media (max-width: 576px)': {
        width: 60,
        height: 36,
      },
    },
    '& .MuiSwitch-switchBase': {
      transform: 'translateX(0) !important',
    },
    '& .MuiButtonBase-root': {
      top: 10,
      left: 10,
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 18px)',
      padding: 0,
      transform: 'translateX(0) !important',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'transparent',
        },
        '& .MuiSwitch-thumb': {
          transform: 'translateX(-31px)',
          '@media(max-width: 576px)': {
            transform: 'translateX(-24px)',
          },
        },
      },
      '&[aria-disabled="true"]': {
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'transparent',
        },
      },
      '& .MuiIconButton-label': {
        height: '100%',
      },
      '@media(max-width: 576px)': {
        top: 9,
        left: 9,
      },
    },
    '& .MuiSwitch-input': {
      left: 0,
      width: '100%',
    },
    '& .MuiSwitch-thumb': {
      position: 'absolute',
      left: 34,
      top: 3,
      width: 15,
      height: 15,
      borderRadius: 0,
      boxShadow: 'none',
      backgroundColor: '#FBC15E',
      transition: 'transform 0.05s ease-out',
      '@media(max-width: 576px)': {
        width: 12,
        height: 12,
        left: 27,
      },
    },
    '& .MuiSwitch-track': {
      width: 52,
      height: 21,
      borderRadius: 0,
      border: '1px solid #91B1BF',
      backgroundColor: 'transparent',
      opacity: 1,
      '@media(max-width: 576px)': {
        width: 40,
        height: 16,
      },
    },
    '@media(max-width: 576px)': {
      '&.MuiSwitch-root': {
        height: 34,
        width: 60,
      },
    },
  },
  switchActiveText: {
    color: theme.palette.type === 'light' ? '#000' : '#fff',
  },
  switchText: {
    color: '#91B1BF',
  },
  button: {
    '&.MuiButtonBase-root': {
      boxSizing: 'border-box',
      maxWidth: 300,
      minHeight: 38,
      borderRadius: 0,
      border: '1px solid #91B1BF',
      backgroundColor: '#91B1BF',
      fontFamily: 'Arial',
      fontSize: 18,
      textTransform: 'none',
      color: '#fff',
      transition: 'none',
      '&[disabled]': {
        border: '1px solid #91B1BF',
        backgroundColor: '#E6EDEF !important',
        color: '#666',
      },
      '@media (max-width: 576px)': {
        minHeight: 28,
        fontSize: 14,
        fontWeight: 400,
        maxWidth: '100%',
      },
    },
    '&.MuiButton-text': {
      padding: '2px 6px',
      '@media (max-width: 576px)': {
        paddingTop: 1,
        paddingBottom: 1,
      },
    },
  },
  primary: {
    '&.MuiButtonBase-root': {
      backgroundColor: '#91B1BF',
      color: '#fff',
      '&:hover': {
        borderColor: '#688695',
        backgroundColor: '#688695',
      },
    },
  },
  secondary: {
    '&.MuiButtonBase-root': {
      borderColor: '#91B1BF',
      backgroundColor: '#fff',
      color: '#91B1BF',
      '&:hover': {
        border: '1px solid #c4d5de',
        backgroundColor: '#c4d5de',
        color: '#fff',
      },
    },
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    paddingRight: 0,
    paddingLeft: 0,
    border: 0,
    background: 'transparent',
    fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 400,
    color: '#91B1BF',
    cursor: 'pointer',
    position: 'relative',
    '@media (max-width: 576px)': {
      flexDirection: 'row',
      fontFamily: 'Open Sans',
      fontSize: 14,
      lineHeight: '19px',
    },
    '&:not(:last-child)': {
      marginRight: 44,
    },
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      color: theme.palette.type === 'light' ? '#363E47' : '#fff',
      '& svg': {
        fill: '#FBC15E',
      },
    },
    '& svg': {
      width: 15,
      height: 15,
      marginBottom: 6,
      fill: '#91B1BF',
      '@media (max-width: 576px)': {
        marginRight: 10,
        marginBottom: 0,
        width: 12,
        maxHeight: 15,
        height: 'auto',
      },
    },
  },
  iconButtonUnique: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& svg': {
      marginRight: 4,
      marginBottom: 0,
    },
    '&:hover': {
      '&:after': {
        content: "''",
        width: '100%',
        height: 2,
        background: '#FBC15E',
        position: 'absolute',
        bottom: -4,
      },
    },
  },
  autocompletePaper: {
    borderRadius: 0,
    border: 0,
    boxShadow: 'none',
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    '& .MuiAutocomplete-listbox': {
      padding: 0,
      border: '1px solid #91B1BF',
      '&::-webkit-scrollbar': {
        width: 6,
        backgroundColor: 'transparent',
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#91B1BF',
        border: 'none',
        cursor: 'pointer',
      },
    },
    '& li': {
      padding: '6px 10px',
      fontSize: 16,
      lineHeight: '16px',
      '&:hover': {
        backgroundColor: '#91B1BF1A !important',
      },
    },
  },
  autocompletePaper_inputSelect: {
    marginTop: '-1px !important',
    '& li': {
      height: 32,
      minHeight: 28,
    },
    fontFamily: 'Open Sans',
    '& .MuiAutocomplete-noOptions': {
      border: '1px solid #91B1BF',
    },
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: 'rgba(145, 177, 191, 0.15)',
    },
    '& .MuiAutocomplete-listbox': {
      overflowY: 'auto',
      maxHeight: 204,
      padding: 0,
      border: '1px solid #91B1BF',
      '&::-webkit-scrollbar': {
        width: 6,
        backgroundColor: 'transparent',
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#91B1BF',
        border: 'none',
        cursor: 'pointer',
      },
    },
  },
  autocompletePaper_unique: {
    margin: '0px !important',
    '& .MuiAutocomplete-listbox': {
      borderTop: 0,
      borderLeft: '1px solid #91B1BF',
      borderRight: '1px solid #91B1BF',
      borderBottom: '1px solid #91B1BF',
    },
    '& li': {
      display: 'grid',
      gridTemplateColumns: '39.5% 33.79% 10.56% 16.15%',
      padding: '18px 12px',
      '&:not(:last-child)': {
        borderBottom: '1px dashed rgba(145, 177, 191, .6)',
      },
    },
  },
  paperList: {
    display: 'grid',
    gridTemplateColumns: '39.5% 33.79% 10.56% 16.15%',
    paddingTop: 7,
    paddingBottom: 5,
    borderTop: '1px solid #91B1BF',
    borderLeft: '1px solid #91B1BF',
    borderRight: '1px solid #91B1BF',
    paddingLeft: 12,
    paddingRight: 12,
  },
  paperList__heading: {
    fontSize: 14,
    lineHeight: '19px',
    color: '#91B1BF',
  },
  paperList__option: {
    '&:not(:last-child)': {
      marginRight: 20,
      wordBreak: 'break-word',
    },
  },
  CustomDatepicker__rangeButtonWrapper: {
    '& $CustomDatepicker__rangeButton': {
      '&:not(:last-child)': {
        marginRight: 13,
      },
    },
  },
  CustomDatepicker__rangeButton: {
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
    outline: 'none',
    cursor: 'pointer',
    '&:nth-of-type(2)': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
    '& svg': {
      fill: '#91B1BF',
    },
  },
  CustomDatepicker__modal: {
    position: 'absolute',
    top: 48,
    backgroundColor: '#fff',
    zIndex: 1,
    width: '100%',
    '@media(max-width: 576px)': {
      width: 'calc(100% + (18px*2))',
      left: '-18px !important',
    },
    '& .react-datepicker': {
      borderRadius: 0,
      minWidth: 237,
    },
    '& > div': {
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    },
  },
  CustomDatepicker__popper: {
    width: '100%',
    '@media(max-width: 576px)': {
      width: 'calc(100% + (18px*2))',
      left: '-18px !important',
    },
    '&.react-datepicker-popper': {
      margin: 0,
    },
  },
  CustomDatepicker__headerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 23px 7px',
  },
  CustomDatepicker__monthYearButtons: {
    marginRight: 17,
    '& > .react-datepicker-year-header.react-datepicker__header': {
      paddingTop: 0,
      fontFamily: 'Open Sans',
      color: theme.palette.type === 'light' ? '#000' : '#fff',
    },
  },
  CustomDatepicker__button: {
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    borderRadius: 5,
    border: '1px solid #91B1BF',
    minHeight: 22,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 600,
    fontFamily: 'Open Sans',
    color: theme.palette.type === 'light' ? '#000' : '#91B1BF',
  },
  CustomDatepicker__button_month: {
    maxWidth: 79,
    width: 79,
    marginRight: 7,
  },
  CustomDatepicker__calendar_days: {

  },
  CustomDatepicker__calendar: {
    width: '100%',
    '&.react-datepicker': {
      position: 'relative',
      overflow: 'hidden',
      // height: 243,
      borderRadius: 0,
      borderColor: '#91B1BF',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
    },
    '& .react-datepicker__year-wrapper': {
      columnGap: 16,
    },
    '& .react-datepicker__month-container': {
      // position: 'absolute',
      float: 'none',
      width: '100%',
      height: '100%',
      '& .react-datepicker__header': {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
      },
      '&:nth-child(2)': {
        '& .react-datepicker__header': {
          display: 'none',
        },
      },
      '& .react-datepicker__month': {
        paddingTop: 79,
      },
    },
    '& .react-datepicker__header': {
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
      border: 'none',
      paddingTop: 22,
    },
    '& .react-datepicker__month--selected': {
      borderRadius: 0,
      borderColor: '#000',
      backgroundColor: '#fff',
      border: 'none',
      '&:hover': {
        borderColor: theme.palette.type === 'light' ? '#000' : '#fff',
        backgroundColor: '#fff',
      },
    },
    '& .react-datepicker__month--disabled': {
      color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, .3) !important' : '#ffffff44 !important',
    },
    '& .react-datepicker__year-text': {
      '&.react-datepicker__year-text--disabled': {
        color: theme.palette.type === 'light' ? '#fff' : '#ffffff44',
      },
    },
    '& .react-datepicker__year-text--selected': {
      borderRadius: 0,
      backgroundColor: 'rgba(145, 177, 191, 0.3)',
      color: '#000',
      border: '1px solid transparent',
      '&:hover': {
        borderColor: '#000',
        backgroundColor: 'rgba(145, 177, 191, 0.3)',
      },
    },
    '& .react-datepicker__month-text, .react-datepicker__year-text': {
      border: '1px solid transparent',
      borderRadius: 0,
      minHeight: 44,
      minWidth: 44,
      width: 44,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Open Sans',
      fontSize: 14,
      lineHeight: '19px',
      outline: 'none',
      color: theme.palette.type === 'light' ? '#000' : '#fff',
      '&:hover': {
        borderColor: theme.palette.type === 'light' ? '#000' : '#fff',
        backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
      },
    },
    '& .react-datepicker__day-names': {
      justifyContent: 'space-between',
      display: 'flex',
      margin: '0 16px 1px',
      '& .react-datepicker__day-name': {
        width: 25,
        height: 25,
        margin: '0 2px',
        display: 'inline-flex',
        fontSize: 9,
        maxWidth: 25,
        alignItems: 'center',
        fontFamily: 'Open Sans',
        lineHeight: '16px',
        justifyContent: 'center',
        color: theme.palette.type === 'light' ? '#000' : '#fff',
      },
    },
    '& .react-datepicker__month, .react-datepicker__year': {
      margin: '0 16px 14px',
      '& .react-datepicker__month-wrapper, .react-datepicker__year-wrapper': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .react-datepicker__month-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected': {
        borderRadius: 0,
        backgroundColor: 'rgba(145, 177, 191, 0.6)',
        color: theme.palette.type === 'light' ? '#000' : '#fff',
        '&:hover': {
          backgroundColor: 'rgba(145, 177, 191, 0.6)',
        },
      },
    },
    '& .react-datepicker__week': {
      justifyContent: 'space-between',
      display: 'flex',
      marginBottom: 5,
      '&:nth-of-type(6), &:nth-of-type(7)': {
        // display: 'none',
      },
    },
    '& .react-datepicker__day': {
      fontSize: 14,
      lineHeight: '21px',
      fontFamily: 'Open Sans',
      maxWidth: 25,
      width: 25,
      height: 25,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 2px',
      borderRadius: 0,
      border: '1px solid transparent',
      outline: 'none',
      color: theme.palette.type === 'light' ? '#000' : '#fff',
      '&:hover': {
        borderColor: theme.palette.type === 'light' ? '#000' : '#fff',
        backgroundColor: 'transparent',
      },
      '&.react-datepicker__day--outside-month': {
        color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, .3)' : '#ffffff44',
      },
      '&.react-datepicker__day--selected': {
        backgroundColor: 'rgba(145, 177, 191, 0.6)',
        color: theme.palette.type === 'light' ? '#000' : '#fff',
        '&:hover': {
          backgroundColor: 'rgba(145, 177, 191, 0.6)',
        },
      },
      '&.react-datepicker__day--today': {
        backgroundColor: 'rgba(145, 177, 191, 0.6) !important',
        fontWeight: 400,
        color: '#000',
        '&:hover': {
          backgroundColor: 'rgba(145, 177, 191, 0.6)',
        },
      },
      '&.react-datepicker__day--disabled': {
        backgroundColor: 'transparent !important',
        color: theme.palette.type === 'light' ? '#ccc !important' : '#ffffff44 !important',
      },
      '&.react-datepicker__day--keyboard-selected': {
        backgroundColor: theme.palette.type === 'light' ? '#fff' : 'rgba(145, 177, 191, 0.6)',
        color: theme.palette.type === 'light' ? '#000' : '#fff',
      },
    },
  },
  CustomDatepicker__monthCalendar: {
    '& $CustomDatepicker__headerButtons': {
      marginBottom: 2,
    },
    '& .react-datepicker__month-container': {
      '& .react-datepicker__header': {
        display: 'block !important',
      },
    },
    '& .react-datepicker__month-container .react-datepicker__month': {
      margin: '0 16px 3px',
      paddingTop: 47,
    },
  },
  CustomDatepicker__yearCalendar: {
    '& .react-datepicker__year-wrapper': {
      maxWidth: '100%',
    },
    '& $CustomDatepicker__headerButtons': {
      marginBottom: 2,
    },
    '& .react-datepicker__year--container .react-datepicker__year': {
      margin: '0 16px 3px',
    },
  },
  invalid_border: {
    '& .MuiInputBase-input': {
      borderColor: '#B11B1B',
    },
  },
}))

export default useStyles

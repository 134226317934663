import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getTableCellWidthPercent } from '../../../../utils/helpers/table'
import { useSizeContext } from '../../../../context/SizeContext'
import MessageHistoryCard from './MessageHistoryCard'

import { theme } from '../../../theme'
import { Table as TableNew } from '../../molecules/Table'
import { formatDateAndTime } from '../../../../utils/GetDate'
import { ETagTypeByMessageStatus } from './types'
import Tag from '../../atoms/Tag'
import { StyledHistoryWrapper } from './styled'
import { rowsPerPage } from '../../../../config/constants'
import { Pagination } from '../../organisms'
import { useGetDocumentsListQuery } from '../../../../redux/documents/documentsApi'

const tableDesktopWidth = 1160

const columns = [
  {
    title: 'Дата',
    width: getTableCellWidthPercent(353, tableDesktopWidth),
    id: 'date',
    render: (_, row) => formatDateAndTime({ date: row?.date, time: row?.time }),
  },
  {
    title: 'ID',
    width: getTableCellWidthPercent(121, tableDesktopWidth),
    id: 'id',
  },
  {
    title: 'Тип',
    width: '144px',
    id: 'type',
    render: (_, row) => row?.typeName,
  },
  {
    title: 'Тема',
    width: getTableCellWidthPercent(291, tableDesktopWidth),
    id: 'subject',
  },
  {
    showAlways: true,
    title: 'Статус',
    id: 'status',
    width: '204px',
    render: (_, row) => (
      <Tag type={ETagTypeByMessageStatus[row?.statusUid?.toLowerCase()]}>
        {row?.statusName}
      </Tag>
    ),
  },
]

const MessageHistory = () => {
  const history = useHistory()

  const size = useSizeContext()

  const isDesktop = theme.bp.isMD(size.width)

  const [page, setPage] = useState(0)

  const { data: historyData } = useGetDocumentsListQuery('UsersRequests')

  const onRowClickHandler = ({ id }) => {
    if (id) {
      history.push(`/feedback/message/${id}`)
    }
  }

  return (
    <StyledHistoryWrapper>
      <div style={{
        marginBottom: '28px',
      }}
      >
        {!isDesktop ? (
          <div>
            {(historyData || [])?.map?.((data) => (
              <MessageHistoryCard
                key={data?.id}
                onClick={onRowClickHandler}
                {...data}
              />
            ))}
          </div>
        ) : (
          <TableNew
            columns={columns}
            rows={(historyData || [])?.slice(page * rowsPerPage, (page + 1) * rowsPerPage) || []}
            onRowClick={onRowClickHandler}
          />
        )}
      </div>
      {isDesktop && (
        <Pagination
          total={(historyData || [])?.length}
          current={page + 1}
          onChange={(currentPage) => setPage(currentPage - 1)}
          pageSize={rowsPerPage}
        />
      )}
    </StyledHistoryWrapper>
  )
}

export default MessageHistory

import { getYear } from 'date-fns'

const DEFAULT_YEAR_ITEM_NUMBER = 12

export const getMonthName = (num) => {
  const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
  return months[num]
}

export const getShortMonthName = (num) => {
  const months = ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек']
  return months[num]
}

export const getYearsPeriod = (
  date,
  yearItemNumber = DEFAULT_YEAR_ITEM_NUMBER,
) => {
  const endPeriod = Math.ceil(getYear(date) / yearItemNumber) * yearItemNumber
  const startPeriod = endPeriod - (yearItemNumber - 1)
  return { startPeriod, endPeriod }
}

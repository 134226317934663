import React from 'react'
import { ReactComponent as PortfolioIcon } from '../../assets/images/portfolio.svg'
import { ReactComponent as SyncIcon } from '../../assets/images/sync.svg'
import { ReactComponent as TradeIcon } from '../../assets/images/trade.svg'
import { ReactComponent as TrendingUpIcon } from '../../assets/images/trending-up.svg'
import { ReactComponent as DocumentIcon } from '../../assets/images/document-icon.svg'
import { ReactComponent as ListIcon } from '../../assets/images/list.svg'
import { ReactComponent as MessageIcon } from '../../assets/images/message.svg'
import { ReactComponent as AppealIcon } from '../../assets/images/appeal.svg'
import { ReactComponent as MessagesIcon } from '../../assets/images/messages.svg'
import { ReactComponent as UserIcon } from '../../assets/images/user.svg'
import { ReactComponent as ExitIcon } from '../../assets/images/exit.svg'

const tradeToggler = process.env.REACT_APP_TRADE_TOGGLER || 'show'

export const navList = [
  {
    title: 'Портфель',
    link: '/portfolio',
    icon: <PortfolioIcon className="portfolio-icon" />,
    id: 'portfolio',
  },
  {
    title: 'Поручения',
    link: '/transfers',
    icon: <SyncIcon className="sync-icon" />,
    id: 'transfers',
  },
  {
    title: 'Торговля',
    link: '/trades',
    icon: <TradeIcon className="trade-icon" />,
    id: 'trades',
  },
  {
    title: 'Сканер',
    link: '/scaner',
    icon: <TrendingUpIcon className="trending-icon" />,
    id: 'scaner',
  },
  {
    title: 'Документы',
    link: '/documents',
    icon: <DocumentIcon className="document-icon" />,
    id: 'documents',
  },
  {
    title: 'Отчеты',
    link: '/reports',
    icon: <ListIcon className="list-icon" />,
    id: 'reports',
  },
  {
    title: 'Обращения',
    link: '/feedback',
    icon: <AppealIcon />,
    id: 'feedback',
  },
  {
    title: 'Контакты',
    link: '/contacts',
    icon: <MessageIcon className="message-icon" />,
    id: 'contacts',
  },
].filter((item) => (tradeToggler === 'hide' ? item.link !== '/trades' : item))

export const actionsList = [
  {
    title: 'Профиль',
    link: '/profile',
    icon: <UserIcon />,
  },
  {
    title: 'Документы на подпись',
    link: '/sign',
    icon: <MessagesIcon />,
    hasBadge: true,
  },
  {
    title: 'Выйти/сменить пользователя',
    link: '/logout',
    icon: <ExitIcon />,
  },
]

export default {
  typography: {
    fontFamily: [
      'Open Sans',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 577,
      lg: 578,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
}

import React from "react";
import {ThemeProvider} from "styled-components";
import {useSize} from "../../../../hooks/useSize";
import { SizeContext } from 'context/SizeContext'
import {MainLayout} from "../../templates/MainLayout";
import Sign from "./Sign";
import {theme} from 'ui/theme';




export const SignNew: React.FC =() => {
  const size = useSize()
  return (
    <ThemeProvider theme={theme}>

      <SizeContext.Provider value={size}>
        <MainLayout title="Документы на подпись">
          <Sign/>
        </MainLayout>
      </SizeContext.Provider>
    </ThemeProvider>
  )
}

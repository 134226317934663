import useInput from 'hooks/useInput';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { selectConnectionState } from 'redux/connection/selector';
import { selectCurrentError } from 'redux/error/selector';
import { clearMetadata, fetchMetadataStartAsync, getMetadataSuccess } from 'redux/metadata/metadataSlice';
import { selectCurrentMetadata } from 'redux/metadata/selector';
import {
  selectBalancesMoney,
  selectCurrentDeleteMoney,
  selectGetTransferMoney,
  selectPerformTransferMoney, selectPerformTransferMoneyStatus,
  selectSaveTransferMoney, selectSaveTransferMoneyStatus
} from 'redux/transfers/money/selector';
import {
  clearPartTransferMoney,
  clearTransferMoney,
  deleteMoneySuccess, fetchDeleteMoneyStartAsync,
  fetchGetBalancesMoneyStartAsync,
  fetchPerformTransferMoneyStartAsync,
  fetchSaveTransferMoneyStartAsync,
  fetchTransferMoneyStartAsync,
  getBalancesMoneySuccess,
  getTransferMoneySuccess,
  performTransferMoneySuccess,
  saveTransferMoneySuccess
} from 'redux/transfers/money/transferMoneySlice';
import { currentUserLoginAndId, selectCurrentThumbprint } from 'redux/user/selector';
import { Button, TextField, Form, FormCol, FormFooter, FormFooterActions, FormRow, DateField, Field, PriceChangeCard, Select, CustomModal } from 'ui/components';
import { format } from 'date-fns';
import formAction from 'utils/FormAction';
import createUri from 'utils/FilterUri';
import { encodeToUTF8 } from 'utils/EncodeToUTF8';
import numberWithSpaces from 'utils/Money';
import getPrettyNumber from "../../../../../../utils/GetPrettyNumber";
import JsonToSHA256 from 'utils/JsonToSHA256';
import { BlockHeading } from 'ui/components/molecules/BlockHeading';
import { SMSConfirmModal } from '../modals/SMSConfim';
import { formatDateServer } from 'utils/GetDate';
import { TransferHeading } from '../parts/TransferHeading';
import { StyledFormRightCol, StyledPriceChangeCardWrapper } from '../../styled';
import { setConfirmStatusModal } from 'redux/ui/uiSlice'
import { sortArrayByOrder } from '../../../../../../utils/SortArrayByOrder'
import { clearError } from '../../../../../../redux/error/errorSlice'
import { useScrollTop } from "../../../../../../hooks/useScrollTop";
import moment from "moment";

const transferType = 'Orders.Type.NonTrade.Money.Transfer'
const cancelType = 'Orders.Type.Modify.Cancellation'

const currenciesOrder = ["RUB", "USD", "EUR", "CNY", "HKD"]

export const Transfer: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const scrollToTop = useScrollTop()

  const [clientPortfolio, setClientPortfolio] = useState('')
  const [partnerPortfolio, setPartnerPortfolio] = useState('')
  const [clientMoneyStorage, setClientMoneyStorage] = useState('')
  const [partnerMoneyStorage, setPartnerMoneyStorage] = useState('')
  const [arrayCurrencies, setArrayCurrencies] = useState([])
  const [currentCurrency, setCurrentCurrency] = useState('')
  const currentAmount = useInput('', { isOnlyNumbers: true })
  const [validity, setValidity] = useState(null)
  const [expirationDate, setExpirationDate] = useState(null)
  const [transferHeader, setTransferHeader] = useState('')
  const [commentary, setCommentary] = useState('')
  const [allDisabled, setAllDisabled] = useState(false)
  const [currentActions, setCurrentActions] = useState(null)
  const [showTimer, setShowTimer] = useState(false)
  const [code, setCode] = useState('')
  const [secretPassPhrase, setSecretPassPhrase] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [codeTextError, setCodeTextError] = useState('')
  const [currentStatus, setCurrentStatus] = useState<any>('')
  const [submitAction, setSubmitAction] = useState('')
  const urlParams = useParams<{ id?: string }>()
  const location = useLocation()
  const transferId = useMemo(() => urlParams?.id, [urlParams])
  const transferUid = useMemo(() => location?.state?.orderUid, [location])
  const [metadataUid, setMetadataUid] = useState(transferUid || transferType)
  const [formDisabled, setFormDisabled] = useState(false)

  const [arrayMinusPlatform, setArrayMinusPlatform] = useState([])
  const [arrayPlusPlatform, setArrayPlusPlatform] = useState([])
  const [activeId, setActiveId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [updModalOpen, setUpdModalOpen] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [amountError, setAmountError] = useState(false)
  const [clientMoneyStorageError, setClientMoneyStorageError] = useState(false)
  const [expirationDateError, setExpirationDateError] = useState(false)
  const [partnerMoneyStorageError, setPartnerMoneyStorageError] = useState(false)
  const [partnerPortfolioError, setPartnerPortfolioError] = useState(false)
  const [clientPortfolioError, setClientPortfolioError] = useState(false)
  const [currencyError, setCurrencyError] = useState(false)
  const [amountTextError, setAmountTextError] = useState('')
  const [clientMoneyStorageTextError, setClientMoneyStorageTextError] = useState('')
  const [expirationDateTextError, setExpirationDateTextError] = useState('')
  const [partnerMoneyStorageTextError, setPartnerMoneyStorageTextError] = useState('')
  const [partnerPortfolioTextError, setPartnerPortfolioTextError] = useState('')
  const [clientPortfolioTextError, setClientPortfolioTextError] = useState('')
  const [currencyTextError, setCurrencyTextError] = useState('')
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [clientBalances, setClientBalances] = useState(null)
  const [partnerBalances, setPartnerBalances] = useState(null)
  const [isCleared, setIsCleared] = useState(false)
  const [canModifyDate, setCanModifyDate] = useState(true)
  const [hashSha256, setHashSha256] = useState(null)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [copyStatus, setCopyStatus] = useState(false)
  const [copyData, setCopyData] = useState<{
    order: {},
    portfolios: {},
    validity: {}
  } | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [disableValidation, setDisableValidation] = useState(false)


  const setConfirmModalStatus = (status) => {
    dispatch(setConfirmStatusModal(status))
  }

  const fetchTransferMoneyStart = (id) => {
    dispatch(fetchTransferMoneyStartAsync({ id }))
  }

  const fetchSaveTransferMoneyStart = (data, id) => {
    dispatch(fetchSaveTransferMoneyStartAsync({ data, id }))
  }

  const fetchPerformTransferMoneyStart = (data, id) => {
    dispatch(fetchPerformTransferMoneyStartAsync({ data, id }))
  }

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchGetBalancesMoneyStart = (filterBody) => {
    dispatch(fetchGetBalancesMoneyStartAsync({ filterBody }))
  }

  const fetchDeleteTransferStart = (id) => {
    dispatch(fetchDeleteMoneyStartAsync({ id }))
  }

  const getRespTransferMoney = useSelector(selectGetTransferMoney)
  const getRespMetadata = useSelector(selectCurrentMetadata)
  const getSaveTransferMoney = useSelector(selectSaveTransferMoney)
  const statusRequestPerform = useSelector(selectPerformTransferMoneyStatus)
  const statusRequestSave = useSelector(selectSaveTransferMoneyStatus)
  const getPerformTransferMoney = useSelector(selectPerformTransferMoney)
  const getBalances = useSelector(selectBalancesMoney)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)
  const deleteMoneySelector = useSelector(selectCurrentDeleteMoney)

  const currentStatusDescription = getRespTransferMoney?.order?.statusDescription
    && sanitizeHtml(getRespTransferMoney?.order?.statusDescription)
  const todayDate = moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD')

  const clearFields = (needClearData = true) => {
    if (needClearData) dispatch(clearMetadata())
    dispatch(clearTransferMoney())
    setClientPortfolio('')
    setPartnerPortfolio('')
    setClientMoneyStorage('')
    setArrayMinusPlatform([])
    setPartnerMoneyStorage('')
    setArrayPlusPlatform([])
    setTransferHeader('')
    setCommentary('')
    setArrayCurrencies([])
    setCurrentCurrency('')
    currentAmount.setValue('')
    setExpirationDate('')
    setValidity(new Date())
    setAllDisabled(false)
    setCurrentActions(null)
    setAmountError(false)
    setClientMoneyStorageError(false)
    setClientPortfolioError(false)
    setExpirationDateError(false)
    setPartnerMoneyStorageError(false)
    setPartnerPortfolioError(false)
    setCurrencyError(false)
    setCodeError(false)
    setClientBalances(null)
    setIsCleared(true)
    setCode('')
    setSecretPassPhrase('')
  }

  const fillFields = (order: any, portfolios: any) => {
    const expirationDate = getRespTransferMoney?.order?.expirationDate;
    const isActualDate = expirationDate < todayDate ? todayDate : expirationDate

    if (Object.keys(portfolios)?.length) {
      const licenseMinus = portfolios?.find((item) => item.name === order?.clientPortfolio?.name)
      setArrayMinusPlatform(licenseMinus?.moneyStorages)
      setArrayCurrencies(licenseMinus?.currencies)
      const licensePlus = portfolios?.find((item) => item.name === order?.partnerPortfolio?.name)
      setArrayPlusPlatform(licensePlus?.moneyStorages)
    } else {
      setArrayMinusPlatform([order?.partnerMoneyStorage])
      setArrayPlusPlatform([order?.clientMoneyStorage])
      setArrayCurrencies([{ uid: order?.currency?.uid }])
    }
    setClientPortfolio(order?.clientPortfolio?.name)
    setPartnerPortfolio(order?.partnerPortfolio?.name)
    setClientMoneyStorage(order?.clientMoneyStorage?.description)
    setPartnerMoneyStorage(order?.partnerMoneyStorage?.description)
    setCurrentCurrency(order?.currency?.uid)
    currentAmount.setValue(order?.amount)
    setCommentary(order?.comment)
    setExpirationDate(isActualDate)
    setValidity(isActualDate)
    // eslint-disable-next-line no-underscore-dangle
    if (!order?._links[0]?.canModify) {
      setAllDisabled(true)
      setCanModifyDate(false)
    }
  }

  const handleBackToHistory = () => {
    history.push('/transfers/money/log')
  }

  useEffect(() => {

    clearFields()

    if (transferId === 'new') {
      setMetadataUid(transferType)
      setActiveId(null)

      return
    }

    if (!transferId) return;

    setActiveId(transferId)

    scrollToTop()
  }, [transferId])



  useEffect(() => {
    if (transferId === 'new') {
      fetchMetadataStart(transferType)
      setIsLoading(false)

      return
    }

    if (!transferId) return;

    fetchMetadataStart(metadataUid)
    fetchTransferMoneyStart(transferId)



  }, [transferId])


  // useEffect(() => {
  //
  //   fetchTransferMoneyStart(transferId)
  //
  // }, [transferId])



  useEffect(() => () => {
    // eslint-disable-next-line no-console
    dispatch(clearError())
    clearFields()
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  useEffect(() => {
    if (isCleared) {
      if (getRespTransferMoney?.order) {
        setIsLoading(false)
        setCurrentStatus(getRespTransferMoney?.order?.status)
        let order = null
        if (getRespTransferMoney?.order?.type?.uid === transferType) {
          order = getRespTransferMoney?.order
          if (getRespMetadata?.references && Object.keys(getRespMetadata.references).length) {
            const portfolios = getRespMetadata?.references?.portfolios
            fillFields(order, portfolios)
          }
          if (getRespMetadata?.order?.type?.uid === cancelType) {
            const cancelDate = getRespMetadata?.order?.expirationDate
            const reqObj = {
              type: { uid: cancelType },
              expirationDate: cancelDate && formatDateServer(new Date(cancelDate)),
              affectedOrder: { id: +activeId },
              secret: '',
            }

            const date = getRespMetadata?.order?.date
            const number = getRespMetadata?.order?.number

            // @ts-ignore
            reqObj.date = date
            // @ts-ignore
            reqObj.number = number

            const WordArrayToBase64 = JsonToSHA256(reqObj)
            if (WordArrayToBase64) {
              reqObj.secret = WordArrayToBase64
              setHashSha256(WordArrayToBase64)
            }

            fetchPerformTransferMoneyStart(reqObj, null)
          }
        } else if (getRespTransferMoney?.order?.type?.uid === cancelType) {
          order = getRespTransferMoney?.order?.affectedOrder
          fillFields(order, [])
          // eslint-disable-next-line no-underscore-dangle
          if (getRespTransferMoney?.order?._links[0]?.canModify) {
            setCanModifyDate(true)
          }
        }
        // eslint-disable-next-line no-underscore-dangle
        setCurrentActions(getRespTransferMoney?.order?._links[0])
        setTransferHeader(`${getRespTransferMoney?.order?.description}`)
      } else if (transferId !== 'new') {
        setIsLoading(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRespTransferMoney, getRespMetadata, isCleared])

  useEffect(() => {
    if (isCleared) {
      if (getSaveTransferMoney?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveTransferMoney?.order?.id)
        }
        if (getSaveTransferMoney?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
      }
      if (getPerformTransferMoney?.requiredAction) {
        if (!activeId || +activeId !== getPerformTransferMoney?.order?.id) {
          setActiveId(getPerformTransferMoney?.order?.id)
        }
        if (getPerformTransferMoney?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }

        setCurrentStatus(getPerformTransferMoney?.order?.status)
      }
      const msgData = getSaveTransferMoney || getPerformTransferMoney
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespTransferMoney?.order?.type?.uid === cancelType) ? cancelType : transferType
      if (msgData?.order?.description) {
        setTransferHeader(`${msgData?.order?.description}`)
      }
      if (typeUID) {
        setIsLoading(false)
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(msgData?.order?._links[0])
      if (msgData?.order?.type?.uid === cancelType) {
        if (msgData?.order?.type?.uid === cancelType
          && getRespTransferMoney?.order?.type?.uid !== cancelType
        ) {
          const cancelDate = getRespMetadata?.order?.expirationDate
          setExpirationDate(cancelDate)
          setValidity(cancelDate)
        }
      }

      if (statusRequestSave === 200 || statusRequestPerform === 200) {
        switch (getSaveTransferMoney?.order?.status?.uid || getPerformTransferMoney?.order?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (getSaveTransferMoney?.order?.id) {
              history.replace(`/transfers/money/transfer/${getSaveTransferMoney?.order?.id}`)
              setAllDisabled(false)
              setFormDisabled(false)
            }
            if (activeId) {
              setUpdModalOpen(true)
            } else {
              if (submitAction !== 'save') {
                setSaveModalOpen(true)
              }
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaveTransferMoney, getPerformTransferMoney, isCleared])

  useEffect(() => {
    if (serverError) {
      if (serverError?.Amount?.length) {
        setAmountError(true)
        setAmountTextError(serverError?.Amount[0])
      }
      if (serverError?.ClientMoneyStorage?.length) {
        setClientMoneyStorageError(true)
        setClientMoneyStorageTextError(serverError?.ClientMoneyStorage[0])
      }
      if (serverError?.ClientPortfolio?.length) {
        setClientPortfolioError(true)
        setClientPortfolioTextError(serverError?.ClientPortfolio[0])
      }
      if (serverError?.ExpirationDate?.length) {
        setExpirationDateError(true)
        setExpirationDateTextError(serverError?.ExpirationDate[0])
      }
      if (serverError?.PartnerMoneyStorage?.length) {
        setPartnerMoneyStorageError(true)
        setPartnerMoneyStorageTextError(serverError?.PartnerMoneyStorage[0])
      }
      if (serverError?.PartnerPortfolio?.length) {
        setPartnerPortfolioError(true)
        setPartnerPortfolioTextError(serverError?.PartnerPortfolio[0])
      }
      if (serverError?.Currency?.length) {
        setCurrencyError(true)
        setCurrencyTextError(serverError?.Currency[0])
      }
      if (serverError?.Secret?.length) {
        setCodeError(true)
        setCodeTextError(serverError?.Secret[0])
      }
      const msgData = getSaveTransferMoney || getPerformTransferMoney
      if ((!currentActions || currentActions?.canModify) && !msgData?.requiredAction?.type) {
        setAllDisabled(false)
      }
      setIsLoading(false)
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError])

  const copyTransfer = () => {
    if (copyData !== null) {
      fillFields(copyData.order, copyData.portfolios)
      setValidity(copyData.validity)
    }

    setAllDisabled(false)
    setCanModifyDate(true)

    setCopyStatus(false)
    setCopyData(null)
  }

  const preCopy = () => {
    setCopyData({
        order: {
          ...getRespTransferMoney?.order,
          amount: numberWithSpaces(getRespTransferMoney?.order?.amount)
        },
        portfolios: getRespMetadata?.references?.portfolios,
        validity
      })

    history.replace('/transfers/money/transfer/new')

    setMetadataUid(transferType)
    fetchMetadataStart(transferType)
    setIsLoading(false)
    setActiveId(null)

    clearFields();

    setCopyStatus(true)
  }

  useEffect(() => {
    if (getRespMetadata && copyStatus) {
      copyTransfer();
    }
  }, [copyStatus, getRespMetadata])

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value;
    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const listMainFields = () => {
    let licenseMinus = null
    let licensePlus = null
    let moneyStorageMinus = null
    let moneyStoragePlus = null
    if (transferUid === cancelType) {
      licenseMinus = getRespTransferMoney?.order?.affectedOrder?.clientPortfolio
      licensePlus = getRespTransferMoney?.order?.affectedOrder?.partnerPortfolio
      const [clientMoneyStorageObj] = arrayMinusPlatform as Array<any>
      const [partnerMoneyStorageObj] = arrayPlusPlatform as Array<any>
      moneyStorageMinus = clientMoneyStorageObj
      moneyStoragePlus = partnerMoneyStorageObj
    } else {
      licenseMinus = getRespMetadata?.references?.portfolios?.find(
        (item) => item.name === clientPortfolio,
      )
      licensePlus = getRespMetadata?.references?.portfolios?.find(
        (item) => item.name === partnerPortfolio,
      )
      moneyStorageMinus = licenseMinus?.moneyStorages?.find(
        (item) => item.description === clientMoneyStorage,
      )
      moneyStoragePlus = licensePlus?.moneyStorages?.find(
        (item) => item.description === partnerMoneyStorage,
      )
    }
    const obj = {
      clientPortfolio: licenseMinus,
      partnerPortfolio: licensePlus,
      clientMoneyStorage: moneyStorageMinus,
      partnerMoneyStorage: moneyStoragePlus,
    }
    return obj
  }

  useEffect(() => {
    if (getBalances) {
      const mainFields = listMainFields()
      const hasClientPortfolioId = getBalances?.portfolio?.id === mainFields?.clientPortfolio?.id
      const hasPartnerPortfolioId = getBalances?.portfolio?.id === mainFields?.partnerPortfolio?.id
      const hasStorageUid = getBalances?.storage?.uid

      if (!getBalances?.portfolio) return
      if (hasClientPortfolioId && (
        hasStorageUid ? mainFields?.clientMoneyStorage?.uid === getBalances?.storage?.uid
          : !mainFields?.clientMoneyStorage?.uid)) {
        setClientBalances(getBalances)
      }

      if (hasPartnerPortfolioId && (
        hasStorageUid ? mainFields?.partnerMoneyStorage?.uid === getBalances?.storage?.uid
          : !mainFields?.partnerMoneyStorage?.uid)) {
        setPartnerBalances(getBalances)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBalances])

  const fetchBalancesMoney = (filterBody, setBalances) => {
    if (filterBody) {
      fetchGetBalancesMoneyStart(filterBody.replaceAll('+', ''))
    }
    setBalances(null)
  }

  useMemo(() => {
    const objMainFields = listMainFields()
    let filterBodyClient
    if (clientPortfolio || clientMoneyStorage) {
      filterBodyClient = createUri({
        name: 'balancesMoney',
        portfolioId: objMainFields?.clientPortfolio?.id,
        currencyUid: currentCurrency,
        storageUid: objMainFields?.clientMoneyStorage?.uid,
        condition: '&',
      })
      fetchBalancesMoney(filterBodyClient, setClientBalances)
    }
    setClientBalances(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientPortfolio, clientMoneyStorage, currentCurrency])

  useEffect(() => {
    const objMainFields = listMainFields()
    let filterBodyPartner
    if (partnerPortfolio || partnerMoneyStorage) {
      filterBodyPartner = createUri({
        name: 'balancesMoney',
        portfolioId: objMainFields?.partnerPortfolio?.id,
        currencyUid: currentCurrency,
        storageUid: objMainFields?.partnerMoneyStorage?.uid,
        condition: '&',
      })
      fetchBalancesMoney(filterBodyPartner, setPartnerBalances)
    }
    setPartnerBalances(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerPortfolio, partnerMoneyStorage, currentCurrency])

  const addNeedsProps: any = (typeUID, msgData) => {
    const mainFields = listMainFields()
    let reqObj = {}
    if (typeUID === cancelType) {
      reqObj = {
        type: { uid: typeUID },
        expirationDate: formatDateServer(new Date(validity)),
        affectedOrder: {
          id: msgData?.order?.affectedOrder?.id
            || getRespTransferMoney?.order?.affectedOrder?.id,
        },
        secret: '',
      }
      return reqObj
    }
    reqObj = {
      type: { uid: typeUID },
      dateTime: formatDateServer(new Date()),
      expirationDate: formatDateServer(new Date(validity)),
      clientPortfolio: { id: mainFields.clientPortfolio?.id },
      clientMoneyStorage: { uid: mainFields.clientMoneyStorage?.uid },
      partnerPortfolio: { id: mainFields.partnerPortfolio?.id },
      partnerMoneyStorage: { uid: mainFields.partnerMoneyStorage?.uid },
      comment: commentary,
      currency: { uid: currentCurrency },
      amount: +String(currentAmount.value).replace(/ /g, ''),
      secret: '',
    }
    return reqObj
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const msgData = getSaveTransferMoney || getPerformTransferMoney
    const typeUID = msgData?.requiredAction && msgData?.order?.type?.uid === cancelType
      ? cancelType : transferType
    const requestObj: any = addNeedsProps(typeUID, msgData)

    const date = getRespMetadata?.order?.date
    const number = getRespMetadata?.order?.number

    requestObj.date = date
    requestObj.number = number

    const WordArrayToBase64 = JsonToSHA256(requestObj)
    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHashSha256(WordArrayToBase64)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID,
      putPerformData: fetchPerformTransferMoneyStart,
      postPerformData: fetchPerformTransferMoneyStart,
      activeId,
      secretPassPhrase,
    })
  }

  const handleChangePortfolioMinus = (option) => {
    setClientPortfolio(option?.value)
    setClientMoneyStorage('')
    const currentObj = getRespMetadata?.references?.portfolios?.find(
      (elm) => elm.name === option?.value,
    )
    if (currentObj?.moneyStorages?.length) {
      setArrayMinusPlatform(currentObj?.moneyStorages)
    } else {
      setArrayMinusPlatform([])
    }
    setArrayCurrencies(currentObj?.currencies)
    setClientPortfolioError(false)
  }

  const handleChangePortfolioPlus = (option) => {
    setPartnerPortfolio(option?.value)
    setPartnerMoneyStorage('')
    const currentObj = getRespMetadata?.references?.portfolios?.find(
      (elm) => elm.name === option?.value,
    )
    if (currentObj?.moneyStorages?.length) {
      setArrayPlusPlatform(currentObj.moneyStorages)
    } else {
      setArrayPlusPlatform([])
    }
    setClientMoneyStorageError(false)
  }

  const handleChangePlatformMinus = (option) => {
    setClientMoneyStorage(option?.value)
    setPartnerPortfolioError(false)
  }

  const handleChangePlatformPlus = (option) => {
    setPartnerMoneyStorage(option?.value)
    setPartnerMoneyStorageError(false)
  }

  const handleChangeCurrency = (option) => {
    setCurrentCurrency(option?.value)
    setCurrencyError(false)
  }

  const handleChangeValidity = (date) => {
    setValidity(date)
    setExpirationDateError(false)
  }

  const handleChangeCommentary = (event) => {
    setCommentary(event.target.value)
  }

  const handlePerformTransfer = (event) => {
    event.preventDefault()
      setFormDisabled(true)
      setIsLoading(true)

      if (submitAction === 'perform') {
        setAllDisabled(true)
      }
      const msgData = getSaveTransferMoney || getPerformTransferMoney
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespTransferMoney?.order?.type?.uid === cancelType) ? cancelType : transferType
      const reqObj = addNeedsProps(typeUID, msgData)

      const date = getRespMetadata?.order?.date
      const number = getRespMetadata?.order?.number

      reqObj.date = date
      reqObj.number = number

      if (submitAction !== 'save') {
        const WordArrayToBase64 = JsonToSHA256(reqObj)
        if (WordArrayToBase64) {
          reqObj.secret = WordArrayToBase64
          setHashSha256(WordArrayToBase64)
        }
      }

      formAction({
        submitAction,
        requestObj: reqObj,
        typeUID,
        msgData,
        putPerformData: fetchPerformTransferMoneyStart,
        postPerformData: fetchPerformTransferMoneyStart,
        putSaveData: fetchSaveTransferMoneyStart,
        postSaveData: fetchSaveTransferMoneyStart,
        activeId,
        code,
        setCodeError,
        setCodeTextError,
        setCancellationModalOpen,
        setFormDisabled,
        setIsLoading,
        currentThumbPrint,
        secretPassPhrase,
        disableValidation
      })
      setDisableValidation(false)
  }

  const handleCancelOperation = (event) => {
    if (getRespMetadata?.order?.type?.uid === cancelType) { handleBackToHistory() } else {
      event.preventDefault()
      dispatch(clearPartTransferMoney())
      setAllDisabled(false)
      setFormDisabled(false)
  }
  }

  const handleDownloadXml = () => {
    const msgData = getSaveTransferMoney || getPerformTransferMoney
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.order?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const createMarkup = () => (
    { __html: currentStatusDescription }
  )

  const getHandler = (handler) => (!allDisabled ? handler : null)


  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const handleDeletedOrder = () => {
    if (transferId) {
      setDeleteModalOpen(true)
    }
  }

  useEffect(() => {
    if (deleteMoneySelector?.status === 200) {
      handleBackToHistory()
    }
  }, [deleteMoneySelector])

  const handleDeleteOk = () => {
    fetchDeleteTransferStart(transferId)
    setDeleteModalOpen(false)
  }


  const handleCloseSaveModal = (goToHistory = false) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseUpdModal = () => {
    setUpdModalOpen(false)
    handleBackToHistory()
  }

  const handleClosePerformModal = (goToHistory = false) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
    setIsLoading(false)
  }

  const handleCancelOk = () => {
    setMetadataUid(cancelType)
    fetchMetadataStart(cancelType)
    handleCloseCancellationModal()
  }

  const handleActionReject = (modalType) => {
    if (modalType === 'saveModal') {
      handleCloseSaveModal(false)
    }
    if (modalType === 'performModal') {
      handleClosePerformModal(true)
    }
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)
    clearFields(false)
    setActiveId(null)
    setFormDisabled(false)
    setMetadataUid(transferType)
    history.replace('/transfers/money/transfer/new')
  }

  /**
   * Отображать ли модальное окно подтверждения по запросу кода.
   */
  const isConfirmModalShow = () => (
    getSaveTransferMoney?.requiredAction?.type === 'code'
    || getPerformTransferMoney?.requiredAction?.type === 'code'
    || getSaveTransferMoney?.requiredAction?.type === 'mac'
    || getPerformTransferMoney?.requiredAction?.type === 'mac'
  )

  useEffect(() => {
    if (!isConfirmModalShow()) {
      setConfirmModalStatus(false)
    }

    if (isConfirmModalShow()  && showModalConfirm) {
      setConfirmModalStatus(true)
      setDisableValidation(true)
    }
  }, [getSaveTransferMoney, getPerformTransferMoney, showModalConfirm])

  const isStatusDraft = currentStatus?.uid === 'Draft'

  const handleCloseSmsModal = () => {
    setShowModalConfirm(false)
    setConfirmModalStatus(false)

    setCodeError(false)
    dispatch(clearError())

    setCodeTextError('')
    setCode('')
  }
  const draftStatus = getRespTransferMoney?.order?.status?.uid == 'Draft';

  return <>
    <TransferHeading
      transferHeader={transferHeader}
      isRemoval={isStatusDraft}
      isDownload
      deletedOrderCall = {handleDeletedOrder}
    />
    <Form onSubmit={handlePerformTransfer}>
      <FormRow>
        <FormCol flex={1}>
          <FormRow>
            <Field
              label="Портфель списания"
              hasError={clientPortfolioError}
              caption={clientPortfolioError ? clientPortfolioTextError : ''}>
              <Select
                options={(
                  getRespMetadata?.references?.portfolios?.map((option) => option.name)
                  || [getRespTransferMoney?.order?.affectedOrder?.clientPortfolio?.name]
                )?.map(option => ({ label: option, value: option }))
                }
                value={clientPortfolio || null}
                placeholder="Введите текст или выберите из списка"
                isDisabled={allDisabled}
                onChange={getHandler(handleChangePortfolioMinus)}
              />
            </Field>
            <Field
              label="Площадка списания"
              hasError={partnerPortfolioError}
              caption={partnerPortfolioError ? partnerPortfolioTextError : ''}
            >
              <Select
                options={(!!arrayMinusPlatform?.length && arrayMinusPlatform?.map((item) => (
                  ({
                    label: item.description,
                    value: item.description
                  })
                )))}
                value={clientMoneyStorage || null}
                placeholder="Выберите из списка"
                isDisabled={allDisabled}

                onChange={getHandler(handleChangePlatformMinus)}
              />
            </Field>
          </FormRow>
        </FormCol>
        <StyledFormRightCol>
          <StyledPriceChangeCardWrapper>
            <PriceChangeCard
              plain
              label={`Доступно на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
              value={((clientBalances && clientBalances.balanceT0 >= 0) && `${getPrettyNumber(clientBalances.balanceT0)} ${clientBalances.currency?.uid}`) || ('00,00 RUB')}
            />
          </StyledPriceChangeCardWrapper>
        </StyledFormRightCol>
      </FormRow>
      <FormRow>
        <FormCol flex={1}>
          <FormRow>
            <Field
              label="Портфель зачисления"
              hasError={clientMoneyStorageError}
              caption={clientMoneyStorageError ? clientMoneyStorageTextError : ''}
            >
              <Select
                options={(
                  getRespMetadata?.references?.portfolios?.map((option) => option.name)
                  || [getRespTransferMoney?.order?.affectedOrder?.partnerPortfolio?.name]
                )?.map(option => ({ label: option, value: option }))}
                value={partnerPortfolio ? { label: partnerPortfolio, value: partnerPortfolio } : null}
                placeholder="Введите текст или выберите из списка"
                isDisabled={allDisabled}
                onChange={getHandler(handleChangePortfolioPlus)}
              />
            </Field>
            <Field
              label="Площадка зачисления"
              caption={partnerMoneyStorageError ? partnerMoneyStorageTextError : ''}
              hasError={partnerMoneyStorageError}
            >
              <Select
                options={(arrayPlusPlatform?.map(option => ({ label: option.description, value: option.description })))}
                value={partnerMoneyStorage ? { label: partnerMoneyStorage, value: partnerMoneyStorage } : null}
                placeholder="Выберите из списка"
                isDisabled={allDisabled}
                onChange={getHandler(handleChangePlatformPlus)}
              />
            </Field>
          </FormRow>
        </FormCol>
        <StyledFormRightCol>
          <StyledPriceChangeCardWrapper>
            <PriceChangeCard
              plain
              label={`Баланс на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
              value={((partnerBalances && partnerBalances.balanceT0 >= 0) && `${getPrettyNumber(partnerBalances.balanceT0)} ${partnerBalances.currency?.uid}`) || ('00,00 RUB')}
            />
          </StyledPriceChangeCardWrapper>
        </StyledFormRightCol>
      </FormRow>
      {((clientPortfolio && partnerPortfolio
        && clientMoneyStorage && partnerMoneyStorage) &&
        <>
          <FormRow>
            <FormCol flex={1}>
              <FormRow>
                <Field
                  label="Валюта"
                  hasError={currencyError}
                  caption={currencyError ? currencyTextError : ''}
                >
                  <Select
                    options={sortArrayByOrder(arrayCurrencies || [], currenciesOrder, 'uid').map((item) => ({ label: item.uid, value: item.uid }))}
                    value={currentCurrency || null}
                    placeholder="Выберите из списка"
                    isDisabled={allDisabled}
                    onChange={getHandler(handleChangeCurrency)}
                    hasError={currencyError}
                  />
                </Field>
                <Field
                  label="Сумма"
                  hasError={amountError}
                  caption={amountError ? amountTextError : ''}
                >
                  <TextField
                    placeholder="Введите сумму"
                    value={numberWithSpaces(currentAmount.value) || ''}
                    onChange={(e) => {
                      currentAmount.onChange(e)
                      setAmountError(false)
                    }}
                    disabled={allDisabled}
                    hasError={amountError}
                    type="text"
                    inputMode="decimal"
                  />
                </Field>
              </FormRow>
            </FormCol>
            <StyledFormRightCol>
              {
                (clientPortfolio && partnerPortfolio
                  && clientMoneyStorage && partnerMoneyStorage) &&
                <FormRow>
                  <Field
                    label="Срок действия поручения до"
                    hasError={expirationDateError}
                    caption={expirationDateError ? expirationDateTextError : ''}
                  >
                    <DateField
                      placeholder=""
                      value={validity || ''}
                      onChange={handleChangeValidity}
                      minDate={todayDate}
                      maxDate={allDisabled && !canModifyDate ? validity : undefined}
                    />
                  </Field>
                </FormRow>
              }
            </StyledFormRightCol>
          </FormRow>
          <FormRow>
            <FormCol flex={1}>
              <Field label="Комментарий">
                <TextField
                  placeholder="Введите текст"
                  value={commentary || ''}
                  onChange={handleChangeCommentary}
                  disabled={allDisabled}
                />
              </Field>
            </FormCol>
            <StyledFormRightCol />
          </FormRow>
        </>
      )}
      {
        partnerMoneyStorage &&
        <FormFooter>
          <FormFooterActions>
            {getRespTransferMoney?.order && (
              <BlockHeading title={`Статус: ${currentStatus?.name}`} caption={currentStatusDescription ? <div dangerouslySetInnerHTML={createMarkup()} /> : ''} />
            )}
            {
              !allDisabled && !getRespTransferMoney?.order ?
                <Button variant='tertiary' onClick={() => clearFields(false)} type={'button'}>Очистить форму</Button>
                : null
            }

          </FormFooterActions>
          <FormFooterActions>
            {
              ((currentActions && currentActions?.canModify) || !currentActions)
              && (!getSaveTransferMoney?.requiredAction?.type
                && !getPerformTransferMoney?.requiredAction?.type
              ) &&
              <>
                <Button
                  variant='primary'
                  type={'submit'}
                  onClick={() => {
                    setSubmitAction('perform')
                    setShowModalConfirm(true)
                  }}
                  disabled={formDisabled}
                >
                  Исполнить
                </Button>
                {getRespMetadata?.order?.type?.uid !== cancelType && (
                  <Button
                    variant='secondary'
                    onClick={draftStatus ? preCopy : () => {
                      setSubmitAction('save')
                    }}
                    type={'submit'}
                  >
                    {draftStatus ? 'Скопировать' : 'Сохранить в черновики'}
                  </Button>
                )}
                {
                  draftStatus && (
                    <Button
                      variant='secondary'
                      type='submit'
                      onClick={() => { setSubmitAction('save') }}
                      disabled={formDisabled}
                    >
                      Сохранить
                    </Button>
                  )
                }
              </> ||
              (getSaveTransferMoney?.requiredAction?.type === 'sign' || getPerformTransferMoney?.requiredAction?.type === 'sign') &&
              <>
                <Button
                  variant='primary'
                  type={'submit'}
                  onClick={() => {
                    setSubmitAction('perform')
                    setShowModalConfirm(true)
                  }}
                >
                  Подписать
                </Button>
                <Button
                  variant='primary'
                  type={'button'}
                  onClick={handleDownloadXml}
                >Скачать .xml</Button>
                <Button
                  variant='secondary'
                  type={'button'}
                  onClick={handleCancelOperation}
                >Отменить</Button>
              </> ||
              (getSaveTransferMoney?.requiredAction?.type === 'code' || getPerformTransferMoney?.requiredAction?.type === 'code' || getSaveTransferMoney?.requiredAction?.type === 'mac' || getPerformTransferMoney?.requiredAction?.type === 'mac') &&
              <>
                <Button
                  variant='primary'
                  type={'submit'}
                  onClick={() => {
                    setSubmitAction('perform')
                    setShowModalConfirm(true)
                  }}
                >Подтвердить
                </Button>
                <Button
                  variant='secondary'
                  type={'button'}
                  onClick={handleCancelOperation}
                >
                  Отменить
                </Button>
              </>
            }
            {
              (currentActions && currentActions?.canCancel) &&
              <Button
                variant='primary'
                onClick={() => {
                  setCancellationModalOpen(true)
                  setShowModalConfirm(true)
                }}
                type={'button'}
              >
                Отменить поручение
              </Button>
            }
            {
              (getRespTransferMoney?.order?.status?.uid === 'Sent' ||
                getRespTransferMoney?.order?.status?.uid === 'Signed' ||
                getRespTransferMoney?.order?.status?.uid === 'Received' ||
                getRespTransferMoney?.order?.status?.uid === 'Executed' ||
                getRespTransferMoney?.order?.status?.uid === 'Cancelled' ||
                getRespTransferMoney?.order?.status?.uid === 'Rejected' ||
                getRespTransferMoney?.order?.status?.uid === 'Processing'
              ) &&
              getRespMetadata?.order?.type?.uid !== cancelType &&
              <Button
                variant='secondary'
                onClick={preCopy}
                type={'button'}
              >
                Скопировать
              </Button>
            }
            {currentStatus?.uid && (
              <Button
                variant='secondary'
                onClick={handleBackToHistory}
              >
                Назад
              </Button>
            )}
          </FormFooterActions>
        </FormFooter>
      }

      <SMSConfirmModal
        isShowImmitPaste
        title={  (getSaveTransferMoney?.order?.type?.uid === cancelType)
          ? "Подтвердите создание поручения на отмену"
          : "Подтвердите создание поручения"}
        handleResetTimer={handleResetTimer}
        handleStartTimer={handleStartTimer}
        hashSha256={hashSha256}
        showTimer={showTimer}
        code={code}
        codeError={codeError}
        setCodeError={setCodeError}
        setCodeTextError={setCodeTextError}
        codeTextError={codeTextError}
        isOpen={ isConfirmModalShow()  && showModalConfirm }
        handleClose={handleCloseSmsModal}
        handleChangeCodeInput={handleChangeCodeInput}
        handleSubmit={() => {
          setSubmitAction('perform');
          setConfirmModalStatus(true)
        }}
      />

    </Form>
    {/*{*/}
    {/*  (getSaveTransferMoney?.requiredAction?.type === 'code' || getPerformTransferMoney?.requiredAction?.type === 'code' || getSaveTransferMoney?.requiredAction?.type === 'mac' || getPerformTransferMoney?.requiredAction?.type === 'mac') && (*/}
    {/*    <SMSConfirmModal*/}
    {/*      title={'Подтвердите создание поручения'}*/}
    {/*      handleResetTimer={handleResetTimer}*/}
    {/*      handleStartTimer={handleStartTimer}*/}
    {/*      hashSha256={hashSha256}*/}
    {/*      showTimer={showTimer}*/}
    {/*      codeError={codeError}*/}
    {/*      codeTextError={codeTextError}*/}
    {/*      code={code}*/}
    {/*      handleChangeCodeInput={handleChangeCodeInput}*/}
    {/*      handleSubmit={(e) => {*/}
    {/*        setSubmitAction('perform');*/}
    {/*        handlePerformTransfer(e);*/}
    {/*      }}*/}
    {/*    />*/}
    {/*  )*/}
    {/*}*/}

    <CustomModal
      open={deleteModalOpen}
      title={`Удалить черновик поручения?`}
      actionText="Отменить это действие будет невозможно"
      textOk="Да, удалить"
      handleClose={handleCloseDeleteModal}
      callbacks={{
        withOk: handleDeleteOk,
        withCancel: handleCloseDeleteModal,
      }}
    />

    <CustomModal
      open={saveModalOpen}
      title="Поручение успешно создано."
      handleClose={handleCloseSaveModal}
      actionText="Хотите создать новое?"
      actionReject={() => handleActionReject('saveModal')}
      actionSuccess={handleActionSuccess}
      textOk="Да, создать"
      disableOverlay
    />
    <CustomModal
      open={updModalOpen}
      title="Поручение обновлено"
      handleClose={handleCloseUpdModal}
      closeInterval={1800}
    />
    <CustomModal
      open={performModalOpen}
      title="Поручение успешно подписано"
      handleClose={handleClosePerformModal}
      actionText="Хотите создать новое?"
      textOk="Да, создать"
      actionReject={() => handleActionReject('performModal')}
      actionSuccess={handleActionSuccess}
      disableOverlay
    />
    <CustomModal
      open={cancellationModalOpen}
      title="Создать поручение на отмену"
      handleClose={handleCloseCancellationModal}
      textOk="Да, создать"
      callbacks={{
        withOk: handleCancelOk,
        withCancel: () => {
          handleCloseCancellationModal()
        },
      }}
    />
  </>
}

import { styled } from "ui/theme";
import { ISwitchboxProps } from "./types";

export const StyledSwitchbox = styled.div<ISwitchboxProps>`
  cursor: pointer;
  background-color: ${({ checked, theme }) => checked ? theme.color.text.accent : theme.color.text.secondary};
  width: 46px;
  height: 24px;
  border-radius: 12px;
  padding: 2px;
  display: flex;
  justify-content: ${({ checked }) => checked ? 'flex-end' : 'flex-start'};
`

export const StyledSwitchboxToggler = styled.div<{ checked?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #fff;
`
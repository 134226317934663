import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  PageHeading: {
    position: 'fixed',
    zIndex: 1,
    top: 54,
    left: 46,
    boxSizing: 'border-box',
    width: 'calc(100% - 46px)',
    padding: '12px 10px 10px',
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#292D34',
  },
  PageHeading__text: {
    margin: 0,
    fontSize: 18,
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    color: theme.palette.type === 'light' ? '#313A4E' : '#fff',
  },
}))

export default useStyles

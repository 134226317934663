import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import { ReactComponent as DropdownIcon } from '../../assets/images/dropdown.svg'
import useStyles from './CustomControls.style'
import CustomInput from './CustomInput'
import useDisableBodyScroll from '../../hooks/useDisableBodyScroll'

const CustomSelect = ({
  label, error, errorText, required, unique,
  inputValue, onChangeInput, withInput, inFilter,
  readOnly, value, onChange, children, labelId,
  name, color, disabled, placeholder,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  useDisableBodyScroll(open)

  return (
    <FormControl
      error={error}
      variant="outlined"
      size="small"
      fullWidth
      className={classNames(
        classes.formControl,
        {
          [classes.uniqueFormControl]: unique,
          [classes.groupFormControl]: withInput,
        },
      )}
      required={required}
    >
      {label && (
        <FormLabel className={classes.inputLabel}>
          {label}
        </FormLabel>
      )}
      {withInput && (
        <CustomInput
          value={inputValue}
          onChange={onChangeInput}
          fullWidth
          variant="outlined"
          size="small"
          unique
          disabled={disabled}
        />
      )}
      <Select
        className={classes.selectField}
        IconComponent={DropdownIcon}
        readOnly={readOnly}
        labelId={labelId}
        placeholder={placeholder}
        MenuProps={{
          classes: {
            paper: classNames(
              classes.menuPaper,
              {
                [classes.menuPaperUnique]: unique,
                [classes.menuPaperFilter]: inFilter,
              },
            ),
            list: classes.menuList,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        color={color}
        name={name}
        value={value}
        onChange={onChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        required={required}
      >
        <MenuItem
          className={classes.selectField__outOfRange}
          value={value}
        >
          {value}
        </MenuItem>
        {unique && (
          <MenuItem value="">
            &nbsp;
          </MenuItem>
        )}
        {children}
      </Select>
      {error && (
        <FormHelperText>{errorText}</FormHelperText>
      )}
    </FormControl>
  )
}

CustomSelect.defaultProps = {
  label: null,
  error: false,
  errorText: '',
  children: null,
  required: false,
  unique: false,
  onChangeInput: () => {},
  inputValue: '',
  withInput: false,
  inFilter: false,
  readOnly: false,
  value: null,
  onChange: () => {},
  labelId: '',
  name: '',
  color: null,
  disabled: false,
  placeholder: 'Введите текст',
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  required: PropTypes.bool,
  unique: PropTypes.bool,
  onChangeInput: PropTypes.func,
  inputValue: PropTypes.string,
  withInput: PropTypes.bool,
  inFilter: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  labelId: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default CustomSelect

import { ReactNode } from 'react'
import { CalculatedData } from 'ui/components/pages/Portfolio/utils'

export const ACCORDION_VARIANTS_LIST = {
  PORTFOLIO: 'portfolio',
  PLAIN: 'plain',
} as const

export type AccordionVariants = typeof ACCORDION_VARIANTS_LIST[keyof typeof ACCORDION_VARIANTS_LIST]

export type VariantsBadge = 'positive' | 'negative' | 'default'

export type TTitleType = 'standart' | 'bordered'

export interface AccordionProps {
  children?: ReactNode

  /**
   * Инициализирующее состояние аккордеона (открыт, закрыт).
   *
   * @example
   * isOpen: true
   *
   * @default false
   */
  isOpen?: boolean

  /**
   * Название элемента инструмента.
   *
   * @example
   * title: "ОАО "Шестая генерирующая компания оптового рынка электроэнергии" / RU000A0JP6X0"
   *
   * @default ""
   */
  title?: string

  /**
   * Итоговая сумма по инструменту.
   *
   * @example
   * sum: 10000
   *
   * @default 0
   */
  sum?: CalculatedData['totalSum']

  /**
   * Изменения за день по инструменту.
   *
   * @example
   * dayDiff: 11500
   *
   * @default 0
   */
  dayDiff?: CalculatedData['totalDayDiff']['valuationAssetsDelta']

  /**
   * Изменения за день по инструменту в процентах.
   *
   * @example
   * dayDiffPercent: 34.44
   *
   * @default 0
   *
   */
  dayDiffPercent?: CalculatedData['totalDayDiff']['valuationAssetsDeltaPercents']

  /**
   * Доля текущего инструмента портфеля.
   *
   * @example
   * share: 34.44
   *
   * @default 0
   */
  share?: CalculatedData['totalInstrumentShare']

  /**
   * Валюта.
   *
   * @example
   * currency: "RUB"
   *
   * @default "RUB"
   */
  currency?: string

  /**
   * Вариант отображения аккордеона.
   *
   *
   *
   * @example
   * portfolio - отображение аккордеона в Портфеле
   * plain - отображение аккордеона в стандартном виде без шапки
   *
   * @default "portfolio"
   */
  variant?: AccordionVariants

  /**
   * Обработчик по клику (для контролируемого открытия и закрытия аккордеона).
   */
  onHandleClick?: (status: boolean) => void
  /**
   * Paddings аккордеона.
   */
  padding?: string | number

  isOpenDefault?: boolean

  withShadow?: boolean

  bodyPaddingTop?: number

  titleType?: TTitleType

  customTitle?: ReactNode

  titleMaxWidth?: number

  titleFontType?: string

  chevronPosition?: ChevronPosition
}

export type IAccordionContainer = Pick<AccordionProps, 'padding' | 'withShadow' | 'chevronPosition'>

export type ChevronPosition = 'standard' | 'bottom'

import { styled } from 'ui/theme'
import { IAccountValue } from './types'
import { Text } from 'ui/components/atoms'

export const StyledPortfolioActions = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing.md};

  @media ${({ theme }) => theme.breakpoint.sm} {
    flex-direction: column;
    align-items: stretch;
  }
`

export const StyledPortfolioForm = styled.div`
  display: flex;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.md};
  @media ${({ theme }) => theme.breakpoint.md} {
    > div:first-child {
      flex: 2;
    }
    // > div:nth-child(2) {
    //   width: 200px;
    // }
  }
  @media ${({ theme }) => theme.breakpoint.sm} {
    flex: 1;
    > div {
      flex: 1;
    }
  }
`

export const StyledPortfolioCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 370px));
  gap: ${({ theme }) => theme.spacing.md};
`

export const StyledAccountSelectMobile = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%) scale(0);
  background: ${({ theme }) => theme.color.bg.primary};
  padding: 20px 16px;
  z-index: 1;
  transition: ${({ theme }) => theme.transition.common};
  ${({ show }) =>
    show
      ? `
    transform: translateY(0);
    height: auto;
  `
      : ''}
`
export const StyledPortfolioActionsMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`
export const StyledPortfolioMobileCard = styled.div``
export const StyledPortfolioMobileCardTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  ${({ theme }) => theme.font.body2_lg};

  color: ${({ theme }) => theme.color.text.secondary};
`
export const StyledPortfolioMobileCardAmount = styled.div`
  font-size: 28px;
  line-height: 40px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`
export const StyledAccountSelectMobileClose = styled.div`
  margin-left: auto;
`

export const StyledMobileAccount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const StyledMobileAccountValue = styled(Text)<IAccountValue>`
  ${({
    isPositive,
    isNegative,
    theme: {
      color: { text },
    },
  }) => (isPositive || isNegative) && `color: ${isPositive ? text.positive : text.negative}`};
`

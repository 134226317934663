import React, { useEffect, useState } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Media from 'react-media'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg'
import MessageForm from './MessageForm'
import History from './History'
import useStyles from '../InnerTabs/InnerTabs.style'
import useWindowSize from '../../hooks/useWindows'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import PageHeading from '../PageHeading/PageHeading'

export default function MessagePanel() {
  const classes = useStyles()
  const { windowWidth } = useWindowSize()
  const [secondNestingVisibility, setSecondNestingVisibility] = useState(true)
  const { url, path } = useRouteMatch()
  const history = useHistory()
  const { pathname } = useLocation()
  const splitPathname = pathname.split('/')
  const isPathCashClassName = splitPathname[3] === 'new'
  const isPathDocumentsClassName = splitPathname[3] === 'history'

  useEffect(() => {
    const nestingVisibility = !(windowWidth <= 576 && (pathname.match(/\//g) || []).length >= 3)
    setSecondNestingVisibility(nestingVisibility)
  }, [windowWidth, pathname])

  const goTo = (link) => history.push(`${url}/${link}`)
  const getDynamicClassname = (isActive) => (isActive ? `${classes.breadCrumb} ${classes.activeCrumb}` : classes.breadCrumb)

  return (
    <Box>
      {secondNestingVisibility && (
        <>
          <PageHeading text="Обращения" />
          <Grid
            container
            className={classes.crumbContainer}
          >
            <button
              type="button"
              className={getDynamicClassname(isPathCashClassName)}
              onClick={() => goTo('new')}
            >
              <Media
                query={`(max-width: ${breakpoints.phone_large}px)`}
                render={() => (
                  <TransfersIcon />
                )}
              />
              <span>Новое обращение</span>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <ArrowRightIcon />
                )}
              />
            </button>
            <button
              type="button"
              className={getDynamicClassname(isPathDocumentsClassName)}
              onClick={() => goTo('history')}
            >
              <Media
                query={`(max-width: ${breakpoints.phone_large}px)`}
                render={() => (
                  <TransfersIcon />
                )}
              />
              <span>История обращений</span>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <ArrowRightIcon />
                )}
              />
            </button>
          </Grid>
        </>
      )}
      <Switch>
        <Route
          exact
          path={path}
        >
          {windowWidth > 577 ? <Redirect to={`${path}/new`} /> : null}
        </Route>
        <Route
          exact
          path={`${path}/new`}
          component={MessageForm}
        />
        <Route
          exact
          path={`${path}/history`}
          component={History}
        />
      </Switch>
    </Box>
  )
}

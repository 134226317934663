import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  topRow: {
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 32,
    },
  },
  circleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -21,
    '& .MuiBox-root': {
      margin: 21,
    },
  },
  countRow: {
    borderRight: '6px solid transparent',
    paddingTop: 31,
    paddingBottom: 23,
    cursor: 'pointer',
    '@media (max-width: 576px)': {
      paddingTop: 8,
      paddingBottom: 10,
      borderRight: 0,
      borderBottom: '1px solid #91B1BF',
    },
    '&:not(:last-child)': {
      borderBottom: `1px dashed ${theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78'}`,
      '@media (max-width: 576px)': {
        borderTop: '1px solid #91B1BF',
        borderBottom: '1px solid #91B1BF',
      },
    },
    '@media (pointer: fine)': {
      '&:hover': {
        backgroundColor: '#91B1BF1A',
        borderRightColor: '#FBC15E',
      },
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'unset',
    },
  },
  countCol: {
    '&:nth-child(3)': {
      marginBottom: 4,
    },
  },
  countCol_unique: {
    '@media (max-width: 576px)': {
      display: 'flex',
      marginBottom: 6,
      '& $countTitle': {
        marginRight: 5,
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '19px',
      },
      '& $countPercent': {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '19px',
        color: '#91B1BF',
      },
    },
  },
  countRowTitle: {
    fontSize: 18,
    color: theme.palette.type === 'light' ? '#000' : '#fff',
    '@media (max-width: 576px)': {
      fontSize: 16,
      lineHeight: '22px',
    },
  },
  countTitle: {
    display: 'block',
    fontSize: 16,
    fontWeight: 600,
    color: '#91B1BF',
    '@media (max-width: 576px)': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
    },
  },
  countText: {
    fontSize: 16,
    color: theme.palette.type === 'light' ? '#000' : '#fff',
    '@media (max-width: 576px)': {
      fontSize: 14,
      lineHeight: '19px',
    },
  },
  countPercent: {
    fontWeight: 400,
  },
  row: {
    paddingLeft: 26,
    borderLeft: '4px solid',
    borderBottom: `1px dashed ${theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78'}`,
    '&:first-child': {
      borderTop: `1px dashed ${theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78'}`,
    },
    alignItems: 'center',
    cursor: 'pointer',
  },
  infoTable: {
    marginTop: 55,
    width: 860,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  col: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  title: {
    color: '#91B1BF',
    fontSize: 15,
  },
  text: {
    fontSize: 17,
    color: theme.palette.type === 'light' ? '#000' : '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  tableRow: {
    borderRight: '6px solid transparent',
    cursor: 'pointer',
    '&.MuiTableRow-root:not(.MuiTableRow-head)': {
      '&:hover': {
        borderRightColor: '#FBC15E',
        backgroundColor: '#91B1BF1A',
      },
    },
    '&.MuiTableRow-head': {
      cursor: 'initial',
    },
    '&.MuiTableRow-head > .MuiTableCell-root': {
      fontSize: 14,
    },
    '& > .MuiTableCell-root': {
      padding: '15px 12px',
      borderBottomColor: theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78',
      borderBottomStyle: 'dashed',
      fontSize: 16,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 2,
      },
      '& button': {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& svg': {
        fill: '#91B1BF',
      },
    },
  },
  tableHeaderCell: {
    '&.MuiTableCell-head': {
      color: '#91B1BF',
    },
  },
  nestedRow: {
    position: 'relative',
    paddingLeft: 0,
    paddingRight: 0,
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 1,
      borderBottom: `1px dashed ${theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78'}`,
    },
    '&:first-child': {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 6,
        height: '100%',
        backgroundColor: '#5EC0C7',
      },
      '& .MuiBox-root': {
        paddingLeft: 26,
      },
    },
    '& .MuiBox-root': {
      padding: '12px 16px',
      fontSize: 16,
    },
  },
  ActiveFilters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  ActiveFilters_unique: {
    marginBottom: 4,
  },
  ActiveFilters__text: {
    fontSize: 16,
    lineHeight: '21px',
    color: '#91B1BF',
  },
  Tools: {
    display: 'flex',
    flexDirection: 'column',
  },
  Tools__item: {
    padding: '10px 0 12px',
    borderBottom: '1px solid #91B1BF',
    '&:first-child': {
      borderTop: '1px solid #91B1BF',
    },
  },
  Tools__title: {
    marginBottom: 7,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19px',
    color: theme.palette.type === 'light' ? '#323B4E' : '#fff',
  },
  Tools__grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '5px 8px',
  },
  Tools__gridItem: {},
  Tools__gridLabel: {
    fontSize: 12,
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    color: '#91B1BF',
  },
  Tools__gridValue: {
    fontSize: 14,
    lineHeight: '19px',
    color: theme.palette.type === 'light' ? '#000' : '#fff',
  },
  ProgressItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > button': {
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: 14,
      },
    },
  },
}))

export default useStyles

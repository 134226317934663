import { styled } from "ui/theme";

export const ScanerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1054px;
`

export const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 36px 0 44px 0;

  @media ${({ theme }) => theme.breakpoint.xxs} {
    flex-direction: column;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
  max-width: 330px;
  min-height: 178px;
  margin: 16px 0;
  padding: 24px 19px 28px 19px;
  background-color: #1D3E6F;
  border-radius: 8px;

  @media ${({ theme }) => theme.breakpoint.xxs} {
    max-width: 100%;
  }
`

export const CardText = styled.span`
  font-size: 16px;
  font-family: Open Sans;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #FFF;
`

export const BtnWrapper = styled.div`
  margin-left: auto;
`

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

const PrivateAuthRoute = ({ currentUser, component: Component }) => (
  <Route
    render={() => (!currentUser ? (
      <Component />
    ) : (
      <Redirect to={{
        pathname: '/',
      }}
      />
    ))}
  />
)

PrivateAuthRoute.defaultProps = {
  currentUser: PropTypes.object,
}

PrivateAuthRoute.propTypes = {
  currentUser: PropTypes.oneOfType([PropTypes.object]),
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
}

const mapStateToProps = ({ user: { currentUser } }) => ({
  currentUser,
})

export default connect(mapStateToProps, null)(PrivateAuthRoute)

import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import {
  AccordionContainer,
  Chevron,
  Data,
  DataArea,
  DataWrapper,
  DataMobileTitle,
  DataMobileContent,
  WrapperBody,
  WrapperBodyContent,
  WrapperHeading,
} from './styled'
import { Icon } from 'ui/components'
import { AccordionProps, ACCORDION_VARIANTS_LIST } from './types'

import {useSizeContext} from "../../../../../../context/SizeContext";
import {useTheme} from "../../../../../theme";

export const accrodionBodyPaddingTopDefault = 20

const Accordion: FCC<AccordionProps> = ({
  children,
  isOpen,
  variant = ACCORDION_VARIANTS_LIST.PORTFOLIO,
  onHandleClick,
  padding,
  withShadow = true,
  bodyPaddingTop = accrodionBodyPaddingTopDefault,
  isOpenDefault,
  titleType = 'standart',
  columns,
  row,
  layout,
  showChevron,
  onRowClick,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpenDefault || isOpen || false)
  const [maxContentHeight, setMaxContentHeight] = useState(0)
  const [isAnimated, setIsAnimated] = useState(true)

  const size = useSizeContext();
  const theme = useTheme();
  const isMobile = theme.bp.isXS(size.width);
  const isTablet = theme.bp.isSM(size.width);

  const wrapperBodyContentRef = useRef<HTMLDivElement | null>(null)

  const toggleByHandler = useCallback(
    (value: boolean) => {
      if (showChevron) {
        setIsAnimated(true)

        onHandleClick ? onHandleClick(value) : setIsAccordionOpen(value)
      }
    },
    [onHandleClick, showChevron]
  )

  const handleRowClick = useCallback((e) => {
    if (onRowClick) {
      onRowClick(row)
      return
    }

    e.stopPropagation()

    toggleByHandler(!isAccordionOpen)
  }, [toggleByHandler, isAccordionOpen])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((data) => {
      const newHeight = data[0]?.contentRect.height + bodyPaddingTop

      setMaxContentHeight(newHeight)
    })

    if (wrapperBodyContentRef.current) resizeObserver.observe(wrapperBodyContentRef.current)

    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      setIsAnimated(true)

      setIsAccordionOpen(isOpen)
    }
  }, [isOpen])

  const onBodyAnimatedEndHandler = useCallback(() => setIsAnimated(false), [])

  return (
    <AccordionContainer withShadow={withShadow} padding={padding}>
      <WrapperHeading type={titleType} onClick={handleRowClick}>
        <DataArea>
          <DataWrapper layout={layout ? layout : (columns || []).map(() => '1fr')} isDesktop={!(isMobile || isTablet)}>
            {columns.map((item) =>
              (isMobile || isTablet)
                ? <React.Fragment key={item.id}>
                    <DataMobileTitle>{item.title}</DataMobileTitle>
                    <DataMobileContent>{item.render ? item.render(row) : row[item.id]}</DataMobileContent>
                  </React.Fragment>
                : <Data key={item.id}>{item.render ? item.render(row) : row[item.id]}</Data>)
            }
          </DataWrapper>

          {!!children && (
            <Chevron {...(maxContentHeight && { isOpen: isAccordionOpen })}>
              {!!showChevron && <Icon name="arrow-down" size="sm" />}
            </Chevron>
          )}
        </DataArea>
      </WrapperHeading>

      {!!children && (
        <WrapperBody
          onTransitionEnd={onBodyAnimatedEndHandler}
          style={{
            maxHeight: isAccordionOpen ? maxContentHeight : 0,
          }}
          isOpen={isAccordionOpen}
          isAnimated={isAnimated}
        >
          <WrapperBodyContent
            paddingTop={bodyPaddingTop}
            ref={wrapperBodyContentRef}
            variant={variant}
          >
            {children}
          </WrapperBodyContent>
        </WrapperBody>
      )}
    </AccordionContainer>
  )
}

export default Accordion
export { Accordion }

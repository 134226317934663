import useInput from 'hooks/useInput';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  Form, FormCol, FormFooter, FormFooterActions, FormRow,
  CheckboxField, Field, PriceChangeCard, Select, BlockHeading, Text,
  Button, DateField, TextField, CustomModal
} from 'ui/components';
import {
  clearOutputMoney,
  fetchPerformOutputMoneyStartAsync,
  fetchSaveOutputMoneyStartAsync,
  fetchOutputMoneyStartAsync,
  getOutputMoneySuccess,
  performOutputMoneySuccess,
  saveOutputMoneySuccess,
  clearPartOutputMoney,
  fetchGetBanksStartAsync,
  // eslint-disable-next-line import/named
  getBanksSuccess,
  fetchGetPaymentTemplatesStartAsync,
  getPaymentTemplatesSuccess,
  fetchDeletePaymentTemplateStartAsync,
} from 'redux/transfers/money/output/outputMoneySlice'
import { clearMetadata, fetchMetadataStartAsync, getMetadataSuccess } from 'redux/metadata/metadataSlice'
import { selectConnectionState } from 'redux/connection/selector'
import {
  selectGetOutputMoney,
  selectPerformOutputMoney,
  selectSaveOutputMoney,
  selectCurrentBanks,
  selectPaymentTemplates, selectSaveOutputMoneyStatus, selectPerformOutputMoneyStatus,
} from 'redux/transfers/money/output/selector'
import { selectCurrentMetadata } from 'redux/metadata/selector'
import { selectCurrentError } from 'redux/error/selector'
import {
  deleteMoneySuccess,
  fetchDeleteMoneyStartAsync,
  fetchGetBalancesMoneyStartAsync,
  getBalancesMoneySuccess,
  fetchGetNdflStartAsync,
  getNdflSuccess
} from 'redux/transfers/money/transferMoneySlice'
import createUri from 'utils/FilterUri'
import JsonToSHA256 from 'utils/JsonToSHA256';
import formAction from 'utils/FormAction'
import {selectBalancesMoney, selectCurrentDeleteMoney, selectNdfl} from 'redux/transfers/money/selector'
import {
  currentUserLoginAndId,
  selectCurrentThumbprint,
  selectCurrentUser
} from 'redux/user/selector'
import getPrettyNumber from "../../../../../../utils/GetPrettyNumber";
import sanitizeHtml from 'sanitize-html'
import { format } from 'date-fns';
import { encodeToUTF8 } from 'utils/EncodeToUTF8';
import { SMSConfirmModal } from '../modals/SMSConfim';
import { formatDateServer } from 'utils/GetDate';
import { TransferHeading } from '../parts/TransferHeading';
import { StyledFormRightCol, StyledHeading, StyledPriceChangeCardWrapper } from '../../styled';
import { CustomAutocompleteSelect } from '../../../../molecules/CustomAutocompleteSelect'
import { setConfirmStatusModal } from 'redux/ui/uiSlice'
import numberWithSpaces from '../../../../../../utils/Money'
import { clearError } from '../../../../../../redux/error/errorSlice'
import { useScrollTop } from "../../../../../../hooks/useScrollTop";
import moment from "moment";

const transferType = 'Orders.Type.NonTrade.Money.Out'
const cancelType = 'Orders.Type.Modify.Cancellation'

export const Output: React.FC = () => {

  const history = useHistory()

  const scrollToTop = useScrollTop()

  const [clientPortfolio, setClientPortfolio] = useState('')
  const [currentClientPortfolio, setCurrentClientPortfolio] = useState(null)
  const [clientMoneyStorage, setClientMoneyStorage] = useState('')
  const [currentCurrency, setCurrentCurrency] = useState('')
  const currentAmount = useInput('', { isOnlyNumbers: true })
  const [expirationDate, setExpirationDate] = useState(null)
  const [validity, setValidity] = useState<any>('')
  const [arrayCurrencies, setArrayCurrencies] = useState<any>([])
  const [commentary, setCommentary] = useState('')
  const [reason, setReason] = useState('')
  const [allDisabled, setAllDisabled] = useState(false)
  const [currentActions, setCurrentActions] = useState(null)
  const [showTimer, setShowTimer] = useState(false)
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState<any>(false)
  const [codeTextError, setCodeTextError] = useState('')
  const [currentStatus, setCurrentStatus] = useState<any>('')
  const [submitAction, setSubmitAction] = useState('')
  const [transferHeader, setOutputHeader] = useState('')
  const [arrayPlatform, setArrayPlatform] = useState([])
  const [agentName, setAgentName] = useState('')
  const [bankName, setBankName] = useState('')
  const [bic, setBic] = useState('')
  const [accountName, setAccountName] = useState(null)
  const [bankAccount, setBankAccount] = useState('')
  const [ground, setGround] = useState('')
  const [isRealTime, setIsRealTime] = useState(false)
  const [bankSwift, setBankSwift] = useState('')
  const [correspondentSwift, setCorrespondentSwift] = useState('')
  const [addresseeBank, setAddresseeBank] = useState('')
  const [correspondentName, setCorrespondentName] = useState('')
  const [bankAccountAddressee, setBankAccountAddressee] = useState('')
  const [correspondentAccount, setCorrespondentAccount] = useState('')
  const urlParams = useParams<{ id?: string }>()
  const location = useLocation()
  const transferId = useMemo(() => urlParams.id, [urlParams])
  const transferUid = useMemo(() => location?.state?.orderUid, [location])
  const [metadataUid, setMetadataUid] = useState(transferUid || transferType)
  const [formDisabled, setFormDisabled] = useState(false)
  const [activeId, setActiveId] = useState('')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [updModalOpen, setUpdModalOpen] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [expirationDateError, setExpirationDateError] = useState(false)
  const [clientPortfolioError, setClientPortfolioError] = useState(false)
  const [clientMoneyStorageError, setClientMoneyStorageError] = useState(false)
  const [accountNameError, setAccountNameError] = useState(false)
  const [currencyError, setCurrencyError] = useState(false)
  const [amountError, setAmountError] = useState(false)
  const [bankSwiftError, setBankSwiftError] = useState(false)
  const [agentNameError, setAgentNameError] = useState(false)
  const [agentNameTextError, setAgentNameTextError] = useState('')
  const [bankNameError, setBankNameError] = useState(false)
  const [bankNameTextError, setBankNameTextError] = useState('')
  const [bankBicError, setBankBicError] = useState(false)
  const [bankBicTextError, setBankBicTextError] = useState('')
  const [bankAccountError, setBankAccountError] = useState(false)
  const [bankAccountTextError, setBankAccountTextError] = useState('')
  const [correspondentSwiftError, setCorrespondentSwiftError] = useState(false)
  const [correspondentSwiftTextError, setCorrespondentSwiftTextError] = useState('')
  const [correspondentNameError, setCorrespondentNameError] = useState(false)
  const [correspondentNameTextError, setCorrespondentNameTextError] = useState('')
  const [correspondentAccountError, setCorrespondentAccountError] = useState(false)
  const [clientPortfolioTextError, setClientPortfolioTextError] = useState('')
  const [clientMoneyStorageTextError, setClientMoneyStorageTextError] = useState('')
  const [currencyTextError, setCurrencyTextError] = useState('')
  const [expirationDateTextError, setExpirationDateTextError] = useState('')
  const [accountNameTextError, setAccountNameTextError] = useState('')
  const [bankSwiftTextError, setBankSwiftTextError] = useState('')
  const [correspondentAccountTextError, setCorrespondentAccountTextError] = useState('')
  const [amountTextError, setAmountTextError] = useState('')
  const [arrayDisabledFields, setArrayDisabledFields] = useState<any>([])
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [balances, setBalances] = useState(null)
  const [isCleared, setIsCleared] = useState(false)
  const [canModifyDate, setCanModifyDate] = useState(true)
  const [deletePaymentTemplateModalOpen, setDeletePaymentTemplateModalOpen] = useState(false)
  const [deletePaymentTemplateId, setDeletePaymentTemplateId] = useState(null)
  const [secretPassPhrase, setSecretPassPhrase] = useState('')

  const [isNewDetails, setIsNewDetails] = useState(false)
  const [searchStrBankName, setSearchStrBankName] = useState('')
  const [searchStrBic, setSearchStrBic] = useState('')
  const [searchStrCorrespondentAccount, setSearchStrCorrespondentAccount] = useState('')
  const [searchStrBankSwift, setSearchStrBankSwift] = useState('')
  const [searchStrAddresseeBank, setSearchStrAddresseeBank] = useState('')
  const [hashSha256, setHashSha256] = useState(null)
  const [searchStrAccountName, setSearchStrAccountName] = useState('')

  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [copyStatus, setCopyStatus] = useState(false)
  const [copyData, setCopyData] = useState<{
    order: {},
    portfolios: {},
    validity: {}
  } | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [disableValidation, setDisableValidation] = useState(false)


  const setConfirmModalStatus = (status) => {
    dispatch(setConfirmStatusModal(status))
  }


  const fetchOutputMoneyStart = (id) => {
    dispatch(fetchOutputMoneyStartAsync({ id }))
  }

  const fetchSaveOutputMoneyStart = (data, id) => {
    dispatch(fetchSaveOutputMoneyStartAsync({ data, id }))
  }

  const fetchPerformOutputMoneyStart = (data, id) => {
    dispatch(fetchPerformOutputMoneyStartAsync({ data, id }))
  }

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchBanksStart = (filterBody) => {
    dispatch(fetchGetBanksStartAsync(filterBody))
  }

  const fetchPaymentTemplatesStart = (filterBody) => {
    dispatch(fetchGetPaymentTemplatesStartAsync(filterBody))
  }

  const fetchDeletePaymentTemplatesStart = (id) => {
    dispatch(fetchDeletePaymentTemplateStartAsync(id))
  }

  const fetchGetBalancesMoneyStart = (filterBody) => {
    dispatch(fetchGetBalancesMoneyStartAsync({ filterBody }))
  }

  const fetchNdflStart = () => {
    dispatch(fetchGetNdflStartAsync())
  }

  const fetchDeleteTransferStart = (id) => {
    dispatch(fetchDeleteMoneyStartAsync({ id }))
  }

  const getRespOutputMoney = useSelector(selectGetOutputMoney)
  const getRespBanks = useSelector(selectCurrentBanks)
  const getRespPaymentTemplates = useSelector(selectPaymentTemplates)
  const getRespMetadata = useSelector(selectCurrentMetadata)
  const getSaveOutputMoney = useSelector(selectSaveOutputMoney)
  const statusRequestSave = useSelector(selectSaveOutputMoneyStatus)
  const getPerformOutputMoney = useSelector(selectPerformOutputMoney)
  const statusRequestPerform = useSelector(selectPerformOutputMoneyStatus)
  const getBalances = useSelector(selectBalancesMoney)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)
  const currentUser = useSelector(selectCurrentUser)
  const currentNdfl = useSelector(selectNdfl)
  const deleteMoneySelector = useSelector(selectCurrentDeleteMoney)

  const currentStatusDescription = getRespOutputMoney?.order?.statusDescription
    && sanitizeHtml(getRespOutputMoney?.order?.statusDescription)
  const todayDate = moment(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD')

  const deleteTemplate = (id) => {
    if (id) {
      setDeletePaymentTemplateModalOpen(true)
      setDeletePaymentTemplateId(id)
    }
  }

  const clearAdditionalFields = () => {
    setAgentName('')
    setBankName('')
    setBic('')
    setAccountName('')
    setBankAccount('')
    setGround('')
    setBankSwift('')
    setCorrespondentSwift('')
    setAddresseeBank('')
    setCorrespondentName('')
    setBankAccountAddressee('')
    setCorrespondentAccount('')
  }

  const clearFields = (needClearData = true) => {
    if (needClearData) dispatch(clearMetadata())
    dispatch(clearOutputMoney())
    setCommentary('')
    setReason('')
    setArrayCurrencies('')
    setCurrentCurrency('')
    currentAmount.setValue('')
    setExpirationDate('')
    setValidity(new Date())
    setAllDisabled(false)
    setCurrentActions(null)

    setClientPortfolio('')
    setClientMoneyStorage('')
    setShowTimer(false)
    setCode('')
    setCodeError('')
    setCurrentStatus('')
    setSubmitAction('')
    setOutputHeader('')
    clearAdditionalFields()

    setExpirationDateError(false)
    setClientPortfolioError(false)
    setClientMoneyStorageError(false)
    setCurrencyError(false)
    setAmountError(false)
    setAccountNameError(false)
    setBankSwiftError(false)
    setCorrespondentAccountError(false)
    setBalances(null)
    setIsCleared(true)
    setSecretPassPhrase('')
  }

  const clearErrors = () => {
    setExpirationDateError(false)
    setClientPortfolioError(false)
    setClientMoneyStorageError(false)
    setCurrencyError(false)
    setAmountError(false)
    setAccountNameError(false)
    setBankSwiftError(false)
    setCorrespondentAccountError(false)
    setBalances(null)
    setIsCleared(true)
    setSecretPassPhrase('')
  }


  const fillTemplateFields = (currentAccount) => {
    if (typeof (currentAccount) === 'object' && Object.keys(currentAccount)?.length) {
      setAgentName(currentAccount?.agentName)
      let objectFields = {}
      if (currentAccount?.currencyUid?.toLowerCase() === 'rub') {
        objectFields = {
          agentName: !!currentAccount?.agentName,
          bankName: !!currentAccount?.bankName,
          bic: !!currentAccount?.bankBic,
          bankAccount: !!currentAccount?.bankAccount,
          correspondentAccount: !!currentAccount?.correspondentAccount,
          isRealTime: !!currentAccount?.isRealTime,
        }
        setBankName(currentAccount?.bankName)
        setBic(currentAccount?.bankBic)
        setBankAccount(currentAccount?.bankAccount)
        setCorrespondentAccount(currentAccount?.correspondentAccount)
        // eslint-disable-next-line no-unused-expressions
        currentAccount?.isRealTime && setIsRealTime(currentAccount?.isRealTime)
        if (currentAccount?.bankName && currentAccount?.bankAccount) {
          setIsNewDetails(true)
          setAccountName(`${currentAccount?.bankName} / ${currentAccount?.bankAccount}`)
        }
      } else if (currentAccount?.currencyUid?.toLowerCase() !== 'rub') {
        objectFields = {
          agentName: !!currentAccount?.agentName,
          correspondentSwift: !!currentAccount?.correspondentSwift,
          addresseeBank: !!currentAccount?.bankName,
          correspondentName: !!currentAccount?.correspondentName,
          bankAccountAddressee: !!currentAccount?.bankAccount,
          bankSwift: !!currentAccount?.bankSwift,
          correspondentAccount: !!currentAccount?.correspondentAccount,
        }
        setCorrespondentSwift(currentAccount?.correspondentSwift)
        setAddresseeBank(currentAccount?.bankName)
        setCorrespondentName(currentAccount?.correspondentName)
        setBankAccountAddressee(currentAccount?.bankAccount)
        setBankSwift(currentAccount?.bankSwift)
        setCorrespondentAccount(currentAccount?.correspondentAccount)
        setBic(currentAccount?.bankBic)
        setBankName(currentAccount?.bankName)
        setBankAccount(currentAccount?.bankAccount)
        if (currentAccount?.bankName && currentAccount?.bankAccount) {
          setIsNewDetails(true)
          setAccountName(`${currentAccount?.bankName} / ${currentAccount?.bankAccount}`)
        }
      }
      setArrayDisabledFields(objectFields)
    } else {
      clearAdditionalFields()
    }
  }

  const fillTemplate = (paymentTemplates, currency, currentOrder) => {
    let accountNames = null
    if (paymentTemplates?.length >= 0) {
      accountNames = paymentTemplates
    } else {
      accountNames = [{ templateName: `${currentOrder?.bankName} / ${currentOrder?.bankAccount}`, bankName: currentOrder?.bankName }]
    }
    if (currentOrder) {
      const selectedBankName = accountNames?.find(
        (item) => item.bankName === currentOrder?.bankName,
      )
      setAccountName(selectedBankName?.templateName)
      fillTemplateFields(currentOrder)
      return
    }
    if (accountNames?.length === 1) {
      setAccountName(accountNames[0]?.templateName)
      fillTemplateFields(accountNames[0])
    }
  }

  const fillFields = (order, references) => {
    let payments = null
    let licenseMinus = null
    const currenCurrency = order?.currency?.uid
    const expirationDate = getRespOutputMoney?.order?.expirationDate;
    const isActualDate = expirationDate < todayDate ? todayDate : expirationDate
    if (references && Object.keys(references)?.length) {
      licenseMinus = references?.portfolios?.find(
        (item) => item.name === order?.clientPortfolio?.name,
      )
      payments = references?.paymentTemplates
      setArrayPlatform(licenseMinus?.moneyStorages)
      setArrayCurrencies(licenseMinus?.currencies)
    } else {
      setArrayCurrencies([{ uid: order?.currency?.uid }])
      setArrayPlatform([order?.clientMoneyStorage])
    }
    if (currenCurrency) {
      fillTemplate(payments, currenCurrency, order)
    }
    setGround(order?.ground)
    setClientPortfolio(order?.clientPortfolio?.name)
    setClientMoneyStorage(order?.clientMoneyStorage?.description)
    setCurrentCurrency(currenCurrency)
    currentAmount.setValue(order?.amount)
    setExpirationDate(isActualDate)
    setValidity(isActualDate)
    setCommentary(order?.comment)
    setReason(order?.reason)
    // eslint-disable-next-line no-underscore-dangle
    if (!order?._links[0]?.canModify) {
      setAllDisabled(true)
      setCanModifyDate(false)
    }
  }

  const handleBackToHistory = () => {
    history.push('/transfers/money/log')
  }


  useEffect(() => {

    clearFields()

    if (transferId === 'new') {
      setMetadataUid(transferType)
      setActiveId(null)

      return
    }

    if (!transferId) return;

    setActiveId(transferId)

    scrollToTop()
  }, [transferId])


  useEffect(() => {


    if (transferId === 'new') {
      fetchMetadataStart(transferType)
      setIsLoading(false)

      return
    }

    if (!transferId) return;

    fetchMetadataStart(metadataUid)
    fetchOutputMoneyStart(transferId)
  }, [transferId])


  useEffect(() => {
    if (currentUser?.client?.legalEntity === 'person') {
      fetchNdflStart()
    }
  }, [currentUser])

  useEffect(() => {
    if (getRespOutputMoney?.order && isCleared) {
      setIsLoading(false)
      setCurrentStatus(getRespOutputMoney?.order?.status)
      let order = null
      if (getRespOutputMoney?.order?.type?.uid === transferType) {
        order = getRespOutputMoney?.order
        if (getRespMetadata?.references && Object.keys(getRespMetadata.references).length) {
          const portfolios = getRespMetadata?.references?.portfolios
          fillFields(order, portfolios)
        }
        if (getRespMetadata?.order?.type?.uid === cancelType) {
          const cancelDate = getRespMetadata?.order?.expirationDate
          const reqObj = {
            type: { uid: cancelType },
            expirationDate: cancelDate && formatDateServer(new Date(cancelDate)),
            affectedOrder: { id: +activeId },
            secret: '',
          }

          const date = getRespMetadata?.order?.date
          const number = getRespMetadata?.order?.number

          // @ts-ignore
          reqObj.date = date
          // @ts-ignore
          reqObj.number = number

          const WordArrayToBase64 = JsonToSHA256(reqObj)
          if (WordArrayToBase64) {
            reqObj.secret = WordArrayToBase64
            setHashSha256(WordArrayToBase64)
          }

          fetchPerformOutputMoneyStart(reqObj, null)
        }
      } else if (getRespOutputMoney?.order?.type?.uid === cancelType) {
        order = getRespOutputMoney?.order?.affectedOrder
        fillFields(order, [])
        // eslint-disable-next-line no-underscore-dangle
        if (getRespOutputMoney?.order?._links[0]?.canModify) {
          setCanModifyDate(true)
        }
      }
      const references = getRespMetadata?.references

      fillFields(order, references)
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(getRespOutputMoney?.order?._links[0])
      setOutputHeader(`${getRespOutputMoney?.order?.description}`)
    } else if (transferId !== 'new') {
      setIsLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRespOutputMoney, getRespMetadata, isCleared])

  useEffect(() => {
    if (isCleared) {
      if (getSaveOutputMoney?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveOutputMoney?.order?.id)
        }
        if (getSaveOutputMoney?.requiredAction?.type === 'code' || getSaveOutputMoney?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
      }
      if (getPerformOutputMoney?.requiredAction) {
        if (!activeId || +activeId !== getPerformOutputMoney?.order?.id) {
          setActiveId(getPerformOutputMoney?.order?.id)
        }
        if (getPerformOutputMoney?.requiredAction?.type === 'code' || getPerformOutputMoney?.requiredAction?.type === 'mac') {
          setShowTimer(true)
        }
        setCurrentStatus(getPerformOutputMoney?.order?.status)
      }
      const msgData = getSaveOutputMoney || getPerformOutputMoney
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespOutputMoney?.order?.type?.uid === cancelType) ? cancelType : transferType
      if (msgData?.order?.description) {
        setOutputHeader(`${msgData?.order?.description}`)
      }
      if (typeUID) {
        setIsLoading(false)
      }
      // eslint-disable-next-line no-underscore-dangle
      setCurrentActions(msgData?.order?._links[0])
      if (msgData?.order?.type?.uid === cancelType
        && getRespOutputMoney?.order?.type?.uid !== cancelType) {
        const cancelDate = getRespMetadata?.order?.expirationDate
        setExpirationDate(cancelDate)
        setValidity(cancelDate)
      }
      if (statusRequestPerform === 200 || statusRequestSave === 200) {
        switch (getSaveOutputMoney?.order?.status?.uid || getPerformOutputMoney?.order?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (getSaveOutputMoney?.order?.id) {
              history.replace(`/transfers/money/output/${getSaveOutputMoney?.order?.id}`)
              setAllDisabled(false)
              setFormDisabled(false)
            }
            if (activeId) {
              setUpdModalOpen(true)
            } else {
              if (submitAction !== 'save') {
                setSaveModalOpen(true)
              }
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaveOutputMoney, getPerformOutputMoney, isCleared])

  useEffect(() => {
    if (serverError) {
      if (serverError?.ExpirationDate?.length) {
        setExpirationDateError(true)
        setExpirationDateTextError(serverError?.ExpirationDate[0])
      }
      if (serverError?.ClientPortfolio?.length) {
        setClientPortfolioError(true)
        setClientPortfolioTextError(serverError?.ClientPortfolio[0])
      }
      if (serverError?.ClientMoneyStorage?.length) {
        setClientMoneyStorageError(true)
        setClientMoneyStorageTextError(serverError?.ClientMoneyStorage[0])
      }
      if (serverError?.Currency?.length) {
        setCurrencyError(true)
        setCurrencyTextError(serverError?.Currency[0])
      }
      if (serverError?.Amount?.length) {
        setAmountError(true)
        setAmountTextError(serverError?.Amount[0])
      }
      if (serverError?.AccountName?.length) {
        setAccountNameError(true)
        setAccountNameTextError(serverError?.AccountName[0])
      }
      if (serverError?.BankSwift?.length) {
        setBankSwiftError(true)
        setBankSwiftTextError(serverError?.BankSwift[0])
      }
      if (serverError?.CorrespondentAccount?.length) {
        setCorrespondentAccountError(true)
        setCorrespondentAccountTextError(serverError?.CorrespondentAccount[0])
      }
      if (serverError?.Secret?.length) {
        setCodeError(true)
        setCodeTextError(serverError?.Secret[0])
      }
      if (serverError?.AgentName?.length) {
        setAgentNameError(true)
        setAgentNameTextError(serverError?.AgentName[0])
      }
      if (serverError?.BankName?.length) {
        setBankNameError(true)
        setBankNameTextError(serverError?.BankName[0])
      }
      if (serverError?.BankBic?.length) {
        setBankBicError(true)
        setBankBicTextError(serverError?.BankBic[0])
      }
      if (serverError?.BankAccount?.length) {
        setBankAccountError(true)
        setBankAccountTextError(serverError?.BankAccount[0])
      }
      if (serverError?.CorrespondentSwift?.length) {
        setCorrespondentSwiftError(true)
        setCorrespondentSwiftTextError(serverError?.CorrespondentSwift[0])
      }
      if (serverError?.CorrespondentName?.length) {
        setCorrespondentNameError(true)
        setCorrespondentNameTextError(serverError?.CorrespondentName[0])
      }
      const msgData = getSaveOutputMoney || getPerformOutputMoney
      if ((!currentActions || currentActions?.canModify) && !msgData?.requiredAction?.type) {
        setAllDisabled(false)
      }
      setIsLoading(false)
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError])

  const copyOutput = () => {
    if (copyData !== null) {
      fillFields(copyData.order, copyData.portfolios)
      setValidity(copyData.validity)
    }

    setAllDisabled(false)
    setCanModifyDate(true)

    setCopyStatus(false)
    setCopyData(null)
  }

  const preCopy = () => {
    setCopyData({
      order: {
        ...getRespOutputMoney?.order,
        amount: numberWithSpaces(getRespOutputMoney?.order?.amount)
      },
      portfolios: getRespMetadata?.references?.portfolios,
      validity
    })

    history.replace('/transfers/money/output/new')

    setMetadataUid(transferType)
    fetchMetadataStart(transferType)
    setIsLoading(false)
    setActiveId(null)

    clearFields();

    setCopyStatus(true)
  }

  useEffect(() => {
    if (getRespMetadata && copyStatus) {
      copyOutput();
    }
  }, [copyStatus, getRespMetadata])

  useEffect(() => () => {
    dispatch(clearError())
    clearFields()
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const fetchBalancesMoney = (filterBody, setBalance) => {
    if (filterBody) {
      fetchGetBalancesMoneyStart(filterBody.replaceAll('+', ''))
    }
    setBalance(null)
  }

  const getPortfolioObj = (clientPortfolioName) => {
    if (
      getRespMetadata?.order?.type?.uid === cancelType
    ) return getRespOutputMoney?.order?.affectedOrder?.clientPortfolio
    return getRespMetadata?.references?.portfolios?.find(
      (item) => item.name === clientPortfolioName,
    )
  }

  useMemo(() => {
    let license = null
    let moneyStorage = null
    if (transferUid === cancelType) {
      license = getRespOutputMoney?.order?.affectedOrder?.clientPortfolio
      moneyStorage = getRespOutputMoney?.order?.affectedOrder?.clientMoneyStorage
    } else {
      license = getPortfolioObj(clientPortfolio)
      moneyStorage = license?.moneyStorages?.find(
        (item) => item.description === clientMoneyStorage,
      )
    }
    let filterBodyClient
    if (clientPortfolio || clientMoneyStorage) {
      filterBodyClient = createUri({
        name: 'balancesMoney',
        portfolioId: license?.id,
        currencyUid: currentCurrency,
        storageUid: moneyStorage?.uid,
        condition: '&',
      })
      fetchBalancesMoney(filterBodyClient, setBalances)
    }
    setBalances(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientPortfolio, clientMoneyStorage, currentCurrency])

  useEffect(() => {
    if (getBalances) {
      const license = getPortfolioObj(clientPortfolio)
      if (!getBalances?.portfolio) return
      if (getBalances?.portfolio?.id === license?.id) {
        setBalances(getBalances)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBalances])

  const handleChangeCurrency = ({ value }) => {
    setAccountName('')
    clearAdditionalFields()
    setCurrentCurrency(value)
    setCurrencyError(false)
    setSearchStrAccountName('')
    setBankAccountError(false)

    setIsNewDetails(false)
  }

  const handleIsRealTimeSwitchChange = (event) => {
    const { checked } = event.target
    setIsRealTime(checked)
  }

  const handleChangeValidity = (date) => {
    setValidity(date)
    setExpirationDateError(false)
  }

  const handleChangeCommentary = (event) => {
    const { value } = event.target
    setCommentary(value)
  }

  const handleChangeReason = (event) => {
    setReason(event.target.value)
  }

  const handleChangePlatform = ({ value }) => {
    setClientMoneyStorage(value)
    setClientMoneyStorageError(false)
  }

  const handleChangeGround = (event) => {
    const { value } = event.target
    setGround(value)
  }

  const handleChangeCorrespondentAccount = (event) => {
    const { value } = event.target
    setCorrespondentAccount(value)
    setCorrespondentAccountError(false)
  }

  const handleChangeAgentName = (event) => {
    const { value } = event.target
    setAgentName(value)
    setAgentNameError(false)
  }

  const handleChangeBankAccount = (event) => {
    const { value } = event.target
    setBankAccount(value)
    setBankAccountError(false)
  }

  const handleChangeCorrespondentSwift = (event) => {
    const { value } = event.target
    setCorrespondentSwift(value.trim())
    setCorrespondentSwiftError(false)
  }

  const handleChangeCorrespondentName = (event) => {
    const { value } = event.target
    setCorrespondentName(value)
    setCorrespondentNameError(false)
  }

  const handleChangeBankAccountAddressee = (event) => {
    const { value } = event.target
    setBankAccountAddressee(value)
    setBankAccountError(false)
  }

  const addNeedsProps = (typeUID, msgData) => {
    const license = getPortfolioObj(clientPortfolio)
    const moneyStorage = license?.moneyStorages?.find(
      (item) => item.description === clientMoneyStorage,
    )
    if (typeUID === cancelType) {
      const reqObj = {
        type: { uid: typeUID },
        expirationDate: formatDateServer(new Date(validity)),
        affectedOrder: {
          id: msgData?.order?.affectedOrder?.id
            || getRespOutputMoney?.order?.affectedOrder?.id,
        },
        secret: '',
      }
      return reqObj
    }
    const reqObj: any = {
      type: { uid: typeUID },
      dateTime: formatDateServer(new Date()),
      expirationDate: formatDateServer(new Date(validity)),
      clientPortfolio: { id: license?.id },
      clientMoneyStorage: { uid: moneyStorage?.name },
      comment: commentary,
      reason,
      currency: { uid: currentCurrency },
      amount: +String(currentAmount.value).replace(/ /g, ''),
      secret: '',
    }
    if (currentCurrency?.toLowerCase() === 'rub') {
      reqObj.bankBic = bic
      reqObj.agentName = agentName
      reqObj.bankName = bankName
      reqObj.bankAccount = bankAccount
      reqObj.correspondentAccount = correspondentAccount
      reqObj.ground = ground
      reqObj.isRealTime = isRealTime
    } else {
      reqObj.agentName = agentName
      reqObj.bankSwift = bankSwift
      reqObj.correspondentSwift = correspondentSwift
      reqObj.bankName = addresseeBank
      reqObj.correspondentName = correspondentName
      reqObj.bankAccount = bankAccountAddressee
      reqObj.correspondentAccount = correspondentAccount
      reqObj.ground = ground
    }
    return reqObj
  }

  const handlePerformOutput = (event) => {
    event.preventDefault()
      setFormDisabled(true)
      setIsLoading(true)

      if (submitAction === 'perform') {
        setAllDisabled(true)
      }

      const msgData = getSaveOutputMoney || getPerformOutputMoney
      const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
        || getRespOutputMoney?.order?.type?.uid === cancelType) ? cancelType : transferType
      const requestObj = addNeedsProps(typeUID, msgData)

      const date = getRespMetadata?.order?.date
      const number = getRespMetadata?.order?.number

      requestObj.date = date
      requestObj.number = number

      if (submitAction !== 'save') {
        const WordArrayToBase64 = JsonToSHA256(requestObj)
        if (WordArrayToBase64) {
          requestObj.secret = WordArrayToBase64
          setHashSha256(WordArrayToBase64)
        }
      }

      formAction({
        submitAction,
        requestObj,
        typeUID,
        msgData,
        putPerformData: fetchPerformOutputMoneyStart,
        postPerformData: fetchPerformOutputMoneyStart,
        putSaveData: fetchSaveOutputMoneyStart,
        postSaveData: fetchSaveOutputMoneyStart,
        activeId,
        code,
        setCodeError,
        setCodeTextError,
        setCancellationModalOpen,
        setFormDisabled,
        setIsLoading,
        currentThumbPrint,
        secretPassPhrase,
        disableValidation
      })
      setDisableValidation(false)
  }

  const handleChangePortfolio = (option) => {
    setClientPortfolio(option?.value)
    setClientMoneyStorage('')
    const currentObj = getPortfolioObj(option?.value)
    setCurrentClientPortfolio(currentObj)
    if (currentObj?.moneyStorages?.length) {
      setArrayPlatform(currentObj.moneyStorages)
    } else {
      setArrayPlatform([])
    }
    setArrayCurrencies(currentObj?.currencies)
    setCurrentCurrency('')
    setAccountName('')
    setClientPortfolioError(false)
    currentAmount.setValue('')
    setIsRealTime(false)
    setIsNewDetails(false)
  }

  const handleCancelOperation = (event) => {
    if (getRespMetadata?.order?.type?.uid === cancelType) { handleBackToHistory() } else {
      event.preventDefault()
      dispatch(clearPartOutputMoney())
      setAllDisabled(false)
      setFormDisabled(false)
    }
  }

  const handleDownloadXml = () => {
    const msgData = getSaveOutputMoney || getPerformOutputMoney
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.order?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const msgData = getSaveOutputMoney || getPerformOutputMoney
    const typeUID = msgData?.requiredAction && msgData?.order?.type?.uid === cancelType
      ? cancelType : transferType
    const requestObj = addNeedsProps(typeUID, msgData)

    const date = getRespMetadata?.order?.date
    const number = getRespMetadata?.order?.number

    requestObj.date = date
    requestObj.number = number

    const WordArrayToBase64 = JsonToSHA256(requestObj)
    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHashSha256(`${WordArrayToBase64}`)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID,
      putPerformData: fetchPerformOutputMoneyStart,
      postPerformData: fetchPerformOutputMoneyStart,
      activeId,
      secretPassPhrase,
    })
  }

  const createMarkup = () => (
    { __html: currentStatusDescription }
  )

  const getHandler = (handler) => (!allDisabled ? handler : null)

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false)
  }

  const handleDeletedOrder = () => {
    if (transferId) {
      setDeleteModalOpen(true)
    }
  }

  useEffect(() => {
    if (deleteMoneySelector?.status === 200) {
      handleBackToHistory()
    }
  }, [deleteMoneySelector])

  const handleDeleteOk = () => {
    fetchDeleteTransferStart(transferId)
    setDeleteModalOpen(false)
  }

  const handleCloseSaveModal = (goToHistory = false) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseUpdModal = () => {
    setUpdModalOpen(false)
    handleBackToHistory()
  }

  const handleClosePerformModal = (goToHistory = false) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
  }

  const handleCloseDeletePaymentTemplateModal = () => {
    setDeletePaymentTemplateModalOpen(false)
  }

  const handleCancelOk = () => {
    setMetadataUid(cancelType)
    fetchMetadataStart(cancelType)
    handleCloseCancellationModal()
  }
  const handleDeletePaymentTemplateOk = () => {
    fetchDeletePaymentTemplatesStart(deletePaymentTemplateId)
    setDeletePaymentTemplateId(null)
    setDeletePaymentTemplateModalOpen(false)

    if (clientPortfolio && currentCurrency) {
      const portfolioObj = getPortfolioObj(clientPortfolio)
      fetchPaymentTemplatesStart(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Context.PortfolioID=${portfolioObj?.id}`)
    }

  }

  const handleActionReject = (modalType) => {
    if (modalType === 'saveModal') {
      handleCloseSaveModal(false)
    }
    if (modalType === 'performModal') {
      handleClosePerformModal(true)
    }
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)
    clearFields(false)
    setActiveId(null)
    setFormDisabled(false)
    setMetadataUid(transferType)
    setIsNewDetails(false)
    history.replace('/transfers/money/output/new')
  }

  const handleChangeBankNameAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrBankName(value)
      setBankName(value)
      if (value && value.trim().length >= 3) {
        fetchBanksStart(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Search=${value}`)
      }
    }
  }
  const handleChangeBankNameAutocomplete = (event, value) => {
    if (value.name) {
      setSearchStrBankName(value?.name)
      setBankName(value?.name)
      setBic(value?.bic)
      setCorrespondentAccount(value?.correspondentAccount)
      setBankNameError(false)
      setBankBicError(false)
      setCorrespondentAccountError(false)
    } else {
      setSearchStrBankName('')
      setSearchStrCorrespondentAccount('')
      setSearchStrBic('')
      setBankName('')
      setBic('')
      setCorrespondentAccount('')
    }
  }

  const StandartAutocompleteArray = (array, startArray) => {
    const correctStartArray = startArray || []
    return array?.length ? correctStartArray.concat(array) : correctStartArray
  }

  useEffect(() => {
    if (clientPortfolio && currentCurrency) {
      const portfolioObj = getPortfolioObj(clientPortfolio)
      fetchPaymentTemplatesStart(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Context.PortfolioID=${portfolioObj?.id}`)
    }
  }, [currentClientPortfolio?.id, currentCurrency, deletePaymentTemplateId])

  const handleChangeAccountNameAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrAccountName(value)


      if (value && value.trim().length >= 1) {
        fetchPaymentTemplatesStart(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Context.PortfolioID=${currentClientPortfolio?.id}&Search=${value}`)
      }
    }
  }

  const handleChangeAccountNameAutocomplete = (event, object) => {
    if (object) {
      setSearchStrAccountName(object?.templateName)
      setIsNewDetails(true)
      if (!object?.bankSwift) {
        setBankSwift('')
        setSearchStrBankSwift('')
      }
    }
    setAccountName(object?.templateName)
    fillTemplateFields(object)
    setAccountNameError(false)
    setBankSwiftError(false)
    setCorrespondentAccountError(false)
    setAgentNameError(false)
    setBankNameError(false)
    setBankBicError(false)
    setBankAccountError(false)
    setCorrespondentSwiftError(false)
    setCorrespondentNameError(false)
    if (object?.templateName === 'Добавить новые реквизиты') {
      setIsNewDetails(true)
      setArrayDisabledFields([])
      setSearchStrAccountName(object?.templateName)
      setSearchStrBankName('')
      setSearchStrCorrespondentAccount('')
      setSearchStrBic('')
      setBankName('')
      setBic('')
      setCorrespondentAccount('')
      setBankSwift('')
      setAddresseeBank('')
      setSearchStrAddresseeBank('')
      setSearchStrBankSwift('')
    }
    if (object?.templateName === ' ') {
      setIsNewDetails(false)
    }

    setAgentNameTextError('')
    setBankAccountTextError('')
    setBankNameTextError('')
    setCorrespondentNameTextError('')
    setBankBicTextError('')
    setBankSwiftTextError('')
  }

  const handleChangeBicAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrBic(value)
      setBic(value)
      if (value && value.trim().length >= 3) {
        fetchBanksStart(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Filter=[Bic]+contains+${value}`)
      }
    }
  }
  const handleChangeBicAutocomplete = (event, value) => {
    if (value.bic) {
      setSearchStrBic(value?.bic)
      setBankName(value?.name)
      setBic(value?.bic)
      setCorrespondentAccount(value?.correspondentAccount)
      setBankNameError(false)
      setBankBicError(false)
      setCorrespondentAccountError(false)
    } else {
      setSearchStrBankName('')
      setSearchStrCorrespondentAccount('')
      setSearchStrBic('')
      setBankName('')
      setBic('')
      setCorrespondentAccount('')
    }
  }

  const handleChangeCorrespondentAccountAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrCorrespondentAccount(value)
      setCorrespondentAccount(value)
      if (value && value.trim().length >= 3) {
        fetchBanksStart(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Filter=[CorrespondentAccount]+contains+${value}`)
      }
    }
  }
  const handleChangeCorrespondentAccountAutocomplete = (event, value) => {
    if (value.correspondentAccount) {
      setSearchStrCorrespondentAccount(value?.correspondentAccount)
      setBankName(value?.name)
      setBic(value?.bic)
      setCorrespondentAccount(value?.correspondentAccount)
      setBankNameError(false)
      setBankBicError(false)
      setCorrespondentAccountError(false)
    } else {
      setSearchStrBankName('')
      setSearchStrCorrespondentAccount('')
      setSearchStrBic('')
      setBankName('')
      setBic('')
      setCorrespondentAccount('')
    }
  }

  const handleChangeAddresseeBankAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrAddresseeBank(value)
      setAddresseeBank(value)
      if (value && value.trim().length >= 3) {
        fetchBanksStart(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Search=${value}`)
      }
    }
  }
  const handleChangeAddresseeBankAutocomplete = (event, value) => {
    if (value.name) {
      setSearchStrAddresseeBank(value?.name)
      setBankSwift(value?.swift)
      setAddresseeBank(value?.name)
      setBankSwiftError(false)
      setBankNameError(false)
    } else {
      setBankSwift('')
      setAddresseeBank('')
      setSearchStrAddresseeBank('')
      setSearchStrBankSwift('')
    }
  }

  const handleChangeBankSwiftAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSearchStrBankSwift(value)
      setBankSwift(value)
      if (value && value.trim().length >= 3) {
        fetchBanksStart(`Context.CurrencyUid=${currentCurrency}&Context.ObjectUid=${transferType}&Filter=[Swift]+contains+${value}`)
      }
    }
  }
  const handleChangeBankSwiftAutocomplete = (event, value) => {
    if (value.swift) {
      setSearchStrBankSwift(value?.swift)
      setBankSwift(value?.swift)
      setAddresseeBank(value?.name)
      setBankSwiftError(false)
      setBankNameError(false)
    } else {
      setBankSwift('')
      setAddresseeBank('')
      setSearchStrAddresseeBank('')
      setSearchStrBankSwift('')
    }
  }

  /**
   * Отображать ли модальное окно подтверждения по запросу кода.
   */
  const isConfirmModalShow = () => (
    getSaveOutputMoney?.requiredAction?.type === 'code'
    || getPerformOutputMoney?.requiredAction?.type === 'code'
    || getSaveOutputMoney?.requiredAction?.type === 'mac'
    || getPerformOutputMoney?.requiredAction?.type === 'mac'
  )

  useEffect(() => {
    if (!isConfirmModalShow()) {
      setConfirmModalStatus(false)
    }

    if (isConfirmModalShow() && showModalConfirm) {
      setConfirmModalStatus(true)
      setDisableValidation(true)
    }
  }, [getSaveOutputMoney, getPerformOutputMoney, showModalConfirm])

  const isStatusDraft = currentStatus?.uid === 'Draft'

  const handleCloseSmsModal = () => {
    setShowModalConfirm(false)
    setConfirmModalStatus(false)

    setCodeError(false)
    dispatch(clearError())
    setCodeTextError('')
    setCode('')
  }
  const draftStatus = getRespOutputMoney?.order?.status?.uid === 'Draft'
  return <>
    <TransferHeading
      transferHeader={transferHeader}
      // isRemoval={ getRespOutputMoney?.order?.status?.uid !== 'Executed' }
      isRemoval={isStatusDraft}
      isDownload
      deletedOrderCall = {handleDeletedOrder}
    />
    <Form onSubmit={handlePerformOutput}>
      <FormRow>
        <FormCol flex={1} gap={24}>
          <FormRow>
            <Field
              label="Портфель"
              hasError={clientPortfolioError}
              caption={clientPortfolioTextError}>
              <Select
                options={(
                  getRespMetadata?.references?.portfolios?.map((option) => option.name)
                  || [getRespOutputMoney?.order?.affectedOrder?.clientPortfolio?.name]
                )?.map(option => ({ label: option, value: option }))}
                value={clientPortfolio ? { label: clientPortfolio, value: clientPortfolio } : null}
                placeholder="Введите текст или выберите из списка"
                isDisabled={allDisabled}
                onChange={getHandler(handleChangePortfolio)}
              />
            </Field>
            <Field
              label="Площадка"
              hasError={clientMoneyStorageError}
              caption={clientMoneyStorageTextError}
            >
              <Select
                options={(!!arrayPlatform?.length && arrayPlatform?.map((item) => (
                  ({
                    label: item.description,
                    value: item.description
                  })
                )))}
                value={clientMoneyStorage ? { label: clientMoneyStorage, value: clientMoneyStorage } : null}
                placeholder="Выберите из списка"
                isDisabled={allDisabled}
                onChange={getHandler(handleChangePlatform)}
              />
            </Field>
          </FormRow>
          {
            !(clientMoneyStorage && clientPortfolio) &&
            <FormRow>
              <Field label="Основание платежа">
                <TextField
                  placeholder="Введите текст"
                  value={reason || ''}
                  onChange={handleChangeReason}
                  disabled={allDisabled}
                />
              </Field>
            </FormRow>
          }
        </FormCol>
        <StyledFormRightCol>
          <StyledPriceChangeCardWrapper>
            <PriceChangeCard
              plain
              label={`Доступно на ${format(new Date(), 'dd.MM.yyyy HH:mm')}`}
              value={((balances && balances.balanceT0 >= 0) && `${getPrettyNumber(balances.balanceT0)} ${balances.currency?.uid}`) || ('00,00 RUB')}
            />
          </StyledPriceChangeCardWrapper>

        </StyledFormRightCol>
      </FormRow>
      {
        (clientMoneyStorage && clientPortfolio && (
          <>
            <FormRow>
              <FormCol flex={1}>
                <FormRow>
                  <Field
                    label="Валюта"
                    hasError={currencyError}
                    caption={currencyTextError}
                  >
                    <Select
                      options={arrayCurrencies?.map((item) => ({ label: item.uid, value: item.uid }))}
                      value={currentCurrency ? { value: currentCurrency, label: currentCurrency } : null}
                      placeholder="Выберите из списка"
                      isDisabled={allDisabled}
                      onChange={getHandler(handleChangeCurrency)}
                      hasError={amountError}
                    />
                  </Field>
                  <Field
                    label="Сумма"
                    hasError={amountError}
                    caption={amountTextError}
                  >
                    <TextField
                      placeholder="Введите сумму"
                      value={numberWithSpaces(currentAmount.value) || ''}
                      onChange={(e) => {
                        currentAmount.onChange(e)
                        setAmountError(false)
                        setAmountTextError('')
                      }}
                      disabled={allDisabled}
                      hasError={amountError}
                      type="text"
                      inputMode="decimal"
                    />
                  </Field>

                  { currentUser?.client?.legalEntity === 'person' &&
                    (
                      <StyledPriceChangeCardWrapper>
                        <PriceChangeCard
                          plain
                          label={`НДФЛ справочно на ${currentNdfl?.dateTime ? format(new Date(currentNdfl?.dateTime), 'dd.MM.yyyy HH:mm') : ''}`}
                          value={getPrettyNumber(currentNdfl?.toBePay)}
                        />
                      </StyledPriceChangeCardWrapper>
                    )
                  }

                  { currentUser?.client?.legalEntity !== 'person' && (
                    <StyledFormRightCol>
                      <Field
                        label="Срок действия поручения до"
                        hasError={expirationDateError}
                        caption={expirationDateTextError}
                      >
                        <DateField
                          placeholder=""
                          value={validity || ''}
                          onChange={handleChangeValidity}
                          minDate={todayDate}
                          maxDate={allDisabled && !canModifyDate ? validity : undefined}
                        />
                      </Field>
                    </StyledFormRightCol>
                  )}


                </FormRow>

              </FormCol>

            </FormRow>

            {
              clientMoneyStorage && clientPortfolio &&
              <FormRow>
                <FormCol flex={1}>
                  <Field label="Комментарий">
                    <TextField
                      placeholder="Введите текст"
                      value={commentary || ''}
                      onChange={handleChangeCommentary}
                      disabled={allDisabled}
                    />
                  </Field>
                </FormCol>

                { currentUser?.client?.legalEntity === 'person' ? (
                  <StyledFormRightCol>
                    <Field
                      label="Срок действия поручения до"
                      hasError={expirationDateError}
                      caption={expirationDateTextError}
                    >
                      <DateField
                        placeholder=""
                        value={validity || ''}
                        onChange={handleChangeValidity}
                        minDate={expirationDate || new Date()}
                        maxDate={allDisabled && !canModifyDate ? validity : undefined}
                      />
                    </Field>
                  </StyledFormRightCol>
                ) :  <StyledFormRightCol></StyledFormRightCol>
                }

              </FormRow>
            }
            {
              !currentCurrency &&
              <FormRow>
                <FormCol flex={1}>
                  <Field label="Основание платежа">
                    <TextField
                      placeholder="Введите текст"
                      value={reason || ''}
                      onChange={handleChangeReason}
                      disabled={allDisabled}
                    />
                  </Field>
                </FormCol>

                <StyledFormRightCol></StyledFormRightCol>

              </FormRow>
            }
          </>
        )) || null
      }
      {
        (currentCurrency?.toLowerCase() === 'rub' && clientMoneyStorage === 'Внебиржевой счет') &&
        <CheckboxField
          label="Срочный платёж"
          caption={'Банком может взиматься дополнительная комиссия за осуществление срочного платежа'}
          onChange={getHandler(handleIsRealTimeSwitchChange)}
          disabled={allDisabled}
          checked={isRealTime}
          style={{marginBottom: '24px'}}
        />
      }
      {
        currentCurrency && (
          <>
            <StyledHeading>
              <BlockHeading title={'Банковские реквизиты'} caption={'Перечисление денежных средств на реквизиты расчетного счета,' +
                ' открытого в банке за пределами РФ, невозможно'} />
            </StyledHeading>
            <FormRow>
              <FormCol flex={1}>
                <Field label="Наименование счета">

                  <CustomAutocompleteSelect
                    variant="outlined"
                    color="secondary"
                    unique
                    size="small"
                    value={accountName || searchStrAccountName || ''}
                    options={StandartAutocompleteArray(getRespPaymentTemplates && getRespPaymentTemplates, [{ templateName: 'Добавить новые реквизиты' }])}
                    error={accountNameError}
                    hasError={accountNameError}
                    onChange={getHandler(handleChangeAccountNameAutocomplete)}
                    onInputChange={getHandler(handleChangeAccountNameAutocompleteInput)}
                    disabled={allDisabled}
                    disabledMain={allDisabled}
                    onDeleteItem={deleteTemplate}
                    specialOptions={['templateName']}
                    placeholder="Введите текст или выберете из списка"
                  />


                </Field>
              </FormCol>
            </FormRow>
            {
              (((currentCurrency && currentCurrency.toLowerCase() === 'rub' && isNewDetails) && (
                <>
                  <FormRow>
                    <Field
                      label="Получатель"
                      hasError={agentNameError}
                      caption={agentNameTextError}
                    >
                      <TextField
                        placeholder="Введите текст"
                        value={agentName || ''}
                        onChange={handleChangeAgentName}
                        disabled={allDisabled || arrayDisabledFields?.agentName}
                      />
                    </Field>
                  </FormRow>
                  <FormRow>
                    <FormCol flex={1}>
                      <Field
                        label="Расчетный счет"
                        hasError={bankAccountError}
                        caption={bankAccountTextError}
                      >
                        <TextField
                          placeholder="Введите текст"
                          value={bankAccount || ''}
                          onChange={handleChangeBankAccount}
                          disabled={allDisabled || arrayDisabledFields?.bankAccount}
                        />
                      </Field>
                    </FormCol>
                    <FormCol flex={1}>
                      <Field
                        label="Банк"
                        hasError={bankNameError}
                        caption={bankNameTextError}
                      >

                        <CustomAutocompleteSelect
                          variant="outlined"
                          color="secondary"
                          unique
                          size="small"
                          error={bankNameError}
                          hasError={bankNameError}
                          disabledMain={allDisabled || arrayDisabledFields?.bankName}
                          disabled={allDisabled || arrayDisabledFields?.bankName}
                          onInputChange={getHandler(handleChangeBankNameAutocompleteInput)}
                          onChange={getHandler(handleChangeBankNameAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: '' }])}
                          value={bankName || searchStrBankName || ''}
                          specialOptions={['name']}
                          placeholder='Введите текст или выберете из списка'
                        />

                      </Field>
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <FormCol flex={1}>
                      <Field
                        label="Корреспондентский счет"
                        hasError={correspondentAccountError}
                        caption={correspondentAccountTextError}
                      >

                        <CustomAutocompleteSelect
                          variant="outlined"
                          color="secondary"
                          unique
                          size="small"
                          error={correspondentAccountError}
                          hasError={bankNameError}
                          isDisabled={allDisabled || arrayDisabledFields?.correspondentAccount}
                          disabledMain={allDisabled || arrayDisabledFields?.correspondentAccount}
                          onInputChange={getHandler(handleChangeCorrespondentAccountAutocompleteInput)}
                          onChange={getHandler(handleChangeCorrespondentAccountAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: '' }])}
                          value={correspondentAccount || searchStrCorrespondentAccount || ''}
                          specialOptions={['correspondentAccount', 'name']}
                          placeholder='Выберете из списка'
                            />

                      </Field>
                    </FormCol>
                    <FormCol flex={1}>
                      <Field
                        label="БИК"
                        hasError={bankBicError}
                        caption={bankBicTextError}
                      >

                        <CustomAutocompleteSelect
                          variant="outlined"
                          color="secondary"
                          unique
                          size="small"
                          error={bankBicError}
                          hasError={bankBicError}
                          disabledMain={allDisabled || arrayDisabledFields?.bic}
                          onInputChange={getHandler(handleChangeBicAutocompleteInput)}
                          onChange={getHandler(handleChangeBicAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: '' }])}
                          value={bic || searchStrBic || ''}
                          specialOptions={['bic', 'name']}
                          placeholder='Выберете из списка'
                        />

                      </Field>
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <Field
                      label="Назначение платежа"
                    >
                      <TextField
                        placeholder="Введите текст"
                        value={ground || ''}
                        onChange={handleChangeGround}
                        disabled={allDisabled}
                      />
                    </Field>
                  </FormRow>
                </>
              )) || ((currentCurrency && currentCurrency?.toLowerCase() !== 'rub' && isNewDetails) &&
                <>
                  <FormRow>
                    <Field
                      label="Получатель"
                      hasError={agentNameError}
                      caption={agentNameTextError}
                    >
                      <TextField
                        placeholder="Введите текст"
                        value={agentName || ''}
                        onChange={handleChangeAgentName}
                        disabled={allDisabled || arrayDisabledFields?.agentName}
                      />
                    </Field>
                  </FormRow>
                  <FormRow>
                    <FormCol flex={1}>
                      <BlockHeading title={'Банк получатель'} />
                      <Field
                        label="Расчетный счет"
                        hasError={bankAccountError}
                        caption={bankAccountTextError}
                      >
                        <TextField
                          placeholder="Введите текст"
                          value={bankAccountAddressee || ''}
                          onChange={handleChangeBankAccountAddressee}
                          disabled={allDisabled || arrayDisabledFields?.bankAccountAddressee}
                        />
                      </Field>
                      <Field
                        label="Банк"
                        hasError={bankNameError}
                        caption={bankNameTextError}
                      >

                        <CustomAutocompleteSelect
                          variant="outlined"
                          color="secondary"
                          unique
                          size="small"
                          error={bankNameError}
                          hasError={bankNameError}
                          disabledMain={(allDisabled) || arrayDisabledFields?.addresseeBank}
                          onInputChange={getHandler(handleChangeAddresseeBankAutocompleteInput)}
                          onChange={getHandler(handleChangeAddresseeBankAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: '' }])}
                          value={addresseeBank || searchStrAddresseeBank || ''}
                          specialOptions={['name']}
                          placeholder='Введите текст или выберете из списка'
                        />

                      </Field>
                      <Field
                        label="SWIFT"
                        hasError={bankSwiftError}
                        caption={bankSwiftTextError}
                      >

                        <CustomAutocompleteSelect
                          variant="outlined"
                          color="secondary"
                          unique
                          size="small"
                          error={bankSwiftError}
                          hasError={bankSwiftError}
                          disabledMain={allDisabled || arrayDisabledFields?.bankSwift}
                          onInputChange={getHandler(handleChangeBankSwiftAutocompleteInput)}
                          onChange={getHandler(handleChangeBankSwiftAutocomplete)}
                          options={StandartAutocompleteArray(getRespBanks && getRespBanks?.banks, [{ name: '' }])}
                          value={bankSwift || searchStrBankSwift || ''}
                          specialOptions={['swift', 'name']}
                          placeholder='Введите текст или выберете из списка'
                        />

                      </Field>
                    </FormCol>
                    <FormCol flex={1}>
                      <BlockHeading title={'Банк-корреспондент'} />
                      <Field
                        label="Счет банка получателя"
                        hasError={correspondentAccountError}
                        caption={correspondentAccountTextError}
                      >
                        <TextField
                          placeholder="Введите текст"
                          value={correspondentAccount || ''}
                          onChange={handleChangeCorrespondentAccount}
                          disabled={allDisabled || arrayDisabledFields?.correspondentAccount}
                        />
                      </Field>
                      <Field
                        label="Банк"
                        hasError={correspondentNameError}
                        caption={correspondentNameTextError}
                      >
                        <TextField
                          placeholder="Введите текст"
                          value={correspondentName || ''}
                          onChange={handleChangeCorrespondentName}
                          disabled={allDisabled || arrayDisabledFields?.correspondentName}
                        />
                      </Field>
                      <Field
                        label="SWIFT"
                        hasError={correspondentSwiftError}
                        caption={correspondentSwiftTextError}
                      >
                        <TextField
                          placeholder="Введите текст"
                          value={correspondentSwift || ''}
                          onChange={handleChangeCorrespondentSwift}
                          disabled={allDisabled || arrayDisabledFields?.correspondentSwift}
                        />
                      </Field>
                    </FormCol>
                  </FormRow>
                  <FormRow>
                    <Field
                      label="Назначение платежа"
                    >
                      <TextField
                        placeholder="Введите текст"
                        value={ground || ''}
                        onChange={handleChangeGround}
                        disabled={allDisabled}
                      />
                    </Field>
                  </FormRow>
                </>
                ) || null)
            }
            <FormRow>
              <Field label="Основание платежа">
                <TextField
                  placeholder="Введите текст"
                  value={reason || ''}
                  onChange={handleChangeReason}
                  disabled={allDisabled}
                />
              </Field>
            </FormRow>

          </>
        )
      }
      {
        clientMoneyStorage &&
        <FormFooter>
          <FormFooterActions>
            {
              getRespOutputMoney?.order &&
              <BlockHeading title={`Статус: ${currentStatus?.name}`} caption={currentStatusDescription ? <div dangerouslySetInnerHTML={createMarkup()} /> : ''} />
            }
            {
              !allDisabled && !getRespOutputMoney?.order ?
                <Button variant='tertiary' onClick={() => clearFields(false)} type={'button'}>Очистить форму</Button>
                : null
            }
          </FormFooterActions>
          <FormFooterActions>
            {
              (((currentActions && currentActions?.canModify) || !currentActions)
                && (!getSaveOutputMoney?.requiredAction?.type
                  && !getPerformOutputMoney?.requiredAction?.type
                ) && <>
                  <Button
                    variant='primary'
                    type={'submit'}
                    onClick={() => {
                      setSubmitAction('perform')
                      setShowModalConfirm(true)}}
                    >
                    Исполнить
                  </Button>

                  <Button variant='secondary'
                    type="submit"
                    onClick={draftStatus ? preCopy : () => {
                      setSubmitAction('save')
                    }}
                    disabled={formDisabled}>
                    {draftStatus ? 'Скопировать' : 'Сохранить в черновики'}
                  </Button>

                  {
                    draftStatus && (
                      <Button
                        variant='secondary'
                        type='submit'
                        onClick={() => { setSubmitAction('save') }}
                        disabled={formDisabled}
                      >
                        Сохранить
                      </Button>
                    )
                  }
                </> ||
                (getSaveOutputMoney?.requiredAction?.type === 'sign' || getPerformOutputMoney?.requiredAction?.type === 'sign') &&
                <>
                  <Button
                    variant='primary'
                    type={'submit'}
                    onClick={() => {
                      setSubmitAction('perform')
                      setShowModalConfirm(true)
                    }}
                    >
                    Подписать
                  </Button>
                  <Button
                    variant='primary'
                    type={'button'}
                    onClick={handleDownloadXml}
                  >Скачать .xml</Button>
                </> || (getSaveOutputMoney?.requiredAction?.type === 'code' || getPerformOutputMoney?.requiredAction?.type === 'code' || getSaveOutputMoney?.requiredAction?.type === 'mac' || getPerformOutputMoney?.requiredAction?.type === 'mac') &&
                <>
                  <Button
                    variant='primary'
                    type={'submit'}
                    onClick={() => {
                      setSubmitAction('perform')
                      setShowModalConfirm(true)

                      setCodeError(false)
                      setCodeTextError('')
                    }}
                  >
                    Подтвердить
                  </Button>
                  <Button
                    variant='secondary'
                    type="submit"
                    onClick={handleCancelOperation}
                    >
                    Отменить
                  </Button>
                </> || (currentActions && currentActions?.canCancel) &&
                <>
                  <Button
                    variant='primary'
                    type={'button'}
                    onClick={() => {
                      setCancellationModalOpen(true)
                      setShowModalConfirm(true)
                    }}
                  >
                    Отменить поручение
                  </Button>
                  {
                    (
                      getRespOutputMoney?.order?.status?.uid === 'Sent' ||
                      getRespOutputMoney?.order?.status?.uid === 'Signed' ||
                      getRespOutputMoney?.order?.status?.uid === 'Received' ||
                      getRespOutputMoney?.order?.status?.uid === 'Executed' ||
                      getRespOutputMoney?.order?.status?.uid === 'Cancelled' ||
                      getRespOutputMoney?.order?.status?.uid === 'Rejected'
                    ) &&
                    getRespMetadata?.order?.type?.uid !== cancelType &&
                    <Button
                      variant='secondary'
                      onClick={preCopy}
                      type={'button'}
                    >
                      Скопировать
                    </Button>
                  }
                </> || (currentActions && !currentActions?.canModify && !currentActions?.canCancel) &&
                <>
                  {
                    (
                      getRespOutputMoney?.order?.status?.uid === 'Sent' ||
                      getRespOutputMoney?.order?.status?.uid === 'Signed' ||
                      getRespOutputMoney?.order?.status?.uid === 'Received' ||
                      getRespOutputMoney?.order?.status?.uid === 'Executed' ||
                      getRespOutputMoney?.order?.status?.uid === 'Cancelled' ||
                      getRespOutputMoney?.order?.status?.uid === 'Rejected' ||
                      getRespOutputMoney?.order?.status?.uid === 'Processing'
                    ) &&
                    getRespMetadata?.order?.type?.uid !== cancelType &&
                    <Button
                      variant='secondary'
                      onClick={preCopy}
                      type={'button'}
                    >
                      Скопировать
                    </Button>
                  }
                </>
              )
            }
            {currentStatus?.uid && (
              <Button
                variant='secondary'
                onClick={handleBackToHistory}
              >
                Назад
              </Button>
            )}
          </FormFooterActions>
        </FormFooter>
      }

      <SMSConfirmModal
        isShowImmitPaste
        title={  (getSaveOutputMoney?.order?.type?.uid === cancelType)
          ? "Подтвердите создание поручения на отмену"
          : "Подтвердите создание поручения"}
        hashSha256={hashSha256}
        code={code}
        codeError={codeError}
        codeTextError={codeTextError}
        setCodeError={setCodeError}
        setCodeTextError={setCodeTextError}
        showTimer={showTimer}
        isOpen={ isConfirmModalShow()  && showModalConfirm }
        handleResetTimer={handleResetTimer}
        handleStartTimer={handleStartTimer}
        handleClose={handleCloseSmsModal}
        handleChangeCodeInput={handleChangeCodeInput}
        handleSubmit={() => {
          setSubmitAction('perform');
          setConfirmModalStatus(true)
        }}
      />

    </Form>
    <CustomModal
      open={deleteModalOpen}
      title={`Удалить черновик поручения?`}
      actionText="Отменить это действие будет невозможно"
      textOk="Да, удалить"
      handleClose={handleCloseDeleteModal}
      callbacks={{
        withOk: handleDeleteOk,
        withCancel: handleCloseDeleteModal,
      }}
    />

    <CustomModal
      open={saveModalOpen}
      title="Поручение успешно создано."
      handleClose={handleCloseSaveModal}
      actionText="Хотите создать новое?"
      textOk="Да, создать"
      actionReject={() => handleActionReject('saveModal')}
      actionSuccess={handleActionSuccess}
      disableOverlay
    />
    <CustomModal
      open={updModalOpen}
      title="Поручение обновлено"
      handleClose={handleCloseUpdModal}
      closeInterval={1800}
    />
    <CustomModal
      open={performModalOpen}
      title="Поручение успешно подписано"
      handleClose={handleClosePerformModal}
      actionText="Хотите создать новое?"
      textOk="Да, создать"
      actionReject={() => handleActionReject('performModal')}
      actionSuccess={handleActionSuccess}
      disableOverlay
    />
    <CustomModal
      open={cancellationModalOpen}
      title="Создать поручение на отмену"
      handleClose={handleCloseCancellationModal}
      textOk="Да, создать"
      callbacks={{
        withOk: handleCancelOk,
        withCancel: () => {
          handleCloseCancellationModal()
        },
      }}
    />
    <CustomModal
      open={deletePaymentTemplateModalOpen}
      title={`Удалить счет (${getRespPaymentTemplates?.find((elem) => elem.id === deletePaymentTemplateId)?.templateName}) из списка?`}
      handleClose={handleCloseDeletePaymentTemplateModal}
      callbacks={{
        withOk: handleDeletePaymentTemplateOk,
        withCancel: () => {
          handleCloseDeletePaymentTemplateModal()
        },
      }}
    />
  </>
}

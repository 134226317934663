import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchRecoveryStartAsync } from '../../redux/user/userSlice'
import { CustomModal } from '../../ui/components/organisms/CustomModal'
import {
  StyledLoginTitle,
  StyledLabel,
  StyledButton,
  StyledLabelSecond, StyledLoginSecondWrapper, StyledFieldSecond,
} from '../loginRedesign/styled'
import { Button, Text, TextField } from '../../ui/components'

const Recovery = ({ fetching, fetchRecoveryStart }) => {
  const history = useHistory()
  const [username, setUsername] = useState('')
  const [open, setOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    if (!username) {
      return
    }
    const response = await fetchRecoveryStart(username)
    if (response?.status === 403) {
      return
    }
    const requiredAction = response?.requiredAction
    if (requiredAction) {
      setOpen(true)
      setModalTitle(requiredAction?.cause)
    }
  }

  const handleCloseInfoModal = () => {
    setOpen(false)
    history.push('/login')
  }

  const handleChangeInput = (event) => {
    setUsername(event.target.value)
  }

  const handleClickPrev = () => {
    history.push('/')
  }

  return (
    <>
      <StyledLoginSecondWrapper>

        <StyledLoginTitle>
          <Text
            variant="h1"
            color="primary"
          >
            Восстановление пароля
          </Text>
        </StyledLoginTitle>

        <form
          onSubmit={handleSubmitForm}
        >

          <StyledLabelSecond>
            <Text
              variant="body2"
              color="primary"
            >
              На вашу почту будет отправлено сообщение для восстановления пароля
            </Text>
          </StyledLabelSecond>

          <StyledLabel>
            <Text
              variant="body2"
              color="secondary"
            >
              Логин
            </Text>
          </StyledLabel>

          <StyledFieldSecond>
            <TextField
              onChange={handleChangeInput}
              value={username}
              name="username"
              type="email"
            />
          </StyledFieldSecond>

          <StyledButton>
            <Button
              type="submit"
              disabled={fetching}
            >
              Восстановить пароль
            </Button>
          </StyledButton>

          <StyledButton>
            <Button
              type="submit"
              variant="secondary"
              onClick={handleClickPrev}
            >
              Назад
            </Button>
          </StyledButton>

        </form>

        <CustomModal
          open={open}
          title="Проверьте электронную почту"
          actionText={modalTitle}
          handleClose={handleCloseInfoModal}
          callbacks={{
            withClose: handleCloseInfoModal,
          }}
        />

      </StyledLoginSecondWrapper>
    </>
  )
}

Recovery.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetchRecoveryStart: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user: { isFetching } }) => ({
  fetching: isFetching,
})

const mapDispatchToProps = (dispatch) => ({
  fetchRecoveryStart: (login) => dispatch(fetchRecoveryStartAsync({ login })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Recovery)

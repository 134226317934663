import { createSelector } from '@reduxjs/toolkit'

const selectOutputMoney = (state) => state.outputMoney

export const selectGetOutputMoney = createSelector(
  [selectOutputMoney],
  (state) => state.getOutputMoney?.data,
)

export const selectSaveOutputMoney = createSelector(
  [selectOutputMoney],
  (state) => state.saveOutputMoney?.data,
)
export const selectSaveOutputMoneyStatus = createSelector(
  [selectOutputMoney],
  (state) => state.saveOutputMoney?.status,
)

export const selectPerformOutputMoney = createSelector(
  [selectOutputMoney],
  (state) => state.performOutputMoney?.data,
)
export const selectPerformOutputMoneyStatus = createSelector(
  [selectOutputMoney],
  (state) => state.performOutputMoney?.status,
)

export const selectCurrentBanks = createSelector(
  [selectOutputMoney],
  (state) => state.banks?.data,
)

export const selectPaymentTemplates = createSelector(
  [selectOutputMoney],
  (state) => state.paymentTemplates?.data?.templates,
)

import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Media from 'react-media'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import classnames from 'classnames'
import Box from '@material-ui/core/Box'
import useConnect from '../../utils/Connect'
import CustomInputSelect from '../CustomControls/CustomInputSelect'
import {
  selectDocument,
  selectGetDocumentBrokerageServices,
  selectGetDocumentsBrokerageServicesTypes, selectGetJsonFields, selectSaveDocument,
} from '../../redux/documents/brokerageServices/selectop'
import {
  fetchDocumentBrokerageServicesStartAsync,
  fetchDocumentsBrokerageServicesTypesStartAsync,
  fetchJsonFieldsAsync,
  fetchPostDocumentAsync,
  getDocumentsBrokerageServicesTypesSuccess,
  getJsonFieldsSuccess,
  getDocumentBrokerageServicesSuccess,
  fetchJsonFieldsForTypeAsync,
  getJsonFieldsForTypeSuccess,
  fetchPostSaveDocumentAsync,
  saveDocumentSuccess, performDocumentSuccess, clearDocumentStore, clearDocument,
} from '../../redux/documents/brokerageServices/documentsBrokerageServicesSlece'
import endpoints from '../../api/endpoints'
import CustomInput from '../CustomControls/CustomInput'
import FieldsGenerator from '../../utils/FieldsGenerator/FieldsGenerator'
import CustomIconButton from '../CustomControls/CustomIconButton'
import formAction from '../../utils/FormAction'
import { selectConnectionState } from '../../redux/connection/selector'
import CollapseBar from '../CollapseBar/CollapseBar'
import useStyles from './NewDocument.style'
import { selectCurrentThumbprint } from '../../redux/user/selector'
import { ReactComponent as DownloadIcon } from '../../assets/images/icon-button-download.svg'
import Timer from '../../utils/Timer'
import CustomButton from '../CustomControls/CustomButton'
import CustomModal from '../CustomModal/CustomModal'
import DropZone from '../DropZone'
import { selectGetFilesData, selectPostFilesData } from '../../redux/file/selector'
import {
  fetchGetFileStartAsync,
  fetchPostFileStartAsync,
  getFileSuccess,
  postFileSuccess,
} from '../../redux/file/fileSlice'
import { encodeToUTF8 } from '../../utils/EncodeToUTF8'
import { sortFieldsCategories } from './Helpers/sortFieldsCategories'
import useArrayConnect from '../../utils/ArrayConnect'

const cancelType = 'Orders.Type.Modify.Cancellation'

const NewDocument = () => {
  const requestObject = {}

  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const urlParams = useParams()
  const documentIdPath = useMemo(() => urlParams.id, [urlParams])

  const [activeId, setActiveId] = useState('')
  const [values, setValues] = useState({})
  const [documentTypeName, setDocumentTypeName] = useState('')
  const [documentTypeUid, setDocumentTypeUid] = useState('')
  const [statementText, setStatementText] = useState('')
  const [formDisabled, setFormDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [submitAction, setSubmitAction] = useState('')
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [codeTextError, setCodeTextError] = useState('')
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [currentActions, setCurrentActions] = useState(null)
  const [isCleared, setIsCleared] = useState(false)
  const [transferHeader, setTransferHeader] = useState('')
  const [actualStatus, setActualStatus] = useState('')
  const documentId = useMemo(() => urlParams.id, [urlParams])
  const [showTimer, setShowTimer] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [updModalOpen, setUpdModalOpen] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [allDisabled, setAllDisabled] = useState(false)
  const [uniqueCategory, setUniqueCategory] = useState([])
  const [finalObject, setFinalObject] = useState({})

  const [idFiles, setIdFiles] = useState([])
  const [files, setFiles] = useState([])
  const [failedEmptyFile, setFailedEmptyFile] = useState(false)
  const [failedLoadModalOpen, setFailedLoadModalOpen] = useState(false)

  const fetchDocumentsBrokerageServicesTypesStart = () => {
    dispatch(fetchDocumentsBrokerageServicesTypesStartAsync())
  }
  const fetchJsonFieldsStart = () => {
    dispatch(fetchJsonFieldsAsync())
  }
  const fetchJsonFieldsForTypeStart = (documentType) => {
    dispatch(fetchJsonFieldsForTypeAsync(documentType))
  }
  const fetchPostDocumentStart = (data, id) => {
    dispatch(fetchPostDocumentAsync({ data, id }))
  }
  const fetchDocumentBrokerageServicesStart = (id) => {
    dispatch(fetchDocumentBrokerageServicesStartAsync({ id }))
  }
  const fetchPostSaveDocumentStart = (data, id) => {
    dispatch(fetchPostSaveDocumentAsync({ data, id }))
  }
  const fetchPostFileStart = (obj) => {
    dispatch(fetchPostFileStartAsync({ obj }))
  }

  const fetchGetFileStart = (id) => {
    dispatch(fetchGetFileStartAsync({ id }))
  }

  const { getData } = useConnect({
    link: `GET /api${endpoints.documents.getDocument(activeId)}`,
    startFetching: fetchDocumentBrokerageServicesStart,
    action: getDocumentBrokerageServicesSuccess,
  })

  const { getData: documentsTypes } = useConnect({
    link: `GET /api${endpoints.documents.getExchangeRequestsTypelist}`,
    startFetching: fetchDocumentsBrokerageServicesTypesStart,
    action: getDocumentsBrokerageServicesTypesSuccess,
  })

  const { getData: JsonFieldsForType } = useConnect({
    link: `GET /api${endpoints.documents.getExchangeRequestsJsonFieldsForType(documentTypeUid)}`,
    startFetching: fetchJsonFieldsForTypeStart,
    action: getJsonFieldsForTypeSuccess,
  })

  const { getData: getJsonFields } = useConnect({
    link: `GET /api${endpoints.documents.getExchangeRequestsJsonFields}`,
    startFetching: fetchJsonFieldsStart,
    action: getJsonFieldsSuccess,
  })

  const { getData: postDocumentData } = useConnect({
    link: `POST /api${endpoints.documents.postDocument(false)}`,
    startFetching: fetchPostDocumentStart,
    action: performDocumentSuccess,
  })

  const { getData: putDocumentData } = useConnect({
    link: `PUT /api${endpoints.documents.putDocument(null, activeId, false)}`,
    startFetching: fetchPostDocumentStart,
    action: performDocumentSuccess,
  })

  const { getData: postSaveDocumentData } = useConnect({
    link: `POST /api${endpoints.documents.postDocument(false)}`,
    startFetching: fetchPostSaveDocumentStart,
    action: saveDocumentSuccess,
  })

  const { getData: putSaveDocumentData } = useConnect({
    link: `PUT /api${endpoints.documents.putDocument(null, activeId, false)}`,
    startFetching: fetchPostSaveDocumentStart,
    action: saveDocumentSuccess,
  })

  const { getData: postFileData } = useConnect({
    link: `POST /api${endpoints.file.postFile}`,
    startFetching: fetchPostFileStart,
    action: postFileSuccess,
  })

  const { getData: getFilesData } = useArrayConnect({
    link: `GET /api${endpoints.file.getFile()}`,
    idList: idFiles,
    startFetching: fetchGetFileStart,
    action: getFileSuccess,
  })

  const getRespDocumentsTypes = useSelector(selectGetDocumentsBrokerageServicesTypes)
  const getRespJsonFields = useSelector(selectGetJsonFields)
  const connectedWS = useSelector(selectConnectionState)
  const getRespDocument = useSelector(selectGetDocumentBrokerageServices)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const getPerformDocument = useSelector(selectDocument)
  const getSaveDocument = useSelector(selectSaveDocument)
  const respPostFileData = useSelector(selectPostFilesData)
  const respGetFileData = useSelector(selectGetFilesData)

  const processArray = (array) => {
    array.forEach(async (fileId) => {
      await getFilesData(fileId)
    })
  }

  useEffect(() => {
    if (connectedWS) {
      if (getRespDocument?.files) {
        const filesId = getRespDocument?.files?.map((m) => m?.id)
        setIdFiles(filesId)
        processArray(filesId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRespDocument])

  useEffect(() => {
    if (respGetFileData.length && connectedWS) {
      const arrayFileObjects = [...files]
      // eslint-disable-next-line array-callback-return
      respGetFileData.map((file) => {
        if (arrayFileObjects?.every((s) => s.id !== file.id)) {
          arrayFileObjects.push({
            data: file.fileData,
            file: {
              name: file.fileName,
              path: file.fileName,
              size: file.fileLength,
            },
            id: file.id,
          })
        }
        setFiles(arrayFileObjects)
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [respGetFileData])

  const StandartAutocompleteArray = (array, startArray) => {
    const correctStartArray = startArray || []
    return array?.length ? correctStartArray.concat(array) : correctStartArray
  }

  const clearFields = () => {
    dispatch(clearDocumentStore())
    setIsCleared(true)

    setActiveId('')
    setValues({})
    setDocumentTypeName('')
    setDocumentTypeUid('')
    setStatementText('')
    setFormDisabled(false)
    setIsLoading(false)
    setSubmitAction('')
    setCode('')
    setCodeError(false)
    setCodeTextError('')
    setCancellationModalOpen(false)
    setCurrentActions(null)
    setTransferHeader('')
    setActualStatus('')
    setPerformModalOpen(false)
    setUpdModalOpen(false)
    setSaveModalOpen(false)
    setFiles([])
    setFailedEmptyFile(false)
    setFailedLoadModalOpen(false)
    setAllDisabled(false)
    setUniqueCategory([])
    setFinalObject({})
    setIdFiles([])

    documentsTypes()
    getJsonFields()
  }

  const handleAddedFile = (addedFiles) => {
    if (!addedFiles.length) {
      setFiles([])
      return
    }
    const arrayFiles = [...files]
    addedFiles.forEach((file) => {
      const obj = {
        fileName: file.file.name,
        fileData: file.data.split('base64,')[1],
        fileSize: file.file.size,
      }
      postFileData(obj)
      arrayFiles.push(file)
    })
    setTimeout(() => {
      setFiles(arrayFiles)
      setIsLoading(false)
    }, 1000)
  }

  const handleRemoveFile = (removedFile) => {
    if (allDisabled) return
    const newArr = files.filter((f) => f !== removedFile)
    const indexRemovedFile = files.findIndex((f) => f === removedFile)
    setFiles(newArr)
    if (indexRemovedFile !== -1) {
      const arrayIdFiles = [...idFiles]
      arrayIdFiles.splice(indexRemovedFile, 1)
      setIdFiles(arrayIdFiles)
    }
  }

  const handleChangeDocumentType = (event, selectedValue) => {
    const selectedDocumentType = getRespDocumentsTypes.find((e) => e.name === selectedValue)
    setDocumentTypeName(selectedValue)
    setDocumentTypeUid(selectedDocumentType?.uid)
  }
  const handleChangeStatementText = (event) => {
    setStatementText(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const formKeys = Object.keys(event?.target)

    // eslint-disable-next-line array-callback-return
    formKeys.map((e) => {
      if (event.target[e].offsetParent?.offsetParent?.offsetParent?.dataset?.fieldName) {
        requestObject[event.target[e].offsetParent.offsetParent.offsetParent.dataset.fieldName] = event.target[e].value
      }
      if (event.target[e].offsetParent?.offsetParent?.dataset?.fieldName) {
        requestObject[event.target[e].offsetParent.offsetParent.dataset.fieldName] = event.target[e].checked
      }
    })

    const finalRequestObject = {}

    // eslint-disable-next-line no-unused-expressions
    Object.keys(requestObject)?.forEach((el) => { if (!requestObject[el]) delete requestObject[el] })

    const details = JSON.stringify(requestObject)

    const responceIds = idFiles?.map((m) => ({ id: m }))

    finalRequestObject.type = { uid: documentTypeUid }
    finalRequestObject.text = statementText
    finalRequestObject.files = responceIds
    finalRequestObject.details = details

    const msgData = getSaveDocument || getPerformDocument
    const performDocumentId = msgData?.document?.id

    formAction({
      submitAction: 'perform',
      requestObj: finalRequestObject,
      typeUID: documentTypeUid,
      msgData,
      putPerformData: putDocumentData,
      postPerformData: postDocumentData,
      putSaveData: putSaveDocumentData,
      postSaveData: postSaveDocumentData,
      activeId: activeId || performDocumentId,
      code,
      setCodeError,
      setCodeTextError,
      setCancellationModalOpen,
      setFormDisabled,
      setIsLoading,
      currentThumbPrint,
    })
  }

  const handleBackToHistory = () => {
    history.push('/documents/brokerageServices/documents')
  }

  const handleChangeCodeInput = (event) => {
    setCode(event.target.value)
    setCodeError(false)
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const msgData = getSaveDocument || getPerformDocument
    const typeUID = msgData?.requiredAction && msgData?.order?.type?.uid === cancelType
      ? cancelType : documentTypeUid

    const finalRequestObject = {}
    const details = JSON.stringify(requestObject)

    const responceIds = idFiles?.map((m) => ({ id: m }))

    finalRequestObject.type = { uid: documentTypeUid }
    finalRequestObject.text = statementText
    finalRequestObject.files = responceIds
    finalRequestObject.details = details

    formAction({
      submitAction: 'perform', requestObj: finalRequestObject, typeUID, putPerformData: putDocumentData, postPerformData: postDocumentData, activeId,
    })
  }

  const handleClosePerformModal = (goToHistory) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
    setIsLoading(false)
  }

  const handleCloseSaveModal = (goToHistory) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleActionReject = () => {
    handleCloseSaveModal(true)
    handleClosePerformModal(true)
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)
    clearFields(false)
    setActiveId(null)
    setFormDisabled(false)
    // setMetadataUid(documentTypeUid)
    history.replace('/documents/brokerageServices/document/new')
  }

  const handleCloseUpdModal = () => {
    setUpdModalOpen(false)
    handleBackToHistory()
  }

  const handleCancelOk = () => {
    // setMetadataUid(cancelType)
    // getMetaData(cancelType)
    handleCloseCancellationModal()
  }

  const handleCancelOperation = () => {
    dispatch(clearDocument())
  }

  const handleDownloadXml = () => {
    const msgData = getSaveDocument || getPerformDocument
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.document?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  useEffect(() => {
    getJsonFields()
    documentsTypes()
  }, [])

  useEffect(() => {
    if (documentIdPath !== 'new') {
      getData(documentIdPath)
      setActiveId(documentIdPath)
    }
  }, [documentIdPath])

  useEffect(() => {
    JsonFieldsForType(documentTypeUid)

    const validFields = getRespDocument ? Object.keys(JSON.parse(getRespDocument?.details)) : []

    const sortFields = (getRespDocument?.status?.uid === 'Signed') ? getRespJsonFields?.filter((el) => validFields?.some((s) => s === el.fieldName)) : getRespJsonFields?.filter((el) => el.exchangeRequestTypeUID === documentTypeUid)

    const result = sortFieldsCategories(sortFields)
    setUniqueCategory(result.uniqueCategory)
    setFinalObject(result.finalObject)
  }, [documentTypeUid])

  useEffect(() => {
    if (connectedWS) {
      clearFields()
      setActiveId(null)
      if (documentId === 'new') {
        setIsLoading(false)
        clearFields()
      } else if (documentId) {
        getData(documentId)
        setActiveId(documentId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, connectedWS])

  useEffect(() => {
    if (isCleared) {
      if (getRespDocument) {
        setIsLoading(false)
        if (getRespDocument?.type?.name === documentTypeName) {
          /*     if (getRespMetadata?.order?.type?.uid === cancelType) {
            const cancelDate = getRespMetadata?.order?.expirationDate
            const reqObj = {
              affectedOrder: { id: +activeId },
              type: { uid: cancelType },
              expirationDate: cancelDate && format(new Date(cancelDate), 'yyyy-MM-dd'),
            }
            postPerformData(reqObj)
          } */
          // }
          // eslint-disable-next-line no-underscore-dangle
          // setCurrentActions(getRespDocument?.order?._links[0])
          setTransferHeader(`${getRespDocument?.name}`)
          setActualStatus(`${getRespDocument?.status?.name}`)
          // }
          // else if (documentId !== 'new') {
          //   setIsLoading(true)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [getRespDocument, isCleared])

  useEffect(() => {
    if (connectedWS && isCleared) {
      if (getSaveDocument?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveDocument?.document?.id)
        }
        if (getSaveDocument?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
      }
      if (getPerformDocument?.requiredAction) {
        if (!activeId || +activeId !== getPerformDocument?.exchangeRequest?.id) {
          setActiveId(getPerformDocument?.document?.id)
        }
        if (getPerformDocument?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }

        // setCurrentStatus(getPerformDocument?.order?.status)
      }
      const msgData = getSaveDocument || getPerformDocument
      // const typeUID = ((msgData?.requiredAction && msgData?.order?.type?.uid === cancelType)
      //   || getRespDocument?.order?.type?.uid === cancelType) ? cancelType : documentTypeName
      if (msgData?.document?.name) {
        setTransferHeader(`${msgData?.document?.name}`)
        setActualStatus(`${msgData?.document?.status?.name}`)
      }
      // if (typeUID) {
      //   setIsLoading(false)
      // }
      // eslint-disable-next-line no-underscore-dangle
      // setCurrentActions(msgData?.order?._links[0])
      //
      // Нужно вернуть order!!!
      if (getSaveDocument?.status === 200 || getPerformDocument?.status === 200) {
        switch (getSaveDocument?.document?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (activeId) {
              setUpdModalOpen(true)
            } else {
              setSaveModalOpen(true)
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaveDocument, getPerformDocument, isCleared])

  useEffect(() => {
    const fieldValues = getRespDocument ? JSON.parse(getRespDocument?.details) : ''
    if (Object.keys(fieldValues).length) setValues(fieldValues)

    setStatementText(getRespDocument?.text)

    const TypeName = getRespDocumentsTypes.find((e) => e.uid === getRespDocument?.type?.uid)?.name || ''
    setDocumentTypeName(TypeName)
    setDocumentTypeUid(getRespDocument?.type?.uid)
  }, [getRespDocument])

  useEffect(() => {
    if (typeof getRespDocument?.canModify === 'boolean') setAllDisabled(!getRespDocument?.canModify)
  }, [getRespDocument])

  useEffect(() => {
    if (respPostFileData?.id && !idFiles.some((s) => s === respPostFileData?.id)) {
      setIdFiles((props) => [...props, respPostFileData?.id])
    }
  }, [respPostFileData])

  return (
    <Box className={classnames(classes.wrapper, classes.wrapper_unique)}>
      <form onSubmit={handleSubmit}>

        {transferHeader && (
          <Typography className={classes.transferHeader}>
            {transferHeader}
          </Typography>
        )}

        <CustomInputSelect
          variant="outlined"
          color="secondary"
          size="small"
          unique
          label="Тип Документа"
          options={StandartAutocompleteArray(getRespDocumentsTypes && getRespDocumentsTypes.map((e) => e.name), [''])}
          value={documentTypeName || ''}
          onChange={handleChangeDocumentType}
          disabledMain={allDisabled}
        />
        <CustomInput
          variant="outlined"
          color="secondary"
          label="Текст заявления"
          value={statementText || ''}
          onChange={handleChangeStatementText}
          disabled={allDisabled}
          unique
        />

        <Grid
          item
          xs={12}
          className={classes.fileInputWrapper}
        >
          <FormLabel>
            Скан-копия документа
          </FormLabel>
          <div>
            {(!allDisabled && (
              <DropZone
                onAdd={(addedFile) => handleAddedFile(addedFile)}
                onDrop={() => setIsLoading(true)}
                onDelete={(deletedFile) => handleRemoveFile(deletedFile)}
                fileObjects={files}
                maximumNumberofFiles={555}
                isLoading={isLoading}
                readOnly={isLoading}
              />
            )) || (
            <DropZone
              fileObjects={files}
              maximumNumberofFiles={555}
              readOnly
            />
            )}
          </div>
        </Grid>

        {finalObject && uniqueCategory?.filter((e) => !e).map((e) => (
          <Grid
            container
            spacing={2}
            justify="space-between"
            className={classes.otherFieldsContainer}
          >
            {FieldsGenerator(finalObject[e], values, setValues, allDisabled)}
          </Grid>
        ))}
        {finalObject && uniqueCategory?.filter(Boolean).map((e) => (
          <CollapseBar
            className={classes.accordion}
            label={e}
          >
            <Grid
              container
              spacing={2}
              justify="space-between"
            >
              {FieldsGenerator(finalObject[e], values, setValues, allDisabled)}
            </Grid>
          </CollapseBar>
        ))}

        {(getSaveDocument?.requiredAction?.type === 'code' || getPerformDocument?.requiredAction?.type === 'code') && (
          <>
            <Grid
              item
              lg={4}
              sm={6}
              xs={12}
            >
              <CustomInput
                error={codeError}
                errorText={codeTextError}
                variant="outlined"
                label="Введите код из сообщения"
                name="code"
                value={code}
                onChange={handleChangeCodeInput}
                size="small"
                color="secondary"
                unique
                className={classes.inputField}
                autoComplete="off"
              />
            </Grid>
            <Grid
              item
              lg={8}
              sm={6}
              xs={12}
              className={classes.Transfer__codeWrapper}
            >
              {(showTimer && (
                <Typography
                  className={classes.Transfer__codeText}
                  m={0}
                >
                  Если код не пришел, Вы можете запросить новый код через
                  <span
                    className={classes.Transfer__codeInput}
                  >
                    <Timer
                      seconds={30}
                      handleStartTimer={handleStartTimer}
                    />
                  </span>
                </Typography>
              )) || (!showTimer && (
                <CustomButton
                  label="Выслать новый код"
                  modifier="primary"
                  type="submit"
                  fullWidth
                  onClick={handleResetTimer}
                />
              ))}
            </Grid>
          </>
        )}

        <Grid
          item
          lg={6}
          sm={6}
          xs={12}
          className={classes.TransferStatus}
        >
          {actualStatus && (
            <div>
              <Typography className={classes.actualStatus}>
                {`Статус: ${actualStatus}`}
              </Typography>
            </div>
          )}
        </Grid>

        {(((currentActions && currentActions?.canModify) || !currentActions)
          && (!getSaveDocument?.requiredAction?.type
            && !getPerformDocument?.requiredAction?.type
          ) && (
            <Grid
              item
              lg={12}
              sm={12}
              xs={12}
              className={classes.btnGroup}
            >
              <CustomIconButton
                type="submit"
                onClick={() => { setSubmitAction('perform') }}
                disabled={formDisabled || isLoading}
              >
                Исполнить
              </CustomIconButton>
              <CustomIconButton
                type="submit"
                onClick={() => { setSubmitAction('save') }}
                disabled={formDisabled || isLoading}
              >
                Сохранить
              </CustomIconButton>
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <CustomIconButton
                    type="button"
                    disabled={isLoading}
                    onClick={handleBackToHistory}
                  >
                    Назад
                  </CustomIconButton>
                )}
              />
            </Grid>
        )) || ((getSaveDocument?.requiredAction?.type === 'sign' || getPerformDocument?.requiredAction?.type === 'sign') && (
        <Grid
          item
          lg={12}
          sm={12}
          xs={12}
          className={classes.btnGroup}
        >
          <CustomIconButton
            onClick={() => { setSubmitAction('perform') }}
            type="submit"
            disabled={isLoading}
          >
            Подписать
          </CustomIconButton>
          <CustomIconButton
            type="button"
            disabled={isLoading}
            icon={<DownloadIcon />}
            onClick={handleDownloadXml}
          >
            Скачать .xml
          </CustomIconButton>
          <Media
            query="(min-width: 577px)"
            render={() => (
              <CustomIconButton
                type="button"
                disabled={isLoading}
                onClick={handleCancelOperation}
              >
                Назад
              </CustomIconButton>
            )}
          />
        </Grid>
        )) || ((getSaveDocument?.requiredAction?.type === 'code' || getPerformDocument?.requiredAction?.type === 'code') && (
        <Grid
          item
          lg={6}
          sm={6}
          xs={12}
          className={classes.btnGroup}
        >
          <CustomIconButton
            type="submit"
            onClick={() => { setSubmitAction('perform') }}
          >
            Подтвердить
          </CustomIconButton>
          <Media
            query="(min-width: 577px)"
            render={() => (
              <CustomIconButton
                type="button"
              >
                Отменить
              </CustomIconButton>
            )}
          />
        </Grid>
        )) || ((currentActions && currentActions?.canCancel) && (
        <Grid
          item
          lg={6}
          sm={6}
          xs={12}
          className={classes.btnGroup}
        >
          <CustomIconButton
            onClick={() => setCancellationModalOpen(true)}
          >
            Отменить поручение
          </CustomIconButton>
          <Media
            query="(min-width: 577px)"
            render={() => (
              <CustomIconButton
                type="button"
                onClick={handleBackToHistory}
              >
                Назад
              </CustomIconButton>
            )}
          />
        </Grid>
        ))
          || ((currentActions && !currentActions?.canModify && !currentActions?.canCancel)
            && (
              <Media
                query="(min-width: 577px)"
                render={() => (
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                    className={classes.btnGroup}
                  >
                    <CustomIconButton
                      type="button"
                      onClick={handleBackToHistory}
                    >
                      Назад
                    </CustomIconButton>
                  </Grid>
                )}
              />
            ))}

      </form>
      <CustomModal
        open={saveModalOpen}
        title="Документ успешно создан."
        handleClose={handleCloseSaveModal}
        actionText="Хотите создать новый?"
        actionReject={handleActionReject}
        actionSuccess={handleActionSuccess}
        disableOverlay
      />
      <CustomModal
        open={updModalOpen}
        title="Документ обновлен"
        handleClose={handleCloseUpdModal}
      />
      <CustomModal
        open={performModalOpen}
        title="Документ успешно подписан"
        handleClose={handleClosePerformModal}
        actionText="Хотите создать новый?"
        actionReject={handleActionReject}
        actionSuccess={handleActionSuccess}
        disableOverlay
      />
      <CustomModal
        open={cancellationModalOpen}
        title="Создать документ на отмену"
        handleClose={handleCloseCancellationModal}
        callbacks={{
          withOk: handleCancelOk,
          withCancel: () => {
            handleCloseCancellationModal()
          },
        }}
      />
      <CustomModal
        open={failedEmptyFile}
        title="Не удалось загрузить пустой файл"
        handleClose={() => setFailedEmptyFile(false)}
      />
      <CustomModal
        open={failedLoadModalOpen}
        title="Не удалось загрузить файл"
        handleClose={() => setFailedLoadModalOpen(false)}
      />
    </Box>
  )
}
export default NewDocument

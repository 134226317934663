import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { usePagination } from '@material-ui/lab/Pagination'
import cuid from 'cuid'
import useStyles from './Pagination.styles'
import { ReactComponent as LeftIcon } from '../../assets/images/icon-nav-left.svg'

const UsePagination = ({
  count,
  currentPage,
  onChangePage,
}) => {
  const classes = useStyles()
  const { items } = usePagination({
    count,
  })
  let activePage = currentPage
  const handleChangePage = (page) => {
    if (page < 0) return
    if (page > count) return
    activePage = page
    onChangePage(page)
  }

  return (
    <>
      {((count && count > 0) && (
      <nav className={classes.Pagination__container}>
        <ul className={classes.Pagination__list}>
          {items.map(({
            type,
          }, index) => {
            let children = null

            if (type === 'previous') {
              children = (
                <button
                  className={classes.Pagination__buttons}
                  type="button"
                  onClick={() => handleChangePage(activePage - 1)}
                >
                  <LeftIcon />
                </button>
              )
            } else if (type === 'next') {
              children = (
                <button
                  className={classes.Pagination__buttons}
                  type="button"
                  onClick={() => handleChangePage(activePage + 1)}
                >
                  <LeftIcon className={classes.Pagination__revertIcon} />
                </button>
              )
            }

            return (
              <li key={index.toString() + cuid()}>
                {children}
              </li>
            )
          })}
          {`Страница ${currentPage + 1} из ${count + 1}`}
        </ul>
      </nav>
      )) || null}
    </>
  )
}

UsePagination.defaultProps = {
  count: PropTypes.number,
}

UsePagination.propTypes = {
  count: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
}

export default connect(null, null)(UsePagination)

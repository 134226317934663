import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem } from '@material-ui/core'
import { format } from 'date-fns'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import Typography from '@material-ui/core/Typography'
import Media from 'react-media'
import { useHistory } from 'react-router-dom'
import CustomAutocomplete from '../CustomControls/CustomAutocomplete'
import useStyles from './panel.style'
import CustomIconButton from '../CustomControls/CustomIconButton'
import CustomDatepicker from '../CustomControls/CustomDatepicker'
import CustomInput from '../CustomControls/CustomInput'
import CustomSelect from '../CustomControls/CustomSelect'
import CustomSwitch from '../CustomControls/CustomSwitch'
import { ReactComponent as ArrowTopFullUnique } from '../../assets/images/arrow-top-full-unique.svg'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import { fetchMetadataStartAsync, getMetadataSuccess } from '../../redux/metadata/metadataSlice'
import { selectCurrentMetadata } from '../../redux/metadata/selector'
import { selectConnectionState } from '../../redux/connection/selector'
import { selectCurrentSecurities } from '../../redux/securities/selector'
import { fetchSecuritiesStartAsync, getSecuritiesSuccess } from '../../redux/securities/securitiesSlice'
import createUri from '../../utils/FilterUri'
import { fetchPostCalculationsStartAsync, postCalculationsSuccess } from '../../redux/trades/tradesSlice'
import { selectCurrentCalculations } from '../../redux/trades/selector'
import formAction from '../../utils/FormAction'
import {
  selectGetTransferMoney,
  selectPerformTransferMoney,
  selectSaveTransferMoney,
} from '../../redux/transfers/money/selector'
import {
  clearPartTransferMoney, clearTransferMoney,
  fetchPerformTransferMoneyStartAsync,
  fetchSaveTransferMoneyStartAsync,
  performTransferMoneySuccess,
  saveTransferMoneySuccess,
} from '../../redux/transfers/money/transferMoneySlice'
import numberWithSpaces from '../../utils/Money'
import Timer from '../../utils/Timer'
import CustomButton from '../CustomControls/CustomButton'
import { ReactComponent as DownloadIcon } from '../../assets/images/icon-button-download.svg'
import { encodeToUTF8 } from '../../utils/EncodeToUTF8'
import CustomModal from '../CustomModal/CustomModal'
import { fetchPartnersStartAsync, getPartnersSuccess } from '../../redux/partners/partnersSlice'
import { selectCurrentPartners } from '../../redux/partners/selector'
import { selectCurrentError } from '../../redux/error/selector'
import CustomLoader from '../CustomLoader/CustomLoader'
import { selectCurrentThumbprint } from '../../redux/user/selector'

const initialState = {
  issuer: null,
  securitiesSearchStr: '',
  partner: null,
  partnersSearchStr: '',
}

const TradeOrder = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [formDisabled, setFormDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [instrumentType, setInstrumentType] = useState(null)
  const [securitiesNameError, setSecuritiesNameError] = useState(false)
  const [securitiesNameTextError, setSecuritiesNameTextError] = useState('')
  const [clientPortfolioError, setClientPortfolioError] = useState(false)
  const [clientPortfolioTextError, setClientPortfolioTextError] = useState('')
  const [priceError, setPriceError] = useState(false)
  const [priceTextError, setPriceTextError] = useState('')
  const [priceCurrencyError, setPriceCurrencyError] = useState(false)
  const [priceCurrencyTextError, setPriceCurrencyTextError] = useState('')
  const [amountError, setAmountError] = useState(false)
  const [amountTextError, setAmountTextError] = useState('')
  const [settlementCurrencyError, setSettlementCurrencyError] = useState(false)
  const [settlementCurrencyTextError, setSettlementCurrencyTextError] = useState('')
  const [expirationDateError, setExpirationDateError] = useState(false)
  const [expirationDateTextError, setExpirationDateTextError] = useState('')
  const [settlementDateError, setSettlementDateError] = useState(false)
  const [settlementDateTextError, setSettlementDateTextError] = useState('')
  const [partnersNameError, setPartnersNameError] = useState(false)
  const [partnersNameTextError, setPartnersNameTextError] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [codeTextError, setCodeTextError] = useState('')
  const [type, setType] = useState('Orders.Type.Trade.Deal.Buy')
  const [securitiesSearchStr, setSecuritiesSearchStr] = useState(initialState.securitiesSearchStr)
  const [issuer, setIssuer] = useState(initialState.issuer)
  const [clientPortfolio, setClientPortfolio] = useState(null)
  const [priceSwitchState, setPriceSwitchState] = useState(true)
  const [price, setPrice] = useState('')
  const [priceCurrency, setPriceCurrency] = useState('')
  const [amount, setAmount] = useState('')
  const [settlementCurrency, setSettlementCurrency] = useState(null)
  const [settlementCurrencyList, setSettlementCurrencyList] = useState(null)
  const [expirationDate, setExpirationDate] = useState(null)
  const [settlementDate, setSettlementDate] = useState(null)
  const [instruction, setInstruction] = useState('')
  const [partnersSearchStr, setPartnersSearchStr] = useState(
    initialState.partnerSearchStr,
  )
  const [partner, setPartner] = useState(initialState.partner)
  const [showTimer, setShowTimer] = useState(false)
  const [code, setCode] = useState('')
  const [activeId, setActiveId] = useState('')
  const [submitAction, setSubmitAction] = useState('')
  const [allDisabled, setAllDisabled] = useState(false)
  const [currentActions, setCurrentActions] = useState(null)
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false)
  const [saveModalOpen, setSaveModalOpen] = useState(false)
  const [performModalOpen, setPerformModalOpen] = useState(false)
  const [securityInputValue, setSecurityInputValue] = useState(initialState.securitiesSearchStr)

  const fetchSecuritiesStart = (obj) => {
    dispatch(fetchSecuritiesStartAsync(obj))
  }

  const fetchPartnersStart = (obj) => {
    dispatch(fetchPartnersStartAsync(obj))
  }

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchCalculationsStart = (obj) => {
    dispatch(fetchPostCalculationsStartAsync(obj))
  }

  const fetchSaveTransferMoneyStart = (data, id) => {
    dispatch(fetchSaveTransferMoneyStartAsync({ data, id }))
  }

  const fetchPerformTransferMoneyStart = (data, id) => {
    dispatch(fetchPerformTransferMoneyStartAsync({ data, id }))
  }

  const connectedWS = useSelector(selectConnectionState)
  const getRespSecurities = useSelector(selectCurrentSecurities)
  const getRespMetadata = useSelector(selectCurrentMetadata)
  const getCalculations = useSelector(selectCurrentCalculations)?.calculation
  const getRespPartners = useSelector(selectCurrentPartners)
  const getRespTransferMoney = useSelector(selectGetTransferMoney)
  const getSaveTransferMoney = useSelector(selectSaveTransferMoney)
  const getPerformTransferMoney = useSelector(selectPerformTransferMoney)
  const serverError = useSelector(selectCurrentError)
  const currentThumbPrint = useSelector(selectCurrentThumbprint)

  const {
    getData: securitiesFilter,
  } = useConnect({
    link: `GET /api${endpoints.securities.getSecurities()}`,
    startFetching: fetchSecuritiesStart,
    action: getSecuritiesSuccess,
  })

  const {
    getData: partnersFilter,
  } = useConnect({
    link: `GET /api${endpoints.partners.getPartners()}`,
    startFetching: fetchPartnersStart,
    action: getPartnersSuccess,
  })

  const {
    getData: getMetaData,
  } = useConnect({
    link: `GET /api${endpoints.metadata.getMetadata(type)}`,
    startFetching: fetchMetadataStart,
    action: getMetadataSuccess,
  })

  const {
    getData: postCalculations,
  } = useConnect({
    link: `POST /api${endpoints.trades.calculations}`,
    startFetching: fetchCalculationsStart,
    action: postCalculationsSuccess,
  })

  const {
    getData: postSaveData,
  } = useConnect({
    link: `POST /api${endpoints.moneys.postTransferMoney(false)}`,
    startFetching: fetchSaveTransferMoneyStart,
    action: saveTransferMoneySuccess,
  })

  const {
    getData: putSaveData,
  } = useConnect({
    link: `PUT /api${endpoints.moneys.putTransferMoney(null, activeId, false)}`,
    startFetching: fetchSaveTransferMoneyStart,
    action: saveTransferMoneySuccess,
  })

  const {
    getData: postPerformData,
  } = useConnect({
    link: `POST /api${endpoints.moneys.postTransferMoney(false)}`,
    startFetching: fetchPerformTransferMoneyStart,
    action: performTransferMoneySuccess,
  })

  const {
    getData: putPerformData,
  } = useConnect({
    link: `PUT /api${endpoints.moneys.putTransferMoney(null, activeId, false)}`,
    startFetching: fetchPerformTransferMoneyStart,
    action: performTransferMoneySuccess,
  })

  const clearInstrumentType = () => setInstrumentType(null)

  const clearFields = (needClearData = true) => {
    if (needClearData) dispatch(getMetadataSuccess(null))
    setCurrentActions(null)
    dispatch(postCalculationsSuccess(null))
    dispatch(clearTransferMoney())
    setClientPortfolio(null)
    setPrice('')
    setAmount('')
    setExpirationDate(null)
    setSettlementDate(null)
    setInstruction('')
    setPartnersSearchStr('')
    setPartner(initialState.partner)
    setAllDisabled(false)
    setSecuritiesSearchStr('')
    setSecurityInputValue('')
    setCode('')
    setIssuer(initialState.issuer)
  }

  const getTypeUID = () => {
    const msgData = getSaveTransferMoney || getPerformTransferMoney
    return ((msgData?.requiredAction && msgData?.order?.type?.uid === 'Orders.Type.Modify.Cancellation')
      || getRespTransferMoney?.order?.type?.uid === 'Orders.Type.Modify.Cancellation') ? 'Orders.Type.Modify.Cancellation' : type
  }

  const handleBackToHistory = () => {
    history.push('/transfers/money/log')
  }

  useEffect(() => {
    clearFields()
    clearInstrumentType()
    return () => {
      clearFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (connectedWS) {
      clearFields()
    }
    // eslint-disable-next-line
  }, [connectedWS])

  useEffect(() => {
    if (connectedWS) {
      getMetaData(type)
    }
    // eslint-disable-next-line
  }, [connectedWS, type])

  useEffect(() => {
    if (issuer?.id && clientPortfolio) {
      const reqObj = {
        marketBoard: { uid: issuer?.marketBoard?.uid },
        priceType: { uid: priceSwitchState ? 'Orders.Price.Limit' : 'Orders.Price.Market' },
        security: { id: issuer?.id },
        clientPortfolio: { id: +clientPortfolio },
        type: { uid: type },
      }
      if (issuer?.marketBoard?.market?.uid === 'OTC.STOCKS') {
        reqObj.settlementCurrency = {
          uid: settlementCurrency,
        }
        if (priceSwitchState) {
          reqObj.currency = {
            uid: priceCurrency,
          }
        }
        reqObj.volume = amount || 0
      } else {
        reqObj.lotsQuantity = amount || 0
      }
      let currentPrice = null
      if (issuer?.type?.uid === 'Securities.Type.Bonds') {
        currentPrice = (issuer?.faceValue * (parseFloat(price) / 100))
        const priceRelative = parseFloat(price)
        reqObj.price = Number.isNaN(currentPrice) ? null : currentPrice.toFixed(10)
        reqObj.priceRelative = Number.isNaN(priceRelative) ? null : priceRelative.toFixed(1)
      } else {
        currentPrice = (parseFloat(price) || 0)
        reqObj.price = Number.isNaN(currentPrice) ? null : currentPrice.toFixed(1)
      }
      postCalculations(reqObj)
    }
    // eslint-disable-next-line
  }, [issuer, clientPortfolio, price, priceCurrency, amount])

  useEffect(() => {
    if (issuer && getRespMetadata) {
      setExpirationDate(getRespMetadata?.order?.expirationDate
        ? new Date(getRespMetadata.order.expirationDate) : null)
      setSettlementDate(issuer?.settlementDate ? new Date(issuer.settlementDate) : null)
      setExpirationDateError(false)
      setSettlementDateError(false)
      const defaultClientPortfolio = getRespMetadata?.references?.portfolios[0]
      setClientPortfolio(defaultClientPortfolio?.id?.toString() || null)
      setClientPortfolioError(false)
      const defaultCurrency = getRespMetadata?.references?.currencies[0].uid
      setPriceCurrency(issuer?.settlementCurrency?.uid || defaultCurrency)
      setPriceCurrencyError(false)
      const equalClientCurrency = defaultClientPortfolio?.currencies?.find(
        (item) => item.uid === defaultCurrency,
      )
      setSettlementCurrency(
        issuer?.settlementCurrency?.uid
        || (equalClientCurrency
          ? equalClientCurrency?.uid
          : defaultClientPortfolio?.currencies[0].uid),
      )
      setSettlementCurrencyError(false)
      if (getRespMetadata?.order?.priceType?.uid === 'Orders.Price.Market') {
        setPriceSwitchState(false)
      }
    }
    // eslint-disable-next-line
  }, [issuer, getRespMetadata])

  useEffect(() => {
    if (connectedWS) {
      if (getSaveTransferMoney?.requiredAction) {
        if (!activeId) {
          setActiveId(getSaveTransferMoney?.order?.id)
        }
        if (getSaveTransferMoney?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
      }
      if (getPerformTransferMoney?.requiredAction) {
        if (!activeId || +activeId !== getPerformTransferMoney?.order?.id) {
          setActiveId(getPerformTransferMoney?.order?.id)
        }
        if (getPerformTransferMoney?.requiredAction?.type === 'code') {
          setShowTimer(true)
        }
      }
      if (getTypeUID()) {
        setIsLoading(false)
      }
      if (getPerformTransferMoney?.order || getSaveTransferMoney?.order) {
        /* eslint-disable */
        setCurrentActions(
          getPerformTransferMoney?.order?._links[0]
          || getSaveTransferMoney?.order?._links[0],
        )
        /* eslint-enable */
      }
      if (getSaveTransferMoney?.status === 200 || getPerformTransferMoney?.status === 200) {
        switch (getSaveTransferMoney?.order?.status?.uid) {
          case 'Signed':
            setPerformModalOpen(true)
            break
          case 'Draft':
            if (!activeId) {
              setSaveModalOpen(true)
            }
            break
          default:
            handleBackToHistory()
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSaveTransferMoney, getPerformTransferMoney])

  useEffect(() => {
    if (getRespMetadata && clientPortfolio) {
      setSettlementCurrencyList(
        getRespMetadata?.references?.portfolios.find(
          (item) => item.id === +clientPortfolio,
        )?.currencies,
      )
    }
  }, [getRespMetadata, clientPortfolio])

  useEffect(() => {
    if (serverError) {
      if (serverError.errors?.ClientPortfolio?.length) {
        setClientPortfolioError(true)
        setClientPortfolioTextError(serverError.errors?.ClientPortfolio[0])
      }
      if (serverError.errors?.Currency?.length) {
        setPriceCurrencyError(true)
        setPriceCurrencyTextError(serverError.errors?.Currency[0])
      }
      if (serverError.errors?.LotsQuantity?.length) {
        setAmountError(true)
        setAmountTextError(serverError.errors?.LotsQuantity[0])
      }
      if (serverError.errors?.ExpirationDate?.length) {
        setExpirationDateError(true)
        setExpirationDateTextError(serverError.errors?.ExpirationDate[0])
      }
      if (serverError.errors?.SettlementDate?.length) {
        setSettlementDateError(true)
        setSettlementDateTextError(serverError.errors?.SettlementDate[0])
      }
      if (serverError.errors?.Price?.length) {
        setPriceError(true)
        setPriceTextError(serverError.errors?.Price[0])
      }
      if (serverError.errors?.Partner?.length) {
        setPartnersNameError(true)
        setPartnersNameTextError(serverError.errors?.Partner[0])
      }
      if (serverError.errors?.SettlementCurrency?.length) {
        setSettlementCurrencyError(true)
        setSettlementCurrencyTextError(serverError.errors?.SettlementCurrency[0])
      }
      if (serverError.errors?.PriceRelative?.length) {
        setPriceError(true)
        setPriceTextError(serverError.errors?.PriceRelative[0])
      }
      if (serverError.errors?.Volume?.length) {
        setAmountError(true)
        setAmountTextError(serverError.errors?.Volume[0])
      }
      if (serverError?.errors?.Security?.length) {
        setSecuritiesNameError(true)
        setSecuritiesNameTextError(serverError?.errors?.Security[0])
      }
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeTextError(serverError?.errors?.Secret[0])
      }
      setAllDisabled(false)
      setIsLoading(false)
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
      setIsLoading(false)
    }
  }, [serverError])

  const handleChangeTypeToggle = (event, nextView) => {
    if (nextView !== null) {
      setType(nextView)
    }
  }

  const handleChangeInstrumentType = (event) => {
    clearFields(false)
    setInstrumentType(event.target.value)
    setSecurityInputValue('')
    setIssuer(initialState.issuer)
    dispatch(getSecuritiesSuccess(null))
  }

  const applyFilter = (searchValue) => {
    if (searchValue && searchValue.trim().length >= 3) {
      let uri = createUri({ name: 'securitiesUnique', typeUID: instrumentType })
      uri = `&Count=100&context.objectUid=${type}${instrumentType ? `&Filter=${uri}` : ''}&Search=${searchValue}`
      securitiesFilter(uri)
    }
  }

  const handleChangeSecuritiesAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setSecurityInputValue(value)
      applyFilter(value)
      setSecuritiesSearchStr(value)
    }
    setSecuritiesNameError(false)
  }

  const handleChangeSecuritiesAutocomplete = (event, value) => {
    clearFields(false)
    if (!value) {
      return
    }
    if (value?.name) {
      setSecurityInputValue(value.name)
    }
    setIssuer(value)
    applyFilter(securitiesSearchStr)
  }

  const handleChangePortfolio = (event) => {
    setClientPortfolio(event.target.value)
    setSettlementCurrency(null)
    setClientPortfolioError(false)
  }

  const handlePriceSwitchChange = (event) => {
    setPriceSwitchState(event.target.checked)
    if (!event.target.checked) {
      setPrice('')
      if (issuer?.marketBoard?.market?.uid === 'OTC.STOCKS') {
        setPriceCurrency(getRespMetadata?.references?.currencies[0].uid)
      }
    }
    setPriceError(false)
  }

  const handleChangePrice = (event) => {
    setPrice(event.target.value)
    setPriceError(false)
  }

  const handleChangePriceCurrency = (event) => {
    setPriceCurrency(event.target.value)
    setPriceCurrencyError(false)
  }

  const handleChangeAmount = (event) => {
    const { value } = event.target
    setAmount(value.replace(/[^0-9]/g, ''))
    setAmountError(false)
  }

  const handleChangeSettlementCurrency = (event) => {
    setSettlementCurrency(event.target.value)
    setSettlementCurrencyError(false)
  }

  const handleChangeExpirationDate = (date) => {
    const formatDate = format(new Date(date), 'yyyy-MM-dd')
    const metadataExpirationDate = getRespMetadata?.order?.expirationDate

    setSettlementDate(
      formatDate !== metadataExpirationDate ? null : issuer && new Date(issuer.settlementDate),
    )
    setExpirationDate(date)
    setExpirationDateError(false)
  }

  const handleChangeSettlementDate = (date) => {
    setSettlementDate(date)
    setSettlementDateError(false)
  }

  const handleChangeInstruction = (event) => {
    setInstruction(event.target.value)
  }

  const getPartnersList = () => {
    let uri = createUri({ name: 'partnersDefault' })
    uri = `Count=1000&Context.MarketBoardUid=${issuer?.marketBoard?.uid}&Context.ObjectUid=${type}&Filter=${uri}`
    partnersFilter(uri)
  }

  const handleChangePartnersAutocompleteInput = (event) => {
    const value = event?.target?.value
    if (typeof value === 'string') {
      setPartnersSearchStr(value)
      let uri = createUri({ name: 'partners', condition: 'or', searchStr: value })
      uri = `Count=1000&Context.MarketBoardUid=${issuer?.marketBoard?.uid}&Context.ObjectUid=${type}&Filter=${uri}`
      partnersFilter(uri)
    }
    setPartnersNameError(false)
  }

  const handleChangePartnersAutocomplete = (event, value) => {
    if (!value) {
      return
    }
    if (value?.name) {
      setPartnersSearchStr(value.name)
    }
    setPartner(value)
    const uri = 'Count=100'
    partnersFilter(uri)
  }

  const getPercent = () => {
    if (issuer?.type?.uid === 'Securities.Type.Bonds' && parseFloat(price)) {
      return `${numberWithSpaces((issuer?.faceValue * (parseFloat(price) / 100)).toFixed(2))} ${issuer?.faceValueCurrency.uid}`
    }
    return null
  }

  const addNeedsProps = (typeUID, msgData) => {
    let reqObj = {}
    if (typeUID === 'Orders.Type.Modify.Cancellation') {
      reqObj = {
        id: +activeId || null,
        type: { uid: typeUID },
        expirationDate: format(new Date(expirationDate), 'yyyy-MM-dd'),
        affectedOrder: {
          id: msgData?.order?.affectedOrder?.id
            || getRespTransferMoney?.order?.affectedOrder?.id,
        },
      }
      return reqObj
    }
    reqObj = {
      isNegotiated: issuer?.isNegotiatedOperation,
      id: activeId,
      type: {
        uid: type,
      },
      security: {
        id: issuer?.id,
      },
      clientPortfolio: {
        id: clientPortfolio,
      },
      priceType: {
        uid: priceSwitchState ? 'Orders.Price.Limit' : 'Orders.Price.Market',
      },
      price: parseFloat(price),
      marketBoard: {
        uid: issuer?.marketBoard?.uid,
      },
      expirationDate: format(new Date(expirationDate), 'yyyy-MM-dd'),
    }
    if (settlementDate) {
      reqObj.settlementDate = format(new Date(settlementDate), 'yyyy-MM-dd')
    }
    if (priceSwitchState) {
      reqObj.currency = {
        uid: issuer?.settlementCurrency?.uid,
      }
    }
    if (issuer?.isNegotiatedOperation) {
      reqObj.partner = {
        id: partner?.id,
      }
    }
    if (issuer?.marketBoard?.market?.uid === 'OTC.STOCKS') {
      reqObj.volume = amount
      reqObj.settlementCurrency = {
        uid: settlementCurrency,
      }
      if (priceSwitchState) {
        reqObj.currency = {
          uid: priceCurrency,
        }
      }
    } else {
      reqObj.amount = getCalculations?.amount
      reqObj.lotsQuantity = amount
    }
    if (issuer?.type?.uid === 'Securities.Type.Bonds') {
      reqObj.amount = (getCalculations?.amount && (Number.isNaN(getCalculations.amount)
        ? null : getCalculations.amount.toFixed(10))) || null
      reqObj.price = (getCalculations?.price && (Number.isNaN(getCalculations.price)
        ? null : getCalculations.price.toFixed(10))) || null
      reqObj.priceRelative = Number.isNaN(parseFloat(price)) ? null : parseFloat(price).toFixed(1)
    }
    return reqObj
  }

  const handlePerformTransfer = (event) => {
    event.preventDefault()
    if (connectedWS) {
      setFormDisabled(true)
      setIsLoading(true)

      if (submitAction === 'perform') {
        setAllDisabled(true)
      }
      const msgData = getSaveTransferMoney || getPerformTransferMoney
      const typeUID = getTypeUID()
      const reqObj = addNeedsProps(typeUID, msgData)
      formAction({
        submitAction,
        requestObj: reqObj,
        typeUID,
        msgData,
        putPerformData,
        postPerformData,
        putSaveData,
        postSaveData,
        activeId,
        code,
        setCodeError,
        setCodeTextError,
        setCancellationModalOpen,
        setFormDisabled,
        setIsLoading,
        currentThumbPrint,
      })
    }
  }

  const handleDownloadXml = () => {
    const msgData = getSaveTransferMoney || getPerformTransferMoney
    if (msgData?.requiredAction?.signAction) {
      const link = document.createElement('a')
      link.download = `${msgData?.order?.name}.xml`
      const blob = new Blob([encodeToUTF8(msgData?.requiredAction?.signAction?.xml)], {
        type: 'xml',
      })
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }

  const handleCancelOperation = (event) => {
    event.preventDefault()
    dispatch(clearPartTransferMoney())
    setAllDisabled(false)
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const handleResetTimer = () => {
    setShowTimer(true)
    const msgData = getSaveTransferMoney || getPerformTransferMoney
    const typeUID = getTypeUID()
    const requestObj = addNeedsProps(typeUID, msgData)
    formAction({
      submitAction: 'perform', requestObj, typeUID, putPerformData, postPerformData, activeId,
    })
  }

  const handleChangeCodeInput = (event) => {
    setCode(event.target.value)
    setCodeError(false)
  }

  const getHandler = (
    handler,
    additValue = false,
    additCondition = false,
  ) => (!additValue && (!allDisabled ? handler : null))
    || (!allDisabled && !additCondition ? handler : null)

  const handleCloseCancellationModal = () => {
    setCancellationModalOpen(false)
  }

  const handleCancelOk = () => {
    const reqObj = {
      affectedOrder: { id: +activeId },
      type: { uid: 'Orders.Type.Modify.Cancellation' },
      expirationDate: format(new Date(expirationDate), 'yyyy-MM-dd'),
    }
    postPerformData(reqObj)
    handleCloseCancellationModal()
  }

  const handleCloseSaveModal = (goToHistory) => {
    setSaveModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleClosePerformModal = (goToHistory) => {
    setPerformModalOpen(false)
    if (goToHistory) handleBackToHistory()
  }

  const handleActionReject = () => {
    handleCloseSaveModal(true)
    handleClosePerformModal(true)
    clearFields()
    clearInstrumentType()
  }

  const handleActionSuccess = () => {
    handleCloseSaveModal(false)
    handleClosePerformModal(false)
    clearFields(false)
    setActiveId(null)
    setFormDisabled(false)
    clearInstrumentType()
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Box pt="43px">
        {isLoading && (
          <Box mt="79px">
            <CustomLoader />
          </Box>
        )}
        {!isLoading && (
          <form
            onSubmit={handlePerformTransfer}
            autoComplete="off"
          >
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}
              >
                <ToggleButtonGroup
                  className={classes.switchButtonsWrapper}
                  orientation="horizontal"
                  value={type}
                  exclusive
                  onChange={handleChangeTypeToggle}
                >
                  <ToggleButton
                    value="Orders.Type.Trade.Deal.Buy"
                    aria-label="buy"
                  >
                    Покупка
                    <ArrowTopFullUnique />
                  </ToggleButton>
                  <ToggleButton
                    value="Orders.Type.Trade.Deal.Sell"
                    aria-label="sell"
                  >
                    Продажа
                    <ArrowTopFullUnique />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid
                className={classes.portalBtnWrapper}
                item
                xs={12}
              >
                <span className={classes.portalBtnLabel}>
                  Инструмент
                </span>
              </Grid>
              <Grid
                item
                xs={3}
              >
                <CustomSelect
                  label="Тип"
                  value={instrumentType || ''}
                  onChange={getHandler(handleChangeInstrumentType)}
                  unique
                >
                  {(getRespMetadata?.references?.securityTypes
                    && getRespMetadata?.references?.securityTypes?.map((item) => (
                      <MenuItem
                        key={item?.uid}
                        value={item?.uid.toString()}
                      >
                        {item?.description}
                      </MenuItem>
                    ))) || (
                      <MenuItem
                        value=""
                      />
                  )}
                </CustomSelect>
              </Grid>
              <Grid
                item
                xs={9}
              >
                <CustomAutocomplete
                  error={securitiesNameError}
                  errorText={securitiesNameTextError}
                  variant="outlined"
                  label="Наименование инструмента"
                  name="issuer"
                  color="secondary"
                  size="small"
                  inputValue={securityInputValue || ''}
                  onInputChange={handleChangeSecuritiesAutocompleteInput}
                  onChange={handleChangeSecuritiesAutocomplete}
                  searchList={getRespSecurities && getRespSecurities?.securities}
                  uniqueSearchList
                  disabled={allDisabled}
                  unique
                />
              </Grid>
              {issuer?.id && (
                <Grid
                  item
                  xs={12}
                >
                  <div className={classnames(
                    classes.instrumentTable,
                    { [classes.instrumentTableUnique]: issuer?.marketBoard?.market?.uid === 'OTC.STOCKS' },
                  )}
                  >
                    {issuer?.type?.name && (
                      <div className={classes.instrumentTable__item}>
                        <span className={classes.instrumentTable__title}>
                          Вид
                        </span>
                        <p className={classes.instrumentTable__text}>
                          {issuer?.type?.name}
                        </p>
                      </div>
                    )}
                    {issuer?.registerNumber && (
                      <div className={classes.instrumentTable__item}>
                        <span className={classes.instrumentTable__title}>
                          Номер гос. регистрации
                        </span>
                        <p className={classes.instrumentTable__text}>
                          {issuer?.registerNumber}
                        </p>
                      </div>
                    )}
                    {issuer?.isin && (
                      <div className={classes.instrumentTable__item}>
                        <span className={classes.instrumentTable__title}>
                          ISIN
                        </span>
                        <p className={classes.instrumentTable__text}>
                          {issuer?.isin}
                        </p>
                      </div>
                    )}
                    {issuer?.lotSize && (
                      <div className={classes.instrumentTable__item}>
                        <span className={classes.instrumentTable__title}>
                          Лот
                        </span>
                        <p className={classes.instrumentTable__text}>
                          {issuer?.lotSize}
                        </p>
                      </div>
                    )}
                    {(issuer?.settlementCurrency?.uid && issuer?.marketBoard?.market?.uid !== 'OTC.STOCKS') && (
                      <div className={classes.instrumentTable__item}>
                        <span className={classes.instrumentTable__title}>
                          Валюта расчетов
                        </span>
                        <p className={classes.instrumentTable__text}>
                          {issuer?.settlementCurrency?.uid}
                        </p>
                      </div>
                    )}
                    {issuer?.marketBoard?.market?.description && (
                      <div className={
                        classnames(
                          classes.instrumentTable__item,
                          { [classes.instrumentTable__item_long]: issuer?.marketBoard?.market?.uid !== 'OTC.STOCKS' },
                        )
                      }
                      >
                        <span className={classes.instrumentTable__title}>
                          Рынок
                        </span>
                        <p className={classes.instrumentTable__text}>
                          {issuer?.marketBoard?.market?.description}
                        </p>
                      </div>
                    )}
                    {issuer?.marketBoard?.name && (
                      <div className={classes.instrumentTable__item}>
                        <span className={classes.instrumentTable__title}>
                          Режим торгов
                        </span>
                        <p className={classes.instrumentTable__text}>
                          {issuer?.marketBoard?.name}
                        </p>
                      </div>
                    )}
                    {issuer?.faceValue && (
                      <div className={classes.instrumentTable__item}>
                        <span className={classes.instrumentTable__title}>
                          Номинал
                        </span>
                        <p className={classes.instrumentTable__text}>
                          {`${numberWithSpaces(issuer?.faceValue)} ${issuer?.faceValueCurrency?.uid}`}
                        </p>
                      </div>
                    )}
                    {(issuer?.settlementAci || issuer?.settlementAci === 0) && (
                      <div className={classes.instrumentTable__item}>
                        <span className={classes.instrumentTable__title}>
                          НКД
                        </span>
                        <p className={classes.instrumentTable__text}>
                          {`${issuer?.settlementAci} ${issuer?.settlementCurrency?.uid ?? ''}`}
                        </p>
                      </div>
                    )}
                  </div>
                </Grid>
              )}
              <Grid
                item
                lg={3}
                sm={6}
                xs={12}
              >
                <CustomSelect
                  label="Портфель"
                  value={clientPortfolio || ''}
                  onChange={getHandler(handleChangePortfolio)}
                  error={clientPortfolioError}
                  errorText={clientPortfolioTextError}
                  unique
                >
                  {(getRespMetadata?.references?.portfolios
                    && getRespMetadata?.references?.portfolios?.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={item.id.toString()}
                        portfolio={item}
                      >
                        {item.name}
                      </MenuItem>
                    ))) || (
                      <MenuItem
                        value=""
                      />
                  )}
                </CustomSelect>
              </Grid>
              {!(issuer?.marketBoard?.market?.uid === 'OTC.STOCKS' && issuer?.type?.uid !== 'Securities.Type.Bonds') && (
                <Grid
                  item
                  lg={3}
                  xs={12}
                  className={classes.switchContainer}
                >
                  <CustomSwitch
                    name="marketPrice"
                    label="Купить по рыночной цене"
                    isChecked={priceSwitchState}
                    onChange={handlePriceSwitchChange}
                    disabled={allDisabled}
                  />
                </Grid>
              )}
              <Grid
                item
                lg={3}
                sm={6}
                xs={12}
              >
                <CustomInput
                  error={priceError}
                  errorText={priceTextError}
                  name="price"
                  label={issuer?.type?.uid === 'Securities.Type.Bonds' ? 'Цена, % от номинала' : 'Цена'}
                  helperText={getPercent()}
                  value={price}
                  onChange={handleChangePrice}
                  mask={issuer?.type?.uid === 'Securities.Type.Bonds' ? '.' : ''}
                  isAllowed={(values) => (values?.floatValue ?? 0) < 1000}
                  decimalScale={4}
                  allowNegative={false}
                  suffix="%"
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled={!priceSwitchState || allDisabled}
                  unique
                  autoComplete="off"
                />
              </Grid>
              {(issuer?.marketBoard?.market?.uid === 'OTC.STOCKS' && issuer?.type?.uid !== 'Securities.Type.Bonds') && (
                <Grid
                  item
                  lg={3}
                  sm={6}
                  xs={12}
                >
                  <CustomSelect
                    error={priceCurrencyError}
                    errorText={priceCurrencyTextError}
                    label="Валюта цены"
                    value={priceCurrency || ''}
                    onChange={getHandler(handleChangePriceCurrency, true, !priceSwitchState)}
                    unique
                  >
                    {(getRespMetadata?.references?.currencies
                      && getRespMetadata?.references?.currencies?.map((item) => (
                        <MenuItem
                          key={item?.uid}
                          value={item?.uid.toString()}
                          portfolio={item}
                        >
                          {item?.uid}
                        </MenuItem>
                      ))) || (
                        <MenuItem
                          value=""
                        />
                    )}
                  </CustomSelect>
                </Grid>
              )}
              <Grid
                item
                lg={3}
                sm={6}
                xs={12}
              >
                <CustomInput
                  error={amountError}
                  errorText={amountTextError}
                  name="amount"
                  label="Количество"
                  value={amount}
                  onChange={handleChangeAmount}
                  fullWidth
                  variant="outlined"
                  size="small"
                  unique
                  disabled={allDisabled}
                />
              </Grid>
              {(issuer?.marketBoard?.market?.uid === 'OTC.STOCKS' && issuer?.type?.uid !== 'Securities.Type.Bonds') && (
                <Grid
                  item
                  lg={3}
                  xs={12}
                  className={classes.switchContainer}
                >
                  <CustomSwitch
                    name="marketPrice"
                    label="Купить по рыночной цене"
                    isChecked={priceSwitchState}
                    onChange={handlePriceSwitchChange}
                    disabled={allDisabled}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
              >
                <div className={classnames(
                  classes.instrumentTable,
                  classes.instrumentTable_unique,
                )}
                >
                  <div className={classes.instrumentTable__item}>
                    <span className={classes.instrumentTable__title}>
                      Позиций на счете
                    </span>
                    <p className={classes.instrumentTable__text}>
                      {(getCalculations?.depoBalance && `${numberWithSpaces(getCalculations?.depoBalance?.balanceT0)} шт.`) || '...'}
                    </p>
                  </div>
                  <div className={classes.instrumentTable__item}>
                    <span className={classes.instrumentTable__title}>
                      Доступно
                    </span>
                    <p className={classes.instrumentTable__text}>
                      {(getCalculations?.moneyBalance && `${numberWithSpaces(getCalculations?.moneyBalance?.balanceT0)} ${getCalculations?.currency?.uid || 'RUB'}`) || '...'}
                    </p>
                  </div>
                  <div className={classes.instrumentTable__item}>
                    <span className={classes.instrumentTable__title}>
                      {issuer?.type?.uid === 'Securities.Type.Bonds' ? 'Сумма сделки, в т.ч. НКД' : 'Сумма сделки'}
                    </span>
                    <p className={classes.instrumentTable__text}>
                      {(getCalculations?.amount && `${numberWithSpaces(getCalculations?.amount)} ${getCalculations?.currency?.uid}`) || '...'}
                    </p>
                  </div>
                </div>
              </Grid>
              {issuer?.marketBoard?.market?.uid === 'OTC.STOCKS' ? (
                <>
                  <Grid
                    item
                    xs={3}
                  >
                    <Grid
                      className={classes.oneColGridContainer}
                      container
                      spacing={4}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <CustomSelect
                          error={settlementCurrencyError}
                          errorText={settlementCurrencyTextError}
                          label="Валюта расчетов"
                          value={settlementCurrency || ''}
                          onChange={getHandler(handleChangeSettlementCurrency)}
                          unique
                        >
                          {(settlementCurrencyList?.map((item) => (
                            <MenuItem
                              key={item?.uid}
                              value={item?.uid.toString()}
                            >
                              {item?.uid}
                            </MenuItem>
                          ))) || (
                          <MenuItem
                            value=""
                          />
                          )}
                        </CustomSelect>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <CustomDatepicker
                          autoOk
                          error={settlementDateError}
                          errorText={settlementDateTextError}
                          variant="inline"
                          inputVariant="outlined"
                          label="Дата расчетов"
                          format="dd.MM.yyyy"
                          value={settlementDate}
                          size="small"
                          InputAdornmentProps={{ position: 'end' }}
                          onChange={(date) => handleChangeSettlementDate(date)}
                          unique
                          disabled={allDisabled}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <CustomDatepicker
                          autoOk
                          error={expirationDateError}
                          errorText={expirationDateTextError}
                          variant="inline"
                          inputVariant="outlined"
                          label="Срок действия"
                          format="dd.MM.yyyy"
                          value={expirationDate}
                          size="small"
                          InputAdornmentProps={{ position: 'end' }}
                          onChange={(date) => handleChangeExpirationDate(date)}
                          unique
                          disabled={allDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                  >
                    <Grid
                      className={classes.oneColGridContainer}
                      container
                      spacing={4}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <CustomAutocomplete
                          error={partnersNameError}
                          errorText={partnersNameTextError}
                          variant="outlined"
                          label="Контрагент"
                          name="partner"
                          color="secondary"
                          size="small"
                          defaultShowList
                          inputValue={partnersSearchStr || ''}
                          onFocus={() => getPartnersList()}
                          onInputChange={handleChangePartnersAutocompleteInput}
                          onChange={handleChangePartnersAutocomplete}
                          searchList={getRespPartners && getRespPartners?.partners}
                          disabled={allDisabled}
                          unique
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <CustomInput
                          name="additionalInstruction"
                          label="Дополнительные инструкции"
                          value={instruction}
                          onChange={handleChangeInstruction}
                          fullWidth
                          variant="outlined"
                          size="small"
                          multiline={issuer?.isNegotiatedOperation}
                          rows={issuer?.isNegotiatedOperation ? 3 : 1}
                          unique
                          disabled={allDisabled}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={6}
                  >
                    <Grid
                      container
                      spacing={4}
                    >
                      <Grid
                        item
                        xs={6}
                      >
                        <CustomDatepicker
                          autoOk
                          error={expirationDateError}
                          errorText={expirationDateTextError}
                          variant="inline"
                          inputVariant="outlined"
                          label="Срок действия"
                          format="dd.MM.yyyy"
                          value={expirationDate}
                          size="small"
                          InputAdornmentProps={{ position: 'end' }}
                          onChange={(date) => handleChangeExpirationDate(date)}
                          unique
                          disabled={allDisabled}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <CustomDatepicker
                          autoOk
                          error={settlementDateError}
                          errorText={settlementDateTextError}
                          variant="inline"
                          inputVariant="outlined"
                          label="Дата расчетов"
                          format="dd.MM.yyyy"
                          value={settlementDate}
                          size="small"
                          InputAdornmentProps={{ position: 'end' }}
                          onChange={(date) => handleChangeSettlementDate(date)}
                          unique
                          disabled={allDisabled}
                        />
                      </Grid>
                      {issuer?.isNegotiatedOperation && (
                        <Grid
                          className={classes.gridSecondRow}
                          item
                          xs={12}
                        >
                          <CustomAutocomplete
                            error={partnersNameError}
                            errorText={partnersNameTextError}
                            variant="outlined"
                            label="Контрагент"
                            name="partner"
                            color="secondary"
                            size="small"
                            inputValue={partnersSearchStr || ''}
                            onInputChange={handleChangePartnersAutocompleteInput}
                            onChange={handleChangePartnersAutocomplete}
                            searchList={getRespPartners && getRespPartners?.partners}
                            disabled={allDisabled}
                            unique
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    sm={6}
                    xs={12}
                  >
                    <CustomInput
                      name="additionalInstruction"
                      label="Дополнительные инструкции"
                      value={instruction}
                      onChange={handleChangeInstruction}
                      fullWidth
                      variant="outlined"
                      size="small"
                      multiline={issuer?.isNegotiatedOperation}
                      rows={issuer?.isNegotiatedOperation ? 3 : 1}
                      unique
                      disabled={allDisabled}
                    />
                  </Grid>
                </>
              )}
              {(getSaveTransferMoney?.requiredAction?.type === 'code' || getPerformTransferMoney?.requiredAction?.type === 'code') && (
                <>
                  <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                  >
                    <CustomInput
                      error={codeError}
                      errorText={codeTextError}
                      variant="outlined"
                      label="Введите код из сообщения"
                      name="code"
                      value={code}
                      onChange={(event) => handleChangeCodeInput(event)}
                      size="small"
                      color="secondary"
                      unique
                      className={classes.inputField}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid
                    item
                    lg={8}
                    sm={6}
                    xs={12}
                    className={classes.Transfer__codeWrapper}
                  >
                    {(showTimer && (
                      <Typography
                        className={classes.Transfer__codeText}
                        m={0}
                      >
                        Если код не пришел, Вы можете запросить новый код через
                        <span
                          className={classes.Transfer__codeInput}
                        >
                          <Timer
                            seconds={30}
                            handleStartTimer={handleStartTimer}
                          />
                        </span>
                      </Typography>
                    )) || (!showTimer && (
                      <CustomButton
                        label="Выслать новый код"
                        modifier="primary"
                        type="submit"
                        fullWidth
                        onClick={handleResetTimer}
                      />
                    ))}
                  </Grid>
                </>
              )}
              {(((currentActions && currentActions?.canModify) || !currentActions)
                && (!getSaveTransferMoney?.requiredAction?.type
                  && !getPerformTransferMoney?.requiredAction?.type
                ) && (
                  <Grid
                    item
                    xs={12}
                    className={classes.btnGroup}
                  >
                    <CustomIconButton
                      type="submit"
                      onClick={() => { setSubmitAction('perform') }}
                      disabled={formDisabled}
                    >
                      Исполнить
                    </CustomIconButton>
                    <CustomIconButton
                      type="submit"
                      onClick={() => { setSubmitAction('save') }}
                      disabled={formDisabled}
                    >
                      Сохранить
                    </CustomIconButton>
                    <Media
                      query="(min-width: 321px)"
                      render={() => (
                        <CustomIconButton
                          type="button"
                          onClick={handleBackToHistory}
                        >
                          Назад
                        </CustomIconButton>
                      )}
                    />
                  </Grid>
              )) || ((getSaveTransferMoney?.requiredAction?.type === 'sign' || getPerformTransferMoney?.requiredAction?.type === 'sign') && (
              <Grid
                item
                xs={12}
                className={classes.btnGroup}
              >
                <CustomIconButton
                  onClick={() => { setSubmitAction('perform') }}
                  type="submit"
                >
                  Подписать
                </CustomIconButton>
                <CustomIconButton
                  type="button"
                  icon={<DownloadIcon />}
                  onClick={handleDownloadXml}
                >
                  Скачать .xml
                </CustomIconButton>
                <Media
                  query="(min-width: 321px)"
                  render={() => (
                    <CustomIconButton
                      type="button"
                      onClick={handleCancelOperation}
                    >
                      Назад
                    </CustomIconButton>
                  )}
                />
              </Grid>
              )) || ((getSaveTransferMoney?.requiredAction?.type === 'code' || getPerformTransferMoney?.requiredAction?.type === 'code') && (
              <Grid
                item
                xs={12}
                className={classes.btnGroup}
              >
                <CustomIconButton
                  type="submit"
                  onClick={() => { setSubmitAction('perform') }}
                >
                  Подтвердить
                </CustomIconButton>
                <Media
                  query="(min-width: 321px)"
                  render={() => (
                    <CustomIconButton
                      type="button"
                      onClick={handleCancelOperation}
                    >
                      Отменить
                    </CustomIconButton>
                  )}
                />
              </Grid>
              )) || ((currentActions && currentActions?.canCancel) && (
              <Grid
                item
                xs={12}
                className={classes.btnGroup}
              >
                <CustomIconButton
                  onClick={() => { setCancellationModalOpen(true) }}
                >
                  Отменить поручение
                </CustomIconButton>
                <Media
                  query="(min-width: 321px)"
                  render={() => (
                    <CustomIconButton
                      type="button"
                      onClick={handleBackToHistory}
                    >
                      Назад
                    </CustomIconButton>
                  )}
                />
              </Grid>
              ))
                || ((currentActions && !currentActions?.canModify && !currentActions?.canCancel)
                  && (
                    <Media
                      query="(min-width: 321px)"
                      render={() => (
                        <Grid
                          item
                          xs={12}
                          className={classes.btnGroup}
                        >
                          <CustomIconButton
                            type="button"
                            onClick={handleBackToHistory}
                          >
                            Назад
                          </CustomIconButton>
                        </Grid>
                      )}
                    />
                  ))}
            </Grid>
          </form>
        )}
        <CustomModal
          isWarningIcon
          open={cancellationModalOpen}
          title="Создать поручение на отмену"
          handleClose={handleCloseCancellationModal}
          callbacks={{
            withOk: handleCancelOk,
            withCancel: () => {
              handleCloseCancellationModal()
            },
          }}
        />
        <CustomModal
          open={saveModalOpen}
          title="Поручение успешно создано."
          handleClose={handleCloseSaveModal}
          actionText="Хотите создать новое?"
          actionReject={handleActionReject}
          actionSuccess={handleActionSuccess}
          disableOverlay
        />

        <CustomModal
          open={performModalOpen}
          title="Поручение успешно подписано"
          handleClose={handleClosePerformModal}
          actionText="Хотите создать новое?"
          actionReject={handleActionReject}
          actionSuccess={handleActionSuccess}
          disableOverlay
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}

export default TradeOrder

import React from 'react'
import Media from 'react-media'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { Box } from '@material-ui/core'
import useStyles from './sharedStyles'
import CustomSelect from '../../CustomControls/CustomSelect'
import CustomInput from '../../CustomControls/CustomInput'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

const StandPlaceContent = ({ data, handleClickNextPanel, isNeedNextButton }) => {
  const classes = useStyles()

  return (
    <Box pt={3}>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Grid
              container
              className={classes.row}
              spacing={2}
            >
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="inn"
                  label="ИНН"
                  value={data?.inn || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="kpp"
                  label="КПП"
                  value={data?.kpp || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="tin"
                  label="TIN"
                  value={data?.tin || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomInput
                  name="kio"
                  label="КИО"
                  value={data?.kio || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
              <Grid
                item
                lg
                sm={4}
                xs={12}
              >
                <CustomSelect
                  label="Налоговое резидентство"
                  value={data?.taxResidency?.uid === 'RUS'}
                  unique
                >
                  <MenuItem value>Резидент РФ</MenuItem>
                  <MenuItem value={false}>Нерезидент РФ</MenuItem>
                </CustomSelect>
              </Grid>
            </Grid>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px`}
        render={() => (
          <>
            {data?.inn && (
              <div className={classes.Mobile__block}>
                <div className={classes.Mobile__blockHeader}>
                  ИНН
                </div>
                <div className={classes.Mobile__blockText}>
                  {data?.inn}
                </div>
              </div>
            )}
            {data?.kpp && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                КПП
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.kpp}
              </div>
            </div>
            )}
            {data?.tin && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                TIN
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.tin}
              </div>
            </div>
            )}
            {data?.kio && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                КИО
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.kio}
              </div>
            </div>
            )}
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Налоговое резидентство
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.taxResidency?.uid === 'RUS' ? 'Резидент РФ' : 'Нерезидент РФ'}
              </div>
            </div>
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </>
        )}
      />
    </Box>
  )
}

StandPlaceContent.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

StandPlaceContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

export default StandPlaceContent

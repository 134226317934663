import React, {
  useEffect,
  useState,
} from 'react'
import TableCell from '@material-ui/core/TableCell'
import { useDispatch, useSelector } from 'react-redux'

import getCadespluginAPI from 'async-cadesplugin'
import moment from 'moment'
import { ReactComponent as DownloadIcon } from '../../../../assets/images/downloadNew.svg'
import {
  currentUserLoginAndId,
  selectCurrentThumbprint,
  selectCurrentUser,
} from '../../../../redux/user/selector'
import { CustomModal } from '../../organisms'
import { DataTable } from '../../molecules'
import { StyledWrapper } from '../Transfers/components/Money/styled'
import { StyledTableWrapper } from '../Transfers/styled'
import { Button, FormRow } from '../../atoms'
import { SMSConfirmModal } from '../Transfers/components/modals/SMSConfim'
import { setConfirmStatusModal } from '../../../../redux/ui/uiSlice'
import { baseURL } from '../../../../config/api'
import JsonToSHA256 from '../../../../utils/JsonToSHA256'
import formAction from '../../../../utils/FormAction'
import { useGetDocumentsListQuery, usePutDocumentMutation } from '../../../../redux/documents/documentsApi'
import b64toBlob from '../../../../utils/ConvertToBlob'

const Sign = () => {
  const dispatch = useDispatch()

  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [codeErrorText, setCodeErrorText] = useState(null)

  const [showTimer, setShowTimer] = useState(false)
  const [hasSha256, setHasSha256] = useState(null)
  const [secretPassPhrase, setSecretPassPhrase] = useState('')
  const [currentRow, setCurrentRow] = useState(null)
  const [msgData, setMsgData] = useState(null)
  const [isAccept, setAccept] = useState(null)
  const [showSmsModal, setShowSmsModal] = useState(false)
  const [showXmlModal, setShowXmlModal] = useState(false)
  const [serverError, setServerError] = useState(null)

  const currentThumbPrint = useSelector(selectCurrentThumbprint)
  const currentUser = useSelector(selectCurrentUser)
  const objectUserLoginAndId = useSelector(currentUserLoginAndId)

  const { data: needSignData } = useGetDocumentsListQuery('NeedSign')
  const [putClientSign, { data: putClientSignResponse, error: putClientSignError }] = usePutDocumentMutation()

  const reset = () => {
    setShowXmlModal(false)
    setShowSmsModal(false)
    setMsgData(null)
    setCurrentRow(null)

    setCodeError(false)
    setCodeErrorText('')
    setCode('')
    setServerError(null)
  }

  useEffect(() => {
    if (putClientSignResponse) {
      if (putClientSignResponse?.requiredAction?.type === 'mac') {
        setShowSmsModal(true)
        setShowTimer(true)
      }

      if (putClientSignResponse?.requiredAction?.type === 'sign') {
        setShowXmlModal(true)
      }

      if (!putClientSignResponse?.requiredAction?.type) {
        reset()
        return
      }

      setMsgData({
        ...putClientSignResponse,
        order: {
          type: {
            uid: putClientSignResponse?.typeUid,
          },
        },
      })
    }
  }, [putClientSignResponse])

  useEffect(() => {
    setServerError(putClientSignError)
  }, [putClientSignError])

  const handleClientSign = async (reqObj) => {
    try {
      if (reqObj?.thumbPrint && reqObj?.signedXml) {
        const api = await getCadespluginAPI()
        const signature = await api.signXml(reqObj?.thumbPrint, reqObj.signedXml)
        reqObj.signedXml = signature
      }

      putClientSign({ reqObj, docType: 'NeedSign' })
    } catch (error) {
      setServerError(error)
    }
  }

  const handlePerformSign = () => {
    const { id, typeUid } = currentRow
    const requestObj = { ...currentRow, isAccept, secret: '' }

    const WordArrayToBase64 = JsonToSHA256(requestObj)

    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHasSha256(WordArrayToBase64)
    }

    formAction({
      submitAction: 'perform',
      postPerformData: () => {},
      putPerformData: handleClientSign,
      requestObj,
      typeUID: typeUid,
      code,
      currentThumbPrint,
      secretPassPhrase,
      activeId: id,
      msgData: {
        ...msgData,
        requiredAction: {
          ...msgData?.requiredAction,
          type: msgData?.requiredAction?.type === 'error' ? 'mac' : msgData?.requiredAction?.type,
        },
      },
    })
  }

  useEffect(() => {
    if (putClientSignResponse?.requiredAction?.type === 'error') {
      if (putClientSignResponse?.requiredAction?.cause === 'Неправильный код') {
        setCodeError(true)
        setCodeErrorText(putClientSignResponse?.requiredAction?.cause)
      }
    }
    if (serverError?.errors?.Secret?.length) {
      setCodeError(true)
      setCodeErrorText(serverError?.errors?.Secret[0])
    }
  }, [serverError, putClientSignResponse])

  useEffect(() => {
    if (currentRow) {
      handlePerformSign()
    }
  }, [currentRow])

  const setConfirmModalStatus = (status) => {
    if (currentThumbPrint) {
      return
    }
    dispatch(setConfirmStatusModal(status))
  }

  useEffect(() => {
    if (serverError) {
      if (serverError?.errors?.Secret?.length) {
        setCodeError(true)
        setCodeErrorText(serverError?.errors?.Secret[0])
      }
    }
  }, [serverError])

  const handleDownloadFile = async (row, isEps) => {
    try {
      const res = await fetch(`${baseURL}/api/v3/documents/files/download`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${currentUser.accessToken}`,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          documentId: row?.id,
          uid: isEps ? row?.files[0].signUid : row?.files[0].uid,
        }),
      })

      const data = await res.json()

      if (res.status >= 400) {
        throw data
      }

      const downloadLink = document.createElement('a')
      if (data?.fileData) {
        downloadLink.href = URL.createObjectURL(b64toBlob(data?.fileData))
        downloadLink.download = isEps ? row?.files[0].signFileName : row?.files[0].fileName
        downloadLink.click()
      }
      URL.revokeObjectURL(downloadLink.href)
    } catch (error) {
      setServerError(error)
    }
  }

  const handleClickSign = (row, acceptStatus) => {
    setConfirmModalStatus(true)
    setCode('')
    setAccept(acceptStatus)
    setCurrentRow(row)
  }

  const handleChangeCodeInput = (event) => {
    const inputValue = event.target.value

    setCode(inputValue)
    setCodeError(false)

    if (objectUserLoginAndId) {
      const { clientId, userLogin } = objectUserLoginAndId
      setSecretPassPhrase(`${userLogin}${clientId}${inputValue}`)
    }
  }

  const handleStartTimer = (bool) => {
    setShowTimer(bool)
  }

  const handleResetTimer = () => {
    setShowTimer(true)

    const requestObj = { ...currentRow, isAccept, secret: '' }
    // const date = getRespMetadata?.order?.date
    // const number = getRespMetadata?.order?.number

    // requestObj.date = date
    // requestObj.number = number

    const {
      id,
      typeUid,
    } = currentRow

    const WordArrayToBase64 = JsonToSHA256(requestObj)

    if (WordArrayToBase64) {
      requestObj.secret = WordArrayToBase64
      setHasSha256(`${WordArrayToBase64}`)
    }

    formAction({
      submitAction: 'perform',
      requestObj,
      typeUID: typeUid,
      putPerformData: handleClientSign,
      activeId: id,
      secretPassPhrase,
    })
  }

  const handleSubmitCode = () => {
    handlePerformSign()
  }

  const handleCloseConfirmModal = () => {
    reset()
  }

  const Table = ({
    // eslint-disable-next-line react/prop-types
    rows, onRowClick,
    // useCertificate,
    // handleRemove,
    // handleDownload,
  }) => {
    const columnsLayout = ['20%', '30%', '20%']
    const columns = [
      {
        id: 'date',
        title: 'Дата отправки',
        width: '14.8%',
        render: (row) => `${row?.date && moment(row?.date, 'YYYY-MM-DD').format('DD.MM.YYYY')} ${row?.time && moment(row?.time, 'HH:mm:ss').format('HH:mm')}`,
      },
      {
        id: 'type',
        title: 'Тип',
        render: (row) => row?.typeName,
      },
      {
        id: 'format',
        title: 'Формат',
        render: (row) => {
          if (!row.files[0]?.fileName) {
            return null
          }

          const fileExtension = row.files[0].fileName?.split('.')?.pop()?.toUpperCase()

          return (
            <TableCell style={{ padding: 0, borderBottom: 'none' }}>
              <Button
                variant="plain"
                onClick={(event) => {
                  event.stopPropagation()
                  handleDownloadFile(row, false)
                }}
              >
                <DownloadIcon />
                &nbsp;
                {fileExtension}
              </Button>
            </TableCell>
          )
        },
      },
      {
        id: 'sign',
        title: '',
        render: (row) => (
          <FormRow lex={3}>
            <Button
              variant="primary"
              type="submit"
              onClick={() => handleClickSign(row, true)}
            >
              Подписать
            </Button>
            <Button
              type="submit"
              variant="secondary"
              onClick={() => handleClickSign(row, false)}
            >
              Отклонить
            </Button>
          </FormRow>
        ),
      },
    ].filter(Boolean)
    return (
      <DataTable
        columns={columns}
        rows={rows}
        layout={columnsLayout}
        onRowClick={onRowClick}
      />
    )
  }

  const handleCloseXmlModal = () => {
    reset()
  }

  return (
    <>
      <SMSConfirmModal
        isShowImmitPaste
        title={isAccept ? 'Подтвердите подписание документа' : 'Подтвердите отклонение документа'}
        handleResetTimer={handleResetTimer}
        handleStartTimer={handleStartTimer}
        hashSha256={hasSha256}
        showTimer={showTimer}
        code={code}
        codeError={codeError}
        codeTextError={codeErrorText}
        setCodeError={setCodeError}
        setCodeTextError={setCodeErrorText}
        isOpen={showSmsModal}
        handleChangeCodeInput={handleChangeCodeInput}
        handleSubmit={handleSubmitCode}
        handleClose={handleCloseConfirmModal}
      />
      <CustomModal
        open={showXmlModal}
        title={isAccept ? 'Подтвердите подписание документа' : 'Подтвердите отклонение документа'}
        actionText={' '}
        handleClose={handleCloseXmlModal}
        actionReject={handleCloseXmlModal}
        actionSuccess={() => {
          handlePerformSign()
        }}
        textOk="Подтвердить"
        textCancel="Отменить"
        disableOverlay
      />

      <StyledWrapper>
        <StyledTableWrapper>
          <Table
            rows={needSignData || []}
          />
        </StyledTableWrapper>
      </StyledWrapper>

    </>
  )
}

export default Sign

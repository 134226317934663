import React from 'react'
import { FormCol } from 'ui/components/atoms'
import { FirmInfo } from './Blocks/FirmInfo'
import { StateRegistration } from './Blocks/StateRegistration'
import { LegacyRegistration } from './Blocks/LegacyRegistration'
import { AddressInfo } from './Blocks/AddressInfo'
import { TaxAuthorityInfo } from './Blocks/TaxAuthorityInfo'
import { CodeForms } from './Blocks/CodeForms'
import { ContactsInfo } from './Blocks/ContactsInfo'

import type { FC } from 'react'
import { ICustomerTab } from './types'
import JurInfo from './JurInfo'
import { LEGAL_ENTITY } from 'types/client'

const CustomerTab: FC<ICustomerTab> = ({ customer, legalEntity, clientInfo }) => {
  const isFirm = legalEntity === LEGAL_ENTITY.FIRM

  if (!isFirm) return <JurInfo customer={customer} clientInfo={clientInfo} />
  return (
    <FormCol gap={16}>
      {/*
      БЛОК: ФИРМЕННОЕ НАИМЕНОВАНИЕ С УКАЗАНИЕМ ОРГАНИЗАЦИОННО ПРАВОВОЙ ФОРМЫ
    */}
      <FirmInfo
        fullNameEn={customer?.fullNameEn}
        shortNameEn={customer?.shortNameEn}
        fullNameRu={customer?.fullNameRu}
        shortNameRu={customer?.shortNameRu}
        registerCountryRu={customer?.registerCountry?.nameRu}
        registerCountryEn={customer?.registerCountry?.nameEn}
        registerLocationRu={customer?.addressRu}
        registerLocationEn={customer?.addressEn}
      />

      {/*
      БЛОК: СВЕДЕНИЯ О ГОСУДАРСТВЕННОЙ РЕГИСТРАЦИИ
    */}
      <StateRegistration
        rusRegisterNumber={customer?.rusRegisterNumber}
        rusRegisterDate={customer?.rusRegisterDate}
        rusRegisterLocation={customer?.rusRegisterLocation}
        rusRegisterIssuer={customer?.rusRegisterIssuer}
      />

      {/*
      БЛОК: ДЛЯ ЮРИДИЧЕСКИХ ЛИЦ, ЗАРЕГИСТРИРОВАННЫХ НА ТЕРРИТОРИИ РФ ДО 01 ИЮЛЯ 2002 ГОДА
    */}
      <LegacyRegistration
        registerNumber={customer?.registerNumber}
        registerDate={customer?.registerDate}
        registerIssuer={customer?.registerIssuer}
      />

      {/*
      БЛОК: АДРЕС В СООТВЕТСТВИИ С ЕДИНЫМ ГОСУДАРСТВЕННЫМ РЕЕСТРОМ ЮРИДИЧЕСКИХ ЛИЦ (ЕГРЮЛ)
    */}
      <AddressInfo
        title="Адрес в соответствии с&nbsp;единым государственным реестром юридических лиц (ЕГРЮЛ)"
        countryEn={customer?.address?.country?.nameEn}
        countryRu={customer?.address?.country?.nameRu}
        postCode={customer?.address?.postCode}
        regionEn={customer?.address?.regionEn}
        regionRu={customer?.address?.regionRu}
        cityEn={customer?.address?.cityEn}
        cityRu={customer?.address?.cityRu}
        streetEn={customer?.address?.streetEn}
        streetRu={customer?.address?.streetRu}
        house={customer?.address?.house}
        building={customer?.address?.building}
        block={customer?.address?.block}
        apartment={customer?.address?.apartment}
        office={customer?.address?.office}
      />

      {/*
      БЛОК: ПОЧТОВЫЙ АДРЕС
    */}
      <AddressInfo
        title="Почтовый адрес"
        countryEn={customer?.postAddress?.country?.nameEn}
        countryRu={customer?.postAddress?.country?.nameRu}
        postCode={customer?.postAddress?.postCode}
        regionEn={customer?.postAddress?.regionEn}
        regionRu={customer?.postAddress?.regionRu}
        cityEn={customer?.postAddress?.cityEn}
        cityRu={customer?.postAddress?.cityRu}
        streetEn={customer?.postAddress?.streetEn}
        streetRu={customer?.postAddress?.streetRu}
        house={customer?.postAddress?.house}
        building={customer?.postAddress?.building}
        block={customer?.postAddress?.block}
        apartment={customer?.postAddress?.apartment}
        office={customer?.postAddress?.office}
      />

      {/*
      БЛОК: СВЕДЕНИЯ ОБ УЧЁТЕ В НАЛОГОВОМ ОРГАНЕ
    */}
      <TaxAuthorityInfo
        inn={customer?.inn}
        kpp={customer?.kpp}
        tin={customer?.tin}
        kio={customer?.kio}
        taxResidency={customer?.taxResidency}
      />

      {/*
      БЛОК: КОДЫ ФОРМ ФЕДЕРАЛЬНОГО ГОСУДАРСТВЕННОГО СТАТИСТИЧЕСКОГО НАБЛЮДЕНИЯ
    */}
      <CodeForms
        okpo={customer?.okpo}
        okato={customer?.okato}
        okved={customer?.okved}
        okfs={customer?.okfs}
        lei={customer?.lei}
        otherStatisticsCodes={customer?.otherStatisticsCodes}
      />

      {/*
      БЛОК: КОНТАКТНАЯ ИНФОРМАЦИЯ
    */}
      <ContactsInfo
        emails={customer?.emails}
        phones={customer?.phones}
        faxes={customer?.faxes}
        otherContactInfo={customer?.otherContactInfo}
      />
    </FormCol>
  )
}

export { CustomerTab }

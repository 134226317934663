import React from 'react';
import { Tab, Tabs } from 'ui/components';
import { Output } from './Output';
import { StyledWrapper } from './styled';
import { Transfer } from './Transfer';
import { History } from './History';
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useTheme } from 'ui/theme';
import { useSizeContext } from 'context/SizeContext';
import { StyledContent, StyledTabsWrapper } from '../../styled';

export const Money: React.FC = () => {
  const { url, path } = useRouteMatch()
  const location = useLocation()
  const size = useSizeContext();
  const theme = useTheme();
  const isMobile = theme.bp.isXS(size.width);

  const { pathname } = location
  const splitPathname = pathname.split('/')
  const isPathTransfer = splitPathname[3] === 'transfer'
  const isPathOutput = splitPathname[3] === 'output'
  const isPathLog = splitPathname[3] === 'log'

  return <StyledWrapper>
    <StyledTabsWrapper>
      <Tabs variant={2}>
        <Tab
          label="Перевод"
          active={isPathTransfer}
          to={`${url}/transfer/new`}
          component={NavLink}
        />
        <Tab
          label="Вывод"
          active={isPathOutput}
          to={`${url}/output/new`}
          component={NavLink}
          T />
        <Tab
          label={isMobile ? 'История' : 'История поручений'}
          active={isPathLog}
          to={`${url}/log`}
          component={NavLink}
        />
      </Tabs>
    </StyledTabsWrapper>
    <StyledContent>
      <Switch>
        <Route
          exact
          path={path}
        >
          <Redirect to={`${path}/transfer/new`} />
        </Route>
        <Route
          exact
          path={`${path}/transfer/:id`}
          component={Transfer}
        />
        <Route
          exact
          path={`${path}/output/:id`}
          component={Output}
        />
        <Route
          exact
          path={`${path}/log`}
          component={History}
        />
      </Switch>
    </StyledContent>
  </StyledWrapper>
}

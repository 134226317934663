import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { fetchUserLogoutAsync } from '../../redux/user/userSlice'
import { Button, Text } from '../../ui/components'
import {
  StyledButton, StyledInfoBlockLogout,
  StyledLabelSecond, StyledLoginContainer,
  StyledLoginSecondWrapper,
  StyledLoginTitle,
} from '../loginRedesign/styled'

function LogOut({ fetchUserLogout }) {
  const history = useHistory()

  const handleClickLogout = async () => {
    const response = await fetchUserLogout()
    const status = response?.status
    if (status === 200) {
      history.push('/login')
    }
  }

  const handleClickPrev = () => {
    history.go(-1)
  }

  return (
    <>
      <StyledLoginSecondWrapper style={{ maxWidth: 458 }}>
        <StyledLoginContainer>

          <div>
            <StyledLoginTitle>
              <Text
                variant="h1"
                color="primary"
              >
                Уверены, что хотите выйти?
              </Text>
            </StyledLoginTitle>

            <StyledLabelSecond>
              <Text
                variant="body2"
                color="primary"
              >
                Чтобы снова зайти в личный кабинет потребуется заново ввести логин и пароль пользователя
              </Text>
            </StyledLabelSecond>

            <StyledButton>
              <Button
                onClick={handleClickLogout}
              >
                Да, выйти
              </Button>
            </StyledButton>

            <StyledButton>
              <Button
                type="submit"
                variant="secondary"
                onClick={handleClickPrev}
              >
                Назад
              </Button>
            </StyledButton>
          </div>
        </StyledLoginContainer>

        <StyledInfoBlockLogout>
          <Text
            variant="body1_lg"
            color="secondary"
          >
            +7 495 777 29 64
          </Text>
          <Text
            variant="body1_lg"
            color="secondary"
          >
            client@region.ru
          </Text>
        </StyledInfoBlockLogout>
      </StyledLoginSecondWrapper>
    </>

  )
}

LogOut.propTypes = {
  fetchUserLogout: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  fetchUserLogout: (username, password) => dispatch(fetchUserLogoutAsync({ username, password })),
})

export default connect(null, mapDispatchToProps)(LogOut)

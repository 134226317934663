import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({

  tableContainer: {
    [theme.breakpoints.up('lg')]: {
      overflow: 'unset',
    },
  },

  tableRow: {
    borderRight: '6px solid transparent',
    cursor: 'pointer',
    '&.MuiTableRow-root:not(.MuiTableRow-head)': {
      '&:hover': {
        borderRightColor: '#FBC15E',
        backgroundColor: '#91B1BF1A',
      },
    },
    '&.MuiTableRow-head': {
      cursor: 'initial',
    },
    '&.MuiTableRow-head > .MuiTableCell-root': {
      fontSize: 14,
    },
    '& > .MuiTableCell-root': {
      padding: '16px 12px 17px',
      borderBottomColor: theme.palette.type === 'light' ? '#CCDAE1' : '#5C6E78',
      borderBottomStyle: 'dashed',
      fontSize: 16,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 2,
      },
      '& button': {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& svg': {
        fill: '#91B1BF',
      },
      '& .MuiCheckbox-root': {
        padding: '9px 9px 9px 3px',
        '& svg': {
          backgroundColor: '#fff',
        },
      },
      '& .MuiCheckbox-root.MuiIconButton-colorSecondary': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },

  MoneyPanel__BoxWrapper: {
    '@media (max-width: 576px)': {
      marginTop: 10,
      marginBottom: 30,
      paddingTop: 0,
    },
  },

  tableHeaderCell: {
    color: '#91B1BF',
  },

  tableBodyCell: {
    fontSize: 16,
  },

  PhoneGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 98px',
    gridGap: '3px 9px',
    paddingTop: 6,
    paddingBottom: 12,
    borderBottom: '0.5px dashed #91B1BF',
    '&:first-child': {
      borderTop: '0.5px dashed #91B1BF',
    },
  },

  PhoneGrid__item: {
    '&:nth-child(1)': {
      order: 3,
    },
    '&:nth-child(2)': {
      order: 2,
    },
    '&:nth-child(3)': {
      order: 4,
    },
    '&:nth-child(4)': {
      order: 1,
    },
    '&:nth-child(5)': {
      order: 5,
    },
    '&:nth-child(6)': {
      order: 6,
    },
    '&:nth-child(7)': {
      order: 7,
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },

  PhoneGrid__header: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#91B1BF',
  },

  PhoneGrid__value: {
    margin: 0,
    fontSize: 14,
    lineHeight: '19px',
  },

  PhoneGrid__value_medium: {
    fontWeight: 600,
  },

}))

export default useStyles

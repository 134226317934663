import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '50px',
    '@media(max-width: 576px)': {
      marginBottom: 0,
    },
    '& .MuiPaper-root': {
      '@media(max-width: 576px)': {
        backgroundColor: 'transparent',
        marginBottom: (props) => !props.expanded && 15,
      },
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  bar: {
    minHeight: 26,
    backgroundColor: (props) => (theme.palette.type === 'light' ? props.barColor : 'rgb(145, 177, 191)'),
    '&.Mui-expanded': {
      minHeight: '26px',
    },
    '&.MuiAccordionSummary-root': {
      '@media(max-width: 576px)': {
        padding: 0,
      },
    },
    '& > .MuiAccordionSummary-content': {
      flexDirection: 'column',
      margin: 0,
      padding: '4px 0',
      '& .MuiTypography-root': {
        fontSize: 16,
        lineHeight: '17px',
        color: theme.palette.type === 'light' ? '#fff' : '#292D34',
        '@media(max-width: 576px)': {
          color: theme.palette.type === 'light' ? '#313A4E' : '#fff',
          fontSize: 14,
        },
      },
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
  icon: {
    '@media(max-width: 576px)': {
      color: 'transparent',
    },
    '@media(min-width: 577px)': {
      color: (props) => props.barTextColor,
    },
  },
  details: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.type === 'light' ? '#fff' : '#282D34',
    '& > .MuiTabs-root': {
      height: 'auto',
      minHeight: 24,
      paddingTop: 18,
    },
    '& .MuiBox-root': {
      '@media(max-width: 576px)': {
        paddingTop: 0,
      },
      paddingTop: 0,
    },
    '& .MuiButtonBase-root': {
      minHeight: 28,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}))

export default function CollapseBar({
  children,
  label,
  barColor = '#91B1BF',
  barTextColor = '#fff',
  secondaryLabel = '',
  defaultExpanded,
  onChange,
  expanded,
  onClick,
  ...props
}) {
  const classes = useStyles({
    barColor,
    barTextColor,
    secondaryLabel,
    expanded,
  })
  return (
    <div className={classes.root}>
      <Accordion
        elevation={0}
        defaultExpanded={defaultExpanded}
        onChange={onChange}
        expanded={expanded}
        onClick={onClick}
        {...props}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls="expand-content"
          id={label}
          className={classes.bar}
        >
          <Typography className={classes.heading}>{label}</Typography>
          {secondaryLabel && (
            <Typography
              style={{ display: 'block' }}
              className={classes.heading}
            >
              {secondaryLabel}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails
          className={classes.details}
          style={{
            flexDirection: 'column',
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

CollapseBar.defaultProps = {
  barColor: '#91B1BF',
  barTextColor: '#fff',
  secondaryLabel: undefined,
  defaultExpanded: false,
  onChange: () => {},
  onClick: () => {},
  expanded: undefined,
}

CollapseBar.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  barColor: PropTypes.string,
  barTextColor: PropTypes.string,
  secondaryLabel: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  expanded: PropTypes.bool,
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { format } from 'date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Media from 'react-media'
import CustomSelect from '../CustomControls/CustomSelect'
import CustomIconButton from '../CustomControls/CustomIconButton'
import CustomDatepicker from '../CustomControls/CustomDatepicker'
import useStyles from './panel.style'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../config/breakpoints'
import MobileFilter from '../MobileFilter/MobileFilter'
import { selectAccount, selectFormatPeriodFrom, selectFormatPeriodTo } from '../../redux/reports/selector'
import {
  clearFilterFields,
  setAccountFilter,
  setFormatPeriodFromFilter,
  setFormatPeriodToFilter,
} from '../../redux/reports/reportsSlice'

const initialState = {
  account: '',
  period: {
    from: null,
    to: null,
  },
}

const FilterForm = ({ accounts, fetchFilterStart }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const account = useSelector(selectAccount)
  const formatPeriodFrom = useSelector(selectFormatPeriodFrom)
  const formatPeriodTo = useSelector(selectFormatPeriodTo)
  const getFormatDate = (date, formatDate) => format(new Date(date), formatDate)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(clearFilterFields()), [])

  const fetchSetAccountFilter = (value) => {
    dispatch(setAccountFilter(value))
  }

  const fetchSetFormatPeriodFromFilter = (value) => {
    dispatch(setFormatPeriodFromFilter(value && getFormatDate(value, 'yyyy-MM-dd')))
  }

  const fetchSetFormatPeriodToFilter = (value) => {
    dispatch(setFormatPeriodToFilter(value && getFormatDate(value, 'yyyy-MM-dd')))
  }

  const handleChangeAccount = (event) => {
    const { value } = event.target
    fetchSetAccountFilter(value)
  }

  const handleChangePeriodFrom = (date) => {
    fetchSetFormatPeriodFromFilter(date)
  }

  const handleChangePeriodTo = (date) => {
    fetchSetFormatPeriodToFilter(date)
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
  }

  const handleAcceptFilter = () => {
    fetchFilterStart()
  }

  const handleResetFilter = () => {
    fetchSetAccountFilter(
      initialState.account,
    )
    fetchSetFormatPeriodFromFilter(null)
    fetchSetFormatPeriodToFilter(null)
    fetchFilterStart()
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Media
        query="(min-width: 577px)"
        render={() => (
          <form onSubmit={handleSubmitForm}>
            <Grid
              container
              spacing={4}
              className={classes.FilterForm__inputs}
            >
              <Grid
                item
                lg={3}
                sm={6}
                xs={12}
              >
                <CustomSelect
                  label="Счет"
                  value={account}
                  onChange={handleChangeAccount}
                  unique
                >
                  {accounts && accounts.map((item) => {
                    const { id, number } = item

                    return (
                      <MenuItem
                        key={id}
                        value={id}
                      >
                        {number}
                      </MenuItem>
                    )
                  })}
                </CustomSelect>
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xs={12}
              >
                <CustomDatepicker
                  label="Период с"
                  maxDate={formatPeriodTo}
                  value={formatPeriodFrom}
                  setDateFrom={fetchSetFormatPeriodFromFilter}
                  isDateFrom
                  onChange={handleChangePeriodFrom}
                  unique
                />
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xs={12}
              >
                <CustomDatepicker
                  label="Период по"
                  minDate={new Date(formatPeriodFrom)}
                  value={formatPeriodTo || formatPeriodFrom}
                  setDateTo={fetchSetFormatPeriodToFilter}
                  isDateTo
                  onChange={handleChangePeriodTo}
                  unique
                />
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xs={12}
              >
                <div
                  className={classnames(
                    classes.btnGroup,
                    classes.btnGroupUnique,
                    classes.btnGroup_top,
                  )}
                >
                  <CustomIconButton
                    type="submit"
                    unique
                    onClick={handleAcceptFilter}
                    needLine
                    noIcon
                  >
                    Применить
                  </CustomIconButton>
                  <CustomIconButton
                    type="submit"
                    unique
                    noIcon
                    onClick={handleResetFilter}
                  >
                    Очистить
                  </CustomIconButton>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <MobileFilter
            handleAcceptFilter={handleAcceptFilter}
            handleResetFilter={handleResetFilter}
            constItems={[account, formatPeriodFrom, formatPeriodTo]}
          >
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}
              >
                <CustomSelect
                  label="Счет"
                  value={account}
                  onChange={handleChangeAccount}
                  unique
                  inFilter
                >
                  {accounts && accounts.map((item) => {
                    const { id, number } = item

                    return (
                      <MenuItem
                        key={id}
                        value={id}
                      >
                        {number}
                      </MenuItem>
                    )
                  })}
                </CustomSelect>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <CustomDatepicker
                  label="Период с"
                  maxDate={formatPeriodTo}
                  value={formatPeriodFrom}
                  setDateFrom={fetchSetFormatPeriodFromFilter}
                  isDateFrom
                  onChange={handleChangePeriodFrom}
                  unique
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <CustomDatepicker
                  label="Период по"
                  minDate={formatPeriodFrom}
                  value={formatPeriodTo || formatPeriodFrom}
                  setDateTo={fetchSetFormatPeriodToFilter}
                  isDateTo
                  onChange={handleChangePeriodTo}
                  unique
                />
              </Grid>
            </Grid>
          </MobileFilter>
        )}
      />
    </MuiPickersUtilsProvider>
  )
}

FilterForm.defaultProps = {
  accounts: PropTypes.array,
}

FilterForm.propTypes = {
  fetchFilterStart: PropTypes.func.isRequired,
  accounts: PropTypes.oneOfType([PropTypes.array]),
}

export default FilterForm

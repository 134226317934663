import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  getMetadata: null,
  error: null,
}

const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    getMetadata(state) {
      return {
        ...state,
        error: null,
      }
    },
    getMetadataSuccess(state, action) {
      return {
        ...state,
        getMetadata: action.payload,
      }
    },
    getMetadataFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    clearMetadata() {
      return initialState
    },
  },
})

const { actions, reducer } = metadataSlice

export const {
  getMetadata,
  getMetadataSuccess,
  getMetadataFailed,
  clearMetadata,
} = actions

export default reducer

export const fetchMetadataStartAsync = ({ id }) => async (dispatch) => {
  dispatch(getMetadata())
  try {
    const value = await Api.getMetadata(id)
    dispatch(getMetadataSuccess(value))
  } catch (err) {
    dispatch(getMetadataFailed())
  }
}

import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { Icon, Tooltip } from 'ui/components'
import Accordion from '../Accordion'

import {
  StyledCollapsibleTableBodyRow,
  StyledCollapsibleTableBodyCell,
  StyledCollapsibleTableArrow,
  StyledCollapsibleTableBodyContent,
  StyledCollapsibleTableBodyRowWrapper,
  StyleBodyCellValue,
} from './styled'

import { CollapsibleTableColumnData, ICollapsibleTableRowProps } from './types'
import {ChildTable} from "../../pages/Portfolio/components/PortfolioTables";

const CollapsableTableRow = <T extends CollapsibleTableColumnData>({
  columns = [],
  row,
  rows,
  layout = [],
  isOpenExternal,
  accordName
}: ICollapsibleTableRowProps<T>) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [bodyCellsDimensions, setBodyCellsDimensions] = useState([])

  const cellRef = useRef<HTMLDivElement[]>([])

  useLayoutEffect(() => {
    const dimensions = cellRef.current.map(({ offsetWidth, scrollWidth }) => ({
      offsetWidth,
      scrollWidth,
    }))

    setBodyCellsDimensions(dimensions)
  }, [cellRef.current])

  useEffect(() => {
    if (typeof isOpenExternal === 'boolean') setIsOpen(isOpenExternal)
  }, [isOpenExternal])
  return (
    <StyledCollapsibleTableBodyRowWrapper onClick={() => setIsOpen(!isOpen)}>
      <StyledCollapsibleTableBodyRow width={layout}>
        {columns.map(({ render, id, title, width, withTooltip, ...otherColumnProps }, index) => {
          const value = render ? render(row, rows, accordName) : row[id]

          if (!withTooltip)
            return (
              <StyledCollapsibleTableBodyCell key={index} {...otherColumnProps}>
                {value}
              </StyledCollapsibleTableBodyCell>
            )

          const offsetWidth = bodyCellsDimensions[index]?.offsetWidth
          const scrollWidth = bodyCellsDimensions[index]?.scrollWidth

          const showTooltip = offsetWidth && scrollWidth && scrollWidth > offsetWidth

          return (
            <StyledCollapsibleTableBodyCell key={index} {...otherColumnProps}>
              <Tooltip
                wrapper={{ width: '100%' }}
                content={row?.storages?.name}
                active={!!cellRef?.current?.[index] && !!showTooltip}
                maxWidth={400}
              >
                <StyleBodyCellValue
                  {...(!showTooltip && { display: 'flex' })}
                  ref={(el) => (cellRef.current[index] = el)}
                  {...otherColumnProps}
                >
                  {value}
                </StyleBodyCellValue>
              </Tooltip>
            </StyledCollapsibleTableBodyCell>
          )
        })}

        <StyledCollapsibleTableBodyCell>
          <StyledCollapsibleTableArrow open={isOpen}>
            <Icon name={'arrow-down'} size={'md'} />
          </StyledCollapsibleTableArrow>
        </StyledCollapsibleTableBodyCell>
      </StyledCollapsibleTableBodyRow>

      <Accordion bodyPaddingTop={0} padding={0} isOpen={isOpen}>

        { row?.storages && (
            <StyledCollapsibleTableBodyContent>
            <ChildTable rows={row?.storages} />
          </StyledCollapsibleTableBodyContent>
          )
        }

      </Accordion>
    </StyledCollapsibleTableBodyRowWrapper>
  )
}

export default memo(CollapsableTableRow) as typeof CollapsableTableRow

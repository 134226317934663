import { Link } from 'react-router-dom';
import { styled } from "ui/theme";


export const StyledProfileButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.color.text.secondary};
  svg {
    display: block;
    position: relative;
    z-index: 1;
  }
  @media ${({ theme }) => theme.breakpoint.xs} {
    color: ${({ theme }) => theme.color.text.inverted};
  }
`

export const StyledProfileButtonText = styled.span`
`

import React from 'react'
import { Checkbox } from 'ui/components'
import { StyledCheckboxFieldContent, StyledCheckboxField, StyledCheckboxFieldCaption, StyledCheckboxFieldLabel } from './styled'
import { ICheckboxFieldProps } from './types'

export const CheckboxField: React.FC<ICheckboxFieldProps> = ({ label, caption, disabled, hasError, checked = false, onChange, ...props }) => {
  return <StyledCheckboxField {...props}>
    <Checkbox size="md" onChange={onChange} checked={checked} disabled={disabled}/>
    <StyledCheckboxFieldContent>
      <StyledCheckboxFieldLabel>{label}</StyledCheckboxFieldLabel>
      {caption && <StyledCheckboxFieldCaption hasError={hasError}>{caption}</StyledCheckboxFieldCaption>}
    </StyledCheckboxFieldContent>
  </StyledCheckboxField>
}

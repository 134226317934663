import React, { memo } from 'react'
import {
  StyledCollapsibleTable,
  StyledCollapsibleTableHeader,
  StyledCollapsibleTableHeaderCell,
  StyledCollapsibleTableBody,
  StyledChildrenTableRow
} from './styled'

const CollapsibleTable = (
  {
    columns = [],
    rows = [],
    layout = [],
  }) => {
  return (
    <StyledCollapsibleTable>
      <StyledCollapsibleTableHeader layout={layout}>
        {columns.map((column) => (
          <StyledCollapsibleTableHeaderCell key={column.id}>
            {typeof column.title === 'function' ? column.title(rows) : column.title}
          </StyledCollapsibleTableHeaderCell>
        ))}
      </StyledCollapsibleTableHeader>
      <StyledCollapsibleTableBody>
        {
          rows.map((row) => {
            return <StyledChildrenTableRow layout={layout}>
              {
                columns.map((column) => {
                  return (
                    <div key={column?.id}>
                      {
                        column?.render
                          ? column?.render(row)
                          : row[column?.id]
                      }
                    </div>
                  )
                })
              }
            </StyledChildrenTableRow>
          })
        }
      </StyledCollapsibleTableBody>
    </StyledCollapsibleTable>
  )
}

export default memo(CollapsibleTable) as typeof CollapsibleTable

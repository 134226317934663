import {API_USER_REQUEST_STATUS_TYPE_LIST} from "types/userRequest";

/**
 * Правовой тип лица.
 *
 * @example
 * "unknown" - не определен
 * "firm" - юридическое лицо
 * "person" - физическое лицо
 */
export const LEGAL_ENTITY  = {
  /**
   * **Тип:** Не определен
   */
  UNKNOWN: "unknown",

  /**
   * **Тип:** Юридическое лицо.
   */
    FIRM: "firm",

  /**
   * **Тип:** Физическое лицо.
   */
    PERSON: "person"
} as const;

export type LegalEntityList = typeof LEGAL_ENTITY[keyof typeof LEGAL_ENTITY];

/**
 * Клиент
 */
export interface Client {
  /**
   * Полное наименование.
   *
   * @example
   * fullName: "Черноморское отделение Арбатовской конторы по заготовке рогов и копыт"
   */
  fullName: string;

  /**
   * Внутренний идентификатор.
   *
   * @example
   * id: 244396
   */
  id: number;

  /**
   * Признак квалифицированного инвестора.
   *
   * @example
   * isQualifiedInvestor: true
   */
  isQualifiedInvestor: boolean;

  /**
   * Правовой тип лица.
   *
   * @example
   * legalEntity: 'firm'
   */
  legalEntity: LegalEntityList;

  /**
   * Полное наименование.
   *
   * @example
   * shortName: "ООО \"Рога и Копыта\""
   */
  shortName: string;
}

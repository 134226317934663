import { getUserCertificates } from 'crypto-pro'

const getCertificates = async ({ thumbPrints }) => {
  let certificates = null
  try {
    const certificatesDert = await getUserCertificates()
    certificates = certificatesDert.filter((f) => thumbPrints.some((s) => s === f.thumbprint))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
  return certificates
}
export default getCertificates

import { request } from './Request'
import { asyncApiURL, syncApiURL } from '../config/api'
import endpoints from './endpoints'

export default {
  getLink() {
    return request(asyncApiURL, endpoints.scanerLink)
  },
  getScannerIsAccessRequest() {
    return request(syncApiURL, endpoints.scanner.getRequestIsAccess)
  },
  getScannerLinkRequest() {
    return request(syncApiURL, endpoints.scanner.getScannerLink)
  },
  postScannerAccessRequest() {
    return request(syncApiURL, endpoints.scanner.getRequestAccess, 'POST')
  },
}

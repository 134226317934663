import { makeStyles } from '@material-ui/core/styles'

const LinearProgress = makeStyles((theme) => ({
  LinearProgress: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
  },
  LinearProgress__topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 9,
  },
  LinearProgress__category: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    lineHeight: '21px',
    color: theme.palette.type === 'light' ? '#323B4E' : '#fff',
  },
  LinearProgress__mainRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 3,
  },
  LinearProgress__assets: {
    marginRight: 16,
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '19px',
    color: theme.palette.type === 'light' ? '#323B4E' : '#fff',
  },
  LinearProgress__percent: {
    position: 'relative',
    top: 2,
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: '16px',
    color: theme.palette.type === 'light' ? '#323B4E' : '#fff',
  },
  LinearProgress__progress: {
    width: '100%',
    height: 7,
    backgroundColor: 'rgba(145, 177, 191, 0.6)',
  },
  LinearProgress__progressItem: {
    height: '100%',
    transition: '1s ease-out',
  },
}))

export default LinearProgress

import React from 'react'
import Media from 'react-media'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import useStyles from './sharedStyles'
import CustomInput from '../../CustomControls/CustomInput'
import CustomDatepicker from '../../CustomControls/CustomDatepicker'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

const LawRegToDateContent = ({ data, handleClickNextPanel, isNeedNextButton }) => {
  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <Media
        query="(min-width: 577px)"
        render={() => (
          <>
            <Grid
              container
            >
              <Grid
                item
                container
                className={classes.row}
                justify="space-between"
                spacing={2}
              >
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <CustomInput
                    name="ogrn"
                    label="Регистрационный номер"
                    value={data?.registerNumber || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    unique
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <CustomDatepicker
                    label="Дата регистрации"
                    value={data?.registerDate}
                    disabled
                    unique
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <CustomInput
                  name="regPlace"
                  label="Регистрирующий орган"
                  value={data?.registerIssuer || ''}
                  fullWidth
                  variant="outlined"
                  size="small"
                  disabled
                  unique
                />
              </Grid>
            </Grid>
          </>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <>
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Регистрационный номер
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.registerNumber}
              </div>
            </div>
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Дата регистрации
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.registerDate}
              </div>
            </div>
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Регистрирующий орган
              </div>
              <div className={classes.Mobile__blockText}>
                {data?.registerIssuer}
              </div>
            </div>
            {isNeedNextButton && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </>
        )}
      />
    </MuiPickersUtilsProvider>
  )
}

LawRegToDateContent.defaultProps = {
  data: PropTypes.object,
  handleClickNextPanel: () => {},
  isNeedNextButton: false,
}

LawRegToDateContent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  handleClickNextPanel: PropTypes.func,
  isNeedNextButton: PropTypes.bool,
}

export default LawRegToDateContent

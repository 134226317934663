import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import useStyles from './CustomLoader.style'

const CustomLoader = ({ text, length, isNew }) => {
  const classes = useStyles()
  const iterates = [...Array(length)]

  return (
    <div className={isNew ? classes.Loader_new : classes.Loader}>
      {text && (
        <Typography
          className={isNew ? classes.Loader__label_new : classes.Loader__label}
          component="p"
        >
          {text}
        </Typography>
      )}
      <div className={isNew ? classes.Loader__items_new : classes.Loader__items}>
        {iterates?.map((item, key) => <span key={item + key.toString()} />)}
      </div>
    </div>
  )
}

CustomLoader.defaultProps = {
  text: null,
  length: 18,
  isNew: false,
}

CustomLoader.propTypes = {
  text: PropTypes.string,
  length: PropTypes.number,
  isNew: PropTypes.bool,
}

export default CustomLoader

/* eslint-disable */
import Api from '../api/Api';

var CADESCOM_CADES_BES = 1;
var CAPICOM_CURRENT_USER_STORE = 2;
var CAPICOM_MY_STORE = "My";
var CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
// var CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;
const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;

export const fileEds = {
  sign(currentThumbprint, dataToSign, certSubjectName, failedCallback) {
    return new Promise(function(resolve, reject){
      cadesplugin.async_spawn(function *(args) {
        try {
          var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE,
            CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

          var CertificatesObj = yield oStore.Certificates;
          var oCertificates = yield CertificatesObj.Find(
            CAPICOM_CERTIFICATE_FIND_SHA1_HASH, currentThumbprint);
          var Count = yield oCertificates.Count;
          if (Count == 0) {
            throw("Certificate not found: " + args[0]);
          }
          var oCertificate = yield oCertificates.Item(1);
          var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
          yield oSigner.propset_Certificate(oCertificate);

          var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
          yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
          yield oSignedData.propset_Content(dataToSign);

          var sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES, true);

          yield oStore.Close();

          args[2](sSignedMessage);
        }
        catch (err)
        {
          args[3]("Failed to create signature. Error: " + cadesplugin.getLastError(err));
          failedCallback(err)
        }
      }, certSubjectName, dataToSign, resolve, reject);
    });
  },
  run(sCertName, requestObj, dataToSign, thumbprint, failedCallback) {
    if (!sCertName) {
      alert('Введите имя сертификата (CN).')
      return
    }
    var thenable = fileEds.sign(thumbprint, dataToSign, sCertName, failedCallback)

    thenable.then(
      async (result) => {
        const newObj = requestObj
        newObj.sign = result
        newObj.signCertificate = thumbprint
        await Api.postClientSign(newObj)
      },
    )
  },
}

/* eslint-enable */

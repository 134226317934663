import React, { Children, cloneElement } from 'react'
import { StyledField, StyledFieldCaption, StyledFieldLabel, StyledFieldContent } from './styled'
import { IFieldProps } from './types'

export const Field: React.FC<IFieldProps> = ({ label, caption, hasError, children, ...props }) => {
  return <StyledField {...props} style={{justifyContent: 'space-between'}}>
    {label && <StyledFieldLabel hasError={hasError} >{label}</StyledFieldLabel>}
    <StyledFieldContent>
      {
        // @ts-ignore
        Children.map(children, (child) => cloneElement(child, { hasError }))
      }
    </StyledFieldContent>
    {caption && <StyledFieldCaption hasError={hasError}>{caption}</StyledFieldCaption>}
  </StyledField>
}

import React from 'react'
import TabsComponent from '../../components/Tabs/Tabs'
import { ReactComponent as ReportsOrderIcon } from '../../assets/images/reports-order.svg'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import useWindowSize from '../../hooks/useWindows'
import PageHeading from '../../components/PageHeading/PageHeading'
import ReportPanel from '../../components/ReportPanels/ReportPanel'

export default function Reports() {
  const { windowWidth } = useWindowSize()

  const tabLinks = [
    {
      key: '024b2efa-128f-11eb-adc1-0242ac120002',
      link: 'log',
      label: 'Отчеты',
      icon: windowWidth > 576 ? <ReportsOrderIcon /> : <TransfersIcon />,
      component: ReportPanel,
    },
  ]

  return (
    <>
      <PageHeading text="Отчеты" />
      <TabsComponent
        tabLinks={tabLinks}
        wishStyle={{ justifyContent: 'flex-start' }}
        firstStepOverOnMobile
      />
    </>
  )
}

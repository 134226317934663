import React from 'react'
import TabsComponent from '../../components/Tabs/Tabs'
import { ReactComponent as TransfersMoneyIcon } from '../../assets/images/transfers-money.svg'
import { ReactComponent as TransfersSecuritiesIcon } from '../../assets/images/transfers-securities.svg'
import { ReactComponent as TransfersRequisitesIcon } from '../../assets/images/transfers-requisites.svg'
import { ReactComponent as TransfersIcon } from '../../assets/images/transfers.svg'
import Money from '../../components/TransferPanels/Money'
import Securities from '../../components/TransferPanels/Securities'
import Requisites from '../../components/TransferPanels/Requisites'
import PageHeading from '../../components/PageHeading/PageHeading'
import useWindowSize from '../../hooks/useWindows'

export default function Transfers() {
  const { windowWidth } = useWindowSize()

  const tabLinks = [
    {
      key: '6acc4dde-1423-11eb-adc1-0242ac120002',
      link: 'money',
      label: 'Денежные средства',
      icon: windowWidth > 576 ? <TransfersMoneyIcon /> : <TransfersIcon />,
      component: Money,
    },
    {
      key: '75edca6c-1423-11eb-adc1-0242ac120002',
      link: 'securities',
      label: 'Ценные бумаги',
      icon: windowWidth > 576 ? <TransfersSecuritiesIcon /> : <TransfersIcon />,
      component: Securities,
    },
    {
      key: 'd38ebd14-a40d-11eb-bcbc-0242ac130002 ',
      link: 'requisites',
      label: 'Реквизиты',
      icon: windowWidth > 576 ? <TransfersRequisitesIcon /> : <TransfersIcon />,
      component: Requisites,
    },
  ]

  return (
    <>
      <PageHeading text="Поручения" />
      <TabsComponent
        tabLinks={tabLinks}
        wishStyle={{ justifyContent: 'flex-start' }}
      />
    </>
  )
}

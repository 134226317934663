import React from 'react'
import { StyledTab, StyledTabs } from './styled';
import { ITabProps, ITabsProps } from './types';


const TabContext = React.createContext<ITabsProps['variant']>(1);

export const Tabs: React.FC<ITabsProps> = ({ variant = 1, children, ...props }) => {
  return <TabContext.Provider value={variant}>
      <StyledTabs variant={variant} {...props}>
        {children}
    </StyledTabs>
  </TabContext.Provider>
}

export const Tab: React.FC<ITabProps> = ({ label, active, ...props }) => {
  const variant = React.useContext(TabContext);
  return <StyledTab variant={variant} className={active ? 'active' : ''} {...props}>{label}</StyledTab>
}

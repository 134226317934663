import React, {FC, useCallback} from 'react';
import { Radio } from "ui/components/atoms";
import {RadioLabeledWrapper, RadioLabeledLabel, RadioLabeledList, RadioLabeledCaption} from "./styled"
import { RadioLabeledProps } from './types'
import {ChangeRadioEvent} from "ui/components/atoms/Radio/types";

export const RadioLabeled: FC<RadioLabeledProps> = ({
  radioButtonsList,
  label,
  labelBold = true,
  caption,
  onChange = () => {},
}) => {
  const handleChange = useCallback((event: ChangeRadioEvent) => {
      const targetValue = event.value;

      const updatedList = radioButtonsList.map((radioButton) => ({
        ...radioButton,
        checked: radioButton.value === targetValue
      }))

      onChange(event, updatedList)
  }, [])

  return (
    <RadioLabeledWrapper>
      <>
        {/* LABEL */}
        { label && <RadioLabeledLabel labelBold={labelBold} >{label}</RadioLabeledLabel> }

        {/* RADIO BUTTONS LIST */}
        {
          radioButtonsList.length &&
            <RadioLabeledList>
              {
                radioButtonsList.map(({id, name, value, checked, disabled, title}, key) => (
                  <Radio
                    key={key}
                    name={name}
                    value={value}
                    title={title}
                    id={id}
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                  />
                ))
              }
            </RadioLabeledList>
        }

        {/* CAPTION */}
        { caption && <RadioLabeledCaption>{caption}</RadioLabeledCaption> }
      </>

    </RadioLabeledWrapper>
  )
};

import React, { useRef, useState } from 'react';
import { Icon } from 'ui/components';
import { StyledProfileButton, StyledProfileButtonText } from './styled';
import { IProfileButtonProps } from './types';
import { useOnClickOutside } from "hooks/useOnclickOutside";


export const ProfileButton: React.FC<IProfileButtonProps> = ({ agentName }) => {

  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(menuRef, () => setIsOpen(false));
  return (
    <StyledProfileButton ref={menuRef} onClick={() => setIsOpen(!isOpen)}>
      <Icon name="menu-user" size={'md'} />
      <StyledProfileButtonText>{agentName}</StyledProfileButtonText>
    </StyledProfileButton>
  );
}
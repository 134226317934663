import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Selector from '../../../../components/loginRedesign/selector'
import NewPass from './newPass'
import Certificates from '../../../../components/Certificates'
import { fetchResetStartAsync } from '../../../../redux/user/userSlice'
import Code from '../../../../components/loginRedesign/code'
import {
  selectCurrentCertificate,
  selectCurrentCode,
  selectCurrentUser,
  selector,
  selectNewPass,
  selectCurrentResetClient,
} from '../../../../redux/user/selector'

const Reset = ({ fetchResetStart }) => {
  const history = useHistory()
  const [stepVisibility, setStepVisibility] = useState({
    password: false,
    select: false,
    sign: false,
    code: false,
  })
  const [cause, setCause] = useState('')
  const [selectorItems, setSelectorItems] = useState([])
  const [arraySteps, setArraySteps] = useState([])

  const getSelector = useSelector(selector)
  const currentUser = useSelector(selectCurrentUser)
  const currentCertificate = useSelector(selectCurrentCertificate)
  const currentCode = useSelector(selectCurrentCode)
  const currentNewPass = useSelector(selectNewPass)
  const currentReset = useSelector(selectCurrentResetClient)

  const setStepArray = (currentStep) => {
    const currentArraySteps = arraySteps
    const getLastStep = currentArraySteps[currentArraySteps.length - 1]
    if (arraySteps?.length > 1) {
      currentArraySteps.splice(currentArraySteps.length - 1, 1)
    }
    setStepVisibility({
      ...stepVisibility,
      [getLastStep]: true,
      [currentStep]: false,
    })
    setArraySteps(currentArraySteps)
  }

  useEffect(() => {
    const requiredAction = getSelector
    if (requiredAction) {
      setStepVisibility({
        password: false,
        select: true,
        sign: false,
        code: false,
      })
      setCause(requiredAction?.cause)
      setSelectorItems(requiredAction?.selectAction?.items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelector])

  useEffect(() => {
    const accessToken = currentUser?.accessToken
    if (accessToken) {
      history.push('/')
    }
    const type = currentCertificate?.requiredAction?.type
    if (type) {
      switch (type) {
        case 'sign':
          setStepVisibility({
            password: false,
            select: false,
            sign: true,
            code: false,
          })
          break
        case 'code':
          setStepVisibility({
            password: false,
            select: false,
            sign: false,
            code: true,
          })
          break
        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCertificate, currentUser])

  useEffect(() => {
    const type = currentCode?.requiredAction?.type
    if (type === 'sign') {
      setStepVisibility({
        password: false,
        select: false,
        sign: true,
        code: false,
      })
      return
    }
    if (type === 'code') {
      setStepVisibility({
        password: false,
        select: false,
        sign: false,
        code: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCode])

  useEffect(() => {
    const requiredAction = currentNewPass?.requiredAction
    if (requiredAction) {
      setStepVisibility({
        password: true,
        select: false,
        sign: false,
        code: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNewPass])

  useEffect(() => {
    if (currentReset?.refreshToken) {
      history.push('/login')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReset])

  const fetchReset = async (secret) => {
    await fetchResetStart(secret).then((response) => {
      const requiredAction = response?.requiredAction
      setArraySteps([requiredAction?.type])
      if (!requiredAction) {
        const nextLocation = {
          pathname: '/login',
          state: { error: 'forbidden' },
        }
        history.push(nextLocation)
      }
    })
  }

  const params = useParams()
  const { id: secret } = params

  useEffect(() => {
    fetchReset(secret)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        width: '100%',
        maxWidth: 460,
      }}
    >
      {stepVisibility.select && (
        <Selector
          setStepVisibility={setStepVisibility}
          stepVisibility={stepVisibility}
          setStepArray={setStepArray}
          cause={cause}
          selectorItems={selectorItems}
          arraySteps={arraySteps}
          setArraySteps={setArraySteps}
          isPasswordRecovery
        />
      )}
      {stepVisibility.password && (
      <NewPass
        arraySteps={arraySteps}
        setArraySteps={setArraySteps}
      />
      )}
      {stepVisibility.sign && (
        <Certificates
          isPasswordRecovery
          currentUser={currentUser}
          setStepVisibility={setStepVisibility}
          stepVisibility={stepVisibility}
          setStepArray={setStepArray}
          arraySteps={arraySteps}
          setArraySteps={setArraySteps}
        />
      )}
      {stepVisibility.code && (
        <Code
          isPasswordRecovery
          setStepVisibility={setStepVisibility}
          stepVisibility={stepVisibility}
          setStepArray={setStepArray}
          arraySteps={arraySteps}
          setArraySteps={setArraySteps}
        />
      )}
    </div>
  )
}

Reset.propTypes = {
  fetchResetStart: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  fetchResetStart: (secret) => dispatch(fetchResetStartAsync({ secret })),
})

export default connect(null, mapDispatchToProps)(Reset)

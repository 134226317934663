import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    paddingTop: 44,
    '@media(max-width: 576px)': {
      paddingTop: 0,
    },
  },
  crumbContainer: {
    display: 'flex',
    marginTop: 44,
    '@media (max-width: 576px)': {
      position: 'relative',
      left: -8,
      width: 'calc(100% + 8px)',
      marginTop: 32,
      display: 'inline-block',
    },
  },
  breadCrumb: {
    width: 'auto',
    color: '#91B1BF',
    fontSize: 17,
    fontWeight: 400,
    paddingBottom: 7,
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'transparent',
    border: 0,
    borderBottom: `2px solid ${theme.palette.type === 'light' ? '#91B1BF99' : '#91B1BF'}`,
    fontFamily: 'Open Sans',
    cursor: 'pointer',
    '@media (max-width: 576px)': {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: 0,
      borderBottom: 0,
    },
    '&:not(:last-child)': {
      marginRight: 50,
      '@media (max-width: 576px)': {
        marginRight: 0,
        marginBottom: 30,
      },
    },
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      color: theme.palette.type === 'light' ? '#363E47' : '#fff',
      borderBottomColor: '#FBC15E',
      '@media (max-width: 576px)': {
        color: '#91B1BF',
      },
      '& svg': {
        '&:nth-child(2)': {
          '& line': {
            stroke: '#363E47',
          },
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& span': {
      display: 'inline-block',
      marginRight: 31,
      '@media (max-width: 576px)': {
        marginRight: 0,
        fontSize: 16,
        lineHeight: '21px',
        textAlign: 'left',
      },
    },
    '& svg': {
      marginTop: 1,
      '@media (max-width: 576px)': {
        minWidth: 19,
        marginTop: 3,
        marginRight: 10,
        fill: '#91B1BF',
      },
      '& line': {
        stroke: '#91B1BF',
      },
    },
  },
  activeCrumb: {
    color: theme.palette.type === 'light' ? '#363E47' : '#fff',
    borderBottomColor: '#FBC15E',
    '@media (max-width: 576px)': {
      color: '#91B1BF',
    },
    '& svg': {
      '& line': {
        stroke: '#363E47',
      },
    },
  },
}))

export default useStyles

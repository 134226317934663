import styled from 'styled-components'

import { Text } from 'ui/components/atoms/Text'

import { tagColorsByType } from './data'
import { ITag } from './types'

export const StyledSubtitle = styled(Text)<ITag>`
  display: inline-block;
  padding: ${({ theme: { spacing } }) => `${spacing.xxs} ${spacing.sm}`};
  border-radius: ${({ theme: { borderRadius } }) => borderRadius.xs};

  ${({ type }) => `
    background: ${tagColorsByType[type].background};
    color: ${tagColorsByType[type].color};
  `}
  ${({ theme: { font } }) => font.body2};

  @media ${({ theme }) => theme.breakpoint.xs} {
    padding: ${({ theme: { spacing } }) => `${spacing.hair} ${spacing.xs}`};

    ${({ theme: { font } }) => font.caption1};
  }
`

import React from 'react'
import { FormCol } from 'ui/components/atoms'
import RadioSwitch from './components/RadioSwitch'
import { BankRequisites } from './Blocks/BankRequisites'
import { ActivityAndOperationsInfo } from './Blocks/ActivityAndOperationsInfo'

import type { FC } from 'react'
import type { ClientFirmForm } from 'types/clientFirmForm'
import {
  HAS_AML_CONTROL_TITLE,
  HAS_NON_FATF_COUNTRIES_ACCOUNTS,
  IS_BROKER_TITLE,
  IS_STRATEGICALLY_IMPORTANT,
} from './data'
import PlainOperationsDetails from './Blocks/PlainOperationsDetails'
import Trusts from './Blocks/Trusts'
import useMedia from 'hooks/useMedia'

const CommonTab: FC<{ clientInfo: ClientFirmForm['clientInfo'] }> = ({ clientInfo }) => {
  const { isTablet } = useMedia()

  const radioSwitchData = [
    { title: IS_BROKER_TITLE, value: clientInfo?.isBroker },
    {
      title: HAS_AML_CONTROL_TITLE,
      value: clientInfo?.hasAmlControl,
    },
    {
      title: HAS_NON_FATF_COUNTRIES_ACCOUNTS,
      value: clientInfo?.hasNonFatfCountriesAccounts,
    },
    {
      title: IS_STRATEGICALLY_IMPORTANT,
      value: clientInfo?.isStrategicallyImportant,
    },
  ]

  return (
    <FormCol gap={16}>
      {radioSwitchData.map(({ title, value }) => (
        <RadioSwitch
          rowTablet
          paddingTablet="24px 32px"
          alignItemsTablet="center"
          {...(isTablet && { titleMaxWidth: 372 })}
          title={title}
          value={value}
        />
      ))}

      <BankRequisites />

      <ActivityAndOperationsInfo
        isOpen
        licenses={clientInfo?.licenses}
        management={clientInfo?.management}
        affiliates={clientInfo?.affiliates}
        registeredCapital={clientInfo?.registeredCapital}
        registeredCapitalCurrency={clientInfo?.registeredCapitalCurrency}
      />

      <PlainOperationsDetails />

      <Trusts />
    </FormCol>
  )
}

export { CommonTab }

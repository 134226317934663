import { ROUTES } from 'app/data'

export const profileAdditionalInfoTabs = [
  {
    label: 'Доверенное лицо',
    value: ROUTES.profileConfidant,
  },
  {
    label: 'Бенефициары',
    value: ROUTES.profileBeneficiaries,
  },
] as const

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  DesktopAppBar: {
    position: 'absolute',
    '@media (min-width: 577px)': {
      width: 1330,
    },
  },
  DesktopAppBar__inner: {
    position: 'relative',
    display: 'flex',
  },
  DesktopAppBar__top: {
    position: 'absolute',
    top: 0,
    left: 272,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    width: 'calc(100% - 272px)',
    padding: '21px 0 31px',
  },
  DesktopAppBar__actionsList: {
    display: 'flex',
    alignItems: 'center',
  },
  DesktopAppBar__actionsListItem: {
    marginLeft: 8,
    textTransform: 'initial',
    color: '#91b1bf',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      '& svg': {
        fill: theme.palette.type === 'light' ? '#313A4E' : '#fff',
      },
      '& .MuiButton-label': {
        color: theme.palette.type === 'light' ? '#313A4E' : '#fff',
      },
    },
  },
  DesktopAppBar__actionsListLink: {
    width: '100%',
    padding: '6px 16px',
    textDecoration: 'none',
  },
  DesktopAppBar__actionsListIcon: {
    fill: '#91B1BF',
  },
  DesktopAppBar__switchButtons: {
    '& button': {
      marginLeft: 0,
      paddingLeft: 8,
      paddingRight: 8,
      '&:first-child': {
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 13,
          right: -1,
          width: 1,
          height: 16,
          backgroundColor: '#91B1BF',
        },
      },
    },
  },
  DesktopAppBar__badgeWrapper: {
    '& .MuiBadge-badge': {
      top: 4,
      right: -1,
      minWidth: 16,
      height: 16,
      padding: '0 3px',
      fontSize: 10,
    },
  },
  DesktopAppBar__menu: {
    '& .MuiPaper-root': {
      overflow: 'visible',
      maxWidth: 310,
      width: '100%',
      marginTop: 14,
      marginLeft: 8,
      borderRadius: 0,
      border: '1px solid #363E47',
      boxShadow: 'none',
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#2E353C',
      '@media (max-width: 1800px)': {
        marginLeft: -12,
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: -19,
        left: -1,
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '18px 0 0 18px',
        borderColor: 'transparent transparent transparent #313A4E',
        '@media (max-width: 1800px)': {
          left: 'auto',
          right: -1,
          borderWidth: '0 0 18px 18px',
          borderColor: 'transparent transparent #313A4E transparent',
        },
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: -17,
        right: 0,
        display: 'none',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 0 17px 17px',
        borderColor:
          theme.palette.type === 'light'
            ? 'transparent transparent #FFF transparent'
            : 'transparent transparent #2E353C transparent',
        '@media (max-width: 1800px)': {
          display: 'block',
        },
      },
    },
    '& .MuiList-root': {
      position: 'relative',
      padding: '16px 0',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 57,
        height: '100%',
        backgroundColor: '#313A4E',
      },
    },
    '& .MuiButtonBase-root': {
      '&:hover': {
        backgroundColor: 'transparent',
        '& svg': {
          fill: '#fff',
        },
        '& .MuiTypography-root': {
          color: theme.palette.type === 'light' ? '#363E47' : '#fff',
        },
      },
      '& a': {
        display: 'flex',
        width: '100%',
        textDecoration: 'none',
      },
      '& span': {
        position: 'absolute',
        display: 'inline-block',
        width: 16,
        height: 16,
        marginLeft: 2,
        borderRadius: '50%',
        backgroundColor: '#f44336',
        fontSize: 10,
        fontWeight: 500,
        textAlign: 'center',
        color: '#fff',
      },
    },
    '& .MuiListItemIcon-root': {
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        fill: '#91B1BF',
      },
    },
    '& .MuiTypography-root': {
      boxSizing: 'border-box',
      width: '100%',
      padding: '19px 12px',
      fontSize: 15,
      color: '#91B1BF',
    },
  },
  DesktopAppBar__sidebar: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 272,
    height: '100%',
    paddingTop: 29,
    paddingBottom: 28,
  },
  DesktopAppBar__logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  DesktopAppBar__logo: {
    marginRight: 27,
    marginLeft: 16,
    fill: '#ffffff',
  },
  DesktopAppBar__today: {
    fontSize: '14px',
    color: theme.palette.type === 'light' ? '#000' : '#91B1BF',
  },
  DesktopAppBar__todayTime: {
    fontWeight: 400,
    color: theme.palette.type === 'light' ? '#000' : '#91B1BF',
  },
  DesktopAppBar__list: {
    display: 'flex',
    flexDirection: 'column',
  },
  DesktopAppBar__menuList: {
    position: 'relative',
    top: '-1px',
  },
  DesktopAppBar__listItem: {
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& $DesktopAppBar__listIconWrapper': {
        borderRightColor: '#91b1bf',
      },
      '& svg': {
        fill: '#fff',
      },
      '& .MuiTypography-root': {
        color: theme.palette.type === 'light' ? '#313A4E' : '#fff',
      },
    },
    '& .MuiTypography-root': {
      fontSize: '16px',
      color: theme.palette.type === 'light' ? '#527084' : '#91B1BF',
    },
  },
  DesktopAppBar__listItemExit: {
    '&:hover': {
      '&:before': {
        backgroundColor: 'transparent',
      },
      '& $DesktopAppBar__listIconWrapper': {
        '& svg': {
          fill: '#91B1BF',
        },
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: '69px',
      height: '56px',
      backgroundColor: '#91B1BF',
    },
    '& $DesktopAppBar__listIconWrapper': {
      position: 'relative',
      zIndex: 1,
      borderRight: 0,
      '& svg': {
        position: 'relative',
        left: '-3px',
        fill: '#363E47',
      },
    },
    '& .MuiTypography-root': {
      fontSize: '14.39px',
    },
  },
  activeLink: {
    '& $DesktopAppBar__listItem': {
      backgroundColor: 'transparent',
      '& $DesktopAppBar__listIconWrapper': {
        borderRightColor: '#91b1bf',
      },
    },
  },
  DesktopAppBar__listIconWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 69,
    height: 33,
    marginRight: 11,
    paddingLeft: 6,
    color: '#91b1bf',
    borderRight: '6px solid transparent',
    '& svg': {
      fill: '#91B1BF',
    },
  },
  DesktopAppBar__sidebarLayer: {
    position: 'absolute',
    top: 0,
    zIndex: -1,
    width: 69,
    height: '100%',
    backgroundColor: '#313a4e',
  },
}))

export default useStyles

import React from 'react';
import { StyledSwitchbox, StyledSwitchboxToggler } from './styled';
import { ISwitchboxProps } from './types';

export const Switchbox: React.FC<ISwitchboxProps> = ({ checked, name, onChange = () => null }) => {
  return <StyledSwitchbox checked={checked} onClick={(event) => onChange?.({
    ...event,
    target: {
      ...event.target,
      checked: !checked,
      name,
    }
  })}>
    <StyledSwitchboxToggler />
  </StyledSwitchbox>;
};
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: 75,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 32,
    },
  },
}))

export default useStyles

import { createSlice } from '@reduxjs/toolkit'
import Api from '../../api/Api'

const initialState = {
  getHistoryMoney: null,
  getHistoryDepo: null,
  error: null,
  ordersTypesNames: null,
}

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    getHistoryMoney(state) {
      return {
        ...state,
        error: null,
      }
    },
    getHistoryMoneySuccess(state, action) {
      return {
        ...state,
        getHistoryMoney: action.payload,
      }
    },
    getHistoryMoneyFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getHistoryMoneyFilter(state) {
      return {
        ...state,
      }
    },
    getHistoryMoneyFilterFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getHistoryDepo(state) {
      return {
        ...state,
      }
    },
    getHistoryDepoSuccess(state, action) {
      return {
        ...state,
        getHistoryDepo: action.payload,
      }
    },
    getHistoryDepoFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },
    getHistoryDepoFilter(state) {
      return {
        ...state,
      }
    },
    getHistoryDepoFilterFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },

    getOrdersTypesNames(state) {
      return {
        ...state,
      }
    },
    getOrdersTypesNamesSuccess(state, action) {
      return {
        ...state,
        ordersTypesNames: action.payload?.data,
      }
    },
    getOrdersTypesNamesFailed(state, action) {
      return {
        ...state,
        error: action.payload,
      }
    },


  },
})

const { actions, reducer } = historySlice

export const {
  getHistoryMoney,
  getHistoryMoneySuccess,
  getHistoryMoneyFailed,
  getHistoryDepo,
  getHistoryDepoSuccess,
  getHistoryDepoFailed,
  getHistoryDepoFilter,
  getHistoryDepoFilterFailed,
  getHistoryMoneyFilter,
  getHistoryMoneyFilterFailed,
  getOrdersTypesNames,
  getOrdersTypesNamesSuccess,
  getOrdersTypesNamesFailed,
} = actions

export default reducer

export const fetchHistoryMoneyStartAsync = () => async (dispatch) => {
  dispatch(getHistoryMoney())
  try {
    const value = await Api.getHistoryMoney()
    dispatch(getHistoryMoneySuccess(value))
  } catch (err) {
    dispatch(getHistoryMoneyFailed())
  }
}

export const fetchHistoryMoneyFilterStartAsync = ({ obj }) => async (dispatch) => {
  dispatch(getHistoryMoneyFilter())
  try {
    const value = await Api.getHistoryMoneyFilter(obj)
    dispatch(getHistoryMoneySuccess(value))
  } catch (err) {
    dispatch(getHistoryMoneyFilterFailed(err))
  }
}

export const fetchHistoryDepoStartAsync = () => async (dispatch) => {
  dispatch(getHistoryDepo())
  try {
    const value = await Api.getHistoryDepo()
    dispatch(getHistoryDepoSuccess(value))
  } catch (err) {
    dispatch(getHistoryDepoFailed())
  }
}

export const fetchHistoryDepoFilterStartAsync = ({ obj }) => async (dispatch) => {
  dispatch(getHistoryDepoFilter())
  try {
    const value = await Api.getHistoryDepoFilter(obj)
    dispatch(getHistoryDepoSuccess(value))
  } catch (err) {
    dispatch(getHistoryDepoFilterFailed(err))
  }
}

export const fetchOrdersTypesNamesStartAsync = () => async (dispatch) => {
  dispatch(getOrdersTypesNames())
  try {
    const value = await Api.getOrdersTypesNames()
    dispatch(getOrdersTypesNamesSuccess(value))
  } catch (error) {
    dispatch(getOrdersTypesNamesFailed(error?.message))
  }
}


import {useDispatch} from 'react-redux'
import {documentsApi, TDocumentType, useLazyGetDocumentQuery,} from '../redux/documents/documentsApi'
import {Dispatch} from "redux";

export const NEW_RECORD_ACTION = 'I'
export const UPDATE_RECORD_ACTION = 'U'

const DOCUMENTS_OBJECTUID = 'Documents'

const NEED_SIGN_STATUS_UID = 'NeedSign'

const POSSIBLY_DEFINED_NEED_SIGN_GRID_UID = 'NeedSign'
export const ALWAYS_DEFINED_GRID_UIDS = ['Broker', 'Depo', 'UsersRequests']

type TRecordActionType = typeof NEW_RECORD_ACTION | typeof UPDATE_RECORD_ACTION
type TRecordGridUID = TDocumentType

interface IParsedResponse {
  detail?: {
    gridUid?: TRecordGridUID
    action?: TRecordActionType
  }
  id?: string
  message?: string
  objectUid?: 'Documents' | 'reports'
  recordId?: number
  statusUid?: 'new'
  userID?: number
  _links?: any[]
}

interface IUpdateCacheParams {
  dispatch: Dispatch<any>,
  recordId: number,
  documentData: any,
  recordAction: TRecordActionType,
  gridUid: TRecordGridUID,
}

export const updateCache = (
  {
    dispatch,
    recordId,
    recordAction,
    gridUid,
    documentData,
  }: IUpdateCacheParams): void  => {
    if (recordAction === NEW_RECORD_ACTION) {
      // ADD data for exact, defined gridUid if it's not exists in current cache
      if (ALWAYS_DEFINED_GRID_UIDS.includes(gridUid)) {
        dispatch(documentsApi.util.updateQueryData('getDocumentsList', gridUid,
            (draft) => {
              const documentNotExist = -1 === draft?.findIndex((existingDocument) => existingDocument?.id === documentData?.id)

              if (documentNotExist) {
                draft?.unshift(documentData)
              }
            }
          )
        )
      }
      // TODO: разобраться с рудиментом
      // ADD data for 'NeedSign' gridUid, only if it's statusUid === NeedSign and it's not exist in current cache
      if (documentData?.statusUid === NEED_SIGN_STATUS_UID) {
        dispatch(documentsApi.util.updateQueryData('getDocumentsList', POSSIBLY_DEFINED_NEED_SIGN_GRID_UID,
            (draft) => {
              const documentNotExist = -1 === draft?.findIndex((existingDocument) => existingDocument?.id === documentData?.id)
              if (documentNotExist) {
                draft?.unshift(documentData)
              }
            }
          )
        )
      }
    }

    if (recordAction === UPDATE_RECORD_ACTION) {


      // UPDATE any fields in existing document with exact, defined gridUid
      if (ALWAYS_DEFINED_GRID_UIDS.includes(gridUid)) {
        dispatch(
          documentsApi.util.updateQueryData(
            'getDocumentsList',
            gridUid,
            (draft) => {
              const foundedIndex = draft?.findIndex(
                (currentDocument) => currentDocument?.id === recordId
              )

              if (foundedIndex !== -1) {
                draft[foundedIndex] = documentData
              }
            }
          )
        )
      }

    // UPDATE (remove) fields from SignedUid if it Signed and exists in current cache
    if (documentData?.statusUid === 'Signed') {
      dispatch(documentsApi.util.updateQueryData('getDocumentsList', POSSIBLY_DEFINED_NEED_SIGN_GRID_UID, (draft) => {
        const indexToRemove = draft?.findIndex(
          (currentSignDocument) => currentSignDocument?.id === recordId
        )

        if (indexToRemove !== -1) {
          draft?.splice(indexToRemove, 1)
        }
      }))
    }
  }
}

export const useUpdateQueryCacheFromNotificationService = () => {
  const dispatch = useDispatch()

  const [getDocumentData] = useLazyGetDocumentQuery()

  const handleSuccessResponse = async (parsedResponse: IParsedResponse) => {
    const documentData = await getDocumentData(parsedResponse?.recordId)?.unwrap()

      updateCache({
        dispatch,
        recordId: parsedResponse?.recordId,
        recordAction: parsedResponse?.detail?.action,
        gridUid: parsedResponse?.detail?.gridUid,
        documentData: documentData,
      })
  }

  return (parsedResponse) => {
    if (parsedResponse?.objectUid === DOCUMENTS_OBJECTUID && parsedResponse?.recordId) {
      handleSuccessResponse(parsedResponse)
    }
  }
}

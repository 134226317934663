import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import Box from '@material-ui/core/Box'
import DateFnsUtils from '@date-io/date-fns'
import ruRU from 'date-fns/locale/ru'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Hidden from '@material-ui/core/Hidden'
import useStyles from './panel.style'
import CustomSelect from '../CustomControls/CustomSelect'
import CustomIconButton from '../CustomControls/CustomIconButton'
import CustomDatepicker from '../CustomControls/CustomDatepicker'
import useConnect from '../../utils/Connect'
import endpoints from '../../api/endpoints'
import { fetchPostReportsStartAsync, postReportSuccess } from '../../redux/reports/reportsSlice'
import { fetchMetadataStartAsync, getMetadataSuccess } from '../../redux/metadata/metadataSlice'
import { selectConnectionState } from '../../redux/connection/selector'
import { selectCurrentMetadata } from '../../redux/metadata/selector'
import CustomModal from '../CustomModal/CustomModal'
import { selectCurrentError } from '../../redux/error/selector'
import { selectPostReport } from '../../redux/reports/selector'
import PageHeading from '../PageHeading/PageHeading'

const Broker = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [account, setAccount] = useState('')
  const [type, setType] = useState(1)
  const [dateFrom, setDateFrom] = useState(undefined)
  const [dateTo, setDateTo] = useState(undefined)
  const [singleDate, setSingleDate] = useState(null)
  const [formatValue, setFormatValue] = useState('pdf')
  const [emailValue, setEmailValue] = useState('да')

  const [accountError, setAccountError] = useState(false)
  const [typeError, setTypeError] = useState(false)
  const [dateFromError, setDateFromError] = useState(false)
  const [dateToError, setDateToError] = useState(false)
  const [singleDateError, setSingleDateError] = useState(false)
  const [accountTextError, setAccountTextError] = useState('')
  const [typeTextError, setTypeTextError] = useState('')
  const [dateFromTextError, setDateFromTextError] = useState('')
  const [dateToTextError, setDateToTextError] = useState('')
  const [singleDateTextError, setSingleDateTextError] = useState('')
  const [open, setOpen] = useState(false)
  const [formDisabled, setFormDisabled] = useState(false)

  const connectedWS = useSelector(selectConnectionState)
  const reportsBrokerData = useSelector(selectCurrentMetadata)
  const currentPostReport = useSelector(selectPostReport)
  const serverError = useSelector(selectCurrentError)
  const accountList = reportsBrokerData?.references?.accounts
  const reportFormats = reportsBrokerData?.references?.reportFormats

  useEffect(() => {
    if (serverError) {
      if (serverError?.errors?.Account?.length) {
        setAccountError(true)
        setAccountTextError(serverError?.errors?.Account[0])
      }
      if (serverError?.errors?.DateFrom?.length) {
        setDateFromError(true)
        setDateFromTextError(serverError?.errors?.DateFrom[0])
      }
      if (serverError?.errors?.DateTo?.length) {
        setDateToError(true)
        setDateToTextError(serverError?.errors?.DateTo[0])
      }
      if (serverError?.errors?.Type?.length) {
        setTypeError(true)
        setTypeTextError(serverError?.errors?.Type[0])
      }
      if (serverError?.errors?.SingleDate?.length) {
        setSingleDateError(true)
        setSingleDateTextError(serverError?.errors?.SingleDate[0])
      }
      setFormDisabled(false)
    }
    if (serverError?.Message) {
      setFormDisabled(false)
    }
  }, [serverError])

  const fetchMetadataStart = (id) => {
    dispatch(fetchMetadataStartAsync({ id }))
  }

  const fetchPostReportsStart = async (obj) => {
    dispatch(fetchPostReportsStartAsync(obj))
  }

  const handleClose = () => {
    setOpen(false)
    history.push('/reports/log/broker')
  }

  const { getData } = useConnect({
    link: `GET /api${endpoints.metadata.getMetadata('Reports.Type.Broker')}`,
    startFetching: fetchMetadataStart,
    action: getMetadataSuccess,
  })

  const { getData: postReport } = useConnect({
    link: `POST /api${endpoints.reports.request}`,
    startFetching: fetchPostReportsStart,
    action: postReportSuccess,
  })

  useEffect(() => {
    if (connectedWS) {
      getData('Reports.Type.Broker')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedWS])

  const clearFields = () => {
    setAccount('')
    setDateFrom(null)
    setDateTo(null)
  }

  useEffect(() => {
    if (currentPostReport) {
      setOpen(true)
      clearFields()
    }
    return () => {
      dispatch(postReportSuccess(null))
    }
    // eslint-disable-next-line
  }, [currentPostReport])

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    setFormDisabled(true)
    const currentAccount = _.filter(accountList, (item) => item.id === account)
    const reqObj = {
      account: currentAccount[0],
      dateFrom: moment(dateFrom || singleDate).format('YYYY-MM-DD'),
      dateTo: moment(dateTo || singleDate).format('YYYY-MM-DD'),
      dateTime: moment(new Date()).format('YYYY-MM-DD'),
      doSendEmail: emailValue === 'да',
      format: {
        uid: formatValue.toUpperCase(),
      },
      type: 'broker',
    }
    postReport(reqObj)
  }

  const handleChangeAccount = (event) => {
    setAccount(event.target.value)
    setAccountError(false)
  }

  const handleChangeType = (event) => {
    setType(event.target.value)
    setTypeError(false)
  }

  const handleChangeDateFrom = (date) => {
    setDateFrom(date)
    setDateFromError(false)
  }

  const handleChangeDateTo = (date) => {
    setDateTo(date)
    setDateToError(false)
  }

  const handleChangeSingleDate = (date) => {
    setSingleDate(date)
    setSingleDateError(false)
  }

  const handleChangeFormatRadio = (event) => {
    setFormatValue(event.target.value)
  }

  const handleChangeEmailRadio = (event) => {
    setEmailValue(event.target.value)
  }

  const handleClickPrev = () => {
    history.go(-1)
  }

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={ruRU}
    >
      <PageHeading text="Новый отчет" />
      <div className={classes.wrapper}>
        <Box
          pt="49px"
          className={classes.boxWrapper}
          maxWidth={1008}
        >
          <form
            className={classes.form}
            onSubmit={handleSubmitForm}
          >
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <CustomSelect
                  error={accountError}
                  errorText={accountTextError}
                  label="Инвестиционный счёт"
                  value={account}
                  onChange={handleChangeAccount}
                  unique
                >
                  {reportsBrokerData
                    && accountList?.map((item) => {
                      const { id, number } = item

                      return (
                        <MenuItem
                          key={id}
                          value={id}
                          account={item}
                        >
                          {number}
                        </MenuItem>
                      )
                    })}
                </CustomSelect>
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <CustomSelect
                  error={typeError}
                  errorText={typeTextError}
                  label="Вид отчета"
                  value={type}
                  onChange={handleChangeType}
                  unique
                >
                  <MenuItem value={1}>Отчет за период</MenuItem>
                  <MenuItem value={2}>Отчет на дату</MenuItem>
                </CustomSelect>
              </Grid>
              <Grid
                item
                lg={4}
                sm={6}
                xs={12}
              >
                <FormControl
                  className={classes.formControl}
                  component="fieldset"
                >
                  <FormLabel
                    component="legend"
                    className={classes.fieldsetLegend}
                  >
                    Формат
                  </FormLabel>
                  <RadioGroup
                    className={classes.radioGroup}
                    aria-label="format"
                    name="format"
                    value={formatValue}
                    onChange={handleChangeFormatRadio}
                  >
                    {(type === 0 || type === 1) && (
                      <>
                        {!!reportFormats
                          && reportFormats?.map((element) => (
                            <FormControlLabel
                              value={element.uid.toLowerCase()}
                              control={<Radio />}
                              label={element.name}
                              key={element.uid}
                            />
                          ))}
                      </>
                    )}
                    {type === 2 && (
                      <FormControlLabel
                        value="pdf"
                        control={<Radio />}
                        label="PDF"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}
            >
              {(type === 0 || type === 1) && (
                <>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <CustomDatepicker
                      error={dateFromError}
                      errorText={dateFromTextError}
                      label="Период с"
                      setDateFrom={setDateFrom}
                      isDateFrom
                      maxDate={dateTo || new Date().setDate(new Date().getDate() - 1)}
                      value={dateFrom}
                      onChange={handleChangeDateFrom}
                      unique
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <CustomDatepicker
                      error={dateToError}
                      errorText={dateToTextError}
                      label="Период по"
                      setDateTo={setDateTo}
                      isDateTo
                      minDate={dateFrom}
                      maxDate={new Date().setDate(new Date().getDate() - 1)}
                      value={dateTo}
                      onChange={handleChangeDateTo}
                      unique
                    />
                  </Grid>
                </>
              )}
              {type === 2 && (
                <>
                  <Hidden mdDown>
                    <Grid
                      item
                      lg={4}
                    />
                  </Hidden>
                  <Grid
                    item
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <CustomDatepicker
                      error={singleDateError}
                      errorText={singleDateTextError}
                      label="Дата"
                      maxDate={new Date().setDate(new Date().getDate() - 1)}
                      value={singleDate}
                      onChange={handleChangeSingleDate}
                      unique
                    />
                  </Grid>
                </>
              )}
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <FormControl
                  className={classes.formControl}
                  component="fieldset"
                >
                  <FormLabel
                    component="legend"
                    className={classes.fieldsetLegend}
                  >
                    Отправить на почту?
                  </FormLabel>
                  <RadioGroup
                    className={classes.radioGroup}
                    aria-label="send-to-email"
                    name="send-to-email"
                    value={emailValue}
                    onChange={handleChangeEmailRadio}
                  >
                    <FormControlLabel
                      value="да"
                      control={<Radio />}
                      label="Да"
                    />
                    <FormControlLabel
                      value="нет"
                      control={<Radio />}
                      label="Нет"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                lg={8}
              />
              <Grid
                item
                lg={4}
                xs={12}
                className={classes.btnGroup}
              >
                <CustomIconButton
                  type="submit"
                  disabled={formDisabled}
                >
                  Заказать
                </CustomIconButton>
                <CustomIconButton
                  type="button"
                  onClick={handleClickPrev}
                >
                  Отменить
                </CustomIconButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
      <CustomModal
        open={open}
        title="Отчет заказан"
        handleClose={handleClose}
      />
    </MuiPickersUtilsProvider>
  )
}

export default Broker

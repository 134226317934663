import { createSelector } from '@reduxjs/toolkit'

const selectHeartbeats = (state) => state.heartbeats

export const selectCurrentHeartbeats = createSelector(
  [selectHeartbeats],
  (state) => state.heartbeats,
)

export const selectCurrentHeartbeatsError = createSelector(
  [selectHeartbeats],
  (state) => state.error,
)

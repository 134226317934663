import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux'
import { Icon } from 'ui/components';
import { StyledNotificationButton, StyledNotificationButtonCounter, StyledNotificationPopover } from './styled';
import { INotificationButtonProps } from './types';
import { useOnClickOutside } from "hooks/useOnclickOutside";
import { selectCurrentNotifications } from 'redux/notifications/selector';
import Popover from 'antd/lib/popover';
import 'antd/lib/popover/style/css';
import { BadgeCounter } from "../../../../atoms/BadgeCounter";


export const NotificationButton: React.FC<INotificationButtonProps> = ({ value, onOpen }) => {

  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  // useOnClickOutside(menuRef, () => setIsOpen(false));
  const notifications = useSelector(selectCurrentNotifications)
  const trueNotifications = notifications?.length >= 0
    && notifications.filter((item: any) => item.message !== null).reverse()

  const handleClick = (value) => {
    if (value) {
      onOpen();
    }
    setIsOpen(value);
  }
  return (
      <Popover
        placement='bottomRight'
        open={isOpen}
        trigger="click"
        onOpenChange={value => handleClick(value)}
        content={<StyledNotificationPopover>
          <ul>
            {trueNotifications && trueNotifications.length ? trueNotifications.map((notification: any) => (
              <li key={notification.id}>{notification.message}</li>)) : <li>Список уведомлений пуст</li>}
          </ul>
        </StyledNotificationPopover>}>
        <StyledNotificationButton>
          <BadgeCounter badgeContent={value} yPos={-70}>
            <Icon name="bell" size={'md'} />
          </BadgeCounter>
        </StyledNotificationButton>
      </Popover>
  );
}

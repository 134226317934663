import useMedia from 'hooks/useMedia'
import React, { FC, memo } from 'react'
import { Field, FormCol, FormRow, TextField } from 'ui/components'
import Accordion from 'ui/components/molecules/Accordion'
import { useSwitchLanguageTabs } from '../../../hooks'
import { LANGUAGES_LIST } from '../../../types'
import { StyledBeneficiaresAddressRow, StyledBeneficiaresRow } from '../styled'
import { IAddressInformation } from './types'

const AddressInformation: FC<IAddressInformation> = ({ title, addressData, ...arrordionProps }) => {
  const {
    country,
    postCode,
    regionRu,
    cityRu,
    streetRu,
    house,
    building,
    block,
    apartment,
    office,
    regionEn,
    cityEn,
    streetEn,
  } = addressData || {}

  const { isDesktop } = useMedia()

  const { TabBar, activeTab } = useSwitchLanguageTabs({ initialLanguage: LANGUAGES_LIST.RU })

  const isRuLang = activeTab === LANGUAGES_LIST.RU

  const countryName = isRuLang ? country?.nameRu : country?.nameEn || ''
  const regionName = isRuLang ? regionRu : regionEn || ''
  const cityName = isRuLang ? cityRu : cityEn || ''
  const streetName = isRuLang ? streetRu : streetEn || ''

  return (
    <Accordion
      {...(isDesktop && { titleType: 'bordered' })}
      withShadow={false}
      padding={0}
      variant="plain"
      title={title}
      bodyPaddingTop={24}
      {...arrordionProps}
    >
      <FormCol gap={24}>
        <FormCol>
          <TabBar />
        </FormCol>

        <StyledBeneficiaresRow mobileColumn>
          <Field label="Страна">
            <TextField disabled value={countryName} />
          </Field>

          <Field label="Индекс">
            <TextField disabled value={postCode} />
          </Field>
        </StyledBeneficiaresRow>

        <Field label="Регион">
          <TextField disabled value={regionName} />
        </Field>

        <StyledBeneficiaresRow mobileColumn>
          <Field label="Город">
            <TextField disabled value={cityName} />
          </Field>

          <Field label="Улица">
            <TextField disabled value={streetName} />
          </Field>
        </StyledBeneficiaresRow>

        <StyledBeneficiaresAddressRow>
          <Field label="Дом">
            <TextField disabled value={house} />
          </Field>

          <Field label="Строение">
            <TextField disabled value={building} />
          </Field>

          <Field label="Корпус">
            <TextField disabled value={block} />
          </Field>

          <Field label="Квартира">
            <TextField disabled value={apartment} />
          </Field>

          <Field label="Офис">
            <TextField disabled value={office} />
          </Field>
        </StyledBeneficiaresAddressRow>
      </FormCol>
    </Accordion>
  )
}

export default memo(AddressInformation)

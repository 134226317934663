import React from 'react'
import { useSwitchLanguageTabs } from 'ui/components/pages/Profile/hooks'
import { LANGUAGES_LIST } from 'ui/components/pages/Profile/types'
import { Accordion } from 'ui/components/molecules/Accordion'
import { FormCol, FormRow, TextField } from 'ui/components/atoms'
import { Field } from 'ui/components/molecules'

import type { FC } from 'react'
import type { AddressInfoProps } from './types'
import { StyledBeneficiaresAddressRow } from 'ui/components/pages/Profile/AdditionalInfo/Beneficiares/styled'
import CustomerBlocksAccordion from '../CustomerBlocksAccordion'
import useMedia from 'hooks/useMedia'

/**
 * Адрес в соответствии с единым государственным реестром юридических лиц (ЕГРЮЛ).
 *
 * @param title - Заголовок блока.
 * @param countryEn - Страна (на английском).
 * @param countryRu - Страна (на русском).
 * @param postCode - Индекс.
 * @param regionEn - Регион (на английском).
 * @param regionRu - Регион (на русском).
 * @param cityEn - Город (на английском).
 * @param cityRu - Город (на русском).
 * @param streetEn - Улица (на английском).
 * @param streetRu - Улица (на русском).
 * @param house - Дом.
 * @param building - Строение.
 * @param block - Корпус.
 * @param apartment - Квартира.
 * @param office - Офис.
 * @param isOpen - Открыт ли по умолчанию аккордеон.
 *
 * @constructor
 */
export const AddressInfo: FC<AddressInfoProps> = ({
  title,

  countryEn = '',
  countryRu = '',

  postCode = '',

  regionEn = '',
  regionRu = '',

  cityEn = '',
  cityRu = '',

  streetEn = '',
  streetRu = '',

  house = '',
  building = '',
  block = '',
  apartment = '',
  office = '',

  isOpen = false,
}) => {
  const { TabBar, activeTab } = useSwitchLanguageTabs({ initialLanguage: LANGUAGES_LIST.RU })

  const { isMobile, isTablet } = useMedia()

  const otherAddress = (
    <>
      <Field label="Корпус">
        <TextField disabled value={block} />
      </Field>
      <Field label="Квартира">
        <TextField disabled value={apartment} />
      </Field>
      <Field label="Офис">
        <TextField disabled value={office} />
      </Field>
    </>
  )

  return (
    <CustomerBlocksAccordion
      {...(isTablet && { titleMaxWidth: 530 })}
      title={title}
      variant="plain"
      isOpenDefault={isOpen}
    >
      <FormCol gap={24} gapMobile={20}>
        <FormCol gap={24}>
          <TabBar />

          <FormRow gap={16} tabletRowDirection gapTablet={16} gapMobile={20}>
            {/*
          СТРАНА
        */}
            <Field label="Страна">
              <TextField disabled value={activeTab === LANGUAGES_LIST.RU ? countryRu : countryEn} />
            </Field>

            {/*
          ИНДЕКС
        */}
            <Field label="Индекс">
              <TextField disabled value={postCode} />
            </Field>
          </FormRow>
        </FormCol>

        {/*
        РЕГИОН
      */}
        <FormCol>
          <Field label="Регион">
            <TextField disabled value={activeTab === LANGUAGES_LIST.RU ? regionRu : regionEn} />
          </Field>
        </FormCol>

        <FormRow gap={16} tabletRowDirection gapTablet={16} gapMobile={20}>
          {/*
          Город
        */}
          <Field label="Город">
            <TextField disabled value={activeTab === LANGUAGES_LIST.RU ? cityRu : cityEn} />
          </Field>

          {/*
          УЛИЦА
        */}
          <Field label="Улица">
            <TextField disabled value={activeTab === LANGUAGES_LIST.RU ? streetRu : streetEn} />
          </Field>
        </FormRow>

        <FormRow gap={16} tabletRowDirection mobileRowDirection gapTablet={15} gapMobile={16}>
          {/*
          ДОМ
        */}
          <Field label="Дом">
            <TextField disabled value={house} />
          </Field>

          {/*
          СТРОЕНИЕ
        */}
          <Field label="Строение">
            <TextField disabled value={building} />
          </Field>

          {/*
          КОРПУС
        */}

          {/*
          КВАРТИРА
        */}

          {/*
          КВАРТИРА
        */}
          {!isMobile && otherAddress}
        </FormRow>

        {isMobile && (
          <FormRow mobileRowDirection gapMobile={16}>
            {otherAddress}
          </FormRow>
        )}
      </FormCol>
    </CustomerBlocksAccordion>
  )
}

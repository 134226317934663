import React, { useState } from 'react'
import Media from 'react-media'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import useStyles from './sharedStyles'
import CustomInput from '../../CustomControls/CustomInput'
import { ReactComponent as ArrowBottomIcon } from '../../../assets/images/arrow-bottom-mobile.svg'
import { GLOBAL_BREAKPOINTS as breakpoints } from '../../../config/breakpoints'

export default function ProfileLastCollapseInfoContent({ handleClickNextPanel }) {
  const classes = useStyles()
  const [infosContent, setInfosContent] = useState({
    owned: '',
    fullName: '',
    address: '',
  })

  const handleChangeInfosContent = (event) => setInfosContent({
    ...infosContent,
    [event.target.name]: event.target.value,
  })

  return (
    <>
      <Media
        query="(min-width: 577px)"
        render={() => (
          <Grid
            container
            className={classes.row}
            justify="space-between"
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <CustomInput
                className={classes.longLabelField}
                name="owned"
                label="Состав имущества, находящегося в управлении (собственности)"
                value={infosContent.owned}
                fullWidth
                variant="outlined"
                size="small"
                onChange={handleChangeInfosContent}
                disabled
                unique
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <CustomInput
                className={classes.longLabelField}
                name="fullName"
                label="ФИО (при наличии) / наименование учредителей и доверительного собственника (управляющего)"
                value={infosContent.fullName}
                fullWidth
                variant="outlined"
                size="small"
                onChange={handleChangeInfosContent}
                disabled
                unique
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <CustomInput
                className={classes.longLabelField}
                name="address"
                label="Адрес места жительства (места нахождения) учредителей и доверительного собственника (управляющего)"
                value={infosContent.address}
                fullWidth
                variant="outlined"
                size="small"
                onChange={handleChangeInfosContent}
                disabled
                unique
              />
            </Grid>
          </Grid>
        )}
      />
      <Media
        query={`(max-width: ${breakpoints.phone_large}px)`}
        render={() => (
          <div className={classes.Mobile}>
            {infosContent.owned && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Состав имущества, находящегося в управлении (собственности)
              </div>
              <div className={classes.Mobile__blockText}>
                {infosContent.owned}
              </div>
            </div>
            )}
            {infosContent.fullName && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                ФИО (при наличии) /
                наименование учредителей и доверительного собственника (управляющего)
              </div>
              <div className={classes.Mobile__blockText}>
                {infosContent.fullName}
              </div>
            </div>
            )}
            {infosContent.address && (
            <div className={classes.Mobile__block}>
              <div className={classes.Mobile__blockHeader}>
                Адрес места жительства (места нахождения)
                учредителей и доверительного собственника (управляющего)
              </div>
              <div className={classes.Mobile__blockText}>
                {infosContent.address}
              </div>
            </div>
            )}
            {handleClickNextPanel && (
              <div className={classes.MobileFilter__toggleBtnWrapper}>
                <button
                  type="button"
                  className={classes.bottomSidebar__toggleBtn}
                  onClick={handleClickNextPanel}
                >
                  <ArrowBottomIcon />
                </button>
              </div>
            )}
          </div>
        )}
      />
    </>
  )
}
ProfileLastCollapseInfoContent.defaultProps = {
  handleClickNextPanel: () => {},
}

ProfileLastCollapseInfoContent.propTypes = {
  handleClickNextPanel: PropTypes.func,
}
